<div class="modal-header">
  <h4 ngbAutoFocus class="modal-title">Account information</h4>
</div>
<div id="blue-header" class="bolt-background-light-blue" *ngIf="pni">
  <div class="bolt-row bolt-space-inner-wide-xl">
    <div class="bolt-col-9">
      <div class="flex-column">
        <div id="header-pni-name" class="bolt-subheading-lg">
          {{ pni.person?.firstName }} {{ pni.person?.lastName }}
        </div>
        <div id="header-pni-address" class="nwpii">
          {{ pni?.address?.addressLine1 }}, {{ pni.address?.city }}
          {{ pni.address?.postalCode }}
        </div>
        <div id="header-pni-account-label">
          Account #: <span id="header-pni-account-number">{{ accountId }}</span>
        </div>
      </div>
    </div>
    <div class="bolt-col-3 flex-items-center">
      <div id="header-agency-code-label">
        Agency code: <span id="header-agency-code">{{ agencyCode }}</span>
      </div>
    </div>
  </div>
</div>
<div class="modal-body custom-padding">
  <div id="loading-spinner" *ngIf="submitting">
    <span>Updating...</span>
    <bolt-waiting-indicator minimal></bolt-waiting-indicator>
  </div>
  <h5 class="bolt-space-bottom-lg">Producer and submission details</h5>
  <ng-container *ngFor="let product of products">
    <nwx-account-info-form
      [form]="form"
      [product]="product"
      [state]="stateForProduct(product.type)"
      [agencyCode]="agencyCode"
      [canEdit]="canEdit"
      (edit)="trackEdit(product.type)"
    ></nwx-account-info-form>
  </ng-container>
  <div>
    <h5
      [class.bolt-space-bottom-lg]="!editingAccountRegistration"
      [class.mb-0]="editingAccountRegistration"
    >
      Online account registration setup
    </h5>
    <ng-container *ngIf="editingAccountRegistration">
      <div class="bolt-space-bottom-xs ml--22">
        <nwx-message-bubble
          [borderless]="true"
          iconName="info-circle-filled"
          iconColor="theme-info"
          iconSize="sm"
        >
          <div class="bolt-body-copy-sm">
            {{ accountSetupDisclaimer }}
          </div>
        </nwx-message-bubble>
      </div>
    </ng-container>
    <div class="bordered">
      <nwx-account-registration-container
        [submitFromAcctInfo]="submitAccountRegistration"
        [canEdit]="canEdit"
        (toggleDisclaimer)="onToggleDisclaimer()"
        (formReady)="addChildForm('nationwideAccountRegistrationInfo', $event)"
      ></nwx-account-registration-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <bolt-button-bar>
    <bolt-button [slot]="editingAny ? 'cancel' : 'forward'" (click)="close()"
      >Close</bolt-button
    >
    <bolt-button
      *ngIf="editingAny"
      slot="forward"
      type="primary"
      (click)="save()"
      >Save and continue</bolt-button
    >
  </bolt-button-bar>
</div>
