import { createAction, props } from '@ngrx/store';
import { HouseholdMemberEntity } from './household-member.model';
import { MemberEntity } from '@entities/member/member.reducer';
import { ErrorModel } from '@entities/error/error.model';
import { HouseholdMemberRequest } from '@core/models/api/request/household-member-request.model';

export const enum HouseholdMemberActionTypes {
  UPSERT_HOUSEHOLD_MEMBER = '[Umbrella Details] Add or Update Household Member',
  UPSERT_HOUSEHOLD_MEMBER_SUCCESS = '[Umbrella Details] Add or Update Household Member Success',
  UPSERT_HOUSEHOLD_MEMBER_FAIL = '[Umbrella Details] Add or Update Household Member Fail',
  STORE_HOUSEHOLD_MEMBER = '[Umbrella Details] Store Household Member',
  REMOVE_HOUSEHOLD_MEMBER = '[Umbrella Details] Remove Household Member',
  REMOVE_HOUSEHOLD_MEMBER_SUCCESS = '[Umbrella Details] Remove Household Member Success',
  REMOVE_HOUSEHOLD_MEMBER_FAIL = '[Umbrella Details] Remove Household Member Fail',
}

export const upsertHouseholdMember = createAction(
  HouseholdMemberActionTypes.UPSERT_HOUSEHOLD_MEMBER,
  props<{ payload: HouseholdMemberEntity; correlationId?: string }>()
);

export const upsertHouseholdMemberSuccess = createAction(
  HouseholdMemberActionTypes.UPSERT_HOUSEHOLD_MEMBER_SUCCESS,
  props<{ payload: MemberEntity; correlationId?: string }>()
);
export const upsertHouseholdMemberFail = createAction(
  HouseholdMemberActionTypes.UPSERT_HOUSEHOLD_MEMBER_FAIL,
  props<{ payload: ErrorModel; correlationId?: string }>()
);

export const removeHouseholdMember = createAction(
  HouseholdMemberActionTypes.REMOVE_HOUSEHOLD_MEMBER,
  props<{ payload: HouseholdMemberEntity; correlationId?: string }>()
);
export const removeHouseholdMemberSuccess = createAction(
  HouseholdMemberActionTypes.REMOVE_HOUSEHOLD_MEMBER_SUCCESS,
  props<{
    payload: MemberEntity;
    correlationId?: string;
    request: HouseholdMemberRequest;
  }>()
);
export const removeHouseholdMemberFail = createAction(
  HouseholdMemberActionTypes.REMOVE_HOUSEHOLD_MEMBER_FAIL,
  props<{
    payload: ErrorModel;
    householdMemberId: number;
    correlationId?: string;
  }>()
);
