import {
  PolicyHolderType,
  ProductType,
  QuoteStatus,
} from '@app/core/models/api/dsm-types';
import { PersonEntity } from '@app/core/models/entities/person.entity';
import { MemberFormOptions } from '@app/core/models/views/person-form-options';
import { MemberModel } from '@app/core/models/views/person.model';
import { FeatureFlagsModel } from '@app/core/store/entities/feature-flag/feature-flag.model';
import { ProductModel } from '@app/core/store/entities/product/product.model';
import { stateSpecificFlags } from '@assets/metadata/stateSpecificFlags';
import { EntityIds } from '@core/interfaces/interfaces';
import {
  QuoteRetrieveMSAResponse,
  QuoteRetrievePersonalAutoResponse,
} from '@core/models/api/response/retrieve-response.model';
import {
  BasePersonEntity,
  DsmMemberEntityType,
  MemberDriverRole,
  MemberPolicyRole,
  PolicyRoleSequence,
} from '@core/models/entities/base.entity';
import { MemberActions } from '@core/store/actions';
import { DiscountEntity } from '@core/store/entities/discount/discount.entity';
import { DriverEntity } from '@core/store/entities/driver/driver.entity';
import {
  EligibleDiscountId,
  EligibleDiscountsEntity,
} from '@core/store/entities/eligible-discounts/eligible-discounts.entity';
import { MemberEntity } from '@core/store/entities/member/member.reducer';
import { Action } from '@ngrx/store';
import {
  DriversOccupationCodes,
  MembershipTypes,
  Infractiontype,
  ModifierDescriptions,
  GoodDriverDisplayByKey,
  DriverHistoryTypes,
  PropertyProductTypes,
  MaritalStatusToDsmCodes,
  DriverRelationToPNI,
  MAX_AGE_FOR_YOUTH,
} from '../constants/app-constants';
import { DateUtils } from './date.utils';
import { PersonRoleSelectionUtils } from './person-role-selection.utils';
import { Nullable } from './type.utils';
import { ProductUtils } from './product.util';
import { GeneralUtils } from '@shared/utils/general.utils';

// TODO - Prune this file down

export class PersonUtils {
  static isSamePerson(
    a: Nullable<PersonEntity>,
    b: Nullable<PersonEntity>
  ): boolean {
    const aYearOfBirth = this.getYearOfBirth(a?.dateOfBirth);
    const bYearOfBirth = this.getYearOfBirth(b?.dateOfBirth);

    const answer =
      this.equalIfSetReq(
        a?.firstName?.toLocaleLowerCase(),
        b?.firstName?.toLocaleLowerCase()
      ) &&
      this.equalIfSetReq(
        a?.lastName?.toLocaleLowerCase(),
        b?.lastName?.toLocaleLowerCase()
      ) &&
      this.equalIfSetOptional(
        a?.middleName?.toLocaleLowerCase(),
        b?.middleName?.toLocaleLowerCase()
      ) &&
      this.equalIfSetOptional(
        a?.suffix?.toLocaleLowerCase(),
        b?.suffix?.toLocaleLowerCase()
      ) &&
      this.equalIfSetOptional(aYearOfBirth, bYearOfBirth) &&
      this.equalIfSetOptional(a?.gender, b?.gender);
    return answer;
  }

  static isYoungDriver(member: MemberModel): boolean {
    if (!member?.person?.dateOfBirth) {
      return false;
    }

    const diff = DateUtils.getDiffInYearsFromNow(member.person?.dateOfBirth);
    return diff < MAX_AGE_FOR_YOUTH;
  }

  static equalIfSetOptional(a: Nullable<string>, b: Nullable<string>): boolean {
    if (!!a && !!b) {
      return a === b;
    }
    return true;
  }

  static equalIfSetReq(a: Nullable<string>, b: Nullable<string>): boolean {
    if (!a || !b) {
      return false;
    }
    return a === b;
  }

  static isNotMarried(member: MemberModel): boolean {
    if (
      member?.person?.maritalStatus?.length &&
      ![MaritalStatusToDsmCodes.Married].includes(
        String(member.person?.maritalStatus)
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  static isPniForProduct(
    member: MemberModel,
    productType: ProductType
  ): boolean {
    return !!member?.policyRoles?.find(
      (r) =>
        r.productType === productType &&
        r.entityType === 'policyHolder' &&
        r.roleSequence === 'primary'
    );
  }

  static isAutoDriver(member: MemberModel): boolean {
    const isNotAutoDriver =
      member.driverRoles?.length > 0
        ? member.driverRoles?.some(
            (d) =>
              (d.driverType === 'Excluded' || d.driverType === 'NonDriver') &&
              d.reasonNonDriver?.length &&
              d.productType === 'PersonalAuto'
          )
        : true;
    const hasAutoDriver =
      member.driverRoles?.length > 0
        ? member.driverRoles?.some(
            (d) => d.driverType === 'Driver' && d.productType === 'PersonalAuto'
          )
        : true;

    if (hasAutoDriver) {
      return true;
    } else if (isNotAutoDriver) {
      return false;
    }
    return false;
  }

  static isMSADriver(member: MemberModel): boolean {
    const isNotMSADriver =
      member.driverRoles?.length > 0
        ? member.driverRoles?.some(
            (d) =>
              (d.driverType === 'Excluded' || d.driverType === 'NonDriver') &&
              d.reasonNonDriver?.length &&
              d.productType === 'MSA'
          )
        : true;
    const hasMSADriver =
      member.driverRoles?.length > 0
        ? member.driverRoles?.some(
            (d) => d.driverType === 'Driver' && d.productType === 'MSA'
          )
        : true;

    if (hasMSADriver) {
      return true;
    } else if (isNotMSADriver) {
      return false;
    }
    return false;
  }

  static isRVDriver(member: MemberModel): boolean {
    const isNotRVDriver =
      member.driverRoles?.length > 0
        ? member.driverRoles?.some(
            (d) =>
              (d.driverType === 'Excluded' || d.driverType === 'NonDriver') &&
              d.reasonNonDriver?.length &&
              d.productType === 'RV'
          )
        : true;

    const hasRVDriver =
      member.driverRoles?.length > 0
        ? member.driverRoles?.some(
            (d) => d.driverType === 'Driver' && d.productType === 'RV'
          )
        : true;

    if (hasRVDriver) {
      return true;
    } else if (isNotRVDriver) {
      return false;
    }
    return false;
  }

  static isBoatDriver(member: MemberModel): boolean {
    const isNotBoatDriver =
      member.driverRoles?.length > 0
        ? member.driverRoles?.some(
            (d) =>
              (d.driverType === 'Excluded' || d.driverType === 'NonDriver') &&
              d.reasonNonDriver?.length &&
              d.productType === 'Boat'
          )
        : true;

    const hasBoatDriver =
      member.driverRoles?.length > 0
        ? member.driverRoles?.some(
            (d) => d.driverType === 'Driver' && d.productType === 'Boat'
          )
        : true;
    if (hasBoatDriver) {
      return true;
    } else if (isNotBoatDriver) {
      return false;
    }
    return false;
  }

  static isAgeFirstLicensedApplicable(
    featureFlags: FeatureFlagsModel,
    member: MemberModel,
    products: ProductModel[]
  ): boolean {
    if (featureFlags.ageFirstLicensed) {
      if (
        products.find((p) => p.type === 'PersonalAuto') &&
        PersonUtils.isAutoDriver(member)
      ) {
        return true;
      } else if (
        products.find((p) => p.type === 'RV') &&
        PersonUtils.isRVDriver(member)
      ) {
        return true;
      } else if (
        products.find((p) => p.type === 'MSA') &&
        PersonUtils.isMSADriver(member)
      ) {
        return true;
      } else if (
        products.find((p) => p.type === 'Boat') &&
        PersonUtils.isBoatDriver(member)
      ) {
        return true;
      }
    }
    return false;
  }

  static hasYearsDrivingExperience(member: MemberModel): number {
    const age = PersonUtils.CalculateAgeFromDateOfBirth(
      DateUtils.formatDsmDateToOld(member.person?.dateOfBirth as string)
    );
    const ageFirstLicensed = member.ageFirstLicensed ?? 16;
    const yearsOfExperience = age - ageFirstLicensed;
    if (
      PersonUtils.isAutoDriver(member) ||
      PersonUtils.isBoatDriver(member) ||
      PersonUtils.isMSADriver(member) ||
      PersonUtils.isRVDriver(member)
    ) {
      return yearsOfExperience > 0 ? yearsOfExperience : 0;
    }
    return 0;
  }

  static isYouthfulDriverApplicable(
    featureFlags: FeatureFlagsModel,
    member: MemberModel,
    products: ProductModel[]
  ): boolean {
    if (products.find((p) => p.type === 'PersonalAuto')) {
      if (PersonUtils.isAutoDriver(member)) {
        return true;
      }
    }
    if (products.find((p) => p.type === 'MSA')) {
      if (featureFlags.msaYouthfulDriver && PersonUtils.isMSADriver(member)) {
        return true;
      }
    }
    return false;
  }

  static isGoodStudentDiscountApplicable(
    featureFlags: FeatureFlagsModel,
    member: MemberModel,
    products: ProductModel[]
  ): boolean {
    if (products.find((p) => p.type === 'PersonalAuto')) {
      if (
        PersonUtils.isYoungDriver(member) &&
        PersonUtils.isYouthfulDriverApplicable(featureFlags, member, products)
      ) {
        return true;
      }
    }
    if (products.find((p) => p.type === 'RV')) {
      if (
        PersonUtils.isYoungDriver(member) &&
        featureFlags.rvGoodStudent &&
        PersonUtils.isRVDriver(member)
      ) {
        return true;
      }
    }
    return false;
  }

  static isNonCAResidentStudentDiscountVisible(member: MemberModel): boolean {
    if (
      PersonUtils.isAutoDriver(member) &&
      PersonUtils.isNotMarried(member) &&
      PersonUtils.isYoungDriver(member)
    ) {
      return true;
    } else {
      return false;
    }
  }

  static isCAResidentStudentDiscountVisible(member: MemberModel): boolean {
    if (
      PersonUtils.isAutoDriver(member) &&
      !PersonUtils.isPniForProduct(member, 'PersonalAuto') &&
      PersonUtils.isNotMarried(member) &&
      PersonUtils.hasYearsDrivingExperience(member) <= 8
    ) {
      return true;
    } else {
      return false;
    }
  }

  static isResidentStudentDiscountApplicable(
    featureFlags: FeatureFlagsModel,
    member: MemberModel,
    products: ProductModel[]
  ): boolean {
    if (products.find((p) => p.type === 'PersonalAuto')) {
      if (
        !featureFlags?.residentStudentByAgeExcluded &&
        !featureFlags?.residentStudentBasedOnYearsLicensed &&
        PersonUtils.isNonCAResidentStudentDiscountVisible(member)
      ) {
        return true;
      } else if (
        featureFlags?.residentStudentByAgeExcluded &&
        featureFlags?.residentStudentBasedOnYearsLicensed &&
        PersonUtils.isAgeFirstLicensedApplicable(
          featureFlags,
          member,
          products
        ) &&
        PersonUtils.isCAResidentStudentDiscountVisible(member)
      ) {
        return true;
      }
    }
    return false;
  }

  static isNationwideAssociateVisible(
    featureFlags: FeatureFlagsModel,
    person?: MemberModel
  ): boolean {
    if (
      !featureFlags.isNationwideAssociateVisible ||
      !person?.policyRoles?.find(
        (r) => r.entityType === 'policyHolder' && r.roleSequence === 'primary'
      )
    ) {
      return false;
    }
    return true;
  }

  static isSafetyCourseApplicable(
    featureFlags: FeatureFlagsModel,
    person: MemberModel
  ): boolean {
    const isSafetyCourseBoolean: boolean = GeneralUtils.isBoolean(
      person.safetyCourse
    );
    const product: Nullable<ProductType> = person.productType;

    const msaEligible: Nullable<boolean> =
      featureFlags.safetyCourse && product === 'MSA';
    const boatEligible: Nullable<boolean> =
      featureFlags.safetyCourseBoat && product === 'Boat';

    return !!(msaEligible || boatEligible) && isSafetyCourseBoolean;
  }

  static formOptionsForPerson(
    person: MemberModel,
    featureFlags: FeatureFlagsModel,
    quoteStatus: QuoteStatus,
    products: ProductModel[]
  ): MemberFormOptions {
    const options = {} as MemberFormOptions;
    const isAutoSelected = products.some(
      (product) => product.type === 'PersonalAuto'
    );
    const isMSASelected = products.some((product) => product.type === 'MSA');
    const isBoatSelected = products.some((product) => product.type === 'Boat');
    const isRVSelected = products.some((product) => product.type === 'RV');
    const hasUmbrella = products.some(
      (product) => product.type === 'PersonalUmbrella'
    );

    const hasAutoDriver = this.isAutoDriver(person);
    const hasMSADriver = this.isMSADriver(person);
    const hasBoatDriver = this.isBoatDriver(person);
    const hasRVDriver = this.isRVDriver(person);

    (options.hasUmbrella ??= {
      visible: false,
      category: 'Other',
    }).visible = hasUmbrella || false;

    (options.nationwideAssociate ??= {
      visible: false,
      category: 'Discount',
    }).visible = featureFlags.isNationwideAssociateVisible || false;

    (options.hasDriverRoles ??= {
      visible: false,
      category: 'AdditionalInfo',
    }).visible =
      isAutoSelected || isBoatSelected || isMSASelected || isRVSelected;

    (options.ageFirstLicensed ??= {
      visible: false,
      category: 'AdditionalInfo',
    }).visible =
      this.isAgeFirstLicensedApplicable(featureFlags, person, products) ||
      false;
    (options.askForGenderSpecificity ??= {
      visible: false,
      category: 'Other',
    }).visible =
      featureFlags.showGenderX || featureFlags.showGenderXPowersports || false;
    (options.driverOccupation ??= {
      visible: false,
      category: 'Discount',
    }).visible =
      (PersonUtils.isPniForProduct(person, 'PersonalAuto') ||
        person?.relationToPrimaryNamedInsured === 'Spouse') &&
      (featureFlags.driverOccupation || false) &&
      hasAutoDriver;

    (options.driverTypeExcludedNotAllowed ??= {
      visible: false,
      category: 'Other',
    }).visible = featureFlags.driverTypeExcludedNotAllowed || false;

    (options.driverTypeExcludedNotAllowedPS ??= {
      visible: false,
      category: 'Other',
    }).visible = featureFlags.driverTypeExcludedNotAllowedPS || false;

    (options.driverRelationToPNI ??= {
      visible: false,
      category: 'Other',
    }).visible =
      isAutoSelected || isMSASelected || isRVSelected || isBoatSelected;
    if (
      person.policyRoles?.find(
        (r) =>
          r.entityType === 'policyHolder' &&
          r.roleSequence === 'primary' &&
          ProductUtils.isDriverProduct(r.productType)
      )
    ) {
      options.driverRelationToPNI.visible = false;
    }

    (options.driverVehicleAssignment ??= {
      visible: false,
      category: 'Other',
    }).visible =
      (isAutoSelected
        ? featureFlags.driverVehicleAssignment || false
        : false) && hasAutoDriver;

    (options.healthCarePlan ??= {
      visible: false,
      category: 'AdditionalInfo',
    }).visible = featureFlags.healthCarePlan || false;

    (options.isPipExcluded ??= {
      visible: false,
      category: 'Other',
    }).visible = featureFlags.isPipExcluded || false;

    (options.isMembership ??= {
      visible: false,
      category: 'Discount',
    }).visible = featureFlags.showMembership || false;
    (options.infractionDesc ??= {
      visible: false,
      category: 'Discount',
    }).visible =
      (isAutoSelected ? featureFlags.infractionDesc || false : false) &&
      hasAutoDriver;
    (options.pipWorkLossWaiver ??= {
      visible: false,
      category: 'AdditionalInfo',
    }).visible = featureFlags.pipWorkLossWaiver || false;
    (options.requiredUSArmedForces ??= {
      visible: false,
      category: 'Discount',
    }).visible =
      (featureFlags.requiredUSArmedForces || false) &&
      (hasAutoDriver || hasRVDriver);
    (options.requiredUSANationalGuard ??= {
      visible: false,
      category: 'Discount',
    }).visible =
      (featureFlags.requiredUSANationalGuard || false) &&
      (hasAutoDriver || hasRVDriver);
    (options.driverTrainingCourse ??= {
      visible: false,
      category: 'Discount',
    }).visible =
      (isAutoSelected
        ? featureFlags.isEligibleDiscountDriverRequest || false
        : false) && hasAutoDriver;
    (options.msaDriverTrainingCourse ??= {
      visible: false,
      category: 'Discount',
    }).visible =
      (isMSASelected ? featureFlags.msaDriverTrainingCourse || false : false) &&
      hasMSADriver;
    (options.rvDriverTrainingCourse ??= {
      visible: false,
      category: 'Discount',
    }).visible =
      (isRVSelected ? featureFlags.rvDriverTrainingCourse || false : false) &&
      hasRVDriver;
    (options.youthfulDriver ??= {
      visible: false,
      category: 'AdditionalInfo',
    }).visible =
      this.isYouthfulDriverApplicable(featureFlags, person, products) || false;
    (options.goodStudentDiscount ??= {
      visible: false,
      category: 'Discount',
    }).visible =
      this.isGoodStudentDiscountApplicable(featureFlags, person, products) ||
      false;
    (options.studentAwayDiscount ??= {
      visible: false,
      category: 'Discount',
    }).visible =
      this.isResidentStudentDiscountApplicable(
        featureFlags,
        person,
        products
      ) || false;
    (options.employmentInfo ??= {
      visible: false,
      category: 'AdditionalInfo',
    }).visible =
      (isAutoSelected ? featureFlags.employmentInfo || false : false) &&
      hasAutoDriver;
    options.quoteStatus = quoteStatus;
    (options.licenseRequired ??= {
      visible: false,
      category: 'AdditionalInfo',
    }).visible =
      (isAutoSelected || isMSASelected || isBoatSelected || isRVSelected) &&
      (hasAutoDriver || hasMSADriver || hasBoatDriver || hasRVDriver);
    (options.licenseState ??= {
      visible: false,
      category: 'AdditionalInfo',
    }).visible =
      (isAutoSelected || isMSASelected || isBoatSelected || isRVSelected) &&
      (hasAutoDriver || hasMSADriver || hasBoatDriver || hasRVDriver);
    (options.driverRelationToPNIOptions ??= {
      visible: false,
      category: 'Other',
    }).visible =
      (featureFlags.driverRelationToPNIOptions || false) && hasAutoDriver;
    options.driverOccupations = Object.entries(DriversOccupationCodes).map(
      ([value, label]) => ({
        value,
        label,
      })
    );
    options.membershipTypes = MembershipTypes;
    options.infractionTypes = Object.entries(Infractiontype).map(
      ([value, label]) => ({
        value,
        label,
      })
    );
    (options.safetyCourse ??= {
      visible: false,
      category: 'Discount',
    }).visible =
      (featureFlags.safetyCourse || featureFlags.safetyCourseBoat || false) &&
      (hasMSADriver || hasBoatDriver);
    (options.advancedDriverTrainingCourse ??= {
      visible: false,
      category: 'Discount',
    }).visible =
      (featureFlags.requireAdvancedAccidentPrevention || false) &&
      (hasAutoDriver || hasMSADriver || hasRVDriver);
    (options.requiredMSAUSArmedForces ??= {
      visible: false,
      category: 'Discount',
    }).visible =
      (featureFlags.requiredMSAUSArmedForces || false) && hasMSADriver;
    (options.yearsOfMotorcycleAndOffRoadExp ??= {
      visible: false,
      category: 'AdditionalInfo',
    }).visible =
      (featureFlags.yearsOfMotorcycleAndOffRoadExp || false) && hasMSADriver;
    (options.numberOfYearsOfBoatingExperience ??= {
      visible: false,
      category: 'AdditionalInfo',
    }).visible = !!isBoatSelected && hasBoatDriver;
    (options.nonNWHomeownerDiscount ??= {
      visible: false,
      category: 'Discount',
    }).visible = featureFlags.nonNWHomeownerDiscountVisible || false;
    (options.nonNWCondoDiscount ??= {
      visible: false,
      category: 'Discount',
    }).visible = featureFlags.nonNWCondoDiscountVisible || false;
    (options.hasMotorcycleEndorsement ??= {
      visible: false,
      category: 'AdditionalInfo',
    }).visible =
      (featureFlags.hasMotorcycleEndorsement || false) && hasMSADriver;
    const isPropertySelected = !!products.find((product) =>
      PropertyProductTypes.includes(product.type)
    );
    (options.autoMultiLineDiscount ??= {
      visible: false,
      category: 'Other',
    }).visible = !!isAutoSelected;
    (options.propertyMultiLineDiscount ??= {
      visible: false,
      category: 'Other',
    }).visible = !!isPropertySelected;
    (options.msaMultiLineDiscount ??= {
      visible: false,
      category: 'Other',
    }).visible = !!isMSASelected;
    (options.boatMultiLineDiscount ??= {
      visible: false,
      category: 'Other',
    }).visible = !!isBoatSelected;
    (options.rvMultiLineDiscount ??= {
      visible: false,
      category: 'Other',
    }).visible = !!isRVSelected;

    return options;
  }

  static isPersonSaved(person: MemberModel): boolean {
    return !!person.policyRoles?.find((role) => role.entityId);
  }

  static isDriverSaved(driverId: Nullable<number>): boolean {
    return !!driverId;
  }

  static isPolicyHolderSaved(policyHolderId: Nullable<number>): boolean {
    return !!policyHolderId;
  }

  static isHouseholdMemberSaved(householdMemberId: Nullable<number>): boolean {
    return !!householdMemberId;
  }

  static getPersonIndex(
    people: MemberModel[],
    changes: Partial<MemberModel>
  ): number {
    return people.findIndex((person) => person.entityId === changes.entityId);
  }

  // Map attributes that come from eligible or applied discounts
  // to the driver entity so the person form will populate appropriately
  static addDiscountsToDriverEntity(
    input: DriverEntity,
    // featureFlags: FeatureFlagsModel,
    response: QuoteRetrievePersonalAutoResponse | QuoteRetrieveMSAResponse
  ): DriverEntity {
    const quoteState: string = response.policyAddress.state as string;
    const appliedDiscounts: DiscountEntity[] = response.discounts;
    const output: DriverEntity = {
      ...input,
    };
    const membership = this.getMembershipFromPersonDiscounts(
      input.eligibleDiscounts
    );
    if (membership) {
      output.membership = membership;
    }
    const associate = this.getAssociateFromPersonDiscounts(
      input.eligibleDiscounts
    );
    if (associate) {
      output.isNationwideEmployee = associate.selectedOptionValue === 'true';

      if (output.isNationwideEmployee && associate.qualifyingInformation) {
        const { associateNumber } = associate.qualifyingInformation;
        output.associateNumber = associateNumber
          ? String(associateNumber)
          : undefined;
      }
    }
    const goodStudent = this.getNamedBooleanDiscount(
      input.eligibleDiscounts,
      'GoodStudent'
    );
    if (goodStudent !== null) {
      output.goodStudent = goodStudent;
    }
    const residentStudent = this.getNamedBooleanDiscount(
      input.eligibleDiscounts,
      'StudentAway'
    );
    if (residentStudent !== null) {
      output.studentAway = residentStudent;
    }
    const driverHistory = this.getDriverHistoryFromDiscounts(
      input,
      appliedDiscounts,
      quoteState
    );
    if (driverHistory) {
      output.infractionType = driverHistory;
    }
    const driverOccupation = this.getDriverOccupationFromDiscounts(
      input.eligibleDiscounts
    );
    if (stateSpecificFlags.driverOccupation.states.includes(quoteState)) {
      output.driverOccupation = driverOccupation; // want to do this even if it's null
    }
    const armedForces = this.getNamedBooleanDiscount(
      input.eligibleDiscounts,
      'USArmedForces'
    );
    if (armedForces !== null) {
      output.usArmedForces = armedForces;
    }
    const mtNationalGuard = this.getNamedBooleanDiscount(
      input.eligibleDiscounts,
      'MTNationalGuard'
    );
    if (mtNationalGuard !== null) {
      output.mtNationalGuard = mtNationalGuard;
    }
    const driverTraining = this.getDiscountFromPerson(
      input.eligibleDiscounts,
      'DriverTraining',
      PersonUtils.isDriverSaved(input.driverId)
    );
    if (driverTraining !== null) {
      output.driverTraining = driverTraining;
      const courseDate = this.getDateFromPersonDiscounts(
        input.eligibleDiscounts,
        'DriverTraining'
      );
      if (courseDate) {
        output.trainingCourseCompletionDate =
          DateUtils.formatDsmDateToOld(courseDate);
      }
    }

    const advancedDriverTraining = this.getDiscountFromPerson(
      input.eligibleDiscounts,
      'AdvancedDriverTraining',
      PersonUtils.isDriverSaved(input.driverId)
    );
    if (advancedDriverTraining !== null) {
      output.advancedDriverTraining = advancedDriverTraining;
      const courseDate = this.getDateFromPersonDiscounts(
        input.eligibleDiscounts,
        'AdvancedDriverTraining',
        true
      );
      if (courseDate) {
        output.advancedTrainingCourseCompletionDate =
          DateUtils.formatDsmDateToOld(courseDate);
      }
    }

    const safetyCourse = this.getDiscountFromPerson(
      input.eligibleDiscounts,
      'SafetyCourse',
      PersonUtils.isDriverSaved(input.driverId)
    );
    if (safetyCourse !== null) {
      output.safetyCourse = safetyCourse;
      const courseDate = this.getDateFromPersonDiscounts(
        input.eligibleDiscounts,
        'SafetyCourse'
      );
      if (courseDate) {
        output.trainingCourseCompletionDate =
          DateUtils.formatDsmDateToOld(courseDate);
      }
    }

    return output as DriverEntity;
  }

  private static getMembershipFromPersonDiscounts(
    eligibleDiscounts: Nullable<EligibleDiscountsEntity[]>
  ): string | undefined {
    const discount = eligibleDiscounts?.find(
      (d) => d?.eligibleDiscountId === 'Membership'
    );

    return discount?.selectedOptionValue || undefined;
  }

  private static getAssociateFromPersonDiscounts(
    eligibleDiscounts: Nullable<EligibleDiscountsEntity[]>
  ): Nullable<EligibleDiscountsEntity> {
    const discount = eligibleDiscounts?.find(
      (d) => d?.eligibleDiscountId === 'Associate'
    );
    return discount;
  }

  private static getNamedBooleanDiscount(
    eligibleDiscounts: Nullable<EligibleDiscountsEntity[]>,
    discountId: EligibleDiscountId
  ): boolean | null {
    const disc = eligibleDiscounts?.find(
      (d) => d.eligibleDiscountId === discountId
    );
    return disc?.selectedOptionValue === 'true'
      ? true
      : disc?.selectedOptionValue === 'false'
      ? false
      : null;
  }

  private static getDiscountFromPerson(
    eligibleDiscounts: Nullable<EligibleDiscountsEntity[]>,
    eligibleDiscountId: EligibleDiscountId,
    driverIsSaved: boolean
  ): boolean | null {
    const discount = eligibleDiscounts?.find(
      (d) => d.eligibleDiscountId === eligibleDiscountId
    );
    return discount?.selectedOptionValue === 'true'
      ? true
      : discount?.selectedOptionValue === 'false'
      ? driverIsSaved
        ? false
        : null
      : null;
  }

  private static getDateFromPersonDiscounts(
    eligibleDiscounts: Nullable<EligibleDiscountsEntity[]>,
    discountId: EligibleDiscountId,
    isAdvanced?: boolean
  ): string | null {
    const discount = eligibleDiscounts?.find(
      (d) => d.eligibleDiscountId === discountId
    );
    if (discount?.selectedOptionValue) {
      return isAdvanced
        ? discount?.qualifyingInformation
            ?.advancedTrainingCourseCompletionDate || null
        : discount?.qualifyingInformation?.trainingCourseCompletionDate || null;
    }
    return null;
  }

  private static getDriverHistoryFromDiscounts(
    person: DriverEntity,
    appliedDiscounts: DiscountEntity[],
    quoteState: string
  ): string | null {
    if (!stateSpecificFlags.infractionDesc.states.includes(quoteState)) {
      return null;
    }
    const eliteDriverDiscount = appliedDiscounts?.find(
      (d) =>
        d.description === ModifierDescriptions.ELITE_DRIVER &&
        d.isDiscountApplied
    );
    if (eliteDriverDiscount) {
      return DriverHistoryTypes.EXCELLENT;
    } else {
      if (person.infractions) {
        if (
          person.infractions.find(
            (i) => i.infractionDesc === GoodDriverDisplayByKey.Accident
          )
        ) {
          return DriverHistoryTypes.ACCIDENT;
        } else if (
          person.infractions.find(
            (i) => i.infractionDesc === GoodDriverDisplayByKey.Violation
          )
        ) {
          return DriverHistoryTypes.VIOLATION;
        } else {
          return null;
        }
      } else {
        return DriverHistoryTypes.EXCELLENT;
      }
    }
  }

  private static getDriverOccupationFromDiscounts(
    eligibleDiscounts: Nullable<EligibleDiscountsEntity[]>
  ): Nullable<string> {
    const discount = eligibleDiscounts?.find(
      (d) => d.eligibleDiscountId === 'GroupOccupation'
    );
    return discount?.selectedOptionValue || null;
  }

  private static getYearOfBirth(dob: Nullable<string>): string {
    if (!dob) {
      return '';
    } else {
      if (dob.includes('*')) {
        if (dob.includes('/')) {
          return dob.substring(6, 10);
        } else {
          return dob.substring(0, 4);
        }
      }
      return dob.substring(0, 4);
    }
  }

  static hasNonDeletablePolicyRole(roles: MemberPolicyRole[]): boolean {
    return !!roles.find((role) => {
      if (role.entityType === 'policyHolder') {
        if (role.roleSequence === 'primary') {
          return true;
        }
        switch (role.productType) {
          // DSM APIs that lack add/delete policyholder.
          case 'Boat':
          case 'MSA':
          case 'RV':
            return true;
        }
      }
      return false;
    });
  }

  static buildEntityIds(person: MemberModel): EntityIds {
    const entityIds: EntityIds = {
      policyHolderIds: [],
      driverIds: [],
      householdMemberIds: [],
    };
    for (const role of person.policyRoles) {
      switch (role.entityType) {
        case 'policyHolder':
          entityIds.policyHolderIds.push(role.entityId);
          break;
        case 'driver':
          entityIds.driverIds.push(role.entityId);
          break;
        case 'householdMember':
          entityIds.householdMemberIds.push(role.entityId);
          break;
      }
    }
    return entityIds;
  }

  static personModelWithDefaults(
    input: MemberModel,
    quoteState: string
  ): MemberModel {
    const output: MemberModel = {
      ...input,
      person: {
        ...input.person,
      },
    };
    if (!input.licenseState) {
      output.licenseState = quoteState;
    }
    return output;
  }

  static createPeopleModels(
    people: MemberEntity[],
    quoteState: string
  ): MemberModel[] {
    return people.map((person) =>
      this.personModelWithDefaults(person, quoteState)
    );
  }

  static getPolicyHolderType(person: MemberEntity): PolicyHolderType {
    if (person.relationToPrimaryNamedInsured) {
      if (
        [
          MaritalStatusToDsmCodes.Married,
          MaritalStatusToDsmCodes.Separated,
        ].includes(String(person.person?.maritalStatus)) &&
        person.relationToPrimaryNamedInsured === DriverRelationToPNI.SPOUSE
      ) {
        return 'PolicySecNamedInsured';
      } else {
        return 'PolicyAddlNamedInsured';
      }
    } else {
      return 'PolicyAddlNamedInsured';
    }
  }

  static GetPersonName(person: MemberEntity): string {
    return `${person.person?.firstName || ''} ${person.person?.lastName || ''}`;
  }

  // Input can be 'MM/DD/YYYY' or '**/**/YYYY', we must handle both.
  static CalculateAgeFromDateOfBirth(input: string): number {
    if (!input) {
      return NaN;
    }
    const components = input.split('/');
    const countOfComponentsInDate = 3;
    if (components.length !== countOfComponentsInDate) {
      return NaN;
    }
    const year = parseInt(components[2], 10);
    if (isNaN(year)) {
      return NaN;
    }
    const month = parseInt(components[0], 10) || 1;
    const day = parseInt(components[1], 10) || 1;
    const now = DateUtils.getCurrentDate();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1;
    const currentDay = now.getDate();
    let age = currentYear - year;
    if (month > currentMonth) {
      age--;
    } else if (month === currentMonth && day > currentDay) {
      age--;
    }
    return age;
  }

  static makeActionsForMissingMemberRoles(
    members: MemberEntity[],
    products: ProductModel[]
  ): Action[] {
    const actions: Action[] = [];
    const adjustedMembers = PersonRoleSelectionUtils.addMissingPolicyRoles(
      members,
      products.map((p) => p.type)
    );
    for (let i = 0; i < members.length; i++) {
      const original = members[i];
      const adjusted = adjustedMembers[i];
      if (adjusted.policyRoles.length > original.policyRoles.length) {
        actions.push(
          MemberActions.storeMember({
            payload: adjusted,
          })
        );
      }
    }
    if (actions.length) {
      actions.push(MemberActions.sendMembersToBackend());
    }
    return actions;
  }

  static blankMemberEntity(): MemberEntity {
    return {
      entityId: '',
      policyRoles: [],
      driverRoles: [],
    };
  }

  static removeProductReferences(
    member: Nullable<MemberEntity>,
    productType: Nullable<ProductType>
  ): MemberEntity {
    if (!member || !productType) {
      return PersonUtils.blankMemberEntity();
    }
    const output = { ...member };

    if (output.policyRoles) {
      output.policyRoles = output.policyRoles.filter(
        (role) => role.productType !== productType
      );
    }

    if (output.eligibleDiscountIds) {
      output.eligibleDiscountIds = { ...output.eligibleDiscountIds };
      delete output.eligibleDiscountIds[productType];
    }

    if (output.infractionsByProduct) {
      output.infractionsByProduct = { ...output.infractionsByProduct };
      delete output.infractionsByProduct[productType];
    }

    return output;
  }

  static getEntityId(
    member: Nullable<BasePersonEntity>,
    entityType: DsmMemberEntityType,
    productType: ProductType
  ): number {
    return (
      member?.policyRoles?.find(
        (role) =>
          role.productType === productType && role.entityType === entityType
      )?.entityId || 0
    );
  }

  static driverIdFromEntity(
    entity: Nullable<MemberModel>,
    productType: ProductType
  ): number {
    return this.getEntityId(entity, 'driver', productType);
  }

  static policyHolderIdFromEntity(
    entity: Nullable<MemberModel>,
    productType: ProductType
  ): number {
    return this.getEntityId(entity, 'policyHolder', productType);
  }

  static policyHolderIdsFromEntity(entity: Nullable<MemberModel>): number[] {
    const ids =
      entity?.policyRoles
        ?.filter((r) => r.entityType === 'policyHolder')
        .map((r) => r.entityId) || [];
    return Array.from(new Set(ids));
  }

  static findMemberByEntityId<T extends BasePersonEntity>(
    members: T[],
    entityType: DsmMemberEntityType,
    productType: ProductType,
    entityId: number
  ): T | undefined {
    return members.find((m) =>
      m.policyRoles.find(
        (role) =>
          role.entityType === entityType &&
          role.productType === productType &&
          // eslint-disable-next-line eqeqeq
          role.entityId == entityId
      )
    );
  }

  static findMemberByEntityIdAnyProduct<T extends BasePersonEntity>(
    members: T[],
    entityType: DsmMemberEntityType,
    entityId: number
  ): T | undefined {
    return members.find((m) =>
      m.policyRoles.find(
        (role) =>
          role.entityType === entityType &&
          // eslint-disable-next-line eqeqeq
          role.entityId == entityId
      )
    );
  }

  static setDriverRoles(to: MemberEntity, from: MemberEntity): void {
    if (from.productType) {
      if (from.driverId) {
        to.driverRoles = PersonUtils.addDriverRole(to.driverRoles, from);
      }
    }
  }

  static addDriverRole(
    driverRoles: MemberDriverRole[],
    driver: MemberEntity
  ): MemberDriverRole[] {
    const driverType = driver?.driverType;
    const reasonNonDriver = driver?.reasonNonDriver;
    const productType = driver?.productType;
    const newRole = {
      driverType,
      reasonNonDriver,
      productType,
    } as MemberDriverRole;
    const replaceIndex = driverRoles.findIndex(
      (r) => r.productType === productType
    );
    const newRoles = [...driverRoles];
    if (replaceIndex >= 0) {
      newRoles[replaceIndex] = newRole;
    } else {
      newRoles.push(newRole);
    }
    return newRoles;
  }

  static addPolicyRolesFromSingleRoleMemberEntity(
    to: MemberEntity, // mutable
    from: MemberEntity // const
  ): void {
    if (from.productType) {
      if (from.policyHolderId) {
        to.policyRoles = PersonUtils.addPolicyRole(
          to.policyRoles,
          from.productType,
          'policyHolder',
          from.policyHolderId,
          this.policyRoleSequenceFromPolicyHolderType(
            from.policyHolderType || ''
          )
        );
      }
      if (from.driverId) {
        to.policyRoles = PersonUtils.addPolicyRole(
          to.policyRoles,
          from.productType,
          'driver',
          from.driverId
        );
      }
      if (from.householdMemberId) {
        to.policyRoles = PersonUtils.addPolicyRole(
          to.policyRoles,
          from.productType,
          'householdMember',
          from.householdMemberId
        );
      }
    }
  }

  static policyRoleSequenceFromPolicyHolderType(
    phType: string
  ): PolicyRoleSequence {
    switch (phType) {
      case 'PolicyPriNamedInsured':
        return 'primary';
      case 'PolicySecNamedInsured':
        return 'secondary';
      case 'PolicyAddlNamedInsured':
        return 'additional';
    }
    return '';
  }

  static policyHolderTypeFromPolicyRoleSequence(
    seq: PolicyRoleSequence
  ): PolicyHolderType | '' {
    switch (seq) {
      case 'primary':
        return 'PolicyPriNamedInsured';
      case 'secondary':
        return 'PolicySecNamedInsured';
      case 'additional':
        return 'PolicyAddlNamedInsured';
    }
    return '';
  }

  static addPolicyRole(
    policyRoles: MemberPolicyRole[],
    productType: ProductType,
    entityType: DsmMemberEntityType,
    entityId: number,
    roleSequence?: PolicyRoleSequence
  ): MemberPolicyRole[] {
    const newRole = { productType, entityType, entityId, roleSequence };
    const replaceIndex = policyRoles.findIndex(
      (r) =>
        r.productType === productType &&
        r.entityType === entityType &&
        // eslint-disable-next-line eqeqeq
        (r.entityId == entityId || !r.entityId)
    );
    const newRoles = [...policyRoles];
    if (replaceIndex >= 0) {
      newRoles[replaceIndex] = newRole;
    } else {
      newRoles.push(newRole);
    }
    return newRoles;
  }
}
