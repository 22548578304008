import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  AfterContentInit,
  AfterViewInit,
  AfterContentChecked,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

/**
 * Use like this.
 * <nwx-card>
 *   <h3 header>My Card Header</h3>
 *   <nwx-some-form></nwx-some-form>
 * </nwx-card>
 *
 * If you wish to have multiple things in the header and you don't want to add a superfluous div element, then do
 * <nwx-card>
 *   <ng-container [ngProjectAs]="[header]">
 *     <h3>My Card Header</h3>
 *     <p>My subheader</p>
 *   </ng-container>
 *   <nwx-some-form></nwx-some-form>
 * </nwx-card>
 *
 * To use as part of a list, see card-list.component.ts
 */
@Component({
  selector: 'nwx-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @Input() deletable = false;
  @Input() fullHeight: boolean = false;
  @Input() flexStyles: boolean = false;
  @Input() showHeader: boolean = true;
  @Input() noPadding: boolean = false;

  @Output()
  remove: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}
}
