import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'nwx-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardHeaderComponent {
  @Input() title!: string;
  @Input() titleId: string = ''; // For Tosca
  @Input() subtitle!: string;
  @Input() index!: number;
  @Input() total!: number;
  @Input() showSaved!: boolean;
  @Input() isSaved!: boolean;

  constructor() {}
}
