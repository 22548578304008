import {
  DisplayGroupingType,
  ScheduledCategoriesViewModel,
  ScheduledCategoryEntity,
  ScheduledCategoryItem,
} from '@core/store/entities/scheduled-categories/scheduled-category.entity';
import { CoreState } from '@core/store/reducers';
import * as fromSelectors from '@core/store/entities/scheduled-categories/scheduled-category.selector';
import * as fromActions from '@core/store/entities/scheduled-categories/scheduled-category.action';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Nullable } from '@shared/utils/type.utils';
import { BaseRequest } from '@core/models/api/request/base-request.model';
import { ProductType } from '@core/models/api/dsm-types';
@Injectable({
  providedIn: 'root',
})
export class ScheduledCategoriesService {
  constructor(private store: Store<CoreState>) {}

  dispatchLoadScheduledCategories(productTypes: ProductType[]): void {
    this.store.dispatch(
      fromActions.loadScheduledCategories({ payload: productTypes })
    );
  }

  dispatchLoadScheduledPersonalEffects(): void {
    this.store.dispatch(fromActions.loadScheduledPersonalEffects());
  }

  dispatchGetScheduledcategories(request: BaseRequest): void {
    this.store.dispatch(
      fromActions.getAllScheduledCategories({ payload: request })
    );
  }

  getAllScheduledCategoriesByProduct(
    productType: ProductType
  ): Observable<Nullable<ScheduledCategoryEntity[]>> {
    return this.store.select(
      fromSelectors.getAllScheduledCategoriesByProduct(productType)
    );
  }

  getScheduledCategoriesLoading(): Observable<boolean> {
    return this.store.select(fromSelectors.getScheduledCategoriesLoading);
  }

  getScheduledCategoriesLoaded(): Observable<boolean> {
    return this.store.select(fromSelectors.getScheduledCategoriesLoaded);
  }

  updateScheduledCategory(scheduledCategory: ScheduledCategoryEntity): void {
    this.store.dispatch(
      fromActions.updateScheduledCategory({ payload: scheduledCategory })
    );
  }

  getDisplayGroupLoading(): Observable<Nullable<DisplayGroupingType>> {
    return this.store.select(fromSelectors.getDisplayGroupLoading);
  }

  getScheduledCategoriesViewModel(
    productType: ProductType,
    grouping: DisplayGroupingType
  ): Observable<ScheduledCategoriesViewModel> {
    return this.store.select(
      fromSelectors.getScheduledCategoriesViewModel(productType, grouping)
    );
  }

  findCategoryByScheduledItemID(
    productType: ProductType,
    itemId: string
  ): Observable<Nullable<ScheduledCategoryEntity>> {
    return this.store.select(
      fromSelectors.findCategoryByScheduledItemID(productType, itemId)
    );
  }

  findCategoryByEntityId(
    productType: ProductType,

    entityId: string
  ): Observable<Nullable<ScheduledCategoryEntity>> {
    return this.store.select(
      fromSelectors.findCategoryByEntityId(productType, entityId)
    );
  }

  getAllScheduledItems(
    productType: ProductType
  ): Observable<ScheduledCategoryItem[]> {
    return this.store.select(fromSelectors.getAllScheduledItems(productType));
  }

  removeScheduledItem(
    payload: ScheduledCategoryItem,
    productType: ProductType
  ): void {
    this.store.dispatch(
      fromActions.removeScheduledPersonalEffectItem({ payload, productType })
    );
  }
  updateScheduledItem(
    payload: ScheduledCategoryItem,
    productType: ProductType
  ): void {
    this.store.dispatch(
      fromActions.updateScheduledPersonalEffectItem({ payload, productType })
    );
  }

  getScheduledPersonalEffectsCombinedValue(
    productType: ProductType
  ): Observable<number> {
    return this.store.select(
      fromSelectors.getScheduledPersonalEffectsCombinedValue(productType)
    );
  }
}
