import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../error/error.model';
import { WatercraftExposureEntity } from './watercraft-exposures.entity';

export const upsertWatercraftExposure = createAction(
  '[Watercraft Exposures] Upsert Watercraft Exposure',
  props<{ payload: WatercraftExposureEntity; correlationId?: string }>()
);

export const upsertWatercraftExposureSuccess = createAction(
  '[Watercraft Exposures] Upsert Watercraft Exposure Success',
  props<{ payload: WatercraftExposureEntity; correlationId?: string }>()
);

export const upsertWatercraftExposureFail = createAction(
  '[Watercraft Exposures] Upsert Watercraft Exposure Fail',
  props<{
    error: ErrorModel;
    correlationId?: string;
    entityId?: string;
  }>()
);

export const deleteWatercraftExposure = createAction(
  '[Watercraft Exposures] Delete Watercraft Exposure',
  props<{ payload: WatercraftExposureEntity; correlationId?: string }>()
);

export const deleteWatercraftExposureSuccess = createAction(
  '[Watercraft Exposures] Delete Watercraft Exposure Success',
  props<{
    watercraftExposureId: string;
    correlationId?: string;
  }>()
);

export const deleteWatercraftExposureFail = createAction(
  '[Watercraft Exposures] Delete Watercraft Exposure Fail',
  props<{
    error: ErrorModel;
    correlationId?: string;
    entityId?: string;
  }>()
);

export const completelyRemoveWatercraftExposure = createAction(
  '[Watercraft Exposures] Removed previously deleted exposure from deleted state',
  props<{
    watercraftExposureId: string;
    correlationId?: string;
  }>()
);
