import { HubPageComponent } from './hub/hub/hub-page.component';
import { NgModule, inject } from '@angular/core';
import {
  Routes,
  RouterModule,
  CanActivateChildFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationGuard } from '@core/guards/authentication.guard';
import { DocumentsGuard } from '@core/guards/documents.guard';
import { PaymentGuard } from '@core/guards/payment.guard';
import { HubPageGuard } from '@core/guards/hub.guard';
import { SplashComponent } from './splash/pages/splash/splash.component';
import { BillingDownPaymentGuard } from '@core/guards/billing-down-payment.guard';
import { QueryParameterPreservingRedirectComponent } from '@shared/components/query-parameter-preserving-redirect/query-parameter-preserving-redirect.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MetadataGuard } from '@core/guards/metadata.guard';
import { RefreshGuard } from '@shared/guards/refresh.guard';
import { RetrieveQuoteGuard } from '@core/guards/retrieve-quote.guard';
import { BillingAmfBankAccountsGuard } from '@core/guards/amf-bank-accounts.guard';

const authActivateFn: CanActivateChildFn = (childRoute, _state) =>
  inject(AuthenticationGuard).canActivateChild(childRoute);

const routes: Routes = [
  {
    path: '',
    canActivateChild: [authActivateFn],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'home' },
      {
        path: 'retrieve',
        canActivate: [
          () => inject(MetadataGuard).canActivate(),
          (route: ActivatedRouteSnapshot) =>
            inject(RetrieveQuoteGuard).canActivate(route),
        ],
        component: HubPageComponent,
      },
      {
        path: 'retrieve/compRater',
        component: QueryParameterPreservingRedirectComponent,
        data: {
          destination: '/search/compRater',
        },
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'quote',
        canActivate: [
          (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(RefreshGuard).canActivate(_route, state),
        ],
        loadChildren: () =>
          import('./quote/quote.module').then((m) => m.QuoteModule),
      },
      {
        path: 'hub',
        canActivate: [
          (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(RefreshGuard).canActivate(_route, state),
          () => inject(HubPageGuard).canActivate(),
        ],
        loadChildren: () => import('./hub/hub.module').then((m) => m.HubModule),
      },
      {
        path: 'billing',
        canActivate: [
          (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(RefreshGuard).canActivate(_route, state),
          () => inject(PaymentGuard).canActivate(),
          () => inject(BillingDownPaymentGuard).canActivate(),
          () => inject(BillingAmfBankAccountsGuard).canActivate(),
        ],
        loadChildren: () =>
          import('./billing-payment/billing-payment.module').then(
            (m) => m.BillingPaymentModule
          ),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./search/search.module').then((m) => m.SearchModule),
      },
      {
        path: 'summary',
        canActivate: [
          (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(RefreshGuard).canActivate(_route, state),
          () => inject(DocumentsGuard).canActivate(),
        ],
        loadChildren: () =>
          import('./summary/summary.module').then((m) => m.SummaryModule),
      },
    ],
  },
  {
    path: 'splash',
    component: SplashComponent,
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
