<div class="bolt-container-fluid standard-rounded-border">
  <div class="bolt-row bolt-justify-content-between bolt-align-items-center">
    <div class="bolt-col-auto">
      <ng-content select="[content]"></ng-content>
    </div>
    <div class="bolt-col-auto">
      <bolt-button
        size="sm"
        type="primary"
        [iconleft]="buttonIcon"
        (click)="onClick()"
        [attr.id]="buttonId"
      >
        {{ button }}
      </bolt-button>
    </div>
  </div>
</div>
