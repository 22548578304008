<form [formGroup]="form" (ngSubmit)="onSubmit(form)">
  <div class="bolt-space-bottom-lg" id="splash-agency-channel">
    <bolt-select
      ngDefaultControl
      formControlName="agencyChannel"
      label="Agent affiliation"
      name="agentSelection"
      [attr.id]="'sfc-agency-channel'"
      required
    >
      <option value="IA">Independent agent</option>
      <option value="NSS">Nationwide agent</option>
    </bolt-select>
  </div>
  <bolt-button
    submit
    type="primary"
    size="lg"
    name="loginButton"
    [attr.id]="'spc-login-btn'"
    >Log in</bolt-button
  >
  <div class="helper-text" *ngIf="form.get('agencyChannel')?.value === 'IA'">
    <a
      href="https://agentcenter.nationwide.com/WorkspaceAC/workspaceLogin/register"
      >Register for access</a
    >
  </div>
</form>
