<ng-container *ngIf="vm$ | async as vm">
  <div class="modal-header">
    <h4 class="modal-header-title" id="boatTrailerModalHeader">
      Trailer information
    </h4>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="form"
      [nwxExposeFormErrors]="form"
      [submitted]="submitted"
      [suppressNavigationObservation]="true"
      formName="boat-trailer"
    >
      <bolt-textfield
        formControlName="serialNumber"
        label="Serial number"
        id="trailerSerialNumber"
        instructionalText="Required for bindable rate"
        taskElementId="trailer-serial-number-{{ vehicle.vehicleId }}"
      >
      </bolt-textfield>
      <nwx-powersports-year-make-model-form
        [inputYear]="(vm.localVehicle?.year || '').toString()"
        [inputMake]="vm.localVehicle?.make || ''"
        [inputModel]="vm.localVehicle?.model || ''"
        inquiryPath="boat-trailers"
        [vehicleId]="vehicle.vehicleId || ''"
        [vehicleIndex]="vehicleIndex"
        [isMakeNotFound]="vehicle.trailer?.isMakeNotFound || false"
        [isModelNotFound]="vehicle.trailer?.isModelNotFound || false"
        [submitted]="submitted"
        (formReady)="addChildForm('year-make-model', $event)"
        (valueChange)="onValueChange($event)"
      ></nwx-powersports-year-make-model-form>
    </form>
  </div>
  <div class="modal-footer">
    <bolt-button-bar>
      <bolt-button
        slot="forward"
        type="primary"
        name="submit"
        [attr.id]="'trailerModalSubmitBtn'"
        (click)="onSubmit(vm.localVehicle || {})"
        >Save trailer information</bolt-button
      >
      <bolt-button
        slot="back"
        name="cancel"
        [attr.id]="'trailerModalCancelBtn'"
        (click)="onCancel()"
        >Cancel</bolt-button
      >
    </bolt-button-bar>
  </div>
</ng-container>
