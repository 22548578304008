import { TelematicsTextNotificationsModel } from './../telematics-text-updates/telematics-text-updates.model';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChildren,
  ElementRef,
  QueryList,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TelematicsEnrollmentResponse } from '@core/models/api/response/telematics-enrollment-response.model';
import { PolicyHolderEntity } from '@core/store/entities/policyholder/policyholder.entity';
import {
  MobileEnrollment,
  ExtendedTelematicsVehicle,
  VehicleEnrollment,
  EnrolledVehicle,
  SmartMilesVersion,
} from '@core/store/entities/telematics/telematics.model';
import { Nullable } from '@shared/utils/type.utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, map, Observable, Subject, takeUntil } from 'rxjs';
import { TelematicsUtils } from '@shared/utils/telematics.utils';
import { TelematicsService } from '@core/services/telematics.service';
import { MemberService } from '@core/services/member.service';
import { SessionService } from '@core/services/session.service';

@Component({
  selector: 'nwx-telematics-confirmation-modal',
  templateUrl: './telematics-confirmation-modal.component.html',
  styleUrls: ['./telematics-confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelematicsConfirmationModalComponent implements OnInit, OnDestroy {
  programTileVehicles: ExtendedTelematicsVehicle[] = [];
  isMobile = false;
  namedInsured$: Observable<Nullable<PolicyHolderEntity>>;
  currentEnrollment$: Observable<
    Nullable<
      MobileEnrollment | VehicleEnrollment | TelematicsEnrollmentResponse
    >
  >;
  isCA$: Observable<boolean>;

  attemptSubmit$: Subject<void> = new Subject<void>();
  @ViewChildren('confirmationCheckbox', { read: ElementRef })
  confirmationCheckboxes!: QueryList<ElementRef>;

  smartMilesVehicles: ExtendedTelematicsVehicle[] = [];
  smartRideVehicles: ExtendedTelematicsVehicle[] = [];
  smartRideInstantVehicles: ExtendedTelematicsVehicle[] = [];
  smartRideConnectedCarVehicles: ExtendedTelematicsVehicle[] = [];
  smartMilesConnectedCarVehicles: ExtendedTelematicsVehicle[] = [];
  selfReportedVehicles: ExtendedTelematicsVehicle[] = [];
  unenrolledVehicles: ExtendedTelematicsVehicle[] = [];
  btnDisabled: boolean = true;
  @Output() checkboxObs$ = new EventEmitter<boolean>();

  isMobileEnrollmentOnly = false;
  unsubscribe$ = new Subject<void>();

  srccFordVehicles: ExtendedTelematicsVehicle[] = [];
  srccToyotaVehicles: ExtendedTelematicsVehicle[] = [];

  smartMilesAgreements = '';
  smartMilesConnectedCarAgreement1 = '';

  constructor(
    public modal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef,
    private telematicsService: TelematicsService,
    private memberService: MemberService,
    private sessionService: SessionService
  ) {
    this.currentEnrollment$ = this.telematicsService.getCurrentEnrollment();
    combineLatest([
      this.telematicsService.buildProgramTileVehicles(),
      this.currentEnrollment$,
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        map(([programTileVehicles, enrollment]) => ({
          programTileVehicles,
          enrollment,
          isMobile: TelematicsUtils.isMobileEnrollment(enrollment),
        }))
      )
      .subscribe(({ programTileVehicles, enrollment, isMobile }) => {
        this.isMobile = isMobile;
        this.programTileVehicles = programTileVehicles;
        this.rebuildVehicleLists(programTileVehicles, enrollment);
        this.changeDetector.markForCheck();
      });
    this.namedInsured$ = this.memberService.getPrimaryNamedInsuredForProduct('PersonalAuto');
    this.isCA$ = this.sessionService
      .getQuoteState()
      .pipe(map((quoteState) => quoteState === 'CA'));
  }

  ngOnInit(): void {
    this.smartMilesAgreements = this.getSmartMilesAgreements();
    this.smartMilesConnectedCarAgreement1 =
      this.getSmartMilesConnectedCarAgreement1();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private rebuildVehicleLists(
    programTileVehicles: ExtendedTelematicsVehicle[],
    currentEnrollment: Nullable<
      MobileEnrollment | VehicleEnrollment | TelematicsEnrollmentResponse
    >
  ): void {
    this.clearAllocations();
    if (!TelematicsUtils.isVehicleEnrollment(currentEnrollment)) {
      this.isMobileEnrollmentOnly = true;
    }
    if (this.isMobile) {
      this.smartRideVehicles = programTileVehicles;
    } else {
      programTileVehicles?.forEach((vehicle) => {
        if (!this.isVehicleAllocated(vehicle)) {
          if (vehicle.enrollmentStatus === 'Enrolled') {
            if (
              vehicle.vehicleProgram === 'SmartMiles' &&
              vehicle.dataCollectionMethod !== 'ConnectedCar'
            ) {
              this.smartMilesVehicles.push(vehicle);
            }
            if (
              vehicle.vehicleProgram === 'SmartMiles' &&
              vehicle.dataCollectionMethod === 'ConnectedCar'
            ) {
              this.smartMilesConnectedCarVehicles.push(vehicle);
            }
            if (vehicle.vehicleProgram === 'SmartRide') {
              this.smartRideVehicles.push(vehicle);
            }
            if (vehicle.vehicleProgram === 'SelfReported') {
              this.selfReportedVehicles.push(vehicle);
            }
            if (vehicle.vehicleProgram === 'SmartRideInstant') {
              this.smartRideInstantVehicles.push(vehicle);
            }
            if (vehicle.vehicleProgram === 'ConnectedCar') {
              this.smartRideConnectedCarVehicles.push(vehicle);
              if (this.isToyota(vehicle.make)) {
                this.srccToyotaVehicles.push(vehicle);
              }
              if (this.isFord(vehicle.make)) {
                this.srccFordVehicles.push(vehicle);
              }
            }
          } else if (vehicle.enrollmentStatus === 'VerifiedScore') {
            this.smartRideInstantVehicles.push(vehicle);
          } else {
            this.unenrolledVehicles.push(vehicle);
          }
        }
      });
    }
  }

  checkedAgreementBox(): void {
    const allCheckBoxes = this.confirmationCheckboxes;
    const elements = allCheckBoxes.map((el) => el.nativeElement);
    if (elements.every((el) => el.checked === true)) {
      this.btnDisabled = false;
      this.checkboxObs$.emit(true);
    } else {
      this.btnDisabled = true;
    }
  }

  closeModal(): void {
    this.modal.close();
  }

  continue(): void {
    this.attemptSubmit$.next();
  }

  onModalSubmit(event: TelematicsTextNotificationsModel | string) {
    const allChecked = this.confirmationCheckboxes
      ?.map((el) => el.nativeElement)
      .every((el) => el.checked);
    if (allChecked) {
      const result = {
        textNotificationEvent: {},
        connectedCarConsent: false,
      };
      if (typeof event !== 'string') {
        result.textNotificationEvent = {
          ...event,
          textNotification: event.textNotification?.toString() === 'true',
        };
      }
      if (
        this.programTileVehicles.some(
          (v) =>
            v.enrollmentStatus === 'Enrolled' &&
            (v.vehicleProgram === 'ConnectedCar' ||
              v.dataCollectionMethod === 'ConnectedCar')
        )
      ) {
        result.connectedCarConsent = true;
      }
      this.modal.close(result);
    } else {
      this.confirmationCheckboxes
        ?.map((el) => el.nativeElement)
        ?.forEach((el) => {
          el.setAttribute('invalid', '');
        });
    }
  }

  isToyota(make?: string): boolean {
    return make === 'TOYO';
  }

  isFord(make?: string): boolean {
    return make === 'FORD' || make === 'LINC';
  }

  private isVehicleAllocated(vehicle: ExtendedTelematicsVehicle): boolean {
    const allocatedVehicles = [
      ...this.smartMilesVehicles,
      ...this.smartRideVehicles,
      ...this.smartRideInstantVehicles,
      ...this.smartRideConnectedCarVehicles,
      ...this.smartMilesConnectedCarVehicles,
      ...this.unenrolledVehicles,
    ];
    return allocatedVehicles.includes(vehicle);
  }

  private clearAllocations(): void {
    this.smartMilesVehicles = [];
    this.smartRideVehicles = [];
    this.smartRideInstantVehicles = [];
    this.smartRideConnectedCarVehicles = [];
    this.smartMilesConnectedCarVehicles = [];
    this.unenrolledVehicles = [];
    this.srccFordVehicles = [];
    this.srccToyotaVehicles = [];
  }

  get smartMilesVersion(): SmartMilesVersion | null {
    if (this.smartMilesVehicles.length && !this.isMobile) {
      return (this.smartMilesVehicles[0] as EnrolledVehicle).version || '1.0';
    } else {
      return null;
    }
  }

  get smartMilesConnectedCarVersion(): SmartMilesVersion | null {
    if (this.smartMilesConnectedCarVehicles.length) {
      return (
        (this.smartMilesConnectedCarVehicles[0] as EnrolledVehicle).version ||
        '1.0'
      );
    } else {
      return null;
    }
  }

  getSmartMilesAgreements(): string {
    switch (this.smartMilesVersion) {
      case '1.0':
        return `
        - Customer agrees to plug in a device into the vehicle to measure mileage<br />
        - Customer acknowledges that premium will vary monthly based on actual miles driven<br />
        - Customer agrees to receive a one-time text and ongoing program emails`;
      case '2.0':
        return `
        - Customer agrees to plug in a device into the vehicle to measure mileage and driving trends<br/>
        - Customer acknowledges that premium will vary monthly based on mileage and driving trends<br/>
        - Customer agrees to receive a one-time text and ongoing program emails`;
      default:
        return '';
    }
  }

  getSmartMilesConnectedCarAgreement1(): string {
    switch (this.smartMilesConnectedCarVersion) {
      case '1.0':
        return 'Customer acknowledges premium will vary monthly based on actual miles driven';
      case '2.0':
        return 'Customer acknowledges premium will vary monthly based on actual miles driven and driving trends';
      default:
        return '';
    }
  }
}
