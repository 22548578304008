import { Injectable } from '@angular/core';
import * as fromActions from '@core/store/entities/metadata/metadata.action';
import { Store } from '@ngrx/store';
import * as fromSelector from '@core/store/entities/metadata/metadata.selector';
import { Observable } from 'rxjs';
import { Nullable } from '@shared/utils/type.utils';
import { PropertyOptionsModel } from '../store/entities/metadata/models/property-options.model';
import { VersionInformation } from '../store/entities/metadata/metadata.reducer';
import { StateSpecificFlagsModel } from '@assets/metadata/stateSpecificFlags';
import { FeatureFlagId } from '../store/entities/feature-flag/feature-flag.model';
import { map } from 'rxjs/operators';
import { UmbrellaOptionsModel } from '@entities/metadata/models/umbrella-options.model';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  constructor(private store: Store) {}

  dispatchLoadPropertyOptions(): void {
    this.store.dispatch(fromActions.loadPropertyOptions());
  }

  dispatchLoadUmbrellaOptions(): void {
    this.store.dispatch(fromActions.loadUmbrellaOptions());
  }

  dispatchLoadStateSpecificFlags(): void {
    this.store.dispatch(fromActions.loadStateSpecificFlags());
  }

  dispatchLoadFeatureFlags(): void {
    this.store.dispatch(fromActions.loadStateSpecificFlags());
  }

  dispatchLoadStates(): void {
    this.store.dispatch(fromActions.loadStates());
  }

  getFeatureFlagsLoaded(): Observable<boolean> {
    return this.store.select(fromSelector.getStateSpecificFlagsLoaded);
  }

  getStateSpecificFlagsData(): Observable<StateSpecificFlagsModel> {
    return this.store.select(fromSelector.getStateSpecificFlagsData);
  }

  getStateSpecificFlag(name: FeatureFlagId): Observable<boolean | undefined> {
    return this.store.select(fromSelector.getStateSpecificFlag(name));
  }

  getStates(): Observable<string[]> {
    return this.store.select(fromSelector.getStatesList);
  }

  getStatesLoaded(): Observable<boolean> {
    return this.store.select(fromSelector.getStatesLoaded);
  }

  getPropertyOptions(): Observable<Nullable<PropertyOptionsModel>> {
    return this.store.select(fromSelector.getPropertyMetadata);
  }

  getUmbrellaOptions(): Observable<Nullable<UmbrellaOptionsModel>> {
    return this.store.select(fromSelector.getUmbrellaOptions);
  }

  getVersionInformation(): Observable<VersionInformation> {
    return this.store.select(fromSelector.getVersionInformation);
  }

  getRestrictAllProductsStates(): Observable<string[]> {
    return this.getStateSpecificFlagsData().pipe(
      map((stateSpecificFlags) => stateSpecificFlags.restrictAllProducts.states)
    );
  }
}
