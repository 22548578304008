import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'nwx-message-bubble',
  templateUrl: './message-bubble.component.html',
  styleUrls: ['./message-bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageBubbleComponent {
  @Input() iconName!: string;
  @Input() iconColor!: string;
  @Input() borderless: boolean = false;
  @Input() iconSize: string = 'md';
  constructor() {}
}
