import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as protectiveDevicesReducer from '../protective-devices/protective-devices.reducer';
import { ProtectiveDeviceEntity } from './protective-devices.reducer';
import { ProductType } from '@core/models/api/dsm-types';
import { getAllFeatureFlags } from '../feature-flag/feature-flag.selector';
import { ProtectiveDevicesOptionsModel } from '../metadata/models/property-options.model';
import { GeneralUtils } from '@shared/utils/general.utils';
import { getProduct } from '@core/store/entities/product/product.selectors';
import { BaseRequest } from '@core/models/api/request/base-request.model';

export const getProtectiveDevicesState = createSelector(
  fromCore.getCoreState,
  (state: fromCore.CoreState) => state.protectiveDevices
);

export const {
  selectAll: getAllProtectiveDevices,
  selectEntities: getAllProtectiveDevicesEntities,
  selectTotal: getTotalProtectiveDevices,
} = protectiveDevicesReducer.adapter.getSelectors(getProtectiveDevicesState);

export const getProtectiveDevicesForProduct = (productType: ProductType) =>
  createSelector(
    getAllProtectiveDevicesEntities,
    (entities) =>
      entities[productType]?.devices || ([] as ProtectiveDeviceEntity[])
  );

export const getProtectiveDevicesLoaded = (productType: ProductType) =>
  createSelector(
    getProtectiveDevicesForProduct(productType),
    (protectiveDevices) => !GeneralUtils.isEmpty(protectiveDevices)
  );

export const getProtectiveDevicesRequest = (productType: ProductType) =>
  createSelector(getProduct(productType), (product) => {
    return {
      quoteId: product?.quoteId,
      productType,
    } as BaseRequest;
  });

export const getProtectiveDeviceModels = (lineOfBusiness: ProductType) =>
  createSelector(
    getProtectiveDevicesForProduct(lineOfBusiness),
    getAllFeatureFlags,
    (protectiveDevices, featureFlags) => {
      let returnProtectiveDevices: ProtectiveDeviceEntity[] =
        [...protectiveDevices] || [];
      return returnProtectiveDevices.sort();
    }
  );

export const getCategorizedProtectiveDevices = (lineOfBusiness: ProductType) =>
  createSelector(
    getProtectiveDeviceModels(lineOfBusiness),
    (protectiveDevices) => {
      const output = protectiveDevices.reduce(
        (group, protectiveDevice) => {
          const device = { ...protectiveDevice };

          if (
            device.type === 'Wind Mitigation' ||
            device.type === 'Wind Protective Device'
          ) {
            group.windMitigation.splice(0, 0, device);
          } else if (
            device.category === 'Wind Mitigation' ||
            device.category === 'Wind Protective Device'
          ) {
            group.windMitigation.push(device);
          } else {
            group.devices.push(device);
          }

          return group;
        },
        { devices: [], windMitigation: [] } as ProtectiveDevicesOptionsModel
      );
      return output;
    }
  );

export const getBasicProtectiveDevices = (quoteState: string) =>
  createSelector(() => {
    switch (quoteState) {
      case 'NC':
        return [
          {
            type: 'Sprinkler System',
            selectedValues: ['FullyAutomatic_Ext', 'Partial_Ext'],
            category: 'Sprinkler System',
          },
          {
            type: 'Burglar Alarm',
            selectedValues: [
              'LocalAlarm_Ext',
              'CentralAlarm_Ext',
              'DirectAlarm_Ext',
            ],
            category: 'Burglar Alarm',
          },
          {
            type: 'Fire/Smoke Alarm',
            selectedValues: ['LocalAlarm', 'CentralAlarm', 'DirectAlarm'],
            category: 'Fire/Smoke Alarm',
          },
        ];
      case 'NM':
        return [
          {
            type: 'Sprinkler System',
            selectedValues: ['FullyAutomatic_Ext', 'Partial_Ext'],
            category: 'Sprinkler System',
          },
          {
            type: 'Burglar Alarm',
            selectedValues: ['LocalAlarm_Ext', 'CentralBurglarAlarm_Ext'],
            category: 'Burglar Alarm',
          },
          {
            type: 'Fire/Smoke Alarm',
            selectedValues: ['LocalAlarm', 'CentralFireAlarm_Ext'],
            category: 'Fire/Smoke Alarm',
          },
          {
            value: 'false',
            type: 'Wrought Iron Bars',
            category: 'Wrought Iron Bars',
          },
        ];
      default:
        return [
          {
            type: 'Sprinkler System',
            selectedValues: ['FullyAutomatic_Ext', 'Partial_Ext'],
            category: 'Sprinkler System',
          },
          {
            type: 'Burglar Alarm',
            selectedValues: ['LocalAlarm_Ext', 'CentralBurglarAlarm_Ext'],
            category: 'Burglar Alarm',
          },
          {
            type: 'Fire/Smoke Alarm',
            selectedValues: ['LocalAlarm', 'CentralFireAlarm_Ext'],
            category: 'Fire/Smoke Alarm',
          },
        ];
    }
  });

export const getAdvancedProtectiveDevices = (
  quoteState: string,
  productType: ProductType
) =>
  createSelector(() => {
    if (productType === 'Homeowner') {
      switch (quoteState) {
        case 'CT':
        case 'NY':
          return [
            {
              type: 'Wind Protective Device',
              selectedValues: [
                'Hurricane Resistant Permanent Storm Shutters',
                'Hurricane Resistant Laminated Glass',
                'Hurricane Resistant Permanent Storm Shutters and Hurricane Resistant Glass',
              ],
              category: 'Wind Protective Device',
            },
          ];
        case 'FL':
          return [
            {
              value: 'false',
              type: 'Wind Mitigation',
              category: 'Wind Mitigation',
            },
            {
              value: 'Unknown',
              type: 'RoofCovering_Ext',
              selectedValues: [
                'Meets Florida building code',
                'Does not meet Florida building code',
                'Unknown',
              ],
              category: 'Wind Mitigation',
            },
            {
              value: 'Other',
              type: 'RoofGeometry_Ext',
              selectedValues: ['No Value', 'Hip Roof', 'Other'],
              category: 'Wind Mitigation',
            },
            {
              value: 'Unknown',
              type: 'RoofDeckAttachment_Ext',
              selectedValues: [
                'Level A',
                'Level B',
                'Level C',
                'Level D',
                'Reinforced Concrete',
                'Unknown',
              ],
              category: 'Wind Mitigation',
            },
            {
              value: 'Unknown',
              type: 'RoofToWallConnection_Ext',
              selectedValues: [
                'Toe Nail',
                'Clips',
                'Single Wraps',
                'Double Wraps',
                'Structural',
                'Unknown',
              ],
              category: 'Wind Mitigation',
            },
            {
              value: 'None',
              type: 'OpeningProtection_Ext',
              selectedValues: [
                'No Value',
                'None',
                'Basic',
                'Hurricane',
                'Wood Panels',
                'Not Rated',
              ],
              category: 'Wind Mitigation',
            },
            {
              value: 'false',
              type: 'HasSecondaryWaterResist_Ext',
              category: 'Wind Mitigation',
            },
          ];
        case 'MD':
          return [
            {
              type: 'Wind Protective Device',
              selectedValues: [
                'Engineered Shutters (with any glass type)',
                'Non-Engineered Shutters (with any glass type)',
              ],
              category: 'Wind Protective Device',
            },
          ];
        case 'NC':
          return [
            {
              value: 'false',
              type: 'Wind Mitigation',
              category: 'Wind Mitigation',
            },
            {
              type: 'RoofGeometry_Ext',
              selectedValues: ['None', 'Hip Roof'],
              category: 'Wind Mitigation',
            },
            {
              type: 'OpeningProtection_Ext',
              selectedValues: ['None', 'Opening Protection applies'],
              category: 'Wind Mitigation',
            },
          ];
        case 'SC':
          return [
            {
              value: 'false',
              type: 'Wind Mitigation',
              category: 'Wind Mitigation',
            },
            {
              type: 'RoofCovering_Ext',
              selectedValues: [
                'Asphalt or Wood Shingles attached with Screws or Clay/Concrete Tiles, Slate, Build-up Roof with Gravel, Built-up Roof without Gravel Standing, Seam Metal, Single Ply Membrane or Single Ply Membrane Ballasted attached with Nails/Staples, Mortar, Screw or Adhesive/Epoxy',
                'Asphalt or Wood Shingles attached with Nails/Staples, Mortar or Adhesive/Epoxy',
                'Light Metal Panels attached with Nails/Staples, Mortar, Screws or Adhesive/Epoxy or Unknown',
              ],
              category: 'Wind Mitigation',
            },
            {
              type: 'RoofGeometry_Ext',
              selectedValues: [
                'Gable End without Bracing, Complex, Flat, Shed or Unknown',
                'Gable End with Bracing, Stepped, or Gambrel',
                'Hip, Mansard or Pyramid',
              ],
              category: 'Wind Mitigation',
            },
            {
              type: 'RoofToWallConnection_Ext',
              selectedValues: [
                'Anchor Bolts, Hurricane Ties, or Structurally Connected',
                'Nails or Screws',
                'Gravity/Friction or Adhesive/Epoxy or Unknown',
              ],
              category: 'Wind Mitigation',
            },
            {
              type: 'BldgFoundationConnection_Ext',
              selectedValues: [
                'Hurricane Ties, Anchor Bolts or Structurally Connected, Nails/Screws',
                'Nails/Screws',
                'Gravity/Friction or Adhesive/Epoxy or Unknown',
              ],
              category: 'Wind Mitigation',
            },
            {
              type: 'WindowProtection_Ext',
              selectedValues: [
                'Unknown',
                'Engineered Shutters (with any glass type)',
                'Non-Engineered Shutters (with any glass type)',
              ],
              category: 'Wind Mitigation',
            },
            {
              type: 'DoorType_Ext',
              selectedValues: [
                'Double-width, Sliding or Unknown',
                'Reinforced Single-width Doors',
                'Single-width, Reinforced Double-width or Reinforced Sliding Doors',
              ],
              category: 'Wind Mitigation',
            },
            {
              type: 'HasSecondaryWaterResist_Ext',
              category: 'Wind Mitigation',
            },
          ];
        default:
          return [];
      }
    } else {
      return [];
    }
  });
