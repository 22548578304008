import {
  AdditionalInsuredType,
  AddlInterestThirdPartyType,
  MortgageThirdPartyType,
  ProductType,
} from '@core/models/api/dsm-types';
import {
  QuoteRetrieveCondominiumResponse,
  QuoteRetrieveHomeownerResponse,
} from '@core/models/api/response/retrieve-response.model';
import { BaseEntity } from '@core/models/entities/base.entity';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { BasicAddressEntity } from '../address/address.entity';
import {
  MortgageActions,
  RetrieveActions,
  SessionActions,
} from '../../actions';
import { PersonEntity } from '@core/models/entities/person.entity';
import { MortgageBuilder } from '@shared/utils/builders/mortgage/mortgage.builder';

export interface MortgageEntity extends BaseEntity {
  address: BasicAddressEntity;
  companyId: string;
  companyName?: string; // Mortgagee_Ext
  company?: string; // Additional interest owned by a company
  escrow: boolean;
  loanNumber: string;
  mortgageId: string;
  mortgageType: AddlInterestThirdPartyType;
  type?: MortgageThirdPartyType; // Subtype of mortgageType when mortgageType is Mortgagee or Loss Payee; Mortgagee1 denotes primary mortgagee and LossPayee1 denotes primary loss payee
  person?: PersonEntity;
  additionalInsuredType?: AdditionalInsuredType; // Subtype of mortgageType when mortgageType is Additional Insured
}

export interface MortgageState extends EntityState<MortgageEntity> {}

export const adapter: EntityAdapter<MortgageEntity> =
  createEntityAdapter<MortgageEntity>({
    selectId: (mortgage) => mortgage.mortgageId,
  });

const mortgageReducer = createReducer<MortgageState>(
  adapter.getInitialState({}) as MortgageState,
  on(
    SessionActions.clearSessionState,
    () => adapter.getInitialState({}) as MortgageState
  ),
  on(MortgageActions.addMortgageSuccess, (state, { payload }) =>
    adapter.upsertMany(payload, { ...state })
  ),
  on(MortgageActions.updateMortgageSuccess, (state, { payload }) =>
    adapter.updateOne(payload, { ...state })
  ),
  on(MortgageActions.deleteMortgageSuccess, (state, { mortgageId }) =>
    adapter.removeOne(mortgageId, { ...state })
  ),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    const mortgages = (
      payload?.response as
        | QuoteRetrieveHomeownerResponse
        | QuoteRetrieveCondominiumResponse
    )?.mortgages;
    if (mortgages) {
      return adapter.upsertMany(
        getMortgagesFromRetrieve(mortgages, payload.productType),
        state
      );
    }
    return state;
  })
);

export function reducer(
  state: MortgageState | undefined,
  action: Action
): MortgageState {
  return mortgageReducer(state, action);
}

function getMortgagesFromRetrieve(
  mortgages: MortgageEntity[],
  productType: ProductType
): MortgageEntity[] {
  return mortgages.map((mortgage) => ({
    ...MortgageBuilder.addStreetToMortgageAddress(mortgage),
    productType,
    companyName: mortgage.companyName || '',
    escrow: false,
  }));
}
