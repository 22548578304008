import { QuoteRetrieveResponse } from '@core/models/api/response/retrieve-response.model';
import * as fromCore from '@core/store/reducers';
import { createSelector } from '@ngrx/store';
import { getAllProducts } from '../entities/product/product.selectors';
import { getAgency } from '@entities/agency/agency.selector';
import { ProductType } from '@core/models/api/dsm-types';

export const getRetrieveState = createSelector(
  fromCore.getCoreState,
  (state) => state.retrieve
);

export const getRetrievePendingCallCount = createSelector(
  getRetrieveState,
  (state) => state.pendingCallCount
);

export interface DummyProducerProduct {
  imageUrl: string;
  name: string;
  quoteId: string;
  producerCode: string;
  accountId: string;
  agencyCode: string;
  quoteState: string;
  productType: ProductType;
}

/**
 * Null if all retrieved quotes have a valid producerCode, or if no quotes present.
 * Otherwise array of DummyProducerProduct for *all* retrieved quotes.
 */
export const getDummyProducerContent = createSelector(
  getRetrieveState,
  getAllProducts,
  getAgency,
  (state, products, agency) => {
    if (state.ids.length < 1) {
      return null;
    }
    const output: DummyProducerProduct[] = (
      Object.values(state.entities) as QuoteRetrieveResponse[]
    ).map(({ productType, response }) => {
      const product = products.find((p) => p.type === productType);
      return {
        imageUrl: product?.imageUrl || '',
        name: product?.name || '',
        quoteId: response?.quoteId || '',
        producerCode: product?.producer?.producerCode || response?.producer?.producerCode || '',
        accountId: response?.accountId || '',
        agencyCode: agency.agent.agencyCode || '',
        quoteState: response?.policyAddress?.state || '',
        productType: productType || '',
      };
    });
    if (output.find(({ producerCode }) => producerCode.endsWith('- 000'))) {
      return output;
    }
    return null;
  }
);
