import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  amfBankAccountsNotNeeded,
  loadAmfBankAccounts,
  loadAmfBankAccountsFail,
  loadAmfBankAccountsSuccess,
} from './amf-bank-accounts.action';
import { Injectable } from '@angular/core';
import { AgencyService } from '@core/services/agency.service';
import { UserService } from '@core/services/user.service';
import { catchError, combineLatest, mergeMap, of, switchMap, take } from 'rxjs';
import { BillingService } from '@core/services/billing.service';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';

@Injectable()
export class AmfBankAccountsEffects {
  constructor(
    private actions$: Actions,
    private billingService: BillingService,
    private errorSanitizerService: ErrorSanitizerService,
    private agencyService: AgencyService,
    private userService: UserService
  ) {}

  loadAmfBankAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAmfBankAccounts),
      switchMap(() =>
        combineLatest([
          this.userService.getUser(),
          this.agencyService.getAgencyCode(),
        ]).pipe(take(1))
      ),
      mergeMap(([user, agencyCode]) => {
        if (user?.role === 'NSS') {
          return of(amfBankAccountsNotNeeded());
        } else {
          return this.billingService.getAmfBankAccounts(agencyCode).pipe(
            switchMap((response) =>
              of(loadAmfBankAccountsSuccess({ payload: response }))
            ),
            catchError((error) => {
              return of(
                loadAmfBankAccountsFail({
                  error: this.errorSanitizerService.sanitizeError(error),
                })
              );
            })
          );
        }
      })
    )
  );
}
