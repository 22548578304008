<div class="modal-header bolt-typography">
  <h4 class="modal-header-title" id="boatTrailerModalHeader">
    Policy eligibility &amp; property risks
  </h4>
</div>
<div class="modal-body bolt-typography">
  <nwx-eligibility-form
    [model]="model$ | async"
    [highlightAcknowledgementRequired]="highlightAcknowledgementRequired"
    [productType]="productType"
    [submitted]="submitted"
    [error]="submitError"
    (valueChange)="onValueChange($event)"
    (statusChange)="onStatusChange($event)"
    (dismissModal)="onDismiss()"
  ></nwx-eligibility-form>
</div>
<div class="modal-footer bolt-typography">
  <bolt-button-bar>
    <bolt-button
      slot="forward"
      type="primary"
      name="submit"
      [attr.id]="'eligibility-modal-submit'"
      (click)="onSubmit()"
      >Save</bolt-button
    >
  </bolt-button-bar>
</div>
