import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { AuthUrlService } from '@core/services/auth-url.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { SplashFormViewModel } from '@app/splash/components/splash-form/splash-form.model';

@Component({
  selector: 'nwx-splash-container',
  templateUrl: './splash-container.component.html',
  styleUrls: ['./splash-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplashContainerComponent implements OnInit {
  constructor(
    private authUrlService: AuthUrlService,
    private route: ActivatedRoute,
    private window: Window
  ) {}

  ngOnInit(): void {}

  onSubmit(event: SplashFormViewModel): void {
    const agencyChannel: string = event.agencyChannel;
    if (this.window && this.window.localStorage) {
      this.window.localStorage.setItem('agencyChannel', agencyChannel);
    }
    this.authUrlService.performAuthRedirect(
      agencyChannel,
      this.route.snapshot,
      null
    );
  }
}
