import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../error/error.model';
import { LocationExcludedExposureEntity } from './location-excluded-exposures.entity';

export const upsertLocationExcludedExposure = createAction(
  '[Location Excluded Exposures] Upsert Location Excluded Exposure',
  props<{ payload: LocationExcludedExposureEntity; correlationId?: string }>()
);

export const upsertLocationExcludedExposureSuccess = createAction(
  '[Location Excluded Exposures] Upsert Location Excluded Exposure Success',
  props<{ payload: LocationExcludedExposureEntity; correlationId?: string }>()
);

export const upsertLocationExcludedExposureFail = createAction(
  '[Location Excluded Exposures] Upsert Location Excluded Exposure Fail',
  props<{
    error: ErrorModel;
    correlationId?: string;
    entityId?: string;
  }>()
);

export const deleteLocationExcludedExposure = createAction(
  '[Location Excluded Exposures] Delete Location Excluded Exposure',
  props<{ payload: LocationExcludedExposureEntity; correlationId?: string }>()
);

export const deleteLocationExcludedExposureSuccess = createAction(
  '[Location Excluded Exposures] Delete Location Excluded Exposure Success',
  props<{
    locationExcludedExposureId: string;
    correlationId?: string;
  }>()
);

export const deleteLocationExcludedExposureFail = createAction(
  '[Location Excluded Exposures] Delete Location Excluded Exposure Fail',
  props<{
    error: ErrorModel;
    correlationId?: string;
    entityId?: string;
  }>()
);

export const completelyRemoveLocationExcludedExposure = createAction(
  '[Location Excluded Exposures] Completely Remove Location Excluded Exposure',
  props<{ locationExcludedExposureId: string }>()
);
