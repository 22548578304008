<div class="bolt-container-fluid">
  <div class="bolt-row bolt-align-items-center">
    <div class="bolt-col-12">
      <p>
        Insurance for low-mileage vehicles:
        <br />
        <span [innerHTML]="valueProposition"></span>
      </p>
    </div>
    <div class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4">
      <div class="image-section">
        <div class="image-circle">
          <img
            class="tm-icon"
            src="assets/images/telematics/smartmiles-device.svg"
            alt=""
          />
        </div>
        <p class="info">
          Plug a device into the vehicle.<bolt-contextual-help>
            <p>
              <strong>How does this work?</strong><br />
              {{ helpContent1 }}
            </p>
          </bolt-contextual-help>
        </p>
      </div>
    </div>
    <div class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4">
      <div class="image-section">
        <div class="image-circle">
          <img
            class="tm-icon"
            src="assets/images/telematics/smartmiles-chart.svg"
            alt=""
          />
        </div>
        <p class="info">
          {{ helpTitle2 }}
          <bolt-contextual-help>
            <p>
              <strong>How does this work?</strong><br />
              {{ helpContent2 }}
            </p>
          </bolt-contextual-help>
        </p>
      </div>
    </div>
    <div
      class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4 video"
      *ngIf="!isCaliforniaSmartMiles()"
    >
      <ng-container *ngIf="version === '1.0'; else version2Video">
        <iframe
          id="kaltura_player_sm_notca"
          src="https://fast.wistia.net/embed/iframe/0gr3h00f07"
          width="225"
          height="130"
          allowfullscreen
          webkitallowfullscreen
          mozAllowFullScreen
          allow="autoplay *; fullscreen *; encrypted-media *"
          sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
          frameborder="0"
          title="Kaltura Player"
        ></iframe>
      </ng-container>
      <ng-template #version2Video>
        <iframe
          id="kaltura_player_sm_notca"
          src="https://fast.wistia.net/embed/iframe/sz3evovhsk"
          width="225"
          height="130"
          allowfullscreen
          webkitallowfullscreen
          mozAllowFullScreen
          allow="autoplay *; fullscreen *; encrypted-media *"
          sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
          frameborder="0"
          title="Kaltura Player"
        ></iframe>
      </ng-template>
    </div>
    <div
      class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4 video"
      *ngIf="isCaliforniaSmartMiles()"
    >
      <iframe
        id="kaltura_player_sm_ca"
        src="https://fast.wistia.net/embed/iframe/qhct0inzsu"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        frameborder="0"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div *ngFor="let vehicle of vehicles; let i = index">
    <nwx-telematics-vehicle-premium
      [vehicle]="vehicle"
      [vehicleIndex]="i"
      [currentProgram]="'SmartMiles'"
      [enrollment]="enrollment"
      [maxSizeMini]="maxSizeMini"
    ></nwx-telematics-vehicle-premium>
  </div>
</div>
