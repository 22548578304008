import { createAction, props } from '@ngrx/store';
import { TelematicsEnrollmentResponse } from '@core/models/api/response/telematics-enrollment-response.model';
import { TelematicsRecommendationResponse } from '@core/models/api/response/telematics-recommendation-response.model';
import { ErrorModel } from '../error/error.model';
import { TelematicsTextNotificationsModel } from '@app/telematics/components/telematics-text-updates/telematics-text-updates.model';
import { VehicleEntity } from '../vehicle/vehicle.entity';
import {
  ExtendedTelematicsVehicle,
  MobileEnrollment,
  VehicleEnrollment,
} from './telematics.model';
import { Nullable } from '@shared/utils/type.utils';

export const enum TelematicsEnrollmentsActionTypes {
  GET_TELEMATICS_RECOMMENDATION = '[Telematics] Get Telematics Recommendation',
  GET_TELEMATICS_RECOMMENDATION_SUCCESS = '[Telematics] Get Telematics Recommendation Success',
  GET_TELEMATICS_RECOMMENDATION_FAIL = '[Telematics] Get Telematics Recommendation Fail',
  ADD_TELEMATICS_ENROLLMENT = '[Telematics] Add Telematics Enrollment',
  ADD_TELEMATICS_ENROLLMENT_SUCCESS = '[Telematics] Add Telematics Enrollment Success',
  ADD_TELEMATICS_ENROLLMENT_FAIL = '[Telematics] Add Telematics Enrollment Fail',
  UPDATE_TELEMATICS_ENROLLMENT = '[Telematics] Update Telematics Enrollment',
  UPDATE_TELEMATICS_TEXT_NOTIFICATION = '[Telematics] Update Telematics Text Notification',
  UPDATE_TELEMATICS_TEXT_NOTIFICATION_SUCCESS = '[Telematics] Update Telematics Text Notification Success',
  UPDATE_TELEMATICS_ENROLLMENT_SUCCESS = '[Telematics] Update Telematics Enrollment Success',
  UPDATE_TELEMATICS_ENROLLMENT_FAIL = '[Telematics] Update Telematics Enrollment Fail',
  REMOVE_TELEMATICS_ENROLLMENT = '[Telematics] Remove Telematics Enrollment',
  REMOVE_TELEMATICS_ENROLLMENT_SUCCESS = '[Telematics] Remove Telematics Enrollment Success',
  REMOVE_TELEMATICS_ENROLLMENT_FAIL = '[Telematics] Remove Telematics Enrollment Fail',
  GET_TELEMATICS_ENROLLMENT = '[Telematics] Get Telematics Enrollment',
  GET_TELEMATICS_ENROLLMENT_SUCCESS = '[Telematics] Get Telematics Enrollment Success',
  GET_TELEMATICS_ENROLLMENT_FAIL = '[Telematics] Get Telematics Enrollment Fail',
  REFRESH_TELEMATICS_ENROLLMENT = '[Telematics] Refresh Telematics Enrollment',
  REFRESH_TELEMATICS_ENROLLMENT_SUCCESS = '[Telematics] Refresh Telematics Enrollment Success',
  REFRESH_TELEMATICS_ENROLLMENT_FAIL = '[Telematics] Refresh Telematics Enrollment Fail',
  UNSET_TELEMATICS_ENROLLMENT_LOADED = '[Telematics] Unset Telematics Enrollment loaded',
  RESET_TELEMATICS_ENROLLMENT = '[Telematics] Reset Telematics Enrollment',
  REMOVE_VEHICLE_FROM_TELEMATICS = '[Telematics] Remove Vehicle From Telematics',
  REMOVE_VEHICLE_FROM_TELEMATICS_SUCCESS = '[Telematics] Remove Vehicle From Telematics Success',
  REMOVE_VEHICLE_FROM_TELEMATICS_FAIL = '[Telematics] Remove Vehicle From Telematics Fail',
  UPDATE_TELEMATICS_CONFIRMATION = '[Telematics] Update Telematics Confirmation',
  UPDATE_TELEMATICS_REVIEWED = '[Telematics] Update Telematics Reviewed',
  UPDATE_RECOMMENDATION_FOR_VEHICLE = '[Telematics] Update Recommendation For Vehicle',
  UPDATE_STORE_TELEMATICS_VEHICLE = '[Telematics] Update Store Telematics Vehicle',
}

export const getTelematicsRecommendation = createAction(
  TelematicsEnrollmentsActionTypes.GET_TELEMATICS_RECOMMENDATION,
  props<{ dontAutoApply?: boolean }>()
);

export const getTelematicsRecommendationSuccess = createAction(
  TelematicsEnrollmentsActionTypes.GET_TELEMATICS_RECOMMENDATION_SUCCESS,
  props<{ payload: TelematicsRecommendationResponse }>()
);

export const getTelematicsRecommendationFail = createAction(
  TelematicsEnrollmentsActionTypes.GET_TELEMATICS_RECOMMENDATION_FAIL,
  props<{ payload: ErrorModel }>()
);

export const addTelematicsEnrollment = createAction(
  TelematicsEnrollmentsActionTypes.ADD_TELEMATICS_ENROLLMENT,
  props<{ payload: Nullable<TelematicsRecommendationResponse> }>()
);

export const addTelematicsEnrollmentSuccess = createAction(
  TelematicsEnrollmentsActionTypes.ADD_TELEMATICS_ENROLLMENT_SUCCESS,
  props<{ payload?: TelematicsEnrollmentResponse }>()
);

export const addTelematicsEnrollmentFail = createAction(
  TelematicsEnrollmentsActionTypes.ADD_TELEMATICS_ENROLLMENT_FAIL,
  props<{ payload: ErrorModel }>()
);

export const getTelematicsEnrollment = createAction(
  TelematicsEnrollmentsActionTypes.GET_TELEMATICS_ENROLLMENT
);

export const getTelematicsEnrollmentSuccess = createAction(
  TelematicsEnrollmentsActionTypes.GET_TELEMATICS_ENROLLMENT_SUCCESS,
  props<{ payload?: TelematicsEnrollmentResponse }>()
);

export const getTelematicsEnrollmentFail = createAction(
  TelematicsEnrollmentsActionTypes.GET_TELEMATICS_ENROLLMENT_FAIL,
  props<{ payload: ErrorModel }>()
);

export const refreshTelematicsEnrollment = createAction(
  TelematicsEnrollmentsActionTypes.REFRESH_TELEMATICS_ENROLLMENT
);

export const refreshTelematicsEnrollmentSuccess = createAction(
  TelematicsEnrollmentsActionTypes.REFRESH_TELEMATICS_ENROLLMENT_SUCCESS,
  props<{
    payload: {
      response: TelematicsEnrollmentResponse;
      previous: Nullable<VehicleEnrollment | MobileEnrollment>;
      quoteId: string;
    };
  }>()
);

export const refreshTelematicsEnrollmentFail = createAction(
  TelematicsEnrollmentsActionTypes.REFRESH_TELEMATICS_ENROLLMENT_FAIL,
  props<{ payload: ErrorModel }>()
);

export const updateTelematicsEnrollment = createAction(
  TelematicsEnrollmentsActionTypes.UPDATE_TELEMATICS_ENROLLMENT,
  props<{
    payload: {
      selectedProgram: string;
      vehicleId: string;
      dataCollectionMethod?: 'Device' | 'ConnectedCar';
    };
  }>()
);

export const updateTelematicsTextNotification = createAction(
  TelematicsEnrollmentsActionTypes.UPDATE_TELEMATICS_TEXT_NOTIFICATION,
  props<{ payload: TelematicsTextNotificationsModel }>()
);

export const updateTelematicsEnrollmentSuccess = createAction(
  TelematicsEnrollmentsActionTypes.UPDATE_TELEMATICS_ENROLLMENT_SUCCESS,
  props<{ payload?: TelematicsEnrollmentResponse }>()
);

export const updateTelematicsTextNotificationSuccess = createAction(
  TelematicsEnrollmentsActionTypes.UPDATE_TELEMATICS_TEXT_NOTIFICATION_SUCCESS,
  props<{ payload?: TelematicsEnrollmentResponse }>()
);

export const updateTelematicsEnrollmentFail = createAction(
  TelematicsEnrollmentsActionTypes.UPDATE_TELEMATICS_ENROLLMENT_FAIL,
  props<{ payload: ErrorModel }>()
);

export const removeTelematicsEnrollment = createAction(
  TelematicsEnrollmentsActionTypes.REMOVE_TELEMATICS_ENROLLMENT
);

export const removeTelematicsEnrollmentSuccess = createAction(
  TelematicsEnrollmentsActionTypes.REMOVE_TELEMATICS_ENROLLMENT_SUCCESS
);

export const removeTelematicsEnrollmentFail = createAction(
  TelematicsEnrollmentsActionTypes.REMOVE_TELEMATICS_ENROLLMENT_FAIL,
  props<{ payload: ErrorModel }>()
);

export const resetTelematicsEnrollment = createAction(
  TelematicsEnrollmentsActionTypes.RESET_TELEMATICS_ENROLLMENT
);

export const removeVehicleFromTelematics = createAction(
  TelematicsEnrollmentsActionTypes.REMOVE_VEHICLE_FROM_TELEMATICS,
  props<{ payload: number }>()
);

export const removeVehicleFromTelematicsSuccess = createAction(
  TelematicsEnrollmentsActionTypes.REMOVE_VEHICLE_FROM_TELEMATICS_SUCCESS,
  props<{ payload?: TelematicsEnrollmentResponse }>()
);

export const removeVehicleFromTelematicsFail = createAction(
  TelematicsEnrollmentsActionTypes.REMOVE_VEHICLE_FROM_TELEMATICS_FAIL
);

export const updateTelematicsConfirmation = createAction(
  TelematicsEnrollmentsActionTypes.UPDATE_TELEMATICS_CONFIRMATION,
  props<{ payload: boolean }>()
);

export const updateTelematicsReviewed = createAction(
  TelematicsEnrollmentsActionTypes.UPDATE_TELEMATICS_REVIEWED,
  props<{ payload: boolean }>()
);

export const updateRecommendationForVehicle = createAction(
  TelematicsEnrollmentsActionTypes.UPDATE_RECOMMENDATION_FOR_VEHICLE,
  props<{ vehicle: VehicleEntity }>()
);

export const updateStoreTelematicsVehicle = createAction(
  TelematicsEnrollmentsActionTypes.UPDATE_STORE_TELEMATICS_VEHICLE,
  props<{ payload: ExtendedTelematicsVehicle }>()
);

export const openTelematicsModal = createAction(
  '[Telematics] Launch Telematics Modal'
);

export const setTelematicsTabActive = createAction(
  '[Telematics] Set Telematics Tab Active'
);

export const excludeQuoteFromTelematics = createAction(
  '[Telematics] Exclude Quote',
  props<{ quoteId: string }>()
);

export const clearTelematicsExcludedQuotes = createAction(
  '[Telematics] Clear Excluded Quotes'
);

export const clearTelematicsLoading = createAction(
  '[Telematics] Clear Loading'
);
