<div class="outer" [ngClass]="{ 'has-error': model.hasError }">
  <div
    class="nwx-card-header bolt-container-fluid"
    id="hubPolicySummaryCardHeader-{{ model.id }}"
  >
    <div *ngIf="model.staticTextHeader" class="staticTextHeader">
      {{ model.staticTextHeader }}
    </div>
    <div [ngSwitch]="model.syncStatus">
      <div *ngSwitchCase="'Clean'" class="sync-clean">Included &#x2713;</div>
      <div *ngSwitchCase="'SetByBackend'" class="sync-clean">
        Included &#x2713;
      </div>
      <div *ngSwitchCase="'Dirty'" class="sync-dirty">* Unsaved changes</div>
      <div *ngSwitchCase="'CallInProgress'" class="sync-in-progress">
        Updating...
      </div>
    </div>
    <div class="hub-header-headline" id="hcs-header-headline-{{ model.id }}">
      {{ model.headline }}
    </div>
    <div
      *ngIf="model.description"
      id="hcs-header-description-{{ model.id }}"
      class="hub-header-description"
    >
      {{ model.description }}
    </div>
  </div>
  <div class="bolt-col-2" *ngIf="model.unselectOption">
    <bolt-button
      (click)="onUnselect()"
      iconleft="xmark"
      class="nwx-borderless-bolt-button"
    ></bolt-button>
  </div>
  <div>
    <ng-template #form></ng-template>
  </div>
</div>
