import { ObjectValidator } from '@core/helper/object-validator';
import { QuoteRetrieveHomeownerResponse } from '@core/models/api/response/retrieve-response.model';
import {
  OtherStructuresActions,
  RetrieveActions,
  SessionActions,
} from '@core/store/actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

export interface OtherStructuresAdditionalDwellingUnit {
  locationDescription?: string;
  numberOfUnits?: number;
  limit?: number;
  unitId?: number; // Should always be present and unique on units known to DSM.
}

export interface OtherStructureCoverage {
  // sic "Structure" not "Structures", matching DSM model
  coverageId: string;
  selectedValue: boolean;
  additionalDwellingUnits: OtherStructuresAdditionalDwellingUnit[];
}

export interface OtherStructuresEntity {
  quoteId: string; // Not part of the DSM model.
  otherStructureCoverages: OtherStructureCoverage[];
}

export function sanitizeOtherStructuresEntity(
  response: unknown
): OtherStructuresEntity {
  return ObjectValidator.forceSchema<OtherStructuresEntity>(
    response,
    {
      quoteId: 'string',
      otherStructureCoverages: sanitizeOtherStructureCoverages,
    },
    ['quoteId', 'otherStructureCoverages']
  );
}

export function sanitizeOtherStructureCoverages(
  response: unknown
): OtherStructureCoverage[] {
  return ObjectValidator.sanitizeArray(
    response,
    sanitizeOtherStructureCoverage
  );
}

export function sanitizeOtherStructureCoverage(
  response: unknown
): OtherStructureCoverage {
  return ObjectValidator.forceSchema<OtherStructureCoverage>(
    response,
    {
      coverageId: 'string',
      selectedValue: 'boolean',
      additionalDwellingUnits: sanitizeOtherStructuresAdditionalDwellingUnits,
    },
    ['coverageId', 'selectedValue']
  );
}

export function sanitizeOtherStructuresAdditionalDwellingUnits(
  response: unknown
): OtherStructuresAdditionalDwellingUnit[] {
  return ObjectValidator.sanitizeArray(
    response,
    sanitizeOtherStructuresAdditionalDwellingUnit
  );
}

export function sanitizeOtherStructuresAdditionalDwellingUnit(
  response: unknown
): OtherStructuresAdditionalDwellingUnit {
  return ObjectValidator.forceSchema<OtherStructuresAdditionalDwellingUnit>(
    response,
    {
      locationDescription: 'string',
      numberOfUnits: 'number',
      limit: 'number',
      unitId: 'number',
    },
    ['locationDescription', 'numberOfUnits', 'limit', 'unitId']
  );
}

export const OTHER_STRUCTURE_COVERAGE_DUMMY: OtherStructureCoverage = {
  coverageId: '',
  selectedValue: false,
  additionalDwellingUnits: [],
};

export interface OtherStructuresState
  extends EntityState<OtherStructuresEntity> {}

export const adapter = createEntityAdapter<OtherStructuresEntity>({
  selectId: (entity) => entity.quoteId,
});

const initialState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(OtherStructuresActions.storeOtherStructures, (state, action) => {
    return adapter.upsertOne(action.otherStructures, state);
  }),
  on(RetrieveActions.retrieveQuoteSuccess, (state, action) => {
    const rsp = action.payload.response as QuoteRetrieveHomeownerResponse;
    if (rsp.otherStructureCoverages) {
      return adapter.upsertOne(
        {
          quoteId: action.payload.response.quoteId,
          otherStructureCoverages: rsp.otherStructureCoverages,
        },
        state
      );
    }
    return state;
  })
);
