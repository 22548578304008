<div class="modal-header">
  <h4 class="modal-title">Review and accept program enrollment</h4>
</div>
<div class="modal-body">
  <div class="bolt-container">
    <div class="bolt-row bolt-align-items-end">
      <div class="bolt-col">
        <p class="bolt-heading-xs">Accept all to continue</p>
      </div>
    </div>
  </div>
  <div class="bolt-container" *ngIf="isMobile">
    <div class="bolt-row">
      <div class="bolt-col">
        <h4>
          SmartRide&reg; safe-driving discount, with a mobile app
          <img
            class="tm-icon float-right"
            src="assets/images/telematics/smartride-teal.svg"
            alt="Car icon"
          />
        </h4>
        <div class="smartride-tile-body mt-4">
          <div
            class="bolt-row bolt-justify-content-center"
            *ngFor="let vehicle of smartRideVehicles"
          >
            <div class="bolt-col-auto">
              <h4 class="text-center">
                {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
              </h4>
            </div>
          </div>
          <div class="bolt-col-auto">
            <p class="text-start">
              - Customer agrees to download and activate the SmartRide Mobile
              app<br />
              - Customer agrees to receive a one-time text and ongoing program
              emails
            </p>
          </div>
        </div>
        <bolt-checkbox
          #confirmationCheckbox
          class="mt-2"
          value="agree"
          [attr.id]="'tcm-device-mobile-app'"
          required
          (change)="checkedAgreementBox()"
          >Customer agrees to these terms</bolt-checkbox
        >
      </div>
    </div>
  </div>
  <div class="bolt-container" *ngIf="!isMobile">
    <div class="bolt-row" *ngIf="smartMilesVehicles.length">
      <div class="bolt-col">
        <h4>
          SmartMiles&reg; pay-per-mile program, with a device
          <img
            class="tm-icon float-right"
            src="assets/images/telematics/smartmiles-purple.svg"
            alt="Car icon"
          />
        </h4>
        <div class="smartmiles-tile-body mt-4">
          <div
            class="bolt-row bolt-justify-content-center"
            *ngFor="let vehicle of smartMilesVehicles"
          >
            <div class="bolt-col-auto">
              <h4 class="text-center">
                {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
              </h4>
            </div>
          </div>
          <div class="bolt-col-auto">
            <p class="text-start" [innerHTML]="smartMilesAgreements"></p>
          </div>
        </div>
        <bolt-checkbox
          #confirmationCheckbox
          class="mt-2"
          [attr.id]="'tmc-device-mileage'"
          value="agree"
          required
          (change)="checkedAgreementBox()"
          >Customer agrees to these terms</bolt-checkbox
        >
      </div>
    </div>
    <div class="bolt-row" *ngIf="smartMilesConnectedCarVehicles.length">
      <div class="bolt-col">
        <h4>
          SmartMiles&reg; pay-per-mile program, with a connected car
          <img
            class="tm-icon float-right"
            src="assets/images/telematics/connected-car-purple.svg"
            alt="Car icon"
          />
        </h4>
        <div class="smartmiles-tile-body mt-4">
          <div
            class="bolt-row bolt-justify-content-center"
            *ngFor="let vehicle of smartMilesConnectedCarVehicles"
          >
            <div class="bolt-col-auto">
              <h4 class="text-center">
                {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
              </h4>
            </div>
          </div>
          <div class="bolt-col-auto">
            <p class="text-start">
              - {{ smartMilesConnectedCarAgreement1 }}<br />
              - Customer agrees to receive a one-time text and ongoing program
              emails<br />
              - Customer authorizes Nationwide to share
              <bolt-contextual-help label="driving data">
                <p>
                  Personally identifiable information such as Name, Vin, and
                  Policy Number
                </p>
              </bolt-contextual-help>
              with the vehicle manufacturer to complete enrollment
            </p>
          </div>
        </div>
        <bolt-checkbox
          #confirmationCheckbox
          class="mt-2"
          [attr.id]="'tmc-device-mileage'"
          value="agree"
          required
          (change)="checkedAgreementBox()"
          >Customer agrees to these terms</bolt-checkbox
        >
      </div>
    </div>
    <div *ngIf="smartRideVehicles.length && smartMilesVehicles.length">
      <hr />
    </div>
    <div class="bolt-row" *ngIf="smartRideVehicles.length">
      <hr />
      <div class="bolt-col">
        <h4>
          <ng-container
            *ngIf="(isCA$ | async) === false; else CASmartRideDevice"
          >
            SmartRide&reg; safe-driving discount, with a device
          </ng-container>
          <ng-template #CASmartRideDevice>
            SmartRide&reg; low-mileage discount, with a device
          </ng-template>
          <img
            class="tm-icon float-right"
            src="assets/images/telematics/smartride-teal.svg"
            alt="Car icon"
          />
        </h4>
        <div class="smartride-tile-body mt-4">
          <div
            class="bolt-row bolt-justify-content-center"
            *ngFor="let vehicle of smartRideVehicles"
          >
            <div class="bolt-col-auto">
              <h4 class="text-center">
                {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
              </h4>
            </div>
          </div>
          <div class="bolt-col-auto">
            <p class="text-start">
              - Customer agrees to plug in a device into the vehicle
              <span
                *ngIf="
                  (isCA$ | async) === false;
                  else CASmartRideMileageMeasure
                "
              >
                that measures driving behavior
              </span>
              <ng-template #CASmartRideMileageMeasure>
                <span> to measure mileage </span>
              </ng-template>
              <br />
              - Customer agrees to receive a one-time text and ongoing program
              emails
            </p>
          </div>
        </div>
        <bolt-checkbox
          #confirmationCheckbox
          class="mt-2"
          value="agree"
          [attr.id]="'tcm-device-car'"
          required
          (change)="checkedAgreementBox()"
          >Customer agrees to these terms</bolt-checkbox
        >
      </div>
    </div>
    <div
      *ngIf="
        (smartRideVehicles.length && smartRideConnectedCarVehicles.length) ||
        (smartMilesVehicles.length && smartRideConnectedCarVehicles.length)
      "
    >
      <hr />
    </div>
    <div class="bolt-row" *ngIf="smartRideConnectedCarVehicles.length">
      <hr />
      <div class="bolt-col">
        <h4>
          <ng-container
            *ngIf="(isCA$ | async) === false; else CASmartRideDevice"
          >
            SmartRide&reg; safe-driving discount, with a connected car
          </ng-container>
          <ng-template #CASmartRideDevice>
            SmartRide&reg; low-mileage discount, with a connected car
          </ng-template>
          <img
            class="tm-icon float-right"
            src="assets/images/telematics/smartride-teal.svg"
            alt="Car icon"
          />
        </h4>
        <div class="smartride-tile-body mt-4">
          <div
            class="bolt-row bolt-justify-content-center"
            *ngFor="let vehicle of srccToyotaVehicles"
          >
            <div class="bolt-col-auto">
              <h4 class="text-center">
                {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
              </h4>
            </div>
          </div>
          <div class="bolt-col-auto" *ngIf="srccToyotaVehicles.length">
            <p class="text-start">
              - Customer agrees to receive a one-time text and ongoing program
              emails<br />
              - Customer authorizes Nationwide to
              <bolt-contextual-help
                *ngIf="isCA$ | async; else notCA"
                label="share driving data"
              >
                <p>
                  Nationwide Insurance partners with Toyota's affiliate,
                  Connected Analytic Services, LLC ("CAS") to offer personalized
                  discounts to customers with connected vehicles. When you
                  authorize us to obtain Driving Data from CAS, CAS may provide
                  information such as the date, time and distance traveled for
                  every distinct trip taken. If we receive Driving Data from
                  CAS, we will retain this information as part of your quote or
                  policy records pursuant to our
                  <a
                    href="https://www.nationwide.com/personal/privacy-security/pages/privacy"
                    target="_blank"
                  >
                    privacy policy
                  </a>
                </p>
              </bolt-contextual-help>
              <ng-template #notCA>
                <bolt-contextual-help label="share driving data"
                  ><p>
                    Nationwide Insurance partners with Toyota's affiliate,
                    Connected Analytic Services, LLC ("CAS") to offer
                    personalized discounts to customers with connected vehicles.
                    When you authorize us to obtain Driving Data from CAS, CAS
                    may provide information such as the date, time and distance
                    traveled for every distinct trip taken, as well as braking
                    and acceleration events. We may also receive vehicle speed
                    and steering data. If we receive Driving Data from CAS, we
                    will retain this information as part of your quote or policy
                    records pursuant to our
                    <a
                      href="https://www.nationwide.com/personal/privacy-security/pages/privacy"
                      target="_blank"
                    >
                      privacy policy
                    </a>
                  </p>
                </bolt-contextual-help>
              </ng-template>
              with the vehicle manufacturer to complete enrollment
            </p>
          </div>
          <div
            class="bolt-row bolt-justify-content-center"
            *ngFor="let vehicle of srccFordVehicles"
          >
            <div class="bolt-col-auto">
              <h4 class="text-center">
                {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
              </h4>
            </div>
          </div>
          <div class="bolt-col-auto" *ngIf="srccFordVehicles.length">
            <p class="text-start">
              - Customer agrees to receive a one-time text and ongoing program
              emails<br />
              - Customer authorizes Nationwide to share
              <bolt-contextual-help label="driving data">
                <p>
                  Personally identifiable information such as Name, VIN, and
                  Policy Number.
                </p>
              </bolt-contextual-help>
              with the vehicle manufacturer to complete enrollment
            </p>
          </div>
        </div>
        <bolt-checkbox
          #confirmationCheckbox
          class="mt-2"
          value="agree"
          [attr.id]="'tcm-device-car'"
          required
          (change)="checkedAgreementBox()"
          >Customer agrees to these terms</bolt-checkbox
        >
      </div>
    </div>
    <div
      *ngIf="
        (smartRideInstantVehicles.length && smartMilesVehicles.length) ||
        (smartRideInstantVehicles.length && smartRideVehicles.length) ||
        (smartRideInstantVehicles.length &&
          smartRideConnectedCarVehicles.length)
      "
    >
      <hr />
    </div>
    <div class="bolt-row" *ngIf="smartRideInstantVehicles.length">
      <div class="bolt-col">
        <h4>
          SmartRide&reg; safe-driving discount - final discount instantly
          applied
          <img
            class="tm-icon float-right"
            src="assets/images/telematics/connected-car-dk-blue.svg"
            alt="Car icon"
          />
        </h4>
        <div class="smartride-instant-tile-body mt-4">
          <div
            class="bolt-row bolt-justify-content-center"
            *ngFor="let vehicle of smartRideInstantVehicles"
          >
            <div class="bolt-col-auto">
              <h4 class="text-center">
                {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
              </h4>
              <p class="text-start">
                - Customer agrees to the use of data already collected by their
                vehicle to apply the discount<br />
                - Customer agrees to receive a one-time text and ongoing program
                emails
              </p>
            </div>
          </div>
        </div>
        <bolt-checkbox
          #confirmationCheckbox
          class="mt-2"
          [attr.id]="'tcm-device-car-data'"
          value="agree"
          (change)="checkedAgreementBox()"
          required
          >Customer agrees to these terms</bolt-checkbox
        >
      </div>
    </div>
    <div class="bolt-row" *ngIf="selfReportedVehicles.length">
      <div class="bolt-col">
        <div class="mb-2">
          <img
            class="tm-icon float-right"
            src="assets/images/telematics/smartride-teal.svg"
            alt="Car icon"
          />
        </div>
        <h4>Enrolled in Self-Reported Low Mileage Program</h4>
        <div class="smartride-instant-tile-body mt-4">
          <div
            class="bolt-row bolt-justify-content-center"
            *ngFor="let vehicle of selfReportedVehicles"
          >
            <div class="bolt-col-auto">
              <h4 class="text-center">
                {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
              </h4>
              <p class="text-start">
                <ng-container
                  *ngIf="(isCA$ | async) === true; else nonCASelfEnrolled"
                >
                  - Customer agrees to complete a form that provides vehicle's
                  mileage at enrollment and before every policy renewal<br />
                  - Customer agrees to receive a one-time text and ongoing
                  program emails
                </ng-container>
                <ng-template #nonCASelfEnrolled>
                  - Requires proof of mileage by sending us a photo or recent
                  service receipt showing the current odometer reading<br />
                  - Before each policy renewal, provide us with an updated
                  odometer reading<br />
                  - Keep the discount for as long as you're insured with us and
                  continue sending proof of your mileage
                </ng-template>
              </p>
            </div>
          </div>
        </div>
        <bolt-checkbox
          #confirmationCheckbox
          class="mt-2"
          [attr.id]="'tcm-low-mileage'"
          value="agree"
          (change)="checkedAgreementBox()"
          required
          >Customer agrees to these terms</bolt-checkbox
        >
      </div>
    </div>
  </div>
</div>

<hr />
<nwx-telematics-text-updates
  [allCheckboxesChecked]="checkboxObs$"
  [namedInsured]="(namedInsured$ | async) || null"
  [currentEnrollment]="(currentEnrollment$ | async) || null"
  [submitted$]="attemptSubmit$.asObservable()"
  (formSubmit)="onModalSubmit($event)"
></nwx-telematics-text-updates>
<div class="modal-footer">
  <bolt-button-bar>
    <bolt-button slot="back" [attr.id]="'tcm-cancel'" (click)="closeModal()"
      >Cancel</bolt-button
    >
    <bolt-button
      slot="forward"
      type="primary"
      [attr.disabled]="btnDisabled ? 'disabled' : null"
      [attr.id]="'tcm-continue'"
      (click)="continue()"
      >Continue</bolt-button
    >
  </bolt-button-bar>
</div>
