import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { PersonaService } from '@core/services/persona.service';
import { ProductsService } from '@core/services/products.service';
import {
  PersonaReport,
  PersonaRecommendation,
  PersonaRecommendationIdToDisplayLabelAndIcon,
  PersonaRecommendationId,
} from '@entities/persona/persona.model';
import { ModalService } from '@shared/services/modal.service';
import { ProductUtils } from '@shared/utils/product.util';
import { Nullable } from '@shared/utils/type.utils';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { CoverageModalCancelled } from '@app/hub/components/coverage-modal/coverage-modal.component';

@Component({
  selector: 'nwx-persona-display',
  templateUrl: './persona-display.component.html',
  styleUrls: ['./persona-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonaDisplayComponent implements OnChanges, OnDestroy {
  @Input() personaReport: Nullable<PersonaReport> = {
    name: 'None',
    recommendations: [],
  };

  products!: string[];
  recommendationDisplayDetails = PersonaRecommendationIdToDisplayLabelAndIcon;
  selectedProductTypes$!: Observable<ProductType[]>;
  selectedPropertyProductType!: ProductType | undefined;
  errorMessage$ = new BehaviorSubject('');
  operationsInFlight: string[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private personaService: PersonaService,
    private modalService: ModalService,
    private productsService: ProductsService,
    private errorSanitizerService: ErrorSanitizerService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.selectedProductTypes$ = this.productsService.getSelectedProductTypes();
    this.selectedProductTypes$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedProducts: ProductType[]) => {
        this.selectedPropertyProductType = selectedProducts.find(
          (productType) => ProductUtils.isPropertyProduct(productType)
        );
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.personaReport && !!this.personaReport) {
      this.personaReport = this.removeUnavailableRecommendations(this.personaReport);
      this.products = [
        ...new Set(
          this.personaReport.recommendations?.map((rec) => rec.product)
        ),
      ];
      const preferredDisplayOrder = ['Property', 'Auto', ''];
      this.products.sort(
        (a, b) =>
          preferredDisplayOrder.indexOf(a) - preferredDisplayOrder.indexOf(b)
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private removeUnavailableRecommendations(report: PersonaReport): PersonaReport {
    return {
      ...report,
      recommendations: report.recommendations.filter(r => r.available),
    };
  }

  getProductIconName(product: string): string {
    switch (product) {
      case 'Auto':
        return 'city-car.svg';
      case 'Property':
        return 'house.svg';
      case '':
        return 'more-options.svg';
      default:
        return '';
    }
  }

  getRecommendationDisplayDetails(id: PersonaRecommendationId): any {
    return this.recommendationDisplayDetails[id];
  }

  addRecommendation(recommendation: PersonaRecommendation): void {
    this.clearError();
    this.addOperationInFlight(recommendation.id);
    let operation: Promise<void>;
    if (recommendation.isCoverageWithLimit) {
      operation = this.modalService.coverageModal(
        'Enable Coverage',
        recommendation.id,
        this.getProductType(recommendation.product)
      );
    } else {
      operation = this.personaService.acceptPersonaRecommendation(
        recommendation.id
      );
    }
    operation
      .catch((e) => this.displayError(e))
      .then(() => {
        this.removeOperationInFlight(recommendation.id);
      });
  }

  addAllRecommendations(): void {
    this.clearError();
    if (!this.personaReport?.recommendations?.length) {
      return;
    }
    for (const r of this.personaReport.recommendations) {
      this.addOperationInFlight(r.id);
    }
    this.personaService
      .acceptAllPersonaRecommendations(this.personaReport.recommendations)
      .catch((e) => this.displayError(e))
      .then(() => {
        for (const r of this.personaReport?.recommendations || []) {
          this.removeOperationInFlight(r.id);
        }
      });
  }

  removeRecommendation(recommendation: PersonaRecommendation): void {
    this.clearError();
    this.addOperationInFlight(recommendation.id);
    this.personaService
      .declinePersonaRecommendation(recommendation.id)
      .catch((e) => this.displayError(e))
      .then(() => {
        this.removeOperationInFlight(recommendation.id);
      });
  }

  private addOperationInFlight(id: string): void {
    if (!this.operationsInFlight.includes(id)) {
      this.operationsInFlight.push(id);
      this.changeDetector.markForCheck();
    }
  }

  private removeOperationInFlight(id: string): void {
    const p = this.operationsInFlight.indexOf(id);
    if (p >= 0) {
      this.operationsInFlight.splice(p, 1);
      this.changeDetector.markForCheck();
    }
  }

  recommendationInFlight(id: string): boolean {
    return this.operationsInFlight.includes(id);
  }

  getProductRecommendations(product: string): PersonaRecommendation[] {
    return (
      this.personaReport?.recommendations?.filter(
        (rec) => rec.product === product
      ) || []
    );
  }

  getProductType(product: string): ProductType {
    switch (product) {
      case 'Property':
        return this.selectedPropertyProductType || 'Homeowner';
      default:
        return 'PersonalAuto';
    }
  }

  areAllRecommendationsSelected(): boolean {
    return (
      this.personaReport?.recommendations.every(
        (recommendation) => recommendation.present
      ) || false
    );
  }

  showSmartBundleInsights(personaReport: Nullable<PersonaReport>): boolean {
    return personaReport?.name === 'EmptyNester';
  }

  clearError(): void {
    this.errorMessage$.next('');
  }

  displayError(error: any): void {
    if (error instanceof CoverageModalCancelled) {
      return;
    }
    const message =
      this.errorSanitizerService.sanitizeError(error).displayMessage ||
      'Failed to update quote.';
    this.errorMessage$.next(message);
  }
}
