import { Action, createReducer, on } from '@ngrx/store';
import { ErrorModel } from '../error/error.model';
import { QuoteRetrievePersonalAutoResponse } from '@core/models/api/response/retrieve-response.model';
import {
  CurrentCarrierCoverageLapse,
  ProductType,
} from '@core/models/api/dsm-types';
import {
  CurrentCarrierActions,
  RetrieveActions,
  SessionActions,
} from '../../actions';
import { BaseEntity } from '@core/models/entities/base.entity';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProductUtils } from '@shared/utils/product.util';
import { ObjectValidator } from '@core/helper/object-validator';

export interface CurrentCarrierEntity extends BaseEntity {
  currentCarrierName?: string;
  coverageLapse?: CurrentCarrierCoverageLapse;
  currentBodilyInjuryLimit?: string;
  currentCarrierTerms?: number;
  isNationwideWinBack?: boolean;
  informationalMessages?: string[];
  reasonForCoverageLapse?: string;
  currentCarrierHitStatus?: string;
}

export function sanitizeCurrentCarrierEntity(
  input: unknown
): CurrentCarrierEntity {
  return ObjectValidator.forceSchema<CurrentCarrierEntity>(
    input,
    {
      entityId: 'any',
      currentCarrierName: 'string',
      coverageLapse: 'string',
      currentBodilyInjuryLimit: 'string',
      currentCarrierTerms: 'number',
      isNationwideWinBack: 'boolean',
      informationalMessages: ['string'],
      reasonForCoverageLapse: 'string',
      currentCarrierHitStatus: 'string',
    },
    [
      'entityId',
      'currentCarrierName',
      'coverageLapse',
      'currentBodilyInjuryLimit',
      'currentCarrierTerms',
      'isNationwideWinBack',
      'informationalMessages',
      'reasonForCoverageLapse',
      'currentCarrierHitStatus',
    ]
  );
}

export interface CarrierState extends EntityState<CurrentCarrierEntity> {
  isCurrentCarrierHit?: boolean;
  error?: ErrorModel;
}

export const adapter: EntityAdapter<CurrentCarrierEntity> =
  createEntityAdapter<CurrentCarrierEntity>({
    selectId: (currentCarrier) => currentCarrier.productType as ProductType,
  });

const initialState: CarrierState = adapter.getInitialState({
  isCurrentCarrierHit: false,
  error: {},
});

const currentCarrierReducer = createReducer<CarrierState>(
  initialState,

  on(SessionActions.clearSessionState, () => initialState),

  on(CurrentCarrierActions.updateCurrentCarrier, (state) => ({
    ...state,
    currentCarrierLoading: true,
  })),
  on(
    CurrentCarrierActions.updateCurrentCarrierSuccess,
    CurrentCarrierActions.storeCurrentCarrier,
    (state, { payload }) =>
      adapter.upsertOne(payload, {
        ...state,
      })
  ),
  on(CurrentCarrierActions.updateCurrentCarrierFail, (state, { error }) => ({
    ...state,
    isCurrentCarrierHit: false,
    error,
  })),
  on(CurrentCarrierActions.getCurrentCarrierSuccess, (state, { payload }) => ({
    ...state,
    isCurrentCarrierHit: true,
  })),
  on(CurrentCarrierActions.getCurrentCarrierFail, (state, { error }) => ({
    ...state,
    isCurrentCarrierHit: false,
    error,
  })),
  on(
    CurrentCarrierActions.updateCurrentCarrierFromQuote,
    (state, { payload }) =>
      adapter.upsertOne(payload, {
        ...state,
      })
  ),
  on(CurrentCarrierActions.setCurrentCarrier, (state, { payload, hit }) =>
    adapter.upsertOne(payload, {
      ...state,
      isCurrentCarrierHit: hit,
    })
  ),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    const response = payload.response as QuoteRetrievePersonalAutoResponse;
    if (
      response.currentCarrier &&
      // This is a hacky fix for a problem with DSM sending "isNationwideWinBack: false"
      response.currentCarrier.currentCarrierName &&
      ProductUtils.CurrentCarrierProducts.includes(payload.productType)
    ) {
      const currentCarrier = {
        ...response.currentCarrier,
        productType: payload.productType,
      } as CurrentCarrierEntity;
      return adapter.upsertOne(currentCarrier, {
        ...state,
      });
    } else {
      return state;
    }
  })
);

export function reducer(
  state: CarrierState | undefined,
  action: Action
): CarrierState {
  return currentCarrierReducer(state, action);
}
