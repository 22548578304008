import {
  AdditionalInsuredType,
  AdditionalInterestType,
  ProductType,
} from '@core/models/api/dsm-types';

interface AdditionalInterestOptionType {
  additionalInterestType: AdditionalInterestType;
  thirdPartyDescription?: AdditionalInsuredType;
}

export interface AdditionalInterestOption {
  displayName: string;
  appliedProducts: ProductType[];
  value: AdditionalInterestOptionType;
}

export const ALL_VEHICLES: ProductType[] = [
  'Boat',
  'RV',
  'MSA',
  'PersonalAuto'
]

export const ALL_EXCEPT_AUTO_VEHICLES: ProductType[] = [
  'Boat',
  'RV',
  'MSA'
]

export const ADDITIONAL_INTEREST_OPTIONS: AdditionalInterestOption[] = [
  {
    displayName: 'Loan',
    appliedProducts: ALL_VEHICLES,
    value: {
      additionalInterestType: 'LIEN'
    }
  },
  {
    displayName: 'Lease',
    appliedProducts: ALL_VEHICLES,
    value: {
      additionalInterestType: 'LESSOR'
    }
  },
  {
    displayName: 'Additional Insured',
    appliedProducts: ['PersonalAuto'],
    value: {
      additionalInterestType: 'AdditionalInsured_Ext'
    }
  },
  {
    displayName: 'Additional Insured - Individual',
    appliedProducts: ALL_EXCEPT_AUTO_VEHICLES,
    value: {
      additionalInterestType: 'AdditionalInsured_Ext',
      thirdPartyDescription: 'Individual'
    }
  },
  {
    displayName: 'Additional Insured - LLC or company not solely owned by the Primary Insured',
    appliedProducts: ALL_EXCEPT_AUTO_VEHICLES,
    value: {
      additionalInterestType: 'AdditionalInsured_Ext',
      thirdPartyDescription: 'LLCorCompanynotsolelyownedbythePrimaryInsured'
    }
  },
  {
    displayName: 'Additional Insured - LLC or company solely owned by the Primary Insured',
    appliedProducts: ALL_EXCEPT_AUTO_VEHICLES,
    value: {
      additionalInterestType: 'AdditionalInsured_Ext',
      thirdPartyDescription: 'LLCorCompanysolelyownedbythePrimaryInsured'
    }
  },
  {
    displayName: 'Additional Insured - Trust not in name of Primary Insured',
    appliedProducts: ALL_EXCEPT_AUTO_VEHICLES,
    value: {
      additionalInterestType: 'AdditionalInsured_Ext',
      thirdPartyDescription: 'TrustnotinnameofPrimaryInsured'
    }
  },
  {
    displayName: 'Additional Insured - Trust in Name of Primary Insured',
    appliedProducts: ALL_EXCEPT_AUTO_VEHICLES,
    value: {
      additionalInterestType: 'AdditionalInsured_Ext',
      thirdPartyDescription: 'TrustinNameofPrimaryInsured'
    }
  },
  {
    displayName: 'Additional Interest - Campground',
    appliedProducts: ALL_EXCEPT_AUTO_VEHICLES,
    value: {
      additionalInterestType: 'AdditionalInterest_Ext',
      thirdPartyDescription: 'Campground'
    }
  },
  {
    displayName: "Additional Interest - Homeowners' Association",
    appliedProducts: ALL_EXCEPT_AUTO_VEHICLES,
    value: {
      additionalInterestType: 'AdditionalInterest_Ext',
      thirdPartyDescription: 'HomeOwnersAssociation'
    }
  },
  {
    displayName: 'Additional Interest - Marina',
    appliedProducts: ALL_EXCEPT_AUTO_VEHICLES,
    value: {
      additionalInterestType: 'AdditionalInterest_Ext',
      thirdPartyDescription: 'Marina'
    }
  },
  {
    displayName: 'Additional Interest - Storage Facility',
    appliedProducts: ALL_EXCEPT_AUTO_VEHICLES,
    value: {
      additionalInterestType: 'AdditionalInterest_Ext',
      thirdPartyDescription: 'StorageFacility'
    }
  },
  {
    displayName: 'Additional Interest - Other',
    appliedProducts: ALL_EXCEPT_AUTO_VEHICLES,
    value: {
      additionalInterestType: 'AdditionalInterest_Ext',
      thirdPartyDescription: 'Other'
    }
  }
];
