import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from '@core/services/app-config.service';
import { LogService } from '@core/services/log.service';
import { combineLatest, Observable } from 'rxjs';
import { ErrorHandlerResponse } from '@core/models/api/response/error-handler-resposne.model';
import { ErrorHandlerRequest } from '@core/models/api/request/error-handler-request.model';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { SessionService } from '@core/services/session.service';
import { SessionSelectors } from '@core/store/selectors';
import { StringUtils } from '@shared/utils/string.utils';
import { LogApiName } from '@core/models/api/log.model';
import { Nullable } from '@shared/utils/type.utils';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerAdapter {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private store: Store,
    private sessionService: SessionService,
    private log: LogService) {}

  search(body: ErrorHandlerRequest): Observable<Nullable<ErrorHandlerResponse>> {
    const url: string = this.appConfigService.config.errorHandlerApiUrl;

    return this.buildRequestHeaders().pipe(
      switchMap((headers: HttpHeaders) => this.handleRequest(url, body, headers))
    );
  }

  private buildRequestHeaders(): Observable<HttpHeaders> {
    let headers: HttpHeaders = new HttpHeaders()
      .set('client_id', this.appConfigService.config.apiKey)
      .set('content-type', 'application/json');

    return combineLatest([
      this.sessionService.getAccessToken(),
      this.store.select(SessionSelectors.getSessionId)
    ]).pipe(
      take(1),
      map(([accessToken, sessionId]): HttpHeaders => {
        return headers
          .set('Authorization', `Bearer ${accessToken}`)
          .set('X-NW-Message-ID', StringUtils.generateMessageId(sessionId));
      })
    );
  }

  private handleRequest(url: string,
                        body: ErrorHandlerRequest,
                        headers: HttpHeaders): Observable<Nullable<ErrorHandlerResponse>> {
    const callName: LogApiName = 'error-handler-search';
    const correlationId: string = this.log.logApiRequest('POST', url, callName, body);
    return this.httpClient.post<ErrorHandlerResponse>(url, body, { headers: headers, observe: 'response' })
      .pipe(
        tap(response => this.log.logApiResponse(correlationId, response.status, response.body)),
        map(response => response.body),
        catchError((error: HttpErrorResponse) => this.log.logApiError(correlationId, error.error, error.status))
      );
  }
}
