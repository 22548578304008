import { NgModule } from '@angular/core';
import { BoltRadioGroupValueDirective } from './bolt-radio-group-value.directive';
import { CheckboxesAsArrayDirective } from './checkboxes-as-array.directive';
import { ClassWhenOffscreenDirective } from './class-when-offscreen.directive';
import { ExposeFormErrorsDirective } from './expose-form-errors.directive';
import { IsAuthorizedDirective } from './is-authorized.directive';
import { MaskDirective } from './mask.directive';
import { SubmitOnEnterDirective } from './submit-on-enter.directive';

@NgModule({
  declarations: [
    IsAuthorizedDirective,
    ExposeFormErrorsDirective,
    MaskDirective,
    BoltRadioGroupValueDirective,
    ClassWhenOffscreenDirective,
    CheckboxesAsArrayDirective,
    SubmitOnEnterDirective,
  ],
  exports: [
    IsAuthorizedDirective,
    ExposeFormErrorsDirective,
    MaskDirective,
    BoltRadioGroupValueDirective,
    ClassWhenOffscreenDirective,
    CheckboxesAsArrayDirective,
    SubmitOnEnterDirective,
  ],
})
export class SharedDirectivesModule {}
