import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { VinHelper } from '@core/helper/vin.helper';
import {
  AntiTheftDeviceModel,
  VehicleModel,
} from '@core/models/views/vehicle.model';
import {
  bodyTypeToString,
  VehicleInquiryService,
} from '@app/core/services/vehicle-inquiry.service';
import { Observable, of, Subject } from 'rxjs';
import {
  catchError,
  map,
  take,
  takeUntil,
  distinctUntilChanged,
  debounceTime,
} from 'rxjs/operators';
import { VehiclePageUtils } from '@shared/utils/pages/vehicles-page.utils';
import {
  FORM_DEBOUNCE_TIME,
  PERSONAL_AUTO_TRAILER_TYPE,
} from '@shared/constants/app-constants';
import { VehicleUtils } from '@shared/utils/vehicle.utils';

@Component({
  selector: 'nwx-vin-form',
  templateUrl: './vin-form.component.html',
  styleUrls: ['./vin-form.component.scss'],
  // ViewEncapsulation.None is necessary for styling the changed characters in a VIN.
  encapsulation: ViewEncapsulation.None,
})
export class VinFormComponent implements OnInit, OnDestroy {
  @Input() vehicleIndex!: number;
  @Input() vehicle!: Partial<VehicleModel>;
  @Input() allowMasks = false;
  @Input() required!: boolean;

  @Output() valueChange = new EventEmitter<Partial<VehicleModel>>();
  @Output() formReady = new EventEmitter<FormGroup>();

  form!: FormGroup;

  @ViewChild('disambiguationModal', { read: ElementRef })
  disambiguationModal!: ElementRef;
  disambiguationRequired = new Subject<boolean>();
  disambiguationOptions: { value: string; displayHtml: string }[] = [];
  disambiguationForm = new FormGroup({
    selection: new FormControl(''),
  });

  private unsubscribe$ = new Subject<void>();

  private lastViCallVin: string = '';
  private lastViCallOutcome: ValidationErrors | null = null;

  constructor(
    private fb: FormBuilder,
    private vehicleInquiryService: VehicleInquiryService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form = this.buildForm(this.vehicle);
    this.addFormValidators();

    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(FORM_DEBOUNCE_TIME),
        distinctUntilChanged(this.hasChanges)
      )
      .subscribe((changes: VehicleModel) => {
        this.emitChanges(changes);
      });

    this.formReady.emit(this.form);
    if (this.vehicle) {
      this.form.patchValue(this.vehicle, { emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this.form.disable();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get vin(): AbstractControl {
    return this.form?.get('vin') as AbstractControl;
  }

  disambiguateVin(): void {
    this.disambiguationForm.setValue({ selection: '' });
    this.disambiguationModal.nativeElement.openModal();
  }

  submitVinDisambiguation(): void {
    const value = this.disambiguationForm.value.selection;
    if (value) {
      this.form.patchValue({ vin: value });
    }
  }

  hasChanges(a: VehicleModel, b: VehicleModel): boolean {
    return a.vin === b.vin;
  }

  onValueChange(changes: Partial<VehicleModel>): void {
    this.valueChange.emit(changes);
  }

  emitChanges(changes: Partial<VehicleModel>): void {
    this.valueChange.emit({
      vehicleId: this.vehicle.vehicleId,
      vin: VehiclePageUtils.ValidateVinEmpty(changes.vin),
    });
  }

  buildForm(vehicle?: Partial<VehicleModel>): FormGroup {
    return this.fb.group({
      vin: this.fb.control(vehicle?.vin || null, { updateOn: 'blur' }),
      doNotDisable: this.fb.control(null, []), // DO NOT DELETE
    });
  }

  private validateVinSync(control: AbstractControl): ValidationErrors | null {
    const vin = control.value?.trim();
    if (!vin) {
      this.disambiguationRequired.next(false);
      return null;
    }
    const results = VinHelper.validate(vin, this.allowMasks);
    if (!results) {
      this.disambiguationRequired.next(false);
      return null;
    }
    if (results.replacement) {
      if (results.replacement !== control.value) {
        control.setValue(results.replacement);
      }
      this.disambiguationRequired.next(false);
      return null;
    }
    if (results.candidates) {
      this.disambiguationOptions = results.candidates.map((candidate) => ({
        value: candidate,
        displayHtml: VinHelper.highlightDifferences(vin, candidate),
      }));
      this.disambiguationRequired.next(true);
      return { vinValidationError: results.message };
    }
    this.disambiguationRequired.next(false);
    return { vinValidationError: results.message };
  }

  private validateVinAsync(
    control: AbstractControl
  ): Observable<ValidationErrors | null> {
    const vin = control.value?.trim();
    if (vin === this.lastViCallVin) {
      return of(this.lastViCallOutcome);
    }
    if (!vin || vin.includes('*') || control.pristine) {
      this.lastViCallVin = vin;
      this.lastViCallOutcome = null;
      return of(null);
    }
    return this.vehicleInquiryService
      .lookUpVin(
        vin,
        VehicleUtils.inquiryPathFromVehicleType(this.vehicle.vehicleType)
      )
      .pipe(
        take(1),
        map((response) => {
          this.changeDetector.markForCheck();
          const m =
            response.retrieve17DigitVehicleIdentificationNumberOdbiResponse
              .vehicleModel;

          this.valueChange.emit({
            vehicleId: this.vehicle.vehicleId,
            make: m.make,
            model: m.model,
            year: m.modelYear,
            series: m.seriesDescription,
            bodyStyle: m.style,
            bodyType: bodyTypeToString(m.vehicleModelTypeCode?.toString() || ''),
            antiTheftDevices: [m.antiTheftDevice as AntiTheftDeviceModel],
            antiLockBrakes: m.antiLockBrakes,
            vehicleFeatures: VinHelper.addVehicleFeaturesFromVin(
              response,
              this.vehicle
            ),
          });

          this.lastViCallVin = vin;
          this.lastViCallOutcome = null;
          return null;
        }),
        catchError((error) => {
          this.changeDetector.markForCheck();
          this.lastViCallVin = vin;
          return of(
            (this.lastViCallOutcome = {
              vinValidationError:
                'VIN is not in a valid format. Please try again.',
            })
          );
        })
      );
  }
  addFormValidators(): void {
    const vin = this.form.get('vin');
    const isUtilityTrailer =
      this.vehicle.vehicleType === PERSONAL_AUTO_TRAILER_TYPE;

    vin?.addValidators([this.validateVinSync.bind(this)]);
    if (this.required) {
      vin?.addValidators([Validators.required]);
    }
    if (!isUtilityTrailer) {
      vin?.addAsyncValidators([this.validateVinAsync.bind(this)]);
    }
  }
}
