<div class="bolt-container-fluid">
  <div class="bolt-row">
    <div class="bolt-col text-center">
      <h1 class="bolt-heading-md bolt-serif bolt-heading-vibrant-blue">
        Scheduled maintenance is underway.
      </h1>
    </div>
  </div>
  <div class="bolt-row" id="backgroundImageHolder">
    <div class="bolt-col">
      <div class="pale-gray-card">
        <div class="text-center">
          <h2 class="card-heading">
            Nationwide Express&reg; will be back soon.
          </h2>
        </div>
        <div class="text-center">
          To improve your experience our systems are undergoing maintenance.
          Service will resume soon.
        </div>
        <ng-container *ngIf="startTime && endTime">
          <br />
          <div class="bolt-row">
            <div class="bolt-col-1">
              <bolt-icon name="gear" color="medium-blue"></bolt-icon>
            </div>
            <div class="bolt-col-5 bolder no-right-padding">
              Unavailable starting:
            </div>
            <div class="bolt-col no-left-padding">
              {{ startTime | date : 'medium' }}
            </div>
          </div>
          <div class="bolt-row">
            <div class="bolt-col-1">
              <bolt-icon
                name="checkmark-circle"
                color="medium-blue"
              ></bolt-icon>
            </div>
            <div class="bolt-col-5 bolder no-right-padding">
              Expected to resume:
            </div>
            <div class="bolt-col no-left-padding">
              {{ endTime | date : 'medium' }}
            </div>
          </div>
        </ng-container>
        <div class="text-center mt-16">
          <bolt-button (click)="tryAnyway()">Back to login</bolt-button>
        </div>
        <hr />
        <div class="text-center">Call for assistance: 1-888-667-3866</div>
      </div>
    </div>
  </div>
</div>
