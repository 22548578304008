import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UserService } from '@core/services/user.service';
import { ComponentChanges } from '@shared/utils/general.utils';
import { trigger, transition, animate, style } from '@angular/animations';
import { Nullable } from '@shared/utils/type.utils';
import { ModalService } from '@shared/services/modal.service';
@Component({
  selector: 'nwx-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  // transitions can't be applied to elements not present in the dom (#menuElement).
  // :enter and :leave trigger on the element appearing and disappearing from the dom
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('250ms', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [
        animate('250ms', style({ transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
})
export class ProfileMenuComponent implements OnDestroy, OnChanges {
  @Input() userName = '';
  @Input() agentCenterUrl = ''; // blank to hide link
  @Input() producerCode = ''; // blank to hide section
  @Input() producerName = '';
  @Input() maxSizeXSmall!: Nullable<boolean>;
  @ViewChild('buttonElement') buttonElement: ElementRef | null = null;
  @ViewChild('menuElement') menuElement: ElementRef | null = null;

  menuOpen = false;
  userNameForToggleButton = '';
  private mousetrapHandler: ((event: Event) => void) | null = null;
  hoverStyling: boolean = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private window: Window,
    private userService: UserService,
    private modalService: ModalService
  ) {}

  ngOnDestroy(): void {
    this.unregisterMousetrap();
  }

  ngOnChanges(changes: ComponentChanges<ProfileMenuComponent>): void {
    if (changes.userName) {
      this.userNameForToggleButton = this.userName.split(/\s+/)[0];
    }
  }

  toggleMenu(open?: boolean): void {
    if (typeof open === 'boolean') {
      if (this.menuOpen === open) {
        return;
      }
      this.menuOpen = open;
    } else {
      this.menuOpen = !this.menuOpen;
    }
    if (this.menuOpen) {
      this.registerMousetrap();
    } else {
      this.unregisterMousetrap();
    }
    this.changeDetector.markForCheck();
  }

  addHover() {
    this.hoverStyling = true;
  }
  removeHover() {
    if (!this.menuOpen) {
      this.hoverStyling = false;
    }
  }

  onLogOut(): void {
    this.userService.logout();
    this.toggleMenu(false);
  }

  openAnnouncementOverlay() {
    this.toggleMenu(false);
    this.modalService.announcementModal();
  }
  private registerMousetrap(): void {
    if (this.mousetrapHandler) {
      return;
    }
    this.mousetrapHandler = (event: Event) => {
      if (this.mouseEventIsInMenu(event)) {
        // pass
      } else {
        this.menuOpen = false;
        this.changeDetector.detectChanges();
        event.stopPropagation();
        event.preventDefault();
        this.unregisterMousetrap();
      }
    };
    this.window.addEventListener('click', this.mousetrapHandler, {
      capture: true,
    });
    this.window.addEventListener('touchstart', this.mousetrapHandler, {
      capture: true,
    });
  }

  private unregisterMousetrap(): void {
    if (this.mousetrapHandler) {
      this.window.removeEventListener('click', this.mousetrapHandler, {
        capture: true,
      });
      this.window.removeEventListener('touchstart', this.mousetrapHandler, {
        capture: true,
      });
      this.mousetrapHandler = null;
    }
  }

  private mouseEventIsInMenu(event: Event): boolean {
    let target = event.target;
    for (; target; target = (target as Node).parentNode) {
      if (target === this.buttonElement?.nativeElement) {
        return true;
      }
      if (target === this.menuElement?.nativeElement) {
        return true;
      }
    }
    return false;
  }
}
