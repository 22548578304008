import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { map, of, mergeMap, catchError, from } from 'rxjs';
import * as fromActions from './vehicle-inquiry.action';
import { VehicleInquiryService } from '@core/services/vehicle-inquiry.service';
import { VehicleActions, VehicleInquiryActions } from '@core/store/actions';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { PicklistItem } from '@shared/models/picklist.model';
import { RetrieveVehicleSeriesRatesOdbiResponse } from '@core/models/api/vehicle-inquiry.model';
import { ProductUtils } from '@shared/utils/product.util';

@Injectable({
  providedIn: 'root',
})
export class VehicleInquiryEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private vehicleInquiryService: VehicleInquiryService,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  retrieveAndStoreYears$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.retrieveAndStoreYears),
      map((action) => {
        return {
          inquiryPath: action.inquiryPath,
          correlationId: action.correlationId,
        };
      }),
      mergeMap(({ inquiryPath, correlationId }) => {
        return this.vehicleInquiryService.dispatchRetrieveAndStoreYears().pipe(
          map((response) => {
            const years = response.retrieveVehicleModelYearsResponse.modelYear;
            return years.filter((year) => !!year).map((year) => {
              return {
                displayName: year.toString(),
                value: year.toString(),
              } as PicklistItem;
            });
          }),
          mergeMap((response) =>
            of(
              VehicleInquiryActions.setVehicleInquiryYears({
                response,
                inquiryPath,
                correlationId,
              })
            )
          ),
          catchError((error) => {
            const saneError = this.errorSanitizerService.sanitizeError(
              error,
              ProductUtils.productFromInquiryPath(inquiryPath)
            );
            return of(
              VehicleInquiryActions.setVehicleInquiryYearsError({
                inquiryPath,
                correlationId,
              })
            );
          })
        );
      })
    )
  );

  dispatchSetVehicleInquiryMakes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.dispatchSetVehicleInquiryMakes),
      map((action) => {
        return {
          year: action.year,
          inquiryPath: action.inquiryPath,
          correlationId: action.correlationId,
        };
      }),
      mergeMap(({ year, inquiryPath, correlationId }) => {
        return this.vehicleInquiryService
          .dispatchRetrieveAndStoreMakes(year?.toString() || '')
          .pipe(
            map((response) => {
              const makes =
                response.retrieveVehicleModelMakesResponse.vehicleMake;
              return makes.map((make) => {
                return {
                  displayName: make.makeDescription,
                  value: make.make,
                } as PicklistItem;
              });
            }),
            mergeMap((response) =>
              of(
                VehicleInquiryActions.setVehicleInquiryMakes({
                  response: response,
                  year,
                  inquiryPath,
                  correlationId,
                })
              )
            ),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                ProductUtils.productFromInquiryPath(inquiryPath)
              );
              return of(
                VehicleInquiryActions.setVehicleInquiryMakesError({
                  inquiryPath,
                  correlationId,
                })
              );
            })
          );
      })
    )
  );

  dispatchSetVehicleInquiryModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.dispatchSetVehicleInquiryModels),
      map((action) => {
        return {
          year: action.year,
          make: action.make,
          correlationId: action.correlationId,
        };
      }),
      mergeMap(({ year, make, correlationId }) => {
        return this.vehicleInquiryService
          .dispatchRetrieveAndStoreModels(year?.toString() || '', make, 'auto')
          .pipe(
            map((response) => {
              const models =
                response.retrieveVehicleModelsWithDescriptionResponse
                  .modelWithDescription;
              return models.map((model) => {
                return {
                  displayName: model.model,
                  value: model.modelDescription,
                } as PicklistItem;
              });
            }),
            mergeMap((response) =>
              of(
                VehicleInquiryActions.setVehicleInquiryModels({
                  year,
                  make,
                  response: response,
                  inquiryPath: 'auto',
                  correlationId,
                })
              )
            ),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(error, 'PersonalAuto');
              return of(
                VehicleInquiryActions.setVehicleInquiryModelsError({
                  inquiryPath: 'auto',
                  correlationId,
                })
              );
            })
          );
      })
    )
  );

  dispatchSetVehicleInquirySeries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.dispatchSetVehicleInquiryRates),
      map((action) => {
        return {
          vin: action.vin,
          year: action.year,
          make: action.make,
          model: action.model,
          inquiryPath: action.inquiryPath,
          correlationId: action.correlationId,
        };
      }),
      mergeMap(({ vin, year, make, model, inquiryPath, correlationId }) => {
        return this.vehicleInquiryService
          .dispatchRetrieveAndStoreRates(year?.toString() || '', make, model)
          .pipe(
            mergeMap((response) => {
              const actions: Action[] = [];
              actions.push(
                VehicleInquiryActions.setVehicleInquiryRates({
                  year,
                  make,
                  model,
                  response,
                  inquiryPath,
                  correlationId,
                })
              );
              const series = this.findVehicleSeriesInViSeriesResponse(response, vin);
              if (series) {
                actions.push(
                  VehicleActions.setVehicleSeriesByVin({ vin, series })
                );
              }
              return from(actions);
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                ProductUtils.productFromInquiryPath(inquiryPath)
              );
              return of(
                VehicleInquiryActions.setVehicleInquirySeriesError({
                  correlationId,
                })
              );
            })
          );
      })
    )
  );

  private findVehicleSeriesInViSeriesResponse(
    response: RetrieveVehicleSeriesRatesOdbiResponse,
    vin: string
  ): string {
    if (!vin) {
      return '';
    }
    const model = response.retrieveVehicleSeriesRatesOdbiResponse.vehicleModel.find(r => {
      if (!r.vehicleIdentificationPattern) {
        return false;
      }
      return this.vinMatchesPattern(vin, r.vehicleIdentificationPattern);
    });
    return model?.seriesDescription || '';
  }

  private vinMatchesPattern(vin: string, pattern: string): boolean {
    if (vin === pattern) {
      // (vin) might be a pattern itself.
      return true;
    }
    if (vin.length < pattern.length) {
      return false;
    }
    for (let i=0; i < pattern.length; i++) {
      if (pattern[i] !== '&') {
        if (vin[i] !== pattern[i]) {
          return false;
        }
      }
    }
    return true;
  }
}
