import { MemberModel } from '@core/models/views/person.model';
import { createSelector } from '@ngrx/store';
import { GeneralUtils } from '@shared/utils/general.utils';
import { getAllFeatureFlags } from '../../feature-flag/feature-flag.selector';
import { MemberEntity } from '../../member/member.reducer';
import {
  getAllSelectedMemberModels,
  getPrimaryNamedInsured,
} from '../../member/member.selector';
import {
  getOverallFurthestQuoteStatus,
  getSelectedProductTypes,
  getSelectedProducts,
} from '../../product/product.selectors';
import { TaskModel } from '../task.model';
import { TaskUtils } from '../utils/task.util';
import { MemberTaskUtils } from '../utils/member-task.utils';
import {
  selectAllTasksByTaskEntityType,
  selectIncompleteTasksByTaskEntityType,
} from './task.selector';
import { getTelematicsEnrollment } from '@entities/telematics/telematics.action';
import { getCurrentEnrollment } from '@entities/telematics/telematics.selector';

export const getMemberTasksForMember = (memberEntityId: string) =>
  createSelector(
    selectIncompleteTasksByTaskEntityType(['member', 'person', 'people']),
    (tasks) => tasks.filter((task) => task.entityId === memberEntityId)
  );

export const generateMemberTasks = createSelector(
  getAllSelectedMemberModels,
  getAllFeatureFlags,
  getOverallFurthestQuoteStatus,
  getSelectedProductTypes,
  (members, features, quoteStatus, selectedProductTypes) =>
    MemberTaskUtils.generateMemberTasks(
      members,
      features,
      quoteStatus || 'Draft',
      selectedProductTypes
    ).map((task: TaskModel) => {
      const person = members.find(
        (p) => p.entityId === task?.entityId
      );
      return {
        ...task,
        entityFriendlyId: person?.person?.firstName,
        elementId: person
          ? GeneralUtils.createPersonElementId(person.entityId, task.elementId)
          : task.elementId,
      } as TaskModel;
    })
);

export const updateMemberTasks = createSelector(
  selectAllTasksByTaskEntityType(['member', 'person', 'people']),
  generateMemberTasks,
  (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
);

export const generatePNITasks = createSelector(
  getPrimaryNamedInsured,
  getAllFeatureFlags,
  getOverallFurthestQuoteStatus,
  getSelectedProducts,
  (pni, features, quoteStatus, selectedProductModels) => {
    if (!pni) return [];
    return MemberTaskUtils.generatePNITasks(
      pni as MemberEntity,
      features,
      quoteStatus || 'Draft',
      selectedProductModels
    );
  }
);

export const updatePNITasks = createSelector(
  selectAllTasksByTaskEntityType(['pni']),
  generatePNITasks,
  (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
);
