import { TelematicsRecommendationResponse } from '@core/models/api/response/telematics-recommendation-response.model';
import { TelematicsUtils } from '@shared/utils/telematics.utils';
import { createReducer, Action, on } from '@ngrx/store';
import {
  VehicleEnrollment,
  MobileEnrollment,
  ExtendedTelematicsVehicle,
} from './telematics.model';
import {
  TelematicsActions,
  RetrieveActions,
  SessionActions,
} from '@core/store/actions';
import { TelematicsEnrollmentResponse } from '@core/models/api/response/telematics-enrollment-response.model';

export interface TelematicsEnrollmentsState {
  recommendation?: TelematicsRecommendationResponse;
  enrollment?: VehicleEnrollment | MobileEnrollment;
  vehicles: ExtendedTelematicsVehicle[];
  loaded: boolean;
  loading: boolean;
  confirmed: boolean;
  reviewed: boolean;
  excludedQuoteIds: string[];
}

const initialState: TelematicsEnrollmentsState = {
  vehicles: [],
  loaded: false,
  loading: false,
  confirmed: false,
  reviewed: false,
  excludedQuoteIds: [],
};

const telematicsReducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(TelematicsActions.getTelematicsRecommendation, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    TelematicsActions.getTelematicsRecommendationSuccess,
    (state, { payload }) => ({
      ...state,
      recommendation: { ...state.recommendation, ...payload },
    })
  ),
  on(TelematicsActions.getTelematicsRecommendationFail, (state) => ({
    ...state,
    loading: false,
  })),
  on(
    TelematicsActions.getTelematicsEnrollment,
    TelematicsActions.refreshTelematicsEnrollment,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(TelematicsActions.getTelematicsEnrollmentSuccess, (state, { payload }) => {
    return {
      ...state,
      loaded: true,
      loading: false,
      enrollment: TelematicsUtils.buildTelematicsEnrollment(payload),
    };
  }),
  on(TelematicsActions.refreshTelematicsEnrollmentSuccess, (state) => ({
    ...state,
    loaded: true,
    loading: false,
  })),
  on(
    TelematicsActions.getTelematicsEnrollmentFail,
    TelematicsActions.refreshTelematicsEnrollmentFail,
    (state) => ({
      ...state,
      loading: false,
    })
  ),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    if (payload.productType !== 'PersonalAuto') {
      return {
        ...state,
      };
    }

    const { telematicsEnrollments } = payload.response;
    if (!telematicsEnrollments) {
      return {
        ...state,
      };
    }

    return {
      ...state,
      enrollment: TelematicsUtils.buildTelematicsEnrollment(
        telematicsEnrollments[0]
      ),
    };
  }),
  on(TelematicsActions.addTelematicsEnrollment, (state) => ({
    ...state,
    loading: true,
  })),
  on(TelematicsActions.addTelematicsEnrollmentSuccess, (state, { payload }) => {
    let enrollment: MobileEnrollment | VehicleEnrollment | undefined;
    if (payload?.mobileEnrollment) {
      enrollment = {
        enrollmentId: payload.enrollmentId,
        mobileEnrollment: payload.mobileEnrollment,
        enrollmentConsents: payload.enrollmentConsents,
      };
    }
    if (payload?.vehicleEnrollment) {
      enrollment = {
        enrollmentId: payload.enrollmentId,
        vehicles: payload.vehicleEnrollment.vehicles,
        enrollmentConsents: payload.vehicleEnrollment.enrollmentConsents,
      };
    }
    return {
      ...state,
      loaded: true,
      loading: false,
      enrollment,
    };
  }),
  on(TelematicsActions.addTelematicsEnrollmentFail, (state) => ({
    ...state,
    loading: false,
  })),
  on(TelematicsActions.updateTelematicsEnrollment, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    TelematicsActions.updateTelematicsEnrollmentSuccess,
    (state, { payload }) => {
      let enrollment: MobileEnrollment | VehicleEnrollment | undefined;
      if (payload?.mobileEnrollment) {
        enrollment = {
          enrollmentId: payload.enrollmentId,
          mobileEnrollment: payload.mobileEnrollment,
          enrollmentConsents: {
            ...payload.enrollmentConsents,
            ...state.enrollment?.enrollmentConsents,
          },
        };
      }
      if (payload?.vehicleEnrollment) {
        enrollment = {
          enrollmentId: payload.enrollmentId,
          vehicles: payload.vehicleEnrollment.vehicles,
          enrollmentConsents: {
            ...payload.vehicleEnrollment.enrollmentConsents,
            ...payload.enrollmentConsents,
            ...state.enrollment?.enrollmentConsents,
          },
        };
      }
      return {
        ...state,
        loaded: true,
        loading: false,
        enrollment,
      };
    }
  ),
  on(TelematicsActions.updateTelematicsEnrollmentFail, (state) => ({
    ...state,
    loading: false,
  })),
  on(
    TelematicsActions.removeVehicleFromTelematicsSuccess,
    (state, { payload }) => {
      let enrollment: MobileEnrollment | VehicleEnrollment | undefined;
      if (payload?.mobileEnrollment) {
        enrollment = {
          enrollmentId: payload.enrollmentId,
          mobileEnrollment: payload.mobileEnrollment,
          enrollmentConsents: {
            ...payload.enrollmentConsents,
            ...state.enrollment?.enrollmentConsents,
          },
        };
      }
      if (payload?.vehicleEnrollment) {
        enrollment = {
          enrollmentId: payload.enrollmentId,
          vehicles: payload.vehicleEnrollment.vehicles,
          enrollmentConsents: {
            ...payload.vehicleEnrollment.enrollmentConsents,
            ...payload.enrollmentConsents,
            ...state.enrollment?.enrollmentConsents,
          },
        };
      }
      return {
        ...state,
        loaded: true,
        enrollment,
      };
    }
  ),
  on(TelematicsActions.updateTelematicsTextNotification, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    TelematicsActions.updateTelematicsTextNotificationSuccess,
    (state, { payload }) => {
      let enrollment: MobileEnrollment | VehicleEnrollment | undefined;
      if (payload?.mobileEnrollment) {
        enrollment = {
          enrollmentId: payload.enrollmentId,
          mobileEnrollment: payload.mobileEnrollment,
          enrollmentConsents: {
            ...payload.enrollmentConsents,
            ...state.enrollment?.enrollmentConsents,
          },
        };
      }
      if (payload?.vehicleEnrollment) {
        enrollment = {
          enrollmentId: payload.enrollmentId,
          vehicles: payload.vehicleEnrollment.vehicles,
          enrollmentConsents: {
            ...payload.vehicleEnrollment.enrollmentConsents,
            ...payload.enrollmentConsents,
            ...state.enrollment?.enrollmentConsents,
          },
        };
      }
      return {
        ...state,
        loaded: true,
        loading: false,
        enrollment,
      };
    }
  ),
  on(TelematicsActions.removeTelematicsEnrollmentSuccess, (state) => {
    return { ...state, enrollment: undefined };
  }),
  on(TelematicsActions.addTelematicsEnrollmentFail, (state) => {
    return { ...state, loaded: true };
  }),
  on(TelematicsActions.updateTelematicsConfirmation, (state, { payload }) => {
    return { ...state, confirmed: payload };
  }),
  on(TelematicsActions.updateTelematicsReviewed, (state, { payload }) => {
    return { ...state, reviewed: payload };
  }),
  on(TelematicsActions.updateStoreTelematicsVehicle, (state, { payload }) => {
    return {
      ...state,
      vehicles: [
        ...state.vehicles.filter(
          (v) => v.vehicleId?.toString() !== payload.vehicleId?.toString()
        ),
        payload,
      ],
    };
  }),
  on(TelematicsActions.excludeQuoteFromTelematics, (state, { quoteId }) => {
    if (state.excludedQuoteIds.includes(quoteId)) {
      return state;
    } else {
      return {
        ...state,
        excludedQuoteIds: [...state.excludedQuoteIds, quoteId],
      };
    }
  }),
  on(TelematicsActions.clearTelematicsExcludedQuotes, (state) => ({
    ...state,
    excludedQuoteIds: [],
  })),
  on(TelematicsActions.updateRecommendationForVehicle, (state) => ({
    ...state,
    loading: true,
  })),
  on(TelematicsActions.clearTelematicsLoading, (state) => ({
    ...state,
    loading: false,
  }))
);

export function reducer(
  state: TelematicsEnrollmentsState | undefined,
  action: Action
): TelematicsEnrollmentsState {
  return telematicsReducer(state, action);
}
