import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { VehicleEntity } from '@core/store/entities/vehicle/vehicle.entity';
import { PremiumEntity } from '@core/store/entities/premium/premium.entity';
import { CoverageEntity } from '@core/store/entities/coverage/coverage.entity';
import { ProductModel } from '@core/store/entities/product/product.model';
import { CoveredLocationEntity } from '@core/store/entities/covered-location/covered-location.reducer';
import { Nullable } from '@shared/utils/type.utils';
import {
  VehicleEnrollment,
  MobileEnrollment,
} from '@core/store/entities/telematics/telematics.model';
import { Observable } from 'rxjs';
import { CoveragesService } from '@core/services/coverages.service';
import { CoveredLocationService } from '@core/services/covered-location.service';
import { ProductsService } from '@core/services/products.service';
import { TelematicsService } from '@core/services/telematics.service';
import { VehicleService } from '@core/services/vehicle.service';
import { PremiumService } from '@core/services/premium.service';
import { Dictionary } from '@ngrx/entity';
import { LayoutService } from '@core/services/layout.service';

@Component({
  selector: 'nwx-coverage-display-container',
  templateUrl: './coverage-display-container.component.html',
  styleUrls: ['./coverage-display-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageDisplayContainerComponent {
  @Output() editTelematicsClicked = new EventEmitter();

  vehicles$: Observable<VehicleEntity[]>;
  premiums$: Observable<PremiumEntity[]>;
  coverages$: Observable<CoverageEntity[]>;
  products$: Observable<ProductModel[]>;
  coveredLocations$: Observable<Nullable<Dictionary<CoveredLocationEntity>>>;
  enrollment$: Observable<Nullable<VehicleEnrollment | MobileEnrollment>>;
  maxSizeMini$: Observable<boolean>;
  constructor(
    private vehicleService: VehicleService,
    private premiumService: PremiumService,
    private coverageService: CoveragesService,
    private productsService: ProductsService,
    private coveredLocationService: CoveredLocationService,
    private telematicsService: TelematicsService,
    private layoutService: LayoutService
  ) {
    this.vehicles$ = this.vehicleService.getAllVehicles();
    this.premiums$ = this.premiumService.getAllPremiums();
    this.coverages$ = this.coverageService.getAllQuoteCoveragesByVersion();
    this.products$ = this.productsService.getSelectedProducts();
    this.coveredLocations$ =
      this.coveredLocationService.getAllCoveredLocations();
    this.enrollment$ = this.telematicsService.getCurrentEnrollment();
    this.maxSizeMini$ = this.layoutService.maxSizeMini();
  }

  onTelematicsClicked(): void {
    this.editTelematicsClicked.emit();
  }
}
