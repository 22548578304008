import { Directive } from '@angular/core';

@Directive({
  selector: '[nwxIsAuthorized]'
})
export class IsAuthorizedDirective {

  constructor() { }

}
