import { Injectable } from '@angular/core';
import { TaskProductType } from '@core/models/api/dsm-types';
import { RVSubtype } from '@core/models/views/vehicle.model';
import { TaskModel } from '@core/store/entities/task/task.model';
import { CoverageEntity } from '@entities/coverage/coverage.entity';
import { VehicleEntity } from '@entities/vehicle/vehicle.entity';
import { CoverageUtils } from '@shared/utils/coverage.utils';
import { DateUtils } from '@shared/utils/date.utils';

export class TaskHelper {
  static readonly MODAL_AVAILABILITY_TASK_FIELD_NAMES = [
    'primaryUse',
    'vehicleType',
  ];
  static readonly MODAL_PRODUCER_CODE_TASK_FIELD_NAMES = ['producerCode'];
  static readonly MODAL_EFFECTIVE_DATE_TASK_FIELD_NAMES = ['effectiveDate'];
  static readonly MODAL_PNI_TASK_FIELD_NAMES = ['emailAddress', 'homeNumber'];

  static readonly MODAL_MEMBER_TASK_FIELD_NAMES = [
    'maritalStatus',
    'relationToPrimaryNamedInsured',
    'licenseNumber',
    'licenseState',
  ];

  static readonly MODAL_VEHICLE_TASK_FIELD_NAMES = [
    'vin',
    'hin',
    'annualMiles',
  ];

  static readonly MODAL_PROPERTY_TASK_FIELD_NAMES = ['acknowledgement', 'roofYear'];

  static readonly MODAL_PRODUCT_TASK_FIELD_NAMES = [
    ...this.MODAL_VEHICLE_TASK_FIELD_NAMES,
    ...this.MODAL_PROPERTY_TASK_FIELD_NAMES,
  ];

  static readonly MODAL_TASK_FIELD_NAMES = [
    ...this.MODAL_EFFECTIVE_DATE_TASK_FIELD_NAMES,
    ...this.MODAL_PNI_TASK_FIELD_NAMES,
    ...this.MODAL_MEMBER_TASK_FIELD_NAMES,
    ...this.MODAL_VEHICLE_TASK_FIELD_NAMES,
    ...this.MODAL_PROPERTY_TASK_FIELD_NAMES,
  ];

  static readonly MODAL_PRODUCTS_WITH_VEHICLES = [
    'PersonalAuto',
    'MSA',
    'Boat',
    'RV',
  ];

  static readonly PROPERTY_PRODUCT_TYPES = [
    'Homeowner',
    'Condominium',
    'Tenant',
  ];

  static getProductTaskTypes(tasks: TaskModel[]): TaskProductType[] {
    const productTypes: TaskProductType[] = [];
    tasks.forEach((task) => {
      if (this.MODAL_PRODUCT_TASK_FIELD_NAMES.includes(task.field)) {
        productTypes.push(task.productType);
      }
    });
    return [...new Set(productTypes)];
  }

  static getModalTasksByGrouping(
    tasks: TaskModel[],
    grouping: string
  ): TaskModel[] {
    let fieldNames: string[] = [];
    switch (grouping) {
      case 'Availability':
        fieldNames = TaskHelper.MODAL_AVAILABILITY_TASK_FIELD_NAMES;
        break;
      case 'EffectiveDate':
        fieldNames = TaskHelper.MODAL_EFFECTIVE_DATE_TASK_FIELD_NAMES;
        break;
      case 'PNI':
        fieldNames = TaskHelper.MODAL_PNI_TASK_FIELD_NAMES;
        break;
      case 'Member':
        fieldNames = TaskHelper.MODAL_MEMBER_TASK_FIELD_NAMES;
        break;
      case 'PersonalAuto':
      case 'MSA':
      case 'Boat':
      case 'RV':
        fieldNames = TaskHelper.MODAL_VEHICLE_TASK_FIELD_NAMES;
        break;
      case 'ProducerCode':
        fieldNames = TaskHelper.MODAL_PRODUCER_CODE_TASK_FIELD_NAMES;
        break;
      case 'Property':
        fieldNames = TaskHelper.MODAL_PROPERTY_TASK_FIELD_NAMES;
        break;
    }

    if (TaskHelper.MODAL_PRODUCTS_WITH_VEHICLES.includes(grouping)) {
      return tasks.filter(
        (task) =>
          fieldNames.includes(task.field) && task.productType === grouping
      );
    } else {
      let filteredTasks = tasks.filter((task) =>
        fieldNames.includes(task.field)
      );
      filteredTasks =
        TaskHelper.removeUnhandledMaritalStatusTasks(filteredTasks);
      return filteredTasks;
    }
  }

  private static removeUnhandledMaritalStatusTasks(tasks: TaskModel[]) {
    const unhandledMaritalStatusTasks = tasks.filter(
      (task) =>
        task.field === 'maritalStatus' &&
        task.message !== 'Please indicate marital status'
    );
    tasks = tasks.filter((task) => !unhandledMaritalStatusTasks.includes(task));
    return tasks;
  }

  static findApplicableVehicleForReceiptTask(
    vehicles: VehicleEntity[],
    coverages: CoverageEntity[]
  ): VehicleEntity | undefined {
    return vehicles.find((vehicle) => {
      // Condition 1: PDSO coverage is Agreed Value
      const taskAppliesByPDSO = TaskHelper.pdsoCovHasAgreedValue(
        coverages,
        vehicle
      );
      if (taskAppliesByPDSO) {
        // Condition 2: Vehicle is greater than 5 years old
        const vehicleOlderThan5 =
          DateUtils.yearWithinXYearsOfNow(+(vehicle.year || '0') || 0, 5) ===
          false;
        if (vehicleOlderThan5) {
          // All conditions pass: Task needed:
          return true;
        } else {
          return TaskHelper.taskAppliesForVehicleYoungerThan5(
            coverages,
            vehicle
          );
        }
      }
      return false;
    });
  }

  static pdsoCovHasAgreedValue(
    coverages: CoverageEntity[],
    vehicle: VehicleEntity
  ): boolean {
    const pdsoCoverage = CoverageUtils.getCoverageById(
      coverages,
      'PDSO',
      vehicle.vehicleId
    );
    const agreedValueSelected = CoverageUtils.selectedValueDescEquals(
      pdsoCoverage,
      { code: 'PDSOOption', description: 'Agreed Value' }
    );
    return agreedValueSelected;
  }

  static taskAppliesForVehicleYoungerThan5(
    coverages: CoverageEntity[],
    vehicle: VehicleEntity
  ): boolean {
    // Alternate condition 2: Market value above 100000
    const marketValue = vehicle.marketValue || 0;
    if (marketValue > 100000) {
      // Condition 3: Vehicle subtype is NOT Medium Duty Tow or Vintage travel trailer or Vintage motorhome
      if (this.taskAppliesBySubType(vehicle)) {
        // Condition 4: Comp is selected
        const comp = CoverageUtils.getCoverageById(
          coverages,
          'COMP',
          vehicle.vehicleId
        );
        const compSelected = CoverageUtils.isCoverageSelected(comp);
        if (compSelected) {
          // All conditions pass: Task needed:
          return true;
        }
      }
    }
    return false;
  }

  static taskAppliesBySubType(vehicle: VehicleEntity): boolean {
    const vehicleSubType = vehicle.subType as RVSubtype;
    const nonTaskSubTypes: RVSubtype[] = [
      'MediumDutyTow',
      'VintageTT',
      'VintageMH',
    ];
    return nonTaskSubTypes.includes(vehicleSubType) === false;
  }
}
