import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonFormModule } from '@shared/modules/common-form.module';
import { SharedComponentsModule } from '@shared/components/shared-components.module';
import { CommonModule } from '@angular/common';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { PassiveSpinnerModule } from '@shared/components/passive-spinner/passive-spinner.module';
import { AccountRegistrationComponent } from './account-registration.component';

@NgModule({
  declarations: [AccountRegistrationComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    CommonFormModule,
    SharedComponentsModule,
    SharedDirectivesModule,
    PassiveSpinnerModule,
  ],
  exports: [AccountRegistrationComponent],
})
export class AccountRegistrationModule {}
