import { Component, ChangeDetectionStrategy } from '@angular/core';
import {
  DiscountHelpText,
  DiscountsAvailabilityHelpText,
} from '@shared/constants/discounts-help-text-constants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductModel } from '@core/store/entities/product/product.model';

interface HelpText {
  name: string;
  description: string;
}

@Component({
  selector: 'nwx-discounts-info-modal',
  templateUrl: './discounts-info-modal.component.html',
  styleUrls: ['./discounts-info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountsInfoModalComponent {
  // products must be assigned by whoever creates the NgbModal:
  products!: ProductModel[];

  agentDisclaimer = DiscountsAvailabilityHelpText.AGENT;

  discountInformation = DiscountHelpText;

  constructor(public modal: NgbActiveModal) {}

  closeModal(): void {
    this.modal.close();
  }
}
