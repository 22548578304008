import { Injectable } from '@angular/core';
import { ProductsService } from '@core/services/products.service';
import { TaskService } from '@core/services/task.service';
import {
  VehicleActions,
  TaskActions,
  RetrieveActions,
  ProductActions,
  DriverVehicleAssignmentActions,
  CurrentCarrierActions,
  CoverageActions,
} from '@core/store/actions';
import { retrieveQuoteSuccess } from '@core/store/retrieve/retrieve.action';
import { VehicleTaskSelectors } from '@core/store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProductUtils } from '@shared/utils/product.util';
import { switchMap, take, map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VehicleTaskEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private taskService: TaskService,
    private productsService: ProductsService
  ) {}

  updateAutoVehicleTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RetrieveActions.retrieveQuoteSuccess,
        VehicleActions.addVehicleSuccess,
        VehicleActions.updateVehicleSuccess,
        VehicleActions.storeVehicle,
        TaskActions.updateTasks
      ),
      filter((action) =>
        action.type === TaskActions.updateTasks.type
          ? true
          : action.payload.productType === 'PersonalAuto'
      ),
      switchMap(() =>
        this.productsService.getProductIfSelected('PersonalAuto').pipe(take(1))
      ),
      filter(
        (product) =>
          product?.isDsmActive === true &&
          ProductUtils.IsQuoteStarted(product.quoteStatus)
      ),
      switchMap(() =>
        this.store.select(VehicleTaskSelectors.updateAutoTasks).pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updateMSAVehicleTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RetrieveActions.retrieveQuoteSuccess,
        VehicleActions.addVehicleSuccess,
        VehicleActions.updateVehicleSuccess,
        VehicleActions.storeVehicle,
        TaskActions.updateTasks
      ),
      filter((action) =>
        action.type === TaskActions.updateTasks.type
          ? true
          : action.payload.productType === 'MSA'
      ),
      switchMap(() =>
        this.productsService.getProductIfSelected('MSA').pipe(take(1))
      ),
      filter(
        (product) =>
          product?.isDsmActive === true &&
          ProductUtils.IsQuoteStarted(product.quoteStatus)
      ),
      switchMap(() =>
        this.store.select(VehicleTaskSelectors.updateMsaTasks).pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updatePersonalAutoTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RetrieveActions.retrieveQuoteSuccess,
        ProductActions.updateProduct,
        TaskActions.updateTasks
      ),
      switchMap(() =>
        this.store
          .select(VehicleTaskSelectors.updatePersonalAutoProductTasks)
          .pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updateMsaTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        retrieveQuoteSuccess,
        ProductActions.updateProduct,
        TaskActions.updateTasks
      ),
      switchMap(() =>
        this.store
          .select(VehicleTaskSelectors.updateMsaProductTasks)
          .pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updateBoatVehicleTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        VehicleActions.addVehicleSuccess,
        VehicleActions.updateVehicleSuccess,
        VehicleActions.storeVehicle,
        TaskActions.updateTasks
      ),
      filter((action) =>
        action.type === TaskActions.updateTasks.type
          ? true
          : action.payload.productType === 'Boat'
      ),
      switchMap(() =>
        this.productsService.getProductIfSelected('Boat').pipe(take(1))
      ),
      filter(
        (product) =>
          product?.isDsmActive === true &&
          ProductUtils.IsQuoteStarted(product.quoteStatus)
      ),
      switchMap(() =>
        this.store.select(VehicleTaskSelectors.updateBoatTasks).pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updateRVVehicleTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        VehicleActions.addVehicleSuccess,
        VehicleActions.updateVehicleSuccess,
        VehicleActions.storeVehicle,
        TaskActions.updateTasks
      ),
      filter((action) =>
        action.type === TaskActions.updateTasks.type
          ? true
          : action.payload.productType === 'RV'
      ),
      switchMap(() =>
        this.productsService.getProductIfSelected('RV').pipe(take(1))
      ),
      filter(
        (product) =>
          product?.isDsmActive === true &&
          ProductUtils.IsQuoteStarted(product.quoteStatus)
      ),
      switchMap(() =>
        this.store.select(VehicleTaskSelectors.updateRVTasks).pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updateRVTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        retrieveQuoteSuccess,
        ProductActions.updateProduct,
        TaskActions.updateTasks,
        CoverageActions.updateCoverageSuccess
      ),
      switchMap(() =>
        this.productsService.getProductIfSelected('RV').pipe(take(1))
      ),
      filter((product) => product?.isDsmActive === true),
      switchMap(() =>
        this.store
          .select(VehicleTaskSelectors.updateRVProductTasks)
          .pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updateBoatTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        retrieveQuoteSuccess,
        ProductActions.updateProduct,
        TaskActions.updateTasks
      ),
      switchMap(() =>
        this.productsService.getProductIfSelected('Boat').pipe(take(1))
      ),
      filter((product) => product?.isDsmActive === true),
      switchMap(() =>
        this.store
          .select(VehicleTaskSelectors.updateBoatProductTasks)
          .pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updateDVATasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.updateTasks),
      switchMap(() =>
        this.store.select(VehicleTaskSelectors.updateDVATasks).pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updateDVATasksByProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DriverVehicleAssignmentActions.getDriverVehicleAssignmentSuccess,
        DriverVehicleAssignmentActions.addDriverVehicleAssignmentSuccess
      ),
      switchMap((action) =>
        this.store
          .select(
            VehicleTaskSelectors.updateDVATasksByProduct(action.productType)
          )
          .pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updateCurrentCarrierTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        retrieveQuoteSuccess,
        CurrentCarrierActions.getCurrentCarrierSuccess,
        CurrentCarrierActions.updateCurrentCarrierSuccess,
        CurrentCarrierActions.storeCurrentCarrier,
        TaskActions.updateTasks
      ),
      switchMap(() =>
        this.store
          .select(VehicleTaskSelectors.updateCurrentCarrierTasks)
          .pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );
}
