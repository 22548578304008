<div class="modal-header">
  <bolt-icon name="exclamation-circle-filled" color="white"></bolt-icon>
  <h4 id="confirmationModalTitle">{{ headerTitle }}</h4>
</div>
<div class="modal-body">
  <p [innerHTML]="bodyMessage"></p>
  <ng-container *ngIf="notificationType">
    <bolt-notification [attr.type]="notificationType">
      {{ notificationMessage }}
    </bolt-notification>
  </ng-container>
</div>
<div class="modal-footer">
  <bolt-button-bar *ngIf="forwardBtnText || cancelBtnText">
    <bolt-button id="cancelButton" slot="cancel" (click)="cancelBtnClicked()">
      Cancel
    </bolt-button>
    <bolt-button
      id="forwardButton"
      slot="forward"
      (click)="forwardBtnClicked()"
      type="primary"
    >
      {{ forwardBtnText }}</bolt-button
    >
  </bolt-button-bar>
</div>
