import {
  ScheduledCategoryEntity,
  sanitizeScheduledCategoryEntity,
} from '@core/store/entities/scheduled-categories/scheduled-category.entity';
import { BaseRequest } from './base-request.model';
import { ObjectValidator } from '@core/helper/object-validator';

export interface UpdateScheduledCategoryRequest extends BaseRequest {
  item: ScheduledCategoryEntity;
}
export interface UpdateScheduledCategoryResponse {
  scheduledCategories: ScheduledCategoryEntity[];
}

export interface UpdateScheduledPersonalEffectsResponse {
  scheduledPersonalEffects: ScheduledCategoryEntity[];
}
export interface UpdateScheduledPersonalEffectRequest extends BaseRequest {
  scheduledPersonalEffect: ScheduledCategoryEntity;
}

export function sanitizeUpdateScheduledCategoryResponse(
  input: unknown
): UpdateScheduledCategoryResponse {
  return ObjectValidator.forceSchema<UpdateScheduledCategoryResponse>(
    input,
    {
      scheduledCategories: [sanitizeScheduledCategoryEntity],
    },
    []
  );
}

export function sanitizeUpdateScheduledPersonalEffectsResponse(
  input: unknown
): UpdateScheduledPersonalEffectsResponse {
  return ObjectValidator.forceSchema<UpdateScheduledPersonalEffectsResponse>(
    input,
    {
      scheduledPersonalEffects: [sanitizeScheduledCategoryEntity],
    },
    []
  );
}
