import { ProductHelper } from '@core/helper/product.helper';
import { ProductType } from '@core/models/api/dsm-types';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { ErrorModel } from '@core/store/entities/error/error.model';
import {
  selectCurrentPage,
  selectEnabledPages,
} from '@core/store/entities/navigation/navigation.selector';
import * as fromCore from '@core/store/reducers';
import { getSelectedProductTypes } from '@entities/product/product.selectors';
import { createSelector } from '@ngrx/store';

export const getErrorState = createSelector(
  fromCore.getCoreState,
  (state) => state.error
);

export const getErrorIds = createSelector(getErrorState, (state) => state.ids);

export const getErrors = createSelector(
  getErrorState,
  (state) => Object.values(state.entities) as ErrorModel[]
);

export const getErrorsSeverityUnset = createSelector(getErrors, (errors) =>
  errors.filter((e) => e.severity == undefined)
);

export const getConvertedErrors = (severity?: string, category?: string) =>
  createSelector(
    getErrors,
    (errors) =>
      errors.filter((e) => {
        const matchesSeverity =
          !severity || (e.severity && e.severity.toLowerCase() == severity);
        const matchesCategory =
          !category || (e.category && e.category.toLowerCase() == category);
        return matchesSeverity && matchesCategory;
      }) as ErrorModel[]
  );

// filter out multiple quote blockers if they exist for a single product
export const getDisplayableErrors = createSelector(
  getErrorsSeverityUnset,
  getConvertedErrors('error'),
  getConvertedErrors('nwx-error'),
  getSelectedProductTypes,
  (errors, convertedErrors, nwxErrors, types) =>
    errors
      .concat(convertedErrors, nwxErrors)
      ?.reduce((errors: ErrorModel[], currentError: ErrorModel) => {
        if (
          !currentError.productType ||
          (currentError.productType as any) === 'Account'
        ) {
          // Won't have a productType, but we always want to display createAccount failures.
          return [...errors, currentError];
        }
        const isQuoteBlocker = ProductHelper.isQuoteBlockingError(currentError);
        const quoteBlockerExistsForProduct =
          ErrorHelper.quoteBlockerExistsForProduct(
            errors,
            currentError.productType as ProductType
          );
        const forSelectedProduct =
          types.includes(currentError.productType) || false;
        if (
          forSelectedProduct &&
          (!isQuoteBlocker || !quoteBlockerExistsForProduct)
        ) {
          return [...errors, currentError];
        } else {
          return errors;
        }
      }, [])
);

export const getValidationErrors = createSelector(
  getErrorState,
  (state) => state.validationErrors
);

export const getPageValidationErrors = (pageName: string) =>
  createSelector(getValidationErrors, (errs) =>
    errs.filter((err) => err.pageName === pageName)
  );

export const getFormValidationErrors = (formName: string) =>
  createSelector(selectCurrentPage, getValidationErrors, (page, errs) =>
    errs.find((err) => err.pageName === page?.name && err.formName === formName)
  );

export const getCurrentPageValidationErrors = createSelector(
  selectCurrentPage,
  getValidationErrors,
  (page, errs) => errs.filter((err) => err.pageName === page?.name)
);

export const getPageValidationState = createSelector(
  selectEnabledPages,
  getValidationErrors,
  (pages, errors) => {
    const output = {} as { [key: string]: boolean };
    pages
      .map((page) => page.name)
      .map((name): [string, boolean] => [
        name,
        errors.filter((err) => err.pageName === name)?.length > 0,
      ])
      .forEach(([page, hasErrors]) => (output[page] = hasErrors));
    return output;
  }
);

export const getRenovationFormValidationState = createSelector(
  selectEnabledPages,
  getValidationErrors,
  (pages, errors) => {
    const output = {} as { [key: string]: boolean };
    pages
      .map((page) => page.name)
      .map((name): [string, boolean] => [
        name,
        errors.filter(
          (err) => err.pageName === name && err.formName === 'renovations-list'
        )?.length > 0,
      ])
      .forEach(([page, hasErrors]) => (output[page] = hasErrors));

    return output;
  }
);

export const getUnusedId = createSelector(
  getErrorState,
  (state) => Math.max(0, ...(state.ids as number[])) + 1
);

export const hasMsbRetrievalError = createSelector(getErrors, (errors) =>
  errors.some((error) =>
    error.displayMessage?.includes(ErrorSanitizerService.MSB_NOHIT_PHRASE)
  )
);

class ErrorHelper {
  static quoteBlockerExistsForProduct(
    errors: ErrorModel[],
    productType: ProductType
  ): boolean {
    return errors.some(
      (e) =>
        ProductHelper.isQuoteBlockingError(e) && e.productType === productType
    );
  }
}
