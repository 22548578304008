export interface EligibleVehiclesModel {
    year: number,
    make: string
  }

export const eligibleVehicles: EligibleVehiclesModel[] = [
  { 
    year: 2015,
    make: 'GMC' 
  },
  {
    year: 2016,
    make: 'BUIC',
  },
  {
    year: 2016,
    make: 'CADI',
  },

  {
    year: 2016,
    make: 'CHEV',
  },

  {
    year: 2016,
    make: 'OLDS',
  },

  {
    year: 2016,
    make: 'PONT',
  },
  {
    year: 2016,
    make: 'GENE',
  },
  {
    year: 2018,
    make: 'HOND',
  },
  {
    year: 2018,
    make: 'ACUR',
  },
  {
    year: 2018,
    make: 'HYUN',
  },
  {
    year: 2018,
    make: 'GENS',
  },
  {
    year: 2018,
    make: 'TOYO',
  },
];
