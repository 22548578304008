import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  forwardRef,
  ChangeDetectorRef,
  AfterViewChecked,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { AdvancedPicklistItem } from '@core/models/advanced-picklist.model';
import { ComponentChanges } from '@shared/utils/general.utils';

@Component({
  selector: 'nwx-advanced-picklist',
  templateUrl: './advanced-picklist.component.html',
  styleUrls: ['./advanced-picklist.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdvancedPicklistComponent),
      multi: true,
    },
  ],
})
export class AdvancedPicklistComponent
  implements ControlValueAccessor, OnInit, OnChanges, AfterViewChecked
{
  constructor(private cd: ChangeDetectorRef) {}
  @Input() componentId!: string;
  @Input() labelText!: string;
  @Input() itemList: AdvancedPicklistItem[] = [];
  @Input() options: any;
  @Input() selectedItems: any = [];
  @Input() loading!: boolean;
  @Input() invalidFeedbackText!: string;
  @Input() isInvalid!: boolean | null;
  @Input() contextHelpHeading?: string;
  @Input() contextHelpText?: string;
  @Input() helpText?: string;
  @Input() placeholder: string | null = null;
  @Input() labelForId = ''; // becomes <input id>
  @Input() bindValue = '';
  @Input() clearable: boolean = true;

  @Output() itemsSelected = new EventEmitter<any>();

  settings: any = {};
  removeLabel!: boolean;
  isOpened: boolean = false;

  @ViewChild('dropdownElem', { static: true })
  dropdownElem!: NgSelectComponent;

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnInit(): void {
    this.initSettings();
  }

  ngOnChanges(changes: ComponentChanges<AdvancedPicklistComponent>): void {
    this.initSettings();
    this.cd.detectChanges();
  }

  ngAfterViewChecked(): void {
    if (this.settings?.singleSelection) {
      this.addTabIndexes();
    }
    this.cd.detectChanges();
  }

  onChangeSelection(items: any[]): void {
    if (items && !Array.isArray(items) && this.settings.singleSelection)
      items = [items];
    this.itemsSelected.emit(items);
  }

  onPicklistOpened(event: any): void {
    if (this.settings?.singleSelection) {
      this.addTabIndexes();
    }
    this.isOpened = true;
  }

  onPicklistClosed(event: any): void {
    this.isOpened = false;
  }

  writeValue(obj: any): void {
    if (obj !== this.dropdownElem.selectedValues[0]?.value) {
      this.dropdownElem.writeValue(obj);
    }
  }

  private initSettings(): void {
    if (this.options?.singleSelection) {
      this.settings = {
        singleSelection: this.options.singleSelection,
        text: this.options?.placeholderText || 'Select',
        enableSearchFilter:
          this.options?.enableSearch !== undefined
            ? this.options.enableSearch
            : true,
        enableFilterSelectAll:
          this.options?.enableFilterSelectAll !== undefined
            ? this.options.enableFilterSelectAll
            : false,
        disabled: !!this.options?.disabled,
        readonly: !!this.options?.disabled,
        classes: this.options?.classes || 'myclass custom-picklist-drpdwn',
        showCheckbox: false,
        position: this.options?.position || 'bottom',
        autoPosition: this.options?.autoPosition || false,
      };
    } else {
      this.settings = {
        singleSelection: false,
        limitSelection: this.options?.limit,
        text: this.options?.placeholderText || 'Select',
        selectAllText: this.options?.selectAllPlaceholder || 'Select All',
        unSelectAllText: this.options?.deselectAllPlaceholder || 'Deselect All',
        enableSearchFilter:
          this.options?.enableSearch !== undefined
            ? this.options.enableSearch
            : true,
        enableCheckAll:
          this.options?.enableCheckAll !== undefined
            ? this.options.enableCheckAll
            : true,
        enableFilterSelectAll:
          this.options?.enableFilterSelectAll !== undefined
            ? this.options.enableFilterSelectAll
            : true,
        disabled:
          this.options?.disabled !== undefined ? this.options.disabled : false,
        classes: this.options?.classes || 'myclass custom-picklist-drpdwn ',
        showCheckbox:
          this.options?.checkbox !== undefined ? this.options.checkbox : true,
        // eslint-disable-next-line no-magic-numbers
        badgeShowLimit: this.options?.badgeLimit || 7,
        noDataLabel: this.options?.noDataLabel || 'No Data Available',
        position: this.options?.position || 'bottom',
        autoPosition: this.options?.autoPosition || false,
      };
    }
    if (this.options?.groupBy) {
      this.settings.groupBy = 'category';
    }
    this.settings.labelKey = 'label';
    this.settings.primaryKey = 'value';
    this.removeLabel = this.options?.removeLabel || false;
    if (this.settings?.singleSelection) {
      this.addTabIndexes();
    }
  }

  private addTabIndexes(): void {
    const listElement = document.querySelector('ul.lazyContainer');
    if (listElement?.children?.length) {
      for (let i = 0; i < listElement.children.length; i++) {
        listElement.children[i].setAttribute('tabIndex', `${i}`);
      }
    }
  }
}
