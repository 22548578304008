import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  SessionActions,
  LocationExposureActions,
  RetrieveActions,
  UnderlyingPolicyActions,
} from '@core/store/actions';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { Nullable } from '@shared/utils/type.utils';
import { Dictionary } from '@ngrx/entity';
import { LocationExposureEntity } from './location-exposures.entity';
import { UmbrellaUtils } from '@shared/utils/umbrella.utils';

export interface LocationExposureState
  extends EntityState<LocationExposureEntity> {
  error?: Nullable<ErrorModel>;
  deleted?: Dictionary<LocationExposureEntity>;
}

export const adapter: EntityAdapter<LocationExposureEntity> =
  createEntityAdapter<LocationExposureEntity>({
    selectId: (locationExposure: LocationExposureEntity) =>
      locationExposure.locationExposureId,
  });

const LocationExposureReducer = createReducer(
  adapter.getInitialState({
    error: null,
    deleted: {},
  }) as LocationExposureState,

  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({
      error: null,
      deleted: {},
    } as LocationExposureState)
  ),
  on(
    LocationExposureActions.upsertLocationExposureSuccess,
    (state, { payload, oldLocationExposureId }) => {
      const locationExposureDeleted = {
        ...state.deleted,
      };

      UmbrellaUtils.findAccompanyingExposureToRemove<LocationExposureEntity>(
        locationExposureDeleted,
        payload
      );

      if (oldLocationExposureId && state.entities[oldLocationExposureId] && (oldLocationExposureId !== payload.locationExposureId)) {
        state = adapter.removeOne(oldLocationExposureId, state);
      }

      return adapter.upsertOne(payload, {
        ...state,
        deleted: locationExposureDeleted,
      });
    }
  ),
  on(LocationExposureActions.deleteLocationExposure, (state, { payload }) =>
    adapter.upsertOne(payload, state)
  ),
  on(
    LocationExposureActions.deleteLocationExposureSuccess,
    (state, { locationExposureId }) =>
      adapter.removeOne(locationExposureId, {
        ...state,
        deleted: {
          ...state.deleted,
          [locationExposureId]: state.entities[locationExposureId],
        },
      })
  ),
  on(
    LocationExposureActions.completelyRemoveLocationExposure,
    (state, { locationExposureId }) => {
      const locationExposureDeleted = {
        ...state.deleted,
      };
      delete locationExposureDeleted?.[locationExposureId];
      return {
        ...state,
        deleted: locationExposureDeleted,
      };
    }
  ),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    if (payload.productType !== 'PersonalUmbrella') {
      return state;
    }
    return adapter.upsertMany(payload.response.locationExposures, {
      ...state,
    });
  }),
  on(
    UnderlyingPolicyActions.upsertUnderlyingPolicySuccess,
    (state, { payload, existingPolicyNumber }) => {
      if (
        existingPolicyNumber &&
        !!existingPolicyNumber.length &&
        existingPolicyNumber !== payload.underlyingPolicyNumber
      ) {
        const updatedExposures: LocationExposureEntity[] = [];
        const exposuresToUpdate = Object.values(state.entities).filter(
          (exposure) =>
            exposure?.underlyingPolicyNumber === existingPolicyNumber
        );
        for (const exposure of exposuresToUpdate) {
          updatedExposures.push({
            ...exposure,
            underlyingPolicyNumber: payload.underlyingPolicyNumber,
          } as LocationExposureEntity);
        }
        return adapter.upsertMany(updatedExposures, state);
      }
      return state;
    }
  )
);

export function reducer(
  state: LocationExposureState | undefined,
  action: Action
): LocationExposureState {
  return LocationExposureReducer(state, action);
}
