import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '@core/services/header.service';

@Component({
  selector: 'nwx-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceComponent implements OnInit {
  startTime!: Date;
  endTime!: Date;

  constructor(
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private window: Window
  ) {
    this.headerService.suppressNavBar();
    this.headerService.suppressProfileComponent();
    this.headerService.suppressWelcomeMessage();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.startTime = params.startTime;
      this.endTime = params.endTime;
    });
  }
  tryAnyway(): void {
    this.window.sessionStorage.setItem("ignoreMaintenanceMode", "true");
    this.window.location.reload();
  }
}
