import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '@core/services/log.service';
import { StringUtils } from '@shared/utils/string.utils';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  PreBindDocumentEmailRequest,
  PreBindDocumentRequest,
  PreBindDocumentUploadRequest,
} from '../models/api/request/pre-bind-document-request.model';
import {
  PreBindDocumentEmailResponse,
  PreBindDocumentUploadResponse,
  sanitizePreBindDocumentEmailResponse,
  sanitizePreBindDocumentUploadResponse,
} from '../models/api/response/pre-bind-document-response.model';
import {
  PreBindDocumentEntity,
  sanitizePreBindDocumentEntity,
} from '../models/entities/pre-bind-document.entity';
import { AppConfigService } from '../services/app-config.service';
import { DsmAdapter } from './dsm.adapter';
import { ProductType } from '@core/models/api/dsm-types';

@Injectable({
  providedIn: 'root',
})
export class PreBindDocumentsAdapter {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private dsmAdapter: DsmAdapter,
    private logService: LogService
  ) {}

  getPreBindDocument(
    request: PreBindDocumentRequest,
    productType: ProductType
  ): Observable<PreBindDocumentEntity> {
    const path = `/quotes/${request.quoteId}/documents/${request.documentId}`;
    return this.dsmAdapter.request(
      sanitizePreBindDocumentEntity,
      productType,
      'GET',
      path,
      'get-pre-bind-document',
      { noLocks: true }
    );
  }

  uploadDocuments(
    request: PreBindDocumentUploadRequest,
    accessToken: string,
    sessionId: string
  ): Observable<PreBindDocumentUploadResponse> {
    const headers = this.getHeaders(accessToken, sessionId);
    const url = `${this.appConfigService.config.multiproductApi}/agency-pre-bind-documents`;
    const correlationId = this.logService.logApiRequest(
      'POST',
      url,
      'upload-pre-bind-documents',
      request
    );
    return this.httpClient
      .post(url, request, {
        headers,
      })
      .pipe(
        tap((response) => this.logService.logApiResponse(correlationId, 200)),
        map(sanitizePreBindDocumentUploadResponse),
        catchError((error) => {
          this.logService.logApiError(correlationId, error);
          return throwError(() => error);
        })
      );
  }

  email(
    request: PreBindDocumentEmailRequest,
    accessToken: string,
    sessionId: string
  ): Observable<PreBindDocumentEmailResponse> {
    const headers = this.getHeaders(accessToken, sessionId);
    const url = `${this.appConfigService.config.multiproductApi}/pre-bind-documents-emails`;
    const correlationId = this.logService.logApiRequest(
      'POST',
      url,
      'pre-bind-documents-emails'
    );
    return this.httpClient
      .post(url, request, {
        headers,
      })
      .pipe(
        tap((response) => this.logService.logApiResponse(correlationId, 200)),
        map(sanitizePreBindDocumentEmailResponse),
        catchError((error) => {
          this.logService.logApiError(correlationId, error);
          return throwError(() => error);
        })
      );
  }

  private getHeaders(
    accessToken: string,
    sessionId: string,
    isDsmCall?: boolean
  ): HttpHeaders {
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this.appConfigService.config.apiKey)
      .set('Authorization', 'Bearer ' + accessToken)
      .set('X-NW-Message-ID', StringUtils.generateUuid());

    if (isDsmCall) {
      headers = headers.set('session-id', sessionId);
      if (this.appConfigService.config.targetEnv) {
        headers = headers.set(
          'X-NW-Target-Env',
          this.appConfigService.config.targetEnv
        );
      }
    }
    return headers;
  }
}
