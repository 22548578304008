import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ProductModel } from '@core/store/entities/product/product.model';
import { Nullable } from '@shared/utils/type.utils';
import { NavigationService } from '@core/services/navigation.service';
import { StringUtils } from '@shared/utils/string.utils';
import { LogService } from '@core/services/log.service';
import { ComponentStoreService } from '@core/services/component-store.service';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { GeneralUtils } from '@shared/utils/general.utils';
import { ProductUtils } from '@shared/utils/product.util';

export interface UnsupportedProductsState {
  displayableProductsList?: string;
  icon?: string;
}
@Component({
  selector: 'nwx-unsupported-products',
  templateUrl: './unsupported-products.component.html',
  styleUrls: [
    './unsupported-products.component.scss',
    '../../../../scss/_custom-tile.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore],
})
export class UnsupportedProductsComponent implements OnInit {
  @Input() unsupportedProducts: Nullable<ProductModel[]> = [];
  @Input() pcUrl: Nullable<string> = '';

  unsupportedProductsText = '';
  showProductDetails = false;
  vm$!: Observable<UnsupportedProductsState>;

  constructor(
    private navigationService: NavigationService,
    private logService: LogService,
    private readonly componentStore: ComponentStoreService<UnsupportedProductsState>
  ) {}

  ngOnInit(): void {
    this.unsupportedProductsText = StringUtils.createProductNameList(
      this.unsupportedProducts as ProductModel[]
    );
    this.componentStore.initialize({
      displayableProductsList: this.getDisplayableProductsList(),
      icon: 'chevron-down',
    });
    this.vm$ = this.componentStore.get();
  }

  launchPcUrl(): void {
    if (this.pcUrl) {
      this.logService.logBusinessEvent('pivot-to-pc', {
        reason: 'unsupported product',
      });
      this.navigationService.openExternalPage(this.pcUrl);
    }
  }

  toggleProductDetails(): void {
    this.showProductDetails = !this.showProductDetails;
    this.componentStore.update({
      icon: this.showProductDetails ? 'chevron-up' : 'chevron-down',
    });
  }

  getDisplayableProductsList(): string {
    return GeneralUtils.formatArrayWithCommas(
      this.unsupportedProducts?.map((p) =>
        ProductUtils.productBillingName(p.type)
      ) || []
    );
  }
}
