import { createSelector } from '@ngrx/store';

import * as fromForms from '../../reducers';

export const getBillingPlansState = createSelector(
  fromForms.getFormsState,
  (state) => state.billingPlans
);

export const getBillingPlansForm = createSelector(
  getBillingPlansState,
  (state) => state.billingPlansForm
);

export const getBillingPlansFormVisited = createSelector(
  getBillingPlansState,
  (state) => state.visitedByUser
);

export const getBillingPlan = createSelector(
  getBillingPlansForm,
  (state) => state.billingPlan
);

export const getBillToEscrow = createSelector(
  getBillingPlansForm,
  (state) => state.billToEscrowAccount
);
