import { createReducer, on } from '@ngrx/store';
import { ViewportType } from './layout.entity';
import * as layoutActions from './layout.action';
export interface LayoutState {
  viewport: ViewportType;
  showMobileNav: boolean;
}

export const initialState: LayoutState = {
  viewport: 'xxlarge',
  showMobileNav: false,
};

export const reducer = createReducer(
  initialState,
  on(layoutActions.setInitialSize, (state, { payload }) => {
    return {
      ...state,
      viewport: payload.viewport,
      showMobileNav: payload.mobileNav,
    };
  }),

  on(layoutActions.updateLayout, (state, { payload }) => {
    return {
      ...state,
      viewport: payload.viewport,
      showMobileNav: payload.mobileNav,
    };
  })
);
