import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdvancedPicklistComponent } from './advanced-picklist.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule],
  declarations: [AdvancedPicklistComponent],
  exports: [AdvancedPicklistComponent],
})
export class AdvancedPicklistModule {}
