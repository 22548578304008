<form [formGroup]="form">
  <bolt-tabset class="button-tabs">
    <ng-container
      *ngFor="let vehicle of vehicles; let i = index; trackBy: trackVehicles"
    >
      <bolt-tablabel
        >{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
        <ng-container *ngIf="isVehicleFormInvalid(i)">
          <bolt-icon
            name="exclamation-circle-filled"
            size="sm"
            color="theme-error"
            class="pl-1"
          ></bolt-icon> </ng-container
      ></bolt-tablabel>
      <bolt-tabpanel>
        <nwx-tasks-vehicles-form
          [index]="i"
          [vehicle]="vehicle"
          [vehicleTasks]="getVehicleSpecificTasks(vehicle)"
          (valueChange)="onValueChange($event)"
          (formReady)="addChildForm($event, i, 'vehicle')"
        ></nwx-tasks-vehicles-form
      ></bolt-tabpanel>
    </ng-container>
  </bolt-tabset>
</form>
