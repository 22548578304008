import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  getAllPremiums,
  getPremiumsForSelectedProducts,
  getProductTerm,
  getPremiumFor,
} from '@core/store/entities/premium/premium.selectors';
import { PremiumEntity } from '../store/entities/premium/premium.entity';
import { Observable } from 'rxjs';
import { ProductType } from '@core/models/api/dsm-types';

@Injectable({
  providedIn: 'root',
})
export class PremiumService {
  constructor(private store: Store) {}

  getAllPremiums(): Observable<PremiumEntity[]> {
    return this.store.select(getAllPremiums);
  }

  getPremiumsForSelectedProducts(): Observable<PremiumEntity[]> {
    return this.store.select(getPremiumsForSelectedProducts);
  }

  getProductTerm(productId: ProductType): Observable<number> {
    return this.store.select(getProductTerm(productId));
  }

  getPremiumFor(
    productType: ProductType
  ): Observable<PremiumEntity | undefined> {
    return this.store.select(getPremiumFor(productType));
  }
}
