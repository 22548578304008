import { MortgageEntity } from '@core/store/entities/mortgage/mortgage.reducer';
import { BasicAddressEntity } from '@entities/address/address.entity';
import { MortgageModel } from '@shared/components/forms/mortgage-form/mortgage-form.model';
import { AddressUtils } from '@shared/utils/address.utils';
import { Nullable } from '@shared/utils/type.utils';

export class MortgageBuilder {
  static mortgageModelFromEntity(
    entity: Nullable<MortgageEntity>
  ): Nullable<MortgageModel> {
    return entity as MortgageModel;
  }

  static mortgageEntityFromModel(
    model: Nullable<MortgageModel>
  ): Nullable<MortgageEntity> {
    return model as MortgageEntity;
  }

  static formatMortgage(mortgage: MortgageEntity): MortgageEntity {
    const formattedMortgage = { ...this.addStreetToMortgageAddress(mortgage) };
    if (formattedMortgage.mortgageType !== 'AdditionalInsured_Ext') {
      formattedMortgage.additionalInsuredType = undefined;
    }
    return formattedMortgage;
  }

  static addStreetToMortgageAddress(mortgage: MortgageEntity): MortgageEntity {
    if (mortgage.address) {
      return {
        ...mortgage,
        address: AddressUtils.AddStreetValueToAddress(
          mortgage.address
        ) as BasicAddressEntity,
      };
    } else {
      return mortgage;
    }
  }
}
