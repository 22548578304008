import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PowersportsAvailableYearsResponse,
  PowersportsVehicleModelMakesResponse,
  PowersportsVehicleModelsWithDescriptionResponse,
  PowersportsVehicleVinDetailsResponse,
} from '@core/models/api/vehicle-inquiry.model';
import {
  BoatModel,
  BoatTrailerModel,
  RVModel,
} from '@core/models/views/vehicle.model';
import { AppConfigService } from '@core/services/app-config.service';
import { StringUtils } from '@shared/utils/string.utils';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

export type InquiryPath =
  | 'motorcycles'
  | 'boats'
  | 'boat-motors'
  | 'boat-trailers'
  | 'recreational-vehicles'
  | 'auto';

export const MakeModelNotFoundPaths = [
  'boats',
  'boat-motors',
  'boat-trailers',
  'recreational-vehicles',
];

export const MAKE_NOT_FOUND = 'MAKE NOT AVAILABLE';
export const MODEL_NOT_FOUND = 'MODEL NOT AVAILABLE';

@Injectable({
  providedIn: 'root',
})
export class PowersportsAdapter {
  constructor(
    private appConfig: AppConfigService,
    private httpClient: HttpClient
  ) {}

  // GENERIC

  getPowersportsVehicleYears(
    path: InquiryPath
  ): Observable<PowersportsAvailableYearsResponse> {
    const url = `${this.appConfig.config.powersportsVehicleInquiryUrl}/${path}/available-years`;
    const messageID = StringUtils.generateUuid();

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('X-NW-Message-ID', messageID)
      .set('client_id', this.appConfig.config.apiKey);

    return this.httpClient.get<PowersportsAvailableYearsResponse>(url, {
      headers,
    });
  }

  getPowersportsVehicleMakesByYear(
    modelYear: number,
    path: InquiryPath
  ): Observable<PowersportsVehicleModelMakesResponse> {
    const url = `${this.appConfig.config.powersportsVehicleInquiryUrl}/${path}/available-years/${modelYear}/available-makes`;
    const messageID = StringUtils.generateUuid();

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('X-NW-Message-ID', messageID)
      .set('client_id', this.appConfig.config.apiKey);

    return this.httpClient
      .get<PowersportsVehicleModelMakesResponse>(url, {
        headers,
      })
      .pipe(
        map((result) => {
          // If check will be removed once this is integrated w all powersports products
          if (MakeModelNotFoundPaths.includes(path)) {
            result.makes.push({
              make: MAKE_NOT_FOUND,
              makeDescription: MAKE_NOT_FOUND,
            });
          }

          return result;
        })
      );
  }

  getPowersportsVehicleModelsByYearAndMake(
    modelYear: number,
    make: string,
    path: InquiryPath
  ): Observable<PowersportsVehicleModelsWithDescriptionResponse> {
    const url = `${this.appConfig.config.powersportsVehicleInquiryUrl}/${path}/available-years/${modelYear}/available-makes/${make}/available-models`;
    const messageID = StringUtils.generateUuid();
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('client_id', this.appConfig.config.apiKey)
      .set('X-NW-Message-ID', messageID);

    return this.httpClient
      .get<PowersportsVehicleModelsWithDescriptionResponse>(url, { headers })
      .pipe(
        map((response) => {
          return {
            models: response.models.map((m) => {
              return {
                model: StringUtils.removeTrailingUnderscore(m.model),
                modelDescription: StringUtils.removeTrailingUnderscore(m.model),
              };
            }),
          };
        }),
        map((result) => {
          // If check will be removed once not found logic integrated w all powersports products
          if (MakeModelNotFoundPaths.includes(path)) {
            result.models.push({
              model: MODEL_NOT_FOUND,
              modelDescription: MODEL_NOT_FOUND,
            });
          }

          return result;
        })
      );
  }

  getMsaVinDetails(
    vehicleIdentificationNumber: string
  ): Observable<PowersportsVehicleVinDetailsResponse> {
    const url = `${this.appConfig.config.powersportsVehicleInquiryUrl}/motorcycle-models/vin`;

    const messageID = StringUtils.generateUuid();
    const params = new HttpParams({
      fromObject: {
        vin: vehicleIdentificationNumber,
      },
    });

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this.appConfig.config.apiKey)
      .set('X-NW-Message-ID', messageID);

    return this.httpClient.get<PowersportsVehicleVinDetailsResponse>(url, {
      headers,
      params,
    });
  }

  // 'Retrieve the model information for a given year, make and model for known boat.
  getBoatModels(
    year: string,
    make: string,
    model: string
  ): Observable<{ boatModels: BoatModel[] }> {
    const url = `${this.appConfig.config.powersportsVehicleInquiryUrl}/boat-models`;
    const messageID = StringUtils.generateUuid();
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('client_id', this.appConfig.config.apiKey)
      .set('X-NW-Message-ID', messageID);
    const params = new HttpParams()
      .set('year', year)
      .set('make', btoa(make))
      .set('model', btoa(model));

    return this.httpClient.get<{ boatModels: BoatModel[] }>(url, {
      headers,
      params,
    });
  }

  // 'Retrieve the model information for a given year, make and model for known boat.
  getRVModels(
    year: string,
    make: string,
    model: string
  ): Observable<{ rvModels: RVModel[] }> {
    const url = `${this.appConfig.config.powersportsVehicleInquiryUrl}/recreational-vehicle-models`;
    const messageID = StringUtils.generateUuid();
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('client_id', this.appConfig.config.apiKey)
      .set('X-NW-Message-ID', messageID);
    const params = new HttpParams()
      .set('year', year)
      .set('make', btoa(make))
      .set('model', btoa(StringUtils.addTrailingUnderscore(model)));

    return this.httpClient.get<{ rvModels: RVModel[] }>(url, {
      headers,
      params,
    });
  }

  // 'Retrieve the model information for a given year, make and model for known boat motor.
  getBoatMotorModels(
    year: string,
    make: string,
    model: string
  ): Observable<{ boatMotorModels: BoatModel[] }> {
    const url = `${this.appConfig.config.powersportsVehicleInquiryUrl}/boat-motor-models`;
    const messageID = StringUtils.generateUuid();
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('client_id', this.appConfig.config.apiKey)
      .set('X-NW-Message-ID', messageID);
    const params = new HttpParams()
      .set('year', year)
      .set('make', btoa(make))
      .set('model', btoa(model));

    return this.httpClient.get<{ boatMotorModels: BoatModel[] }>(url, {
      headers,
      params,
    });
  }

  getBoatTrailerModels(
    year: string,
    make: string,
    model: string
  ): Observable<{ boatTrailerModels: BoatTrailerModel[] }> {
    const url = `${this.appConfig.config.powersportsVehicleInquiryUrl}/boat-trailer-models`;
    const messageID = StringUtils.generateUuid();
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('client_id', this.appConfig.config.apiKey)
      .set('X-NW-Message-ID', messageID);

    const params = new HttpParams()
      .set('year', year)
      .set('make', btoa(make))
      .set('model', btoa(model));

    return this.httpClient.get<{ boatTrailerModels: BoatTrailerModel[] }>(url, {
      headers,
      params,
    });
  }
}
