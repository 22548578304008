import { ProductType } from '@core/models/api/dsm-types';
import * as fromCore from '@core/store/reducers';
import { createSelector } from '@ngrx/store';
import { CoverageSyncStatus } from './coverage-sync.entity';
import * as fromReducer from './coverage-sync.reducer';

export const getCoverageSyncState = createSelector(
  fromCore.getCoreState,
  (state) => state.coverageSync
);
export const {
  selectAll: getAllCoverageSync,
  selectEntities: getCoverageSyncEntities,
} = fromReducer.adapter.getSelectors(getCoverageSyncState);

export const getDirtyCoverageSync = createSelector(
  getAllCoverageSync,
  (entities) => entities.filter((e) => e.status === CoverageSyncStatus.Dirty)
);

export const getCoverageSyncById = (id: string) => {
  return createSelector(
    getCoverageSyncEntities,
    (entities) => entities[id] || null
  );
};

export const getCoverageSyncByEntityId = (
  coverageId: string,
  productType: ProductType,
  coverableId?: number
) => {
  return createSelector(
    getAllCoverageSync,
    (entities) =>
      entities.find(
        (e) =>
          e.coverageId === coverageId &&
          e.productType === productType &&
          (coverableId ? e.coverableId === coverableId : true)
      ) || null
  );
};
