import {
  ProductType,
  QuoteStatus,
  VehicleType,
} from '@app/core/models/api/dsm-types';
import { VehicleFormOptions } from '@app/core/models/views/vehicle-form-options';
import {
  MotorInquiryCode,
  MotorType,
  MotorTypeCode,
  RegisteredOwnerModel,
  VehicleModel,
} from '@app/core/models/views/vehicle.model';
import { DriverEntity } from '@app/core/store/entities/driver/driver.entity';
import { FeatureFlagsModel } from '@app/core/store/entities/feature-flag/feature-flag.model';
import { InquiryPath } from '@core/adapters/powersports.adapter';
import { MemberModel } from '@core/models/views/person.model';
import {
  AdditionalInformationEntity,
  VehicleEntity,
} from '@core/store/entities/vehicle/vehicle.entity';
import {
  DriverRelationToPNI,
  MAX_AUTO_UTIL_TRAILER_VALUE,
  MIN_DEDUCTIBLE_HIGH_VALUE_MOTORHOME,
  MIN_DEDUCTIBLE_TRAVEL_TRAILER,
  MOTORHOME_HIGH_VALUE,
  MOTORHOME_HIGH_VALUE_MED_DUTY_TOW,
  PERSONAL_AUTO_TRAILER_TYPE,
  TRAVEL_TRAILER_HIGH_VALUE,
} from '@shared/constants/app-constants';
import { VehicleFormFromEntityBuilder } from './builders/vehicles/vehicle-form-from-entity';
import { PersonUtils } from './person.utils';
import { Nullable } from './type.utils';

export class VehicleUtils {
  static formOptionsForVehicles(
    vehicle: VehicleModel,
    featureFlags: FeatureFlagsModel,
    drivers: DriverEntity[],
    quoteState: string,
    quoteStatus: QuoteStatus
  ): VehicleFormOptions {
    const isMSASelected = vehicle.productType === 'MSA';
    const isRVSelected = vehicle.productType === 'RV';
    const isBoatSelected = vehicle.productType === 'Boat';
    const isAutoSelected = vehicle.productType === 'PersonalAuto';
    const isAutoUtilityTrailer =
      vehicle.vehicleType === PERSONAL_AUTO_TRAILER_TYPE && isAutoSelected;
    const options = {} as VehicleFormOptions;

    options.vinMutable = !!vehicle.prefillId ? false : true;
    options.vinVisible = true;
    options.primaryUseVisible = isAutoUtilityTrailer
      ? false
      : featureFlags.vehicleDailyUseQuestions || false;
    options.msaPrimaryUseVisible =
      featureFlags.msaVehicleDailyUseQuestions || false;
    options.costNewVisible = VehicleUtils.getCostNewVisible(
      vehicle,
      featureFlags
    );
    options.costNewVisible = true;
    options.costNewRequired = vehicle.vehicleType === 'Trailer_Ext';
    // Add some featureFlags if customEquipment gets any more complicated than this:
    options.customEquipmentVisible = quoteState !== 'NY';
    options.customEquipmentMinimum = quoteState === 'NC' ? 1000 : 3000;
    options.customEquipmentLabel =
      quoteState === 'NC' ? 'Customizing equipment' : 'Custom equipment';
    options.odometerVisible = featureFlags.vehicleOdometerReading || false;
    options.autoAnnualMilesVisible = featureFlags.autoAnnualMiles
      ? !isAutoUtilityTrailer
      : false;
    options.automaticTirePressureVisible = featureFlags.automaticTirePressure && isRVSelected;
    options.msaAnnualMilesVisible = featureFlags.msaAnnualMiles || false;
    options.lenderVisible = true;
    options.garageLocationVisible = true;
    options.isRegisteredInStateVisible = isAutoUtilityTrailer
      ? false
      : featureFlags.vehicleRegisteredInState || false;
    options.quoteStatus = quoteStatus;
    options.quoteState = quoteState || '';
    options.vehicleFeaturesVisible =
      featureFlags.vehicleFeaturesApplicable || false;
    options.vehicleSubTypeVisible =
      vehicle.productType === 'PersonalAuto' &&
      vehicle.vehicleType !== 'Trailer_Ext';
    options.daytimeRunningLampsVisible =
      featureFlags.daytimeRunningLampsDiscount || false;
    options.antiLockBrakesVisible =
      featureFlags.antiLockBrakesDiscount || isMSASelected || false;
    options.carpoolDiscountVisible =
      (featureFlags.carpoolDiscountVisible && isAutoSelected) || false;
    options.registrationStateVisible =
      featureFlags.rvRegistrationState &&
      (vehicle.vehicleType === 'Motorhome' ||
        vehicle.vehicleType === 'TravelTrailer');
    options.isCoOwnedVisible = isBoatSelected || isMSASelected || isRVSelected;
    if (featureFlags.vehicleRegisteredOwners && !isAutoUtilityTrailer) {
      if (isRVSelected) {
        options.registeredOwnersVisible = vehicle.vehicleType === 'Motorhome';
      } else {
        options.registeredOwnersVisible = true;
      }
      options.drivers =
        VehicleFormFromEntityBuilder.vehicleFormDriversFromEntities(
          drivers,
          vehicle.productType as ProductType
        );
    } else {
      options.registeredOwnersVisible = false;
    }

    options.antiTheftDevicesVisible = this.getAntiTheftDevicesVisible(
      isMSASelected,
      isRVSelected,
      isAutoSelected,
      vehicle,
      featureFlags,
      isAutoUtilityTrailer
    );
    options.auxiliaryLightsVisible = featureFlags.auxiliaryLighting || false;
    options.msaVehicleIsHistoricalVisible =
      (featureFlags.msaVehicleIsHistorical &&
        vehicle.vehicleType === 'Motorcycle') ||
      false;
    options.isRebuiltSalvagedFrameReplacedVisible =
      vehicle.vehicleType !== 'UtilityTrailer' && isMSASelected;
    options.rvWeightRequired = featureFlags.rvWeightRequired || false;
    options.passiveRestraintVisible =
      (featureFlags.rvPassiveRestraintDiscount && isRVSelected) || false;
    options.rvVehicleIsHistoricalVisible =
      (featureFlags.rvVehicleIsHistorical &&
        vehicle.vehicleType === 'Motorhome') ||
      false;
    return options;
  }

  static VehicleIsSaved(vehicleId: string): boolean {
    return !(
      vehicleId?.toString().startsWith('new') ||
      vehicleId?.toString().startsWith('PREFILL')
    );
  }

  static SetRegisteredOwner(
    vehicle: VehicleModel,
    drivers: MemberModel[],
    featureFlags: FeatureFlagsModel
  ): RegisteredOwnerModel[] {
    if (featureFlags.vehicleRegisteredOwners) {
      if (
        vehicle &&
        vehicle.registeredOwners &&
        vehicle.registeredOwners.length > 0
      ) {
        return vehicle.registeredOwners;
      }

      const pniDriver = drivers.find(
        (driver) =>
          driver.relationToPrimaryNamedInsured === DriverRelationToPNI.PNI
      );
      if (pniDriver && vehicle.productType) {
        return [
          {
            driverId: PersonUtils.driverIdFromEntity(
              pniDriver,
              vehicle.productType
            ),
          },
        ];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  static getVehicleIndex(
    vehicles: VehicleModel[],
    changes: Partial<VehicleModel>
  ): number {
    return vehicles.findIndex(
      (vehicle) => vehicle.vehicleId === changes.vehicleId
    );
  }

  static inquiryPathFromVehicleType(vehicleType?: VehicleType): InquiryPath {
    switch (vehicleType) {
      case 'Motorcycle':
      case 'Snowmobile':
      case 'OffRoad':
        return 'motorcycles';
      case 'boat':
        return 'boats';
      case 'motor':
        return 'boat-motors';
      case 'BoatTrailer':
        return 'boat-trailers';
      case 'TravelTrailer':
      case 'Motorhome':
        return 'recreational-vehicles';
      case 'auto':
      case 'Trailer_Ext':
        return 'auto';
      default:
        return 'motorcycles';
    }
  }

  static getTotalEstMarketValue(vehicle: VehicleEntity): number {
    let marketVal = vehicle.estimatedMarketValue || 0;
    if (vehicle.motorDetails) {
      vehicle.motorDetails.forEach((motor) => {
        marketVal += motor.estimatedMarketValue || 0;
      });
    }
    if (vehicle.trailer) {
      marketVal += vehicle.trailer.estimatedMarketValue || 0;
    }
    return marketVal;
  }
  static getTotalMSRP(vehicle: Partial<VehicleModel>): number {
    let msrp = vehicle.msrp || 0;
    if (vehicle.motorDetails) {
      vehicle.motorDetails.forEach((motor) => {
        msrp += motor.msrp || 0;
      });
    }
    if (vehicle.trailer) {
      msrp += vehicle.trailer.msrp || 0;
    }
    return msrp;
  }

  static vehicleRequiresHighDeductible(
    vehicle: Partial<VehicleEntity>
  ): boolean {
    if (vehicle.vehicleType === 'Motorhome') {
      if (vehicle.subType === 'MediumDutyTow') {
        if ((vehicle.marketValue || 0) > MOTORHOME_HIGH_VALUE_MED_DUTY_TOW) {
          return true;
        }
      }
      if ((vehicle.marketValue || 0) > MOTORHOME_HIGH_VALUE) {
        return true;
      }
    }
    if (
      vehicle.vehicleType === 'TravelTrailer' &&
      vehicle.productType === 'RV'
    ) {
      if ((vehicle.marketValue || 0) > TRAVEL_TRAILER_HIGH_VALUE) {
        return true;
      }
    }
    return false;
  }

  static getDeductibleMinByVehicleType(
    vehicle: Partial<VehicleEntity>
  ): number {
    switch (vehicle.vehicleType) {
      case 'TravelTrailer':
        return MIN_DEDUCTIBLE_TRAVEL_TRAILER;
      default:
        return MIN_DEDUCTIBLE_HIGH_VALUE_MOTORHOME;
    }
  }

  static getAntiTheftDevicesVisible(
    isMSASelected: boolean,
    isRVSelected: boolean,
    isAutoSelected: boolean,
    vehicle: VehicleModel,
    featureFlags: FeatureFlagsModel,
    isAutoUtilityTrailer: boolean
  ): boolean {
    if (isRVSelected) {
      return featureFlags.antiTheftDevicesRV || false;
    }
    if (isMSASelected) {
      return vehicle.vehicleType !== 'UtilityTrailer';
    }
    if (isAutoSelected) {
      return (featureFlags.antiTheftDevices || false) && !isAutoUtilityTrailer;
    }
    return false;
  }

  static sanitizeEngineType(motorType: MotorTypeCode): string {
    switch (motorType) {
      case 'InboardOrOutboard':
        return 'InboardOutboard';
      default:
        return motorType;
    }
  }

  static getCostNewVisible(
    vehicle: VehicleModel,
    featureFlags: FeatureFlagsModel
  ): boolean {
    const isUtilityTrailer = vehicle.vehicleType === PERSONAL_AUTO_TRAILER_TYPE;
    if (vehicle.productType === 'PersonalAuto') {
      return isUtilityTrailer ? true : featureFlags.vehicleCostNew || false;
    }
    return false;
  }
}
