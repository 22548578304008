import { AddressType, ProductType } from './../models/api/dsm-types';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AddressEntity } from '@core/store/entities/address/address.entity';
import { Nullable } from '@shared/utils/type.utils';
import { GisService } from '@core/services/gis.service';
import { AddressModel } from '@shared/components/address-input/address-input.model';
import { AddressFormOptions } from '@core/models/views/address-contact-form-options';
import { AddressActions } from '@core/store/actions';
import { AddressSelectors } from '@core/store/selectors';
import {
  getUniqueQuoteStates,
  getSelectedProductsWithAddressState,
} from '@entities/address/address.selector';
import { ProductWithState } from '@entities/product/product.model';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private store: Store, private gisService: GisService) {}

  upsert(address: AddressEntity): void {
    this.store.dispatch(AddressActions.upsertAddress({ payload: address }));
  }

  storeAddress(address: AddressEntity): void {
    this.store.dispatch(AddressActions.storeAddress({ payload: address }));
  }

  getAddressById(addressId: string): Observable<Nullable<AddressModel>> {
    return this.store.select(AddressSelectors.getAddressById(addressId));
  }

  getAddressByType(type: AddressType): Observable<Nullable<AddressEntity>> {
    return this.store.select(AddressSelectors.getAddressByType(type));
  }

  getAll(): Observable<AddressEntity[]> {
    return this.store.select(AddressSelectors.getAllAddress);
  }

  getAddressForProduct(
    productType: ProductType
  ): Observable<Nullable<AddressEntity>> {
    return this.store.select(
      AddressSelectors.getAddressForProduct(productType)
    );
  }

  getSingleLineAddress(productType: ProductType): Observable<string> {
    return this.store.select(
      AddressSelectors.getSingleLineAddress(productType)
    );
  }

  getPropertyAddress(productType: ProductType): Observable<string> {
    return this.store.select(AddressSelectors.getPropertyAddress(productType));
  }

  getBillingAddress(): Observable<AddressModel> {
    return this.store.select(AddressSelectors.getBillingAddress);
  }

  getAddressForNewPolicyHolder(): Observable<AddressModel> {
    return this.store.select(AddressSelectors.getAddressForNewPolicyHolder);
  }

  getAvailableAddress(): Observable<AddressModel> {
    return this.store.select(AddressSelectors.getAvailableAddress);
  }

  getMailingAddress(): Observable<AddressModel> {
    return this.store.select(AddressSelectors.getMailingAddress);
  }

  getAccountAddress(): Observable<AddressModel> {
    return this.store.select(AddressSelectors.getAccountAddress);
  }

  getDefaultAddress(): Observable<AddressModel> {
    return this.store.select(AddressSelectors.getDefaultAddress);
  }

  getUniqueQuoteStates(): Observable<string[]> {
    return this.store.select(getUniqueQuoteStates);
  }

  getSelectedProductsWithAddressState(): Observable<ProductWithState[]> {
    return this.store.select(getSelectedProductsWithAddressState);
  }

  getAddressFormOptions(
    unitNumberVisible: boolean,
    outsideQuoteState: boolean
  ): Observable<AddressFormOptions> {
    return this.store.select(
      AddressSelectors.getAddressFormOptions(
        unitNumberVisible,
        outsideQuoteState
      )
    );
  }

  addressEntitiesDifferent(a?: AddressEntity, b?: AddressEntity): boolean {
    if (!a || !b) {
      if (!a && !b) {
        return false;
      }
      return true;
    }

    const postalCodeA = a.postalCode?.slice(0, 5);
    const postalCodeB = b.postalCode?.slice(0, 5);

    return (
      (a.addressLine1 || '') !== (b.addressLine1 || '') ||
      (a.addressLine2 || '') !== (b.addressLine2 || '') ||
      (a.city || '') !== (b.city || '') ||
      (a.state || '') !== (b.state || '') ||
      (postalCodeA || '') !== (postalCodeB || '')
    );
  }
}
