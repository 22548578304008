import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Nullable } from '@shared/utils/type.utils';
import { Subject } from 'rxjs';

export interface WellContent {
  description: string;
  displayButton: boolean;
  itemList?: PillModel[];
  entityId: string;
  zeroItemsMessage?: string;
  addBtnText?: string;
}
export interface PillModel {
  name: string;
  item: any; // scheduled category item, vehicle, etc. anything
}
@Component({
  selector: 'nwx-pill-list',
  templateUrl: './pill-list.component.html',
  styleUrls: ['./pill-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillListComponent {
  @Input() content!: Nullable<WellContent>;
  @Input() identifier!: Nullable<string>;
  @Input() index!: number;
  @Output() clickEmitted = new EventEmitter<any>();

  unsubscribe$: Subject<void> = new Subject();

  constructor() {}

  handleClick(event?: any) {
    this.clickEmitted.emit(event);
  }
}
