import {
  RetrieveActions,
  PolicyHolderActions,
  ProductActions,
  QuoteActions,
} from '@core/store/actions';
import { ProductsSelectors } from '@core/store/selectors';
import { Action, Store } from '@ngrx/store';
import { concatMap, map, mergeMap, pairwise, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProductsService } from '@core/services/products.service';
import { TelematicsService } from '@core/services/telematics.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  distinctUntilChanged,
  filter,
  withLatestFrom,
  switchMap,
  from,
} from 'rxjs';
import {
  updateDocDeliveryOption,
  documentDeliveryChangedDueToTelematics,
  patchProductQuote,
  updateProducer,
} from './product.actions';
import { AgencyService } from '@core/services/agency.service';
import { ProductUtils } from '@shared/utils/product.util';
import * as AgencySelectors from '@core/store/entities/agency/agency.selector';
import { ModalService } from '@shared/services/modal.service';
import { NationwideAccountRegistrationInfo } from '@core/models/api/dsm-types';
import { MemberService } from '@core/services/member.service';
import { StringUtils } from '@shared/utils/string.utils';
import { PolicyHolderEntity } from '@entities/policyholder/policyholder.entity';
import { ProductHelper } from '@core/helper/product.helper';

@Injectable({
  providedIn: 'root',
})
export class ProductEffects {
  constructor(
    public store: Store,
    private telematicsService: TelematicsService,
    private productsService: ProductsService,
    private agencyService: AgencyService,
    private actions$: Actions,
    private modalService: ModalService,
    private memberService: MemberService
  ) {}

  /** When a quote in PC is "Withdrawn", it is effectively deleted. No further operations will work.
   * Once a quote becomes Withdrawn, don't let any other ill-advised "Pending" or "Error" or any other statuses replace it.
   * In Withdrawn status, we will not send any DSM requests.
   */
  forciblyRetainWithdrawnStatusOnceSet$ = createEffect(() =>
    this.store.select(ProductsSelectors.getSelectedProducts).pipe(
      pairwise(),
      switchMap(([prev, next]) => {
        const actions: Action[] = [];
        for (const product of next) {
          if (product.quoteStatus === 'Withdrawn') {
            continue;
          }
          const prevProduct = prev.find((p) => p.type === product.type);
          if (
            prevProduct?.quoteStatus === 'Withdrawn' &&
            prevProduct.quoteId === product.quoteId
          ) {
            actions.push(
              ProductActions.updateProductStatus({
                payload: product.type,
                status: 'Withdrawn',
              })
            );
          }
        }
        return from(actions);
      })
    )
  );

  forceDocumentDeliveryForSmartMiles$ = createEffect(() =>
    this.telematicsService.isSmartMilesEnrolled().pipe(
      distinctUntilChanged(),
      filter((enrolled) => enrolled),
      withLatestFrom(this.productsService.getDocumentDeliveryPreference()),
      filter(([enrolled, docDeliveryPreference]) => {
        return docDeliveryPreference === 'USMail';
      }),
      switchMap(([enrolled, docDeliveryPreference]) =>
        from([
          patchProductQuote({
            payload: {
              productType: 'PersonalAuto',
              docDelPreference: 'OnlineAccountAccess',
            },
          }),
          updateDocDeliveryOption({
            payload: {
              docDelPreference: 'OnlineAccountAccess',
            },
          }),
          documentDeliveryChangedDueToTelematics({
            previous: docDeliveryPreference,
            docDeliveryPreference: 'OnlineAccountAccess',
          }),
        ])
      )
    )
  );

  addProducerNameToNewProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActions.initiateNewBusinessSuccess),
      concatMap((_res) => this.agencyService.getProducerCodes().pipe(take(1))),
      withLatestFrom(
        this.store.select(ProductsSelectors.getSelectedProducts),
        this.store.select(AgencySelectors.getAgency)
      ),
      concatMap(([search, products, agency]) => {
        const nextActions: Action[] = [];
        for (let product of products) {
          const result = ProductUtils.matchingProducerFromSearch(
            search,
            agency.producerCode
          );
          if (!result) continue;
          nextActions.push(
            updateProducer({
              product: product.type,
              producer: {
                producerCode: agency.producerCode || '',
                agentName: result.agentName || '',
              },
            })
          );
        }
        return from(nextActions);
      })
    )
  );

  displayUnsupportedProductsModel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RetrieveActions.allRetrievesComplete),
        switchMap(() =>
          this.productsService
            .getSelectedProductsIncludingNWXInactive()
            .pipe(take(1))
        ),
        filter((products) => products.every((p) => !p.isDsmActive)),
        switchMap(() => this.modalService.unsupportedRetrievePivot())
      ),
    { dispatch: false }
  );

  storeOnlineAccountRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.storeOnlineAccountRegistration),
      map((action) => action.registration),
      withLatestFrom(this.productsService.getSelectedProducts()),
      mergeMap(([registration, products]) => {
        const nextActions: Action[] = [];
        products.map((p) => {
          nextActions.push(
            ProductActions.updateProduct({
              payload: {
                id: p.type,
                changes: {
                  ...p,
                  nationwideAccountRegistrationInfo:
                    registration as NationwideAccountRegistrationInfo,
                },
              },
            })
          );
        });
        return from(nextActions);
      })
    )
  );
}
