import * as fromCore from '@core/store/reducers';
import { createSelector } from '@ngrx/store';

export const getPropertyFormState = createSelector(
  fromCore.getCoreState,
  (core) => core.propertyForm
);

export const getRoofYearImmutable = createSelector(
  getPropertyFormState,
  (state) => state.roofYearImmutable
);

export const getFortifiedHome = createSelector(
  getPropertyFormState,
  (state) => state.fortifiedHome
);
