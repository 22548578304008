import { Injectable } from '@angular/core';
import { PreQualification } from '@core/interfaces/interfaces';
import { ProductType } from '@core/models/api/dsm-types';
import { RiskItem } from '@entities/covered-location/covered-location.model';
import { CoveredLocationEntity } from '@core/store/entities/covered-location/covered-location.reducer';
import {
  EligibilityFormModel,
  EligibilityInlineMessage,
  EligibilityPrequalificationAnswers,
  EligibilityRiskItems,
} from './eligibility-form.model';
import { Observable, take } from 'rxjs';
import {
  CoveredLocationActions,
  EligibilityFormActions,
  QuoteActions,
} from '@core/store/actions';
import { Action, Store } from '@ngrx/store';
import {
  EligibilityFormSelectors,
  PrequalificationVerbiageSelectors,
} from '@core/store/selectors';
import {
  ActionAggregatorService,
  ExpectedActions,
} from '@core/services/action-aggregator.service';
import { StringUtils } from '@shared/utils/string.utils';
import { PrequalificationVerbiageModel } from '../prequalification-verbiage/prequalification-verbiage.reducer';

@Injectable({
  providedIn: 'root',
})
export class EligibilityFormService {
  constructor(
    private store: Store,
    private actionAggregatorService: ActionAggregatorService
  ) {}

  getEligibilityFormByProductType(
    productType: ProductType
  ): Observable<EligibilityFormModel | undefined> {
    return this.store.select(
      EligibilityFormSelectors.getAnyEligibilityFormByProductType(productType)
    );
  }

  getEligibilityFormByQuoteId(
    quoteId: string
  ): Observable<EligibilityFormModel | undefined> {
    return this.store.select(
      EligibilityFormSelectors.getEligibilityFormByQuoteId(quoteId)
    );
  }

  updateEligibilityFormModel(model: EligibilityFormModel): void {
    model = this.redetermineInlineMessages(model);
    this.store.dispatch(
      EligibilityFormActions.upsertEligibilityForm({ model })
    );
  }

  /** Makes all the business decisions re inline messages, returns a new model with those decisions encoded.
   * We call this internally before passing forms up to the store.
   */
  redetermineInlineMessages(input: EligibilityFormModel): EligibilityFormModel {
    const messages: EligibilityInlineMessage[] = [];
    if (input.prequalificationAnswers) {
      const abortIf = (
        questionCode: keyof EligibilityPrequalificationAnswers,
        badValue: string,
        message: string = ''
      ) => {
        if (input.prequalificationAnswers![questionCode] === badValue) {
          messages.push({
            questionCode,
            action: 'abort',
            message: message || 'This quote is ineligible.', //TODO verbiage
          });
        }
      };
      abortIf(
        'HOOwnerOccupied_Ext',
        'false',
        'Dwellings that are not owner occupied do not qualify for a policy.'
      );
      abortIf(
        'HOInsuranceFraud_Ext',
        'true',
        'Applicants with any household members that have been convicted of insurance fraud do not qualify for a policy.'
      );
      abortIf(
        'HOInsuranceFraud2_Ext',
        'true',
        'Applicants with any household members that have been convicted of insurance fraud do not qualify for a policy.'
      );
      abortIf(
        'HOFireLoss_Ext',
        'true',
        'Applicants with fire losses by anyone in the household in the past 5 years are ineligible.'
      );
      abortIf(
        'HOFireLoss3yrs_Ext',
        'true',
        'Applicants with fire losses by anyone in the household in the past 3 years are ineligible.'
      );
      abortIf(
        'HOFireLoss3yrs_ExtCopy',
        'true',
        'Applicants with fire losses by anyone in the household in the past 3 years are ineligible.'
      );
      abortIf(
        'HOFireLossExCat_Ext',
        'true',
        'Applicants with fire losses by anyone in the household in the past 5 years are ineligible.'
      );
      abortIf(
        'HOFireAccess_Ext',
        'false',
        'Properties that are not accessible to fire equipment year round are ineligible.'
      );
      abortIf(
        'HO3_Foreclosure_Ext',
        'true',
        'Dwellings in the foreclosure process do not qualify for a policy.'
      );
      abortIf(
        'HO3_Foreclosure2_Ext',
        'true',
        'Dwellings in the foreclosure process do not qualify for a policy.'
      );
      abortIf(
        'HO6_Foreclosure1_Ext',
        'true',
        'Dwellings in the foreclosure process do not qualify for a policy.'
      );
      abortIf(
        'HO6_Foreclosure2_Ext',
        'true',
        'Dwellings in the foreclosure process do not qualify for a policy.'
      );
      abortIf(
        'HOVacaRental_Ext',
        'true',
        'Dwellings rented to others as short term rentals are ineligible.'
      );
      abortIf('HOCurrentlyLiving_Ext', 'false');
      const noActionIf = (
        questionCode: keyof EligibilityPrequalificationAnswers,
        badValue: string,
        message: string = ''
      ) => {
        if (input.prequalificationAnswers![questionCode] === badValue) {
          messages.push({
            questionCode,
            action: '',
            message: message || 'This quote is ineligible.', //TODO verbiage
          });
        }
      };
      noActionIf(
        'PUPBusinessActivities_PUE',
        'true',
        'Commercial, agribusiness or non-standard underlying coverages do not qualify for an umbrella policy.'
      );
      noActionIf(
        'household_insurance_offense',
        'true',
        'Household convictions of an insurance related offense (not including accidents or moving violations) are ineligible for coverage.'
      );
      noActionIf(
        'household_personal_liability',
        'true',
        'Household members with personal injury, comprehensive personal liability or premises liability losses in the last 5 years are ineligible.'
      );
    }
    if (input.riskItems) {
      const abortIf = (
        questionCode: keyof EligibilityRiskItems,
        badValue: string,
        message: string = ''
      ) => {
        if (input.riskItems![questionCode] === badValue) {
          messages.push({
            questionCode,
            action: 'abort',
            message: message || 'This quote is ineligible.', //TODO verbiage
          });
        }
      };
      const pcIf = (
        questionCode: keyof EligibilityRiskItems,
        badValue: string,
        message: string = ''
      ) => {
        if (input.riskItems![questionCode] === badValue) {
          messages.push({
            questionCode,
            action: 'pc',
            message:
              message ||
              'This requires underwriting approval.\nPlease continue in PolicyCenter.',
          });
        }
      };
      abortIf('registeredHistoric', 'true', 'Historic homes are ineligible.');
      pcIf(
        'nonResidentialToResidential',
        'true',
        'Dwellings converted to add additional living units are ineligible and require prior approval.'
      );
      abortIf('deededOwner', 'false');
      pcIf(
        'isShortSaleAuctForeclose',
        'true',
        'Properties that have been recently purchased via foreclosure, public auction or short sale require prior approval. Please contact underwriting.'
      );
      abortIf(
        'trampolineTieDowns',
        'false',
        'Ineligible due to unsecured trampoline.'
      );
      abortIf(
        'trampolineNet',
        'false',
        'Ineligible due to unsecured trampoline.'
      );
      pcIf(
        'dangerousOrExoticAnimal',
        'true',
        'Currently Nationwide Express does not return a rate for this risk. Please continue in PolicyCenter.'
      );
      abortIf('animalThatCausedInjury', 'true');
      pcIf('incidentalFarm', 'true');
      pcIf(
        'currentConstruction',
        'true',
        'Dwellings under construction are ineligible. Please contact Nationwide Brokerage Solutions for other possible placement options.'
      );
      pcIf(
        'currentRemodelingOrRehabiliation',
        'true',
        'Prior underwriting approval is required due to extensive remodeling on home.'
      );
      abortIf(
        'swimmingLessonsProvided',
        'true',
        'Ineligible due to swimming lessons conducted on premises.'
      );
      if (
        input.riskItems.distanceToNonOwnedBuildingPool === 'LessThanEq1000ft'
      ) {
        abortIf(
          'selfLatchingGate',
          'false',
          'Ineligible due to unsecured swimming pool.'
        );
        abortIf(
          'poolFence',
          'false',
          'Ineligible due to unsecured swimming pool.'
        ); //TODO not in reqs, await confirmation
      }
      if (
        input.riskItems.distanceToNonOwnedBuildingTrampoline ===
        'LessThanEq1000ft'
      ) {
        abortIf(
          'trampolineFence',
          'false',
          'Ineligible due to unsecured trampoline.'
        );
      }
    }
    // A similar treatment for dog bite history is handled at the view level, eligibility-dog-list.component.html
    const output: EligibilityFormModel = { ...input, inlineMessages: messages };
    return output;
  }

  prequalificationAnswersToForm(
    input?: PreQualification[]
  ): Partial<EligibilityPrequalificationAnswers> | undefined {
    if (!input?.length) {
      return undefined;
    }
    const output: Partial<EligibilityPrequalificationAnswers> = {};
    for (const question of input) {
      const key =
        question.questionCode as keyof EligibilityPrequalificationAnswers;
      output[key] =
        question.answerValue?.toString() || this.defaultAnswer(key) || '';
    }
    return output;
  }

  coveredLocationToForm(
    productType: ProductType,
    input?: CoveredLocationEntity
  ): Partial<EligibilityRiskItems> | undefined {
    if (!input) {
      return undefined;
    }
    const output: Partial<EligibilityRiskItems> = {};
    type RiskItemStringKey = keyof Omit<EligibilityRiskItems, 'dogs'> &
      keyof RiskItem;
    const fromBoolean = (
      key: keyof EligibilityRiskItems & keyof CoveredLocationEntity
    ) => {
      if (input.hasOwnProperty(key)) {
        output[key] = (input[key] ?? this.defaultAnswer(key)).toString();
      }
    };
    const fromBooleanRisk = (key: RiskItemStringKey) => {
      if (input.riskItems?.hasOwnProperty(key)) {
        output[key] = (
          input.riskItems[key] ?? this.defaultAnswer(key)
        ).toString();
      }
    };
    const fromStringRisk = (key: RiskItemStringKey) => {
      if (input.riskItems?.hasOwnProperty(key)) {
        output[key] = (
          input.riskItems[key] || this.defaultAnswer(key)
        ).toString();
      }
    };
    const fromNumberRisk = (key: RiskItemStringKey) => {
      if (input.riskItems?.hasOwnProperty(key)) {
        output[key] = (
          input.riskItems[key] ?? this.defaultAnswer(key)
        ).toString();
      }
    };

    if (productType === 'Homeowner') {
      fromBoolean('registeredHistoric');
      fromBoolean('nonResidentialToResidential');
      fromBoolean('deededOwner');
      fromBoolean('isShortSaleAuctForeclose');
      fromBoolean('currentConstruction');
      fromBoolean('currentRemodelingOrRehabiliation');
      fromBooleanRisk('trampoline');
      fromStringRisk('distanceToNonOwnedBuildingTrampoline');
      fromBooleanRisk('trampolineFence');
      fromBooleanRisk('trampolineTieDowns');
      fromBooleanRisk('trampolineNet');
      fromBooleanRisk('dangerousOrExoticAnimal');
      fromBooleanRisk('animalThatCausedInjury');
      fromBooleanRisk('haveDogs');
      fromBooleanRisk('incidentalFarm');
      fromBooleanRisk('swimmingPool');
      fromStringRisk('typeOfSwimmingPool');
      fromStringRisk('distanceToNonOwnedBuildingPool');
      fromBooleanRisk('swimmingLessonsProvided');
      fromBooleanRisk('poolLadder');
      fromBooleanRisk('removableLadder');
      fromBooleanRisk('divingBoard');
      fromNumberRisk('waterDepthUnderDivingBoard');
      fromBooleanRisk('poolSlide');
      fromNumberRisk('waterDepthUnderSlide');
      fromBooleanRisk('poolFence');
      fromBooleanRisk('selfLatchingGate');
    } else if (productType === 'Condominium') {
      fromBoolean('nonResidentialToResidential');
      fromBoolean('deededOwner');
      fromBoolean('isShortSaleAuctForeclose');
      fromBooleanRisk('trampoline');
      fromStringRisk('distanceToNonOwnedBuildingTrampoline');
      fromBooleanRisk('trampolineFence');
      fromBooleanRisk('trampolineTieDowns');
      fromBooleanRisk('trampolineNet');
      fromBooleanRisk('dangerousOrExoticAnimal');
      fromBooleanRisk('animalThatCausedInjury');
      fromBooleanRisk('haveDogs');
      fromBooleanRisk('incidentalFarm');
    } else if (productType === 'Tenant') {
      fromBooleanRisk('trampoline');
      fromStringRisk('distanceToNonOwnedBuildingTrampoline');
      fromBooleanRisk('trampolineFence');
      fromBooleanRisk('trampolineTieDowns');
      fromBooleanRisk('trampolineNet');
      fromBooleanRisk('dangerousOrExoticAnimal');
      fromBooleanRisk('animalThatCausedInjury');
      fromBooleanRisk('haveDogs');
      fromBooleanRisk('incidentalFarm');
      fromBooleanRisk('swimmingPool');
      fromStringRisk('typeOfSwimmingPool');
      fromStringRisk('distanceToNonOwnedBuildingPool');
      fromBooleanRisk('swimmingLessonsProvided');
      fromBooleanRisk('poolLadder');
      fromBooleanRisk('removableLadder');
      fromBooleanRisk('divingBoard');
      fromNumberRisk('waterDepthUnderDivingBoard');
      fromBooleanRisk('poolSlide');
      fromNumberRisk('waterDepthUnderSlide');
      fromBooleanRisk('poolFence');
      fromBooleanRisk('selfLatchingGate');
    }

    if (input.riskItems?.dogs) {
      output.dogs = input.riskItems.dogs.map((d) => ({
        dogBreed: d.dogBreed || '',
        biteHistory: (d.biteHistory || false).toString(),
        canineGoodCitizen: '', //TODO where does this live in dsm?
      }));
    }

    return output;
  }

  defaultAnswer(key: string): string {
    switch (key) {
      case 'HOOwnerOccupied_Ext':
        return 'true';
      case 'HOCondoOwnerOneProd_Ext':
        return 'true';
      case 'HOInsuranceFraud_Ext':
        return 'false';
      case 'HOArsonConviction_Ext':
        return 'false';
      case 'HOArson_ExtCopy':
        return 'false';
      case 'HOFireLoss_Ext':
        return 'false';
      case 'HOFireLoss3yrs_Ext':
        return 'false';
      case 'HOFireLoss3yrs_ExtCopy':
        return 'false';
      case 'HOFireLossExCat_Ext':
        return 'false';
      case 'HOFireAccess_Ext':
        return 'true';
      case 'HO3_Foreclosure_Ext':
        return 'false';
      case 'HO3_Foreclosure2_Ext':
        return 'false';
      case 'HO6_Foreclosure1_Ext':
        return 'false';
      case 'HO6_Foreclosure2_Ext':
        return 'false';
      case 'HOVacaRental_Ext':
        return 'false';
      case 'HOCurrentlyLiving_Ext':
        return 'true';
      case 'registeredHistoric':
        return 'false';
      case 'nonResidentialToResidential':
        return 'false';
      case 'deededOwner':
        return 'true';
      case 'isShortSaleAuctForeclose':
        return 'false';
      case 'trampoline':
        return 'false';
      case 'dangerousOrExoticAnimal':
        return 'false';
      case 'animalThatCausedInjury':
        return 'false';
      case 'haveDogs':
        return 'false';
      case 'incidentalFarm':
        return 'false';
      case 'currentConstruction':
        return 'false';
      case 'currentRemodelingOrRehabiliation':
        return 'false';
      case 'swimmingPool':
        return 'false';
      case 'PUPNotCoveredByPrimary_PUE':
        return 'false';
      case 'PUPBusinessActivities_PUE':
        return 'false';
      case 'PUPOccupationalLoss_Ext':
        return 'false';
      case 'household_insurance_offense':
        return 'false';
      case 'household_personal_liability':
        return 'false';
    }
    return '';
  }

  syncToBackendAndWait(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.store
        .select(EligibilityFormSelectors.getAllEligibilityForms)
        .pipe(take(1))
        .subscribe((forms) => {
          const expectedActions: ExpectedActions = [];
          const outgoingActions: Action[] = [];
          for (const form of forms) {
            this.prepareActionsForForm(outgoingActions, expectedActions, form);
          }
          this.actionAggregatorService.aggregate(expectedActions, {
            onSuccess: () => resolve(),
            onError: (store, actions) => reject(actions),
            onTimeout: () => reject('timeout'),
          });
          for (const action of outgoingActions) {
            this.store.dispatch(action);
          }
        });
    });
  }

  private prepareActionsForForm(
    outgoingActions: Action[],
    expectedActions: ExpectedActions,
    form: EligibilityFormModel
  ): void {
    if (form.prequalificationAnswers) {
      const correlationId = StringUtils.generateUuid();
      outgoingActions.push(
        QuoteActions.updateQuote({
          productType: form.productType,
          correlationId,
        })
      );
      expectedActions.push({
        success: [
          { type: QuoteActions.updateQuoteSuccess.type, correlationId },
        ],
        error: [{ type: QuoteActions.updateQuoteFail.type, correlationId }],
      });
    }
    if (form.riskItems) {
      const correlationId = StringUtils.generateUuid();
      outgoingActions.push(
        CoveredLocationActions.updateCoveredLocation({
          payload: form.productType,
          includeMsbEstimateNumber: false,
          correlationId,
        })
      );
      expectedActions.push({
        success: [
          {
            type: CoveredLocationActions.updateCoveredLocationSuccess.type,
            correlationId,
          },
        ],
        error: [
          {
            type: CoveredLocationActions.updateCoveredLocationError.type,
            correlationId,
          },
        ],
      });
    }
  }

  addDog(quoteId: string): void {
    this.store.dispatch(EligibilityFormActions.addDog({ quoteId }));
  }

  removeDog(quoteId: string, index: number): void {
    this.store.dispatch(
      EligibilityFormActions.removeDog({
        quoteId,
        index,
      })
    );
  }

  getPrequalificationVerbiage(): Observable<PrequalificationVerbiageModel[]> {
    return this.store.select(
      PrequalificationVerbiageSelectors.selectAllPrequalificationVerbiage
    );
  }
}
