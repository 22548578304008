import { Injectable } from '@angular/core';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, from, of } from 'rxjs';
import { catchError, exhaustMap, take } from 'rxjs/operators';

import { WatercraftExcludedExposureActions } from '@core/store/actions';
import { WatercraftExcludedExposureSelectors } from '@core/store/selectors';
import { WatercraftExposureService } from '@core/services/watercraft-exposure.service';

@Injectable()
export class WatercraftExcludedExposureEffect {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: WatercraftExposureService,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  upsertwatercraftExcludedExposure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          WatercraftExcludedExposureActions.upsertWatercraftExcludedExposure
        ),
        exhaustMap((action) =>
          this.store
            .select(
              WatercraftExcludedExposureSelectors.buildWatercraftExcludedExposureRequest(
                action.payload,
                action.correlationId || ''
              )
            )
            .pipe(take(1))
        ),
        exhaustMap((request) => {
          return this.service
            .addOrUpdateWatercraftExcludedExposure(request)
            .pipe(
              exhaustMap((response) => {
                return from([
                  WatercraftExcludedExposureActions.upsertWatercraftExcludedExposureSuccess(
                    {
                      payload: response,
                    }
                  ),
                ]);
              }),
              catchError((error) => {
                const saneError = this.errorSanitizerService.sanitizeError(
                  error,
                  request.productType
                );
                return of(
                  WatercraftExcludedExposureActions.upsertWatercraftExcludedExposureFail(
                    {
                      error: saneError,
                      entityId:
                        request.watercraftExcludedExposureBody
                          .watercraftExcludedExposure
                          .watercraftExcludedExposureId,
                    }
                  )
                );
              })
            );
        })
      ) as Observable<Action>
  );

  deletewatercraftExcludedExposure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          WatercraftExcludedExposureActions.deleteWatercraftExcludedExposure
        ),
        exhaustMap((action) =>
          this.store
            .select(
              WatercraftExcludedExposureSelectors.buildWatercraftExcludedExposureRequest(
                action.payload,
                action.correlationId || ''
              )
            )
            .pipe(take(1))
        ),
        exhaustMap((request) => {
          return this.service.deleteWatercraftExcludedExposure(request).pipe(
            exhaustMap(() => {
              return from([
                WatercraftExcludedExposureActions.deleteWatercraftExcludedExposureSuccess(
                  {
                    watercraftExcludedExposureId:
                      request.watercraftExcludedExposureBody
                        .watercraftExcludedExposure
                        .watercraftExcludedExposureId,
                  }
                ),
              ]);
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                request.productType
              );
              return of(
                WatercraftExcludedExposureActions.deleteWatercraftExcludedExposureFail(
                  {
                    error: saneError,
                    entityId:
                      request.watercraftExcludedExposureBody
                        .watercraftExcludedExposure
                        .watercraftExcludedExposureId,
                  }
                )
              );
            })
          );
        })
      ) as Observable<Action>
  );
}
