import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, merge, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DsmAdapter, responseUnused } from '../adapters/dsm.adapter';
import { ProductType, VehicleType } from '@app/core/models/api/dsm-types';
import {
  AddVehicleRequest,
  DeleteVehicleRequest,
  UpdateVehicleRequest,
} from '../models/api/request/vehicle-request.model';
import { ErrorModel } from '../store/entities/error/error.model';
import {
  PowersportsPageSubmission,
  sanitizeVehicleEntities,
  sanitizeVehicleEntity,
  VehicleAdditionalInterestEntity,
  VehicleEntity,
  VehiclePageSubmission,
} from '../store/entities/vehicle/vehicle.entity';
import { ProductsService } from './products.service';
import { VehicleInquiryService } from './vehicle-inquiry.service';
import { VehicleModel } from '../models/views/vehicle.model';
import { HeaderCardItem, UpdateSelectedEntity } from '../interfaces/interfaces';
import {
  CompositeActions,
  DriverVehiclePrefillActions,
  VehicleActions,
} from '@core/store/actions';
import { VehicleSelectors } from '../store/selectors';
import { VehiclesPageModel } from '../models/views/vehicle-page-model';
import { MsaPageModel } from '@app/powersports/msa/pages/msa-page.component';
import { StringUtils } from '@shared/utils/string.utils';
import { VehicleUtils } from '@shared/utils/vehicle.utils';
import { EligibleDiscountsEntity } from '@entities/eligible-discounts/eligible-discounts.entity';
import { BasicCompanyInformation } from '@app/additional-interests/additional-interest-holder-selection/additional-interest-holder-selection.component';
import { Nullable } from '@shared/utils/type.utils';
import { MemberModel } from '@core/models/views/person.model';

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  constructor(
    private adapter: DsmAdapter,
    private store: Store,
    private vehicleInquiryService: VehicleInquiryService,
    private productsService: ProductsService
  ) {}

  getAllVehicles(): Observable<VehicleEntity[]> {
    return this.store.select(VehicleSelectors.getAllVehicles);
  }

  getVehiclesByProductType(
    productType: ProductType
  ): Observable<VehicleEntity[]> {
    return this.store.select(
      VehicleSelectors.getAllVehiclesByProduct(productType)
    );
  }

  getSelectedVehiclesByProductType(
    productType: ProductType
  ): Observable<VehicleEntity[]> {
    return this.store.select(
      VehicleSelectors.getAllSelectedVehiclesByProduct(productType)
    );
  }

  getSavedVehiclesByProductType(
    productType: ProductType
  ): Observable<VehicleEntity[]> {
    return this.store.select(
      VehicleSelectors.getAllSavedVehiclesByProduct(productType)
    );
  }

  getAutoTypeVehicles(): Observable<VehicleEntity[]> {
    return this.store.select(VehicleSelectors.getAutoTypeVehicles);
  }

  getVehicleById(id: string | undefined): Observable<VehicleEntity | null> {
    return this.store.select(VehicleSelectors.getVehicleById(id));
  }

  getVehicleError(): Observable<ErrorModel | null> {
    return this.store.select(VehicleSelectors.getVehicleError);
  }

  getAllAutoVehicleModels(): Observable<VehicleModel[]> {
    return this.store.select(VehicleSelectors.getAllAutoVehicleModels);
  }

  getAllAutoSelectedVehicleModels(): Observable<VehicleModel[]> {
    return this.store.select(VehicleSelectors.getAllAutoSelectedVehicleModels);
  }

  getAllSelectedVehicleModelsByProductType(
    productType: ProductType
  ): Observable<VehicleModel[]> {
    return this.store.select(
      VehicleSelectors.getAllProductTypeSelectedVehicleModels(productType)
    );
  }

  getAllVehicleHeaderItems(): Observable<HeaderCardItem[]> {
    return this.store.select(VehicleSelectors.getAllVehicleHeaderItems);
  }

  getAllMsaHeaderItems(): Observable<HeaderCardItem[]> {
    return this.store.select(VehicleSelectors.getAllMsaHeaderItems);
  }
  getAllBoatHeaderItems(): Observable<HeaderCardItem[]> {
    return this.store.select(VehicleSelectors.getAllBoatHeaderItems);
  }
  getAllRVHeaderItems(): Observable<HeaderCardItem[]> {
    return this.store.select(VehicleSelectors.getAllRVHeaderItems);
  }
  getVehiclesPageModel(): Observable<VehiclesPageModel> {
    return this.store.select(VehicleSelectors.getVehiclesPageModel);
  }

  getMsaPageModel(): Observable<MsaPageModel> {
    return this.store.select(VehicleSelectors.getMsaPageModel);
  }
  getBoatPageModel(): Observable<MsaPageModel> {
    return this.store.select(VehicleSelectors.getBoatPageModel);
  }
  getRvPageModel(): Observable<MsaPageModel> {
    return this.store.select(VehicleSelectors.getRvPageModel);
  }
  getAllBoatVehicleModels(): Observable<VehicleModel[]> {
    return this.store.select(VehicleSelectors.getAllBoatVehicleModels);
  }
  getAllRVVehicleModels(): Observable<VehicleModel[]> {
    return this.store.select(VehicleSelectors.getAllRVVehicleModels);
  }
  addVehicle(vehicle: VehicleEntity): void {
    this.store.dispatch(VehicleActions.addVehicle({ payload: vehicle }));
  }

  updateVehicle(vehicle: VehicleEntity): void {
    this.store.dispatch(VehicleActions.updateVehicle({ payload: vehicle }));
  }

  deleteVehicle(vehicle: VehicleEntity): void {
    if (vehicle.vehicleId) {
      this.store.dispatch(VehicleActions.deleteVehicle({ payload: vehicle }));
    }
  }

  addTemporaryNewVehicle(
    index: number,
    vehicleType: VehicleType,
    productType: ProductType
  ): void {
    const newTempVehicleEntity: VehicleEntity = {
      index: index,
      entityId: StringUtils.generateEntityId(),
      vehicleType,
      productType,
      vehicleId: `new-${StringUtils.generateEntityId()}`,
    };
    this.store.dispatch(
      VehicleActions.addNewTempVehicle({ payload: newTempVehicleEntity })
    );
  }

  updateVehicleSelected(updatedSelected: UpdateSelectedEntity): void {
    this.store.dispatch(
      VehicleActions.updatedVehicleSelected({ payload: updatedSelected })
    );
  }

  submitVehiclePage(submission: VehiclePageSubmission): void {
    this.store.dispatch(CompositeActions.submitVehiclePage({ submission }));
  }

  submitMsaPage(submission: PowersportsPageSubmission): void {
    this.store.dispatch(CompositeActions.submitMsaPage({ submission }));
  }

  submitRVPage(submission: PowersportsPageSubmission): void {
    this.store.dispatch(CompositeActions.submitRVPage({ submission }));
  }

  submitBoatPage(submission: PowersportsPageSubmission): void {
    this.store.dispatch(CompositeActions.submitBoatPage({ submission }));
  }

  storeVehicle(vehicle: Partial<VehicleEntity>): void {
    if (
      vehicle.prefillId &&
      vehicle.vehicleId?.toString().startsWith('PREFILL')
    ) {
      this.store.dispatch(
        DriverVehiclePrefillActions.updatePrefillVehicle({
          payload: {
            id: vehicle.prefillId,
            changes: vehicle,
          },
        })
      );
    } else {
      this.store.dispatch(
        VehicleActions.storeVehicle({
          payload: vehicle as VehicleEntity,
        })
      );
    }
  }

  /* REST calls. No Store interaction.
   * These should only be called from VehicleEffects.
   */

  callGetVehicle(
    productType: ProductType,
    quoteId: string,
    vehicleId: string
  ): Observable<VehicleEntity> {
    return this.adapter.request(
      sanitizeVehicleEntity,
      productType,
      'GET',
      `/quotes/${quoteId}/vehicles/${vehicleId}`,
      'get-vehicle'
    );
  }

  callGetVehicles(
    productType: ProductType,
    quoteId: string | undefined
  ): Observable<VehicleEntity[]> {
    if (!quoteId) {
      return throwError(
        () => new Error(`no quoteId for product ${productType}`)
      );
    }
    return this.adapter.request(
      sanitizeVehicleEntities,
      productType,
      'GET',
      `/quotes/${quoteId}/vehicles`,
      'get-vehicles'
    );
  }

  callAddVehicle(request: AddVehicleRequest): Observable<VehicleEntity> {
    return this.adapter.request(
      sanitizeVehicleEntity,
      request.productType as ProductType,
      'POST',
      `/quotes/${request.quoteId}/vehicles`,
      'add-vehicle',
      {
        body: request.body,
      }
    );
  }

  callUpdateVehicle(request: UpdateVehicleRequest): Observable<VehicleEntity> {
    return this.adapter.request(
      sanitizeVehicleEntity,
      request.productType as ProductType,
      'PUT',
      `/quotes/${request.quoteId}/vehicles/${request.body.vehicle.vehicleId}`,
      'update-vehicle',
      {
        body: request.body,
      }
    );
  }

  callDeleteVehicle(request: DeleteVehicleRequest): Observable<unknown> {
    return this.adapter.request(
      responseUnused,
      request.productType as ProductType,
      'DELETE',
      `/quotes/${request.quoteId}/vehicles/${request.vehicleId}`,
      'delete-vehicle'
    );
  }

  // TODO - Move to Selectors file

  fillMissingEntityFields(vehicle: VehicleEntity): Observable<VehicleEntity> {
    if (!vehicle.vehicleType) {
      vehicle = {
        ...vehicle,
        vehicleType: 'auto',
      };
    }

    if (!vehicle.vin && vehicle.vehicleType === 'auto') {
      if (!vehicle.bodyType || !vehicle.bodyStyle) {
        if (!vehicle.year || !vehicle.make || !vehicle.model) {
          return throwError(
            '(vin) or (year,make,model,series) is required to add vehicle'
          );
        }
        if (vehicle.vehicleType === 'auto' && !vehicle.series) {
          return throwError(
            '(vin) or (year,make,model,series) is required to add vehicle'
          );
        }
        if (vehicle.vehicleType === 'auto' && vehicle.series) {
          return this.vehicleInquiryService
            .getBodyTypeAndStyle(
              vehicle.year,
              vehicle.make,
              vehicle.model,
              vehicle.series,
              'auto'
            )
            .pipe(
              map((response) => {
                if (!response) {
                  throw new Error(
                    `Failed to determine body type and style for ${vehicle.year} ${vehicle.make} ${vehicle.model}`
                  );
                }
                return {
                  ...vehicle,
                  ...response,
                };
              })
            );
        } else {
          return of(vehicle);
        }
      }
    }
    return of(vehicle);
  }

  composeAddVehicleRequest(
    vehicle: VehicleEntity
  ): Observable<AddVehicleRequest> {
    return combineLatest([
      this.productsService.getQuoteIdForProduct(
        vehicle.productType as ProductType
      ),
    ]).pipe(
      map(
        ([quoteId]) =>
          ({
            quoteId,
            productType: vehicle.productType,
            body: {
              vehicle: {
                ...vehicle,
                vehicleId: '',
              },
            },
          } as AddVehicleRequest)
      ),
      switchMap((request) =>
        this.applyMissingAntiTheftToVehicleRequest(request)
      ),
      map((request) => this.sanitizeVehicleRequest(request))
    );
  }

  composeUpdateVehicleRequest(
    vehicle: VehicleEntity
  ): Observable<UpdateVehicleRequest> {
    return combineLatest([
      this.productsService.getQuoteIdForProduct(
        vehicle.productType as ProductType
      ),
      this.store.select(
        VehicleSelectors.getVehicleById(vehicle.vehicleId || '')
      ),
    ]).pipe(
      map(
        ([quoteId, entity]) =>
          ({
            quoteId,
            productType: vehicle.productType,
            body: {
              vehicle: {
                ...entity,
                ...vehicle,
              },
            },
          } as UpdateVehicleRequest)
      ),
      switchMap((request) => {
        if (
          request.body.vehicle.vin ||
          request.body.vehicle.vehicleType !== 'auto'
        ) {
          return of(request);
        }
        return this.vehicleInquiryService
          .getBodyTypeAndStyle(
            request.body.vehicle.year || 0,
            request.body.vehicle.make || '',
            request.body.vehicle.model || '',
            request.body.vehicle.series || '',
            VehicleUtils.inquiryPathFromVehicleType(
              request.body.vehicle.vehicleType
            )
          )
          .pipe(
            map((response) => {
              return {
                ...request,
                body: {
                  ...request.body,
                  vehicle: this.removeUndefinedFields({
                    ...request.body.vehicle,
                    ...response,
                  }),
                },
              };
            })
          );
      }),
      switchMap((request) =>
        this.applyMissingAntiTheftToVehicleRequest(request)
      ),
      map((request) => this.sanitizeVehicleRequest(request))
    );
  }

  private applyMissingAntiTheftToVehicleRequest<
    T extends AddVehicleRequest | UpdateVehicleRequest
  >(input: T): Observable<T> {
    if (input.productType === 'PersonalAuto') {
      // We want VI rates if they are already present and can be delivered synchronously. Otherwise null.
      return merge(
        this.vehicleInquiryService
          .getRates(
            input.body.vehicle.year || 0,
            input.body.vehicle.make || '',
            input.body.vehicle.model || '',
            'auto'
          )
          .pipe(catchError(() => of(null))),
        of(null)
      ).pipe(
        map((rates) => {
          let vi =
            rates?.retrieveVehicleSeriesRatesOdbiResponse.vehicleModel.find(
              (m) =>
                m.make === input.body.vehicle.make &&
                m.model === input.body.vehicle.model &&
                m.seriesDescription === input.body.vehicle.series
            );
          if (
            !vi &&
            rates?.retrieveVehicleSeriesRatesOdbiResponse.vehicleModel
              .length === 1 &&
            !input.body.vehicle.series
          ) {
            // If series wasn't provided, and there's just one response, use it.
            vi = rates.retrieveVehicleSeriesRatesOdbiResponse.vehicleModel[0];
          }
          if (vi?.antiTheftDevice) {
            return this.applyMissingAntiTheftToVehicleRequestFromViResponse(
              input,
              vi.antiTheftDevice
            );
          }
          return input;
        })
      );
    }
    return of(input);
  }

  private applyMissingAntiTheftToVehicleRequestFromViResponse<
    T extends AddVehicleRequest | UpdateVehicleRequest
  >(input: T, antiTheftDevice: string): T {
    let discount = input.body.vehicle.eligibleDiscounts?.find(
      (d) => d.eligibleDiscountId === 'AntiTheft'
    );
    if (
      discount?.selectedOptionValue === 'true' &&
      discount?.qualifyingInformation?.selectedAntiTheft?.includes(
        antiTheftDevice
      )
    ) {
      return input;
    }
    if (!discount) {
      return {
        ...input,
        body: {
          ...input.body,
          vehicle: {
            ...input.body.vehicle,
            eligibleDiscounts: [
              ...(input.body.vehicle.eligibleDiscounts || []),
              {
                eligibleDiscountId: 'AntiTheft',
                selectedOptionValue: 'true',
                qualifyingInformation: {
                  selectedAntiTheft: [antiTheftDevice],
                },
              },
            ],
          },
        },
      };
    }
    if (discount.qualifyingInformation?.selectedAntiTheft) {
      discount = {
        ...discount,
        selectedOptionValue: 'true',
        qualifyingInformation: {
          ...discount.qualifyingInformation,
          selectedAntiTheft: [
            ...discount.qualifyingInformation.selectedAntiTheft,
            antiTheftDevice,
          ],
        },
      };
    } else {
      discount = {
        ...discount,
        selectedOptionValue: 'true',
        qualifyingInformation: {
          selectedAntiTheft: [antiTheftDevice],
        },
      };
    }
    const eligibleDiscounts =
      input.body.vehicle.eligibleDiscounts?.filter(
        (d) => d.eligibleDiscountId !== 'AntiTheft'
      ) || [];
    eligibleDiscounts.push(discount);
    return {
      ...input,
      body: {
        ...input.body,
        vehicle: {
          ...input.body.vehicle,
          eligibleDiscounts,
        },
      },
    };
  }

  private sanitizeVehicleRequest<
    T extends AddVehicleRequest | UpdateVehicleRequest
  >(input: T): T {
    const output = {
      ...input,
      body: {
        ...input.body,
        vehicle: {
          ...input.body.vehicle,
        },
      },
    };
    if (output.body.vehicle.eligibleDiscounts) {
      output.body.vehicle.eligibleDiscounts =
        output.body.vehicle.eligibleDiscounts.map((d) => {
          if (!d.selectedOptionValue) {
            return {
              ...d,
              selectedOptionValue: this.defaultEligibleDiscountValue(d),
            };
          } else {
            return d;
          }
        });
    }
    if (!output.body.vehicle.vehicleId) {
      delete output.body.vehicle.vehicleId;
    }
    if (!output.body.vehicle.vehicleSubType) {
      delete output.body.vehicle.vehicleSubType;
    }
    if (output.body.vehicle.vin) {
      output.body.vehicle.vin = output.body.vehicle.vin.trim();
    }
    if (
      output.body.vehicle.hasOwnProperty('customizationAmount') &&
      !output.body.vehicle.customizationAmount
    ) {
      output.body.vehicle.customizationAmount = null as any;
      output.body.vehicle.customizationDetails = null as any;
    }
    if (output.body.vehicle.additionalInterests) {
      output.body.vehicle.additionalInterests =
        output.body.vehicle.additionalInterests.map((ai) => {
          ai = { ...ai };
          if (ai.companyName) {
            ai.companyName = ai.companyName.trim();
          }
          if (typeof ai.additionalInterestId === 'string') {
            delete ai.additionalInterestId;
          }
          return ai;
        });
    }
    return output;
  }

  /* There's a bug in DSM, where they can't accept null for eligibleDiscounts that they type boolean.
   * (and for string-typed eligibleDiscounts, we *must* send null to unset).
   * Until that gets fixed, we'll pick off discounts that we know are typed boolean in DSM, and send "false" instead of null.
   * -sommea1 2023-07-18
   */
  private defaultEligibleDiscountValue(
    discount: EligibleDiscountsEntity
  ): string | null {
    switch (discount.eligibleDiscountId) {
      case 'FiberglassConstruction':
      case 'AutomaticTirePressure':
        return 'false';
    }
    return null;
  }

  private removeUndefinedFields(mutableInput: {
    [id: string]: unknown;
  }): object {
    for (const k of Object.keys(mutableInput)) {
      if (mutableInput[k] === undefined) {
        delete mutableInput[k];
      }
    }
    return mutableInput;
  }

  composeDeleteVehicleRequest(
    vehicle: VehicleEntity
  ): Observable<DeleteVehicleRequest> {
    return combineLatest([
      this.productsService.getQuoteIdForProduct(
        vehicle.productType as ProductType
      ),
    ]).pipe(
      map(
        ([quoteId]) =>
          ({
            quoteId,
            productType: vehicle.productType,
            vehicleId: vehicle.vehicleId,
          } as DeleteVehicleRequest)
      )
    );
  }

  getUniqueAdditionalInterestCompanies(): Observable<
    BasicCompanyInformation[]
  > {
    return this.store.select(
      VehicleSelectors.getUniqueAdditionalInterestCompanies
    );
  }

  getAdditionalInterestsForVehicle(
    vehicleId: string | undefined
  ): Observable<Nullable<VehicleAdditionalInterestEntity[]>> {
    return this.store.select(
      VehicleSelectors.getAdditionalInterestsForVehicle(vehicleId)
    );
  }

  getSelectedMembersAndPersonInterests(): Observable<MemberModel[]> {
    return this.store.select(
      VehicleSelectors.getSelectedMembersAndPersonInterests
    );
  }
}
