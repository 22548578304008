<div class="modal-header">
  <h4>Add additional owned unit not rented</h4>
</div>

<form
  class="modal-body bolt-container"
  [formGroup]="form"
  (submit)="onSave()"
>
  <div class="bolt-row">
    <div class="bolt-col">
      <bolt-textfield
        formControlName="locationDescription"
        label="Location"
        required
      ></bolt-textfield>
    </div>
  </div>
  <div class="bolt-row" *ngIf="showNumberOfUnits">
    <div class="bolt-col">
      <bolt-select
        formControlName="numberOfUnits"
        label="Number of units"
        required
      >
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
      </bolt-select>
    </div>
  </div>
  <div class="bolt-row" *ngIf="showLimit">
    <div class="bolt-col">
      <bolt-textfield
        formControlName="limit"
        label="Limit"
        required
      ></bolt-textfield>
    </div>
  </div>
</form>

<div class="modal-footer">
  <bolt-button-bar>
    <bolt-button
      slot="cancel"
      iconleft="trash"
      (click)="onDelete()"
    >Delete</bolt-button>
    <bolt-button
      slot="back"
      (click)="onCancel()"
    >Cancel</bolt-button>
    <bolt-button
      slot="forward"
      type="primary"
      (click)="onSave()"
    >Save</bolt-button>
  </bolt-button-bar>
</div>
