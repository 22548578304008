import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ModifierEntity } from '@core/store/entities/modifier/modifier.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';

export const addModifier = createAction(
  '[Modifier] Add Modifier',
  props<{ payload: ModifierEntity }>()
);

export const addModifierError = createAction(
  '[Modifier] Add Modifier Error',
  props<{ error: ErrorModel }>()
);

export const addModifierSuccess = createAction(
  '[Modifier] Add Modifier Success',
  props<{ payload: ModifierEntity }>()
);

export const updateModifier = createAction(
  '[Modifier] Update Modifier',
  props<{ payload: ModifierEntity }>()
);

export const updateModifierError = createAction(
  '[Modifier] Update Modifier Error',
  props<{ error: ErrorModel }>()
);

export const updateModifierSuccess = createAction(
  '[Modifier] Update Modifier Success',
  props<{ payload: Update<ModifierEntity> }>()
);

export const deleteModifier = createAction(
  '[Modifier] Delete Modifier',
  props<{ payload: string }>()
);

export const deleteModifierError = createAction(
  '[Modifier] Delete Modifier Error',
  props<{ error: ErrorModel }>()
);

export const deleteModifierSuccess = createAction(
  '[Modifier] Delete Modifier Success',
  props<{ payload: string }>()
);
