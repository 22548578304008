import { Injectable } from '@angular/core';
import { LogEvent } from '../models/api/log.model';

/**
 * Filters log entries to eliminate PII etc, or to reduce size.
 */
@Injectable({
  providedIn: 'root',
})
export class LogRedactionService {
  static readonly RECURSION_LIMIT = 10;

  redact(input: LogEvent): LogEvent | null {
    if (!input || typeof input !== 'object') {
      return input;
    }
    try {
      if (this.lengthOnlyForGisAndVinResponses(input)) {
        return input;
      }
    } catch (e) {}
    return this.sanitizeObjectRecursively(
      input,
      LogRedactionService.RECURSION_LIMIT
    ) as LogEvent;
  }

  /* Replace response body with just the relevant count, in place.
   * Returns true if matched.
   * There is no value in filling Splunk with every GIS response :P
   * ^ wellllll actually, Josh disagrees (CODEMINERS-6176)
   */
  private lengthOnlyForGisAndVinResponses(event: LogEvent): boolean {
    if (event.evtType === 'API' && event.event?.endsWith('-response')) {
      if (typeof event.message === 'object') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const message = (event.message || {}) as { [key: string]: any };
        switch (event.apiInfo?.logicalName) {
          case 'suggest-address':
            event.message = '' + message.suggestions?.length;
            return true;
          // Not standardize-address, let those redact generically.
          case 'vehicle-model-years':
            event.message =
              '' + message.retrieveVehicleModelYearsResponse?.modelYear.length;
            return true;
          case 'vehicle-model-makes':
            event.message =
              '' +
              message.retrieveVehicleModelMakesResponse?.vehicleMake.length;
            return true;
          case 'vehicle-models':
            event.message =
              '' + message.retrieveVehicleModelsResponse?.model.length;
            return true;
          case 'vehicle-models-with-description':
            event.message =
              '' +
              message.retrieveVehicleModelsWithDescriptionResponse
                ?.modelWithDescription.length;
            return true;
          case 'vehicle-model-series':
            event.message =
              '' +
              message.retrieveVehicleModelSeriesResponse?.modelSeries.length;
            return true;
          case 'vehicle-series-rates-odbi':
            event.message =
              '' +
              message.retrieveVehicleSeriesRatesOdbiResponse?.vehicleModel
                .length;
            return true;
          case 'vehicle-series-rates-cc':
            event.message =
              '' +
              message.retrieveVehicleSeriesRatesCCResponse?.vehicleModel.length;
            return true;
        }
      }
    }
    return false;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */

  private sanitizeObjectRecursively(input: any, limit: number): any {
    if (typeof input === 'function') {
      return null;
    }
    if (typeof input === 'string') {
      return this.sanitizeString(input);
    }
    if (!input || typeof input !== 'object') {
      return input;
    }
    limit--;
    if (limit < 0) {
      return '(recursion limit)' as any;
    }
    if (input instanceof Array) {
      return input.map((child) => this.sanitizeObjectRecursively(child, limit));
    }
    try {
      const output = {} as any;
      for (const key of Object.keys(input)) {
        if (!this.validKey(key)) {
          continue;
        }
        const value = this.sanitizeObjectWithKey(key, input[key]);
        output[key] = this.sanitizeObjectRecursively(value, limit);
      }
      return output;
    } catch (e) {
      return '(error processing log object)';
    }
  }

  private sanitizeString(input: string): string {
    let match;
    if (match = input.match(/^(.*Dwelling at )[0-9A-Za-z ,-.]+(:.*)$/)) {
      input = match[1] + '****' + match[2];
    }
    while (match = input.match(/^(.*address for )([A-Za-z])[A-Za-z, -]+( does not match.*)$/)) {
      input = match[1] + match[2] + '***' + match[3];
    }
    while (match = input.match(/^(.*address on file, )[A-Za-z0-9, -]* [A-Z]{2} \d{5}(-\d{4})?(.*)$/)) {
      input = match[1] + '****' + match[3];
    }
    while (match = input.match(/^(.*)\[([A-Za-z])[A-Za-z -]*, DOB \d{2}\/\d{2}\/\d{4}, DL( [A-Z]{2})?[ A-Za-z0-9\*-]*\](.*)$/)) {
      input = match[1] + `[${match[2]}***, DOB **/**/****, DL${match[3] || ''}]` + match[4];
    }
    while (match = input.match(/^(.*mismatch in date of birth for )([A-Za-z])[A-Za-z -]+(\..*)$/)) {
      input = match[1] + match[2] + '***' + match[3];
    }
    while (match = input.match(/^(.*DOB appears to be )\d{2}\/\d{2}\/\d{4}(.*)$/)) {
      input = match[1] + '**/**/****' + match[2];
    }
    while (match = input.match(/^(.*address for Driver )([A-Za-z])[A-Za-z -]+ appears to be [A-Za-z0-9 -]* and does not match(.*)$/)) {
      input = match[1] + match[2] + '*** appears to be **** and does not match' + match[3];
    }
    while (match = input.match(/^(.*Unable to verify driver [A-Za-z])[A-Za-z -]+ at address\(es\) [A-Za-z0-9 -]*(\..*)$/)) {
      input = match[1] + '*** at address(es) ****' + match[2];
    }
    while (match = input.match(/^(.*does not match named insured or spouse. \[[A-Za-z])[A-Za-z, -]+ of Vehicle(.*)$/)) {
      input = match[1] + '**** of Vehicle' + match[2];
    }
    return input;
  }

  private validKey(key: string): boolean {
    if (!key) {
      return false;
    }
    if (typeof key !== 'string') {
      return false;
    }
    if (key.startsWith('_')) {
      return false;
    }
    return true;
  }

  private sanitizeObjectWithKey(key: string, value: any): any {
    if (value) {
      switch (key) {
        case 'prequalificationAnswers':
          return this.sanitizePrequalificationAnswers(value);
        case 'discounts':
          return this.sanitizeDiscounts(value);
        case 'eligibleDiscounts':
          return this.sanitizeEligibleDiscounts(value);
        case 'drivers':
          return this.sanitizeDrivers(value);
        case 'vehicles':
          return this.sanitizeVehicles(value);
        case 'producers':
          return this.sanitizeProducers(value);
        case 'coverages':
          return this.sanitizeCoverages(value);
      }
    }
    return value;
  }

  private sanitizePrequalificationAnswers(input: any): any {
    if (!(input instanceof Array)) {
      return input;
    }
    return input.map((v) => ({
      code: v.code || v.questionCode || '',
      answer: v.answer ?? v.answerValue ?? '',
    }));
  }

  private sanitizeDiscounts(input: any): any {
    if (!(input instanceof Array)) {
      return input;
    }
    return input
      .filter((v) => v.isDiscountApplied)
      .map((v) => v.description || null);
  }

  private sanitizeEligibleDiscounts(input: any): any {
    if (!(input instanceof Array)) {
      return input;
    }
    return input.map((v) => `${v.name}=${v.selectedOptionValue}`);
  }

  private sanitizeDrivers(input: any): any {
    if (!(input instanceof Array)) {
      return input;
    }
    return input.map((v) => ({
      ...v,
      person: `${v.person?.firstName} ${v.person?.lastName}`,
      // eligibleDiscounts will be handled recursively
    }));
  }

  private sanitizeVehicles(input: any): any {
    if (!(input instanceof Array)) {
      return input;
    }
    // eligibleDiscounts will be handled recursively
    return input;
  }

  private sanitizeProducers(input: any): any {
    if (!(input instanceof Array)) {
      return input;
    }
    return input.map((v) => ({
      agentName: v.agentName || '',
      agentState: v.agentState || '',
      agencyCode: v.agencyCode || '',
      producerCode: v.producerCode || ''
    }));
  }

  private sanitizeCoverages(input: any): any {
    if (!(input instanceof Array)) {
      return input;
    }
    return input.map((v) => ({
      coverageId: v.coverageId,
      selectedValue:
        '[' +
        v.selectedValue?.map((sv: any) => `${sv.code}=${sv.value}`).join(',') +
        ']',
    }));
  }
}
