import { AccountEffect } from '@entities/account/account.effect';
import { AgencyEffects } from '@entities/agency/agency.effect';
import { BillingAccountEffects } from '@entities/billing-account/billing-account.effect';
import { BillingEffects } from '@entities/billing/billing.effect';
import { BusinessLoggingEffects } from '@core/store/business-logging/business-logging.effect';
import { CompraterRoutingEffects } from '@entities/comprater-routing/comprater-routing.effect';
import { CoverageEffects } from '@entities/coverage/coverage.effect';
import { CoverageSyncEffects } from '@entities/coverage-sync/coverage-sync.effect';
import { CoveredLocationEffects } from '@entities/covered-location/covered-location.effect';
import { CoveredLocationTaskEffects } from '@entities/task/effects/covered-location-task.effect';
import { CurrentCarrierEffects } from '@entities/current-carrier/current-carrier.effect';
import { DigitalIdCardsTextEffects } from '@entities/digital-id-cards-text/digital-id-cards-text.effect';
import { DocumentEffects } from '@entities/document/document.effect';
import { DriverEffects } from '@entities/driver/driver.effect';
import { DriverVehicleAssignmentEffects } from '@entities/driver-vehicle-assignment/driver-vehicle-assignment.effect';
import { DriverVehiclePrefillEffects } from '@entities/driver-vehicle-prefill/driver-vehicle-prefill.effect';
import { EligibilityFormEffects } from '@app/eligibility/store/eligibility-form.effect';
import { EligibleDiscountsEffects } from '@entities/eligible-discounts/eligible-discounts.effect';
import { ErrorEffects } from '@entities/error/error.effect';
import { EscrowEffects } from '@entities/escrow/escrow.effect';
import { FeatureFlagEffects } from '@entities/feature-flag/feature-flag.effect';
import { HydrationEffects } from '@core/store/hydrate/hydrate.effect';
import { HouseholdMemberEffects } from '@entities/household-member/household-member.effect';
import { LayoutEffects } from '@entities/layout/layout.effect';
import { MetadataEffects } from '@entities/metadata/metadata.effect';
import { MortgageEffects } from '@entities/mortgage/mortgage.effect';
import { MultiProductDiscountEffects } from '@entities/multi-product-discount/multi-product-discount.effect';
import { NavigationEffects } from '@entities/navigation/navigation.effect';
import { MemberEffects } from '@entities/member/member.effects';
import { PageAlertEffect } from '@entities/page-alert/page-alert.effect';
import { PageSubmissionEffects } from '@core/store/page-submission/page-submission.effect';
import { PersonaEffects } from '@entities/persona/persona.effect';
import { PolicyIssueEffects } from '@entities/policy-issue/policy-issue.effect';
import { PolicyNumberEffects } from '@entities/policy-number/policy-number.effect';
import { PolicyholderEffects } from '@entities/policyholder/policyholder.effect';
import { PowersportsVehicleInquiryEffect } from '@entities/vehicle-inquiry/powersports-vehicle-inquiry.effect';
import { PreBindDocumentsEffects } from '@entities/pre-bind-documents/pre-bind-documents.effect';
import { PrequalificationVerbiageEffects } from '@app/eligibility/prequalification-verbiage/prequalification-verbiage.effect';
import { PropertyFormEffects } from '@core/store/property-form/property-form.effect';
import { ProtectiveDevicesEffects } from '@entities/protective-devices/protective-devices.effect';
import { QuoteEffects } from '@entities/quote/quote.effect';
import { QuoteStatusChangesEffect } from '@entities/quote/quote-status-changes.effect';
import { RetrieveEffects } from '@core/store/retrieve/retrieve.effect';
import { RouterEffects } from '@core/store/router/router.effect';
import { ScheduledCategoryEffects } from '@entities/scheduled-categories/scheduled-category.effect';
import { TaskEffects } from '@entities/task/effects/task.effect';
import { TelematicsEffect } from '@entities/telematics/telematics.effect';
import { UmbrellaTaskEffects } from '@entities/task/effects/umbrella-task.effects';
import { UnderlyingPolicyEffect } from '@entities/underlying-policy/underlying-policy.effect';
import { UWReportsEffects } from '@entities/uw-reports/uw-reports.effect';
import { VehicleEffects } from '@entities/vehicle/vehicle.effect';
import { VehicleInquiryEffects } from '@entities/vehicle-inquiry/vehicle-inquiry.effect';
import { SessionEffects } from '@entities/session/session.effect';
import { VehicleTaskEffects } from '@entities/task/effects/vehicle-task.effects';
import { MemberTaskEffects } from '@entities/task/effects/member-task.effects';
import { ProductEffects } from '@entities/product/product.effect';
import { QuoteLetterEffects } from '@entities/quote-letter/quote-letter.effect';
import { AmfBankAccountsEffects } from '@entities/amf-bank-accounts/amf-bank-accounts.effect';
import { VehicleExposureEffect } from '@entities/exposures/vehicle-exposures/vehicle-exposures.effect';
import { LocationExposureEffect } from '@entities/exposures/location-exposures/location-exposures.effect';
import { WatercraftExposureEffect } from '@entities/exposures/watercraft-exposures/watercraft-exposures.effect';
import { VehicleExcludedExposureEffect } from '@entities/exposures/vehicle-excluded-exposures/vehicle-excluded-exposures.effect';
import { LocationExcludedExposureEffect } from '@entities/exposures/location-excluded-exposures/location-excluded-exposures.effect';
import { WatercraftExcludedExposureEffect } from '@entities/exposures/watercraft-excluded-exposures/watercraft-excluded-exposures.effect';

export const effects = [
  AccountEffect,
  AgencyEffects,
  AmfBankAccountsEffects,
  BillingAccountEffects,
  BillingEffects,
  BusinessLoggingEffects,
  CompraterRoutingEffects,
  CoverageEffects,
  CoverageSyncEffects,
  CoveredLocationEffects,
  CoveredLocationTaskEffects,
  CurrentCarrierEffects,
  DigitalIdCardsTextEffects,
  DocumentEffects,
  DriverEffects,
  DriverVehicleAssignmentEffects,
  DriverVehiclePrefillEffects,
  EligibilityFormEffects,
  EligibleDiscountsEffects,
  ErrorEffects,
  EscrowEffects,
  FeatureFlagEffects,
  HydrationEffects,
  HouseholdMemberEffects,
  LayoutEffects,
  LocationExposureEffect,
  LocationExcludedExposureEffect,
  MemberTaskEffects,
  MetadataEffects,
  MortgageEffects,
  MultiProductDiscountEffects,
  NavigationEffects,
  MemberEffects,
  PageAlertEffect,
  PageSubmissionEffects,
  PersonaEffects,
  PolicyIssueEffects,
  PolicyNumberEffects,
  PolicyholderEffects,
  PowersportsVehicleInquiryEffect,
  PreBindDocumentsEffects,
  PrequalificationVerbiageEffects,
  ProductEffects,
  PropertyFormEffects,
  ProtectiveDevicesEffects,
  QuoteEffects,
  QuoteLetterEffects,
  QuoteStatusChangesEffect,
  RetrieveEffects,
  RouterEffects,
  RouterEffects,
  ScheduledCategoryEffects,
  SessionEffects,
  TaskEffects,
  TelematicsEffect,
  UmbrellaTaskEffects,
  UnderlyingPolicyEffect,
  UWReportsEffects,
  VehicleEffects,
  VehicleExposureEffect,
  VehicleInquiryEffects,
  VehicleTaskEffects,
  WatercraftExposureEffect,
  VehicleExcludedExposureEffect,
  WatercraftExcludedExposureEffect,
];
