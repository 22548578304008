import { createAction, props } from '@ngrx/store';
import { DriverVehicleAssignmentEntity } from '@core/store/entities/driver-vehicle-assignment/driver-vehicle-assignment.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { ProductType } from '@core/models/api/dsm-types';
import { VehicleEntity } from '../vehicle/vehicle.entity';

export const getDriverVehicleAssignment = createAction(
  '[DVA] Get Driver Vehicle Assignment',
  props<{ product: ProductType; correlationId?: string }>()
);

export const getDriverVehicleAssignmentError = createAction(
  '[DVA] Get Driver Vehicle Assignment Error',
  props<{ error: ErrorModel; correlationId?: string }>()
);

export const getDriverVehicleAssignmentSuccess = createAction(
  '[DVA] Get Driver Vehicle Assignment Success',
  props<{
    payload: DriverVehicleAssignmentEntity[];
    correlationId?: string;
    productType: ProductType;
  }>()
);

export const addDriverVehicleAssignments = createAction(
  '[DVA] Add Driver Vehicle Assignment',
  props<{
    productType: ProductType;
    assignments: DriverVehicleAssignmentEntity[];
  }>()
);

export const addDriverVehicleAssignmentError = createAction(
  '[DVA] Add Driver Vehicle Assignment Error',
  props<{ payload: ErrorModel }>()
);

export const addDriverVehicleAssignmentSuccess = createAction(
  '[DVA] Add Driver Vehicle Assignment Success',
  props<{
    payload: DriverVehicleAssignmentEntity[];
    productType: ProductType;
  }>()
);

export const checkForAutoAssign = createAction(
  '[DVA] Check For One to One DVA',
  props<{ productType: ProductType; vehicle: VehicleEntity }>()
);
