<div class="popup" *ngIf="resultsTemplates.length" #popup>
  <ng-container
    [ngTemplateOutlet]="resultsTemplates.first.templateRef"
  ></ng-container>
</div>
<bolt-textfield
  #boltTextField
  [label]="label"
  [instructionaltext]="instructionaltext"
  [error]="error"
  [required]="required"
  [optionaltext]="optionaltext"
  [disabled]="disabled"
  [type]="type"
  [maxlength]="maxlength"
  [inputsize]="inputsize"
  [prefix]="prefix"
  [prefixsymbol]="prefixsymbol"
  [suffix]="suffix"
  [suffixsymbol]="suffixsymbol"
  [aligninput]="aligninput"
  [invalid]="invalid"
  [spellcheck]="spellcheck"
  autocomplete="off"
  [autocorrect]="autocorrect"
  [autocapitalize]="autocapitalize"
  [inputmode]="inputmode"
  (input)="onInput()"
  (focus)="onFocus()"
  [attr.id]="'twmc - searchText'"
  [attr.name]="'twmc - searchText'"
></bolt-textfield>
