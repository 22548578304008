import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { ProductModel } from '@core/store/entities/product/product.model';
import { UrlType } from '@shared/services/pc-url.service';

@Component({
  selector: 'nwx-billing-purchase-issue',
  templateUrl: './billing-purchase-issue.component.html',
  styleUrls: ['./billing-purchase-issue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingPurchaseIssueComponent {
  @Input() products!: ProductModel[];
  @Input() errorMessage!: string;

  constructor() {}

  getContent(): string {
    if (this.products.length === 1) {
      return 'Due to temporary issues, this policy needs to be purchased in PolicyCenter. Full policy purchase and issuance through Nationwide Express will be back soon.';
    } else {
      return 'Due to temporary issues, these policies need to be purchased in PolicyCenter. Full policy purchase and issuance through Nationwide Express will be back soon.';
    }
  }

  getUrlType(): UrlType {
    if (this.products.length > 1) {
      return 'Account';
    } else {
      return 'Job';
    }
  }

  getProductType(): ProductType | undefined {
    if (this.products.length === 1) {
      return this.products[0].type;
    } else {
      return undefined;
    }
  }

  getButtonText(): string {
    if (this.products.length === 1) {
      return 'Purchase and issue policy in PolicyCenter';
    } else {
      return 'Purchase and issue policies in PolicyCenter';
    }
  }
}
