import { RetrieveActions, SessionActions } from '@core/store/actions';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { Nullable } from '@shared/utils/type.utils';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './covered-location.action';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import { ProductType } from '@core/models/api/dsm-types';
import { BaseEntity } from '@core/models/entities/base.entity';
import {
  ConstructionInfoModel,
  ElectricWiring,
  PropertyLocation,
  OilTankModel,
  RiskItem,
} from './covered-location.model';
import { CoveredLocationUtils } from '@shared/utils/builders/property/covered-location.utils';
import { RenovationEntity } from '@core/interfaces/interfaces';
import { RenovationModel } from '@property/components/forms/renovation-form/renovation-form.model';

export interface CoveredLocationEntity extends BaseEntity {
  productType: ProductType;
  associatedMSBEstimateNumber?: string;
  businessOnPremises?: Nullable<boolean>;
  constructionInfo?: ConstructionInfoModel;
  currentConstruction?: Nullable<boolean>;
  currentRemodelingOrRehabiliation?: Nullable<boolean>;
  datePurchased?: string;
  deededOwner?: Nullable<boolean>;
  electricWiring?: ElectricWiring;
  eligibleProperty?: Nullable<boolean>;
  flatRoof?: Nullable<boolean>;
  gatedCommunity?: Nullable<boolean>;
  hasHydrantIn1000Ft?: Nullable<boolean>;
  hasOilTank?: Nullable<boolean>;
  historicDistrict?: Nullable<boolean>;
  isInCurrLocForThreeYrs?: Nullable<boolean>;
  isPersonalPropertyLimitSameAsPreviousPolicy?: boolean;
  isShortSaleAuctForeclose?: Nullable<boolean>;
  location?: PropertyLocation;
  modularHome?: Nullable<boolean>;
  nonResidentialToResidential?: Nullable<boolean>;
  numberOfAmps?: string;
  numberOfFamilies?: number;
  numberOfOccupants?: number;
  occupancy?: string;
  oilTank?: OilTankModel;
  protectiveDevices?: CoveredLocationProtectiveDevices;
  reconstructionCost?: number | null;
  registeredHistoric?: Nullable<boolean>;
  renovations?: RenovationEntity[];
  riskItems?: RiskItem;
  roofCondition?: Nullable<string>;
  roofUlType?: string;
  seasonal?: Nullable<boolean>;
  secondaryHome?: Nullable<boolean>;
  thermostat?: Nullable<boolean>;
  townhouse?: Nullable<boolean>;
  unitsInBuilding?: number;
  unitsBetweenFirewalls?: number;
  warningMessages?: unknown[];
  wasReconstructionCostUpdated?: boolean;
  weeksRented?: string;
}

export interface CoveredLocationProtectiveDevices {
  sprinklerSystem?: string | null;
  burglarAlarm?: string | null;
  fireOrSmokeAlarm?: string | null;
  hasWroughtIronBar?: boolean;
  windProtectiveDevices?: string[];
  windMitigations?: CoveredLocationWindMitigation[];
  hasSecondaryWaterResistance?: boolean;
  fireProtectiveDevice?: string | null;
}

export interface CoveredLocationWindMitigation {
  category: string;
  selectedType: string;
  availableOptions?: CoveredLocationWindMitigationOption[]; // retrieves only
}

export interface CoveredLocationWindMitigationOption {
  description: string;
  type: string;
}

export interface CoveredLocationState
  extends EntityState<CoveredLocationEntity> {
  error?: Nullable<ErrorModel>;
  loading?: Nullable<boolean>;
}

export const adapter: EntityAdapter<CoveredLocationEntity> =
  createEntityAdapter<CoveredLocationEntity>({
    selectId: (location: CoveredLocationEntity) => location.productType,
  });

const coveredLocationReducer = createReducer(
  adapter.getInitialState({
    error: null,
  }) as CoveredLocationState,
  on(
    SessionActions.clearSessionState,
    () =>
      adapter.getInitialState({
        error: null,
      }) as CoveredLocationState
  ),
  on(fromActions.getCoveredLocationSuccess, (state, { payload: response }) =>
    adapter.upsertOne(
      addAttributesToDsmResponse(response.productType, {
        ...response,
        renovations: maintainRoofYearRenovations(
          response,
          state.entities[response.productType]
        ),
      }),
      {
        ...state,
      }
    )
  ),
  on(fromActions.getCoveredLocationFail, (state, { error }) => ({
    ...state,
    error,
  })),
  on(fromActions.updateCoveredLocation, (state) => ({
    ...state,
    loading: true,
    error: undefined,
  })),
  on(fromActions.updateCoveredLocationError, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(
    fromActions.updateCoveredLocationSuccess,
    (state, { payload: coveredLocation }) =>
      adapter.upsertOne(
        addAttributesToDsmResponse(coveredLocation.productType, {
          ...state.entities[coveredLocation.productType],
          ...coveredLocation,
          constructionInfo: {
            ...state.entities[coveredLocation.productType]?.constructionInfo,
            ...coveredLocation.constructionInfo,
          },
        }),
        { ...state, loading: false }
      )
  ),
  on(fromActions.storeCoveredLocation, (state, { payload: coveredLocation }) =>
    adapter.upsertOne(
      addAttributesToDsmResponse(coveredLocation.productType, {
        ...state.entities[coveredLocation.productType],
        ...coveredLocation,
        constructionInfo: {
          ...state.entities[coveredLocation.productType]?.constructionInfo,
          ...coveredLocation.constructionInfo,
        },
      }),
      state
    )
  ),
  on(fromActions.patchCoveredLocationInStore, (state, { coveredLocation }) => {
    const existing = state.entities[coveredLocation.productType as ProductType];
    if (existing) {
      const entities = { ...state.entities };
      const newEntity = {
        ...existing,
        ...coveredLocation,
      };
      entities[coveredLocation.productType as ProductType] = newEntity;
      return {
        ...state,
        entities,
      };
    }
    return state;
  }),
  on(fromActions.removeCoveredLocation, (state, { payload: productType }) =>
    adapter.removeOne(productType, state)
  ),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    if (
      payload.productType === 'Homeowner' ||
      payload.productType === 'Condominium' ||
      payload.productType === 'Tenant'
    ) {
      return adapter.addOne(
        {
          ...addAttributesToDsmResponse(
            payload.productType,
            payload.response.coveredLocation
          ),
          productType: payload.productType,
        },
        state
      );
    } else {
      return state;
    }
  })
);

export function reducer(
  state: CoveredLocationState | undefined,
  action: Action
): CoveredLocationState {
  return coveredLocationReducer(state, action);
}

function maintainRoofYearRenovations(
  coveredLocation: CoveredLocationEntity,
  previousState: CoveredLocationEntity | undefined
): RenovationEntity[] | undefined {
  const renovations = coveredLocation?.renovations as RenovationModel[];
  const currentRoofRenovation = renovations?.find((r) => r.type === 'Roof');

  const prevRenovations = previousState?.renovations as RenovationModel[];
  const previousRoofRenovation = prevRenovations?.find(
    (r) => r.type === 'Roof'
  );
  const roofYear = currentRoofRenovation?.year
    ? currentRoofRenovation?.year
    : previousRoofRenovation?.year;

  return coveredLocation.renovations?.map((renovation) => {
    const newReno = {
      ...renovation,
    };
    if (renovation.type === 'Roof') {
      newReno.year = roofYear;
    }
    return newReno;
  });
}

function addAttributesToDsmResponse(
  productType: ProductType,
  coveredLocation: CoveredLocationEntity
): CoveredLocationEntity {
  coveredLocation = dropWindMitigationsIfBlank(coveredLocation);
  return {
    ...coveredLocation,
    renovations:
      productType !== 'Tenant'
        ? CoveredLocationUtils.updateRenovationsArray(
            coveredLocation.renovations,
            coveredLocation.constructionInfo?.yearBuilt
          )
        : coveredLocation.renovations,
    constructionInfo: {
      ...coveredLocation.constructionInfo,
      garages: CoveredLocationUtils.updateGaragesArray(
        coveredLocation?.constructionInfo?.garages
      ),
      exteriorWalls: CoveredLocationUtils.updateCommonTypesArray(
        coveredLocation?.constructionInfo?.exteriorWalls
      ),
      roofShape: CoveredLocationUtils.updateCommonTypesArray(
        coveredLocation?.constructionInfo?.roofShape
      ),
    },
  };
}

function dropWindMitigationsIfBlank(
  coveredLocation: CoveredLocationEntity
): CoveredLocationEntity {
  if (coveredLocation.protectiveDevices?.windMitigations) {
    if (
      !coveredLocation.protectiveDevices.windMitigations.find(
        (d) => d.selectedType
      )
    ) {
      coveredLocation = {
        ...coveredLocation,
        protectiveDevices: {
          ...coveredLocation.protectiveDevices,
        },
      };
      delete coveredLocation.protectiveDevices!.windMitigations;
      delete coveredLocation.protectiveDevices!.hasSecondaryWaterResistance;
    }
  }
  return coveredLocation;
}
