import { ObjectValidator } from '@core/helper/object-validator';

export interface PDSProducerResponse {
  type: 'ExclusiveAgent' | 'NSS';
  producerCode: string;
}

export interface AMFProducerResponse {
  type: 'IndependentAgent';
  agencyCode: string;
  producerCode: string;
}

export type ProducerResponse = PDSProducerResponse | AMFProducerResponse;

export function sanitizeProducerResponse(input: unknown): ProducerResponse {
  return ObjectValidator.forceSchema<ProducerResponse>(
    input,
    {
      type: 'string',
      producerCode: 'string',
      agencyCode: 'string',
    },
    ['agencyCode']
  );
}
