import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StringUtils } from '@shared/utils/string.utils';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { DriverVehiclePrefillRequest } from '../models/api/request/driver-vehicle-prefill-request.model';
import { DriverVehiclePrefillResponse } from '../models/api/response/driver-vehicle-prefill-response.model';
import { AppConfigService } from '../services/app-config.service';
import { LogService } from '../services/log.service';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root',
})
export class DriverVehiclePrefillAdapter {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private log: LogService,

    private sessionService: SessionService
  ) {}

  customersInfo(
    request: DriverVehiclePrefillRequest
  ): Observable<DriverVehiclePrefillResponse> {

    let accessToken;

    this.sessionService
      .getAccessToken()
      .pipe(take(1))
      .subscribe({
        next: (token: Nullable<string>) => {
          accessToken = token;
        },
        error: () => {},
        complete: () => {},
      });

    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${accessToken}`)
      .set('X-NW-Message-ID', StringUtils.generateUuid())
      .set('client_id', this.appConfigService.config.apiKey);

    const url = this.appConfigService.config.driverVehiclePrefillUrl;
    const correlationId = this.log.logApiRequest('POST', url, 'customer-info');
    return this.httpClient
      .post<DriverVehiclePrefillResponse>(url, request, {
        headers,
      })
      .pipe(
        tap((response) =>
          this.log.logApiResponse(correlationId, 200, response)
        ),
        map((response) => response || { drivers: [], vehicles: [] }),
        catchError((error) => {
          if (error && !error.status && error.statusText === 'Unknown Error') {
            // 2023-05-09: Prefill proxy is disabled in prod. Don't report errors to the user.
            // Unfortunately, the error causes a CORS error in the proxy or something, so we only get Status Zero.
            this.log.logApiError(correlationId, error); // discard return value
            return of({ drivers: [], vehicles: [] });
          }
          return this.log.logApiError(correlationId, error);
        })
      );
  }
}
