import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  RetrieveActions,
  SessionActions,
  LocationExcludedExposureActions,
} from '@core/store/actions';
import { LocationExcludedExposureEntity } from './location-excluded-exposures.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { Nullable } from '@shared/utils/type.utils';
import { Dictionary } from '@ngrx/entity';

export interface LocationExcludedExposureState
  extends EntityState<LocationExcludedExposureEntity> {
  error?: Nullable<ErrorModel>;
  deleted?: Dictionary<LocationExcludedExposureEntity>;
}

export const adapter: EntityAdapter<LocationExcludedExposureEntity> =
  createEntityAdapter<LocationExcludedExposureEntity>({
    selectId: (locationExcludedExposure: LocationExcludedExposureEntity) =>
      locationExcludedExposure.locationExcludedExposureId,
  });

const locationExcludedExposureReducer = createReducer(
  adapter.getInitialState({
    error: null,
    deleted: {},
  }) as LocationExcludedExposureState,

  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({
      error: null,
      deleted: {},
    } as LocationExcludedExposureState)
  ),
  on(
    LocationExcludedExposureActions.upsertLocationExcludedExposureSuccess,
    (state, { payload }) =>
      adapter.upsertOne(payload, {
        ...state,
      })
  ),
  on(
    LocationExcludedExposureActions.deleteLocationExcludedExposure,
    (state, { payload }) =>
      adapter.upsertOne(payload, {
        ...state,
        deleted: { [payload.locationExcludedExposureId]: payload },
      })
  ),
  on(
    LocationExcludedExposureActions.deleteLocationExcludedExposureSuccess,
    (state, { locationExcludedExposureId }) =>
      adapter.removeOne(locationExcludedExposureId, {
        ...state,
      })
  ),
  on(
    LocationExcludedExposureActions.completelyRemoveLocationExcludedExposure,
    (state, { locationExcludedExposureId }) => {
      const deleted = { ...state.deleted };
      delete deleted?.[locationExcludedExposureId];
      return { ...state, deleted };
    }
  ),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    if (payload.productType !== 'PersonalUmbrella') {
      return state;
    }
    return adapter.upsertMany(payload.response.locationExcludedExposures, {
      ...state,
    });
  })
);

export function reducer(
  state: LocationExcludedExposureState | undefined,
  action: Action
): LocationExcludedExposureState {
  return locationExcludedExposureReducer(state, action);
}
