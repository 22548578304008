import { ChangeDetectionStrategy, Component } from '@angular/core';
import { getAnnouncementItems } from '@entities/announcement-content/announcement-content.selector';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Nullable } from '@shared/utils/type.utils';
import { Observable } from 'rxjs';

@Component({
  selector: 'nwx-announcement-overlay',
  templateUrl: './announcement-overlay.component.html',
  styleUrls: ['./announcement-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementOverlayComponent {
  announcementItems$: Observable<Nullable<string[]>>;

  constructor(private modal: NgbActiveModal, private store: Store) {
    this.announcementItems$ = this.store.select(getAnnouncementItems);
  }

  close(): void {
    const rightNow = new Date().toISOString();
    window.localStorage.setItem('closedAnnouncementModal', rightNow);
    this.modal.close();
  }
}
