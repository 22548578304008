import { createSelector } from '@ngrx/store';

import * as fromCore from '@core/store/reducers';
import * as fromPremiums from '@core/store/entities/premium/premium.reducers';
import { ProductType } from '@core/models/api/dsm-types';
import { getSelectedProductTypes } from '../product/product.selectors';

export const getPremiumState = createSelector(
  fromCore.getCoreState,
  (state) => state.premiums
);

export const {
  selectAll: getAllPremiums,
  selectEntities: getPremiumEntities,
  selectTotal: getTotalPremiums,
} = fromPremiums.adapter.getSelectors(getPremiumState);

export const getPremiumsForSelectedProducts = createSelector(
  getAllPremiums,
  getSelectedProductTypes,
  (premiums, selectedProducts) =>
    premiums.filter(
      (premium) => selectedProducts.indexOf(premium.productType) >= 0
    )
);

export const getProductTerm = (productId: ProductType) =>
  createSelector(
    getPremiumEntities,
    (entities) => entities[productId]?.termMonths || 1
  );

export const getPremiumFor = (productType: ProductType) =>
  createSelector(getPremiumEntities, (premiums) => premiums[productType]);
