import { Component, Input, SimpleChanges } from '@angular/core';
import { CoverageEntity } from '@entities/coverage/coverage.entity';

/**
 * Any coverage that might have more than one term, call out here the term we want
 * to display in the Deductible column.
 * This is exported only for the sake of unit tests.
 */
export const PREFERRED_TERM_CODES = [
  'BILimit',
  'PDLimit',
  'MPLimit',
  'UMBILimit',
  'UMPDLimit',
  'UIMBILimit',
  'RSAOption',
  'COMPDeductible',
  'COLLDeductible',
  'RENTRELimit',
];

interface CoveragesTableDisplayRow {
  label: string;
  deductible: string;
  premium: string;
}

@Component({
  selector: 'nwx-coverages-table',
  templateUrl: './coverages-table.component.html',
  styleUrls: ['./coverages-table.component.scss'],
})
export class CoveragesTableComponent {
  @Input() coverages: CoverageEntity[] = [];
  @Input() termMonths = 12;
  deductibleColumnHeaderLabel = 'Deductible';
  displayRows: CoveragesTableDisplayRow[] = [];
  showPremium = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.coverages) {
      this.displayRows = this.coverages.map((coverage) =>
        this.displayRowForCoverage(coverage)
      );
    }
  }

  private displayRowForCoverage(
    coverage: CoverageEntity
  ): CoveragesTableDisplayRow {
    return {
      label: coverage.name,
      deductible: this.deductibleForCoverage(coverage),
      premium: this.premiumForCoverage(coverage),
    };
  }

  private deductibleForCoverage(coverage: CoverageEntity): string {
    if (!coverage.selectedValue?.length) {
      return '';
    }
    //TODO Mike and Leslie are deciding what we want for display formatting -- it's not this:
    const selectedValue =
      coverage.selectedValue.find((sv) =>
        PREFERRED_TERM_CODES.includes(sv.code)
      ) || coverage.selectedValue[0];
    switch (selectedValue.code) {
      case 'BILimit':
      case 'PDLimit':
      case 'UMBILimit':
      case 'UMPDLimit':
      case 'UIMBILimit':
        return this.abbreviatePersonOccurrenceLimit(selectedValue.value);
      case 'RENTRELimit':
      case 'MPLimit':
      case 'COMPDeductible':
      case 'COLLDeductible':
        return selectedValue.value;
    }
    return selectedValue.description || '';
  }

  private abbreviatePersonOccurrenceLimit(value: string): string {
    if (!value) {
      return '';
    }
    const match = value.match(/^(\d+)\/(\d+)$/);
    if (match) {
      const person = this.abbreviateDecimalIntegerForLimit(match[1]);
      const occurrence = this.abbreviateDecimalIntegerForLimit(match[2]);
      return person + '/' + occurrence;
    }
    return this.abbreviateDecimalIntegerForLimit(value);
  }

  private abbreviateDecimalIntegerForLimit(input: string): string {
    if (input.endsWith('000000')) {
      return input.substring(0, input.length - 6) + 'M';
    }
    if (input.endsWith('000')) {
      return input.substring(0, input.length - 3);
    }
    return input;
  }

  private premiumForCoverage(coverage: CoverageEntity): string {
    if (!coverage.coverageCost?.length) {
      return '$0.00';
    }
    return (
      '$' +
      coverage.coverageCost
        .reduce((a, v) => a + (v?.actualTermAmount?.amount || 0), 0)
        .toFixed(2)
    );
  }
}
