import { Injectable } from '@angular/core';
import {
  ActionAggregatorService,
  ExpectedActions,
} from '@core/services/action-aggregator.service';
import {
  concatMap,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { PeoplePageUtils } from '@shared/utils/pages/people-page.utils';
import { VehiclePageUtils } from '@shared/utils/pages/vehicles-page.utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  CompositeActions,
  PolicyHolderActions,
  ProductActions,
} from '../actions';
import { getAllFeatureFlags } from '../entities/feature-flag/feature-flag.selector';
import {
  HomeownerPageUtils,
  CondoPageUtils,
  TenantPageUtils,
} from '@shared/utils/pages/property-page.utils';
import { MemberService } from '@core/services/member.service';
import { AccountService } from '@core/services/account.service';
import { initiateOrUpdateSelectedProducts } from '../entities/quote/quote.action';
import { createAccount } from '../entities/account/account.action';
import { QuoteActions, AccountActions } from '@core/store/actions/index';
import { from } from 'rxjs';
import { PniPageUtils } from '@shared/utils/pages/pni-page.utils';
import { deleteAddress } from '../entities/address/address.action';
import {
  updatePniBeforeProducts,
  upsertPolicyHolder,
  upsertPolicyHolderError,
  upsertPolicyHolderSuccess,
} from '../entities/policyholder/policyholder.actions';
import { StringUtils } from '@shared/utils/string.utils';
import { PersonUtils } from '@shared/utils/person.utils';
import { ProductsSelectors } from '../selectors';
import { CoveredLocationSelectors } from '../selectors';
import { getProtectiveDevicesForProduct } from '@entities/protective-devices/protective-devices.selector';
import { submitUmbrellaPage } from './composite.action';
import { ProductsService } from '@core/services/products.service';
import { ProductHelper } from '@core/helper/product.helper';
import { PolicyHolderEntity } from '@entities/policyholder/policyholder.entity';
import { getFortifiedHome } from '../property-form/property-form.selector';
import { UmbrellaPageUtils } from '@shared/utils/pages/umbrella-page.utils';

@Injectable({
  providedIn: 'root',
})
export class PageSubmissionEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private memberService: MemberService,
    private accountService: AccountService,
    private aggregatorService: ActionAggregatorService,
    private productsService: ProductsService
  ) {}

  submitPniPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.submitPniPage),
      map((action) => action.submission),
      withLatestFrom(this.accountService.getAccountLoaded()),
      switchMap(([submission, isAccountLoaded]) => {
        const nextActions: Action[] = [];
        if (!submission.mailingAddress) {
          nextActions.push(deleteAddress({ payload: 'Mailing' }));
        }
        if (isAccountLoaded) {
          const policyHolderIds = PersonUtils.policyHolderIdsFromEntity(
            submission.pni
          );
          if (policyHolderIds.length) {
            nextActions.push(
              updatePniBeforeProducts({ pni: submission.pni, policyHolderIds })
            );
          } else {
            nextActions.push(initiateOrUpdateSelectedProducts());
          }
        } else {
          nextActions.push(createAccount());
        }
        // Aggregator for SubmitPni lives in the InitiateOrUpdateAction
        return nextActions;
      })
    )
  );

  updatePniBeforeProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePniBeforeProducts),
      concatMap((action) => {
        const actions: any[] = [];
        action.policyHolderIds.forEach((policyHolderId) => {
          const productType = action.pni.policyRoles.find(
            (role) =>
              role.entityId === policyHolderId &&
              role.entityType === 'policyHolder'
          )?.productType;
          if (productType) {
            actions.push(
              upsertPolicyHolder({
                payload: {
                  ...action.pni,
                  policyHolderId,
                  productType,
                },
                correlationId: StringUtils.generateUuid(),
              })
            );
          }
        });
        if (!actions.length) {
          return from([]);
        }
        const expected: ExpectedActions = actions.map((action) => {
          return {
            success: [
              {
                type: upsertPolicyHolderSuccess.type,
                correlationId: action.correlationId as string,
              },
            ],
            error: [
              {
                type: upsertPolicyHolderError.type,
                correlationId: action.correlationId as string,
              },
            ],
          };
        });
        this.aggregatorService.aggregate(expected, {
          onSuccess: () =>
            this.store.dispatch(initiateOrUpdateSelectedProducts()),
          onError: () =>
            this.store.dispatch(
              CompositeActions.submitPniPageError({ payload: null })
            ),
          onTimeout: () =>
            this.store.dispatch(
              CompositeActions.submitPniPageError({
                payload: { reason: 'timeout', message: 'Timeout updating PNI' },
              })
            ),
        });
        return from(actions);
      })
    )
  );

  initiateOrUpdateSelectedProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        QuoteActions.initiateOrUpdateSelectedProducts,
        AccountActions.createAccountSuccess
      ),
      withLatestFrom(this.store.select(ProductsSelectors.getSelectedProducts)),
      mergeMap(([action, products]) => {
        const nextActions: Action[] = [];
        const expectedActions: ExpectedActions = [];

        PniPageUtils.InitiateOrUpdateProducts(
          products,
          nextActions,
          expectedActions
        );

        // The default 30 second timeout is inadequate for multi-product inits.
        // There's no particular reason for this (30+20*n) formula, just guessing.
        const timeout = (30 + 20 * products.length) * 1000;

        this.aggregatorService.aggregate(
          expectedActions,
          {
            onSuccess: (store, actions) => {
              store.dispatch(
                CompositeActions.submitPniPageSuccess({ payload: actions })
              );
            },
            onError: (store, actions) => {
              store.dispatch(
                CompositeActions.submitPniPageError({ payload: actions })
              );
            },
            onTimeout: (store) => {
              store.dispatch(
                CompositeActions.submitPniPageError({
                  payload: {
                    reason: 'Timeout',
                    message: 'Timeout initiating quotes.',
                  },
                })
              );
            },
          },
          timeout
        );
        return from(nextActions);
      })
    )
  );

  submitPeoplePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.submitPeoplePage),
      map((action) => action.submission),
      switchMap((submission) => {
        const { actions, expectedActions, ridingAssociation, rvAssociation } =
          submission;
        // (actions) visited ngrx so it is immutable. Make a copy:
        const nextActions = [...actions];

        if (ridingAssociation?.productType) {
          PeoplePageUtils.PolicyLineDiscount(ridingAssociation, nextActions);
        }

        if (rvAssociation?.productType) {
          PeoplePageUtils.PolicyLineDiscount(rvAssociation, nextActions);
        }

        this.aggregatorService.aggregate(expectedActions, {
          onSuccess: (store, responseActions) => {
            store.dispatch(
              CompositeActions.submitPeoplePageSuccess({
                payload: responseActions,
              })
            );
          },
          onError: (store, responseActions) => {
            store.dispatch(
              CompositeActions.submitPeoplePageError({
                payload: responseActions,
              })
            );
          },
          onTimeout: (store) => {
            store.dispatch(
              CompositeActions.submitPeoplePageError({
                payload: {
                  reason: 'Timeout',
                  message: 'Timeout submitting people page',
                },
              })
            );
          },
        });

        return nextActions;
      })
    )
  );

  submitVehiclePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.submitVehiclePage),
      map((action) => action.submission),
      withLatestFrom(this.store.select(getAllFeatureFlags)),
      switchMap(([submission, featureFlags]) => {
        const { created, updated, deleted, currentCarrier } = submission;
        const nextActions: Action[] = [];
        const expectedActions: ExpectedActions = [];

        VehiclePageUtils.delete(deleted, nextActions, expectedActions);
        VehiclePageUtils.update(updated, nextActions, expectedActions);
        VehiclePageUtils.create(created, nextActions, expectedActions);
        VehiclePageUtils.updateQuote(
          'PersonalAuto',
          nextActions,
          expectedActions
        );

        if (currentCarrier && featureFlags.currentCarrierRequired) {
          VehiclePageUtils.currentCarrier(
            currentCarrier,
            nextActions,
            expectedActions
          );
        }

        this.aggregatorService.aggregate(expectedActions, {
          onSuccess: (store, actions) => {
            store.dispatch(
              CompositeActions.submitVehiclePageSuccess({ payload: actions })
            );
          },
          onError: (store, actions) => {
            store.dispatch(
              CompositeActions.submitVehiclePageError({ payload: actions })
            );
          },
          onTimeout: (store) => {
            store.dispatch(
              CompositeActions.submitVehiclePageError({
                payload: {
                  reason: 'Timeout',
                  message: 'Timeout submitting vehicles',
                },
              })
            );
          },
        });

        return nextActions;
      })
    )
  );

  submitMsaPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.submitMsaPage),
      map((action) => action.submission),
      withLatestFrom(this.store.select(getAllFeatureFlags)),
      switchMap(([submission, featureFlags]) => {
        const { created, updated, deleted, currentCarrier } = submission;
        const nextActions: Action[] = [];
        const expectedActions: ExpectedActions = [];

        VehiclePageUtils.update(updated, nextActions, expectedActions);
        VehiclePageUtils.create(created, nextActions, expectedActions);
        VehiclePageUtils.delete(deleted, nextActions, expectedActions);
        VehiclePageUtils.updateQuote('MSA', nextActions, expectedActions);

        if (
          currentCarrier &&
          (featureFlags.currentCarrierRequired ||
            featureFlags.currentCarrierDefaults)
        ) {
          VehiclePageUtils.currentCarrier(
            currentCarrier,
            nextActions,
            expectedActions
          );
        }

        this.aggregatorService.aggregate(expectedActions, {
          onSuccess: (store, actions) => {
            store.dispatch(
              CompositeActions.submitMsaPageSuccess({ payload: actions })
            );
          },
          onError: (store, actions) => {
            store.dispatch(
              CompositeActions.submitMsaPageError({ payload: actions })
            );
          },
          onTimeout: (store) => {
            store.dispatch(
              CompositeActions.submitMsaPageError({
                payload: {
                  reason: 'Timeout',
                  message: 'Timeout submitting MSA vehicles.',
                },
              })
            );
          },
        });

        return nextActions;
      })
    )
  );

  submitHomeownerPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.submitHomeownerPage),
      withLatestFrom(
        this.store.select(
          CoveredLocationSelectors.getCoveredLocationEntity('Homeowner')
        ),
        this.store.select(getFortifiedHome)
      ),
      switchMap(([_submission, _coveredLocation, fortifiedHome]) => {
        const nextActions: Action[] = [];
        const expectedActions: ExpectedActions = [];

        HomeownerPageUtils.update(nextActions, expectedActions);

        this.aggregatorService.aggregate(expectedActions, {
          onSuccess: (store, actions) => {
            store.dispatch(
              CompositeActions.submitHomeownerPageSuccess({
                payload: actions,
              })
            );
          },
          onError: (store, actions) => {
            store.dispatch(
              CompositeActions.submitHomeownerPageError({ payload: actions })
            );
          },
          onTimeout: (store) => {
            store.dispatch(
              CompositeActions.submitHomeownerPageError({
                payload: {
                  reason: 'Timeout',
                  message: 'Timeout submitting Homeowner',
                },
              })
            );
          },
        });

        return nextActions;
      })
    )
  );

  submitCondoPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.submitCondoPage),
      withLatestFrom(
        this.store.select(
          CoveredLocationSelectors.getCoveredLocationEntity('Condominium')
        ),
        this.store.select(getProtectiveDevicesForProduct('Condominium')),
        this.store.select(getAllFeatureFlags)
      ),
      switchMap(
        ([submission, coveredLocation, protectiveDevices, featureFlags]) => {
          const nextActions: Action[] = [];
          const expectedActions: ExpectedActions = [];

          CondoPageUtils.update(
            protectiveDevices,
            nextActions,
            expectedActions
          );

          this.aggregatorService.aggregate(expectedActions, {
            onSuccess: (store, actions) => {
              store.dispatch(
                CompositeActions.submitCondoPageSuccess({ payload: actions })
              );
            },
            onError: (store, actions) => {
              store.dispatch(
                CompositeActions.submitCondoPageError({ payload: actions })
              );
            },
            onTimeout: (store) => {
              store.dispatch(
                CompositeActions.submitCondoPageError({
                  payload: {
                    reason: 'Timeout',
                    message: 'Timeout submitting Condo',
                  },
                })
              );
            },
          });

          return nextActions;
        }
      )
    )
  );

  submitTenantPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.submitTenantPage),
      // map((action) => action.submission),
      withLatestFrom(
        this.store.select(
          CoveredLocationSelectors.selectCoveredLocationModel('Tenant')
        ),
        this.store.select(getAllFeatureFlags)
      ),
      switchMap(([submission, coveredLocation, featureFlags]) => {
        const nextActions: Action[] = [];
        const expectedActions: ExpectedActions = [];

        TenantPageUtils.update(nextActions, expectedActions);

        this.aggregatorService.aggregate(expectedActions, {
          onSuccess: (store, actions) => {
            store.dispatch(
              CompositeActions.submitTenantPageSuccess({ payload: actions })
            );
          },
          onError: (store, actions) => {
            store.dispatch(
              CompositeActions.submitTenantPageError({ payload: actions })
            );
          },
          onTimeout: (store) => {
            store.dispatch(
              CompositeActions.submitTenantPageError({
                payload: {
                  reason: 'Timeout',
                  message: 'Timeout submitting Renters',
                },
              })
            );
          },
        });

        return nextActions;
      })
    )
  );

  submitRVPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.submitRVPage),
      map((action) => action.submission),
      withLatestFrom(this.store.select(getAllFeatureFlags)),
      switchMap(([submission, featureFlags]) => {
        const { created, updated, deleted, currentCarrier } = submission;
        const nextActions: Action[] = [];
        const expectedActions: ExpectedActions = [];

        VehiclePageUtils.update(updated, nextActions, expectedActions);
        VehiclePageUtils.create(created, nextActions, expectedActions);
        VehiclePageUtils.delete(deleted, nextActions, expectedActions);
        VehiclePageUtils.updateQuote('RV', nextActions, expectedActions);

        if (
          currentCarrier &&
          (featureFlags.currentCarrierRequired ||
            featureFlags.currentCarrierDefaults)
        ) {
          VehiclePageUtils.currentCarrier(
            currentCarrier,
            nextActions,
            expectedActions
          );
        }
        this.aggregatorService.aggregate(expectedActions, {
          onSuccess: (store, actions) => {
            store.dispatch(
              CompositeActions.submitRVPageSuccess({ payload: actions })
            );
          },
          onError: (store, actions) => {
            store.dispatch(
              CompositeActions.submitRVPageError({ payload: actions })
            );
          },
          onTimeout: (store) => {
            store.dispatch(
              CompositeActions.submitRVPageError({
                payload: {
                  reason: 'Timeout',
                  message: 'Timeout submitting RV',
                },
              })
            );
          },
        });

        return nextActions;
      })
    )
  );

  submitBoatPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.submitBoatPage),
      map((action) => action.submission),
      withLatestFrom(this.store.select(getAllFeatureFlags)),
      switchMap(([submission, featureFlags]) => {
        const { created, updated, deleted, currentCarrier } = submission;
        const nextActions: Action[] = [];
        const expectedActions: ExpectedActions = [];

        VehiclePageUtils.update(updated, nextActions, expectedActions);
        VehiclePageUtils.create(created, nextActions, expectedActions);
        VehiclePageUtils.delete(deleted, nextActions, expectedActions);
        VehiclePageUtils.updateQuote('Boat', nextActions, expectedActions);

        if (
          currentCarrier &&
          (featureFlags.currentCarrierRequired ||
            featureFlags.currentCarrierDefaults)
        ) {
          VehiclePageUtils.currentCarrier(
            currentCarrier,
            nextActions,
            expectedActions
          );
        }

        this.aggregatorService.aggregate(expectedActions, {
          onSuccess: (store, actions) => {
            store.dispatch(
              CompositeActions.submitBoatPageSuccess({ payload: actions })
            );
          },
          onError: (store, actions) => {
            store.dispatch(
              CompositeActions.submitBoatPageError({ payload: actions })
            );
          },
          onTimeout: (store) => {
            store.dispatch(
              CompositeActions.submitBoatPageError({
                payload: {
                  reason: 'Timeout',
                  message: 'Timeout submitting Boat',
                },
              })
            );
          },
        });

        return nextActions;
      })
    )
  );

  submitUmbrellaPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitUmbrellaPage),
      switchMap((action) => {
        const nextActions: Action[] = [];
        const expectedActions: ExpectedActions = [];

        UmbrellaPageUtils.updateQuote(
          'PersonalUmbrella',
          nextActions,
          expectedActions
        );

        this.aggregatorService.aggregate(expectedActions, {
          onSuccess: (store, actions) => {
            store.dispatch(
              CompositeActions.submitUmbrellaPageSuccess({ payload: actions })
            );
          },
          onError: (store, actions) => {
            store.dispatch(
              CompositeActions.submitUmbrellaPageError({ payload: actions })
            );
          },
          onTimeout: (store) => {
            store.dispatch(
              CompositeActions.submitUmbrellaPageError({
                payload: {
                  reason: 'Timeout',
                  message: 'Timeout submitting Umbrella',
                },
              })
            );
          },
        });

        return nextActions;

        /* this.store.dispatch(
          QuoteActions.updateQuote({ productType: 'PersonalUmbrella' })
        );
        return this.umbrellaService.syncUmbrellaToCurrentState().pipe(
          filter((value) => value),
          map((result) => {
            return CompositeActions.submitUmbrellaPageSuccess({
              payload: null,
            });
          }),
          catchError((error) =>
            of(CompositeActions.submitUmbrellaPageError({ payload: error }))
          )
        ); */
      })
    )
  );

  submitAccountRegistrationModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.submitAccountRegistrationModal),
      map((action) => action.registrationInfo),
      withLatestFrom(
        this.productsService.getSelectedProducts(),
        this.memberService.getPniEmail(),
        this.memberService.getPrimaryNamedInsured()
      ),
      mergeMap(([registration, products, email, pni]) => {
        const nextActions: Action[] = [];
        const expectedActions: ExpectedActions = [];
        products.map((p) => {
          const correlationId = StringUtils.generateUuid();
          nextActions.push(
            ProductActions.patchProductQuote({
              payload: {
                productType: p.type,
                nationwideAccountRegistrationInfo:
                  ProductHelper.buildAccountRegistrationInfo(registration),
              },
              correlationId,
            })
          );
          expectedActions.push({
            success: [
              { type: QuoteActions.updateQuoteSuccess.type, correlationId },
            ],
            error: [{ type: QuoteActions.updateQuoteFail.type, correlationId }],
          });
          if (registration.primaryNamedInsuredEmail && !email) {
            const correlationId = StringUtils.generateUuid();
            nextActions.push(
              PolicyHolderActions.upsertPolicyHolder({
                payload: {
                  ...pni,
                  emailAddress: registration.primaryNamedInsuredEmail,
                  productType: p.type,
                } as PolicyHolderEntity,
                correlationId,
              })
            );
            expectedActions.push({
              success: [
                {
                  type: PolicyHolderActions.upsertPolicyHolderSuccess.type,
                  correlationId,
                },
              ],
              error: [
                {
                  type: PolicyHolderActions.upsertPolicyHolderError.type,
                  correlationId,
                },
              ],
            });
          }
        });

        this.aggregatorService.aggregate(expectedActions, {
          onSuccess: (store, responseActions) => {
            this.store.dispatch(
              CompositeActions.submitAccountRegistrationModalSuccess()
            );
          },
          onError: (store, responseActions) => {
            this.store.dispatch(
              CompositeActions.submitAccountRegistrationModalError({
                payload: responseActions,
              })
            );
          },
          onTimeout: (store) => {
            this.store.dispatch(
              CompositeActions.submitAccountRegistrationModalError({
                payload: { reason: 'Tineout' },
              })
            );
          },
        });

        return nextActions;
      })
    )
  );
}
