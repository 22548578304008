import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CandidateUnderlyingPolicy,
  UnderlyingPolicyEntity,
  sanitizeCandidateUnderlyingPolicies,
  sanitizeUnderlyingPolicyEntity,
} from '@entities/underlying-policy/underlying-policy.entity';
import { DsmAdapter, responseUnused } from './dsm.adapter';
import { AppConfigService } from '@core/services/app-config.service';
import { UnderlyingPolicyRequest } from '@core/models/api/request/underlying-policy-request.model';

@Injectable({
  providedIn: 'root',
})
export class UnderlyingPolicyAdapter {
  constructor(
    public adapter: DsmAdapter,
    public appService: AppConfigService
  ) {}

  addUnderlyingPolicy(
    request: UnderlyingPolicyRequest
  ): Observable<UnderlyingPolicyEntity> {
    let headers;
    if (!this.appService.isProd && this.appService.config.autoApproveUW) {
      headers = new HttpHeaders({
        'Auto-Approve-Rules': 'true',
      });
    }
    return this.adapter.request(
      sanitizeUnderlyingPolicyEntity,
      'PersonalUmbrella',
      'POST',
      `/quotes/${request.quoteId}/underlying-policies`,
      'add-underlying-policy',
      {
        body: request.underlyingPolicyBody,
        headers,
      }
    );
  }

  updateUnderlyingPolicy(
    request: UnderlyingPolicyRequest
  ): Observable<UnderlyingPolicyEntity> {
    let headers;
    if (!this.appService.isProd && this.appService.config.autoApproveUW) {
      headers = new HttpHeaders({
        'Auto-Approve-Rules': 'true',
      });
    }
    return this.adapter.request(
      sanitizeUnderlyingPolicyEntity,
      'PersonalUmbrella',
      'PUT',
      `/quotes/${request.quoteId}/underlying-policies/${request.underlyingPolicyBody.underlyingPolicy.underlyingPolicyId}`,
      'update-underlying-policy',
      {
        body: request.underlyingPolicyBody,
        headers,
      }
    );
  }

  removeUnderlyingPolicy(
    request: UnderlyingPolicyRequest
  ): Observable<unknown> {
    return this.adapter.request(
      responseUnused,
      'PersonalUmbrella',
      'DELETE',
      `/quotes/${request.quoteId}/underlying-policies/${request.underlyingPolicyBody.underlyingPolicy.underlyingPolicyId}`,
      'remove-underlying-policy'
    );
  }

  getCandidateUnderlyingPolicies(
    quoteId: string
  ): Observable<CandidateUnderlyingPolicy[]> {
    return this.adapter.request(
      sanitizeCandidateUnderlyingPolicies,
      'PersonalUmbrella',
      'GET',
      `/quotes/${quoteId}/candidate-underlying-policies`,
      'get-candidate-underlying-policies'
    );
  }
}
