import { ProductType } from '@core/models/api/dsm-types';
import { DsmAdapter } from '@core/adapters/dsm.adapter';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import {
  GetCurrentCarrierRequest,
  UpdateCurrentCarrierRequest,
} from '../models/api/request/update-current-carrier-request.model';
import {
  CurrentCarrierEntity,
  sanitizeCurrentCarrierEntity,
} from '../store/entities/current-carrier/current-carrier.reducer';

@Injectable({
  providedIn: 'root',
})
export class CurrentCarrierAdapter extends DsmAdapter {
  retrieveCurrentCarrier(
    request: GetCurrentCarrierRequest
  ): Observable<CurrentCarrierEntity> {
    return this.request(
      sanitizeCurrentCarrierEntity,
      request.productType as ProductType,
      'get',
      `/quotes/${request.quoteId}/current-carrier/primary`,
      'current-carrier'
    );
  }

  updateCurrentCarrier(
    request: UpdateCurrentCarrierRequest
  ): Observable<CurrentCarrierEntity> {
    return this.request(
      sanitizeCurrentCarrierEntity,
      request.productType as ProductType,
      'put',
      `/quotes/${request.quoteId}/current-carrier/primary`,
      'current-carrier',
      {
        body: request.body,
      }
    );
  }
}
