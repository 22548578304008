import { Injectable } from '@angular/core';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, from, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  take,
} from 'rxjs/operators';

import { CompositeActions, UnderlyingPolicyActions } from '@core/store/actions';
import { UnderlyingPolicySelector } from '@core/store/selectors';
import { UnderlyingPolicyService } from '@core/services/underlying-policy.service';
import {
  ActionAggregatorService,
  ExpectedActions,
} from '@core/services/action-aggregator.service';
import { UmbrellaPageUtils } from '@shared/utils/pages/umbrella-page.utils';

@Injectable()
export class UnderlyingPolicyEffect {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: UnderlyingPolicyService,
    private errorSanitizerService: ErrorSanitizerService,
    private aggregatorService: ActionAggregatorService
  ) {}

  upsertUnderlyingPolicy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UnderlyingPolicyActions.upsertUnderlyingPolicy),
        mergeMap((action) =>
          this.store
            .select(
              UnderlyingPolicySelector.buildUnderlyingPolicyRequest(
                action.payload,
                action.correlationId || ''
              )
            )
            .pipe(
              take(1),
              map((request) => ({ request, action }))
            )
        ),
        mergeMap(({ request, action }) => {
          return this.service.addOrUpdateUnderlyingPolicy(request).pipe(
            mergeMap((response) => {
              return from([
                UnderlyingPolicyActions.upsertUnderlyingPolicySuccess({
                  payload: response,
                  existingPolicyNumber: action.existingPolicyNumber || '',
                  correlationId: request.correlationId,
                }),
              ]);
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                request.productType
              );
              return of(
                UnderlyingPolicyActions.upsertUnderlyingPolicyFail({
                  error: saneError,
                  entityId:
                    request.underlyingPolicyBody.underlyingPolicy
                      .underlyingPolicyId,
                  correlationId: request.correlationId,
                })
              );
            })
          );
        })
      ) as Observable<Action>
  );

  restoreUnderlyingPolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnderlyingPolicyActions.restoreUnderlyingPolicies),
      exhaustMap((actions) =>
        this.store
          .select(UnderlyingPolicySelector.getAllDeletedUnderlyingPolicies)
          .pipe(take(1))
      ),
      mergeMap((underlyingPolicies) => {
        const nextActions: Action[] = [];
        const expectedActions: ExpectedActions = [];

        underlyingPolicies.forEach((policy) => {
          UmbrellaPageUtils.updateUnderlyingPolicy(
            {
              ...policy,
              underlyingPolicyId: '',
            },
            nextActions,
            expectedActions
          );
        });

        nextActions.push(
          UnderlyingPolicyActions.clearDeletedUnderlyingPolicies()
        );

        this.aggregatorService.aggregate(expectedActions, {
          onSuccess: (store, actions) => {
            store.dispatch(
              CompositeActions.upsertUnderlyingPoliciesSuccess({
                payload: actions,
              })
            );
          },
          onError: (store, actions) => {
            store.dispatch(
              CompositeActions.upsertUnderlyingPoliciesError({
                payload: actions,
              })
            );
          },
          onTimeout: (store) => {
            store.dispatch(
              CompositeActions.upsertUnderlyingPoliciesError({
                payload: {
                  reason: 'Timeout',
                  message: 'Timeout submitting Umbrella',
                },
              })
            );
          },
        });

        nextActions.forEach((action) => {
          this.store.dispatch(action);
        });

        return this.actions$.pipe(
          ofType(
            CompositeActions.upsertUnderlyingPoliciesSuccess,
            CompositeActions.upsertUnderlyingPoliciesError
          ),
          take(1),
          switchMap((action) => {
            const exposures: Action[] = action.payload.map((policy: any) => {
              return CompositeActions.restoreExposure({
                payload: policy.payload,
              });
            });
            return from(exposures);
          }),
          catchError((error) => {
            const saneError = this.errorSanitizerService.sanitizeError(error);
            return of(
              CompositeActions.upsertUnderlyingPoliciesError({
                payload: saneError,
              })
            );
          })
        );
      }),
      catchError((error) => {
        const saneError = this.errorSanitizerService.sanitizeError(error);
        return of(
          UnderlyingPolicyActions.upsertUnderlyingPolicyFail({
            error: saneError,
          })
        );
      })
    )
  );

  deleteUnderlyingPolicy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UnderlyingPolicyActions.deleteUnderlyingPolicy),
        mergeMap((action) =>
          this.store
            .select(
              UnderlyingPolicySelector.buildUnderlyingPolicyRequest(
                action.payload,
                action.correlationId || ''
              )
            )
            .pipe(take(1))
        ),
        mergeMap((request) => {
          return this.service.deleteUnderlyingPolicy(request).pipe(
            mergeMap(() => {
              return from([
                UnderlyingPolicyActions.deleteUnderlyingPolicySuccess({
                  underlyingPolicyId:
                    request.underlyingPolicyBody.underlyingPolicy
                      .underlyingPolicyId,
                  underlyingPolicyNumber:
                    request.underlyingPolicyBody.underlyingPolicy
                      .underlyingPolicyNumber,
                }),
              ]);
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                request.productType
              );
              return of(
                UnderlyingPolicyActions.deleteUnderlyingPolicyFail({
                  error: saneError,
                  entityId:
                    request.underlyingPolicyBody.underlyingPolicy
                      .underlyingPolicyId,
                })
              );
            })
          );
        })
      ) as Observable<Action>
  );

  removeAccompanyingPolicyIfRateFails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnderlyingPolicyActions.removeAccompanyingPolicyIfRateFails),
      mergeMap((action) =>
        this.store
          .select(
            UnderlyingPolicySelector.getUnderlyingPolicyByProductType(
              action.productType
            )
          )
          .pipe(take(1))
      ),
      mergeMap((policy) => {
        if (policy) {
          return from([
            UnderlyingPolicyActions.deleteUnderlyingPolicy({ payload: policy }),
          ]);
        } else {
          return EMPTY;
        }
      })
    )
  );

  // Probably don't need this
  /* removeDeletedAccompanyingPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnderlyingPolicyActions.deleteUnderlyingPolicySuccess),
      exhaustMap((action) =>
        this.store
          .select(
            UnderlyingPolicySelector.isAccompanyingPolicy(
              action.underlyingPolicyNumber,
              action.underlyingPolicyId
            )
          )
          .pipe(take(1))
      ),
      exhaustMap((accompanyingPolicy) => {
        if (accompanyingPolicy.isAccompanying) {
          return from([
            UnderlyingPolicyActions.removeDeletedAccompanyingPolicy({
              underlyingPolicyId: accompanyingPolicy.underlyingPolicyId,
              underlyingPolicyNumber: accompanyingPolicy.quoteId,
            }),
          ]);
        }
        return EMPTY;
      })
    )
  ); */
}
