import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductType } from '@core/models/api/dsm-types';
import { EligibleDiscountRequest } from '../models/api/request/eligible-discount-request.model';
import {
  EligibleDiscountResponse,
  sanitizeEligibleDiscountResponse,
  sanitizeEligibleDiscountResponses,
} from '../models/api/response/eligible-discount-response.model';
import { DsmAdapter } from './dsm.adapter';

@Injectable({
  providedIn: 'root',
})
export class EligibleDiscountsAdapter extends DsmAdapter {
  getPolicyLine(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse[]> {
    if (!this.productSupportsPolicyLineDiscounts(request.productType)) {
      return of([]);
    }
    return this.request(
      sanitizeEligibleDiscountResponses,
      request.productType as ProductType,
      'GET',
      `/quotes/${request.quoteId}/eligible-discounts`,
      'get-policy-line'
    ).pipe(
      map((response) =>
        response.map((res) => {
          res.productType = request.productType;
          res.modelId = request?.quoteId || '';
          return res;
        })
      )
    );
  }

  getVehicleDiscounts(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse[]> {
    return this.request(
      sanitizeEligibleDiscountResponses,
      request.productType as ProductType,
      'GET',
      `/quotes/${request.quoteId}/vehicles/${request.vehicleId}/eligible-discounts`,
      'get-vehicle-discounts'
    ).pipe(
      map((response) =>
        response.map((res) => {
          res.productType = request.productType;
          res.modelId = request.quoteId || '';
          res.vehicleId = request.vehicleId;
          return res;
        })
      )
    );
  }

  updateVehicleDiscount(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    return this.request(
      sanitizeEligibleDiscountResponse,
      request.productType as ProductType,
      'PUT',
      `/quotes/${request.quoteId}/vehicles/${request.vehicleId}/eligible-discounts/${request.name}`,
      'update-vehicle-discount',
      {
        body: {
          eligibleDiscount: request.eligibleDiscount,
        },
      }
    ).pipe(
      map((response) => {
        return {
          ...response,
          productId: request.productType,
          modelId: request.quoteId || '',
          vehicleId: request.vehicleId,
        };
      })
    );
  }

  updatePolicyLine(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    const payload = {
      eligibleDiscount: {
        ...request.eligibleDiscount,
        qualifyingInformation: request.eligibleDiscount?.qualifyingInformation,
      },
    };
    return this.request(
      sanitizeEligibleDiscountResponse,
      request.productType as ProductType,
      'PUT',
      `/quotes/${request.quoteId}/eligible-discounts/${request.name}`,
      'update-policy-line',
      {
        body: payload,
      }
    );
  }

  updateDriverDiscount(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    const payload = {
      eligibleDiscount: request.eligibleDiscount,
    };
    return this.request(
      sanitizeEligibleDiscountResponse,
      request.productType as ProductType,
      'PUT',
      `/quotes/${request.quoteId}/drivers/${request.modelId}/eligible-discounts/${request?.eligibleDiscount?.eligibleDiscountId}`,
      'update-driver-discount',
      {
        body: payload,
      }
    );
  }

  productSupportsPolicyLineDiscounts(
    productId: ProductType | undefined
  ): boolean {
    switch (productId) {
      case 'PersonalUmbrella':
        return false;
    }
    return true;
  }
}
