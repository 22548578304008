import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StringUtils } from '@shared/utils/string.utils';
import { Nullable } from '@shared/utils/type.utils';
import { MonoTypeOperatorFunction, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, retry, take, tap } from 'rxjs/operators';
import { DownPaymentExperienceRequest } from '../models/api/request/down-payment-experience-request.model';
import { EstablishEscrowAccountRequest } from '../models/api/request/establish-escrow-account-request.model';
import { SetupAccountExperienceRequest } from '../models/api/request/setup-account-experience-request.model';
import { BankNameResponseModel } from '../models/api/response/bank-name-response.model';
import { DownPaymentExperienceResponse } from '../models/api/response/down-payment-experience-response.model';
import { EstablishEscrowAccountResponse } from '../models/api/response/establish-escrow-account-response.model';
import { SetupAccountExperienceResponse } from '../models/api/response/setup-account-experience-response.model';
import { AppConfigService } from '../services/app-config.service';
import { LogService } from '../services/log.service';
import { SessionService } from '../services/session.service';
import { AmfBankAccountsResponse } from '@core/models/api/response/amf-bank-accounts-response.moel';
import { ErrorMessageService } from '@core/services/error-message.service';

@Injectable({
  providedIn: 'root',
})
export class BillingAdapter {
  // Please keep mutable, so tests can mess with them:
  static RETRY_LIMIT = 3;
  static RETRY_DELAY = 500;

  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private log: LogService,
    private errorMessageService: ErrorMessageService,
    private sessionService: SessionService
  ) {}

  downPaymentExperience(
    request: DownPaymentExperienceRequest
  ): Observable<DownPaymentExperienceResponse> {
    return this.sessionService.getAccessToken().pipe(
      take(1),
      mergeMap((accessToken) => {
        const url = `${this.appConfigService.config.personalLinesBillingApiUrl}/billing-accounts/1/down-payment-experience`;
        let headers = new HttpHeaders();
        headers = this.setHeaders(headers, accessToken, true);
        headers = headers.set('X-NW-Message-ID', StringUtils.generateUuid());
        const correlationId = this.log.logApiRequest(
          'POST',
          url,
          'down-payment-experience',
          request
        );
        return this.httpClient
          .post<DownPaymentExperienceResponse>(url, request, {
            headers,
          })
          .pipe(
            tap((response) =>
              this.log.logApiResponse(correlationId, 200, response)
            ),
            map((response) => response || {}),
            catchError((error) => this.log.logApiError(correlationId, error))
          );
      })
    );
  }

  setupAccount(
    request: SetupAccountExperienceRequest
  ): Observable<SetupAccountExperienceResponse> {
    return this.sessionService.getAccessToken().pipe(
      take(1),
      mergeMap((accessToken) => {
        const url = `${this.appConfigService.config.moneyProcessingApiUrl}/personal-lines-accounts/1/setup-account-experience`;
        let headers = new HttpHeaders();
        headers = this.setHeaders(headers, accessToken, true);
        headers = headers.set('X-NW-MessageID', StringUtils.generateUuid());
        const correlationId = this.log.logApiRequest(
          'POST',
          url,
          'setup-account-experience',
          request
        );
        return this.httpClient
          .post<SetupAccountExperienceResponse>(url, request.body, {
            headers,
          })
          .pipe(
            this.retryForKnownTimingIssue(correlationId),
            tap((response) =>
              this.log.logApiResponse(correlationId, 200, response)
            ),
            map((response) => response || {}),
            catchError((error) => this.log.logApiError(correlationId, error))
          );
      })
    );
  }

  private retryForKnownTimingIssue(
    correlationId: string
  ): MonoTypeOperatorFunction<SetupAccountExperienceResponse> {
    return retry({
      count: BillingAdapter.RETRY_LIMIT,
      delay: (error, retryCount) => {
        const message = error?.error?.developerMessage || '';
        if (message.match(/Policy number undefined/)) {
          this.log.logBusinessEvent('retry-setup-account', {
            correlationId,
            message,
          });
          return of(BillingAdapter.RETRY_DELAY);
        }
        return throwError(() => error);
      },
    });
  }

  establishEscrowAccount(
    request: EstablishEscrowAccountRequest
  ): Observable<any> {
    return this.sessionService.getAccessToken().pipe(
      take(1),
      mergeMap((accessToken) => {
        const url = `${this.appConfigService.config.escrowServiceUrl}/escrowAccount`;
        let headers = new HttpHeaders();
        headers = this.setHeaders(headers, accessToken, false);
        headers = headers.set('X-NW-Message-ID', StringUtils.generateUuid());
        const correlationId = this.log.logApiRequest(
          'POST',
          url,
          'establish-escrow-account',
          request
        );
        return this.httpClient
          .post<EstablishEscrowAccountResponse>(url, request, {
            headers,
          })
          .pipe(
            tap((response) => {
              this.errorOnQuietEscrowFailure(response);
              this.log.logApiResponse(correlationId, 200, response);
            }),
            map((response) => response || {}),
            catchError((error) => this.log.logApiError(correlationId, error))
          );
      })
    );
  }

  // fail & block issue on some escrow calls which return 2XX status but have a real error message
  errorOnQuietEscrowFailure(response: EstablishEscrowAccountResponse) {
    const res = response.setupNewAccountResponse;
    if (res.ReturnInfo?.returnSeverity !== 'Successful') {
      this.errorMessageService.addError({
        displayMessage: res.ReturnInfo.providerReturnInfo.providerReturnMessage,
      });
      throw res.ReturnInfo.providerReturnInfo.providerReturnMessage;
    }
  }

  getBankName(routingNumber: string): Observable<BankNameResponseModel> {
    return this.sessionService.getAccessToken().pipe(
      take(1),
      mergeMap((accessToken) => {
        const url =
          `${this.appConfigService.config.moneyProcessingUrl}/banks/` +
          routingNumber;
        let headers = new HttpHeaders();
        headers = this.setHeaders(headers, accessToken, false);
        headers = headers.set('X-NW-MessageID', StringUtils.generateUuid());

        const correlationId = this.log.logApiRequest(
          'POST',
          url,
          'get-bank-name'
        );
        return this.httpClient
          .get<BankNameResponseModel>(url, { headers })
          .pipe(
            tap((response) =>
              this.log.logApiResponse(correlationId, 200, response)
            ),
            map((response) => response || {}),
            catchError((error) => this.log.logApiError(correlationId, error))
          );
      })
    );
  }

  getAmfBankAccounts(
    agencyNumber: string
  ): Observable<AmfBankAccountsResponse> {
    return this.sessionService.getAccessToken().pipe(
      take(1),
      mergeMap((accessToken) => {
        const url = `${this.appConfigService.config.licensingAgencyAccountsUrl}/amf-bank-accounts/${agencyNumber}`;
        let headers = new HttpHeaders();
        headers = this.setHeaders(headers, accessToken, false);
        headers = headers.set('X-NW-Message-ID', StringUtils.generateUuid());
        const correlationId = this.log.logApiRequest(
          'GET',
          url,
          'get-amf-agency-accounts',
          null
        );
        return this.httpClient
          .get<AmfBankAccountsResponse>(url, {
            headers,
          })
          .pipe(
            tap((response) =>
              this.log.logApiResponse(correlationId, 200, response)
            ),
            map((response) => response || {}),
            catchError((error) => {
              this.log.logApiError(correlationId, error);
              return of(error.error);
            })
          );
      })
    );
  }

  setHeaders(
    headers: HttpHeaders,
    accessToken: Nullable<string>,
    isBillingConnectionType: boolean
  ): HttpHeaders {
    headers = headers
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this.appConfigService.config.apiKey)
      .set('Authorization', 'Bearer ' + accessToken);

    if (isBillingConnectionType && !this.appConfigService.config.production) {
      headers = headers.set(
        'connectionType',
        this.appConfigService.config.billingTargetEnv
      );
      if (this.appConfigService.config.targetEnv) {
        headers = headers.set(
          'policyCenterEnv',
          this.appConfigService.config.targetEnv
        );
      }
    }
    return headers;
  }
}
