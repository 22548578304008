import { createSelector } from '@ngrx/store';
import { getSelectedProducts } from '@entities/product/product.selectors';
import { getAllFeatureFlags } from '@entities/feature-flag/feature-flag.selector';
import { getScheduledCategoryEntities } from '@entities/scheduled-categories/scheduled-category.selector';
import {
  BaseCoverageTaskParams,
  PropertyCoverageTaskParams, UmbrellaCoverageTaskParams,
  VehicleCoverageTaskParams,
} from '@entities/task/task.model';
import { getCoveredLocationEntities } from '@entities/covered-location/covered-location.selector';
import { getDwellingReplacementCostConfirmation } from '@entities/confirmations/confirmations.selector';
import { getCoveragesFor } from '@entities/coverage/coverage.selector';
import { PropertyProductTypes } from '@shared/constants/app-constants';
import { CoverageEntity } from '@entities/coverage/coverage.entity';
import { CoveredLocationEntity } from '@entities/covered-location/covered-location.reducer';
import { ProductType } from '@core/models/api/dsm-types';
import { getAllSelectedVehiclesByProduct } from '@entities/vehicle/vehicle.selector';
import { PropertyTaskUtils } from '@entities/task/utils/property-task.utils';
import { VehicleTaskUtils } from '@entities/task/utils/vehicle-task.utils';
import { UmbrellaTaskUtils } from '@entities/task/utils/umbrella-task.utils';

export const getBaseCoverageTaskParams = createSelector(
  getSelectedProducts,
  getAllFeatureFlags,
  getScheduledCategoryEntities,
  (products,
   features,
   scheduledCategories) => {
    return {
      products: products,
      features: features,
      scheduledCategoryEntities: scheduledCategories
    } as BaseCoverageTaskParams
  }
);

export const getPropertyCoverageTaskParams = createSelector(
  getBaseCoverageTaskParams,
  getCoveredLocationEntities,
  getDwellingReplacementCostConfirmation,
  getCoveragesFor('Homeowner'),
  getCoveragesFor('Condominium'),
  getCoveragesFor('Tenant'),
  (baseCoverageTaskParams,
   coveredLocationEntities,
   dwellingReplacementCostConfirmation,
   homeCoverages,
   condoCoverages,
   tenantCoverages) => {
    const propertyProduct = baseCoverageTaskParams.products
      .find(product => PropertyProductTypes.includes(product.type));

    let coverages: CoverageEntity[] = [];
    switch (propertyProduct?.type) {
      case 'Homeowner':
        coverages = homeCoverages;
        break;
      case 'Condominium':
        coverages = condoCoverages;
        break;
      case 'Tenant':
        coverages = tenantCoverages;
        break;
      default:
        break;
    }
    let coveredLocation: CoveredLocationEntity | undefined;
    if (propertyProduct?.type) {
      coveredLocation = coveredLocationEntities[propertyProduct.type];
    }

    return {
      ...baseCoverageTaskParams,
      coverages: coverages,
      coveredLocation: coveredLocation,
      dwellingReplacementCostConfirmation: dwellingReplacementCostConfirmation
    } as PropertyCoverageTaskParams;
  }
);

export const getVehicleCoverageTaskParams = (productType: ProductType) => createSelector(
  getBaseCoverageTaskParams,
  getAllSelectedVehiclesByProduct(productType),
  getCoveragesFor(productType),
  (baseCoverageTaskParams,
   vehicles,
   coverages) => {
    return {
      ...baseCoverageTaskParams,
      vehicles: vehicles,
      coverages: coverages
    } as VehicleCoverageTaskParams;
  }
);

export const getUmbrellaCoverageTaskParams = createSelector(
  getBaseCoverageTaskParams,
  (baseCoverageTaskParams) => {
    return {
      ...baseCoverageTaskParams
    } as UmbrellaCoverageTaskParams
  }
);

export const getPropertyCoverageTasks = createSelector(
  getPropertyCoverageTaskParams,
  params => {
    return [
      ...PropertyTaskUtils.generatePropertyCoverageTasks(params)
    ];
  },
);

export const getVehicleCoverageTasks = createSelector(
  getVehicleCoverageTaskParams('PersonalAuto'),
  getVehicleCoverageTaskParams('MSA'),
  getVehicleCoverageTaskParams('RV'),
  getVehicleCoverageTaskParams('Boat'),
  (autoParams,
   msaParams,
   rvParams,
   boatParams) => {
    return [
      ...VehicleTaskUtils.generateAutoCoverageTasks(autoParams),
      ...VehicleTaskUtils.generateMsaCoverageTasks(msaParams),
      ...VehicleTaskUtils.generateRvCoverageTasks(rvParams),
      ...VehicleTaskUtils.generateBoatCoverageTasks(boatParams),
    ];
  },
);

export const getUmbrellaCoverageTasks = createSelector(
  getUmbrellaCoverageTaskParams,
  (params) => {
    return [
      ...UmbrellaTaskUtils.generateCoverageTasks(params)
    ];
  }
);
