import { EligibleDiscountRequest } from '@core/models/api/request/eligible-discount-request.model';
import { EligibleDiscountResponse } from '@core/models/api/response/eligible-discount-response.model';
import { EligibleDiscountsEntity } from '@core/store/entities/eligible-discounts/eligible-discounts.entity';

export class EligibleDiscountsBuilder {
  static buildEntityFromArrayResponse(
    response: EligibleDiscountResponse[]
  ): EligibleDiscountsEntity[] {
    return response.map((modifier) =>
      EligibleDiscountsBuilder.buildEntityFromResponse(modifier)
    );
  }

  static buildEntityFromResponse(
    response: EligibleDiscountResponse,
    request?: EligibleDiscountRequest
  ): EligibleDiscountsEntity {
    const entity: EligibleDiscountsEntity = {
      name: response.name,
      description: response.description,
      selectedOptionValue: response.selectedOptionValue,
      productType: response.productType,
      eligibleDiscountId: response.eligibleDiscountId,
      modelId: response.modelId,
    };
    if (response.productType === undefined) {
      entity.productType = request?.productType || undefined;
    }
    if (response.quoteId === undefined) {
      entity.quoteId = request?.quoteId || undefined;
    }
    if (response.modelId === undefined) {
      entity.modelId = request?.modelId || request?.quoteId || undefined;
    }
    if (response.qualifyingInformation) {
      entity.qualifyingInformation = response.qualifyingInformation;
    }
    if (response.availableOptions) {
      entity.availableOptions = response.availableOptions;
    }
    if (request?.isMultiProduct) {
      entity.isMultiProduct = request.isMultiProduct;
    }
    return entity;
  }
}
