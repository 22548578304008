export class HttpParamsUtils {

  static extractRequestedRoute(hashString: string): string | null {
    if (hashString === '') {
      return null;
    }

    let hsh = hashString.startsWith('#') ? hashString.slice(1) : hashString;
    const fragmentParams: { [key: string]: string } = hsh
      .split('&')
      .reduce((acc: { [key: string]: string }, next) => {
        let [k, v] = next.split('=');
        acc[k] = decodeURIComponent(v);
        return acc;
      }, {});
    let state;
    try {
      state = atob(fragmentParams['state']);
    } catch (e) {
      console.error('state not base-64', { fragmentParams, e });
      return null;
    }
    if (state) {
      let stateParams: { [key: string]: string | null } = state
        .split('&')
        .reduce((acc: { [key: string]: string | null }, next) => {
          let [k, v] = next.split('=');
          acc[k] = decodeURIComponent(v);
          return acc;
        }, { requestedRoute: null });
      return stateParams.requestedRoute;
    } else {
      return null;
    }
  }
}
