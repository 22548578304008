import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { showCreditNotice } from '../store/actions/disclaimers.action';

@Injectable({
  providedIn: 'root',
})
export class DisclaimersService {
  constructor(private store: Store, private actions$: Actions) {}

  showCreditNotice(): void {
    this.store.dispatch(showCreditNotice());
  }

  listenForCreditNoticeRequests(): Observable<void> {
    return this.actions$.pipe(ofType(showCreditNotice));
  }
}
