import { Injectable } from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { EligibleDiscountRequest } from '@core/models/api/request/eligible-discount-request.model';
import { EligibleDiscountsService } from '@core/services/eligible-discounts.service';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { ProductsService } from '@core/services/products.service';
import { RetrieveActions } from '@core/store/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { EligibleDiscountsBuilder } from '@shared/utils/builders/eligible-discounts.builder';
import { HomeownerPageUtils } from '@shared/utils/pages/property-page.utils';
import { StringUtils } from '@shared/utils/string.utils';
import { from, of } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { getSelectedProductTypes } from '../product/product.selectors';
import * as fromActions from './eligible-discounts.action';
import { EligibleDiscountId } from './eligible-discounts.entity';
import * as fromSelectors from './eligible-discounts.selector';

@Injectable()
export class EligibleDiscountsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private eligibleDiscountsService: EligibleDiscountsService,
    private errorSanitizerService: ErrorSanitizerService,
    private productsService: ProductsService
  ) {}

  updateVehicleDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateVehicleDiscount),
      map((action) => action.payload),
      mergeMap((request) => {
        const payload: EligibleDiscountRequest = {
          ...request,
          eligibleDiscount: {
            eligibleDiscountId: request.eligibleDiscountId,
            selectedOptionValue: request.selectedOptionValue,
            qualifyingInformation: request.qualifyingInformation,
          },
          productType: request.productType as ProductType,
        };
        return this.eligibleDiscountsService
          .updateVehicleDiscount(payload)
          .pipe(
            mergeMap((response) => {
              return of(
                fromActions.updateVehicleDiscountSuccess({
                  payload: response,
                })
              );
            }),
            catchError((error) => {
              const safeError = this.errorSanitizerService.sanitizeError(
                error,
                payload.productType
              );
              return of(
                fromActions.updateVehicleDiscountFail({ error: safeError })
              );
            })
          );
      })
    )
  );

  getPolicyLine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getPolicyLine),
      concatMap((action) =>
        this.store
          .select(
            fromSelectors.buildEligibleDiscountsRequest(
              action.productType,
              action.correlationId
            )
          )
          .pipe(
            take(1),
            map((request) => request)
          )
      ),
      concatMap((request) =>
        this.eligibleDiscountsService.getPolicyLine(request).pipe(
          switchMap((response) => {
            const entities =
              EligibleDiscountsBuilder.buildEntityFromArrayResponse(response);
            return of(
              fromActions.getPolicyLineSuccess({
                response: entities,
                correlationId: request.correlationId,
              })
            );
          }),
          catchError((error) => {
            const safeError = this.errorSanitizerService.sanitizeError(
              error,
              request.productType
            );
            return of(
              fromActions.getPolicyLineFail({
                error: safeError,
                correlationId: request.correlationId,
              })
            );
          })
        )
      )
    )
  );

  updatePolicyLineIfDefined$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updatePolicyLineIfDefined),
      switchMap((action) =>
        this.eligibleDiscountsService
          .getPolicyLineModifier(
            action.entity.eligibleDiscountId,
            action.entity.productType || ('' as ProductType)
          )
          .pipe(
            take(1),
            map((existing) => ({ action, existing }))
          )
      ),
      filter(({ action, existing }) => !!existing),
      map(({ action, existing }) =>
        fromActions.updatePolicyLine({ entity: action.entity })
      )
    )
  );

  updatePolicyLine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updatePolicyLine),
      mergeMap((action) =>
        this.store
          .select(
            fromSelectors.buildUpdatePolicyLineRequest(
              action.entity,
              action.correlationId
            )
          )
          .pipe(take(1))
      ),
      mergeMap((request) =>
        this.eligibleDiscountsService.updatePolicyLine(request).pipe(
          mergeMap((response) => {
            const entity = EligibleDiscountsBuilder.buildEntityFromResponse(
              response,
              request
            );
            return of(
              fromActions.updatePolicyLineSuccess({
                entity,
                correlationId: request.correlationId,
              })
            );
          }),
          catchError((error) => {
            const safeError = this.errorSanitizerService.sanitizeError(
              error,
              request.productType
            );
            return of(
              fromActions.updatePolicyLineFail({
                error: safeError,
                productType: request.eligibleDiscount
                  ?.productType as ProductType,
                eligibleDiscountId:
                  request.eligibleDiscount?.eligibleDiscountId ||
                  ('' as EligibleDiscountId),
                correlationId: request.correlationId,
              })
            );
          })
        )
      )
    )
  );

  updateFortifiedHome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateFortifiedHome),
      withLatestFrom(this.eligibleDiscountsService.getFortifiedHome()),
      map(([_a, fh]) =>
        fromActions.updatePolicyLine({
          entity: HomeownerPageUtils.formatFortifiedHomeEntity(fh),
        })
      )
    )
  );

  updateDriverDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateDriverDiscount),
      map((action) => action.payload),
      mergeMap((request) => {
        const payload: EligibleDiscountRequest = {
          ...request,
          eligibleDiscount: {
            eligibleDiscountId: request.eligibleDiscountId,
            selectedOptionValue: request.selectedOptionValue,
            qualifyingInformation: request.qualifyingInformation
              ? request.qualifyingInformation
              : {},
          },
          productType: request.productType as ProductType,
        };

        return this.eligibleDiscountsService.updateDriverDiscount(payload).pipe(
          mergeMap((response) => {
            return of(
              fromActions.updateDriverDiscountSuccess({
                payload: {
                  ...response,
                  modelId: request.modelId,
                },
              })
            );
          }),
          catchError((error) => {
            const safeError = this.errorSanitizerService.sanitizeError(
              error,
              payload.productType
            );
            return of(
              fromActions.updateDriverDiscountFail({ error: safeError })
            );
          })
        );
      })
    )
  );

  /*
  If a product was unselected, remove the associated eligible discounts as well
  */
  updateDiscountsOnProductUpdates$ = createEffect(() =>
    this.store.select(getSelectedProductTypes).pipe(
      distinctUntilChanged((a, b) =>
        StringUtils.unorderedStringListsEquivalent(a, b)
      ),
      withLatestFrom(this.eligibleDiscountsService.getAllEligibleDiscounts()),
      switchMap(([products, discounts]) => {
        const nextActions: Action[] = [];
        const currentlySelectedProducts = products; /*.payload*/
        const productsToRemoveDiscountsFrom = new Set<ProductType>();

        discounts.forEach((d) => {
          if (d?.productType) {
            if (!currentlySelectedProducts.includes(d.productType)) {
              productsToRemoveDiscountsFrom.add(d.productType);
            }
          }
        });
        productsToRemoveDiscountsFrom.forEach((product) =>
          nextActions.push(
            fromActions.removeDiscountsForProduct({ payload: product })
          )
        );
        return from(nextActions);
      })
    )
  );

  /* CODEMINERS-4698 2022-06-06
   * Looks like a DSM defect, sometimes we retrieve quotes with the "HomeCarIndicator_I" eligible discount having no selectedOptionValue.
   * We don't care about that missing field, but if we later update the quote, DSM rejects it.
   * So look for this one narrow case, and trigger updatePolicyLine immediately if we see it.
   */
  dontLetHomeCarIndicatorBeUndefinedAfterRetrieve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RetrieveActions.retrieveQuoteSuccess),
      map((action) => ({
        action,
        discounts: action.payload.response?.eligibleDiscounts,
      })),
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      filter(({ action, discounts }) => !!discounts),
      map(({ action, discounts }) => ({
        action,
        discountIndex: discounts!.findIndex(
          (d) => d.eligibleDiscountId === 'HomeCarIndicator_I'
        ),
      })),
      filter(({ action, discountIndex }) => discountIndex >= 0),
      map(({ action, discountIndex }) => {
        const discount =
          action.payload.response.eligibleDiscounts![discountIndex];
        if (discount.selectedOptionValue) {
          return null;
        }
        return fromActions.updatePolicyLine({
          entity: {
            ...discount,
            productType: action.payload.productType,
            selectedOptionValue: 'None',
          },
        });
      }),
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
      filterOutNull()
    )
  );
}
