import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '@core/store/actions/index';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HouseholdMemberAdapter } from '@core/adapters/household-member.adapter';
import { catchError, mergeMap, of, switchMap, take } from 'rxjs';
import { MemberEntity } from '@entities/member/member.reducer';
import { buildHouseholdMemberRequest } from './household-member.selector';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { HouseholdMemberService } from '@core/services/household-member.service';

@Injectable()
export class HouseholdMemberEffects {
  constructor(
    private actions$: Actions,
    private adapter: HouseholdMemberAdapter,
    private store: Store,
    private errorSanitizerService: ErrorSanitizerService,
    private householdMemberService: HouseholdMemberService
  ) {}

  upsertHouseholdMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.HouseholdMemberActions.upsertHouseholdMember),
      mergeMap((action) =>
        this.store
          .select(
            buildHouseholdMemberRequest(action.payload, action.correlationId)
          )
          .pipe(take(1))
      ),
      mergeMap((request) =>
        this.householdMemberService.addOrUpdateHouseholdMember(request).pipe(
          switchMap((response) => {
            return of(
              fromActions.HouseholdMemberActions.upsertHouseholdMemberSuccess({
                payload: {
                  ...response,
                  productType: request.productType,
                } as MemberEntity,
                correlationId: request.correlationId,
              })
            );
          }),
          catchError((error) => {
            const sanitizedError = this.errorSanitizerService.sanitizeError(
              error,
              'PersonalUmbrella'
            );
            return of(
              fromActions.HouseholdMemberActions.upsertHouseholdMemberFail({
                payload: sanitizedError,
                correlationId: request.correlationId,
              })
            );
          })
        )
      )
    )
  );

  deleteHouseholdMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.HouseholdMemberActions.removeHouseholdMember),
      mergeMap((action) =>
        this.store
          .select(
            buildHouseholdMemberRequest(action.payload, action.correlationId)
          )
          .pipe(take(1))
      ),
      mergeMap((request) =>
        this.householdMemberService.removeHouseholdMember(request).pipe(
          switchMap(() => {
            return of(
              fromActions.HouseholdMemberActions.removeHouseholdMemberSuccess({
                payload: request.householdMember as MemberEntity,
                correlationId: request.correlationId,
                request,
              })
            );
          }),
          catchError((error) => {
            return of(
              fromActions.HouseholdMemberActions.removeHouseholdMemberFail({
                payload: this.errorSanitizerService.sanitizeError(
                  error,
                  'PersonalUmbrella'
                ),
                householdMemberId: Number(
                  request.householdMember?.householdMemberId
                ),
                correlationId: request.correlationId,
              })
            );
          })
        )
      )
    )
  );
}
