import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../error/error.model';
import { VehicleExcludedExposureEntity } from './vehicle-excluded-exposures.entity';

export const upsertVehicleExcludedExposure = createAction(
  '[Vehicle Excluded Exposures] Upsert Vehicle Excluded Exposure',
  props<{ payload: VehicleExcludedExposureEntity; correlationId?: string }>()
);

export const upsertVehicleExcludedExposureSuccess = createAction(
  '[Vehicle Excluded Exposures] Upsert Vehicle Excluded Exposure Success',
  props<{ payload: VehicleExcludedExposureEntity; correlationId?: string }>()
);

export const upsertVehicleExcludedExposureFail = createAction(
  '[Vehicle Excluded Exposures] Upsert Vehicle Excluded Exposure Fail',
  props<{
    error: ErrorModel;
    correlationId?: string;
    entityId?: string;
  }>()
);

export const deleteVehicleExcludedExposure = createAction(
  '[Vehicle Excluded Exposures] Delete Vehicle Excluded Exposure',
  props<{ payload: VehicleExcludedExposureEntity; correlationId?: string }>()
);

export const deleteVehicleExcludedExposureSuccess = createAction(
  '[Vehicle Excluded Exposures] Delete Vehicle Excluded Exposure Success',
  props<{
    vehicleExcludedExposureId: string;
    correlationId?: string;
  }>()
);

export const deleteVehicleExcludedExposureFail = createAction(
  '[Vehicle Excluded Exposures] Delete Vehicle Excluded Exposure Fail',
  props<{
    error: ErrorModel;
    correlationId?: string;
    entityId?: string;
  }>()
);

export const completelyRemoveVehicleExcludedExposure = createAction(
  '[Vehicle Excluded Exposure] Completely Remove Vehicle Excluded Exposure',
  props<{ vehicleExcludedExposureId: string }>()
);
