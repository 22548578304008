import { createAction, props } from '@ngrx/store';
import { FeatureFlagModel } from './feature-flag.reducer';

export const setAllFeaureFlags = createAction(
  '[Feature Flag] Set All Feature Flags'
);

export const replaceAllFeatureFlags = createAction(
  '[Feature Flag] Replace All Feature Flags',
  props<{ featureFlags: FeatureFlagModel[] }>()
);
