<div class="modal-header">
  <div class="modal-header-title">
    {{ product.name }} coverage summary
  </div>
  <div class="modal-header-label">Submission ID# {{ product.quoteId }}</div>
</div>
<div class="modal-body">

  <div class="section premium">
    <span class="monthly-premium">{{ monthlyPremium }}</span>
    <span class="monthly-premium-comment">&nbsp;/ month</span>
    <span class="spacer"></span>
    <span class="term-premium">{{ termPremium }}</span>
    <span class="term-premium-comment">&nbsp;/ {{ termMonths }} month term</span>
  </div>

  <div class="section">
    <div class="unpad section-title">Policy coverages overview</div>
    <nwx-coverages-table
      [coverages]="getPolicyCoverages()"
      [termMonths]="termMonths"
    ></nwx-coverages-table>
  </div>

  <div class="section" *ngFor="let vehicle of vehicles">
    <div class="unpad section-title">
      <span class="ymm">{{ getVehicleHeadline(vehicle) }}</span>
      <div class="telematics-row">
        <div class="bolt-row bolt-justify-content-between" *ngIf="enrollment">
          <div
            class="bolt-col-xs"
            *ngIf="getTelematicsEnrollmentById(vehicle.vehicleId) as programType"
          >
            <span [class]="programType" class="telematics-badge">
              <div>
                <strong
                  >{{
                    programType === 'SmartRideInstant'
                      ? 'SmartRide Instant'
                      : programType
                  }}®</strong
                >
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <nwx-coverages-table
      [coverages]="getVehicleCoverages(vehicle)"
      [termMonths]="termMonths"
    ></nwx-coverages-table>
  </div>

</div>
<div class="modal-footer">
  <div class="buttons-row">
    <a href="javascript:0" (click)="onEditCoverages($event)" class="edit-coverages-link">
      <bolt-icon name="pencil"></bolt-icon>
      <div>Edit all coverages</div>
    </a>
    <bolt-button type="primary" (click)="onClose()">Close</bolt-button>
  </div>
</div>
