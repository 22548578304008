import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as fromActions from '@core/store/actions/index';
import * as fromFormActions from '@forms-store/store/actions/index';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import {
  concatMap,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { ProductsService } from '@core/services/products.service';
import { ProductType } from '@core/models/api/dsm-types';
import { MetadataService } from '@core/services/metadata.service';
import { LogService } from '@core/services/log.service';
import { OperatorFunction } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuoteStatusChangesEffect {
  constructor(
    private store: Store,
    private actions$: Actions,
    private productsService: ProductsService,
    private metaDataService: MetadataService,
    private logService: LogService
  ) {}

  dropAutoToDraftOnChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.VehicleActions.addVehicleSuccess,
        fromActions.VehicleActions.updateVehicleSuccess,
        fromActions.VehicleActions.deleteVehicleSuccess,
        fromActions.DriverActions.upsertDriverSuccess,
        fromActions.DriverActions.deleteDriverSuccess,
        fromActions.TelematicsActions.addTelematicsEnrollmentSuccess,
        fromActions.TelematicsActions.updateTelematicsEnrollmentSuccess,
        fromActions.ProductActions.removeProduct
      ),
      withLatestFrom(
        this.productsService.getProductStatus('PersonalAuto'),
        this.metaDataService.getStateSpecificFlag('preBindDocuments')
      ),
      switchMap(([action, status, preBindDocumentsEnabled]) => {
        const nextActions: Action[] = [];
        if (status === 'Quoted' || status === 'Binding' || status === 'Error') {
          this.logRecalculate('PersonalAuto', action.type);
          nextActions.push(
            fromActions.ProductActions.updateProductStatus({
              payload: 'PersonalAuto',
              status: 'Draft',
            })
          );

          if (preBindDocumentsEnabled) {
            nextActions.push(
              fromFormActions.ConfirmationsAction.updatePreBindDocumentsForm({
                payload: {
                  hasReceivedDocuments: false,
                  hasReviewedDocuments: false,
                  doesNotHaveQuestions: false,
                },
              })
            );
          }
        }
        return nextActions;
      }),
      filterOutNull()
    )
  );

  dropMSAToDraftOnChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.VehicleActions.addVehicleSuccess,
        fromActions.VehicleActions.updateVehicleSuccess,
        fromActions.VehicleActions.deleteVehicleSuccess,
        fromActions.DriverActions.upsertDriverSuccess,
        fromActions.DriverActions.deleteDriverSuccess,
        fromActions.ProductActions.removeProduct
      ),
      withLatestFrom(
        this.productsService.getProductStatus('MSA'),
        this.metaDataService.getStateSpecificFlag('preBindDocuments')
      ),
      switchMap(([action, status, preBindDocumentsEnabled]) => {
        const nextActions: Action[] = [];
        if (status === 'Quoted' || status === 'Binding' || status === 'Error') {
          this.logRecalculate('MSA', action.type);
          nextActions.push(
            fromActions.ProductActions.updateProductStatus({
              payload: 'MSA',
              status: 'Draft',
            })
          );

          if (preBindDocumentsEnabled) {
            nextActions.push(
              fromFormActions.ConfirmationsAction.updatePreBindDocumentsForm({
                payload: {
                  hasReceivedDocuments: false,
                  hasReviewedDocuments: false,
                  doesNotHaveQuestions: false,
                },
              })
            );
          }
        }
        return nextActions;
      }),
      filterOutNull()
    )
  );

  dropBoatToDraftOnChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.VehicleActions.addVehicleSuccess,
        fromActions.VehicleActions.updateVehicleSuccess,
        fromActions.VehicleActions.deleteVehicleSuccess,
        fromActions.DriverActions.upsertDriverSuccess,
        fromActions.DriverActions.deleteDriverSuccess,
        fromActions.ProductActions.removeProduct
      ),
      withLatestFrom(this.productsService.getProductStatus('Boat')),
      switchMap(([action, status]) => {
        const nextActions: Action[] = [];
        if (status === 'Quoted' || status === 'Binding' || status === 'Error') {
          this.logRecalculate('Boat', action.type);
          nextActions.push(
            fromActions.ProductActions.updateProductStatus({
              payload: 'Boat',
              status: 'Draft',
            })
          );
        }
        return nextActions;
      }),
      filterOutNull()
    )
  );

  dropRVToDraftOnChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.VehicleActions.addVehicleSuccess,
        fromActions.VehicleActions.updateVehicleSuccess,
        fromActions.VehicleActions.deleteVehicleSuccess,
        fromActions.DriverActions.upsertDriverSuccess,
        fromActions.DriverActions.deleteDriverSuccess,
        fromActions.ProductActions.removeProduct,
        fromActions.ScheduledCategoryActions.updateScheduledCategorySuccess
      ),
      withLatestFrom(this.productsService.getProductStatus('RV')),
      switchMap(([action, status]) => {
        const nextActions: Action[] = [];
        if (status === 'Quoted' || status === 'Binding' || status === 'Error') {
          this.logRecalculate('RV', action.type);
          nextActions.push(
            fromActions.ProductActions.updateProductStatus({
              payload: 'RV',
              status: 'Draft',
            })
          );
        }
        return nextActions;
      }),
      filterOutNull()
    )
  );

  dropHomeToDraftOnChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.PolicyHolderActions.upsertPolicyHolderSuccess,
        fromActions.PolicyHolderActions.deletePolicyHolderSuccess,
        fromActions.CoveredLocationActions.updateCoveredLocation,
        fromActions.ProductActions.removeProduct,
        fromActions.ScheduledCategoryActions.updateScheduledCategorySuccess
      ),
      withLatestFrom(this.productsService.getProductStatus('Homeowner')),
      map(([action, status]) => {
        if (status === 'Quoted' || status === 'Binding' || status === 'Error') {
          this.logRecalculate('Homeowner', action.type);
          return fromActions.ProductActions.updateProductStatus({
            payload: 'Homeowner',
            status: 'Draft',
          });
        }
        return null;
      }),
      filterOutNull()
    )
  );

  dropRentersToDraftOnChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.PolicyHolderActions.upsertPolicyHolderSuccess,
        fromActions.PolicyHolderActions.deletePolicyHolderSuccess,
        fromActions.CoveredLocationActions.updateCoveredLocation,
        fromActions.ProductActions.removeProduct
      ),
      withLatestFrom(this.productsService.getProductStatus('Tenant')),
      map(([action, status]) => {
        if (status === 'Quoted' || status === 'Binding' || status === 'Error') {
          this.logRecalculate('Tenant', action.type);
          return fromActions.ProductActions.updateProductStatus({
            payload: 'Tenant',
            status: 'Draft',
          });
        }
        return null;
      }),
      filterOutNull()
    )
  );

  dropCondoToDraftOnChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.PolicyHolderActions.upsertPolicyHolderSuccess,
        fromActions.PolicyHolderActions.deletePolicyHolderSuccess,
        fromActions.CoveredLocationActions.updateCoveredLocation,
        fromActions.ProductActions.removeProduct,
        fromActions.ScheduledCategoryActions.updateScheduledCategorySuccess
      ),
      withLatestFrom(this.productsService.getProductStatus('Condominium')),
      map(([action, status]) => {
        if (status === 'Quoted' || status === 'Binding' || status === 'Error') {
          this.logRecalculate('Condominium', action.type);
          return fromActions.ProductActions.updateProductStatus({
            payload: 'Condominium',
            status: 'Draft',
          });
        }
        return null;
      }),
      filterOutNull()
    )
  );

  /**
   * getAllCoverages is not idempotent, like you'd think.
   * We set the `refreshCoverages=true` flag, which updates things on the backend and resets quote status.
   */
  dropRelevantProductToDraftOnUpdateOrGetCoverage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.CoverageActions.updateCoverageSuccess,
        fromActions.CoverageActions.getAllCoveragesSuccess
      ),
      map(
        (action: any) =>
          action.payload?.[0]?.productId ||
          action.updatedCoverage?.[0]?.productId
      ) as OperatorFunction<any, ProductType>,
      filterOutNull(),
      concatMap((productId) =>
        this.productsService.getProductStatus(productId).pipe(
          take(1),
          map((status) => ({
            productId,
            status,
          }))
        )
      ),
      map(({ productId, status }) => {
        if (productId) {
          if (
            status === 'Quoted' ||
            status === 'Binding' ||
            status === 'Error'
          ) {
            this.logRecalculate(productId, 'Coverage change');
            return fromActions.ProductActions.updateProductStatus({
              payload: productId,
              status: 'Draft',
            });
          }
        }
        return null;
      }),
      filterOutNull()
    )
  );

  dropRelevantProductToDraftOnUpdateDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.EligibleDiscountsActions.updatePolicyLineSuccess),
      concatMap((action) =>
        this.productsService
          .getProductStatus(action.entity.productType as ProductType)
          .pipe(
            take(1),
            map((status) => ({
              productId: action.entity.productType,
              status,
              eligibleDiscountId: action.entity.eligibleDiscountId,
            }))
          )
      ),
      map(({ productId, status, eligibleDiscountId }) => {
        if (productId) {
          if (
            status === 'Quoted' ||
            status === 'Binding' ||
            status === 'Error'
          ) {
            this.logRecalculate(
              productId,
              `Discount change (${eligibleDiscountId})`
            );
            return fromActions.ProductActions.updateProductStatus({
              payload: productId,
              status: 'Draft',
            });
          }
        }
        return null;
      }),
      filterOutNull()
    )
  );

  dropProductToDraftOnManualInvalidation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.QuoteActions.manuallyInvalidateProduct),
      map((action) => {
        this.logRecalculate(action.productType, action.reason);
        return fromActions.ProductActions.updateProductStatus({
          payload: action.productType,
          status: 'Draft',
        });
      })
    )
  );

  private logRecalculate(productId: ProductType, reason: string): void {
    this.logService.logBusinessEvent('recalc-required', { productId, reason });
  }
}
