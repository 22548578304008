import { SharedComponentsModule } from './../shared/components/shared-components.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavSidebarComponent } from './nav-sidebar/nav-sidebar.component';
import { RouterModule } from '@angular/router';
import { AccountInfoContainerModule } from '@shared/components/account-info-container/account-info-container.module';

@NgModule({
  declarations: [NavSidebarComponent],
  exports: [NavSidebarComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    SharedComponentsModule,
    AccountInfoContainerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NavSidebarModule {}
