import { PreBindDocumentUploadResponse } from '@core/models/api/response/pre-bind-document-response.model';
import { PreBindDocumentEntity } from '@core/models/entities/pre-bind-document.entity';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from '@core/store/actions/index';

export interface PreBindDocumentsState
  extends EntityState<PreBindDocumentEntity> {
  personalAutoUploadResponse: PreBindDocumentUploadResponse;
  msaUploadResponse: PreBindDocumentUploadResponse;
  loading: number;
  loaded: boolean;
  emailResending: boolean;
  emailResent: boolean;
}

export const adapter: EntityAdapter<PreBindDocumentEntity> =
  createEntityAdapter<PreBindDocumentEntity>({
    selectId: (document) => document.documentId,
  });

export const initialState = adapter.getInitialState({
  personalAutoUploadResponse: {
    folderId: '',
    password: '',
    folderUrl: '',
  },
  msaUploadResponse: {
    folderId: '',
    password: '',
    folderUrl: '',
  },
  loading: 0,
  loaded: false,
  emailResending: false,
  emailResent: false,
});

const preBindDocumentsReducer = createReducer(
  initialState,
  on(fromActions.SessionActions.clearSessionState, () => initialState),
  on(fromActions.PreBindDocumentsActions.uploadPreBindDocuments, (state) => ({
    ...state,
    loading: state.loading + 1,
    loaded: false,
  })),
  on(
    fromActions.PreBindDocumentsActions.uploadPreBindDocumentsFail,
    (state) => ({
      ...state,
      loading: state.loading - 1,
      loaded: state.loading - 1 === 0 ? true : false,
    })
  ),
  on(
    fromActions.PreBindDocumentsActions.uploadPreBindDocumentsSuccess,
    (state, { uploadResponse, productType }) => ({
      ...state,
      personalAutoUploadResponse:
        productType === 'PersonalAuto'
          ? uploadResponse
          : state.personalAutoUploadResponse,
      msaUploadResponse:
        productType === 'MSA' ? uploadResponse : state.msaUploadResponse,
      loading: state.loading - 1,
      loaded: state.loading - 1 === 0 ? true : false,
    })
  ),
  on(
    fromActions.PreBindDocumentsActions.replacePreBindDocumentsByProductType,
    (state, { documents, productType }) => {
      const entityIdsTobeRemoved: number[] = Object.values(state.entities)
        .filter(
          (entity?: PreBindDocumentEntity) =>
            entity?.productType === productType
        )
        .map((entity) => entity?.documentId || 0);

      const updatedState = adapter.removeMany(entityIdsTobeRemoved, {
        ...state,
      });
      return adapter.upsertMany(documents, {
        ...updatedState,
      });
    }
  ),
  on(fromActions.PreBindDocumentsActions.dropAllPreBindDocuments, (state) => {
    return adapter.removeAll(state);
  }),
  on(
    fromActions.PreBindDocumentsActions.dropPreBindDocuments,
    (state, { productType }) => {
      return adapter.removeMany(
        (doc) => doc.productType === productType,
        state
      );
    }
  ),
  on(fromActions.PreBindDocumentsActions.resendDocumentUrlEmail, (state) => ({
    ...state,
    emailResending: true,
    emailResent: false,
  })),
  on(
    fromActions.PreBindDocumentsActions.resendDocumentUrlEmailSuccess,
    (state, { response }) => ({
      ...state,
      personalAutoUploadResponse: {
        ...state.personalAutoUploadResponse,
        emailSuccess: response.hasError,
      },
      msaUploadResponse: {
        ...state.msaUploadResponse,
        emailSuccess: response.hasError,
      },
      emailResending: false,
      emailResent: true,
    })
  ),
  on(
    fromActions.PreBindDocumentsActions.resendDocumentUrlEmailFail,
    (state) => ({
      ...state,
      personalAutoUploadResponse: {
        ...state.personalAutoUploadResponse,
        emailSuccess: false,
      },
      msaUploadResponse: {
        ...state.msaUploadResponse,
        emailSuccess: false,
      },
      emailResending: false,
      emailResent: true,
    })
  ),
  on(
    fromActions.PreBindDocumentsActions.updatePreBindDocument,
    (state, { document }) => {
      return adapter.updateOne(
        {
          id: document.documentId,
          changes: document,
        },
        state
      );
    }
  )
);

export function reducer(
  state: PreBindDocumentsState | undefined,
  action: Action
): PreBindDocumentsState {
  return preBindDocumentsReducer(state, action);
}
