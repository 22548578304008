<div class="bolt-container-fluid">
  <div class="bolt-row bolt-align-items-center">
    <div class="bolt-col-12">
      <p>
        This vehicle instantly earned a SmartRide&reg; discount, based on safe
        driving behavior.
      </p>
    </div>
    <div class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4">
      <div class="image-section">
        <div class="image-circle">
          <img
            class="tm-icon"
            src="assets/images/telematics/connected-car-dk-blue.svg"
            alt=""
          />
        </div>
        <p class="info">
          Discount applied based upon the driving data already shared.
          <bolt-contextual-help>
            <p>
              <strong>How does this work?</strong><br />
              This vehicle has built-in connected car technology that can
              capture driving behavior. The customer earned a SmartRide&reg;
              safe-driving behavior discount, based on the historical data
              already shared with us.
            </p>
          </bolt-contextual-help>
        </p>
      </div>
    </div>
    <div class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4">
      <div class="image-section">
        <div class="image-circle">
          <img
            class="tm-icon"
            src="assets/images/telematics/dollar-lock-dk-blue.svg"
            alt=""
          />
        </div>
        <p class="info">
          Keep the discount for as long as the vehicles are insured with us.
          <bolt-contextual-help>
            <p>
              <strong>How does this work?</strong><br />
              The SmartRide&reg; discount has been applied. No additional data
              collection or action is required to keep the discount.
            </p>
          </bolt-contextual-help>
        </p>
      </div>
    </div>
    <div class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4 video">
      <iframe
        id="kaltura_player_sri"
        src="https://cdnapisec.kaltura.com/p/2256561/sp/225656100/embedIframeJs/uiconf_id/38502422/partner_id/2256561?iframeembed=true&playerId=kaltura_player_sri&entry_id=1_gk2uqdbb&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=1_p56o5kdp"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        frameborder="0"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div *ngFor="let vehicle of vehicles; let i = index">
    <nwx-telematics-vehicle-premium
      [vehicle]="vehicle"
      [vehicleIndex]="i"
      [currentProgram]="'SmartRideInstant'"
      [enrollment]="enrollment"
    ></nwx-telematics-vehicle-premium>
  </div>
</div>
