import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DsmAdapter, responseUnused } from './dsm.adapter';
import { AppConfigService } from '@core/services/app-config.service';
import {
  LocationExposureEntity,
  sanitizeLocationExposureEntity,
} from '@entities/exposures/location-exposures/location-exposures.entity';
import { HttpHeaders } from '@angular/common/http';
import {
  LocationExcludedExposureRequest,
  LocationExposureRequest,
} from '@core/models/api/request/location-exposure-request.model';
import {
  LocationExcludedExposureEntity,
  sanitizeLocationExcludedExposureEntity,
} from '@entities/exposures/location-excluded-exposures/location-excluded-exposures.entity';

@Injectable({
  providedIn: 'root',
})
export class LocationExposureAdapter {
  constructor(
    public adapter: DsmAdapter,
    public appService: AppConfigService
  ) {}

  addLocationExposure(
    request: LocationExposureRequest
  ): Observable<LocationExposureEntity> {
    let headers;
    if (!this.appService.isProd && this.appService.config.autoApproveUW) {
      headers = new HttpHeaders({
        'Auto-Approve-Rules': 'true',
      });
    }
    return this.adapter.request(
      sanitizeLocationExposureEntity,
      'PersonalUmbrella',
      'POST',
      `/quotes/${request.quoteId}/location-exposures`,
      'add-location-exposure',
      {
        body: request.locationExposureBody,
        headers,
      }
    );
  }

  addLocationExcludedExposure(
    request: LocationExcludedExposureRequest
  ): Observable<LocationExcludedExposureEntity> {
    let headers;
    if (!this.appService.isProd && this.appService.config.autoApproveUW) {
      headers = new HttpHeaders({
        'Auto-Approve-Rules': 'true',
      });
    }
    return this.adapter.request(
      sanitizeLocationExcludedExposureEntity,
      'PersonalUmbrella',
      'POST',
      `/quotes/${request.quoteId}/location-excluded-exposures`,
      'add-location-excluded-exposure',
      {
        body: request.locationExcludedExposureBody,
        headers,
      }
    );
  }

  updateLocationExposure(
    request: LocationExposureRequest
  ): Observable<LocationExposureEntity> {
    let headers;
    if (!this.appService.isProd && this.appService.config.autoApproveUW) {
      headers = new HttpHeaders({
        'Auto-Approve-Rules': 'true',
      });
    }
    return this.adapter.request(
      sanitizeLocationExposureEntity,
      'PersonalUmbrella',
      'PUT',
      `/quotes/${request.quoteId}/location-exposures/${request.locationExposureBody.locationExposure.locationExposureId}`,
      'update-location-exposure',
      {
        body: request.locationExposureBody,
        headers,
      }
    );
  }

  updateLocationExcludedExposure(
    request: LocationExcludedExposureRequest
  ): Observable<LocationExcludedExposureEntity> {
    let headers;
    if (!this.appService.isProd && this.appService.config.autoApproveUW) {
      headers = new HttpHeaders({
        'Auto-Approve-Rules': 'true',
      });
    }
    return this.adapter.request(
      sanitizeLocationExcludedExposureEntity,
      'PersonalUmbrella',
      'PUT',
      `/quotes/${request.quoteId}/location-excluded-exposures/${request.locationExcludedExposureBody.locationExcludedExposure.locationExcludedExposureId}`,
      'update-location-exposure',
      {
        body: request.locationExcludedExposureBody,
        headers,
      }
    );
  }

  removeLocationExposure(
    request: LocationExposureRequest
  ): Observable<unknown> {
    return this.adapter.request(
      responseUnused,
      'PersonalUmbrella',
      'DELETE',
      `/quotes/${request.quoteId}/location-exposures/${request.locationExposureBody.locationExposure.locationExposureId}`,
      'remove-location-exposure'
    );
  }

  removeLocationExcludedExposure(
    request: LocationExcludedExposureRequest
  ): Observable<unknown> {
    let headers;
    if (!this.appService.isProd && this.appService.config.autoApproveUW) {
      headers = new HttpHeaders({
        'Auto-Approve-Rules': 'true',
      });
    }
    return this.adapter.request(
      responseUnused,
      'PersonalUmbrella',
      'DELETE',
      `/quotes/${request.quoteId}/location-excluded-exposures/${request.locationExcludedExposureBody.locationExcludedExposure.locationExcludedExposureId}`,
      'remove-location-excluded-exposure',
      {
        body: request.locationExcludedExposureBody,
        headers,
      }
    );
  }
}

