import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DiscountsService } from '@core/services/discounts.service';
import { ProductsService } from '@core/services/products.service';
import {
  GroupedDiscounts,
  DiscountEntity,
} from '@core/store/entities/discount/discount.entity';
import { ProductModel } from '@core/store/entities/product/product.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'nwx-discount-display-container',
  templateUrl: './discount-display-container.component.html',
  styleUrls: ['./discount-display-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountDisplayContainerComponent {
  discounts$: Observable<GroupedDiscounts[][]>;
  products$: Observable<ProductModel[]>;
  allDiscounts$: Observable<DiscountEntity[]>;

  constructor(
    private discountsService: DiscountsService,
    private productsService: ProductsService
  ) {
    this.products$ =
      this.productsService.getSelectedQuoteProductsWithoutErrors();
    this.discounts$ = this.discountsService.getGroupedSelectedDiscounts();
    this.allDiscounts$ = this.discountsService.getAllNonDuplicateDiscounts();
  }
}
