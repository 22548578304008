import { ObjectValidator } from '@core/helper/object-validator';
import { ProductType } from '../api/dsm-types';

export interface PreBindDocumentEntity {
  name: string;
  documentId: number;
  documentByteStream?: string;
  productType: ProductType;
}

export function sanitizePreBindDocumentEntity(
  input: unknown
): PreBindDocumentEntity {
  return ObjectValidator.forceSchema<PreBindDocumentEntity>(
    input,
    {
      name: 'string',
      documentId: 'number',
      documentByteStream: 'string',
      productType: 'string',
    },
    ['productType']
  );
}
