import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Reducers } from '@core/constants/reducers';
import { EffectsModule } from '@ngrx/effects';
import { createReducer, StoreModule } from '@ngrx/store';
import { reducers } from './store/reducers';
import { BillingPlansEffects } from './store/models/billing-plans/billing-plans.effect';
import { ConfirmationsEffects } from './store/models/confirmations/confirmations.effect';

const effects = [BillingPlansEffects, ConfirmationsEffects];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(Reducers.Forms, reducers),
    EffectsModule.forFeature(effects),
    EffectsModule.forRoot(),
    StoreModule.forRoot({ Forms: createReducer({}) }),
  ],
})
export class FormsStoreModule {}
