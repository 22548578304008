<form
  [formGroup]="form"
  [nwxExposeFormErrors]="form"
  formName="msa-vin"
  *ngIf="vm$ | async as vm"
>
  <ng-container *ngIf="!vehicle.prefillId; else immutableVIN">
    <bolt-textfield
      class="nwpii"
      label="VIN"
      formControlName="vin"
      name="vin-{{ vehicleIndex }}"
      [id]="'vin-' + vehicleIndex"
      [instructionaltext]="vm.instructionalText"
      [attr.required]="required || null"
    ></bolt-textfield>
  </ng-container>

  <ng-template #immutableVIN>
    <div class="readonly-label">
      <div class="vin-label">VIN</div>
      <div class="readonly">
        {{ vehicle.vin }}
      </div>
    </div>
  </ng-template>

  <bolt-modal
    #disambiguationModal
    type="question"
    heading="Pick a vehicle"
    primarybutton="Select vehicle"
    (bolt-modal-primary-button-click)="submitVinDisambiguation()"
  >
    <form [formGroup]="disambiguationForm">
      <bolt-select
        label=""
        formControlName="selection"
        name="selection"
        [id]="'disambiguation-selection-' + vehicleIndex"
        optionaltext="hide"
      >
        <option
          *ngFor="let option of disambiguationOptions"
          [value]="option.value.model"
        >
          {{ option.displayHtml }}
        </option>
      </bolt-select>
    </form>
  </bolt-modal>
</form>
