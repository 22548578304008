import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProductType } from '@core/models/api/dsm-types';
import {
  getCoveredLocationEntity,
  getCoveredLocationLoaded,
} from '../store/entities/covered-location/covered-location.selector';
import { Observable, filter, switchMap, take, tap, throwError } from 'rxjs';
import { CoveredLocationEntity } from '@core/store/entities/covered-location/covered-location.reducer';
import { Nullable } from '@shared/utils/type.utils';
import { CoveredLocationSelectors } from '@core/store/selectors';
import { Dictionary } from '@ngrx/entity';
import { CoveredLocationModel } from '@core/store/entities/covered-location/covered-location.model';
import { CoveredLocationActions } from '@core/store/actions';
import { LoadingService } from './loading.service';
import { PropertyService } from './property.service';
import { patchCoveredLocationInStore } from '@entities/covered-location/covered-location.action';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root',
})
export class CoveredLocationService {
  constructor(
    private store: Store,
    private loadingService: LoadingService,
    private propertyService: PropertyService,
    private actions$: Actions,
    private window: Window
  ) {}

  dispatchGetCoveredLocation(productType: ProductType): void {
    this.store.dispatch(
      CoveredLocationActions.getCoveredLocation({
        payload: productType,
      })
    );
  }

  resetCoveredLocationLoaded(): void {
    this.store.dispatch(CoveredLocationActions.resetCoveredLocationLoaded());
  }

  removeCoveredLocation(productType: ProductType): void {
    this.store.dispatch(
      CoveredLocationActions.removeCoveredLocation({ payload: productType })
    );
  }

  updateCoveredLocation(
    productType: ProductType,
    includeMsbEstimateNumber: boolean
  ): void {
    this.store.dispatch(
      CoveredLocationActions.updateCoveredLocation({
        payload: productType,
        includeMsbEstimateNumber,
      })
    );
  }

  getCoveredLocationLoading(): Observable<Nullable<boolean>> {
    return this.store.select(
      CoveredLocationSelectors.getCoveredLocationLoading
    );
  }

  getAllCoveredLocations(): Observable<
    Nullable<Dictionary<CoveredLocationEntity>>
  > {
    return this.store.select(
      CoveredLocationSelectors.getCoveredLocationEntities
    );
  }

  getCoveredLocation(
    productType: ProductType
  ): Observable<CoveredLocationModel> {
    return this.store.select(
      CoveredLocationSelectors.selectCoveredLocationModel(productType)
    );
  }

  getCoveredLocationLoaded(productType: ProductType): Observable<boolean> {
    return this.store.select(getCoveredLocationLoaded(productType));
  }

  getCoveredLocationFailed(): Observable<boolean> {
    return this.store.select(CoveredLocationSelectors.getCoveredLocationFailed);
  }

  orchestrateMsbEstimateNumberChangeWithSpinner(
    associatedMSBEstimateNumber: string,
    productType: ProductType
  ): Observable<boolean> {
    this.loadingService.beginLoading(
      'msb-estimate',
      'Retrieving MSB Estimate...'
    );
    this.propertyService.storeCoveredLocation({
      associatedMSBEstimateNumber,
      productType,
    } as CoveredLocationEntity);
    this.updateCoveredLocation(productType, true);
    return this.getCoveredLocationLoading().pipe(
      filter((loading) => !loading),
      take(1),
      tap((_) => this.loadingService.endLoading('msb-estimate')),
      switchMap((_) => this.getCoveredLocationFailed().pipe(take(1)))
    );
  }

  getCoveredLocationEntity(
    productType: ProductType
  ): Observable<CoveredLocationEntity> {
    return this.store.select(getCoveredLocationEntity(productType));
  }

  patchCoveredLocationInStore(
    coveredLocation: Partial<CoveredLocationEntity>
  ): void {
    this.store.dispatch(patchCoveredLocationInStore({ coveredLocation }));
  }

  getCoveredLocationIfNotLoadedYet(
    productType: ProductType
  ): Observable<CoveredLocationEntity> {
    return this.getCoveredLocationLoaded(productType).pipe(
      take(1),
      switchMap((loaded) => {
        if (loaded) {
          return this.getCoveredLocationEntity(productType);
        }
        // Must kick to the next execution frame, to allow time for the upcoming observable to get subscribed to.
        // Otherwise we would miss a synchronous result from getCoveredLocation.
        this.window.setTimeout(() => {
          this.dispatchGetCoveredLocation(productType);
        }, 0);
        return this.actions$.pipe(
          ofType(
            CoveredLocationActions.getCoveredLocationSuccess,
            CoveredLocationActions.getCoveredLocationFail
          ),
          switchMap((action) => {
            if (
              action.type ===
              CoveredLocationActions.getCoveredLocationSuccess.type
            ) {
              return this.getCoveredLocationEntity(productType);
            }
            return throwError(() => action);
          })
        );
      })
    );
  }
}
