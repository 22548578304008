import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WatercraftExposureAdapter } from '@core/adapters/watercraft-exposure.adapter';
import {
  WatercraftExcludedExposureRequest,
  WatercraftExposureRequest,
} from '@core/models/api/request/watercraft-exposure-request.model';
import { Store } from '@ngrx/store';
import {
  deleteWatercraftExposure,
  upsertWatercraftExposure,
} from '@entities/exposures/watercraft-exposures/watercraft-exposures.action';
import {
  WatercraftExposureAndProductInfo,
  WatercraftExposureEntity,
} from '@entities/exposures/watercraft-exposures/watercraft-exposures.entity';
import {
  buildWatercraftExposures,
  getWatercraftExposureOptions,
  getWatercraftExposureTiles,
  getWatercraftExposuresAndProductInfo,
  watercraftExposuresExist,
} from '@entities/exposures/watercraft-exposures/watercraft-exposures.selector';
import { Nullable } from '@shared/utils/type.utils';
import { WatercraftExposureOptionsModel } from '@entities/metadata/models/umbrella-options.model';
import {
  WatercraftExcludedExposureAndProductInfo,
  WatercraftExcludedExposureEntity,
} from '@entities/exposures/watercraft-excluded-exposures/watercraft-excluded-exposures.entity';
import {
  getWatercraftExcludedExposureTiles,
  getWatercraftExcludedExposuresAndProductInfo,
} from '@entities/exposures/watercraft-excluded-exposures/watercraft-excluded-exposures.selector';
import {
  deleteWatercraftExcludedExposure,
  upsertWatercraftExcludedExposure,
} from '@entities/exposures/watercraft-excluded-exposures/watercraft-excluded-exposures.action';
import { UmbrellaTileModel } from '@shared/components/umbrella-tile/umbrella-tile.component';
@Injectable({
  providedIn: 'root',
})
export class WatercraftExposureService {
  constructor(
    private adapter: WatercraftExposureAdapter,
    private store: Store
  ) {}

  addOrUpdateWatercraftExposure(
    request: WatercraftExposureRequest
  ): Observable<WatercraftExposureEntity> {
    if (
      request.watercraftExposureBody.watercraftExposure.watercraftExposureId
    ) {
      return this.adapter.updateWatercraftExposure(request);
    } else {
      return this.adapter.addWatercraftExposure(request);
    }
  }

  upsertWatercraftExposure(watercraftExposure: WatercraftExposureEntity): void {
    this.store.dispatch(
      upsertWatercraftExposure({ payload: watercraftExposure })
    );
  }

  removeWatercraftExposure(WatercraftExposure: WatercraftExposureEntity): void {
    this.store.dispatch(
      deleteWatercraftExposure({ payload: WatercraftExposure })
    );
  }

  deleteWatercraftExposure(request: WatercraftExposureRequest): Observable<unknown> {
    return this.adapter.removeWatercraftExposure(request);
  }

  getWatercraftExposuresAndProductInfo(): Observable<
    WatercraftExposureAndProductInfo[]
  > {
    return this.store.select(getWatercraftExposuresAndProductInfo);
  }

  getWatercraftExposureTiles(): Observable<UmbrellaTileModel[]> {
    return this.store.select(getWatercraftExposureTiles);
  }

  getWatercraftExposureOptions(): Observable<
    Nullable<WatercraftExposureOptionsModel>
  > {
    return this.store.select(getWatercraftExposureOptions);
  }

  addOrUpdateWatercraftExcludedExposure(
    request: WatercraftExcludedExposureRequest
  ): Observable<WatercraftExcludedExposureEntity> {
    if (
      request.watercraftExcludedExposureBody.watercraftExcludedExposure
        .watercraftExcludedExposureId
    ) {
      return this.adapter.updateWatercraftExcludedExposure(request);
    } else {
      return this.adapter.addWatercraftExcludedExposure(request);
    }
  }

  upsertWatercraftExcludedExposure(
    watercraftExcludedExposure: WatercraftExcludedExposureEntity
  ): void {
    this.store.dispatch(
      upsertWatercraftExcludedExposure({ payload: watercraftExcludedExposure })
    );
  }

  removeWatercraftExcludedExposure(
    watercraftExcludedExposure: WatercraftExcludedExposureEntity
  ): void {
    this.store.dispatch(
      deleteWatercraftExcludedExposure({ payload: watercraftExcludedExposure })
    );
  }

  deleteWatercraftExcludedExposure(
    request: WatercraftExcludedExposureRequest
  ): Observable<unknown> {
    return this.adapter.removeWatercraftExcludedExposure(request);
  }

  getWatercraftExcludedExposuresAndProductInfo(): Observable<
    WatercraftExcludedExposureAndProductInfo[]
  > {
    return this.store.select(getWatercraftExcludedExposuresAndProductInfo);
  }

  getWatercraftExcludedExposureTiles(): Observable<UmbrellaTileModel[]> {
    return this.store.select(getWatercraftExcludedExposureTiles);
  }

  buildWatercraftExposures(): Observable<WatercraftExposureEntity[]> {
    return this.store.select(buildWatercraftExposures);
  }

  watercraftExposuresExist(): Observable<boolean> {
    return this.store.select(watercraftExposuresExist);
  }
}
