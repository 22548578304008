import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../services/app-config.service';
import * as models from '@core/models/api/vehicle-inquiry.model';
import { LogService } from '../services/log.service';
import { catchError, map, tap } from 'rxjs/operators';
import { LogApiName } from '../models/api/log.model';

@Injectable({
  providedIn: 'root',
})
export class VehicleInquiryAdapter {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private log: LogService
  ) {}

  retrieveVehicleModelYears(): Observable<models.RetrieveVehicleModelYearsResponse> {
    return this.get('/vehicleModelYears', 'vehicle-model-years', {});
  }

  retrieveVehicleModelMakes(
    modelYear: string
  ): Observable<models.RetrieveVehicleModelMakesResponse> {
    return this.get('/vehicleModelMakes', 'vehicle-model-makes', { modelYear });
  }

  retrieveVehicleModels(
    modelYear: string,
    make: string
  ): Observable<models.RetrieveVehicleModelsResponse> {
    return this.get('/vehicleModels', 'vehicle-models', { modelYear, make });
  }

  retrieveVehicleModelsWithDescription(
    modelYear: string,
    make: string
  ): Observable<models.RetrieveVehicleModelsWithDescriptionResponse> {
    return this.get(
      '/vehicleModelsWithDescription',
      'vehicle-models-with-description',
      { modelYear, make }
    );
  }

  retrieveVehicleModelSeries(
    modelYear: string,
    make: string,
    model: string
  ): Observable<models.RetrieveVehicleModelSeriesResponse> {
    return this.get('/vehicleModelSeries', 'vehicle-model-series', {
      modelYear,
      make,
      model,
    });
  }

  retrieve10DigitVehicleIdentificationNumber(
    vehicleIdentificationNumberPattern: string
  ): Observable<models.Retrieve10DigitVehicleIdentificationNumberResponse> {
    return this.get(
      '/10DigitVehicleIdentificationNumber',
      '10-digit-vehicle-identification-number',
      {
        vehicleIdentificationNumberPattern,
      }
    );
  }

  retrieve17DigitVehicleIdentificationNumberOdbi(
    vehicleIdentificationNumber: string
  ): Observable<models.Retrieve17DigitVehicleIdentificationNumberOdbiResponse> {
    return this.get(
      '/17DigitVehicleIdentificationNumberOdbi',
      '17-digit-vehicle-identification-number-odbi',
      {
        vehicleIdentificationNumber,
      }
    );
  }

  retrieve17DigitVehicleIdentificationNumberCC(
    vehicleIdentificationNumber: string
  ): Observable<models.Retrieve17DigitVehicleIdentificationNumberCCResponse> {
    return this.get(
      '/17DigitVehicleIdentificationNumberCC',
      '17-digit-vehicle-identification-number-cc',
      {
        vehicleIdentificationNumber,
      }
    );
  }

  retrieveVehicleSeriesRatesOdbi(
    modelYear: string,
    make: string,
    model: string
  ): Observable<models.RetrieveVehicleSeriesRatesOdbiResponse> {
    return this.get('/vehicleSeriesRatesOdbi', 'vehicle-series-rates-odbi', {
      modelYear,
      make,
      model,
    });
  }

  retrieveVehicleSeriesRatesCC(
    modelYear: string,
    make: string,
    model: string
  ): Observable<models.RetrieveVehicleSeriesRatesCCResponse> {
    return this.get('/vehicleSeriesRatesCC', 'vehicle-series-rates-cc', {
      modelYear,
      make,
      model,
    });
  }

  private get(
    endpoint: string,
    serviceName: LogApiName,
    params: { [key: string]: string }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    const url = `${this.appConfigService.config.vehicleInquiryUrl}${endpoint}`;
    const httpParams = new HttpParams({
      fromObject: {
        ...params,
        // Required for all calls:
        client_id: this.appConfigService.config.apiKey,
        echoBack: 'false',
        coveredObject: 'AU',
        // Required for some, safely ignored by others:
        codeType: 'NWC', // or 'ISO'
        company: '01', // Company code of the policy the vehicle is/is to be written on
        state: '13', // Two digit numeric state code of the policy the vehicle is/is to be written
        productCode: '00', // Residual Market for NAPS/PC policies, or IM001/IA001/IP001 for SA policies
        effectiveDate: this.getEffectiveDate(),
        ratingType: 'NB', // "NB"=New Business or "REN"=Renewal/Change
      },
    });
    const correlationId = this.log.logApiRequest('GET', url, serviceName);
    return this.httpClient
      .get(url, {
        params: httpParams,
        observe: 'response',
      })
      .pipe(
        tap((response) =>
          this.log.logApiResponse(correlationId, response.status, response.body)
        ),
        map((response) => response.body),
        catchError((error) => this.log.logApiError(correlationId, error))
      );
  }

  private getEffectiveDate(): string {
    return new Date().toISOString().split('T')[0].replace(/-/g, '');
  }
}
