import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import {
  UnderlyingPoliciesAndProductInfo,
  UnderlyingPolicyEntity,
} from './underlying-policy.entity';
import { ProductsSelectors } from '@core/store/selectors';
import * as fromUnderlyingPolicies from '@core/store/entities/underlying-policy/underlying-policy.reducer';
import { getAllFeatureFlags } from '@entities/feature-flag/feature-flag.selector';
import { ProductType } from '@core/models/api/dsm-types';
import { UnderlyingPolicyRequest } from '../../../models/api/request/underlying-policy-request.model';
import { UmbrellaUtils } from '@shared/utils/umbrella.utils';
import { OptionsModel } from '@entities/metadata/models/property-options.model';
import { Dictionary } from '@ngrx/entity';
import { UmbrellaTileModel } from '@shared/components/umbrella-tile/umbrella-tile.component';
import {
  getProduct,
  getSelectedProducts,
} from '@entities/product/product.selectors';
import { Nullable } from '@shared/utils/type.utils';

export const getUnderlyingPolicyState = createSelector(
  fromCore.getCoreState,
  (state) => state.underlyingPolicies
);

export const {
  selectAll: getAllUnderlyingPolicies,
  selectEntities: getUnderlyingPolicies,
} = fromUnderlyingPolicies.adapter.getSelectors(getUnderlyingPolicyState);

export const buildUnderlyingPolicyRequest = (
  underlyingPolicy: UnderlyingPolicyEntity,
  correlationId?: string
) =>
  createSelector(
    ProductsSelectors.getProduct(underlyingPolicy.productType as ProductType),
    getAllFeatureFlags,
    (product, featureFlags): UnderlyingPolicyRequest => {
      return {
        underlyingPolicyBody: {
          underlyingPolicy,
        },
        quoteId: product?.quoteId,
        productType: product?.type,
        correlationId,
      } as UnderlyingPolicyRequest;
    }
  );

export const getUnderlyingPoliciesNumberAndName = createSelector(
  getAllUnderlyingPolicies,
  (underlyingPolicies): UnderlyingPolicyEntity[] => {
    return underlyingPolicies.map((up) => {
      return {
        ...up,
        currentCarrierLabel: UmbrellaUtils.getCurrentCarrierNameLabel(
          up.currentCarrierName
        ),
      };
    }) as UnderlyingPolicyEntity[];
  }
);

export const getUnderlyingPoliciesAndProductInfo = createSelector(
  getUnderlyingPoliciesNumberAndName,
  ProductsSelectors.getAllProducts,
  (underlyingPolicies, products): UnderlyingPoliciesAndProductInfo[] => {
    return underlyingPolicies.map((up) => {
      switch (up.policyType) {
        case 'PA':
          return {
            underlyingPolicy: up,
            product: products.find(
              (product) => product.type === 'PersonalAuto'
            ),
          };
        case 'PWL':
          return {
            underlyingPolicy: up,
            product: {
              ...products.find((product) => product.type === 'Boat'),
              name: 'Watercraft',
            },
          };
        case 'MV':
          return {
            underlyingPolicy: up,
            product: {
              ...products.find((product) => product.type === 'MSA'),
              name: 'Misc Vehicle',
            },
          };
        default:
          return {
            underlyingPolicy: up,
            product: {
              ...products.find((product) => product.type === 'Homeowner'),
              name: 'Property',
            },
          };
      }
    }) as UnderlyingPoliciesAndProductInfo[];
  }
);

export const getUnderlyingPoliciesLabels = createSelector(
  getUnderlyingPoliciesAndProductInfo,
  (underlyingPolicies): OptionsModel[] => {
    return underlyingPolicies.map((up) => {
      return {
        label: `${UmbrellaUtils.getCurrentCarrierNameLabel(
          up.underlyingPolicy.currentCarrierName
        )} - ${up.underlyingPolicy.underlyingPolicyNumber} (${
          up.product.name
        })`,
        value: up.underlyingPolicy.underlyingPolicyNumber,
      };
    }) as OptionsModel[];
  }
);

export const getUnderlyingPolicyTiles = createSelector(
  getUnderlyingPoliciesAndProductInfo,
  (underlyingPolicies): UmbrellaTileModel[] => {
    return underlyingPolicies.map((policy) => {
      const titleLine1 = `${policy.product?.name} -
      ${policy.underlyingPolicy?.currentCarrierLabel}`;

      const quoteStatusAdjustment =
        policy.product?.quoteStatus === 'NotStarted'
          ? 'Not Started'
          : policy.product?.quoteStatus;
      const subheading = policy.product?.quoteStatus
        ? `${quoteStatusAdjustment?.toUpperCase()} POLICY`
        : 'ISSUED POLICY';

      return {
        data: policy.underlyingPolicy,
        subheading,
        titleLine1,
        subDetails: [
          {
            label: 'Policy number',
            value: policy.underlyingPolicy?.underlyingPolicyNumber,
          },
          {
            label: 'Effective date',
            value: policy.underlyingPolicy?.effectiveDate,
          },
        ],
        icon: policy.product?.imageUrl,
        alt: `${policy.product.name} product icon`,
        type: 'underlying-policy',
      };
    });
  }
);

export const policyTypeExists = (type: string) =>
  createSelector(getAllUnderlyingPolicies, (policies) => {
    if (policies.find((policy) => policy.policyType === type)) {
      return true;
    }
    return false;
  });

export const getAllAccompanyingPolicies = createSelector(
  getAllUnderlyingPolicies,
  getSelectedProducts,
  (policies, products): UmbrellaTileModel[] => {
    return products
      .filter((product) => {
        if (product.type === 'PersonalUmbrella') {
          return false;
        }
        if (policies.length === 0) {
          return true;
        }
        return !policies.some(
          (policy) => policy.underlyingPolicyNumber === product.quoteId
        );
      })
      .map((product) => {
        return {
          subheading: 'NOT ADDED',
          titleLine1: `${product.name}`,
          subDetails: [
            {
              label: 'Policy number',
              value: product.quoteId,
            },
          ],
          icon: product.imageUrl,
          type: product.type,
          alt: `${product.name} product icon`,
          data: product,
        };
      });
  }
);

export const isAccompanyingPolicy = (
  quoteId: string,
  underlyingPolicyId?: string
) =>
  createSelector(getSelectedProducts, (products): any => {
    return {
      isAccompanying: products.some((product) => product.quoteId === quoteId),
      quoteId: quoteId,
      underlyingPolicyId: underlyingPolicyId,
    };
  });

export const getAllDeletedUnderlyingPolicies = createSelector(
  getUnderlyingPolicyState,
  getAllAccompanyingPolicies,
  (underlyingPolicyState, accompanyPolicies): UnderlyingPolicyEntity[] => {
    const deleted =
      underlyingPolicyState.deleted as Dictionary<UnderlyingPolicyEntity>;
    return Object.values(deleted)
      .map((key) => {
        return key as UnderlyingPolicyEntity;
      })
      .filter(
        (policy) =>
          !accompanyPolicies.some(
            (accompanyingPolicy) =>
              accompanyingPolicy.data?.quoteId === policy.underlyingPolicyNumber
          )
      );
  }
);

export const getUnderlyingPolicyByProductType = (productType: ProductType) =>
  createSelector(
    getAllUnderlyingPolicies,
    getProduct(productType),
    (underlyingPolicies, product): Nullable<UnderlyingPolicyEntity> => {
      const policy = underlyingPolicies.find(
        (policy) => policy.underlyingPolicyNumber === product?.quoteId
      );
      if (policy) {
        return {
          ...policy,
          productType: 'PersonalUmbrella',
        };
      }
      return null;
    }
  );
