import { Injectable } from "@angular/core";
import { PrequalificationVerbiageActions, QuoteActions, RetrieveActions } from "@core/store/actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { filter, map, mergeMap, tap } from "rxjs";
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { PreQualification } from "@core/interfaces/interfaces";

@Injectable({
  providedIn: 'root',
})
export class PrequalificationVerbiageEffects {
  constructor(
    private actions$: Actions
  ) {}

  updateVerbiageFromDsmResponses$ = createEffect(() => this.actions$.pipe(
    ofType(
      QuoteActions.updateQuoteSuccess, // action.response.prequalificationAnswers
      QuoteActions.initiateNewBusinessSuccess, // action.payload.prequalificationAnswers
      RetrieveActions.retrieveQuoteSuccess // action.payload.response.prequalificationAnswers
    ),
    map((action: any) => (
      action.response?.prequalificationAnswers ||
      action.payload?.prequalificationAnswers ||
      action.payload?.response?.prequalificationAnswers
    )),
    filterOutNull(),
    mergeMap((pqa) => pqa as PreQualification[]),
    filter((question) => !!question.questionText),
    map((question) => PrequalificationVerbiageActions.upsertPrequalificationVerbiage({
      questionCode: question.questionCode,
      questionText: question.questionText || '',
    }))
  ));
}
