import * as fromCore from '@core/store/reducers';
import { createSelector } from '@ngrx/store';
import * as fromMetadataSelector from '@core/store/entities/metadata/metadata.selector';
import { FeatureFlagId, FeatureFlagsModel } from './feature-flag.model';
import { FeatureFlagModel, FeatureFlagState } from './feature-flag.reducer';
import { getQuoteState } from '../session/session.selector';
import {
  getAllProducts,
  getSelectedProducts,
} from '@core/store/entities/product/product.selectors';
import { DateUtils } from '@shared/utils/date.utils';
import { getUser } from '../user/user.selector';
import { ProductHelper } from '@core/helper/product.helper';
import { getAgency } from '../agency/agency.selector';

export const getFeatureFlagState = createSelector(
  fromCore.getCoreState,
  (state) => state.featureFlags
);

const getFeatureFlags = createSelector(
  getFeatureFlagState,
  (state: FeatureFlagState) => state.entities
);

export const getAllFeatureFlags = createSelector(getFeatureFlags, (flags) => {
  const returnFeatureFlags = (
    Object.keys(flags) as (keyof FeatureFlagsModel)[]
  ).reduce((a, v) => {
    if (flags[v]?.enabled) {
      a[v] = true;
    }
    return a;
  }, {} as FeatureFlagsModel);
  return returnFeatureFlags;
});

export const getFeatureFlag = (key: keyof FeatureFlagsModel) =>
  createSelector(getAllFeatureFlags, (flags) => flags[key] || false);

export const getStateSpecificFlagsAsFeatureFlags = createSelector(
  fromMetadataSelector.getStateSpecificFlagsData,
  getQuoteState,
  getSelectedProducts,
  (stateSpecificFlags, ratingState, selectedProducts): FeatureFlagModel[] => {
    const returnFeatureFlags: FeatureFlagModel[] = [];
    Object.keys(stateSpecificFlags).forEach((flag) => {
      const criteria = stateSpecificFlags[flag as FeatureFlagId];

      let enabled = true;
      let productEntities = [...selectedProducts];

      let featureEffDate = '';
      if (criteria.effectiveDate) {
        if (typeof(criteria.effectiveDate) === 'string') {
          featureEffDate = criteria.effectiveDate;
        } else {
          featureEffDate =
            criteria.effectiveDate[ratingState] ||
            criteria.effectiveDate['ALL'] ||
            '';
        }
      }

      if (criteria.states.length !== 1 || criteria.states[0] !== 'ALL') {
        if (!criteria.states.includes(ratingState)) {
          enabled = false;
        }
      }

      if (
        criteria.offerings?.length &&
        !criteria.offerings?.some(
          (offering) =>
            selectedProducts.map((entity) => entity.type).indexOf(offering) >= 0
        )
      ) {
        enabled = false;
      }

      if (criteria.offerings?.length) {
        productEntities = productEntities.filter((product) =>
          criteria.offerings?.includes(product.type)
        );
      }

      const productEffDate =
        productEntities.reduce(
          (a, v) => ((v.effectiveDate || '') > a ? v.effectiveDate || '' : a),
          ''
        ) || DateUtils.getCurrentDateIso();

      if (featureEffDate && featureEffDate > productEffDate) {
        enabled = false;
      }

      if (enabled) {
        returnFeatureFlags.push({ id: flag as FeatureFlagId, enabled });
      }
    });

    return returnFeatureFlags;
  }
);

export const getAllAvailableProducts = createSelector(
  getAllProducts,
  getAllFeatureFlags,
  getQuoteState,
  getUser,
  getAgency,
  (products, featureFlags, quoteState, user, agency) =>
    products.map((product) =>
      ProductHelper.setProductAvailable(
        product,
        featureFlags,
        quoteState,
        user,
        agency.producerCode
      )
    )
);
