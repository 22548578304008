import { Injectable } from '@angular/core';
import { CoverageGrouping } from '@core/store/entities/coverage/coverage-grouping.model';
import { Store } from '@ngrx/store';
import { FormsState } from '../store/reducers';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { SelectedCardModel } from '@shared/components/card-list/selected-card/selected-card.component';
import { VehicleCoverageFormComponent } from '@app/coverages/forms/vehicle-coverage/vehicle-coverage-form.component';
import { CoverageModel } from '@core/models/views/coverage.model';
import { Nullable } from '@shared/utils/type.utils';
import {
  CoverageEntity,
  CoverageTerm,
  CoverageWarning,
} from '@core/store/entities/coverage/coverage.entity';
import { ProductType } from '@core/models/api/dsm-types';
import {
  getFormCoveragesByProduct,
  getAllCoverages,
} from '../store/entities/coverage/coverage.selector';
import { updateCoverage } from '../store/entities/coverage/coverage.action';
import { CoverageUtils } from '@shared/utils/coverage.utils';
import { DriverCoverageFormComponent } from '@app/coverages/forms/driver-coverage/driver-coverage-form.component';
import { VehicleInquiryService } from '@core/services/vehicle-inquiry.service';
import { FormGroup } from '@angular/forms';
import { updateDwellingReplacementCostForm } from '@entities/confirmations/confirmations.action';
import { getDwellingReplacementCostConfirmation } from '@entities/confirmations/confirmations.selector';
import { InquiryPath } from '@core/adapters/powersports.adapter';
import { CoveredLocationService } from '@core/services/covered-location.service';

@Injectable({
  providedIn: 'root',
})
export class CoverageFormService {
  constructor(
    private store: Store<FormsState>,
    private vehicleInquiryService: VehicleInquiryService,
    private coveredLocationService: CoveredLocationService
  ) {}

  getAllCoverages(): Observable<CoverageModel[]> {
    return this.store.select(getAllCoverages);
  }

  getFormCoveragesByProduct(
    productId: ProductType
  ): Observable<Nullable<CoverageModel>> {
    return this.store.select(getFormCoveragesByProduct(productId));
  }

  updateCoverage(
    productId: ProductType,
    coverage: Partial<CoverageModel>
  ): void {
    this.store.dispatch(
      updateCoverage({ productType: productId, payload: coverage })
    );
  }

  getVehicleSelectedCardModels(
    vehicleCoverageGroupings: Nullable<CoverageGrouping[]>,
    form: FormGroup,
    termMonths: number,
    warningMessages: Nullable<CoverageWarning[]>,
    inquiryPath: InquiryPath,
    productType: ProductType
  ): Observable<SelectedCardModel<VehicleCoverageFormComponent>[]> {
    if (!vehicleCoverageGroupings?.length) {
      return of([]);
    }
    return combineLatest(
      vehicleCoverageGroupings.map((vehicle, index) => {
        return of({
          headline: vehicle.title,
          description: vehicle.subTitle,
          id: vehicle.vehicleId,
          formComponentInputs: {
            coverageGrouping: vehicle,
            vehicleIndex: index,
            productType,
            form,
            termMonths,
            warningMessages,
          },
          syncStatus: undefined,
          unselectOption: false,
        } as SelectedCardModel<VehicleCoverageFormComponent>).pipe(
          switchMap((model) => {
            const matched = model.headline.match(/^(\d{4}) ([A-Z]{4})$/);
            if (matched && matched[1] && matched[2]) {
              return merge(
                of(model),
                this.vehicleInquiryService
                  .getVehicleDisplayName(+matched[1], matched[2], inquiryPath)
                  .pipe(
                    map((name) => ({
                      ...model,
                      headline: name,
                    }))
                  )
              );
            } else {
              return of(model);
            }
          })
        );
      })
    );
  }

  getDriverSelectedCardModels(
    driverCoverageGroupings: Nullable<CoverageGrouping[]>,
    form: FormGroup,
    termMonths: number,
    warningMessages: Nullable<CoverageWarning[]>
  ): Observable<SelectedCardModel<DriverCoverageFormComponent>[]> {
    if (!driverCoverageGroupings?.length) {
      return of([]);
    }

    return of(
      driverCoverageGroupings.map((driverGrouping) => {
        return {
          headline: driverGrouping.title,
          id: driverGrouping.driverId,
          formComponentInputs: {
            coverageGrouping: driverGrouping,
            form,
            termMonths,
            warningMessages,
          },
          unselectOption: false,
        } as SelectedCardModel<DriverCoverageFormComponent>;
      })
    );
  }

  buildFormCoverageModel(coverages: CoverageEntity[]): CoverageModel {
    const model = coverages.reduce(
      (map: { [key: string]: string }, coverage: CoverageEntity) => {
        if (!coverage.mandatory) {
          const selectedValue = coverage.selectedValue
            ? coverage.selectedValue.find((value) => value.code === 'selected')
            : null;
          const val = selectedValue ? selectedValue.value : 'true';
          map[
            CoverageUtils.getFormKey(coverage.coverageId, coverage.coverableId)
          ] = val;
        }

        // Exclude terms after the first, if the coverage is unselected.
        // This is good UX in general, but in particular NC Auto DIOption causes problems if present in the form when declined.
        // Update: We must *not* do this for PIP, as the term that sets it "unselected" is not the first one.
        let terms: CoverageTerm[] = [];
        if (
          coverage.terms?.length &&
          coverage.selectedValue?.find(
            (v) => v.code === 'selected' && v.value === 'false'
          ) &&
          coverage.coverageId !== 'PIP'
        ) {
          terms = [coverage.terms[0]];
        } else if (coverage.terms) {
          terms = coverage.terms;
        }

        terms.forEach((term, index) => {
          const selectedValue = coverage.selectedValue
            ? coverage.selectedValue.find((value) => value.code === term.code)
            : null;
          // Create a value "DECLINE_COVERAGE" for terms that we hijack as a "decline the whole coverage" control.
          let val = '';
          if (selectedValue) {
            val = selectedValue.value;
          } else if (
            !coverage.mandatory &&
            coverage.selectedValue?.find((v) => v.code === 'selected')
              ?.value !== 'true' &&
            coverage.coverageId !== 'PersonalPropOtherRes'
          ) {
            val = 'DECLINE_COVERAGE';
          }

          map[
            CoverageUtils.getFormKey(
              coverage.coverageId,
              coverage.coverableId,
              term.code
            )
          ] = val;
        });

        return map;
      },
      {}
    );
    return model;
  }

  dispatchUpdateDwellingReplacementCostForm(value: boolean): void {
    this.store.dispatch(updateDwellingReplacementCostForm({ payload: value }));
  }

  getDwellingReplacementCostConfirmation(): Observable<boolean> {
    return this.store.select(getDwellingReplacementCostConfirmation);
  }

  getPersonalPropertyExtraQuestionValid(): Observable<boolean> {
    return combineLatest([
      this.getAllCoverages(),
      this.coveredLocationService.getAllCoveredLocations(),
    ]).pipe(
      map(([coverages, coveredLocations]) => {
        const coveredLocation = coveredLocations?.Tenant;
        const tenantCoverages = coverages.find((c) => c.id === 'Tenant');
        if (
          coveredLocation &&
          tenantCoverages &&
          typeof coveredLocation.isPersonalPropertyLimitSameAsPreviousPolicy !==
            'boolean'
        ) {
          const key = Object.keys(tenantCoverages).find((k) =>
            k.match(/^BasicCoverage_\d+_BasicCoverageLimit$/)
          );
          if (key) {
            const value = +(tenantCoverages[key] || 0);
            if (value >= 200000) {
              return false;
            }
          }
        }
        return true;
      })
    );
  }
}
