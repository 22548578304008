import { Injectable } from '@angular/core';
import { TaskService } from '@core/services/task.service';
import {
  PolicyHolderActions,
  MemberActions,
  TaskActions,
  DriverActions,
} from '@core/store/actions';
import { MemberTaskSelectors } from '@core/store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, take, map, concatMap } from 'rxjs/operators';
import { upsertPolicyHolderSuccess } from '../../policyholder/policyholder.actions';

@Injectable({
  providedIn: 'root',
})
export class MemberTaskEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private taskService: TaskService
  ) {}

  updatePNITasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PolicyHolderActions.upsertPolicyHolderSuccess,
        MemberActions.storeMember,
        TaskActions.updateTasks
      ),
      switchMap(() =>
        this.store.select(MemberTaskSelectors.updatePNITasks).pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updateMemberTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MemberActions.storeMember,
        upsertPolicyHolderSuccess,
        DriverActions.upsertDriverSuccess,
        TaskActions.updateTasks
      ),
      concatMap(() =>
        this.store.select(MemberTaskSelectors.updateMemberTasks).pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );
}
