<div
  *ngIf="mode === 'sideWithShadow' || mode === 'bottom'"
  class="outer"
  [ngClass]="{
    'collapsed-mobile-outer':
      (mode === 'bottom' || mode === 'sideWithShadow') && !toggled
  }"
  (click)="mode === 'bottom' ? collapseMobileNav() : null"
></div>
<nav
  id="nav-sidebar"
  #nav
  [ngClass]="{
    expanded: toggled,
    shadow: true
  }"
  tabindex="0"
>
  <div class="chevron-container">
    <bolt-icon
      *ngIf="mode === 'bottom'"
      (click)="collapseMobileNav()"
      name="xmark"
      color="black"
      [attr.id]="'closeDelete'"
      size="sm"
      title="collapse"
    ></bolt-icon>
    <bolt-icon
      *ngIf="mode === 'sideWithShadow' || mode === 'side'"
      (click)="toggleSidebar()"
      name="chevron-right"
      color="black"
      [attr.id]="'chevron'"
      size="sm"
      [ngClass]="{ rotate: toggled }"
      title="toggle"
    ></bolt-icon>
  </div>
  <div class="home-container">
    <button
      #homeContainer
      class="home-container-button"
      (click)="showNewQuoteModal()"
    >
      <img
        class="img-svg"
        src="{{ HomePage.icon }}"
        alt="{{ HomePage.name }}"
      />
      <p
        #sidebarText
        class="home-text link"
        [ngClass]="{ invisible: !toggled }"
      >
        Start a new quote
      </p>
    </button>
  </div>
  <hr />
  <div #sidebarContent class="sidebar-content">
    <a
      *ngFor="let page of pages$ | async; trackBy: trackPage"
      href="javascript:void(0)"
      [ngClass]="{
        step: true,
        shrunk: !toggled,
        active: page.id === currentPage?.id,
        locked:
          !page.isNavigable ||
          pageShouldLockWithoutQuoteId(products$ | async, page)
      }"
      (click)="navigationDesired(page)"
      id="nav-sidebar-step-{{ page.id }}"
    >
      <img
        *ngIf="page.icon"
        class="img-svg"
        src="{{ page.icon }}"
        alt="{{ page.name + ' icon' }}"
      />
      <img
        *ngIf="page.isComplete && page.isNavigable"
        class="faux-feedback-icon complete-icon complete-img"
        name="faux-bolt-success"
        src="/assets/images/nav-bar/completed-checkmark.svg"
        alt="completed image icon"
      />

      <p #sidebarText class="link" [ngClass]="{ invisible: !toggled }">
        {{ page.name }}
      </p>
      <img
        *ngIf="page.id !== 'HUB' && pageErrorMap[page.name] && page.isNavigable"
        class="faux-feedback-icon error-img"
        name="faux-bolt-error"
        src="/assets/images/nav-bar/exclamation-circle-filled.svg"
        alt="error image icon"
      />
      <bolt-icon
        *ngIf="!page.isNavigable"
        class="feedback-icon"
        name="lock-closed"
        color="gray"
        size="sm"
      ></bolt-icon>
      <bolt-icon
        class="feedback-icon"
        *ngIf="
          (page.id === 'HUB' || (hubPageLoaded$ | async)) &&
          !pageErrorMap[page.name] &&
          (page.hasWarning || page.hasReviewWarning) &&
          page.isNavigable
        "
        name="exclamation-triangle-filled"
        color="theme-warning"
        size="sm"
      ></bolt-icon>
    </a>
    <ng-container *ngIf="doAnyProductsHaveBindSuspension(products$ | async)">
      <div [ngClass]="{ hide: toggled }">
        <img
          class="faux-feedback-icon info-img"
          name="faux-bolt-info-icon"
          src="/assets/images/nav-bar/icon-bind-suspension.svg"
          (mouseenter)="onErrorHover()"
          (mouseleave)="onErrorHover()"
          alt="error icon"
        />
      </div>
      <div
        [ngClass]="{
          'nwx-card-dark-sea-blue': true,
          hide: shouldBindSuspensionBeHidden(),
          'hover-position': hovering
        }"
      >
        <div class="bolt-typography nwx-card-header">
          <bolt-icon
            name="notification-error"
            size="sm"
            style="position: relative; top: 3px"
            color="white"
          ></bolt-icon>
          &nbsp;&nbsp;Bind Suspension
        </div>
        <div class="nwx-card-body">
          <ng-container *ngFor="let product of products$ | async">
            <ng-container *ngIf="product.hasBindSuspension">
              <p class="product-heading">
                <img
                  class="product-heading-img-svg"
                  src="{{ getProductIcon(product.type, pages$ | async) }}"
                  alt="{{ product.name + ' icon' }}"
                />&nbsp;{{ product.name }}
              </p>
            </ng-container>
          </ng-container>
          <div class="product-heading-divider"></div>
          <p class="suspension-text">{{ bindSuspensionMessage }}</p>
        </div>
      </div>
    </ng-container>
    <div class="help-container-divider"></div>
    <a
      href="javascript:void(0)"
      [ngClass]="{
        step: true,
        shrunk: !toggled,
        locked: accountInfoLocked
      }"
      (click)="showAccountInfoModal()"
    >
      <img
        class="img-svg"
        [border]="0"
        src="{{ AccountInfoPage.icon }}"
        alt="{{ AccountInfoPage.name + ' icon' }}"
      />
      <p
        #sidebarText
        class="link help-link"
        [ngClass]="{ invisible: !toggled }"
      >
        Account information
      </p>
    </a>
    <a
      href="javascript:void(0)"
      [ngClass]="{
        step: true,
        shrunk: !toggled,
        locked: !HelpPage.isNavigable
      }"
      (click)="showHelpModal()"
    >
      <img
        class="img-svg help-svg"
        [border]="0"
        src="{{ HelpPage.icon }}"
        alt="{{ HelpPage.name + ' icon' }}"
      />
      <p
        #sidebarText
        class="link help-link"
        [ngClass]="{ invisible: !toggled }"
      >
        Help
      </p>
    </a>
  </div>
</nav>
