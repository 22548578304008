import {
  AddressModel,
  AddressModelsEquivalent,
} from '@shared/components/address-input/address-input.model';
import { VehicleLenderModel } from '@shared/components/forms/vehicle-lender-form/vehicle-lender-form.model';
import { Nullable } from '@shared/utils/type.utils';
import { GeneralUtils } from '@shared/utils/general.utils';
import {
  NumberOfCoOwnersType,
  ProductType,
  VehicleType,
} from '../api/dsm-types';
import { VehicleFormOptions } from './vehicle-form-options';
import {
  EligibleDiscountId,
  EligibleDiscountsEntity,
} from '@core/store/entities/eligible-discounts/eligible-discounts.entity';
import { VehicleAdditionalInterestEntity } from '@entities/vehicle/vehicle.entity';
import { ObjectValidator } from '@core/helper/object-validator';

/**
 * Contained in Store, matches our UI.
 * The vehicles a user is aware of are all VehicleModel.
 * Every model has an ID, which could come from PC, prefill, or generated locally.
 * Rule of thumb: Contains only enough data to populate the form.
 */
export interface VehicleModel {
  vehicleId: string;
  options?: VehicleFormOptions;
  entityId?: string;
  prefillId?: string;
  year?: number;
  make?: string;
  model?: string;
  series?: Nullable<string>; // aka modelDescription
  bodyType?: Nullable<string>;
  bodyStyle?: Nullable<string>;
  vehicleSubType?: Nullable<string>;
  carPool?: Nullable<string>;
  vin?: string;
  hin?: string;
  selected?: boolean;
  primaryUse?: PrimaryUseType;
  costNew?: number;
  mandatoryAntiTheftDevices?: AntiTheftDeviceModel[];
  antiTheftDevices?: AntiTheftDeviceModel[];
  vehicleFeatures?: VehicleFeaturesModel[];
  passiveRestraint?: Nullable<PassiveRestraintModel>;
  registeredOwners?: RegisteredOwnerModel[];
  odometerReading?: number;
  annualMiles?: Nullable<number>;
  milesOneWay?: number;
  daysCommutingPerWeek?: number;
  isPurchasedNew?: boolean;
  purchasedNewDate?: Nullable<string>;
  isRegisteredInState?: boolean;
  lender?: VehicleLenderType;
  lenderInfo?: Nullable<VehicleLenderModel>;
  additionalInterests?: Nullable<VehicleAdditionalInterestEntity[]>;
  garageLocation?: AddressModel;
  antiLockBrakes?: string;
  customizationAmount?: string;
  customizationDetails?: string;
  vehicleType?: VehicleType;
  // MSA specific fields
  subTypeClass?: string;
  subType?: string;
  marketValue?: number;
  value?: number;
  additionalInformation?: Nullable<AdditionalInformation>;
  hasPriorDamage?: boolean;
  damageLocation?: string;
  cubicCentimeters?: number;
  productType?: ProductType;
  horsepower?: number;
  auxiliaryLights?: string;
  agreedValue?: number;
  eligibleDiscounts?: EligibleDiscountsEntity[];

  // msa,boat,rv
  weight?: number;
  boatWeight?: number;
  purchasedDate?: string;
  totalHorsepower?: number;
  estimatedMarketValue?: number;
  totalEstimatedMarketValue?: number;
  msrp?: number;
  totalManufacturersSuggestedRetailPrice?: number;

  // Boat specific fields
  hullType?: HullType;
  serialNumber?: string;
  motorSerialNumber?: string;
  motorType?: MotorTypeCode;
  fuelType?: FuelType;
  boatLength?: BoatLength;
  numOfMotors?: number;
  topSpeed?: number;
  hasTrailer?: boolean;
  trailer?: BoatTrailer;
  isVehicleCoOwned?: boolean;
  type?: string; // TODO boat types
  motorDetails?: OutboardMotor[];
  maxNoEngines?: number; // non entity field
  totalMarketValue?: number;
  controlNumber?: string;
  // RV specific fields
  vehicleLength?: number;
  modelNumber?: string;
  safetyDevice?: string;
  hasFiberglassShell?: string;
  hasTirePressureMonitoring?: string;

  // RV Utility Trailer
  enclosedToTransportVehicles?: boolean;

  isMakeNotFound?: boolean;
  isModelNotFound?: boolean;
}

export interface BoatModel {
  year: string;
  make: string;
  model: string;
  averageTrade: number;
  boatType: string;
  controlNumber: string;
  edition: string;
  engineType: string;
  highTrade: number;
  horsePower: string;
  hullMaterialType: HullTypeCode;
  lengthFeet: number;
  lengthInches: number;
  lowTrade: number;
  msrp: number;
  originalBoatType: string;
  rateSymbolBoatType: string;
  rateSymbolEngineType: string;
  ratingDescription: string;
  retailPrice: number;
  weight: string;
  widthFeet: number;
  widthInches: number;
}

export interface RVModel {
  year: string;
  make: string;
  model: string;
  controlNumber: string;
  length: number;
  modelCategory: string;
  modelNumber: string;
  rateSymbol: string;
  type: string;
  valueHigh: number;
  weight: string;
}

export interface BoatTrailerModel {
  year: string;
  make: string;
  model: string;
  averageTrade: number;
  axles: number;
  brakeIndicator: string;
  capacityWeight: number;
  controlNumber: string;
  edition: string;
  highTrade: number;
  lowTrade: number;
  length: string;
  msrp: number;
  retailPrice: number;
  shipWeight: number;
  tiltIndicator: string;
  tireSize: string;
  estimatedMarketValue?: number;
}

export interface BoatTrailer {
  serialNumber?: string;
  year?: number;
  make?: string;
  model?: string;
  estimatedMarketValue?: number;
  msrp?: number;
  controlNumber?: string;
  isMakeNotFound?: boolean;
  isModelNotFound?: boolean;
  weight?: number;
}

export function sanitizeBoatTrailer(input: unknown): BoatTrailer {
  return ObjectValidator.forceSchema<BoatTrailer>(
    input,
    {
      serialNumber: 'string',
      year: 'number',
      make: 'string',
      model: 'string',
      estimatedMarketValue: 'number',
      msrp: 'number',
      controlNumber: 'string',
      isMakeNotFound: 'boolean',
      isModelNotFound: 'boolean',
      weight: 'number',
    },
    [
      'serialNumber',
      'year',
      'make',
      'model',
      'estimatedMarketValue',
      'msrp',
      'controlNumber',
      'isMakeNotFound',
      'isModelNotFound',
      'weight',
    ]
  );
}

export interface OutboardMotor {
  serialNumber?: string;
  year?: number;
  make?: string;
  model?: string;
  horsepower?: string;
  estimatedMarketValue?: number;
  msrp?: number;
  nwmotorNumber?: number;
  isMakeNotFound?: boolean;
  isModelNotFound?: boolean;
}

export function sanitizeOutboardMotor(input: unknown): OutboardMotor {
  return ObjectValidator.forceSchema<OutboardMotor>(
    input,
    {
      serialNumber: 'string',
      year: 'number',
      make: 'string',
      model: 'string',
      horsepower: 'string',
      estimatedMarketValue: 'number',
      msrp: 'number',
      nwmotorNumber: 'number',
      isMakeNotFound: 'boolean',
      isModelNotFound: 'boolean',
    },
    [
      'serialNumber',
      'year',
      'make',
      'model',
      'horsepower',
      'estimatedMarketValue',
      'msrp',
      'nwmotorNumber',
      'isMakeNotFound',
      'isModelNotFound',
    ]
  );
}

export type HullType =
  | 'Aluminum'
  | 'Cement'
  | 'Fabric'
  | 'Fiberglass'
  | 'Kevlar'
  | 'Other'
  | 'Plastic'
  | 'Rubber'
  | 'Steel'
  | 'Wood';

export type HullTypeCode =
  | 'A'
  | 'C'
  | 'Fabr'
  | 'F'
  | 'K'
  | 'O'
  | 'P'
  | 'R'
  | 'S'
  | 'W';

export const HullTypeMap: { [key: string]: HullType } = {
  A: 'Aluminum',
  C: 'Cement',
  Fabr: 'Fabric',
  F: 'Fiberglass',
  K: 'Kevlar',
  O: 'Other',
  P: 'Plastic',
  R: 'Rubber',
  S: 'Steel',
  W: 'Wood',
};

export type MotorType =
  | 'Inboard'
  | 'Inboard/Outboard'
  | 'Jet'
  | 'No Motor'
  | 'Outboard';

export type MotorTypeCode =
  | 'Inboard'
  | 'InboardOrOutboard'
  | 'Jet'
  | 'NoMotor'
  | 'Outboard';

export type MotorInquiryCode = 'IB' | 'IO' | 'Jet' | 'NO' | 'OB' | 'IN';

export const MotorTypeToCode = {
  Inboard: 'Inboard',
  InboardOrOutboard: 'Inboard/Outboard',
  Jet: 'Jet',
  NoMotor: 'No Motor',
  Outboard: 'Outboard',
};

export const MotorTypeMap: { [key: string]: MotorTypeCode } = {
  IB: 'Inboard',
  IN: 'Inboard',
  IO: 'InboardOrOutboard',
  Jet: 'Jet',
  NO: 'NoMotor',
  OB: 'Outboard',
};

export const EngineLimitsPerMotorType: { [key: string]: number } = {
  Inboard: 2,
  InboardOrOutboard: 2,
  Jet: 2,
  Outboard: 3,
};

export type BoatType =
  | 'BassBoat'
  | 'CabinCruiser'
  | 'Canoe'
  | 'Catamaran'
  | 'Fishing'
  | 'Houseboat'
  | 'Kayak'
  | 'Performance'
  | 'PersonalWatercraft'
  | 'Pontoon'
  | 'Runabout'
  | 'Sailboat'
  | 'SkiAndSurf'
  | 'SkiSurf'
  | 'Utility';

export const RatingSymbolBoatTypeMap: { [key: string]: BoatType } = {
  BASS: 'BassBoat',
  CABIN: 'CabinCruiser',
  CANOE: 'Canoe',
  FISH: 'Fishing',
  HOUSE: 'Houseboat',
  KAYAK: 'Kayak',
  PERF: 'Performance',
  PWC: 'PersonalWatercraft',
  PONT: 'Pontoon',
  RUN: 'Runabout',
  SAIL: 'Sailboat',
  SKSRF: 'SkiAndSurf',
  UTIL: 'Utility',
};

export type RVSubtype =
  | 'ChassisMount'
  | 'ClassA'
  | 'ClassB'
  | 'ClassC'
  | 'MediumDutyTow'
  | 'MHToyHauler'
  | 'OffRoadMH'
  | 'ProBus'
  | 'SuperC'
  | 'Toterhome'
  | 'VintageMH'
  | 'DestinationTrailer'
  | 'FifthWheelHorseTrailer'
  | 'FifthWheelToyHauler'
  | 'FifthWheelTrailer'
  | 'HorseTrailer'
  | 'ParkModel'
  | 'PopUpTrailer'
  | 'TravelTrailer'
  | 'TruckCamper'
  | 'TTToyHauler'
  | 'VintageTT'
  | 'CarHauler'
  | 'UtilityTrailer';

export const RVSubtypeDisplayNames: Array<{
  vehicleType: string;
  subType: string;
  displayName: string;
}> = [
  {
    vehicleType: 'Motorhome',
    subType: 'ChassisMount',
    displayName: 'Chassis Mount Motorhome',
  },

  {
    vehicleType: 'Motorhome',
    subType: 'ClassA',
    displayName: 'Class A Motorhome',
  },

  {
    vehicleType: 'Motorhome',
    subType: 'ClassB',
    displayName: ' Class B Motorhome',
  },

  {
    vehicleType: 'Motorhome',
    subType: 'ClassC',
    displayName: ' Class C Motorhome',
  },

  {
    vehicleType: 'Motorhome',
    subType: 'MHToyHauler',
    displayName: ' Motorhome Toy Hauler',
  },

  {
    vehicleType: 'Motorhome',
    subType: 'MediumDutyTow',
    displayName: ' Medium Duty Tow',
  },

  {
    vehicleType: 'Motorhome',
    subType: 'OffRoadMH',
    displayName: ' Off-Road Motorhome',
  },

  {
    vehicleType: 'Motorhome',
    subType: 'ProBus',
    displayName: 'Professional Bus Conversion',
  },

  {
    vehicleType: 'Motorhome',
    subType: 'SuperC',
    displayName: ' Class C Motorhome on a Semi Chassis',
  },

  {
    vehicleType: 'Motorhome',
    subType: 'Toterhome',
    displayName: ' Medium Duty w/ Additional Living Quarters',
  },

  {
    vehicleType: 'Motorhome',
    subType: 'VintageMH',
    displayName: ' Vintage Motorhome',
  },

  {
    vehicleType: 'TravelTrailer',
    subType: 'DestinationTrailer',
    displayName: 'Destination Travel Trailer',
  },

  {
    vehicleType: 'TravelTrailer',
    subType: 'FifthWheelHorseTrailer',
    displayName: ' Horse Trailer (Fifth Wheel)',
  },

  {
    vehicleType: 'TravelTrailer',
    subType: 'FifthWheelToyHauler',
    displayName: ' Fifth Wheel Toy Hauler',
  },

  {
    vehicleType: 'TravelTrailer',
    subType: 'FifthWheelTrailer',
    displayName: ' 5th Wheel Trailer',
  },

  {
    vehicleType: 'TravelTrailer',
    subType: 'HorseTrailer',
    displayName: ' Horse Trailer',
  },

  {
    vehicleType: 'TravelTrailer',
    subType: 'ParkModel',
    displayName: ' Park Model Trailer',
  },

  {
    vehicleType: 'TravelTrailer',
    subType: 'PopUpTrailer',
    displayName: ' Pop-up Tent Trailer',
  },

  {
    vehicleType: 'TravelTrailer',
    subType: 'TTToyHauler',
    displayName: ' Travel Trailer Toy Hauler',
  },

  {
    vehicleType: 'TravelTrailer',
    subType: 'TravelTrailer',
    displayName: ' Travel Trailer',
  },

  {
    vehicleType: 'TravelTrailer',
    subType: 'TruckCamper',
    displayName: ' Truck Camper',
  },

  {
    vehicleType: 'TravelTrailer',
    subType: 'VintageTT',
    displayName: ' Vintage Trailer (includes vintage truck camper)',
  },
];

export const RateSymbolToRVSubtype: { [key: string]: RVSubtype } = {
  '232': 'ChassisMount',
  '230': 'ClassA',
  '235': 'ClassB',
  '240': 'ClassC',
  '280': 'MediumDutyTow',
  '275': 'MHToyHauler',
  '231': 'OffRoadMH',
  '300': 'ProBus',
  '241': 'SuperC',
  '281': 'Toterhome',
  '290': 'VintageMH',
  '407': 'DestinationTrailer',
  '225': 'FifthWheelHorseTrailer',
  '270': 'FifthWheelToyHauler',
  '245': 'FifthWheelTrailer',
  '305': 'HorseTrailer',
  '406': 'ParkModel',
  '250': 'PopUpTrailer',
  '260': 'TravelTrailer',
  '255': 'TruckCamper',
  '265': 'TTToyHauler',
  '285': 'VintageTT',
  '998': 'CarHauler',
  '999': 'UtilityTrailer',
};

export const RateSymbolRVSubtypeMap: { [key: string]: RVSubtype } = {};

export type FuelType = 'Diesel' | 'Electric' | 'Gasoline';
export interface BoatLength {
  boatLengthFeet: number;
  boatLengthInches: number;
}
export interface AdditionalInformation {
  isStoredInLockedGarage?: string;
  isCoOwned?: boolean;
  numberOfCoOwners?: NumberOfCoOwnersType;
  isRebuiltSalvagedFrameReplaced?: string;
  specialHazard?: string;
  hasVehicleBeenConvertedToTrike?: string;
  isVehicleBuiltByFirm?: string;
  isVehicleRegisteredAsHistorical?: string;
  registrationState?: string;
}

export const TypeCodeToRVVehicleType: { [key: string]: VehicleType } = {
  '050': 'Motorhome',
  '017': 'TravelTrailer',
  '007': 'UtilityTrailer',
};

export const AutoPrimaryUseDisplayTypes: PrimaryUseDisplayType[] = [
  'Work/School',
  'Recreational',
  'Farm',
  'Business',
];
export const RVPrimaryUseDisplayTypes: PrimaryUseDisplayType[] = [
  'Full timer - seasonal',
  'Full timer - travel',
  'Full timer - non travel',
  'Pleasure (<30 days)',
  'Pleasure (30 - 150 days)',
];
// API values
export type PrimaryUseType =
  | 'Work'
  | 'Pleasure'
  | 'Farm'
  | 'IndividualBusiness'
  | 'FullTimerSeasonal'
  | 'FullTimerNonTravel'
  | 'FullTimerTravel'
  | 'PleasureLT30Days'
  | 'PleasureIn30To150Days';

export const FullTimePrimaryUses: PrimaryUseType[] = [
  'FullTimerNonTravel',
  'FullTimerSeasonal',
  'FullTimerTravel',
];

// All display values:
export type PrimaryUseDisplayType =
  | 'Work/School'
  | 'Recreational'
  | 'Farm'
  | 'Business'
  | 'Full timer - seasonal'
  | 'Full timer - travel'
  | 'Full timer - non travel'
  | 'Pleasure (<30 days)'
  | 'Pleasure (30 - 150 days)';

// display to api value
export const PrimaryUseMap = {
  'Work/School': 'Work',
  Recreational: 'Pleasure',
  Farm: 'Farm',
  Business: 'IndividualBusiness',
  'Full timer - seasonal': 'FullTimerSeasonal',
  'Full timer - non travel': 'FullTimerNonTravel',
  'Full timer - travel': 'FullTimerTravel',
  'Pleasure (<30 days)': 'PleasureLT30Days',
  'Pleasure (30 - 150 days)': 'PleasureIn30To150Days',
};

export enum VehicleLenderType {
  None = 'None',
  Loan = 'Loan',
  Lease = 'Lease',
}

export interface RegisteredOwnerModel {
  driverId: number;
}

export type VehicleFeaturesModel =
  | 'AntiLockBrakes'
  | 'DaytimeRunningLamps'
  | 'AntiTheft';
export type PassiveRestraintModel =
  | 'None'
  | 'AirBag_Ext'
  | 'DriverSideAirBags_Ext'
  | 'PassiveSeatBelt_Ext';
export type RVSafetyDeviceModel =
  | 'None'
  | 'LPGasDetection'
  | 'SpartanChassisFireSuppression'
  | 'FireSuppressionSystem';

export type AntiTheftDeviceModel = 'V' | 'K' | 'P' | 'D' | 'A';

export const ALL_VEHICLE_FEATURES: EligibleDiscountId[] = [
  'AntiLockBrakes',
  'DaytimeRunningLamps',
  'AntiTheft',
];

export function vehicleModelIsEquivalent(
  a: VehicleModel,
  b: VehicleModel
): boolean {
  if (a === b) {
    return true;
  }
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return (
    a.primaryUse === b.primaryUse &&
    a.costNew === b.costNew &&
    // a.mandatoryAntiTheftDevices === b.mandatoryAntiTheftDevices &&
    // a.antiTheftDevices === b.antiTheftDevices &&
    vehicleFeaturesModelsEquivalent(a.vehicleFeatures, b.vehicleFeatures) &&
    // a.passiveRestraint === b.passiveRestraint &&
    registeredOwnersEquivalent(a.registeredOwners, b.registeredOwners) &&
    a.odometerReading === b.odometerReading &&
    a.annualMiles === b.annualMiles &&
    a.milesOneWay === b.milesOneWay &&
    a.daysCommutingPerWeek === b.daysCommutingPerWeek &&
    a.isPurchasedNew === b.isPurchasedNew &&
    a.purchasedNewDate === b.purchasedNewDate &&
    a.isRegisteredInState === b.isRegisteredInState &&
    a.lender === b.lender &&
    vehicleLenderModelEquivalent(a.lenderInfo, b.lenderInfo) &&
    a.vin === b.vin &&
    yearMakeModelIsEquivalent(a, b)
  );
}

export function vehicleFeaturesModelsEquivalent(
  a: VehicleFeaturesModel[] | null | undefined,
  b: VehicleFeaturesModel[] | null | undefined
): boolean {
  return GeneralUtils.arraysEquivalent(a, b, (aa, bb) => aa === bb);
}

export function registeredOwnersEquivalent(
  a: RegisteredOwnerModel[] | null | undefined,
  b: RegisteredOwnerModel[] | null | undefined
): boolean {
  return GeneralUtils.arraysEquivalent(a, b, registeredOwnerModelsEquivalent);
}

export function registeredOwnerModelsEquivalent(
  a: RegisteredOwnerModel | null | undefined,
  b: RegisteredOwnerModel | null | undefined
): boolean {
  if (a === b) {
    return true;
  }
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return a.driverId === b.driverId;
}

export function vehicleLenderModelEquivalent(
  a: VehicleLenderModel | null | undefined,
  b: VehicleLenderModel | null | undefined
): boolean {
  if (a === b) {
    return true;
  }
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return (
    a.additionalInterestId === b.additionalInterestId &&
    a.companyName === b.companyName &&
    a.loanExpiration === b.loanExpiration &&
    AddressModelsEquivalent(a.address, b.address)
  );
}

export function vehicleLenderTypeEquivalent(
  a: VehicleLenderType | null | undefined,
  b: VehicleLenderType | null | undefined
): boolean {
  if (a === b) {
    return true;
  }
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return a === b;
}

export function yearMakeModelIsEquivalent(
  a: VehicleModel,
  b: VehicleModel
): boolean {
  if (a === b) {
    return true;
  }
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return (
    a.year === b.year &&
    a.make === b.make &&
    a.model === b.model &&
    a.series === b.series
  );
}
