import {
  AfterViewInit,
  Directive,
  ElementRef,
  forwardRef,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

interface ShadyElement extends HTMLInputElement {
  renderRoot: HTMLElement;
}

type RadioValue = undefined | null | string | boolean | number;

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BoltRadioGroupValueDirective),
  multi: true,
};

@Directive({
  selector: '[nwxBoltRadioGroupValue]',
  providers: [VALUE_ACCESSOR],
})
export class BoltRadioGroupValueDirective
  implements ControlValueAccessor, AfterViewInit, OnDestroy {
  private unsubscribe = new Subject<void>();
  private eventListener: ((event: Event) => void) | null = null;
  private value: RadioValue = undefined;
  private onChange = (value: RadioValue) => {};
  private onTouched = () => {};

  constructor(private element: ElementRef) {}

  ngAfterViewInit(): void {
    this.eventListener = (event: Event) => this.onDomEvent(event);
    this.element.nativeElement.addEventListener('input', this.eventListener);
    // We could have gotten a value already via writeValue(), but the inner components weren't ready for it...
    if (this.value !== undefined) {
      this.writeValue(this.value);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    if (this.eventListener) {
      this.element.nativeElement.removeEventListener(
        'input',
        this.eventListener
      );
      this.eventListener = null;
    }
  }

  writeValue(value: RadioValue): void {
    this.value = value;
    for (const input of this.iterateInputs()) {
      const inner = input.renderRoot?.querySelector('input');
      if (input.value === value) {
        input.checked = true;
        input.setAttribute('checked', 'checked');
        if (inner) {
          inner.checked = true;
          inner.setAttribute('checked', 'checked');
        }
      } else {
        input.checked = false;
        input.removeAttribute('checked');
        if (inner) {
          inner.checked = false;
          inner.removeAttribute('checked');
        }
      }
    }
  }

  registerOnChange(fn: (value: RadioValue) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.element.nativeElement.setAttribute('disabled', 'disabled');
    } else {
      this.element.nativeElement.removeAttribute('disabled');
    }
  }

  private iterateInputs(): ShadyElement[] {
    return Array.from(
      this.element.nativeElement.querySelectorAll('bolt-radio')
    );
  }

  private onDomEvent(event: Event): void {
    if (!event.target) {
      return;
    }
    const target = event.target as HTMLInputElement;
    this.onChange(target.value);
  }
}
