import { Directive, ElementRef } from '@angular/core';

/**
 * Workaround for a Bolt problem, that pressing Enter with a bolt-textfield focussed
 * does not submit its form as expected.
 * This happens because the textfield and the submit button
 * are each in their own Shadow DOM, and can't see each other.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'bolt-textfield',
})
export class SubmitOnEnterDirective {
  constructor(element: ElementRef) {
    if (element?.nativeElement) {
      this.attach(element.nativeElement);
    }
  }

  private attach(element: Element): void {
    element.addEventListener('keypress', (event: Event) => {
      if ((event as KeyboardEvent).key === 'Enter') {
        const form = this.findForm(element);
        if (form) {
          event.preventDefault();
          event.stopPropagation();
          form.dispatchEvent(new Event('submit', { cancelable: true }));
        }
      }
    });
  }

  private findForm(child: Element): Element | null {
    for (; child; child = child.parentNode as Element) {
      if (child.tagName === 'FORM') {
        return child;
      }
    }
    return null;
  }
}
