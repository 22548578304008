import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';
import {
  ExtendedTelematicsVehicle,
  MobileEnrollment,
  VehicleEnrollment,
} from '@core/store/entities/telematics/telematics.model';
import { Nullable } from '@shared/utils/type.utils';
import { TelematicsUtils } from '@shared/utils/telematics.utils';

@Component({
  selector: 'nwx-smartride-connected-car-enrollment',
  templateUrl: './smartride-connected-car-enrollment.component.html',
  styleUrls: [
    './smartride-connected-car-enrollment.component.scss',
    './../telematics/telematics.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartrideConnectedCarEnrollmentComponent implements OnInit {
  @Input() vehicles: ExtendedTelematicsVehicle[] = [];
  @Input() quoteState!: string;
  @Input() enrollment: Nullable<VehicleEnrollment | MobileEnrollment>;

  initialDiscount = '10%';
  maximumDiscount = '40%';

  constructor() {}

  ngOnInit(): void {
    this.initialDiscount = TelematicsUtils.getDiscountMetadata(
      'initialDiscount',
      this.quoteState
    );
    this.maximumDiscount = TelematicsUtils.getDiscountMetadata(
      'maximumDiscount',
      this.quoteState
    );
  }

  get isCA(): boolean {
    return this.quoteState === 'CA';
  }
}
