import { CompraterRoutingResponse } from "@core/adapters/comprater-routing.adapter";
import { SessionActions, CompraterRoutingActions } from "@core/store/actions";
import { createReducer, on } from "@ngrx/store";

export interface CompraterRoutingState {
  response: CompraterRoutingResponse;
  callCount: number;
  failedQuoteIds: string[];
}

const initialState: CompraterRoutingState = {
  response: [],
  callCount: 0,
  failedQuoteIds: [],
};

export const reducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(CompraterRoutingActions.fetchCompraterRouting, (state) => ({
    ...state,
    callCount: state.callCount + 1,
  })),
  on(CompraterRoutingActions.fetchCompraterRoutingSuccess, (state, { response }) => ({
    ...state,
    callCount: state.callCount - 1,
    response: response,
  })),
  on(CompraterRoutingActions.fetchCompraterRoutingError, (state, { quoteId }) => ({
    ...state,
    callCount: state.callCount - 1,
    failedQuoteIds: addToArrayIfMissing(state.failedQuoteIds, quoteId),
  }))
);

function addToArrayIfMissing(array: string[], input: string): string[] {
  if (array.indexOf(input) >= 0) {
    return array;
  }
  return [...array, input];
}
