import { Action, createReducer, on } from '@ngrx/store';
import { setHasMortgage } from './has-mortgage.action';

export type HasMortgageState = boolean | undefined;

const mortgageReducer = createReducer<boolean>(
  false,
  on(setHasMortgage, (_, { payload }) => payload)
);

export function reducer(state: boolean | undefined, action: Action): boolean {
  return mortgageReducer(state, action);
}
