import { umbrellaOptions } from '@assets/metadata/umbrella/umbrella-options';
import { LocationExposureEntity } from '@entities/exposures/location-exposures/location-exposures.entity';
import { Dictionary } from '@ngrx/entity';
import { VehicleExposureEntity } from '../../core/store/entities/exposures/vehicle-exposures/vehicle-exposures.entity';
import { WatercraftExposureEntity } from '../../core/store/entities/exposures/watercraft-exposures/watercraft-exposures.entity';

export class UmbrellaUtils {
  static getCurrentCarrierNameLabel(
    currentCarrierName: string | undefined
  ): string | undefined {
    const currentCarrierOptions =
      umbrellaOptions?.underlyingPolicy?.currentCarrierName.concat(
        umbrellaOptions?.underlyingPolicy?.nwCurrentCarrierName
      );
    const currentCarrierNameLabel = currentCarrierOptions.find(
      (carrier) => carrier.value === currentCarrierName
    )?.displayName;
    return currentCarrierNameLabel;
  }

  // Vehicle Exposure properties
  static getVehicleLabel(vehicleType: string): string | undefined {
    const vehicleTypeOptions = umbrellaOptions?.vehicleExposures?.vehicleType;
    const vehicleTypeLabel = vehicleTypeOptions.find(
      (vehicle) => vehicle.value === vehicleType
    )?.displayName;
    return vehicleTypeLabel;
  }

  // Location Exposure properties
  static getLocationUsageLabel(locationUsage: string): string | undefined {
    const locationUsageOptions =
      umbrellaOptions?.locationExposures?.locationUsage;
    const locationUsageLabel = locationUsageOptions.find(
      (location) => location.value === locationUsage
    )?.displayName;
    return locationUsageLabel;
  }

  static getPolicyTypeLabel(policyType: string): string | undefined {
    const policyTypeOptions = umbrellaOptions?.locationExposures?.policyType;
    const policyTypeLabel = policyTypeOptions.find(
      (location) => location.value === policyType
    )?.displayName;
    return policyTypeLabel;
  }

  // Watercraft Exposure properties
  static getWatercraftTypeLabel(watercraftType: string): string | undefined {
    const watercraftTypeOptions =
      umbrellaOptions?.watercraftExposures?.watercraftType;
    const watercraftTypeLabel = watercraftTypeOptions.find(
      (watercraft) => watercraft.value === watercraftType
    )?.displayName;
    return watercraftTypeLabel;
  }

  static getBoatTypeLabel(boatType: string | undefined): string | undefined {
    const boatTypeOptions = umbrellaOptions?.watercraftExposures?.boatType;
    const boatTypeLabel = boatTypeOptions.find(
      (boat) => boat.value === boatType
    )?.displayName;
    return boatTypeLabel;
  }

  private static getExposureId(
    exposure:
      | LocationExposureEntity
      | VehicleExposureEntity
      | WatercraftExposureEntity
      | undefined
  ): any {
    if ((exposure as LocationExposureEntity).locationExposureId !== undefined) {
      return (exposure as LocationExposureEntity).locationExposureId;
    }
    if ((exposure as VehicleExposureEntity).vehicleExposureId !== undefined) {
      return (exposure as VehicleExposureEntity).vehicleExposureId;
    }
    if (
      (exposure as WatercraftExposureEntity).watercraftExposureId !== undefined
    ) {
      return (exposure as WatercraftExposureEntity).watercraftExposureId;
    }
    return undefined;
  }

  static findAccompanyingExposureToRemove<
    T extends
      | LocationExposureEntity
      | VehicleExposureEntity
      | WatercraftExposureEntity
  >(exposureDeleted: Dictionary<T>, payload: T) {
    const accompanyingExposureToRemove: T | undefined = Object.values(
      exposureDeleted
    ).find((exposure) => {
      if (exposure) {
        return (
          exposure?.underlyingPolicyNumber === payload?.underlyingPolicyNumber
        );
      }
      return undefined;
    });

    if (accompanyingExposureToRemove) {
      let id = this.getExposureId(accompanyingExposureToRemove);
      if (id) delete exposureDeleted?.[id];
    }
  }
}
