import { Router } from '@angular/router';
import { ProductsService } from '@core/services/products.service';
import { PageIdentifier, getPageByUrl } from '@core/constants/pages';
import {
  BillingActions,
  CompositeActions,
  CoveredLocationActions,
  QuoteActions,
  RouterActions,
  TaskActions,
} from '@core/store/actions';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
  concatMap,
  filter,
  map,
  mergeMap,
  pairwise,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { NavigationActions } from '../../actions';
import { Action, Store } from '@ngrx/store';
import { RouterNavigatedAction, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { combineLatest, of } from 'rxjs';
import {
  navigatePostRetrieveComplete,
  Page,
  PageSubCategory,
} from './navigation.action';
import { PcUrlService } from '@shared/services/pc-url.service';
import { NavigationService } from '@core/services/navigation.service';
import {
  NavigationSelectors,
  ProductsSelectors,
  TaskSelectors,
} from '@core/store/selectors';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import {
  selectCurrentPageId,
  selectEnabledPages,
  selectPageById,
} from './navigation.selector';
import { LogService } from '@core/services/log.service';
import { UWReportsAdapter } from '@core/adapters/uw-reports.adapter';
import { ProductType } from '@core/models/api/dsm-types';
import { BillingService } from '@core/services/billing.service';
import { TaskService } from '@core/services/task.service';

@Injectable()
export class NavigationEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private productsService: ProductsService,
    private pcUrlService: PcUrlService,
    private navigationService: NavigationService,
    private logService: LogService,
    private uwReportsAdapter: UWReportsAdapter,
    private router: Router,
    private billingService: BillingService,
    private window: Window,
    private taskService: TaskService
  ) {}

  navigatePostRetrieveEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.navigatePostRetrieve),
        tap(() =>
          this.router
            .navigate(['/hub'])
            .catch((e) => e)
            .then(() => {
              this.store.dispatch(navigatePostRetrieveComplete());
            })
        )
      ),
    { dispatch: false }
  );

  enableBuildQuotePages$ = createEffect(() =>
    this.productsService.getSelectedProductsIncludingNWXInactive().pipe(
      map((selectedProducts) =>
        NavigationActions.enableBuildQuotePages({
          payload: selectedProducts,
        })
      )
    )
  );

  setCurrentPageOnNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map((event: RouterNavigatedAction) => {
        let urlPath = event.payload.routerState.url.split('?')[0];
        urlPath = urlPath.split('#')[0];
        const page = getPageByUrl(urlPath);
        return NavigationActions.setCurrentPage({ payload: page });
      })
    )
  );

  setCoveragesNavigable$ = createEffect(() =>
    this.store.select(selectEnabledPages).pipe(
      filter(
        (pages) => !pages.find((page) => page.id === 'Coverages')?.isNavigable
      ),
      withLatestFrom(
        this.store.select(ProductsSelectors.getStatusOfAllProducts)
      ),
      filter(([pages, productStatuses]) => {
        if (productStatuses.every((s) => s === 'Quoted' || s === 'Binding')) {
          return true;
        }
        const buildQuotePages = pages.filter(
          (p) => p.subcategory === PageSubCategory.BUILD_QUOTE
        );
        if (!buildQuotePages.length) {
          return false;
        }
        const lonelyPage = buildQuotePages.find((p) => !p.visited);
        return !lonelyPage;
      }),
      map(() =>
        NavigationActions.setPageNavigable({
          payload: 'Coverages',
          isNavigable: true,
        })
      )
    )
  );

  setHubNavigable$ = createEffect(() =>
    this.productsService.getSelectedQuoteProductsWithoutErrors().pipe(
      filter((products) => !!products.length),
      filter((products) =>
        products.every((product) => product.quoteCoverageLoaded)
      ),
      map((_) =>
        NavigationActions.setPageNavigable({
          payload: 'HUB',
          isNavigable: true,
        })
      )
    )
  );

  setBillingPaymentNavigable$ = createEffect(() =>
    combineLatest([
      this.productsService.getSelectedProductEntitiesWithoutErrors(),
      this.store.select(selectCurrentPageId),
      this.store.select(selectPageById('Billing')),
    ]).pipe(
      map(([products, currentPage, billingPage]) => {
        const isNavigable =
          (currentPage === 'HUB' || currentPage === 'Billing') &&
          !!(
            products.length &&
            products.every(
              (q) => q.quoteStatus === 'Binding' || q.quoteStatus === 'Issued'
            )
          );
        if (isNavigable !== billingPage?.isNavigable) {
          return NavigationActions.setPageNavigable({
            payload: 'Billing',
            isNavigable,
          });
        } else {
          return null;
        }
      }),
      filterOutNull()
    )
  );

  setQuotingPagesUnnavigableOnIssue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.billingComplete),
      map((_) => NavigationActions.disableQuotePagesOnIssue())
    )
  );

  navigateToPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.navigateToPage),
      map((action) =>
        RouterActions.go({
          payload: {
            path: [action.pageUrl],
          },
          showSpinner: action.showSpinner,
        })
      )
    )
  );

  navigateToPageField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.navigateToPageField),
      map((action) =>
        RouterActions.go({
          payload: {
            path: [action.pageUrl],
            extras: { fragment: action.field },
          },
        })
      )
    )
  );

  pivotToPolicyCenter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.linkToPolicyCenter),
        map((action) => action.payload),
        switchMap((action) =>
          this.pcUrlService
            .getPolicyCenterUrl(action.id || '', action.urlType)
            .pipe(
              take(1),
              map((pivot) => ({ url: pivot, reason: action.reason }))
            )
        ),
        tap((pivot) => {
          this.logService.logBusinessEvent('pivot-to-pc', {
            reason: pivot.reason,
          });
          this.navigationService.openExternalPage(pivot.url);
        })
      ),
    { dispatch: false }
  );

  logPivotToPolicyCenter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.logLinkToPolicyCenter),
        map((action) => action.payload),
        tap((action) => {
          this.logService.logBusinessEvent('pivot-to-pc', {
            reason: action.reason,
          });
        })
      ),
    { dispatch: false }
  );

  launchMSBSite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.launchMSBSite),
        map((action) => action.payload),
        switchMap((productType) =>
          this.productsService.getProduct(productType).pipe(take(1))
        ),
        switchMap((product) => {
          return this.uwReportsAdapter
            .getMSBLaunchUrl(
              product?.quoteId as string,
              product?.type as ProductType
            )
            .pipe(
              take(1),
              tap((response) => {
                this.logService.logBusinessEvent('launch-msb-page');
                this.navigationService.openExternalPage(
                  response.referenceReportHref
                );
              })
            );
        })
      ),
    { dispatch: false }
  );

  // if attempting navigation and the mobile nav is still open, collapse it
  setTargetPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.setTargetPage),
      withLatestFrom(this.navigationService.getMobileToggled()),
      filter(([action, toggled]) => toggled),
      map(([action, toggled]) => NavigationActions.collapseMobileNav())
    )
  );

  markPagesCompleteAfterSubmitSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CompositeActions.submitMsaPageSuccess,
        CompositeActions.submitPeoplePageSuccess,
        CompositeActions.submitPniPageSuccess,
        CompositeActions.submitVehiclePageSuccess,
        CompositeActions.submitBoatPageSuccess,
        CompositeActions.submitRVPageSuccess
      ),
      withLatestFrom(this.store.select(NavigationSelectors.selectCurrentPage)),
      map(([_a, p]) => p),
      filterOutNull(),
      map((page) => NavigationActions.markComplete({ payload: page.id }))
    )
  );

  markPropertyPageCompleteAfterUpdateCoveredLocationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoveredLocationActions.updateCoveredLocationSuccess),
        map(() => NavigationActions.markComplete({ payload: 'Homeowner' }))
      )
  );

  /* markUmbrellaPageCompleteAfterUpdateQuoteWithPrequalAnswered$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuoteActions.updateQuoteSuccess),
        filter((action) => action.payload === 'PersonalUmbrella'),
        withLatestFrom(
          this.store.select(UmbrellaDetailsSelector.getUmbrellaEligibility)
        ),
        map(([action, eligible]) => {
          if (eligible) {
            return NavigationActions.markComplete({ payload: 'Umbrella' });
          }
          return null;
        }),
        filterOutNull()
      ) as any
  ); */

  setPageVisited$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.setCurrentPage),
      filter((action) => !action.payload.visited),
      map((action) =>
        NavigationActions.setPageVisited({
          page: action.payload.id,
          visited: true,
        })
      )
    )
  );

  navigateToBillingPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.navigateToBillingPage),
      map(() => {
        this.billingService.unsetDownPaymentFailed();
        return NavigationActions.navigateToPage({
          pageUrl: '/billing',
          showSpinner: true,
        });
      })
    )
  );

  dropNavigationWarningsOnTaskClear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.clear),
      withLatestFrom(this.store.select(NavigationSelectors.getAllPages)),
      switchMap(([action, pages]) => pages),
      filter((page) => page.hasWarning),
      map((page) => NavigationActions.clearWarning({ payload: page.id }))
    )
  );

  reloadApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.reloadApp),
        tap(() => {
          this.window.location = this.window.location.origin;
        })
      ),
    { dispatch: false }
  );

  updateNavigationBarOnQuoteStatusChange$ = createEffect(() =>
    this.store.select(ProductsSelectors.getOverallFurthestQuoteStatus).pipe(
      pairwise(),
      filter(([prev, next]) => prev === 'Draft' && next === 'Quoted'),
      concatMap(() =>
        this.store.select(TaskSelectors.getIncompleteTasks).pipe(
          take(1),
          concatMap((tasks) => tasks)
        )
      ),
      concatMap((task) =>
        this.store
          .select(
            NavigationSelectors.selectPageById(task.page?.id as PageIdentifier)
          )
          .pipe(
            take(1),
            filterOutNull(),
            map((page) => ({ task, page }))
          )
      ),
      mergeMap(({ task, page }) => {
        const returnActions: Action[] = [];
        this.taskService.setPageWarnings(returnActions, [task], page);
        return returnActions;
      })
    )
  );

  updateNavigationBarAddTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.addTask),
      mergeMap((action) => {
        const returnActions: Action[] = [];
        const pageId = action.payload.page?.id as PageIdentifier;

        if (action.payload.entityType === 'review') {
          if (!action.payload.page?.hasReviewWarning) {
            returnActions.push(
              NavigationActions.setReviewWarning({
                payload: pageId,
              })
            );
          }
        } else {
          if (!action.payload.page?.hasWarning) {
            returnActions.push(
              NavigationActions.setWarning({
                payload: pageId,
              })
            );
          }
        }

        if (action.payload.page?.isComplete) {
          returnActions.push(
            NavigationActions.markIncomplete({
              payload: pageId,
            })
          );
        }

        return returnActions;
      })
    )
  );

  updateNavigationBarCompleteTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.completeTask),
      filter((action) => !!action.payload.page),
      mergeMap((action) =>
        combineLatest([
          this.store.select(
            TaskSelectors.getIncompleteTasksForPage(
              action.payload.page?.id as string
            )
          ),
          this.store.select(ProductsSelectors.getOverallFurthestQuoteStatus),
          this.store.select(
            NavigationSelectors.selectPageById(
              action.payload.page?.id as PageIdentifier
            )
          ),
          of(action),
        ]).pipe(take(1))
      ),
      mergeMap(([incompleteTasks, overallQuoteStatus, page, action]) => {
        const returnActions: Action[] = [];

        this.taskService.setPageWarnings(returnActions, incompleteTasks, page);
        this.taskService.setPageComplete(
          returnActions,
          incompleteTasks,
          overallQuoteStatus,
          page
        );

        return returnActions;
      })
    )
  );

  /* The proper logic for tasks being dismissed is just above us, updateNavigationBarCompleteTask$.
   * But that doesn't fire when we dismiss a Review task.
   */
  aliasDismissReviewTaskAsCompleteTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.dismissReviewTask),
      withLatestFrom(this.store.select(TaskSelectors.getReviewTasks)),
      map(([action, tasks]) =>
        tasks.find((t) => t.field === action.payload.field)
      ),
      filterOutNull(),
      map((task) => TaskActions.completeTask({ payload: task }))
    )
  );
}
