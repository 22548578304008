import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PolicyNumberAdapter } from '../adapters/policy-number.adapter';
import { PolicyNumberRequest } from '../models/api/request/policy-number-request.model';
import { PolicyNumberResponse } from '../models/api/response/policy-number-response.model';
import { generatePolicyNumber } from '../store/entities/policy-number/policy-number.action';
import {
  isPolicyNumberLoaded,
  isPolicyNumberLoading,
} from '../store/entities/policy-number/policy-number.selector';
import { QuoteService } from './quote.service';

@Injectable({
  providedIn: 'root',
})
export class PolicyNumberService {
  constructor(
    private policyNumberAdapter: PolicyNumberAdapter,
    private store: Store,
    private quoteService: QuoteService
  ) {}

  generatePolicyNumber(
    request: PolicyNumberRequest
  ): Observable<PolicyNumberResponse> {
    return this.policyNumberAdapter.generatePolicyNumber(request);
  }

  dispatchGeneratePolicyNumber(): void {
    this.store.dispatch(generatePolicyNumber());
  }

  isPolicyNumberLoaded(): Observable<boolean> {
    return this.store.select(isPolicyNumberLoaded);
  }

  isPolicyNumberLoading(): Observable<boolean> {
    return this.store.select(isPolicyNumberLoading);
  }

  generatePolicyNumberIfAbsent(): Observable<boolean> {
    let requested = false;
    return combineLatest([
      this.isPolicyNumberLoaded(),
      this.isPolicyNumberLoading(),
      this.quoteService.getQuoteInProgressCount(),
    ]).pipe(
      map(([loaded, loading, quoteInProgressCount]) => {
        if (quoteInProgressCount) {
          return false;
        }
        if (loaded) {
          return true;
        }
        if (loading) {
          return false;
        }
        if (requested) {
          throw new Error('Failed to generate policy numbers');
        }
        requested = true;
        this.dispatchGeneratePolicyNumber();
        return false;
      }),
      filter((proceed) => !!proceed)
    );
  }
}
