import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors } from "@angular/forms";
import { PropertyFormService } from "@core/store/property-form/property-form.service";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: 'nwx-roof-year',
  templateUrl: './roof-year.component.html',
  styleUrls: ['./roof-year.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: RoofYearComponent,
    multi: true,
  }],
})
export class RoofYearComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {

  @Output() confirmClicked = new EventEmitter<void>();
  value: string = '';
  confirmed = false;
  disabled = false;
  errorMessage: string | null = null;
  private unsubscribe$ = new Subject<void>();
  private onChange = (v: string) => {};
  private onTouched = () => {};
  @ViewChildren('boltTextField') private boltTextFields!: QueryList<ElementRef>;
  private boltTextField: ElementRef | null = null;
  private mutationObserver: MutationObserver;
  private submitted = false;

  constructor(
    private propertyFormService: PropertyFormService,
    private changeDetector: ChangeDetectorRef,
    element: ElementRef
  ) {
    this.propertyFormService.getRoofYearImmutable().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(v => this.confirmed = v);

    this.mutationObserver = new MutationObserver((events) => this.onMutation(events));
    this.mutationObserver.observe(element.nativeElement, { attributes: true });
  }

  ngAfterViewInit(): void {
    if (!this.boltTextFields) {
      // Could happen in unit tests. Shouldn't ever happen in real life but who knows.
      return;
    }
    if (this.boltTextFields.first) {
      this.boltTextField = this.boltTextFields.first;
      if (this.boltTextField.nativeElement) {
        this.boltTextField.nativeElement.value = this.value;
      }
    }
    this.boltTextFields.changes.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((v) => {
      if (this.boltTextFields.length < 1) {
        this.boltTextField = null;
      } else {
        this.boltTextField = this.boltTextFields.first;
        if (this.boltTextField.nativeElement) {
          this.boltTextField.nativeElement.value = this.value;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.mutationObserver.disconnect();
  }

  writeValue(v: any): void {
    if (v) {
      this.value = v.toString();
    } else {
      this.value = '';
    }
    if (this.boltTextField) {
      this.boltTextField.nativeElement.value = this.value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  onMutation(events: MutationRecord[]): void {
    for (const event of events) {
      if (event.type === 'attributes') {
        if (event.attributeName === 'error') {
          this.errorMessage = (event.target as HTMLElement).getAttribute('error') || (
            (this.submitted && !this.value) ? 'Required' : null
          );
          this.changeDetector.markForCheck();
        }
      }
    }
  }

  onBoltChange(event: Event): void {
    const value = this.boltTextField?.nativeElement.value || '';
    if (value === this.value) {
      return;
    }
    this.value = value;
    this.onChange(this.value);
  }

  onBoltBlur(): void {
    this.onTouched();
  }

  validate(value: string, requireConfirmation = true): ValidationErrors | null {
    if (!value) {
      return { required: true };
    }
    value = value.toString();
    if (!value.match(/^\d{4}$/)) {
      return { year: true };
    }
    if (requireConfirmation && !this.confirmed) {
      return { required: true };
    }
    return null;
  }

  onConfirmRoofYear(): void {
    if (this.validate(this.value, false)) {
      return;
    }
    this.propertyFormService.setRoofYearImmutable(true);
    this.confirmClicked.next();
  }

  getRoofYearNotificationBubbleType(): 'info' | 'error' {
    const validation = this.validate(this.value, false);
    if (!validation) {
      return 'info';
    }
    if (validation.required) {
      return 'info';
    }
    return 'error';
  }

  highlightRequiredErrors(): void {
    this.submitted = true;
    if (!this.value) {
      this.errorMessage = 'Required';
    }
  }
}
