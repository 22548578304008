import { createReducer, on } from '@ngrx/store';
import { updateAccountRegistrationInfoForm } from './account-registration.action';

export interface AccountRegistrationInfoFormModel {
  hasUpdatedRegistration: boolean;
}

const initialState: AccountRegistrationInfoFormModel = {
  hasUpdatedRegistration: false,
};

export const reducer = createReducer<AccountRegistrationInfoFormModel>(
  initialState,
  on(updateAccountRegistrationInfoForm, (state, action) => action.model)
);
