import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injectable,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ProductsService } from '@core/services/products.service';
import { ProductModel } from '@core/store/entities/product/product.model';
import { GeneralUtils } from '@shared/utils/general.utils';
import { CustomValidators } from '@app/validators/custom-validators';
import { NavigationService } from '@core/services/navigation.service';
import { Page } from '@core/store/entities/navigation/navigation.action';
import { SessionService } from '@core/services/session.service';
import { UserService } from '@core/services/user.service';
import { MetadataService } from '@core/services/metadata.service';
import { StateSpecificFlagsModel } from '@assets/metadata/stateSpecificFlags';
import { UserModel } from '@core/store/entities/user/user.model';
import { UserType } from '@core/models/api/request/user-context.model';
import { HUB_PAGE_INDEX, MEMBER_PAGE_INDEX } from '@core/constants/pages';
import { ProductType } from '@core/models/api/dsm-types';
import { AgencyService } from '@core/services/agency.service';
import { MemberService } from '@core/services/member.service';

@Component({
  selector: 'nwx-product-toggle-form',
  templateUrl: './product-toggle-form.component.html',
  styleUrls: ['./product-toggle-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductToggleFormComponent implements OnInit {
  @Input() page!: Page;

  products$!: Observable<ProductModel[]>;
  quoteState$!: Observable<string>;
  stateSpecificFlags$!: Observable<StateSpecificFlagsModel>;
  user$!: Observable<UserModel>;
  form!: FormGroup;
  userType = UserType;
  restrictedProducts$!: Observable<ProductType[]>;
  readonly = false;

  constructor(
    public modal: NgbActiveModal,
    private productsService: ProductsService,
    private navigationService: NavigationService,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private sessionService: SessionService,
    private userService: UserService,
    private metadataService: MetadataService,
    private agencyService: AgencyService,
    private memberService: MemberService
  ) {}

  ngOnInit(): void {
    this.form = this.buildForm();
    this.quoteState$ = this.sessionService.getQuoteState();
    this.stateSpecificFlags$ = this.metadataService.getStateSpecificFlagsData();
    this.user$ = this.userService.getUser();

    this.products$ = this.productsService
      .getAllAvailableProducts()
      .pipe(map((products) => GeneralUtils.sortProductsForDisplay(products)));

    this.productsService
      .getSelectedProductsIncludingNWXInactive()
      .pipe(
        take(1),
        map((selectedProducts) => this.patchProducts(selectedProducts))
      )
      .subscribe();

    this.restrictedProducts$ = this.sessionService
      .getQuoteState()
      .pipe(
        switchMap((quoteState) =>
          this.agencyService.getAgentFilteredProducts(quoteState)
        )
      );
  }

  // Returns a Promise only for the sake of unit tests; ignore in real life.
  updateProducts(): Promise<void> {
    const { valid, value } = this.form;
    if (!valid) {
      this.selectedProducts.markAsTouched();
      this.changeDetector.detectChanges();
      return Promise.resolve();
    }
    this.productsService.updateSelectedProducts(value.selectedProducts);
    this.navigationService.setCurrentPage(this.page);
    let initiate = Promise.resolve();
    if (this.page.id === 'PNI') {
      this.navigationService.restrictNavigationBeyondPage(MEMBER_PAGE_INDEX);
    }
    if (this.page.id === 'HUB') {
      initiate = this.productsService.updateProductsAtHub().then(() => {
        return this.memberService.submitPeoplePageAndAwaitCompletion();
      });
      this.navigationService.restrictNavigationBeyondPage(HUB_PAGE_INDEX);
    }
    this.readonly = true;
    this.changeDetector.markForCheck();
    return initiate
      .then(() => {
        this.readonly = false;
        this.changeDetector.markForCheck();
        this.closeModal();
      })
      .catch((error) => {
        this.readonly = false;
        this.changeDetector.markForCheck();
      });
  }

  closeModal(): void {
    this.modal.close('close');
  }

  get selectedProducts(): FormArray {
    return this.form.get('selectedProducts') as FormArray;
  }

  private buildForm(): FormGroup {
    return this.fb.group({
      agencyCode: this.fb.control('', []),
      producerCode: this.fb.control('', []),
      state: this.fb.control('', []),
      selectedProducts: this.fb.array(
        [],
        [CustomValidators.validProductCombinationsRequired]
      ),
      agentState: this.fb.control('', []),
      agentName: this.fb.control('', []),
    });
  }

  private patchProducts(selectedProducts: ProductModel[]): void {
    selectedProducts.forEach((product) => {
      const formProducts = this.form.get('selectedProducts') as FormArray;
      if (formProducts.value.includes(product.type)) {
        return;
      }
      formProducts.controls.push(new FormControl(product.type));
    });
    (this.form.get('selectedProducts') as FormArray).updateValueAndValidity();
  }
}
