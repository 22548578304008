import { Injectable } from '@angular/core';
import { DriverVehiclePrefillResponseVehicle } from '@core/models/api/response/driver-vehicle-prefill-response.model';
import { DriverVehiclePrefillAddress } from '@core/models/api/request/driver-vehicle-prefill-request.model';
import { VehicleModel } from '@app/core/models/views/vehicle.model';
import { Nullable } from '../type.utils';
import { VehicleEntity } from '@app/core/store/entities/vehicle/vehicle.entity';
import { VehicleModelHelper } from '@core/helper/vehicle-model.helper';
import { StringUtils } from '../string.utils';

@Injectable()
export class VehicleBuilder {
  static buildVehicleEntitiesFromPrefillResponse(
    prefill: DriverVehiclePrefillResponseVehicle[],
    address: DriverVehiclePrefillAddress,
    currentVehicles: VehicleModel[]
  ): Nullable<VehicleEntity[]> {
    if (!prefill) {
      return null;
    }

    let counter = 0;
    const entities: VehicleEntity[] = prefill.map((vehicle) => {
      counter++;
      const entity: VehicleEntity = {
        entityId: StringUtils.generateEntityId(),
        vehicleId: 'PREFILL' + counter,
        prefillId: vehicle.prefillId,
        bodyStyle: vehicle.bodyStyle,
        make: vehicle.makeCode,
        model: vehicle.modelCode,
        year: +vehicle.modelYear,
        vin: vehicle.vin,
        primaryUse: 'Work',
        vehicleType: 'auto',
        selected: false,
        additionalInterests: [],
        garageLocation: {
          addressLine1: address.streetName,
          city: address.city,
          state: address.state,
          postalCode: address.zipCode,
        },
        productType: 'PersonalAuto',
      };

      return entity;
    });
    return this.removeDuplicates(entities, currentVehicles);
  }

  static removeDuplicates(
    prefillVehicles: VehicleEntity[],
    existingVehicles: VehicleModel[]
  ): VehicleEntity[] {
    return prefillVehicles.filter(
      (v) => !VehicleModelHelper.vehicleIsDuplicate(v, existingVehicles)
    );
  }
}
