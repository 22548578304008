import { Action, createReducer, on } from '@ngrx/store';
import { QuoteLetterActions, SessionActions } from '../../actions';
import { ErrorModel } from '../error/error.model';

export interface QuoteLetterState {
  quoteLetter: string;
  error: ErrorModel;
  loading: boolean;
  loaded: boolean;
}

const initialState: QuoteLetterState = {
  quoteLetter: '',
  error: {},
  loading: false,
  loaded: false,
};

const quoteLetterReducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(QuoteLetterActions.loadQuoteLetter, (state, {}) => ({
    ...state,
    loading: true,
  })),
  on(QuoteLetterActions.loadQuoteLetterSuccess, (state, { payload }) => ({
    ...state,
    quoteLetter: payload,
    loading: false,
    loaded: true,
  })),
  on(QuoteLetterActions.loadQuoteLetterFail, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }))
);

export function reducer(
  state: QuoteLetterState | undefined,
  action: Action
): QuoteLetterState {
  return quoteLetterReducer(state, action);
}

export const getQuoteLetter = (state: QuoteLetterState) => state.quoteLetter;
export const getQuoteLetterLoaded = (state: QuoteLetterState) => state.loaded;
export const getQuoteLetterLoading = (state: QuoteLetterState) => state.loading;
