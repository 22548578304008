<div class="bolt-container">
  <div class="panel" *ngFor="let report of reportDetails">
    <div
      class="panel-header bolt-row bolt-background-pale-gray-50 bolt-justify-content-between bolt-space-inner-wide-xl"
    >
      <div class="bolt-col-10 reports-details">
        <img
          class="product-image"
          src="assets/images/icons/person-circle-colorized.svg"
        />{{ report.driverName }}
      </div>
      <div class="bolt-col-2">
        <div class="reports-icon" *ngIf="report.status === 'Clean'">
          <bolt-icon
            name="checkmark-circle-filled"
            color="theme-success"
          ></bolt-icon>
          &nbsp;&nbsp;Clear
        </div>
        <div class="reports-icon" *ngIf="report.status !== 'Clean'">
          <bolt-icon
            name="exclamation-triangle-filled"
            color="theme-warning"
          ></bolt-icon
          >&nbsp;&nbsp;Adverse
        </div>
      </div>
    </div>
    <div
      class="panel-body bolt-space-inner-xl"
      *ngIf="report.incidents.length > 0"
    >
      <div class="bolt-row" *ngFor="let incident of report.incidents">
        <div class="bolt-col-6">
          <strong>{{ incident.type.toUpperCase() }}</strong>
        </div>
        <div class="bolt-col-6">{{ incident.description }}</div>
        <div class="bolt-col-6"><strong>Violation/suspension date</strong></div>
        <div class="bolt-col-6">incident.violationSuspensionDate</div>
        <div class="bolt-col-6">
          <strong>Conviction/reinstatement date</strong>
        </div>
        <div class="bolt-col-6">{{ incident.convictionReinstatementDate }}</div>
      </div>
    </div>
  </div>
</div>
