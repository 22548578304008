import { CoverageModel } from '@core/models/views/coverage.model';
import { SessionActions } from '@core/store/actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CoverageActions } from '../../actions';

export interface CoverageFormState extends EntityState<CoverageModel> {}

export const adapter: EntityAdapter<CoverageModel> =
  createEntityAdapter<CoverageModel>();

export const reducer = createReducer(
  adapter.getInitialState({}),
  on(SessionActions.clearSessionState, () => adapter.getInitialState()),
  // on(CoverageActions.addCoverages, (state, { payload }) => {
  //   return adapter.(payload, state);
  // }),
  on(
    CoverageActions.addFormCoverage,
    (state, { productType: productId, payload }) => {
      return adapter.setOne({ ...payload, id: productId }, state);
    }
  ),
  on(
    CoverageActions.updateCoverage,
    (state, { productType: productId, payload }) => {
      return adapter.updateOne({ id: productId, changes: payload }, state);
    }
  )
);
