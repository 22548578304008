import { BillingPlansFormModel } from '@app/billing-payment/components/billing-plans-form/billing-plans-form.model';
import { createAction, props } from '@ngrx/store';

export const updateBillingPlansForm = createAction(
  '[Forms] Update Billing Plans form',
  props<{
    payload: Partial<BillingPlansFormModel>;
  }>()
);

export const billingPlansFormVisited = createAction(
  '[Forms] Billing Plans Form Visited'
);

export const resetBillingPlansFormVisited = createAction(
  '[Forms] Reset Billing Plans Form Visited'
);

// Just a signal action in case we need to know later (eg to present a toast "we changed the billing plan"?)
export const billingPlanChangedDueToTelematics = createAction(
  '[Forms] Billing Plan Changed Due To Telematics',
  props<{ previous: string, billingPlan: string }>()
);
