import { getCoreState } from '@core/store/reducers';
import { createSelector } from '@ngrx/store';

export const getLayoutState = createSelector(
  getCoreState,
  (core) => core.layout
);

export const getLayoutViewport = createSelector(
  getLayoutState,
  (state) => state.viewport
);

// max is 575px
export const maxSizeMini = createSelector(
  getLayoutState,
  (state) => state.viewport === 'mini'
);

// max is 767px
export const maxSizeXSmall = createSelector(getLayoutState, (state) =>
  ['mini', 'xsmall'].includes(state.viewport)
);

// 991px
export const maxSizeSmall = createSelector(getLayoutState, (state) =>
  ['mini', 'xsmall', 'small'].includes(state.viewport)
);

// 1199px
export const maxSizeMedium = createSelector(getLayoutState, (state) =>
  ['mini', 'xsmall', 'small', 'medium'].includes(state.viewport)
);

// 1499px
export const maxSizeLarge = createSelector(getLayoutState, (state) =>
  ['mini', 'xsmall', 'small', 'medium', 'large'].includes(state.viewport)
);

//2649px
export const maxSizeXLarge = createSelector(getLayoutState, (state) =>
  ['mini', 'xsmall', 'small', 'medium', 'large', 'xlarge'].includes(
    state.viewport
  )
);

// larger than 2649px
export const maxSizeXXLarge = createSelector(getLayoutState, (state) =>
  ['mini', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge'].includes(
    state.viewport
  )
);
