import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable, of, OperatorFunction } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { VehicleInquiryAdapter } from '../adapters/vehicle-inquiry.adapter';
import * as models from '@core/models/api/vehicle-inquiry.model';
import { PicklistItem } from '@shared/models/picklist.model';
import { VehicleInquirySelectors } from '../store/selectors';
import { VehicleInquiryActions } from '../store/actions';
import {
  BODY_TYPES_NAME,
  BODY_TYPES_DESCRIPTION,
} from '@shared/constants/app-constants';
import { Nullable } from '@shared/utils/type.utils';
import { VehicleInquiryModel } from '@core/store/entities/vehicle-inquiry/vehicle-inquiry.reducer';
import { getProductVehicleInquiry } from '@core/store/entities/vehicle-inquiry/vehicle-inquiry.selector';
import { InquiryPath } from '@core/adapters/powersports.adapter';

export function bodyTypeFromString(nameOrDescription: string): string {
  for (const key of Object.keys(BODY_TYPES_NAME)) {
    if (nameOrDescription === BODY_TYPES_NAME[key]) {
      return key;
    }
  }
  for (const key of Object.keys(BODY_TYPES_DESCRIPTION)) {
    if (nameOrDescription === BODY_TYPES_DESCRIPTION[key]) {
      return key;
    }
  }
  return nameOrDescription;
}

export function bodyTypeToString(bodyType: string): string {
  return (
    BODY_TYPES_NAME[bodyType] || BODY_TYPES_DESCRIPTION[bodyType] || bodyType
  );
}

@Injectable({
  providedIn: 'root',
})
export class VehicleInquiryService {
  constructor(private store: Store, private adapter: VehicleInquiryAdapter) {}

  getYearsInDisplayOrder(inquiryPath: InquiryPath): Observable<number[]> {
    return this.getYears(inquiryPath).pipe(
      map((years) => {
        const sorted = [...(years?.map((y) => +y.displayName) as number[])];
        sorted.sort((a, b) => b - a);
        return sorted;
      })
    );
  }

  getProductVehicleInquiry(
    inquiryPath: InquiryPath
  ): Observable<Nullable<VehicleInquiryModel>> {
    return this.store.select(getProductVehicleInquiry(inquiryPath));
  }

  getVehicleInquiryYearsLoaded(
    inquiryPath: InquiryPath
  ): Observable<Nullable<boolean>> {
    return this.store.select(
      VehicleInquirySelectors.getVehicleInquiryYearsLoaded(inquiryPath)
    );
  }

  getYears(inquiryPath: InquiryPath): Observable<Nullable<PicklistItem[]>> {
    return this.store
      .select(VehicleInquirySelectors.getVehicleInquiryYears(inquiryPath))
      .pipe(
        filter((years) => {
          if (!years) {
            this.store.dispatch(
              VehicleInquiryActions.setPlaceholderVehicleInquiryYears({
                inquiryPath,
              })
            );
            this.retrieveAndStoreYears(inquiryPath);
            return false;
          } else {
            return true;
          }
          // This cast clarifies that (years) can no longer be null.
        }) as OperatorFunction<Nullable<PicklistItem[]>, PicklistItem[]>
      );
  }

  dispatchRetrieveAndStoreYears(): Observable<models.RetrieveVehicleModelYearsResponse> {
    return this.adapter.retrieveVehicleModelYears();
  }

  retrieveAndStoreYears(inquiryPath: InquiryPath): void {
    this.adapter
      .retrieveVehicleModelYears()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          const formattedResponse =
            response.retrieveVehicleModelYearsResponse.modelYear
              .filter((year) => !!year)
              .map((year) => {
                return {
                  displayName: year.toString(),
                  value: year.toString(),
                } as PicklistItem;
              });
          this.store.dispatch(
            VehicleInquiryActions.setVehicleInquiryYears({
              response: formattedResponse,
              inquiryPath,
            })
          );
        },
        error: (error) =>
          this.store.dispatch(
            VehicleInquiryActions.setVehicleInquiryYearsError({
              inquiryPath,
            })
          ),
      });
  }

  getMakesAsPicklist(
    year: number,
    inquiryPath: InquiryPath
  ): Observable<Nullable<PicklistItem[]>> {
    if (!year) {
      return of([]);
    }
    return this.getMakes(year, inquiryPath);
  }

  getMakes(
    year: number,
    inquiryPath: InquiryPath
  ): Observable<Nullable<PicklistItem[]>> {
    let failureCount = 0;
    return this.store
      .select(VehicleInquirySelectors.getVehicleInquiryMakes(year, inquiryPath))
      .pipe(
        filter((response) => {
          if (response === undefined) {
            if (failureCount++ > 0) {
              throw new Error('VehicleInquiry error');
            }
            this.store.dispatch(
              VehicleInquiryActions.setPlaceholderVehicleInquiryMakes({
                inquiryPath,
                year,
              })
            );
            this.retrieveAndStoreMakes(year, inquiryPath);
            return false;
          } else if (!response) {
            return false;
          } else {
            return true;
          }
        }) as OperatorFunction<Nullable<PicklistItem[]>, PicklistItem[]>
      );
  }

  dispatchRetrieveAndStoreMakes(
    year: string
  ): Observable<models.RetrieveVehicleModelMakesResponse> {
    return this.adapter.retrieveVehicleModelMakes(year);
  }

  private retrieveAndStoreMakes(year: number, inquiryPath: InquiryPath): void {
    this.adapter
      .retrieveVehicleModelMakes(year.toString())
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          const formattedResponse =
            response.retrieveVehicleModelMakesResponse.vehicleMake.map(
              (response) => {
                return {
                  displayName: response.makeDescription,
                  value: response.make,
                } as PicklistItem;
              }
            );
          this.store.dispatch(
            VehicleInquiryActions.setVehicleInquiryMakes({
              year,
              response: formattedResponse,
              inquiryPath,
            })
          );
        },
        error: (error) => {
          this.store.dispatch(
            VehicleInquiryActions.removeVehicleInquiryMakes({
              year,
              inquiryPath,
            })
          );
        },
      });
  }


  getModelsAsPicklist(
    year: number,
    make: string,
    inquiryPath: InquiryPath
  ): Observable<Nullable<PicklistItem[]>> {
    if (!year || !make) {
      return of([]);
    }
    return this.getModels(year, make, inquiryPath).pipe(
      map((response) => {
        return response
          ?.filter(
            (item, i) =>
              i === 0 || item.displayName !== response[i - 1].displayName
          )
          .map((pair) => {
            return {
              displayName: pair.displayName,
              value: pair.displayName,
            };
          });
      })
    );
  }

  dispatchRetrieveAndStoreModels(
    year: string,
    make: string,
    inquiryPath: InquiryPath
  ): Observable<models.RetrieveVehicleModelsWithDescriptionResponse> {
    return this.adapter.retrieveVehicleModelsWithDescription(year, make);
  }

  getModels(
    year: number,
    make: string,
    inquiryPath: InquiryPath
  ): Observable<Nullable<PicklistItem[]>> {
    let failureCount = 0;
    return this.store
      .select(
        VehicleInquirySelectors.getVehicleInquiryModels(year, make, inquiryPath)
      )
      .pipe(
        filter((response) => {
          if (response === undefined) {
            if (failureCount++ > 0) {
              throw new Error('VehicleInquiry error');
            }
            this.store.dispatch(
              VehicleInquiryActions.setPlaceholderVehicleInquiryModels({
                inquiryPath,
                year,
                make,
              })
            );
            this.retrieveAndStoreModels(year, make, inquiryPath);
            return false;
          } else if (!response) {
            return false;
          } else {
            return true;
          }
        }) as OperatorFunction<Nullable<PicklistItem[]>, PicklistItem[]>
      );
  }

  private retrieveAndStoreModels(
    year: number,
    make: string,
    inquiryPath: InquiryPath
  ): void {
    this.adapter
      .retrieveVehicleModelsWithDescription(year.toString(), make)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          const formattedResponse =
            response.retrieveVehicleModelsWithDescriptionResponse.modelWithDescription.map(
              (model) => {
                return {
                  displayName: model.model,
                  value: model.modelDescription,
                } as PicklistItem;
              }
            );
          this.store.dispatch(
            VehicleInquiryActions.setVehicleInquiryModels({
              year,
              make,
              response: formattedResponse,
              inquiryPath,
            })
          );
        },
        error: (error) => {
          this.store.dispatch(
            VehicleInquiryActions.removeVehicleInquiryModels({
              year,
              make,
              inquiryPath,
            })
          );
        },
      });
  }

  getSeriesAsPicklist(
    year: number,
    make: string,
    model: string,
    inquiryPath: InquiryPath
  ): Observable<Nullable<PicklistItem[]>> {
    if (!year || !make || !model) {
      return of([]);
    }
    return this.getSeries(year, make, model, inquiryPath).pipe(
      map((response) => {
        return response
          ?.filter(
            (item, i) =>
              i === 0 || item.displayName !== response[i - 1].displayName
          )
          .map((pair) => {
            return {
              displayName: pair.displayName,
              value: pair.displayName,
            };
          });
      })
    );

  }

  getSeries(
    year: number,
    make: string,
    model: string,
    inquiryPath: InquiryPath
  ): Observable<Nullable<PicklistItem[]>> {
    let failureCount = 0;
    return this.store
      .select(
        VehicleInquirySelectors.getVehicleInquirySeries(year, make, model, inquiryPath)
      )
      .pipe(
        filter((response) => {
          if (response === undefined) {
            if (failureCount++ > 0) {
              throw new Error('VehicleInquiry error');
            }
            this.store.dispatch(
              VehicleInquiryActions.setPlaceholderVehicleInquirySeries({
                inquiryPath,
                year,
                make,
                model,
              })
            );
            this.retrieveAndStoreSeries(year, make, model, inquiryPath);
            return false;
          } else if (!response) {
            return false;
          } else {
            return true;
          }
        }) as OperatorFunction<Nullable<PicklistItem[]>, PicklistItem[]>
      );
  }

  dispatchRetrieveAndStoreSeries(
    year: string,
    make: string,
    model: string
  ): Observable<models.RetrieveVehicleModelSeriesResponse> {
    return this.adapter.retrieveVehicleModelSeries(year, make, model);
  }

  private retrieveAndStoreSeries(
    year: number,
    make: string,
    model: string,
    inquiryPath: InquiryPath
  ): void {
    this.adapter
    .retrieveVehicleModelSeries(year.toString(), make, model)
    .pipe(take(1))
    .subscribe({
      next: (response) => {
        try {
          const formattedResponse =
            response.retrieveVehicleModelSeriesResponse.modelSeries.map(
              (modelSeries) => {
                return {
                    displayName: modelSeries.seriesDescription,
                    value: modelSeries.style,
                  } as PicklistItem;
            });
          this.store.dispatch(
            VehicleInquiryActions.setVehicleInquirySeries({
              year,
              make,
              model,
              response: formattedResponse,
              inquiryPath,
            })
          );
        } catch (e) {
          this.store.dispatch(
            VehicleInquiryActions.removeVehicleInquirySeries({
              year,
              make,
              model,
              inquiryPath,
            })
          );
        }
      },
      error: (error) => {
        this.store.dispatch(
          VehicleInquiryActions.removeVehicleInquirySeries({
            year,
            make,
            model,
            inquiryPath,
          })
        );
      },
    });
  }

  getRates(
    year: number,
    make: string,
    model: string,
    inquiryPath: InquiryPath
  ): Observable<models.RetrieveVehicleSeriesRatesOdbiResponse> {
    if (!year || !make || !model) {
      return from([]);
    }
    let failureCount = 0;
    return this.store
      .select(VehicleInquirySelectors.getRates(year, make, model, inquiryPath))
      .pipe(
        filter((response) => {
          if (response === undefined) {
            if (failureCount++ > 0) {
              throw new Error('VehicleInquiry error');
            }
            this.store.dispatch(
              VehicleInquiryActions.setVehicleInquiryRates({
                year,
                make,
                model,
                response: null,
                inquiryPath,
              })
            );
            this.retrieveAndStoreRates(year, make, model, inquiryPath);
          }
          if (!response) {
            return false;
          }
          return true;
        }),
        map(
          (response) =>
            response as models.RetrieveVehicleSeriesRatesOdbiResponse
        )
      );
  }
  dispatchRetrieveAndStoreRates(
    year: string,
    make: string,
    model: string
  ): Observable<models.RetrieveVehicleSeriesRatesOdbiResponse> {
    return this.adapter.retrieveVehicleSeriesRatesOdbi(year, make, model);
  }

  private retrieveAndStoreRates(
    year: number,
    make: string,
    model: string,
    inquiryPath: InquiryPath
  ): void {
    this.adapter
      .retrieveVehicleSeriesRatesOdbi(year.toString(), make, model)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.store.dispatch(
            VehicleInquiryActions.setVehicleInquiryRates({
              year,
              make,
              model,
              response,
              inquiryPath,
            })
          );
        },
        error: (error) => {
          this.store.dispatch(
            VehicleInquiryActions.removeVehicleInquiryRates({
              year,
              make,
              model,
              inquiryPath,
            })
          );
        },
      });
  }

  getBodyTypeAndStyle(
    year: number,
    make: string,
    model: string,
    series: string,
    inquiryPath: InquiryPath
  ): Observable<{
    bodyType: string;
    bodyStyle: string;
  } | null> {
    return this.getRates(year, make, model, inquiryPath).pipe(
      map((response) => {
        const data =
          response.retrieveVehicleSeriesRatesOdbiResponse.vehicleModel.find(
            (v) => v.seriesDescription === series
          );
        if (data) {
          let vehicleModelTypeCode: string | number = data.vehicleModelTypeCode;
          if (
            vehicleModelTypeCode === null ||
            vehicleModelTypeCode === undefined
          ) {
            vehicleModelTypeCode = '';
          } else {
            vehicleModelTypeCode = vehicleModelTypeCode.toString();
          }
          return {
            bodyType: bodyTypeToString(vehicleModelTypeCode),
            bodyStyle: data.style,
          };
        } else {
          return null;
        }
      })
    );
  }

  shortModelNameFromDescription(
    year: number,
    make: string,
    modelDescription: string,
    inquiryPath: InquiryPath
  ): Observable<string> {
    return this.getModels(year, make, inquiryPath).pipe(
      map((response) => {
        return (
          response?.find((m) => m.value === modelDescription)?.displayName || ''
        );
      })
    );
  }

  getVehicleDisplayName(
    year: number,
    make: string,
    inquiryPath: InquiryPath
  ): Observable<string> {
    if (!year || !make) {
      return of('');
    }
    return this.getMakes(year, inquiryPath).pipe(
      map((response) => {
        const matchedMake =
          response?.find((pair) => pair.value === make)?.displayName || make;
        return `${year} ${matchedMake}`;
      })
    );
  }

  lookUpVin(
    vin: string,
    inquiryPath: InquiryPath
  ): Observable<models.Retrieve17DigitVehicleIdentificationNumberOdbiResponse> {
    let failureCount = 0;
    return this.store
      .select(VehicleInquirySelectors.getVin(vin, inquiryPath))
      .pipe(
        filter((response) => {
          if (response === undefined) {
            if (failureCount++ > 0) {
              throw new Error('VehicleInquiry error');
            }
            this.store.dispatch(
              VehicleInquiryActions.setVehicleInquiryVin({
                vin,
                response: null,
                inquiryPath,
              })
            );
            this.retrieveAndStoreVin(vin, inquiryPath);
          }
          if (!response) {
            return false;
          }
          return true;
        }),
        map(
          (response) =>
            response as models.Retrieve17DigitVehicleIdentificationNumberOdbiResponse
        )
      );
  }

  private retrieveAndStoreVin(vin: string, inquiryPath: InquiryPath): void {
    this.adapter
      .retrieve17DigitVehicleIdentificationNumberOdbi(vin)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.store.dispatch(
            VehicleInquiryActions.setVehicleInquiryVin({
              vin,
              response,
              inquiryPath,
            })
          );
        },
        error: (error) => {
          this.store.dispatch(
            VehicleInquiryActions.removeVehicleInquiryVin({ vin, inquiryPath })
          );
        },
      });
  }
}
