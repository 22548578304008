import { ProductType } from '@core/models/api/dsm-types';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromActions from '@core/store/entities/uw-reports/uw-reports.action';
import {
  getLoaded,
  getUWReportsByProduct,
  getUwReportsLoading,
  // getCountOfAdverseReports,
} from '../store/entities/uw-reports/uw-reports.selector';
import { Observable } from 'rxjs';
import { UWReportsResponse } from '../models/api/response/uw-reports-response.model';
import { map } from 'rxjs/operators';
import { NavigationActions, UWReportsActions } from '@core/store/actions';
import { UWReportsByProduct } from '@entities/uw-reports/uw-reports.reducer';

@Injectable({
  providedIn: 'root',
})
export class UWReportsService {
  constructor(private store: Store) {}

  refreshUWReportsByProduct(productType: ProductType): void {
    this.store.dispatch(fromActions.refreshUWReportsByProduct({ productType }));
  }

  dispatchGetUWReports(quoteId: string, product: ProductType): void {
    this.store.dispatch(fromActions.getUWReports({ quoteId, product }));
  }

  getReportsGroupedByProduct(): Observable<UWReportsByProduct> {
    return this.store.select(getUWReportsByProduct);
  }

  dispatchGetMSBEstimate(productType: ProductType): void {
    this.store.dispatch(fromActions.getMSBEstimate({ payload: productType }));
  }

  dispatchGetMSBLaunchUrl(quoteId: string, product: ProductType): void {
    this.store.dispatch(
      fromActions.getMSBLaunchUrl({ quoteId, productType: product })
    );
  }

  uwReportsLoaded(): Observable<boolean> {
    return this.store.select(getLoaded);
  }

  launchMSB(productType: ProductType): void {
    this.store.dispatch(
      NavigationActions.launchMSBSite({
        payload: productType,
      })
    );
  }

  // put this on hold. Requested countOfAdverseReports API calls from from Wildlings
  // getCountOfAdverseReports(): Observable<number> {
  //   return this.store.select(getCountOfAdverseReports);
  // }

  getUwReportsLoading(): Observable<boolean> {
    return this.store.select(getUwReportsLoading);
  }
}
