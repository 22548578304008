import { RenovationEntity } from '@core/interfaces/interfaces';
import {
  CommonType,
  GarageModel,
} from '@core/store/entities/covered-location/covered-location.model';
import {
  ProtectiveDeviceModel,
  ProtectiveDevicesOptionsModel,
} from '@core/store/entities/metadata/models/property-options.model';
import { StringUtils } from '@shared/utils/string.utils';
import { Nullable } from '@shared/utils/type.utils';

export class CoveredLocationUtils {
  // This is currently unused
  static getProtectiveDevicesArray(
    protectiveDevicesObject: Nullable<ProtectiveDevicesOptionsModel>
  ): ProtectiveDeviceModel[] {
    return [
      ...(protectiveDevicesObject?.devices || []),
      ...(protectiveDevicesObject?.windMitigation || []),
    ];
  }

  static updateRenovationsArray(
    renovations: Nullable<RenovationEntity[]>,
    year: Nullable<number>
  ): RenovationEntity[] {
    const existingRenovations = renovations?.map(
      (renovation) => renovation.type
    );
    const returnRenovations = [...(renovations || [])];

    if (!existingRenovations?.includes('Roof')) {
      returnRenovations.push({
        type: 'Roof',
      } as RenovationEntity);
    }
    if (!existingRenovations?.includes('Cooling')) {
      returnRenovations?.push({
        type: 'Cooling',
        year,
      } as RenovationEntity);
    }
    if (!existingRenovations?.includes('Plumbing')) {
      returnRenovations?.push({
        type: 'Plumbing',
        year,
      } as RenovationEntity);
    }
    if (!existingRenovations?.includes('Electrical')) {
      returnRenovations?.push({
        type: 'Electrical',
        year,
      } as RenovationEntity);
    }
    if (!existingRenovations?.includes('Heating')) {
      returnRenovations?.push({
        type: 'Heating',
        year,
      } as RenovationEntity);
    }

    return returnRenovations;
  }

  static updateGaragesArray(garages: Nullable<GarageModel[]>): GarageModel[] {
    if (!garages?.length) {
      return [
        {
          garageType: undefined,
          numberOfCars: 1,
          garageId: StringUtils.generateEntityId(),
        },
      ];
    } else {
      return garages.map((garage) => ({
        garageType: garage.garageType,
        numberOfCars: garage.numberOfCars
          ? garage.numberOfCars
          : this.getNumberOfCarsFromSqFt(garage.squareFeet ?? 0),
        garageId: garage.garageId
          ? garage.garageId
          : StringUtils.generateEntityId(),
      })) as GarageModel[];
    }
  }

  // This needs to be fixed probably to match MSB
  static getNumberOfCarsFromSqFt(squareFeet: number): number {
    switch (true) {
      case squareFeet <= 330:
        return 1;
      case squareFeet > 330 && squareFeet <= 460:
        return 1.5;
      case squareFeet > 460 && squareFeet <= 576:
        return 2;
      case squareFeet > 576 && squareFeet <= 690:
        return 2.5;
      case squareFeet > 690 && squareFeet <= 825:
        return 3;
      case squareFeet > 825 && squareFeet <= 945:
        return 3.5;
      case squareFeet > 945 && squareFeet <= 1080:
        return 4;
      case squareFeet > 1080 && squareFeet <= 1195:
        return 4.5;
      case squareFeet > 1195:
        return 5;
      default:
        return 0;
    }
  }

  static updateCommonTypesArray(
    commonTypes: Nullable<CommonType[]>
  ): CommonType[] {
    if (!commonTypes?.length) {
      return [
        {
          type: undefined,
          percentage: undefined,
          id: StringUtils.generateEntityId(),
        },
      ];
    } else {
      return commonTypes.map((commonType) => ({
        type: commonType.type,
        percentage: commonType.percentage ? commonType.percentage : undefined,
        id: commonType.id ? commonType.id : StringUtils.generateEntityId(),
      })) as CommonType[];
    }
  }
}
