<ng-container>
  <div class="bolt-container-fluid">
    <ng-container *ngIf="showSaved">
      <p class="status-indicator" *ngIf="isSaved; else unsaved">
        Included in Quote
        <bolt-icon
          [attr.id]="'small-check-' + index"
          name="checkmark"
          color="white"
          size="sm"
        ></bolt-icon>
      </p>
      <ng-template #unsaved
        ><p class="status-indicator">* Not Added to Quote</p></ng-template
      >
    </ng-container>
    <h3 class="card-header-title" [id]="titleId || title">
      {{ title }}
    </h3>

    <ng-container *ngIf="subtitle">
      <p class="card-header-label" *ngIf="total > 0; else noCount">
        {{ subtitle || '' }} ({{ index + 1 }}&nbsp;of&nbsp;{{ total }})
      </p>
      <ng-template #noCount>
        <p class="card-header-label">
          {{ subtitle || '' }}
        </p>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
