import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DsmAdapter, responseUnused } from './dsm.adapter';
import { AppConfigService } from '@core/services/app-config.service';
import {
  WatercraftExposureEntity,
  sanitizeWatercraftExposureEntity,
} from '@entities/exposures/watercraft-exposures/watercraft-exposures.entity';
import { HttpHeaders } from '@angular/common/http';
import {
  WatercraftExcludedExposureRequest,
  WatercraftExposureRequest,
} from '@core/models/api/request/watercraft-exposure-request.model';
import {
  WatercraftExcludedExposureEntity,
  sanitizeWatercraftExcludedExposureEntity,
} from '@entities/exposures/watercraft-excluded-exposures/watercraft-excluded-exposures.entity';

@Injectable({
  providedIn: 'root',
})
export class WatercraftExposureAdapter {
  constructor(
    public adapter: DsmAdapter,
    public appService: AppConfigService
  ) {}

  addWatercraftExposure(
    request: WatercraftExposureRequest
  ): Observable<WatercraftExposureEntity> {
    let headers;
    if (!this.appService.isProd && this.appService.config.autoApproveUW) {
      headers = new HttpHeaders({
        'Auto-Approve-Rules': 'true',
      });
    }
    return this.adapter.request(
      sanitizeWatercraftExposureEntity,
      'PersonalUmbrella',
      'POST',
      `/quotes/${request.quoteId}/watercraft-exposures`,
      'add-watercraft-exposure',
      {
        body: request.watercraftExposureBody,
        headers,
      }
    );
  }

  addWatercraftExcludedExposure(
    request: WatercraftExcludedExposureRequest,
    quoteId?: string
  ): Observable<WatercraftExcludedExposureEntity> {
    return this.adapter.request(
      sanitizeWatercraftExcludedExposureEntity,
      'PersonalUmbrella',
      'POST',
      `/quotes/${request.quoteId}/watercraft-excluded-exposures`,
      'add-watercraft-excluded-exposure',
      {
        body: request.watercraftExcludedExposureBody,
      }
    );
  }

  updateWatercraftExposure(
    request: WatercraftExposureRequest
  ): Observable<WatercraftExposureEntity> {
    let headers;
    if (!this.appService.isProd && this.appService.config.autoApproveUW) {
      headers = new HttpHeaders({
        'Auto-Approve-Rules': 'true',
      });
    }
    return this.adapter.request(
      sanitizeWatercraftExposureEntity,
      'PersonalUmbrella',
      'PUT',
      `/quotes/${request.quoteId}/watercraft-exposures/${request.watercraftExposureBody.watercraftExposure.watercraftExposureId}`,
      'update-watercraft-exposure',
      {
        body: request.watercraftExposureBody,
        headers,
      }
    );
  }

  updateWatercraftExcludedExposure(
    request: WatercraftExcludedExposureRequest
  ): Observable<WatercraftExcludedExposureEntity> {
    let headers;
    if (!this.appService.isProd && this.appService.config.autoApproveUW) {
      headers = new HttpHeaders({
        'Auto-Approve-Rules': 'true',
      });
    }
    return this.adapter.request(
      sanitizeWatercraftExcludedExposureEntity,
      'PersonalUmbrella',
      'PUT',
      `/quotes/${request.quoteId}/watercraft-excluded-exposures/${request.watercraftExcludedExposureBody.watercraftExcludedExposure.watercraftExcludedExposureId}`,
      'update-watercraft-excluded-exposure',
      {
        body: request.watercraftExcludedExposureBody,
        headers,
      }
    );
  }

  removeWatercraftExposure(
    request: WatercraftExposureRequest
  ): Observable<unknown> {
    return this.adapter.request(
      responseUnused,
      'PersonalUmbrella',
      'DELETE',
      `/quotes/${request.quoteId}/watercraft-exposures/${request.watercraftExposureBody.watercraftExposure.watercraftExposureId}`,
      'remove-watercraft-exposure'
    );
  }

  removeWatercraftExcludedExposure(
    request: WatercraftExcludedExposureRequest
  ): Observable<unknown> {
    return this.adapter.request(
      responseUnused,
      'PersonalUmbrella',
      'DELETE',
      `/quotes/${request.quoteId}/watercraft-excluded-exposures/${request.watercraftExcludedExposureBody.watercraftExcludedExposure.watercraftExcludedExposureId}`,
      'remove-watercraft-excluded-exposure',
      {
        body: request.watercraftExcludedExposureBody,
      }
    );
  }
}
