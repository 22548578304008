import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { ProductModel } from '../../entities/product/product.model';
import { CoreState } from '../../reducers/index';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { from } from 'rxjs';
import {
  switchMap,
  catchError,
  withLatestFrom,
  filter,
  take,
  map,
  mergeMap,
  exhaustMap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CoveredLocationAdapter } from '@core/adapters/covered-location.adapter';
import * as fromActions from './covered-location.action';
import { Nullable } from '@shared/utils/type.utils';
import { CoveredLocationSelectors } from '@core/store/selectors';
import { CoveredLocationEntity } from '@core/store/entities/covered-location/covered-location.reducer';
import { ProductsService } from '@core/services/products.service';
import { ProductType } from '@core/models/api/dsm-types';
import { CoveredLocationService } from '@core/services/covered-location.service';

@Injectable()
export class CoveredLocationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<CoreState>,
    private coveredLocationAdapter: CoveredLocationAdapter,
    private errorSanitizerService: ErrorSanitizerService,
    private productsService: ProductsService,
    private coveredLocationService: CoveredLocationService
  ) {}

  getCoveredLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCoveredLocation),
      exhaustMap((action) =>
        this.productsService.getProduct(action.payload).pipe(take(1))
      ),
      switchMap((product: Nullable<ProductModel>) => {
        return this.coveredLocationAdapter
          .getCoveredLocation(product as ProductModel)
          .pipe(
            switchMap((response) =>
              from([
                fromActions.getCoveredLocationSuccess({
                  payload: {
                    ...response,
                    productType: product?.type as ProductType,
                  },
                }),
              ])
            ),
            catchError((error) => {
              const safeError = this.errorSanitizerService.sanitizeError(
                error,
                product?.type
              );
              return from([
                fromActions.getCoveredLocationFail({ error: safeError }),
              ]);
            })
          );
      })
    )
  );

  updateCoveredLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateCoveredLocation),
      mergeMap((action) =>
        this.store
          .select(
            CoveredLocationSelectors.buildCoveredLocationRequest(
              action.payload,
              action.includeMsbEstimateNumber
            )
          )
          .pipe(
            take(1),
            withLatestFrom(
              this.coveredLocationService.getCoveredLocation(action.payload)
            ),
            map(([request, existingCoveredLocation]) => ({
              request,
              correlationId: action.correlationId,
              existingCoveredLocation,
            }))
          )
      ),
      mergeMap(({ request, correlationId, existingCoveredLocation }) =>
        this.coveredLocationAdapter.updateCoveredLocation(request).pipe(
          switchMap((response: CoveredLocationEntity) => {
            let wasReconstructionCostUpdated = false;
            if (
              existingCoveredLocation.wasReconstructionCostUpdated ||
              existingCoveredLocation.reconstructionCost !==
                response.reconstructionCost
            ) {
              wasReconstructionCostUpdated = true;
            }
            return from([
              fromActions.updateCoveredLocationSuccess({
                payload: {
                  ...response,
                  wasReconstructionCostUpdated,
                  productType: request.product.type,
                },
                correlationId,
              }),
            ]);
          }),
          catchError((error) => {
            const safeError = this.errorSanitizerService.sanitizeError(
              error,
              request.product.type
            );
            return from([
              fromActions.updateCoveredLocationError({
                error: safeError,
                correlationId,
              }),
            ]);
          })
        )
      )
    )
  );
}
