import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LayoutService } from '@core/services/layout.service';
import { PremiumService } from '@core/services/premium.service';
import { ProductsService } from '@core/services/products.service';
import { SessionService } from '@core/services/session.service';
import { TelematicsService } from '@core/services/telematics.service';
import {
  VehicleEnrollment,
  MobileEnrollment,
  ExtendedTelematicsVehicle,
} from '@core/store/entities/telematics/telematics.model';
import { PremiumEntity } from '@entities/premium/premium.entity';
import { Nullable } from '@shared/utils/type.utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'nwx-telematics-container',
  templateUrl: './telematics-container.component.html',
  styleUrls: ['./telematics-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelematicsContainerComponent {
  telematics$: Observable<Nullable<VehicleEnrollment | MobileEnrollment>>;
  programTileVehicles$: Observable<ExtendedTelematicsVehicle[]>;
  quoteState$: Observable<string>;
  isRecommendationEnrolled$: Observable<boolean>;
  isAnyTelematicsEnrolled$: Observable<boolean>;
  shouldProposeReenrollExcluded$: Observable<boolean>;
  hasDrivingDataConsent$: Observable<Nullable<boolean>>;
  maxSizeMini$!: Observable<boolean>;
  maxSizeMedium$!: Observable<boolean>;
  premium$: Observable<Nullable<PremiumEntity>>;

  constructor(
    private telematicsService: TelematicsService,
    private sessionService: SessionService,
    private productService: ProductsService,
    private layoutService: LayoutService,
    private premiumService: PremiumService
  ) {
    this.isRecommendationEnrolled$ =
      this.telematicsService.isRecommendationEnrolled();
    this.telematics$ = this.telematicsService.getCurrentEnrollment();
    this.programTileVehicles$ =
      this.telematicsService.buildProgramTileVehicles();
    this.quoteState$ = this.sessionService.getQuoteState();
    this.isAnyTelematicsEnrolled$ =
      this.telematicsService.isAnyTelematicsEnrolled();
    this.shouldProposeReenrollExcluded$ =
      this.telematicsService.shouldProposeReenrollExcluded();
    this.hasDrivingDataConsent$ = this.productService
      .getProduct('PersonalAuto')
      .pipe(map((p) => p?.hasDrivingDataConsent));
    this.maxSizeMini$ = this.layoutService.maxSizeMini();
    this.maxSizeMedium$ = this.layoutService.maxSizeMedium();
    this.premium$ = this.premiumService.getPremiumFor('PersonalAuto');
  }
}
