import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Nullable } from '@shared/utils/type.utils';
import { ProductType } from '@core/models/api/dsm-types';
import { Observable, ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { MemberModel } from '@core/models/views/person.model';
import {
  BasicCompanyInformation
} from '@app/additional-interests/additional-interest-holder-selection/additional-interest-holder-selection.component';
import { VehicleModel } from '@core/models/views/vehicle.model';
import {
  AdditionalInterestFormState
} from '@app/additional-interests/additional-interests-form/additional-interests-form.component';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentStoreService } from '@core/services/component-store.service';
import { VehicleService } from '@core/services/vehicle.service';
import { PersonEntity } from '@core/models/entities/person.entity';
import {
  ADDITIONAL_INTEREST_OPTIONS, AdditionalInterestOption,
} from '@app/additional-interests/vehicle/models/vehicle-additional-interests.type';
import { FormHelper } from '@core/helper/form-helper';
import { ComponentStore } from '@ngrx/component-store';
import { AddressModel } from '@shared/components/address-input/address-input.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationService } from '@core/services/navigation.service';
import { filter } from 'rxjs/operators';
import { GeneralUtils } from '@shared/utils/general.utils';
import { VehicleAdditionalInterestEntity } from '@entities/vehicle/vehicle.entity';

export interface VehicleAdditionalInterestModalResult {
  action: 'save' | 'delete' | 'cancel';
  value: Nullable<VehicleAdditionalInterestEntity>;
}

@Component({
  selector: 'nwx-vehicle-additional-interest-modal',
  templateUrl: './vehicle-additional-interest-modal.component.html',
  styleUrls: ['./vehicle-additional-interest-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore]
})
export class VehicleAdditionalInterestModalComponent implements OnInit, OnDestroy {
  @Input() productType: ProductType = 'PersonalAuto';
  @Input() mode: 'add' | 'edit' = 'add';
  @Input() vehicle: Nullable<Partial<VehicleModel>>;
  @Input() interest: Nullable<VehicleAdditionalInterestEntity>;

  members$: Observable<MemberModel[]>;
  companies$: Observable<BasicCompanyInformation[]>;
  vm$: Observable<AdditionalInterestFormState> = new Observable<AdditionalInterestFormState>();
  addressStandardizing$: ReplaySubject<boolean> = new ReplaySubject<boolean>()
  unsubscribe$: Subject<void> = new Subject<void>();

  form: FormGroup = new FormGroup({});
  vehicleName: string = '';
  additionalInterestOptions: AdditionalInterestOption[] = [];
  address: Nullable<Partial<AddressModel>>;

  get formAdditionalInterestType(): Nullable<string> {
    return this.form?.get('additionalInterestType')?.value;
  }

  constructor(private vehicleService: VehicleService,
              private componentStore: ComponentStoreService<AdditionalInterestFormState>,
              private activeModal: NgbActiveModal,
              private navigationService: NavigationService) {
    this.members$ = this.vehicleService.getSelectedMembersAndPersonInterests();
    this.companies$ = this.vehicleService.getUniqueAdditionalInterestCompanies();
  }

  ngOnInit() {
    this.vehicleName = `${this.vehicle?.year} ${this.vehicle?.make} ${this.vehicle?.model}`;
    this.additionalInterestOptions = this.getAdditionalInterestOptionsForProduct();
    this.componentStore.initialize({
      interestType: this.getInterestType(),
      displaySelection: this.mode === 'add',
      title: this.getTitle(),
      assocCompany: this.getInterestCompany(),
      assocPerson: this.interest?.person
    });
    this.vm$ = this.componentStore.get();
    this.form = this.buildForm();
    this.enableRelevantControls(this.getInterestType());
    if (this.interest?.address) {
      this.address = { ...this.interest.address };
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.complete();
  }

  onSelectPerson(person: Nullable<Partial<PersonEntity>>): void {
    this.componentStore.update({
      interestType: 'person',
      assocPerson: person,
      displaySelection: false
    });
    this.form.patchValue({person: person});
    this.enableRelevantControls('person');
  }

  onSelectCompany(company: BasicCompanyInformation): void {
    this.componentStore.update({
      interestType: 'company',
      assocCompany: company,
      displaySelection: false,
    });
    this.form.patchValue({companyName: company.company});
    this.enableRelevantControls('company');
  }

  updateInterestType(event: Event): void {
    const interestType: any = GeneralUtils.getEventValue(event);
    this.componentStore.update({interestType});
    this.enableRelevantControls(interestType);
  }

  getInterestAddress(): Partial<Nullable<AddressModel>> {
    return this.interest?.address ? {...this.interest?.address} : null;
  }

  addChildForm(name: string, form: FormGroup): void {
    this.form.setControl(name, form);
  }

  onAddressChange(address: Partial<AddressModel>): void {
    this.address = { ...address };
  }

  closeModal(): void {
    const result: VehicleAdditionalInterestModalResult = {
      action: 'cancel',
      value: this.interest
    }
    this.activeModal.close(result);
  }

  createNewAdditionalInterest(): void {
    this.componentStore.update({
      displaySelection: false,
      interestType: 'person',
    });
  }

  deleteAdditionalInterest(): void {
    const result: VehicleAdditionalInterestModalResult = {
      action: 'delete',
      value: this.interest
    }
    this.activeModal.close(result);
  }

  saveAdditionalInterest(): void {
    const addressControl = this.form.get('address');
    if (addressControl?.touched && !this.isManualAddress(addressControl)) {
      this.addressStandardizing$.pipe(
        takeUntil(this.unsubscribe$),
        filter(standarizing => !standarizing),
        take(1)
      ).subscribe(() => this.submitIfValid())
    } else {
      this.submitIfValid();
    }
  }

  private getInterestType(): Nullable<'person' | 'company'> {
    if (this.interest?.companyName) return 'company';
    if (this.interest?.person) return 'person';
    return undefined;
  }

  private getTitle(): string {
    let titlePrefix: string;
    switch (this.mode) {
      case 'add':
        titlePrefix = 'Add';
        break;
      case 'edit':
        titlePrefix = 'Edit'
        break;
    }
    return `${titlePrefix} additional interest`;
  }

  private getInterestCompany(): Nullable<BasicCompanyInformation> {
    if (this.interest?.companyName) {
      return {
        company: this.interest.companyName,
        address: { ...this.interest.address }
      };
    }
    return undefined;
  }

  private buildForm(): FormGroup {
    return new FormGroup({
      additionalInterestType: new FormControl(this.formatThirdPartyInterestType() || null,
        [Validators.required]),
      person: new FormControl(this.interest?.person || null),
      companyName: new FormControl(this.interest?.companyName || null)
    });
  }

  private formatThirdPartyInterestType(): Nullable<string> {
    const option = ADDITIONAL_INTEREST_OPTIONS.find(option => {
      const typeIsMatch: boolean = option.value.additionalInterestType === this.interest?.additionalInterestType;
      if (this.interest?.thirdPartyDescription) {
        const descriptionIsMatch: boolean = option.value.thirdPartyDescription === this.interest?.thirdPartyDescription;
        return typeIsMatch && descriptionIsMatch;
      }
      return typeIsMatch;
    });

    return option?.displayName;
  }

  private enableRelevantControls(interestType: Nullable<'person' | 'company'>): void {
    FormHelper.enableOrDisable(
      'person',
      interestType === 'person',
      [],
      this.form
    );
    FormHelper.enableOrDisable(
      'companyName',
      interestType === 'company',
      [Validators.required],
      this.form
    );
  }

  private getAdditionalInterestOptionsForProduct(): AdditionalInterestOption[] {
    return ADDITIONAL_INTEREST_OPTIONS.filter(aio => aio.appliedProducts.includes(this.productType));
  }

  private isManualAddress(addressControl: AbstractControl): boolean {
    return addressControl.value.hasOwnProperty('manual');
  }

  private submitIfValid(): void {
    if (this.form.valid) {
      const result: VehicleAdditionalInterestModalResult = {
        action: 'save',
        value: this.formatAdditionalInterestForEmission()
      }
      this.activeModal.close(result);
    } else {
      this.navigationService.submitPage();
    }
  }

  private formatAdditionalInterestForEmission(): VehicleAdditionalInterestEntity {
    const option = ADDITIONAL_INTEREST_OPTIONS.find(option => {
      return option.displayName === this.formAdditionalInterestType;
    });
    const additionalInterest: VehicleAdditionalInterestEntity = {
      additionalInterestId: this.generateRandomId(),
      ...this.interest,
      ...this.form.value,
      additionalInterestType: option?.value.additionalInterestType,
      address: {
        addressLine1: this.address?.addressLine1,
        addressLine2: this.address?.addressLine2,
        city: this.address?.city,
        state: this.address?.state,
        postalCode: this.address?.postalCode
      }
    };
    if (option?.value.thirdPartyDescription) {
      additionalInterest.thirdPartyDescription = option.value.thirdPartyDescription;
    }
    return additionalInterest;
  }

  private generateRandomId(): string {
    return `NEW-${Date.now()}`;
  }
}
