import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LogService } from '@core/services/log.service';
import { SessionService } from '@core/services/session.service';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RefreshGuard {
  constructor(
    private sessionService: SessionService,
    private logService: LogService,
    private router: Router
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): Observable<boolean> {
    return this.sessionService.getQuoteState().pipe(
      take(1),
      map((state: string) => {
        if (state) {
          return true;
        }
        this.logService.logUiEvent('illegal-refresh', routerState.url, 'error');
        this.router.navigateByUrl('/');
        return false;
      })
    );
  }
}
