import { ProductType } from '@core/models/api/dsm-types';
import { createSelector } from '@ngrx/store';

import * as fromForms from '../../reducers';
import * as fromCoverages from './coverage.reducer';

export const getCoverageState = createSelector(
  fromForms.getFormsState,
  (state) => state.coverages
);

export const {
  selectAll: getAllCoverages,
  selectEntities: getCoverageEntities,
  selectTotal: getTotalCoverages,
} = fromCoverages.adapter.getSelectors(getCoverageState);

export const getFormCoveragesByProduct = (productId: ProductType) =>
  createSelector(getCoverageEntities, (entities) => entities[productId]);
