import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { getPageByUrl } from '@core/constants/pages';
import { AppConfigService } from '@core/services/app-config.service';
import { ProductsService } from '@core/services/products.service';
import {
  setEffectiveDate,
  setTargetEnv,
} from '@core/store/entities/dsm/dsm.action';
import {
  Page,
  PageSubCategory,
} from '@core/store/entities/navigation/navigation.action';
import { selectNavigationPath } from '@core/store/entities/navigation/navigation.selector';
import { ProductModel } from '@core/store/entities/product/product.model';
import { Store } from '@ngrx/store';
import { GeneralUtils } from '@shared/utils/general.utils';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, filter } from 'rxjs/operators';
import { DebugModel } from '../debug.model';

@Component({
  selector: 'nwx-debug-overlay',
  templateUrl: './debug-overlay.component.html',
  styleUrls: ['./debug-overlay.component.scss'],
})
export class DebugOverlayComponent implements OnInit, OnDestroy {
  readonly pcEnvs = [
    'pls01',
    'pls02',
    'pls03',
    'pls04',
    'pls05',
    'pls06',
    'pls07',
    'pls08',
    'pls09',
    'pls10',
    'pls11',
    'pls12',
    'pls13',
    'pls14',
    'pls15',
    'pls16',
    'pls17',
    'pls18',
    'pls19',
    'pls44',
    'pls48',
    'pls57',
  ];

  readonly stagePcEnvs = [
    // 'jan24a',
    // 'jan24b',
    // 'feb24a',
    // 'feb24b',
    // 'mar24a',
    // 'mar24b',
    // 'apr24a',
    // 'apr24b',
    // 'may24a',
    // 'may24b',
    // 'jun24a',
    // 'jun24b',
    // 'jul24a',
    // 'jul24b',
    'aug23a',
    'aug23b',
    'sep23a',
    'sep23b',
    // 'oct23a',
    // 'oct23b',
    // 'nov23a',
    // 'nov23b',
    // 'dec23a',
    // 'dec23b',
    'comprater',
  ];

  readonly envNames = {
    test: 'test',
    stage: 'stage',
  };

  readonly nwxEnvs = {
    uat: 'uat',
    deva: 'Alex',
    devb: 'Andy',
    devc: 'Eileen',
    devd: 'Forrest',
    deve: 'Jim',
    devf: 'Joe',
    devg: 'Lydia',
    test1: 'Test 1',
    test2: 'Test 2',
    test3: 'Test 3',
    ProdLike: 'ProdLike',
    tosca: 'Tosca',
  };

  readonly billingEnvs = ['CIBD', 'CIB1', 'CIAA', 'CIBB'];

  readonly TOSCA_DEFAULT_PLSENV = 'pls19';

  open = false;
  alertPcEnvError = false;
  form!: FormGroup;
  currentEnvironment = '';
  currentEnvironmentName = '';
  private unsubscribe$ = new Subject<void>();
  products$!: Observable<ProductModel[]>;
  pages$!: Observable<Page[]>;
  pcEnvironments: string[] = [];

  constructor(
    private appConfigService: AppConfigService,
    private changeDetector: ChangeDetectorRef,
    private store: Store,
    private productService: ProductsService,
    private router: Router
  ) {
    this.currentEnvironment = this.findRouterCookie();
    this.currentEnvironmentName = this.appConfigService.config.environmentName;
  }

  ngOnInit(): void {
    this.products$ = this.productService.getAllProducts();
    this.setPcEnvironments();
    this.form = this.buildForm();
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => this.onChange(value));
    this.readValueFromAppConfigService();
    this.form
      .get('testEnvironment')
      ?.valueChanges.pipe(
        filter((testEnv) => testEnv !== this.currentEnvironment)
      )
      .subscribe((testEnv: string) => {
        if (testEnv === 'uat') {
          this.deleteCookie('aws_router');
        } else {
          this.deleteCookie('aws_router');
          this.setCookie('aws_router', testEnv);
        }
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.href =
          window.location.protocol + '//' + window.location.host + '/';
      });
    this.form
      .get('environmentName')
      ?.valueChanges.pipe(
        filter((envName) => envName !== this.currentEnvironmentName)
      )
      .subscribe((envName: string) => {
        const agencyChannel = window.localStorage.getItem('agencyChannel');
        window.localStorage.clear();
        window.sessionStorage.clear();
        if (agencyChannel) {
          window.localStorage.setItem('agencyChannel', agencyChannel);
        }
        window.sessionStorage.setItem('environmentName', envName);
        window.location.href =
          window.location.protocol + '//' + window.location.host + '/';
      });

    this.pages$ = this.store.select(selectNavigationPath);

    // autofill:
    const routerEventsSubscription = this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => {
          if (!this.appConfigService.config.autofillTarget) {
            return false;
          }
          if (event instanceof NavigationEnd) {
            const page = getPageByUrl(event.urlAfterRedirects);
            return page?.subcategory !== PageSubCategory.GLOBAL;
          }
          return false;
        })
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (
            event.urlAfterRedirects ===
            this.appConfigService.config.autofillTarget
          ) {
            this.appConfigService.autofill = false;
            this.appConfigService.autofillTarget = null;
            routerEventsSubscription.unsubscribe();
          } else {
            const timer = setTimeout(() => {
              const button = document.querySelector(
                'bolt-button-bar bolt-button[type="primary"]'
              ) as HTMLElement;
              if (button) {
                button.click();
                const timer2 = setTimeout(() => {
                  const button = document.querySelector(
                    'nwx-search-accounts-container div div bolt-button-bar bolt-button[slot="cancel"]'
                  ) as HTMLElement;
                  if (button) {
                    button.click();
                  }
                  clearTimeout(timer2);
                }, 1500);
              }
              clearTimeout(timer);
            }, 1000);
          }
        }
      });
  }

  deleteCookie(cookieName: string): void {
    window.document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }

  setCookie(cookieName: string, cookieValue: string): void {
    window.document.cookie = `${cookieName}=${cookieValue}; Max-Age=2147483647`;
  }

  ngOnDestroy(): void {
    this.form.disable();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onToggle(): void {
    this.open = !this.open;
  }

  onChange(value: DebugModel): void {
    this.writeValueToAppConfigService(value);
  }

  onAutofillChange(event: Event): void {
    const pageUrl = GeneralUtils.getEventValue(event);

    this.appConfigService.autofillTarget = pageUrl;
    this.form.patchValue({ autofillTarget: pageUrl, autofill: true });
  }

  buildForm(): FormGroup {
    let returnedProducts: ProductModel[] = [];
    this.products$
      .pipe(take(1))
      .subscribe((products) => (returnedProducts = products));
    return new FormGroup({
      pcEnv: new FormControl(),
      environmentName: new FormControl(),
      billingEnv: new FormControl(),
      ignoreRiskFilter: new FormControl(),
      testEnvironment: new FormControl(),
      products: new FormArray(
        returnedProducts.map((product) => new FormControl(product, []))
      ),
      autofill: new FormControl(),
      autofillTarget: new FormControl(),
      autoApproveUW: new FormControl(),
    });
  }

  private readValueFromAppConfigService(): void {
    const src = this.appConfigService.config;
    const v: DebugModel = {
      testEnvironment: this.currentEnvironment,
      environmentName: src.environmentName,
      pcEnv: src.targetEnv || '',
      billingEnv: src.billingTargetEnv,
      ignoreRiskFilter: !!src.ignoreRiskFilter,
      autofill: src.autofill,
      autofillTarget: src.autofillTarget,
      autoApproveUW: src.autoApproveUW,
    };
    if (this.currentEnvironment === 'tosca') {
      v.pcEnv = this.TOSCA_DEFAULT_PLSENV;
    }
    this.form.patchValue(v);
  }

  updateProducts(product: ProductModel, event: Event): void {
    const target = event.target as HTMLInputElement;
    this.productService.updateProduct({
      type: product.type,
      isAvailable: target.checked,
    });
  }

  get formProducts(): FormArray | null {
    return this.form.get('products') as FormArray;
  }

  private writeValueToAppConfigService(value: DebugModel): void {
    this.appConfigService.billingTargetEnv = value.billingEnv;
    this.appConfigService.ignoreRiskFilter = value.ignoreRiskFilter;
    this.appConfigService.autofill = value.autofill;
    this.appConfigService.autoApproveUW = value.autoApproveUW;

    if (value.pcEnv !== this.appConfigService.config.targetEnv) {
      this.appConfigService.targetEnv = value.pcEnv;
      this.store.dispatch(setTargetEnv({ targetEnv: value.pcEnv }));
      this.appConfigService
        .fetchDate(value.pcEnv)
        .pipe(take(1))
        .subscribe(
          (effectiveDate) => {
            this.setGlobalEffectiveDateFromClocksOutput(effectiveDate);
            this.alertPcEnvError = false;
            this.changeDetector.markForCheck();
          },
          (error) => {
            this.alertPcEnvError = true;
            this.changeDetector.markForCheck();
          }
        );
    }
  }

  private setGlobalEffectiveDateFromClocksOutput(annoying: string): void {
    // The annoying input is formatted like "Sat Jul 16 08:20:41 EDT 2022".
    // Since that originated in Javascript (DSM Clocks proxy), let's assume we can feed it to Date verbatim.
    const date = new Date(annoying);
    // This format is also annoying but that name's in use...
    function pad2(input: number): string {
      return input.toString().padStart(2, '0');
    }
    const mmddyyyy = `${pad2(date.getMonth() + 1)}/${pad2(
      date.getDate()
    )}/${date.getFullYear()}`;
    this.store.dispatch(setEffectiveDate({ effectiveDate: mmddyyyy }));
  }

  findRouterCookie(): string {
    const kv = document.cookie
      .split(';')
      .map((kv1) => kv1.split('='))
      .find(([key, value]) => key.trim() === 'aws_router');

    if (kv && kv.length > 0) {
      return kv[1];
    } else {
      return 'uat';
    }
  }

  showEnvName(): boolean {
    if (['stage', 'test'].includes(this.currentEnvironmentName)) {
      return true;
    }
    return false;
  }

  setPcEnvironments(): void {
    if (this.currentEnvironmentName === 'stage') {
      this.pcEnvironments = this.stagePcEnvs;
    } else {
      this.pcEnvironments = this.pcEnvs;
    }
  }
}
