import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import {
  CoverageActions,
  RetrieveActions,
  SessionActions,
} from '@core/store/actions';

import {
  CoverageChange,
  CoverageEntity,
} from '@core/store/entities/coverage/coverage.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';

export interface CoverageState extends EntityState<CoverageEntity> {
  error: ErrorModel;
  loaded: boolean;
  loading: number;
}

export const adapter: EntityAdapter<CoverageEntity> =
  createEntityAdapter<CoverageEntity>({
    selectId: (coverage) =>
      `${coverage.productId}_${coverage.version}_${coverage.coverageId}${
        coverage.coverableId ? `_${coverage.coverableId}` : ''
      }`,
  });

const initialState = adapter.getInitialState({
  error: {},
  loaded: false,
  loading: 0,
});

const CoverageReducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(CoverageActions.getAllCoverages, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(CoverageActions.getAllCoveragesSuccess, (state, { payload }) =>
    adapter.addMany(payload, {
      ...state,
      loading: state.loading > 0 ? state.loading - 1 : 0,
      loaded: true,
      error: {},
    })
  ),
  on(CoverageActions.getAllCoveragesFail, (state, { error }) => ({
    ...state,
    error,
    loading: state.loading - 1,
    loaded: true,
  })),
  // on(CoverageActions.addCoverageSuccess, (state, { payload }) =>
  //   adapter.addOne(payload, { ...state })
  // ),
  on(CoverageActions.addManyCoverages, (state, { payload }) =>
    adapter.addMany(payload, { ...state })
  ),
  on(CoverageActions.addCoverageError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(CoverageActions.updateCoverage, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(
    CoverageActions.updateCoverageSuccess,
    (state, { payload, updatedCoverage }) => ({
      ...state,
      loading: state.loading - 1,
    })
  ),
  on(CoverageActions.updateCoverageError, (state, { error }) => {
    return {
      ...state,
      error,
      loading: state.loading - 1,
      loaded: false,
    };
  }),
  on(CoverageActions.deleteCoverageSuccess, (state, { payload }) =>
    adapter.removeOne(payload, { ...state })
  ),
  on(CoverageActions.deleteCoveragesByProduct, (state, { payload }) => {
    const keys = (state.ids as string[])
      .map((id) => {
        return { id, entity: state.entities[id] };
      })
      .filter((e) => e?.entity?.productId === payload)
      .map((e) => e.id);
    return adapter.removeMany(keys, state);
  }),
  on(CoverageActions.deleteCoverageError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(CoverageActions.pokeCoverageEntityStore, (state) => {
    return { ...state };
  })
);

export function reducer(
  state: CoverageState | undefined,
  action: Action
): CoverageState {
  return CoverageReducer(state, action);
}
