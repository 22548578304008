import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccountRegistrationModule } from '../account-registration.module';
import { AccountRegistrationContainerComponent } from './account-registration-container.component';

@NgModule({
  declarations: [AccountRegistrationContainerComponent],
  imports: [CommonModule, AccountRegistrationModule],
  exports: [AccountRegistrationContainerComponent],
})
export class AccountRegistrationContainerModule {}
