import { Injectable } from '@angular/core';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, exhaustMap, filter, mergeMap, take } from 'rxjs/operators';
import { Observable, combineLatest, from, of } from 'rxjs';

import {
  CompositeActions,
  UmbrellaActions,
  UnderlyingPolicyActions,
  WatercraftExcludedExposureActions,
  WatercraftExposureActions,
} from '@core/store/actions';
import {
  WatercraftExcludedExposureSelectors,
  WatercraftExposureSelectors,
} from '@core/store/selectors';
import { WatercraftExposureService } from '@core/services/watercraft-exposure.service';

@Injectable()
export class WatercraftExposureEffect {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: WatercraftExposureService,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  upsertWatercraftExposure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WatercraftExposureActions.upsertWatercraftExposure),
        mergeMap((action) =>
          this.store
            .select(
              WatercraftExposureSelectors.buildWatercraftExposureRequest(
                action.payload,
                action.correlationId || ''
              )
            )
            .pipe(take(1))
        ),
        mergeMap((request) => {
          return this.service.addOrUpdateWatercraftExposure(request).pipe(
            mergeMap((response) => {
              return from([
                WatercraftExposureActions.upsertWatercraftExposureSuccess({
                  payload: response,
                }),
              ]);
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                request.productType
              );
              return of(
                WatercraftExposureActions.upsertWatercraftExposureFail({
                  error: saneError,
                  entityId:
                    request.watercraftExposureBody.watercraftExposure
                      .watercraftExposureId,
                })
              );
            })
          );
        })
      ) as Observable<Action>
  );

  deleteWatercraftExposure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WatercraftExposureActions.deleteWatercraftExposure),
        mergeMap((action) =>
          this.store
            .select(
              WatercraftExposureSelectors.buildWatercraftExposureRequest(
                action.payload,
                action.correlationId || ''
              )
            )
            .pipe(take(1))
        ),
        mergeMap((request) => {
          return this.service.deleteWatercraftExposure(request).pipe(
            mergeMap(() => {
              return from([
                WatercraftExposureActions.deleteWatercraftExposureSuccess({
                  watercraftExposureId:
                    request.watercraftExposureBody.watercraftExposure
                      .watercraftExposureId,
                }),
              ]);
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                request.productType
              );
              return of(
                WatercraftExposureActions.deleteWatercraftExposureFail({
                  error: saneError,
                  entityId:
                    request.watercraftExposureBody.watercraftExposure
                      .watercraftExposureId,
                })
              );
            })
          );
        })
      ) as Observable<Action>
  );

  restoreWatercraftExposure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UmbrellaActions.restoreExposures),
      exhaustMap(() =>
        combineLatest([
          this.store.select(
            WatercraftExposureSelectors.getDeletedWatercraftExposuresWithActivePolicy
          ),
          this.store.select(
            WatercraftExcludedExposureSelectors.getDeletedWatercraftExcludedExposures
          ),
        ]).pipe(take(1))
      ),
      mergeMap(([exposures, excludedExposures]) => {
        const exposureActions: Action[] = [];
        exposures.forEach((exposure) => {
          exposureActions.push(
            WatercraftExposureActions.upsertWatercraftExposure({
              payload: {
                ...exposure,
                watercraftExposureId: '',
              },
            })
          );
          exposureActions.push(
            WatercraftExposureActions.completelyRemoveWatercraftExposure({
              watercraftExposureId: exposure.watercraftExposureId,
            })
          );
        });
        excludedExposures.forEach((exposure) => {
          exposureActions.push(
            WatercraftExcludedExposureActions.upsertWatercraftExcludedExposure({
              payload: {
                ...exposure,
                watercraftExcludedExposureId: '',
              },
            })
          );
          exposureActions.push(
            WatercraftExcludedExposureActions.completelyRemoveWatercraftExcludedExposure(
              {
                watercraftExcludedExposureId:
                  exposure.watercraftExcludedExposureId,
              }
            )
          );
        });
        return exposureActions;
      })
    )
  );

  buildAccompanyingWatercraftExposures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnderlyingPolicyActions.buildAccompanyingExposures),
      filter((action) => action.productType === 'Boat'),
      exhaustMap((action) =>
        this.store
          .select(WatercraftExposureSelectors.buildWatercraftExposures)
          .pipe(take(1))
      ),
      exhaustMap((watercraftExposures) => {
        const watercraftExposuresArray: Action[] = [];
        watercraftExposures.forEach((exposure) => {
          watercraftExposuresArray.push(
            WatercraftExposureActions.upsertWatercraftExposure({
              payload: {
                ...exposure,
                watercraftExposureId: '',
              },
            })
          );
        });
        return from(watercraftExposuresArray);
      })
    )
  );

  cascadeDeleteWatercraftExposure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UnderlyingPolicyActions.deleteUnderlyingPolicySuccess),
        exhaustMap((action) =>
          this.store
            .select(
              WatercraftExposureSelectors.getWatercraftExposuresByUnderlyingPolicyNumber(
                action.underlyingPolicyNumber
              )
            )
            .pipe(take(1))
        ),
        exhaustMap((watercraftExposures) => {
          return watercraftExposures.map((exposure) => {
            return WatercraftExposureActions.deleteWatercraftExposureSuccess({
              watercraftExposureId: exposure.watercraftExposureId,
            });
          });
        })
      ) as Observable<Action>
  );

  cascadeRestoreWatercraftExposures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.restoreExposure),
      exhaustMap((action) =>
        this.store
          .select(
            WatercraftExposureSelectors.getDeletedWatercraftExposuresByUnderlyingPolicyNumber(
              action.payload.underlyingPolicyNumber
            )
          )
          .pipe(take(1))
      ),
      exhaustMap((watercraftExposures) => {
        const watercraftExposuresArray: Action[] = [];
        watercraftExposures.forEach((exposure) => {
          watercraftExposuresArray.push(
            WatercraftExposureActions.upsertWatercraftExposure({
              payload: {
                ...exposure,
                watercraftExposureId: '',
              },
            })
          );
          watercraftExposuresArray.push(
            WatercraftExposureActions.completelyRemoveWatercraftExposure({
              watercraftExposureId: exposure.watercraftExposureId,
            })
          );
        });
        return from(watercraftExposuresArray);
      })
    )
  );
}
