import { ProductType } from '@core/models/api/dsm-types';
import { createSelector } from '@ngrx/store';
import { ProductModel } from '../entities/product/product.model';
import { getSelectedProducts } from '../entities/product/product.selectors';

export interface BusinessLoggingContent {
  selectedProducts: ProductType[];
  // Really it's [id: ProductType] but TS doesn't like that:
  quoteIds: { [id: string]: string };
  policyNumbers: { [id: string]: string };
}

function formatBusinessContent(
  selectedProducts: ProductModel[]
  // TODO Other things will surely be wanted here. Waiting for Josh's opinion.
): BusinessLoggingContent {
  const quoteIds: { [id: string]: string } = {};
  const policyNumbers: { [id: string]: string } = {};
  selectedProducts.forEach((product) => {
    if (product.quoteId) {
      quoteIds[product.type] = product.quoteId;
    }
    if (product.policyNumber) {
      policyNumbers[product.type] = product.policyNumber;
    }
  });
  return {
    selectedProducts: selectedProducts.map((product) => product.type),
    quoteIds,
    policyNumbers,
  };
}

export const getInitiateResultsForBusinessLog = createSelector(
  getSelectedProducts,
  (selectedProducts) => {
    if (!selectedProducts?.length) {
      return null;
    }
    let validCount = 0;
    for (const product of selectedProducts) {
      if (product.hasError) {
        continue;
      }
      if (!product.quoteStatus) {
        return null;
      }
      if (product.quoteStatus === 'NotStarted') {
        return null;
      }
      if (product.quoteStatus === 'Pending') {
        return null;
      }
      validCount++;
    }
    if (!validCount) {
      return null;
    }
    return formatBusinessContent(selectedProducts);
  }
);

export const getRc1ResultsForBusinessLog = createSelector(
  getSelectedProducts,
  (selectedProducts) => {
    if (!selectedProducts?.length) {
      return null;
    }
    let validCount = 0;
    for (const product of selectedProducts) {
      if (product.hasError) {
        continue;
      }
      if (!product.quoteStatus) {
        return null;
      }
      if (product.quoteStatus === 'NotStarted') {
        return null;
      }
      if (product.quoteStatus === 'Pending') {
        return null;
      }
      if (product.quoteStatus === 'Draft') {
        return null;
      }
      validCount++;
    }
    if (!validCount) {
      return null;
    }
    return formatBusinessContent(selectedProducts);
  }
);

export const getRc2ResultsForBusinessLog = createSelector(
  getSelectedProducts,
  (selectedProducts) => {
    if (!selectedProducts?.length) {
      return null;
    }
    let validCount = 0;
    for (const product of selectedProducts) {
      if (product.hasError) {
        continue;
      }
      if (!product.quoteStatus) {
        return null;
      }
      if (product.quoteStatus === 'NotStarted') {
        return null;
      }
      if (product.quoteStatus === 'Pending') {
        return null;
      }
      if (product.quoteStatus === 'Draft') {
        return null;
      }
      if (product.quoteStatus === 'Quoted') {
        return null;
      }
      validCount++;
    }
    if (!validCount) {
      return null;
    }
    return formatBusinessContent(selectedProducts);
  }
);

export const getIssueResultsForBusinessLog = createSelector(
  getSelectedProducts,
  (selectedProducts) => {
    if (!selectedProducts?.length) {
      return null;
    }
    let validCount = 0;
    for (const product of selectedProducts) {
      if (product.hasError) {
        continue;
      }
      if (product.quoteStatus !== 'Issued') {
        return null;
      }
      validCount++;
    }
    if (!validCount) {
      return null;
    }
    return formatBusinessContent(selectedProducts);
  }
);
