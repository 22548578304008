import { Injectable } from '@angular/core';
import { BillingAdapter } from '@core/adapters/billing.adapter';
import { ProductType } from '@core/models/api/dsm-types';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { EscrowService } from '@core/services/escrow.service';
import { ProductsService } from '@core/services/products.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { EscrowAccountActions } from '../../actions';

@Injectable({
  providedIn: 'root',
})
export class EscrowEffects {
  constructor(
    private actions$: Actions,
    private billingAdapter: BillingAdapter,
    private escrowService: EscrowService,
    private productsService: ProductsService,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  establishAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EscrowAccountActions.establishEscrowAccount),
      switchMap((action) =>
        this.productsService.getProduct(action.productType).pipe(take(1))
      ),
      switchMap((product) =>
        this.escrowService
          .buildEstablishAccountRequest(product?.type as ProductType)
          .pipe(
            take(1),
            map((request) => ({ request, product }))
          )
      ),
      switchMap(({ request, product }) =>
        this.billingAdapter.establishEscrowAccount(request).pipe(
          map((response) =>
            EscrowAccountActions.establishEscrowAccountSuccess({
              payload: response,
            })
          ),
          catchError((error) =>
            from([
              EscrowAccountActions.establishEscrowAccountFailure({
                error: this.errorSanitizerService.sanitizeError(error, product?.type),
              }),
            ])
          )
        )
      )
    )
  );
}
