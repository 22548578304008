export class StateUtils {
  static readonly stateCodeByState: { [key: string]: string } = {
    AL: '01',
    AZ: '02',
    AR: '03',
    CA: '04',
    CO: '05',
    CT: '06',
    DE: '07',
    DC: '08',
    FL: '09',
    GA: '10',
    ID: '11',
    IL: '12',
    IN: '13',
    IA: '14',
    KS: '15',
    KY: '16',
    LA: '17',
    ME: '18',
    MD: '19',
    MA: '20',
    MI: '21',
    MN: '22',
    MS: '23',
    MO: '24',
    MT: '25',
    NE: '26',
    NV: '27',
    NH: '28',
    NJ: '29',
    NM: '30',
    NY: '31',
    NC: '32',
    ND: '33',
    OH: '34',
    OK: '35',
    OR: '36',
    PA: '37',
    RI: '38',
    SC: '39',
    SD: '40',
    TN: '41',
    TX: '42',
    UT: '43',
    VT: '44',
    VA: '45',
    WA: '46',
    WV: '47',
    WI: '48',
    WY: '49',
    HI: '52',
    AK: '54',
    PR: '58',
  };

  static stateAbbreviationFromNumericCode(code: string): string | undefined {
    const entry = Object.entries(StateUtils.stateCodeByState).find(
      ([key, value]) => value === code
    );

    if (entry) {
      return entry[0];
    } else {
      return undefined;
    }
  }
}
