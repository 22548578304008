import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../error/error.model';
import { WatercraftExcludedExposureEntity } from './watercraft-excluded-exposures.entity';

export const upsertWatercraftExcludedExposure = createAction(
  '[Watercraft Excluded Exposure] Upsert Watercraft Excluded Exposure',
  props<{ payload: WatercraftExcludedExposureEntity; correlationId?: string }>()
);

export const upsertWatercraftExcludedExposureSuccess = createAction(
  '[Watercraft Excluded Exposure] Upsert Watercraft Excluded Exposure Success',
  props<{ payload: WatercraftExcludedExposureEntity; correlationId?: string }>()
);

export const upsertWatercraftExcludedExposureFail = createAction(
  '[Watercraft Excluded Exposure] Upsert Watercraft Excluded Exposure Fail',
  props<{
    error: ErrorModel;
    correlationId?: string;
    entityId?: string;
  }>()
);

export const deleteWatercraftExcludedExposure = createAction(
  '[Watercraft Excluded Exposure] Delete Watercraft Excluded Exposure',
  props<{ payload: WatercraftExcludedExposureEntity; correlationId?: string }>()
);

export const deleteWatercraftExcludedExposureSuccess = createAction(
  '[Watercraft Excluded Exposure] Delete Watercraft Excluded Exposure Success',
  props<{
    watercraftExcludedExposureId: string;
    correlationId?: string;
  }>()
);

export const deleteWatercraftExcludedExposureFail = createAction(
  '[Watercraft Excluded Exposure] Delete Watercraft Excluded Exposure Fail',
  props<{
    error: ErrorModel;
    correlationId?: string;
    entityId?: string;
  }>()
);

export const completelyRemoveWatercraftExcludedExposure = createAction(
  '[Watercraft Excluded Exposure] Completely Remove Watercraft Excluded Exposure',
  props<{ watercraftExcludedExposureId: string }>()
);
