import {
  Component,
  ChangeDetectionStrategy,
  ContentChildren,
  QueryList,
  AfterContentInit,
  TemplateRef,
  Directive,
  ChangeDetectorRef,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[nwxCard]',
})
export class CardListItemDirective {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public readonly template: TemplateRef<any>) {}
}

/**
 * Due to some wonkiness with angular content projection, its a little bit overly complicated to use this when the inner content is dynamic.
 * If you have a dynamic list of cards, do this.
 *
 * <nwx-card-list>
 *   <ng-container *ngFor="thing of myThings; let i = index">
 *     <ng-template nwxCard>
 *       <nwx-card>
 *         <h3 header>My Card Header {{i}}</h3>
 *         <nwx-my-inner-form
 *           [thing]="thing"
 *         ></nwx-my-inner-form>
 *       </nwx-card>
 *     </ng-template>
 *   </ng-container>
 * </nwx-card-list>
 *
 */
@Component({
  selector: 'nwx-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardListComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(CardListItemDirective, { descendants: false })
  cards!: QueryList<CardListItemDirective>;

  @Input() hideList: number[] = [];
  @Input() unselectedList!: number[];
  @Input() cardClass: string = 'bolt-col-md-6';
  @Input() flexStyles!: boolean;
  @Input() idDiscriminator: string = '';

  teardown$: Subject<void> = new Subject<void>();

  constructor(private cd: ChangeDetectorRef) {}
  ngAfterContentInit(): void {
    this.cards.changes
      .pipe(takeUntil(this.teardown$))
      .subscribe((_) => this.cd.detectChanges());
  }

  ngOnDestroy(): void {
    this.teardown$.next();
    this.teardown$.complete();
  }
}
