import { ProductType } from '@core/models/api/dsm-types';

export interface DiscountInformation {
  discountName?: string;
  helpText?: string;
}
export const DiscountHelpText: {
  [key in ProductType | 'PolicyLevel']?: DiscountInformation[];
} = {
  PersonalAuto: [
    {
      discountName: 'Accident Free',
      helpText:
        'Applied to drivers who have five years or more verifiable driving experience, driver is free from major violations in the most recent five years and free of any chargeable accidents in the most recent five years. Available in all states except CA.',
    },
    {
      discountName: 'Accident Prevention',
      helpText:
        'Applied to eligible drivers who have taken an accident prevention course.',
    },
    {
      discountName: 'Advance Quote',
      helpText:
        'Applied to policies bound at least eight days prior to the current policy effective date. Not available in CA and NY.',
    },
    {
      discountName: 'Anti-Lock Brake',
      helpText:
        'Applied for vehicles equipped with anti-lock brakes in eligible states. Available in FL and NY only.',
    },
    {
      discountName: 'Anti-Theft',
      helpText:
        'Applied if a vehicle has equipment designed to inhibit the theft of the vehicle or its components in eligible states.  Available in FL, KY, IL, MN, NM and NY only.',
    },
    {
      discountName: 'Auto Financial Discount',
      helpText:
        'Applied for policyholders when we also provide coverage on a Life or Annuity policy.',
    },
    {
      discountName: 'Car Pool Discount',
      helpText:
        'Applied when driver is participating in an employer-sponsored Travelink Traffic Mitigation Program.  Proof of participation and annual recertification may be required for qualification.  Available in DE only.',
    },
    {
      discountName: 'Daytime Running Lamps',
      helpText:
        'Applied for vehicles equipped with daytime running lamps in eligible states. Available in NY only.',
    },
    {
      discountName: 'EasyPay',
      helpText:
        'A $30.00 one time discount available to customers who sign up for recurring EFT for the first time. The discount applies to the first eligible vehicle listed on the policy and will be removed after one policy period on the first subsequent renewal. Available in all states except CA, NY and WA.',
    },
    {
      discountName: 'Good Driver',
      helpText:
        'Applied to the vehicle the qualifying driver is assigned to. Available in CA only.',
    },
    {
      discountName: 'Good Student Discount',
      helpText:
        'Applied if a driver is under age 25, a full-time high school student or college student and meets certain academic requirements.',
    },
    {
      discountName: 'Group Membership',
      helpText:
        'Applied based on affiliation with member (Affinity) groups. Available in MI only.',
    },
    {
      discountName: 'Group Occupation',
      helpText:
        'Applied if the Primary Named Insured or Spouse is a member of one of the qualifying professional groups. Available in CA only.',
    },
    {
      discountName: 'New Vehicle Discount',
      helpText:
        'Applied only to Private Passenger Autos with model years within the most recent five years. Available in all states except CA and WA.',
    },
    {
      discountName: 'Paperless Policy Discount',
      helpText:
        'A recurring 5% discount for customers who choose to receive their policy via on-line account. Available in all states except in CA and WA.',
    },
    {
      discountName: 'Passive Restraint',
      helpText:
        'Applied to a vehicle equipped with full front seat airbags that meet federal or appropriate equivalent standards. A lesser discount applies to a driver side only airbag in eligible states.',
    },
    {
      discountName: 'Resident Student Discount - California Only',
      helpText:
        'Applied when an unmarried driver is a student residing at an educational institution over 100 road miles from the garaging location of the vehicle(s) whose driving experience is between 0 to 8 years. Driver cannot be the principal driver on the primary vehicle.',
    },
    {
      discountName: 'Resident Student Discount',
      helpText:
        'Applied when an unmarried driver under the age of 25 is a student residing at an education institution over 100 road miles from the garaging location of the vehicle(s). Available in all states except NC. For California, please see alternate explanation entitled: "Resident Student Discount - CA Only" since discount is defined differently.',
    },
    {
      discountName: 'Select Discount',
      helpText:
        'Applied to premium, when customer previously had Nationwide Insurance, and has had consecutive terms with current carrier. Available in all states except in CA and NY.',
    },
    {
      discountName: 'Self Reported Verified Mileage Program',
      helpText:
        'Applied for vehicles that verify annual mileage by providing vehicle odometer photos when the vehicle is first enrolled in the program and at each subsequent renewal. Available in CA only.',
    },
    {
      discountName: 'SmartRide Connected Car',
      helpText:
        'The SmartRide Connected Car program enables vehicles to be rated with a discount on new business. The discount will apply as long as vehicle remains on the policy under the SmartRide Connected Car program.',
    },
  ],
  PersonalUmbrella: [],
  Boat: [
    {
      discountName: 'Multi-Boat Discount',
      helpText: `The discount is applied when the policyholder insures two or more boats within the same household are listed on any Nationwide RV policy.  The discount amount may increase based on the number of boats. The discount does not apply to personal watercrafts or any motor only rating units.`,
    },
    {
      discountName: 'Prior Insurance Discount',
      helpText:
        'The discount is applied based on whether home/car discount applies to the policy, policy tenure and years with prior insurance input on the policy.',
    },
    {
      discountName: 'Homeowner Discount',
      helpText:
        'The discount is applied when the named insured owns a Home or Condominium, or Farm, or Non-Nationwide Home, Non-Nationwide Condo, Non-Nationwide Farm policy.',
    },
    {
      discountName: 'Paid In Full Discount',
      helpText:
        'The discount is applied when the policy premium is paid in full, and a Paid in Full bill plan is selected.',
    },
    {
      discountName: 'Safety Course Discount',
      helpText:
        'The discount is applied when the listed driver has successfully completed a watercraft safety course. Other factors that impact discount are course completion date, driver age and state.',
    },
    {
      discountName: 'Diesel Fuel Discount',
      helpText: 'The discount is applied to boats powered with diesel fuel.',
    },
    {
      discountName: 'Advance Quote',
      helpText:
        'The discount is applied to new Business policies when a quote is entered at least eight days prior to the policy effective date and where credit is ordered.',
    },
    {
      discountName: 'Accident Prevention Course Discount',
      helpText:
        'The discount is applied to eligible drivers who have taken an accident prevention course.',
    },
    {
      discountName: 'Boating Experience Discount',
      helpText:
        'The discount applies based on the number of years of boating experience. The discount is calculated at driver level.',
    },
  ],
  Condominium: [
    {
      // BEGIN OF Condominium
      discountName: 'Age Of Construction',
      helpText: 'Applied based on the age of the house.',
    },
    {
      discountName: 'Age of Insured',
      helpText: 'Applied based on a person’s age.',
    },
    {
      discountName: 'Claim Free',
      helpText:
        'Applied if the insured hasn’t filed any claims. Available in all states except NY.',
    },
    {
      discountName: 'Farm Bureau',
      helpText:
        'Applied if the insured is a voting member in the bureau (Only for states that offer Farm Bureau).',
    },
    {
      discountName: 'Gated Community',
      helpText: 'Applied if home is in a qualified gated community.',
    },
    {
      discountName: 'Good Payer',
      helpText: `Possible applied discount based on the insured's policy tenure, months since last record of nonsufficient funds, number of warning notices for late pay, number of mortgages and the presence of cancellation notices in the last year`,
    },
    {
      discountName: 'Group Membership',
      helpText:
        'Applied based on affiliation with member (Affinity) groups. Available in MI only.',
    },
    {
      discountName: 'Home and Car (Multi-Policy Discount)',
      helpText: 'Applied if a personal Auto policy is also provided.',
    },
    {
      discountName: 'Home Purchase',
      helpText:
        'Applied if the house was purchased no more than 13 months prior to policy effective date.',
    },
    {
      discountName: 'Home Renovation',
      helpText:
        'Applied based on the difference between the year renovated and the effective year of the policy. Available in MN only.',
    },
    {
      discountName: 'Marital Status (Personal Status)',

      helpText: 'Applied if the marital status is married or widowed.',
    },
    {
      discountName: 'Protective Devices',
      helpText:
        'Applied based on the type of protective device selected on the policy.',
    },
    {
      discountName: 'Roof Rating Factor',
      helpText:
        'Applied based on the roof type, renovation year, UL class and thickness.',
    },
  ],
  DwellingFire: [],
  Homeowner: [
    {
      // BEGIN OF HOMEOWNERS
      discountName: 'Age Of Construction',
      helpText: 'Applied based on the age of the house.',
    },
    {
      discountName: 'Age of Insured',
      helpText: 'Applied based on a person’s age.',
    },
    {
      discountName: 'Claim Free',
      helpText:
        'Applied if the insured hasn’t filed any claims. Available in all states except NY.',
    },
    {
      discountName: 'Farm Bureau',
      helpText:
        'Applied if the insured is a voting member in the bureau (Only for states that offer Farm Bureau).',
    },
    {
      discountName: 'Fortified Home',
      helpText:
        'When the Homeowners policy covers the peril of Windstorm or Hail, a risk may be eligible for a premium credit.  Available in all states except FL.',
    },
    {
      discountName: 'Gated Community',
      helpText: 'Applied if home is in a qualified gated community.',
    },
    {
      discountName: 'Good Payer',
      helpText: `Possible applied discount based on the insured's policy tenure, months since last record of nonsufficient funds, number of warning notices for late pay, number of mortgages and the presence of cancellation notices in the last year`,
    },
    {
      discountName: 'Group Membership',
      helpText:
        'Applied based on affiliation with member (Affinity) groups. Available in MI only.',
    },
    {
      discountName: 'Home and Car (Multi-Policy Discount)',
      helpText: 'Applied if a personal Auto policy is also provided.',
    },
    {
      discountName: 'Home Purchase',
      helpText:
        'Applied if the house was purchased no more than 13 months prior to policy effective date.',
    },
    {
      discountName: 'Home Renovation',
      helpText:
        'Applied based on the difference between the year renovated and the effective year of the policy.',
    },
    {
      discountName: 'Marital Status (Personal Status)',
      helpText: 'Applied if the marital status is married or widowed.',
    },

    {
      discountName: 'Prior Insurance',
      helpText:
        'Applied based on whether home/car discount applies to the policy, policy tenure and years with prior insurance input on the policy. Available in all states except NY.',
    },
    {
      discountName: 'Protective Devices',
      helpText:
        'Applied based on the type of protective device selected on the policy.',
    },
    {
      discountName: 'Roof Rating Factor',
      helpText:
        'Applied based on the roof type, renovation year, UL class and thickness.',
    },
  ],
  Life: [],
  MSA: [
    {
      discountName: 'Multi-Vehicle Discount',
      helpText:
        'The Multi-Vehicle discount applies when two or more vehicles are on any motorcycle policy in the household. Utility trailer is not counted as a qualifying vehicle.',
    },
    {
      discountName: 'Prior Insurance Discount',
      helpText:
        'The Prior Insurance discount may be added at new business policy when the insured has had continuous motorcycle coverage with a non-Nationwide company for the preceding six-month period.',
    },
    {
      discountName: 'Homeowner Discount',
      helpText:
        'The Homeowner discount applies when the named insured owns a Home or Condominium. The discount can be applied in addition to the Multi-Line Discount.',
    },

    {
      discountName: 'Paid in Full Discount',
      helpText:
        'The Paid in Full discount will be applied when the policy premium is paid in full and a Paid in Full bill plan is selected.',
    },
    {
      discountName: 'Safety Course Discount',
      helpText:
        'This discount applies if any driver has taken a Motorcycle Safety Foundation (MSF) or other state-approved safety course for motorcycles, ATVs, or snowmobiles.',
    },
    {
      discountName: 'Riding Association Discount',
      helpText:
        'Applies if any driver is a member of an approved Riding Association.',
    },
    {
      discountName: 'Anti-Lock Brakes (ABS) Discount',
      helpText:
        "This discount applies if the vehicle is equipped with a factory installed two- or three-wheel Anti-Lock Braking System (ABS). Discount applies to On/Off Road Motorcycles and doesn't apply to Snowmobiles, Garden Tractors, or Trailers. ",
    },
    {
      discountName: 'Anti-Theft Discount',
      helpText:
        'Discount applies if vehicle has passive or active anti-theft system or device permanently installed, either as original equipment from the manufacturer or as aftermarket equipment on the vehicle, that is designed to inhibit the theft of the vehicle or its components.',
    },
    {
      discountName: 'Advance Quote Discount',
      helpText:
        'It applies to new Business policies when a quote is entered at least eight days prior to the policy effective date and where credit is ordered. This discount does not apply to Intra-Agency Transfer policies.',
    },
    {
      discountName: 'Accident Prevention Discount',
      helpText:
        'Applied to eligible drivers who have taken an accident prevention course.',
    },
    {
      discountName: 'Three Year Accident-Free Discount',
      helpText:
        'Discount is applied in Maryland, if the driver is free from chargeable traffic accidents and has no more than one minor violation for the most recent 35 months.',
    },
    {
      discountName: 'Good Student Discount',
      helpText:
        'Applied if a driver is under age 25, a full-time high school student or college student   and meets certain academic requirements.',
    },
    {
      discountName: 'Safe Driver Discount',
      helpText:
        'Applies if Driver is free of minor and major and serious violations within the last 35 months.',
    },
    {
      discountName: 'Auxiliary Lighting System Discount',
      helpText:
        'Discount eligibility determined by equipment permanently installed on snowmobile in MN state. Discount in not available for any other vehicle types or states.',
    },
    {
      discountName: 'Driver Training Discount',
      helpText:
        'South Carolina offers Driver Training Discount if driver is less than 25 years old and has completed an appropriate training course.',
    },
  ],
  Pet: [],
  RV: [
    {
      discountName: 'Multi-Vehicle Discount',
      helpText:
        'The discount is applied when 2 or more vehicles (Motorhomes or Travel Trailers) within the same household are listed on any Nationwide RV policy.',
    },
    {
      discountName: 'Prior Insurance Discount',
      helpText:
        'The discount is applied based on whether home/car discount applies to the policy, policy tenure and years with prior insurance input on the policy.',
    },
    {
      discountName: 'Homeowner Discount',
      helpText:
        'The discount is applied when the named insured owns a Home or Condominium, or Farm, or Non-Nationwide Home, Non-Nationwide Condo, Non-Nationwide Farm policy.',
    },
    {
      discountName: 'Paid In Full Discount',
      helpText:
        'The discount is applied when the policy premium is paid in full, and a Paid in Full bill plan is selected.',
    },
    {
      discountName: 'Safety Course Discount',
      helpText:
        'The discount is applied when the listed driver has successfully completed a safety course. Other factors that impact discount are course completion date, driver age and state.',
    },
    {
      discountName: 'RV Association Discount',
      helpText:
        'The discount is applied when any listed driver is a member of one or more Nationwide approved associations.',
    },
    {
      discountName: 'Good Driver Discount',
      helpText:
        'The discount is applied when a listed driver on the policy does not have any major violations or incidents in the previous 35 months. For NC state, the duration is 36 months.',
    },
    {
      discountName: 'Safety Device Discount',
      helpText:
        'The discount is applied when the insured has a safety device permanently installed.',
    },
    {
      discountName: 'Fiberglass Construction Discount',
      helpText:
        'The discount is applied when the exterior of a Travel trailer is made from Fiberglass.',
    },
    {
      discountName: 'Automatic Tire Pressure Monitoring System Discount',
      helpText:
        'The discount is applied when the vehicle is equipped with an automatic tire pressure monitoring system.',
    },
    {
      discountName: 'Advance Quote Discount',
      helpText:
        'The discount is applied to new Business policies when a quote is entered at least eight days prior to the policy effective date and where credit is ordered.',
    },
    {
      discountName: 'Accident Prevention Course Discount',
      helpText:
        'The discount is applied to eligible drivers who have taken an accident prevention course.',
    },
    {
      discountName: 'Driver Training',
      helpText:
        'The discount is applied in the state of South Carolina when a driver under the age of 25 completes a driver training course.',
    },
    {
      discountName: 'Good Student Discount',
      helpText:
        'The discount is applied if the driver is under age 25 and a full-time high school student or enrolled as a full-time student in college or university and meets certain academic standards.',
    },
    {
      discountName: 'Anti-Theft Discount',
      helpText:
        'The discount applied if a vehicle has equipment designed to inhibit the theft of the vehicle or its components in eligible states.',
    },
    {
      discountName: 'Work Loss Discount',
      helpText:
        'The discount is applied in the state of Michigan if the insured driver is 60 years old or greater, and has no expectation of actual income loss, and chooses to waive the Work loss portion of PIP.  Non-Relatives are not eligible for this discount/',
    },
    {
      discountName: 'Daytime Running Lamps',
      helpText:
        'The discount is applied in the state of New York when the insured motorhome has Daytime Running Lamps.',
    },
    {
      discountName: 'Passive Occupant Restraint Discount',
      helpText:
        'The discount is applied for any vehicles equipped with full front seat air bags that meet federal or appropriate equivalent standards.',
    },
    {
      discountName: 'Anti-Lock Brakes (ABS)',
      helpText:
        'The discount is applied if the vehicle is equipped with a factory installed two or four wheel Anti-Lock Braking System',
    },
    {
      discountName: 'Senior Discount',
      helpText:
        'The discount is applied in the state of Michigan for drivers who are 65 and older.',
    },
  ],
  Tenant: [
    {
      // BEGIN OF HOMEOWNERS
      discountName: 'Age Of Construction',
      helpText: 'Applied based on the age of the house.',
    },
    {
      discountName: 'Age of Insured',
      helpText: 'Applied based on a person’s age.',
    },
    {
      discountName: 'Claim Free',
      helpText:
        'Applied if the insured hasn’t filed any claims. Available in all states except NY.',
    },
    {
      discountName: 'Farm Bureau',
      helpText:
        'Applied if the insured is a voting member in the bureau (Only for states that offer Farm Bureau).',
    },
    {
      discountName: 'Gated Community',
      helpText: 'Applied if home is in a qualified gated community.',
    },
    {
      discountName: 'Good Payer',
      helpText: `Possible applied discount based on the insured's policy tenure, months since last record of nonsufficient funds, number of warning notices for late pay, number of mortgages and the presence of cancellation notices in the last year`,
    },
    {
      discountName: 'Group Membership',
      helpText:
        'Applied based on affiliation with member (Affinity) groups. Available in MI only.',
    },
    {
      discountName: 'Home and Car (Multi-Policy Discount)',
      helpText: 'Applied if a personal Auto policy is also provided.',
    },
    {
      discountName: 'Marital Status (Personal Status)',
      helpText: 'Applied if the marital status is married or widowed.',
    },
    {
      discountName: 'Protective Devices',
      helpText:
        'Applied based on the type of protective device selected on the policy.',
    },
  ],
  TermLife: [],
  PolicyLevel: [
    {
      discountName: 'Multi Line',
      helpText:
        'Applied when insured has a property or Auto policy along with an additional qualifying policy.',
    },
    {
      discountName: 'Nationwide Associate',
      helpText:
        'Applied if the insured is a current or retired Nationwide Associate. Applied if the insured is a current or retired Nationwide associate. Not available in MS, TN or CA for auto and Motorcycle/ATV insurance. Not available in MS or TN for property insurance.',
    },
  ],
};

export const DiscountsAvailabilityHelpText = {
  labelInnerHTML:
    '<font color=#575757>Information on all available discounts</font>',
  AGENT: '*Not all discounts can be obtained by using this tool.',
  HOMEOWNERS:
    '*Not all discounts can be obtained by using this tool. Please transfer to PolicyCenter for Fortified Home discounts.',
  DIRECT:
    '*Not all discounts can be obtained by using this tool. Please call {phoneNumber} for more information',
};
