import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiCacheService {
  private static instance: ApiCacheService;
  private static cacheKey = 'cache';
  static getInstance(): ApiCacheService {
    return this.instance;
  }

  constructor() {
    ApiCacheService.instance = this;
    window.sessionStorage.setItem(ApiCacheService.cacheKey, JSON.stringify({}));
  }

  hasKey(key: string): boolean {
    const cacheString = window.sessionStorage.getItem(ApiCacheService.cacheKey);
    if (cacheString !== null) {
      const existingCache = JSON.parse(cacheString);
      if (existingCache[key]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(key: string): Observable<any> {
    const cacheString = window.sessionStorage.getItem(ApiCacheService.cacheKey);
    if (cacheString) {
      const existingCache = JSON.parse(cacheString);
      return of(existingCache[key]);
    }
    return of(null);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(key: string, value: any): void {
    const cacheString = window.sessionStorage.getItem(ApiCacheService.cacheKey);
    if (cacheString) {
      const theCache = JSON.parse(cacheString);
      theCache[key] = value;
      window.sessionStorage.setItem(
        ApiCacheService.cacheKey,
        JSON.stringify(theCache)
      );
    }
  }

  deleteKey(key: string): void {
    const cacheString = window.sessionStorage.getItem(ApiCacheService.cacheKey);
    if (cacheString) {
      const theCache = JSON.parse(cacheString);
      if (theCache[key]) {
        delete theCache[key];
      }
      window.sessionStorage.setItem(
        ApiCacheService.cacheKey,
        JSON.stringify(theCache)
      );
    }
  }

  clear(): void {
    window.sessionStorage.setItem(ApiCacheService.cacheKey, JSON.stringify({}));
  }
}
