import { QuoteRetrieveResponse } from '@core/models/api/response/retrieve-response.model';
import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SessionActions, RetrieveActions } from '@core/store/actions/index';

export interface RetrieveState extends EntityState<QuoteRetrieveResponse> {
  pendingCallCount: number;
}

export const adapter: EntityAdapter<QuoteRetrieveResponse> =
  createEntityAdapter<QuoteRetrieveResponse>({
    selectId: (retrieve) => retrieve.productType || '',
  });

const retrieveStateReducer = createReducer(
  {
    ...adapter.getInitialState(),
    pendingCallCount: 0,
  },
  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({
      pendingCallCount: 0,
    })
  ),
  on(RetrieveActions.retrieveQuotes, (state, { payload }) => {
    return {
      ...state,
      pendingCallCount: state.pendingCallCount + (payload?.quotes?.length || 0),
    };
  }),
  on(
    RetrieveActions.retrieveQuoteSuccess,
    RetrieveActions.retrieveInactiveQuoteSuccess,
    (state, { payload }
  ) => {
    return adapter.upsertOne(payload, {
      ...state,
      pendingCallCount: state.pendingCallCount - 1,
    });
  }),
  on(RetrieveActions.retrieveQuoteError, (state) => {
    return {
      ...state,
      pendingCallCount: state.pendingCallCount - 1,
    };
  })
);

export function reducer(
  state: RetrieveState | undefined,
  action: Action
): RetrieveState {
  return retrieveStateReducer(state, action);
}
