import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { CoverageSyncActions, SessionActions } from '@core/store/actions';

import { CoverageSyncEntity } from './coverage-sync.entity';

export interface CoverageSyncState extends EntityState<CoverageSyncEntity> {}

export const adapter: EntityAdapter<CoverageSyncEntity> =
  createEntityAdapter<CoverageSyncEntity>({});

let nextCoverageSyncId = 1;

function generateCoverageSyncId(): string {
  const id = `CVGSID${nextCoverageSyncId}`;
  nextCoverageSyncId++;
  return id;
}

const coverageSyncReducer = createReducer(
  adapter.getInitialState(),
  on(SessionActions.clearSessionState, () => adapter.getInitialState()),
  on(CoverageSyncActions.updateCoverageSync, (state, { payload }) => {
    if (!payload.id) {
      payload = { ...payload, id: generateCoverageSyncId() };
    }
    return adapter.upsertOne(payload, state);
  })
);

export function reducer(
  state: CoverageSyncState | undefined,
  action: Action
): CoverageSyncState {
  return coverageSyncReducer(state, action);
}
