import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ProductType } from '@core/models/api/dsm-types';
import { CoveragesService } from '@core/services/coverages.service';
import { PersonaService } from '@core/services/persona.service';
import { CoverageEntity } from '@entities/coverage/coverage.entity';
import { PersonaRecommendationId } from '@entities/persona/persona.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoverageUtils } from '@shared/utils/coverage.utils';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, Subject, takeUntil } from 'rxjs';

export class CoverageModalCancelled {}

@Component({
  selector: 'nwx-coverage-modal',
  templateUrl: './coverage-modal.component.html',
  styleUrls: ['./coverage-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageModalComponent implements OnInit, OnDestroy {
  @Input() modalTitle!: string;
  @Input() recommendationId!: PersonaRecommendationId;
  @Input() productType!: ProductType;

  form: FormGroup = this.fb.group({});
  quoteCoverages$!: Observable<Nullable<CoverageEntity[]>>;
  unsubscribe$: Subject<void> = new Subject<void>();
  coverage!: CoverageEntity | undefined;
  formControlName!: string;
  result: Promise<void>;
  private resolveOutcome: (() => void) | null = null;
  private rejectOutcome: ((error: any) => void) | null = null;
  private callInFlight = false;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private personaService: PersonaService,
    private coveragesService: CoveragesService
  ) {
    this.result = new Promise((resolve, reject) => {
      this.resolveOutcome = resolve;
      this.rejectOutcome = reject;
    });
  }

  ngOnInit(): void {
    this.quoteCoverages$ = this.coveragesService.getQuoteCoverages(
      this.productType
    );
    this.quoteCoverages$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((coverages: Nullable<CoverageEntity[]>) => {
        this.coverage = coverages?.find(
          (cov) => cov.coverageId === this.recommendationId
        );
        if (!!this.coverage) {
          this.coverage = this.adjustIncomingCoverage(this.coverage);
          this.formControlName = CoverageUtils.getFormKey(
            this.coverage.coverageId,
            this.coverage.coverableId,
            this.coverage.terms[0]?.code
          );
          this.form.addControl(
            this.formControlName,
            new FormControl('', [Validators.required])
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (!this.callInFlight) {
      this.rejectOutcome?.(null);
      this.resolveOutcome = null;
      this.rejectOutcome = null;
    }
  }

  private adjustIncomingCoverage(coverage: CoverageEntity): CoverageEntity {
    return {
      ...coverage,
      mandatory: true, // To suppress the Decline option.
    };
  }

  onCancel(): void {
    this.rejectOutcome?.(new CoverageModalCancelled());
    this.resolveOutcome = null;
    this.rejectOutcome = null;
    this.activeModal.close();
  }

  onSave(): void {
    const value = this.form.get(this.formControlName)?.value;
    if (!value) {
      return;
    }
    this.callInFlight = true;
    this.personaService
      .acceptPersonaRecommendation(this.recommendationId, value)
      .then(() => {
        this.resolveOutcome?.();
        this.resolveOutcome = null;
        this.rejectOutcome = null;
      })
      .catch((error) => {
        this.rejectOutcome?.(error);
        this.resolveOutcome = null;
        this.rejectOutcome = null;
      });
    this.activeModal.close();
  }
}
