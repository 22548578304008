<nwx-header *ngIf="headerApplicable(currentPage$ | async)"></nwx-header>
<nwx-comprater-header
  *ngIf="compraterHeaderApplicable(currentPage$ | async)"
></nwx-comprater-header>
<nwx-toaster></nwx-toaster>
<div id="main-container">
  <ng-container *ngIf="(currentPage$ | async)?.showNavBar || false">
    <nwx-nav-sidebar
      [currentPage]="currentPage$ | async"
      [ngClass]="{ 'needs-min-width': !(maxSizeXSmall$ | async) }"
    ></nwx-nav-sidebar>
  </ng-container>
  <main #main class="nwx-containers">
    <router-outlet></router-outlet>
  </main>
</div>
<footer>
  <nwx-footer></nwx-footer>
</footer>
<nwx-loading-spinner
  [loading]="(appLoading$ | async) || false"
  [message]="(loadingMessage$ | async) || ''"
></nwx-loading-spinner>
<nwx-debug-overlay *ngIf="showDebugOverlay"></nwx-debug-overlay>
