import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { CompraterRoutingResponse } from '@core/adapters/comprater-routing.adapter';
import { CompraterProducerSelectionPageRepresentation } from '@core/constants/pages';
import { AgencyService } from '@core/services/agency.service';
import { CompraterRoutingService } from '@core/services/comprater-routing.service';
import { HeaderService } from '@core/services/header.service';
import { LayoutService } from '@core/services/layout.service';
import { UserService } from '@core/services/user.service';
import { Page } from '@core/store/entities/navigation/navigation.action';
import { UserModel } from '@core/store/entities/user/user.model';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'nwx-comprater-header',
  templateUrl: './comprater-header.component.html',
  styleUrls: ['./comprater-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompraterHeaderComponent implements OnInit, OnDestroy {
  static pagesWithCompraterHeader: (Page | null)[] = [
    CompraterProducerSelectionPageRepresentation,
  ];

  user$: Observable<UserModel>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  shouldShowProfileComponent$: Observable<boolean>;

  public shouldShowNavBar = false;
  public agentName = '';
  public agentFirstName = '';
  public isIaUser = false;
  agentCenterUrl = '';
  userName$: Observable<string>;
  producerCode$: Observable<string>;
  agentName$: Observable<string>;
  routingSummary$: Observable<
    'nwxSingle' | 'nwxMulti' | 'pcSingle' | 'pcMulti'
  >;
  maxSizeXSmall$: Observable<boolean>;

  constructor(
    private userService: UserService,
    private headerService: HeaderService,
    private agencyService: AgencyService,
    private compraterRoutingService: CompraterRoutingService,
    private layoutService: LayoutService
  ) {
    this.user$ = userService.getUser();
    this.headerService.showProfileComponent();
    this.shouldShowProfileComponent$ =
      headerService.shouldShowProfileComponent();
    this.userName$ = this.userService.getUserName();
    this.producerCode$ = this.agencyService.getProducerCode();
    this.agentName$ = this.agencyService.getAgentName();
    this.routingSummary$ = this.compraterRoutingService
      .getCompraterRoutingPassively()
      .pipe(map((response) => this.summarizeCompraterRouting(response)));
    this.maxSizeXSmall$ = this.layoutService.maxSizeXSmall();
  }

  ngOnInit(): void {
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user: UserModel) => {
      if (user?.firstName && user?.lastName) {
        this.agentName = `${user.firstName} ${user.lastName}`;
        this.agentFirstName = user.firstName;
      }
      if (user?.role && (user?.role === 'IA' || user?.role === 'EA')) {
        this.isIaUser = true;
        this.agentCenterUrl = 'https://agentcenter.nationwide.com/';
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  private summarizeCompraterRouting(
    response: CompraterRoutingResponse
  ): 'nwxSingle' | 'nwxMulti' | 'pcSingle' | 'pcMulti' {
    let nwxCount = 0,
      pcCount = 0;
    for (const quote of response) {
      switch (quote.destination) {
        case 'NWX':
          nwxCount++;
          break;
        case 'PC':
          pcCount++;
          break;
      }
    }
    if (nwxCount && pcCount) {
      return 'nwxMulti';
    }
    if (nwxCount === 1) {
      return 'nwxSingle';
    }
    if (nwxCount) {
      return 'nwxMulti';
    }
    if (pcCount === 1) {
      return 'pcSingle';
    }
    if (pcCount) {
      return 'pcMulti';
    }
    // Fallback, eg error.
    return 'nwxSingle';
  }
}
