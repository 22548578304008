import { ObjectValidator } from '@core/helper/object-validator';
import { ProducerType } from '@shared/constants/app-constants';

export interface ProducerRequest {
  producerCode: string;
  type?: ProducerType;
  agencyCode?: string;
}

export function sanitizeProducerRequest(request: unknown): ProducerRequest {
  return ObjectValidator.forceSchema(request, {
    producerCode: 'string',
    type: 'string',
    agencyCode: 'string',
  }) as ProducerRequest;
}
