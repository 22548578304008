import { ProductType } from '@core/models/api/dsm-types';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { EligibleDiscountId, EligibleDiscountsEntity } from './eligible-discounts.entity';

export const updateVehicleDiscount = createAction(
  '[Eligible Discount] Update Vehicle Discount',
  props<{ payload: EligibleDiscountsEntity }>()
);

export const updateVehicleDiscountSuccess = createAction(
  '[Eligible Discount] Update Vehicle Discount Success',
  props<{ payload: EligibleDiscountsEntity }>()
);

export const updateVehicleDiscountFail = createAction(
  '[Eligible Discount] Update Vehicle Discount Fail',
  props<{ error: ErrorModel }>()
);

export const getAllPolicyLine = createAction(
  '[Eligible Discounts] Get All Policy Line'
);

export const addAllEligibleDiscounts = createAction(
  '[Eligible Discounts] Add All Discounts',
  props<{ response: EligibleDiscountsEntity[] }>()
);

export const storeEligibleDiscount = createAction(
  '[Eligible Discounts] Store Eligible Discount',
  props<{ entity: EligibleDiscountsEntity }>()
);

export const getAllPolicyLineSuccess = createAction(
  '[Eligible Discounts] Get All Policy Line Success',
  props<{ response: EligibleDiscountsEntity[] }>()
);

export const getAllPolicyLineFail = createAction(
  '[Eligible Discounts] Get All Policy Line Fail',
  props<{ error: ErrorModel }>()
);

export const getPolicyLine = createAction(
  '[Eligible Discounts] Get Policy Line',
  props<{ productType: ProductType; correlationId?: string }>()
);

export const getPolicyLineSuccess = createAction(
  '[Eligible Discounts] Get Policy Line Success',
  props<{ response: EligibleDiscountsEntity[]; correlationId?: string }>()
);

export const getPolicyLineFail = createAction(
  '[Eligible Discounts] Get Policy Line  Fail',
  props<{ error: ErrorModel; correlationId?: string }>()
);

export const updatePolicyLineIfDefined = createAction(
  '[Eligible Discounts] Update Policy Line If Defined',
  props<{ entity: EligibleDiscountsEntity }>()
);

export const updatePolicyLine = createAction(
  '[Eligible Discounts] Update Policy Line',
  props<{ entity: EligibleDiscountsEntity; correlationId?: string }>()
);

export const updatePolicyLineSuccess = createAction(
  '[Eligible Discounts] Update Policy Line Success',
  props<{ entity: EligibleDiscountsEntity; correlationId?: string }>()
);

export const updatePolicyLineFail = createAction(
  '[Eligible Discounts] Update Policy Line Fail',
  props<{
    error: ErrorModel;
    productType: ProductType;
    eligibleDiscountId: EligibleDiscountId;
    correlationId?: string;
  }>()
);

export const updateFortifiedHome = createAction(
  '[Eligible Discount] Update Fortified Home Discount'
);

export const updateDriverDiscount = createAction(
  '[Eligible Discount] Update Driver Discount',
  props<{ payload: EligibleDiscountsEntity }>()
);

export const updateDriverDiscountSuccess = createAction(
  '[Eligible Discount] Update Driver Discount Success',
  props<{ payload: EligibleDiscountsEntity }>()
);

export const updateDriverDiscountFail = createAction(
  '[Eligible Discount] Update Driver Discount Fail',
  props<{ error: ErrorModel }>()
);

export const storePolicyLineModifier = createAction(
  '[Discount] Store Policy Line Modifier',
  props<{ discount: Update<EligibleDiscountsEntity> }>()
);

export const removeDiscountsForProduct = createAction(
  '[Eligible Discount] Remove Discounts For Product',
  props<{ payload: ProductType }>()
);
