import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ToastComponent } from './toast/toast.component';
import { ToasterComponent } from './toaster.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ToasterComponent, ToastComponent],
  exports: [ToasterComponent, ToastComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ToasterModule {}
