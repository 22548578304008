<div class="modal-header">
  <h4 class="modal-title" id="reportModalHeader">{{ modalTitle }}</h4>
</div>
<div class="modal-body">
  <p>Please select the limit for the coverage:</p>
  <nwx-coverage-row
    [coverage]="coverage"
    [form]="form"
    *ngIf="!!coverage"
  ></nwx-coverage-row>
</div>
<div class="modal-footer">
  <bolt-button-bar>
    <bolt-button
      slot="cancel"
      [attr.id]="'cm-cancel'"
      (click)="onCancel()"
      >Cancel</bolt-button
    >
    <bolt-button slot="forward" [attr.id]="'cm-save'" (click)="onSave()"
      >Save</bolt-button
    >
  </bolt-button-bar>
</div>
