import { ObjectValidator } from '@core/helper/object-validator';
import { Nullable } from '@shared/utils/type.utils';

export interface PersonEntity {
  firstName?: Nullable<string>;
  middleName?: Nullable<string>;
  lastName?: Nullable<string>;
  suffix?: Nullable<string>;
  gender?: Nullable<string>;
  maritalStatus?: Nullable<string>;
  dateOfBirth?: Nullable<string>;
  ssn?: Nullable<string>;
}

export function sanitizePersonEntity(response: unknown): PersonEntity {
  return ObjectValidator.forceSchema<PersonEntity>(
    response,
    {
      firstName: 'string',
      middleName: 'string',
      lastName: 'string',
      suffix: 'string',
      gender: 'string',
      maritalStatus: 'string',
      dateOfBirth: 'string',
      ssn: 'string',
    },
    [
      'firstName',
      'middleName',
      'lastName',
      'suffix',
      'gender',
      'maritalStatus',
      'dateOfBirth',
      'ssn',
    ]
  );
}
