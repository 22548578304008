<div
  class="pt-12 pb-12 bolt-container-fluid bolt-background-pale-gray-25 standard-rounded-border"
>
  <div class="bolt-row bolt-justify-content-between bolt-align-items-center">
    <div class="bolt-col">
      <ng-content select="[content]"></ng-content>
    </div>
    <div class="bolt-col-auto">
      <ng-container *ngIf="overrideIcon; else standardIcon">
        <ng-content select="[supplementalContent]"></ng-content>
      </ng-container>
      <ng-template #standardIcon>
        <bolt-icon
          [attr.name]="icon"
          color="vibrant-blue"
          size="sm"
          (click)="emitIconClick()"
        ></bolt-icon>
      </ng-template>
    </div>
  </div>
</div>
