import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../error/error.model';

export const loadQuoteLetter = createAction('[Quote] Load Quote Letter');

export const loadQuoteLetterSuccess = createAction(
  '[Quote] Load Quote Letter Success',
  props<{ payload: string }>()
);

export const loadQuoteLetterFail = createAction(
  '[Quote] Load Quote Letter Fail',
  props<{ error: ErrorModel }>()
);
