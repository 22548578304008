<div *ngIf="isModal" class="modal-header bolt-space-bottom-md">
  <div class="modal-header-title" id="accountRegModalHeader">
    Online account registration
  </div>
  <div class="bolt-body-copy-md">
    To manage policy and view documents online
  </div>
</div>
<ng-container *ngIf="readonly; else editable">
  <div class="bolt-container-fluid">
    <div class="bolt-row bolt-align-items-center">
      <div class="bolt-col">
        <ng-container *ngIf="hasUpdatedRegistrationForm; else disclaimer">
          <label for="readonlyLinkPreference" class="faux-bolt-label no-mb">
            Online registration preference
          </label>
        </ng-container>
        <ng-template #disclaimer>
          <nwx-message-bubble
            [borderless]="true"
            iconName="info-circle-filled"
            iconColor="theme-info"
            iconSize="sm"
          >
            <div class="bolt-body-copy-sm">
              {{ accountSetupDisclaimer }}
            </div>
          </nwx-message-bubble>
        </ng-template>
      </div>
      <div class="bolt-col">
        <ng-container *ngIf="hasUpdatedRegistrationForm; else needsToUpdate">
          <div class="readonly" id="readonlyLinkPreference">
            {{ readableLinkPref }}
            <bolt-icon
              *ngIf="canEdit"
              name="pencil-filled"
              color="medium-blue"
              (click)="exitReadonly()"
            ></bolt-icon>
          </div>
        </ng-container>
        <ng-template #needsToUpdate>
          <bolt-button
            type="primary"
            size="sm"
            width="full"
            (click)="exitReadonly()"
            >Set up online account
          </bolt-button>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #editable>
  <div class="bolt-container-fluid relative" [class.modal-body]="isModal">
    <nwx-passive-spinner *ngIf="showSpinner"></nwx-passive-spinner>
    <div [class.spinner]="showSpinner">
      <ng-container *ngIf="isModal">
        <div class="bolt-space-bottom-2xl">
          <nwx-message-bubble
            [borderless]="true"
            iconName="info-circle-filled"
            iconColor="theme-info"
            >{{ accountSetupDisclaimer }}
          </nwx-message-bubble>
        </div>
        <hr class="full-length" />
      </ng-container>

      <form
        [formGroup]="form"
        [nwxExposeFormErrors]="form"
        formName="onlineAccountRegistration"
      >
        <div class="bolt-row bolt-space-bottom-sm">
          <div class="bolt-col">
            <label
              for="doesCustomerWantOnlineAccount"
              class="faux-bolt-label no-mb"
            >
              {{ setupAcctLabel }}
            </label>
          </div>
          <div class="bolt-col">
            <bolt-radio-group
              [arialabel]="setupAcctLabel"
              label=""
              formControlName="doesCustomerWantOnlineAccount"
              required
              horizontal
              (change)="exitReadonly()"
              id="doesCustomerWantOnlineAccount"
            >
              <bolt-radio value="true">Yes</bolt-radio>
              <bolt-radio value="false">No</bolt-radio>
            </bolt-radio-group>
          </div>
        </div>
        <div *ngIf="wantsOnlineAccount" class="bolt-row bolt-space-bottom-sm">
          <div class="bolt-col">
            <label for="doesCustomerWantOnlineAccount">
              <strong>{{ linkPrefLabel }}</strong>
            </label>
          </div>
          <div class="bolt-col">
            <bolt-radio-group
              [arialabel]="linkPrefLabel"
              label=""
              formControlName="registrationLinkCommunicationMethod"
              required
              horizontal
              id="registrationLinkCommunicationMethod"
            >
              <bolt-radio value="MobilePhone">Mobile</bolt-radio>
              <bolt-radio value="ElectronicAddress">Email</bolt-radio>
            </bolt-radio-group>
          </div>
        </div>
        <ng-container *ngIf="linkPreference && wantsOnlineAccount">
          <div class="bolt-row bolt-align-items-center bolt-space-bottom-sm">
            <div class="bolt-col">
              <label
                *ngIf="linkPreference === 'MobilePhone'"
                for="primaryNamedInsuredMobileNumber"
              >
                <strong>Mobile phone number</strong>
              </label>
              <label
                *ngIf="linkPreference === 'ElectronicAddress'"
                for="primaryNamedInsuredEmail"
                ><strong>Email address</strong>
              </label>
            </div>
            <div class="bolt-col">
              <bolt-textfield
                *ngIf="linkPreference === 'MobilePhone'"
                arialabel="Mobile phone number"
                formControlName="primaryNamedInsuredMobileNumber"
                required
                class="nwpii"
                [nwxMask]="'phone'"
                [attr.id]="'primaryNamedInsuredPhoneNumber'"
              ></bolt-textfield>
              <ng-container *ngIf="linkPreference === 'ElectronicAddress'">
                <div *ngIf="pniEmail; else manualEmail" class="readonly">
                  {{ pniEmail }}
                </div>
                <ng-template #manualEmail>
                  <bolt-textfield
                    formControlName="primaryNamedInsuredEmail"
                    required
                    class="nwpii"
                    arialabel="Email address"
                    [attr.id]="'primaryNamedInsuredEmail'"
                  ></bolt-textfield>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <div
          class="bolt-row"
          *ngIf="linkPreference === 'MobilePhone' && wantsOnlineAccount"
        >
          <div class="bolt-col">
            <bolt-checkbox
              required
              formControlName="hasConsentedToOneTimeRegistrationText"
              id="hasConsentedToOneTimeRegistrationText"
            >
              The customer consents to receive a one time link regarding online
              account registration.
            </bolt-checkbox>
          </div>
        </div>
        <ng-container *ngIf="wantsOnlineAccount">
          <div class="bolt-row">
            <div class="bolt-col">
              <hr class="margin-spacing" />
            </div>
          </div>
          <div class="bolt-row">
            <div class="bolt-col bolt-body-copy-sm bolt-space-bottom-sm">
              <strong>Tell the customer:</strong> "If they do not receive a
              link, they need to go to Nationwide.com to complete the
              registration."
            </div>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</ng-template>
<div *ngIf="isModal" class="modal-footer">
  <bolt-button-bar>
    <bolt-button
      slot="forward"
      type="primary"
      (click)="onSubmit()"
      id="confirmRegistration"
    >
      Confirm registration
    </bolt-button>
  </bolt-button-bar>
</div>
