import { ProductType } from '@core/models/api/dsm-types';
import { TermAmount, sanitizeTermAmount } from '../coverage/coverage.entity';
import { ObjectValidator } from '@core/helper/object-validator';
import { Vehicle } from '@entities/quote-letter/quote-letter.model';

export interface PremiumEntity {
  productType: ProductType;
  termMonths?: number | undefined;
  total?: TermAmount;
  taxes?: TermAmount;
  fees?: TermAmount;
  totalBeforeTaxes?: TermAmount;
  vehiclePremiums?: VehiclePremium[];
  coverablePremiums?: CoverablePremium[];
}

export function sanitizePremiumEntity(input: unknown): PremiumEntity {
  return ObjectValidator.forceSchema<PremiumEntity>(
    input,
    {
      productType: 'string',
      termMonths: 'number',
      total: sanitizeTermAmount,
      taxes: sanitizeTermAmount,
      fees: sanitizeTermAmount,
      totalBeforeTaxes: sanitizeTermAmount,
      vehiclePremiums: [sanitizeVehiclePremium],
      coverablePremiums: [sanitizeCoverablePremium],
    },
    []
  );
}

export interface VehiclePremium {
  variableMileagePremium: number;
  totalBasePremium?: number;
  ratedTermMileage?: number;
  totalCostPerMile?: number;
  vehicleID: number;
}

export function sanitizeVehiclePremium(input: unknown): VehiclePremium {
  return ObjectValidator.forceSchema<VehiclePremium>(
    input,
    {
      variableMileagePremium: 'number',
      totalBasePremium: 'number',
      ratedTermMileage: 'number',
      totalCostPerMile: 'number',
      vehicleID: 'number',
    },
    ['totalBasePremium', 'ratedTermMileage', 'totalCostPerMile']
  );
}

export interface CoverablePremium {
  coverableTotal?: number;
  coverableType?: string;
  coverableID?: number;
}

export function sanitizeCoverablePremium(input: unknown): CoverablePremium {
  return ObjectValidator.forceSchema<CoverablePremium>(
    input,
    {
      coverableTotal: 'number',
      coverableType: 'string',
      coverableID: 'number',
    },
    ['coverableTotal', 'coverableType', 'coverableID']
  );
}
