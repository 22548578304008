import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HubCoveragesSelectedCardModel } from '@core/models/views/coverage.model';
import { ComponentChanges } from '@shared/utils/general.utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/no-explicit-any

@Component({
  selector: 'hub-coverages-selected-card',
  templateUrl: './hub-coverages-selected-card.component.html',
  styleUrls: ['./hub-coverages-selected-card.component.scss'],
})
export class HubCoveragesSelectedCardComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() formTitle: string | undefined;
  @Input() model!: HubCoveragesSelectedCardModel;
  @Input() formComponentType?: Type<{}>;
  @Input() eventHandlers?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: (arg?: any) => void;
  };
  @Input() parentForm: FormGroup | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() changed = new EventEmitter<any>();
  @Output() unselect = new EventEmitter<void>();

  @ViewChild('form', { read: ViewContainerRef }) form!: ViewContainerRef;

  private initializedForm = false;
  private formComponent?: Component;
  private unsubscribe = new Subject<void>();

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    if (this.formComponentType) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(
        this.formComponentType
      );
      const component = this.form.createComponent(factory);
      this.formComponent = component.instance;
      this.connectChangeEmitter();
      this.deliverFormComponentInputs();
      this.connectEventHandlers();
      component.changeDetectorRef.detectChanges();
      this.initializedForm = true;
    }
  }

  ngOnChanges(
    changes: ComponentChanges<HubCoveragesSelectedCardComponent>
  ): void {
    if (changes.model && this.initializedForm) {
      this.deliverFormComponentInputs();
    }
  }

  private connectChangeEmitter(): void {
    if (this.formComponent) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const source = (this.formComponent as { changed?: EventEmitter<any> })
        .changed;
      if (source) {
        source.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
          this.changed.next(event);
        });
      }
    }
  }

  private deliverFormComponentInputs(): void {
    if (this.formComponent && this.model.formComponentInputs) {
      const changes: SimpleChanges = {};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const assignableComponent = this.formComponent as { [key: string]: any };
      for (const key of Object.keys(this.model.formComponentInputs)) {
        const value = this.model.formComponentInputs[key];
        if (assignableComponent[key] === value) {
          continue;
        }
        changes[key] = {
          currentValue: value,
          previousValue: assignableComponent[key],
          firstChange: false,
          isFirstChange: () => false,
        };
        assignableComponent[key] = value;
      }

      if (this.parentForm) {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        assignableComponent['parentForm'] = this.parentForm;
        if (this.formTitle) {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          assignableComponent['formTitle'] = this.formTitle;
        }
      }

      if (assignableComponent.ngOnChanges && Object.keys(changes).length > 0) {
        assignableComponent.ngOnChanges(changes);
      }
    }
  }

  private connectEventHandlers(): void {
    if (this.eventHandlers && this.formComponent) {
      const kindOfComponent = this.formComponent as {
        [key: string]: EventEmitter<void>;
      };
      for (const key of Object.keys(this.eventHandlers)) {
        const handle = this.eventHandlers[key];
        const source = kindOfComponent[key];
        if (source) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          source.subscribe((event: any) => handle(event));
        }
      }
    }
  }

  onUnselect(): void {
    this.unselect.next();
  }
}
