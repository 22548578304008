import { Injectable } from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { DriverVehicleAssignmentActions } from '@core/store/actions';
import { DriverVehicleAssignmentSelectors } from '@core/store/selectors';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { firstValueFrom, map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { addDriverVehicleAssignments } from '../store/entities/driver-vehicle-assignment/driver-vehicle-assignment.actions';
import { DriverVehicleAssignmentEntity } from '../store/entities/driver-vehicle-assignment/driver-vehicle-assignment.entity';

@Injectable({
  providedIn: 'root',
})
export class DriverVehicleAssignmentService {
  constructor(private store: Store, private actions$: Actions) {}

  addAssignments(
    assignments: DriverVehicleAssignmentEntity[],
    productType: ProductType
  ): void {
    this.store.dispatch(
      addDriverVehicleAssignments({
        productType,
        assignments,
      })
    );
  }

  getAllDriverVehicleAssignments(
    productType: ProductType
  ): Observable<DriverVehicleAssignmentEntity[]> {
    return this.store.select(
      DriverVehicleAssignmentSelectors.getAllDriverVehicleAssignmentsByProductType(
        productType
      )
    );
  }

  sendAssignmentsAndAwaitResult(
    assignments: DriverVehicleAssignmentEntity[],
    productType: ProductType
  ): Promise<any> {
    const outcome = firstValueFrom(
      this.actions$.pipe(
        ofType(
          DriverVehicleAssignmentActions.addDriverVehicleAssignmentSuccess,
          DriverVehicleAssignmentActions.addDriverVehicleAssignmentError
        ),
        map((action) => {
          if (
            action.type ===
            DriverVehicleAssignmentActions.addDriverVehicleAssignmentSuccess
              .type
          ) {
            return null;
          } else {
            throw new Error(action.payload.displayMessage);
          }
        })
      )
    );
    this.store.dispatch(
      DriverVehicleAssignmentActions.addDriverVehicleAssignments({
        productType,
        assignments,
      })
    );
    return outcome;
  }
}
