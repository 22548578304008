import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CustomValidators } from '@app/validators/custom-validators';
import { states } from '@assets/metadata/states';
import { FormHelper } from '@core/helper/form-helper';
import { MemberModel } from '@core/models/views/person.model';
import { ComponentStoreService } from '@core/services/component-store.service';
import { TaskModel } from '@core/store/entities/task/task.model';
import { ProductModel } from '@entities/product/product.model';
import { ComponentStore } from '@ngrx/component-store';
import { DriverRelationToPniOptions } from '@shared/constants/app-constants';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';

interface TasksMemberFormState {
  isPni?: boolean;
  relationToPrimaryNamedInsuredOptions?: { value: string; display: string }[];
  states?: string[];
  showMaritalStatus?: boolean;
  showRelationToPrimaryNamedInsured?: boolean;
  showLicenseState?: boolean;
  showLicenseNumber?: boolean;
}

@Component({
  selector: 'nwx-tasks-members-form',
  templateUrl: './tasks-members-form.component.html',
  styleUrls: ['./tasks-members-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore],
})
export class TasksMembersFormComponent implements OnInit, OnDestroy {
  @Input() index: number = 0;
  @Input() policyholder!: Partial<Nullable<MemberModel>>;
  @Input() member!: Partial<Nullable<MemberModel>>;
  @Input() pniTasks!: Nullable<TaskModel[]> | undefined;
  @Input() memberTasks!: Nullable<TaskModel[]> | undefined;
  @Input() products!: ProductModel[];

  @Output() valueChange = new EventEmitter<Partial<MemberModel>>();
  @Output() formReady = new EventEmitter<FormGroup>();

  form!: FormGroup;

  vm$!: Observable<TasksMemberFormState>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private readonly componentStore: ComponentStoreService<TasksMemberFormState>
  ) {}

  ngOnInit(): void {
    this.form = this.buildForm(this.member);

    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged(this.hasChanges))
      .subscribe((changes: MemberModel) => {
        this.emitChanges(changes);
      });

    this.componentStore.initialize({
      isPni: !!this.member?.policyRoles?.find(r => (
        r.entityType === 'policyHolder' &&
        r.roleSequence === 'primary'
      )),
      relationToPrimaryNamedInsuredOptions: DriverRelationToPniOptions,
      states: states,
      showMaritalStatus: false,
      showRelationToPrimaryNamedInsured: false,
      showLicenseState: false,
      showLicenseNumber: false,
    });

    this.vm$ = this.componentStore.get();

    this.initializeMemberQuestions();

    this.formReady.emit(this.form);
  }

  ngOnDestroy(): void {
    this.form.disable();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initializeMemberQuestions(): void {
    this.setFormField(
      this.doesTaskFieldExist('maritalStatus'),
      'person.maritalStatus',
      [CustomValidators.MaritalStatus]
    );
    this.setFormField(
      this.doesTaskFieldExist('relationToPrimaryNamedInsured'),
      'relationToPrimaryNamedInsured',
      []
    );
    this.setFormField(
      this.doesTaskFieldExist('licenseNumber'),
      'licenseState',
      []
    );
    this.setFormField(
      this.doesTaskFieldExist('licenseNumber'),
      'licenseNumber',
      []
    );
  }

  onValueChange(changes: Partial<MemberModel>): void {
    this.valueChange.emit(changes);
  }

  emitChanges(changes: Partial<MemberModel>): void {
    this.valueChange.emit({
      ...this.member,
      person: {
        ...this.member?.person,
        maritalStatus: changes.person?.maritalStatus
          ? changes.person?.maritalStatus
          : this.member?.person?.maritalStatus,
      },
      relationToPrimaryNamedInsured: changes.relationToPrimaryNamedInsured
        ? changes.relationToPrimaryNamedInsured
        : this.member?.relationToPrimaryNamedInsured,
      licenseState: changes.licenseState
        ? changes.licenseState
        : this.member?.licenseState,
      licenseNumber: changes.licenseNumber
        ? changes.licenseNumber
        : this.member?.licenseNumber,
    });
  }

  hasChanges(a: MemberModel, b: MemberModel): boolean {
    return (
      a.person?.maritalStatus === b.person?.maritalStatus &&
      a.relationToPrimaryNamedInsured === b.relationToPrimaryNamedInsured &&
      a.licenseState === b.licenseState &&
      a.licenseNumber === b.licenseNumber
    );
  }

  buildForm(member: Partial<Nullable<MemberModel>>): FormGroup {
    return this.fb.group({
      person: this.fb.group({
        maritalStatus: this.fb.control(
          member?.person?.maritalStatus || null,
          []
        ),
      }),
      relationToPrimaryNamedInsured: this.fb.control(
        member?.relationToPrimaryNamedInsured || null,
        []
      ),
      licenseState: this.fb.control(member?.licenseState || null, []),
      licenseNumber: this.fb.control(member?.licenseNumber || null, []),
    });
  }

  addChildForm(name: string, form: FormGroup): void {
    this.form.setControl(name, form);
  }

  doesTaskFieldExist(fieldName: string): boolean {
    return !!this.memberTasks?.find((t) => t.field === fieldName);
  }

  setFormField(
    isEnabled: boolean,
    fieldName: string,
    validators: ValidatorFn[]
  ): void {
    let secondPartOfFieldName = fieldName;
    if (fieldName.includes('.')) {
      secondPartOfFieldName = fieldName.split('.')[1];
    }
    this.componentStore.update({
      ['show' +
      secondPartOfFieldName.charAt(0).toUpperCase() +
      secondPartOfFieldName.slice(1)]: isEnabled,
    });

    FormHelper.enableOrDisable(fieldName, isEnabled, validators, this.form);
  }
}
