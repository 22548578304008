<div
  class="bolt-container-fluid help-padding"
  [formGroup]="form"
  [nwxExposeFormErrors]="form"
  formName="property"
  *ngIf="vm$ | async as vm"
>

  <div
    class="bolt-row nwx-task-tile bolt-space-bottom-xl"
    *ngIf="vm.showRoofYear"
  >
    <nwx-roof-year
      formControlName="roofYear"
      (confirmClicked)="onRoofYearConfirmed()"
    ></nwx-roof-year>
    <div class="bolt-col"></div>
  </div>

  <div class="bolt-row nwx-task-tile" *ngIf="vm.showEligibilityQuestions">
    <div class="bolt-col">
      <nwx-eligibility-inline-container
        (statusChange)="onEligibilityStatusChange($event)"
        [productType]="productType"
      ></nwx-eligibility-inline-container>
    </div>
  </div>
</div>
