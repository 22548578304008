import { PolicyHolderType } from '@core/models/api/dsm-types';
import { BasePersonEntity } from '@core/models/entities/base.entity';
import { PersonEntity, sanitizePersonEntity } from '@core/models/entities/person.entity';
import { Nullable } from '@shared/utils/type.utils';
import { AddressEntity, sanitizeAddressEntity } from '../address/address.entity';
import { ObjectValidator } from '@core/helper/object-validator';

export interface PolicyHolderEntity extends BasePersonEntity {
  address?: Nullable<AddressEntity>;
  associateNumber?: string;
  emailAddress?: Nullable<string>;
  homeNumber?: Nullable<string>;
  isNationwideEmployee?: boolean;
  policyHolderId: Nullable<number>;
  policyHolderType?: PolicyHolderType;
  person?: Nullable<PersonEntity>;
  membership?: Nullable<string>;
  nonSpecifiedGender?: boolean | null;
  driverStatus?: string;
  doesPolicyHolderTypeNeedUpdated?: boolean;
}

export function sanitizePolicyHolderEntities(
  input: unknown
): PolicyHolderEntity[] {
  return ObjectValidator.sanitizeArray(input, sanitizePolicyHolderEntity);
}

export function sanitizePolicyHolderEntity(response: unknown): PolicyHolderEntity {
  return ObjectValidator.forceSchema<PolicyHolderEntity>(response, {
    // Not present in responses. Declared here just for completeness:
    entityId: 'any',
    productIds: 'any',
    productType: 'any',
    quoteId: 'string',
    selected: 'boolean',
    eligibleDiscountIds: 'any',
    policyRoles: 'any',
    driverRoles: 'any',
    doesPolicyHolderTypeNeedUpdated: 'boolean',
    // Real fields:
    address: sanitizeAddressEntity,
    associateNumber: 'string',
    emailAddress: 'string',
    homeNumber: 'string',
    isNationwideEmployee: 'boolean',
    policyHolderId: 'number',
    policyHolderType: 'string',
    person: sanitizePersonEntity,
    membership: 'string',
    nonSpecifiedGender: 'boolean',
    driverStatus: 'string',
  }, [
    'entityId',
    'productIds',
    'productType',
    'quoteId',
    'selected',
    'eligibleDiscountIds',
    'policyRoles',
    'driverRoles',
    'doesPolicyHolderTypeNeedUpdated',
    'address',
    'associateNumber',
    'emailAddress',
    'homeNumber',
    'isNationwideEmployee',
    'policyHolderType',
    'person',
    'membership',
    'nonSpecifiedGender',
    'driverStatus',
  ]);
}
