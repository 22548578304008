import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ExtendedTelematicsVehicle,
  MobileEnrollment,
  VehicleEnrollment,
} from '@core/store/entities/telematics/telematics.model';
import { TelematicsUtils } from '@shared/utils/telematics.utils';
import { TelematicsService } from '@core/services/telematics.service';
import { Nullable } from '@shared/utils/type.utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'nwx-smartride-enrollment',
  templateUrl: './smartride-enrollment.component.html',
  styleUrls: [
    './smartride-enrollment.component.scss',
    './../telematics/telematics.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartrideEnrollmentComponent implements OnInit, OnDestroy {
  @Input() vehicles: ExtendedTelematicsVehicle[] = [];
  @Input() isMobile = false;
  @Input() quoteState!: string;
  @Input() enrollment: Nullable<VehicleEnrollment | MobileEnrollment>;
  @Input() maxSizeMini!: Nullable<boolean>;

  isSmartRideEarlyReward = false;
  isIncreasedDiscount = false;
  initialDiscount = '10%';
  maximumDiscount = '40%';
  unsubscribe$ = new Subject<void>();

  constructor(
    private telematicsService: TelematicsService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.initialDiscount = TelematicsUtils.getDiscountMetadata(
      'initialDiscount',
      this.quoteState
    );
    this.maximumDiscount = TelematicsUtils.getDiscountMetadata(
      'maximumDiscount',
      this.quoteState
    );
    this.telematicsService
      .isSmartRideEarlyReward()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.isSmartRideEarlyReward = value;
        this.changeDetector.detectChanges();
      });
    this.isIncreasedDiscount = this.initialDiscount === '15%';
  }
  get isCA(): boolean {
    return this.quoteState === 'CA';
  }
}
