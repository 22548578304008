import { ObjectValidator } from '@core/helper/object-validator';
import {
  BaseEntity,
  BaseEntityOptionalFields,
  BaseEntitySanitizerFields,
} from '@core/models/entities/base.entity';
import { BoatType } from '@core/models/views/vehicle.model';
import { ProductModel } from '@entities/product/product.model';
import { UnderlyingPolicyEntity } from '@entities/underlying-policy/underlying-policy.entity';

export interface WatercraftExposureAndProductInfo {
  watercraftExposure: WatercraftExposureEntity;
  underlyingPolicy?: UnderlyingPolicyEntity;
  product: ProductModel;
  options?: {
    watercraftTypeLabel?: string;
    boatTypeLabel?: string;
    carrierLabel?: string;
  };
}

export interface WatercraftExposureEntity extends BaseEntity {
  productId?: string;
  watercraftExposureId: string;
  underlyingPolicyNumber?: string;
  watercraftType: string;
  boatInfo?: {
    year?: number;
    manufacturer?: string;
    model?: string;
    boatType?: BoatType;
    length?: number;
    engineType?: string;
    numberOfMotors?: string; // 'One'
    weight?: number;
    horsepower?: number;
    maximumSpeed?: number;
  };
}

export function sanitizeWatercraftExposureEntity(
  input: unknown
): WatercraftExposureEntity {
  return ObjectValidator.forceSchema<WatercraftExposureEntity>(
    input,
    {
      productId: 'string',
      watercraftExposureId: 'string',
      underlyingPolicyNumber: 'string',
      watercraftType: 'string',
      boatInfo: {
        year: 'number',
        manufacturer: 'string',
        model: 'string',
        boatType: 'string',
        length: 'number',
        engineType: 'string',
        numberOfMotors: 'string',
        weight: 'number',
        horsepower: 'number',
        maximumSpeed: 'number',
      },
      ...BaseEntitySanitizerFields,
    },
    [...BaseEntityOptionalFields]
  );
}
