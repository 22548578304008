import { ProductType } from '@core/models/api/dsm-types';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { PolicyNumberRequest } from '../models/api/request/policy-number-request.model';
import {
  PolicyNumberResponse,
  sanitizePolicyNumberResponse,
} from '../models/api/response/policy-number-response.model';
import { DsmAdapter } from './dsm.adapter';

@Injectable({
  providedIn: 'root',
})
export class PolicyNumberAdapter {
  constructor(public adapter: DsmAdapter) {}
  generatePolicyNumber(
    request: PolicyNumberRequest
  ): Observable<PolicyNumberResponse> {
    return this.adapter.request(
      sanitizePolicyNumberResponse,
      request.productType as ProductType,
      'patch',
      `/quotes/${request.quoteId}?action=generatePolicyNumber`,
      'generate-policy-number',
      {
        body: request,
      }
    );
  }
}
