import { createSelector } from '@ngrx/store';

import * as fromCore from '@core/store/reducers';

const getUserState = createSelector(
  fromCore.getCoreState,
  (state) => state.users
);

export const getUser = createSelector(getUserState, (users) => users.user);

export const getEffectiveUserRole = createSelector(
  getUser,
  (user) => user.role
);

export const isNssUser = createSelector(
  getUserState,
  (users) => users.user.role === 'NSS'
);
