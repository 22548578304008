import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PersonaService } from '../../../services/persona.service';
import { PersonaSelectors } from '@core/store/selectors';
import { debounceTime, filter, map, tap, withLatestFrom } from 'rxjs';
import { PersonaActions } from '@core/store/actions';
import { LogService } from '@core/services/log.service';

@Injectable({
  providedIn: 'root',
})
export class PersonaEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private personaService: PersonaService,
    private logService: LogService
  ) {}

  reconsiderPersona$ = createEffect(() =>
    this.store.select(PersonaSelectors.getPersonaSelectionInputs).pipe(
      // getPersonaSelectionInputs reads a broad swath of the store. If we don't debounce, it generates too much traffic.
      debounceTime(1000),
      map((inputs) => this.personaService.digestPersonaSelectionInputs(inputs)),
      map((criteria) => this.personaService.choosePersona(criteria)),
      withLatestFrom(this.store.select(PersonaSelectors.getPersonaName)),
      filter(([next, prev]) => next !== prev),
      tap(([next]) =>
        this.logService.logBusinessEvent('persona-selected', next)
      ),
      map(([name]) => PersonaActions.setPersonaName({ name }))
    )
  );
}
