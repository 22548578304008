import { ProductType } from '@core/models/api/dsm-types';
import {
  Page,
  PageSubCategory,
} from '../store/entities/navigation/navigation.action';

export type PageIdentifier =
  | 'MaintenancePage'
  | 'SplashPage'
  | 'StartQuote'
  | 'SearchQuote'
  | 'CompraterProducerSelection'
  | 'PNI'
  | 'People'
  | 'Vehicles'
  | 'MSA'
  | 'Homeowner'
  | 'Condo'
  | 'Tenant'
  | 'Umbrella'
  | 'DwellingFire'
  | 'Boat'
  | 'RV'
  | 'HUB'
  | 'Coverages'
  | 'Billing'
  | 'Summary'
  | 'PolicyCenter'
  | 'Help'
  | 'AccountInfo';

export const MEMBER_PAGE_INDEX = 10;
export const HUB_PAGE_INDEX = 60;

export const MaintenancePageRepresentation: Page = {
  id: 'MaintenancePage',
  url: '/maintenance',
  name: 'Maintenance Page',
  index: -1,
  subcategory: PageSubCategory.GLOBAL,
  hasError: false,
  isComplete: false,
  isNavigable: true,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: false,
  visited: false,
};

export const SplashPagePageRepresentation: Page = {
  id: 'SplashPage',
  url: '/splash',
  name: 'Splash Page',
  index: -1,
  subcategory: PageSubCategory.GLOBAL,
  hasError: false,
  isComplete: false,
  isNavigable: true,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: false,
  visited: false,
};

export const StartQuotePageRepresentation: Page = {
  id: 'StartQuote',
  url: '/home/start',
  name: 'Start Quote',
  index: -1,
  subcategory: PageSubCategory.GLOBAL,
  icon: '/assets/images/nav-bar/store-colorized.svg',
  hasError: false,
  isComplete: false,
  isNavigable: true,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: false,
  visited: false,
};

export const SearchQuotePageRepresentation: Page = {
  id: 'SearchQuote',
  url: '/home/search',
  name: 'Search Quote',
  index: -1,
  subcategory: PageSubCategory.GLOBAL,
  hasError: false,
  isComplete: false,
  isNavigable: true,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: false,
  visited: false,
};

export const CompraterProducerSelectionPageRepresentation: Page = {
  id: 'CompraterProducerSelection',
  url: '/search/compRater',
  name: 'Retrieve Comprater Quote',
  index: -1,
  subcategory: PageSubCategory.GLOBAL,
  hasError: false,
  isComplete: false,
  isNavigable: true,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: false,
  visited: false,
};

export const PNIPageRepresentation: Page = {
  id: 'PNI',
  url: '/quote/pni',
  name: 'Primary & products',
  index: 0,
  icon: '/assets/images/nav-bar/icon-pni.svg',
  completedIcon: '/assets/images/nav-bar/member-completed.svg',
  subcategory: PageSubCategory.MEMBERS,
  hasError: false,
  isComplete: false,
  isNavigable: true,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: true,
  showNavBar: true,
  visited: false,
};

export const PeoplePageRepresentation: Page = {
  id: 'People',
  url: '/quote/people',
  name: 'Policy members',
  index: MEMBER_PAGE_INDEX,
  icon: '/assets/images/nav-bar/icon-policy-members.svg',
  completedIcon: '/assets/images/nav-bar/icon-policy-members.svg',
  subcategory: PageSubCategory.MEMBERS,
  hasError: false,
  isComplete: false,
  isNavigable: true,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: true,
  showNavBar: true,
  visited: false,
};

export const VehiclesPageRepresentation: Page = {
  id: 'Vehicles',
  url: '/quote/vehicle',
  name: 'Auto',
  index: 20,
  icon: '/assets/images/nav-bar/icon-auto.svg',
  completedIcon: '/assets/images/nav-bar/car-status-check.svg',
  subcategory: PageSubCategory.BUILD_QUOTE,
  hasError: false,
  isComplete: false,
  isNavigable: false,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: true,
  associatedProducts: ['PersonalAuto'],
  visited: false,
};

export const MsaPageRepresentation: Page = {
  id: 'MSA',
  url: '/quote/powersports/msa',
  name: 'Powersports / ATV',
  index: 25,
  icon: '/assets/images/nav-bar/icon-motorcycle.svg',
  completedIcon: '/assets/images/nav-bar/car-status-check.svg',
  subcategory: PageSubCategory.BUILD_QUOTE,
  hasError: false,
  isComplete: false,
  isNavigable: false,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: true,
  associatedProducts: ['MSA'],
  visited: false,
};

export const HomeownerPageRepresentation: Page = {
  id: 'Homeowner',
  url: '/quote/property/homeowner',
  name: 'Homeowner',
  index: 30,
  icon: '/assets/images/nav-bar/icon-home.svg',
  completedIcon: '/assets/images/nav-bar/home-complete.svg',
  subcategory: PageSubCategory.BUILD_QUOTE,
  hasError: false,
  isComplete: false,
  isNavigable: false,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: true,
  associatedProducts: ['Homeowner'],
  visited: false,
};

export const CondoPageRepresentation: Page = {
  id: 'Condo',
  url: '/quote/property/condo',
  name: 'Condominium',
  index: 30,
  icon: '/assets/images/nav-bar/icon-condo.svg',
  completedIcon: '/assets/images/nav-bar/icon-condo.svg',
  subcategory: PageSubCategory.BUILD_QUOTE,
  hasError: false,
  isComplete: false,
  isNavigable: false,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: true,
  associatedProducts: ['Condominium'],
  visited: false,
};

export const TenantPageRepresentation: Page = {
  id: 'Tenant',
  url: '/quote/property/tenant',
  name: 'Renters',
  index: 30,
  icon: '/assets/images/nav-bar/icon-renters.svg',
  completedIcon: '/assets/images/nav-bar/icon-renters.svg',
  subcategory: PageSubCategory.BUILD_QUOTE,
  hasError: false,
  isComplete: false,
  isNavigable: false,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: true,
  associatedProducts: ['Tenant'],
  visited: false,
};

export const DwellingFirePageRepresentation: Page = {
  id: 'DwellingFire',
  url: '/quote/dwelling-fire',
  name: 'Dwelling Fire',
  index: 48,
  icon: '/assets/images/nav-bar/icon-dwelling-fire.svg',
  completedIcon: '/assets/images/nav-bar/icon-dwelling-fire.svg',
  subcategory: PageSubCategory.BUILD_QUOTE,
  hasError: false,
  isComplete: false,
  isNavigable: false,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: true,
  associatedProducts: ['DwellingFire'],
  visited: false,
};

export const BoatPageRepresentation: Page = {
  id: 'Boat',
  url: '/quote/boat',
  name: 'Boat',
  index: 26,
  icon: '/assets/images/nav-bar/icon-boat.svg',
  completedIcon: '/assets/images/nav-bar/icon-boat.svg',
  subcategory: PageSubCategory.BUILD_QUOTE,
  hasError: false,
  isComplete: false,
  isNavigable: false,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: true,
  associatedProducts: ['Boat'],
  visited: false,
};

export const RVPageRepresentation: Page = {
  id: 'RV',
  url: '/quote/rv',
  name: 'RV',
  index: 27,
  icon: '/assets/images/nav-bar/icon-rv.svg',
  completedIcon: '/assets/images/nav-bar/icon-rv.svg',
  subcategory: PageSubCategory.BUILD_QUOTE,
  hasError: false,
  isComplete: false,
  isNavigable: false,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: true,
  associatedProducts: ['RV'],
  visited: false,
};

export const UmbrellaPageRepresentation: Page = {
  id: 'Umbrella',
  url: '/quote/umbrella',
  name: 'Umbrella',
  index: 40,
  icon: '/assets/images/nav-bar/icon-umbrella.svg',
  completedIcon: '/assets/images/nav-bar/icon-umbrella.svg',
  subcategory: PageSubCategory.BUILD_QUOTE,
  hasError: false,
  isComplete: false,
  isNavigable: false,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: true,
  associatedProducts: ['PersonalUmbrella'],
  visited: false,
};

export const CoveragesPageRepresentation: Page = {
  id: 'Coverages',
  url: '/quote/coverages',
  name: 'Coverages & billing',
  index: 50,
  icon: '/assets/images/nav-bar/icon-coverages.svg',
  completedIcon: '/assets/images/nav-bar/coverages-completed.svg',
  subcategory: PageSubCategory.COVERAGES,
  hasError: false,
  isComplete: false,
  isNavigable: false,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: true,
  showNavBar: true,
  visited: false,
};

export const HubPageRepresentation: Page = {
  id: 'HUB',
  url: '/hub',
  name: 'Quote summary',
  index: HUB_PAGE_INDEX,
  icon: '/assets/images/nav-bar/icon-finalize-quote.svg',
  completedIcon: '/assets/images/nav-bar/folder-check.svg',
  subcategory: PageSubCategory.FINALIZE_QUOTE,
  hasError: false,
  isComplete: false,
  isNavigable: false,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: true,
  showNavBar: true,
  visited: false,
};

export const BillingPaymentsPageRepresentation: Page = {
  id: 'Billing',
  url: '/billing',
  name: 'Pay & bind',
  index: 70,
  icon: '/assets/images/nav-bar/checkmark.svg',
  completedIcon: '/assets/images/nav-bar/folder-check.svg',
  subcategory: PageSubCategory.BILLING_PAYMENT,
  hasError: false,
  isComplete: false,
  isNavigable: false,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: true,
  showNavBar: true,
  visited: false,
};

export const SummaryPageRepresentation: Page = {
  id: 'Summary',
  url: '/summary',
  name: 'Policy success',
  index: 80,
  icon: '/assets/images/nav-bar/checkmark.svg',
  completedIcon: '/assets/images/nav-bar/folder-check.svg',
  subcategory: PageSubCategory.GLOBAL,
  hasError: false,
  isComplete: false,
  isNavigable: true,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: true,
  visited: false,
};

export const GoToPolicyCenterRepresentation: Page = {
  id: 'PolicyCenter',
  url: '',
  name: 'Go To Policy Center',
  index: 90,
  icon: '/assets/images/nav-bar/icon-suitcase.svg',
  completedIcon: '/assets/images/nav-bar/folder-check.svg',
  subcategory: PageSubCategory.GLOBAL,
  hasError: false,
  isComplete: false,
  isNavigable: true,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: true,
  visited: false,
};

export const HelpPageRepresentation: Page = {
  id: 'Help',
  url: '/help',
  name: 'Help',
  index: -1,
  icon: '/assets/images/nav-bar/help-colorized.svg',
  subcategory: PageSubCategory.GLOBAL,
  hasError: false,
  isComplete: false,
  isNavigable: true,
  hasReviewWarning: false,
  hasWarning: false,
  enabled: false,
  showNavBar: true,
  visited: false,
};

export const AccountInfoPageRepresentation: Page = {
  id: 'AccountInfo',
  url: '',
  name: 'Account information',
  index: -2,
  icon: '/assets/images/nav-bar/document-outline.svg',
  subcategory: PageSubCategory.GLOBAL,
  hasError: false,
  isComplete: false,
  isNavigable: false,
  hasWarning: false,
  hasReviewWarning: false,
  enabled: false,
  showNavBar: true,
  visited: false
}

export enum PageIndex {
  'maintenance' = MaintenancePageRepresentation.index,
  'splashpage' = SplashPagePageRepresentation.index,
  'startquote' = StartQuotePageRepresentation.index,
  'searchquote' = SearchQuotePageRepresentation.index,
  'compraterproducerselection' = CompraterProducerSelectionPageRepresentation.index,
  'pni' = PNIPageRepresentation.index,
  'vehicles' = VehiclesPageRepresentation.index,
  'msa' = MsaPageRepresentation.index,
  'people' = PeoplePageRepresentation.index,
  'homeowner' = HomeownerPageRepresentation.index,
  'condo' = CondoPageRepresentation.index,
  'tenant' = TenantPageRepresentation.index,
  'umbrella' = UmbrellaPageRepresentation.index,
  'coverages' = CoveragesPageRepresentation.index,
  'hub' = HubPageRepresentation.index,
  'billing' = BillingPaymentsPageRepresentation.index,
  'summary' = SummaryPageRepresentation.index,
  'help' = HelpPageRepresentation.index,
  'accountinfo' = AccountInfoPageRepresentation.index,
}

export const allPages = [
  MaintenancePageRepresentation,
  SplashPagePageRepresentation,
  StartQuotePageRepresentation,
  SearchQuotePageRepresentation,
  CompraterProducerSelectionPageRepresentation,
  PNIPageRepresentation,
  PeoplePageRepresentation,
  VehiclesPageRepresentation,
  HomeownerPageRepresentation,
  CondoPageRepresentation,
  TenantPageRepresentation,
  MsaPageRepresentation,
  UmbrellaPageRepresentation,
  DwellingFirePageRepresentation,
  BoatPageRepresentation,
  RVPageRepresentation,
  CoveragesPageRepresentation,
  HubPageRepresentation,
  BillingPaymentsPageRepresentation,
  SummaryPageRepresentation,
  GoToPolicyCenterRepresentation,
  HelpPageRepresentation,
  AccountInfoPageRepresentation,
];

export const getPageByUrl = (url: string): Page => {
  const foundPage = allPages.find((page: Page) => page.url === url);
  if (!foundPage) {
    return SplashPagePageRepresentation;
  } else {
    return foundPage;
  }
};

export const getPageRepresentationByProductId = (
  productId: ProductType
): Page => {
  switch (productId) {
    case 'PersonalAuto':
      return VehiclesPageRepresentation;
    case 'MSA':
      return MsaPageRepresentation;
    case 'Homeowner':
      return HomeownerPageRepresentation;
    case 'Condominium':
      return CondoPageRepresentation;
    case 'Tenant':
      return TenantPageRepresentation;
    case 'PersonalUmbrella':
      return UmbrellaPageRepresentation;
    case 'DwellingFire':
      return DwellingFirePageRepresentation;
    case 'Boat':
      return BoatPageRepresentation;
    case 'RV':
      return RVPageRepresentation;
    default:
      return VehiclesPageRepresentation;
  }
};

export type NavigationEntities = {
  [K in PageIdentifier]: Page;
};

export const createNavigationStateEntities = (): NavigationEntities =>
  allPages.reduce((a: NavigationEntities, b: Page) => {
    a[b.id] = b;
    return a;
  }, {} as NavigationEntities);

export const pagesToShowAnnouncement = allPages.filter(
  (p) =>
    p !== SplashPagePageRepresentation && p !== MaintenancePageRepresentation
);
