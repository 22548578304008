import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromBilling from '@core/store/entities/billing/billing.reducer';
import * as fromProducts from '@core/store/entities/product/product.selectors';
import * as fromAgents from '@core/store/entities/agency/agency.selector';
import { ProductType } from '@core/models/api/dsm-types';
import { DownPaymentExperienceResponse } from '@core/models/api/response/down-payment-experience-response.model';
import { ProductModel } from '../product/product.model';

export const buildDownPaymentExperienceRequest = (productIds: ProductType[]) =>
  createSelector(fromProducts.getSelectedProducts, (products) => {
    if (!productIds.length) {
      return null;
    }
    return {
      distributionChannel: 'INTERNET',
      policiesAndQuotes: products
        .filter((product) => productIds.indexOf(product.type) >= 0)
        .map((product) => ({
          policyNumber: product.policyNumber,
          quoteId: product.quoteId || '',
        })),
    };
  });

export const getBillingState = createSelector(
  fromCore.getCoreState,
  (state: fromCore.CoreState) => state.billing
);

export const getBillingInfo = createSelector(
  getBillingState,
  (state) => state.billing
);

export const getDownPaymentLoaded = createSelector(
  getBillingState,
  fromBilling.getDownPaymentLoaded
);

export const getDownPaymentLoading = createSelector(
  getBillingState,
  fromBilling.getDownPaymentLoading
);

export const getDownPaymentFailed = createSelector(
  getBillingState,
  fromBilling.getDownPaymentFailed
);

function addNumberLikeStrings(a: string, b: string): string {
  if (typeof a !== 'string') {
    a = '0';
  }
  if (typeof b !== 'string') {
    b = '0';
  }
  return (parseFloat(a.trim()) + parseFloat(b.trim())).toFixed(2);
}

export const getAllDownPayments = createSelector(
  getBillingState,
  (state) => state.downPayments
);

export const getDownPaymentForProducts = (products: ProductModel[]) =>
  createSelector(getBillingState, (state) => {
    return state.downPayments.find((dp) => {
      if (dp.policyLevel.length !== products.length) {
        return false;
      }
      for (const policyLevel of dp.policyLevel) {
        const policyNumber =
          policyLevel.displayPolicyNumber || policyLevel.policyNumber;
        const product = products.find(
          (p) => p.quoteId === policyNumber || p.policyNumber === policyNumber
        );
        if (!product) {
          return false;
        }
      }
      return true;
    });
  });

export const getDownPayment = createSelector(getBillingState, (state) => {
  if (state.downPayments.length < 1) {
    return {
      eligiblePayplansAndPayMethods: [],
      policyLevel: [],
    };
  }
  if (state.downPayments.length === 1) {
    return state.downPayments[0];
  }
  const output: DownPaymentExperienceResponse = {
    eligiblePayplansAndPayMethods: [],
    policyLevel: [],
  };
  for (const input of state.downPayments) {
    for (const inPlan of input.eligiblePayplansAndPayMethods || []) {
      const outPlan = output.eligiblePayplansAndPayMethods.find(
        (p) => p.payMethod === inPlan.payMethod && p.payPlan === inPlan.payPlan
      );
      if (outPlan) {
        outPlan.downPaymentPremium = addNumberLikeStrings(
          outPlan.downPaymentPremium,
          inPlan.downPaymentPremium
        );
        outPlan.downPaymentFee = addNumberLikeStrings(
          outPlan.downPaymentFee,
          inPlan.downPaymentFee
        );
        outPlan.totalDownPaymentWithFees = addNumberLikeStrings(
          outPlan.totalDownPaymentWithFees,
          inPlan.totalDownPaymentWithFees
        );
        outPlan.monthlyFees = addNumberLikeStrings(
          outPlan.monthlyFees,
          inPlan.monthlyFees
        );
        outPlan.monthlyInstallment = addNumberLikeStrings(
          outPlan.monthlyInstallment,
          inPlan.monthlyInstallment
        );
        outPlan.fullPayAmount = addNumberLikeStrings(
          outPlan.fullPayAmount,
          inPlan.fullPayAmount
        );
      } else {
        output.eligiblePayplansAndPayMethods.push({ ...inPlan });
      }
    }
    for (const inProduct of input.policyLevel || []) {
      // There shouldn't be overlap between the products of multiple
      // downPayment responses, but allowing it for consistency's sake.
      const outProduct = output.policyLevel.find(
        (p) =>
          p.policyNumber === inProduct.policyNumber &&
          p.displayPolicyNumber === inProduct.displayPolicyNumber &&
          p.product === inProduct.product
      );
      if (outProduct) {
        outProduct.downpayment = addNumberLikeStrings(
          outProduct.downpayment,
          inProduct.downpayment
        );
        outProduct.monthlyInstallment = addNumberLikeStrings(
          outProduct.monthlyInstallment,
          inProduct.monthlyInstallment
        );
        outProduct.termPremium = addNumberLikeStrings(
          outProduct.termPremium,
          inProduct.termPremium
        );
      } else {
        output.policyLevel.push({ ...inProduct });
      }
    }
  }
  return output;
});

export const getAgent = createSelector(
  fromAgents.getAgency,
  (agency) => agency
);
