import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { take, map, switchMap, tap } from 'rxjs/operators';
import { QuoteService } from '@core/services/quote.service';
import { ProductsService } from '../services/products.service';
import { TaskService } from '../services/task.service';
import { TaskUtils } from '../store/entities/task/utils/task.util';
import { SessionService } from '@core/services/session.service';
import { ProductModel } from '@entities/product/product.model';
import { TaskModel } from '@entities/task/task.model';

@Injectable({
  providedIn: 'root',
})
export class HubPageGuard {
  constructor(
    private quoteService: QuoteService,
    private productsService: ProductsService,
    private taskService: TaskService,
    private sessionService: SessionService
  ) {}

  canActivate(): Observable<boolean> {
    return this.checkStore();
  }

  private checkStore(): Observable<boolean> {
    this.sessionService.dispatchHubLoaded();
    return combineLatest([
      this.taskService.getIncompleteTasks(),
      this.productsService.getSelectedProducts(),
    ]).pipe(
      take(1),
      tap(([tasks, products]) =>
        this.rateDraftProductsIfTasksPermit(tasks, products)
      ),
      map(() => true)
    );
  }

  private rateDraftProductsIfTasksPermit(
    tasks: TaskModel[],
    products: ProductModel[]
  ): void {
    const draftTasks = tasks.filter((t) => t.ratingStatusOrdinal === 'Draft');
    for (const product of products) {
      if (product.quoteStatus !== 'Draft') {
        continue;
      }
      if (
        draftTasks.find((t) =>
          TaskUtils.isProductType(t.productType, product.type)
        )
      ) {
        continue;
      }
      /* if (TaskUtils.anyDraftTaskExistsWithUmbrella(tasks, product.type)) {
        continue;
      } */
      this.quoteService.dispatchRateQuote(product.type, 'quote');
    }
  }
}
