import { VehicleModel } from '@core/models/views/vehicle.model';
import { DateUtils } from '@shared/utils/date.utils';
import { GeneralUtils } from '@shared/utils/general.utils';
import { ProductModel } from '../../product/product.model';
import { VehicleEntity } from '../../vehicle/vehicle.entity';
import { TaskVerbiage } from '../task-verbiage';
import { CurrentCarrierEntity } from '../../current-carrier/current-carrier.reducer';
import { TaskValidators } from '../task-validators';
import { RuleTemplate } from '../task.model';

export const VehicleRules = {
  auto: {
    product: [
      {
        rule: TaskValidators.nonEntityRequiredField<ProductModel>(
          'isAssignedRiskPlan',
          'Please indicate if this policy is part of a state assigned risk plan',
          'Draft',
          'PersonalAuto',
          'assigned-risk-plan'
        ),
        feature: 'assignedRiskPlan',
      },
    ] as RuleTemplate[],

    vehicles: [
      {
        rule: TaskValidators.atLeastOne<VehicleEntity>(
          'Vehicle',
          TaskVerbiage.VEHICLE_REQUIRED,
          'Draft',
          'PersonalAuto'
        ),
      },
    ] as RuleTemplate[],
    vehicle: [
      {
        rule: TaskValidators.requiredField<VehicleEntity, VehicleModel>(
          'Please provide odometer reading',
          'Draft',
          'PersonalAuto',
          'odometerReading',
          'odometer'
        ),
        feature: 'vehicleOdometerReading',
      },
      {
        rule: TaskValidators.requiredField<VehicleEntity, VehicleModel>(
          'Vehicle identification number (VIN) is required',
          'Quoted',
          'PersonalAuto',
          'vin',
          'vin'
        ),
        quoteStatus: 'Quoted',
      },
      {
        rule: TaskValidators.unmaskedVinRequired(),
        quoteStatus: 'Quoted',
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity, VehicleModel>(
          (entity) => entity.vehicleType === 'auto' && !entity.annualMiles,
          'Vehicle annual mileage is required for an initial rate',
          'Draft',
          'PersonalAuto',
          'annualMiles',
          'annual-miles'
        ),
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity>(
          (entity) =>
            entity.vehicleType === 'auto'
              ? DateUtils.yearWithinThreeOfNow(entity.year || 0) &&
                !GeneralUtils.isBoolean(entity?.isPurchasedNew)
              : false,
          'Purchased new status is required',
          'Draft',
          'PersonalAuto',
          'isPurchasedNew',
          'is-purchased-new'
        ),
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity, VehicleModel>(
          (entity) =>
            entity.vehicleType === 'auto' && !entity.isRegisteredInState,
          'Vehicle registered in state is required',
          'Draft',
          'PersonalAuto',
          'isRegisteredInState',
          'registered-in-state'
        ),
        feature: 'vehicleRegisteredInState',
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity>(
          (entity) =>
            !!entity.eligibleDiscounts?.find(
              ({ eligibleDiscountId, qualifyingInformation }) =>
                eligibleDiscountId === 'AntiTheft' &&
                qualifyingInformation?.selectedAntiTheft?.length === 0
            ),
          'Please indicate anti-theft devices',
          'Quoted',
          'PersonalAuto',
          'antiTheftDevices',
          'anti-theft'
        ),
        feature: 'antiTheftDevices',
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity, VehicleModel>(
          (entity) =>
            entity.vehicleType === 'auto' ? !entity.primaryUse : false,
          'Vehicle primary use is required',
          'Draft',
          'PersonalAuto',
          'primaryUse',
          'primary-use'
        ),
        feature: 'vehicleDailyUseQuestions',
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity, VehicleModel>(
          (entity) =>
            entity.vehicleType === 'auto' && !entity.registeredOwners?.length,
          'Vehicle registered owner is required',
          'Draft',
          'PersonalAuto',
          'registeredOwners',
          'registered-owner'
        ),
        feature: 'vehicleRegisteredOwners',
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity, VehicleModel>(
          (vehicle) =>
            GeneralUtils.isBoolean(vehicle.hasPriorDamage) &&
            !!vehicle.hasPriorDamage &&
            (!vehicle.damageLocation ||
              typeof vehicle.damageLocation !== 'string' ||
              !vehicle.damageLocation?.length),
          'Add Vehicle Prior damage location information',
          'Draft',
          'PersonalAuto',
          'damageLocation',
          'damage-location'
        ),
      },
    ] as RuleTemplate[],
  },
  msa: {
    product: [] as RuleTemplate[],
    vehicles: [
      {
        rule: TaskValidators.atLeastOne<VehicleEntity>(
          'Vehicle',
          TaskVerbiage.VEHICLE_REQUIRED,
          'Draft',
          'MSA'
        ),
      },
    ] as RuleTemplate[],
    vehicle: [
      {
        rule: TaskValidators.requiredField<VehicleEntity>(
          'Vehicle identification number (VIN) is required',
          'Quoted',
          'MSA',
          'vin',
          'vin'
        ),
        quoteStatus: 'Quoted',
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity>(
          (entity) =>
            DateUtils.yearWithinThreeOfNow(entity?.year || 0) &&
            entity?.vehicleType !== 'UtilityTrailer' &&
            !GeneralUtils.isBoolean(entity?.isPurchasedNew),
          'Purchased new status is required',
          'Draft',
          'MSA',
          'isPurchasedNew',
          'is-purchased-new'
        ),
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity>(
          (entity) =>
            !!entity.eligibleDiscounts?.find(
              ({ eligibleDiscountId, qualifyingInformation }) =>
                eligibleDiscountId === 'AntiTheft' &&
                qualifyingInformation?.selectedAntiTheft?.length === 0
            ),
          'Please indicate anti-theft devices',
          'Quoted',
          'MSA',
          'antiTheftDevices',
          'anti-theft'
        ),
        feature: 'antiTheftDevices',
      },
      {
        rule: TaskValidators.requiredField<VehicleEntity, VehicleModel>(
          'Vehicle registered owner is required',
          'Draft',
          'MSA',
          'registeredOwners',
          'registered-owner'
        ),
        feature: 'vehicleRegisteredOwners',
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity>(
          (entity) =>
            !['UtilityTrailer'].includes(entity?.vehicleType || 'Motorcycle') &&
            !['LawnandGardenTractors'].includes(entity?.subType || 'Other') &&
            !entity?.cubicCentimeters,
          'Vehicle cubic centimeters is required',
          'Draft',
          'MSA',
          'cubicCentimeters',
          'cubic-centimeters'
        ),
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity>(
          (entity) =>
            entity?.subType === 'LawnandGardenTractors' && !entity?.horsepower,
          'Vehicle horsepower is required',
          'Draft',
          'MSA',
          'horsepower',
          'horsepower'
        ),
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity>(
          (entity) =>
            (entity?.subType === 'LawnandGardenTractors' ||
              entity?.subType === 'DuneBuggy') &&
            !entity?.marketValue,
          'Vehicle market value is required',
          'Draft',
          'MSA',
          'marketValue',
          'market-value'
        ),
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity>(
          (entity) =>
            entity?.vehicleType === 'UtilityTrailer' && !entity?.value,
          'Vehicle value is required',
          'Draft',
          'MSA',
          'value',
          'value'
        ),
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity>(
          (entity) =>
            entity?.vehicleType === 'Motorcycle' &&
            entity?.subType === 'Trike' &&
            !entity?.agreedValue,
          'Vehicle agreed value is required',
          'Draft',
          'MSA',
          'agreedValue',
          'agreed-value'
        ),
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity, VehicleModel>(
          (vehicle) =>
            GeneralUtils.isBoolean(vehicle.hasPriorDamage) &&
            !!vehicle.hasPriorDamage &&
            (!vehicle.damageLocation ||
              typeof vehicle.damageLocation !== 'string' ||
              !vehicle.damageLocation?.length),
          'Add Vehicle prior damage location information',
          'Draft',
          'MSA',
          'damageLocation',
          'damage-location'
        ),
      },
    ] as RuleTemplate[],
  },
  boat: {
    product: [] as RuleTemplate[],
    vehicles: [
      {
        rule: TaskValidators.atLeastOne<VehicleEntity>(
          'Vehicle',
          TaskVerbiage.VEHICLE_REQUIRED,
          'Draft',
          'Boat'
        ),
      },
    ] as RuleTemplate[],
    vehicle: [
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity, VehicleModel>(
          (entity) => entity?.vehicleType === 'boat' && !entity?.hin,
          'Hull identification number (HIN) is required',
          'Quoted',
          'Boat',
          'hin',
          'hin'
        ),
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity, VehicleModel>(
          (entity) =>
            entity?.vehicleType === 'motor' && !entity?.motorSerialNumber,
          'Serial number is required',
          'Quoted',
          'Boat',
          'serialNumber',
          'serialNumber'
        ),
        quoteStatus: 'Quoted',
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity>(
          (entity) =>
            DateUtils.yearWithinThreeOfNow(entity?.year || 0) &&
            entity?.vehicleType !== 'UtilityTrailer' &&
            entity?.vehicleType !== 'motor' &&
            !GeneralUtils.isBoolean(entity?.isPurchasedNew),
          'Purchased new status is required',
          'Draft',
          'Boat',
          'isPurchasedNew',
          'is-purchased-new'
        ),
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity>(
          (entity) =>
            entity?.vehicleType === 'UtilityTrailer' && !entity?.value,
          'Market value is required',
          'Draft',
          'Boat',
          'marketValue',
          'marketValue'
        ),
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity, VehicleModel>(
          (vehicle) =>
            GeneralUtils.isBoolean(vehicle.hasPriorDamage) &&
            !!vehicle.hasPriorDamage &&
            (!vehicle.damageLocation ||
              typeof vehicle.damageLocation !== 'string' ||
              !vehicle.damageLocation?.length),
          'Add Vehicle prior damage location information',
          'Draft',
          'Boat',
          'additionalInformation',
          'prior-damage-location'
        ),
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity>(
          (entity) =>
            (entity.trailer?.year && !entity.trailer?.serialNumber) || false,
          'Trailer serial number is required',
          'Quoted',
          'Boat',
          'serialNumber',
          'boat-trailer-input'
        ),
      },
    ] as RuleTemplate[],
  },
  rv: {
    product: [] as RuleTemplate[],
    vehicles: [
      {
        rule: TaskValidators.atLeastOne<VehicleEntity>(
          'Vehicle',
          TaskVerbiage.VEHICLE_REQUIRED,
          'Draft',
          'RV'
        ),
      },
    ] as RuleTemplate[],
    vehicle: [
      {
        rule: TaskValidators.requiredField<VehicleEntity, VehicleModel>(
          'Vehicle identification number (VIN) is required',
          'Quoted',
          'RV',
          'vin',
          'vin'
        ),
        quoteStatus: 'Quoted',
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity>(
          (entity) =>
            entity?.vehicleType === 'UtilityTrailer' && !entity?.marketValue,
          'Market value is required',
          'Draft',
          'RV',
          'marketValue',
          'marketValue'
        ),
      },
      {
        rule: TaskValidators.requiredFieldIf<VehicleEntity, VehicleModel>(
          (vehicle) =>
            GeneralUtils.isBoolean(vehicle.hasPriorDamage) &&
            !!vehicle.hasPriorDamage &&
            (!vehicle.damageLocation ||
              typeof vehicle.damageLocation !== 'string' ||
              !vehicle.damageLocation?.length),
          'Add Vehicle prior damage location information',
          'Draft',
          'RV',
          'damageLocation',
          'damage-location'
        ),
      },
    ] as RuleTemplate[],
  },
  currentCarrier: {
    auto: [
      {
        rule: TaskValidators.requiredField<CurrentCarrierEntity>(
          'Current carrier information is required',
          'Draft',
          'PersonalAuto',
          'currentCarrierName',
          'current-carrier'
        ),
        feature: 'currentCarrierRequired',
      },
    ] as RuleTemplate[],
    msa: [
      {
        rule: TaskValidators.requiredField<CurrentCarrierEntity>(
          'Current carrier information is required',
          'Draft',
          'MSA',
          'currentCarrierName',
          'current-carrier'
        ),
        feature: 'currentCarrierRequired',
      },
    ] as RuleTemplate[],
    boat: [
      {
        rule: TaskValidators.requiredField<CurrentCarrierEntity>(
          'Current carrier information is required',
          'Draft',
          'Boat',
          'currentCarrierName',
          'current-carrier'
        ),
        feature: 'currentCarrierRequired',
      },
    ] as RuleTemplate[],
    rv: [
      {
        rule: TaskValidators.requiredField<CurrentCarrierEntity>(
          'Current carrier information is required',
          'Draft',
          'RV',
          'currentCarrierName',
          'current-carrier'
        ),
        feature: 'currentCarrierRequired',
      },
    ] as RuleTemplate[],
  },
};
