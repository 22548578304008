import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TelematicsRecommendationResponse } from '@core/models/api/response/telematics-recommendation-response.model';
import { TelematicsService } from '@core/services/telematics.service';
import {
  ExtendedTelematicsVehicle,
  MobileEnrollment,
  VehicleEnrollment,
} from '@core/store/entities/telematics/telematics.model';
import { TELEMATICS_MILES_THRESHOLD } from '@shared/constants/app-constants';
import { Nullable } from '@shared/utils/type.utils';
import { BillingFormService } from '@forms-store/services/billing-form.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TelematicsUtils } from '@shared/utils/telematics.utils';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { PriceSummaryUtils } from '@shared/utils/hub/price-summary/price-summary.util';
import { VehicleService } from '@core/services/vehicle.service';
import { BillingService } from '@core/services/billing.service';
import { ProductsService } from '@core/services/products.service';
import { DocumentDeliveryType } from '@core/models/api/dsm-types';
import { QuoteService } from '@core/services/quote.service';

@Component({
  selector: 'nwx-telematics-change-modal',
  templateUrl: './telematics-change-modal.component.html',
  styleUrls: [
    '../../../../scss/_custom-modal.scss',
    './telematics-change-modal.component.scss',
    '../../../../scss/_custom-tile.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelematicsChangeModalComponent implements OnInit, OnDestroy {
  // Must be provided by whoever owns the NgbModal:
  vehicle!: ExtendedTelematicsVehicle;
  currentProgram!:
    | 'SmartMiles'
    | 'SmartRide'
    | 'SmartRideInstant'
    | 'ConnectedCar'
    | 'SmartMilesConnectedCar' // this value only for this component
    | 'SelfReported'
    | 'NotEnrolled';
  enrollment: Nullable<VehicleEnrollment | MobileEnrollment>;
  isMobile = false;

  currentBillingPlan = '';
  recommendation: Nullable<TelematicsRecommendationResponse> = null;
  isSmartMilesEligibleState = false;
  isSelfReportedEligibleState = false;
  isDeviceOnlyState = false;
  numVehicles = 0;
  docDelPreference: Nullable<DocumentDeliveryType>;
  smartmilesMessages: { topic: string; message: string }[] = [];

  form: FormGroup;
  disableRemove = false;
  disableSmartMiles = false;
  showSmartRideInstant = false;
  showSmartRideConnectedCar = false;
  showSmartMilesConnectedCar = false;
  showSmartRideDeviceLabel = false;
  billingPlan = '';
  private unsubscribe$ = new Subject<void>();

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private telematicsService: TelematicsService,
    private vehicleService: VehicleService,
    private billingService: BillingService,
    private productsService: ProductsService,
    private quoteService: QuoteService
  ) {
    this.form = this.buildForm();
    this.telematicsService
      .getCurrentRecommendation()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((recommendation) => (this.recommendation = recommendation));
    this.telematicsService
      .isSmartMilesEligibleState()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v) => (this.isSmartMilesEligibleState = v));
    this.telematicsService
      .isSelfReportedEligibleState()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v) => (this.isSelfReportedEligibleState = v));
    this.telematicsService
      .isDeviceOnlyState()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v) => (this.isDeviceOnlyState = v));
    this.vehicleService
      .getSavedVehiclesByProductType('PersonalAuto')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicles) => (this.numVehicles = vehicles.length));
    this.billingService
      .getBillingPlan()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((billingPlan) => (this.currentBillingPlan = billingPlan));
    this.productsService
      .getDocumentDeliveryPreference()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (docDelPreference) => (this.docDelPreference = docDelPreference)
      );
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe(() => this.collectWarnings());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.checkAllInputs();
  }

  private checkAllInputs(): void {
    this.checkInputForSMCC();
    this.selectedProgram?.patchValue(this.currentProgram);
    this.showSmartRideInstant = this.isSmartRideInstantAvailable();
    this.showSmartRideDeviceLabel = this.isSmartRideDeviceAvailable();
    this.showSmartRideConnectedCar = this.isSmartRideConnectedCarAvailable();
    this.showSmartMilesConnectedCar = this.isSmartMilesConnectedCarAvailable();
    this.disableRemove = this.isMobile && this.numVehicles > 1;
    this.disableSmartMiles = !this.isSmartMilesEligibleState;
    this.billingPlan = this.currentBillingPlan;
    if (this.vehicle.annualMiles && this.isSmartMilesEligibleState) {
      this.disableSmartMiles =
        +this.vehicle.annualMiles > TELEMATICS_MILES_THRESHOLD;
    }
  }

  closeModal(): void {
    this.modal.close('close');
  }

  setSelectedProgram(program: string, event: Event): void {
    event.preventDefault();
    this.form.markAsTouched();
    this.selectedProgram?.patchValue(program);
  }

  onRadioClicked(program: string, event: Event): void {
    event.stopPropagation();
    this.setSelectedProgram(program, event);
  }

  updateProgram(): void {
    const { valid, value } = this.form;
    let dataCollectionMethod = 'Device';
    if (!valid) {
      this.selectedProgram?.markAsTouched();
      return;
    }
    if (value.selectedProgram === this.currentProgram) {
      this.closeModal();
    }
    if (value.selectedProgram === 'SmartMilesConnectedCar') {
      value.selectedProgram = 'SmartMiles';
      dataCollectionMethod = 'ConnectedCar';
    }
    if (
      value.selectedProgram === 'SmartMiles' &&
      this.docDelPreference === 'USMail'
    ) {
      this.productsService.updateDocDeliveryOption({
        docDelPreference: 'OnlineAccountAccess',
      });
      this.productsService
        .getSelectedProducts()
        .pipe(take(1))
        .subscribe((products) => {
          products.forEach((product) => {
            this.quoteService.dispatchUpdateQuote(product.type, {
              docDelPreference: 'OnlineAccountAccess',
            });
          });
        });
    }
    if (this.isMobile && value.selectedProgram === 'NotEnrolled') {
      this.telematicsService.dispatchRemoveEnrollment();
    } else {
      this.telematicsService.dispatchUpdateEnrollment(
        value.selectedProgram,
        this.vehicle.vehicleId,
        dataCollectionMethod as 'Device' | 'ConnectedCar'
      );
    }
    this.closeModal();
  }

  isSmartMilesConnectedCarAvailable(): boolean {
    return (
      this.recommendation?.qualifyingInformation?.vehicles?.some((vehicle) => {
        return (
          vehicle.vehicleId?.toString() === this.vehicle.vehicleId?.toString() &&
          vehicle.availablePrograms?.some(
            (program) =>
              program.name === 'SmartMiles' &&
              program.dataCollectionMethod === 'ConnectedCar'
          )
        );
      }) || false
    );
  }

  isSmartRideConnectedCarAvailable(): boolean {
    return (
      (this.vehicle.isConnectable &&
        (!this.isDeviceOnlyState || this.isCA())) ??
      false
    );
  }

  isSmartRideInstantAvailable(): boolean {
    return (
      this.recommendation?.qualifyingInformation?.vehicles?.some((vehicle) => {
        return (
          vehicle.vehicleId?.toString() === this.vehicle.vehicleId?.toString() &&
          vehicle.availablePrograms?.some(
            (program) =>
              program.name === 'SmartRideInstant' && program.recommended
          )
        );
      }) || false
    );
  }

  isSmartRideDeviceAvailable(): boolean {
    const isVehicleEnrollment = TelematicsUtils.isVehicleEnrollment(
      this.enrollment
    );
    if (isVehicleEnrollment) {
      const otherDeviceVehicles = (
        this.enrollment as VehicleEnrollment
      ).vehicles.filter(
        (v) =>
          v.vehicleId?.toString() !== this.vehicle.vehicleId?.toString() &&
          (v.enrollmentStatus === 'VerifiedScore' ||
            (v.enrollmentStatus === 'Enrolled' &&
              (v.vehicleProgram === 'SmartMiles' ||
                v.vehicleProgram === 'ConnectedCar')))
      );

      if (
        this.vehicle.isSmartRideDeviceEligible &&
        (this.isDeviceOnlyState || otherDeviceVehicles.length)
      ) {
        return true;
      }
    }
    return false;
  }

  isCA(): boolean {
    return this.isSelfReportedEligibleState;
  }

  get selectedProgram(): AbstractControl | null {
    return this.form.get('selectedProgram');
  }

  get showTelematicsWarning(): boolean {
    const selectedProgram = this.selectedProgram?.value as string;
    return (
      selectedProgram === 'SmartMiles' &&
      !['MONTHLY EFT', 'MONTHLY RECURRING BANKCARD'].includes(this.billingPlan)
    );
  }

  get showPaperlessWarning(): boolean {
    const selectedProgram = this.selectedProgram?.value as string;
    return (
      selectedProgram === 'SmartMiles' && this.docDelPreference === 'USMail'
    );
  }

  get billingPlanForDisplay(): string {
    return PriceSummaryUtils.paymentMethodDiscountValueForBillingPlan(
      this.billingPlan
    );
  }

  private buildForm(): FormGroup {
    return this.fb.group({
      selectedProgram: this.fb.control('', [Validators.required]),
    });
  }

  private checkInputForSMCC() {
    if (
      this.currentProgram === 'SmartMiles' &&
      this.vehicle.enrollmentStatus === 'Enrolled' &&
      this.vehicle.dataCollectionMethod === 'ConnectedCar'
    ) {
      this.currentProgram = 'SmartMilesConnectedCar';
    }
  }

  applyClass(baseProgram: string, actualProgram: string): Observable<boolean> {
    return of(baseProgram === actualProgram);
  }

  isSelectedProgram(programName: string): boolean {
    return this.selectedProgram?.value === programName;
  }

  collectWarnings(): void {
    const anyWarnings =
      this.selectedProgram?.value === 'SmartMiles' &&
      (this.docDelPreference === 'USMail' ||
        !['MONTHLY EFT', 'MONTHLY RECURRING BANKCARD'].includes(
          this.billingPlan
        ));
    if (anyWarnings) {
      this.smartmilesMessages = [];
      // non paperless: incompatible with smartmiles
      if (
        this.selectedProgram?.value === 'SmartMiles' &&
        this.docDelPreference === 'USMail'
      ) {
        this.smartmilesMessages.push({
          topic: 'U.S. Mail document delivery',
          message: 'incompatible with SmartMiles',
        });
      }
      // pay in full: incompatible with smartmiles
      if (
        this.selectedProgram?.value === 'SmartMiles' &&
        !['MONTHLY EFT', 'MONTHLY RECURRING BANKCARD'].includes(
          this.billingPlan
        )
      ) {
        this.smartmilesMessages.push({
          topic: this.billingPlan,
          message: 'incompatible with SmartMiles',
        });
      }
    }
  }

  get tamFromRecommendation(): Nullable<number> {
    const isVehicleEnrollment = TelematicsUtils.isVehicleEnrollment(
      this.enrollment
    );
    if (isVehicleEnrollment) {
      const vehicleFromEnrollment = (
        this.enrollment as VehicleEnrollment
      ).vehicles.find((v) => v.vehicleId === this.vehicle.vehicleId);
      return vehicleFromEnrollment?.typicalAnnualMileage;
    } else {
      const vehicleFromRecommendation =
        this.recommendation?.qualifyingInformation?.vehicles?.find(
          (vehicle) =>
            vehicle.vehicleId?.toString() === this.vehicle.vehicleId?.toString()
        );
      return vehicleFromRecommendation?.typicalAnnualMileage;
    }
  }
}
