import { PicklistItem } from '@shared/models/picklist.model';
import { VehicleModel } from '@core/models/views/vehicle.model';
import { VehicleEntity } from '@core/store/entities/vehicle/vehicle.entity';
import {
  VEHICLE_SUB_TYPES,
  BODY_TYPES_NAME,
  BODY_STYLES,
} from '@shared/constants/app-constants';

export class VehicleModelHelper {
  static getVehicleSubTypesAsPicklist(): PicklistItem[] {
    return Object.keys(VEHICLE_SUB_TYPES).map((k) => ({
      displayName: VEHICLE_SUB_TYPES[k],
      value: k,
    }));
  }

  static getBodyTypesAsPicklist(): PicklistItem[] {
    // Remove the negative options, I assume those are outdated or something? They look redundant.
    return Object.keys(BODY_TYPES_NAME)
      .filter((key) => !key.startsWith('-'))
      .map((key) => ({
        displayName: BODY_TYPES_NAME[key],
        value: BODY_TYPES_NAME[key],
      }));
  }

  static getBodyStylesAsPicklist(): PicklistItem[] {
    return BODY_STYLES.map((style) => ({
      displayName: style,
      value: style,
    }));
  }

  static equalModels(v1: VehicleEntity, v2: VehicleModel): boolean {
    return (
      v1.year === v2.year &&
      v1.make === v2.make &&
      v1.model === v2.model &&
      v1.series === v2.series
    );
  }

  static vehicleIsDuplicate(
    prefillVehicle: VehicleEntity,
    vehicles: VehicleModel[]
  ): boolean {
    const duplicates = vehicles.filter((vehicle) => {
      if (vehicle.vin && prefillVehicle.vin) {
        return (
          this.vinsMatch(vehicle.vin, prefillVehicle.vin) &&
          VehicleModelHelper.equalModels(prefillVehicle, vehicle)
        );
      } else {
        return VehicleModelHelper.equalModels(prefillVehicle, vehicle);
      }
    });
    return duplicates.length > 0;
  }

  private static vinsMatch(a: string | undefined, b: string | undefined): boolean {
    a = a?.replace(/\*/g, '');
    b = b?.replace(/\*/g, '');
    if (!a && !b) {
      return true;
    }
    if (!a || !b) {
      return false;
    }
    return (a.indexOf(b) >= 0 || b.indexOf(a) >= 0);
  }
}
