import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DriverVehiclePrefillAdapter } from '../adapters/driver-vehicle-prefill.adapter';
import { UpdateSelectedEntity } from '../interfaces/interfaces';
import { DriverVehiclePrefillRequest } from '../models/api/request/driver-vehicle-prefill-request.model';
import { DriverVehiclePrefillResponse } from '../models/api/response/driver-vehicle-prefill-response.model';
import { MemberModel } from '../models/views/person.model';
import { DriverVehiclePrefillActions } from '../store/actions';
import { getDriverVehiclePrefillLoaded } from '../store/entities/driver-vehicle-prefill/driver-vehicle-prefill.selector';
import { VehicleEntity } from '../store/entities/vehicle/vehicle.entity';

@Injectable({
  providedIn: 'root',
})
export class DriverVehiclePrefillService {
  constructor(
    private driverVehiclePrefillAdapter: DriverVehiclePrefillAdapter,
    private store: Store
  ) {}

  customersInfo(
    request: DriverVehiclePrefillRequest
  ): Observable<DriverVehiclePrefillResponse> {
    return this.driverVehiclePrefillAdapter.customersInfo(request);
  }

  dispatchGetCustomersInfo(): any {
    return this.store.dispatch(DriverVehiclePrefillActions.getCustomersInfo());
  }

  updatePrefillVehicleSelected(updatedSelected: UpdateSelectedEntity): void {
    const vehicle: Update<VehicleEntity> = {
      changes: { selected: updatedSelected.selected },
      id: updatedSelected.entityId || '',
    };

    this.store.dispatch(
      DriverVehiclePrefillActions.updatePrefillVehicleSelected({
        payload: vehicle,
      })
    );
  }

  updatePrefillPersonSelected(updatedSelected: UpdateSelectedEntity): void {
    const driver: Update<MemberModel> = {
      changes: { selected: updatedSelected.selected },
      id: updatedSelected.entityId || '',
    };

    this.store.dispatch(
      DriverVehiclePrefillActions.updatePrefillPersonSelected({
        payload: driver,
      })
    );
  }

  storePrefillPerson(updatedPrefill: MemberModel): void {
    const driver: Update<MemberModel> = {
      changes: updatedPrefill,
      id: updatedPrefill.prefillId || '',
    };

    this.store.dispatch(
      DriverVehiclePrefillActions.storePrefillPerson({
        payload: driver,
      })
    );
  }

  getDriverVehiclePrefillLoaded(): Observable<boolean> {
    return this.store.select(getDriverVehiclePrefillLoaded);
  }
}
