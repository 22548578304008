import { ObjectValidator } from '@core/helper/object-validator';
import {
  BaseEntity,
  BaseEntityOptionalFields,
  BaseEntitySanitizerFields,
} from '@core/models/entities/base.entity';
import { ProductModel } from '@entities/product/product.model';
import { UnderlyingPolicyEntity } from '@entities/underlying-policy/underlying-policy.entity';
import { Nullable } from '@shared/utils/type.utils';

export interface VehicleExcludedExposureAndProductInfo {
  vehicleExcludedExposure: VehicleExcludedExposureEntity;
  underlyingPolicy?: UnderlyingPolicyEntity;
  product: ProductModel;
  options?: {
    vehicleLabel?: string;
    carrierLabel?: string;
  };
}
export interface VehicleExcludedExposureEntity extends BaseEntity {
  productId?: string;
  underlyingPolicyNumber?: Nullable<string>;
  carrier: string;
  vehicleExcludedExposureId: string;
  vehicleType: string;
  year: number;
  make: string;
  model: string;
  vin: string;
}

export function sanitizeVehicleExcludedExposureEntity(
  input: unknown
): VehicleExcludedExposureEntity {
  return ObjectValidator.forceSchema<VehicleExcludedExposureEntity>(
    input,
    {
      productId: 'string',
      underlyingPolicyNumber: 'string',
      carrier: 'string',
      vehicleExcludedExposureId: 'string',
      vehicleType: 'string',
      year: 'number',
      make: 'string',
      model: 'string',
      vin: 'string',
      ...BaseEntitySanitizerFields,
    },
    ['productId', 'underlyingPolicyNumber', ...BaseEntityOptionalFields]
  );
}
