import {
  TelematicsMobileEnrollmentType,
  TelematicsVehicleProgramType,
} from '@core/models/api/dsm-types';
import { TelematicsEnrollmentRequest } from '@core/models/api/request/telematics-enrollment-request.model';
import { TelematicsEnrollmentResponse } from '@core/models/api/response/telematics-enrollment-response.model';
import { TelematicsRecommendationResponse } from '@core/models/api/response/telematics-recommendation-response.model';
import {
  EnrolledVehicle,
  MobileEnrollment,
  VehicleEnrollment,
} from '@core/store/entities/telematics/telematics.model';
import {
  StateExceptionTelematicsDiscountMetadata,
  SmartRideEarlyRewardReleases,
} from '../constants/app-constants';
import { DateUtils } from './date.utils';
import { Nullable } from './type.utils';

export class TelematicsUtils {
  static isMobileEnrollment(
    enrollment: Nullable<
      MobileEnrollment | VehicleEnrollment | TelematicsEnrollmentResponse
    >
  ): boolean {
    return Object.keys(enrollment || {}).includes('mobileEnrollment');
  }

  static isVehicleEnrollment(
    enrollment: Nullable<
      MobileEnrollment | VehicleEnrollment | TelematicsEnrollmentResponse
    >
  ): boolean {
    return Object.keys(enrollment || {}).includes('vehicles');
  }

  static getDiscountMetadata(
    discountType: 'initialDiscount' | 'maximumDiscount',
    quoteState: string
  ): string {
    if (
      StateExceptionTelematicsDiscountMetadata[quoteState] &&
      StateExceptionTelematicsDiscountMetadata[quoteState][discountType]
    ) {
      return StateExceptionTelematicsDiscountMetadata[quoteState][discountType];
    }
    return discountType === 'initialDiscount' ? '10%' : '40%';
  }

  static buildTelematicsEnrollment(
    telematicsEnrollment: TelematicsEnrollmentResponse | undefined
  ): MobileEnrollment | VehicleEnrollment | undefined {
    let enrollment: MobileEnrollment | VehicleEnrollment | undefined;
    if (telematicsEnrollment?.mobileEnrollment) {
      enrollment = {
        enrollmentId: telematicsEnrollment.enrollmentId,
        mobileEnrollment: telematicsEnrollment.mobileEnrollment,
        enrollmentConsents: telematicsEnrollment.enrollmentConsents,
      };
    }
    if (telematicsEnrollment?.vehicleEnrollment) {
      enrollment = {
        enrollmentId: telematicsEnrollment.enrollmentId,
        enrollmentConsents: telematicsEnrollment.enrollmentConsents ?? {},
        vehicles: telematicsEnrollment.vehicleEnrollment.vehicles,
      };
    }
    return enrollment;
  }

  static filterOutIdlessVehiclesAndTrimNotEnrolled(
    enrollment: VehicleEnrollment
  ): VehicleEnrollment {
    return {
      ...enrollment,
      vehicles: enrollment.vehicles
        ?.filter((v) => !!v.vehicleId)
        .map((v) => {
          if (v.enrollmentStatus === 'NotEnrolled') {
            return {
              enrollmentStatus: 'NotEnrolled',
              vehicleId: v.vehicleId,
            };
          }
          return v;
        }),
    };
  }

  static flattenMobileEnrollmentRequest(
    request: TelematicsEnrollmentRequest
  ): any {
    // per comment on CODEMINERS-4197, removing smartMilesAnnualMileage from requests to prevent API error
    if (request.enrollment.vehicleEnrollment) {
      request.enrollment.vehicleEnrollment.vehicles =
        request.enrollment.vehicleEnrollment.vehicles?.map((v) => {
          if (
            v.enrollmentStatus !== 'NotEnrolled' &&
            v.smartMilesAnnualMileage
          ) {
            const { smartMilesAnnualMileage, ...returnVehicle } = v;
            return returnVehicle;
          }
          return v;
        });
    }
    let enrollment: any = request.enrollment;
    if (
      request.enrollment.mobileEnrollment?.mobileEnrollment
        ?.enrollmentStatus === 'Enrolled'
    ) {
      enrollment = {
        mobileEnrollment: {
          ...request.enrollment.mobileEnrollment?.mobileEnrollment,
        },
        enrollmentConsents:
          request.enrollment.enrollmentConsents ||
          request.enrollment.mobileEnrollment.enrollmentConsents,
      };
    }
    return enrollment;
  }

  static isSmartRideEarlyReward(
    state: string,
    quoteEffectiveDate: string
  ): boolean {
    if (quoteEffectiveDate) {
      let stateEffectiveDate = SmartRideEarlyRewardReleases.find(
        (release) => release.state === state
      )?.releaseDate;
      if (stateEffectiveDate) {
        stateEffectiveDate = DateUtils.formatDateToDSM(stateEffectiveDate);
        return quoteEffectiveDate >= stateEffectiveDate;
      }
    }
    return false;
  }
}
