import { createReducer, on } from '@ngrx/store';
import { PersonaName } from './persona.model';
import { PersonaActions, SessionActions } from '@core/store/actions';

export interface PersonaState {
  name: PersonaName;
}

const initialState: PersonaState = {
  name: 'None',
};

export const reducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(PersonaActions.setPersonaName, (state, action) => ({
    ...state,
    name: action.name,
  }))
);
