import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromCoverages from '@core/store/entities/coverage/coverage.reducer';
import { ProductType } from '@core/models/api/dsm-types';
import { CoverageChange, CoverageEntity } from './coverage.entity';
import { getQuoteState } from '../session/session.selector';
import { getAllVehicles } from '../vehicle/vehicle.selector';
import { getAllFeatureFlags } from '../feature-flag/feature-flag.selector';
import { getProduct } from '../product/product.selectors';
import { getAllCoveredLocations } from '@entities/covered-location/covered-location.selector';

export const getCoverageState = createSelector(
  fromCore.getCoreState,
  (state) => state.coverages
);

export const {
  selectAll: getAllCoverages,
  selectEntities: getCoverageEntities,
  selectTotal: getTotalCoverages,
} = fromCoverages.adapter.getSelectors(getCoverageState);

export const getAllCoveragesByVersion = (version?: string) =>
  createSelector(getAllCoverages, (coverages) =>
    coverages?.filter((cvg) => cvg.version === (version || 'CURRENT'))
  );

export const getCoveragesFor = (productType: ProductType, version?: string) =>
  createSelector(getAllCoverages, (coverages) =>
    coverages?.filter(
      (cvg) =>
        cvg.version === (version || 'CURRENT') && cvg.productId === productType
    )
  );

export const getCoverageRuleData = (coverages: CoverageEntity[]) =>
  createSelector(
    getQuoteState,
    getAllFeatureFlags,
    getAllVehicles,
    getAllCoveredLocations,
    getCoveragesFor(coverages[0] ? coverages[0].productId : 'PersonalAuto'),
    // TODO: need to identify comp rater flag
    (
      quoteState,
      featureFlags,
      vehicles,
      coveredLocations,
      previousCoverages
    ) => ({
      coverages,
      quoteState,
      productId: coverages[0] ? coverages[0].productId : 'PersonalAuto',
      featureFlags,
      vehicles,
      coveredLocations,
      previousCoverages,
      isCompRater: false, // TODO: need to pull from store once available
    })
  );

export const buildUpdateCoverageRequest = (
  productId: ProductType,
  coverageChanges: CoverageChange[]
) =>
  createSelector(getProduct(productId), () => {
    return {
      coverages: coverageChanges,
      productId: productId as ProductType,
    };
  });

export const getQuoteCoveragesLoaded = createSelector(
  getCoverageState,
  (state) => state.loaded && !state.loading
);

export const getQuoteCoveragesLoading = createSelector(
  getCoverageState,
  (state) => state.loading > 0
);

export const getQuoteCoveragesError = createSelector(
  getCoverageState,
  (state) => state.error
);

// TODO - Indlude Telematics clicked
// export const getCoverageDisplayProducts = createSelector(
//   getAllProducts,
//   getAllVehicles,
//   getAllPremiums,
//   getAllCoverages,
//   getCoveredLocationData,
//   selectAllRenovations,
//   getCurrentEnrollment,
//   (
//     products,
//     vehicles,
//     premiums,
//     coverages,
//     coveredLocation,
//     renovations,
//     enrollment
//   ) =>
//     products.map((product) =>
//       createCoverageDisplay(
//         product,
//         vehicles,
//         premiums,
//         coverages,
//         coveredLocation,
//         renovations,
//         enrollment
//       )
//     )
// );

// function createCoverageDisplayModel(product: ProductModel, ...models: any[]) {
//   return {
//     id: product.type,
//     headline: `${product.name} policy`,
//     unselectOption: false,
//     hasError: product.hasError || false,
//     formComponentInputs: { ...models },
//   };
// }

// function createCoverageDisplay(
//   product: ProductModel,
//   vehicles: VehicleEntity[],
//   premiums: PremiumEntity[],
//   coverages: CoverageEntity[],
//   coveredLocation: Nullable<CoveredLocation>,
//   renovations: RenovationEntity[],
//   enrollment: Nullable<VehicleEnrollment | MobileEnrollment>
// ): CoverageDisplayProduct {
//   switch (product.type) {
//     case 'PersonalAuto':
//       return {
//         productId: product.type,
//         componentType: AutoCoverageDisplayComponent,
//         model: createCoverageDisplayModel(
//           product,
//           vehicles,
//           premiums,
//           coverages,
//           enrollment,
//           product
//         ),
//         visible: !!(product.quoteId && product.quoteRated),
//       };

//     case 'Homeowner':
//       return {
//         productId: product.type,
//         componentType: HomeownersCoverageDisplayComponent,
//         model: createCoverageDisplayModel(
//           product,
//           coveredLocation,
//           renovations,
//           premiums,
//           coverages,
//           product
//         ),
//         visible: !!(product.quoteId && product.quoteRated),
//       };

//     case 'Tenant':
//       return {
//         productId: product.type,
//         componentType: RentersCoverageDisplayComponent,
//         model: createCoverageDisplayModel(
//           product,
//           coveredLocation,
//           renovations,
//           premiums,
//           coverages,
//           product
//         ),
//         visible: !!(product.quoteId && product.quoteRated),
//       };

//     case 'Condominium':
//       return {
//         productId: product.type,
//         componentType: CondoCoverageDisplayComponent,
//         model: createCoverageDisplayModel(
//           product,
//           coveredLocation,
//           renovations,
//           premiums,
//           coverages,
//           product
//         ),
//         visible: !!(product.quoteId && product.quoteRated),
//       };

//     case 'Umbrella':
//       return {
//         productId: product.type,
//         componentType: UmbrellaCoverageDisplayComponent,
//         model: createCoverageDisplayModel(
//           product,
//           premiums,
//           coverages,
//           product
//         ),
//         visible: !!(product.quoteId && product.quoteRated),
//       };
//     case 'TermLife':
//       return {} as CoverageDisplayProduct;
//   }
// }
