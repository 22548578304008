import { Observable, Subject } from 'rxjs';
import { filter, finalize, switchMap, take, tap } from 'rxjs/operators';

interface GlobalLockChange {
  lockName: string;
  state: boolean;
}

const globalLocks = new Set<string>();
const globalLockChanges = new Subject<GlobalLockChange>();

function isLocked(lockName: string): boolean {
  return globalLocks.has(lockName);
}

function acquireLock(lockName: string): void {
  globalLocks.add(lockName);
  globalLockChanges.next({ lockName, state: true });
}

function relinquishLock(lockName: string): void {
  globalLocks.delete(lockName);
  globalLockChanges.next({ lockName, state: false });
}

export function withLock<T>(
  lockName: string
): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>) => {
    if (!isLocked(lockName)) {
      acquireLock(lockName);
      return source.pipe(finalize(() => relinquishLock(lockName)));
    }
    return globalLockChanges.pipe(
      filter(
        (change) =>
          change.lockName === lockName && !change.state && !isLocked(lockName)
      ),
      take(1),
      tap(() => acquireLock(lockName)),
      switchMap(() => source),
      finalize(() => relinquishLock(lockName))
    );
  };
}

export const internal = {
  globalLocks,
  globalLockChanges,
  isLocked,
  acquireLock,
  relinquishLock,
};
