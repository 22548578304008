import { stateSpecificFlags } from '@assets/metadata/stateSpecificFlags';
import { ProductType } from '@core/models/api/dsm-types';
import {
  FeatureFlagId,
  FeatureFlagsModel,
} from '@core/store/entities/feature-flag/feature-flag.model';

export class FeatureFlagUtils {
  static FlagAppliesToProductType(
    featureFlag: FeatureFlagId,
    productType: ProductType
  ): boolean {
    const featureFlagModel = stateSpecificFlags;
    const { offerings } = featureFlagModel[featureFlag] || {};
    return offerings ? offerings.includes(productType) : true;
  }

  static FlagApplies(
    featureFlag: FeatureFlagId,
    allFlags: FeatureFlagsModel
  ): boolean {
    return allFlags[featureFlag] ?? false;
  }
}
