import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DsmAdapter, responseUnused } from './dsm.adapter';
import { TelematicsRecommendationRequest } from '../models/api/request/telematics-recommendation-request.model';
import {
  TelematicsRecommendationResponse,
  sanitizeTelematicsRecommendation,
} from '../models/api/response/telematics-recommendation-response.model';
import { TelematicsEnrollmentRequest } from '../models/api/request/telematics-enrollment-request.model';
import {
  TelematicsEnrollmentResponse,
  sanitizeTelematicsEnrollmentResponse,
} from '../models/api/response/telematics-enrollment-response.model';
import { TelematicsUtils } from '@shared/utils/telematics.utils';

@Injectable({
  providedIn: 'root',
})
export class TelematicsAdapter extends DsmAdapter {
  getTelematicsRecommendation(
    request: TelematicsRecommendationRequest
  ): Observable<TelematicsRecommendationResponse> {
    return this.request<TelematicsRecommendationResponse>(
      sanitizeTelematicsRecommendation,
      'PersonalAuto',
      'POST',
      `/quotes/${request.quoteId}/telematics-recommended-programs`,
      'telematics-recommendation',
      {
        baseUrl: `${this.appConfigService.config.pcEdgeAutoUrl}`,
        body: { qualifyingInformation: request.qualifyingInformation },
      }
    );
  }

  getTelematicsEnrollment(
    quoteId?: string
  ): Observable<TelematicsEnrollmentResponse> {
    return this.request<TelematicsEnrollmentResponse>(
      sanitizeTelematicsEnrollmentResponse,
      'PersonalAuto',
      'GET',
      `/quotes/${quoteId}/telematics-enrollments`,
      'get-telematics',
      {
        baseUrl: `${this.appConfigService.config.pcEdgeAutoUrl}`,
      }
    );
  }

  addTelematicsEnrollment(
    request: TelematicsEnrollmentRequest
  ): Observable<TelematicsEnrollmentResponse> {
    const body = {
      enrollment: TelematicsUtils.flattenMobileEnrollmentRequest(request),
    };

    return this.request<TelematicsEnrollmentResponse>(
      sanitizeTelematicsEnrollmentResponse,
      'PersonalAuto',
      'POST',
      `/quotes/${request.quoteId}/telematics-enrollments`,
      'add-telematics',
      {
        baseUrl: `${this.appConfigService.config.pcEdgeAutoUrl}`,
        body,
      }
    );
  }

  updateTelematicsEnrollment(
    request: TelematicsEnrollmentRequest
  ): Observable<TelematicsEnrollmentResponse> {
    const body = {
      enrollment: TelematicsUtils.flattenMobileEnrollmentRequest(request),
    };
    const enrollmentId =
      request.enrollment.mobileEnrollment?.enrollmentId ||
      request.enrollment.vehicleEnrollment?.enrollmentId ||
      request.enrollment.enrollmentId;

    return this.request<TelematicsEnrollmentResponse>(
      sanitizeTelematicsEnrollmentResponse,
      'PersonalAuto',
      'PUT',
      `/quotes/${request.quoteId}/telematics-enrollments/${enrollmentId}`,
      'update-telematics',
      {
        baseUrl: `${this.appConfigService.config.pcEdgeAutoUrl}`,
        body,
      }
    );
  }

  deleteTelematicsEnrollment(
    quoteId: string,
    enrollmentId: string
  ): Observable<unknown> {
    return this.request(
      responseUnused,
      'PersonalAuto',
      'DELETE',
      `/quotes/${quoteId}/telematics-enrollments/${enrollmentId}`,
      'delete-telematics',
      {
        baseUrl: `${this.appConfigService.config.pcEdgeAutoUrl}`,
      }
    );
  }
}
