import { Retrieve17DigitVehicleIdentificationNumberOdbiResponse } from '@core/models/api/vehicle-inquiry.model';
import {
  VehicleFeaturesModel,
  VehicleModel,
} from '@core/models/views/vehicle.model';
import {
  ANTI_LOCK_BREAKES_CODES,
  ANTI_THEFT_CODES,
  DAY_TIME_RUNNING_LAMPS_CODE,
} from '@shared/constants/app-constants';

export interface VinValidationResults {
  message: string;
  replacement?: string;
  candidates?: string[];
}

export class VinHelper {
  // Prettier wants this statement to take 19 lines if we say it right:
  static readonly CHECKSUM_WEIGHTS_BY_POSITION =
    '8,7,6,5,4,3,2,10,0,9,8,7,6,5,4,3,2'.split(',').map((n) => +n);

  static readonly LOOKALIKE_CHARACTERS = [
    ['Z', '2'],
    ['S', '5'],
    ['B', '8'],
    // 'I' and 'O' are fixed by normalization.
  ];

  static readonly LOOKALIKE_SANITY_LIMIT = 1000;

  /**
   * Returns null if input is valid exactly as is.
   * If we find exactly one similar valid VIN, return it as (replacement).
   * If we find multiple possible replacements, return them as (candidates).
   * A non-null result will always have a sensible (message).
   */
  static validate(
    vin: string,
    allowMasks = false
  ): VinValidationResults | null {
    // Don't validate if it's masked, even just a little masked.
    if (vin.indexOf('*') >= 0) {
      return null;
    }
    const norm = this.normalize(vin);

    // 10-digit mask patterns are OK if the caller says so.
    // We don't do full validation on these, because we can't calculate the checksum.
    if (this.is10DigitVinMask(norm)) {
      if (allowMasks) {
        return null;
      } else {
        return {
          message: 'Full VIN required.',
        };
      }
    }

    // If lexical validation fails, stop right there.
    const message = this.validateLexicalVin(norm);
    if (message) {
      return { message };
    }

    // If the normalized VIN has a valid checksum, we can stop.
    // Either it is perfect, or we have a single proposal.
    if (this.validateChecksum(norm)) {
      if (norm === vin) {
        return null;
      } else {
        return {
          message: 'Valid after normalization.',
          replacement: norm,
        };
      }
    }

    // Now it gets expensive -- search for lookalikes.
    const lookalikes = this.getValidLookalikes(norm);
    if (lookalikes.length < 1) {
      return { message: 'Invalid.' };
    } else if (lookalikes.length === 1) {
      return {
        message: 'Modified to satisfy checksum.',
        replacement: lookalikes[0],
      };
    } else {
      return {
        message: 'Multiple candidates discovered.',
        candidates: lookalikes,
      };
    }
  }

  static is10DigitVinMask(vin: string): boolean {
    if (!vin.match(/^[0-9A-Z&]{10}$/)) {
      return false;
    }
    if (vin.match(/\&/g)?.length !== 2) {
      return false;
    }
    return true;
  }

  /**
   * Given an input VIN (reference) and proposed substitution (proposal),
   * insert <span class="difference"> to highlight the changed characters.
   */
  static highlightDifferences(reference: string, proposal: string): string {
    let output = '';
    let referencePosition = 0;
    let proposalPosition = 0;
    while (proposalPosition < proposal.length) {
      let differentCount = 0;
      while (proposalPosition + differentCount < proposal.length) {
        if (referencePosition + differentCount >= reference.length) {
          differentCount++;
        } else {
          const refChar = reference
            .substr(referencePosition + differentCount, 1)
            .toUpperCase();
          if (refChar.match(/\s/)) {
            referencePosition++;
            break;
          } else {
            const proChar = proposal
              .substr(proposalPosition + differentCount, 1)
              .toUpperCase();
            if (refChar === proChar) {
              break;
            }
            if (refChar === 'I' && proChar === '1') {
              break;
            }
            if (refChar === 'O' && proChar === '0') {
              break;
            }
            differentCount++;
          }
        }
      }
      if (differentCount) {
        output += '<span>';
        output += proposal.substr(proposalPosition, differentCount);
        output += '</span>';
        proposalPosition += differentCount;
        referencePosition += differentCount;
      } else {
        output += proposal.substr(proposalPosition, 1);
        proposalPosition++;
        referencePosition++;
      }
    }
    return output;
  }

  /**
   * Returns the same VIN with unambiguous normalization:
   *  - Remove whitespace.
   *  - All letters to uppercase.
   *  - Replace Eye with One and Oh with Zero (those letters are illegal).
   * Other VinHelper methods generally expect a normalized VIN.
   */
  private static normalize(vin: string): string {
    return vin
      .replace(/\s/g, '')
      .toUpperCase()
      .replace(/I/g, '1')
      .replace(/O/g, '0');
  }

  /**
   * Given a normalized VIN, assert:
   *  - Correct length (17).
   *  - All digits legal.
   * Returns '' if valid, or a description of the failure.
   */
  private static validateLexicalVin(vin: string): string {
    if (vin.length !== 17) {
      return `Invalid length ${vin.length}, must be 17.`;
    }
    const match = vin.match(/[^0-9A-HJ-NPR-Z\*]/);
    if (match) {
      return `Illegal character '${match[0]}'.`;
    }
    return '';
  }

  /**
   * Given a normalized VIN, calculate and assert its checksum.
   */
  private static validateChecksum(vin: string): boolean {
    const numericVin = this.vinAsNumbers(vin);
    this.applyWeightsToNumericVin(numericVin);
    const sum = numericVin.reduce((a, v) => a + v, 0);
    const remainder = sum % 11;
    const expectedDigit = remainder < 10 ? remainder.toString() : 'X';
    return vin[8] === expectedDigit;
  }

  private static vinAsNumbers(vin: string): number[] {
    return Array.from(vin).map((ch) => this.vinCharacterValue(ch));
  }

  private static applyWeightsToNumericVin(vin: number[]): void {
    for (let i = 0; i < vin.length; i++) {
      vin[i] *= VinHelper.CHECKSUM_WEIGHTS_BY_POSITION[i];
    }
  }

  /**
   * Value 0..9 for any VIN-legal character, for checksum purposes.
   */
  private static vinCharacterValue(ch: string): number {
    // 0..9 are themselves.
    if (ch >= '0' && ch <= '9') {
      return +ch;
    }
    // A..R are (n%9+1), including the gaps for I, O, and Q.
    if (ch >= 'A' && ch <= 'R') {
      return ((ch.charCodeAt(0) - 'A'.charCodeAt(0)) % 9) + 1;
    }
    // S..Z are 2..9, for some reason they broke the pattern between R and S.
    if (ch >= 'S' && ch <= 'Z') {
      return ch.charCodeAt(0) - 'S'.charCodeAt(0) + 2;
    }
    // Anything else is illegal, return NaN to poison the result.
    return NaN;
  }

  /**
   * Returns an array of valid VINs which resemble the input, with certain replacements.
   * If (vin) itself is valid, it will be in the results.
   */
  private static getValidLookalikes(vin: string): string[] {
    // If there are too many possibilities, don't bother.
    // At the limit, 2**17==128k, not all that bad, but we'll stop shorter than that.
    const suspectPositions = this.locateSuspectCharactersInString(vin);
    const totalPossibleCount = 2 ** suspectPositions.length;
    if (totalPossibleCount > VinHelper.LOOKALIKE_SANITY_LIMIT) {
      return [];
    }
    const splitVin = vin.split('');
    const lookalikes = [];
    for (let i = 0; i < totalPossibleCount; i++) {
      const lookalike = this.generateLookalike(splitVin, suspectPositions, i);
      if (this.validateChecksum(lookalike)) {
        lookalikes.push(lookalike);
      }
    }
    return lookalikes;
  }

  private static locateSuspectCharactersInString(vin: string): number[] {
    const suspectPositions: number[] = [];
    for (let i = 0; i < vin.length; i++) {
      if (
        VinHelper.LOOKALIKE_CHARACTERS.find(
          (pair) => pair[0] === vin[i] || pair[1] === vin[i]
        )
      ) {
        suspectPositions.push(i);
      }
    }
    return suspectPositions;
  }

  private static generateLookalike(
    input: string[],
    suspectPositions: number[],
    index: number
  ): string {
    const output = [...input];
    for (const position of suspectPositions) {
      const ch = input[position];
      const possibilities = VinHelper.LOOKALIKE_CHARACTERS.find(
        (p) => p[0] === ch || p[1] === ch
      );
      if (!possibilities) {
        throw new Error(`not a lookalike char: ${ch}`);
      }
      output[position] = possibilities[index % 2];
      index = Math.floor(index / 2);
    }
    return output.join('');
  }

  static addVehicleFeaturesFromVin(
    response: Retrieve17DigitVehicleIdentificationNumberOdbiResponse,
    vehicle: Partial<VehicleModel>
  ) {
    let vehicleFeatures: VehicleFeaturesModel[] = [];
    const m =
      response.retrieve17DigitVehicleIdentificationNumberOdbiResponse
        .vehicleModel;
    if (
      ANTI_THEFT_CODES.includes(m.antiTheftDevice) &&
      vehicle?.options?.antiTheftDevicesVisible
    ) {
      vehicleFeatures.push('AntiTheft');
    }
    if (
      ANTI_LOCK_BREAKES_CODES.includes(m.antiLockBrakes) &&
      vehicle?.options?.antiLockBrakesVisible
    ) {
      vehicleFeatures.push('AntiLockBrakes');
    }
    if (
      m.daytimeRunningLights === DAY_TIME_RUNNING_LAMPS_CODE &&
      vehicle?.options?.daytimeRunningLampsVisible
    ) {
      vehicleFeatures.push('DaytimeRunningLamps');
    }
    return vehicleFeatures;
  }
}
