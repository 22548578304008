import { InquiryPath } from '@core/adapters/powersports.adapter';
import { ProductType, QuoteStatus } from '@core/models/api/dsm-types';
import { ProductModel } from '@core/store/entities/product/product.model';
import {
  PropertyProductTypes,
  ProtectionProductTypes,
  VehicleProductTypes,
} from '@shared/constants/app-constants';
import { Nullable } from './type.utils';
import { Products } from '@assets/metadata/products';
import { ProducerSearch } from '@core/adapters/producer-search.model';

export class ProductUtils {
  static isPropertyProduct = (productType: ProductType) =>
    PropertyProductTypes.includes(productType);
  static isVehicleProduct = (productType: ProductType) =>
    VehicleProductTypes.includes(productType);
  static isPersonalAuto = (productType: ProductType) =>
    productType === 'PersonalAuto';
  static isProtectionProduct = (productType: ProductType) =>
    ProtectionProductTypes.includes(productType);

  static isEscrowProduct(productType: ProductType): boolean {
    return productType === 'Homeowner' || productType === 'Condominium';
  }
  static isCurrentCarrierReportProduct = (productType: ProductType) =>
    this.CurrentCarrierReportProducts.includes(productType);

  static isDriverProduct(product: ProductType): boolean {
    const driverTypeProducts: ProductType[] = [
      'PersonalAuto',
      'MSA',
      'Boat',
      'RV',
    ];
    return driverTypeProducts.includes(product);
  }
  static isPowersportsProductType(productType: Nullable<ProductType>): boolean {
    switch (productType) {
      case 'MSA':
      case 'Boat':
      case 'RV':
        return true;
      default:
        return false;
    }
  }

  static ReportsAndUWProducts = Products.filter(
    (p) => p.uwReportsImplemented
  ).map((p) => p.type);

  static CurrentCarrierProducts: ProductType[] = [
    'PersonalAuto',
    'MSA',
    'Boat',
    'RV',
  ];

  static CurrentCarrierReportProducts: ProductType[] = ['PersonalAuto'];

  static isScheduledPersonalEffectsProduct(productType: ProductType): boolean {
    return this.ScheduledPersonalEffectsProducts.includes(productType);
  }
  static ScheduledPersonalEffectsProducts: ProductType[] = ['RV'];

  static getImageUrlForProduct(productType: ProductType): string {
    switch (productType) {
      case 'PersonalAuto':
        return 'assets/images/products/personalAuto/car-circle.svg';
      case 'Condominium':
        return 'assets/images/products/condo/condo-circle.svg';
      case 'Homeowner':
        return 'assets/images/products/homeowner/home-circle-nwx.svg';
      case 'Tenant':
        return 'assets/images/products/tenant/renters_new.svg';
      case 'TermLife':
        return 'assets/images/products/termlife/heart-circle-colorized.svg';
      case 'PersonalUmbrella':
        return 'assets/images/products/umbrella/umbrella_circle.svg';
      case 'MSA':
        return 'assets/images/products/msa/motorcycle_circle.svg';
      case 'Boat':
        return 'assets/images/products/boat/boat_circle.svg';
      case 'RV':
        return 'assets/images/products/rv/rv_circle.svg';
      case 'DwellingFire':
        return 'assets/images/products/dwellingFire/fire_circle.svg';
      default:
        return '';
    }
  }

  static getNameForProduct(productType: ProductType): string {
    switch (productType) {
      case 'PersonalAuto':
        return 'Auto';
      case 'Condominium':
        return 'Condo';
      case 'Homeowner':
        return 'Homeowners';
      case 'Tenant':
        return 'Renters';
      case 'TermLife':
        return 'Term Life';
      case 'PersonalUmbrella':
        return 'Umbrella';
      case 'MSA':
        return 'Motorcycle';
      case 'Boat':
        return 'Pleasure Boatowners | Boats, Personal Watercraft, Trailers';
      case 'RV':
        return 'Recreational Vehicle | Motorhomes, Travel Trailers, Utility Trailers';
      case 'DwellingFire':
        return 'Dwelling Fire';
      default:
        return '';
    }
  }

  static productBillingName(productType: ProductType): string {
    switch (productType) {
      case 'PersonalAuto':
        return 'Auto';
      case 'Condominium':
        return 'Condo';
      case 'Homeowner':
        return 'Homeowners';
      case 'Tenant':
        return 'Renters';
      case 'TermLife':
        return 'Term Life';
      case 'PersonalUmbrella':
        return 'Umbrella';
      case 'MSA':
        return 'Motorcycle';
      case 'Boat':
        return 'Boat';
      case 'RV':
        return 'Recreational Vehicle';
      case 'DwellingFire':
        return 'Dwelling Fire';
      default:
        return '';
    }
  }

  static productTypeFromUrlFragment(fragment: string): ProductType {
    switch (fragment) {
      case 'auto':
        return 'PersonalAuto';
      case 'msa':
        return 'MSA';
      case 'boat':
        return 'Boat';
      case 'rv':
        return 'RV';
      case 'homeowner':
        return 'Homeowner';
      case 'condo':
        return 'Condominium';
      case 'tenant':
        return 'Tenant';
      default:
        return 'PersonalAuto';
    }
  }
  static baseInquiryPathFromProduct(product: ProductType): InquiryPath {
    switch (product) {
      case 'Boat':
        return 'boats';
      case 'RV':
        return 'recreational-vehicles';
      case 'MSA':
      default:
        return 'motorcycles';
    }
  }
  static productFromInquiryPath(inquiryPath: InquiryPath): ProductType {
    if (inquiryPath.startsWith('boat')) return 'Boat';
    if (inquiryPath === 'recreational-vehicles') return 'RV';
    if (inquiryPath === 'motorcycles') return 'MSA';
    if (inquiryPath === 'auto') return 'PersonalAuto';
    return 'MSA';
  }
  static getProductHelpText(
    product: ProductModel,
    quoteState?: string
  ): string {
    switch (product.type) {
      case 'Homeowner': {
        if (quoteState === 'NC') {
          return 'HO3, HE7';
        }
        return 'HO3';
      }
      case 'Tenant': {
        return 'HO4';
      }
      case 'Condominium': {
        return 'HO6';
      }
      case 'PersonalUmbrella': {
        return 'Auto and Property product required';
      }
      case 'MSA': {
        return 'Scooters, dirt bikes, etc...';
      }
      case 'RV': {
        if (product.isDsmActive) {
          return 'Motorhomes, travel trailers, and utility trailers';
        } else {
          return '';
        }
      }
      case 'DwellingFire': {
        return '';
      }
      case 'Boat': {
        if (product.isDsmActive) {
          return 'Boats, trailers, and motors';
        } else {
          return '';
        }
      }
    }
    return '';
  }

  static IsQuoteStarted(quoteStatus: Nullable<QuoteStatus>): boolean {
    return (
      quoteStatus === 'Error' ||
      quoteStatus === 'Draft' ||
      quoteStatus === 'Pending' ||
      quoteStatus === 'Quoted' ||
      quoteStatus === 'Binding'
    );
  }

  // BROKEN - This doesn't work anymore, it needs to know about the products in the store now.
  static isActiveProduct(productType: ProductType): boolean {
    return Products.some(
      (product) => product.type === productType && product.isDsmActive
    );
  }

  static matchingProducerFromSearch(
    search: ProducerSearch,
    producerCode?: string
  ) {
    if (!producerCode) return null;
    return search.producers.find((p) => p.producerCode === producerCode);
  }

  static atLeastOneActive(products: ProductModel[]): boolean {
    return products.some((p) => p.isDsmActive);
  }
}
