import {
  AfterViewInit,
  Directive,
  ElementRef,
  forwardRef,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface ShadyElement extends HTMLInputElement {
  renderRoot: HTMLElement;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CheckboxValue = any;

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxesAsArrayDirective),
  multi: true,
};

/**
 * Apply to bolt-checkbox-group to make its value an array of "value" from the checked bolt-checkboxes.
 */
@Directive({
  selector: '[nwxCheckboxesAsArray]',
  providers: [VALUE_ACCESSOR],
})
export class CheckboxesAsArrayDirective
  implements ControlValueAccessor, AfterViewInit, OnDestroy
{
  private mutationObserver?: MutationObserver;
  private unsubscribe = new Subject<void>();
  private eventListener: ((event: Event) => void) | null = null;
  private value: CheckboxValue[] = [];
  private onChange = (value: CheckboxValue[]) => {};
  private onTouched = () => {};

  constructor(private element: ElementRef, private window: Window) {}

  ngAfterViewInit(): void {
    this.eventListener = (event: Event) => this.onDomEvent(event);
    this.element.nativeElement.addEventListener('input', this.eventListener);
    // We could have gotten a value already via writeValue(), but the inner components weren't ready for it...
    this.writeValue(this.value);
    this.initializeMutationObserver();
  }

  private initializeMutationObserver(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type WindowWithMutationObserver = any;
    if (
      !this.mutationObserver &&
      (this.window as WindowWithMutationObserver).MutationObserver &&
      this.element.nativeElement
    ) {
      this.mutationObserver = new (
        this.window as WindowWithMutationObserver
      ).MutationObserver((events: MutationRecord[]) =>
        this.observeMutation(events)
      );
      this.mutationObserver?.observe(this.element.nativeElement, {
        childList: true,
        subtree: true,
      });
    }
  }

  private observeMutation(events: MutationRecord[]): void {
    if (this.checkboxesWereAdded(events)) {
      this.writeValue(this.value);
    }
  }

  private checkboxesWereAdded(events: MutationRecord[]): boolean {
    for (const event of events) {
      if (event.addedNodes) {
        for (const node of event.addedNodes as unknown as HTMLElement[]) {
          if (node.tagName === 'INPUT') {
            return true;
          }
        }
      }
    }
    return false;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    if (this.eventListener) {
      this.element.nativeElement.removeEventListener(
        'input',
        this.eventListener
      );
      this.eventListener = null;
    }
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  writeValue(value: CheckboxValue[]): void {
    this.value = [...(value || [])];
    for (const input of this.iterateInputs()) {
      const inner = input.renderRoot?.querySelector('input');
      const innerValue = input.value;
      if (this.value.find((v) => this.valuesEquivalent(v, innerValue))) {
        input.checked = true;
        input.setAttribute('checked', 'checked');
        if (inner) {
          inner.checked = true;
          inner.setAttribute('checked', 'checked');
        }
      } else {
        input.checked = false;
        input.removeAttribute('checked');
        if (inner) {
          inner.checked = false;
          inner.removeAttribute('checked');
        }
      }
    }
  }

  private valuesEquivalent(a: CheckboxValue, b: CheckboxValue): boolean {
    const ajson = JSON.stringify(a);
    const bjson = JSON.stringify(b);
    return ajson === bjson;
  }

  registerOnChange(fn: (value: CheckboxValue) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.element.nativeElement.setAttribute('disabled', 'disabled');
    } else {
      this.element.nativeElement.removeAttribute('disabled');
    }
  }

  private iterateInputs(): ShadyElement[] {
    return Array.from(
      this.element.nativeElement.querySelectorAll('bolt-checkbox')
    );
  }

  private onDomEvent(event: Event): void {
    // The <input> state is updated before we receive this event.
    // The <bolt-checkbox> gets updated after, apparently.
    const input = event.composedPath()?.[0] as HTMLInputElement;
    const boltCheckbox = event.target as HTMLInputElement;
    if (!input || !boltCheckbox) {
      return;
    }
    let changed = false;
    if (input.checked) {
      changed = this.addToValue(boltCheckbox.value);
    } else {
      changed = this.removeFromValue(boltCheckbox.value);
    }
    if (changed) {
      this.onChange([...this.value]);
    }
  }

  private addToValue(value: CheckboxValue): boolean {
    if (this.value.find((v) => this.valuesEquivalent(v, value))) {
      return false;
    }
    this.value = [...this.value, value];
    return true;
  }

  private removeFromValue(value: CheckboxValue): boolean {
    const index = this.value.findIndex((v) => this.valuesEquivalent(v, value));
    if (index >= 0) {
      this.value = [...this.value];
      this.value.splice(index, 1);
      return true;
    } else {
      return false;
    }
  }
}
