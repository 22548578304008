import { PaymentFormModel } from '@app/billing-payment/components/payment-form/payment-form.model';
import { ProductType } from '@core/models/api/dsm-types';
import { createAction, props } from '@ngrx/store';
import {
  BillingAccountEntity,
  BillingAccountStatus,
} from './billing-account.reducer';

/**
 * Store only. Set id 0 to generate a new one.
 */
export const upsertBillingAccount = createAction(
  '[Billing Account] Upsert Billing Account',
  props<{ payload: BillingAccountEntity }>()
);

export const createBillingAccount = createAction(
  '[Billing Account] Create Billing Account',
  props<{ payload: BillingAccountEntity; form: PaymentFormModel }>()
);

export const createBillingAccountIfAbsent = createAction(
  '[Billing Account] Create Billing Account If Absent',
  props<{ productTypes: ProductType[]; form: PaymentFormModel }>()
);

export const removeBillingAccountErrors = createAction(
  '[Billing Account] Remove Errors'
);

export const setBillingAccountStatus = createAction(
  '[Billing Account] Set Status',
  props<{
    productType: ProductType;
    status: BillingAccountStatus;
    accountNumber?: string;
    receiptId?: string;
  }>()
);
