import { Injectable, NgZone } from '@angular/core';
import { ViewportType } from '@core/store/entities/layout/layout.entity';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, pairwise } from 'rxjs/operators';
import * as fromActions from '@core/store/entities/layout/layout.action';
import * as layoutSelectors from '@core/store/entities/layout/layout.selector';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private breakpointSizeChange$: Subject<number> = new Subject<number>();
  private resizeObserver: ResizeObserver;

  constructor(
    private store: Store,
    private ngZone: NgZone,
    private window: Window
  ) {
    this.resizeObserver = new this.window.ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        // window.innerWidth includes the scrollbar width
        // (consistent with media queries, resizeObserver omits scrollwidth)
        this.breakpointSizeChange$.next(window.innerWidth);
      }
    );
    this.resizeObserver.observe(document.body);
    this.handleBreakpointChanges();
  }

  getInitialSize(): ViewportType {
    // window.innerWidth includes the scrollbar width
    return this.categorizeSize(window.innerWidth);
  }

  getViewport(): Observable<ViewportType> {
    return this.store.select(layoutSelectors.getLayoutViewport);
  }

  /*
   * Breakpoints:
   * 0 - 575: mini
   * 576 - 767: xsmall
   * 768 - 991: small
   * 992 - 1199: medium
   * 1200 - 1499: large
   * 1500 - 2649: xlarge
   * 2650 - infinity: xxlarge
   */
  categorizeSize(size: number): ViewportType {
    // anything UP to the breakpoint: so 767.99 would be xsmall
    if (size < 576) {
      return 'mini';
    } else if (size < 768) {
      return 'xsmall';
    } else if (size < 992) {
      return 'small';
    } else if (size < 1200) {
      return 'medium';
    } else if (size < 1500) {
      return 'large';
    } else if (size < 2650) {
      return 'xlarge';
    } else {
      return 'xxlarge';
    }
  }

  handleBreakpointChanges() {
    this.breakpointSizeChange$
      .pipe(
        pairwise(),
        map(([prev, next]) => [
          this.categorizeSize(prev),
          this.categorizeSize(next),
        ]),
        filter(([prev, next]) => prev !== next),
        map(([prev, next]) => next),
        debounceTime(30),
      )
      .subscribe((updatedViewportType) => {
        this.ngZone.run(() => {
          this.store.dispatch(
            fromActions.updateLayout({
              payload: {
                viewport: updatedViewportType,
                mobileNav: this.showMobileNav(updatedViewportType),
              },
            })
          );
        });
      });
  }

  /*
  These methods will return true if the screen viewport is <= the max size,
  or false otherwise
*/
  maxSizeMini(): Observable<boolean> {
    return this.store.select(layoutSelectors.maxSizeMini);
  }
  maxSizeXSmall(): Observable<boolean> {
    return this.store.select(layoutSelectors.maxSizeXSmall);
  }
  maxSizeSmall(): Observable<boolean> {
    return this.store.select(layoutSelectors.maxSizeSmall);
  }
  maxSizeMedium(): Observable<boolean> {
    return this.store.select(layoutSelectors.maxSizeMedium);
  }
  maxSizeLarge(): Observable<boolean> {
    return this.store.select(layoutSelectors.maxSizeLarge);
  }
  maxSizeXLarge(): Observable<boolean> {
    return this.store.select(layoutSelectors.maxSizeXLarge);
  }
  maxSizeXXLarge(): Observable<boolean> {
    return this.store.select(layoutSelectors.maxSizeXXLarge);
  }

  showMobileNav(vp: ViewportType): boolean {
    return ['mini', 'xsmall'].includes(vp);
  }
}
