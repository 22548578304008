import { ObjectValidator } from '@core/helper/object-validator';
import { ProductType } from '@core/models/api/dsm-types';

export interface DocumentsEntity {
  documents: DocumentEntity[];
  productType: ProductType;
}

export interface DocumentEntity {
  attachmentRequired: boolean;
  category: string;
  code: string;
  dateCreated: string;
  docUID: string;
  editionDate: string;
  esignable: boolean;
  formNumber: string;
  formSubCategory: string;
  name: string;
  pniemail?: string;
  roles?: Role[];
  timestamp: string;
}

export interface Role {
  role: string;
}

export function sanitizeDocumentsEntity(input: unknown): DocumentsEntity {
  return ObjectValidator.forceSchema<DocumentsEntity>(
    input,
    {
      documents: [sanitizeDocumentEntity],
      productType: 'string',
    },
    ['productType']
  );
}

export function sanitizeDocumentEntity(input: unknown): DocumentEntity {
  return ObjectValidator.forceSchema<DocumentEntity>(
    input,
    {
      attachmentRequired: 'boolean',
      category: 'string',
      code: 'string',
      dateCreated: 'string',
      docUID: 'string',
      editionDate: 'string',
      esignable: 'boolean',
      formNumber: 'string',
      formSubCategory: 'string',
      name: 'string',
      pniemail: 'string',
      roles: [{ role: 'string' }],
      timestamp: 'string',
    },
    ['pniemail', 'roles']
  );
}
