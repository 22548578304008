import {
  ProductType,
  quoteStatusGreaterThan,
} from '@core/models/api/dsm-types';
import { VehicleModel } from '@core/models/views/vehicle.model';
import { getCoveragesFor } from '@entities/coverage/coverage.selector';
import { CurrentCarrierEntity } from '@entities/current-carrier/current-carrier.reducer';
import { getCurrentCarrierEntities } from '@entities/current-carrier/current-carrier.selector';
import { getAllDriverVehicleAssignments } from '@entities/driver-vehicle-assignment/driver-vehicle-assignment.selectors';
import { DriverEntity } from '@entities/driver/driver.entity';
import { getAllFeatureFlags } from '@entities/feature-flag/feature-flag.selector';
import { getAllSelectedDriverMemberModels } from '@entities/member/member.selector';
import { ProductModel } from '@entities/product/product.model';
import {
  getOverallFurthestQuoteStatus,
  getPolicyDateOptions,
  getProduct,
  getProductFurthestQuoteStatus,
  getSelectedActiveProductTypes,
  getSelectedProductTypes,
} from '@entities/product/product.selectors';
import { VehicleEntity } from '@entities/vehicle/vehicle.entity';
import {
  getAllSelectedVehiclesByProduct,
  getAllVehiclesByProduct,
} from '@entities/vehicle/vehicle.selector';
import { createSelector } from '@ngrx/store';
import { GeneralUtils } from '@shared/utils/general.utils';
import { TaskModel } from '../task.model';
import { TaskUtils } from '../utils/task.util';
import { VehicleTaskUtils } from '../utils/vehicle-task.utils';
import { getIncompleteTasks, selectAllTasks } from './task.selector';
import { forkJoin, merge, take } from 'rxjs';

export const getIncompleteVehicleTasks = (productId: ProductType) =>
  createSelector(
    getIncompleteTasks,
    (tasks) =>
      tasks.filter(
        (t) => t?.entityType === 'vehicle' && t?.productType === productId
      ) as TaskModel[]
  );

export const getAllVehicleTasks = (productId: ProductType) =>
  createSelector(
    selectAllTasks,
    (tasks) =>
      tasks.filter(
        (t) => t?.entityType === 'vehicle' && t?.productType === productId
      ) as TaskModel[]
  );

export const getVehicleTasksForVehicle = (vehicle: VehicleModel) =>
  createSelector(
    getIncompleteVehicleTasks(vehicle.productType as ProductType),
    getProductFurthestQuoteStatus(vehicle.productType as ProductType),
    (tasks, quoteStatus) =>
      tasks.filter(
        (task) =>
          `${task.entityId}` === `${vehicle.entityId}` &&
          !quoteStatusGreaterThan(
            task.ratingStatusOrdinal || 'Draft',
            quoteStatus || 'Draft'
          )
      )
  );

export const getIncompleteDVATasks = createSelector(
  getIncompleteTasks,
  (tasks) =>
    tasks.filter(
      (t) => t?.entityType === 'driverVehicleAssignment'
    ) as TaskModel[]
);

export const getIncompleteDVATasksByProduct = (productType: ProductType) =>
  createSelector(
    getIncompleteTasks,
    (tasks) =>
      tasks.filter(
        (t) =>
          t?.entityType === 'driverVehicleAssignment' &&
          t?.productType === productType
      ) as TaskModel[]
  );

export const getAllDVATasks = createSelector(
  selectAllTasks,
  (tasks) =>
    tasks.filter(
      (t) => t?.entityType === 'driverVehicleAssignment'
    ) as TaskModel[]
);

export const getDVATasksByProduct = (productType: ProductType) =>
  createSelector(
    selectAllTasks,
    (tasks) =>
      tasks.filter(
        (t) =>
          t?.entityType === 'driverVehicleAssignment' &&
          t?.productType === productType
      ) as TaskModel[]
  );

export const generateDVATasksByProduct = (productType: ProductType) =>
  createSelector(
    getSelectedProductTypes,
    getAllDriverVehicleAssignments,
    getAllVehiclesByProduct(productType),
    getAllSelectedDriverMemberModels(productType),
    getAllFeatureFlags,
    getProductFurthestQuoteStatus(productType),
    (
      selectedProductIds,
      assignments,
      vehicles,
      people,
      features,
      quoteStatus
    ) =>
      selectedProductIds.indexOf(productType) >= 0
        ? VehicleTaskUtils.generateDVATasks(
            assignments,
            vehicles,
            people as DriverEntity[],
            features,
            quoteStatus || 'Draft'
          )
        : []
  );

export const generateAllDVATasks = createSelector(
  getSelectedProductTypes,
  generateDVATasksByProduct('PersonalAuto'),
  generateDVATasksByProduct('MSA'),
  (selectedProductTypes, personalAutoDvaTasks, msaDvaTasks) => {
    const dvaTasks: TaskModel[] = [];
    if (selectedProductTypes.indexOf('PersonalAuto') >= 0) {
      dvaTasks.concat(personalAutoDvaTasks);
    }
    if (selectedProductTypes.indexOf('MSA') >= 0) {
      dvaTasks.concat(msaDvaTasks);
    }
    return dvaTasks;
  }
);

export const updateDVATasks = createSelector(
  getAllDVATasks,
  generateAllDVATasks,
  (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
);

export const updateDVATasksByProduct = (productType: ProductType) =>
  createSelector(
    getDVATasksByProduct(productType),
    generateDVATasksByProduct(productType),
    (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
  );

export const generateVehicleTasks = (productId: ProductType) =>
  createSelector(
    getSelectedActiveProductTypes,
    getAllSelectedVehiclesByProduct(productId),
    getAllFeatureFlags,
    getProductFurthestQuoteStatus(productId),
    getProduct(productId),
    getCoveragesFor('PersonalAuto'),
    (selectedProductIds, vehicles, features, quoteStatus, product, coverages) =>
      VehicleTaskUtils.generateVehicleTasks(
        vehicles,
        features,
        quoteStatus || 'Draft',
        product || ({} as ProductModel),
        coverages
      ).map((task: TaskModel) =>
        addMatchedVehicleDetailsToTask(
          task,
          vehicles.find(
            (vehicle) => '' + vehicle.vehicleId === (task?.entityId || '-1')
          )
        )
      ) || []
  );

export const updateAutoTasks = createSelector(
  getAllVehicleTasks('PersonalAuto'),
  generateVehicleTasks('PersonalAuto'),
  (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
);

export const updateMsaTasks = createSelector(
  getAllVehicleTasks('MSA'),
  generateVehicleTasks('MSA'),
  (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
);

export const updateBoatTasks = createSelector(
  getAllVehicleTasks('Boat'),
  generateVehicleTasks('Boat'),
  (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
);

export const updateRVTasks = createSelector(
  getAllVehicleTasks('RV'),
  generateVehicleTasks('RV'),
  (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
);

export const generatePersonalAutoProductTasks = createSelector(
  getAllFeatureFlags,
  getProductFurthestQuoteStatus('PersonalAuto'),
  getProduct('PersonalAuto'),
  getPolicyDateOptions('PersonalAuto'),
  getSelectedActiveProductTypes,
  (features, quoteStatus, product, policyDateOptions, selectedProducts) =>
    VehicleTaskUtils.generatePersonalAutoTasks(
      features,
      quoteStatus || 'Draft',
      product as ProductModel,
      selectedProducts,
      policyDateOptions
    )
);
export const generateMsaProductTasks = createSelector(
  getAllFeatureFlags,
  getProductFurthestQuoteStatus('MSA'),
  getProduct('MSA'),
  getPolicyDateOptions('MSA'),
  getSelectedActiveProductTypes,
  (features, quoteStatus, product, policyDateOptions, selectedProducts) =>
    VehicleTaskUtils.generateMsaTasks(
      features,
      quoteStatus || 'Draft',
      product as ProductModel,
      selectedProducts,
      policyDateOptions
    )
);

export const generateBoatProductTasks = createSelector(
  getAllFeatureFlags,
  getProductFurthestQuoteStatus('Boat'),
  getProduct('Boat'),
  getPolicyDateOptions('Boat'),
  getSelectedActiveProductTypes,
  (features, quoteStatus, product, policyDateOptions, selectedProducts) =>
    VehicleTaskUtils.generateBoatTasks(
      features,
      quoteStatus || 'Draft',
      product as ProductModel,
      selectedProducts,
      policyDateOptions
    )
);

export const generateRVProductTasks = createSelector(
  getAllFeatureFlags,
  getProductFurthestQuoteStatus('RV'),
  getProduct('RV'),
  getPolicyDateOptions('RV'),
  getSelectedActiveProductTypes,
  getAllVehiclesByProduct('RV'),
  getCoveragesFor('RV'),
  (
    features,
    quoteStatus,
    product,
    policyDateOptions,
    selectedProducts,
    vehicles,
    coverages
  ) =>
    VehicleTaskUtils.generateRVTasks(
      features,
      quoteStatus || 'Draft',
      product as ProductModel,
      selectedProducts,
      policyDateOptions,
      vehicles,
      coverages
    )
);

export const getPersonalAutoProductTasks = createSelector(
  selectAllTasks,
  (tasks) =>
    tasks.filter((t) => t?.entityType === 'personalAutoProduct') as TaskModel[]
);

export const updatePersonalAutoProductTasks = createSelector(
  getPersonalAutoProductTasks,
  generatePersonalAutoProductTasks,
  (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
);

export const getMsaProductTasks = createSelector(
  selectAllTasks,
  (tasks) => tasks.filter((t) => t?.entityType === 'msaProduct') as TaskModel[]
);

export const updateMsaProductTasks = createSelector(
  getMsaProductTasks,
  generateMsaProductTasks,
  (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
);

export const getRVProductTasks = createSelector(
  selectAllTasks,
  (tasks) => tasks.filter((t) => t?.entityType === 'rvProduct') as TaskModel[]
);

export const updateRVProductTasks = createSelector(
  getRVProductTasks,
  generateRVProductTasks,
  (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
);

export const getBoatProductTasks = createSelector(
  selectAllTasks,
  (tasks) => tasks.filter((t) => t?.entityType === 'boatProduct') as TaskModel[]
);

export const updateBoatProductTasks = createSelector(
  getBoatProductTasks,
  generateBoatProductTasks,
  (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
);

export const generateCurrentCarrierTasks = createSelector(
  getSelectedActiveProductTypes,
  getOverallFurthestQuoteStatus,
  getCurrentCarrierEntities,
  getAllFeatureFlags,
  (products, quoteStatus, currentCarriers, features) => {
    const returnTasks: TaskModel[] = [];
    if (products.indexOf('PersonalAuto') >= 0) {
      VehicleTaskUtils.generateAutoCurrentCarrierTasks(
        currentCarriers['PersonalAuto' as ProductType] as CurrentCarrierEntity,
        quoteStatus || 'Draft',
        features
      ).forEach((task) => returnTasks.push(task));
    }

    if (products.indexOf('MSA') >= 0) {
      VehicleTaskUtils.generateMsaCurrentCarrierTasks(
        currentCarriers['MSA' as ProductType] as CurrentCarrierEntity,
        quoteStatus || 'Draft',
        features
      ).forEach((task) => returnTasks.push(task));
    }
    return returnTasks;
  }
);

export const getCurrentCarrierTasks = createSelector(
  selectAllTasks,
  (tasks) =>
    tasks.filter((t) => t?.entityType === 'currentCarrier') as TaskModel[]
);

export const updateCurrentCarrierTasks = createSelector(
  getCurrentCarrierTasks,
  generateCurrentCarrierTasks,
  (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
);

function addMatchedVehicleDetailsToTask(
  task: TaskModel,
  matchedVehicle?: VehicleEntity
): TaskModel {
  return {
    ...task,
    entityFriendlyId: matchedVehicle
      ? `${matchedVehicle.year} ${matchedVehicle.make} ${
          matchedVehicle.model || ''
        }`
      : '',
    elementId: matchedVehicle
      ? GeneralUtils.createVehicleElementId(
          matchedVehicle || ({} as VehicleEntity),
          task.elementId || ''
        )
      : undefined,
  };
}
