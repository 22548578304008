import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { AddressFormOptions } from '@core/models/views/address-contact-form-options';
import { from, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { GisAdapter } from '../adapters/gis.adapter';
import { StandardizedAddress, Suggestion } from '../adapters/gis.model';
import { AddressEntity } from '../store/entities/address/address.entity';
import { SessionService } from './session.service';

let SUGGESTION_STREAM_DEBOUNCE_MS = 1000;

export function __TEST_ONLY_debounceFast(): void {
  SUGGESTION_STREAM_DEBOUNCE_MS = 1;
}

@Injectable({
  providedIn: 'root',
})
export class GisService {
  constructor(
    private gisAdapter: GisAdapter,
    private sessionService: SessionService
  ) {}

  suggestAddress(
    address: string,
    outsideQuoteState?: boolean
  ): Observable<Suggestion[]> {
    let quoteState = '';
    const stageOne = outsideQuoteState
      ? this.gisAdapter.suggestAddresses(address)
      : this.sessionService.getQuoteState().pipe(
          take(1),
          switchMap((state) => {
            quoteState = state;
            return this.gisAdapter.suggestAddresses(address, state);
          })
        );
    return stageOne.pipe(
      map((response) => {
        return (
          response.suggestions?.filter(
            (suggestion) =>
              this.isUsaAddress(suggestion.text || '') &&
              (!outsideQuoteState
                ? this.matchesQuoteState(suggestion.text, quoteState)
                : true)
          ) || []
        );
      })
    );
  }

  private isUsaAddress(address: string): boolean {
    return address.endsWith('USA');
  }

  private matchesQuoteState(address: string, quoteState: string): boolean {
    const words = address.split(/[^\w]/);
    return words.includes(quoteState);
  }

  /**
   * Emits a replacement for the input on success.
   * Anything goes wrong, the returned Observable will fail with an error object you can report to Angular Forms.
   */
  validateAndStandardizeAddress(
    text: string,
    options: AddressFormOptions
  ): Observable<StandardizedAddress> {
    const prevalidationResponse = this.prevalidateStandardize(text);
    if (prevalidationResponse) {
      return throwError(prevalidationResponse);
    }
    return this.gisAdapter.standardizeAddress(text).pipe(
      map((response) => {
        // TODO filter responses, eg (precision=="") we don't want it
        const addresses = response.standardizedAddresses.filter((a) =>
          this.isValidAddress(a)
        );
        if (addresses.length < 1) {
          throw { addressNotFound: true };
        }
        if (addresses.length > 1) {
          // TODO We'll want to handle this case eventually with UI.
          throw { multipleAddresses: addresses.length };
        }
        const addr = addresses[0];
        if (
          !options.outsideQuoteState &&
          options.quoteState &&
          addr.state !== options.quoteState
        ) {
          throw { wrongState: options.quoteState };
        }
        return addr;
      }),
      catchError((error) => {
        // Validation errors will have one field set. Anything else might be a real error.
        if (Object.keys(error)?.length !== 1) {
          return throwError({ invalidAddress: true });
        }
        return throwError(error);
      })
    );
  }

  prevalidateStandardize(text: string): ValidationErrors | null {
    text = text.trim();
    if (!text) {
      return { required: true };
    }
    const words = text.split(/\s/);
    if (words.length < 2) {
      // eg just a ZIP code. GIS will call that valid but we know it's not.
      return { invalidAddress: true };
    }
    return null;
  }

  isValidAddress(address: StandardizedAddress): boolean {
    return !!(
      address.street &&
      address.city &&
      address.state &&
      address.zipCode
    );
  }
}
