import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AccountInfoComponent } from './account-info.component';
import { CommonModule } from '@angular/common';
import { CommonFormModule } from '@shared/modules/common-form.module';
import { AcountInfoFormModule } from '../account-info-form/account-info-form.module';
import { AccountRegistrationContainerModule } from '@app/online-account-registration/account-registration-container/account-registration-container.module';
import { SharedComponentsModule } from '../shared-components.module';

@NgModule({
  declarations: [AccountInfoComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    CommonFormModule,
    AcountInfoFormModule,
    AccountRegistrationContainerModule,
    SharedComponentsModule,
  ],
  exports: [AccountInfoComponent],
})
export class AcountInfoModule {}
