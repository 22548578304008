import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

// Basically bolt-notication with rounded borders, padding differences and color variations
@Component({
  selector: 'nwx-notification-bubble',
  templateUrl: './notification-bubble.component.html',
  styleUrls: ['./notification-bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationBubbleComponent {
  @Input() type: 'warning' | 'error' | 'info' | 'nwx-error' = 'info';
  @Input() size: 'sm' | 'md' = 'md';
  @Input() paddingsize: 'sm' | 'lg' = 'lg';

  getIconName(): string {
    switch (this.type) {
      case 'warning':
        return 'exclamation-triangle-filled';
      case 'error':
      case 'nwx-error':
        return 'exclamation-circle-filled';
      default:
        return 'info-circle-filled';
    }
  }
}
