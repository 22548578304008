import { ProductType } from '@core/models/api/dsm-types';
import { MemberModel } from '@core/models/views/person.model';
import { CoverageEntity } from '@entities/coverage/coverage.entity';
import { CoveredLocationEntity } from '@entities/covered-location/covered-location.reducer';
import { CurrentCarrierEntity } from '@entities/current-carrier/current-carrier.reducer';
import { ProductModel } from '@entities/product/product.model';
import {
  MobileEnrollment,
  VehicleEnrollment,
} from '@entities/telematics/telematics.model';
import { VehicleEntity } from '@entities/vehicle/vehicle.entity';
import { Dictionary } from '@ngrx/entity';

export type PersonaName =
  | 'None'
  | 'UpcomingProfessional'
  | 'FirstTimeHomeBuyer'
  | 'TeenDriver'
  | 'EmptyNester'
  | 'DoItYourselfer';

export function personaDisplayName(name: PersonaName): string {
  switch (name) {
    case 'None':
      return '';
    case 'UpcomingProfessional':
      return 'Upcoming Professional Couple';
    case 'FirstTimeHomeBuyer':
      return 'First Time Home Buyer';
    case 'TeenDriver':
      return 'Family with Teen Driver';
    case 'EmptyNester':
      return 'Resizing Empty Nester';
    case 'DoItYourselfer':
      return 'Digital Do-It-Yourselfer';
  }
  return name;
}

export interface PersonaModel {
  name: PersonaName;
}

export const enum TwoWayRating {
  Adverse = -1,
  Unknown = 0,
  Favorable = 1,
}

/** Straight off the store.
 */
export interface PersonaSelectionInputs {
  products: ProductModel[];
  vehicles: VehicleEntity[];
  currentCarriers: Dictionary<CurrentCarrierEntity>;
  coverages: CoverageEntity[];
  drivers: MemberModel[];
  telematics: VehicleEnrollment | MobileEnrollment | undefined;
  coveredLocations: Dictionary<CoveredLocationEntity>;
  personaName: PersonaName;
}

/** Digested for decision-making.
 */
export interface PersonaSelectionCriteria {
  autoRated: boolean;
  propertyRated: boolean;
  propertyProduct: ProductType | '';
  autoVehicleCount: number;
  creditRating: TwoWayRating;
  autoPriorTerms: number;
  propertyPriorTerms: number;
  driverRating: TwoWayRating;
  highRiskTerritory: boolean;
  homeSquareFootage: number;
  homeStories: number;
  propertyAoi: number;
  youngestDriverAge: number;
  oldestDriverAge: number;
  pniAge: number;
  autoBiLimit: string;
  telematicsEnrolled: boolean;
  previousPersona: PersonaName;
  selectedProducts: string[];
}

export type PersonaRecommendationId =
  | 'Telematics'
  | 'IdTheft'
  | 'ValuablesPlus'
  | 'Warranty'
  | 'RoadsidePlus'
  | 'SmartHome'
  | 'Umbrella'
  | 'HomeMaintenanceJourneyGuide'
  | 'ServiceLine'
  | 'EquipmentBreakdown'
  | 'WaterBackupLimited';

export const PersonaRecommendationIdToDisplayLabelAndIcon = {
  Telematics: {
    label: 'Telematics',
    icon: 'phone',
  },
  IdTheft: {
    label: 'Identity theft',
    icon: 'wallet-circle-filled.svg',
  },
  ValuablesPlus: {
    label: 'Valuables Plus',
    icon: 'plus',
  },
  Warranty: {
    label: 'Warranty',
    icon: 'question-circle',
  },
  RoadsidePlus: {
    label: 'Roadside assistance',
    icon: 'tow-truck.svg',
  },
  SmartHome: {
    label: 'Smart home',
    icon: 'wi-fi.svg',
  },
  Umbrella: {
    label: 'Umbrella',
    icon: 'umbrella-circle-filled.svg',
  },
  HomeMaintenanceJourneyGuide: {
    label: 'Home maintenance journey guide',
    icon: 'question-circle',
  },
  ServiceLine: {
    label: 'Service line',
    icon: 'handshake.svg',
  },
  EquipmentBreakdown: {
    label: 'Equipment breakdown',
    icon: 'truck.svg',
  },
  WaterBackupLimited: {
    label: 'Water backup',
    icon: 'shield',
  },
};

export interface PersonaRecommendation {
  id: PersonaRecommendationId;
  product: 'Auto' | 'Property' | '';
  present: boolean;
  available: boolean;
  isCoverageWithLimit: boolean;
}

export interface PersonaReport {
  name: PersonaName;
  recommendations: PersonaRecommendation[];
}

export const VinVehicleTypeToDsmVehicleType = {
  CYCLE: 'Motorcycle',
  OFFRD: 'OffRoad',
  SNOWM: 'Snowmobile',
  UTLTR: 'UtilityTrailer',
};
