import * as fromCore from '@core/store/reducers';
import { createSelector } from '@ngrx/store';
import * as fromEligibleDiscounts from './eligible-discounts.reducer';
import * as fromProducts from '@core/store/entities/product/product.selectors';
import { EligibleDiscountRequest } from '@core/models/api/request/eligible-discount-request.model';
import { ProductType } from '@core/models/api/dsm-types';
import {
  EligibleDiscountId,
  EligibleDiscountsEntity,
} from './eligible-discounts.entity';
import { Dictionary } from '@ngrx/entity';

export const getEligibleDiscountsState = createSelector(
  fromCore.getCoreState,
  (state) => state.eligibleDiscounts
);

export const {
  selectAll: selectAllEligibleDiscount,
  selectEntities: selectEligibleDiscountEntities,
} = fromEligibleDiscounts.adapter.getSelectors(getEligibleDiscountsState);

export const getEligibleDiscountsLoading = createSelector(
  getEligibleDiscountsState,
  (state) => state.loading > 0
);

export const eligibleDiscountsForProduct = (product: ProductType) =>
  createSelector(selectEligibleDiscountEntities, (entities) =>
    Object.values(entities).filter((e) => e?.productType === product)
  );

export const getAllEligibleDiscounts = () =>
  createSelector(selectEligibleDiscountEntities, (entities) => {
    return Object.values(entities) as EligibleDiscountsEntity[];
  });

export const getEligibleDiscountCallsInFlight = createSelector(
  getEligibleDiscountsState,
  (state) => state.callsInFlight
);

export const getPolicyLineModifier = (
  name: EligibleDiscountId,
  productId: ProductType
) =>
  createSelector(
    selectEligibleDiscountEntities,
    fromProducts.getProduct(productId),
    (entities, product) => {
      return entities[`${name}_${product?.quoteId}`];
    }
  );

export const getPolicyLineModifierByQuoteId = (
  name: EligibleDiscountId,
  quoteId: string
) =>
  createSelector(
    selectEligibleDiscountEntities,
    (entities) => entities[`${name}_${quoteId}`]
  );

export const getPropertyPolicyLineModifier = (
  productType: ProductType,
  name: EligibleDiscountId
) =>
  createSelector(
    selectEligibleDiscountEntities,
    fromProducts.getProduct(productType),
    (entities, product) => {
      return entities[`${name}_${product?.quoteId}`];
    }
  );

export const getSortedPolicyLineModifier = (
  name: EligibleDiscountId,
  productId: ProductType
) =>
  createSelector(getPolicyLineModifier(name, productId), (entity) => {
    const newAvailableOptions = entity?.availableOptions
      ? [...entity?.availableOptions]
      : [];
    return {
      ...entity,
      availableOptions: newAvailableOptions.sort(function (a, b) {
        if (a.optionValue < b.optionValue) {
          return -1;
        }
        if (a.optionValue > b.optionValue) {
          return 1;
        }
        return 0;
      }),
    };
  });

export const getModifiersForMultipleEntities = (
  name: EligibleDiscountId,
  productTypeFilter?: (p: ProductType) => boolean
) =>
  createSelector(selectEligibleDiscountEntities, (entities) => {
    const nameAndSeparator = name + '_';
    const output: Dictionary<EligibleDiscountsEntity> = {};
    for (const key of Object.keys(entities)) {
      if (key.startsWith(nameAndSeparator)) {
        if (!productTypeFilter || productTypeFilter(entities[key]?.productType as ProductType)) {
          output[key] = entities[key];
        }
      }
    }
    return output;
  });

export const isEligibleDiscountPresent = (
  eligibleDiscountId: EligibleDiscountId
) =>
  createSelector(
    selectAllEligibleDiscount,
    (allDiscounts) =>
      !!allDiscounts.find((d) => d.eligibleDiscountId === eligibleDiscountId)
  );

export const buildEligibleDiscountsRequest = (
  productId: ProductType,
  correlationId?: string
) =>
  createSelector(
    fromProducts.getProduct(productId),
    (product) =>
      ({
        quoteId: product?.quoteId,
        productType: productId as ProductType,
        correlationId,
      } as EligibleDiscountRequest)
  );

export const buildUpdatePolicyLineRequest = (
  entity: EligibleDiscountsEntity,
  correlationId?: string
) =>
  createSelector(
    fromProducts.getProduct(entity.productType as ProductType),
    (product) => {
      const request: EligibleDiscountRequest = {
        productType: product?.type as ProductType,
        quoteId: product?.quoteId,
        name: entity.eligibleDiscountId,
        eligibleDiscount: {
          eligibleDiscountId: entity.eligibleDiscountId,
          selectedOptionValue: entity.selectedOptionValue || null,
          qualifyingInformation: entity.qualifyingInformation,
        },
        correlationId: correlationId,
      };
      if (entity.qualifyingInformation) {
        request.eligibleDiscount!.qualifyingInformation = {
          ...entity.qualifyingInformation,
        };
      }

      if (
        request.eligibleDiscount!.eligibleDiscountId === 'SmartDevice' &&
        request.eligibleDiscount!.selectedOptionValue === 'none selected'
      ) {
        request.eligibleDiscount!.selectedOptionValue = 'Declined';
      }

      return request;
    }
  );
