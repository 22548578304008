import { Injectable } from '@angular/core';
import { FullStoryService } from '@core/services/fullstory.service';
import { LogService } from '@core/services/log.service';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { getAccountId } from '../entities/account/account.selector';
import {
  issuePolicyFail,
  issuePolicySuccess,
} from '../entities/policy-issue/policy-issue.action';
import { areAllProductsInitiated } from '../entities/product/product.selectors';
import {
  initiateNewBusinessFail,
  initiateNewBusinessSuccess,
  rateBindSuccess,
  rateQuoteFail,
  rateQuoteSuccess,
} from '../entities/quote/quote.action';
import { getSessionId } from '../entities/session/session.selector';
import { getUser } from '../entities/user/user.selector';
import {
  getInitiateResultsForBusinessLog,
  getIssueResultsForBusinessLog,
  getRc1ResultsForBusinessLog,
  getRc2ResultsForBusinessLog,
} from './business-logging.selector';
import { getPrimaryNamedInsured } from '../entities/member/member.selector';

declare type NewRelic = any;
declare const newrelic: NewRelic;

@Injectable()
export class BusinessLoggingEffects {
  constructor(
    private store: Store,
    private fullStoryService: FullStoryService,
    private actions$: Actions,
    private log: LogService,
    private window: Window
  ) {}

  logQuoteInitiate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initiateNewBusinessSuccess, initiateNewBusinessFail),
        withLatestFrom(this.store.select(getInitiateResultsForBusinessLog)),
        map(([action, result]) => result),
        filterOutNull(),
        tap((result) => this.log.logBusinessEvent('initiate', result)),
        tap((result) =>
          (this.window as any).newrelic?.addPageAction('RC0', result)
        )
      ),
    { dispatch: false }
  );

  logQuoteRc1$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(rateQuoteSuccess, rateQuoteFail),
        withLatestFrom(this.store.select(getRc1ResultsForBusinessLog)),
        map(([action, result]) => result),
        filterOutNull(),
        tap((result) => this.log.logBusinessEvent('rc1', result)),
        tap((result) =>
          (this.window as any).newrelic?.addPageAction('RC1', result)
        )
      ),
    { dispatch: false }
  );

  logQuoteRc2$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(rateBindSuccess, rateQuoteFail),
        withLatestFrom(this.store.select(getRc2ResultsForBusinessLog)),
        map(([action, result]) => result),
        filterOutNull(),
        tap((result) => this.log.logBusinessEvent('rc2', result)),
        tap((result) =>
          (this.window as any).newrelic?.addPageAction('RC2', result)
        )
      ),
    { dispatch: false }
  );

  logQuoteIssue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(issuePolicySuccess, issuePolicyFail),
        withLatestFrom(this.store.select(getIssueResultsForBusinessLog)),
        map(([action, result]) => result),
        filterOutNull(),
        tap((result) => this.log.logBusinessEvent('issue', result)),
        tap((result) =>
          (this.window as any).newrelic?.addPageAction('RC3', result)
        )
      ),
    { dispatch: false }
  );

  checkForPrimaryNamedInsuredModification$ = createEffect(
    () =>
      this.store.select(getPrimaryNamedInsured).pipe(
        filter(
          (pni) =>
            pni?.person?.firstName !== null && pni?.person?.lastName !== null
        ),
        tap(() => this.fullStoryService.setPrimaryNamedInsured())
      ),
    { dispatch: false }
  );

  checkForUserModification$ = createEffect(
    () =>
      this.store.select(getUser).pipe(
        filter((user) => user.firstName !== null && user.lastName !== null),
        tap(() => this.fullStoryService.setAgentName())
      ),
    { dispatch: false }
  );

  checkForSessionIdModification$ = createEffect(
    () =>
      this.store.select(getSessionId).pipe(
        filter((id) => id !== null),
        tap((id) => this.fullStoryService.setSessionId(id))
      ),
    { dispatch: false }
  );

  checkForAccountIdModification$ = createEffect(
    () =>
      this.store.select(getAccountId).pipe(
        filter((id) => id !== null),
        tap((id) => this.fullStoryService.setAccountId(id))
      ),
    { dispatch: false }
  );

  checkForLoadedQuotes$ = createEffect(
    () =>
      this.store.select(areAllProductsInitiated).pipe(
        filter((loaded) => loaded === true),
        tap(() => this.fullStoryService.setProductSubmissionInfo())
      ),
    { dispatch: false }
  );
}
