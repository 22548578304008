<ng-container *ngIf="message; else noMessage">
  <bolt-icon
    attr.id="{{ id }}-warning"
    name="exclamation-triangle-filled"
    class="warning-display-with-warning-message"
    color="theme-warning"
  ></bolt-icon>
  <span class="warning-message">
    <em>{{ message }}</em>
  </span>
</ng-container>
<ng-template #noMessage>
  <div class="warning-display">
    <bolt-icon
      attr.id="{{ id }}-warning"
      name="exclamation-triangle-filled"
      color="theme-warning"
      [attr.size]="(maxSizeMini$ | async) ? 'sm' : 'md'"
    ></bolt-icon>
  </div>
</ng-template>
