import { Injectable } from "@angular/core";
import { DsmAdapter } from "@core/adapters/dsm.adapter";
import { ObjectValidator } from "@core/helper/object-validator";
import { ProductType } from "@core/models/api/dsm-types";
import { Observable, from } from "rxjs";

export interface PolicyRolesPerson {
  policyHolderId?: number;
  driverId?: number;
  policyHolderDisplayName?: string; /* Present in response, omit from request. */
  policyHolderType: string; /* 'PolicyPriNamedInsured' | 'PolicySecNamedInsured' | 'PolicyAddlNamedInsured' */
}

export interface PolicyRolesResponse {
  policyRoles: PolicyRolesPerson[];
  informationalMessages: any[];
}

export function sanitizePolicyRolesPerson(input: unknown): PolicyRolesPerson {
  return ObjectValidator.forceSchema<PolicyRolesPerson>(input, {
    policyHolderId: 'number',
    driverId: 'number',
    policyHolderDisplayName: 'string',
    policyHolderType: 'string',
  });
}

export function sanitizePolicyRolesResponse(input: unknown): PolicyRolesResponse {
  return ObjectValidator.forceSchema<PolicyRolesResponse>(input, {
    policyRoles: [sanitizePolicyRolesPerson],
    informationalMessages: 'any',
  });
}

@Injectable({
  providedIn: 'root',
})
export class PolicyRolesApiService {
  constructor(
    public dsmAdapter: DsmAdapter
  ) {}

  patchPolicyRoles(
    productType: ProductType,
    quoteId: string,
    changes: PolicyRolesPerson[]
  ): Observable<PolicyRolesResponse> {
    const body = {
      policyRoles: changes,
    };
    return this.dsmAdapter.request<PolicyRolesResponse>(
      sanitizePolicyRolesResponse,
      productType,
      'PATCH',
      `/quotes/${quoteId}/policy-roles`,
      'patch-policy-roles',
      { body }
    );
  }
}
