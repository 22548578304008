import { Injectable } from '@angular/core';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Injectable({
  providedIn: 'root',
})
export class Base64Utils {
  constructor(private window: Window) {}

  decodeBase64(encoded: string): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      try {
        const xhr = new (this.window as any).XMLHttpRequest();

        const dataUrl = 'data:application/octet-stream;base64,' + encoded;
        xhr.responseType = 'arraybuffer';
        xhr.open('GET', dataUrl);
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== 4) {
            return;
          }
          if (xhr.response) {
            resolve(xhr.response);
          } else {
            reject('Failed to decode');
          }
        };
        xhr.send();
      } catch (e) {
        reject(e);
      }
    });
  }

  decodeBase64ToBlobUrl(encoded: string, contentType: string): Promise<string> {
    return this.decodeBase64(encoded).then((bin) => {
      const blob = new (this.window as any).Blob([bin], { type: contentType });
      const url = (this.window as any).URL.createObjectURL(blob);
      return url;
    });
  }
}
