import { ProductType } from '@core/models/api/dsm-types';
import { MemberModel } from '@core/models/views/person.model';
import { ExpectedActions } from '@core/services/action-aggregator.service';
import {
  PolicyHolderActions,
  DriverActions,
  EligibleDiscountsActions,
  HouseholdMemberActions,
  QuoteActions,
} from '@app/core/store/actions';
import { PeoplePageSubmission } from '@app/people/pages/member/member-page.component';
import { EligibleDiscountsEntity } from '@core/store/entities/eligible-discounts/eligible-discounts.entity';
import { Action } from '@ngrx/store';
import { PersonUtils } from '../person.utils';
import { Nullable } from '../type.utils';
import { PageUtils } from './page.utils';
import { ProductModel } from '@core/store/entities/product/product.model';
import { StringUtils } from '@shared/utils/string.utils';
import { HouseholdMemberEntity } from '@core/store/entities/household-member/household-member.model';
import { MemberEntity } from '@core/store/entities/member/member.reducer';
import { MemberPolicyRole } from '@core/models/entities/base.entity';
import { PersonRoleSelectionUtils } from '../person-role-selection.utils';
import { syncMultiProductDiscountsToBackEnd } from '@entities/multi-product-discount/multi-product-discount.action';
import { updateQuoteFail } from '../../../core/store/entities/quote/quote.action';

interface AnyPersonAction {
  payload: {
    policyHolderId: number;
    driverId: number;
    householdMemberId: number;
  };
  policyHolder: {
    policyHolderId: number;
  };
}

export class PeoplePageUtils extends PageUtils {
  static CreatePeoplePageSubmission(
    people: Nullable<MemberModel[]>,
    selectedProducts: ProductModel[],
    ridingAssociation: Nullable<EligibleDiscountsEntity>,
    rvAssociation: Nullable<EligibleDiscountsEntity>
  ): PeoplePageSubmission {
    if (people) {
      people = PersonRoleSelectionUtils.addMissingPolicyRoles(
        people,
        selectedProducts.map((product) => product.type)
      );
    } else {
      people = [];
    }

    const actions = PeoplePageUtils.generateSubmitActions(
      people,
      selectedProducts
        .filter((product) => product.isDsmActive)
        .map((product) => product.type)
    );

    const expectedActions = PeoplePageUtils.generateExpectedActions(actions);

    return {
      actions,
      expectedActions,
      ridingAssociation,
      rvAssociation,
    };
  }

  private static generateExpectedActions(actions: Action[]): ExpectedActions {
    const expectedActions: ExpectedActions = [];
    for (const action of actions) {
      const successType = PeoplePageUtils.getActionSuccessType(action.type);
      const errorType = PeoplePageUtils.getActionErrorType(action.type);
      if (!successType && !errorType) {
        continue;
      }
      const correlationId = (action as unknown as { correlationId: string })
        .correlationId;
      expectedActions.push({
        success: successType ? [{ type: successType, correlationId }] : [],
        error: errorType ? [{ type: errorType, correlationId }] : [],
      });
    }
    return expectedActions;
  }

  private static getActionSuccessType(trigger: string): string {
    switch (trigger) {
      case PolicyHolderActions.upsertPolicyHolder.type:
        return PolicyHolderActions.upsertPolicyHolderSuccess.type;
      case DriverActions.upsertDriver.type:
        return DriverActions.upsertDriverSuccess.type;
      case HouseholdMemberActions.upsertHouseholdMember.type:
        return HouseholdMemberActions.upsertHouseholdMemberSuccess.type;
      case PolicyHolderActions.deletePolicyHolder.type:
        return PolicyHolderActions.deletePolicyHolderSuccess.type;
      case DriverActions.deleteDriver.type:
        return DriverActions.deleteDriverSuccess.type;
      case HouseholdMemberActions.removeHouseholdMember.type:
        return HouseholdMemberActions.removeHouseholdMemberSuccess.type;
      case QuoteActions.updateQuote.type:
        return QuoteActions.updateQuoteSuccess.type;
    }
    return '';
  }

  private static getActionErrorType(trigger: string): string {
    switch (trigger) {
      case PolicyHolderActions.upsertPolicyHolder.type:
        return PolicyHolderActions.upsertPolicyHolderError.type;
      case DriverActions.upsertDriver.type:
        return DriverActions.upsertDriverError.type;
      case HouseholdMemberActions.upsertHouseholdMember.type:
        return HouseholdMemberActions.upsertHouseholdMemberFail.type;
      case PolicyHolderActions.deletePolicyHolder.type:
        return PolicyHolderActions.deletePolicyHolderError.type;
      case DriverActions.deleteDriver.type:
        return DriverActions.deleteDriverError.type;
      case HouseholdMemberActions.removeHouseholdMember.type:
        return HouseholdMemberActions.removeHouseholdMemberFail.type;
      case QuoteActions.updateQuote.type:
        return QuoteActions.updateQuoteFail.type;
    }
    return '';
  }

  private static generateSubmitActions(
    people: MemberModel[],
    selectedProducts: ProductType[]
  ): Action[] {
    const actions: Action[] = [];

    if (selectedProducts.includes('PersonalUmbrella')) {
      const correlationId = StringUtils.generateUuid();
      actions.push(
        QuoteActions.updateQuote({
          productType: 'PersonalUmbrella',
          correlationId,
        })
      );
    }

    for (const member of people) {
      for (const role of member.policyRoles) {
        if (!member.selected) {
          PeoplePageUtils.addDeleteAction(actions, member, role);
        } else if (selectedProducts.includes(role.productType)) {
          // If we had a sense of "dirty forms", this would be the place to check for them.
          PeoplePageUtils.addUpsertAction(actions, member, role);
        }
      }
    }

    actions.push(syncMultiProductDiscountsToBackEnd());

    return actions;
  }

  private static addUpsertAction(
    actions: Action[],
    member: MemberModel,
    role: MemberPolicyRole
  ): void {
    switch (role.entityType) {
      case 'policyHolder':
        PeoplePageUtils.addUpsertPolicyHolderAction(actions, member, role);
        break;
      case 'driver':
        if (PeoplePageUtils.driverIsReadyToAdd(member)) {
          PeoplePageUtils.addUpsertDriverAction(actions, member, role);
        }
        break;
      case 'householdMember':
        PeoplePageUtils.addUpsertHouseholdMemberAction(actions, member, role);
        break;
    }
  }

  /** In general, this is the form's problem, not ours.
   * But adding people can be triggered on a retrieve, and we need to not send invalid requests then.
   */
  private static driverIsReadyToAdd(member: MemberModel): boolean {
    if (!member.relationToPrimaryNamedInsured) {
      return false;
    }
    return true;
  }

  private static addDeleteAction(
    actions: Action[],
    member: MemberModel,
    role: MemberPolicyRole
  ): void {
    if (!role.entityId) {
      return;
    }
    switch (role.entityType) {
      case 'policyHolder':
        PeoplePageUtils.addDeletePolicyHolderAction(actions, member, role);
        break;
      case 'driver':
        PeoplePageUtils.addDeleteDriverAction(actions, member, role);
        break;
      case 'householdMember':
        PeoplePageUtils.addDeleteHouseholdMemberAction(actions, member, role);
        break;
    }
  }

  private static addUpsertPolicyHolderAction(
    actions: Action[],
    member: MemberModel,
    role: MemberPolicyRole
  ): void {
    actions.push(
      PolicyHolderActions.upsertPolicyHolder({
        payload: {
          ...member,
          policyHolderId: role.entityId,
          productType: role.productType,
        },
        correlationId: StringUtils.generateUuid(),
      })
    );
  }

  private static addUpsertDriverAction(
    actions: Action[],
    member: MemberModel,
    role: MemberPolicyRole
  ): void {
    actions.push(
      DriverActions.upsertDriver({
        payload: {
          ...member,
          driverId: role.entityId,
          productType: role.productType,
        },
        correlationId: StringUtils.generateUuid(),
      })
    );
  }

  private static addUpsertHouseholdMemberAction(
    actions: Action[],
    member: MemberModel,
    role: MemberPolicyRole
  ): void {
    // CODEMINERS-5074: If this person is also a policyHolder on this product, do not update householdMember.
    /* if (
      member.policyRoles.find(
        (r) =>
          r.productType === role.productType && r.entityType === 'policyHolder'
      )
    ) {
      return;
    } */
    const nonSpecifiedGender = member.person?.gender === 'X';
    const payload: HouseholdMemberEntity = {
      person: {
        ...member.person,
        gender: nonSpecifiedGender ? undefined : member.person?.gender,
      },
      nonSpecifiedGender,
      occupation: member?.occupation,
      employerName: member?.employerName,
      driverStatus:
        member.relationToPrimaryNamedInsured === 'PrimaryNamedInsured'
          ? 'PrincipalDriver'
          : 'OccasionalDriver',
      hasLiabilityLosses: member?.hasLiabilityLosses,
      householdMemberId: role.entityId,
      productType: role.productType,
      entityId: '',
      policyRoles: [],
      prefillId: member.prefillId,
    }
    if (member.ageFirstLicensed) {
      payload.ageFirstLicensed = member.ageFirstLicensed;
    }
    actions.push(
      HouseholdMemberActions.upsertHouseholdMember({
        payload,
        correlationId: StringUtils.generateUuid(),
      })
    );
  }

  private static addDeletePolicyHolderAction(
    actions: Action[],
    member: MemberModel,
    role: MemberPolicyRole
  ): void {
    if (
      actions.find(
        (a) =>
          a.type === PolicyHolderActions.deletePolicyHolder.type &&
          (a as unknown as AnyPersonAction).policyHolder.policyHolderId ===
            role.entityId
      )
    ) {
      return;
    }
    actions.push(
      PolicyHolderActions.deletePolicyHolder({
        policyHolder: {
          ...member,
          policyHolderId: role.entityId,
          productType: role.productType,
        },
        correlationId: StringUtils.generateUuid(),
      })
    );
  }

  private static addDeleteDriverAction(
    actions: Action[],
    member: MemberModel,
    role: MemberPolicyRole
  ): void {
    if (
      actions.find(
        (a) =>
          a.type === DriverActions.deleteDriver.type &&
          (a as unknown as AnyPersonAction).payload.driverId === role.entityId
      )
    ) {
      return;
    }
    actions.push(
      DriverActions.deleteDriver({
        payload: {
          ...member,
          driverId: role.entityId,
          productType: role.productType,
        },
        correlationId: StringUtils.generateUuid(),
      })
    );
  }

  private static addDeleteHouseholdMemberAction(
    actions: Action[],
    member: MemberModel,
    role: MemberPolicyRole
  ): void {
    if (
      actions.find(
        (a) =>
          a.type === HouseholdMemberActions.removeHouseholdMember.type &&
          (a as unknown as AnyPersonAction).payload.householdMemberId ===
            role.entityId
      )
    ) {
      return;
    }
    actions.push(
      HouseholdMemberActions.removeHouseholdMember({
        payload: {
          ...member,
          householdMemberId: role.entityId,
          productType: role.productType,
        },
        correlationId: StringUtils.generateUuid(),
      })
    );
  }

  static PolicyLineDiscount(
    policyLineDiscount: Nullable<EligibleDiscountsEntity>,
    nextActions: Action[]
  ): void {
    if (policyLineDiscount) {
      nextActions.push(
        EligibleDiscountsActions.updatePolicyLine({
          entity: policyLineDiscount,
        })
      );
    }
  }

  static CreateHouseholdMember(
    member: MemberEntity,
    productType: ProductType
  ): HouseholdMemberEntity {
    return {
      person: {
        ...member.person,
        gender: member.nonSpecifiedGender ? undefined : member.person?.gender,
      },
      ageFirstLicensed: member.ageFirstLicensed || null,
      nonSpecifiedGender: member.nonSpecifiedGender,
      occupation: member?.occupation,
      employerName: member?.employerName,
      driverStatus:
        member.relationToPrimaryNamedInsured === 'PrimaryNamedInsured'
          ? 'PrincipalDriver'
          : 'OccasionalDriver',
      hasLiabilityLosses: member?.hasLiabilityLosses,
      householdMemberId: PersonUtils.getEntityId(
        member,
        'householdMember',
        productType
      ),
      productType,
      policyRoles: member.policyRoles,
      prefillId: member.prefillId,
    } as HouseholdMemberEntity;
  }
}
