<div class="bolt-container no-pad">
  <div class="bolt-row column-headers">
    <div class="bolt-col-6 no-pad">Coverages</div>
    <div class="bolt-col-4 no-pad align-right">{{deductibleColumnHeaderLabel}}</div>
    <div class="bolt-col-2 no-pad align-right" *ngIf="showPremium">Premium</div>
  </div>
  <div class="bolt-row" *ngFor="let row of displayRows">
    <div class="bolt-col-6 no-pad">{{row.label}}</div>
    <div class="bolt-col-4 no-pad align-right">{{row.deductible}}</div>
    <div class="bolt-col-2 no-pad align-right" *ngIf="showPremium">{{row.premium}}</div>
  </div>
</div>
