<ng-container *ngIf="confirmed ; else roofYearMutable">
  <div class="bolt-container">
    <div class="bolt-row disabled-row">
      <div class="bolt-col-8 disabled-key">
        Year roof was last replaced?
      </div>
      <div class="bolt-col-4 disabled-value">
        {{ value }}
      </div>
    </div>
  </div>
  <div class="roofConfirmation">
    <nwx-notification-bubble type="info">
      Roof year replacement has been confirmed and can no longer be modified.
    </nwx-notification-bubble>
  </div>
</ng-container>

<ng-template #roofYearMutable>
  <bolt-textfield
    #boltTextField
    label="Year roof was last replaced?"
    nwxMask="year"
    id="Roof"
    name="Roof"
    required="required"
    [error]="errorMessage"
    (input)="onBoltChange($event)"
    (blur)="onBoltBlur()"
  ></bolt-textfield>
  <div class="roofConfirmation">
    <bolt-button type="primary" id="roofYearButton" class="roofYearButton" (click)="onConfirmRoofYear()">Confirm roof year</bolt-button>
    <nwx-notification-bubble [type]="getRoofYearNotificationBubbleType()">
      Date confirmation is required.
      Once confirmation is complete, the date can not be modified.
    </nwx-notification-bubble>
  </div>
</ng-template>
