import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import {
  LocationExcludedExposureAndProductInfo,
  LocationExcludedExposureEntity,
} from './location-excluded-exposures.entity';
import { ProductsSelectors } from '@core/store/selectors';
import * as fromLocationExcludedExposures from '@core/store/entities/exposures/location-excluded-exposures/location-excluded-exposures.reducer';
import { getAllFeatureFlags } from '@entities/feature-flag/feature-flag.selector';
import { LocationExcludedExposureRequest } from '@core/models/api/request/location-exposure-request.model';
import { getUnderlyingPoliciesAndProductInfo } from '@entities/underlying-policy/underlying-policy.selector';
import { UmbrellaUtils } from '@shared/utils/umbrella.utils';
import { UmbrellaTileModel } from '@shared/components/umbrella-tile/umbrella-tile.component';
import { GeneralUtils } from '@shared/utils/general.utils';
import { LocationExposureEntity } from '../location-exposures/location-exposures.entity';

export const getLocationExcludedExposureState = createSelector(
  fromCore.getCoreState,
  (state) => state.locationExcludedExposures
);

export const {
  selectAll: getAllLocationExcludedExposures,
  selectEntities: getLocationExcludedExposures,
} = fromLocationExcludedExposures.adapter.getSelectors(
  getLocationExcludedExposureState
);

export const buildLocationExcludedExposureRequest = (
  locationExcludedExposure: LocationExcludedExposureEntity,
  correlationId?: string
) =>
  createSelector(
    ProductsSelectors.getProduct('PersonalUmbrella'),
    getAllFeatureFlags,
    (product, featureFlags): LocationExcludedExposureRequest => {
      return {
        locationExcludedExposureBody: {
          locationExcludedExposure: {
            productId: locationExcludedExposure?.productId,
            locationExcludedExposureId:
              locationExcludedExposure?.locationExcludedExposureId,
            underlyingPolicyNumber:
              locationExcludedExposure?.underlyingPolicyNumber,
            carrier: locationExcludedExposure?.carrier,
            propertyType: locationExcludedExposure?.propertyType,
            locationAddress: locationExcludedExposure?.locationAddress,
            locationUsage: locationExcludedExposure?.locationUsage,
          },
        },
        quoteId: product?.quoteId,
        productType: product?.type,
      } as LocationExcludedExposureRequest;
    }
  );

export const getLocationExcludedExposuresAndProductInfo = createSelector(
  getAllLocationExcludedExposures,
  getUnderlyingPoliciesAndProductInfo,
  (
    locationExcludedExposures,
    underlyingPolicies
  ): LocationExcludedExposureAndProductInfo[] => {
    const underlyingPolicyNumbers = underlyingPolicies.map(
      (policy) => policy?.underlyingPolicy?.underlyingPolicyNumber
    );
    return (
      locationExcludedExposures
        /* .filter((exposure) =>
        underlyingPolicyNumbers.includes(
          exposure?.underlyingPolicyNumber as string
        )
      ) */
        .map((exposure) => {
          return {
            locationExcludedExposure: exposure,
            underlyingPolicy: underlyingPolicies.find(
              (up) =>
                up.underlyingPolicy.underlyingPolicyNumber ===
                exposure.underlyingPolicyNumber
            )?.underlyingPolicy,
            product: underlyingPolicies.find(
              (up) =>
                up.underlyingPolicy.underlyingPolicyNumber ===
                exposure.underlyingPolicyNumber
            )?.product,
          };
        }) as LocationExcludedExposureAndProductInfo[]
    );
  }
);

export const getLocationExcludedExposureTiles = createSelector(
  getLocationExcludedExposuresAndProductInfo,
  (locationExposures): UmbrellaTileModel[] => {
    return locationExposures.map((exposure) => {
      const titleLine1 = GeneralUtils.setTitlecase(
        exposure.locationExcludedExposure?.locationAddress?.addressLine1
      );
      const titleLine2 = `${GeneralUtils.setTitlecase(
        exposure.locationExcludedExposure?.locationAddress?.city
      )}, ${exposure.locationExcludedExposure?.locationAddress?.state} ${
        exposure.locationExcludedExposure?.locationAddress?.postalCode
      }`;

      return {
        data: exposure.locationExcludedExposure,
        subheading: 'EXCLUDED EXPOSURE',
        titleLine1,
        titleLine2,
        subDetails: [
          {
            value: `${UmbrellaUtils.getCurrentCarrierNameLabel(
              exposure?.locationExcludedExposure?.carrier
            )} - ${UmbrellaUtils.getLocationUsageLabel(
              exposure?.locationExcludedExposure?.locationUsage
            )}`,
          },
        ],
        icon: exposure.product?.imageUrl,
        alt: 'Property product icon',
        type: 'location-excluded-exposure',
      };
    });
  }
);

export const getDeletedLocationExcludedExposures = createSelector(
  getLocationExcludedExposureState,
  (state) => {
    return Object.values(state.deleted || {}).filter(
      (v) => !!v
    ) as LocationExcludedExposureEntity[];
  }
);
