import { PropertyCoverageTaskParams, TaskModel } from '@entities/task/task.model';
import { CoveragesPageRepresentation } from '@core/constants/pages';
import { ProductModel } from '@entities/product/product.model';
import { formatCurrency } from '@angular/common';
import { NavigationActions } from '@core/store/actions';
import {
  CONDO_BIND_PERSONAL_PROPERTY_LIMIT,
  TENANT_BIND_PERSONAL_PROPERTY_LIMIT,
} from '@shared/constants/app-constants';

export class PropertyTaskValidators {
  static coverageCustomRules(params: PropertyCoverageTaskParams): TaskModel[] {
    return [
      ...this.dwellingReplacementCostConfirmationRules(params),
      ...this.personalPropertyLimitRules(params)
    ];
  }

  private static dwellingReplacementCostConfirmationRules(params: PropertyCoverageTaskParams): TaskModel[] {
    let coverageTasks: TaskModel[] = [];
    const quotedHomeownerProduct: boolean = params.products.some(
      (product) => product.type === 'Homeowner'
    );

    if (
      params.features.showDwellingReplacementCost &&
      quotedHomeownerProduct &&
      !params.dwellingReplacementCostConfirmation
    ) {
      coverageTasks.push({
        page: CoveragesPageRepresentation,
        message:
          "Please confirm that you've read the Dwelling Replacement Cost options and Other Structures coverage",
        field: 'dwelling-replacement-cost-confirmation',
        elementId: 'dwelling-replacement-cost-verbiage',
        productType: 'Homeowner',
        entityType: 'coverage',
        ratingStatusOrdinal: 'Quoted',
        errorMessage: 'Required before bind',
      });
    }

    return coverageTasks;
  }

  private static personalPropertyLimitRules(params: PropertyCoverageTaskParams): TaskModel[] {
    let coverageTasks: TaskModel[] = [];
    const tenantProduct = params.products.find(p => p.type === 'Tenant');
    if (tenantProduct) {
      coverageTasks = coverageTasks.concat(
        this.personalPropertyLimitRulesByProduct(tenantProduct,
                                                 params,
                                                 TENANT_BIND_PERSONAL_PROPERTY_LIMIT)
      );
    }

    const condoProduct = params.products.find(p => p.type === 'Condominium');
    if (condoProduct) {
      coverageTasks = coverageTasks.concat(
        this.personalPropertyLimitRulesByProduct(condoProduct,
                                                 params,
                                                 CONDO_BIND_PERSONAL_PROPERTY_LIMIT)
      );
    }

    return coverageTasks;
  }

  private static personalPropertyLimitRulesByProduct(product: ProductModel,
                                                     params: PropertyCoverageTaskParams,
                                                     limit: number): TaskModel[] {
    let tasks: TaskModel[] = [];
    const hasCoverage: boolean = params.coverages?.some(c => {
      return c.productId === product.type && c.coverageId === 'BasicCoverage' &&
        c.selectedValue.some(v => v.code === 'BasicCoverageLimit' && +v.value >= limit);
    }) ?? false;

    if (hasCoverage && !(params.coveredLocation?.isPersonalPropertyLimitSameAsPreviousPolicy ?? false)) {
      tasks.push(this.buildPersonalPropertyLimitTask(product, limit));
    }

    return tasks;
  }

  private static buildPersonalPropertyLimitTask(product: ProductModel, limit: number): TaskModel {
    const limitCurrency: string = formatCurrency(limit, 'en-US', '$', 'USD', '1.0-0');
    return {
      page: CoveragesPageRepresentation,
      entityType: 'coverage',
      productType: product.type,
      field: 'BasicCoverageLimit',
      ratingStatusOrdinal: 'Draft',
      message: `Personal property limit greater than or equal to ${limitCurrency} requires prior underwriting approval. ` +
        'Please update coverage limit or continue quote in PolicyCenter.',
      actionMessage: 'Update coverage limit',
      resolveLabel: 'Continue quote in PolicyCenter',
      resolve: NavigationActions.linkToPolicyCenter({
        payload: {
          reason: 'BasicCoverageLimit',
          urlType: 'Job',
          id: product.quoteId,
        },
      }),
    }
  }
}
