import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';
import {
  EnrolledVehicle,
  ExtendedTelematicsVehicle,
  MobileEnrollment,
  SmartMilesVersion,
  VehicleEnrollment,
} from '@core/store/entities/telematics/telematics.model';
import { Nullable } from '@shared/utils/type.utils';

@Component({
  selector: 'nwx-smartmiles-enrollment',
  templateUrl: './smartmiles-enrollment.component.html',
  styleUrls: [
    './smartmiles-enrollment.component.scss',
    './../telematics/telematics.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartmilesEnrollmentComponent implements OnInit {
  @Input() vehicles: ExtendedTelematicsVehicle[] = [];
  @Input() quoteState!: string;
  @Input() enrollment: Nullable<VehicleEnrollment | MobileEnrollment>;
  @Input() maxSizeMini!: Nullable<boolean>;

  valueProposition = '';
  helpContent1 = '';
  helpTitle2 = '';
  helpContent2 = '';

  constructor() {}

  ngOnInit() {
    this.valueProposition = this.getValueProposition();
    this.helpContent1 = this.getContextualHelpContent1();
    this.helpTitle2 = this.getContextualHelpTitle2();
    this.helpContent2 = this.getContextualHelpContent2();
  }

  isCaliforniaSmartMiles(): boolean {
    return this.quoteState === 'CA';
  }

  get version(): SmartMilesVersion {
    if (this.vehicles.length) {
      return (this.vehicles[0] as EnrolledVehicle).version || '1.0';
    }
    return '1.0';
  }

  getValueProposition(): string {
    switch (this.version) {
      case '1.0':
        return `The <strong>monthly cost varies</strong> based on the vehicle's <strong>mileage</strong>.`;
      case '2.0':
        return `The <strong>monthly cost varies</strong> based on the vehicle's <strong>mileage</strong> and <strong>driving trends.</strong>`;
      default:
        return `The <strong>monthly cost varies</strong> based on the vehicle's <strong>mileage</strong>.`;
    }
  }

  getContextualHelpContent1(): string {
    switch (this.version) {
      case '1.0':
        return `We send the customer a device that plugs into a port below the vehicle's dashboard. The device measures mileage.`;
      case '2.0':
        return `We send the customer a device that plugs into a port below the vehicle's dashboard. The device measures mileage and driving trends.`;
      default:
        return `We send the customer a device that plugs into a port below the vehicle's dashboard. The device measures mileage.`;
    }
  }

  getContextualHelpTitle2(): string {
    switch (this.version) {
      case '1.0':
        return 'Monthly cost varies based on mileage.';
      case '2.0':
        return 'Monthly cost varies based on mileage and driving trends.';
      default:
        return 'Monthly cost varies based on mileage.';
    }
  }

  getContextualHelpContent2(): string {
    switch (this.version) {
      case '1.0':
        return `The monthly cost consists of two parts: a base rate, which is the same every month and a variable rate (number of miles driven multiplied by the cost per mile), which varies depending on mileage. The fewer miles the customer drives, the more they can save.`;
      case '2.0':
        return `The monthly cost consists of two parts: a base rate, which is the same every month and a variable rate (number of miles driven multiplied by the cost per mile), which varies depending on mileage and driving trends. The cost per mile may increase or decrease each month based on these four driving factors: Route Consistency, Smooth Speed Changes, Stop-and-Go Traffic, Hard Braking.`;
      default:
        return `The monthly cost consists of two parts: a base rate, which is the same every month and a variable rate (number of miles driven multiplied by the cost per mile), which varies depending on mileage. The fewer miles the customer drives, the more they can save.`;
    }
  }
}
