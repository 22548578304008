import { Injectable } from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { EligibleDiscountsActions } from '@core/store/actions';
import {
  EligibleDiscountId,
  EligibleDiscountsEntity,
} from '@entities/eligible-discounts/eligible-discounts.entity';
import { MultiProductDiscountsFormModel } from '@forms-store/store/models/multi-product-discounts-form/multi-product-discounts-form.reducer';
import { Action } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class MultiProductDiscountService {
  determineChanges(
    productTypes: ProductType[],
    discounts: EligibleDiscountsEntity[],
    form: MultiProductDiscountsFormModel
  ): Action[] {
    const changes: Action[] = [];
    for (const discount of discounts) {
      if (!this.isMultiProductDiscountId(discount.eligibleDiscountId)) {
        continue;
      }
      const value = this.getValueForMultiProductDiscount(
        discount,
        productTypes,
        form
      );
      if (value === discount.selectedOptionValue) {
        continue;
      }
      changes.push(
        EligibleDiscountsActions.updatePolicyLine({
          entity: {
            ...discount,
            selectedOptionValue: value,
          },
        })
      );
    }
    return changes;
  }

  private getValueForMultiProductDiscount(
    discount: EligibleDiscountsEntity,
    productTypes: ProductType[],
    form: MultiProductDiscountsFormModel
  ): string {
    // "HomeCarIndicator_I" has four values depending on PersonalAuto and FarmAuto.
    // We can't take this off the form generically.
    if (discount.eligibleDiscountId === 'HomeCarIndicator_I') {
      if (form.personalAuto) {
        if (form.farmAuto) {
          return this.valueOptionIfPresent(discount, 'NWAutoAndNWFarmAuto');
        } else {
          return this.valueOptionIfPresent(discount, 'NationwideAutoPolicy');
        }
      } else {
        if (form.farmAuto) {
          return this.valueOptionIfPresent(discount, 'NWFarmAuto');
        } else {
          return this.valueOptionIfPresent(discount, 'None');
        }
      }
    }

    // "Umbrella/Pet": For tax purposes, your dog counts as an umbrella.
    if (discount.eligibleDiscountId === 'Umbrella/Pet') {
      if (form.personalUmbrella || form.vetPet) {
        return this.getPositiveValue(discount);
      } else {
        return this.getNegativeValue(discount);
      }
    }

    // "RV" discount for Boat is true if an RV or MSA policy exists.
    // Ignore the "boatRv" flag; that's set regardless.
    if (
      discount.eligibleDiscountId === 'RV' &&
      discount.productType === 'Boat'
    ) {
      if (productTypes.includes('MSA') || productTypes.includes('RV')) {
        return this.getPositiveValue(discount);
      }
      if (form.powersports) {
        return this.getPositiveValue(discount);
      }
      return this.getNegativeValue(discount);
    }

    // "RV" discount for "RV" means Boat or MSA, you can't make this stuff up.
    if (discount.eligibleDiscountId === 'RV' && discount.productType === 'RV') {
      if (productTypes.includes('MSA') || productTypes.includes('Boat')) {
        return this.getPositiveValue(discount);
      }
      if (form.powersports) {
        return this.getPositiveValue(discount);
      }
      return this.getNegativeValue(discount);
    }

    // Read it from the form if possible. Best to do what the user saw.
    // The form should stay fresh re automatic discount decisions for selected products.
    // This clause should catch everything.
    const formKey = this.getFormKeyForDiscountId(discount.eligibleDiscountId);
    if (formKey === 'property') {
      return this.getPropertyDiscountValue(discount, form.property);
    } else if (formKey) {
      if (form[formKey]) {
        return this.getPositiveValue(discount);
      } else {
        return this.getNegativeValue(discount);
      }
    }

    // Generic selected-products check.
    const otherProductType = this.getOtherProductTypeForDiscountId(
      discount.eligibleDiscountId
    );
    if (otherProductType) {
      if (productTypes.includes(otherProductType)) {
        return this.getPositiveValue(discount);
      } else {
        return this.getNegativeValue(discount);
      }
    }

    // Welp we tried. Returning the existing value ensures no change request will be sent.
    return discount.selectedOptionValue || '';
  }

  private isMultiProductDiscountId(id: EligibleDiscountId): boolean {
    switch (id) {
      case 'AnnuityFP':
      case 'Auto':
      case 'Bank':
      case 'Commercial':
      case 'CondoPP':
      case 'FarmAgribusiness':
      case 'FarmPP':
      case 'FinancialProduct':
      case 'HomeCarIndicator_I':
      case 'HomeFinancial':
      case 'HomeownerPP':
      case 'Powersports':
      case 'RV':
      case 'TenantsPP':
      case 'Umbrella':
      case 'Umbrella/Pet':
      case 'VetPet':
      case 'NNWHomeownersPP':
      case 'NNWCondoPP':
        return true;
    }
    // Observed but not called out in reqs: VariableLifeFP, NNWHomeownersPP, NNWFarmPP, NNWCondoPP, TraditionalLifeFP,
    // NNWMobileHomePP, NNWTenantsPP, NNTenantsPP, NNCondoPP, NNFarmPP
    // ^ Per Leslie, these are not currently in scope, and will soon be added as a separate "multiline" form. -sommea1 2023-03-08
    return false;
  }

  private getOtherProductTypeForDiscountId(
    id: EligibleDiscountId
  ): ProductType | null {
    switch (id) {
      case 'Auto':
        return 'PersonalAuto';
      case 'CondoPP':
        return 'Condominium';
      case 'HomeCarIndicator_I':
        return 'PersonalAuto';
      case 'HomeownerPP':
        return 'Homeowner';
      case 'Powersports':
        return null; // handled special
      case 'RV':
        return null; // handled special
      case 'TenantsPP':
        return 'Tenant';
      case 'Umbrella':
        return 'PersonalUmbrella';
      case 'Umbrella/Pet':
        return 'PersonalUmbrella';
    }
    return null;
  }

  private getFormKeyForDiscountId(
    id: EligibleDiscountId
  ): keyof MultiProductDiscountsFormModel | '' {
    switch (id) {
      case 'AnnuityFP':
        return 'annuities';
      case 'Auto':
        return 'personalAuto';
      case 'Bank':
        return 'nationwideBank';
      case 'Commercial':
        return 'commercial';
      case 'CondoPP':
        return 'property';
      case 'FarmAgribusiness':
        return 'farm';
      case 'FarmPP':
        return 'farm';
      case 'FinancialProduct':
        return 'homeFinancial';
      case 'HomeCarIndicator_I':
        return '';
      case 'HomeFinancial':
        return 'homeFinancial';
      case 'HomeownerPP':
        return 'property';
      case 'Powersports':
        return 'powersports';
      case 'RV':
        return 'boatRv';
      case 'TenantsPP':
        return 'property';
      case 'Umbrella':
        return 'personalUmbrella';
      case 'Umbrella/Pet':
        return ''; // handled special
      case 'VetPet':
        return 'vetPet';
      case 'NNWHomeownersPP':
        return 'nonNWHomeowner';
      case 'NNWCondoPP':
        return 'nonNWCondo';
    }
    return '';
  }

  private valueOptionIfPresent(
    discount: EligibleDiscountsEntity,
    prefer: string
  ): string {
    if (discount.availableOptions) {
      if (discount.availableOptions.find((o) => o.optionValue === prefer)) {
        return prefer;
      }
    }
    return discount.selectedOptionValue || '';
  }

  private getPropertyDiscountValue(
    discount: EligibleDiscountsEntity,
    selectedProductFromForm: string
  ): string {
    let expectedSelection = '';
    switch (discount.eligibleDiscountId) {
      case 'CondoPP':
        expectedSelection = 'Condominium';
        break;
      case 'HomeownerPP':
        expectedSelection = 'Homeowner';
        break;
      case 'TenantsPP':
        expectedSelection = 'Tenant';
        break;
    }
    if (selectedProductFromForm === expectedSelection) {
      return this.getPositiveValue(discount);
    } else {
      return this.getNegativeValue(discount);
    }
  }

  private getPositiveValue(discount: EligibleDiscountsEntity): string {
    if (discount.availableOptions) {
      // Should pick from among these, but how?
      // Only HomeCarIndicator_I is currently known to use availableOptions, and we pick it off special before this point.
    }
    return 'true';
  }

  private getNegativeValue(discount: EligibleDiscountsEntity): string {
    if (discount.availableOptions) {
      // Should pick from among these, but how?
    }
    return 'false';
  }
}
