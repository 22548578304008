import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FeatureFlagActions } from '@core/store/actions';
import { FeatureFlagId } from './feature-flag.model';

export interface FeatureFlagState extends EntityState<FeatureFlagModel> {}

export interface FeatureFlagModel {
  id: FeatureFlagId;
  enabled: boolean;
}

export const adapter: EntityAdapter<FeatureFlagModel> =
  createEntityAdapter<FeatureFlagModel>({ selectId: (flag) => flag.id });

const featureFlagReducer = createReducer(
  adapter.getInitialState(),
  on(FeatureFlagActions.replaceAllFeatureFlags, (state, { featureFlags }) => {
    return adapter.addMany(featureFlags, adapter.removeAll(state));
  })
);

export function reducer(
  state: FeatureFlagState | undefined,
  action: Action
): FeatureFlagState {
  return featureFlagReducer(state, action);
}
