import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromActions from '@core/store/entities/protective-devices/protective-devices.action';
import { Observable } from 'rxjs';
import { ProtectiveDevicesOptionsModel } from '@core/store/entities/metadata/models/property-options.model';
import { ProductType } from '@core/models/api/dsm-types';
import { getCategorizedProtectiveDevices } from '@core/store/entities/protective-devices/protective-devices.selector';
import { Nullable } from '@shared/utils/type.utils';
import { PROTECTIVE_DEVICE_STRINGS, ProtectiveDeviceTypes } from '@shared/constants/protective-devices-constants';
import { SessionService } from './session.service';
import { ProtectiveDeviceEntity } from '@entities/protective-devices/protective-devices.reducer';
import {
  CoveredLocationEntity,
  CoveredLocationWindMitigation,
} from '@entities/covered-location/covered-location.reducer';
import { GeneralUtils } from '@shared/utils/general.utils';

@Injectable({
  providedIn: 'root',
})
export class ProtectiveDevicesService {
  private quoteState = '';

  constructor(private store: Store, sessionService: SessionService) {
    sessionService.getQuoteState().subscribe((s) => (this.quoteState = s));
  }

  dispatchGetProtectionDevices(
    productType: ProductType,
    quoteState: string
  ): void {
    this.store.dispatch(
      fromActions.getProtectiveDevices({ payload: { productType, quoteState } })
    );
  }

  getProtectiveDevicesOptions(
    productType: ProductType
  ): Observable<ProtectiveDevicesOptionsModel> {
    return this.store.select(getCategorizedProtectiveDevices(productType));
  }

  helpTextForProtectiveDeviceType(type: Nullable<string>): string {
    if (type) {
      const typeMetadata = PROTECTIVE_DEVICE_STRINGS.find(
        (t) => t.typekey === type || t.pcDisplayString === type
      );
      if (typeMetadata?.helpText) {
        return typeMetadata.helpText;
      }
    }
    return '';
  }

  displayStringForProtectiveDeviceType(type: Nullable<string>): string {
    if (!type) {
      return '';
    }
    const typeMetadata = PROTECTIVE_DEVICE_STRINGS.find(
      (t) => t.typekey === type || t.pcDisplayString === type
    );
    if (typeMetadata) {
      return typeMetadata.nwxDisplayString || typeMetadata.pcDisplayString;
    }
    return type;
  }

  /* Return the string we want to show the user.
   * This is not necessarily the "display" or "typekey" strings below, which are both meaningful to PC.
   * Strings returned here can not necessarily be converted back to the "display" or "typekey" set.
   * We have free rein to modify verbiage here. Not in the other two similar functions.
   */
  sanitizeProtectiveDeviceValueForDisplay(
    type: Nullable<string>,
    value: Nullable<string>
  ): string {
    if (!value) {
      return '';
    }
    const typeMetadata = PROTECTIVE_DEVICE_STRINGS.find(
      (t) => t.typekey === type || t.pcDisplayString === type
    );
    if (typeMetadata) {
      const valueMetadata = typeMetadata.values.find(
        (v) => v.typekey === value || v.pcDisplayString === value
      );
      if (valueMetadata) {
        return valueMetadata.nwxDisplayString || valueMetadata.pcDisplayString;
      }
    }
    return value;
  }

  /**
   * Policycenter's "display" strings. Not necessarily what we show the user (use sanitizeProtectiveDeviceValueForDisplay for that).
   */
  displayStringForProtectiveDeviceValue(
    type: Nullable<string>,
    value: Nullable<string>
  ): string {
    if (!value) {
      return '';
    }
    const typeMetadata = PROTECTIVE_DEVICE_STRINGS.find(
      (t) => t.typekey === type || t.pcDisplayString === type
    );
    if (typeMetadata) {
      const valueMetadata = typeMetadata.values.find(
        (v) => v.typekey === value || v.pcDisplayString === value
      );
      if (valueMetadata) {
        return valueMetadata.pcDisplayString;
      }
    }
    return value;
  }

  typekeyStringForProtectiveDeviceValue(
    type: Nullable<string>,
    value: Nullable<string>
  ): string {
    if (!value) {
      return '';
    }

    // Two annoying exceptions: "OpeningProtection_Ext:None" and "RoofGeometry_Ext:Hip Roof" have different values in FL and NC.
    // Our constants use the FL strings: "None" and "RoofGeometryHipRoof".
    if (
      this.quoteState === 'NC' &&
      type === 'OpeningProtection_Ext' &&
      value === 'None'
    ) {
      return 'OpeningProtectionNone';
    }
    if (
      this.quoteState === 'NC' &&
      type === 'RoofGeometry_Ext' &&
      value === 'Hip Roof'
    ) {
      return 'HipRoof';
    }

    const typeMetadata = PROTECTIVE_DEVICE_STRINGS.find(
      (t) => t.typekey === type || t.pcDisplayString === type
    );
    if (typeMetadata) {
      const valueMetadata = typeMetadata.values.find(
        (v) => v.typekey === value || v.pcDisplayString === value
      );
      if (valueMetadata) {
        return valueMetadata.typekey;
      }
    }
    return value;
  }

  /* The adapter does some cleanup, but there's further work that can only be done here.
   */
  sanitizeGetProtectiveDevicesResponse(
    devices: ProtectiveDeviceEntity[],
    coveredLocation: CoveredLocationEntity
  ): ProtectiveDeviceEntity[] {
    return devices.map((d) => {
      const existingDeviceValue: Nullable<string> = this.findExistingDeviceValueFromEntity(d, coveredLocation);
      return {
        ...d,
        value: existingDeviceValue ?? this.typekeyStringForProtectiveDeviceValue(d.type, d.value),
      };
    });
  }

  private findExistingDeviceValueFromEntity(
    device: ProtectiveDeviceEntity,
    coveredLocation: CoveredLocationEntity
  ): Nullable<string> {
    if (device.category === ProtectiveDeviceTypes.WIND_MITIGATION.typekey) {
      return this.getExistingWindMitigationFromEntity(device, coveredLocation);
    }

    switch (device.type) {
      case ProtectiveDeviceTypes.SPRINKLER_SYSTEM.typekey:
      case ProtectiveDeviceTypes.SPRINKLER_SYSTEM.pcDisplayString:
        return coveredLocation.protectiveDevices?.sprinklerSystem;
      case ProtectiveDeviceTypes.BURGLAR_ALARM.typekey:
      case ProtectiveDeviceTypes.BURGLAR_ALARM.pcDisplayString:
        return coveredLocation.protectiveDevices?.burglarAlarm;
      case ProtectiveDeviceTypes.FIRE_SMOKE_ALARM.typekey:
      case ProtectiveDeviceTypes.FIRE_SMOKE_ALARM.pcDisplayString:
        return coveredLocation.protectiveDevices?.fireOrSmokeAlarm;
      case ProtectiveDeviceTypes.WROUGHT_IRON_BARS.typekey:
      case ProtectiveDeviceTypes.WROUGHT_IRON_BARS.pcDisplayString:
        return GeneralUtils.parseStringFromBoolean(coveredLocation.protectiveDevices?.hasWroughtIronBar);
      case ProtectiveDeviceTypes.WIND_PROTECTIVE_DEVICE.typekey:
      case ProtectiveDeviceTypes.WIND_PROTECTIVE_DEVICE.pcDisplayString:
        return this.getCoveredLocationWindProtectiveDevice(coveredLocation);
    }
    return null;
  }

  private getExistingWindMitigationFromEntity(
    device: ProtectiveDeviceEntity,
    coveredLocation: CoveredLocationEntity
  ): Nullable<string> {
    // Wind Mitigation Pseudo Device
    if (device.type === ProtectiveDeviceTypes.WIND_MITIGATION.typekey) {
      const hasWindMitigations: boolean = (coveredLocation.protectiveDevices?.windMitigations?.length ?? 0) > 0;
      return GeneralUtils.parseStringFromBoolean(hasWindMitigations);
    }

    // Secondary Water Resistance Device
    if (device.type === ProtectiveDeviceTypes.SECONDARY_WATER_RESISTANCE.typekey) {
      return GeneralUtils.parseStringFromBoolean(coveredLocation.protectiveDevices?.hasSecondaryWaterResistance);
    }

    // Actual Wind Mitigation Devices
    const existingWindMitigation: Nullable<CoveredLocationWindMitigation> = coveredLocation
      .protectiveDevices?.windMitigations?.find(
        (wm: CoveredLocationWindMitigation) => wm.category === device.type
      );
    return existingWindMitigation?.selectedType ?? device.value;
  }

  private getCoveredLocationWindProtectiveDevice(
    coveredLocation: CoveredLocationEntity
  ): Nullable<string> {
    if (!coveredLocation.protectiveDevices?.windProtectiveDevices) return null;
    return coveredLocation.protectiveDevices.windProtectiveDevices.length > 0
      ? coveredLocation.protectiveDevices.windProtectiveDevices[0]
      : null;
  }
}
