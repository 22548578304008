import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { ProductsService } from '@core/services/products.service';
import { ProductModel } from '@core/store/entities/product/product.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'nwx-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpComponent implements OnInit, OnDestroy {
  quotedProducts: ProductModel[] = [];
  unsubscribe$: Subject<void> = new Subject();
  techSupportLink = 'https://nwproduction.service-now.com/agent/';

  constructor(
    public activeModal: NgbActiveModal,
    private productsService: ProductsService
  ) {}

  ngOnInit(): void {
    this.productsService
      .getSelectedProducts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedProducts) => {
        this.quotedProducts = selectedProducts.filter(
          (product) => product?.quoteId && product?.quoteId?.length > 0
        );
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
