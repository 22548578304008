<div
  class="bolt-container-fluid help-padding"
  [formGroup]="form"
  [nwxExposeFormErrors]="form"
  formName="vehicle-{{ vehicle?.vehicleId }}"
  *ngIf="vm$ | async as vm"
>
  <div class="bolt-row">
    <div class="bolt-col" *ngIf="vm.showVin">
      <ng-container [ngSwitch]="vehicle?.productType">
        <ng-container *ngSwitchCase="'PersonalAuto'">
          <nwx-vin-form
            *ngIf="
              !!vehicle?.productType && vehicle?.productType === 'PersonalAuto'
            "
            attr.taskElementId="vin-{{ vehicle?.vehicleId }}"
            [vehicleIndex]="index"
            [vehicle]="vehicle || {}"
            (valueChange)="onValueChange($event)"
            (formReady)="addChildForm('vin', $event)"
          ></nwx-vin-form>
        </ng-container>
        <ng-container *ngSwitchCase="'MSA'">
          <nwx-msa-vin-form
            *ngIf="!!vehicle?.productType && vehicle?.productType === 'MSA'"
            attr.taskElementId="vin-{{ vehicle?.vehicleId }}"
            [vehicle]="vehicle || {}"
            [vehicleIndex]="index"
            (valueChange)="onValueChange($event)"
            (formReady)="addChildForm('vin', $event)"
          ></nwx-msa-vin-form>
        </ng-container>
        <ng-container *ngSwitchCase="'RV'">
          <bolt-textfield
            *ngIf="!!vehicle?.productType && vehicle?.productType === 'RV'"
            attr.taskElementId="vin-{{ vehicle?.vehicleId }}"
            label="VIN"
            instructionaltext="VIN required for bindable rate."
            formControlName="vin"
            name="vin"
          ></bolt-textfield>
        </ng-container>
      </ng-container>
    </div>
    <div class="bolt-col">
      <bolt-textfield
        *ngIf="vm.showHin"
        label="HIN"
        instructionaltext="HIN required for bindable rate."
        attr.taskElementId="hin-{{ vehicle?.vehicleId }}"
        formControlName="hin"
        name="hin"
      ></bolt-textfield>
    </div>
    <div class="bolt-col">
      <bolt-textfield
        *ngIf="vm.showAnnualMiles"
        label="Annual miles driven"
        formControlName="annualMiles"
        name="annualMiles"
        type="number"
      ></bolt-textfield>
    </div>
  </div>
</div>
