import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  tap,
  filter,
  take,
  switchMap,
  catchError,
  skipWhile,
} from 'rxjs/operators';

import { DocumentsService } from '@core/services/documents.service';
import { Store } from '@ngrx/store';
import { isIssueCompleted } from '../store/entities/product/product.selectors';

@Injectable({ providedIn: 'root' })
export class DocumentsGuard {
  constructor(
    private documentsService: DocumentsService,
    private store: Store
  ) {}
  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(isIssueCompleted).pipe(
      skipWhile((flag) => !flag),
      switchMap(() =>
        this.documentsService.getDocumentsLoaded().pipe(
          tap((loaded) => {
            if (!loaded) {
              this.documentsService.dispatchGetDocuments();
            }
          }),
          filter((loaded) => loaded),
          take(1)
        )
      )
    );
  }
}
