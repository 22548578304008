import { Action, createReducer, on } from '@ngrx/store';
import { AgencyActions, RetrieveActions } from '@core/store/actions';
import { AgencyModel } from '@core/store/entities/agency/agency.model';
import { ProducerResponse } from '@core/adapters/producer-search.model';
import { ProducerSearch } from '../../../adapters/producer-search.model';

interface StateMap {
  [state: string]: ProducerSearch;
}

export interface AgencyState {
  agency: AgencyModel;
  isAgentStateLoaded: boolean;
  producerSearch: ProducerSearch | null;
  producerOptions: StateMap;
}

const initialState: AgencyState = {
  agency: {
    agent: {
      agencyCode: '',
    },
  },
  producerSearch: null,
  producerOptions: {},
  isAgentStateLoaded: false,
};

const agencyReducer = createReducer(
  initialState,
  on(AgencyActions.updateAgency, (state, { payload }) => {
    const agency = { ...payload };
    if (!payload.npn) {
      agency.npn = state.agency.npn;
    }
    return {
      ...state,
      agency: agency,
    };
  }),
  on(AgencyActions.setAgentState, (state, { agentState }) => ({
    ...state,
    agency: {
      ...state.agency,
      agent: {
        ...state.agency.agent,
        agentState: agentState,
      } as ProducerResponse,
    },
  })),
  on(AgencyActions.setProducerSearchResponse, (state, { search }) => ({
    ...state,
    producerSearch: search,
  })),
  on(
    AgencyActions.setProducerSearchForState,
    (state, { searchState, search }) => ({
      ...state,
      producerOptions: {
        ...state.producerOptions,
        [searchState]: search,
      } as StateMap,
    })
  )
);

export function reducer(
  state: AgencyState | undefined,
  action: Action
): AgencyState {
  return agencyReducer(state, action);
}
