import { Injectable, NgZone } from '@angular/core';
import { RouterActions } from '@core/store/actions';
import { NavigationPath } from '@core/store/router/router.action';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  constructor(private store: Store, private ngZone: NgZone) {}

  go(path: NavigationPath, showSpinner: boolean | string = false): void {
    this.store.dispatch(RouterActions.go({ payload: path, showSpinner }));
  }

  goConditionally(page: string, query?: object): void {
    this.store.dispatch(RouterActions.goConditionaly({ page, query }));
  }
}
