import { AddressEntity } from '@core/store/entities/address/address.entity';
import { AddressRequest } from '@core/models/api/request/address-request.model';
import { PolicyHolderEntity } from '@core/store/entities/policyholder/policyholder.entity';
import { Nullable } from '../type.utils';
import { DateUtils } from '../date.utils';
import { PolicyHolderRequest } from '@core/models/api/request/update-policyholder-request.model';
import { PolicyHolderType, ProductType } from '@core/models/api/dsm-types';

export class PolicyholderBuilder {
  static buildPolicyholderEntityFromResponse(
    response: PolicyHolderEntity,
    request: PolicyHolderRequest
  ): PolicyHolderEntity {
    return {
      entityId: request.policyHolder.entityId,
      person: {
        ...response?.person,
        gender: request.policyHolder.nonSpecifiedGender
          ? 'X'
          : response.person?.gender,
        dateOfBirth: response?.person?.dateOfBirth,
      },
      emailAddress: response.emailAddress,
      homeNumber: response.homeNumber,
      policyHolderId: response.policyHolderId,
      // This is because of DSM bug
      policyHolderType:
        response.policyHolderType || request.policyHolder.policyHolderType,
      prefillId: request.policyHolder.prefillId,
      productType: request.productType,
      membership: request.policyHolder.membership,
      selected: true,
    } as PolicyHolderEntity;
  }

  static buildPolicyholderRequestFromEntity(
    policyHolder: PolicyHolderEntity,
    productType?: ProductType
  ): PolicyHolderEntity {

    let policyHolderType: PolicyHolderType | undefined = policyHolder.policyHolderType;
    const phRole = policyHolder.policyRoles?.find(r => (
      r.entityType === 'policyHolder' &&
      r.productType === productType
    ));
    switch (phRole?.roleSequence) {
      case 'primary': policyHolderType = 'PolicyPriNamedInsured'; break;
      case 'secondary': policyHolderType = 'PolicySecNamedInsured'; break;
      case 'additional': policyHolderType = 'PolicyAddlNamedInsured'; break;
    }

    const resp: PolicyHolderEntity = {
      person: {
        ...policyHolder.person,
        suffix: policyHolder?.person?.suffix || null,
        dateOfBirth: policyHolder.person?.dateOfBirth?.includes('/')
          ? DateUtils.formatDateToDSM(policyHolder.person?.dateOfBirth)
          : policyHolder.person?.dateOfBirth,
      },
      address: policyHolder.address,
      associateNumber: policyHolder.associateNumber,
      emailAddress: policyHolder.emailAddress,
      homeNumber: policyHolder.homeNumber,
      isNationwideEmployee: policyHolder.isNationwideEmployee,
      entityId: policyHolder.entityId,
      policyHolderId: phRole?.entityId,
      policyHolderType,
      prefillId: policyHolder.prefillId,
      productType: policyHolder.productType,
      membership: policyHolder.membership,
      policyRoles: [],
      doesPolicyHolderTypeNeedUpdated:
        policyHolder.doesPolicyHolderTypeNeedUpdated,
    };

    if (resp.person?.gender === 'X') {
      delete resp.person?.gender;
      resp.nonSpecifiedGender = true;
    }

    if (policyHolder.productType === 'PersonalUmbrella') {
      resp.driverStatus =
        policyHolderType === 'PolicyPriNamedInsured'
          ? 'PrincipalDriver'
          : 'OccasionalDriver';
    }

    return resp;
  }

  static singleLineAddressFromResponse(address: AddressEntity): string {
    const street = `${address.street || address.addressLine1}, ${
      address.city
    }, ${address.state}, ${address.postalCode}`;
    return street;
  }

  static selectAddressId(
    address: AddressRequest,
    product: Nullable<string>
  ): string {
    if (address?.addressIds) {
      const id = address.addressIds[product || ''];
      if (id) {
        return id;
      }
    }
    return address?.addressId ? address?.addressId : '0';
  }
}
