import * as fromCore from '@core/store/reducers';
import { createSelector } from '@ngrx/store';

export const getAnnouncementContentState = createSelector(
  fromCore.getCoreState,
  (state) => state.announcementContent
);
export const getAnnouncementDate = createSelector(
  getAnnouncementContentState,
  (state) => state.announcementDate
);
export const getAnnouncementItems = createSelector(
  getAnnouncementContentState,
  (state) => state.announcementItems
);
