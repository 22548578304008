import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../error/error.model';
import { MortgageEntity } from './mortgage.reducer';
import {
  AddlInterestThirdPartyType,
  ProductType,
} from '@core/models/api/dsm-types';

export const addMortgage = createAction(
  '[Mortgage] Add Mortgage',
  props<{
    payload: MortgageEntity;
    correlationId?: string;
  }>()
);

export const addMortgageError = createAction(
  '[Mortgage] Add Mortgage Error',
  props<{
    error: ErrorModel;
    correlationId?: string;
  }>()
);

export const addMortgageSuccess = createAction(
  '[Mortgage] Add Mortgage Success',
  props<{
    payload: MortgageEntity[];
    correlationId?: string;
  }>()
);

export const updateMortgage = createAction(
  '[Mortgage] Update Mortgage',
  props<{ payload: MortgageEntity; correlationId?: string }>()
);

export const updateMortgageError = createAction(
  '[Mortgage] Update Mortgage Error',
  props<{
    error: ErrorModel;
    correlationId?: string;
  }>()
);

export const updateMortgageSuccess = createAction(
  '[Mortgage] Update Mortgage Success',
  props<{ payload: Update<MortgageEntity>; correlationId?: string }>()
);

export const deleteMortgage = createAction(
  '[Mortgage] Delete Mortgage',
  props<{
    payload: MortgageEntity;
    correlationId?: string;
  }>()
);

export const deleteAllMortgage = createAction(
  '[Mortgage] Delete All Mortgages'
);

export const deleteMortgagesOfType = createAction(
  '[Mortgage] Delete Mortgages Of Type',
  props<{
    payload: {
      productType: ProductType;
      mortgageType: AddlInterestThirdPartyType;
    };
  }>()
);

export const deleteMortgagesOfTypes = createAction(
  '[Mortgage] Delete Mortgages Of Types',
  props<{
    payload: {
      productType: ProductType;
      mortgageTypes: AddlInterestThirdPartyType[];
    };
  }>()
);

export const switchMortgageToPrimary = createAction(
  '[Mortgage] Switch Mortgage to primary',
  props<{ payload: MortgageEntity }>()
);

export const deleteMortgageError = createAction(
  '[Mortgage] Delete Mortgage Error',
  props<{
    error: ErrorModel;
    correlationId?: string;
  }>()
);

export const deleteMortgageSuccess = createAction(
  '[Mortgage] Delete Mortgage Success',
  props<{
    mortgageId: string;
    correlationId?: string;
  }>()
);
