import { DigitalIdCardsTextResponse } from '@core/models/api/response/digital-id-cards-text-response.model';
import { SessionActions } from '@core/store/actions';
import { Action, createReducer, on } from '@ngrx/store';
import { ErrorModel } from '../error/error.model';
import * as DigitalIdCardsTextActions from './digital-id-cards-text.action';

export interface DigitalIdCardsTextState {
  response: DigitalIdCardsTextResponse | null;
  loaded: boolean;
  loading: boolean;
  error?: ErrorModel | null;
}

export const initialState: DigitalIdCardsTextState = {
  response: null,
  loaded: false,
  loading: false,
};

const digitalIdCardsTextReducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(DigitalIdCardsTextActions.sendAutoIdCards, (state, {}) => ({
    ...state,
    loading: true,
  })),
  on(DigitalIdCardsTextActions.sendAutoIdCardsFail, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error,
  })),
  on(
    DigitalIdCardsTextActions.sendAutoIdCardsSuccess,
    (state, { payload }) => ({
      ...state,
      response: payload,
      loaded: true,
      loading: false,
    })
  )
);

export function reducer(
  state: DigitalIdCardsTextState | undefined,
  action: Action
): DigitalIdCardsTextState {
  return digitalIdCardsTextReducer(state, action);
}
