import { ObjectValidator } from '@core/helper/object-validator';
import { ProductType } from '@core/models/api/dsm-types';

export type DiscountCategory =
  | 'PolicyLine'
  | 'PersonalVehicle'
  | 'PolicyDriver';

export interface DiscountEntity {
  description: string;
  isDiscountApplied: boolean;
  category: DiscountCategory;
  coverableId?: string; // quote ID
  productType: ProductType; // product type
}

export function sanitizeDiscountEntity(input: unknown): DiscountEntity {
  return ObjectValidator.forceSchema<DiscountEntity>(
    input,
    {
      description: 'string',
      isDiscountApplied: 'boolean',
      category: 'string',
      coverableId: 'string',
      productType: 'string',
    },
    []
  );
}

export interface GroupedDiscounts {
  product: string;
  discounts: DiscountEntity[];
}
export interface GroupedDiscountsObject {
  [id: string]: GroupedDiscounts[];
}
