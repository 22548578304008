import { ObjectValidator } from "@core/helper/object-validator";

export interface EmploymentInfoEntity {
  currentEmployment?: string;
  fullTimeEmployment?: string;
}

export function sanitizeEmploymentInfoEntity(response: unknown): EmploymentInfoEntity {
  return ObjectValidator.forceSchema<EmploymentInfoEntity>(response, {
    currentEmployment: 'string',
    fullTimeEmployment: 'string',
  }, [
   'currentEmployment',
   'fullTimeEmployment',
  ]);
}
