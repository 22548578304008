import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PropertyOptionsModel } from '../store/entities/metadata/models/property-options.model';
import { propertyOptions } from '@assets/metadata/property/property-options';
import {
  stateSpecificFlags,
  StateSpecificFlagsModel,
} from '@assets/metadata/stateSpecificFlags';
import { states } from '@assets/metadata/states';
import { UmbrellaOptionsModel } from '@entities/metadata/models/umbrella-options.model';
import { umbrellaOptions } from '@assets/metadata/umbrella/umbrella-options';

@Injectable({
  providedIn: 'root',
})
export class JsonMetadataAdapter {
  constructor() {}

  getPropertyOptions(): Observable<PropertyOptionsModel> {
    return of(propertyOptions);
  }

  getStateSpecificFlags(): Observable<StateSpecificFlagsModel> {
    return of(stateSpecificFlags);
  }

  getStates(): Observable<string[]> {
    return of(states);
  }

  getUmbrellaOptions(): Observable<UmbrellaOptionsModel> {
    return of(umbrellaOptions);
  }
}
