<div class="well-outer">
  <div class="bolt-container-fluid">
    <div class="bolt-row bolt-justify-content-between bolt-space-bottom-sm">
      <div class="bolt-col-auto">
        <p class="description">{{ content?.description }}</p>
      </div>
      <ng-container *ngIf="content?.displayButton">
        <div class="bolt-col-auto">
          <button
            class="action-button"
            (click)="handleClick()"
            [attr.id]="'addItemBtn-' + index"
          >
            {{ content?.addBtnText || 'Add item' }}
            <bolt-icon name="plus" size="sm" color="vibrant-blue"></bolt-icon>
          </button>
        </div>
      </ng-container>
    </div>
    <div class="item-list">
      <ng-container *ngFor="let item of content?.itemList; let idx = index">
        <nwx-pill-item
          [identifier]="idx + '-' + identifier"
          [content]="item"
          (clickEvent)="handleClick($event)"
        ></nwx-pill-item>
      </ng-container>
    </div>
    <ng-container
      *ngIf="content?.zeroItemsMessage && !content?.itemList?.length"
    >
      <nwx-message-bubble iconName="info-circle-filled" iconColor="theme-info">
        <p>{{ content?.zeroItemsMessage }}</p>
      </nwx-message-bubble>
    </ng-container>
  </div>
</div>
