import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { DocumentsActions, SessionActions } from '@core/store/actions';

import { DocumentsEntity } from '@core/store/entities/document/document.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';

export interface DocumentsState extends EntityState<DocumentsEntity> {
  error: ErrorModel;
  loaded: boolean;
}

export const adapter: EntityAdapter<DocumentsEntity> =
  createEntityAdapter<DocumentsEntity>({
    selectId: (Documents) => Documents.productType,
  });

const DocumentsReducer = createReducer(
  adapter.getInitialState({
    error: {},
    loaded: false,
  }),
  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({
      error: {},
      loaded: false,
    })
  ),
  on(DocumentsActions.addDocumentSuccess, (state, { payload }) =>
    adapter.addOne(payload, { ...state, loaded: true })
  ),
  on(DocumentsActions.addDocumentError, (state, { error }) => {
    return {
      ...state,
      error,
      loaded: true,
    };
  }),
  on(DocumentsActions.updateDocumentSuccess, (state, { payload }) =>
    adapter.updateOne(payload, { ...state })
  ),
  on(DocumentsActions.updateDocumentError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(DocumentsActions.deleteDocumentSuccess, (state, { payload }) =>
    adapter.removeOne(payload, { ...state })
  ),
  on(DocumentsActions.deleteDocumentError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  })
);

export function reducer(
  state: DocumentsState | undefined,
  action: Action
): DocumentsState {
  return DocumentsReducer(state, action);
}
