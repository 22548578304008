import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NationwideAccountRegistrationInfo } from '@core/models/api/dsm-types';
import { MemberService } from '@core/services/member.service';
import { ProductsService } from '@core/services/products.service';
import { CompositeActions } from '@core/store/actions';
import { AccountRegistrationFormService } from '@forms-store/services/account-registration-form.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, Subject, take } from 'rxjs';

@Component({
  selector: 'nwx-account-registration-container',
  templateUrl: './account-registration-container.component.html',
  styleUrls: ['./account-registration-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountRegistrationContainerComponent
  implements OnInit, OnDestroy
{
  @Input() isModal: boolean = false;
  @Input() submitFromAcctInfo?: Observable<void>;
  @Input() canEdit: boolean = true;

  @Output() toggleDisclaimer = new EventEmitter<void>();
  @Output() formReady = new EventEmitter<FormGroup>();

  nationwideAccountRegistrationInfo$: Observable<
    Nullable<NationwideAccountRegistrationInfo>
  >;
  pniPhone$: Observable<Nullable<string>>;
  pniEmail$: Observable<Nullable<string>>;
  hasUpdatedRegistrationForm$: Observable<Nullable<boolean>>;
  form!: FormGroup;
  unsubscribe$ = new Subject<void>();
  showSpinner = false;

  constructor(
    private productsService: ProductsService,
    private memberService: MemberService,
    private accountRegistrationService: AccountRegistrationFormService,
    private ngbModal: NgbActiveModal,
    private store: Store,
    private actions$: Actions,
    private cd: ChangeDetectorRef
  ) {
    this.nationwideAccountRegistrationInfo$ =
      this.productsService.getNationwideAccountRegistrationInfo();
    this.pniPhone$ = this.memberService.getPniHomeNumber();
    this.pniEmail$ = this.memberService.getPniEmail();
    this.hasUpdatedRegistrationForm$ =
      this.accountRegistrationService.hasUpdatedRegistrationForm();
    this.form = new FormGroup({});
  }

  ngOnInit(): void {
    this.formReady.emit(this.form);
    this.submitFromAcctInfo
      ?.pipe(take(1))
      .subscribe(() => this.onSubmitModal());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmitModal(): void {
    this.showSpinner = true;
    this.cd.detectChanges();
    this.actions$
      .pipe(
        ofType(
          CompositeActions.submitAccountRegistrationModalSuccess,
          CompositeActions.submitAccountRegistrationModalError
        ),
        take(1)
      )
      .subscribe(() => {
        this.showSpinner = false;
        this.ngbModal.close();
      });
    this.store.dispatch(
      CompositeActions.submitAccountRegistrationModal({
        registrationInfo: this.form.get('nationwideAccountRegistrationInfo')
          ?.value,
      })
    );
    this.accountRegistrationService.updateAccountRegistrationInfoForm({
      hasUpdatedRegistration: true,
    });
  }

  onToggleDisclaimer(): void {
    this.toggleDisclaimer.emit();
  }

  onFormReady(name: string, form: FormGroup): void {
    this.form.setControl(name, form);
  }
}
