import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  RetrieveActions,
  SessionActions,
  WatercraftExcludedExposureActions,
} from '@core/store/actions';
import { WatercraftExcludedExposureEntity } from './watercraft-excluded-exposures.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { Nullable } from '@shared/utils/type.utils';
import { Dictionary } from '@ngrx/entity';

export interface WatercraftExcludedExposureState
  extends EntityState<WatercraftExcludedExposureEntity> {
  error?: Nullable<ErrorModel>;
  deleted?: Dictionary<WatercraftExcludedExposureEntity>;
}

export const adapter: EntityAdapter<WatercraftExcludedExposureEntity> =
  createEntityAdapter<WatercraftExcludedExposureEntity>({
    selectId: (watercraftExcludedExposure: WatercraftExcludedExposureEntity) =>
      watercraftExcludedExposure.watercraftExcludedExposureId,
  });

const WatercraftExcludedExposureReducer = createReducer(
  adapter.getInitialState({
    error: null,
    deleted: {},
  }) as WatercraftExcludedExposureState,

  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({
      error: null,
      deleted: {},
    } as WatercraftExcludedExposureState)
  ),
  on(
    WatercraftExcludedExposureActions.upsertWatercraftExcludedExposureSuccess,
    (state, { payload }) =>
      adapter.upsertOne(payload, {
        ...state,
      })
  ),
  on(
    WatercraftExcludedExposureActions.deleteWatercraftExcludedExposure,
    (state, { payload }) =>
      adapter.upsertOne(payload, {
        ...state,
        deleted: { [payload.watercraftExcludedExposureId]: payload },
      })
  ),
  on(
    WatercraftExcludedExposureActions.deleteWatercraftExcludedExposureSuccess,
    (state, { watercraftExcludedExposureId }) =>
      adapter.removeOne(watercraftExcludedExposureId, {
        ...state,
      })
  ),
  on(
    WatercraftExcludedExposureActions.completelyRemoveWatercraftExcludedExposure,
    (state, { watercraftExcludedExposureId }) => {
      const deleted = { ...state.deleted };
      delete deleted?.[watercraftExcludedExposureId];
      return { ...state, deleted };
    }
  ),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    if (payload.productType !== 'PersonalUmbrella') {
      return state;
    }
    return adapter.upsertMany(payload.response.watercraftExcludedExposures, {
      ...state,
    });
  })
);

export function reducer(
  state: WatercraftExcludedExposureState | undefined,
  action: Action
): WatercraftExcludedExposureState {
  return WatercraftExcludedExposureReducer(state, action);
}
