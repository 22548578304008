import { ObjectValidator } from '@core/helper/object-validator';

export interface UWReportsResponse {
  reportSummaryId?: string;
  status?: string;
  needed?: string;
  orderedDate?: string;
  receivedDate?: string;
  requiredForBind?: boolean;
  requiredForQuote?: boolean;
  currentCarrierReportDetails?: CurrentCarrierReportDetails;
  // Auto
  alhReportDetails?: AlhReportDetails;
  mvrReportDetails?: MvrReportDetails;
  // Property
  plhReportDetails?: PlhReportDetails;
  msbReportDetails?: MsbReportDetails;
  // Credit
  cbrReportDetails?: CbrReportDetails;
}

export interface CurrentCarrierReportDetails {
  carrier: string;
  liabilityLimits: string;
  expirationDate: string;
  noHit: string;
  insuredName: string;
  status: string;
}

export interface AlhReportDetails {
  driverName: string;
  status: string;
  claims: Claims[];
}

export interface MvrReportDetails {
  driverName: string;
  status: string;
  incidents: Incidents[];
}

export interface CbrReportDetails {
  insuredName: string;
  status: string;
}

export interface PlhReportDetails {
  losses: Losses[];
}

export interface MsbReportDetails {
  estimateNumber: string;
  reportHref: string;
}

export interface Claims {
  claimCoverages: ClaimCoverages[];
  vin: string;
  claimDate: string;
}

export interface ClaimCoverages {
  status: string;
  amountPaid: number;
  claimType: string;
}

export interface Incidents {
  type: string;
  description: string;
  violationSuspensionDate: string;
  convictionReinstatementDate: string;
}

export interface Losses {
  cause: string;
  amount: string;
  date: string;
  catastrophe: boolean;
  weather: boolean;
  subrogatted: boolean;
  status: string;
  overrideReason: string;
  claimSource: string;
}

export function sanitizeUWReportsResponses(
  input: unknown
): UWReportsResponse[] {
  return ObjectValidator.sanitizeArray(input, sanitizeUWReportsResponse);
}

export function sanitizeUWReportsResponse(input: unknown): UWReportsResponse {
  return ObjectValidator.forceSchema<UWReportsResponse>(
    input,
    {
      reportSummaryId: 'string',
      status: 'string',
      needed: 'string',
      orderedDate: 'string',
      receivedDate: 'string',
      requiredForBind: 'boolean',
      requiredForQuote: 'boolean',
      currentCarrierReportDetails: sanitizeCurrentCarrierReportDetails,
      alhReportDetails: sanitizeAlhReportDetails,
      mvrReportDetails: sanitizeMvrReportDetails,
      plhReportDetails: sanitizePlhReportDetails,
      msbReportDetails: sanitizeMsbReportDetails,
      cbrReportDetails: sanitizeCbrReportDetails,
    },
    [
      'currentCarrierReportDetails',
      'alhReportDetails',
      'mvrReportDetails',
      'plhReportDetails',
      'msbReportDetails',
      'cbrReportDetails',
    ]
  );
}

export function sanitizeCurrentCarrierReportDetails(
  input: unknown
): CurrentCarrierReportDetails {
  return ObjectValidator.forceSchema<CurrentCarrierReportDetails>(input, {
    carrier: 'string',
    liabilityLimits: 'string',
    expirationDate: 'string',
    noHit: 'string',
    insuredName: 'string',
    status: 'string',
  });
}

export function sanitizeAlhReportDetails(input: unknown): AlhReportDetails {
  return ObjectValidator.forceSchema<AlhReportDetails>(input, {
    driverName: 'string',
    status: 'string',
    claims: [sanitizeClaims],
  });
}

export function sanitizeClaims(input: unknown): Claims {
  return ObjectValidator.forceSchema<Claims>(input, {
    claimCoverages: [sanitizeClaimCoverages],
    vin: 'string',
    claimDate: 'string',
  });
}

export function sanitizeClaimCoverages(input: unknown): ClaimCoverages {
  return ObjectValidator.forceSchema<ClaimCoverages>(input, {
    status: 'string',
    amountPaid: 'number',
    claimType: 'string',
  });
}

export function sanitizeMvrReportDetails(input: unknown): MvrReportDetails {
  return ObjectValidator.forceSchema<MvrReportDetails>(input, {
    driverName: 'string',
    status: 'string',
    incidents: [sanitizeIncidents],
  });
}

export function sanitizeIncidents(input: unknown): Incidents {
  return ObjectValidator.forceSchema<Incidents>(input, {
    type: 'string',
    description: 'string',
    violationSuspensionDate: 'string',
    convictionReinstatementDate: 'string',
  });
}

export function sanitizePlhReportDetails(input: unknown): PlhReportDetails {
  return ObjectValidator.forceSchema<PlhReportDetails>(input, {
    losses: [sanitizeLosses],
  });
}

export function sanitizeLosses(input: unknown): Losses {
  return ObjectValidator.forceSchema<Losses>(input, {
    cause: 'string',
    amount: 'string',
    date: 'string',
    catastrophe: 'boolean',
    weather: 'boolean',
    subrogatted: 'boolean',
    status: 'string',
    overrideReason: 'string',
    claimSource: 'string',
  });
}

export function sanitizeMsbReportDetails(input: unknown): MsbReportDetails {
  return ObjectValidator.forceSchema<MsbReportDetails>(input, {
    estimateNumber: 'string',
    reportHref: 'string',
  });
}

export function sanitizeCbrReportDetails(input: unknown): CbrReportDetails {
  return ObjectValidator.forceSchema<CbrReportDetails>(input, {
    insuredName: 'string',
    status: 'string',
  });
}
