import { ProductType } from '@core/models/api/dsm-types';

export interface EligibilityFormModel {
  productType: ProductType;
  quoteId: string;
  acknowledgement: boolean;
  prequalificationAnswers?: Partial<EligibilityPrequalificationAnswers>;
  riskItems?: Partial<EligibilityRiskItems>; // aka coveredLocation
  inlineMessages?: EligibilityInlineMessage[];
}

export interface EligibilityInlineMessage {
  questionCode:
    | keyof EligibilityPrequalificationAnswers
    | keyof EligibilityRiskItems;
  message: string;
  action: EligibilityInlineAction;
}

export type EligibilityInlineAction = '' | 'pc' | 'abort';

export interface EligibilityPrequalificationAnswers {
  HOOwnerOccupied_Ext: string;
  HOCondoOwnerOneProd_Ext: string;
  HOInsuranceFraud_Ext: string;
  HOInsuranceFraud2_Ext: string;
  HOArsonConviction_Ext: string;
  HOArson_ExtCopy: string;
  HOFireAccess_Ext: string;
  HOFireLoss_Ext: string;
  HOFireLoss3yrs_Ext: string;
  HOFireLoss3yrs_ExtCopy: string;
  HOFireLossExCat_Ext: string;
  HO3_Foreclosure_Ext: string;
  HO3_Foreclosure2_Ext: string;
  HO6_Foreclosure1_Ext: string;
  HO6_Foreclosure2_Ext: string;
  HO3_Foreclosure_Damage_Ext: string;
  HO6_ForeClosure_Damage_Ext: string;
  HOVacaRental_Ext: string;
  HOCurrentlyLiving_Ext: string;
  PUPNotCoveredByPrimary_PUE: string;
  PUPBusinessActivities_PUE: string;
  PUPOccupationalLoss_Ext: string;
  household_insurance_offense: string;
  household_personal_liability: string;
}

export interface EligibilityRiskItems {
  registeredHistoric: string;
  nonResidentialToResidential: string;
  deededOwner: string;
  isShortSaleAuctForeclose: string;
  trampoline: string;
  distanceToNonOwnedBuildingTrampoline: string;
  trampolineFence: string;
  trampolineTieDowns: string;
  trampolineNet: string;
  dangerousOrExoticAnimal: string;
  animalThatCausedInjury: string;
  haveDogs: string;
  dogs: EligibilityDog[];
  incidentalFarm: string;
  currentConstruction: string;
  currentRemodelingOrRehabiliation: string; // sic Rehabiliation
  swimmingPool: string;
  typeOfSwimmingPool: string;
  distanceToNonOwnedBuildingPool: string;
  swimmingLessonsProvided: string;
  poolLadder: string;
  removableLadder: string;
  divingBoard: string;
  waterDepthUnderDivingBoard: string;
  poolSlide: string;
  waterDepthUnderSlide: string;
  poolFence: string;
  selfLatchingGate: string;
}

export interface EligibilityDog {
  dogBreed: string;
  biteHistory: string;
  canineGoodCitizen: string;
}

/* Order of this list determines order of display.
 */
export const ALL_PREQUALIFICATION_KEYS: (keyof EligibilityPrequalificationAnswers)[] =
  [
    'HOOwnerOccupied_Ext',
    'HOCondoOwnerOneProd_Ext',
    'HOFireAccess_Ext',
    'HOInsuranceFraud_Ext',
    'HOInsuranceFraud2_Ext',
    'HOArsonConviction_Ext',
    'HOArson_ExtCopy',
    'HOFireLoss_Ext',
    'HOFireLoss3yrs_Ext',
    'HOFireLoss3yrs_ExtCopy',
    'HOFireLossExCat_Ext',
    'HO3_Foreclosure_Ext',
    'HO3_Foreclosure2_Ext',
    'HO6_Foreclosure1_Ext',
    'HO6_Foreclosure2_Ext',
    'HO3_Foreclosure_Damage_Ext',
    'HO6_ForeClosure_Damage_Ext',
    'HOVacaRental_Ext',
    'HOCurrentlyLiving_Ext',
  ];

export const ALL_RISK_ITEM_KEYS: (keyof EligibilityRiskItems)[] = [
  'registeredHistoric',
  'nonResidentialToResidential',
  'deededOwner',
  'isShortSaleAuctForeclose',
  'trampoline',
  'distanceToNonOwnedBuildingTrampoline',
  'trampolineFence',
  'trampolineTieDowns',
  'trampolineNet',
  'dangerousOrExoticAnimal',
  'animalThatCausedInjury',
  'haveDogs',
  'dogs',
  'incidentalFarm',
  'currentConstruction',
  'currentRemodelingOrRehabiliation',
  'swimmingPool',
  'typeOfSwimmingPool',
  'distanceToNonOwnedBuildingPool',
  'swimmingLessonsProvided',
  'poolLadder',
  'removableLadder',
  'divingBoard',
  'waterDepthUnderDivingBoard',
  'poolSlide',
  'waterDepthUnderSlide',
  'poolFence',
  'selfLatchingGate',
];

export const RISK_ITEM_KEYS_IN_MAIN_COVERED_LOCATION_OBJECT: (keyof EligibilityRiskItems)[] =
  [
    'registeredHistoric',
    'nonResidentialToResidential',
    'deededOwner',
    'isShortSaleAuctForeclose',
    'currentConstruction',
    'currentRemodelingOrRehabiliation',
  ];

export const ALL_ELIGIBILITY_FORM_KEYS: (
  | keyof EligibilityPrequalificationAnswers
  | keyof EligibilityRiskItems
)[] = [...ALL_PREQUALIFICATION_KEYS, ...ALL_RISK_ITEM_KEYS];
