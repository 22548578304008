import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AccountService } from '@core/services/account.service';
import { AddressService } from '@core/services/address.service';
import { AgencyService } from '@core/services/agency.service';
import { MemberService } from '@core/services/member.service';
import { ProductsService } from '@core/services/products.service';
import { AgencyModel } from '@entities/agency/agency.model';
import { PolicyHolderEntity } from '@entities/policyholder/policyholder.entity';
import {
  ProductModel,
  ProductWithState,
} from '@entities/product/product.model';
import { AccountRegistrationFormService } from '@forms-store/services/account-registration-form.service';
import { Nullable } from '@shared/utils/type.utils';
import { Observable } from 'rxjs';

@Component({
  selector: 'nwx-account-info-container',
  templateUrl: './account-info-container.component.html',
  styleUrls: ['./account-info-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountInfoContainerComponent implements OnInit {
  pni$!: Observable<Nullable<PolicyHolderEntity>>;
  products$!: Observable<ProductModel[]>;
  agency$!: Observable<AgencyModel>;
  accountId$!: Observable<Nullable<string>>;
  productsWithState$!: Observable<ProductWithState[]>;

  constructor(
    private memberService: MemberService,
    private productsService: ProductsService,
    private agencyService: AgencyService,
    private accountService: AccountService,
    private addressService: AddressService
  ) {}

  ngOnInit(): void {
    this.pni$ = this.memberService.getPrimaryNamedInsured();
    this.products$ = this.productsService.getSelectedProducts();
    this.agency$ = this.agencyService.getAgency();
    this.accountId$ = this.accountService.getAccountId();
    this.productsWithState$ =
      this.addressService.getSelectedProductsWithAddressState();
  }
}
