import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import {
  ExtendedTelematicsVehicle,
  MobileEnrollment,
  VehicleEnrollment,
} from '@core/store/entities/telematics/telematics.model';
import { Nullable } from '@shared/utils/type.utils';

@Component({
  selector: 'nwx-no-program-enrollment',
  templateUrl: './no-program-enrollment.component.html',
  styleUrls: ['./no-program-enrollment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoProgramEnrollmentComponent {
  @Input() vehicles: ExtendedTelematicsVehicle[] = [];
  @Input() enrollment: Nullable<VehicleEnrollment | MobileEnrollment>;

  constructor() {}
}
