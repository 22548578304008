import { ObjectValidator } from '@core/helper/object-validator';
import {
  BaseEntity,
  BaseEntityOptionalFields,
  BaseEntitySanitizerFields,
} from '@core/models/entities/base.entity';
import {
  AddressEntity,
  sanitizeAddressEntity,
} from '@entities/address/address.entity';
import { ProductModel } from '@entities/product/product.model';
import { UnderlyingPolicyEntity } from '@entities/underlying-policy/underlying-policy.entity';
import { Nullable } from '@shared/utils/type.utils';

export interface LocationExcludedExposureAndProductInfo {
  locationExcludedExposure: LocationExcludedExposureEntity;
  underlyingPolicy?: UnderlyingPolicyEntity;
  product: ProductModel;
  options?: {
    carrierLabel?: string;
    policyTypeLabel?: string;
    locationUsageLabel?: string;
  };
}

export interface LocationExcludedExposureEntity extends BaseEntity {
  productId?: string;
  locationExcludedExposureId: string;
  underlyingPolicyNumber?: Nullable<string>;
  carrier: string;
  propertyType: string;
  locationAddress?: AddressEntity;
  locationUsage: string;
}

export function sanitizeLocationExcludedExposureEntity(
  input: unknown
): LocationExcludedExposureEntity {
  return ObjectValidator.forceSchema<LocationExcludedExposureEntity>(
    input,
    {
      productId: ' string',
      locationExcludedExposureId: 'string',
      underlyingPolicyNumber: 'string',
      carrier: 'string',
      propertyType: 'string',
      locationAddress: sanitizeAddressEntity,
      locationUsage: 'string',
      ...BaseEntitySanitizerFields,
    },
    [
      'productId',
      'underlyingPolicyNumber',
      'locationAddress',
      ...BaseEntityOptionalFields,
    ]
  );
}
