import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { MemberModel } from '@core/models/views/person.model';
import { AddressService } from '@core/services/address.service';
import { MemberService } from '@core/services/member.service';
import { MortgageService } from '@core/services/mortgage.service';
import { MortgageModel } from '@shared/components/forms/mortgage-form/mortgage-form.model';
import { DWELLING_ADDITIONAL_INTEREST_TYPES } from '@shared/constants/app-constants';
import { Nullable } from '@shared/utils/type.utils';
import { Observable } from 'rxjs';
import { BasicCompanyInformation } from '../additional-interest-holder-selection/additional-interest-holder-selection.component';

@Component({
  selector: 'nwx-additional-interests-form-container',
  templateUrl: './additional-interests-form-container.component.html',
  styleUrls: ['./additional-interests-form-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalInterestsFormContainerComponent implements OnInit {
  @Input() mode: 'add' | 'edit' = 'add';
  @Input() interest?: Nullable<MortgageModel>; // if editing a current interest, this will be populated
  @Input() productType: ProductType = 'Homeowner';

  members$!: Observable<MemberModel[]>;
  interestHolderCompanies$!: Observable<BasicCompanyInformation[]>;

  displayAddress$!: Observable<string>;

  constructor(
    private memberService: MemberService,
    private mortgageService: MortgageService,
    private addressService: AddressService
  ) {}

  ngOnInit(): void {
    this.members$ = this.memberService.getAllSelectedPeople();
    this.displayAddress$ = this.addressService.getSingleLineAddress(
      this.productType
    );
    this.interestHolderCompanies$ =
      this.mortgageService.getUniqueAddlInterestHolderCompanies(
        this.productType,
        DWELLING_ADDITIONAL_INTEREST_TYPES
      );
  }

  onSave(event: MortgageModel): void {
    this.mortgageService.dispatchAddMortgage({
      ...event,
      mortgageId: this.interest?.mortgageId,
    });
  }

  onDelete(event: MortgageModel): void {
    this.mortgageService.dispatchDeleteMortgage(event as MortgageModel);
  }
}
