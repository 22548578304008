import { createSelector } from '@ngrx/store';
import * as fromProducts from '@core/store/entities/product/product.selectors';
import { PolicyNumberRequest } from '@core/models/api/request/policy-number-request.model';

export const getProductsWithoutPolicyNumber = createSelector(
  fromProducts.getSelectedProductsWithoutErrors,
  (products) => products.filter((product) => !product.policyNumberLoaded)
);

export const isPolicyNumberLoaded = createSelector(
  fromProducts.getSelectedProductsWithoutErrors,
  (products) => {
    return products.every((product) => product.policyNumberLoaded);
  }
);

export const isPolicyNumberLoading = createSelector(
  fromProducts.getSelectedProductsWithoutErrors,
  (products) => {
    return products.some((product) => product.policyNumberLoading);
  }
);

export const buildPolicyNumberRequests = createSelector(
  getProductsWithoutPolicyNumber,
  (products) => {
    return products.map((product) => {
      const request: PolicyNumberRequest = {
        quoteId: product.quoteId || '',
        productType: product.type,
      };

      return request;
    });
  }
);
