<div
  class="bolt-row bolt-no-gutters bolt-align-items-center bottom-message"
  [class.borderless]="borderless"
>
  <div class="bolt-col-auto">
    <bolt-icon
      [attr.name]="iconName"
      [attr.color]="iconColor"
      [attr.size]="iconSize"
    ></bolt-icon>
  </div>
  <div class="bolt-col">
    <ng-content> </ng-content>
  </div>
</div>
