<ng-container *ngIf='vm$ | async as vm'>
  <!--  Header  -->
  <div class="modal-header">
    <h4 id="addlInterestsModalHeader">{{ vm.title }}</h4>
    <span class="modal-header-label">{{ vehicleName }}</span>
  </div>

  <!--  Body  -->
  <div class='modal-body'>
    <form [formGroup]='form'
          [nwxExposeFormErrors]='form'
          formName='additionalInterest'>

      <ng-container *ngIf='vm.displaySelection; else formDisplay'>
        <!--  Add Additional Interest  -->
        <div class='padding'>
          <nwx-additional-interest-holder-selection [members]='members$ | async'
                                                    [interestHolderCompanies]='companies$ | async'
                                                    (selectInterestHolder)='onSelectPerson($event)'
                                                    (selectInterestCompany)='onSelectCompany($event)'
          ></nwx-additional-interest-holder-selection>
        </div>
      </ng-container>

      <ng-template #formDisplay>
        <!--  Edit Additional Interest  -->
        <!--  Additional Interest Types  -->
        <div class='bolt-background-light-blue padding'>
          <div class='bolt-container-fluid'>
            <div class='bolt-row'>
              <div class='bolt-col'>
                <bolt-radio-group label='Interest type'
                                  horizontal
                                  required
                                  [attr.id]="'interest-type-group'"
                                  [attr.disabled]="mode === 'edit' || null"
                                  [value]='vm.interestType'
                                  (change)='updateInterestType($event)'>
                  <bolt-radio value='person'>Person</bolt-radio>
                  <bolt-radio value='company'>Company</bolt-radio>
                </bolt-radio-group>
              </div>
              <div class='bolt-col'>
                <bolt-select label='Third party type'
                             formControlName='additionalInterestType'
                             required>
                  <option *ngFor='let option of additionalInterestOptions'
                          [value]='option.displayName'>
                    {{option.displayName}}
                  </option>
                </bolt-select>
              </div>
            </div>
          </div>
        </div>


        <div class='padding'>
          <!--  Person Input  -->
          <ng-container *ngIf="vm.interestType === 'person'; else companyInput">
            <nwx-additional-interest-person-form [interestHolder]='vm.assocPerson'
                                                 (formReady)="addChildForm('person', $event)"
            ></nwx-additional-interest-person-form>
          </ng-container>

          <!--  Company Input  -->
          <ng-template #companyInput>
            <bolt-textfield label='Company name'
                            formControlName='companyName'
                            required
                            [attr.id]="'companyNameTextField'"
            ></bolt-textfield>
          </ng-template>
          <div class='bolt-row'>
            <div class='bolt-col'>
              <nwx-address-input label='Additional Interest address'
                                 addressType='AdditionalInterest'
                                 controlId='{{productType}}-ai-address'
                                 [noPadding]='true'
                                 [address]='getInterestAddress()'
                                 [outsideQuoteState]='true'
                                 [unitNumberVisible]='true'
                                 [standardizingSubject$]='addressStandardizing$'
                                 (formReady)="addChildForm('address', $event)"
                                 (valueChange)='onAddressChange($event)'
              ></nwx-address-input>
            </div>
          </div>
        </div>
      </ng-template>
    </form>
  </div>

  <!--  Footer  -->
  <div class='modal-footer'>
    <bolt-button-bar>
      <bolt-button [attr.id]="'cancel-button'" slot='back' (click)='closeModal()'>Cancel</bolt-button>

      <!--  Add Additional Interest  -->
      <ng-container *ngIf='vm.displaySelection; else editButtons'>
        <bolt-button slot='forward'
                     type='primary'
                     [attr.id]="'createNewButton'"
                     (click)='createNewAdditionalInterest()'>
          Create new additional interest
        </bolt-button>
      </ng-container>

      <!--  Edit Additional Interest  -->
      <ng-template #editButtons>
        <bolt-button *ngIf="mode === 'edit'"
                     slot='cancel'
                     iconleft='trash'
                     [attr.id]="'deleteExistingButton'"
                     (click)='deleteAdditionalInterest()'>
          Delete
        </bolt-button>
        <bolt-button slot='forward'
                     type='primary'
                     [attr.id]="'saveNewButton'"
                     (click)='saveAdditionalInterest()'>
          Save additional interest
        </bolt-button>
      </ng-template>
    </bolt-button-bar>
  </div>
</ng-container>
