<div class="bolt-container-fluid splash-page">
  <div clss="bolt-row">
    <div class="faux-header logo-container">
      <h3>
        <a [routerLink]="'/'"
          >Nationwide&nbsp;<span class="post-header">Express&reg;</span></a
        >
      </h3>
      <div class="badge-container">
        <img
          src="/assets/images/icons/nwx-agent-version-2-transparent-badge.svg"
          alt="Version 2"
        />
      </div>
    </div>
  </div>
  <div class="bolt-row main-content">
    <div class="bolt-col-12 bolt-col-md-6 bolt-space-bottom-xl">
      <bolt-logo
        color="white"
        type="nw-mark"
        class="bolt-space-bottom-3xl"
      ></bolt-logo>
      <h1 class="bolt-heading-xl">Insurance is evolving.</h1>
      <h2 class="spotlight-subheader">Your tools should too.</h2>

      <p class="description">
        Business that's easy and efficient - that's our commitment to you.
        That's why we've evolved <strong>Nationwide Express&reg;</strong> to
        allow you to sell wherever your customers are while simplifying the
        entire quote, bind, and pay experience.
      </p>
    </div>
    <div class="bolt-col-12 bolt-col-md-6">
      <nwx-splash-container></nwx-splash-container>
    </div>
  </div>
  <div class="cityscape-container">
    <div>
      <img
        class="cityscape"
        src="../../../../assets/images/cityscape.svg"
        alt=""
        height="200px"
      />
    </div>
  </div>
</div>
