import { createSelector } from '@ngrx/store';
import { PolicyHolderType, ProductType } from '@core/models/api/dsm-types';
import * as fromProducts from '@core/store/entities/product/product.selectors';
import { PolicyHolderRequest } from '@core/models/api/request/update-policyholder-request.model';
import { PolicyholderBuilder } from '@shared/utils/builders/policyholder.builder';
import { PolicyHolderEntity } from './policyholder.entity';
import { getAddressForNewPolicyHolder } from '../address/address.selector';

function getPolicyHolderType(policyHolder: PolicyHolderEntity): PolicyHolderType {
  const role = policyHolder.policyRoles.find(r => (
    r.entityType === 'policyHolder' &&
    r.productType === policyHolder.productType
  ));
  if (role) {
    switch (role.roleSequence) {
      case 'primary': return 'PolicyPriNamedInsured';
      case 'secondary': return 'PolicySecNamedInsured';
      case 'additional': return 'PolicyAddlNamedInsured';
    }
  }
  // Shouldn't ever get this far; If we created a policyRole for policyHolder, we should have set roleSequence.
  return 'PolicyAddlNamedInsured';
}

export const buildPolicyholderRequest = (
  policyholderEntity: PolicyHolderEntity,
  correlationId?: string
) =>
  createSelector(
    fromProducts.getProduct(policyholderEntity.productType as ProductType),
    getAddressForNewPolicyHolder,
    (product, address) =>
      ({
        quoteId: product?.quoteId,
        productType: product?.type as ProductType,
        policyHolder: PolicyholderBuilder.buildPolicyholderRequestFromEntity({
          ...policyholderEntity,
          address,
          policyHolderType: getPolicyHolderType(policyholderEntity),
        }, product?.type),
        correlationId,
      } as PolicyHolderRequest)
  );
