import * as Actions from './uw-reports.action';
import { Action, on } from '@ngrx/store';
import { createReducer } from '@ngrx/store';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { UWReportsResponse } from '@core/models/api/response/uw-reports-response.model';
import { SessionActions } from '@core/store/actions';

export interface UWReportsState {
  auto: UWReportsResponse[];
  homeowner: UWReportsResponse[];
  condo: UWReportsResponse[];
  renters: UWReportsResponse[];
  msa: UWReportsResponse[];
  rv: UWReportsResponse[];
  boat: UWReportsResponse[];
  error: ErrorModel;
  loaded: boolean;
  loading: number;
}

export interface UWReportsByProduct {
  auto: UWReportsResponse[];
  homeowner: UWReportsResponse[];
  condo: UWReportsResponse[];
  renters: UWReportsResponse[];
  msa: UWReportsResponse[];
  rv: UWReportsResponse[];
  boat: UWReportsResponse[];
}

const initialState: UWReportsState = {
  auto: [{}],
  homeowner: [{}],
  condo: [{}],
  renters: [{}],
  msa: [{}],
  rv: [{}],
  boat: [{}],
  error: {},
  loaded: false,
  loading: 0,
};

const uwReportsReducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(Actions.getUWReports, (state) => {
    return {
      ...state,
      loaded: false,
      loading: state.loading + 1,
    };
  }),
  on(Actions.getUWReportsSuccess, (state, { payload, product }) => {
    return {
      ...state,
      auto: product === 'PersonalAuto' ? payload : state.auto,
      homeowner: product === 'Homeowner' ? payload : state.homeowner,
      condo: product === 'Condominium' ? payload : state.condo,
      renters: product === 'Tenant' ? payload : state.renters,
      msa: product === 'MSA' ? payload : state.msa,
      rv: product === 'RV' ? payload : state.rv,
      boat: product === 'Boat' ? payload : state.boat,
      loaded: true,
      loading: state.loading - 1,
    };
  }),
  on(Actions.storeUWReports, (state, { payload, product }) => {
    return {
      ...state,
      auto: product === 'PersonalAuto' ? payload : state.auto,
      homeowner: product === 'Homeowner' ? payload : state.homeowner,
      condo: product === 'Condominium' ? payload : state.condo,
      renters: product === 'Tenant' ? payload : state.renters,
      msa: product === 'MSA' ? payload : state.msa,
      rv: product === 'RV' ? payload : state.rv,
      boat: product === 'Boat' ? payload : state.boat,
    };
  }),
  on(Actions.getUWReportsFail, (state, { error }) => {
    return {
      ...state,
      loaded: true,
      loading: state.loading - 1,
      error,
    };
  }),
  on(Actions.resetUWReportsLoaded, (state) => {
    return {
      ...state,
      loaded: false,
      loading: 0,
    };
  }),
  on(Actions.getMSBLaunchUrlSuccess, (state, { payload }) => {
    return {
      ...state,
      homeowner: state.homeowner
        .map((report) => Object.assign({}, report) as UWReportsResponse)
        .map((report) => {
          if (report.reportSummaryId === 'MSB') {
            return {
              ...report,
              msbReportDetails: payload,
            };
          } else {
            return report;
          }
        }),
    };
  })
);

export function reducer(
  state: UWReportsState | undefined,
  action: Action
): UWReportsState {
  return uwReportsReducer(state, action);
}
