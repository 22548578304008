import { ProductType } from '@core/models/api/dsm-types';
import { ProductsSelectors } from '@core/store/selectors';
import { createSelector } from '@ngrx/store';
import { GeneralUtils } from '@shared/utils/general.utils';
import { getCoveredLocationEntity } from '../../covered-location/covered-location.selector';
import { getAllFeatureFlags } from '../../feature-flag/feature-flag.selector';
import { getMortgagesOfMortgageType } from '../../mortgage/mortgage.selector';
import { TaskModel } from '../task.model';
import { getIncompleteTasks, selectAllTasks } from './task.selector';
import { TaskUtils } from '../utils/task.util';
import { PropertyTaskUtils } from '../utils/property-task.utils';
import {
  getPolicyDateOptions,
  getProduct,
  getSelectedActiveProductTypes,
  getSelectedProductTypes,
} from '@entities/product/product.selectors';
import { getBillToEscrow } from '@forms-store/store/models/billing-plans/billing-plans.selector';
import { getMortgageState } from '@entities/has-mortgage/has-mortgage.selector';

export const getCoveredLocationTasks = (productType: ProductType) =>
  createSelector(
    selectAllTasks,
    (tasks) =>
      tasks.filter(
        (t) =>
          t?.entityType === 'coveredLocation' && t?.productType === productType
      ) as TaskModel[]
  );

export const generateCoveredLocationTasks = (productType: ProductType) =>
  createSelector(
    getSelectedProductTypes,
    ProductsSelectors.getProduct(productType),
    getCoveredLocationEntity(productType),
    getMortgageState,
    getMortgagesOfMortgageType(productType, 'Mortgagee_Ext'),
    getAllFeatureFlags,
    getPolicyDateOptions('Homeowner'),
    getSelectedActiveProductTypes,
    getBillToEscrow,
    (
      selectedProductTypes,
      product,
      coveredLocation,
      hasMortgage,
      mortgages,
      featureFlags,
      policyDateOptions,
      selectedProducts,
      billToEscrow
    ) =>
      selectedProductTypes.indexOf(productType) >= 0
        ? !GeneralUtils.isEmpty(product)
          ? PropertyTaskUtils.generatePropertyTasks(
              coveredLocation,
              featureFlags,
              selectedProducts,
              policyDateOptions,
              product,
              mortgages,
              billToEscrow,
              hasMortgage
            )
          : []
        : []
  );

export const updateCoveredLocationTasks = (productType: ProductType) =>
  createSelector(
    getCoveredLocationTasks(productType),
    generateCoveredLocationTasks(productType),
    (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
  );

export const getPropertyTasks = createSelector(
  getIncompleteTasks,
  (tasks) =>
    tasks.filter((t) => t?.entityType === 'coveredLocation') as TaskModel[]
);

// Needs to know which product is being tasked
export const generatePropertyTasks = (productType: ProductType) =>
  createSelector(
    getProduct(productType),
    getCoveredLocationEntity(productType),
    getMortgageState,
    getMortgagesOfMortgageType(productType, 'Mortgagee_Ext'),
    getAllFeatureFlags,
    getPolicyDateOptions(productType),
    getSelectedActiveProductTypes,
    getBillToEscrow,
    (
      product,
      coveredLocation,
      hasMortgage,
      mortgages,
      features,
      policyDateOptions,
      selectedProducts,
      billToEscrow
    ) =>
      !GeneralUtils.isEmpty(product)
        ? PropertyTaskUtils.generatePropertyTasks(
            coveredLocation,
            features,
            selectedProducts,
            policyDateOptions,
            product,
            mortgages,
            billToEscrow,
            hasMortgage
          )
        : []
  );

export const updatePropertyTasks = (productType: ProductType) =>
  createSelector(
    getPropertyTasks,
    generatePropertyTasks(productType),
    (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
  );
