import { PcUrlService } from '@shared/services/pc-url.service';
import { Injectable } from '@angular/core';
import { AgencyAdapter } from '../adapters/agency.adapter';
import { Observable, of } from 'rxjs';
import { ProducerSearch } from '../adapters/producer-search.model';
import { Store } from '@ngrx/store';
import * as fromSelectors from '@core/store/selectors/index';
import { AgencyModel } from '../store/entities/agency/agency.model';
import { updateAgency } from '../store/entities/agency/agency.action';
import { DistributionPartnerRoleResponse } from '../models/api/response/distribution-partner-role-response.model';
import { DistributionPartnerSearchConstants } from '@shared/constants/app-constants';
import { ProducerUtils } from '@shared/utils/producer.utils';
import {
  combineLatestWith,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import {
  ACORDProductCode,
  FilteredProducer,
  AgencyProducerFilter,
  FilteredAgency,
} from '@core/models/api/response/producer-filter-response.model';
import { ProductType } from '@core/models/api/dsm-types';

@Injectable({
  providedIn: 'root',
})
export class AgencyService {
  constructor(
    private agencyAdapter: AgencyAdapter,
    private store: Store,
    private pcUrlService: PcUrlService
  ) {}

  getProducerCodes(state?: string): Observable<ProducerSearch> {
    return this.agencyAdapter.producerCodeSearch(state);
  }

  getDistributionPartnerRoles(
    producerCode: string
  ): Observable<DistributionPartnerRoleResponse> {
    const request = {
      legacyAdministrativeSystem: ProducerUtils.isPDSProducer(producerCode)
        ? DistributionPartnerSearchConstants.PDS
        : DistributionPartnerSearchConstants.AMF_ADMIN_SYSTEM,
      legacyIdentifierType:
        DistributionPartnerSearchConstants.LEGACY_INDIVIDUAL_ROLE_NUMBER,
      legacyIdentifier: ProducerUtils.buildLegacyIdentifier(producerCode),
    };
    return this.agencyAdapter.getDistributionPartnerRoles(request);
  }

  getAgencyResidentState(agency: AgencyModel): Observable<string> {
    if (agency.agent?.agentState) {
      return of(agency.agent.agentState);
    } else if (
      agency.producerCode &&
      ProducerUtils.isPDSProducer(agency.producerCode)
    ) {
      return of(ProducerUtils.getPDSStateAbbreviation(agency.producerCode));
    } else {
      return of('notFound');
    }
  }

  private findResidentState(
    dpimResponse: DistributionPartnerRoleResponse,
    agency: AgencyModel
  ): string {
    const residentStateLicenseSummaries =
      dpimResponse?.licenseSummaries?.filter(
        (summary) => summary.residentStateIndicator === 'Y'
      );
    if (residentStateLicenseSummaries?.length === 1) {
      return residentStateLicenseSummaries[0]?.licenseState;
    } else if (residentStateLicenseSummaries?.length > 1) {
      const isSingleState = residentStateLicenseSummaries.every(
        (summary) =>
          summary.licenseState ===
          residentStateLicenseSummaries[0]?.licenseState
      );
      if (isSingleState) {
        return residentStateLicenseSummaries[0]?.licenseState;
      }
      const previousState = agency.agent.agentState;
      if (
        previousState &&
        residentStateLicenseSummaries.find(
          (s) => s.licenseState === previousState
        )
      ) {
        return previousState;
      }
      throw new Error('Multiple agent resident states');
    } else {
      throw new Error('Agent resident state not found');
    }
  }

  getAvailableLicensedStates(
    producerCode: string,
    existingStates: string[]
  ): Observable<string[]> {
    return this.agencyAdapter
      .getProducerLicenseDetails(
        ProducerUtils.buildLegacyIdentifier(producerCode)
      )
      .pipe(
        withLatestFrom(this.getAgency()),
        map(([response, agency]) => {
          // store npn from response
          const updatedAgency = {
            npn: response.data.npn,
            agent: agency.agent,
            producerCode: agency.producerCode,
          };
          this.updateAgency(updatedAgency);
          const licensedStates = response.data.licenses
            // Filter existing states (cant add same state more than once)
            ?.filter(
              (license: any) => !existingStates.includes(license.licenseState)
            )
            .map((license: any) => license.licenseState)
            .reduce((lst: string[], state: string) => {
              if (lst.includes(state)) {
                return lst;
              } else {
                return [...lst, state];
              }
            }, [])
            .sort();
          return licensedStates;
        })
      );
  }

  getAgentFilteredProducts(state: string): Observable<ProductType[]> {
    return this.getAgency().pipe(
      switchMap((agency) => {
        const agencyCode = agency.producerCode?.substring(0, 8);
        if (agency.npn && agencyCode) {
          return this.agencyAdapter
            .getProducerFilters(agency.npn)
            .pipe(
              combineLatestWith(
                this.agencyAdapter.getAgencyFilters(agencyCode, state)
              )
            );
        } else {
          return of([]);
        }
      }),
      map(([producerFilterResponse, agencyFilterResponse]) => {
        const producerFilters: FilteredProducer[] | undefined =
          producerFilterResponse?.filteredProducers;
        const agencyFilters: FilteredAgency[] | undefined =
          agencyFilterResponse?.filteredAgencies;

        if (producerFilters || agencyFilters) {
          const producerRestrictedProducts = producerFilters
            ?.filter(
              (filter: FilteredProducer) =>
                filter.state === state || filter.state === 'ALL'
            )
            .flatMap(
              (filter: FilteredProducer) =>
                ACORDProductCode[filter.product] as ProductType
            );

          const agencyRestrictedProducts = agencyFilters?.flatMap(
            (filter: FilteredAgency) =>
              ACORDProductCode[filter.product] as ProductType
          );

          return [
            ...new Set(producerRestrictedProducts),
            ...new Set(agencyRestrictedProducts),
          ];
        } else {
          return [];
        }
      })
    );
  }

  getAgency(): Observable<AgencyModel> {
    return this.store.select(fromSelectors.AgencySelectors.getAgency);
  }

  getAgencyCode(): Observable<string> {
    return this.getAgency().pipe(
      map((agency) => agency.agent.agencyCode || '')
    );
  }

  getProducerCode(): Observable<string> {
    return this.getAgency().pipe(map((agency) => agency.producerCode || ''));
  }

  getAgentName(): Observable<string> {
    return this.getAgency().pipe(map((agency) => agency.agent.agentName || ''));
  }

  updateAgency(agency: AgencyModel): void {
    this.store.dispatch(updateAgency({ payload: agency }));
  }

  buildPCHydrationUrl(quoteId: string): Observable<string> {
    return this.pcUrlService.getPolicyCenterUrl(quoteId, 'Comprater');
  }
}
