<div class="bolt-container-fluid tm-content-container">
  <div *ngFor="let vehicle of vehicles; let i = index">
    <nwx-telematics-vehicle-premium
      [vehicle]="vehicle"
      [vehicleIndex]="i"
      [currentProgram]="'NotEnrolled'"
      [allowChange]="false"
      [enrollment]="enrollment"
    ></nwx-telematics-vehicle-premium>
  </div>
</div>
