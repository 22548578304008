import { Action, createReducer, on } from '@ngrx/store';
import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update,
} from '@ngrx/entity';
import {
  VehicleActions,
  RetrieveActions,
  EligibleDiscountsActions,
  SessionActions,
} from '@core/store/actions';
import { VehicleEntity } from '@core/store/entities/vehicle/vehicle.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { VehicleUtils } from '@shared/utils/vehicle.utils';
import { EligibleDiscountsEntity } from '../eligible-discounts/eligible-discounts.entity';
import { Nullable } from '@shared/utils/type.utils';

export interface VehicleState extends EntityState<VehicleEntity> {
  error?: Nullable<ErrorModel>;
}

export const adapter: EntityAdapter<VehicleEntity> =
  createEntityAdapter<VehicleEntity>({
    selectId: (vehicle: VehicleEntity) => vehicle.vehicleId,
  });

const vehicleReducer = createReducer(
  adapter.getInitialState({
    error: null,
  }) as VehicleState,

  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({
      error: null,
    } as VehicleState)
  ),
  on(VehicleActions.addVehicle, (state) => ({
    ...state,
  })),
  on(
    VehicleActions.addVehicleSuccess,
    VehicleActions.addNewTempVehicleSuccess,
    (state, { payload }) =>
      adapter.addOne(payload, {
        ...state,
      })
  ),
  on(VehicleActions.addVehicleError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(
    VehicleActions.updateVehicleSuccess,
    VehicleActions.storeVehicle,
    (state, { payload }) =>
      adapter.upsertOne(payload, {
        ...state,
      })
  ),
  on(VehicleActions.refetchAllVehiclesSuccess, (state, { response }) => {
    return adapter.upsertMany(response, state);
  }),
  on(VehicleActions.updateVehicleError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(VehicleActions.upsertAllVehicleSuccess, (state, { payload }) =>
    adapter.upsertMany(payload, { ...state })
  ),
  on(VehicleActions.deleteVehicle, (state) => ({
    ...state,
  })),
  on(VehicleActions.deleteVehicleSuccess, (state, { payload }) =>
    adapter.removeOne(payload, {
      ...state,
    })
  ),
  on(VehicleActions.deleteTempVehicle, (state, { payload }) =>
    adapter.removeOne(payload, {
      ...state,
    })
  ),
  on(VehicleActions.deleteVehicleError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(VehicleActions.updatedVehicleSelected, (state, { payload }) => {
    const vehicleEntity = {
      ...state.entities[payload.entityId],
      selected: payload.selected,
    } as VehicleEntity;

    const updateVehicle = {
      id: vehicleEntity.vehicleId,
      changes: vehicleEntity,
    } as Update<VehicleEntity>;

    return adapter.updateOne(updateVehicle, state);
  }),
  on(VehicleActions.setVehicleSeriesByVin, (state, { vin, series }) => {
    const entity = Object.values(state.entities).find((v) => v?.vin === vin);
    if (entity) {
      return adapter.updateOne(
        {
          id: entity.vehicleId,
          changes: { series },
        },
        state
      );
    } else {
      return state;
    }
  }),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    if (
      payload.productType !== 'PersonalAuto' &&
      payload.productType !== 'MSA' &&
      payload.productType !== 'Boat' &&
      payload.productType !== 'RV'
    ) {
      return {
        ...state,
      };
    }
    let vehicles: VehicleEntity[] = [];
    switch (payload.productType) {
      case 'PersonalAuto':
      case 'MSA':
        vehicles = payload.response.vehicles.map(
          (vehicle) =>
            ({
              ...vehicle,
              productType: payload.productType,
              selected: true,
              additionalInformation: { ...vehicle.additionalInformation },
              auxiliaryLights: vehicle.eligibleDiscounts
                ? getAuxiliaryLights(vehicle.eligibleDiscounts)
                : undefined,
              carPool:
                vehicle.eligibleDiscounts &&
                payload.productType === 'PersonalAuto'
                  ? getCarpool(vehicle.eligibleDiscounts)
                  : undefined,
            } as VehicleEntity)
        );
        return adapter.addMany(vehicles, state);
      case 'Boat':
        vehicles = payload.response.pleasureBoatVehicles.map(
          (vehicle) =>
            ({
              ...vehicle,
              productType: payload.productType,
              selected: true,
              additionalInformation: { ...vehicle.additionalInformation },
            } as VehicleEntity)
        );
        return adapter.addMany(vehicles, state);
      case 'RV':
        vehicles = payload.response.recreationalVehicles.map(
          (vehicle) =>
            ({
              ...vehicle,
              productType: payload.productType,
              selected: true,
              additionalInformation: { ...vehicle.additionalInformation },
            } as VehicleEntity)
        );
        return adapter.addMany(vehicles, state);
    }
  }),
  on(
    EligibleDiscountsActions.updateVehicleDiscountSuccess,
    (state, { payload }) => {
      const vehicle = {
        ...state.entities[payload.modelId || 0],
      } as VehicleEntity;
      const discounts = [...(vehicle.eligibleDiscounts || [])];

      const disc = discounts?.find(
        (discount) => discount.eligibleDiscountId === payload.eligibleDiscountId
      );
      if (disc) {
        const idx = discounts.indexOf(disc);
        if (idx !== -1) {
          discounts.splice(idx, 1);
        }
      }
      if (payload.eligibleDiscountId === 'Carpool') {
        vehicle.carPool = getCarpool([payload]);
      }
      discounts.push(payload);
      vehicle.eligibleDiscounts = discounts;
      const update: Update<VehicleEntity> = {
        id: payload.modelId || '0',
        changes: vehicle || ({} as VehicleEntity),
      };
      return adapter.updateOne(update, state);
    }
  )
);

export function reducer(
  state: VehicleState | undefined,
  action: Action
): VehicleState {
  return vehicleReducer(state, action);
}

function getAuxiliaryLights(
  eligibleDiscounts: Nullable<EligibleDiscountsEntity[]>
) {
  const discount = eligibleDiscounts?.find(
    (discount) => discount.eligibleDiscountId === 'AuxillaryLightingSystem'
  );
  if (discount) {
    return JSON.parse(discount.selectedOptionValue || '');
  }
  return undefined;
}

function getCarpool(eligibleDiscounts: Nullable<EligibleDiscountsEntity[]>) {
  const discount = eligibleDiscounts?.find(
    (discount) => discount.eligibleDiscountId === 'Carpool'
  );
  if (discount) {
    return discount.selectedOptionValue;
  }
  return undefined;
}
