import { ProductType } from '@core/models/api/dsm-types';
import * as fromCore from '@core/store/reducers';
import { createSelector } from '@ngrx/store';
import * as fromReducer from './billing-account.reducer';

export const getBillingAccountState = createSelector(
  fromCore.getCoreState,
  (state) => state.billingAccount
);

export const {
  selectAll: getAllBillingAccounts,
  selectEntities: selectBillingAccountEntities,
} = fromReducer.adapter.getSelectors(getBillingAccountState);

export const getBillingAccountForProductId = (productId: ProductType) =>
  createSelector(getAllBillingAccounts, (accounts) => {
    return accounts.find((account) => account.productIds.includes(productId));
  });

export const getBillingAccountForProductIds = (productIds: ProductType[]) =>
  createSelector(getAllBillingAccounts, (accounts) => {
    return accounts.find(
      (account) =>
        account.productIds.length === productIds.length &&
        account.productIds.every((id) => productIds.includes(id))
    );
  });

export const getBillingAccountsLoading = createSelector(
  getAllBillingAccounts,
  (accounts) => {
    return !!accounts.find((account) => account.status === 'pending');
  }
);
