import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import { getQuoteState } from '../session/session.selector';
import { FeatureFlagId } from '../feature-flag/feature-flag.model';

export const getMetadataState = createSelector(
  fromCore.getCoreState,
  (state: fromCore.CoreState) => state.metadata
);

export const getStateSpecificFlags = createSelector(
  getMetadataState,
  (state) => state.stateSpecificFlags
);

export const getStateSpecificFlagsLoaded = createSelector(
  getStateSpecificFlags,
  (flags) => flags.loaded
);

export const getStateSpecificFlagsLoading = createSelector(
  getStateSpecificFlags,
  (flags) => flags.loading
);

export const getPropertyMetadata = createSelector(
  getMetadataState,
  (state) => state.propertyOptions.data
);

export const getStatesList = createSelector(
  getMetadataState,
  (state) => state.states.states
);

export const getStatesLoaded = createSelector(
  getMetadataState,
  (state) => state.states.loaded
);

export const getPropertyOptions = createSelector(
  getMetadataState,
  (state) => state.propertyOptions.data
);

export const getUmbrellaOptions = createSelector(
  getMetadataState,
  (state) => state.umbrellaOptions.data
);

export const getStateSpecificFlagsData = createSelector(
  getMetadataState,
  (state) => state.stateSpecificFlags.data
);

export const getStateSpecificFlag = (flagName: string) =>
  createSelector(
    getStateSpecificFlagsData,
    getQuoteState,
    (stateSpecificFlags, ratingState) => {
      const flag = flagName as FeatureFlagId;
      if (stateSpecificFlags && !stateSpecificFlags.hasOwnProperty(flag)) {
        return false;
      }
      return stateSpecificFlags[flag]?.states?.includes(ratingState);
    }
  );

export const getVersionInformation = createSelector(
  getMetadataState,
  (state) => state.versionInformation.data || {}
);
