import { ProducerSearch } from './../adapters/producer-search.model';
import { ProductModel } from '@core/store/entities/product/product.model';
import { InitiateNewBusinessRequest } from '@core/models/api/request/initiate-new-business-request.model';
import { QuoteAdapter } from './../adapters/quote.adapter';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { CoreState } from '../store/reducers';
import { ProductType, RatingType } from '@core/models/api/dsm-types';
import { InitiateNewBusinessResponse } from '../models/api/response/initiate-new-business-response.model';
import {
  QuoteRequest,
  UpdateQuoteFormRequest,
  UpdateQuoteRequest,
} from '@core/models/api/request/update-quote-request.model';
import { UpdateQuoteResponse } from '@core/models/api/response/update-quote-response.model';
import {
  ProductActions,
  QuoteActions,
  TelematicsActions,
} from '../store/actions';
import { ProductsService } from './products.service';
import {
  getQuoteInProgressCount,
  getQuoteUpdateInProgress,
} from '../store/entities/quote/quote.selector';
import { Actions, ofType } from '@ngrx/effects';
import { StringUtils } from '@shared/utils/string.utils';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  constructor(
    private store: Store<CoreState>,
    private adapter: QuoteAdapter,
    private productsService: ProductsService,
    private actions$: Actions
  ) {}

  initiateNewBusiness(
    request: InitiateNewBusinessRequest
  ): Observable<InitiateNewBusinessResponse> {
    return this.adapter.initiateNewBusiness(request);
  }

  rateQuote(request: QuoteRequest): Observable<UpdateQuoteResponse> {
    return this.adapter.rateQuote(request);
  }

  updateQuote(request: UpdateQuoteRequest): Observable<UpdateQuoteResponse> {
    return this.adapter.updateQuote(request);
  }

  storeAssignedRiskPlan(isAssignedRiskPlan: boolean): void {
    this.store.dispatch(
      ProductActions.updateProduct({
        payload: {
          id: 'PersonalAuto',
          changes: {
            isAssignedRiskPlan,
          },
        },
      })
    );
  }

  getQuoteUpdateInProgress(): Observable<number> {
    return this.store.select(getQuoteUpdateInProgress);
  }

  getQuoteInProgressCount(): Observable<number> {
    return this.store.select(getQuoteInProgressCount);
  }

  dispatchRateQuote(productId: ProductType, ratingType: RatingType): void {
    this.store.dispatch(
      QuoteActions.rateQuote({ productType: productId, ratingType })
    );
  }

  dispatchUpdateQuote(
    productType: ProductType,
    override?: Partial<UpdateQuoteFormRequest>
  ): void {
    this.store.dispatch(QuoteActions.updateQuote({ productType, override }));
  }

  updateQuoteAndAwaitResponse(
    productType: ProductType,
    override?: Partial<UpdateQuoteFormRequest>
  ): Observable<UpdateQuoteResponse> {
    const correlationId = StringUtils.generateUuid();
    const outcome$ = this.actions$.pipe(
      ofType(QuoteActions.updateQuoteSuccess, QuoteActions.updateQuoteFail),
      filter((action) => action.correlationId === correlationId),
      take(1),
      map((action) => {
        if (action.type === QuoteActions.updateQuoteFail.type) {
          throw action.error;
        }
        return action.response;
      })
    );
    this.store.dispatch(
      QuoteActions.updateQuote({
        productType,
        override,
        correlationId,
      })
    );
    return outcome$;
  }

  rateSelectedProducts(ratingType: RatingType): void {
    this.store.dispatch(QuoteActions.rateQuoteSelectedProducts({ ratingType }));
  }

  dispatchRateQuoteForProducts(products: ProductModel[]): void {
    products.forEach((product) =>
      this.store.dispatch(
        QuoteActions.rateQuote({
          productType: product.type,
          ratingType: 'quote',
        })
      )
    );
  }

  dispatchRateBindForProducts(products: ProductModel[]): void {
    products.forEach((product) =>
      this.store.dispatch(
        QuoteActions.rateQuote({
          productType: product.type,
          ratingType: 'bind',
        })
      )
    );
  }

  onQuoteCallsCompleted(
    callback: () => void,
    unsubscribe?: Observable<void>
  ): void {
    const source = unsubscribe
      ? this.actions$.pipe(takeUntil(unsubscribe))
      : this.actions$;
    source
      .pipe(ofType(QuoteActions.quoteCallsCompleted), take(1))
      .subscribe((a) => {
        callback();
      });
  }

  waitForTelematicsRefresh(): Observable<any> {
    return this.actions$.pipe(
      ofType(
        TelematicsActions.refreshTelematicsEnrollmentFail,
        TelematicsActions.refreshTelematicsEnrollmentSuccess
      ),
      take(1)
    );
  }

  bindRateAndWait(products: ProductModel[]): Observable<void> {
    const finished = new Subject<void>();
    this.onQuoteCallsCompleted(() => {
      finished.next();
      finished.complete();
    });
    for (const product of products) {
      this.dispatchRateQuote(product.type, 'bind');
    }
    return finished;
  }
}
