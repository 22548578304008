import { ObjectValidator } from '@core/helper/object-validator';
import {
  InformationalMessage,
  VehicleEnrollment,
  sanitizeVehicleEnrollment,
  sanitizeWarning,
  MobileEnrollmentValue,
  sanitizeMobileEnrollmentValue,
} from '@core/store/entities/telematics/telematics.model';

export interface TelematicsEnrollmentResponse {
  enrollmentId?: string;
  vehicleEnrollment?: VehicleEnrollment;
  mobileEnrollment?: MobileEnrollmentValue;
  informationalMessages?: InformationalMessage[];
  enrollmentConsents?: {
    phoneNumber?: string;
    hasConsentToOneTimeTextActivation?: boolean;
    hasConsentToOngoingTexts?: boolean;
  };
}

export function sanitizeTelematicsEnrollmentResponse(
  input: unknown
): TelematicsEnrollmentResponse {
  return ObjectValidator.forceSchema<TelematicsEnrollmentResponse>(
    input,
    {
      enrollmentId: 'string',
      vehicleEnrollment: sanitizeVehicleEnrollment,
      mobileEnrollment: sanitizeMobileEnrollmentValue,
      informationalMessages: (i) =>
        ObjectValidator.sanitizeArray(i, sanitizeWarning),
      enrollmentConsents: sanitizeTelematicsConsents,
    },
    [
      'enrollmentId',
      'vehicleEnrollment',
      'mobileEnrollment',
      'informationalMessages',
      'enrollmentConsents',
    ]
  );
}

export function sanitizeTelematicsConsents(input: unknown) {
  return ObjectValidator.forceSchema(
    input,
    {
      phoneNumber: 'string',
      hasConsentToOneTimeTextActivation: 'boolean',
      hasConsentToOngoingTexts: 'boolean',
    },
    [
      'phoneNumber',
      'hasConsentToOneTimeTextActivation',
      'hasConsentToOngoingTexts',
    ]
  );
}
