export const suffixConstants = [
  {
    value: 'jr',
    display: 'JR',
  },
  {
    value: 'sr',
    display: 'SR',
  },
  {
    value: 'c_Ir',
    display: 'I',
  },
  {
    value: 'c_II',
    display: 'II',
  },
  {
    value: 'c_III',
    display: 'III',
  },
  {
    value: 'c_IV',
    display: 'IV',
  },
  {
    value: 'c_V_Ext',
    display: 'V',
  },
  {
    value: 'c_VI_Ext',
    display: 'VI',
  },
];
export interface GenderOption {
  id: number;
  label: string;
  value: 'M' | 'F' | 'X';
}
export const genderOptions: GenderOption[] = [
  { id: 0, label: 'Male', value: 'M' },
  { id: 1, label: 'Female', value: 'F' },
];
