import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { LogService } from '@core/services/log.service';
import { retrieveQuoteSuccess } from '@core/store/retrieve/retrieve.action';
import { updateDwellingReplacementCostForm } from '@core/store/entities/confirmations/confirmations.action';
import {
  CoverageActions,
  QuoteActions,
  ScheduledCategoryActions,
  TaskActions,
} from '@core/store/actions';
import { PcUrlService } from '@shared/services/pc-url.service';
import { of } from 'rxjs';
import { ProductsSelectors, TaskSelectors } from '@core/store/selectors';
import { TaskService } from '@core/services/task.service';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { StringUtils } from '@shared/utils/string.utils';
import * as PropertyFormSelectors from '@core/store/property-form/property-form.selector';
import {
  rateBindSuccess,
  rateQuoteSuccess,
  updateQuoteSuccess,
} from '@entities/quote/quote.action';

@Injectable({
  providedIn: 'root',
})
export class TaskEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private taskService: TaskService,
    private loggingService: LogService,
    private pcUrlService: PcUrlService,
    private window: Window
  ) {}

  rebuildTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.rebuildTasks),
      switchMap(() => [TaskActions.clear(), TaskActions.updateTasks()])
    )
  );

  rebuildTasksWhenSelectedProductsChange$ = createEffect(() =>
    this.store.select(ProductsSelectors.getSelectedProductTypes).pipe(
      distinctUntilChanged((prev, next) =>
        StringUtils.unorderedStringListsEquivalent(prev, next)
      ),
      map(() => TaskActions.rebuildTasks())
    )
  );

  updateCoverageTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CoverageActions.getAllCoveragesSuccess,
        CoverageActions.updateCoverageSuccess,
        ScheduledCategoryActions.updateScheduledCategorySuccess,
        updateDwellingReplacementCostForm,
        retrieveQuoteSuccess,
        TaskActions.updateTasks
      ),
      switchMap(() =>
        this.store.select(TaskSelectors.updateCoverageTasks).pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updateAccountTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rateQuoteSuccess, updateQuoteSuccess),
      switchMap(() =>
        this.store.select(TaskSelectors.updateAccountTasks).pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updateReviewTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retrieveQuoteSuccess, TaskActions.updateTasks),
      switchMap(() =>
        this.store.select(TaskSelectors.updateReviewTasks).pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updateTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActions.rateQuoteSuccess, QuoteActions.rateBindSuccess),
      map(() => TaskActions.updateTasks())
    )
  );

  updateTasksWhenRoofYearBecomesImmutable$ = createEffect(() =>
    this.store.select(PropertyFormSelectors.getRoofYearImmutable).pipe(
      distinctUntilChanged(),
      filter((immutable) => immutable),
      map(() => TaskActions.rebuildTasks())
    )
  );

  openInPolicyCenter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.openInPolicyCenter),
      switchMap((action) =>
        this.pcUrlService.getPolicyCenterUrl(action.quoteId, 'Job').pipe(
          take(1),
          map((url) => ({ url, action }))
        )
      ),
      switchMap(({ url, action }) => {
        this.loggingService.logBusinessEvent('pivot-to-pc', action.reason);
        this.window.open(url);
        return of(TaskActions.openInPolicyCenterSuccess());
      }),
      catchError((error) => of(TaskActions.openInPolicyCenterFail()))
    )
  );

  aliasDismissReviewTaskAsCompleteTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.dismissReviewTask),
      withLatestFrom(this.store.select(TaskSelectors.getReviewTasks)),
      map(([action, tasks]) =>
        tasks.find((t) => t.field === action.payload.field)
      ),
      filterOutNull(),
      map((task) => TaskActions.completeTask({ payload: task }))
    )
  );
}
