import {
  CoverageChange,
  CoverageEntity,
  CoverageInformationalMessage,
  sanitizeCoverageEntity,
  sanitizeCoverageInformationalMessage,
} from '@core/store/entities/coverage/coverage.entity';
import { BaseRequest } from './base-request.model';
import { ObjectValidator } from '@core/helper/object-validator';

export interface CoveragesRequest extends BaseRequest {
  productModelSync?: boolean;
}

export interface CoverageChangeRequest extends BaseRequest {
  coverages: CoverageChange[];
}

export interface CoverageChangeResponse {
  coverages: CoverageEntity[];
  messages: CoverageInformationalMessage[];
}

export function sanitizeCoverageChangeResponse(
  input: unknown
): CoverageChangeResponse {
  return ObjectValidator.forceSchema<CoverageChangeResponse>(
    input,
    {
      coverages: [sanitizeCoverageEntity],
      messages: [sanitizeCoverageInformationalMessage],
    },
    []
  );
}
