import { Injectable } from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import {
  CompositeActions,
  CoveredLocationActions,
} from '@core/store/actions';
import { CoveredLocationEntity } from '@core/store/entities/covered-location/covered-location.reducer';
import {
  getPropertyOptions,
  getReconstructionCost,
} from '@core/store/entities/covered-location/covered-location.selector';
import { PropertyOptionsModel } from '@core/store/entities/metadata/models/property-options.model';
import { storeProtectiveDevices } from '@core/store/entities/protective-devices/protective-devices.action';
import { ProtectiveDeviceEntity } from '@core/store/entities/protective-devices/protective-devices.reducer';
import { setFortifiedHome } from '@core/store/property-form/property-form.action';
import { Store } from '@ngrx/store';
import { FortifiedHomeModel } from '@property/components/forms/fortified-home-form/fortified-home-form.model';
import { Nullable } from '@shared/utils/type.utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  constructor(private store: Store) {}

  getPropertyOptions(
    productType: ProductType
  ): Observable<PropertyOptionsModel> {
    return this.store.select(getPropertyOptions(productType));
  }

  submitHomeownerPage(): void {
    this.store.dispatch(CompositeActions.submitHomeownerPage());
  }

  submitCondoPage(): void {
    this.store.dispatch(CompositeActions.submitCondoPage());
  }

  submitTenantPage(): void {
    this.store.dispatch(CompositeActions.submitTenantPage());
  }

  storeCoveredLocation(coveredLocation: CoveredLocationEntity): void {
    this.store.dispatch(
      CoveredLocationActions.storeCoveredLocation({ payload: coveredLocation })
    );
  }

  storeFortifiedHome(fh: FortifiedHomeModel) {
    this.store.dispatch(
      setFortifiedHome({
        payload: fh,
      })
    );
  }

  storeProtectiveDevices(
    lineOfBusiness: ProductType,
    devices: ProtectiveDeviceEntity[]
  ): void {
    this.store.dispatch(
      storeProtectiveDevices({
        payload: {
          productType: lineOfBusiness,
          devices,
        },
      })
    );
  }

  getReconstructionCost(
    productType: ProductType
  ): Observable<Nullable<number>> {
    return this.store.select(getReconstructionCost(productType));
  }
}
