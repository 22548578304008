import { createAction, props } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export interface NavigationPath {
  path: string[];
  query?: object;
  extras?: NavigationExtras;
}

export const go = createAction(
  '[Router] Go',
  props<{
    payload: NavigationPath;
    // true to create a fresh spinner, or the name of one you've already spun up
    showSpinner?: boolean | string;
  }>()
);

export const goConditionaly = createAction(
  '[Router] Go Conditionaly',
  props<{
    page: string;
    query?: object;
  }>()
);

export const back = createAction('[Router] Back');

export const forward = createAction('[Router] Forward');
