import { ErrorModel } from '@core/store/entities/error/error.model';
import { createAction, props } from '@ngrx/store';
import { ProtectiveDevicesListEntity } from './protective-devices.reducer';
import { ProductType } from '@core/models/api/dsm-types';

export const getProtectiveDevices = createAction(
  '[Protective Devices] Get Protective Devices',
  props<{ payload: { productType: ProductType; quoteState: string } }>()
);

export const getProtectiveDevicesSuccess = createAction(
  '[Protective Devices] Get Protective Devices Success',
  props<{ payload: ProtectiveDevicesListEntity }>()
);

export const getProtectiveDevicesFail = createAction(
  '[Protective Devices] Get Protective Devices Fail',
  props<{ error: ErrorModel }>()
);

export const storeProtectiveDevices = createAction(
  '[Protective Devices] Store Protective Devices',
  props<{ payload: ProtectiveDevicesListEntity }>()
);
