import { Injectable } from '@angular/core';
import { DsmAdapter } from './dsm.adapter';
import { EuaJwtModel } from '../store/entities/session/session.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class EuaAdapter {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService
  ) {}

  getEuaUserInfo(accessToken: string): Observable<EuaJwtModel> {
    const url = this.appConfigService.config.euaUserInfoUrl;
    return this.httpClient.request<EuaJwtModel>('GET', url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}
