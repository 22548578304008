import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UnderlyingPolicyAdapter } from '@core/adapters/underlying-policy.adapter';
import { UnderlyingPolicyRequest } from '@core/models/api/request/underlying-policy-request.model';
import {
  UnderlyingPoliciesAndProductInfo,
  UnderlyingPolicyEntity,
} from '@entities/underlying-policy/underlying-policy.entity';
import { Store } from '@ngrx/store';
import {
  deleteUnderlyingPolicy,
  upsertUnderlyingPolicy,
} from '@entities/underlying-policy/underlying-policy.action';
import {
  getAllDeletedUnderlyingPolicies,
  getAllUnderlyingPolicies,
  getUnderlyingPoliciesAndProductInfo,
  getUnderlyingPoliciesNumberAndName,
  getUnderlyingPoliciesLabels,
  policyTypeExists,
  getUnderlyingPolicyTiles,
  getAllAccompanyingPolicies,
} from '@entities/underlying-policy/underlying-policy.selector';
import { OptionsModel } from '@entities/metadata/models/property-options.model';
import {
  UmbrellaOptionsModel,
  UnderlyingPolicyOptionsModel,
} from '@entities/metadata/models/umbrella-options.model';
import { Nullable } from '@shared/utils/type.utils';
import { UnderlyingPolicyActions } from '@core/store/actions';
import {
  getAllUnderlyingAndExposureOptions,
  getAllUnderlyingPolicyOptions,
} from '@entities/umbrella/umbrella.selector';
import { UmbrellaTileModel } from '@shared/components/umbrella-tile/umbrella-tile.component';
import { ProductType } from '@core/models/api/dsm-types';

@Injectable({
  providedIn: 'root',
})
export class UnderlyingPolicyService {
  constructor(private adapter: UnderlyingPolicyAdapter, private store: Store) {}

  getAllUnderlyingPolicies(): Observable<UnderlyingPolicyEntity[]> {
    return this.store.select(getAllUnderlyingPolicies);
  }

  addOrUpdateUnderlyingPolicy(
    request: UnderlyingPolicyRequest
  ): Observable<UnderlyingPolicyEntity> {
    if (request.underlyingPolicyBody.underlyingPolicy.underlyingPolicyId) {
      return this.adapter.updateUnderlyingPolicy(request);
    } else {
      return this.adapter.addUnderlyingPolicy(request);
    }
  }

  upsertUnderlyingPolicy(
    underlyingPolicy: UnderlyingPolicyEntity,
    existingPolicyNumber?: string
  ): void {
    this.store.dispatch(
      upsertUnderlyingPolicy({
        payload: underlyingPolicy,
        existingPolicyNumber,
      })
    );
  }

  removeUnderlyingPolicy(underlyingPolicy: UnderlyingPolicyEntity): void {
    this.store.dispatch(deleteUnderlyingPolicy({ payload: underlyingPolicy }));
  }

  deleteUnderlyingPolicy(
    request: UnderlyingPolicyRequest
  ): Observable<unknown> {
    return this.adapter.removeUnderlyingPolicy(request);
  }

  getUnderlyingPoliciesAndProductInfo(): Observable<
    UnderlyingPoliciesAndProductInfo[]
  > {
    return this.store.select(getUnderlyingPoliciesAndProductInfo);
  }

  getUnderlyingPoliciesNumberAndName(): Observable<UnderlyingPolicyEntity[]> {
    return this.store.select(getUnderlyingPoliciesNumberAndName);
  }

  getAllUnderlyingAndExposureOptions(): Observable<UmbrellaOptionsModel> {
    return this.store.select(getAllUnderlyingAndExposureOptions);
  }

  getUnderlyingPoliciesLabels(): Observable<OptionsModel[]> {
    return this.store.select(getUnderlyingPoliciesLabels);
  }

  getUnderlyingPolicyTiles(): Observable<UmbrellaTileModel[]> {
    return this.store.select(getUnderlyingPolicyTiles);
  }

  getAllUnderlyingPolicyOptions(): Observable<
    Nullable<UnderlyingPolicyOptionsModel>
  > {
    return this.store.select(getAllUnderlyingPolicyOptions);
  }

  restoreUnderlyingPolicies(): void {
    return this.store.dispatch(
      UnderlyingPolicyActions.restoreUnderlyingPolicies()
    );
  }

  getAllDeletedUnderlyingPolicies(): Observable<UnderlyingPolicyEntity[]> {
    return this.store.select(getAllDeletedUnderlyingPolicies);
  }

  clearDeletedUnderlyingPolicies(): void {
    return this.store.dispatch(
      UnderlyingPolicyActions.clearDeletedUnderlyingPolicies()
    );
  }

  policyTypeExists(type: string): Observable<boolean> {
    return this.store.select(policyTypeExists(type));
  }

  getAllAccompanyingPolicies(): Observable<UmbrellaTileModel[]> {
    return this.store.select(getAllAccompanyingPolicies);
  }

  buildAccompanyingExposures(
    productType: ProductType,
    quoteId: string | undefined
  ): void {
    return this.store.dispatch(
      UnderlyingPolicyActions.buildAccompanyingExposures({
        productType,
        quoteId,
      })
    );
  }
}
