import {
  BaseEntity,
  BaseEntityOptionalFields,
  BaseEntitySanitizerFields,
} from '@core/models/entities/base.entity';
import { BoatType } from '@core/models/views/vehicle.model';
import { ProductModel } from '@entities/product/product.model';
import { UnderlyingPolicyEntity } from '@entities/underlying-policy/underlying-policy.entity';
import { Nullable } from '../../../../../shared/utils/type.utils';
import { ObjectValidator } from '@core/helper/object-validator';

export interface WatercraftExcludedExposureAndProductInfo {
  watercraftExcludedExposure: WatercraftExcludedExposureEntity;
  underlyingPolicy?: UnderlyingPolicyEntity;
  product: ProductModel;
  options?: {
    watercraftTypeLabel?: string;
    carrierLabel?: string;
  };
}

export interface WatercraftExcludedExposureEntity extends BaseEntity {
  productId?: string;
  watercraftExcludedExposureId: string;
  underlyingPolicyNumber?: Nullable<string>;
  carrier: string;
  watercraftType: string;
  hin: string;
  year: number;
  make: string;
  model: string;
}

export function sanitizeWatercraftExcludedExposureEntity(
  input: unknown
): WatercraftExcludedExposureEntity {
  return ObjectValidator.forceSchema<WatercraftExcludedExposureEntity>(
    input,
    {
      productId: 'string',
      watercraftExcludedExposureId: 'string',
      underlyingPolicyNumber: 'string',
      carrier: 'string',
      watercraftType: 'string',
      hin: 'string',
      year: 'number',
      make: 'string',
      model: 'string',
      ...BaseEntitySanitizerFields,
    },
    [...BaseEntityOptionalFields, 'productId', 'underlyingPolicyNumber']
  );
}
