import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { eligibleVehicles } from '@assets/metadata/auto/smartRideInstantEligibleVehicles';
import { LoadingService } from '@core/services/loading.service';
import { QuoteService } from '@core/services/quote.service';
import { TelematicsService } from '@core/services/telematics.service';
import {
  VehicleEnrollment,
  MobileEnrollment,
  ExtendedTelematicsVehicle,
} from '@core/store/entities/telematics/telematics.model';
import { PremiumEntity } from '@entities/premium/premium.entity';
import { ModalService } from '@shared/services/modal.service';
import { ComponentChanges } from '@shared/utils/general.utils';
import { TelematicsUtils } from '@shared/utils/telematics.utils';
import { Nullable } from '@shared/utils/type.utils';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'nwx-telematics',
  templateUrl: './telematics.component.html',
  styleUrls: ['./telematics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelematicsComponent implements OnChanges {
  @Input() enrollment: Nullable<VehicleEnrollment | MobileEnrollment>;
  @Input() programTileVehicles!: ExtendedTelematicsVehicle[];
  @Input() quoteState!: string;
  @Input() isRecommendationEnrolled = false;
  @Input() isAnyTelematicsEnrolled = false;
  @Input() shouldProposeReenrollExcluded = false;
  @Input() hasDrivingDataConsent = false;
  @Input() maxSizeMini!: Nullable<boolean>;
  @Input() maxSizeMedium!: Nullable<boolean>;
  @Input() premium: Nullable<PremiumEntity> = null;
  isMobile = false;

  smartMilesVehicles: ExtendedTelematicsVehicle[] = [];
  smartRideVehicles: ExtendedTelematicsVehicle[] = [];
  smartRideInstantVehicles: ExtendedTelematicsVehicle[] = [];
  smartRideConnectedCarVehicles: ExtendedTelematicsVehicle[] = [];
  smartMilesConnectedCarVehicles: ExtendedTelematicsVehicle[] = [];
  selfReportedVehicles: ExtendedTelematicsVehicle[] = [];
  ineligibleVehicles: ExtendedTelematicsVehicle[] = [];
  unenrolledVehicles: ExtendedTelematicsVehicle[] = [];

  telematicsCallsPending = 0;

  vehicleEnrollment: Nullable<VehicleEnrollment>;
  mobileEnrollment: Nullable<MobileEnrollment>;

  constructor(
    private telematicsService: TelematicsService,
    private modalService: ModalService,
    private quoteService: QuoteService,
    private loadingService: LoadingService
  ) {}

  ngOnChanges(changes: ComponentChanges<TelematicsComponent>): void {
    this.clearAllocations();
    this.determineEnrollmentType();
    this.allocateVehiclesByProgram();
  }

  removeAllPrograms(): void {
    this.telematicsService.dispatchRemoveEnrollment();
  }

  resetAllPrograms(): void {
    this.telematicsService.dispatchResetEnrollment();
  }

  reenrollExcluded(): void {
    this.telematicsService.clearExcludedQuotes();
    this.telematicsService.callGetTelematicsEnrollment();
  }

  openSmartRideDataSharingModal(): void {
    this.modalService
      .smartrideDataSharingConsent()
      .pipe(take(1))
      .subscribe((result) => {
        if (result && typeof result === 'boolean') {
          this.loadingService.beginLoading('update-telematics');
          this.quoteService.dispatchUpdateQuote('PersonalAuto', {
            hasDrivingDataConsent: result,
          });
          this.quoteService
            .getQuoteUpdateInProgress()
            .pipe(
              filter((v) => !!v),
              take(1)
            )
            .subscribe(() => {
              this.programTileVehicles.forEach((v) => {
                if (this.vehiclesEligibleForSmartRideDataSharing([v])) {
                  this.telematicsService.dispatchUpdateRecommendationForVehicle(
                    v
                  );
                  this.telematicsCallsPending++;
                  this.quoteService.waitForTelematicsRefresh().subscribe({
                    next: () => {
                      if (this.telematicsCallsPending > 0) {
                        this.telematicsCallsPending--;
                        if (!this.telematicsCallsPending) {
                          this.loadingService.endLoading('update-telematics');
                        }
                      }
                    },
                  });
                }
              });
            });
        }
      });
  }

  displaySmartRideDataSharingConsent(): boolean {
    if (this.hasDrivingDataConsent) {
      return false;
    }
    if (
      this.vehiclesEligibleForSmartRideDataSharing(this.programTileVehicles) &&
      !this.hasDrivingDataConsent
    ) {
      return true;
    } else {
      return false;
    }
  }

  private vehiclesEligibleForSmartRideDataSharing(
    vehicles: Nullable<ExtendedTelematicsVehicle[]>
  ): boolean {
    return (
      vehicles?.some((v) =>
        eligibleVehicles.some(
          (e) => e.year <= (v.year || 0) && e.make === v.make
        )
      ) || false
    );
  }

  private determineEnrollmentType(): void {
    if (TelematicsUtils.isMobileEnrollment(this.enrollment)) {
      this.mobileEnrollment = this.enrollment as MobileEnrollment;
      this.vehicleEnrollment = null;
      this.isMobile = true;
    } else if (TelematicsUtils.isVehicleEnrollment(this.enrollment)) {
      this.vehicleEnrollment = this.enrollment as VehicleEnrollment;
      this.mobileEnrollment = null;
      this.isMobile = false;
    } else {
      this.unenrolledVehicles = this.programTileVehicles;
    }
  }

  private allocateVehiclesByProgram(): void {
    if (this.isMobile) {
      this.smartRideVehicles = this.programTileVehicles;
      return;
    }
    this.programTileVehicles.forEach((vehicle) => {
      if (!this.isVehicleAllocated(vehicle)) {
        if (vehicle.enrollmentStatus === 'Enrolled') {
          if (
            vehicle.vehicleProgram === 'SmartMiles' &&
            vehicle.dataCollectionMethod !== 'ConnectedCar'
          ) {
            this.smartMilesVehicles.push(vehicle);
          }
          if (
            vehicle.vehicleProgram === 'SmartMiles' &&
            vehicle.dataCollectionMethod === 'ConnectedCar'
          ) {
            this.smartMilesConnectedCarVehicles.push(vehicle);
          }
          if (vehicle.vehicleProgram === 'SmartRide') {
            this.smartRideVehicles.push(vehicle);
          }
          if (vehicle.vehicleProgram === 'SelfReported') {
            this.selfReportedVehicles.push(vehicle);
          }
          if (vehicle.vehicleProgram === 'SmartRideInstant') {
            this.smartRideInstantVehicles.push(vehicle);
          }
          if (vehicle.vehicleProgram === 'ConnectedCar') {
            this.smartRideConnectedCarVehicles.push(vehicle);
          }
        } else if (vehicle.enrollmentStatus === 'VerifiedScore') {
          this.smartRideInstantVehicles.push(vehicle);
        } else {
          this.unenrolledVehicles.push(vehicle);
        }
      }
    });
  }

  private isVehicleAllocated(vehicle: ExtendedTelematicsVehicle): boolean {
    const allocatedVehicles = [
      ...this.smartMilesVehicles,
      ...this.smartRideVehicles,
      ...this.smartRideInstantVehicles,
      ...this.smartRideConnectedCarVehicles,
      ...this.smartMilesConnectedCarVehicles,
      ...this.selfReportedVehicles,
      ...this.unenrolledVehicles,
      ...this.ineligibleVehicles,
    ];
    return allocatedVehicles.includes(vehicle);
  }

  private clearAllocations(): void {
    this.smartMilesVehicles = [];
    this.smartRideVehicles = [];
    this.smartRideInstantVehicles = [];
    this.smartRideConnectedCarVehicles = [];
    this.smartMilesConnectedCarVehicles = [];
    this.selfReportedVehicles = [];
    this.unenrolledVehicles = [];
    this.ineligibleVehicles = [];
  }

  get isCA(): boolean {
    return this.quoteState === 'CA';
  }
}
