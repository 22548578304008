import { Injectable } from '@angular/core';
import { BillingService } from '@core/services/billing.service';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import {
  catchError,
  delayWhen,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { BillingSelectors } from '../../selectors';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { ProductsService } from '@core/services/products.service';
import { QuoteService } from '@core/services/quote.service';
import { BillingIssuePoliciesModalComponent } from '@app/billing-payment/components/billing-issue-policies-modal/billing-issue-policies-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillingActions } from '@core/store/actions';
import { LogService } from '@core/services/log.service';
import { AgencyService } from '@core/services/agency.service';
import { BillingPlansAction } from '@forms-store/store/actions';
import { GeneralUtils } from '@shared/utils/general.utils';
import { NavigationService } from '@core/services/navigation.service';
import {
  BillingPaymentsPageRepresentation,
  HubPageRepresentation,
} from '@core/constants/pages';
import { MortgageService } from '@core/services/mortgage.service';
import { ProductUtils } from '@shared/utils/product.util';
@Injectable()
export class BillingEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private billingService: BillingService,
    private errorSanitizerService: ErrorSanitizerService,
    private productsService: ProductsService,
    private quoteService: QuoteService,
    private modalService: NgbModal,
    private agencyService: AgencyService,
    private log: LogService,
    private navigationService: NavigationService,
    private mortgageService: MortgageService
  ) {}

  loadDownPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.loadDownPayment),
      delayWhen(() =>
        this.quoteService.getQuoteInProgressCount().pipe(
          filter((inProgressCount) => !inProgressCount),
          take(1)
        )
      ),
      switchMap(() =>
        this.billingService.getProductsByBillingAccount().pipe(take(1))
      ),
      map((productsByBillingAccount) =>
        productsByBillingAccount.filter(
          (pbb) => pbb.billingAccountType === 'billing'
        )
      ),
      tap((productsByBillingAccount) =>
        this.billingService.adjustDownPaymentCallCount(
          productsByBillingAccount.length
        )
      ),
      mergeMap(
        (productsByBillingAccount) =>
          productsByBillingAccount.length
            ? from(productsByBillingAccount)
            : of({ productIds: [] }) // dummy for escrow-only cases
      ),
      mergeMap(({ productIds }) =>
        this.store
          .select(
            BillingSelectors.buildDownPaymentExperienceRequest(productIds)
          )
          .pipe(take(1))
      ),
      mergeMap((request) => {
        if (!request) {
          return of(BillingActions.downPaymentNotNeeded());
        } else {
          return this.billingService.downPaymentExperience(request).pipe(
            switchMap((response) =>
              from([
                BillingActions.loadDownPaymentSuccess({ payload: response }),
                BillingActions.adjustDownPaymentCallCount({ d: -1 }),
              ])
            ),
            catchError((error) => {
              return from([
                BillingActions.loadDownPaymentFail({
                  error: this.errorSanitizerService.sanitizeError(error),
                }),
                BillingActions.adjustDownPaymentCallCount({ d: -1 }),
              ]);
            })
          );
        }
      })
    )
  );

  loadDownPaymentIfAllProductsRated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.loadDownPaymentIfAllProductsRated),
      withLatestFrom(this.productsService.getSelectedProducts()),
      map(([action, products]) => {
        if (
          products.every(
            (p) => p.quoteStatus === 'Quoted' || p.quoteStatus === 'Binding'
          )
        ) {
          return BillingActions.loadDownPayment();
        } else {
          return null;
        }
      }),
      filterOutNull()
    )
  );

  setupAccountFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.setupAccountFail),
      withLatestFrom(
        this.productsService.getSelectedProducts(),
        this.agencyService.getAgency()
      ),
      map(([action, products, agency]) => {
        const modalRef = this.modalService.open(
          BillingIssuePoliciesModalComponent,
          {
            size: 'xl',
            centered: true,
            backdrop: 'static',
            keyboard: false,
          }
        );
        modalRef.componentInstance.products = products;
        modalRef.componentInstance.errorMessage = action.error.developerMessage;

        // log SAE025 error
        if (modalRef.componentInstance.errorMessage?.includes('SAE025')) {
          this.log.logBusinessEvent('display-pivot-to-pc', {
            ...agency,
            reason: 'Setup Account failed with SAE025 error',
          });
        }

        return BillingActions.showIssuePoliciesWarning();
      })
    )
  );
}
