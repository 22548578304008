import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromAmfBankAccounts from '@core/store/entities/amf-bank-accounts/amf-bank-accounts.reducer';

export const getAmfBankAccountsState = createSelector(
  fromCore.getCoreState,
  (state: fromCore.CoreState) => state.amfBankAccounts
);

export const getAmfBankAccountsLoaded = createSelector(
  getAmfBankAccountsState,
  fromAmfBankAccounts.getAmfBankAccountsLoaded
);

export const getAmfBankAccountsLoading = createSelector(
  getAmfBankAccountsState,
  fromAmfBankAccounts.getAmfBankAccountsLoading
);

export const getAmfBankAccountsFailed = createSelector(
  getAmfBankAccountsState,
  fromAmfBankAccounts.getAmfBankAccountsFailed
);

export const getAmfBankAccounts = createSelector(
  getAmfBankAccountsState,
  (state) => {
    return state.amfBankAccounts;
  }
);

export const hasAgencySweepBankAccount = createSelector(
  getAmfBankAccounts,
  (accounts) => {
    return (
      accounts !== undefined &&
      accounts.agencyAccounts !== undefined &&
      accounts.agencyAccounts.some((account) => account.ezSweep)
    );
  }
);
