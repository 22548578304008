import { PreBindDocumentEntity } from '@core/models/entities/pre-bind-document.entity';
import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromDocuments from './pre-bind-documents.reducer';
import * as fromProduct from '@core/store/entities/product/product.selectors';
import * as fromUser from '@core/store/entities/user/user.selector';
import * as fromPeople from '@core/store/entities/member/member.selector';
import * as fromSession from '@core/store/entities/session/session.selector';
import * as fromAddress from '@core/store/entities/address/address.selector';
import {
  PreBindDocumentEmailRequest,
  PreBindDocumentRequest,
  PreBindDocumentUploadRequest,
  PreBindDocumentUploadRequestModel,
} from '@core/models/api/request/pre-bind-document-request.model';
import { ProductType } from '@core/models/api/dsm-types';
import { PersonUtils } from '@shared/utils/person.utils';

export const getPreBindDocumentsState = createSelector(
  fromCore.getCoreState,
  (state) => state.preBindDocuments
);

export const {
  selectAll: selectAllPreBindDocuments,
  selectEntities: selectPreBindDocumentsEntities,
  selectIds: selectPreBindDocumentsIds,
} = fromDocuments.adapter.getSelectors(getPreBindDocumentsState);

export const selectAllPreBindDocumentByProductType = (
  productType: ProductType
) =>
  createSelector(selectAllPreBindDocuments, (preBindDocuments) => {
    return preBindDocuments.filter((doc) => doc.productType === productType);
  });

export const buildPreBindDocumentDetailRequest = (productType: ProductType) =>
  createSelector(
    fromProduct.getProduct(productType),
    selectAllPreBindDocumentByProductType(productType),
    fromAddress.getAddressForProduct(productType),
    (product, preBindDocuments, policyAddress) => {
      return preBindDocuments.map((doc) => {
        return {
          productType: product?.type,
          quoteId: product?.quoteId,
          documentId: doc.documentId,
          zipCode: policyAddress?.postalCode?.split('-')[0],
          userContext: {},
        };
      }) as PreBindDocumentRequest[];
    }
  );

export const getPreBindDocumentsUploadResponse = (productType: ProductType) =>
  createSelector(getPreBindDocumentsState, (state) =>
    productType === 'PersonalAuto'
      ? state.personalAutoUploadResponse
      : state.msaUploadResponse
  );

export const buildPreBindDocumentEmailRequest = (productType: ProductType) =>
  createSelector(
    fromProduct.getProduct(productType),
    fromSession.getAccessToken,
    getPreBindDocumentsUploadResponse(productType),
    fromUser.getUser,
    fromPeople.getPrimaryNamedInsuredForProduct(productType),
    fromSession.getSessionId,
    selectAllPreBindDocumentByProductType(productType),
    (
      product,
      accessToken,
      uploadResponse,
      user,
      policyholder,
      sessionId,
      preBindDocuments
    ) => {
      const docs = preBindDocuments.map((doc) => {
        return {
          documentId: doc.documentId,
          documentName: doc.name,
          documentByteStream: doc.documentByteStream,
          extension: 'pdf',
        } as PreBindDocumentUploadRequestModel;
      });

      const request = {
        channel: user.role,
        email: policyholder?.emailAddress,
        contactInformation: policyholder?.homeNumber,
        submissionNumber: product?.quoteId,
        url: uploadResponse.folderUrl,
        policyHolderId: PersonUtils.getEntityId(
          policyholder,
          'policyHolder',
          productType
        ),
        productType: productType,
        documents: docs,
      } as PreBindDocumentEmailRequest;
      return { request, accessToken: accessToken || '', sessionId };
    }
  );

export const buildPreBindDocumentUploadRequest = (
  productType: ProductType,
  documents: PreBindDocumentEntity[]
) =>
  createSelector(
    fromProduct.getProduct(productType),
    fromSession.getAccessToken,
    getPreBindDocumentsUploadResponse(productType),
    fromUser.getUser,
    fromPeople.getPrimaryNamedInsuredForProduct(productType),
    fromSession.getSessionId,
    fromAddress.getAddressForProduct(productType),
    (
      product,
      accessToken,
      uploadResponse,
      user,
      policyholder,
      sessionId,
      policyAddress
    ) => {
      const docs = documents.map((doc) => {
        return {
          documentId: doc.documentId,
          documentName: doc.name?.replace('/', ' '),
          documentByteStream: doc.documentByteStream,
          extension: 'pdf',
        } as PreBindDocumentUploadRequestModel;
      });

      const request = {
        channel: user.role,
        email: policyholder?.emailAddress || '',
        submissionNumber: product?.quoteId,
        contactInformation: '',
        zipCode: policyAddress?.postalCode?.split('-')[0],
        documents: docs,
        policyHolderId: policyholder?.policyHolderId?.toString(),
      } as PreBindDocumentUploadRequest;

      if (uploadResponse && uploadResponse.folderId.length > 0) {
        request.folderId = uploadResponse.folderId;
      }
      return { request, documents, accessToken: accessToken || '', sessionId };
    }
  );

export const getPreBindDocumentsUploaded = createSelector(
  getPreBindDocumentsState,
  fromProduct.getSelectedProductsWithoutErrors,
  (state, products) => {
    const autoSelected = !!products.find(
      (product) => product.type === 'PersonalAuto'
    );
    const msaSelected = !!products.find((product) => product.type === 'MSA');
    return (
      state.loaded &&
      (autoSelected ? !!state.personalAutoUploadResponse : true) &&
      (msaSelected ? !!state.msaUploadResponse : true)
    );
  }
);

export const getPreBindDocumentsUploading = createSelector(
  getPreBindDocumentsState,
  (state) => state.loading
);

export const getPreBindCallsIdle = createSelector(
  getPreBindDocumentsState,
  (state) => !state.loading
);

export const isPreBindDocumentEmailSent = createSelector(
  getPreBindDocumentsState,
  (state) => state.emailResent
);

export const areAllPreBindDocumentsRetrieved = (productType: ProductType) =>
  createSelector(selectAllPreBindDocuments, (documents) => {
    const productDocs = documents.filter(
      (doc) => doc.productType === productType
    );
    return (
      productDocs.length > 0 &&
      productDocs.every((doc) => !!doc.documentByteStream?.length)
    );
  });

export const getPreBindDocumentProductTypes = createSelector(
  selectAllPreBindDocuments,
  (documents) => Array.from(new Set(documents.map((doc) => doc.productType)))
);
