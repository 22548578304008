import { PrequalificationVerbiageActions } from "@core/store/actions";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";

export interface PrequalificationVerbiageModel {
  questionCode: string;
  questionText: string;
}

export interface PrequalificationVerbiageState extends EntityState<PrequalificationVerbiageModel> {
}

export const adapter = createEntityAdapter<PrequalificationVerbiageModel>({
  selectId: (entity) => entity.questionCode,
});

const initialState: PrequalificationVerbiageState = {
  ...adapter.getInitialState(),
};

export const reducer = createReducer<PrequalificationVerbiageState>(
  initialState,
  on(PrequalificationVerbiageActions.upsertPrequalificationVerbiage, (state, { questionCode, questionText }) => {
    return adapter.upsertOne({ questionCode, questionText }, state);
  })
);
