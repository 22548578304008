// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { MemberDriverRole } from '@core/models/entities/base.entity';
import { MemberModel } from '@core/models/views/person.model';
import { AddressEntity } from '@core/store/entities/address/address.entity';
import { VehicleEntity } from '@core/store/entities/vehicle/vehicle.entity';
import { PolicyHolderEntity } from '@entities/policyholder/policyholder.entity';

export const environment = {
  production: false,
  // To replace this: Change its name, clear your sessionStorage, reload app, initiate a quote.
  // Then in the JS console: printNwxTestSessionConfig(). Ta da!
  // Set "testSessionConfig" to bootstrap into a live session:
  testSessionConfigX: false,
  accountAddress: {
    addressType: 'Account',
    country: 'US',
    state: 'OH',
    addressLine1: '12195 Emma Ln',
    city: 'Cleveland',
    postalCode: '44130',
  } as AddressEntity,
  defaultAddress: {
    addressType: 'Default',
    country: 'US',
    state: 'OH',
    addressLine1: '12195 Emma Ln',
    city: 'Cleveland',
    postalCode: '44130',
    street: '12195 Emma Ln, Cleveland, OH, 44130',
  } as AddressEntity,
  members: {
    OH: [
      {
        selected: true,
        entityId: 'undefined',
        policyHolderType: 'PolicyPriNamedInsured',
        relationToPrimaryNamedInsured: 'PrimaryNamedInsured',
        person: {
          firstName: 'Heidi',
          lastName: 'Mcapin',
          dateOfBirth: '1980-04-24',
          gender: 'F',
          maritalStatus: 'M',
        },
        emailAddress: 'abc@abc.com',
        homeNumber: '555-555-5555',
        address: {
          country: 'US',
          state: 'OH',
          addressLine1: '12195 Emma Ln',
          city: 'Cleveland',
          postalCode: '44130',
        },
        policyRoles: [] as unknown as PolicyHolderEntity,
        driverRoles: [] as unknown as MemberDriverRole,
        isNewlyAddedDriver: true,
        informationConfidenceLevel: 'LOW',
        numberOfYearsOfBoatingExperience: 26,
        isFinancialFilingRequired: null,
        licenseState: 'OH',
        ageFirstLicensed: 16,
        driverType: 'Driver',
        licenseStatus: 'Active',
        isDomesticHelp: false,
        isInternationalLicense: false,
        employmentInformation: {
          currentEmployment: 'GE12MoNotInclStudent_Ext',
          fullTimeEmployment: 'GE_12mo',
        },
        healthCarePlan: null,
        trainingCourseCompletionDate: null,
        advancedTrainingCourseCompletionDate: null,
        yearsOfMotorcycleAndOffRoadExp: null,
        isLicenseRequired: true,
        licenseNumber: 'BE202572',
        defensiveDrivingCourseDate: null,
        isLivingWithNWInsuredParent: false,
        hasGraduatedCollege: false,
        infractions: false,
        goodStudent: false,
        studentAway: false,
      } as unknown as MemberModel,
      {
        selected: true,
        entityId: 'undefined',
        policyHolderType: 'PolicySecNamedInsured',
        relationToPrimaryNamedInsured: 'Coowner',
        ageFirstLicensed: 16,
        isLicenseRequired: true,
        licenseNumber: 'KK324431',
        isFinancialFilingRequired: null,
        licenseState: 'OH',
        healthCarePlan: null,
        yearsOfMotorcycleAndOffRoadExp: null,
        numberOfYearsOfBoatingExperience: '15',
        policyRoles: [] as unknown as PolicyHolderEntity,
        driverRoles: [] as unknown as MemberDriverRole,
        person: {
          firstName: 'James',
          lastName: 'Brown',
          dateOfBirth: '1986-04-19',
          gender: 'M',
          maritalStatus: 'M',
        },
        trainingCourseCompletionDate: null,
        advancedTrainingCourseCompletionDate: null,
        defensiveDrivingCourseDate: null,
        isNewlyAddedDriver: true,
        informationConfidenceLevel: 'LOW',
        driverType: 'Driver',
        licenseStatus: 'Active',
        isDomesticHelp: false,
        isInternationalLicense: false,
        employmentInformation: {
          currentEmployment: 'GE12MoNotInclStudent_Ext',
          fullTimeEmployment: 'GE_12mo',
        },
        isLivingWithNWInsuredParent: false,
        hasGraduatedCollege: false,
        infractions: false,
        goodStudent: false,
        studentAway: false,
      } as unknown as MemberModel,
    ] as MemberModel[],
  },
  vehicles: {
    PersonalAuto: [
      {
        annualMiles: 5000,
        year: 2013,
        sequenceNumber: 1,
        isSmartRideDeviceEligible: true,
        antiTheftDevice: 'P',
        isConnectable: false,
        vehicleId: null,
        bodyStyle: 'SEDAN 4D',
        eligibleDiscounts: [
          {
            name: 'Passive Restraint',
            selectedOptionValue: 'AirBag_Ext',
            availableOptions: [
              {
                optionDescription: 'Airbag Full',
                optionValue: 'AirBag_Ext',
              },
              {
                optionDescription: 'Airbag Drivers Side',
                optionValue: 'DriverSideAirBags_Ext',
              },
              {
                optionDesccription: 'Passive Seat Belt Full',
                optionValue: 'PassiveSeatBelt_Ext',
              },
            ],
            eligibleDiscountId: 'PassiveRestraint',
            description: 'Passive Restraint System',
          },
          {
            name: 'Anti Theft',
            selectedOptionValue: 'true',
            availableOptions: [
              {
                optionDescription: 'Alarm',
                isMandatory: false,
                optionValue: 'A',
              },
              {
                optionDescription: 'Recovery Device',
                isMandatory: false,
                optionValue: 'K',
              },
              {
                optionDescription: 'VIN Etching',
                isMandatory: false,
                optionValue: 'V',
              },
              {
                optionDescription: 'Active Disabling',
                isMandatory: false,
                optionValue: 'D',
              },
              {
                optionDescription: 'Passive Disabling',
                isMandatory: true,
                optionValue: 'P',
              },
            ],
            qualifyingInformation: {
              selectedAntiTheft: ['P'],
            },
            eligibleDiscountId: 'AntiTheft',
            description: 'Anti Theft',
          },
        ],
        primaryUse: 'Work',
        vehicleType: 'auto',
        vin: '1HGCR2E51DA192310',
        bodyType: '4 DOOR 100 IN',
        garageLocation: {
          country: 'US',
          state: 'OH',

          addressLine1: '217 OAKLAND PARK AVE',
          city: 'COLUMBUS',
          postalCode: '43214-4121',
        },
        make: 'HOND',
        model: 'ACCORD SP',
        isMakeNotFound: false,
        isModelNotFound: false,
        isPurchasedNew: false,
        informationalMessages: [],
        series: 'ACCORD SPORT 4DR SED',
        antiLockBrakes: 'S',
        selected: true,
        productType: 'PersonalAuto',
        odometerReading: null,
        milesOneWay: null,
        daysCommutingPerWeek: null,
        isRegisteredInState: null,
        passiveRestraint: 'AirBag_Ext',
        vehicleSubType: null,
        previouslyOwned: true,
        purchasedNewDate: null,
        registeredOwners: [],
        additionalInterests: [],
        costNew: null,
        motorDetails: [],
        hasTrailer: false,
        isPriorDamaged: false,
      } as unknown as VehicleEntity,
    ],
    MSA: [
      {
        vehicleId: null,
        cubicCentimeters: 2294,
        eligibleDiscounts: [
          {
            name: 'Anti-Lock Brakes',
            selectedOptionValue: 'false',
            eligibleDiscountId: 'AntiLockBrakes',
            description: 'Anti-Lock Brakes',
          },
          {
            name: 'Anti Theft',
            qualifyingInformation: {
              selectedAntiTheft: ['P'],
            },
            selectedOptionValue: 'true',
            availableOptions: [
              {
                optionDescription: 'Active Disabling',
                isMandatory: false,
                optionValue: 'D',
              },
              {
                optionDescription: 'VIN Etching',
                isMandatory: false,
                optionValue: 'V',
              },
              {
                optionDescription: 'Recovery Device',
                isMandatory: false,
                optionValue: 'K',
              },
              {
                optionDescription: 'Passive Disabling',
                isMandatory: true,
                optionValue: 'P',
              },
              {
                optionDescription: 'Alarm',
                isMandatory: false,
                optionValue: 'A',
              },
            ],
            eligibleDiscountId: 'AntiTheft',
            description: 'Anti Theft',
          },
        ],
        subType: 'Cruiser',
        subTypeClass: 'Cruiser',
        hasPriorDamage: false,
        vehicleType: 'Motorcycle',
        additionalInformation: {
          isStoredInLockedGarage: true,
          isCoOwned: false,
          numberOfCoOwners: null,
          isRebuiltSalvagedFrameReplaced: false,
          registrationState: null,
        },
        isPurchasedNew: false,
        year: '2014',
        sequenceNumber: 1,
        vin: 'SMTC02L44EJ621185',
        garageLocation: {
          country: 'US',
          state: 'OH',
          addressLine1: '12195 EMMA LN',
          city: 'CLEVELAND',
          postalCode: '44130-2275',
          street: '12195 EMMA LN, CLEVELAND, OH, 44130-2275',
        },
        make: 'Triumph',
        model: 'Rocket III ABS Roadster',
        annualMiles: null,
        selected: true,
        productType: 'MSA',
        auxiliaryLights: null,
        primaryUse: 'Work',
        milesOneWay: null,
        daysCommutingPerWeek: null,
        previouslyOwned: true,
        purchasedNewDate: null,
        registeredOwners: [],
        additionalInterests: [],
        costNew: null,
        value: null,
        motorDetails: [],
        hasTrailer: false,
        isPriorDamaged: false,
        safetyDevice: null,
        hasFiberglassShell: null,
        hasTirePressureMonitoring: null,
        isMakeNotFound: false,
        isModelNotFound: false,
      },
    ],
    Boat: [
      {
        vehicleId: null,
        isMakeNotFound: false,
        isModelNotFound: false,
        year: '2016',
        type: 'Runabout',
        boatLength: {
          boatLengthFeet: 24,
          boatLengthInches: 3,
        },
        hin: 'ALSMS291F585',
        boatWeight: 4430,
        eligibleDiscounts: [],
        totalHorsepower: '300',
        totalManufacturersSuggestedRetailPrice: 85650,
        totalMarketValue: 53000,
        vehicleType: 'boat',
        make: 'Regal',
        model: '24 RX FasDeck Volvo Penta',
        estimatedMarketValue: 53037,
        fuelType: 'Gasoline',
        hullType: 'Fiberglass',
        motorType: 'Inboard',
        numOfMotors: '1',
        topSpeed: 25,
        garageLocation: {
          country: 'US',
          state: 'OH',
          addressLine1: '12195 EMMA LN',
          city: 'CLEVELAND',
          postalCode: '44130-2275',
          street: '12195 EMMA LN, CLEVELAND, OH, 44130-2275',
        },
        informationalMessages: [
          {
            errors: [],
            warnings: [
              {
                message: 'Field MotorDetails should not be set and is ignored.',
              },
            ],
          },
        ],
        annualMiles: null,
        selected: true,
        productType: 'Boat',
        additionalInformation: {
          isStoredInLockedGarage: true,
          isCoOwned: false,
          numberOfCoOwners: null,
          registrationState: null,
        },
        purchasedNewDate: null,
        additionalInterests: [],
        costNew: null,
        motorDetails: [],
        hasTrailer: false,
        isPriorDamaged: false,
        motorSerialNumber: null,
      },
      {
        vehicleId: null,
        isMakeNotFound: false,
        isModelNotFound: false,
        year: '2021',
        type: 'Utility',
        boatLength: {
          boatLengthFeet: 24,
          boatLengthInches: 3,
        },
        hin: 'PEA520141073',
        boatWeight: 2700,
        eligibleDiscounts: [],
        totalHorsepower: '175',
        totalManufacturersSuggestedRetailPrice: 61505,
        totalMarketValue: 58000,
        vehicleType: 'boat',
        make: 'Allied Boats',
        model: 'Allied Warhawk P21',
        estimatedMarketValue: 41654,
        fuelType: 'Gasoline',
        hullType: 'Aluminum',
        motorDetails: [
          {
            isMakeNotFound: false,
            isModelNotFound: false,
            year: 2020,
            make: 'Mercury',
            model: 'FourStroke 175',
            horsepower: 175,
            nwmotorNumber: 1,
            serialNumber: '123456',
          },
        ],
        motorType: 'Outboard',
        numOfMotors: 1,
        topSpeed: 45,
        isPurchasedNew: false,
        garageLocation: {
          country: 'US',
          state: 'OH',
          addressLine1: '12195 EMMA LN',
          city: 'CLEVELAND',
          postalCode: '44130-2275',
          street: '12195 EMMA LN, CLEVELAND, OH, 44130-2275',
        },
        informationalMessages: [
          {
            errors: [],
            warnings: [
              {
                message:
                  'Field MotorDetails[0].SerialNumber should not be set and is ignored.',
              },
              {
                message: 'Field MotorDetails should not be set and is ignored.',
              },
            ],
          },
        ],
        annualMiles: null,
        selected: true,
        productType: 'Boat',
        additionalInformation: {
          isStoredInLockedGarage: true,
          isCoOwned: false,
          numberOfCoOwners: null,
          registrationState: null,
        },
        previouslyOwned: true,
        purchasedNewDate: null,
        additionalInterests: [],
        costNew: null,
        hasTrailer: false,
        isPriorDamaged: false,
        motorSerialNumber: null,
      },
    ],
    RV: [
      {
        vehicleId: null,
        isMakeNotFound: false,
        isModelNotFound: false,
        vehicleLength: '22',
        eligibleDiscounts: [
          {
            name: 'Automatic Tire Pressure Monitoring System',
            selectedOptionValue: 'false',
            eligibleDiscountId: 'AutomaticTirePressure',
            description: 'Automatic Tire Pressure Monitoring Discount',
          },
        ],
        subType: 'ClassB',
        primaryUse: 'PleasureLT30Days',
        vehicleType: 'Motorhome',
        additionalInformation: {
          isStoredInLockedGarage: true,
          isCoOwned: false,
          numberOfCoOwners: null,
          registrationState: 'OH',
        },
        estimatedMarketValue: 69201,
        marketValue: 69000,
        purchasedDate: '2022-02-02',
        weight: 8550,
        year: '2016',
        garageLocation: {
          country: 'US',
          state: 'OH',
          addressLine1: '12195 EMMA LN',
          city: 'CLEVELAND',
          postalCode: '44130-2275',
          street: '12195 EMMA LN, CLEVELAND, OH, 44130-2275',
        },
        make: 'SPORTSMOBILE',
        model:
          'SPORTSMOBILE CLASS B MOTORHOMES EXTENDED BODY SERI_E116S SPRINTER EB 2.1L 4 CYL. DSL',
        informationalMessages: [
          {
            errors: [],
            warnings: [
              {
                message: 'Field Weight should not be set and is ignored.',
              },
              {
                message:
                  'Field VehicleLength should not be set and is ignored.',
              },
            ],
          },
        ],
        annualMiles: null,
        selected: true,
        productType: 'RV',
        auxiliaryLights: null,
        vin: '1F65F5DY1E0A04974',
        milesOneWay: null,
        daysCommutingPerWeek: null,
        purchasedNewDate: null,
        additionalInterests: [],
        costNew: null,
        motorDetails: [],
        hasTrailer: false,
        isPriorDamaged: false,
        safetyDevice: null,
        hasFiberglassShell: null,
        hasTirePressureMonitoring: 'false',
      },
      {
        vehicleId: null,
        isMakeNotFound: false,
        isModelNotFound: false,
        vehicleLength: '39',
        eligibleDiscounts: [
          {
            name: 'Automatic Tire Pressure Monitoring System',
            selectedOptionValue: 'false',
            eligibleDiscountId: 'AutomaticTirePressure',
            description: 'Automatic Tire Pressure Monitoring Discount',
          },
        ],
        subType: 'ClassC',
        primaryUse: 'PleasureLT30Days',
        vehicleType: 'Motorhome',
        additionalInformation: {
          isStoredInLockedGarage: true,
          isCoOwned: false,
          numberOfCoOwners: null,
          registrationState: 'OH',
        },
        estimatedMarketValue: 233727,
        marketValue: 233000,
        purchasedDate: '2022-02-02',
        weight: 31000,
        year: '2021',
        vin: '1F66F5DN1L0A15500',
        garageLocation: {
          country: 'US',
          state: 'OH',
          addressLine1: '12195 EMMA LN',
          city: 'CLEVELAND',
          postalCode: '44130-2275',
          street: '12195 EMMA LN, CLEVELAND, OH, 44130-2275',
        },
        make: 'ENTEGRA COACH',
        model: 'ACCOLADE CLASS C MOTORHOMES',
        informationalMessages: [
          {
            errors: [],
            warnings: [
              {
                message: 'Field Weight should not be set and is ignored.',
              },
              {
                message:
                  'Field VehicleLength should not be set and is ignored.',
              },
            ],
          },
        ],
        annualMiles: null,
        selected: true,
        productType: 'RV',
        auxiliaryLights: null,
        milesOneWay: null,
        daysCommutingPerWeek: null,
        purchasedNewDate: null,
        additionalInterests: [],
        costNew: null,
        motorDetails: [],
        hasTrailer: false,
        isPriorDamaged: false,
        safetyDevice: null,
        hasFiberglassShell: null,
        hasTirePressureMonitoring: 'false',
      },
    ],
    Homeowner: [],
    Condominium: [],
    Tenant: [],
    PersonalUmbrella: [],
    DwellingFire: [],
    TermLife: [],
    Life: [],
    Pet: [],
  },
  currentCarrier: {
    PersonalAuto: {
      currentCarrierName: '21stCentury',
      coverageLapse: '0Days',
      currentBodilyInjuryLimit: 'GE100_300',
      currentCarrierTerms: 6,
      reasonForCoverageLapse: null,
      productType: 'PersonalAuto',
      isNationwideWinBack: false,
      informationalMessages: [],
    },
    MSA: {
      currentCarrierName: 'AllOtherSelectRisk',
      coverageLapse: '0DaysLapseInPrior6Months',
      currentBodilyInjuryLimit: null,
      currentCarrierTerms: 6,
      reasonForCoverageLapse: null,
      productType: 'MSA',
    },
    Boat: {
      currentCarrierName: 'Allied',
      coverageLapse: '0DaysLapseInPrior6Months',
      currentBodilyInjuryLimit: null,
      currentCarrierTerms: 6,
      reasonForCoverageLapse: null,
      productType: 'Boat',
    },
    RV: {
      currentCarrierName: 'Ambassador',
      coverageLapse: '0DaysLapseInPrior6Months',
      currentBodilyInjuryLimit: null,
      currentCarrierTerms: 6,
      reasonForCoverageLapse: null,
      productType: 'RV',
    },
    Homeowner: {},
    Condominium: {},
    Tenant: {},
    PersonalUmbrella: {},
    DwellingFire: {},
    TermLife: {},
    Life: {},
    Pet: {},
  },
  coveredLocation: {
    datePurchased: '2020-08-01',
    unitsInBuilding: 1,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
