import {
  VehicleLenderType,
  VehicleModel,
} from '@core/models/views/vehicle.model';
import {
  VehicleAdditionalInterestEntity,
  VehicleCostNewEntity,
  VehicleEntity,
} from '@app/core/store/entities/vehicle/vehicle.entity';
import { vehicleDiscountOptions } from '@app/shared/constants/app-constants';
import { DateUtils } from '../../date.utils';
import { Nullable } from '../../type.utils';
import { GeneralUtils } from '@shared/utils/general.utils';
import { AddressEntity } from '@core/store/entities/address/address.entity';
import { EligibleDiscountsEntity } from '@core/store/entities/eligible-discounts/eligible-discounts.entity';

export class VehicleEntityFromFormBuilder {
  static generateVehicleEntityFromForm(
    form: Partial<VehicleModel>
  ): Partial<VehicleEntity> {
    return {
      vehicleId: form.vehicleId,
      prefillId: form.prefillId,
      year: form.year,
      make: form.make,
      model: form.model,
      series: form.series,
      bodyType: form.bodyType,
      bodyStyle: form.bodyStyle,
      vin: form.vin,
      hin: form.hin,
      primaryUse: form.primaryUse,
      odometerReading: form.odometerReading,
      annualMiles: form.annualMiles,
      milesOneWay: form.milesOneWay,
      carPool: form.carPool,
      daysCommutingPerWeek: form.daysCommutingPerWeek,
      isRegisteredInState: form.isRegisteredInState,
      passiveRestraint: form.passiveRestraint || undefined,
      antiLockBrakes: form.antiLockBrakes,
      selected: form.selected,
      vehicleSubType: form.vehicleSubType,
      isPurchasedNew: form.isPurchasedNew,
      previouslyOwned: this.negativeIfBoolean(form.isPurchasedNew),
      purchasedNewDate: form.isPurchasedNew ? form.purchasedNewDate : null,
      registeredOwners: form.registeredOwners,
      additionalInterests: this.additionalInterestsFromForm(form),
      garageLocation: this.garageLocationFromForm(form),
      eligibleDiscounts: this.getEligibleDiscountsFromForm(form),
      costNew: this.getCostNew(form),
      customizationAmount: +(form.customizationAmount || 0),
      customizationDetails: form.customizationDetails,
      hasPriorDamage: form.hasPriorDamage,
      damageLocation: form.damageLocation,
      additionalInformation: this.getAdditionalInformation(form),
      vehicleType: form.vehicleType,
      cubicCentimeters: form.cubicCentimeters,
      productType: form.productType,
      subTypeClass: form.subTypeClass,
      subType: form.subType,
      marketValue: form.marketValue,
      value: form.value,
      horsepower: form.horsepower,
      auxiliaryLights: form.auxiliaryLights,
      agreedValue: form.agreedValue,
      hullType: form.hullType,
      motorType: form.motorType,
      fuelType: form.fuelType,
      boatLength: form.boatLength,
      numOfMotors: form.numOfMotors,
      motorDetails: form.motorDetails || [],
      topSpeed: form.topSpeed,
      vehicleLength: form.vehicleLength,
      boatWeight: form.boatWeight,
      weight: form.weight,
      totalHorsepower: form.totalHorsepower,
      purchasedDate: form.purchasedDate,
      estimatedMarketValue: form.estimatedMarketValue,
      totalMarketValue: form.totalMarketValue,
      hasTrailer: !!form.trailer?.year,
      trailer: form.trailer?.year ? form.trailer : undefined,
      isVehicleCoOwned: false,
      type: form.type,
      modelNumber: form.modelNumber,
      enclosedToTransportVehicles: form.enclosedToTransportVehicles,
      safetyDevice: form.safetyDevice || undefined,
      hasFiberglassShell: form.hasFiberglassShell || undefined,
      hasTirePressureMonitoring: form.hasTirePressureMonitoring || undefined,
      msrp: form.msrp,
      totalManufacturersSuggestedRetailPrice:
        form.totalManufacturersSuggestedRetailPrice,
      controlNumber: form.controlNumber,
      serialNumber: form.serialNumber,
      motorSerialNumber: form.motorSerialNumber,
      isMakeNotFound: form.isMakeNotFound,
      isModelNotFound: form.isModelNotFound,
    };
  }

  static negativeIfBoolean(
    input: boolean | null | undefined
  ): boolean | undefined {
    if (typeof input !== 'boolean') {
      return undefined;
    }
    return !input;
  }

  static getCostNew(
    form: Partial<VehicleModel>
  ): Nullable<VehicleCostNewEntity> {
    if (form.costNew) {
      return {
        amount: form.costNew,
        currency: 'USD',
      };
    }
    return null;
  }

  private static getEligibleDiscountsFromForm(
    form: Partial<VehicleModel>
  ): EligibleDiscountsEntity[] {
    const features = [];
    if (form.vehicleFeatures) {
      for (const feature of form.vehicleFeatures) {
        features.push(feature);
      }
    }
    if (
      form.options?.antiTheftDevicesVisible &&
      !features.includes('AntiTheft')
    ) {
      features.push('AntiTheft');
    }

    let returnEligibleDiscounts = features
      .map((option) => {
        const discountOption = { ...(vehicleDiscountOptions[option] || {}) };

        if (option === 'AntiTheft' && form.antiTheftDevices) {
          const existingFormDiscount = form.eligibleDiscounts?.find(
            (discount) => discount.eligibleDiscountId === option
          );
          discountOption.availableOptions =
            discountOption.availableOptions?.map((opt) => ({
              ...opt,
              isMandatory: Object.values(
                form.antiTheftDevices as any
              )?.includes(String(opt.optionValue)),
            }));

          discountOption.qualifyingInformation = {
            ...(existingFormDiscount?.qualifyingInformation ||
              discountOption.qualifyingInformation ||
              {}),
            selectedAntiTheft: form.antiTheftDevices,
          };
        }
        return discountOption;
      })
      .filter((v) => v && v.eligibleDiscountId);

    if (form.passiveRestraint !== undefined && form.passiveRestraint !== null) {
      const index = returnEligibleDiscounts.findIndex((discount) => {
        return discount.eligibleDiscountId === 'PassiveRestraint';
      });
      returnEligibleDiscounts.splice(
        index === -1 ? returnEligibleDiscounts.length : index,
        1,
        {
          ...vehicleDiscountOptions.PassiveRestraint,
          selectedOptionValue: form.passiveRestraint,
        }
      );
    }

    if (form.safetyDevice !== undefined) {
      const safetyDiscount = vehicleDiscountOptions['SafetyDevice'];
      returnEligibleDiscounts.push({
        ...safetyDiscount,
        selectedOptionValue: form.safetyDevice,
      });
    }

    if (form.hasFiberglassShell !== undefined) {
      const safetyDiscount = vehicleDiscountOptions['FiberglassConstruction'];
      returnEligibleDiscounts.push({
        ...safetyDiscount,
        selectedOptionValue: form.hasFiberglassShell,
      });
    }

    if (form.hasTirePressureMonitoring !== undefined) {
      const safetyDiscount = vehicleDiscountOptions['AutomaticTirePressure'];
      returnEligibleDiscounts.push({
        ...safetyDiscount,
        selectedOptionValue: form.hasTirePressureMonitoring,
      });
    }

    if (form.eligibleDiscounts) {
      returnEligibleDiscounts = [
        ...form.eligibleDiscounts.filter((d) => {
          return !returnEligibleDiscounts.find(
            (dd) => dd.eligibleDiscountId === d.eligibleDiscountId
          );
        }),
        ...returnEligibleDiscounts,
      ];
    }

    const smartMilesIndex = returnEligibleDiscounts.findIndex(
      (e) => e.eligibleDiscountId === 'SmartMiles'
    );
    if (smartMilesIndex >= 0) {
      const smartMilesDiscount = {
        ...returnEligibleDiscounts[smartMilesIndex],
        qualifyingInformation: {
          ...returnEligibleDiscounts[smartMilesIndex].qualifyingInformation,
          annualMiles: (form.annualMiles || 0).toString(),
        },
      };
      returnEligibleDiscounts[smartMilesIndex] = smartMilesDiscount;
    }

    return returnEligibleDiscounts;
  }

  private static garageLocationFromForm(
    form: Partial<VehicleModel>
  ): AddressEntity {
    const entity = {
      ...form.garageLocation,
    };

    return entity as AddressEntity;
  }

  private static additionalInterestsFromForm(
    form: Partial<VehicleModel>
  ): VehicleAdditionalInterestEntity[] | undefined {
    // We can add more products as the app onboards more to the new Vehicle Additional Interests Component,
    // once all vehicle products are onboarded, we can remove this function and use form.additionalInterests
    if (form.productType === 'PersonalAuto') {
      return form.additionalInterests || [];
    }

    // Old logic used by Lender Info Component
    const output: VehicleAdditionalInterestEntity[] = [];
    switch (form.lender) {
      case VehicleLenderType.None:
        break;
      case VehicleLenderType.Loan:
        if (form.lenderInfo?.companyName) {
          output.push({
            additionalInterestType: 'LIEN',
            companyName: form.lenderInfo?.companyName,
            expirationDate:
              DateUtils.formatDateToDSM(
                form.lenderInfo?.loanExpiration || ''
              ) || null,
            address: {
              addressLine1: form?.lenderInfo?.address?.addressLine1 || '',
              addressLine2: form.lenderInfo?.address?.addressLine2 || '',
              city: form.lenderInfo?.address?.city || '',
              state: form.lenderInfo?.address?.state || '',
              postalCode: form.lenderInfo?.address?.postalCode || '',
            },
          });
        } else {
          output.push({
            additionalInterestType: 'LIEN',
          });
        }
        break;
      case VehicleLenderType.Lease:
        if (form.lenderInfo?.companyName) {
          output.push({
            additionalInterestType: 'LESSOR',
            companyName: form.lenderInfo?.companyName,
            expirationDate:
              DateUtils.formatDateToDSM(
                form.lenderInfo?.loanExpiration || ''
              ) || null,
            address: {
              addressLine1: form.lenderInfo?.address?.addressLine1 || '',
              addressLine2: form.lenderInfo?.address?.addressLine2 || '',
              city: form.lenderInfo?.address?.city || '',
              state: form.lenderInfo?.address?.state || '',
              postalCode: form.lenderInfo?.address?.postalCode || '',
            },
          });
        } else {
          output.push({
            additionalInterestType: 'LESSOR',
          });
        }
        break;
    }
    return output;
  }

  private static getAdditionalInformation(form: Partial<VehicleModel>) {
    const additionalInfo = form.additionalInformation;
    if (additionalInfo) {
      return {
        isStoredInLockedGarage: GeneralUtils.parseBooleanFromString(
          additionalInfo.isStoredInLockedGarage
        ),
        isCoOwned: additionalInfo.isCoOwned,
        numberOfCoOwners: additionalInfo?.numberOfCoOwners,
        isRebuiltSalvagedFrameReplaced: GeneralUtils.parseBooleanFromString(
          additionalInfo?.isRebuiltSalvagedFrameReplaced
        ),
        specialHazard: additionalInfo?.specialHazard,
        hasVehicleBeenConvertedToTrike: GeneralUtils.parseBooleanFromString(
          additionalInfo?.hasVehicleBeenConvertedToTrike
        ),
        isVehicleBuiltByFirm: GeneralUtils.parseBooleanFromString(
          additionalInfo?.isVehicleBuiltByFirm
        ),
        isVehicleRegisteredAsHistorical: GeneralUtils.parseBooleanFromString(
          additionalInfo?.isVehicleRegisteredAsHistorical
        ),
        registrationState: additionalInfo?.registrationState,
      };
    }
    return undefined;
  }
}
