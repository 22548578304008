<div class="product-task-list-container">
  <div ngbAccordion>
    <ng-container *ngFor="let groups of tasks; let i = index">
      <div
        ngbAccordionItem="task-panel-{{ i }}"
        class="task-panel"
        *ngFor="let group of groups"
        id="task-panel-{{ i }}"
        #item="ngbAccordionItem"
      >
        <div ngbAccordionHeader class="custom-header">
          <div class="bolt-container-fluid">
            <div class="bolt-row bolt-align-items-center">
              <div class="bolt-col-sm-12 bolt-col-md-6">
                <button
                  ngbAccordionButton
                  class="btn btn-link task-btn-link container-fluid text-left pl-0"
                >
                  <div class="flex-center">
                    <ng-container [ngSwitch]="group.icon">
                      <bolt-icon
                        *ngSwitchCase="'exclamation-triangle-filled'"
                        size="sm"
                        [name]="group.icon"
                        color="theme-warning"
                        class="inline-icon"
                      ></bolt-icon>
                      <bolt-icon
                        *ngSwitchCase="'exclamation-circle-filled'"
                        size="sm"
                        [name]="group.icon"
                        color="theme-info"
                        class="inline-icon"
                      ></bolt-icon>
                      <bolt-icon
                        *ngSwitchCase="'info-circle-filled'"
                        size="sm"
                        [name]="group.icon"
                        color="medium-blue"
                        class="inline-icon"
                      ></bolt-icon>
                    </ng-container>
                    <h4 class="task-category">
                      {{ group.title }}
                    </h4>
                    <div class="bolt-space-right-sm">
                      <h4
                        class="task-amount"
                        [ngPlural]="group.taskList.length"
                      >
                        <ng-template ngPluralCase="=1">
                          ({{ group.taskList.length }}&nbsp;task)
                        </ng-template>
                        <ng-template ngPluralCase="other">
                          ({{ group.taskList.length }}&nbsp;tasks)
                        </ng-template>
                      </h4>
                    </div>
                    <bolt-icon
                      size="sm"
                      color="charcoal"
                      name="chevron-down"
                      [attr.name]="
                        item.collapsed ? 'chevron-down' : 'chevron-up'
                      "
                      class="task-collapse-icon"
                    ></bolt-icon>
                  </div>
                </button>
              </div>
              <div class="bolt-col-sm-12 bolt-col-md-6">
                <div class="flex-center bolt-space-left-md right-side-row">
                  <div class="outer-for-vert-line">
                    <ng-container [ngSwitch]="group.buttonType">
                      <bolt-button
                        *ngSwitchCase="'info-button'"
                        size="sm"
                        (click)="getDispatchGroupTaskAction(group)"
                        [name]="group.icon"
                        [attr.id]="'tlc-task-info-btn'"
                        type="primary"
                        class="task-button"
                        >{{ getGroupTaskActionLabel(group) | titlecase }}
                      </bolt-button>
                      <bolt-button
                        *ngSwitchCase="'warning-button'"
                        size="sm"
                        (click)="getDispatchGroupTaskAction(group)"
                        [name]="group.icon"
                        [attr.id]="'tlc-task-warning-btn-' + group.page"
                        type="primary"
                        class="task-button"
                        >{{ getGroupTaskActionLabel(group) | titlecase }}
                      </bolt-button>
                      <bolt-button
                        *ngSwitchCase="'error-button'"
                        size="sm"
                        (click)="getDispatchGroupTaskAction(group)"
                        [name]="group.icon"
                        [attr.id]="'tlc-group-error-btn'"
                        type="primary"
                        class="task-button"
                        >{{ getGroupTaskActionLabel(group) | titlecase }}
                      </bolt-button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="bolt-container-fluid">
                <ng-container
                  *ngFor="let task of group.taskList; let t = index"
                >
                  <div
                    class="bolt-row bolt-align-items-center bolt-justify-content-between bolt-space-bottom-md"
                  >
                    <div class="bolt-col-sm-12 bolt-col-md">
                      <div class="task-message">
                        <ul>
                          <li>
                            <span [innerHTML]="getTaskMessage(task)"></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="bolt-col-sm-12 bolt-col-md-auto">
                      <div class="align-top" *ngIf="task.resolve">
                        <div
                          class="right-side-row flex-center bolt-space-left-md"
                        >
                          <div class="outer-for-vert-line">
                            <ng-container [ngSwitch]="group.buttonType">
                              <bolt-button
                                *ngSwitchCase="'info-button'"
                                size="sm"
                                [attr.id]="'tlc-task-sec-info-btn'"
                                class="task-button"
                                (click)="getDispatchTaskAction(group, task)"
                                >{{ getTaskActionLabel(group, task) }}
                              </bolt-button>
                              <bolt-button
                                *ngSwitchCase="'warning-button'"
                                size="sm"
                                [attr.id]="'tlc-task-sec-warning-info-btn'"
                                class="task-button"
                                (click)="getDispatchTaskAction(group, task)"
                                >{{ getTaskActionLabel(group, task) }}
                              </bolt-button>
                              <bolt-button
                                *ngSwitchCase="'error-button'"
                                size="sm"
                                [attr.id]="'tlc-task-sec-error-info-btn'"
                                class="task-button"
                                (click)="getDispatchTaskAction(group, task)"
                                >{{ getTaskActionLabel(group, task) }}
                              </bolt-button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
