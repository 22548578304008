import { CurrentCarrierFormModel } from '@core/interfaces/interfaces';
import { VehicleModel } from '@core/models/views/vehicle.model';
import { ExpectedActions } from '@core/services/action-aggregator.service';
import {
  VehicleActions,
  CurrentCarrierActions,
  QuoteActions,
  DriverVehiclePrefillActions,
  EligibleDiscountsActions,
} from '@app/core/store/actions';
import { CurrentCarrierEntity } from '@app/core/store/entities/current-carrier/current-carrier.reducer';
import {
  PowersportsPageSubmission,
  VehicleEntity,
  VehiclePageSubmission,
} from '@app/core/store/entities/vehicle/vehicle.entity';
import { EligibleDiscountsEntity } from '@core/store/entities/eligible-discounts/eligible-discounts.entity';
import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { VehicleEntityFromFormBuilder } from '../builders/vehicles/vehicle-entity-from-form';
import { CurrentCarrierUtils } from '../current-carrier.utils';
import { Nullable } from '../type.utils';
import { VehicleUtils } from '../vehicle.utils';
import { PageUtils } from './page.utils';
import { StringUtils } from '@shared/utils/string.utils';
import { ProductType } from '../../../core/models/api/dsm-types';

export class VehiclePageUtils extends PageUtils {
  static handlePassiveRetraintDiscount(vehicle: VehicleEntity) {
    const unwantedDiscountIndex =
      vehicle.eligibleDiscounts?.findIndex(
        (discount) =>
          discount.eligibleDiscountId === 'PassiveRestraint' &&
          discount.selectedOptionValue === 'None'
      ) ?? -1;

    if (unwantedDiscountIndex >= 0) {
      vehicle.eligibleDiscounts?.splice(unwantedDiscountIndex, 1);
    }
  }

  static update(
    updated: VehicleEntity[],
    nextActions: Action[],
    expectedActions: ExpectedActions
  ): void {
    updated.forEach((updatedVehicle) => {
      const correlationId = StringUtils.generateUuid();
      nextActions.push(
        VehicleActions.updateVehicle({
          payload: updatedVehicle,
          correlationId,
        })
      );

      expectedActions.push(
        this.getExpectedActions(
          VehicleActions.updateVehicleSuccess.type,
          VehicleActions.updateVehicleError.type,
          correlationId
        )
      );
    });
  }

  static create(
    created: VehicleEntity[],
    nextActions: Action[],
    expectedActions: ExpectedActions
  ): void {
    created.forEach((newVehicle) => {
      const correlationId = StringUtils.generateUuid();
      nextActions.push(
        VehicleActions.addVehicle({
          payload: newVehicle,
          correlationId,
        })
      );

      expectedActions.push(
        this.getExpectedActions(
          VehicleActions.addVehicleSuccess.type,
          VehicleActions.addVehicleError.type,
          correlationId
        )
      );
    });
  }

  static delete(
    deleted: VehicleEntity[],
    nextActions: Action[],
    expectedActions: ExpectedActions
  ): void {
    deleted.forEach((deletedVehicle) => {
      const correlationId = StringUtils.generateUuid();

      if (VehicleUtils.VehicleIsSaved(deletedVehicle.vehicleId)) {
        nextActions.push(
          VehicleActions.deleteVehicle({
            payload: deletedVehicle,
            correlationId,
          })
        );

        if (deletedVehicle.prefillId) {
          this.updatePrefillVehicle(deletedVehicle, nextActions);
        }

        expectedActions.push(
          this.getExpectedActions(
            VehicleActions.deleteVehicleSuccess.type,
            VehicleActions.deleteVehicleError.type,
            correlationId
          )
        );
      } else {
        nextActions.push(
          VehicleActions.deleteTempVehicle({
            payload: deletedVehicle.vehicleId?.toString(),
          })
        );
      }
    });
  }

  static currentCarrier(
    currentCarrier: CurrentCarrierEntity,
    nextActions: Action[],
    expectedActions: ExpectedActions
  ): void {
    const correlationId = StringUtils.generateUuid();
    nextActions.push(
      CurrentCarrierActions.updateCurrentCarrier({
        payload: currentCarrier,
        correlationId,
      })
    );
    expectedActions.push(
      this.getExpectedActions(
        CurrentCarrierActions.updateCurrentCarrierSuccess.type,
        CurrentCarrierActions.updateCurrentCarrierFail.type,
        correlationId
      )
    );
  }

  static policyLineDiscount(
    policyLineDiscount: Nullable<EligibleDiscountsEntity>,
    nextActions: Action[]
  ): void {
    if (policyLineDiscount) {
      nextActions.push(
        EligibleDiscountsActions.updatePolicyLine({
          entity: policyLineDiscount,
        })
      );
    }
  }

  static updateQuote(
    productType: ProductType,
    nextActions: Action[],
    expectedActions: ExpectedActions
  ): void {
    const correlationId = StringUtils.generateUuid();
    nextActions.push(
      QuoteActions.updateQuote({
        productType,
        correlationId,
      })
    );

    expectedActions.push(
      this.getExpectedActions(
        QuoteActions.updateQuoteSuccess.type,
        QuoteActions.updateQuoteFail.type,
        correlationId
      )
    );
  }

  static createVehiclePageSubmission(
    vehicles: Nullable<VehicleModel[]>,
    currentCarrier: Nullable<CurrentCarrierFormModel>
  ): VehiclePageSubmission {
    const newVehicles: VehicleEntity[] = [];
    const updatedVehicles: VehicleEntity[] = [];
    const deletedVehicles: VehicleEntity[] = [];

    VehiclePageUtils.mapVehiclesForSubmission(
      vehicles,
      newVehicles,
      updatedVehicles,
      deletedVehicles
    );

    const submission: VehiclePageSubmission = {
      created: newVehicles,
      updated: updatedVehicles,
      deleted: deletedVehicles,
      currentCarrier: null,
    };
    if (currentCarrier) {
      submission.currentCarrier =
        CurrentCarrierUtils.currentCarrierEntityFromForm(currentCarrier);
    }
    return submission;
  }

  static CreateMsaPageSubmission(
    vehicles: Nullable<VehicleModel[]>,
    currentCarrier: Nullable<CurrentCarrierFormModel>
  ): PowersportsPageSubmission {
    const newVehicles: VehicleEntity[] = [];
    const updatedVehicles: VehicleEntity[] = [];
    const deletedVehicles: VehicleEntity[] = [];

    VehiclePageUtils.mapVehiclesForSubmission(
      vehicles,
      newVehicles,
      updatedVehicles,
      deletedVehicles
    );

    const submission: PowersportsPageSubmission = {
      created: newVehicles,
      updated: updatedVehicles,
      deleted: deletedVehicles,
      currentCarrier: null,
    };
    if (currentCarrier) {
      submission.currentCarrier =
        CurrentCarrierUtils.currentCarrierEntityFromForm(currentCarrier);
    }
    return submission;
  }

  static CreateRVPageSubmission(
    vehicles: Nullable<VehicleModel[]>,
    currentCarrier: Nullable<CurrentCarrierFormModel>
  ): PowersportsPageSubmission {
    const newVehicles: VehicleEntity[] = [];
    const updatedVehicles: VehicleEntity[] = [];
    const deletedVehicles: VehicleEntity[] = [];

    VehiclePageUtils.mapVehiclesForSubmission(
      vehicles,
      newVehicles,
      updatedVehicles,
      deletedVehicles
    );

    const submission: PowersportsPageSubmission = {
      created: newVehicles,
      updated: updatedVehicles,
      deleted: deletedVehicles,
      currentCarrier: null,
    };
    if (currentCarrier) {
      submission.currentCarrier =
        CurrentCarrierUtils.currentCarrierEntityFromForm(currentCarrier);
    }
    return submission;
  }

  static CreateBoatPageSubmission(
    vehicles: Nullable<VehicleModel[]>,
    currentCarrier: CurrentCarrierFormModel
  ): PowersportsPageSubmission {
    const newVehicles: VehicleEntity[] = [];
    const updatedVehicles: VehicleEntity[] = [];
    const deletedVehicles: VehicleEntity[] = [];

    VehiclePageUtils.mapVehiclesForSubmission(
      vehicles,
      newVehicles,
      updatedVehicles,
      deletedVehicles
    );

    return {
      created: newVehicles,
      updated: updatedVehicles,
      deleted: deletedVehicles,
      currentCarrier:
        CurrentCarrierUtils.currentCarrierEntityFromForm(currentCarrier),
    };
  }

  private static mapVehiclesForSubmission(
    vehicles: Nullable<VehicleModel[]>,
    newVehicles: VehicleEntity[],
    updatedVehicles: VehicleEntity[],
    deletedVehicles: VehicleEntity[]
  ): void {
    vehicles?.forEach((vehicle) => {
      const vehicleEntity =
        VehicleEntityFromFormBuilder.generateVehicleEntityFromForm(vehicle);

      this.handlePassiveRetraintDiscount(vehicleEntity as VehicleEntity);
      const saved = VehicleUtils.VehicleIsSaved(vehicleEntity.vehicleId || '');
      if (vehicleEntity.selected && !saved) {
        newVehicles.push(vehicleEntity as VehicleEntity);
      } else if (vehicleEntity.selected) {
        updatedVehicles.push(vehicleEntity as VehicleEntity);
      } else if (!vehicleEntity.selected && saved) {
        deletedVehicles.push(vehicleEntity as VehicleEntity);
      }
    });
  }

  private static updatePrefillVehicle(
    vehicle: VehicleEntity,
    nextActions: Action[]
  ): void {
    const updated: Update<VehicleEntity> = {
      id: vehicle.prefillId || '',
      changes: { vehicleId: 'PREFILL', selected: false },
    };
    nextActions.push(
      DriverVehiclePrefillActions.updatePrefillVehicle({
        payload: updated,
      })
    );
  }

  static ValidateVinEmpty(vin: string | undefined): string | undefined {
    if (!vin) {
      return undefined;
    }
    if (vin.length === 0) {
      return undefined;
    }
    return vin.trim();
  }
}
