import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonFormModule } from '@shared/modules/common-form.module';
import { AccountInfoContainerComponent } from './account-info-container.component';
import { AcountInfoModule } from '../account-info/account-info.module';

@NgModule({
  declarations: [AccountInfoContainerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, CommonFormModule, AcountInfoModule],
  exports: [AccountInfoContainerComponent],
})
export class AccountInfoContainerModule {}
