import { createAction, props } from '@ngrx/store';
import { DriverEntity } from '@core/store/entities/driver/driver.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { MemberEntity } from '../member/member.reducer';
import { DriverRequest } from '@core/models/api/request/driver-request.model';

export const upsertDriver = createAction(
  '[Driver] Add or Update Driver',
  props<{ payload: DriverEntity; correlationId?: string }>()
);

export const upsertDriverError = createAction(
  '[Driver] Add or Update Driver Error',
  props<{ error: ErrorModel; driverId: number; correlationId?: string; entityId?: string }>()
);

export const upsertDriverSuccess = createAction(
  '[Driver] Add or Update Driver Success',
  props<{ payload: MemberEntity; correlationId?: string }>()
);

export const deleteDriver = createAction(
  '[Driver] Delete Driver',
  props<{ payload: DriverEntity; correlationId?: string }>()
);

export const deleteDriverError = createAction(
  '[Driver] Delete Driver Error',
  props<{ error: ErrorModel; driverId: number; correlationId?: string }>()
);

export const deleteDriverSuccess = createAction(
  '[Driver] Delete Driver Success',
  props<{ request: DriverRequest; correlationId?: string }>()
);
