<button
  [class.nwx-tile]="isAvailable"
  [class.nwx-tile-disabled]="!isAvailable"
  class="text-decoration-none"
  [class.selected]="
    quoteId ? isQuoteIdSelected(quoteId) : isProductSelected(product.type)
  "
  [class.tile-error]="setShowError()"
  [class.has-footer]="!product.isDsmActive"
  [class.property]="isPropertyProduct(product.type)"
  (click)="isAvailable ? onProductTileClicked(product.type, $event) : null"
  attr.id="{{ product.type }}-product-tile"
>
  <div class="tile-content">
    <div class="tile-img-content">
      <img [src]="imageUrl" alt="{{ product.type }} icon" class="tile-img" />
    </div>
    <div class="tile-text-content">
      <span class="content-label">{{ name }}</span>
      <span class="content-subtext bolt-body-copy-sm">{{ helpText }}</span>
    </div>
    <div *ngIf="customContent" class="custom-content">
      <ng-content></ng-content>
    </div>
    <ng-container *ngIf="!isPropertyProduct(product.type)">
      <nwx-decorative-bolt-checkbox
        type="checkbox"
        [checked]="(quoteId ? isQuoteIdSelected(quoteId) : isProductSelected(product.type)) || false"
        [disabled]="!isAvailable"
      ></nwx-decorative-bolt-checkbox>
    </ng-container>
    <ng-container *ngIf="isPropertyProduct(product.type)">
      <nwx-decorative-bolt-checkbox
        type="radio"
        [checked]="(quoteId ? isQuoteIdSelected(quoteId) : isProductSelected(product.type)) || false"
        [disabled]="!isAvailable"
      ></nwx-decorative-bolt-checkbox>
    </ng-container>
  </div>
  <div class="tile-footer" *ngIf="!product.isDsmActive">
    <div class="bolt-container-fluid">
      <div class="bolt-row bolt-align-items-center bolt-justify-content-center">
        <div class="bolt-col-auto">
          <a href="#" (click)="pivotToPolicyCenter()">
            <bolt-icon
              class="little-icon"
              size="sm"
              color="black"
              name="external-link"
            ></bolt-icon>
            Complete this quote in PolicyCenter
          </a>
        </div>
      </div>
    </div>
  </div>
</button>

<div *ngIf="setShowError()" class="error">
  <bolt-icon name="exclamation-circle-filled" color="theme-error"></bolt-icon>
  <p class="bolt-body-copy-sm mb-0">
    <em>Not available individually</em>
  </p>
</div>
