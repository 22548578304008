import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, take } from 'rxjs';
import { PreBindDocumentsAdapter } from '../adapters/pre-bind-documents.adapter';
import {
  PreBindDocumentEmailRequest,
  PreBindDocumentRequest,
  PreBindDocumentUploadRequest,
} from '../models/api/request/pre-bind-document-request.model';
import {
  PreBindDocumentEmailResponse,
  PreBindDocumentUploadResponse,
} from '../models/api/response/pre-bind-document-response.model';
import { PreBindDocumentEntity } from '../models/entities/pre-bind-document.entity';
import * as fromSelectors from '@core/store/selectors/index';
import { updatePreBindDocument } from '@core/store/entities/pre-bind-documents/pre-bind-documents.action';
import { ProductType } from '@core/models/api/dsm-types';

@Injectable({
  providedIn: 'root',
})
export class PreBindDocumentsService {
  constructor(
    private preBindDocumentsAdapter: PreBindDocumentsAdapter,
    private store: Store
  ) {}

  getPreBindDocument(
    request: PreBindDocumentRequest,
    productType: ProductType
  ): Observable<PreBindDocumentEntity> {
    return this.preBindDocumentsAdapter
      .getPreBindDocument(request, productType)
      .pipe(
        take(1),
        map((document) => {
          this.store.dispatch(updatePreBindDocument({ document }));
          return document;
        })
      );
  }

  uploadDocuments(
    request: PreBindDocumentUploadRequest,
    accessToken: string,
    sessionId: string
  ): Observable<PreBindDocumentUploadResponse> {
    return this.preBindDocumentsAdapter.uploadDocuments(
      request,
      accessToken,
      sessionId
    );
  }

  sendEmail(
    request: PreBindDocumentEmailRequest,
    accessToken: string,
    sessionId: string
  ): Observable<PreBindDocumentEmailResponse> {
    return this.preBindDocumentsAdapter.email(request, accessToken, sessionId);
  }

  getPreBindDocumentUploadResponse(
    productType: ProductType
  ): Observable<PreBindDocumentUploadResponse> {
    return this.store.select(
      fromSelectors.PreBindDocumentSelectors.getPreBindDocumentsUploadResponse(
        productType
      )
    );
  }

  getPreBindDocumentUploaded(): Observable<boolean> {
    return this.store.select(
      fromSelectors.PreBindDocumentSelectors.getPreBindDocumentsUploaded
    );
  }

  getPreBindCallsIdle(): Observable<boolean> {
    return this.store.select(
      fromSelectors.PreBindDocumentSelectors.getPreBindCallsIdle
    );
  }
}
