import { Component, Input } from '@angular/core';

@Component({
  selector: 'nwx-passive-spinner',
  templateUrl: './passive-spinner.component.html',
  styleUrls: ['./passive-spinner.component.scss'],
})
export class PassiveSpinnerComponent {
  @Input() type = 'button';
  @Input() label = 'Saving...';
}
