import { Injectable } from '@angular/core';
import { DigitalIdCardsTextService } from '@core/services/digital-id-cards.text.service';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, mergeMap, switchMap, take } from 'rxjs/operators';
import * as fromDigitalIdCardsTextActions from '@core/store/entities/digital-id-cards-text/digital-id-cards-text.action';
import * as fromDigitalIdCardsTextSelectors from '@core/store/entities/digital-id-cards-text/digital-id-cards-text.selector';

@Injectable()
export class DigitalIdCardsTextEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private digitalIdCardsTextService: DigitalIdCardsTextService,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  sendDigitalAutoIdCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDigitalIdCardsTextActions.sendAutoIdCards),
      switchMap((action) =>
        this.store
          .select(
            fromDigitalIdCardsTextSelectors.buildDigitalIdCardsTextRequest(
              'PersonalAuto',
              action.mobileNumber
            )
          )
          .pipe(take(1))
      ),
      switchMap((request) =>
        this.digitalIdCardsTextService.sendText(request).pipe(
          mergeMap((response) => {
            return from([
              fromDigitalIdCardsTextActions.sendAutoIdCardsSuccess({
                payload: response,
              }),
            ]);
          }),
          catchError((error) => {
            const safeError = this.errorSanitizerService.sanitizeError(error, 'PersonalAuto');
            return of(
              fromDigitalIdCardsTextActions.sendAutoIdCardsFail({
                error: safeError,
              })
            );
          })
        )
      )
    )
  );
}
