import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nwx-smartride-data-sharing-consent-modal',
  templateUrl: './smartride-data-sharing-consent-modal.component.html',
  styleUrls: ['./smartride-data-sharing-consent-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartrideDataSharingConsentModalComponent {
  showPartners = false;
  constructor(public modal: NgbActiveModal) {}

  decline(): void {
    this.modal.close(false);
  }

  confirm(): void {
    this.modal.close(true);
  }

  showPartnersPanel(): void {
    this.showPartners = !this.showPartners;
  }
}
