import { Router } from '@angular/router';
import { UserModel } from '@core/store/entities/user/user.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CoreState } from '../store/reducers';
import { updateUser } from '../store/entities/user/user.action';
import { AppConfigService } from './app-config.service';
import * as fromSelectors from '@core/store/selectors/index';
import { EuaJwtModel } from '../store/entities/session/session.model';
import {
  getOverallFurthestQuoteStatus,
  getSelectedProducts,
} from '../store/entities/product/product.selectors';
import { map, take } from 'rxjs/operators';
import { getAccountId } from '../store/entities/account/account.selector';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private store: Store<CoreState>,
    private router: Router,
    private appConfigServe: AppConfigService,
    private window: Window
  ) {}

  getUser(): Observable<UserModel> {
    return this.store.select(fromSelectors.UserSelectors.getUser);
  }

  isNss(): Observable<boolean> {
    return this.store.select(fromSelectors.UserSelectors.isNssUser);
  }
  getUserName(): Observable<string> {
    return this.getUser().pipe(
      map((user) =>
        user ? `${user.firstName || ''} ${user.lastName || ''}` : ''
      )
    );
  }

  updateUser(user: Partial<UserModel>): void {
    this.store.dispatch(updateUser({ payload: user }));
  }

  populateUserWithJwt(jwtContents: EuaJwtModel): void {
    const user = {
      isAuthorized: true,
      realm: jwtContents.realm,
      identityMethod: jwtContents.identity_method,
      authMethod: jwtContents.auth_method,
      firstName: jwtContents.firstName,
      lastName: jwtContents.lastName,
      userId: jwtContents.userId,
      nwieId: jwtContents.nwieId,
      racfId: jwtContents.racfId,
    };
    this.updateUser(user);
  }

  logout(): void {
    const capture = this.capturePermanentStorage();
    this.window.localStorage.clear();
    this.window.sessionStorage.clear();
    this.restorePermanentStorage(capture);
    this.window.location.href =
      this.window.location.protocol + '//' + this.window.location.host;
  }

  private capturePermanentStorage(): any {
    return {
      closedAnnouncementModal: this.window.localStorage.getItem('closedAnnouncementModal'),
    };
  }

  private restorePermanentStorage(capture: any): void {
    if (capture.closedAnnouncementModal) {
      this.window.localStorage.setItem('closedAnnouncementModal', capture.closedAnnouncementModal);
    }
  }

  goToPolicyCenter(isIaUser: boolean): void {
    let pcHostName = '';
    const target = this.appConfigServe.config.targetEnv || '';
    if (isIaUser) {
      pcHostName = this.appConfigServe.config.pcExternalDeepLaunchUrl;
    } else {
      pcHostName = this.appConfigServe.config.pcInternalDeepLaunchUrl;
    }
    pcHostName = pcHostName.replace('<targetEnv>', target);

    this.store
      .select(getOverallFurthestQuoteStatus)
      .pipe(take(1))
      .subscribe((status) => {
        if (status === 'Issued') {
          this.store
            .select(getSelectedProducts)
            .pipe(take(1))
            .subscribe((products) => {
              if (products.length > 1) {
                const issuedProducts = products.filter(
                  (product) => product.quoteFurthestStatus === 'Issued'
                );
                if (issuedProducts.length === products.length) {
                  products.forEach((product) => {
                    this.window.open(
                      `${pcHostName}/pc/PolicyFile.do?PolicyNumber=${product.policyNumber}`,
                      '_blank'
                    );
                  });
                } else {
                  this.store
                    .select(getAccountId)
                    .pipe(take(1))
                    .subscribe((accountId) => {
                      this.window.location.href = `${pcHostName}/pc/AccountFile.do?AccountNumber=${accountId}`;
                    });
                }
              } else {
                this.window.location.href = `${pcHostName}/pc/PolicyFile.do?PolicyNumber=${products[0].policyNumber}`;
              }
            });
        } else {
          this.store
            .select(getAccountId)
            .pipe(take(1))
            .subscribe((accountId) => {
              if (accountId) {
                this.window.location.href = `${pcHostName}/pc/AccountFile.do?AccountNumber=${accountId}`;
              } else {
                this.window.location.href = `${pcHostName}/pc/PolicyCenter.do`;
              }
            });
        }
      });
  }
}
