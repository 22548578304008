import { createAction, props } from '@ngrx/store';

export const updateRatingState = createAction(
  '[Session] Update Rating State',
  props<{ payload: string }>()
);

export const updateAccessToken = createAction(
  '[Session] Update Access Token',
  props<{ payload: string }>()
);

export const setIsComprater = createAction(
  '[Session] Set isComprater',
  props<{ isComprater: boolean }>()
);

export const setTaskAsReviewed = createAction(
  '[Session] Set task as reviewed',
  props<{ key: string }>()
);

export const setHubLoaded = createAction('[Session] Set Hub Page Loaded');

/** Scope of this action is the entire Store, everything that might pertain to a specific quote.
 * (not just the "session" slice)
 */
export const clearSessionState = createAction('[Session] Clear');

export const setUserType = createAction(
  '[Session] Set User Type',
  props<{ userType: string }>()
);
