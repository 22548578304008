import { Injectable } from '@angular/core';
import { BillingService } from '@core/services/billing.service';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { ProductsSelectors } from '@core/store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, from } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
} from 'rxjs/operators';
import {
  setupAccountFail,
  setupAccountSuccess,
} from '../billing/billing.action';
import { ProductModel } from '../product/product.model';
import {
  createBillingAccount,
  createBillingAccountIfAbsent,
  setBillingAccountStatus,
} from './billing-account.action';
import { getBillingAccountForProductIds } from './billing-account.selector';

@Injectable()
export class BillingAccountEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private billingService: BillingService,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  createBillingAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createBillingAccount),
      mergeMap((action) =>
        combineLatest(
          action.payload.productIds.map((id) =>
            this.store.select(ProductsSelectors.getProduct(id))
          )
        ).pipe(
          take(1),
          map((products) => ({
            action,
            products: products as ProductModel[], // i promise it's not null
          }))
        )
      ),
      mergeMap(({ action, products }) => {
        return this.billingService
          .buildSetupAccountExperienceRequest(action.form, products)
          .pipe(
            take(1),
            map((request) => ({ request, products }))
          );
      }),
      concatMap(({ request, products }) =>
        this.billingService.setupAccount(request).pipe(
          switchMap((response) =>
            from([
              setBillingAccountStatus({
                productType: products[0].type,
                status: 'ok',
                accountNumber: response.accountNumber,
                receiptId: response.receiptId,
              }),
              setupAccountSuccess({ payload: response }),
            ])
          ),
          catchError((error) =>
            from([
              setBillingAccountStatus({
                productType: products[0].type,
                status: 'error',
              }),
              setupAccountFail({
                error: this.errorSanitizerService.sanitizeError(error),
              }),
            ])
          )
        )
      )
    )
  );

  createBillingAccountIfAbsent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createBillingAccountIfAbsent),
      switchMap(({ productTypes: productIds, form }) =>
        this.store
          .select(getBillingAccountForProductIds(productIds))
          .pipe(map((account) => ({ account, productIds, form })))
      ),
      filter(({ account }) => !account),
      map(({ productIds, form }) =>
        createBillingAccount({
          form,
          payload: {
            id: 0,
            status: 'pending',
            productIds,
          },
        })
      )
    )
  );
}
