import { ProductType } from '@core/models/api/dsm-types';
import { CoverageModel } from '@core/models/views/coverage.model';
import { createAction, props } from '@ngrx/store';

// export const addCoverages = createAction(
//   '[Forms] Add Coverages',
//   props<{ productId: ProductIds; payload: CoverageModel }>()
// );

export const addFormCoverage = createAction(
  '[Forms] Update Form Coverages',
  props<{ productType: ProductType; payload: CoverageModel }>()
);

export const updateCoverage = createAction(
  '[Forms] Update Form Coverage',
  props<{ productType: ProductType; payload: Partial<CoverageModel> }>()
);
