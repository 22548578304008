import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../error/error.model';
import { VehicleExposureEntity } from './vehicle-exposures.entity';

export const upsertVehicleExposure = createAction(
  '[Vehicle Exposures] Upsert Vehicle Exposure',
  props<{ payload: VehicleExposureEntity; correlationId?: string }>()
);

export const upsertVehicleExposureSuccess = createAction(
  '[Vehicle Exposures] Upsert Vehicle Exposure Success',
  props<{ payload: VehicleExposureEntity; correlationId?: string }>()
);

export const upsertVehicleExposureFail = createAction(
  '[Vehicle Exposures] Upsert Vehicle Exposure Fail',
  props<{
    error: ErrorModel;
    correlationId?: string;
    entityId?: string;
  }>()
);

export const deleteVehicleExposure = createAction(
  '[Vehicle Exposures] Delete Vehicle Exposure',
  props<{ payload: VehicleExposureEntity; correlationId?: string }>()
);

export const deleteVehicleExposureSuccess = createAction(
  '[Vehicle Exposures] Delete Vehicle Exposure Success',
  props<{
    vehicleExposureId: string;
    correlationId?: string;
  }>()
);

export const deleteVehicleExposureFail = createAction(
  '[Vehicle Exposures] Delete Vehicle Exposure Fail',
  props<{
    error: ErrorModel;
    correlationId?: string;
    entityId?: string;
  }>()
);

export const completelyRemoveVehicleExposure = createAction(
  '[Vehicle Exposures] Removed previously deleted exposure from deleted state',
  props<{
    vehicleExposureId: string;
    correlationId?: string;
  }>()
);
