import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ErrorModel } from "./error.model";
import { Observable } from "rxjs";
import { ErrorSelectors } from "@core/store/selectors";

@Injectable ({providedIn: "root"})
export class ErrorService{

    constructor(private store: Store){}

    getErrors():Observable < ErrorModel[] >{
        return this.store.select(ErrorSelectors.getErrors)
    }

}