import { Action, createReducer, on } from '@ngrx/store';
import { GetStartedFormModel } from '@app/get-started/components/get-started-form/get-started-form.model';
import { GetStartedActions } from '../../actions';

export type GetStartedState = { getStartedForm: Partial<GetStartedFormModel> };

const propertyReducer = createReducer<GetStartedState>(
  {
    getStartedForm: {},
  },
  on(GetStartedActions.updateGetStartedForm, (state, { payload }) => ({
    ...state,
    getStartedForm: { ...state.getStartedForm, ...payload },
  }))
);

export function reducer(
  state: GetStartedState | undefined,
  action: Action
): GetStartedState {
  return propertyReducer(state, action);
}
