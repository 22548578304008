import { Injectable } from '@angular/core';
import { BillingService } from '@core/services/billing.service';
import { Store } from '@ngrx/store';
import { Observable, catchError, filter, of, switchMap, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingAmfBankAccountsGuard {
  constructor(private billingService: BillingService, private store: Store) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.billingService.getAmfBankAccountsLoaded().pipe(
      tap((isLoaded) => {
        if (!isLoaded) {
          this.billingService.dispatchLoadAmfBankAccounts();
        }
      }),
      filter((isLoaded) => isLoaded),
      take(1)
    );
  }
}
