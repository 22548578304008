import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DistributedDocumentsResponse } from '@core/models/api/response/distributed-documents-response.model';
import { AppConfigService } from '@core/services/app-config.service';
import { Nullable } from '@shared/utils/type.utils';
import { SessionService } from '../services/session.service';
import { catchError, map, take, tap } from 'rxjs/operators';
import { LogService } from '../services/log.service';

@Injectable({
  providedIn: 'root',
})
export class DistributedDocumentsAdapter {
  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private log: LogService,
    private sessionService: SessionService
  ) {}

  documents(body: any): Observable<DistributedDocumentsResponse> {
    let accessToken;

    this.sessionService
      .getAccessToken()
      .pipe(take(1))
      .subscribe({
        next: (token: Nullable<string>) => {
          accessToken = token;
        },
        error: () => {},
        complete: () => {},
      });

    const url = this.appConfigService.config.distributedDocumentsUrl;
    const correlationId = this.log.logApiRequest('POST', url, 'documents');
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('client_id', this.appConfigService.config.apiKey)
      .set('Authorization', `Bearer ${accessToken}`)
      .set(
        'TargetEnvironment',
        this.appConfigService.config.distributedDocumentsTargetEnv
      );

    return this.http
      .post<DistributedDocumentsResponse>(url, body, { headers })
      .pipe(
        tap((response) =>
          this.log.logApiResponse(correlationId, 200, {
            ...response,
            message: '(not logged)',
            fileOutput: `(${(response.fileOutput.length / 1000).toFixed(
              1
            )} kb)`,
          })
        ),
        map((response) => response),
        catchError((error) => this.log.logApiError(correlationId, error))
      );
  }
}
