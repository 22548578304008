import { createAction, props } from "@ngrx/store";
import { MultiProductDiscountsFormModel } from "./multi-product-discounts-form.reducer";

export const updateMultiProductDiscountsForm = createAction(
  '[MPD Form] Update',
  props<{ model: MultiProductDiscountsFormModel }>()
);

export const updatePartialMultiProductDiscountsForm = createAction(
  '[MPD Form] Update Partial',
  props<{ model: Partial<MultiProductDiscountsFormModel> }>()
);
