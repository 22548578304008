import { ObjectValidator } from '@core/helper/object-validator';
import {
  BaseEntity,
  BaseEntityOptionalFields,
  BaseEntitySanitizerFields,
} from '@core/models/entities/base.entity';
import {
  AddressEntity,
  sanitizeAddressEntity,
} from '@entities/address/address.entity';
import { ProductModel } from '@entities/product/product.model';
import { UnderlyingPolicyEntity } from '@entities/underlying-policy/underlying-policy.entity';
import { Nullable } from '@shared/utils/type.utils';

export interface ExposureModel {
  underlyingPolicyNumber: string;
  excludedExposure?: boolean;
}

export interface LocationExposureAndProductInfo {
  locationExposure: LocationExposureEntity;
  underlyingPolicy?: UnderlyingPolicyEntity;
  product: ProductModel;
  options?: {
    carrierLabel?: string;
    policyTypeLabel?: string;
    locationUsageLabel?: string;
  };
}

export interface LocationExposureEntity extends BaseEntity {
  productId?: string;
  locationExposureId: string;
  underlyingPolicyNumber?: string;
  policyType: string;
  locationUsage: string;
  numberOfUnits: string;
  locationAddress?: AddressEntity;
  addressDescription?: string;
  dwellingDetails?: DwellingDetails;
}

export function sanitizeLocationExposureEntity(
  input: unknown
): LocationExposureEntity {
  return ObjectValidator.forceSchema<LocationExposureEntity>(
    input,
    {
      productId: 'string',
      locationExposureId: 'string',
      underlyingPolicyNumber: 'string',
      policyType: 'string',
      locationUsage: 'string',
      numberOfUnits: 'string',
      locationAddress: sanitizeAddressEntity,
      addressDescription: 'string',
      dwellingDetails: sanitizeDwellingDetails,
      ...BaseEntitySanitizerFields,
    },
    [
      'productId',
      'underlyingPolicyNumber',
      'locationAddress',
      'addressDescription',
      'dwellingDetails',
      ...BaseEntityOptionalFields,
    ]
  );
}

export interface DwellingDetails {
  hasLiabilityLosses?: boolean;
  liabilityLossDescription?: string;
  hasPool?: boolean;
  isPoolFenced?: boolean;
  hasDivingBoard?: boolean;
  hasTrampoline?: boolean;
  hasTrampolineSafetyNet?: boolean;
  hasNonDomesticAnimals?: boolean;
  nonDomesticedAnimalDescription?: string;
  hasNonDomesticAnimalCausedInjury?: boolean;
  nonDomesticAnimalInjuryDescription?: string;
  haveDogs?: boolean;
  dogs?: DogModel[];
}

export function sanitizeDwellingDetails(input: unknown): DwellingDetails {
  return ObjectValidator.forceSchema<DwellingDetails>(
    input,
    {
      hasLiabilityLosses: 'boolean',
      liabilityLossDescription: 'string',
      hasPool: 'boolean',
      isPoolFenced: 'boolean',
      hasDivingBoard: 'boolean',
      hasTrampoline: 'boolean',
      hasTrampolineSafetyNet: 'boolean',
      hasNonDomesticAnimals: 'boolean',
      nonDomesticedAnimalDescription: 'string',
      hasNonDomesticAnimalCausedInjury: 'boolean',
      nonDomesticAnimalInjuryDescription: 'string',
      haveDogs: 'boolean',
      dogs: [sanitizeDogModel],
    },
    [
      'hasLiabilityLosses',
      'liabilityLossDescription',
      'hasPool',
      'isPoolFenced',
      'hasDivingBoard',
      'hasTrampoline',
      'hasTrampolineSafetyNet',
      'hasNonDomesticAnimals',
      'nonDomesticedAnimalDescription',
      'hasNonDomesticAnimalCausedInjury',
      'nonDomesticAnimalInjuryDescription',
      'haveDogs',
      'dogs',
    ]
  );
}

export interface DogModel {
  dogId?: string;
  breed?: Nullable<string>;
  hasBiteHistory?: boolean;
}

export function sanitizeDogModel(input: unknown): DogModel {
  return ObjectValidator.forceSchema<DogModel>(
    input,
    {
      dogId: 'string',
      breed: 'string',
      hasBiteHistory: 'boolean',
    },
    ['dogId', 'breed', 'hasBiteHistory']
  );
}
