export enum CoverageDisplayGrouping {
  // Property
  PropertyLiability = 'Property / Liability',
  Deductibles = 'Deductibles',
  AdditionalProtection = 'Additional Protection to Purchase',
  SpecialLimitsOfLiability = 'Special Limits of Liability',
  // Auto
  Policy = 'Policy Coverages',
  Vehicle = 'Vehicle Coverages',
  Driver = 'Driver Coverages',
  AdditionalPolicy = 'Additional Policy Options',

  // Umbrella
  Underlying = 'Underlying Coverages',
  AdditionalUnderlying = 'Additional Policy Options',
}
