import { Nullable } from '@shared/utils/type.utils';
import { StateSpecificFlagsModel } from '@assets/metadata/stateSpecificFlags';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './metadata.action';
import { PropertyOptionsModel } from './models/property-options.model';
import { UmbrellaOptionsModel } from './models/umbrella-options.model';

export interface MetadataState {
  stateSpecificFlags: {
    data: StateSpecificFlagsModel;
    loaded: boolean;
    loading: boolean;
  };
  propertyOptions: {
    data: Nullable<PropertyOptionsModel>;
    loaded: boolean;
    loading: boolean;
  };
  umbrellaOptions: {
    data: Nullable<UmbrellaOptionsModel>;
    loaded: boolean;
    loading: boolean;
  };
  versionInformation: {
    data: Nullable<VersionInformation>;
    loaded: boolean;
    loading: boolean;
  };
  states: {
    states: string[];
    loaded: boolean;
  };
}

const initialState: MetadataState = {
  stateSpecificFlags: {
    data: {} as StateSpecificFlagsModel,
    loaded: false,
    loading: false,
  },
  propertyOptions: {
    data: null,
    loaded: false,
    loading: false,
  },
  umbrellaOptions: {
    data: null,
    loaded: false,
    loading: false,
  },
  versionInformation: {
    data: null,
    loaded: false,
    loading: false,
  },
  states: {
    states: [],
    loaded: false,
  },
};

const metadataReducer = createReducer(
  initialState,
  on(fromActions.loadStateSpecificFlagsSuccess, (state, { response }) => {
    const stateSpecificFlags = {
      data: response,
      loaded: true,
      loading: false,
    };
    return { ...state, stateSpecificFlags };
  }),
  on(fromActions.loadStateSpecificFlagsFail, (state, { error }) => ({
    ...state,
    data: error,
    loaded: true,
  })),
  on(fromActions.loadStatesSuccess, (state, { response }) => {
    return {
      ...state,
      states: { states: response, loaded: true },
    };
  }),
  on(fromActions.loadStatesFail, (state, { error }) => ({
    ...state,
    data: error,
    loaded: true,
  })),
  on(fromActions.loadPropertyOptionsSuccess, (state, { response }) => {
    const propertyOptions = {
      data: response,
      loaded: true,
      loading: false,
    };
    return { ...state, propertyOptions };
  }),
  on(fromActions.loadPropertyOptionsFail, (state, { error }) => ({
    ...state,
    data: error,
    loaded: true,
  })),
  on(fromActions.replacePropertyOptions, (state, { propertyOptions }) => {
    return {
      ...state,
      propertyOptions: {
        data: { ...propertyOptions },
        loaded: true,
        loading: false,
      },
    };
  }),
  on(fromActions.setVersionInformation, (state, { payload }) => {
    return {
      ...state,
      versionInformation: {
        data: { ...payload },
        loaded: true,
        loading: false,
      },
    };
  }),
  on(fromActions.loadUmbrellaOptionsSuccess, (state, { response }) => {
    const umbrellaOptions = {
      data: response,
      loaded: true,
      loading: false,
    };
    return { ...state, umbrellaOptions };
  }),
  on(fromActions.loadUmbrellaOptionsFail, (state, { error }) => ({
    ...state,
    data: error,
    loaded: true,
  }))
);

export function reducer(
  state: MetadataState | undefined,
  action: Action
): MetadataState {
  return metadataReducer(state, action);
}

export interface VersionInformation {
  dateTime?: string;
  commitHash?: string;
  commitMessage?: string;
  commitAuthor?: string;
  commitDateTime?: string;
  commitBranch?: string;
  prNumber?: string;
  deployEnv?: string;
}
