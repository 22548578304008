export interface UserContext {
  userType?: UserType;
  privateLabelInformation?: PrivateLabelInformation;
  spid?: string;
  referrer?: string;
  initiatedBy?: string;
  retrieve?: boolean;
  retrievedBy?: UserType;
  producer?: PreBindDocumentUserContextProducer;
  gtm?: string;
  telephoneNumber?: string;
  guid?: string;
  otp?: string;
  prequalifiedPartnerScoreId?: string;
  vendorId?: string;
  carrierId?: string;
  state?: string;
  lob?: string;
}

export interface PrivateLabelInformation {
  type: PrivateLabelType;
  key: string;
}

export interface PreBindDocumentUserContextProducer {
  type: string;
  stateCode: string;
  agencyCode: string;
  producerId: string;
  producerCode: string;
}

export enum UserType {
  DIRECT = 'Direct',
  EA = 'EA',
  IA = 'IA',
  NSS = 'NSS',
  DIRECT_PRIVATELABEL = 'Direct Private Label',
  IA_PRIVATELABEL = 'IA Private Label',
}

export enum PrivateLabelType {
  DOMAIN = 'Domain',
  QUERYPARAMETER = 'QueryParameter',
  SPID = 'SPID',
}

export enum InitiatedByType {
  IOS_MOBILE = 'NWX_iOS_Mobile',
  ANDROID_MOBILE = 'NWX_Android_Mobile',
  COMPRATER = 'COMPRATER',
}
