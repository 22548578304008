import { ChangeDetectorRef, Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { OtherStructureCoverage, OtherStructuresAdditionalDwellingUnit } from "@entities/other-structures/other-structures.reducer";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

export interface StructuresRentedModalResult {
  action: 'save' | 'delete' | 'cancel';
  unit: OtherStructuresAdditionalDwellingUnit | null;
}

@Component({
  selector: 'nwx-structures-rented-modal',
  templateUrl: './structures-rented-modal.component.html',
  styleUrls: ['./structures-rented-modal.component.scss'],
})
export class StructuresRentedModalComponent {
  unit: OtherStructuresAdditionalDwellingUnit | null = null;
  coverage: OtherStructureCoverage | null = null;
  form = new FormGroup({
    locationDescription: new FormControl(''),
    numberOfUnits: new FormControl(''),
    limit: new FormControl(0),
    unitId: new FormControl(0),
  });
  showNumberOfUnits = true;
  showLimit = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private ngbActiveModal: NgbActiveModal
  ) {}

  setUnit(unit: OtherStructuresAdditionalDwellingUnit | null): void {
    this.unit = unit;
    this.form.setValue({
      locationDescription: unit?.locationDescription || '',
      numberOfUnits: unit?.numberOfUnits?.toString() || '',
      limit: unit?.limit || 0,
      unitId: unit?.unitId || 0,
    });
    this.changeDetector.markForCheck();
  }

  setCoverage(coverage: OtherStructureCoverage): void {
    this.coverage = coverage;
    this.setVisibilityFlagsForCoverageId(this.coverage?.coverageId || '');
    this.changeDetector.markForCheck();
  }

  private setVisibilityFlagsForCoverageId(coverageId: string): void {
    if (coverageId === 'StructuresRented') {
      this.showNumberOfUnits = false;
      this.showLimit = true;
      this.form.get('limit')?.setValidators([
        Validators.min(1),
      ]);
    } else {
      this.showNumberOfUnits = true;
      this.showLimit = false;
      this.form.get('limit')?.setValidators([]);
    }
  }

  onCancel(): void {
    this.ngbActiveModal.close({
      action: 'cancel',
      unit: this.unit,
    });
  }

  onDelete(): void {
    this.ngbActiveModal.close({
      action: 'delete',
      unit: this.unit,
    });
  }

  onSave(): void {
    if (this.form.valid) {
      this.ngbActiveModal.close({
        action: 'save',
        unit: this.sanitizeFormValue(),
      });
    }
  }

  private sanitizeFormValue(): OtherStructuresAdditionalDwellingUnit {
    return {
      locationDescription: (this.form.value.locationDescription || '').toString(),
      numberOfUnits: +(this.form.value.numberOfUnits || 0),
      limit: +(this.form.value.limit || 0),
      unitId: +(this.form.value.unitId || 0),
    };
  }
}
