import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { createReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from '@core/store/reducers';
import { Reducers } from '@core/constants/reducers';
import { effects } from '@core/store/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(Reducers.Core, reducers),
    EffectsModule.forFeature(effects),
    EffectsModule.forRoot(),
    StoreModule.forRoot({ Core: createReducer({}) }),
  ],
  providers: [CurrencyPipe],
})
export class CoreModule {}
