import { ObjectValidator } from '@core/helper/object-validator';
import { PersonRequest, sanitizePersonRequest } from '../request/person-request.model';
import { ProducerRequest, sanitizeProducerRequest } from '../request/producer-request.model';
import { AddressEntity, sanitizeAddressEntity } from '@core/store/entities/address/address.entity';

export interface AccountResponse {
  accountHolder: AccountHolderResponse;
  producer: ProducerRequest;
  accountId: string;
}

export interface AccountHolderResponse {
  person: PersonRequest;
  address: AddressEntity;
}

export function sanitizeAccountResponse(response: unknown): AccountResponse {
  return ObjectValidator.forceSchema(response, {
    accountHolder: sanitizeAccountHolderResponse,
    producer: sanitizeProducerRequest,
    accountId: 'string',
  }) as AccountResponse;
}

export function sanitizeAccountHolderResponse(response: unknown): AccountHolderResponse {
  return ObjectValidator.forceSchema(response, {
    person: sanitizePersonRequest,
    address: sanitizeAddressEntity,
  }) as AccountHolderResponse;
}
