import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardHeaderComponent } from './card-header.component';
import { CommonFormModule } from '@shared/modules/common-form.module';

@NgModule({
  declarations: [CardHeaderComponent],
  imports: [CommonModule, CommonFormModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [CardHeaderComponent],
})
export class CardHeaderModule {}
