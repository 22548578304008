import { EscrowAccount } from '@core/models/entities/escrow-account.entity';
import { SessionActions } from '@core/store/actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
  escrowPaymentNotSelected,
  establishEscrowAccount,
  establishEscrowAccountFailure,
  establishEscrowAccountSuccess,
} from './escrow.action';

export type EscrowState = EscrowAccount;

const escrowReducer = createReducer(
  {},
  on(SessionActions.clearSessionState, () => ({})),
  // on(addEscrowAccount, (state, { payload }) => ({
  //   ...state,
  //   response: payload.response,
  // })),
  on(establishEscrowAccount, (state, {}) => ({
    ...state,
    status: 'pending',
  })),
  on(establishEscrowAccountSuccess, (state, { payload }) => ({
    ...state,
    status: 'success',
    response: payload,
  })),
  on(establishEscrowAccountFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error,
  })),
  on(escrowPaymentNotSelected, (state) => ({
    ...state,
    status: '',
  }))
);

export function reducer(
  state: EscrowState | undefined,
  action: Action
): EscrowState {
  return escrowReducer(state, action);
}
