<form [formGroup]="form">
  <table formArrayName="dogs">
    <tr class="header bolt-background-vibrant-blue">
      <td>
        <bolt-button
          color="white"
          type="primary"
          (click)="onAdd()"
          [attr.id]="'edl-add'"
          >Add</bolt-button
        >
      </td>
      <th>Breed</th>
      <th>Bite history</th>
      <!--<th>Canine good citizen</th>-->
    </tr>
    <tr
      *ngFor="let control of getDogControls(); let i = index"
      [formGroup]="control"
      [nwxExposeFormErrors]="control"
      [submitted]="submitted"
    >
      <td>
        <bolt-button
          type="tertiary"
          (click)="onRemove(i)"
          attr.id="remove-{{ i }}"
          >Remove
        </bolt-button>
      </td>
      <td>
        <bolt-select
          formControlName="dogBreed"
          attr.id="dogBreed-{{ i }}"
          attr.name="dogBreed-{{ i }}"
          arialabel="Dog breed"
          required
        >
          <option *ngFor="let breed of dogBreeds" [value]="breed.value">
            {{ breed.displayName }}
          </option>
        </bolt-select>
      </td>
      <td>
        <bolt-radio-group
          formControlName="biteHistory"
          horizontal
          attr.id="biteHistory-{{ i }}"
          arialabel="Bite history"
          required
        >
          <bolt-radio value="true" id="yes-{{ i }}">Yes</bolt-radio>
          <bolt-radio value="false" id="no-{{ i }}">No</bolt-radio>
        </bolt-radio-group>
      </td>
      <!--
      <td>
        <bolt-radio-group
          formControlName="canineGoodCitizen"
          horizontal
          required
        >
          <bolt-radio value="true">Yes</bolt-radio>
          <bolt-radio value="false">No</bolt-radio>
        </bolt-radio-group>
      </td>
      -->
    </tr>
    <tr *ngIf="showInlineMessage">
      <td colspan="3">
        <bolt-notification type="warning">
          <div class="inline-message-content">
            <div class="inline-message-text">
              Applicants who currently own animals that have caused an injury are ineligible.
            </div>
            <bolt-button type="primary" (click)="removeProduct.next()">Remove product</bolt-button>
          </div>
        </bolt-notification>
      </td>
    </tr>
  </table>
</form>
