import { Component, OnInit } from '@angular/core';
import { RouterService } from '@core/services/router.service';
import { CoverageEntity } from '@entities/coverage/coverage.entity';
import { PremiumEntity } from '@entities/premium/premium.entity';
import { ProductModel } from '@entities/product/product.model';
import {
  EnrolledVehicle,
  MobileEnrollment,
  VehicleEnrollment,
} from '@entities/telematics/telematics.model';
import { VehicleEntity } from '@entities/vehicle/vehicle.entity';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TelematicsUtils } from '@shared/utils/telematics.utils';
import { Nullable } from '@shared/utils/type.utils';

@Component({
  selector: 'nwx-coverages-modal',
  templateUrl: './coverages-modal.component.html',
  styleUrls: ['./coverages-modal.component.scss'],
})
export class CoveragesModalComponent implements OnInit {
  // These must be supplied when the modal is instantiated:
  coverages!: CoverageEntity[];
  premiums!: PremiumEntity[];
  product!: ProductModel;
  vehicles!: VehicleEntity[];
  enrollment: Nullable<VehicleEnrollment | MobileEnrollment>;

  monthlyPremium = '--';
  termPremium = '--';
  termMonths = 6;

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private routerService: RouterService
  ) {}

  ngOnInit(): void {
    this.extractPremiums();
  }

  private extractPremiums(): void {
    const premium = this.premiums?.find(
      (p) => p.productType === this.product?.type
    );
    if (premium) {
      this.monthlyPremium = this.formatDollars(
        (premium.total?.amount || 0) / (premium.termMonths || 1)
      );
      this.termPremium = this.formatDollars(premium.total?.amount || 0);
      this.termMonths = premium.termMonths || 1;
    } else {
      this.monthlyPremium = '--';
      this.termPremium = '--';
      this.termMonths = 6;
    }
  }

  private formatDollars(input: number): string {
    return '$' + input.toFixed(2);
  }

  getPolicyCoverages(): CoverageEntity[] {
    return this.coverages.filter((c) => !c.coverableId);
  }

  getVehicleHeadline(vehicle: VehicleEntity): string {
    return `${vehicle.year} ${vehicle.make} ${vehicle.model}`;
  }

  getVehicleCoverages(vehicle: VehicleEntity): CoverageEntity[] {
    return this.coverages.filter((c) => c.coverableId === vehicle.vehicleId);
  }

  getTelematicsEnrollmentById(vehicleId: string | undefined): string {
    let vehicleProgram = '';
    if (this.enrollment) {
      if (TelematicsUtils.isMobileEnrollment(this.enrollment)) {
        vehicleProgram = 'SmartRide';
      } else {
        const vehicleEnrollment = this.enrollment as VehicleEnrollment;
        vehicleProgram = (
          vehicleEnrollment.vehicles.find(
            (v) =>
              v.vehicleId?.toString() === vehicleId?.toString() &&
              (v.enrollmentStatus === 'Enrolled' ||
                v.enrollmentStatus === 'VerifiedScore')
          ) as EnrolledVehicle
        )?.vehicleProgram;
      }
    }
    if (vehicleProgram === 'ConnectedCar') {
      return 'SmartRide';
    }
    if (vehicleProgram === 'SmartMilesConnectedCar') {
      return 'SmartMiles';
    }
    return vehicleProgram;
  }

  onClose(): void {
    this.ngbActiveModal.close();
  }

  onEditCoverages(event: Event): void {
    event.preventDefault();
    this.ngbActiveModal.close();
    this.routerService.go({
      path: ['/quote/coverages'],
      extras: {
        queryParams: { product: this.product.type },
      },
    });
  }
}
