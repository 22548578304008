<div class="price-box-right">
  <div class="package-box">
    <div class="package-box-headline">
      <h2 class="blue-box-headline-text">Package</h2>
      <button
        [disabled]="!content.quotePdfAvailable"
        id="quoteLetterPdf"
        (click)="showPdf.next()"
      >
        <span>.PDF</span>
        <bolt-icon name="download" size="sm"></bolt-icon>
      </button>
    </div>
    <ng-container *ngIf="content?.downPayments?.length">
      <div *ngFor="let payment of content.downPayments" class="down-payments">
        <div class="bolt-row">
          <div class="bolt-col-6 down-payment-amount">
            {{ payment.amount }}
            <span class="down-payment-disclaimer" *ngIf="maxSizeMedium">
              Total of all terms
            </span>
          </div>

          <div class="bolt-col-6 down-payment-name">
            {{ payment.name }}
            <span
              *ngIf="content.downPaymentDisclaimer && maxSizeMedium"
              class="down-payment-disclaimer"
            >
              {{ content.downPaymentDisclaimer }}</span
            >
          </div>
        </div>
        <div class="bolt-row" *ngIf="payment.caption">
          <div class="bolt-col-12 down-payment-caption">
            {{ payment.caption }}
          </div>
        </div>
      </div>
      <div
        class="down-payment-disclaimer"
        *ngIf="content?.downPaymentDisclaimer && !maxSizeMedium"
      >
        {{ content.downPaymentDisclaimer }}
      </div>
    </ng-container>
    <div class="bolt-row" *ngIf="content?.downPaymentSubstitute">
      <div class="bolt-col-12 down-payment-substitute">
        {{ content.downPaymentSubstitute }}
      </div>
    </div>
    <div class="bolt-row bolt-align-items-end">
      <div class="bolt-col-12"></div>
    </div>
    <div class="bolt-row" *ngIf="!maxSizeMedium">
      <div class="package-button-box">
        <button
          class="billing-plans"
          iconright="chevron-down"
          id="billing-plans-btn"
          (click)="openModal.next()"
        >
          <div class="btn-content">
            <div>
              <div class="billing-button-label">
                Billing: {{ content.selectedBillingPlan }}
              </div>
              <div *ngIf="content?.billingPlanByline">
                <div class="billing-button-subtext">
                  {{ content.billingPlanByline }}
                </div>
              </div>
            </div>
            <div class="icon-circle">
              <bolt-icon
                name="chevron-down"
                color="black"
                size="sm"
              ></bolt-icon>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="maxSizeMedium">
  <div class="bolt-row">
    <div class="bolt-col-12">
      <div class="package-button-box">
        <button
          class="billing-plans"
          iconright="chevron-down"
          id="billing-plans-btn"
          (click)="openModal.next()"
        >
          <div class="btn-content">
            <div>
              <div class="billing-button-label">
                Billing: {{ content.selectedBillingPlan }}
              </div>
              <div *ngIf="content?.billingPlanByline">
                <div class="billing-button-subtext">
                  {{ content.billingPlanByline }}
                </div>
              </div>
            </div>
            <div class="icon-circle">
              <bolt-icon
                name="chevron-down"
                color="black"
                size="sm"
              ></bolt-icon>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
