import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CoveredLocationModel } from '@entities/covered-location/covered-location.model';
import { ProductModel } from '@entities/product/product.model';
import { MSB_HELP_CONTENT } from '@shared/constants/help-text-constants';
import { ModalService } from '@shared/services/modal.service';
import { Nullable } from '@shared/utils/type.utils';

@Component({
  selector: 'nwx-msb-report-number',
  templateUrl: './msb-report-number.component.html',
  styleUrls: ['./msb-report-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsbReportNumberComponent {
  @Input() coveredLocation!: Partial<CoveredLocationModel>;
  @Input() productModel!: Nullable<ProductModel>;
  @Input() isLaunchedFromHubPage!: boolean;

  pencilTitle = MSB_HELP_CONTENT;

  constructor(private modalService: ModalService) {}

  openMSBEstimateModal(): void {
    this.modalService.msbEstimateModal(
      this.coveredLocation.associatedMSBEstimateNumber,
      this.productModel?.associatedMSBEstimateNumber,
      this.productModel?.type,
      this.isLaunchedFromHubPage
    );
  }
}
