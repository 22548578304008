import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromAddress from './address.reducer';
import { AddressType, ProductType } from '@core/models/api/dsm-types';
import { getQuoteState } from '../session/session.selector';
import { getStatesList } from '../metadata/metadata.selector';
import { AddressEntity } from './address.entity';
import {
  getProduct,
  getSelectedProducts,
} from '@entities/product/product.selectors';

export const getAddressState = createSelector(
  fromCore.getCoreState,
  (state) => state.address
);

export const {
  selectAll: getAllAddress,
  selectEntities: getAddressEntities,
  selectTotal: getTotalAddress,
} = fromAddress.adapter.getSelectors(getAddressState);

export const getAddressById = (id: string) =>
  createSelector(getAllAddress, (addresses) =>
    addresses.find((address) => address.addressId === id)
  );

export const getAddressByType = (type: AddressType) =>
  createSelector(getAddressEntities, (addresses) => addresses[type]);

export const getAddressForProduct = (product: ProductType) =>
  createSelector(getAddressByType(product), (address) => address);

export const getSingleLineAddress = (product: ProductType) =>
  createSelector(getAddressForProduct(product), (address) =>
    address
      ? `${address?.addressLine1}, ${address?.city}, ${address?.state}, ${address?.postalCode}`
      : ''
  );

export const getPropertyAddress = (productType: ProductType) =>
  createSelector(
    getProduct(productType),
    getAddressEntities,
    (product, addresses) => {
      const address = addresses[product?.type as ProductType];

      return address
        ? `${address?.addressLine1}, ${address?.city}, ${address?.state}, ${address?.postalCode}`
        : '';
    }
  );

export const getAvailableAddress = createSelector(
  getAddressByType('Account'),
  getAddressByType('Mailing'),
  getAddressByType('Billing'),
  (accountAddress, mailingAddress, billingAddress) =>
    ({
      ...accountAddress,
      ...mailingAddress,
      ...billingAddress,
    } as AddressEntity)
);

export const getMailingAddress = createSelector(
  getAddressByType('Mailing'),
  (mailingAddress) =>
    ({
      ...mailingAddress,
    } as AddressEntity)
);

export const getDefaultAddress = createSelector(
  getAddressByType('Default'),
  (defaultAddress) =>
    ({
      ...defaultAddress,
    } as AddressEntity)
);

export const getAccountAddress = createSelector(
  getAddressByType('Account'),
  (accountAddress) =>
    ({
      ...accountAddress,
    } as AddressEntity)
);

export const getBillingAddress = createSelector(
  getAddressByType('Billing'),
  (billingAddress) =>
    ({
      ...billingAddress,
    } as AddressEntity)
);

export const getAddressForNewPolicyHolder = createSelector(
  getAddressByType('Account'),
  getAddressByType('Mailing'),
  (accountAddress, mailingAddress) =>
    ({
      ...accountAddress,
      ...mailingAddress,
    } as AddressEntity)
);

export const getAddressFormOptions = (
  unitNumberVisible: boolean,
  outsideQuoteState: boolean
) =>
  createSelector(getQuoteState, getStatesList, (quoteState, states) => ({
    quoteState,
    outsideQuoteState,
    states,
    unitNumberVisible,
  }));

export const getUniqueQuoteStates = createSelector(
  getSelectedProducts,
  getAddressEntities,
  (products, addresses) =>
    products
      .map((product) => addresses[product.type]?.state || '')
      .filter(
        (state, index, all) => state.length && all.indexOf(state) === index
      )
);

export const getSelectedProductsWithAddressState = createSelector(
  getSelectedProducts,
  getAddressEntities,
  (products, addresses) =>
    products
      .map((product) => {
        return {
          product: product.type,
          state: addresses[product.type]?.state || '',
        };
      })
      .filter((item) => item.state.length)
);
