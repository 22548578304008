import { ProductType } from '@core/models/api/dsm-types';
import * as fromCore from '@core/store/reducers';
import { createSelector, Selector } from '@ngrx/store';
import { DsmProduct } from './dsm.model';
import { DsmState } from './dsm.reducer';
import { LogApiName } from '@core/models/api/log.model';

export const getDsmState = createSelector(
  fromCore.getCoreState,
  (state) => state.dsm
);

export const getTargetEnv = createSelector(
  getDsmState,
  (state) => state.targetEnv
);

export const getApplicationName = createSelector(
  getDsmState,
  (state) => state.applicationName
);

export const getEffectiveDate = createSelector(
  getDsmState,
  (state) => state.effectiveDate
);

export const getDsmAutoProduct = createSelector(
  getDsmState,
  (state) => state.products.PersonalAuto
);
export const getDsmCondoProduct = createSelector(
  getDsmState,
  (state) => state.products.Condominium
);
export const getDsmHomeownersProduct = createSelector(
  getDsmState,
  (state) => state.products.Homeowner
);
export const getDsmLifeProduct = createSelector(
  getDsmState,
  (state) => state.products['TermLife']
);
export const getDsmRentersProduct = createSelector(
  getDsmState,
  (state) => state.products.Tenant
);
export const getDsmUmbrellaProduct = createSelector(
  getDsmState,
  (state) => state.products.PersonalUmbrella
);

export function getDsmProduct(
  productId: ProductType
): (state: object) => DsmProduct | undefined {
  switch (productId) {
    case 'PersonalAuto':
      return getDsmAutoProduct;
    case 'Condominium':
      return getDsmCondoProduct;
    case 'Homeowner':
      return getDsmHomeownersProduct;
    case 'TermLife':
      return getDsmLifeProduct;
    case 'Tenant':
      return getDsmRentersProduct;
    case 'PersonalUmbrella':
      return getDsmUmbrellaProduct;
  }
  throw new Error(`Invalid product ID '${productId}'`);
}

export const getDsmCallCount = createSelector(
  getDsmState,
  (state) => state.callsInFlight.length
);

export const getDsmCallNames = createSelector(
  getDsmState,
  (state) => state.callsInFlight
);
