import {
  ErrorModel,
  ValidationErrorModel,
} from '@core/store/entities/error/error.model';
import { ProductActions, SessionActions } from '@core/store/actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as errorActions from './error.action';
import { ProductType } from '@core/models/api/dsm-types';

export interface ErrorState extends EntityState<ErrorModel> {
  validationErrors: ValidationErrorModel[];
}

export const adapter = createEntityAdapter<ErrorModel>();

const initialState = {
  ...adapter.getInitialState(),
  validationErrors: [] as ValidationErrorModel[],
};

export const reducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(errorActions.addError, (state, action) => {
    let error = action.payload;
    if (!error.id) {
      error = { ...error, id: Math.max(0, ...(state.ids as number[])) + 1 };
    }
    return adapter.addOne(error, state);
  }),
  on(errorActions.clearErrors, (state) => adapter.removeAll(state)),
  on(errorActions.setSideNavErrorIcon, (state, action) => {
    return {
      ...state,
      validationErrors: state.validationErrors
        .filter(
          (err) =>
            !(
              err.pageName === action.payload.pageName &&
              err.formName === action.payload.formName
            )
        )
        .concat(action.payload)
        .filter((err) => err.errors && Object.keys(err.errors).length > 0),
    };
  }),
  on(errorActions.removeErrorsById, (state, action) => {
    return adapter.removeMany(action.ids, state);
  }),
  on(errorActions.removeError, (state, action) => {
    return adapter.removeOne(action.id, state);
  }),
  on(errorActions.clearErrorsByType, (state, action) => {
    let productType = action.payload.productType;
    let actionType = action?.payload?.sourceActionType?.replace(' Success', '');

    const entities = Object.keys(state.entities)?.filter((entityId) => {
      const entity = state.entities[entityId];
      if(action.payload.entityId && action.payload.entityId !== entity?.entityId) {
        return false;
      }
      return productType === entity?.productType && entity?.sourceActionType?.includes(actionType as string);
    });

    if(entities.length) {
      return adapter.removeMany(entities, state);
    }
    return state;
  }),
  on(errorActions.clearValidationErrorsForForm, (state, action) => {
    return {
      ...state,
      validationErrors: state.validationErrors.filter(e => (
        e.formName !== action.formName
      )),
    };
  }),
  on(ProductActions.removeProduct, (state, action) => {
    let productType = action.payload;
    const entities = Object.keys(state.entities)?.filter((entityId) => {
      const entity = state.entities[entityId];
      return productType === entity?.productType });
      if(entities.length) {
        return adapter.removeMany(entities, state);
      }
      return state;
  }),

  on(ProductActions.updateSelectedProducts, (state, action) => {
    let productTypes = action.payload;
    const entities = Object.keys(state.entities)?.filter((entityId) => {
      const entity = state.entities[entityId];
      return  !productTypes.includes(entity?.productType as ProductType);
    });
      if(entities) {
        return adapter.removeMany(entities, state);
      }
      return state;
  }),
);
