import { Injectable } from '@angular/core';
import { TaskService } from '@core/services/task.service';
import { TaskActions } from '@core/store/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, take, map, concatMap } from 'rxjs/operators';
import {
  deleteUnderlyingPolicySuccess,
  upsertUnderlyingPolicySuccess,
} from '@entities/underlying-policy/underlying-policy.action';
import {
  deleteVehicleExposureSuccess,
  upsertVehicleExposureSuccess,
} from '@entities/exposures/vehicle-exposures/vehicle-exposures.action';
import {
  deleteLocationExposureSuccess,
  upsertLocationExposureSuccess,
} from '@entities/exposures/location-exposures/location-exposures.action';
import {
  deleteWatercraftExposureSuccess,
  upsertWatercraftExposureSuccess,
} from '@entities/exposures/watercraft-exposures/watercraft-exposures.action';
import { updateUmbrellaTasks } from '@entities/umbrella/umbrella.selector';
import { updateQuoteSuccess } from '@entities/quote/quote.action';

@Injectable({
  providedIn: 'root',
})
export class UmbrellaTaskEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private taskService: TaskService
  ) {}

  updateUmbrellaTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        upsertUnderlyingPolicySuccess,
        deleteUnderlyingPolicySuccess,
        upsertVehicleExposureSuccess,
        deleteVehicleExposureSuccess,
        upsertLocationExposureSuccess,
        deleteLocationExposureSuccess,
        upsertWatercraftExposureSuccess,
        deleteWatercraftExposureSuccess,
        updateQuoteSuccess,
        TaskActions.updateTasks
      ),
      concatMap(() => this.store.select(updateUmbrellaTasks).pipe(take(1))),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );
}
