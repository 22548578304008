import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as fromStore from '@core/store/reducers';
import { DistributedDocumentsAdapter } from '@core/adapters/distributed-documents.adapter';
import { DistributedDocumentsResponse } from '../models/api/response/distributed-documents-response.model';
import { Store } from '@ngrx/store';
import { QuoteLetterSelectors } from '../store/selectors';
import { filter, take } from 'rxjs/operators';
import { Base64Utils } from '@shared/utils/base64.utils';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class QuoteLetterService {
  constructor(
    private distributedDocumentsAdapter: DistributedDocumentsAdapter,
    private store: Store<fromStore.CoreState>,
    private navigationService: NavigationService,
    private base64Utils: Base64Utils
  ) {}

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  documents(request: any): Observable<DistributedDocumentsResponse> {
    return this.distributedDocumentsAdapter.documents(request);
  }

  getQuoteLetter(): Observable<string> {
    return this.store.select(QuoteLetterSelectors.getQuoteLetter);
  }

  showQuoteLetterInNewTab(): void {
    this.getQuoteLetter()
      .pipe(take(1))
      .subscribe((letter) => this.showEncodedQuoteLetterInNewTab(letter));
  }

  showEncodedQuoteLetterInNewTab(base64EncodedPdf: string): void {
    if (!base64EncodedPdf) {
      return;
    }
    this.base64Utils
      .decodeBase64ToBlobUrl(base64EncodedPdf, 'application/pdf')
      .then((url) => {
        this.navigationService.openExternalPage(url, '_blank');
      })
      .catch((err) => {
        // Opportunity here to report the error.
      });
  }
}
