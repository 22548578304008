import { Nullable } from '@shared/utils/type.utils';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { on } from '@ngrx/store';
import { createReducer } from '@ngrx/store';
import { Action } from '@ngrx/store';
import {
  clearAccountError,
  createAccount,
  createAccountFail,
  createAccountSuccess,
  linkAccount,
  setAccountId,
} from './account.action';
import { RetrieveActions, SessionActions } from '../../actions';

export interface AccountState {
  accountId: Nullable<string>;
  error: ErrorModel;
  loaded: boolean;
  loading: boolean;
  isLinked: boolean;
}

const initialState: AccountState = {
  accountId: null,
  error: {},
  loaded: false,
  loading: false,
  isLinked: false,
};

const accountReducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(createAccount, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(createAccountSuccess, (state, { response }) => ({
    ...state,
    accountId: response.accountId,
    loaded: true,
    loading: false,
  })),
  on(createAccountFail, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false,
  })),
  on(clearAccountError, (state) => {
    if (state.accountId) {
      return {
        ...state,
        error: {},
      };
    } else {
      return {
        ...state,
        error: {},
        loaded: false,
      };
    }
  }),
  on(
    RetrieveActions.retrieveQuoteSuccess,
    RetrieveActions.retrieveInactiveQuoteSuccess,
    (state, { payload }) => {
      if (payload.response.accountId) {
        return {
          ...state,
          loaded: true,
          accountId: payload.response.accountId,
          loading: false,
        };
      } else {
        return {
          ...state,
          accountId: state.accountId,
          loading: false,
        };
      }
    }
  ),
  on(setAccountId, (state, { accountId }) => {
    return {
      ...state,
      accountId,
      loaded: true,
      loading: false,
    };
  }),
  on(linkAccount, (state) => {
    return { ...state, isLinked: true };
  })
);

export function reducer(
  state: AccountState | undefined,
  action: Action
): AccountState {
  return accountReducer(state, action);
}
