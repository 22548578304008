import { Injectable } from '@angular/core';
import { LayoutService } from '@core/services/layout.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as fromActions from './layout.action';
import { mergeMap, map, tap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutEffects {
  constructor(
    private actions$: Actions,
    private layoutService: LayoutService
  ) {}

  getInitialSize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getInitialSize),
      mergeMap((action) => {
        let nextActions: Action[] = [];
        let size = this.layoutService.getInitialSize();
        if (size) {
          nextActions.push(
            fromActions.setInitialSize({
              payload: {
                viewport: size,
                mobileNav: this.layoutService.showMobileNav(size),
              },
            })
          );
        }
        return from(nextActions);
      })
    )
  );
}
