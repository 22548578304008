import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ProductType } from '@core/models/api/dsm-types';
import { NavigationService } from '@core/services/navigation.service';
import { ProductModel } from '@core/store/entities/product/product.model';
import { ProductUtils } from '@shared/utils/product.util';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'nwx-product-tile',
  templateUrl: './product-tile.component.html',
  styleUrls: [
    '../../../../scss/_custom-tile.scss',
    './product-tile.component.scss',
  ],
})
export class ProductTileComponent {
  @Input() form!: FormGroup;
  @Input() product!: ProductModel;
  @Input() name!: Nullable<string>;
  @Input() imageUrl!: Nullable<string>;
  @Input() helpText!: Nullable<string>;
  @Input() customContent!: boolean;
  @Input() quoteId?: string;
  @Input() isAvailable?: boolean;

  @Output() productSelected = new EventEmitter<ProductType>();

  constructor(private navigationService: NavigationService) {}

  onProductTileClicked(productType: ProductType, event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.selectedProducts.markAsTouched();
    this.productSelected.emit(productType);
  }

  isProductSelected(productType: ProductType): boolean | undefined {
    return this.selectedProducts?.value?.includes(productType);
  }

  isPropertyProduct(productType: ProductType): boolean {
    return ProductUtils.isPropertyProduct(productType);
  }

  isQuoteIdSelected(quoteId: string): boolean {
    return this.form
      .get('selectedQuotes')
      ?.value?.map(
        (q: { productType: ProductType; quoteId: string }) => q.quoteId
      )
      ?.includes(quoteId);
  }

  checkRadio(productType: ProductType): boolean | undefined {
    if (this.quoteId) {
      return this.isQuoteIdSelected(this.quoteId);
    } else {
      return this.isProductSelected(productType);
    }
  }

  applyClass(productType: ProductType): Observable<boolean | undefined> {
    return of(this.checkRadio(productType));
  }

  setShowError(): boolean {
    return (
      (this.selectedProducts?.invalid &&
        this.product.type === 'PersonalUmbrella' &&
        this.selectedProducts?.errors?.invalidUmbrellaSelections) ||
      (this.selectedProducts?.invalid &&
        this.product.type === 'DwellingFire' &&
        this.selectedProducts?.errors?.invalidDwellingFireSelections)
    );
  }

  pivotToPolicyCenter(): void {
    this.navigationService.pivotToPolicyCenter(
      'Product Selection Page',
      'Account'
    );
  }

  get selectedProducts(): FormArray {
    return this.form.get('selectedProducts') as FormArray;
  }
}
