import { OperatorFunction } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export function filterOutNull<T>(): OperatorFunction<T | null | undefined, T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return filter((arg) => !!arg || (arg as any) === false) as OperatorFunction<
    T | null | undefined,
    T
  >;
}

export function emptyListIfNull<T>(): OperatorFunction<T[] | null, T[]> {
  return map((arg) => {
    if (arg !== null && arg !== undefined) {
      return arg;
    } else {
      return [];
    }
  });
}

export function filterOutUndefined<T>(): OperatorFunction<T | undefined, T> {
  return filter((arg) => arg !== undefined) as OperatorFunction<
    T | undefined,
    T
  >;
}
