import { ObjectValidator } from '@core/helper/object-validator';
import { BasePersonEntity } from '@core/models/entities/base.entity';
import { PersonEntity, sanitizePersonEntity } from '@core/models/entities/person.entity';
import { Nullable } from '@shared/utils/type.utils';

export interface HouseholdMemberEntity extends BasePersonEntity {
  occupation?: string;
  person?: Nullable<PersonEntity>;
  householdMemberId?: number;
  employerName?: string;
  driverStatus?: string;
  hasLiabilityLosses?: boolean;
  productId?: string;
  prefillId?: string;
  ageFirstLicensed?: Nullable<number>;
  nonSpecifiedGender?: Nullable<boolean>;
}

export function sanitizeHouseholdMembers(response: unknown): HouseholdMemberEntity[] {
  return ObjectValidator.sanitizeArray(response, sanitizeHouseholdMemberEntity);
}

export function sanitizeHouseholdMemberEntity(response: unknown): HouseholdMemberEntity {
  return ObjectValidator.forceSchema<HouseholdMemberEntity>(response, {
    // Not present in responses. Declared here just for completeness:
    entityId: 'any',
    productIds: 'any',
    productType: 'any',
    quoteId: 'string',
    selected: 'boolean',
    eligibleDiscountIds: 'any',
    policyRoles: 'any',
    driverRoles: 'any',
    productId: 'any',
    // Real fields:
    occupation: 'string',
    person: sanitizePersonEntity,
    householdMemberId: 'number',
    employerName: 'string',
    driverStatus: 'string',
    hasLiabilityLosses: 'boolean',
    prefillId: 'string',
    ageFirstLicensed: 'number',
    nonSpecifiedGender: 'boolean',
  }, [
    'entityId',
    'productIds',
    'productType',
    'quoteId',
    'selected',
    'eligibleDiscountIds',
    'policyRoles',
    'driverRoles',
    'productId',
    'occupation',
    'person',
    'householdMemberId',
    'employerName',
    'driverStatus',
    'hasLiabilityLosses',
    'prefillId',
    'ageFirstLicensed',
    'nonSpecifiedGender',
  ]);
}
