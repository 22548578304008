import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { ErrorMessageService } from '@core/services/error-message.service';
import { removeError } from '@core/store/entities/error/error.action';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { ComponentChanges } from '@shared/utils/general.utils';

@Component({
  selector: 'nwx-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnChanges {
  static readonly FADE_OUT_DURATION_MS = 1000;

  @Input() error!: ErrorModel;
  message$ = of('');
  private fadeTimeOut = 0;

  constructor(
    private errorMessageService: ErrorMessageService,
    private store: Store,
    private window: Window,
    private element: ElementRef
  ) {}

  ngOnChanges(changes: ComponentChanges<ToastComponent>): void {
    if (changes.error) {
      this.message$ = this.errorMessageService.getVerbiageForErrorModel(
        this.error
      );
      this.refreshFadeOut();
    }
  }

  onDismiss(event: Event): void {
    this.store.dispatch(removeError({ id: this.error.id || 0 }));
    event.preventDefault();
  }

  onRemediate(event: Event): void {
    event.preventDefault();
  }

  private refreshFadeOut(): void {
    const fadeOutStartTime =
      (this.error.expirationTimeMs || 0) - ToastComponent.FADE_OUT_DURATION_MS;
    const fadeOutTimeFromNow = fadeOutStartTime - Date.now();
    if (this.fadeTimeOut) {
      this.window.clearTimeout(this.fadeTimeOut);
      this.fadeTimeOut = 0;
    }
    if (fadeOutTimeFromNow > 0) {
      this.fadeTimeOut = this.window.setTimeout(() => {
        this.beginFadeOut();
        this.fadeTimeOut = 0;
      }, fadeOutTimeFromNow);
    }
  }

  private beginFadeOut(): void {
    this.element.nativeElement.style = 'opacity: 0';
  }
}
