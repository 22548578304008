import {
  AvailableOption,
  EligibleDiscountId,
  QualifyingInformation,
  sanitizeAvailableOption,
  sanitizeQualifyingInformation,
} from '@core/store/entities/eligible-discounts/eligible-discounts.entity';
import { ProductType } from '../dsm-types';
import { ObjectValidator } from '@core/helper/object-validator';

export interface EligibleDiscountResponse {
  eligibleDiscountId: EligibleDiscountId;
  productType?: ProductType;
  name: string;
  description: string;
  selectedOptionValue: string;
  trainingCourseCompletionDate: string;
  modelId: string;
  vehicleId?: string;
  qualifyingInformation?: QualifyingInformation;
  availableOptions?: AvailableOption[];
  quoteId?: string;
  correlationId?: string;
}

export function sanitizeEligibleDiscountResponses(
  input: unknown
): EligibleDiscountResponse[] {
  return ObjectValidator.coerceValueToSchema(
    input,
    [sanitizeEligibleDiscountResponse],
    ''
  );
}

export function sanitizeEligibleDiscountResponse(
  input: unknown
): EligibleDiscountResponse {
  return ObjectValidator.forceSchema<EligibleDiscountResponse>(
    input,
    {
      eligibleDiscountId: 'string',
      productType: 'string',
      name: 'string',
      description: 'string',
      selectedOptionValue: 'string',
      trainingCourseCompletionDate: 'string',
      modelId: 'string',
      vehicleId: 'string',
      qualifyingInformation: sanitizeQualifyingInformation,
      availableOptions: [sanitizeAvailableOption],
      quoteId: 'string',
      correlationId: 'string',
    },
    [
      'productType',
      'trainingCourseCompletionDate',
      'modelId',
      'vehicleId',
      'qualifyingInformation',
      'availableOptions',
      'quoteId',
      'correlationId',
    ]
  );
}
