import { ElementRef } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  FormArray,
  FormControl,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { pairwise, takeUntil } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericFormModel = { [key: string]: any } | null | undefined;

export type AngularPatchValueOptions = {
  onlySelf?: boolean;
  emitEvent?: boolean;
};

/**
 * Alternative to form.patchValue() if it's important that only changed values get patched.
 * By default, patchValue() will retrigger validation on controls that haven't actually changed.
 */
export function patchFormWithChangedValuesOnly(
  form: FormGroup,
  model: GenericFormModel,
  options?: AngularPatchValueOptions
): GenericFormModel {
  const changes = changedValuesOnly(form.value, model) || {};
  form.patchValue(changes, options);
  return changes;
}

export function changedValuesOnly(
  previous: GenericFormModel,
  incoming: GenericFormModel,
  recursionLimit = 10
): GenericFormModel {
  if (recursionLimit-- <= 0) {
    return incoming;
  }
  if (
    previous === null ||
    previous === undefined ||
    incoming === null ||
    incoming === undefined
  ) {
    return incoming;
  }
  const changes: GenericFormModel = {};
  for (const key of Object.keys(incoming)) {
    if (valueIsDifferent(previous[key], incoming[key], recursionLimit)) {
      if (incoming[key] instanceof Array) {
        // Don't descend into arrays, keep them as-is.
        changes[key] = incoming[key];
      } else if (typeof incoming[key] === 'object') {
        changes[key] = changedValuesOnly(
          previous[key],
          incoming[key],
          recursionLimit
        );
      } else {
        changes[key] = incoming[key];
      }
    }
  }
  return changes;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function valueIsDifferent(a: any, b: any, recursionLimit = 10): boolean {
  if (recursionLimit-- <= 0) {
    return true;
  }
  if (a === b) {
    return false;
  }
  if (typeof a !== typeof b) {
    return true;
  }
  if (typeof a !== 'object') {
    return true;
  }
  if (!a || !b) {
    return true;
  }
  const akeys = Object.keys(a).sort();
  const bkeys = Object.keys(b).sort();
  if (akeys.length !== bkeys.length) {
    return true;
  }
  for (let i = 0; i < akeys.length; i++) {
    if (akeys[i] !== bkeys[i]) {
      return true;
    }
    if (valueIsDifferent(a[akeys[i]], b[bkeys[i]], recursionLimit)) {
      return true;
    }
  }
  return false;
}

export function nullIfEmpty(str: string | null | undefined): string | null {
  if (str === null || str === undefined || str === '') {
    return null;
  }

  return str;
}

export function emptyIfNull(str: string | null | undefined): string {
  if (str === null || str === undefined) {
    return '';
  }

  return str;
}

export function withoutNulls<T extends {}>(partial: Partial<T>): Partial<T> {
  const result: Partial<T> = {};
  const keys = Object.keys(partial) as (keyof T)[];
  for (const name of keys) {
    if (partial[name] !== null && partial[name] !== undefined) {
      result[name] = partial[name];
    }
  }
  return result;
}

export function scrollToFirstInvalidControl(el: ElementRef): void {
  const firstInvalidControl: HTMLElement | null =
    el.nativeElement.querySelector(
      'form .ng-invalid:not(div,form,nwx-card,nwx-card-list)'
    );

  firstInvalidControl?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
}

export function maskPii(obj: any, workingCopy: any = {}): any {
  const pii = [
    'dateOfBirth',
    'firstName',
    'lastName',
    'fullName',
    'licenseNumber',
    'phoneNumber',
    'emailAddress',
    'homeNumber',
    'houseNumber',
    'mobileNumber',
    'vin',
    'accountNumber',
    'routingNumber',
    'bankAccountNumber',
    'bankRoutingNumber',
    'phone',
    'cardNumber',
    'cardVerificationValue',
    'deliveryInformation',
    'documentByteStream',
    'fullAddress',
    'addressLine1',
    'displayName',
    'insuredName',
    'driverName',
    'name',
    'preferredName',
    'street',
    'streetName',
    'latitude',
    'longitude',
    'pniemail',
    'email',
  ];
  const safeSubtreeKeys = [
    'eligibleDiscounts', // these have a "name", which would otherwise get masked
  ];
  function isPII(label: string): boolean {
    return pii.includes(label);
  }
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  if (obj instanceof Array) {
    const newArray = [];
    for (const value of obj) {
      if (typeof value === 'object' && value !== null) {
        newArray.push(maskPii(value));
      } else {
        newArray.push(value);
      }
    }
    return newArray;
  }
  for (const key of Object.keys(obj)) {
    if (safeSubtreeKeys.includes(key)) {
      workingCopy[key] = obj[key];
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      workingCopy[key] = maskPii(obj[key]);
    } else if (isPII(key) && obj[key] !== null) {
      workingCopy[key] = '****';
    } else {
      workingCopy[key] = obj[key];
    }
  }
  return workingCopy;
}

// Bolt select lists always emit an initial value of empty string, followed by a second value of what you've said is the value in the markup
// This has the effect of always having a bolt-select be dirty even when the user hasn't touched it. This can trigger validation errors, or
// result in unintended behavior. disregardBoltInitialValue basically makes it so that you'll get the right value for your select without it
// starting off in a dirty state
export function disregardBoltInitialValue(
  control: AbstractControl | undefined | null,
  unsubscribe$: Observable<void>
): void {
  if (control) {
    control?.valueChanges
      .pipe(takeUntil(unsubscribe$), pairwise())
      .subscribe(([prevVal, newVal]) => {
        if (newVal === '') {
          control.patchValue(null);
        } else if (prevVal === '' && newVal === null) {
          control.markAsPristine();
        } else if (newVal === prevVal) {
          control.markAsPristine();
        }
      });
  }
}
