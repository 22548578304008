import { Injectable } from '@angular/core';
import { PageAlertService } from '@core/services/page-alert.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  withLatestFrom,
  filter,
  exhaustMap,
  map,
  catchError,
} from 'rxjs/operators';
import * as fromActions from '@core/store/actions';
import { PageAlert } from './page-alert.model';

@Injectable()
export class PageAlertEffect {
  constructor(
    private actions$: Actions,
    private pageAlertService: PageAlertService,
    private window: Window
  ) { }

  loadPageAlerts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.PageAlertActions.LoadAllPageAlerts),
      withLatestFrom(this.pageAlertService.getPageAlertLoaded()),
      filter(([_, loaded]) => !loaded),
      exhaustMap(() =>
        this.pageAlertService.establishPageAlerts()),
      map((pageAlerts) => {
        let isUnderMaintenance = pageAlerts.some(
          (alert) => alert.underMaintenance
        );

        if (this.window.sessionStorage.getItem('ignoreMaintenanceMode') === 'true') {
          isUnderMaintenance = false;
        }

        if (isUnderMaintenance) {
          const pageAlert = pageAlerts.find(
            (pageAlert) => pageAlert.underMaintenance
          );
          return fromActions.RouterActions.go({
            payload: {
              path: ['/maintenance'],
              query: {
                startTime:
                  pageAlert?.underMaintenanceStartTime?.toLocaleString(),
                endTime:
                  pageAlert?.underMaintenanceEndTime?.toLocaleString(),
              },
              extras: { skipLocationChange: true },
            },
          });
        }
        return fromActions.PageAlertActions.LoadAllPageAlertSuccess({
          payload: pageAlerts.map((pageAlert) => this.makePageAlertSerializable(pageAlert)),
        });
      }),
      catchError((error) =>
        of(
          fromActions.PageAlertActions.LoadAllPageAlertFail({
            error: error ? error.message : '',
          })
        )
      )
    )
  );

  private makePageAlertSerializable(pageAlert: PageAlert): PageAlert {
    pageAlert = { ...pageAlert }
    delete pageAlert.underMaintenanceEndTime;
    delete pageAlert.underMaintenanceStartTime;
    return pageAlert
  }

}
