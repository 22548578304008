import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MemberModel } from '@core/models/views/person.model';
import { TaskModel } from '@core/store/entities/task/task.model';
import { ProductModel } from '@entities/product/product.model';
import { GeneralUtils } from '@shared/utils/general.utils';
import { PersonUtils } from '@shared/utils/person.utils';
import { Nullable } from '@shared/utils/type.utils';

@Component({
  selector: 'nwx-tasks-members-list',
  templateUrl: './tasks-members-list.component.html',
  styleUrls: ['./tasks-members-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksMembersListComponent implements OnInit {
  @Input() pniTasks!: Nullable<TaskModel[]>;
  @Input() memberTasks!: Nullable<TaskModel[]>;
  @Input() policyholder!: Partial<Nullable<MemberModel>>;
  @Input() members!: MemberModel[];
  @Input() submitted!: boolean;
  @Input() products!: ProductModel[];

  @Output() valueChange = new EventEmitter<MemberModel[]>();
  @Output() formReady = new EventEmitter<FormGroup>();

  form!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      members: this.fb.array(this.members?.map((m) => this.fb.group({}))),
    });

    this.formReady.emit(this.form);
  }

  get formMembers(): FormArray {
    return this.form.get('members') as FormArray;
  }

  onValueChange(changes: Partial<MemberModel>): void {
    const personIndex = PersonUtils.getPersonIndex(this.members, changes);
    if (personIndex > -1) {
      this.members = GeneralUtils.pureReplace<MemberModel>(
        this.members,
        changes,
        personIndex
      );
      this.valueChange.emit(this.members);
    }
  }

  addChildForm(child: FormGroup, index: number, name: string): void {
    let group = this.formMembers.at(index) as FormGroup;
    if (!group && index === -1) {
      group = this.form;
    }
    if (!!group) {
      group.setControl(name, child);
    } else {
      this.formMembers.setControl(index, child);
    }
  }

  getMemberSpecificTasks(
    member: MemberModel
  ): Nullable<TaskModel[]> | undefined {
    return this.memberTasks?.filter((t) => t.entityId === member.entityId);
  }

  trackMembers(index: number, member: MemberModel): string {
    return member.entityId as string;
  }

  isMemberFormInvalid(index: number): boolean {
    return (
      (this.form.get('members') as FormArray)?.at(index)?.status ===
        'INVALID' && this.submitted
    );
  }

  isMemberPni(member: MemberModel): boolean {
    return member.relationToPrimaryNamedInsured === 'PrimaryNamedInsured';
  }
}
