<div class="start-new-quote-modal">
  <div class="modal-header">
    <h4 ngbAutoFocus class="modal-title">Modifying an existing quote</h4>
  </div>
  <div *ngIf="!onlyAllowNewQuote; else newQuoteOnly" class="modal-body">
    <div class="bolt-container">
      <div class="bolt-row">
        <div class="bolt-col-xs-12">
          <p>
            There is an existing quote in session. Navigating to the Start page
            will clear the session and allow you to either start a new quote or
            retrieve a prior quote.
          </p>
        </div>
      </div>
      <div class="bolt-row bolt-justify-content-center">
        <div class="bolt-col-sm-6">
          <a routerLink="home/start">
            <bolt-button
              (click)="continueCurrent()"
              type="primary"
              width="full-md"
              >Cancel</bolt-button
            >
          </a>
        </div>
        <div class="bolt-col-sm-6">
          <a class="no-bottom-border">
            <bolt-button (click)="startNew()" type="secondary" width="full-md">
              Start new quote
            </bolt-button>
          </a>
        </div>
      </div>
    </div>
  </div>
  <ng-template #newQuoteOnly>
    <div class="modal-body">
      <div class="bolt-container">
        <div class="bolt-row">
          <div class="bolt-col-xs-12">
            <p>Do you wish to start a new quote?</p>
          </div>
        </div>
        <div class="bolt-row bolt-justify-content-center">
          <div class="bolt-col-sm-6">
            <a>
              <bolt-button (click)="startNew()" type="primary" width="full-md">
                Start New
              </bolt-button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
