import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../error/error.model';
import { LocationExposureEntity } from './location-exposures.entity';

export const upsertLocationExposure = createAction(
  '[Location Exposures] Upsert Location Exposure',
  props<{ payload: LocationExposureEntity; correlationId?: string }>()
);

export const upsertLocationExposureSuccess = createAction(
  '[Location Exposures] Upsert Location Exposure Success',
  props<{
    payload: LocationExposureEntity;
    oldLocationExposureId: string;
    correlationId?: string;
   }>()
);

export const upsertLocationExposureFail = createAction(
  '[Location Exposures] Upsert Location Exposure Fail',
  props<{
    error: ErrorModel;
    correlationId?: string;
    entityId?: string;
  }>()
);

export const deleteLocationExposure = createAction(
  '[Location Exposures] Delete Location Exposure',
  props<{ payload: LocationExposureEntity; correlationId?: string }>()
);

export const deleteLocationExposureSuccess = createAction(
  '[Location Exposures] Delete Location Exposure Success',
  props<{
    locationExposureId: string;
    correlationId?: string;
  }>()
);

export const deleteLocationExposureFail = createAction(
  '[Location Exposures] Delete Location Exposure Fail',
  props<{
    error: ErrorModel;
    correlationId?: string;
    entityId?: string;
  }>()
);

export const completelyRemoveLocationExposure = createAction(
  '[Location Exposures] Removed previously deleted exposure from deleted state',
  props<{
    locationExposureId: string;
    correlationId?: string;
  }>()
);
