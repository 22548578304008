import { Injectable } from '@angular/core';
import * as PropertyFormSelectors from '@core/store/property-form/property-form.selector';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PropertyFormActions } from '../actions';
import { FortifiedHomeModel } from '@property/components/forms/fortified-home-form/fortified-home-form.model';
import { getFortifiedHome } from '@core/store/property-form/property-form.selector';

@Injectable({
  providedIn: 'root',
})
export class PropertyFormService {
  constructor(private store: Store) {}

  getRoofYearImmutable(): Observable<boolean> {
    return this.store.select(PropertyFormSelectors.getRoofYearImmutable);
  }

  setRoofYearImmutable(roofYearImmutable: boolean): void {
    this.store.dispatch(
      PropertyFormActions.setRoofYearImmutable({ roofYearImmutable })
    );
  }

  getFortifiedHome(): Observable<FortifiedHomeModel> {
    return this.store.select(getFortifiedHome);
  }
}
