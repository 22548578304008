import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import { ProductsSelectors } from '@core/store/selectors';
import * as fromWatercraftExcludedExposures from '@core/store/entities/exposures/watercraft-excluded-exposures/watercraft-excluded-exposures.reducer';
import { getAllFeatureFlags } from '@entities/feature-flag/feature-flag.selector';
import { WatercraftExcludedExposureRequest } from '@core/models/api/request/watercraft-exposure-request.model';
import { getUnderlyingPoliciesAndProductInfo } from '@entities/underlying-policy/underlying-policy.selector';
import { getUmbrellaOptions } from '@entities/metadata/metadata.selector';
import { Nullable } from '../../../../../shared/utils/type.utils';
import { UmbrellaUtils } from '@shared/utils/umbrella.utils';
import {
  WatercraftExcludedExposureAndProductInfo,
  WatercraftExcludedExposureEntity,
} from './watercraft-excluded-exposures.entity';
import { WatercraftExposureOptionsModel } from '@entities/metadata/models/umbrella-options.model';
import { UmbrellaTileModel } from '@shared/components/umbrella-tile/umbrella-tile.component';
import { WatercraftExposureEntity } from '../watercraft-exposures/watercraft-exposures.entity';

export const getWatercraftExcludedExposureState = createSelector(
  fromCore.getCoreState,
  (state) => state.watercraftExcludedExposures
);

export const {
  selectAll: getAllWatercraftExcludedExposures,
  selectEntities: getWatercraftExcludedExposures,
} = fromWatercraftExcludedExposures.adapter.getSelectors(
  getWatercraftExcludedExposureState
);

export const buildWatercraftExcludedExposureRequest = (
  watercraftExcludedExposure: WatercraftExcludedExposureEntity,
  correlationId?: string
) =>
  createSelector(
    ProductsSelectors.getProduct('PersonalUmbrella'),
    getAllFeatureFlags,
    (product, featureFlags): WatercraftExcludedExposureRequest => {
      return {
        watercraftExcludedExposureBody: {
          watercraftExcludedExposure: {
            productId: watercraftExcludedExposure?.productId,
            watercraftExcludedExposureId:
              watercraftExcludedExposure?.watercraftExcludedExposureId,
            underlyingPolicyNumber: null,
            carrier: watercraftExcludedExposure?.carrier,
            watercraftType: watercraftExcludedExposure?.watercraftType,
            hin: watercraftExcludedExposure?.hin,
            year: watercraftExcludedExposure?.year,
            make: watercraftExcludedExposure?.make,
            model: watercraftExcludedExposure?.model,
          },
        },
        quoteId: product?.quoteId,
        productType: product?.type,
      } as WatercraftExcludedExposureRequest;
    }
  );

export const getWatercraftExcludedExposuresAndProductInfo = createSelector(
  getAllWatercraftExcludedExposures,
  getUnderlyingPoliciesAndProductInfo,
  (
    watercraftExcludedExposures,
    underlyingPolicies
  ): WatercraftExcludedExposureAndProductInfo[] => {
    return watercraftExcludedExposures.map((exposure) => {
      return {
        watercraftExcludedExposure: exposure,
        underlyingPolicy: underlyingPolicies.find(
          (up) =>
            up.underlyingPolicy.underlyingPolicyNumber ===
            exposure.underlyingPolicyNumber
        )?.underlyingPolicy,
        product: underlyingPolicies.find(
          (up) =>
            up.underlyingPolicy.underlyingPolicyNumber ===
            exposure.underlyingPolicyNumber
        )?.product,
      };
    }) as WatercraftExcludedExposureAndProductInfo[];
  }
);

export const getWatercraftExcludedExposureTiles = createSelector(
  getWatercraftExcludedExposuresAndProductInfo,
  (watercraftExposures): UmbrellaTileModel[] => {
    return watercraftExposures.map((exposure) => {
      return {
        data: exposure.watercraftExcludedExposure,
        subheading: 'EXCLUDED EXPOSURE',
        titleLine1: `${exposure?.watercraftExcludedExposure?.year} ${exposure?.watercraftExcludedExposure?.make} ${exposure?.watercraftExcludedExposure?.model}`,
        titleLine2: '',
        subDetails: [
          {
            value: `${UmbrellaUtils.getCurrentCarrierNameLabel(
              exposure?.watercraftExcludedExposure?.carrier
            )} - ${UmbrellaUtils.getWatercraftTypeLabel(
              exposure?.watercraftExcludedExposure?.watercraftType
            )}`,
          },
        ],
        icon: exposure.product?.imageUrl,
        alt: 'Watercraft product icon',
        type: 'watercraft-exposure',
      };
    });
  }
);

export const getWatercraftExcludedExposureOptions = createSelector(
  getUmbrellaOptions,
  (umbrellaOptions): Nullable<WatercraftExposureOptionsModel> => {
    return umbrellaOptions?.watercraftExposures;
  }
);

export const getDeletedWatercraftExcludedExposures = createSelector(
  getWatercraftExcludedExposureState,
  (state) => {
    return Object.values(state.deleted || {}).filter(
      (v) => !!v
    ) as WatercraftExcludedExposureEntity[];
  }
);
