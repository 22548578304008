import { DigitalIdCardsTextResponse } from '@core/models/api/response/digital-id-cards-text-response.model';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../error/error.model';

export const sendAutoIdCards = createAction(
  '[Digital ID Cards] Send Digital ID Cards',
  props<{ mobileNumber: string }>()
);

export const sendAutoIdCardsSuccess = createAction(
  '[Digital ID Cards] Send Digital ID Cards Success',
  props<{ payload: DigitalIdCardsTextResponse }>()
);

export const sendAutoIdCardsFail = createAction(
  '[DriverVehiclePrefill] Send Digital ID Cards Fail',
  props<{ error: ErrorModel }>()
);
