import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Nullable } from '@shared/utils/type.utils';
import {
  PriceSummaryDisplayContent,
  PriceSummaryDownPayment,
} from '../price-summary-display/price-summary-display-content';

@Component({
  selector: 'nwx-package-display',
  templateUrl: './package-display.component.html',
  styleUrls: ['./package-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackageDisplayComponent {
  @Input() content: PriceSummaryDisplayContent = {};
  @Input() maxSizeMedium: Nullable<boolean>;
  @Output() showPdf = new EventEmitter<void>();
  @Output() openModal = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
