import {
  BOAT_TYPE,
  BOAT_NUMBER_NUMBER_OF_MOTORS,
  UMBRELLA_BOAT_ENGINE_TYPE,
} from '../../../app/shared/constants/app-constants';

export const umbrellaOptions = {
  underlyingPolicy: {
    policyType: [
      { id: 0, label: 'Personal Auto', value: 'PA' },
      { id: 1, label: 'Property', value: 'HO' },
      { id: 2, label: 'Watercraft', value: 'PWL' },
      { id: 3, label: 'Misc Vehicle', value: 'MV' },
    ],
    isNationwidePolicy: [
      { id: 0, label: 'Nationwide', value: true },
      { id: 1, label: 'Other Carrier', value: false },
    ],
    currentCarrierName: [
      { value: 'AAA_Ext', displayName: 'AAA' },
      { value: 'AARP_Ext', displayName: 'AARP' },
      { value: 'AIG_Ext', displayName: 'AIG' },
      { value: 'Allstate_Ext', displayName: 'Allstate' },
      { value: 'AmericanFamily_Ext', displayName: 'American Family' },
      { value: 'Ameriprise_Ext', displayName: 'Ameriprise' },
      { value: 'Amica_Ext', displayName: 'Amica' },
      { value: 'AutoOwners_Ext', displayName: 'Auto-Owners' },
      { value: 'Aviva_Ext', displayName: 'Aviva' },
      { value: 'Chubb_Ext', displayName: 'Chubb' },
      { value: 'Encompass_Ext', displayName: 'Encompass' },
      { value: 'Erie_Ext', displayName: 'Erie' },
      { value: 'Esurance_Ext', displayName: 'Esurance' },
      { value: 'FarmBureau_Ext', displayName: 'Farm Bureau' },
      { value: 'Farmers_Ext', displayName: 'Farmers' },
      { value: 'FiremansFund_Ext', displayName: "Fireman's Fund" },
      { value: 'GMAC_Ext', displayName: 'GMAC' },
      { value: 'Geico_Ext', displayName: 'GEICO' },
      { value: 'Grange_Ext', displayName: 'Grange' },
      { value: 'Hanover_Ext', displayName: 'Hanover' },
      { value: 'Hagerty_Ext', displayName: 'Hagerty' },
      { value: 'LibertyMutual_Ext', displayName: 'Liberty Mutual' },
      { value: 'Mercury_Ext', displayName: 'Mercury' },
      { value: 'Metlife_Ext', displayName: 'MetLife' },
      { value: 'Other', displayName: 'Other' },
      { value: 'Progressive_Ext', displayName: 'Progressive' },
      { value: 'SafeAuto_Ext', displayName: 'Safe Auto' },
      { value: 'Safeco_Ext', displayName: 'Safeco' },
      { value: 'Sentry_Ext', displayName: 'Sentry' },
      { value: 'StateAuto_Ext', displayName: 'State Auto' },
      { value: 'StateFarm_Ext', displayName: 'State Farm' },
      { value: 'TheHartford_Ext', displayName: 'The Hartford' },
      { value: 'Titan_Ext', displayName: 'Titan' },
      { value: 'Travelers_Ext', displayName: 'Travelers' },
      { value: 'TwentyFirst_Ext', displayName: '21st Century' },
      { value: 'USAA_Ext', displayName: 'USAA' },
      { value: 'Unitrin_Ext', displayName: 'Unitrin' },
      { value: 'Westfield_Ext', displayName: 'Westfield' },
    ],
    nwCurrentCarrierName: [
      { value: 'Nationwide_Ext', displayName: 'Nationwide' }, // NW
      { value: 'Allied_Ext', displayName: 'Allied' }, // NW
      { value: 'Harleysville_Ext', displayName: 'Harleysville' }, // NW
    ],
  },
  excludedExposureTypes: [
    { id: 1, value: 'vehicle', label: 'Vehicle' },
    { id: 2, value: 'location', label: 'Property' },
    { id: 3, value: 'watercraft', label: 'Watercraft' },
  ],
  vehicleExposures: {
    vehicleType: [
      { displayName: 'Antique', value: 'Antique_Ext' },
      { displayName: 'Named Non-owner', value: 'NamedNO_Ext' },
      { displayName: 'Offroad', value: 'OffRoad_Ext' },
      { displayName: 'Onroad', value: 'OnRoad_Ext' },
      { displayName: 'Private Passenger', value: 'PrivatePass_Ext' },
      /* { displayName: 'Passenger Vehicles', value: 'PP' },
      { displayName: 'Commercial - Non-passenger', value: 'Commercial' },
      { displayName: 'Livery Vehicles', value: 'PublicTransport' },
      { displayName: 'Special', value: 'Special' },
      { displayName: 'Utility Trailer', value: 'Trailer_Ext' },
      { displayName: 'Other', value: 'other' }, */
    ],
    hasCompOnly: [
      { id: 0, label: 'Yes', value: true },
      { id: 1, label: 'No', value: false },
    ],
  },
  locationExposures: {
    policyType: [
      { displayName: 'Homeowner', value: 'HO3' },
      { displayName: 'Condominium', value: 'HO6' },
      { displayName: 'Renter', value: 'HO4' },
    ],
    locationUsage: [
      { displayName: 'Primary', value: 'prim' },
      { displayName: 'Rental', value: 'rent' },
      {
        displayName: 'Additional Residence',
        value: 'AdditionalResidence_Ext',
      },
    ],
    numberOfUnits: [
      { id: 1, label: '1', value: 1 },
      { id: 2, label: '2', value: 2 },
      { id: 3, label: '3-4', value: 4 },
      { id: 4, label: '5-8', value: 8 },
      { id: 5, label: '9-16', value: 16 },
      { id: 6, label: '17+', value: 17 },
    ],
    dogBreed: [
      { value: 'BullTerriers', displayName: 'American Pit Bull Terrier' },
      { value: 'Chows', displayName: 'Chow-Chow' },
      { value: 'DobermanPinschers', displayName: 'Dobermans' },
      { value: 'PitBulls', displayName: 'Pit Bull Breeds' },
      { value: 'PresaCanarios_Ext', displayName: 'Presa Canarios' },
      { value: 'Rottweilers', displayName: 'Rottweilers' },
      {
        value: 'StaffordshireBullTerriers',
        displayName: 'Staffordshire Terrier',
      },
      { value: 'WolfHybrids', displayName: 'Wolf Hybrids' },
      { value: 'AMiixOfAnyOfTheseBreeds', displayName: 'Mixture of any above' },
      { value: 'Other', displayName: 'None of the above' },
    ],
  },
  watercraftExposures: {
    watercraftType: [
      { displayName: 'Boat', value: 'Boat_Ext' },
      { displayName: 'Personal Watercraft', value: 'Watercraft_Ext' },
    ],
    boatType: BOAT_TYPE,
    engineType: UMBRELLA_BOAT_ENGINE_TYPE,
    numOfMotors: BOAT_NUMBER_NUMBER_OF_MOTORS,
  },
};
