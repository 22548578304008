import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import {
  WatercraftExposureAndProductInfo,
  WatercraftExposureEntity,
} from './watercraft-exposures.entity';
import { ProductsSelectors } from '@core/store/selectors';
import * as fromWatercraftExposures from '@core/store/entities/exposures/watercraft-exposures/watercraft-exposures.reducer';
import { getAllFeatureFlags } from '@entities/feature-flag/feature-flag.selector';
import { WatercraftExposureRequest } from '@core/models/api/request/watercraft-exposure-request.model';
import {
  getAllUnderlyingPolicies,
  getUnderlyingPoliciesAndProductInfo,
} from '@entities/underlying-policy/underlying-policy.selector';
import { getUmbrellaOptions } from '@entities/metadata/metadata.selector';
import { Nullable } from '../../../../../shared/utils/type.utils';
import { WatercraftExposureOptionsModel } from '@entities/metadata/models/umbrella-options.model';
import { UmbrellaUtils } from '@shared/utils/umbrella.utils';
import { getAllVehiclesByProduct } from '@entities/vehicle/vehicle.selector';
import { VehicleUtils } from '@shared/utils/vehicle.utils';
import { BoatType, MotorTypeCode } from '@core/models/views/vehicle.model';
import { VehicleEntity } from '@entities/vehicle/vehicle.entity';
import { getWatercraftExcludedExposuresAndProductInfo } from '../watercraft-excluded-exposures/watercraft-excluded-exposures.selector';
import { Dictionary } from '@ngrx/entity';
import { UmbrellaTileModel } from '@shared/components/umbrella-tile/umbrella-tile.component';

export const getWatercraftExposureState = createSelector(
  fromCore.getCoreState,
  (state) => state.watercraftExposures
);

export const {
  selectAll: getAllWatercraftExposures,
  selectEntities: getWatercraftExposures,
} = fromWatercraftExposures.adapter.getSelectors(getWatercraftExposureState);

export const buildWatercraftExposureRequest = (
  watercraftExposure: WatercraftExposureEntity,
  correlationId?: string
) =>
  createSelector(
    ProductsSelectors.getProduct('PersonalUmbrella'),
    getAllFeatureFlags,
    (product, featureFlags): WatercraftExposureRequest => {
      return {
        watercraftExposureBody: {
          watercraftExposure: {
            productId: watercraftExposure?.productId,
            watercraftExposureId: watercraftExposure?.watercraftExposureId,
            underlyingPolicyNumber: watercraftExposure?.underlyingPolicyNumber,
            watercraftType: watercraftExposure?.watercraftType,
            boatInfo:
              watercraftExposure?.watercraftType === 'Boat_Ext'
                ? {
                    year: watercraftExposure?.boatInfo?.year,
                    manufacturer: watercraftExposure?.boatInfo?.manufacturer,
                    model: watercraftExposure?.boatInfo?.model,
                    boatType: watercraftExposure?.boatInfo?.boatType,
                    length: watercraftExposure?.boatInfo?.length,
                    engineType: watercraftExposure?.boatInfo?.engineType,
                    numberOfMotors:
                      watercraftExposure?.boatInfo?.numberOfMotors,
                    weight: watercraftExposure?.boatInfo?.weight,
                    horsepower: watercraftExposure?.boatInfo?.horsepower,
                    maximumSpeed: watercraftExposure?.boatInfo?.maximumSpeed,
                  }
                : null,
          },
        },
        quoteId: product?.quoteId,
        productType: product?.type,
      } as WatercraftExposureRequest;
    }
  );

export const getWatercraftExposuresAndProductInfo = createSelector(
  getAllWatercraftExposures,
  getUnderlyingPoliciesAndProductInfo,
  (
    watercraftExposures,
    underlyingPolicies
  ): WatercraftExposureAndProductInfo[] => {
    return watercraftExposures.map((exposure) => {
      return {
        watercraftExposure: exposure,
        underlyingPolicy: underlyingPolicies.find(
          (up) =>
            up.underlyingPolicy.underlyingPolicyNumber ===
            exposure.underlyingPolicyNumber
        )?.underlyingPolicy,
        product: underlyingPolicies.find(
          (up) =>
            up.underlyingPolicy.underlyingPolicyNumber ===
            exposure.underlyingPolicyNumber
        )?.product,
      };
    }) as WatercraftExposureAndProductInfo[];
  }
);

export const getWatercraftExposureTiles = createSelector(
  getWatercraftExposuresAndProductInfo,
  (watercraftExposures): UmbrellaTileModel[] => {
    return watercraftExposures.map((exposure) => {
      const titleLine1 =
        exposure.watercraftExposure.watercraftType === 'Boat_Ext'
          ? `${UmbrellaUtils.getBoatTypeLabel(
              exposure?.watercraftExposure?.boatInfo?.boatType
            )}`
          : `${UmbrellaUtils.getWatercraftTypeLabel(
              exposure?.watercraftExposure?.watercraftType
            )}`;
      const titleLine2 = `Underlying ${UmbrellaUtils.getWatercraftTypeLabel(
        exposure?.watercraftExposure?.watercraftType
      )}
        #${exposure.watercraftExposure?.watercraftExposureId}`;
      return {
        data: exposure.watercraftExposure,
        subheading: `${UmbrellaUtils.getCurrentCarrierNameLabel(
          exposure?.underlyingPolicy?.currentCarrierName
        )} - ${exposure.underlyingPolicy?.underlyingPolicyNumber}`,
        titleLine1,
        titleLine2,
        subDetails: [],
        icon: exposure.product?.imageUrl,
        alt: 'Watercraft product icon',
        type: 'watercraft-exposure',
      };
    });
  }
);

export const getWatercraftExposureOptions = createSelector(
  getUmbrellaOptions,
  (umbrellaOptions): Nullable<WatercraftExposureOptionsModel> => {
    return umbrellaOptions?.watercraftExposures;
  }
);

export const watercraftExposuresExist = createSelector(
  getAllWatercraftExposures,
  getWatercraftExcludedExposuresAndProductInfo,
  (exposures, excludedExposures) => {
    if (exposures.length > 0 || excludedExposures.length > 0) {
      return true;
    }
    return false;
  }
);

export const buildWatercraftExposures = createSelector(
  getAllVehiclesByProduct('Boat'),
  ProductsSelectors.getProductIfSelected('Boat'),
  (vehicles, boatProduct) => {
    const exposures: WatercraftExposureEntity[] = [];
    if (boatProduct?.isDsmActive) {
      vehicles?.forEach((vehicle) => {
        switch (vehicle.vehicleType) {
          case 'boat':
            switch (vehicle.type) {
              case 'PersonalWatercraft':
                exposures.push({
                  productType: vehicle.productType,
                  underlyingPolicyNumber: boatProduct?.quoteId,
                  watercraftType: 'Watercraft_Ext',
                } as WatercraftExposureEntity);
                break;
              default:
                exposures.push({
                  productType: vehicle.productType,
                  underlyingPolicyNumber: boatProduct?.quoteId,
                  watercraftType: 'Boat_Ext',
                  boatInfo: {
                    year: vehicle.year,
                    manufacturer: vehicle.make,
                    model: vehicle.model,
                    boatType: vehicle.type as BoatType,
                    length: vehicle.boatLength?.boatLengthFeet,
                    engineType: VehicleUtils.sanitizeEngineType(
                      vehicle.motorType as MotorTypeCode
                    ),
                    numberOfMotors: getNumberOfMotors(vehicle),
                    weight: vehicle.weight,
                    horsepower: vehicle.totalHorsepower,
                    maximumSpeed: vehicle.topSpeed,
                  },
                } as WatercraftExposureEntity);
                break;
            }
            break;
        }
      });
    }
    return exposures;
  }
);

function getNumberOfMotors(vehicle: VehicleEntity): string {
  if (!vehicle.numOfMotors) {
    return 'Zero';
  } else if (vehicle.numOfMotors === 1) {
    return 'One';
  } else {
    return 'TwoPlus';
  }
}

export const getWatercraftExposuresByUnderlyingPolicyNumber = (
  underlyingPolicyNumer: string
) =>
  createSelector(
    getAllWatercraftExposures,
    (watercraftExposures): WatercraftExposureEntity[] => {
      return watercraftExposures.filter((exposure) => {
        return exposure.underlyingPolicyNumber === underlyingPolicyNumer;
      });
    }
  );

export const getAllDeletedWatercraftExposures = createSelector(
  getWatercraftExposureState,
  (watercraftExposureState): WatercraftExposureEntity[] => {
    const deleted =
      watercraftExposureState.deleted as Dictionary<WatercraftExposureEntity>;
    return Object.values(deleted).map((key) => {
      return key as WatercraftExposureEntity;
    });
  }
);

export const getDeletedWatercraftExposuresWithActivePolicy = createSelector(
  getAllDeletedWatercraftExposures,
  getAllUnderlyingPolicies,
  (watercraftExposures, underlyingPolicies): WatercraftExposureEntity[] => {
    const underlyingPolicyNumbers = underlyingPolicies.map(
      (policy) => policy?.underlyingPolicyNumber
    );
    return watercraftExposures.filter((exposure) =>
      underlyingPolicyNumbers.includes(
        exposure?.underlyingPolicyNumber as string
      )
    );
  }
);

export const getDeletedWatercraftExposuresByUnderlyingPolicyNumber = (
  underlyingPolicyNumer: string
) =>
  createSelector(
    getAllDeletedWatercraftExposures,
    (watercraftExposures): WatercraftExposureEntity[] => {
      return watercraftExposures.filter((exposure) => {
        return exposure.underlyingPolicyNumber === underlyingPolicyNumer;
      });
    }
  );
