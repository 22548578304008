import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
} from '@angular/core';
import {
  getPageRepresentationByProductId,
  PeoplePageRepresentation,
  PNIPageRepresentation,
} from '@core/constants/pages';
import { ComponentStoreService } from '@core/services/component-store.service';
import { RouterService } from '@core/services/router.service';
import { DiscountEntity } from '@core/store/entities/discount/discount.entity';
import { ProductModel } from '@core/store/entities/product/product.model';
import { ComponentStore } from '@ngrx/component-store';
import { ComponentChanges } from '@shared/utils/general.utils';
import { ProductUtils } from '@shared/utils/product.util';
import { Nullable } from '@shared/utils/type.utils';
import { Observable } from 'rxjs';

export interface DiscountsListState {
  driverDiscountsApplicable?: boolean;
}
@Component({
  selector: 'nwx-discounts-list',
  templateUrl: './discounts-list.component.html',
  styleUrls: ['./discounts-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore],
})
export class DiscountsListComponent implements OnInit, OnChanges {
  @Input() discounts!: DiscountEntity[];
  @Input() product!: ProductModel;
  @Input() header!: string;
  @Input() maxSizeXSmall!: Nullable<boolean>;

  vm$!: Observable<DiscountsListState>;

  constructor(
    private routerService: RouterService,
    private readonly componentStore: ComponentStoreService<DiscountsListState>
  ) {}

  ngOnInit(): void {
    this.componentStore.initialize({
      driverDiscountsApplicable: ProductUtils.isDriverProduct(
        this.product?.type
      ),
    });
    this.vm$ = this.componentStore.get();
  }

  ngOnChanges(changes: ComponentChanges<DiscountsListComponent>): void {
    this.discounts = changes?.discounts?.currentValue || [];
  }

  navigateToMemberPage(): void {
    this.routerService.go({ path: [PeoplePageRepresentation.url] }, true);
  }

  navigateToPniPage(): void {
    this.routerService.go({ path: [PNIPageRepresentation.url] }, true);
  }

  navigateToPage() {
    const page = getPageRepresentationByProductId(this.product.type);
    this.routerService.go({ path: [page.url] }, true);
  }

  get hasPaperlessDiscount(): boolean {
    return (
      this.discounts.filter(
        (d) =>
          d.isDiscountApplied && d.description === 'Paperless Policy Discount'
      ).length > 0
    );
  }

  get eligibleForPaperless(): boolean {
    return this.product.type === 'PersonalAuto';
  }
}
