import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';

@Component({
  selector: 'nwx-start-new-quote',
  templateUrl: './start-new-quote.component.html',
  styleUrls: ['./start-new-quote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartNewQuoteComponent implements OnInit {
  public parent: NgbModalRef | null = null;
  public onlyAllowNewQuote = false;

  constructor(private router: Router, private window: Window) {}

  ngOnInit(): void {}

  continueCurrent(): void {
    if (this.parent) {
      this.parent.close();
    }
  }

  startNew(): void {
    this.window.location.href = this.window?.location.origin;
  }
}
