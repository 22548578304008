<div class="nav-toggle" (click)="openMobileNav()">
  <span>
    ({{ currentPageIndex }}/{{ countValidPages }})
    <bolt-icon
      name="chevron-down"
      title="toggle navigation"
      color="vibrant-blue"
      size="sm"
    ></bolt-icon>
  </span>
</div>
