<div class="modal-header">
  <h4 class="modal-title">Add or remove products</h4>
</div>
<div class="modal-body">
  <h4 class="custom-heading">Products in this quote</h4>
  <nwx-product-list-form
    [form]="form"
    [products]="(products$ | async) || []"
    [quoteState]="(quoteState$ | async) || ''"
    [stateSpecificFlags]="(stateSpecificFlags$ | async) || null"
    [isNSSAgent]="(user$ | async)?.role === userType.NSS ? true : false"
    [restrictedProducts]="(restrictedProducts$ | async) || []"
  ></nwx-product-list-form>
  <div class="edit-proof-blotter" [ngClass]="{ visible: readonly }"></div>
  <div class="passive-spinner-container" *ngIf="readonly">
    <nwx-passive-spinner label="Updating..."></nwx-passive-spinner>
  </div>
</div>
<div class="modal-footer">
  <bolt-button-bar>
    <bolt-button slot="back" (click)="closeModal()" [disabled]="readonly">Cancel</bolt-button>
    <bolt-button slot="forward" type="primary" (click)="updateProducts()" [disabled]="readonly"
      >Save products to quote</bolt-button
    >
  </bolt-button-bar>
</div>
