import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';
import { MetadataService } from '../services/metadata.service';

@Injectable({ providedIn: 'root' })
export class MetadataGuard {
  constructor(private metadataService: MetadataService) {}
  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.metadataService.getFeatureFlagsLoaded().pipe(
      tap((loaded) => {
        if (!loaded) {
          this.metadataService.dispatchLoadStateSpecificFlags();
          this.metadataService.dispatchLoadStates();
        }
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }
}
