import { ProducerUtils } from './../../shared/utils/producer.utils';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import {
  AgencyCodeSearchResponse,
  JITProducer,
  JITProducerResponse,
  ProducerLicenseDetailsResponse,
  ProducerResponse,
  ProducerSearch,
  ProducerSearchResultType,
  sanitizeAgencyCodeSearchResponse,
  sanitizeJITProducerResponse,
  sanitizeProducerLicenseDetailsResponse,
} from './producer-search.model';
import { DsmAdapter } from './dsm.adapter';
import { DistributionPartnerRoleRequest } from '../models/api/request/distribution-partner-role-request.model';
import { DistributionPartnerRoleResponse } from '../models/api/response/distribution-partner-role-response.model';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { StringUtils } from '@shared/utils/string.utils';
import { setProducerSearchResponse } from '@entities/agency/agency.action';
import {
  AgencyProducerFilter,
  sanitizeAgencyProducerFilterResponse,
} from '@core/models/api/response/producer-filter-response.model';

@Injectable({
  providedIn: 'root',
})
export class AgencyAdapter extends DsmAdapter {
  searchAllAgencyCodes(): Observable<AgencyCodeSearchResponse> {
    return this.request<AgencyCodeSearchResponse>(
      sanitizeAgencyCodeSearchResponse,
      'AgencyCodeSearch',
      'GET',
      '/agencycodes/search',
      'search-producer-codes',
      {
        baseUrl: `${this.appConfigService.config.pcEdgeAgentAccountManagementUrl}`,
        neverRetry: true,
      }
    );
  }

  searchAllProducerCodes(
    agencyCodes: string[],
    state?: string
  ): Observable<JITProducer[]> {
    return this.request<JITProducerResponse>(
      sanitizeJITProducerResponse,
      'ProducerCodeSearch',
      'POST',
      '/producers',
      'search-producer-codes',
      {
        baseUrl: `${this.appConfigService.config.jitLicensedProducersUrl}`,
        neverRetry: true,
        body: {
          submittedBy: 'AgencyExpress',
          licenseType: 'PL',
          state: state,
          agencyList: agencyCodes,
        },
      }
    ).pipe(map((response: any) => response?.data?.producerList || []));
  }

  getProducerLicenseDetails(producerCode: string): Observable<any> {
    return this.request<ProducerLicenseDetailsResponse>(
      sanitizeProducerLicenseDetailsResponse,
      'ProducerCodeSearch',
      'GET',
      `/${producerCode}/licenses?systemId=AgencyExpress&active=true`,
      'search-producer-codes',
      {
        baseUrl: `${this.appConfigService.config.jitLicenseDetailsUrl}`,
        neverRetry: true,
      }
    );
  }

  // AAF product restrictions for producer code + state
  getProducerFilters(npn: string): Observable<AgencyProducerFilter> {
    return this.request<any>(
      sanitizeAgencyProducerFilterResponse,
      'ProducerCodeSearch',
      'GET',
      `/producerFilters?systemId=AgencyExpress&npn=${npn}`,
      'search-producer-codes',
      {
        baseUrl: `${this.appConfigService.config.agentAppointmentFilteringUrl}`,
        neverRetry: true,
      }
    ).pipe(
      map((response: any) => (response?.data as AgencyProducerFilter) || {})
    );
  }

  getAgencyFilters(
    agencyCode: string,
    state: string
  ): Observable<AgencyProducerFilter> {
    return this.request<any>(
      sanitizeAgencyProducerFilterResponse,
      'ProducerCodeSearch',
      'GET',
      `/agencyFilters?systemId=AgencyExpress&agency=${agencyCode}&state=${state}`,
      'search-producer-codes',
      {
        baseUrl: `${this.appConfigService.config.agentAppointmentFilteringUrl}`,
        neverRetry: true,
      }
    ).pipe(
      map((response: any) => (response?.data as AgencyProducerFilter) || {})
    );
  }

  producerCodeSearch(state?: string): Observable<ProducerSearch> {
    return this.searchAllAgencyCodes().pipe(
      switchMap((agencyResponse) => {
        const agencyCodes = [
          ...new Set(
            agencyResponse.agencies
              ?.map((p: any) => p.agencyCode)
              .filter((code: any) => !!code)
          ),
        ] as string[];
        return this.searchAllProducerCodes(agencyCodes, state).pipe(
          map((producerResponse) => {
            const producers: ProducerResponse[] = producerResponse.map(
              (res) => {
                const agencyCode = res.producerCode?.slice(0, 8);
                return {
                  agencyCode: agencyCode || '',
                  agentName: `${res.firstName || ''} ${res.lastName}`,
                  producerCode: `${agencyCode} - ${res.producerCode?.slice(
                    -3
                  )}`,
                  agentState: agencyResponse.agencies.find(
                    (a: any) => a.agencyCode === agencyCode
                  )?.agencyState,
                };
              }
            );
            let sortedProducers = Array.from(producers).sort((p1, p2) =>
              p1.producerCode >= p2.producerCode ? 1 : -1
            );
            sortedProducers = sortedProducers.map((p) => {
              if (ProducerUtils.isPDSProducer(p.producerCode)) {
                return {
                  ...p,
                  agencyCode: p.producerCode.slice(2),
                };
              } else {
                return p;
              }
            });
            let type: ProducerSearchResultType;
            const hasAmfCodes = producers.some((p) =>
              p.producerCode.includes(' - ')
            );
            const hasPdsCodes = producers.some(
              (p) => !p.producerCode.includes(' - ')
            );
            if (hasAmfCodes && hasPdsCodes) {
              type = 'MIXED';
            } else if (hasAmfCodes) {
              type = 'AMF';
            } else {
              type = 'PDS';
            }

            const producerSearchResult = {
              type,
              producers: sortedProducers,
            } as ProducerSearch;

            this.store.dispatch(
              setProducerSearchResponse({ search: producerSearchResult })
            );
            return producerSearchResult;
          })
        );
      })
    );
  }

  getDistributionPartnerRoles(
    request: DistributionPartnerRoleRequest
  ): Observable<DistributionPartnerRoleResponse> {
    const url = `${this.appConfigService.config.distributionPartnerManagementUrl}/distributionpartnerroles/${request.legacyIdentifier}`;
    let params = new HttpParams();
    params = params.set('legacyIdentifierType', request.legacyIdentifierType);
    params = params.set(
      'legacyAdministrativeSystem',
      request.legacyAdministrativeSystem
    );
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this.appConfigService.config.apiKey)
      .set(
        'X-NWD-Scope',
        this.appConfigService.config.distributionPartnerManagementScope
      )
      .set(
        'X-NW-Message-ID',
        sessionStorage.sessionId || StringUtils.generateUuid()
      );

    const correlationId = this.log.logApiRequest(
      'GET',
      url,
      'get-distribution-partner-roles',
      {
        params: this.getParameters(params),
        legacyIdentifier: request.legacyIdentifier,
      }
    );
    return this.httpClient
      .get<DistributionPartnerRoleResponse>(url, {
        params,
        headers,
      })
      .pipe(
        tap((response) =>
          this.log.logApiResponse(
            correlationId,
            200,
            this.getStatesFromResponse(response)
          )
        ),
        map((response) => response || {}),
        catchError((error) => this.log.logApiError(correlationId, error))
      );
  }

  getAMFProducerOrganizationRole(
    amfAgencyCode: string
  ): Observable<DistributionPartnerRoleResponse> {
    const url = `${this.appConfigService.config.distributionPartnerManagementUrl}/distributionpartnerroles/${amfAgencyCode}`;
    let params = new HttpParams();
    params = params.set(
      'legacyIdentifierType',
      'Legacy Producer Organization Role Number'
    );
    params = params.set('legacyAdministrativeSystem', 'AMF');
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this.appConfigService.config.apiKey)
      .set(
        'X-NWD-Scope',
        this.appConfigService.config.distributionPartnerManagementScope
      )
      .set(
        'X-NW-Message-ID',
        sessionStorage.sessionId || StringUtils.generateUuid()
      );

    const correlationId = this.log.logApiRequest(
      'GET',
      url,
      'get-amf-producer-organization-role',
      {
        params: this.getParameters(params),
        legacyIdentifier: amfAgencyCode,
      }
    );
    return this.httpClient
      .get<DistributionPartnerRoleResponse>(url, {
        params,
        headers,
      })
      .pipe(
        tap((response) =>
          this.log.logApiResponse(
            correlationId,
            200,
            this.getStatesFromResponse(response)
          )
        ),
        map((response) => response || {}),
        catchError((error) => this.log.logApiError(correlationId, error))
      );
  }

  private getParameters(params: HttpParams): { [x: string]: string | null }[] {
    const paramsArray = params.keys().map((x) => ({ [x]: params.get(x) }));

    return paramsArray;
  }

  private getStatesFromResponse(
    response: DistributionPartnerRoleResponse
  ): any {
    const activelyLicensedAndAppointedStates =
      ProducerUtils.getActiveLicenseSummaries(response)
        .map((license) => license.licenseState)
        .sort();
    const residentStates = response.licenseSummaries
      .filter((summary) => summary.residentStateIndicator === 'Y')
      .map((summary) => summary.licenseState)
      .sort();

    return {
      activelyLicensedAndAppointedStates,
      residentStates,
    };
  }
}
