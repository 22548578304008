import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { PassiveSpinnerComponent } from './passive-spinner.component';

@NgModule({
  imports: [],
  declarations: [PassiveSpinnerComponent],
  exports: [PassiveSpinnerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PassiveSpinnerModule {}
