import { Injectable } from '@angular/core';
import {
  StateSpecificFlagsModel,
  stateSpecificFlags,
} from '@assets/metadata/stateSpecificFlags';
import { Store } from '@ngrx/store';
import { Nullable } from '@shared/utils/type.utils';
import { Observable } from 'rxjs';
import { FeatureFlagsModel } from '../store/entities/feature-flag/feature-flag.model';
import {
  getAllFeatureFlags,
  getFeatureFlag,
} from '../store/entities/feature-flag/feature-flag.selector';
import { CoreState } from '../store/reducers';
import { ProductType } from '@core/models/api/dsm-types';

export interface FeatureFlagsCriteria {
  role?: string;
  effectiveDate?: string;
  quoteState?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(private store: Store<CoreState>) {}

  getAllFeatureFlags(): Observable<FeatureFlagsModel> {
    return this.store.select(getAllFeatureFlags);
  }

  isFlagApplicable(
    stateSpecficFlags: Nullable<StateSpecificFlagsModel>,
    quoteState: string,
    featureFlag: keyof StateSpecificFlagsModel,
    productType: ProductType
  ): boolean {
    if (stateSpecficFlags?.[featureFlag].states.includes(quoteState)) {
      if (stateSpecificFlags?.[featureFlag].offerings) {
        return (
          stateSpecificFlags?.[featureFlag].offerings?.includes(productType) ??
          false
        );
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  getFeatureFlag(name: keyof FeatureFlagsModel): Observable<boolean> {
    return this.store.select(getFeatureFlag(name));
  }
}
