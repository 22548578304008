import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalEventMetadata } from '@app/coverages/scheduled-categories/scheduled-categories-container/scheduled-categories-container.component';
import { OutboardMotor } from '@core/models/views/vehicle.model';
import { ComponentStoreService } from '@core/services/component-store.service';
import { NavigationService } from '@core/services/navigation.service';
import { VehicleService } from '@core/services/vehicle.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentStore } from '@ngrx/component-store';
import {
  PillModel,
  WellContent,
} from '@shared/components/pill-list/pill-list.component';
import { ModalResultWithMetadata } from '@shared/services/modal.service';
import { Nullable } from '@shared/utils/type.utils';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ModalState {
  wellContent?: WellContent;
  modalTitle?: string;
  disableSubmissionBtns?: boolean;
  localMotor?: Partial<OutboardMotor>;
  listOfMotors?: Nullable<OutboardMotor[]>;
  motorLimitReached?: boolean;
}
@Component({
  selector: 'nwx-outboard-motor-modal',
  templateUrl: './outboard-motor-modal.component.html',
  styleUrls: ['./outboard-motor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore],
})
export class OutboardMotorModalComponent implements OnInit {
  @Input() motorList!: OutboardMotor[];
  @Input() currentMotor!: Partial<OutboardMotor>;
  @Input() vehicleId!: string;
  @Input() vehicleNumOfMotors!: number;

  formSaved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  form!: FormGroup;
  vm$!: Observable<ModalState>;
  mode: 'edit' | 'add' = 'add';
  showSpinner = false;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private readonly componentStore: ComponentStoreService<ModalState>,
    private navigationService: NavigationService,
    private cd: ChangeDetectorRef,
    private vehicleService: VehicleService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({});
    // if any property exists on the motor, it's been touched before
    // year for simplicity
    if (this.currentMotor?.year) {
      this.mode = 'edit';
    }
    this.componentStore.initialize({
      localMotor: this.currentMotor,
      listOfMotors: this.motorList,
      motorLimitReached: this.motorLimitReached(this.motorList),
      wellContent: {} as WellContent,
    });
    this.vm$ = this.componentStore.get();
    this.populateWellContent(this.motorList);
  }

  onFormReady(name: string, event: FormGroup) {
    this.form?.setControl(name, event);
  }

  close(): void {
    this.modal.close();
  }

  // update the running list of motors and close the modal with that data
  removeItem(
    motor: Partial<OutboardMotor> | undefined,
    existingMotors: Nullable<OutboardMotor[]>
  ): void {
    const indexToRemoveFrom =
      existingMotors?.findIndex(
        (m) => m.nwmotorNumber === motor?.nwmotorNumber
      ) || 0;

    const updatedMotorList = [...(existingMotors || [])];
    updatedMotorList?.splice(indexToRemoveFrom, 1);
    this.modal.close({
      items: updatedMotorList,
      metadata: 'removeItem',
    } as ModalResultWithMetadata);
  }

  saveItem(
    currentMotor: Nullable<OutboardMotor>,
    listOfmotors: Nullable<OutboardMotor[]>,
    metadata?: ModalEventMetadata
  ): void {
    if (this.form?.valid) {
      // if it's in our local list update it if not add it
      let list = [...(listOfmotors || [])];

      if (list.length) {
        const matchingMotorIndex = list.findIndex(
          (motor) => motor.nwmotorNumber === currentMotor?.nwmotorNumber
        );
        if (matchingMotorIndex >= 0) {
          list[matchingMotorIndex] = currentMotor as OutboardMotor;
        } else {
          list.push(currentMotor as OutboardMotor);
        }
      } else {
        list = [];
        list.push(currentMotor as OutboardMotor);
      }

      this.componentStore.update({ listOfMotors: list });

      // if we are keeping the modal open for users to add additional motors
      if (metadata === 'keepModalOpen') {
        // instantiate a new motor object and update component store
        let newLocalMotor = {
          nwmotorNumber: this.getHighestIndex(list || []),
        };
        this.componentStore.update({ localMotor: newLocalMotor });
        this.cd.detectChanges();
        this.populateWellContent(list);

        this.vehicleService.storeVehicle({
          vehicleId: this.vehicleId,
          motorDetails: list,
          numOfMotors: list.length,
        });
        this.formSaved$.next(false);
        this.formSaved$.next(true);

        let motorLimitReached = this.motorLimitReached(list);
        this.componentStore.update({ motorLimitReached });
      } else {
        // close the modal with the list of motors to be added
        this.modal.close({
          items: list,
          metadata,
        } as ModalResultWithMetadata);
      }
    } else {
      this.navigationService.submitPage();
    }
  }

  motorLimitReached(list: Nullable<OutboardMotor[]>): boolean {
    let added = (list?.length || 0) + 1;
    return added === +this.vehicleNumOfMotors;
  }

  // just update component store, we don't write to the store until save button is clicked
  onValueChange(event: Partial<OutboardMotor>): void {
    this.componentStore.update({ localMotor: event });
  }

  editExisting(event: Nullable<OutboardMotor>): void {
    this.componentStore.update({ localMotor: event as OutboardMotor });
  }

  getHighestIndex(motors: OutboardMotor[]): number {
    let numbers = motors.map((m) => m.nwmotorNumber || 0);
    let res = Math.max(...numbers) + 1;
    return res;
  }

  // get UI content
  populateWellContent(motors: Nullable<OutboardMotor[]>): void {
    const wellContent = {
      description: `Motor details added:`,
      displayButton: false,
      entityId: this.vehicleId || '',
      itemList: this.getPillItemsFromMotors(motors),
    } as WellContent;
    this.componentStore.update({ wellContent });
  }

  // get UI content
  getPillItemsFromMotors(motors: Nullable<OutboardMotor[]>): PillModel[] {
    if (!motors || !motors.length) {
      return [];
    }
    return motors
      .filter((m) => m.year && m.make && m.model)
      .map((m) => {
        return {
          name: `${m.year || ''} ${m.make || ''} - ${
            m.horsepower || 'unknown'
          } HP`,
          item: m,
        };
      });
  }

  onCallInFlight(callInFlight: boolean): void {
    this.showSpinner = callInFlight;
    this.cd.markForCheck();
  }
}
