import { createSelector } from '@ngrx/store';
import { getAllSelectedMemberModels } from '@core/store/entities/member/member.selector';
import { getProduct } from '../product/product.selectors';
import { PeoplePageUtils } from '@shared/utils/pages/people-page.utils';
import { ProductType } from '@core/models/api/dsm-types';
import { HouseholdMemberRequest } from '@core/models/api/request/household-member-request.model';
import { HouseholdMemberEntity } from './household-member.model';

export const buildHouseholdMemberRequest = (
  householdMember: HouseholdMemberEntity,
  correlationId?: string
) =>
  createSelector(
    getProduct(householdMember.productType as ProductType),
    (product) => {
      return {
        householdMember,
        correlationId,
        quoteId: product?.quoteId,
        productType: product?.type,
      } as HouseholdMemberRequest;
    }
  );

export const buildHouseholdMembers = createSelector(
  getAllSelectedMemberModels,
  (members) =>
    members.map((member) =>
      PeoplePageUtils.CreateHouseholdMember(member, 'PersonalUmbrella')
    )
);
