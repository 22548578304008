import { Injectable } from '@angular/core';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, from, of } from 'rxjs';
import {
  concatMap,
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  addError,
  clearErrorsByType,
  clearPageErrors,
  removeError,
  removeErrorsById,
} from './error.action';
import { getErrorIds, getErrors, getUnusedId } from './error.selector';
import { LogService } from '@core/services/log.service';
import { ProductType } from '@core/models/api/dsm-types';
import { ErrorHandlerService } from '@core/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorEffects {
  static readonly RECORD_COUNT_LIMIT = 5;
  static readonly RECORD_COUNT_TARGET = 2;
  static readonly EXPIRATION_TIME_MS = 5000;

  constructor(
    private store: Store,
    private actions: Actions,
    private errorSanitizerService: ErrorSanitizerService,
    private errorHandlerService: ErrorHandlerService,
    private logService: LogService
  ) {}

  detectAllErrors$ = createEffect(
    () =>
      this.actions.pipe(
        filter((action) => this.isErrorAction(action)),
        map((action) => this.extractPayloadFromSourceAction(action)),
        filterOutNull(),
        concatMap((error) => from(this.splitError(error))),
        withLatestFrom(this.store.select(getErrors)),
        filter(([newError, existingErrors]) =>
          this.newErrorIsHelpful(newError, existingErrors)
        ),
        map(([newError, existingErrors]) => newError),
        withLatestFrom(this.store.select(getUnusedId)),
        map(([error, id]) => ({ severity: 'information', ...error, id })),
        map((error) => addError({ payload: error }))
      ) as Observable<Action>
  );

  clearOutErrors$ = createEffect(() =>
    this.actions.pipe(
      filter((action) => !this.isErrorAction(action)),
      map((action) => this.extractSourceAction(action)),
      filterOutNull(),
      map((error) => clearErrorsByType({ payload: error }))
    )
  );

  dropOldRecordsIfTooMany$ = createEffect(() =>
    this.actions.pipe(
      ofType(addError),
      switchMap(() => {
        return (this.store.select(getErrorIds) as Observable<number[]>).pipe(
          take(1)
        );
      }),
      filter((ids) => ids.length > ErrorEffects.RECORD_COUNT_LIMIT),
      map((ids) =>
        removeErrorsById({
          ids: ids.slice(0, ids.length - ErrorEffects.RECORD_COUNT_TARGET),
        })
      )
    )
  );

  scheduleErrorExpiration$ = createEffect(() =>
    this.actions.pipe(
      ofType(addError),
      map((action) => action.payload),
      filter((error) => !!error.expirationTimeMs),
      mergeMap((error) =>
        of(removeError({ id: error.id || 0 })).pipe(
          // See 'filter' above.
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          delay(error.expirationTimeMs! - Date.now())
        )
      )
    )
  );

  translateError$ = createEffect(() =>
    this.actions.pipe(
      ofType(addError),
      map((action) => action.payload),
      filter((originalError) => !originalError.sourcedByErrorHandler),
      mergeMap((originalError) => {
        return this.errorHandlerService
          .searchErrorForTranslation(originalError)
          .pipe(
            switchMap((newError) =>
              from([
                removeError({ id: originalError.id || 0 }),
                addError({ payload: newError }),
              ])
            )
          );
      })
    )
  );

  private isInvalidErrorAction(action: Action): boolean {
    return action.type === '[Error] Set Validation Error' ? true : false;
  }

  // TODO - We might need this, but right now it's doing nothing
  clearPageErrors$ = createEffect(
    () => this.actions.pipe(ofType(clearPageErrors)),
    { dispatch: false }
  );

  private isActualError(action: Action): boolean {
    // Some actions end "Error" or "Fail" but are not actual errors:
    if (
      action.type === '[Account] Clear Account Error' ||
      action.type === '[People] Submit People Error' ||
      action.type === '[Product] Clear Error'
    ) {
      return false;
    }
    return true;
  }

  isLoggedErrorAction(action: Action): boolean {
    if (this.isActualError(action) && !this.isInvalidErrorAction(action)) {
      return true;
    }
    return false;
  }

  private isErrorAction(action: Action): boolean {
    if (
      (this.isActualError(action) && action.type.endsWith('Error')) ||
      action.type.endsWith('Fail')
    ) {
      return true;
    }
    return false;
  }

  logErrorMessages$ = createEffect(
    () =>
      this.actions.pipe(
        filter((action) => this.isLoggedErrorAction(action)),
        ofType(addError),
        map((action) => action.payload),
        tap((error) =>
          this.logService.logUiEvent(
            'display-error',
            error.displayMessage,
            'error'
          )
        )
      ),
    { dispatch: false }
  );

  private extractPayloadFromSourceAction(action: Action): ErrorModel | null {
    let payload = null;
    let productType: ProductType | undefined = undefined;
    if (action.hasOwnProperty('error')) {
      payload = (action as unknown as { error: unknown }).error;
    } else if (action.hasOwnProperty('payload')) {
      payload = (action as unknown as { payload: unknown }).payload;
    } else if (action.hasOwnProperty('response')) {
      payload = (action as unknown as { response: unknown }).response;
    } else {
      payload = action;
    }
    if (action.hasOwnProperty('productId')) {
      productType = (action as unknown as { productId: any }).productId;
      payload = {
        ...(payload as any),
        productId: productType,
      };
    }
    if (action.hasOwnProperty('entityId')) {
      payload = {
        ...(payload as any),
        entityId: (action as unknown as { entityId: unknown }).entityId,
      };
    }

    return {
      ...this.errorSanitizerService.sanitizeError(payload, productType),
      sourceActionType: action.type,
    };
  }

  private splitError(error: ErrorModel): ErrorModel[] {
    const splitMessage = error.displayMessage?.split(' | ') || [];
    const returnArray =
      splitMessage?.map(
        (message) =>
          ({
            ...error,
            displayMessage: message,
          } as ErrorModel)
      ) || [];
    return returnArray as ErrorModel[];
  }

  private extractSourceAction(action: any): ErrorModel | null {
    let payload = null;
    if (!action.type.endsWith('Success')) {
      return null;
    }
    if (action.hasOwnProperty('productId')) {
      payload = {
        ...(payload as any),
        productType: (action as unknown as { productId: unknown }).productId,
      };
    }
    if (action.hasOwnProperty('productType')) {
      payload = {
        ...(payload as any),
        productType: (action as unknown as { productType: unknown })
          .productType,
      };
    }
    if (action.payload && typeof action.payload === 'object') {
      if (action?.payload.hasOwnProperty('productType')) {
        payload = {
          ...(payload as any),
          productType: (action.payload as unknown as { productType: unknown })
            .productType,
        };
      }
      if (action?.payload.hasOwnProperty('entityId')) {
        payload = {
          ...(payload as any),
          entityId: (action.payload as unknown as { entityId: unknown })
            .entityId,
        };
      }
      if (action?.payload.hasOwnProperty('vehicleId')) {
        payload = {
          ...(payload as any),
          entityId: (action.payload as unknown as { vehicleId: unknown })
            .vehicleId,
        };
      }
    }
    if (Array.isArray(action?.payload)) {
      if (action?.payload[0]?.hasOwnProperty('productId')) {
        payload = {
          ...(payload as any),
          productType: (action.payload[0] as unknown as { productId: unknown })
            .productId,
        };
      }
    }
    if (action.hasOwnProperty('entityId')) {
      payload = {
        ...(payload as any),
        entityId: (action as unknown as { entityId: unknown }).entityId,
      };
    }
    return {
      ...payload,
      sourceActionType: action.type,
    };
  }

  private newErrorIsHelpful(
    newError: ErrorModel,
    existingErrors: ErrorModel[]
  ): boolean {
    if (!existingErrors.length) {
      return true;
    }
    if (!newError.displayMessage) {
      return false;
    }
    if (
      newError.displayMessage === ErrorSanitizerService.GENERIC_ERROR_MESSAGE
    ) {
      return false;
    }
    if (
      existingErrors.find(
        (e) =>
          e.productType === newError.productType &&
          e.displayMessage === newError.displayMessage
      )
    ) {
      return false;
    }
    return true;
  }
}
