<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'checkbox'">
    <svg class="checkbox" [ngClass]="{'disabled':disabled}" viewBox="0 0 28 28">
      <rect class="cb-box" x="1" y="1" width="26" height="26" rx="4"></rect>
      <polygon class="cb-check" *ngIf="checked"
        points="21.3565 8.3185 11.4075 18.2675 6.6445 13.5025 5.9365 14.2095 10.7015 18.9735 11.4075 19.6815 12.1155 18.9735 22.0635 9.0255"
      ></polygon>
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'radio'">
    <svg class="checkbox" [ngClass]="{'disabled':disabled}" viewBox="0 0 30 30">
      <circle class="rd-circle" cx="15" cy="15" r="14"></circle>
      <circle class="rd-dot" *ngIf="checked" cx="15" cy="15" r="7"></circle>
    </svg>
  </ng-container>
</ng-container>
