import * as fromPeople from '@app/core/store/entities/member/member.reducer';
import {
  HeaderCardItem,
  SubmitPeoplePageData,
  SubmitPniPageData,
} from '@core/interfaces/interfaces';
import { PeoplePageModel } from '@core/models/views/people-page-model';
import { MemberFormOptions } from '@core/models/views/person-form-options';
import * as fromCore from '@core/store/reducers';
import { createSelector } from '@ngrx/store';
import { PersonUtils } from '@shared/utils/person.utils';
import { getAddressByType } from '@core/store/entities/address/address.selector';
import { getSortedPolicyLineModifier } from '../eligible-discounts/eligible-discounts.selector';
import { getAllFeatureFlags } from '../feature-flag/feature-flag.selector';
import {
  getOverallFurthestQuoteStatus,
  getSelectedProducts,
  getSelectedProductTypes,
  getSelectedProductsIncludingNWXInactive,
  getSelectedDriverProducts,
} from '../product/product.selectors';
import { getQuoteState } from '../session/session.selector';
import { PniPageModel } from '@core/models/views/pni-page-model';
import { ProductType } from '@core/models/api/dsm-types';
import { MemberEntity } from '@app/core/store/entities/member/member.reducer';
import { genderOptions } from '@shared/constants/suffix-constants';
import { PolicyHolderEntity } from '../policyholder/policyholder.entity';
import { ProductsSelectors, SessionSelectors } from '@core/store/selectors';
import { Nullable } from '@shared/utils/type.utils';
import { MemberModel } from '@core/models/views/person.model';

export const getMemberState = createSelector(
  fromCore.getCoreState,
  (state) => state.members
);

export const {
  selectAll: getAllMembers,
  selectEntities: getMemberEntities,
  selectTotal: getTotalMembers,
} = fromPeople.adapter.getSelectors(getMemberState);

export const getAllMemberModels = createSelector(
  getAllMembers,
  getQuoteState,
  (people, quoteState) => PersonUtils.createPeopleModels(people, quoteState)
);

export const getAllSelectedMemberModels = createSelector(
  getAllMemberModels,
  (people) => {
    return people.filter((p) => p.selected === true);
  }
);

export const getAllSelectedDriverMemberModels = (productType: ProductType) =>
  createSelector(getAllSelectedMemberModels, (drivers) => {
    return drivers.filter(
      (driver) =>
        driver?.driverRoles?.find(
          (d) => d.driverType === 'Driver' && d.productType === productType
        ) || driver.driverType === 'Driver'
    );
  });

function compareProductTypesForPniSearchOrder(
  a: ProductType,
  b: ProductType
): number {
  // Sort them as strings. That doesn't make sense businessly, but I don't know what would.
  // The order must be deterministic.
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export const getPrimaryNamedInsured = createSelector(
  getAllMemberModels,
  ProductsSelectors.getSelectedProductTypes,
  (people, productTypes) => {
    productTypes = [...productTypes].sort(compareProductTypesForPniSearchOrder);
    for (const productType of productTypes) {
      const person = people.find((p) =>
        p.policyRoles.find(
          (r) =>
            r.entityType === 'policyHolder' &&
            r.productType === productType &&
            r.roleSequence === 'primary'
        )
      );
      if (person) {
        return person as PolicyHolderEntity;
      }
    }
    return (people.find(
      (p) => p.policyHolderType === 'PolicyPriNamedInsured'
    ) || people[0]) as PolicyHolderEntity | undefined;
  }
);

export const getPrimaryNamedInsuredForProduct = (productType: ProductType) =>
  createSelector(
    getAllMemberModels,
    (people) =>
      (people.find((p) =>
        p.policyRoles.find(
          (r) =>
            r.entityType === 'policyHolder' &&
            r.productType === productType &&
            r.roleSequence === 'primary'
        )
      ) ||
        people.find(
          // This comes up during initiate. PNI exists, but no roles assigned yet.
          (p) =>
            p.policyRoles.length === 0 &&
            p.policyHolderType === 'PolicyPriNamedInsured'
        ) ||
        people.find(
          // During initiate of a second product, use PNI from any other product.
          (p) =>
            p.policyRoles.find(
              (r) =>
                r.entityType === 'policyHolder' && r.roleSequence === 'primary'
            )
        )) as PolicyHolderEntity | undefined
  );

// First product that we can find a PNI for, typically productTypes[0], return that person.
export const getPrimaryNamedInsuredForMultipleProducts = (
  productTypes: ProductType[]
) =>
  createSelector(getAllMemberModels, (people) => {
    for (const productType of productTypes) {
      const person = people.find((p) =>
        p.policyRoles.find(
          (r) =>
            r.entityType === 'policyHolder' &&
            r.productType === productType &&
            r.roleSequence === 'primary'
        )
      );
      if (person) {
        return person as PolicyHolderEntity;
      }
    }
    return undefined;
  });

export const getAllMemberModelsWithOptions = createSelector(
  getAllMemberModels,
  getAllFeatureFlags,
  getOverallFurthestQuoteStatus,
  getSelectedProducts,
  (peopleModels, featureFlags, quoteStatus, products) => {
    return peopleModels.map((person) => {
      return {
        ...person,
        options: PersonUtils.formOptionsForPerson(
          person,
          featureFlags,
          quoteStatus || 'Draft',
          products
        ) as MemberFormOptions,
      };
    });
  }
);

export const getSelectedMemberNames = createSelector(
  getAllSelectedMemberModels,
  (members): string[] =>
    members.map(
      (member) => `${member.person?.firstName} ${member.person?.lastName}`
    )
);

export const getGenderOptions = createSelector(
  getSelectedProductTypes,
  getAllFeatureFlags,
  (products, featureFlags) => {
    const newGenderOptions = [...genderOptions];

    if (featureFlags.showGenderX || featureFlags.showGenderXPowersports)
      newGenderOptions.push({
        id: 2,
        label: 'Non Specified Gender',
        value: 'X',
      });
    return JSON.parse(JSON.stringify(newGenderOptions));
  }
);

export const getMemberPageModel = createSelector(
  getAllMemberModelsWithOptions,
  getSelectedProductsIncludingNWXInactive,
  getSelectedDriverProducts(),
  getSortedPolicyLineModifier('AssociationMember', 'MSA'),
  getSortedPolicyLineModifier('AssociationMember', 'RV'),
  (
    people,
    selectedProducts,
    selectedDriverProducts,
    ridingAssociation,
    rvAssociation
  ) => {
    return {
      people,
      selectedProducts,
      selectedDriverProducts,
      ridingAssociation,
      rvAssociation,
    } as PeoplePageModel;
  }
);

export const getPniPageModel = createSelector(
  getPrimaryNamedInsured,
  getAddressByType('Default'),
  getAddressByType('Mailing'),
  getAllFeatureFlags,
  getOverallFurthestQuoteStatus,
  getSelectedProducts,
  (
    pni,
    accountAddress,
    mailingAddress,
    featureFlags,
    quoteStatus,
    products
  ) => {
    return {
      pni: {
        ...pni,
        options: {
          ...(PersonUtils.formOptionsForPerson(
            pni as MemberEntity,
            featureFlags,
            quoteStatus || 'Draft',
            products
          ) as MemberFormOptions),
          driverRelationToPNI: {
            visible: false,
            category: 'Other',
          },
        },
      },
      accountAddress,
      mailingAddress,
    } as PniPageModel;
  }
);

export const getAllMemberHeaderItems = createSelector(
  getAllMemberModels,
  (memberModels) => {
    return memberModels.map(
      (member) =>
        ({
          title:
            member?.person?.firstName && member.person?.lastName
              ? `${member.person?.firstName} ${member.person?.lastName}`
              : 'New Member',
          selected: member.selected,
          entityId: member.entityId,
          entityIds: PersonUtils.buildEntityIds(member),
          prefillId: member.prefillId,
          saved: PersonUtils.isPersonSaved(member),
          unselectable: PersonUtils.hasNonDeletablePolicyRole(
            member.policyRoles
          ),
          imageSource:
            '../../../../../assets/images/icons/person-circle-colorized.svg',
        } as HeaderCardItem)
    );
  }
);

export const getSubmitPeoplePageData = createSelector(
  getSelectedProducts,
  (products) =>
    ({
      products,
    } as SubmitPeoplePageData)
);

export const getSubmitPniPageData = createSelector(
  getSelectedProductTypes,
  (products) =>
    ({
      products,
    } as SubmitPniPageData)
);

export const createNewMember = (entityId: string) =>
  createSelector(getSelectedProductTypes, (products) => ({
    ...PersonUtils.blankMemberEntity(),
    selected: true,
    entityId,
  }));

export const getDriverCallCount = createSelector(
  getMemberState,
  (state) => state.driverCallCount
);

export const getPniHomeNumber = createSelector(
  getPrimaryNamedInsured,
  (pni) => pni?.homeNumber
);

export const getPniEmail = createSelector(
  getPrimaryNamedInsured,
  (pni) => pni?.emailAddress
);

export const getPersonMinimumAge = (person: Partial<Nullable<MemberModel>>) =>
  createSelector(
    SessionSelectors.getQuoteState,
    ProductsSelectors.getSelectedProductTypes,
    (quoteState, selectedProducts) => {
      if (quoteState === 'CA') {
        const isPowersports = selectedProducts.find(
          (p) => p === 'MSA' || p === 'RV' || p === 'Boat'
        );
        if (isPowersports) {
          return 10;
        } else {
          return 14;
        }
      }
      return 16;
    }
  );
