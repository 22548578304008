import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StringUtils } from '@shared/utils/string.utils';
import { Observable, throwError } from 'rxjs';
import { LogEvent } from '../models/api/log.model';
import { AppConfigService } from '../services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class SplunkAdapter {
  private readonly DELIVERY_TIMEOUT = 1000;
  private readonly OUTGOING_MESSAGE_LENGTH_LIMIT = 50000;

  // Splunk rejects requests longer than 200k.
  // Somewhere short of that, truncate messages even if it means breaking JSON format.
  private readonly PANIC_TRUNCATION_SIZE = 150000;

  private queuedMessages: string[] = [];
  private messagesInFlight: string[] = [];
  private timeoutId = 0;

  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,

    private window: Window
  ) {}

  log(event: LogEvent): void {
    const eventText = JSON.stringify(event);
    this.addEventTextToQueue(eventText);
  }

  // Send a request with everything we've got queued, even if another is in flight.
  // Do this when the user closes her browser.
  // TODO Find a reasonable way to call this.
  lastChanceSynchronize(): void {
    if (this.queuedMessages.length < 1) {
      return;
    }
    const lastChanceMessages = this.queuedMessages;
    this.queuedMessages = [];
    this.sendHttpRequest(lastChanceMessages).subscribe(
      () => {},
      (error) => {
        this.queuedMessages.splice(0, 0, ...lastChanceMessages);
      },
      () => {}
    );
  }

  private addEventTextToQueue(eventText: string): void {
    if (eventText.length > this.PANIC_TRUNCATION_SIZE) {
      eventText = `"CONTENT OMITTED DUE TO LENGTH ${eventText.length}"`;
    }
    this.queuedMessages.push(eventText);
    this.callApiLater();
  }

  private callApiLater(): void {
    if (this.timeoutId) {
      return;
    }
    this.timeoutId = this.window.setTimeout(() => {
      this.callApiNow();
    }, this.DELIVERY_TIMEOUT);
  }

  private callApiNow(): void {
    if (!this.moveMessagesFromQueueToInFlight()) {
      return;
    }
    this.sendHttpRequest(this.messagesInFlight).subscribe(
      () => {},
      (error) => {
        this.returnMessagesInFlightToQueue();
        this.timeoutId = 0;
        this.callApiLater(); // if you don't at first succeed...
      },
      () => {
        this.messagesInFlight = [];
        this.timeoutId = 0;
        if (this.queuedMessages.length) {
          // Lastly, retrigger later if we've collected any log entries since sending the request.
          this.callApiLater();
        }
      }
    );
  }

  private moveMessagesFromQueueToInFlight(): boolean {
    if (this.queuedMessages.length < 1) {
      return false;
    }
    if (this.messagesInFlight.length > 0) {
      throw new Error(
        `Log synchronization triggered with a call still in flight`
      );
    }
    const countToSend = this.countQueuedMessagesToSend();
    this.messagesInFlight.splice(
      0,
      0,
      ...this.queuedMessages.splice(0, countToSend)
    );
    return true;
  }

  private countQueuedMessagesToSend(): number {
    let count = 0;
    let size = 0;
    while (count < this.queuedMessages.length) {
      const nextLength = this.queuedMessages[count].length;
      if (!count || size + nextLength < this.OUTGOING_MESSAGE_LENGTH_LIMIT) {
        count++;
        size += nextLength;
      } else {
        break;
      }
    }
    return count;
  }

  private returnMessagesInFlightToQueue(): void {
    this.queuedMessages.splice(
      this.queuedMessages.length,
      0,
      ...this.messagesInFlight
    );
    this.messagesInFlight = [];
  }

  private sendHttpRequest(messages: string[]): Observable<unknown> {
    const transactionId = StringUtils.generateUuid();

    const url = this.appConfigService.config?.splunkApiUrl;
    if (!url) {
      return throwError(() => 'AppConfigService not loaded yet');
    }

    const headers = new HttpHeaders()
      .set('client_id', this.appConfigService.config.apiKey)
      .set('Content-Type', 'application/json')
      .set('X-Nw-Transaction-Id', transactionId);

    const body = {
      splunkToken: this.appConfigService.config.splunkToken,
      logs: messages.map((text) => ({
        event: text,
        fields: '{}',
        sourcetype: 'dgs_mpse_json',
      })),
    };

    // This HTTP call is a rare exception to the policy of logging all HTTP calls.
    // (hopefully that doesn't need explained)
    return this.httpClient.post(url, body, { headers });
  }
}
