import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { SimpleConfirmationModalComponent } from './simple-confirmation-modal.component';

@NgModule({
  declarations: [SimpleConfirmationModalComponent],
  exports: [SimpleConfirmationModalComponent],
  imports: [CommonModule, RouterModule, NgbModule, SharedDirectivesModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SimpleConfirmationModalModule {}
