import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { EligibilityInlineContainerComponent } from '@app/eligibility/eligibility-inline-container/eligibility-inline-container.component';
import { ProductType } from '@core/models/api/dsm-types';
import { ComponentStoreService } from '@core/services/component-store.service';
import { PropertyService } from '@core/services/property.service';
import { PropertyOptionsModel } from '@core/store/entities/metadata/models/property-options.model';
import { ProductModel } from '@core/store/entities/product/product.model';
import { TaskModel } from '@core/store/entities/task/task.model';
import { CoveredLocationModel } from '@entities/covered-location/covered-location.model';
import { CoveredLocationEntity } from '@entities/covered-location/covered-location.reducer';
import { getPropertyOptions } from '@entities/covered-location/covered-location.selector';
import { getProduct } from '@entities/product/product.selectors';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { RenovationModel } from '@property/components/forms/renovation-form/renovation-form.model';
import { RoofYearComponent } from '@property/components/forms/roof-year/roof-year.component';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, Subject, takeUntil } from 'rxjs';

interface TasksPropertyFormState {
  showRoofYear?: boolean;
  roofRenovation?: RenovationModel;
  showEligibilityQuestions?: boolean;
  productDisplayName?: string;
}

@Component({
  selector: 'nwx-tasks-property-form',
  templateUrl: './tasks-property-form.component.html',
  styleUrls: ['./tasks-property-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore],
})
export class TasksPropertyFormComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() coveredLocation!: Nullable<CoveredLocationModel> | undefined;
  @Input() propertyTasks!: TaskModel[] | undefined;
  @Input() productType!: ProductType;
  @Input() submitted = false;

  @Output() formReady = new EventEmitter<FormGroup>();

  @ViewChild(EligibilityInlineContainerComponent)
  eligibilityInlineContainer: EligibilityInlineContainerComponent | null = null;
  private roofYearComponent: RoofYearComponent | null = null;
  @ViewChild(RoofYearComponent) set _roofYearComponent(v: RoofYearComponent) {
    this.roofYearComponent = v;
    this.form.get('roofYear')?.updateValueAndValidity({ emitEvent: false });
  }

  propertyOptions$!: Observable<PropertyOptionsModel>;
  selectedPropertyProduct$!: Observable<ProductModel | undefined>;

  form!: FormGroup;

  vm$!: Observable<TasksPropertyFormState>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private readonly componentStore: ComponentStoreService<TasksPropertyFormState>,
    private store: Store,
    private propertyService: PropertyService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      eligibilityFormStatus: this.fb.control(
        '',
        this.doesTaskFieldExist('acknowledgement')
          ? [(c) => this.validateEligibilityFormStatus(c.value)]
          : []
      ),
      roofYear: this.fb.control(
        this.coveredLocation?.renovations?.find(r => r.type === 'Roof')?.year || '',
        [(control: AbstractControl) => this.validateRoofYear(control)],
      ),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(v => this.onValueChange(v));

    this.propertyOptions$ = this.store.select(
      getPropertyOptions(this.coveredLocation?.productType || 'Homeowner')
    );

    this.selectedPropertyProduct$ = this.store.select(
      getProduct(this.coveredLocation?.productType || 'Homeowner')
    );

    this.componentStore.initialize({
      showRoofYear: this.doesTaskFieldExist('roofYear'),
      roofRenovation: this.getRoofRenovation(),
      showEligibilityQuestions: this.doesTaskFieldExist('acknowledgement'),
      productDisplayName: this.getProductDisplayName(
        this.coveredLocation?.productType
      ),
    });

    this.vm$ = this.componentStore.get();

    this.formReady.emit(this.form);
  }

  ngOnDestroy(): void {
    this.form.disable();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.submitted) {
      if (this.submitted) {
        this.eligibilityInlineContainer?.highlightRequiredErrors();
        this.roofYearComponent?.highlightRequiredErrors();
      }
    }
  }

  onValueChange(formValue: any): void {
    if (!this.coveredLocation) {
      return;
    }
    let renovations = this.coveredLocation.renovations || [];
    const roofRenovationIndex = renovations.findIndex(r => r.type === 'Roof');
    if (roofRenovationIndex >= 0) {
      const roofRenovation = renovations[roofRenovationIndex];
      renovations = [...renovations];
      renovations[roofRenovationIndex] = {
        ...roofRenovation,
        year: formValue.roofYear,
      };
    } else {
      renovations = [...renovations, { type: 'Roof', year: formValue.roofYear }];
    }
    const output = {
      ...this.coveredLocation,
      renovations,
    } as CoveredLocationEntity;
    this.propertyService.storeCoveredLocation(output);
  }

  addChildForm(name: string, form: FormGroup): void {
    this.form.setControl(name, form);
  }

  doesTaskFieldExist(fieldName: string): boolean {
    return !!this.propertyTasks?.find((t) => t.field === fieldName);
  }

  isHomeowner(): boolean {
    return this.coveredLocation?.productType === 'Homeowner';
  }

  getProductDisplayName(productType: ProductType | undefined): string {
    switch (productType?.toLowerCase()) {
      case 'homeowner': {
        return 'Homeowners';
      }
      case 'condominium': {
        return 'Condo';
      }
      case 'tenant': {
        return 'Renters';
      }
      default: {
        return '';
      }
    }
  }

  getRoofRenovation(): RenovationModel {
    return this.coveredLocation?.renovations?.find(
      (renovation) => renovation.type === 'Roof'
    ) as RenovationModel;
  }

  onRoofYearConfirmed(): void {
    this.form.get('roofYear')?.updateValueAndValidity({ emitEvent: false });
  }

  private validateRoofYear(control: AbstractControl): ValidationErrors | null {
    const validation = this.roofYearComponent?.validate(control.value) || null;
    return validation;
  }

  onEligibilityStatusChange(status: string): void {
    this.form.get('eligibilityFormStatus')?.setValue(status);
  }

  private validateEligibilityFormStatus(
    status: string
  ): ValidationErrors | null {
    if (status === 'VALID') {
      return null;
    }
    return { required: true };
  }
}
