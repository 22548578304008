import { PageIdentifier } from '@core/constants/pages';
import {
  ProductType,
  QuoteStatus,
  TaskProductType,
} from '@core/models/api/dsm-types';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { TaskModel } from './task.model';

export const clear = createAction('[Task] Clear');

export const addTask = createAction(
  '[Task] Add',
  props<{ payload: TaskModel }>()
);
export const completeTask = createAction(
  '[Task] Complete',
  props<{ payload: TaskModel }>()
);
export const uncompleteTask = createAction(
  '[Task] Uncomplete',
  props<{ payload: TaskModel }>()
);

export const updateTask = createAction(
  '[Task] Update',
  props<Update<TaskModel>>()
);

export const beginResolving = createAction('[Task] Begin Resolving');
export const endResolving = createAction('[Task] End Resolving');
export const rebuildTasks = createAction('[Task] Rebuild');
export const updateTasks = createAction('[Task] Update Tasks');
export const generateReviewTasks = createAction('[Task] Generate Review Tasks');

export const dismissReviewTask = createAction(
  '[Task] Dismiss Review Task',
  props<{
    payload: {
      field: string;
      productType: TaskProductType;
    };
  }>()
);

export const displayPageTasksAsErrors = createAction(
  '[Task] Display Page Tasks As Errors',
  props<{ payload: PageIdentifier }>()
);

export const clearReviewTasksForPage = createAction(
  '[Task] Clear review tasks from page',
  props<{ payload: PageIdentifier; quoteStatusLessOrEqual?: QuoteStatus }>()
);

export const toggleShowTasks = createAction(
  '[Task] Toggle Show Tasks on Hub Page'
);

export const setShowTasks = createAction(
  '[Task] Show Tasks on Hub Page',
  props<{ payload: boolean }>()
);

export const openInPolicyCenter = createAction(
  '[Task] Open in PolicyCenter',
  props<{
    quoteId: string;
    removeProduct?: ProductType;
    lookUpQuoteIdForProduct?: ProductType;
    reason: string;
  }>()
);

export const openInPolicyCenterSuccess = createAction(
  '[Task] Open in PolicyCenter Success'
);

export const openInPolicyCenterFail = createAction(
  '[Task] Open in PolicyCenter Fail'
);

export const submitTasksModal = createAction(
  '[Task] Submit Tasks Modal',
  props<{ payload: any }>()
);

export const closeTasksModal = createAction('[Task] Close Tasks Modal');
