<div class="bolt-background-medium-blue">
  <div class="footer-container bolt-body-copy-sm">
    <div *ngIf="showPrivacyAddenda" class="addendum">
      <div class="centered-container">
        <p>Notify your customer of legally required notices</p>
        <p>
          Privacy, Credit Report and Insurance Score Pre-Notice Requirement
          <br />
          Nationwide’s privacy policy to protect your personal information is
          available upon request. To give you the quote or any policy renewals
          you request, Nationwide reviews your insurance loss history and other
          consumer reports, such as your credit report, where the law allows. A
          third party company could be used to calculate an insurance score
          based on your credit. You have the right to review your personal
          information in our files and request that mistakes be corrected.
        </p>
        <p>VT agents must say: Do I have your permission to proceed?</p>
        <p>
          AZ, CA, CT, GA, ME, MN, MT, NC, NJ, NV, OR & VA agents: Include a copy
          of Nationwide's Privacy Statement when you send the quote.
        </p>
      </div>
    </div>

    <div *ngIf="showDriversAddenda" class="addendum">
      <div class="centered-container">
        <p *ngIf="!isCA()">
          Assumes all drivers were licensed at age 16 and have not been
          convicted of insurance fraud.
        </p>
        <p *ngIf="isCA()">
          Assumes all drivers are licensed and have not been convicted of
          insurance fraud.
        </p>
      </div>
    </div>

    <div *ngIf="showVehiclesAddenda" class="addendum">
      <div class="centered-container">
        <p>Assumes vehicle:</p>
        <ul class="unadorned-list">
          <li>Isn't used for business, speed or racing</li>
          <li>Isn't garaged in AK, HI, LA or MA</li>
          <li>Doesn't have aftermarket add-ons or accessories installed</li>
        </ul>
      </div>
    </div>

    <div *ngIf="showMsaVehiclesAddenda" class="addendum">
      <p>Assumes vehicle:</p>
      <ul class="unadorned-list">
        <li>Isn’t used for business or commercial use</li>
        <li>Isn't used for racing or speed contest</li>
        <li>Isn’t garaged in AK, HI, LA, MA, NJ and OK</li>
        <li>Doesn’t have unrepaired damage or are partially assembled</li>
      </ul>
      <p>If a vehicle is used for business, please call 1-888-490-1549</p>
    </div>

    <div *ngIf="showBoatVehiclesAddenda" class="addendum">
      <div class="centered-container">
        <p>Assumes boat:</p>
        <ul class="unadorned-list">
          <li>
            Is not used for racing or speed contest (not including sailboats)
          </li>
          <li>Is not garaged in AK, HI, LA, MA, NJ and OK</li>
          <li>
            Is not an amphibious vehicle, airboat, iceboat, live-aboard,
            custom-made, or steel hull
          </li>
          <li>Is in seaworthy condition</li>
        </ul>
      </div>
    </div>
    <div *ngIf="showRvVehiclesAddenda" class="addendum">
      <div class="centered-container">
        <p>Assumes vehicle:</p>
        <ul class="unadorned-list">
          <li>Is not used for racing or speed contest</li>
          <li>Is not garaged in AK, HI, LA, MA, NJ and OK</li>
        </ul>
      </div>
    </div>

    <div *ngIf="showCoveragesAddenda" class="addendum">
      <div class="centered-container">
        <p *ngIf="hasProperty">
          Let your customer know that by Continuing, they acknowledge that their
          Property policy does not cover losses from floods. If they want to
          learn more about Flood insurance it is offered by the
          <a href="https://www.floodsmart.gov/" target="_blank">
            National Flood Insurance Program</a
          >
          and we can provide that information upon their request.
        </p>
        <p>
          Coverage packages are provided for informational purposes only. If
          your customer’s unique circumstances warrant modifications to these
          packages, you and your customer are responsible for selecting the
          appropriate coverage options.
        </p>
        <p>
          These are only some of the coverage options and discounts we offer.
          For additional coverage options and limits, try using
          <a href="{{ policyCenterUrl }}">PolicyCenter</a> to complete this
          quote.
        </p>
      </div>
    </div>

    <div *ngIf="showHubAddenda" class="addendum">
      <div class="centered-container">
        <p>
          This quote is not a reflection of contractual language. For specific
          coverage information, please refer to the policy and/or declarations
          page. This quote does not guarantee or bind coverage. This quote is an
          estimate and is subject to change if your customer’s circumstances are
          different than the information provided, and/or any information
          provided is inaccurate or incomplete.
        </p>
        <p>
          Products underwritten by Nationwide Mutual Insurance Company and
          Affiliates, Columbus, Ohio. Availability varies. Discounts do not
          apply to all coverage elements; actual savings vary by state, coverage
          selections, rating factors and policy changes.
        </p>
        <p *ngIf="hasAuto">
          Enrollment discount applies during data collection; final discount is
          calculated on driving behavior and could be zero. Final discount
          applies at the next policy renewal and remains until drivers or
          vehicles on the policy change.
        </p>
      </div>
    </div>
  </div>
</div>

<div
  class="bolt-footer bolt-footer-container footer-container bolt-background-dark-blue"
>
  <div class="bolt-container-fluid">
    <div class="bolt-footer__head">
      <div class="bolt-footer__intro">
        <div class="bolt-footer__logo">
          <bolt-logo color="white" size="md"></bolt-logo>
        </div>
        <div class="bolt-footer__phone">
          <p><strong>1-888-667-3866</strong></p>
        </div>
        <div class="bolt-footer__social">
          <ul>
            <li>
              <a href="https://www.facebook.com/nationwide" target="_blank"
                ><bolt-icon name="facebook"></bolt-icon
              ></a>
            </li>
            <li>
              <a href="https://twitter.com/nationwide" target="_blank"
                ><bolt-icon name="twitter"></bolt-icon
              ></a>
            </li>
            <li>
              <a href="https://www.instagram.com/nationwide/" target="_blank"
                ><bolt-icon name="instagram"></bolt-icon
              ></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/2340/" target="_blank"
                ><bolt-icon name="linkedin"></bolt-icon
              ></a>
            </li>
            <li>
              <a href="https://www.youtube.com/nationwide" target="_blank"
                ><bolt-icon name="youtube"></bolt-icon
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bolt-footer__body">
      <nav id="footerNav" title="Footer navigation">
        <ul>
          <li>
            <a
              target="_blank"
              href="https://www.nationwide.com/personal/about-us/"
              >About us</a
            >
          </li>
          <li>
            <a target="_blank" href="https://www.nationwide.com/agents/">
              For agents</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.nationwide.com/personal/about-us/careers/"
              >Careers
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.nationwide.com/personal/contact/"
              >Contact us
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.nationwide.com/personal/about-us/terms-conditions"
              >Terms &amp; conditions
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.nationwide.com/personal/privacy-security"
              >Privacy
            </a>
          </li>
          <li>
            <a href="#" (click)="showCreditNotice(creditNotice, $event)"
              >Credit notice</a
            >
          </li>
          <li>
            <div id="teconsent"></div>
          </li>
        </ul>
      </nav>
      <div class="bolt-footer__content">
        <p class="bolt-body-copy-sm bolt-footer-disclaimer">
          Products underwritten by Nationwide Mutual Insurance Company and
          Affiliated Companies. Not all Nationwide affiliated companies are
          mutual companies, and not all Nationwide members are insured by a
          mutual company. Subject to underwriting guidelines, review and
          approval. Products and discounts not available to all persons in all
          states. Nationwide Investment Services Corporation, member FINRA. Home
          Office: One Nationwide Plaza, Columbus, OH. Nationwide, the Nationwide
          N and Eagle and other marks displayed on this page are service marks
          of Nationwide Mutual Insurance Company, unless otherwise disclosed.
          &copy;2023 Nationwide Mutual Insurance Company.
        </p>
      </div>
    </div>
    <div class="bolt-footer__tail">
      <div class="bolt-footer__tagline">
        <div class="bolt-footer__tagline">
          Nationwide is on your side<sup>&reg;</sup>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #creditNotice let-modal>
  <div class="modal-header">
    Credit Notice
    <a href="#" (click)="modal.dismiss(); $event.preventDefault()">
      <bolt-icon name="xmark"></bolt-icon>
    </a>
  </div>
  <div class="modal-body">
    Nationwide collects information from you and other third-party sources and
    may share your information with third parties without your authorization, as
    permitted or required by law. We review your credit report and the credit
    report of any individual listed in the application or obtain or use an
    insurance credit score based on the information contained in the credit
    report, where permitted by law. We may use a third party in connection with
    the development of your insurance credit score. You may request that your
    credit information be updated and if you question the accuracy of the credit
    information, we will, upon your request, re-evaluate you based on corrected
    credit information from a consumer reporting agency. Examples of the type of
    information collected are driving records, driving history, insurance claims
    histories, credit reports and credit-based insurance scores. Once you become
    a customer, Nationwide may continue to use your consumer reports to update
    or renew your insurance policy. Please review our <a
      target="_blank"
      href="https://www.nationwide.com/personal/privacy-security"
    >
      Privacy Policy</a
    >, which describes Nationwide’s use of collected information, and our <a
      target="_blank"
      href="https://www.nationwide.com/personal/about-us/terms-conditions"
      >Terms and Conditions</a
    >.
  </div>
  <div class="modal-footer">
    <bolt-button (click)="modal.dismiss()">Close</bolt-button>
  </div>
</ng-template>
