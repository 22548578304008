import { ProductType } from '@core/models/api/dsm-types';
import { VehicleFormDriver } from '@core/models/views/vehicle-form-options';
import {
  ALL_VEHICLE_FEATURES,
  AntiTheftDeviceModel,
  EngineLimitsPerMotorType,
  PassiveRestraintModel,
  RVSafetyDeviceModel,
  VehicleFeaturesModel,
  VehicleLenderType,
  VehicleModel,
} from '@core/models/views/vehicle.model';
import { AddressEntity } from '@core/store/entities/address/address.entity';
import { DriverEntity } from '@core/store/entities/driver/driver.entity';
import { MemberEntity } from '@core/store/entities/member/member.reducer';
import { VehicleEntity } from '@core/store/entities/vehicle/vehicle.entity';
import { EligibleDiscountId } from '@entities/eligible-discounts/eligible-discounts.entity';
import { AddressModel } from '@shared/components/address-input/address-input.model';
import { VehicleLenderModel } from '@shared/components/forms/vehicle-lender-form/vehicle-lender-form.model';
import { AddressUtils } from '@shared/utils/address.utils';
import { GeneralUtils } from '@shared/utils/general.utils';
import { PersonUtils } from '@shared/utils/person.utils';
import { VehicleUtils } from '@shared/utils/vehicle.utils';
import { DateUtils } from '../../date.utils';
import { Nullable } from '../../type.utils';

export class VehicleFormFromEntityBuilder {
  static generateVehicleFormsFromEntities(
    entities: VehicleEntity[]
  ): VehicleModel[] {
    return entities.map((entity) => this.generateVehicleFormFromEntity(entity));
  }

  static generateVehicleFormFromEntity(entity: VehicleEntity): VehicleModel {
    const output = {
      vehicleId: entity.vehicleId,
      prefillId: entity?.prefillId,
      year: entity.year,
      make: entity.make,
      model: entity.model,
      series: entity.series,
      bodyType: entity.bodyType,
      bodyStyle: entity.bodyStyle,
      vehicleSubType: entity.vehicleSubType,
      vin: entity.vin,
      hin: entity.hin,
      odometerReading: entity.odometerReading,
      annualMiles: entity?.annualMiles ? +entity?.annualMiles : null,
      milesOneWay: entity?.milesOneWay,
      daysCommutingPerWeek: entity?.daysCommutingPerWeek,
      primaryUse: entity.primaryUse,
      isRegisteredInState: entity.isRegisteredInState,
      purchasedNewDate: entity?.purchasedNewDate,
      costNew: entity?.costNew?.amount,
      customizationAmount: entity?.customizationAmount,
      customizationDetails: entity?.customizationDetails,
      isPurchasedNew: entity.isPurchasedNew,
      previouslyOwned: !entity.isPurchasedNew,
      carPool: entity.carPool,
      vehicleFeatures: this.getVehicleFeaturesFromEntity(entity),
      passiveRestraint: this.getPassiveRestraintFromEntity(entity),
      antiTheftDevices: this.getAntiTheftDevicesFromEntity(entity),
      mandatoryAntiTheftDevices:
        this.getMandatoryAntiTheftDevicesFromEntity(entity),
      lender: this.lenderFromEntity(entity),
      lenderInfo: this.getLenderInfoFromEntity(entity),
      additionalInterests: entity.additionalInterests,
      garageLocation: this.garageLocationFromEntity(entity),
      registeredOwners: entity?.registeredOwners?.map((ro) => ({ ...ro })),
      selected: entity.selected,
      antiLockBrakes: entity.antiLockBrakes,
      vehicleType: entity.vehicleType,
      hasPriorDamage: entity.hasPriorDamage,
      damageLocation: entity.damageLocation,
      additionalInformation: this.getAdditionalInformation(entity),
      cubicCentimeters: entity.cubicCentimeters,
      productType: entity.productType,
      subTypeClass: entity.subTypeClass,
      subType: entity.subType,
      marketValue: entity.marketValue,
      value: entity.value,
      horsepower: entity.horsepower,
      auxiliaryLights: entity.auxiliaryLights,
      agreedValue: entity.agreedValue,
      hullType: entity.hullType,
      motorType: entity.motorType,
      motorDetails: entity.motorDetails,
      fuelType: entity.fuelType,
      boatLength: entity.boatLength,
      numOfMotors: entity.numOfMotors,
      type: entity.type, // TODO typing.. boat type
      topSpeed: entity.topSpeed,
      totalHorsepower: entity.totalHorsepower,
      vehicleLength: entity.vehicleLength,
      boatWeight: entity.boatWeight,
      weight: entity.weight,
      purchasedDate: entity.purchasedDate,
      maxNoEngines: EngineLimitsPerMotorType[entity.motorType || ''],
      estimatedMarketValue: entity.estimatedMarketValue,
      totalMarketValue: entity.totalMarketValue,
      eligibleDiscounts: [...(entity.eligibleDiscounts || [])],
      hasTrailer: entity.hasTrailer,
      trailer: entity.trailer,
      modelNumber: entity.modelNumber,
      enclosedToTransportVehicles: entity.enclosedToTransportVehicles,
      safetyDevice: this.getSafetyDeviceFromEntity(entity),
      hasFiberglassShell: this.getHasFiberglassShellFromEntity(entity),
      hasTirePressureMonitoring:
        this.getHasTirePressureMonitoringFromEntity(entity),
      msrp: entity.msrp,
      totalEstimatedMarketValue: VehicleUtils.getTotalEstMarketValue(entity),
      totalManufacturersSuggestedRetailPrice:
        entity.totalManufacturersSuggestedRetailPrice,
      controlNumber: entity.controlNumber,
      serialNumber: entity.serialNumber,
      motorSerialNumber: entity.motorSerialNumber,
      isMakeNotFound: entity.isMakeNotFound,
      isModelNotFound: entity.isModelNotFound,
    } as VehicleModel;
    return output;
  }

  private static getLenderInfoFromEntity(
    entity: VehicleEntity
  ): Nullable<VehicleLenderModel> {
    if (this.lenderFromEntity(entity) !== VehicleLenderType.None) {
      return entity.additionalInterests?.length
        ? {
            ...entity.additionalInterests[0],
            loanExpiration: DateUtils.formatDsmDateToOld(
              entity.additionalInterests[0].expirationDate || ''
            ),
            address: this.lenderAddressFromEntity(entity),
          } as VehicleLenderModel
        : ({} as VehicleLenderModel);
    }

    return null;
  }

  private static getVehicleFeaturesFromEntity(
    entity: VehicleEntity
  ): VehicleFeaturesModel[] {
    return (entity?.eligibleDiscounts || [])
      .filter(
        ({ selectedOptionValue, eligibleDiscountId }) =>
          selectedOptionValue === 'true' &&
          eligibleDiscountId &&
          this.isVehicleFeature(eligibleDiscountId)
      )
      .map((discount) => discount.eligibleDiscountId as VehicleFeaturesModel);
  }

  private static isVehicleFeature(feature: EligibleDiscountId): boolean {
    return ALL_VEHICLE_FEATURES.includes(feature);
  }

  private static getAntiTheftDevicesFromEntity(
    entity: VehicleEntity
  ): AntiTheftDeviceModel[] {
    const atDiscount = entity?.eligibleDiscounts?.find(
      (ed) => ed.eligibleDiscountId === 'AntiTheft'
    );
    if (atDiscount) {
      return atDiscount.qualifyingInformation
        ?.selectedAntiTheft as AntiTheftDeviceModel[];
    }

    return [];
  }

  private static getSafetyDeviceFromEntity(
    entity: VehicleEntity
  ): Nullable<RVSafetyDeviceModel> {
    return (entity?.eligibleDiscounts || []).find(
      (discount) => discount.eligibleDiscountId === 'SafetyDevice'
    )?.selectedOptionValue as RVSafetyDeviceModel;
  }

  private static getHasTirePressureMonitoringFromEntity(
    entity: VehicleEntity
  ): Nullable<string> {
    return (entity?.eligibleDiscounts || []).find(
      (discount) => discount.eligibleDiscountId === 'AutomaticTirePressure'
    )?.selectedOptionValue;
  }

  private static getHasFiberglassShellFromEntity(
    entity: VehicleEntity
  ): Nullable<string> {
    return (entity?.eligibleDiscounts || []).find(
      (discount) => discount.eligibleDiscountId === 'FiberglassConstruction'
    )?.selectedOptionValue;
  }

  private static getMandatoryAntiTheftDevicesFromEntity(
    entity: VehicleEntity
  ): AntiTheftDeviceModel[] {
    const atDiscount = entity?.eligibleDiscounts?.find(
      (ed) => ed.eligibleDiscountId === 'AntiTheft'
    );
    if (atDiscount) {
      return (atDiscount.availableOptions || [])
        .filter(({ isMandatory }) => isMandatory)
        .map(({ optionValue }) => optionValue as AntiTheftDeviceModel);
    }

    return [];
  }

  private static getPassiveRestraintFromEntity(
    entity: VehicleEntity
  ): Nullable<PassiveRestraintModel> {
    const prDiscount = entity?.eligibleDiscounts?.find(
      (ed) => ed.eligibleDiscountId === 'PassiveRestraint'
    );
    if (prDiscount) {
      return prDiscount.selectedOptionValue as PassiveRestraintModel;
    }

    if (
      entity?.year &&
      entity.year >= 1996 &&
      entity.productType === 'PersonalAuto' &&
      entity.vehicleType === 'auto'
    ) {
      return 'AirBag_Ext';
    }

    return null;
  }

  private static lenderFromEntity(
    entity: VehicleEntity
  ): VehicleLenderType | undefined {
    if (entity.additionalInterests) {
      for (const ai of entity.additionalInterests) {
        switch (ai.additionalInterestType) {
          case 'LIEN':
            return VehicleLenderType.Loan;
          case 'LESSOR':
            return VehicleLenderType.Lease;
        }
      }
      return VehicleLenderType.None;
    }
    return VehicleLenderType.None;
  }

  private static garageLocationFromEntity(entity: VehicleEntity): AddressModel {
    return {
      ...entity.garageLocation,
      street: AddressUtils.SingleLineAddressFromAddressEntity(
        entity.garageLocation as AddressEntity
      ),
    } as AddressModel;
  }

  private static lenderAddressFromEntity(entity: VehicleEntity): AddressModel {
    return {
      ...entity.additionalInterests?.[0].address,
      street: entity.additionalInterests?.[0].address
        ? AddressUtils.SingleLineAddressFromAddressEntity(
            entity.additionalInterests?.[0].address as AddressEntity
          )
        : undefined,
    } as AddressEntity;
  }

  static vehicleFormDriversFromEntities(
    inputs: DriverEntity[],
    productType: ProductType
  ): VehicleFormDriver[] | undefined {
    return inputs?.map((input) =>
      this.vehicleFormDriverFromEntity(input, productType)
    );
  }

  static vehicleFormDriverFromEntity(
    input: DriverEntity,
    productType: ProductType
  ): VehicleFormDriver {
    const driverId = PersonUtils.getEntityId(input, 'driver', productType);
    return {
      driverId,
      name: PersonUtils.GetPersonName(input as MemberEntity),
      modelValue: { driverId },
    };
  }

  private static getAdditionalInformation(entity: VehicleEntity) {
    const additionalInfo = entity.additionalInformation;
    if (additionalInfo) {
      return {
        isStoredInLockedGarage: GeneralUtils.isBoolean(
          additionalInfo?.isStoredInLockedGarage
        )
          ? String(additionalInfo.isStoredInLockedGarage)
          : null,
        isCoOwned: additionalInfo.isCoOwned,
        numberOfCoOwners: additionalInfo?.numberOfCoOwners,
        isRebuiltSalvagedFrameReplaced: GeneralUtils.isBoolean(
          additionalInfo?.isRebuiltSalvagedFrameReplaced
        )
          ? String(additionalInfo.isRebuiltSalvagedFrameReplaced)
          : null,
        specialHazard: additionalInfo.specialHazard,
        hasVehicleBeenConvertedToTrike: GeneralUtils.isBoolean(
          additionalInfo?.hasVehicleBeenConvertedToTrike
        )
          ? String(additionalInfo.hasVehicleBeenConvertedToTrike)
          : null,
        isVehicleBuiltByFirm: GeneralUtils.isBoolean(
          additionalInfo?.isVehicleBuiltByFirm
        )
          ? String(additionalInfo.isVehicleBuiltByFirm)
          : null,
        isVehicleRegisteredAsHistorical: GeneralUtils.isBoolean(
          additionalInfo?.isVehicleRegisteredAsHistorical
        )
          ? String(additionalInfo.isVehicleRegisteredAsHistorical)
          : null,
        registrationState: additionalInfo?.registrationState,
      };
    }
    return null;
  }
}
