import { ProductType } from '@core/models/api/dsm-types';
import { EstablishEscrowAccountResponse } from '@core/models/api/response/establish-escrow-account-response.model';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../error/error.model';

export const establishEscrowAccount = createAction(
  '[Escrow] Establish Escrow Account',
  props<{ productType: ProductType }>()
);

export const escrowPaymentNotSelected = createAction(
  '[Escrow] Escrow Payment Not Selected'
);

export const establishEscrowAccountSuccess = createAction(
  '[Escrow] Establish Escrow Account Success',
  props<{ payload: EstablishEscrowAccountResponse }>()
);

export const establishEscrowAccountFailure = createAction(
  '[Escrow] Establish Escrow Account Failure',
  props<{ error: ErrorModel }>()
);
