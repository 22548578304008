import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { ProductType } from '@core/models/api/dsm-types';
import { FeatureFlagService } from '@core/services/feature-flag.service';
import { ProductModel } from '@core/store/entities/product/product.model';
import { ComponentChanges } from '@shared/utils/general.utils';
import { Nullable } from '@shared/utils/type.utils';
import { StateSpecificFlagsModel } from '@assets/metadata/stateSpecificFlags';
import { merge, of, Subject, Subscription } from 'rxjs';
import { ProductUtils } from '@shared/utils/product.util';
import { PcUrlService } from '@shared/services/pc-url.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'nwx-product-list-form',
  templateUrl: './product-list-form.component.html',
  styleUrls: ['./product-list-form.component.scss'],
})
export class ProductListFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() products!: ProductModel[];
  @Input() form!: FormGroup;
  @Input() submitted: Nullable<boolean> = false;
  @Input() quoteState!: string;
  @Input() stateSpecificFlags!: Nullable<StateSpecificFlagsModel>;
  @Input() isNSSAgent = false;
  @Input() showProductCategories: boolean = false;
  @Input() restrictedProducts: ProductType[] = []; // AAF filtered products
  @Output() overrideSubmitButtonBehavior = new EventEmitter<
    '' | 'policycenter'
  >();

  inlineErrorMessage = '';
  private policyCenterUrl = '';
  policyCenterUrlForLink = '';
  selectedProductWasRemoved: boolean = false;
  vehicleProducts: ProductModel[] = [];
  propertyProducts: ProductModel[] = [];
  protectionProducts: ProductModel[] = [];
  private formValiditySubscription: Subscription | null = null;
  private unsubscribe$ = new Subject<void>();
  private currentSubmitButtonBehavior = '';

  constructor(
    private changeDetector: ChangeDetectorRef,
    private featureFlagService: FeatureFlagService,
    private pcUrlService: PcUrlService
  ) {
    pcUrlService
      .getPolicyCenterUrl()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((url) => {
        this.policyCenterUrl = url;
      });
  }

  ngOnInit(): void {
    this.vehicleProducts = this.getVehicleProducts(this.products);
    this.propertyProducts = this.getPropertyProducts(this.products);
    this.protectionProducts = this.getProtectionProducts(this.products);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.formValiditySubscription) {
      this.formValiditySubscription.unsubscribe();
      this.formValiditySubscription = null;
    }
  }

  ngOnChanges(changes: ComponentChanges<ProductListFormComponent>): void {
    this.selectedProductWasRemoved = false;
    if (changes.form) {
      if (this.formValiditySubscription) {
        this.formValiditySubscription.unsubscribe();
        this.formValiditySubscription = null;
      }
      const control = this.form.get('selectedProducts');
      if (control) {
        this.formValiditySubscription = merge(
          of(null),
          control.statusChanges
        ).subscribe(() => {
          this.inlineErrorMessage = this.composeErrorMessage();
          this.signalSubmitButtonBehavior();
          this.changeDetector.detectChanges();
        });
      }
    }
    this.inlineErrorMessage = this.composeErrorMessage();
    this.signalSubmitButtonBehavior();
    if (changes.quoteState || changes.products || changes.restrictedProducts) {
      this.vehicleProducts = this.getVehicleProducts(this.products);
      this.propertyProducts = this.getPropertyProducts(this.products);
      this.protectionProducts = this.getProtectionProducts(this.products);

      this.removeUnavailableProductsFromSelectedProducts();
    }
  }

  onProductSelectionsReset(): void {
    this.selectedProducts.clear();
    this.selectedProducts.markAsPristine();
    this.selectedProducts.markAsUntouched();
  }

  updateProductSelection(id: ProductType): void {
    if (this.isSelected(id)) {
      const index = this.selectedProducts.controls.findIndex(
        (control) => control.value === id
      );
      this.selectedProducts.removeAt(index);
    } else {
      this.removeIncompatibleProducts(id);
      this.selectedProducts.push(new FormControl(id));
    }
  }

  isSelected(id: ProductType): boolean {
    return this.selectedProducts?.value?.includes(id);
  }

  getVehicleProducts = (products: ProductModel[]) =>
    products.filter(
      (product) =>
        ProductUtils.isVehicleProduct(product.type) &&
        !this.restrictedProducts?.includes(product.type)
    );

  getPropertyProducts = (products: ProductModel[]) =>
    products.filter(
      (product) =>
        ProductUtils.isPropertyProduct(product.type) &&
        !this.restrictedProducts?.includes(product.type)
    );

  getProtectionProducts = (products: ProductModel[]) =>
    products.filter(
      (product) =>
        ProductUtils.isProtectionProduct(product.type) &&
        !this.restrictedProducts?.includes(product.type)
    );

  getProductHelpText(product: ProductModel): string {
    return ProductUtils.getProductHelpText(product, this.quoteState);
  }

  getProductByType(type: ProductType): ProductModel {
    return this.products.find((p) => p.type === type) as ProductModel;
  }

  get selectedProducts(): FormArray {
    return this.form.get('selectedProducts') as FormArray;
  }

  private removeIncompatibleProducts(productType: ProductType): void {
    if (!ProductUtils.isPropertyProduct(productType)) {
      return;
    }
    this.selectedProducts.controls.forEach((control, index) => {
      if (ProductUtils.isPropertyProduct(control.value)) {
        (this.selectedProducts as FormArray).removeAt(index);
      }
    });
  }

  private removeUnavailableProductsFromSelectedProducts(): void {
    const unavailableProducts = [
      ...this.vehicleProducts.filter((product) => !product.isAvailable),
      ...this.propertyProducts.filter((product) => !product.isAvailable),
    ];
    unavailableProducts.forEach((product) => {
      this.selectedProducts.controls.forEach((control, index) => {
        if (control.value === product.type) {
          (this.selectedProducts as FormArray).removeAt(index);
        }
      });
    });
    // this also removes selected products for any AAF restrictions
    this.selectedProducts.controls.forEach((control, index) => {
      if (this.restrictedProducts?.includes(control.value)) {
        (this.selectedProducts as FormArray).removeAt(index);
        this.selectedProductWasRemoved = true;
      }
    });
  }

  private composeErrorMessage(): string {
    this.policyCenterUrlForLink = '';
    const control = this.form.get('selectedProducts');
    if (control && control.errors) {
      if (control.errors.invalidDwellingFireSelections) {
        return 'Dwelling Fire cannot be quoted without another Property product selected.';
      }
      if (control.errors.invalidUmbrellaSelections) {
        return 'Umbrella requires underlying auto and property policies. Please select at least auto and a property product to quote umbrella.';
      }
      if (control.errors.required) {
        if (this.quoteState && !this.products?.find((p) => p.isAvailable)) {
          this.policyCenterUrlForLink = this.policyCenterUrl;
          return `Quoting for ${this.quoteState} now requires underwriting approval.`;
        } else if (this.submitted) {
          return 'Please select one or more products to continue.';
        }
      }
    }
    return '';
  }

  private signalSubmitButtonBehavior(): void {
    if (this.inlineErrorMessage.includes('requires underwriting approval')) {
      this.signalExplicitSubmitButtonBehavior('policycenter');
    } else {
      this.signalExplicitSubmitButtonBehavior('');
    }
  }

  private signalExplicitSubmitButtonBehavior(
    behavior: '' | 'policycenter'
  ): void {
    if (behavior !== this.currentSubmitButtonBehavior) {
      this.currentSubmitButtonBehavior = behavior;
      this.overrideSubmitButtonBehavior.next(behavior);
    }
  }
}
