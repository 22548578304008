<div class="modal-header">
  <h4 class="modal-title" id="reportModalHeader">{{ modalTitle }}</h4>
</div>
<div class="modal-body">
  <nwx-mvr-report-details
    *ngIf="mvrReportDetails"
    [reportDetails]="mvrReportDetails"
  ></nwx-mvr-report-details>
  <nwx-alh-report-details
    *ngIf="alhReportDetails"
    [reportDetails]="alhReportDetails"
  ></nwx-alh-report-details>
  <nwx-plh-report-details
    *ngIf="plhReportDetails"
    [reportDetails]="plhReportDetails"
  >
  </nwx-plh-report-details>
  <nwx-current-carrier-form
    *ngIf="currentCarrierReportDetails?.length"
    [model]="currentCarrierModel$ | async"
    [options]="currentCarrierOptions$ | async"
    (valueChange)="onCurrentCarrierChange($event)"
  ></nwx-current-carrier-form>
</div>

<div class="modal-footer">
  <ng-container *ngIf="showSaveAndCancel; else showCloseOnly">
    <bolt-button-bar>
      <bolt-button
        slot="cancel"
        [attr.id]="'vrm-cancel'"
        (click)="activeModal.close()"
        >Cancel</bolt-button
      >
      <bolt-button slot="forward" [attr.id]="'vrm-save'" (click)="onSave()"
        >Save</bolt-button
      >
    </bolt-button-bar>
  </ng-container>
  <ng-template #showCloseOnly>
    <bolt-button-bar>
      <bolt-button
        slot="back"
        [attr.id]="'vrm-close'"
        (click)="activeModal.close()"
        >Close</bolt-button
      >
    </bolt-button-bar>
  </ng-template>
</div>
