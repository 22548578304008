<nwx-account-registration
  [registrationInfo]="nationwideAccountRegistrationInfo$ | async"
  [pniPhone]="pniPhone$ | async"
  [pniEmail]="pniEmail$ | async"
  [isModal]="isModal"
  [showSpinner]="showSpinner"
  [hasUpdatedRegistrationForm]="hasUpdatedRegistrationForm$ | async"
  [canEdit]="canEdit"
  (formReady)="onFormReady('nationwideAccountRegistrationInfo', $event)"
  (submitModal)="onSubmitModal()"
  (toggleDisclaimer)="onToggleDisclaimer()"
></nwx-account-registration>
