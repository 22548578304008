import { ProductType } from '@core/models/api/dsm-types';
import { MemberModel } from '@core/models/views/person.model';
import { ProductUtils } from './product.util';
import { DsmMemberEntityType, MemberPolicyRole, PolicyRoleSequence } from '@core/models/entities/base.entity';

export class PersonRoleSelectionUtils {
  static addMissingPolicyRoles(
    input: MemberModel[],
    selectedProducts: ProductType[]
  ): MemberModel[] {
    if (input.length < 1) {
      return [];
    }
    selectedProducts = selectedProducts.filter((product) =>
      ProductUtils.isActiveProduct(product)
    );
    let members = input.map((member) =>
      PersonRoleSelectionUtils.addMissingPolicyRoles1(input, member, selectedProducts)
    );
    members = this.ensurePniExistsForEachProduct(members, selectedProducts);
    return members;
  }

  private static ensurePniExistsForEachProduct(
    members: MemberModel[],
    selectedProducts: ProductType[]
  ): MemberModel[] {
    for (const productType of selectedProducts) {
      const pni = members.find(m => m.policyRoles.find(r => (
        r.productType === productType &&
        r.entityType === 'policyHolder' &&
        r.roleSequence === 'primary'
      )));
      if (!pni) {
        // PNI for any other product, and not a PH for this one, add them as PNI for this one.
        let newPniIndex = members.findIndex(m => m.policyRoles.find(r => (
          r.entityType === 'policyHolder' &&
          r.roleSequence === 'primary'
        )) && !m.policyRoles.find(r => (
          r.entityType === 'policyHolder' &&
          r.productType === productType
        )));
        if (newPniIndex >= 0) {
          members[newPniIndex] = this.addRole(members[newPniIndex], productType, 'policyHolder', 'primary');
        } else {
          // Anybody with policyHolderType "PolicyPriNamedInsured".
          newPniIndex = members.findIndex(m => m.policyHolderType === 'PolicyPriNamedInsured');
          if (newPniIndex >= 0) {
            members[newPniIndex] = this.addRole(members[newPniIndex], productType, 'policyHolder', 'primary');
          } else {
            // Panic! Use whoever is first in the list.
            members[0] = this.addRole(members[0], productType, 'policyHolder', 'primary');
          }
        }
      }
    }
    return members;
  }
  
  private static addMissingPolicyRoles1(
    allMembers: MemberModel[],
    member: MemberModel,
    selectedProducts: ProductType[]
  ): MemberModel {
    for (const product of selectedProducts) {
      member = this.addMissingRolesForProduct(member, product, allMembers, selectedProducts);
    }
    return member;
  }

  private static addMissingRolesForProduct(
    member: MemberModel,
    productType: ProductType,
    allMembers: MemberModel[],
    allProducts: ProductType[]
  ): MemberModel {
    if (ProductUtils.isDriverProduct(productType)) {
      member = this.addMissingDriverRole(member, productType, allMembers);
    }
    if (ProductUtils.isPropertyProduct(productType)) {
      member = this.addMissingPolicyHolderRole(member, productType, allMembers);
    }
    if (productType === 'PersonalUmbrella') {
      member = this.addMissingPolicyHolderRoleIfSecondary(member, productType, allMembers);
      member = this.addMissingHouseholdMemberRole(member, productType, allMembers);
    }
    member = this.setRelationSpouseIfUnsetAndSni(member, allMembers);
    return member;
  }

  private static addMissingDriverRole(
    member: MemberModel,
    productType: ProductType,
    allMembers: MemberModel[]
  ): MemberModel {
    if (this.hasRole(member, productType, 'driver')) {
      return member;
    }
    return this.addRole(member, productType, 'driver');
  }

  private static addMissingPolicyHolderRole(
    member: MemberModel,
    productType: ProductType,
    allMembers: MemberModel[]
  ): MemberModel {
    if (this.hasRole(member, productType, 'policyHolder')) {
      return member;
    }
    const roleSequence = this.roleSequenceForNewPolicyHolder(member, productType, allMembers);
    return this.addRole(member, productType, 'policyHolder', roleSequence);
  }

  private static addMissingPolicyHolderRoleIfSecondary(
    member: MemberModel,
    productType: ProductType,
    allMembers: MemberModel[]
  ): MemberModel {
    if (this.hasRole(member, productType, 'policyHolder')) {
      return member;
    }
    const roleSequence = this.roleSequenceForNewPolicyHolder(member, productType, allMembers);
    if (roleSequence === 'secondary') {
      return this.addRole(member, productType, 'policyHolder', roleSequence);
    }
    return member;
  }

  private static roleSequenceForNewPolicyHolder(
    member: MemberModel,
    productType: ProductType,
    allMembers: MemberModel[]
  ): PolicyRoleSequence {
    if (member.policyHolderType === 'PolicyPriNamedInsured') {
      if (!this.findPolicyHolderByRoleSequence(allMembers, productType, 'primary')) {
        return 'primary';
      }
    } else if (member.policyHolderType === 'PolicySecNamedInsured') {
      if (!this.findPolicyHolderByRoleSequence(allMembers, productType, 'secondary')) {
        return 'secondary';
      }
    }
    if (
      member.person?.maritalStatus === 'M' &&
      !this.findPolicyHolderByRoleSequence(allMembers, productType, 'secondary')
    ) {
      const pni = this.findPolicyHolderByRoleSequence(allMembers, productType, 'primary');
      if (pni && pni.person?.maritalStatus === 'M') {
        return 'secondary';
      }
    }
    return 'additional';
  }

  private static findPolicyHolderByRoleSequence(
    allMembers: MemberModel[],
    productType: ProductType,
    roleSequence: PolicyRoleSequence
  ): MemberModel | undefined {
    return allMembers.find(m => m.policyRoles.find(r => (
      r.productType === productType &&
      r.entityType === 'policyHolder' &&
      r.roleSequence === roleSequence
    )));
  }

  private static addMissingHouseholdMemberRole(
    member: MemberModel,
    productType: ProductType,
    allMembers: MemberModel[]
  ): MemberModel {
    if (
      this.hasRole(member, productType, 'householdMember') ||
      this.hasRole(member, productType, 'policyHolder')
    ) {
      return member;
    }
    return this.addRole(member, productType, 'householdMember');
  }

  private static setRelationSpouseIfUnsetAndSni(
    member: MemberModel,
    allMembers: MemberModel[]
  ): MemberModel {
    if (member.relationToPrimaryNamedInsured) {
      return member;
    }
    if (member.person?.maritalStatus !== 'M') {
      return member;
    }
    if (member.policyRoles.find(r => (
      r.entityType === 'policyHolder' &&
      r.roleSequence === 'secondary'
    ))) {
      return {
        policyHolderType: 'PolicySecNamedInsured', // but let (member) override
        ...member,
        relationToPrimaryNamedInsured: 'Spouse',
      };
    }
    return member;
  }

  private static hasRole(
    member: MemberModel,
    productType: ProductType,
    entityType: DsmMemberEntityType
  ): boolean {
    return !!member.policyRoles.find(r => (
      r.productType === productType &&
      r.entityType === entityType
    ));
  }

  private static addRole(
    member: MemberModel,
    productType: ProductType,
    entityType: DsmMemberEntityType,
    roleSequence?: PolicyRoleSequence
  ): MemberModel {
    const role: MemberPolicyRole = {
      productType,
      entityType,
      entityId: 0,
    };
    if (roleSequence) {
      role.roleSequence = roleSequence;
    }
    return {
      ...member,
      policyRoles: [
        ...member.policyRoles,
        role,
      ],
    };
  }
}
