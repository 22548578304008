import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import {
  rebuildFormState,
  setCoverageSyncStatusByEntityId,
  updateCoverageSync,
} from './coverage-sync.action';
import * as fromFormCoverageActions from '@app/forms-store/store/entities/coverage/coverage.action';
import { CoverageSyncService } from '@core/services/coverage-sync.service';
import { CoverageFormService } from '@app/forms-store/services/coverage-form.service';

import * as backendActions from '@core/store/entities/coverage/coverage.action';
import * as backendSelectors from '@core/store/entities/coverage/coverage.selector';
import { CoverageSyncStatus } from './coverage-sync.entity';
import {
  concatMap,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { ProductType } from '@core/models/api/dsm-types';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { CoverageHelper } from '@core/helper/coverage.helper';
import { CoveragesService } from '@core/services/coverages.service';
import { SessionService } from '@core/services/session.service';
import { combineLatest, from } from 'rxjs';
import { VehicleService } from '@core/services/vehicle.service';
import { Nullable } from '@shared/utils/type.utils';
import { CoverageEntity } from '../coverage/coverage.entity';
import { VehicleEntity } from '../vehicle/vehicle.entity';
import { PERSONAL_AUTO_TRAILER_TYPE } from '@shared/constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class CoverageSyncEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private coverageFormService: CoverageFormService,
    private coverageSyncService: CoverageSyncService,
    private coverageService: CoveragesService,
    private sessionService: SessionService,
    private coverageHelper: CoverageHelper,
    private vehicleService: VehicleService
  ) {}

  formFieldUpdated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromFormCoverageActions.updateCoverage),
        map((action) => {
          const changeKey = Object.keys(action.payload)[0];
          const splitKey = changeKey.split('_');
          const coverageId = splitKey[0];
          const coverableId = !isNaN(+splitKey[1]) ? splitKey[1] : undefined;
          const code =
            isNaN(+splitKey[1]) && splitKey[2] === undefined
              ? splitKey[1]
              : splitKey[2];
          let selectedValue = action.payload[changeKey];

          let termCode: string;
          if (selectedValue === 'DECLINE_COVERAGE') {
            termCode = 'selected';
            selectedValue = 'false';
          } else {
            termCode = code;
          }
          if (
            !termCode &&
            (selectedValue === 'true' || selectedValue === 'false')
          ) {
            termCode = 'selected';
          }

          const termValue = selectedValue?.includes?.('_' + termCode)
            ? selectedValue.split('_')[0]
            : selectedValue;
          combineLatest([
            this.coverageService.getQuoteCoverages(action.productType),
            this.sessionService.getQuoteState(),
            this.vehicleService.getVehiclesByProductType(action.productType),
          ])
            .pipe(
              take(1),
              map(([coverages, quoteState, vehicles]) => {
                const currentCoverage = this.buildCurrentCoverage(
                  coverages,
                  coverageId,
                  coverableId
                );
                let filteredCoverages = this.removeUtilityTrailerCoverages(
                  vehicles,
                  coverages
                );
                filteredCoverages = this.removeBoatMotorCoverages(
                  vehicles,
                  filteredCoverages
                );
                filteredCoverages = this.checkForMockSpreadableCoverages(
                  currentCoverage,
                  filteredCoverages,
                  termCode,
                  termValue
                );
                const changes = this.coverageHelper.createCoverageChanges(
                  filteredCoverages,
                  currentCoverage,
                  termCode,
                  termValue || '',
                  quoteState
                );
                // Form may inadvertently trigger update,
                // want to make sure value actually changed
                // before dispatching the service request
                const changeValue = changes[0]?.selectedValue?.find(
                  (changeVal) => changeVal.code === termCode
                )?.value;
                const currentSelectedValue =
                  currentCoverage?.selectedValue?.find(
                    (currValue) => currValue.code === termCode
                  )?.value;
                if (changeValue === currentSelectedValue) {
                  return;
                }
                this.coverageService.dispatchCoverageUpdate(changes);
              })
            )
            .subscribe();
        })
      ),
    { dispatch: false }
  );

  backendUpdateStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backendActions.updateCoverage),
      map((action) =>
        setCoverageSyncStatusByEntityId({
          coverageId: action.payload[0].coverageId,
          status: CoverageSyncStatus.CallInProgress,
          coverableId: action.payload[0].coverableId,
          productId: action.payload[0].productId as ProductType,
        })
      )
    )
  );

  backendUpdateCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(backendActions.updateCoverageSuccess),
      map((action) =>
        setCoverageSyncStatusByEntityId({
          coverageId: action.updatedCoverage[0].coverageId,
          status: CoverageSyncStatus.Clean,
          coverableId: action.updatedCoverage[0].coverableId,
          productId: action.updatedCoverage[0].productId as ProductType,
        })
      )
    );
  });

  backendUpdateFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(backendActions.updateCoverageError),
      concatMap((action) =>
        from([
          backendActions.pokeCoverageEntityStore(),
          setCoverageSyncStatusByEntityId({
            coverageId: action.updatedCoverage[0].coverageId,
            status: CoverageSyncStatus.Dirty,
            coverableId: action.updatedCoverage[0].coverableId,
            productId: action.updatedCoverage[0].productId as ProductType,
          }),
          rebuildFormState(),
        ])
      )
    );
  });

  setCoverageSyncStatusByEntityId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setCoverageSyncStatusByEntityId),
      switchMap((action) => {
        return this.coverageSyncService
          .getCoverageSyncByEntityId(
            action.coverageId,
            action.productId,
            action.coverableId
          )
          .pipe(
            filterOutNull(),
            take(1),
            map((sync) =>
              updateCoverageSync({
                payload: {
                  ...sync,
                  status: action.status,
                },
              })
            )
          );
      })
    );
  });

  rebuildFormState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rebuildFormState),
      withLatestFrom(this.store.select(backendSelectors.getAllCoverages)),
      map(([action, entities]) => {
        const coverageModel =
          this.coverageFormService.buildFormCoverageModel(entities);
        return fromFormCoverageActions.addFormCoverage({
          productType: entities[0].productId as ProductType,
          payload: coverageModel,
        });
      })
    )
  );

  private removeUtilityTrailerCoverages(
    vehicles: VehicleEntity[],
    coverages: Nullable<CoverageEntity[]>
  ): Nullable<CoverageEntity[]> {
    const utilityTrailersCoverableIds: string[] = [];
    vehicles
      .filter(
        (vehicle) =>
          vehicle.vehicleType === 'UtilityTrailer' ||
          vehicle.vehicleType === PERSONAL_AUTO_TRAILER_TYPE
      )
      ?.forEach((vehicle) =>
        utilityTrailersCoverableIds.push(vehicle.vehicleId)
      );
    let filteredCoverages = coverages;
    utilityTrailersCoverableIds.forEach(
      (vehicleId) =>
        (filteredCoverages = filteredCoverages?.filter(
          (coverage) => coverage.coverableId !== vehicleId
        ))
    );
    return filteredCoverages;
  }

  private removeBoatMotorCoverages(
    vehicles: VehicleEntity[],
    coverages: Nullable<CoverageEntity[]>
  ): Nullable<CoverageEntity[]> {
    const motorCoverableIds: string[] = [];
    vehicles
      .filter((vehicle) => vehicle.vehicleType === 'motor')
      ?.forEach((vehicle) => motorCoverableIds.push(vehicle.vehicleId));
    let filteredCoverages = coverages;
    motorCoverableIds.forEach(
      (vehicleId) =>
        (filteredCoverages = filteredCoverages?.filter(
          (coverage) => coverage.available === true
        ))
    );
    return filteredCoverages;
  }

  private checkForMockSpreadableCoverages(
    coverage: Nullable<CoverageEntity>,
    filteredCoverages: Nullable<CoverageEntity[]>,
    termCode: string,
    termValue: string | undefined
  ): Nullable<CoverageEntity[]> {
    let newFilteredCoverages = filteredCoverages;
    if (coverage?.coverageId === 'ENO') {
      newFilteredCoverages = this.makeTermsSpreadable(
        coverage,
        filteredCoverages,
        termCode,
        termValue,
        'ENO'
      );
    }
    return newFilteredCoverages;
  }

  private makeTermsSpreadable(
    coverage: Nullable<CoverageEntity>,
    filteredCoverages: Nullable<CoverageEntity[]>,
    termCode: string,
    termValue: string | undefined,
    coverageId: string
  ) {
    return filteredCoverages
      ?.filter((cov) => {
        return (
          cov.coverageId === coverageId &&
          (cov.coverableId === coverage?.coverableId
            ? termValue !== undefined
            : cov?.selectedValue?.find(
                (currValue) => currValue.code === termCode
              )?.value !== undefined)
        );
      })
      .map((coverage) => {
        return {
          ...coverage,
          terms: coverage.terms.map((term) => {
            return {
              ...term,
              spreadable: true,
            };
          }),
        };
      });
  }

  private buildCurrentCoverage(
    coverages: Nullable<CoverageEntity[]>,
    coverageId: string,
    coverableId: string | undefined
  ) {
    let newCoverage = coverages?.find(
      (cvg) =>
        cvg.coverageId === coverageId &&
        (coverableId ? `${cvg.coverableId}` === `${coverableId}` : true)
    );

    if (newCoverage?.coverageId === 'ENO') {
      newCoverage = {
        ...newCoverage,
        terms: newCoverage?.terms.map((term) => {
          return {
            ...term,
            spreadable: true,
          };
        }),
      } as CoverageEntity;
    }
    return newCoverage;
  }
}
