import { StartNewQuoteComponent } from './../../shared/components/start-new-quote/start-new-quote.component';
import { NavigationService } from '@core/services/navigation.service';
import { map, takeUntil } from 'rxjs/operators';
import {
  StartQuotePageRepresentation,
  HelpPageRepresentation,
  GoToPolicyCenterRepresentation,
  AccountInfoPageRepresentation,
  PageIdentifier,
} from '@core/constants/pages';
import { Page } from '@core/store/entities/navigation/navigation.action';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  OnChanges,
} from '@angular/core';
import { Observable, Subject, take } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectCurrentPageId,
  selectEnabledPages,
} from '@core/store/entities/navigation/navigation.selector';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpComponent } from '@shared/components/help/help.component';
import { Nullable } from '@shared/utils/type.utils';
import { ComponentChanges } from '@shared/utils/general.utils';
import { SessionService } from '@core/services/session.service';
import {
  getPageValidationErrors,
  getPageValidationState,
} from '@core/store/entities/error/error.selector';
import { ViewportType } from '@core/store/entities/layout/layout.entity';
import { LayoutService } from '@core/services/layout.service';
import { ProductsService } from '@core/services/products.service';
import { ProductModel } from '@entities/product/product.model';
import { ProductType } from '@core/models/api/dsm-types';
import { BIND_SUSPENSION_MESSAGE } from '@shared/constants/app-constants';
import { AccountInfoComponent } from '@shared/components/account-info/account-info.component';
import { AccountInfoContainerComponent } from '@shared/components/account-info-container/account-info-container.component';
import { getDsmCallNames } from '@core/store/entities/dsm/dsm.selector';

@Component({
  selector: 'nwx-nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.scss'],
})
export class NavSidebarComponent implements OnInit, OnChanges, OnDestroy {
  static SIDEBAR_WIDTH = '250px';

  mode!: 'bottom' | 'sideWithShadow' | 'side';
  public toggled = true;
  public hideSidebar = true;
  public hovering = false;
  accountInfoLocked = false;
  teardown: Subject<boolean> = new Subject<boolean>();
  pages$: Observable<Page[]>;
  pageErrorMap: { [key: string]: boolean };
  hubPageLoaded$!: Observable<boolean>;
  products$: Observable<ProductModel[]>;
  bindSuspensionMessage = BIND_SUSPENSION_MESSAGE;

  @Input() currentPage!: Nullable<Page>;

  @ViewChild('sidebarContent')
  sidebarContent!: ElementRef;

  @ViewChildren('sidebarText')
  navMenuOptions!: QueryList<ElementRef>;
  @ViewChild('nav')
  navParent!: ElementRef;

  toggleListener$!: Observable<number>;

  mobileToggled$: Observable<boolean>;

  constructor(
    private store: Store,
    private cd: ChangeDetectorRef,
    private navigationService: NavigationService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private layoutService: LayoutService,
    private productsService: ProductsService
  ) {
    this.pageErrorMap = {};
    this.pages$ = this.store
      .select(selectEnabledPages)
      .pipe(takeUntil(this.teardown));
    this.store
      .select(getPageValidationState)
      .pipe(takeUntil(this.teardown))
      .subscribe((status) => (this.pageErrorMap = status));
    this.layoutService
      .getViewport()
      .pipe(takeUntil(this.teardown))
      .subscribe((viewportSize: ViewportType) => {
        this.setMode(viewportSize);
      });
    this.store
      .select(selectCurrentPageId)
      .pipe(takeUntil(this.teardown))
      .subscribe((page: PageIdentifier) => {
        this.accountInfoLocked = page === 'PNI';
      });
    this.mobileToggled$ = this.navigationService.getMobileToggled();
    this.products$ = this.productsService.getSelectedProducts();
  }

  ngOnDestroy(): void {
    this.teardown.next(true);
  }

  ngOnInit(): void {
    this.hubPageLoaded$ = this.sessionService.getHubPageLoaded();
    if (this.mode !== 'side') {
      this.toggled = false;
    }
    this.mobileToggled$.pipe(takeUntil(this.teardown)).subscribe((toggled) => {
      if (this.mode === 'bottom') {
        this.toggled = toggled;
      }
    });
  }

  ngOnChanges(changes: ComponentChanges<NavSidebarComponent>): void {
    this.hubPageLoaded$ = this.sessionService.getHubPageLoaded();
  }

  toggleSidebar(): void {
    this.toggled = !this.toggled;
    // need to make sure this stay in sync with the mobile version:
    if (!this.toggled) {
      this.navigationService.collapseMobileNav();
    }
  }

  onErrorHover(): void {
    this.hovering = !this.hovering;
  }

  shouldBindSuspensionBeHidden(): boolean {
    if (this.toggled) {
      return false;
    } else if (this.hovering) {
      return false;
    } else {
      return true;
    }
  }

  navigationDesired(desiredPage: Page): void {
    this.store
      .select(getDsmCallNames)
      .pipe(take(1))
      .subscribe((calls) => {
        if (calls.find((c) => c.endsWith('-initiate'))) {
          // It's unusual for an initiate to be in flight when a navigation is requested.
          // Can arise if you add a product at Hub.
          // Guards assume that all selected products are initiated,
          // so we must not let the user navigate anywhere until the initiate is settled.
          // TIB-637, 2024-06-24, sommea1
        } else {
          this.allowDesiredNavigation(desiredPage);
        }
      });
  }

  private allowDesiredNavigation(desiredPage: Page): void {
    if (desiredPage.id === GoToPolicyCenterRepresentation.id) {
      this.navigationService.pivotToPolicyCenter('summaryPageNav', 'Account');
      return;
    }
    this.navigationService.submitPage();
    if (
      !desiredPage.isNavigable ||
      desiredPage.name === this.currentPage?.name
    ) {
      return;
    }
    this.navigationService.setTargetPage(this.currentPage as Page, desiredPage);
  }

  get HomePage(): Page {
    return StartQuotePageRepresentation;
  }

  get HelpPage(): Page {
    return HelpPageRepresentation;
  }

  get AccountInfoPage(): Page {
    return AccountInfoPageRepresentation;
  }

  showNewQuoteModal(): void {
    const modal = this.modalService.open(StartNewQuoteComponent, {
      centered: true,
      keyboard: false,
    });

    modal.componentInstance.parent = modal;
    if (this.currentPage?.id === 'Summary') {
      modal.componentInstance.onlyAllowNewQuote = true;
    }
  }

  showHelpModal(): void {
    const modal = this.modalService.open(HelpComponent, {
      centered: true,
      keyboard: false,
      size: 'xl',
    });

    modal.componentInstance.parent = modal;
  }

  showAccountInfoModal(): void {
    if (this.accountInfoLocked) return;

    const modal = this.modalService.open(AccountInfoContainerComponent, {
      centered: true,
      keyboard: false,
      size: 'lg',
    });

    modal.componentInstance.parent = modal;
  }

  trackPage(index: number, page: Page): number {
    return +page.id || index;
  }

  pageHasErrors(pageName: string): Observable<boolean> {
    return this.store
      .select(getPageValidationErrors(pageName))
      .pipe(map((errors) => errors.length > 0));
  }

  setMode(layout: ViewportType): void {
    switch (layout) {
      case 'mini':
      case 'xsmall':
        this.mode = 'bottom'; // 0 - 767.999
        break;
      case 'small':
        this.mode = 'sideWithShadow'; // 768 - 991.999
        break;
      case 'medium':
      case 'large':
      case 'xlarge':
      case 'xxlarge':
        this.mode = 'side'; // 992 and up
        break;
    }
  }

  collapseMobileNav() {
    this.toggled = false;
    this.navigationService.collapseMobileNav();
  }

  doAnyProductsHaveBindSuspension(
    products: Nullable<ProductModel[]>
  ): boolean | undefined {
    return products?.some((product) => product.hasBindSuspension);
  }

  pageShouldLockWithoutQuoteId(
    products: Nullable<ProductModel[]>,
    page: Page
  ): boolean {
    const associatedProducts = products?.filter((p) =>
      page.associatedProducts?.includes(p.type)
    );

    if (associatedProducts?.length) {
      const anyPageHasMissingId = associatedProducts.some((p) => !p.quoteId);
      return anyPageHasMissingId;
    }
    return false;
  }

  getProductIcon(
    productType: ProductType,
    pages: Page[] | null
  ): string | undefined {
    const page = pages?.find((page) =>
      page.associatedProducts?.includes(productType)
    );
    return page ? page.icon : '';
  }
}
