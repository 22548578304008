import { ObjectValidator } from '@core/helper/object-validator';
import { ProductType } from '@core/models/api/dsm-types';
import { Nullable } from '@shared/utils/type.utils';
import { Observable } from 'rxjs';

export type ScheduledItemType = 'Category' | 'PersonalEffect';
export interface ScheduledCategoryEntity {
  scheduledCategoryId: string;
  name: string;
  isScheduledItemsApplicable: boolean;
  limit?: string;
  scheduledItems?: ScheduledCategoryItem[];
  isLimitApplicable?: boolean;
  availableOptions?: ScheduledCategoryOption[];
  isIncomeLessThan5000Required?: boolean;
  isIncomeLessThan5000?: boolean;
  productType?: ProductType;
}

export interface ScheduledCategoryItem {
  itemId: string;
  description: string;
  value: string;
  scheduledCategoryId: string;
  isPreviouslyInsuredByNW?: boolean;
  appraisalDate?: string; // yyyy-mm-dd
  areItemsOnPublicDisplay?: boolean;
  limit?: string; // TODO: this should only be attached to parent Entity
  isIncomeLessThan5000?: boolean; // TODO: this should only be attached to parent Entity
}
export interface ScheduledCategoryOption {
  code: string;
  value: string;
  priority: string;
}

export interface ScheduledCategoriesViewModel {
  title: string;
  displayGrouping: DisplayGroupingType;
  scheduledCategories: ScheduledCategoryEntity[];
}

export type DisplayGroupingType = 'Blanket' | 'Plus';

export interface ScheduledCategoriesResponse {
  scheduledCategories: ScheduledCategoryEntity[];
}
export interface ScheduledCategoryEvent {
  parentCategory: ScheduledCategoryEntity;
  scheduledItem?: Nullable<ScheduledCategoryItem>;
}
export interface ScheduledItemFormWrapperInputs {
  entityId: string;
  scheduledItemType: ScheduledItemType;
  productType: ProductType;
  currentItem?: Nullable<ScheduledCategoryItem>;
  scheduledItemsAdded$?: Observable<ScheduledCategoryItem[]>;
  allScheduledCategories$?: Observable<Nullable<ScheduledCategoryEntity[]>>; // used to populate category dropdown for personal effects
}

export function sanitizeScheduledCategoryEntities(
  input: unknown
): ScheduledCategoryEntity[] {
  return ObjectValidator.sanitizeArray(input, sanitizeScheduledCategoryEntity);
}

export function sanitizeScheduledCategoryEntity(
  input: unknown
): ScheduledCategoryEntity {
  return ObjectValidator.forceSchema<ScheduledCategoryEntity>(
    input,
    {
      scheduledCategoryId: 'string',
      name: 'string',
      isScheduledItemsApplicable: 'boolean',
      limit: 'string',
      scheduledItems: [sanitizeScheduledCategoryItem],
      isLimitApplicable: 'boolean',
      availableOptions: [sanitizeScheduledCategoryOption],
      isIncomeLessThan5000Required: 'boolean',
      isIncomeLessThan5000: 'boolean',
      productType: 'string',
    },
    []
  );
}

export function sanitizeScheduledCategoryItem(
  input: unknown
): ScheduledCategoryItem {
  return ObjectValidator.forceSchema<ScheduledCategoryItem>(
    input,
    {
      itemId: 'string',
      description: 'string',
      value: 'string',
      scheduledCategoryId: 'string',
      isPreviouslyInsuredByNW: 'boolean',
      appraisalDate: 'string',
      areItemsOnPublicDisplay: 'boolean',
      limit: 'string',
      isIncomeLessThan5000: 'boolean',
    },
    []
  );
}

export function sanitizeScheduledCategoryOption(
  input: unknown
): ScheduledCategoryOption {
  return ObjectValidator.forceSchema<ScheduledCategoryOption>(
    input,
    {
      code: 'string',
      value: 'string',
      priority: 'string',
    },
    []
  );
}
