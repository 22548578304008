import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import {
  CurrentCarrierFormModel,
  CurrentCarrierFormOptions,
} from '@core/interfaces/interfaces';
import {
  AlhReportDetails,
  CurrentCarrierReportDetails,
  MvrReportDetails,
  PlhReportDetails,
} from '@core/models/api/response/uw-reports-response.model';
import { CurrentCarrierService } from '@core/services/current-carrier.service';
import { UWReportsService } from '@core/services/uw-reports.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'nwx-view-report-modal',
  templateUrl: './view-report-modal.component.html',
  styleUrls: ['./view-report-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewReportModalComponent implements OnInit {
  // Must be assigned by whoever creates the NgbModal:
  modalTitle!: string;
  alhReportDetails!: AlhReportDetails[] | null;
  mvrReportDetails!: MvrReportDetails[] | null;
  plhReportDetails!: PlhReportDetails[] | null;
  currentCarrierReportDetails!: CurrentCarrierReportDetails[] | null;

  currentCarrierModel$: Observable<CurrentCarrierFormModel>;
  currentCarrierOptions$: Observable<CurrentCarrierFormOptions>;
  modelInStore?: CurrentCarrierFormModel;
  modifiedModel?: CurrentCarrierFormModel;
  showSaveAndCancel = false;

  constructor(
    public activeModal: NgbActiveModal,
    private currentCarrierService: CurrentCarrierService,
    private uwReportsService: UWReportsService
  ) {
    this.currentCarrierModel$ = currentCarrierService
      .getCurrentCarrierFormModel('PersonalAuto')
      .pipe(tap((model) => (this.modelInStore = model)));
    this.currentCarrierOptions$ =
      currentCarrierService.getCurrentCarrierFormOptions('PersonalAuto');
  }

  ngOnInit(): void {
    if (this.currentCarrierReportDetails) {
      this.showSaveAndCancel = true;
    }
  }

  onCurrentCarrierChange(model: CurrentCarrierFormModel): void {
    this.modifiedModel = model;
  }

  onSave(): void {
    this.currentCarrierService
      .saveIfChanged(this.modifiedModel, this.modelInStore)
      .then((response) => {
        this.uwReportsService.refreshUWReportsByProduct('PersonalAuto');
        this.activeModal.close();
      })
      .catch((error) => {
        // Errors will enter the store as usual. I think we can ignore here.
      });
  }
}
