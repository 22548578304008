import { ProductType, RatingType } from '@core/models/api/dsm-types';
import { InitiateNewBusinessRequest } from '@core/models/api/request/initiate-new-business-request.model';
import { ProducerRequest } from '@core/models/api/request/producer-request.model';
import {
  UpdateQuoteFormRequest,
  UpdateQuoteRequest,
  UpdateQuoteRequestBody,
} from '@core/models/api/request/update-quote-request.model';
import { getCoreState } from '@core/store/reducers';
import { EligibilityFormSelectors } from '@core/store/selectors';
import {
  getAccountId,
  getAccountLinked,
} from '@entities/account/account.selector';
import {
  getAddressByType,
  getAddressForProduct,
} from '@entities/address/address.selector';
import { getAgency } from '@entities/agency/agency.selector';
import { getAllSelectedMemberModels } from '@entities/member/member.selector';
import { PolicyHolderEntity } from '@entities/policyholder/policyholder.entity';
import { UpdateQuoteOptions } from '@entities/product/product.actions';
import {
  getPolicyDateOptions,
  getProduct,
} from '@entities/product/product.selectors';
import { createSelector } from '@ngrx/store';
import { PersonUtils } from '@shared/utils/person.utils';
import { getUser } from '../user/user.selector';
import { ProducerUtils } from '@shared/utils/producer.utils';
import { getAllFeatureFlags } from '@entities/feature-flag/feature-flag.selector';
import { GeneralUtils } from '@shared/utils/general.utils';

export const getQuoteState = createSelector(getCoreState, (core) => core.quote);

export const buildInitiateNewBusinessRequest = (
  productType: ProductType,
  correlationId?: string
) =>
  createSelector(
    getAddressByType('Default'),
    getAccountId,
    getAccountLinked,
    getProduct(productType),
    getAgency,
    getAllSelectedMemberModels,
    getUser,
    getPolicyDateOptions(productType),
    (
      policyAddress,
      accountId,
      accountLinked,
      product,
      agency,
      people,
      user,
      policyDateOptions
    ) => {
      const producer: ProducerRequest = {
        producerCode: agency.producerCode || '', // '340030090',
        type: ProducerUtils.getProducerType(user, agency.producerCode || ''),
      };

      let policyHolders: PolicyHolderEntity[] = [];
      if (!accountId || accountLinked) {
        policyHolders = people
          .filter(
            (person) => person.policyHolderType !== 'PolicyPriNamedInsured'
          )
          .map(
            (person) =>
              ({
                policyHolderType: PersonUtils.getPolicyHolderType(person),
                entityId: person.entityId,
                policyHolderId: null,
                productId: productType,
                person: {
                  ...person.person,
                },
                policyRoles: [],
              } as PolicyHolderEntity)
          );
      }

      let request: InitiateNewBusinessRequest = {
        producer,
        effectiveDate: product?.effectiveDate,
        productType,
        accountId,
        creditConsent: true,
        yearsWithPriorCarrier: '0',
        policyHolders,
        policyAddress,
        correlationId,
        hasNWPSPolicies: false,
        docDelPreference: product?.docDelPreference,
        automateElectronicDocumentDelivery:
          product?.automateElectronicDocumentDelivery,
      };

      if (
        ['Homeowner', 'Condominium'].includes(productType) &&
        !!product?.associatedMSBEstimateNumber
      ) {
        request = {
          ...request,
          coveredLocation: {
            associatedMSBEstimateNumber: product?.associatedMSBEstimateNumber,
            productType,
          },
        };
      }

      if (productType === 'PersonalUmbrella') {
        request = {
          ...request,
          effectiveDate: policyDateOptions.minDate,
        };
      }

      return request;
    }
  );

export const buildRateQuoteRequest = (
  productType: ProductType,
  ratingType: RatingType
) =>
  createSelector(getProduct(productType), (product) => {
    return {
      quoteId: product?.quoteId,
      productType,
      ratingType,
    };
  });

export const buildUpdateQuoteRequest = (
  productType: ProductType,
  override?: Partial<UpdateQuoteFormRequest>,
  correlationId?: string
) =>
  createSelector(
    getProduct(productType),
    getAddressForProduct(productType),
    EligibilityFormSelectors.buildQuotePrequalificationAnswersFromEligibilityForm(
      productType
    ),
    getAllFeatureFlags,
    (product, dsmAddress, prequalificationAnswers, featureFlags) => {
      const request: UpdateQuoteRequest = {
        quoteId: product?.quoteId,
        productType,
        body: { productType },
        previousEffectiveDate: product?.effectiveDate,
      };

      if (override?.docDelPreference) {
        request.body.docDelPreference = override?.docDelPreference;
      }

      if (
        GeneralUtils.isBoolean(override?.automateElectronicDocumentDelivery)
      ) {
        request.body.automateElectronicDocumentDelivery =
          override?.automateElectronicDocumentDelivery;
      }

      if (override?.pni?.person) {
        const gender = override.pni.person.gender;
        request.body.pni = {
          person: {
            ...override.pni.person,
            gender:
              productType === 'PersonalAuto'
                ? gender
                : gender === 'X'
                ? ''
                : gender,
          },
        };
      }

      if (override?.address) {
        request.body.policyAddress = {
          addressType: productType,
          addressId: dsmAddress?.addressId || '0',
          addressLine1: override?.address?.addressLine1,
          addressLine2: override?.address?.addressLine2,
          city: override?.address?.city,
          state: override?.address?.state,
          postalCode: override?.address?.postalCode,
          country: override?.address?.country,
        };
      }

      if (override?.hasCustomerViewedDocuments) {
        request.body.hasCustomerViewedDocuments =
          override?.hasCustomerViewedDocuments;
      }

      if (override?.hasReceivedRequiredDocuments) {
        request.body.hasReceivedRequiredDocuments =
          override?.hasReceivedRequiredDocuments;
      }

      if (prequalificationAnswers) {
        request.body.prequalificationAnswers = prequalificationAnswers;
      }

      if (override?.yearsWithPriorCarrier) {
        request.body.yearsWithPriorCarrier = override?.yearsWithPriorCarrier;
      } else if (product?.yearsWithPriorCarrier) {
        request.body.yearsWithPriorCarrier = product.yearsWithPriorCarrier;
      }

      if (featureFlags?.assignedRiskPlan) {
        request.body.isAssignedRiskPlan = product?.isAssignedRiskPlan;
      }

      if (typeof override?.isAssignedRiskPlan === 'boolean') {
        request.body.isAssignedRiskPlan = override?.isAssignedRiskPlan;
      }

      if (typeof override?.hasDrivingDataConsent === 'boolean') {
        request.body.hasDrivingDataConsent = override?.hasDrivingDataConsent;
      }

      if (override?.currentBillTo) {
        request.body.currentBillTo = override?.currentBillTo;
      }

      if (product?.termType) {
        request.body.termType = product.termType;
      }
      if (product?.offeringType) {
        request.body.offeringType = product.offeringType;
      }
      if (correlationId) {
        request.correlationId = correlationId;
      }
      if (typeof product?.hasNWPSPolicies === 'boolean') {
        request.body.hasNWPSPolicies = product.hasNWPSPolicies;
        if (typeof product.numberOfOtherVehicles === 'number') {
          request.body.numberOfOtherVehicles = product.numberOfOtherVehicles;
        } else {
          request.body.numberOfOtherVehicles = null;
        }
      }
      if (typeof product?.hasCompanyCar === 'boolean') {
        request.body.hasCompanyCar = product.hasCompanyCar;
      }
      if (product?.additionalHouseholdInfo) {
        request.body.additionalHouseholdInfo = product.additionalHouseholdInfo;
      }

      if (product?.eligibilityInformation) {
        request.body.eligibilityInformation = product.eligibilityInformation;
      }

      return request;
    }
  );

export const buildPatchQuoteRequest = (
  body: UpdateQuoteRequestBody,
  options?: UpdateQuoteOptions,
  correlationId?: string
) =>
  createSelector(
    getProduct(body.productType),
    EligibilityFormSelectors.buildQuotePrequalificationAnswersFromEligibilityForm(
      body.productType
    ),
    (product, prequalificationAnswers) => {
      const request: UpdateQuoteRequest = {
        quoteId: product?.quoteId,
        productType: body.productType,
        body: { ...body },
        previousEffectiveDate: product?.effectiveDate,
      };

      if (
        !request.body.hasOwnProperty('isAssignedRiskPlan') &&
        typeof product?.isAssignedRiskPlan === 'boolean'
      ) {
        request.body.isAssignedRiskPlan = product.isAssignedRiskPlan;
      }

      if (prequalificationAnswers) {
        request.body.prequalificationAnswers = prequalificationAnswers;
      }

      if (options?.overrideQuoteStatus) {
        request.quoteStatus = options?.quoteStatus || product?.quoteStatus;
      }

      if (product?.yearsWithPriorCarrier) {
        request.body.yearsWithPriorCarrier = product.yearsWithPriorCarrier;
      }

      if (request.body.effectiveDate === product?.effectiveDate) {
        delete request.body.effectiveDate;
      }

      if (correlationId) {
        request.correlationId = correlationId;
      }
      return request;
    }
  );

export const getQuoteUpdateInProgress = createSelector(
  getQuoteState,
  (state) => state.updateInProgress
);

export const getQuoteInProgressCount = createSelector(
  getQuoteState,
  (state) => state.quoteInProgress
);

export const getQuoteOrUpdateInProgressCount = createSelector(
  getQuoteUpdateInProgress,
  getQuoteInProgressCount,
  (a, b) => a + b
);
