import { createAction, props } from '@ngrx/store';
import { PageAlert } from './page-alert.model';

export const LoadAllPageAlerts = createAction(
  '[Page Alerts] Load All Page Alerts'
);

export const LoadAllPageAlertFail = createAction(
  '[Page Alerts] Load All Page Alerts Fail',
  props<{ error: Error }>()
);

export const LoadAllPageAlertSuccess = createAction(
  '[Page Alerts] Load All Page Alerts Success',
  props<{ payload: PageAlert[] }>()
);
