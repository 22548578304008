<div class="bolt-container-fluid outer-container">
  <div class="bolt-row">
    <div class="bolt-col-6" [ngClass]="{
        'bolt-col-xl-auto': product.displayType === 'addOn',
        'bolt-col-xl-5': product.displayType !== 'addOn'
      }">
      <div class="product-name">
        <img class="product-image" src="{{ product.imageUrl }}" alt="{{ product.name }} icon" />
        <div>
          <div class="product-label-text" id="pd-{{ product.name }}">
            {{ product.name }}
          </div>
          <div class="product-helper-text" id="pd-{{ product.helpText }}-helper-text" *ngIf="product.helpText">
            {{ product.helpText }}
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="product.displayType === 'selected'">
      <ng-container *ngIf="
          product.installmentPrice || product.totalPrice;
          else priceSubstitute
        ">
        <div class="bolt-col-6 bolt-col-xl-7">
          <div class="bolt-row">
            <div class="bolt-col-sm-12 bolt-col-md-6 premium-overview-installment"
              id="pd-{{ product.name }}-installment">
              <p class="installment-price" id="pd-{{ product.name }}-installment-price"
                *ngIf="product.installmentPrice">
                {{ product.installmentPrice }}
              </p>
              <p class="installment-frequency" id="pd-{{ product.name }}-installment-frequency"
                *ngIf="product.installmentFrequency">
                {{ product.installmentFrequency }}
              </p>
            </div>

            <div class="bolt-col-sm-12 bolt-col-md-6 premium-overview-total" id="pd-{{ product.name }}-total">
              <p class="total-price" id="pd-{{ product.name }}-total-price" *ngIf="product.totalPrice">
                {{ product.totalPrice }}
              </p>
              <p class="total-term-length" id="pd-{{ product.name }}-total-term-length" *ngIf="product.termLength">
                {{ product.termLength }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>
      
      <ng-template #priceSubstitute>
        <div class="bolt-col-6 bolt-col-xl-7 error" *ngIf="product.errorMessage">
          <bolt-icon size="md" name="exclamation-circle-filled" color="theme-info"
            id="errorIconFor{{ product.name }}"></bolt-icon>
          <div class="error-message" id="errormessageFor{{ product.name }}">
            {{ product.errorMessage }}
          </div>
        </div>
        <div class="bolt-col-6 bolt-col-xl-7 error" *ngIf="product.warningMessage">
          <bolt-icon size="md" name="exclamation-triangle-filled" color="theme-warning"
            id="warningIconFor{{ product.name }}"></bolt-icon>
          <div class="error-message" id="warningMessageFor{{ product.name }}">
            {{ product.warningMessage }}
          </div>
        </div>
        <div class="bolt-col-6 bolt-col-xl-7 error" *ngIf="product.message">
          <div class="error-message" id="messageFor{{ product.name }}">
            {{ product.message }}
          </div>
        </div>
        <div class="bolt-col-6 bolt-col-xl-7 loading" *ngIf="
            !product.errorMessage && !product.warningMessage && !product.message
          ">
          <nwx-passive-spinner class="flat" label="Loading"></nwx-passive-spinner>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="product.displayType === 'addOn'">
      <div class="bolt-col upsell-content">
        <bolt-button type="secondary" size="sm" [attr.disabled]="product.disabled ? true : null"
          (click)="addUpsell.next(product?.type)" id="pdc-add-{{ product.name }}">{{ product.name }}</bolt-button>
      </div>
    </ng-container>
  </div>
</div>