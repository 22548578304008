<div
  class="bolt-container-fluid"
  [formGroup]="form"
  [nwxExposeFormErrors]="form"
  [formName]="getFormName()"
  [submitted]="submitted"
>
  <div class="bolt-row nwx-task-tile bolt-space-bottom-sm">
    <div class="bolt-col-12 bolt-col-sm-6 bolt-col-lg-4 bolt-space-bottom-sm">
      <div class="bolt-row bolt-no-gutters">
        <div class="bolt-col-auto title-img-content">
          <img
            [src]="imageUrl(product.type)"
            alt="{{ product.type }} icon"
            class="title-img"
          />
        </div>
        <div class="bolt-col title-text-content">
          <div class="content-label">
            <strong>{{ product.name }}</strong>
          </div>
          <div class="content-subtext bolt-body-copy-sm">
            Submission ID: <span class="bold">{{ product.quoteId }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="bolt-col-12 bolt-col-sm-6 bolt-col-lg-5 bolt-space-bottom-sm">
      <bolt-date-picker
        ngDefaultControl
        instructionaltext="MM/DD/YYYY"
        label=""
        formControlName="effectiveDate"
        [name]="camelCaseProductType + '-effective-date'"
        [attr.id]="camelCaseProductType + '-effective-date'"
        [min]="calendarMinDate"
        [max]="maxEffectiveDate"
      ></bolt-date-picker>
    </div>
    <div class="bolt-col">
      <ng-template #removeModal let-modal>
        <div class="modal-header">Operation can not be undone</div>
        <div class="modal-body">Remove this product?</div>
        <div class="modal-footer">
          <bolt-button
            [id]="camelCaseProductType + '-cancel'"
            (click)="modal.dismiss()"
            >Cancel</bolt-button
          >
          <bolt-button
            [id]="camelCaseProductType + '-remove'"
            (click)="modal.close()"
            >Remove</bolt-button
          >
        </div>
      </ng-template>
      <div
        class="remove-policy-container"
        (click)="promptToRemovePolicy($event, removeModal)"
      >
        <a href="#">Remove policy</a>
        <bolt-icon
          name="trash"
          [id]="product.type + '-trash'"
          color="medium-blue"
          size="md"
          class="icon-top-padding"
        ></bolt-icon>
      </div>
    </div>
  </div>
</div>
