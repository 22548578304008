import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '@core/helper/form-helper';
import {
  NationwideAccountRegistrationInfo,
  RegistrationLinkCommunicationMethod,
} from '@core/models/api/dsm-types';
import { NavigationService } from '@core/services/navigation.service';
import { OnlineAccountRegHelpText } from '@shared/constants/help-text-constants';
import { GeneralUtils } from '@shared/utils/general.utils';
import { Nullable } from '@shared/utils/type.utils';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
  selector: 'nwx-account-registration',
  templateUrl: './account-registration.component.html',
  styleUrls: ['./account-registration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountRegistrationComponent implements OnInit, OnDestroy {
  @Input() registrationInfo: Nullable<NationwideAccountRegistrationInfo>;
  @Input() pniPhone: Nullable<string>;
  @Input() pniEmail: Nullable<string>;
  @Input() isModal: Nullable<boolean>;
  @Input() hasUpdatedRegistrationForm: Nullable<boolean>;
  @Input() showSpinner: boolean = false;
  @Input() canEdit: boolean = true;

  @Output() formReady = new EventEmitter<FormGroup>();
  @Output() submitModal = new EventEmitter<void>();
  @Output() toggleDisclaimer = new EventEmitter<void>();

  readonly = true;
  form: FormGroup = new FormGroup({});
  setupAcctLabel = 'Does the customer want to set up an online account?';
  linkPrefLabel =
    'How would the customer like to receive their one-time registration link?';
  accountSetupDisclaimer = OnlineAccountRegHelpText;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.form = this.buildForm(this.fb);
    this.formReady.emit(this.form);
    this.setValidators();
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged(this.hasChanges))
      .subscribe((changes: NationwideAccountRegistrationInfo) => {
        if (
          changes.registrationLinkCommunicationMethod ||
          changes.doesCustomerWantOnlineAccount
        ) {
          this.setValidators();
        }
      });
    this.setReadonly();
    if (!this.canEdit) {
      this.form.disable();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get linkPreference(): RegistrationLinkCommunicationMethod {
    return this.form.get('registrationLinkCommunicationMethod')?.value;
  }

  get readableLinkPref(): 'Email' | 'Mobile phone' {
    switch (this.linkPreference) {
      case 'ElectronicAddress':
        return 'Email';
      case 'MobilePhone':
        return 'Mobile phone';
    }
  }

  get wantsOnlineAccount(): boolean {
    return GeneralUtils.booleanFromStringOrPassThrough(
      this.form.get('doesCustomerWantOnlineAccount')?.value
    );
  }

  buildForm(fb: FormBuilder): FormGroup {
    return fb.group({
      doesCustomerWantOnlineAccount: fb.control(this.getSetupOnlineAccount(), [
        Validators.required,
      ]),
      registrationLinkCommunicationMethod: fb.control(
        this.registrationInfo?.registrationLinkCommunicationMethod,
        [Validators.required]
      ),
      primaryNamedInsuredMobileNumber: fb.control(
        this.registrationInfo?.primaryNamedInsuredMobileNumber || this.pniPhone,
        [Validators.required]
      ),
      primaryNamedInsuredEmail: fb.control(this.pniEmail, {
        validators: [Validators.required],
        updateOn: 'blur',
      }),
      hasConsentedToOneTimeRegistrationText: fb.control(
        this.registrationInfo?.hasConsentedToOneTimeRegistrationText,
        [Validators.required, Validators.requiredTrue]
      ),
    });
  }

  hasChanges(
    a: NationwideAccountRegistrationInfo,
    b: NationwideAccountRegistrationInfo
  ): boolean {
    return (
      a.doesCustomerWantOnlineAccount === b.doesCustomerWantOnlineAccount &&
      a.hasConsentedToOneTimeRegistrationText ===
        b.hasConsentedToOneTimeRegistrationText &&
      a.primaryNamedInsuredMobileNumber === b.primaryNamedInsuredMobileNumber &&
      a.registrationLinkCommunicationMethod ===
        b.registrationLinkCommunicationMethod &&
      a.primaryNamedInsuredEmail === b.primaryNamedInsuredEmail
    );
  }

  setReadonly(): void {
    this.readonly =
      !!this.linkPreference && this.wantsOnlineAccount && !this.isModal;
  }

  setValidators(): void {
    FormHelper.enableOrDisable(
      'primaryNamedInsuredEmail',
      this.canEdit &&
        this.wantsOnlineAccount &&
        this.linkPreference === 'ElectronicAddress',
      [Validators.required],
      this.form
    );
    FormHelper.enableOrDisable(
      'hasConsentedToOneTimeRegistrationText',
      this.canEdit &&
        this.wantsOnlineAccount &&
        this.linkPreference === 'MobilePhone',
      [Validators.required, Validators.requiredTrue],
      this.form
    );
    FormHelper.enableOrDisable(
      'primaryNamedInsuredMobileNumber',
      this.canEdit &&
        this.wantsOnlineAccount &&
        this.linkPreference === 'MobilePhone',
      [Validators.required],
      this.form
    );
    FormHelper.enableOrDisable(
      'registrationLinkCommunicationMethod',
      this.canEdit && this.wantsOnlineAccount,
      [Validators.required],
      this.form
    );
  }

  getSetupOnlineAccount(): 'true' | 'false' {
    return (
      GeneralUtils.parseStringFromBoolean(
        this.registrationInfo?.doesCustomerWantOnlineAccount
      ) || 'true'
    );
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.submitModal.next();
    } else {
      this.navigationService.submitPage();
    }
  }

  exitReadonly(): void {
    this.toggleDisclaimer.emit();
    this.readonly = false;
  }
}
