<form [formGroup]="form">
  <bolt-tabset class="members">
    <ng-container
      *ngFor="let member of members; let i = index; trackBy: trackMembers"
    >
      <bolt-tablabel
        >{{ member.person?.firstName }}
        {{ member.person?.lastName }}
        <ng-container *ngIf="isMemberPni(member)"> (PNI) </ng-container>
        <ng-container *ngIf="isMemberFormInvalid(i)">
          <bolt-icon
            name="exclamation-circle-filled"
            size="sm"
            color="theme-error"
          ></bolt-icon> </ng-container
      ></bolt-tablabel>
      <bolt-tabpanel>
        <nwx-tasks-members-form
          [index]="i"
          [member]="member"
          [policyholder]="policyholder"
          [memberTasks]="getMemberSpecificTasks(member)"
          [pniTasks]="pniTasks"
          [products]="products"
          (valueChange)="onValueChange($event)"
          (formReady)="addChildForm($event, i, 'member')"
        ></nwx-tasks-members-form
      ></bolt-tabpanel>
    </ng-container>
  </bolt-tabset>
</form>
