<div
  class="coverages-outer-container"
  [formGroup]="form"
>
  <ng-container *ngIf="totalPolicyPremium > 0; else premiumSubstitute">
    <div
      class="premium-section bolt-justify-content-center"
      id="hcdc-premium-monthly-{{ product.type }}"
    >
      <h2 class="premium-monthly">
        {{ totalPolicyPremium / 12 | currency
        }}<span class="per-month"><strong>&nbsp;/ month</strong></span>
      </h2>
    </div>
    <div class="bolt-row bolt-justify-content-center" id="hcdc-premium-total">
      <h3 class="premium-total">
        {{ totalPolicyPremium | currency }}
      </h3>
      <span class="per-month-monthly"> &nbsp;/ 12 month term</span>
    </div>
    <hr />
    <div class="bolt-row effective-container">
      <div class="bolt-col-xs-12 bolt-col-sm-6">
        <div
          class="update-effectiveDate-container"
          *ngIf="!showDatePicker"
          (click)="showDatePicker = true"
        >
          <a id="hcdc-Effective-date-{{ product.type }}"
            >Effective date {{ form.value.effectiveDate }}</a
          >
          <bolt-icon
            name="pencil"
            [attr.id]="'hcdc-pencil-' + product.type"
            color="medium-blue"
            [attr.size]="maxSizeMini ? 'sm' : 'md'"
            class="icon-top-padding"
          ></bolt-icon>
        </div>
        <nwx-datepicker-input
          *ngIf="showDatePicker"
          controlId="effectiveDate"
          id="hcdc-effective-date"
          label="Effective date"
          controlName="effectiveDate"
          placeholder="MM/DD/YYYY"
          [minDate]="policyDateOptions?.minDate || null"
          [maxDate]="policyDateOptions?.maxDate || null"
          [showAdvancedQuoteDiscount]="false"
          [disabled]="(updateInProgress$ | async) || false"
          relevantProduct="Homeowner"
        ></nwx-datepicker-input>
      </div>
      <div class="bolt-col-xs-12 bolt-col-sm-6">
        <ng-template #removeModal let-modal>
          <div class="modal-header">Operation can not be undone</div>
          <div class="modal-body">Remove this product?</div>
          <div class="modal-footer">
            <bolt-button [attr.id]="'hcdc-cancel'" (click)="modal.dismiss()"
              >Cancel</bolt-button
            >
            <bolt-button [attr.id]="'hcdc-remove'" (click)="modal.close()"
              >Remove</bolt-button
            >
          </div>
        </ng-template>
        <div
          class="remove-policy-container"
          id="hcdc-remove-policy"
          (click)="promptToRemovePolicy($event, removeModal)"
        >
          <a href="#">Remove policy</a>
          <bolt-icon
            name="trash"
            color="medium-blue"
            [attr.size]="maxSizeMini ? 'sm' : 'md'"
            class="icon-top-padding"
          ></bolt-icon>
        </div>
      </div>
    </div>
    <hr />
    <div class="details-container">
      <div class="bolt-row bolt-justify-content-between reconstruction-cost">
        <div class="bolt-col-xs-6">Reconstruction cost</div>
        <div class="bolt-col-xs-6">
          {{
            !!coveredLocation?.reconstructionCost
              ? (coveredLocation?.reconstructionCost | currency)
              : '$- -.- -'
          }}
        </div>
      </div>
      <div class="bolt-row bolt-justify-content-between">
        <div class="bolt-col-xs-12">
          <nwx-msb-report-number
            [coveredLocation]="coveredLocation"
            [productModel]="product"
            [isLaunchedFromHubPage]="true"
          ></nwx-msb-report-number>
        </div>
      </div>
    </div>
    <hr />
    <div class="details-container">
      <div class="info-row heading">
        <div class="bolt-row bolt-justify-content-between">
          <div class="bolt-col-xs-6">
            <strong>Property Details</strong>
          </div>
        </div>
      </div>
      <div class="bolt-row info-row">
        <div class="bolt-col-xs nwpii">{{ formattedAddress | titlecase }}</div>
      </div>
      <div class="bolt-row bolt-justify-content-between info-row">
        <div class="bolt-col-xs-6">Square footage</div>
        <div class="bolt-col-xs-6">
          {{ coveredLocation?.constructionInfo?.squareFootage }}
        </div>
      </div>
      <div class="bolt-row bolt-justify-content-between info-row">
        <div class="bolt-col-xs-6">Year roof last replaced</div>
        <div class="bolt-col-xs-6">
          {{ yearRoofReplaced }}
        </div>
      </div>
      <div class="bolt-row bolt-justify-content-between info-row">
        <div class="bolt-col-xs-6">Year built</div>
        <div class="bolt-col-xs-6">
          {{ coveredLocation?.constructionInfo?.yearBuilt }}
        </div>
      </div>
      <div class="bolt-row info-row">
        <div class="bolt-col-xs-6" (click)="routeToHome()">
          <span id="hcdc-view-all-reconstruction-info" class="reconstruction-link"
            >View all reconstruction info</span
          >
        </div>
      </div>
    </div>
    <hr class="coverages-divider" />
    <div class="details-container">
      <div class="info-row heading">
        <div class="bolt-row bolt-justify-content-between">
          <div class="bolt-col-xs-6">
            <strong>Coverages</strong>
          </div>
        </div>
      </div>
      <div
        class="bolt-row bolt-justify-content-between"
        *ngFor="let coverage of displayCoverages"
      >
        <div class="bolt-col-xs-6 info-row">{{ coverage.name }}</div>
        <div class="bolt-col-xs-6 info-row">
          {{ coverage.value }}
        </div>
      </div>
    </div>
    <hr class="coverages-divider" />
    <div class="details-container">
      <div class="info-row heading">
        <div class="bolt-row bolt-justify-content-between">
          <div class="bolt-col-xs-6">
            <strong>Deductibles</strong>
          </div>
        </div>
      </div>
      <div
        class="bolt-row bolt-justify-content-between"
        *ngFor="let deductible of displayDeductibles"
      >
        <div class="bolt-col-xs-6 info-row">{{ deductible.name }}</div>
        <div
          class="bolt-col-xs-6 info-row"
          attr.id="{{ deductible.name }}"
          attr.name="{{ deductible.name }}"
        >
          {{ deductible.value }}
        </div>
      </div>
      <div class="coverages-footer">
        <div class="bolt-row">
          <div class="bolt-col-xs pb-20">
            <strong class="remaining-covs"
              >... +{{ remainingCoverageCount }} more coverages</strong
            >
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="button-container"> 
    <div class="bolt-row">
      <div class="bolt-col-xs">
        <bolt-button
          type="primary"
          size="sm"
          [attr.id]="'acdc-view-edit-coverage'"
          (click)="editCoverages()"
          >View/edit all coverages</bolt-button
        >
      </div>
    </div>
  </div>
</div>

<ng-template #premiumSubstitute>
  <div id="premiumSubstitute" class="premium-section bolt-justify-content-center">
    <h2 class="premium-monthly">
      <span class="per-month"><strong>&nbsp;$ --.-- / month</strong></span>
    </h2>
  </div>
  <div class="bolt-row bolt-justify-content-center">
    <h3 class="premium-total">
      $ --.-- 
    </h3>
    <span class="per-month-monthly">
      &nbsp;/ 12 month term</span
    >
  </div>
  <hr />
  <div class="coverages-footer pb-20">
    <nwx-notification-bubble type="info">
      Rate unavailable
    </nwx-notification-bubble>
  </div>
  <hr />
  <div class="details-container-last"></div>
</ng-template>