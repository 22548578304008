import { RetrieveActions, SessionActions } from '@core/store/actions';
import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { PremiumActions } from '@core/store/actions';

import { PremiumEntity } from '@core/store/entities/premium/premium.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';

export interface PremiumState extends EntityState<PremiumEntity> {
  error: ErrorModel;
}

export const adapter: EntityAdapter<PremiumEntity> =
  createEntityAdapter<PremiumEntity>({
    selectId: (premium) => premium.productType,
  });

const PremiumReducer = createReducer(
  adapter.getInitialState({
    error: {},
  }),
  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({
      error: {},
    })
  ),
  on(PremiumActions.addPremiumSuccess, (state, { payload }) =>
    adapter.addOne(payload, { ...state })
  ),
  on(PremiumActions.addPremiumError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(PremiumActions.updatePremium, (state, { payload }) =>
    adapter.upsertOne(payload, { ...state })
  ),
  on(PremiumActions.updatePremiumSuccess, (state, { payload }) =>
    adapter.updateOne(payload, { ...state })
  ),
  on(PremiumActions.updatePremiumError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(PremiumActions.deletePremiumSuccess, (state, { payload }) =>
    adapter.removeOne(payload, { ...state })
  ),
  on(PremiumActions.deletePremiumError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    if (
      payload?.response?.offeredQuotes[0]?.premium &&
      !payload?.response?.offeredQuotes[0]?.hasBlockingUwIssues
    ) {
      return adapter.addOne(
        {
          ...payload.response.offeredQuotes[0].premium,
          productType: payload.productType,
        },
        state
      );
    } else {
      return { ...state };
    }
  })
);

export function reducer(
  state: PremiumState | undefined,
  action: Action
): PremiumState {
  return PremiumReducer(state, action);
}
