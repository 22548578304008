import {
  getPageRepresentationByProductId,
  VehiclesPageRepresentation,
  MsaPageRepresentation,
  RVPageRepresentation,
  BoatPageRepresentation,
} from '@core/constants/pages';
import { ProductType, QuoteStatus } from '@core/models/api/dsm-types';
import { VehicleUtils } from '@shared/utils/vehicle.utils';
import { CurrentCarrierEntity } from '../../current-carrier/current-carrier.reducer';
import { DriverVehicleAssignmentEntity } from '../../driver-vehicle-assignment/driver-vehicle-assignment.entity';
import { DriverEntity } from '../../driver/driver.entity';
import { FeatureFlagsModel } from '../../feature-flag/feature-flag.model';
import { ProductModel } from '../../product/product.model';
import { VehicleEntity } from '../../vehicle/vehicle.entity';
import { TaskModel, VehicleCoverageTaskParams } from '../task.model';
import { TaskUtils } from './task.util';
import { VehicleRules } from '../rules/vehicle.rules';
import { TaskValidators } from '../task-validators';
import { PolicyDateOptions } from '@core/interfaces/interfaces';
import { CoverageEntity } from '@entities/coverage/coverage.entity';
import { VehicleTaskValidators } from '@entities/task/validators/vehicle-task-validators';

export class VehicleTaskUtils {
  static generateVehicleTasks(
    vehicles: VehicleEntity[],
    features: FeatureFlagsModel,
    productQuoteStatus: QuoteStatus,
    product: ProductModel,
    coverages: CoverageEntity[]
  ): TaskModel[] {
    let t;
    switch (product.type) {
      case 'PersonalAuto':
        t = VehicleRules.auto;
        break;
      case 'MSA':
        t = VehicleRules.msa;
        break;
      case 'Boat':
        t = VehicleRules.boat;
        break;
      case 'RV':
        t = VehicleRules.rv;
        break;
      default:
        t = VehicleRules.auto;
        break;
    }

    const savedVehicles = vehicles.filter((vehicle) =>
      VehicleUtils.VehicleIsSaved(vehicle.vehicleId)
    );

    let returnRules = TaskValidators.runEntityRulesForAll(
      TaskUtils.createTaskTemplates(t.vehicles, features, productQuoteStatus, [
        product.type,
      ]),
      vehicles,
      getPageRepresentationByProductId(product.type),
      'vehicle',
      product.type
    )
      .concat(
        TaskValidators.runEntityRulesForEach(
          TaskUtils.createTaskTemplates(
            t.vehicle,
            features,
            productQuoteStatus,
            [product.type]
          ),
          savedVehicles,
          getPageRepresentationByProductId(product.type),
          'vehicle',
          product.type,
          'vehicleId'
        )
      )
      .concat(
        TaskValidators.runEntityRulesForSingle(
          TaskUtils.createTaskTemplates(
            t.product,
            features,
            productQuoteStatus,
            [product.type]
          ),
          product,
          getPageRepresentationByProductId(product.type),
          'vehicle',
          product.type
        )
      )
      .concat(
        TaskValidators.autoCustomRules(
          savedVehicles,
          features,
          productQuoteStatus
        )
      )
      .concat(
        TaskValidators.utilityTrailerCustomRules(savedVehicles, coverages)
      );
    const surchargeTask = TaskValidators.multiOwnerSurchargeRule(
      savedVehicles.filter((v) => v.productType === product.type),
      product
    );
    if (surchargeTask) {
      returnRules.push(surchargeTask);
    }
    return returnRules;
  }

  static generatePersonalAutoTasks(
    features: FeatureFlagsModel,
    productQuoteStatus: QuoteStatus,
    product: ProductModel,
    selectedProducts: ProductType[],
    policyDateOptions: PolicyDateOptions
  ): TaskModel[] {
    const t = VehicleRules.auto;
    return TaskValidators.runEntityRulesForSingle(
      TaskUtils.createTaskTemplates(t.product, features, productQuoteStatus, [
        product.type,
      ]),
      product,
      VehiclesPageRepresentation,
      'personalAutoProduct',
      'PersonalAuto'
    ).concat(
      TaskValidators.productCustomRules(
        product,
        selectedProducts,
        policyDateOptions
      )
    );
  }

  static generateMsaTasks(
    features: FeatureFlagsModel,
    productQuoteStatus: QuoteStatus,
    product: ProductModel,
    selectedProducts: ProductType[],
    policyDateOptions: PolicyDateOptions
  ): TaskModel[] {
    const t = VehicleRules.msa;
    return TaskValidators.runEntityRulesForSingle(
      TaskUtils.createTaskTemplates(t.product, features, productQuoteStatus, [
        product.type,
      ]),
      product,
      MsaPageRepresentation,
      'msaProduct',
      'MSA'
    ).concat(
      TaskValidators.productCustomRules(
        product,
        selectedProducts,
        policyDateOptions
      )
    );
  }

  static generateBoatTasks(
    features: FeatureFlagsModel,
    productQuoteStatus: QuoteStatus,
    product: ProductModel,
    selectedProducts: ProductType[],
    policyDateOptions: PolicyDateOptions
  ): TaskModel[] {
    const t = VehicleRules.boat;
    return TaskValidators.runEntityRulesForSingle(
      TaskUtils.createTaskTemplates(t.product, features, productQuoteStatus, [
        product.type,
      ]),
      product,
      BoatPageRepresentation,
      'boatProduct',
      'Boat'
    ).concat(
      TaskValidators.productCustomRules(
        product,
        selectedProducts,
        policyDateOptions
      )
    );
  }

  static generateRVTasks(
    features: FeatureFlagsModel,
    productQuoteStatus: QuoteStatus,
    product: ProductModel,
    selectedProducts: ProductType[],
    policyDateOptions: PolicyDateOptions,
    vehicles: VehicleEntity[],
    coverages: CoverageEntity[]
  ): TaskModel[] {
    const t = VehicleRules.rv;
    return [
      ...TaskValidators.runEntityRulesForSingle(
        TaskUtils.createTaskTemplates(t.product, features, productQuoteStatus, [
          product.type,
        ]),
        product,
        RVPageRepresentation,
        'rvProduct',
        'RV'
      ),
      ...TaskValidators.productCustomRules(
        product,
        selectedProducts,
        policyDateOptions
      ),
      ...TaskValidators.rvCustomRules(vehicles, product, coverages),
    ];
  }

  static generateDVATasks(
    driverVehicleAssignments: DriverVehicleAssignmentEntity[],
    vehicles: VehicleEntity[],
    drivers: DriverEntity[],
    features: FeatureFlagsModel,
    productQuoteStatus: QuoteStatus
  ): TaskModel[] {
    return TaskValidators.dvaCustomRules(
      drivers,
      vehicles,
      driverVehicleAssignments,
      features,
      productQuoteStatus
    );
  }

  static generateAutoCurrentCarrierTasks(
    currentCarrier: CurrentCarrierEntity,
    productQuoteStatus: QuoteStatus,
    features: FeatureFlagsModel
  ): TaskModel[] {
    const c = VehicleRules.currentCarrier;

    return TaskValidators.runEntityRulesForSingle<CurrentCarrierEntity>(
      TaskUtils.createTaskTemplates(c.auto, features, productQuoteStatus, [
        'PersonalAuto',
      ]),
      currentCarrier,
      VehiclesPageRepresentation,
      'currentCarrier',
      'PersonalAuto'
    );
  }

  static generateMsaCurrentCarrierTasks(
    currentCarrier: CurrentCarrierEntity,
    productQuoteStatus: QuoteStatus,
    features: FeatureFlagsModel
  ): TaskModel[] {
    const c = VehicleRules.currentCarrier;

    return TaskValidators.runEntityRulesForSingle<CurrentCarrierEntity>(
      TaskUtils.createTaskTemplates(c.msa, features, productQuoteStatus, [
        'MSA',
      ]),
      currentCarrier,
      MsaPageRepresentation,
      'currentCarrier',
      'MSA'
    );
  }

  static generateAutoCoverageTasks(params: VehicleCoverageTaskParams): TaskModel[] {
    return [
      ...VehicleTaskValidators.getDeclinedCompCollWithAdditionalInterestsTasks(params)
    ]
  }

  static generateMsaCoverageTasks(params: VehicleCoverageTaskParams): TaskModel[] {
    return [];
  }

  static generateRvCoverageTasks(params: VehicleCoverageTaskParams): TaskModel[] {
    return [
      ...VehicleTaskValidators.getRcpeWithFtssTasks(params),
      ...VehicleTaskValidators.getFtssRequiredWhenFullTimerTasks(params),
      ...VehicleTaskValidators.getRvMinimumDeductibleTasks(params),
      ...VehicleTaskValidators.getRvScheduledPersonalEffectsTasks(params)
    ];
  }

  static generateBoatCoverageTasks(params: VehicleCoverageTaskParams): TaskModel[] {
    return [];
  }
}
