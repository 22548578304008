import { ProductType } from '@core/models/api/dsm-types';
import { createSelector } from '@ngrx/store';
import * as fromProducts from '@core/store/entities/product/product.selectors';
import { PolicyIssueRequest } from '@core/models/api/request/policy-issue-request.model';

export const buildPolicyIssueRequest = (productType: ProductType) =>
  createSelector(fromProducts.getSelectedProductsWithoutErrors, (products) => {
    const product = products.find((product) => product.type === productType);
    if (!product || product.quoteStatus === 'Issued') {
      return null;
    }
    return {
      quoteId: product.quoteId,
      productType: product.type,
    } as PolicyIssueRequest;
  });
