import { StandardizedAddress } from '@core/adapters/gis.model';
import { AddressType } from '@core/models/api/dsm-types';
import {
  AddressEntity,
  BasicAddressEntity,
} from '@core/store/entities/address/address.entity';

export class AddressUtils {
  static SingleLineAddressFromStandardized(addr: StandardizedAddress): string {
    return `${addr.street}, ${addr.city}, ${addr.state}, ${addr.zipCode}`;
  }

  static SingleLineAddressFromAddressEntity(addr: BasicAddressEntity): string {
    if (!!addr.addressLine1 && !!addr.city && addr.state && addr.postalCode) {
      return `${addr.addressLine1}, ${addr.city}, ${addr.state}, ${addr.postalCode}`;
    } else {
      return '';
    }
  }

  static AddressEntityFromStandardized(
    input: StandardizedAddress,
    addressLine2: string,
    addressType: AddressType
  ): AddressEntity {
    return {
      addressType,
      addressId: '0',
      street: AddressUtils.SingleLineAddressFromStandardized(input),
      addressLine1: input.street,
      addressLine2,
      city: input.city,
      state: input.state,
      postalCode: input.zipCode,
      country: 'USA', // GIS does not report country
    };
  }

  static AddStreetValueToAddress(
    addressEntity: BasicAddressEntity | null | undefined
  ): BasicAddressEntity | null | undefined {
    if (!addressEntity) {
      return addressEntity;
    } else {
      return {
        ...addressEntity,
        street: this.SingleLineAddressFromAddressEntity(addressEntity),
      };
    }
  }

  static isSameAddress(
    address1: AddressEntity,
    address2: AddressEntity
  ): boolean {
    return (
      address1.addressType === address2.addressType &&
      address1.addressLine1 === address2.addressLine1 &&
      address1.city === address2.city &&
      address1.state === address2.state &&
      address1.postalCode === address2.postalCode
    );
  }
}
