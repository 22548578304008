<div *ngIf="vm$ | async as vm">
  <div class="modal-header">
    <h4 class="bolt-body-copy-lg" id="scheduledItemFormModalHeader">
      {{ vm.modalTitle }}
    </h4>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="loading$ | async">
      <nwx-passive-spinner label="Updating..."></nwx-passive-spinner>
    </ng-container>
    <div [ngClass]="{ blurred: (loading$ | async) }"></div>
    <ng-container *ngIf="mode === 'add' && vm.wellContent?.itemList?.length">
      <div class="bolt-space-bottom-lg">
        <nwx-pill-list
          [identifier]="scheduledItem?.itemId"
          [content]="vm.wellContent"
          (clickEmitted)="editExisting($event)"
        ></nwx-pill-list>
      </div>
    </ng-container>
    <nwx-scheduled-item-form
      [scheduledItem]="scheduledItem"
      [combinedPersonalEffectsValue]="combinedPersonalEffectsValue$ | async"
      [parentCategory]="parentCategory$ | async"
      [scheduledItemType]="scheduledItemType"
      [allScheduledCategories]="allScheduledCategories$ | async"
      (valueChange)="onValueChange($event)"
      (formReady)="onFormReady('scheduledItemForm', $event)"
      (disableButtons)="enableOrDisableButtons($event)"
    ></nwx-scheduled-item-form>
  </div>
  <div class="modal-footer">
    <div class="bolt-container-fluid">
      <div class="bolt-row bolt-justify-content-between btn-row">
        <div class="bolt-col-md-12 bolt-col-lg-auto">
          <div class="bolt-row">
            <div class="bolt-col-md-12 bolt-col-lg-auto">
              <bolt-button [attr.id]="'cancelBtn'" (click)="close()"
                >Cancel</bolt-button
              >
            </div>
            <div
              class="bolt-col-md-12 bolt-col-lg-auto"
              *ngIf="mode === 'edit'"
            >
              <bolt-button [attr.id]="'removeBtn'" (click)="removeItem()"
                >Remove</bolt-button
              >
            </div>
          </div>
        </div>
        <div class="bolt-col-md-12 bolt-col-lg-auto">
          <div class="bolt-row">
            <div class="bolt-col-md-12 bolt-col-lg-auto">
              <bolt-button
                (click)="saveItem()"
                [attr.id]="'saveAndCloseBtn'"
                [attr.disabled]="vm.disableSubmissionBtns ? true : null"
                >Save
              </bolt-button>
            </div>
            <div class="bolt-col-md-12 bolt-col-lg-auto" *ngIf="mode === 'add'">
              <bolt-button
                type="primary"
                class="last"
                (click)="saveItem('keepModalOpen')"
                [attr.id]="'saveAndOpenBtn'"
                [attr.disabled]="vm.disableSubmissionBtns ? true : null"
              >
                Save and add next item
              </bolt-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
