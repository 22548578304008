<div>
  <div class="modal-header">
    <h4 id="disambiguationModalHeader">{{ header }}</h4>
    <h5>{{ subHeader }}</h5>
  </div>
  <div class="modal-body">
    <div>
      <table>
        <tr>
          <th
            id="modelNumberHeader"
            *ngIf="disambiguationOptions[0].value.modelNumber"
          >
            Model number
          </th>
          <th
            id="controlNumberHeader"
            *ngIf="disambiguationOptions[0].value.controlNumber"
          >
            Control number
          </th>
          <th id="lengthHeader">Length</th>
          <th id="weightHeader">Weight</th>
          <th id="marketValueHeader">Est. market value</th>
          <th></th>
        </tr>
        <ng-container
          *ngFor="let option of disambiguationOptions; let i = index"
        >
          <tr>
            <td
              [id]="'modelNumberTd-' + option.value.modelNumber"
              *ngIf="option.value.modelNumber"
            >
              {{ option.value.modelNumber }}
            </td>
            <td
              [id]="'controlNumberTd-' + option.value.controlNumber"
              *ngIf="option.value.controlNumber"
            >
              {{ option.value.controlNumber }}
            </td>

            <td
              [id]="
                'lengthTd-' +
                (option.value.controlNumber || option.value.modelNumber)
              "
              *ngIf="option.value.length"
            >
              {{ option.value.length }}
            </td>
            <td
              [id]="
                'splitLengthTd-' +
                (option.value.controlNumber || option.value.modelNumber)
              "
              *ngIf="option.value.lengthFeet"
            >
              {{ option.value.lengthFeet }} ft
              {{ option.value.lengthInches }} inches
            </td>

            <td
              [id]="
                'weightTd-' +
                (option.value.controlNumber || option.value.modelNumber)
              "
              *ngIf="option.value.weight"
            >
              {{ option.value.weight }}
            </td>
            <td
              [id]="
                'capacityWeightTd-' +
                (option.value.controlNumber || option.value.modelNumber)
              "
              *ngIf="option.value.capacityWeight"
            >
              {{ option.value.capacityWeight }}
            </td>

            <td
              [id]="
                'valueHighTd-' +
                (option.value.controlNumber || option.value.modelNumber)
              "
              *ngIf="option.value.valueHigh"
            >
              {{ option.value.valueHigh | currency }}
            </td>
            <td
              [id]="
                'averageTradeTd-' +
                (option.value.controlNumber || option.value.modelNumber)
              "
              *ngIf="option.value.averageTrade"
            >
              {{ option.value.averageTrade | currency }}
            </td>
            <td>
              <bolt-button
                size="sm"
                (click)="saveSelection(option)"
                [id]="'selectButton' + i"
                >select</bolt-button
              >
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
  <div class="modal-footer"></div>
</div>
