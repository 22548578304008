import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SplashComponent } from './pages/splash/splash.component';
import { SplashRoutingModule } from './splash-routing.module';
import { SplashFormModule } from './components/splash-form/splash-form.module';
import { SplashContainerModule } from './containers/splash-container/splash-container.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    SplashRoutingModule,
    SplashFormModule,
    SplashContainerModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([]),
  ],
  exports: [SplashFormModule],
  declarations: [SplashComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SplashModule {}
