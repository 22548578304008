import { createSelector } from '@ngrx/store';
import { ProducerRequest } from '@core/models/api/request/producer-request.model';
import {
  AccountHolderRequest,
  AccountRequest,
} from '@core/models/api/request/account-request.model';
import * as fromCore from '@core/store/reducers';
import { emptyIfNull } from '@core/helper/form-hacks';
import { AddressEntity } from '../address/address.entity';
import { getAgency } from '@core/store/entities/agency/agency.selector';
import { getPrimaryNamedInsured } from '../member/member.selector';
import { getAddressByType } from '../address/address.selector';

export const getAccountState = createSelector(
  fromCore.getCoreState,
  (state: fromCore.CoreState) => state.account
);

export const getAccountLoaded = createSelector(
  getAccountState,
  (state): boolean => state.loaded
);

export const getAccountLoading = createSelector(
  getAccountState,
  (state): boolean => state.loading
);

export const getAccountId = createSelector(
  getAccountState,
  (state) => state.accountId
);

export const getAccountLinked = createSelector(
  getAccountState,
  (state) => state.isLinked
);

export const buildCreateAccountRequest = createSelector(
  getPrimaryNamedInsured,
  getAddressByType('Default'),
  getAgency,
  (pni, address, agency) => {
    const accountHolderRequest: AccountHolderRequest = {
      person: {
        ...pni?.person,
        // This is a bug with DSM that they know about. We can't send empty string as middle name
        middleName: pni?.person?.middleName || null,
        suffix: pni?.person?.suffix || null,
      },
      address: address as AddressEntity,
      emailAddress: pni?.emailAddress,
      homeNumber: pni?.homeNumber,
    };

    if (pni?.person?.gender === 'X') {
      delete accountHolderRequest.person?.gender;
      accountHolderRequest.nonSpecifiedGender = true;
    } else if (accountHolderRequest.nonSpecifiedGender) {
      accountHolderRequest.nonSpecifiedGender = false;
    }
    // Need to update
    const producerRequest: ProducerRequest = {
      producerCode: emptyIfNull(agency.producerCode),
    };

    return {
      accountHolder: accountHolderRequest,
      producer: producerRequest,
    } as AccountRequest;
  }
);
