import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Type,
  QueryList,
  ViewChildren,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import {
  CoverageDisplayProduct,
  HubCoveragesSelectedCardModel,
} from '@core/models/views/coverage.model';
import { CoverageEntity } from '@core/store/entities/coverage/coverage.entity';
import { CoveredLocationEntity } from '@core/store/entities/covered-location/covered-location.reducer';
import { PremiumEntity } from '@core/store/entities/premium/premium.entity';
import { ProductModel } from '@core/store/entities/product/product.model';
import {
  VehicleEnrollment,
  MobileEnrollment,
} from '@core/store/entities/telematics/telematics.model';
import { VehicleEntity } from '@core/store/entities/vehicle/vehicle.entity';
import { HubCoveragesSelectedCardComponent } from '@shared/components/card-list/hub-coverages-selected-card/hub-coverages-selected-card.component';
import { ComponentChanges, GeneralUtils } from '@shared/utils/general.utils';
import { Nullable } from '@shared/utils/type.utils';
import { Dictionary } from '@ngrx/entity';
import { Subject } from 'rxjs';
import { CondoCoverageDisplayComponent } from '../condo-coverage-display/condo-coverage-display.component';
import { AutoCoverageDisplayComponent } from '../coverage-display-form/auto-coverage-display.component';
import { PowersportsCoverageDisplayComponent } from '../powersports-coverage-display/powersports-coverage-display.component';
import { UmbrellaCoverageDisplayComponent } from '../umbrella-coverage-display/umbrella-coverage-display.component';
import { HomeownerCoverageDisplayComponent } from '../homeowner-coverage-display/homeowner-coverage-display.component';
import { RenterCoverageDisplayComponent } from '../renter-coverage-display/renter-coverage-display.component';

@Component({
  selector: 'nwx-hub-coverages-card-list',
  templateUrl: './hub-coverages-card-list.component.html',
  styleUrls: ['./hub-coverages-card-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HubCoveragesCardListComponent implements OnChanges, OnDestroy {
  @Input() products!: ProductModel[];
  @Input() vehicles!: VehicleEntity[];
  @Input() premiums!: PremiumEntity[];
  @Input() coverages!: CoverageEntity[];
  @Input() enrollment!: Nullable<VehicleEnrollment | MobileEnrollment>;
  @Input() coveredLocations!: Dictionary<CoveredLocationEntity>;
  @Input() maxSizeMini!: Nullable<boolean>;
  coverageDisplayProducts: CoverageDisplayProduct[] = [];

  @ViewChildren(HubCoveragesSelectedCardComponent)
  cards!: QueryList<HubCoveragesSelectedCardComponent>;

  @Output() editTelematicsClicked = new EventEmitter();

  editTelematics = new Subject().subscribe(() =>
    this.editTelematicsClicked.emit()
  );

  constructor() {}

  ngOnChanges(changes: ComponentChanges<HubCoveragesCardListComponent>): void {
    if (changes.products) {
      this.addOrUpdateCoverageDisplayProducts();
    }
    if (
      changes.vehicles ||
      changes.premiums ||
      changes.coverages ||
      changes.enrollment ||
      changes.editTelematics ||
      changes.maxSizeMini
    ) {
      this.updateCardInputsByProductId('PersonalAuto');
    }
    if (
      changes.coveredLocations ||
      changes.vehicles ||
      changes.premiums ||
      changes.coverages ||
      changes.enrollment ||
      changes.maxSizeMini
    ) {
      this.updateCardInputsByProductId('MSA');
    }
    if (
      changes.vehicles ||
      changes.premiums ||
      changes.coverages ||
      changes.enrollment ||
      changes.maxSizeMini
    ) {
      this.updateCardInputsByProductId('Boat');
    }
    if (
      changes.vehicles ||
      changes.premiums ||
      changes.coverages ||
      changes.enrollment ||
      changes.maxSizeMini
    ) {
      this.updateCardInputsByProductId('RV');
    }
    if (
      changes.coveredLocations ||
      changes.premiums ||
      changes.coverages ||
      changes.maxSizeMini
    ) {
      this.updateCardInputsByProductId('Homeowner');
    }
    if (
      changes.coveredLocations ||
      changes.premiums ||
      changes.coverages ||
      changes.maxSizeMini
    ) {
      this.updateCardInputsByProductId('Tenant');
    }
    if (
      changes.coveredLocations ||
      changes.premiums ||
      changes.coverages ||
      changes.maxSizeMini
    ) {
      this.updateCardInputsByProductId('Condominium');
    }
    if (this.cards) {
      for (const card of this.cards) {
        card.ngOnChanges({ model: true } as unknown as SimpleChanges);
      }
    }
  }

  private addOrUpdateCoverageDisplayProducts(): void {
    if (this.products) {
      for (const product of this.products) {
        const cdp = this.coverageDisplayProducts.find(
          (p) => p.productId === product.type
        );
        if (cdp) {
          this.updateSelectedCardModelWithProduct(cdp.model, product);
        } else {
          const componentType = this.componentTypeForProductId(product.type);
          if (componentType) {
            this.coverageDisplayProducts.push({
              productId: product.type,
              componentType,
              model: this.selectedCardModelForProduct(product),
            });
          }
        }
      }
      this.coverageDisplayProducts = this.coverageDisplayProducts.filter(
        (cdp) => this.products.find((product) => product.type === cdp.productId)
      );
      this.coverageDisplayProducts = GeneralUtils.sortCoverageDisplayProducts(
        this.coverageDisplayProducts
      );
    }
  }

  private selectedCardModelForProduct(
    product: ProductModel
  ): HubCoveragesSelectedCardModel {
    const model = {
      id: product.type,
      headline: `${product.name} policy`,
      unselectOption: false,
      hasError: product.hasError || false,
    } as HubCoveragesSelectedCardModel<unknown>;
    this.updateSelectedCardModelWithProduct(model, product);
    return model;
  }

  private updateSelectedCardModelWithProduct(
    model: HubCoveragesSelectedCardModel,
    product: ProductModel
  ): void {
    if (product.quoteId) {
      model.description = `Submission #${product.quoteId}`;
    } else {
      model.description = '';
    }
    this.updateCardInputs(model, product);
  }

  private updateCardInputsByProductId(productType: ProductType): void {
    const cdp = this.coverageDisplayProducts?.find(
      (p) => p.productId === productType
    );
    if (cdp) {
      const product = this.products?.find(
        (product) => product.type === productType
      );
      if (product) {
        cdp.model = Object.assign({}, cdp.model);
        this.updateCardInputs(cdp.model, product);
      }
    }
  }

  private componentTypeForProductId(
    productType: ProductType
  ): Nullable<Type<{}>> {
    switch (productType) {
      case 'PersonalAuto':
        return AutoCoverageDisplayComponent;
      case 'MSA':
      case 'Boat':
      case 'RV':
        return PowersportsCoverageDisplayComponent;
      case 'Homeowner':
        return HomeownerCoverageDisplayComponent;
      case 'Tenant':
        return RenterCoverageDisplayComponent;
      case 'Condominium':
        return CondoCoverageDisplayComponent;
      case 'PersonalUmbrella':
        return UmbrellaCoverageDisplayComponent;
    }
    return null;
  }

  private updateCardInputs(
    model: HubCoveragesSelectedCardModel,
    product: ProductModel
  ): void {
    switch (product.type) {
      case 'PersonalAuto':
        model.formComponentInputs = {
          vehicles: this.vehicles.filter(
            (vehicle) => vehicle.productType === 'PersonalAuto'
          ),
          premiums: this.premiums,
          coverages: this.coverages,
          enrollment: this.enrollment,
          editTelematics: this.editTelematics,
          product,
          maxSizeMini: this.maxSizeMini,
        };
        break;

      case 'MSA':
        model.formComponentInputs = {
          vehicles: this.vehicles.filter(
            (vehicle) => vehicle.productType === 'MSA'
          ),
          premiums: this.premiums,
          coverages: this.coverages,
          product,
          maxSizeMini: this.maxSizeMini,
        };
        break;

      case 'RV':
        model.formComponentInputs = {
          vehicles: this.vehicles.filter(
            (vehicle) => vehicle.productType === 'RV'
          ),
          premiums: this.premiums,
          coverages: this.coverages,
          product,
          maxSizeMini: this.maxSizeMini,
        };
        break;
      case 'Boat':
        model.formComponentInputs = {
          vehicles: this.vehicles.filter(
            (vehicle) => vehicle.productType === 'Boat'
          ),
          premiums: this.premiums,
          coverages: this.coverages,
          product,
          maxSizeMini: this.maxSizeMini,
        };
        break;
      case 'Homeowner':
        model.formComponentInputs = {
          coveredLocation: this.coveredLocations['Homeowner'],
          premiums: this.premiums,
          coverages: this.coverages,
          product,
          maxSizeMini: this.maxSizeMini,
        };
        break;

      case 'Tenant':
        model.formComponentInputs = {
          coveredLocation: this.coveredLocations['Tenant'],
          premiums: this.premiums,
          coverages: this.coverages,
          product,
          maxSizeMini: this.maxSizeMini,
        };
        break;

      case 'Condominium':
        model.formComponentInputs = {
          coveredLocation: this.coveredLocations['Condominium'],
          premiums: this.premiums,
          coverages: this.coverages,
          product,
          maxSizeMini: this.maxSizeMini,
        };
        break;

      case 'PersonalUmbrella':
        model.formComponentInputs = {
          premiums: this.premiums,
          coverages: this.coverages,
          product,
          maxSizeMini: this.maxSizeMini,
        };
        break;
      case 'TermLife':
        break;
    }
  }

  ngOnDestroy(): void {
    this.editTelematics.unsubscribe();
  }
}
