import { BillingModel } from '@app/billing-payment/models/billing.model';
import {
  DownPaymentExperienceResponse,
  EligiblePayplansAndPayMethods,
} from '@core/models/api/response/down-payment-experience-response.model';
import { QuoteActions, SessionActions } from '@core/store/actions';
import { BillingPlansAction } from '@forms-store/store/actions';
import { Action, createReducer, on } from '@ngrx/store';
import { ErrorModel } from '../error/error.model';
import {
  adjustDownPaymentCallCount,
  clearDownPayment,
  downPaymentNotNeeded,
  loadDownPayment,
  loadDownPaymentFail,
  loadDownPaymentSuccess,
  setPaymentMethod,
  unsetDownPaymentFailed,
} from './billing.action';

export interface BillingState {
  billing: BillingModel;
  downPaymentLoading: number;
  downPaymentLoaded: boolean;
  downPaymentFailed: boolean;
  downPayments: DownPaymentExperienceResponse[];
  paymentMethod?: EligiblePayplansAndPayMethods;
  error: ErrorModel;
}

const initialState: BillingState = {
  billing: {} as BillingModel,
  downPaymentLoading: 0,
  downPaymentLoaded: false,
  downPaymentFailed: false,
  downPayments: [],
  error: {},
};

const billingReducer = createReducer<BillingState>(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(loadDownPayment, (state, {}) => ({
    ...state,
    downPaymentLoaded: false,
    downPaymentFailed: false,
    downPayments: [],
  })),
  on(loadDownPaymentSuccess, (state, { payload }) => ({
    ...state,
    downPayments: [...state.downPayments, payload],
    downPaymentLoaded: true,
  })),
  on(loadDownPaymentFail, (state, { error }) => ({
    ...state,
    downPaymentLoaded: false,
    downPaymentFailed: true,
    error,
  })),
  on(clearDownPayment, (state) => ({
    ...state,
    downPayments: [],
    downPaymentLoaded: false,
    downPaymentFailed: false,
  })),
  on(setPaymentMethod, (state, { payload }) => ({
    ...state,
    paymentMethod: payload,
  })),
  on(downPaymentNotNeeded, (state, {}) => ({
    ...state,
    downPayment: {
      eligiblePayplansAndPayMethods: [],
      policyLevel: [],
    },
    downPaymentLoaded: true,
  })),
  on(adjustDownPaymentCallCount, (state, { d }) => {
    return { ...state, downPaymentLoading: state.downPaymentLoading + d };
  }),
  on(unsetDownPaymentFailed, (state) => ({
    ...state,
    downPaymentFailed: false,
  })),
  on(QuoteActions.rateQuote, (state) => ({
    ...state,
    downPayments: [],
    downPaymentLoaded: false,
    downPaymentFailed: false,
  })),
  on(BillingPlansAction.updateBillingPlansForm, (state) => ({
    ...state,
    downPayments: [],
    downPaymentLoaded: false,
    downPaymentFailed: false,
  }))
);

export function reducer(
  state: BillingState | undefined,
  action: Action
): BillingState {
  return billingReducer(state, action);
}

export const getDownPaymentLoaded = (state: BillingState) =>
  state.downPaymentLoaded;
export const getDownPaymentLoading = (state: BillingState) =>
  !!state.downPaymentLoading;
export const getDownPaymentFailed = (state: BillingState) =>
  state.downPaymentFailed;
export const getBillingState = (state: BillingState) => state.billing;
