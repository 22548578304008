import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { CoverageInformationalMessage } from '../coverage/coverage.entity';
import { CoverageMessageActions, SessionActions } from '@core/store/actions';

export interface CoverageMessageState
  extends EntityState<CoverageInformationalMessage> {
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<CoverageInformationalMessage> =
  createEntityAdapter<CoverageInformationalMessage>({
    selectId: (message) => message.productId,
  });

const CoverageMessageReducer = createReducer(
  adapter.getInitialState({
    loaded: false,
    loading: false,
  }),
  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({
      loaded: false,
      loading: false,
    })
  ),
  on(
    CoverageMessageActions.addCoverageInformationalMessage,
    (state, { payload }) => adapter.addOne(payload, { ...state, loaded: true })
  ),
  on(
    CoverageMessageActions.removeProductCoverageInformationalMessage,
    (state, { id }) =>
      adapter.removeOne(id, {
        ...state,
      })
  ),
  on(CoverageMessageActions.removeAllCoverageInformationalMessage, (state) =>
    adapter.removeAll(state)
  )
);

export function reducer(
  state: CoverageMessageState | undefined,
  action: Action
): CoverageMessageState {
  return CoverageMessageReducer(state, action);
}
