import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filterOutUndefined } from '@shared/rxjs/filter-out-null.operator';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ProductType } from '../models/api/dsm-types';
import {
  QuoteRetrieveApiResponse,
  QuoteRetrieveResponse,
  sanitizeQuoteRetrieveResponse,
} from '../models/api/response/retrieve-response.model';
import { LogService } from '../services/log.service';
import { AppConfigService } from './../services/app-config.service';
import {
  AccountsSearchResult,
  QuoteSearchResults,
  sanitizeAccountSearchResult,
  sanitizeQuoteSearchResult,
} from './../services/quote-search.service';
import { StringUtils } from '@shared/utils/string.utils';
import { getAccessToken } from './../store/entities/session/session.selector';
import { AccountHolder } from '../services/quote-search.service';
import { ObjectValidator } from '@core/helper/object-validator';

export interface HydrateQuoteResponse {
  quoteId: string;
  postalCode: string;
  lastName: string;
  productType: ProductType;
  informationalMessages: InformationalMessages[];
}

export function sanitizeHydrateQuoteResponse(
  input: unknown
): HydrateQuoteResponse {
  return ObjectValidator.forceSchema<HydrateQuoteResponse>(
    input,
    {
      quoteId: 'string',
      postalCode: 'string',
      lastName: 'string',
      productType: 'string',
      informationalMessages: [sanitizeInformationalMessage],
    },
    []
  );
}

export interface InformationalMessages {
  errors: Message[];
  warnings: Message[];
}

export function sanitizeInformationalMessage(
  input: unknown
): InformationalMessages {
  return ObjectValidator.forceSchema<InformationalMessages>(input, {
    errors: [sanitizeMessage],
    warnings: [sanitizeMessage],
  });
}

export function sanitizeInformationalMessages(
  input: unknown
): InformationalMessages[] {
  return ObjectValidator.sanitizeArray(input, sanitizeInformationalMessage);
}

export interface Message {
  message: string;
}

export function sanitizeMessage(input: unknown): Message {
  return ObjectValidator.forceSchema<Message>(input, { message: 'string' });
}

interface QuoteSearchRequest {
  firstName: string | null;
  lastName: string | null;
  postalCode: string | null;
  quoteId?: string;
  accountNumber?: string;
  scopeByUserId?: boolean;
}

export interface AccountsSearchRequest {
  accountNumber?: string;
  phoneNumber?: string;
  lastName?: string;
  firstName?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  producerCode?: string;
}

@Injectable({
  providedIn: 'root',
})
export class QuoteRetrieveAdapter {
  accessToken = '';
  targetEnv = '';

  constructor(
    private httpClient: HttpClient,
    private store: Store,
    private appConfig: AppConfigService,

    private log: LogService
  ) {
    this.store
      .select(getAccessToken)
      .pipe(filterOutUndefined())
      .subscribe((token) => (this.accessToken = token));
  }

  genNwHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.accessToken}`);
    headers = headers.set('client_id', this.appConfig.config.apiKey);
    headers = headers.set('X-NW-Message-ID', StringUtils.generateUuid());
    if (this.appConfig.config.targetEnv) {
      headers = headers.set('X-NW-Target-Env', this.appConfig.config.targetEnv);
    }

    return headers;
  }

  searchByFirstLastName(
    firstName: string,
    lastName: string
  ): Observable<QuoteSearchResults> {
    return this.search({
      firstName,
      lastName,
      postalCode: null,
    });
  }

  searchByLastNameZip(
    lastName: string,
    zip: string
  ): Observable<QuoteSearchResults> {
    return this.search({
      firstName: null,
      lastName,
      postalCode: zip,
    });
  }

  searchByQuoteId(quoteId: string): Observable<QuoteSearchResults> {
    return this.search(
      {
        firstName: null,
        lastName: null,
        postalCode: null,
        quoteId,
      },
      (results) => {
        const foundQuote = results.quotes.find((q) => q.quoteId === quoteId);
        const accountId = foundQuote?.accountNumber;
        if (!accountId) {
          return {
            quotes: [],
          };
        }
        return {
          ...results,
          quotes: [
            ...results.quotes.filter((q) => q.accountNumber === accountId),
          ],
        };
      }
    );
  }

  searchByAccountNumber(
    accountNumber?: string
  ): Observable<QuoteSearchResults> {
    return this.search({
      firstName: null,
      lastName: null,
      postalCode: null,
      accountNumber,
    });
  }

  // quoteAccountManagement

  public searchAccounts(
    body: AccountsSearchRequest
  ): Observable<AccountsSearchResult> {
    const requestUrl = `${this.appConfig.config.quoteAccountManagement}/accounts/search`;
    const correlationId = this.log.logApiRequest(
      'POST',
      requestUrl,
      'search-accounts',
      body
    );
    return this.httpClient
      .post<AccountsSearchResult>(requestUrl, body, {
        headers: this.genNwHeaders(),
      })
      .pipe(
        tap((response) => {
          const logBody = response.accounts?.map((account) => {
            return {
              accountHolder: account.accountHolder,
              accountNumber: account.accountId,
              producer: account.producer,
            };
          });
          this.log.logApiResponse(correlationId, 200, logBody);
        }),
        map((response) => sanitizeAccountSearchResult(response)),
        catchError((error) => this.log.logApiError(correlationId, error))
      );
  }

  // Future goal state is to default scopeByUserId to true and show results as My Submissions
  private search(
    { scopeByUserId = false, ...body }: QuoteSearchRequest,
    responseFilter?: (results: QuoteSearchResults) => QuoteSearchResults
  ): Observable<QuoteSearchResults> {
    const requestUrl = `${this.appConfig.config.pcEdgeSearchQuotesUrl}?scopeByUserId=${scopeByUserId}`;
    const correlationId = this.log.logApiRequest(
      'POST',
      requestUrl,
      'search-quote',
      body
    );
    return this.httpClient
      .post<QuoteSearchResults>(requestUrl, body, {
        headers: this.genNwHeaders(),
      })
      .pipe(
        map((response) => responseFilter?.(response) || response),
        tap((response) => {
          const logBody = response.quotes?.map((quote) => {
            return {
              quoteId: quote.quoteId,
              accountNumber: quote.accountNumber,
              lineOfBusiness: quote.lineOfBusiness,
              producerCode: quote.producerCode,
              creationTime: quote.creationTime,
              quoteSource: quote.quoteSource,
            };
          });
          this.log.logApiResponse(correlationId, 200, logBody);
        }),
        map((response) => {
          return {
            quotes: ObjectValidator.sanitizeArray(response.quotes,sanitizeQuoteSearchResult)
          };
        }),
        catchError((error) => this.log.logApiError(correlationId, error))
      );
  }

  retrieve(
    quoteId: string,
    productType: ProductType
  ): Observable<QuoteRetrieveResponse> {
    switch (productType) {
      case 'PersonalAuto':
        return this._retrieve(
          productType,
          `${this.appConfig.config.pcEdgeAutoUrl}/quotes/${quoteId}`
        );
      case 'MSA':
        return this._retrieve(
          productType,
          `${this.appConfig.config.pcEdgePowerSportsUrl}/quotes/${quoteId}`
        );
      case 'Homeowner':
        return this._retrieve(
          productType,
          `${this.appConfig.config.pcEdgeHomeownersUrl}/quotes/${quoteId}`
        );
      case 'Tenant':
        return this._retrieve(
          productType,
          `${this.appConfig.config.pcEdgeRentersUrl}/quotes/${quoteId}`
        );
      case 'Condominium':
        return this._retrieve(
          productType,
          `${this.appConfig.config.pcEdgeCondoUrl}/quotes/${quoteId}`
        );
      case 'PersonalUmbrella':
        return this._retrieve(
          productType,
          `${this.appConfig.config.pcEdgeUmbrellaUrl}/quotes/${quoteId}`
        );
      case 'DwellingFire':
        return of({
          productType,
          response: {
            quoteId,
          },
        } as unknown as QuoteRetrieveResponse);
      // return this._retrieve(
      //   productType,
      //   `${this.appConfig.config.pcEdgeDwellingFireUrl}/quotes/${quoteId}`
      // );
      case 'RV':
        return this._retrieve(
          productType,
          `${this.appConfig.config.pcEdgeRVUrl}/quotes/${quoteId}`
        );
      case 'Boat':
        return this._retrieve(
          productType,
          `${this.appConfig.config.pcEdgeBoatUrl}/quotes/${quoteId}`
        );
      default:
        throw new Error(`Attempt to retrieve unknown product "${productType}"`);
    }
  }

  private _retrieve(
    productType: ProductType,
    url: string
  ): Observable<QuoteRetrieveResponse> {
    const correlationId = this.log.logApiRequest('GET', url, 'retrieve-quote');
    return this.httpClient
      .get<QuoteRetrieveApiResponse>(url, {
        headers: this.genNwHeaders(),
        params: {
          productCode: productType,
        },
      })
      .pipe(
        tap((response) => this.failOnWithdrawnStatus(response, productType)),
        map((response) => ({ productType, response } as QuoteRetrieveResponse)),
        tap((response) => {
          this.log.logApiResponse(correlationId, 200, response.response);
        }),
        map((response) => sanitizeQuoteRetrieveResponse(response)),
        catchError((err) => this.log.logApiError(correlationId, err))
      );
  }

  private failOnWithdrawnStatus(
    response: QuoteRetrieveApiResponse,
    productType: ProductType
  ): void {
    if (response.quoteStatus === 'Withdrawn') {
      throw new Error(
        `${productType} quote ${response.quoteId} has been withdrawn`
      );
    }
  }

  hydrateQuote(
    quoteId: string,
    producerCode: string
  ): Observable<HydrateQuoteResponse[]> {
    const requestBody = {
      producerCode,
      comparativeQuoteId: quoteId,
    };
    const url = `${this.appConfig.config.pcEdgeCompraterRetrieveUrl}`;
    const correlationId = this.log.logApiRequest(
      'GET',
      url,
      'hydrate-quote',
      requestBody
    );

    return this.httpClient
      .post<HydrateQuoteResponse[]>(url, requestBody, {
        headers: this.genNwHeaders(),
      })
      .pipe(
        tap((response) =>
          this.log.logApiResponse(correlationId, 200, response)
        ),
        map((response)=>ObjectValidator.sanitizeArray(response,sanitizeHydrateQuoteResponse)),
        catchError((err) => this.log.logApiError(correlationId, err))
      );
  }
}
