import { Injectable } from "@angular/core";
import { CompraterRoutingAdapter } from "@core/adapters/comprater-routing.adapter";
import { CompraterRoutingActions } from "@core/store/actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompraterRoutingEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private compraterRoutingAdapter: CompraterRoutingAdapter
  ) {}

  fetchCompraterRouting$ = createEffect(() => this.actions$.pipe(
    ofType(CompraterRoutingActions.fetchCompraterRouting),
    switchMap(({ quoteId }) => {
      return this.compraterRoutingAdapter.fetchCompraterRouting(quoteId).pipe(
        // Response we pass along must have the requested quote in it, otherwise force an error.
        tap((response) => {
          if (!response.find((r) => r.comparativeQuoteId === quoteId)) {
            throw new Error(`invalid comprater routing response`);
          }
        }),
        map((response) => CompraterRoutingActions.fetchCompraterRoutingSuccess({ response })),
        catchError((error) => of(CompraterRoutingActions.fetchCompraterRoutingError({ quoteId })))
      );
    })
  ));
}
