<div *ngIf="vm$ | async as vm">
  <div class="modal-header">
    <h4 id="outboardMotorModalHeader">
      {{ vm.modalTitle }}
      Outboard motor information
    </h4>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="mode === 'add' && vm.wellContent?.itemList?.length">
      <div class="bolt-space-bottom-lg">
        <nwx-pill-list
          [identifier]=""
          [content]="vm.wellContent"
          (clickEmitted)="editExisting($event)"
        ></nwx-pill-list>
      </div>
    </ng-container>
    <nwx-outboard-motor-form
      [motor]="vm.localMotor"
      [allMotors]="vm.listOfMotors"
      [vehicleId]="vehicleId"
      [formSaved$]="formSaved$"
      (valueChange)="onValueChange($event)"
      (formReady)="onFormReady('outboard-motor-form', $event)"
      (callInFlight)="onCallInFlight($event)"
    ></nwx-outboard-motor-form>
  </div>
  <div class="modal-footer">
    <div class="bolt-container-fluid">
      <div class="bolt-row bolt-justify-content-between btn-row">
        <div class="bolt-col-md-12 bolt-col-lg-auto">
          <div class="bolt-row">
            <div class="bolt-col-md-12 bolt-col-lg-auto">
              <bolt-button [attr.id]="'cancelBtn'" (click)="close()">
                Cancel
              </bolt-button>
            </div>
            <div
              class="bolt-col-md-12 bolt-col-lg-auto"
              *ngIf="mode === 'edit'"
            >
              <bolt-button
                [attr.id]="'removeBtn'"
                (click)="removeItem(vm.localMotor, vm.listOfMotors)"
                >Remove
              </bolt-button>
            </div>
          </div>
        </div>
        <div class="bolt-col-md-12 bolt-col-lg-auto">
          <div class="bolt-row" *ngIf="showSpinner ; else submitButtons">
            <div class="spinner-box">
              <nwx-passive-spinner
                class="flat transparent"
                label="Loading"
              ></nwx-passive-spinner>
            </div>
          </div>
          <ng-template #submitButtons>
            <div class="bolt-row">
              <div class="bolt-col-md-12 bolt-col-lg-auto">
                <bolt-button
                  (click)="saveItem(vm.localMotor, vm.listOfMotors)"
                  [attr.id]="'saveAndCloseBtn'"
                  [attr.disabled]="vm.disableSubmissionBtns ? true : null"
                  [attr.type]="
                    mode === 'edit'
                      ? 'primary'
                      : vm.motorLimitReached
                      ? 'primary'
                      : 'secondary'
                  "
                  >Save
                </bolt-button>
              </div>
              <div
                class="bolt-col-md-12 bolt-col-lg-auto"
                *ngIf="mode === 'add' && !vm.motorLimitReached"
              >
                <bolt-button
                  type="primary"
                  class="last"
                  (click)="
                    saveItem(vm.localMotor, vm.listOfMotors, 'keepModalOpen')
                  "
                  [attr.id]="'saveAndOpenBtn'"
                  [attr.disabled]="vm.disableSubmissionBtns ? true : null"
                >
                  Save and add next item
                </bolt-button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
