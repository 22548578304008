<div
  class="bolt-container-fluid"
  [formGroup]="form"
  [nwxExposeFormErrors]="form"
  formName="current-carrier"
  *ngIf="vm$ | async as vm"
>
  <div class="bolt-row acc-hit mb-3" *ngIf="options?.showAccHit">
    <div class="bolt-col-auto">
      <bolt-icon
        name="checkmark-circle-filled"
        color="theme-success"
      ></bolt-icon>
    </div>
    <div class="bolt-col">
      <div class="bolt-row">
        <div class="bolt-col-12 bolt-col-sm-6">
          <strong>Current carrier:</strong> {{ vm.carrierName }}
        </div>
        <div class="bolt-col-12 bolt-col-sm-6">
          <strong>Limits:</strong> {{ vm.carrierLimits }}
        </div>
      </div>
    </div>
  </div>

  <div
    class="bolt-row acc-no-hit"
    *ngIf="!vm.isAutoRetrieve && options?.showAccNoHitWarning"
  >
    <div class="bolt-col aligned-row one-line-not-found">
      <bolt-icon name="info-circle-filled" color="theme-info" class="not-found-icon"></bolt-icon>
      <strong>Current carrier:</strong>
      <span class="fake-leading-space">Not found.</span>
    </div>
  </div>

  <div
    class="bolt-row"
    *ngIf="vm.isAutoRetrieve || vm.displayCurrentCarrierWarning"
  >
    <div class="aligned-row bolt-container-fluid pl-0">
      <div id="prior-carrier-reminder" class="card">
        Prior Insurance information is populated from Current Carrier report
        when available. Before entering prior carrier information, ensure a
        valid declaration page confirming continuous coverage is provided by
        applicant prior to binding policy.
      </div>
    </div>
  </div>

  <hr
    *ngIf="
      (options?.showAccHit ||
        options?.showAccNoHitWarning ||
        vm.displayCurrentCarrierWarning) &&
      options?.allowManualEntry
    "
    class="cc-card-full-width-hr"
  />

  <div class="bolt-row" *ngIf="options?.allowManualEntry">
    <div class="bolt-col-12">
      <div class="bolt-row" *ngIf="vm.showName">
        <div class="bolt-col-lg-7">
          <strong>Current carrier of primary named</strong>
        </div>
        <div class="bolt-col-lg-5">
          <bolt-select
            ngDefaultControl
            formControlName="currentCarrierName"
            name="currentCarrierName"
            [attr.id]="'ccf-current-carrier-name'"
            required
            class="right-no-icon"
            (change)="onNameChange($event)"
            arialabel="Current carrier of primary named"
          >
            <option
              *ngFor="let option of options?.currentCarrierNames"
              [value]="option.key"
            >
              {{ option.value }}
            </option>
          </bolt-select>
        </div>
      </div>
      <div class="bolt-row" *ngIf="vm.showLimits">
        <div class="bolt-col-lg-7">
          <strong>Current bodily injury limits</strong>
        </div>
        <div class="bolt-col-lg-5">
          <bolt-select
            ngDefaultControl
            formControlName="currentBodilyInjuryLimit"
            name="currentBodilyInjuryLimit"
            [attr.id]="'ccf-current-bodily-injury-limit'"
            required
            class="right-no-icon"
            [attr.disabled]="vm.liabilityLimitDisabled ? 'disabled' : null"
            (change)="onLimitChange($event)"
            arialabel="Current bodily injury limit"
          >
            <option
              *ngFor="let option of options?.priorBiLimits"
              [value]="option.key"
            >
              {{ option.value }}
            </option>
          </bolt-select>
        </div>
      </div>
      <div class="bolt-row" *ngIf="vm.showCoverageLapse">
        <div class="bolt-col-lg-7">
          <strong>Lapse in coverage</strong>
        </div>
        <div class="bolt-col-lg-5">
          <bolt-select
            ngDefaultControl
            formControlName="coverageLapse"
            name="coverageLapse"
            [attr.id]="'ccf-lapse-in-coverage'"
            required
            class="right-no-icon"
            [attr.disabled]="vm.coverageLapseDisabled ? 'disabled' : null"
            (change)="onCoverageLapseChange($event)"
            arialabel="Lapse in coverage"
          >
            <option
              *ngFor="let option of options?.lapseInCoverage"
              [value]="option.key"
            >
              {{ option.value }}
            </option>
          </bolt-select>
        </div>
      </div>
      <div class="bolt-row" *ngIf="vm.showReasonForLapse">
        <div class="bolt-col-lg-7">
          <strong>Reason for lapse</strong>
        </div>
        <div class="bolt-col-lg-5">
          <bolt-select
            ngDefaultControl
            formControlName="reasonForCoverageLapse"
            name="reasonForCoverageLapse"
            [attr.id]="'ccf-reason-for-coverage-lapse'"
            class="right-no-icon"
            (change)="onLimitChange($event)"
            arialabel="Reason for lapse"
          >
            <option
              *ngFor="let option of options?.reasonForLapse"
              [value]="option.key"
            >
              {{ option.value }}
            </option>
          </bolt-select>
        </div>
      </div>
    </div>
  </div>
</div>
