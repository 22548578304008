import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  allPages,
  createNavigationStateEntities,
  PageIdentifier,
  SummaryPageRepresentation,
  GoToPolicyCenterRepresentation,
} from '@core/constants/pages';
import { ProductType } from '@core/models/api/dsm-types';
import {
  BillingActions,
  NavigationActions,
  QuoteActions,
  RetrieveActions,
  SessionActions,
  TaskActions,
} from '@core/store/actions';
import { Page, PageSubCategory } from './navigation.action';
import { NavigationBus } from '@core/services/navigation.service';
import { ProductModel } from '../product/product.model';
import { Nullable } from '@shared/utils/type.utils';

export interface NavigationState extends EntityState<Page> {
  currentPage: PageIdentifier;
  targetPage: NavigationBus;
  mobileNavToggled: boolean;
  autofillPage: Nullable<PageIdentifier>;
}

const initialState: NavigationState = {
  ids: allPages.map((page) => page.id),
  entities: createNavigationStateEntities(),
  currentPage: '' as PageIdentifier,
  targetPage: {} as NavigationBus,
  mobileNavToggled: false,
  autofillPage: null,
};

const byIndex = (a: Page, b: Page): number => {
  if (a.index < b.index) {
    return -1;
  } else if (a.index > b.index) {
    return 1;
  } else {
    return 0;
  }
};

export const adapter: EntityAdapter<Page> = createEntityAdapter<Page>({
  selectId: (page) => page.id,
  sortComparer: byIndex,
});

const navigationStateReducerFunction = createReducer<NavigationState>(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(NavigationActions.setCurrentPage, (state, { payload: page }) => {
    return {
      ...state,
      currentPage: page.id,
    };
  }),
  on(NavigationActions.setTargetPage, (state, { payload: page }) => {
    return {
      ...state,
      targetPage: page,
    };
  }),
  on(
    NavigationActions.enableBuildQuotePages,
    (state, { payload: products }) => {
      return adapter.upsertMany(upsertEnabledPages(products, state), state);
    }
  ),
  on(
    NavigationActions.setPageNavigable,
    (state: NavigationState, { payload: pageId, isNavigable }) =>
      adapter.upsertOne(
        setPageAttribute(pageId, state, 'isNavigable', isNavigable),
        state
      )
  ),
  on(NavigationActions.setPageVisited, (state, { page, visited }) =>
    adapter.upsertOne(setPageAttribute(page, state, 'visited', visited), state)
  ),
  on(NavigationActions.enablePage, (state, { payload: page }) =>
    adapter.upsertOne(setPageAttribute(page.id, state, 'enabled', true), state)
  ),
  on(BillingActions.billingComplete, (state, {}) =>
    adapter.upsertMany(
      [
        setPageAttribute(SummaryPageRepresentation.id, state, 'enabled', true),
        setPageAttribute(
          GoToPolicyCenterRepresentation.id,
          state,
          'enabled',
          true
        ),
      ],
      state
    )
  ),
  on(
    QuoteActions.initiateNewBusinessSuccess,
    RetrieveActions.retrieveQuoteSuccess,
    (state, { payload: response }) =>
      adapter.upsertMany(getNavigablePages(response.productType, state), state)
  ),
  on(QuoteActions.updateQuoteSuccess, (state, { payload: productType }) =>
    adapter.upsertMany(
      getNavigablePages(productType as ProductType, state),
      state
    )
  ),
  on(NavigationActions.disableQuotePagesOnIssue, (state, {}) =>
    adapter.upsertMany(setQuotePagesDisabled(state), state)
  ),
  on(
    NavigationActions.setWarning,
    (state: NavigationState, { payload: pageId }) =>
      adapter.upsertOne(
        setPageAttribute(pageId, state, 'hasWarning', true),
        state
      )
  ),
  on(
    NavigationActions.setReviewWarning,
    (state: NavigationState, { payload: pageId }) =>
      adapter.upsertOne(
        setPageAttribute(pageId, state, 'hasReviewWarning', true),
        state
      )
  ),
  on(
    NavigationActions.clearWarning,
    (state: NavigationState, { payload: pageId }) =>
      adapter.upsertOne(
        setPageAttribute(pageId, state, 'hasWarning', false),
        state
      )
  ),
  on(
    NavigationActions.clearReviewWarning,
    (state: NavigationState, { payload: pageId }) =>
      adapter.upsertOne(
        setPageAttribute(pageId, state, 'hasReviewWarning', false),
        state
      )
  ),
  on(
    NavigationActions.setErrors,
    (state: NavigationState, { payload: pageId }) =>
      adapter.upsertOne(
        setPageAttribute(pageId, state, 'hasError', true),
        state
      )
  ),
  on(
    NavigationActions.clearErrors,
    (state: NavigationState, { payload: pageId }) =>
      adapter.upsertOne(
        setPageAttribute(pageId, state, 'hasError', false),
        state
      )
  ),
  on(
    NavigationActions.markComplete,
    (state: NavigationState, { payload: pageId }) =>
      adapter.upsertOne(
        setPageAttribute(pageId, state, 'isComplete', true),
        state
      )
  ),
  on(
    NavigationActions.markIncomplete,
    (state: NavigationState, { payload: pageId }) =>
      adapter.upsertOne(
        setPageAttribute(pageId, state, 'isComplete', false),
        state
      )
  ),
  on(NavigationActions.collapseMobileNav, (state) => {
    return {
      ...state,
      mobileNavToggled: false,
    };
  }),
  on(NavigationActions.openMobileNav, (state) => {
    return {
      ...state,
      mobileNavToggled: true,
    };
  }),
  on(TaskActions.clearReviewTasksForPage, (state, action) => {
    const newEntities = { ...state.entities };
    const entity = newEntities[action.payload];
    if (entity?.hasReviewWarning) {
      newEntities[action.payload] = {
        ...entity,
        hasReviewWarning: false,
      };
      return { ...state, entities: newEntities };
    }
    return state;
  }),
  on(NavigationActions.setAutofillTarget, (state, payload) => {
    return {
      ...state,
      autofillPage: payload.payload.id,
    };
  })
);

export function navigationStateReducer(
  state: NavigationState | undefined,
  action: Action
): NavigationState {
  return navigationStateReducerFunction(state, action);
}

function upsertEnabledPages(
  products: ProductModel[],
  state: NavigationState
): Page[] {
  // update the existing entities based on the selected products
  // if an entity has a selected product in its array of 'associate products',
  // then set enabled: true
  const updatedPages = Object.values(state.entities) as Page[];

  return updatedPages.map((updatedPage) => {
    const modifiedUpdatedPage = { ...updatedPage };
    if (modifiedUpdatedPage.subcategory === PageSubCategory.BUILD_QUOTE) {
      modifiedUpdatedPage.enabled = modifiedUpdatedPage.associatedProducts
        ? modifiedUpdatedPage.associatedProducts?.some(
            (associatedProduct) =>
              !!products.find((product) => product.type === associatedProduct)
          )
        : true;
      modifiedUpdatedPage.isNavigable = !!products.find((product) =>
        pageIsNavigableByProduct(product, modifiedUpdatedPage)
      );
    }
    return modifiedUpdatedPage;
  });
}

function getNavigablePages(
  product: ProductType,
  state: NavigationState
): Page[] {
  const pages = Object.values({ ...state.entities }) as Page[];

  return pages
    .filter(
      (page) =>
        page.subcategory === PageSubCategory.BUILD_QUOTE &&
        (!page.associatedProducts || page.associatedProducts?.includes(product))
    )
    .map((updatedPage) => ({ ...updatedPage, isNavigable: true } as Page));
}

function setQuotePagesDisabled(state: NavigationState): Page[] {
  const pages = Object.values({ ...state.entities }) as Page[];

  return pages
    .filter(
      (page) =>
        page.id !== SummaryPageRepresentation.id &&
        page.id !== GoToPolicyCenterRepresentation.id
    )
    .map((page) => ({ ...page, enabled: false, isNavigable: false }));
}

function pageIsNavigableByProduct(product: ProductModel, page: Page): boolean {
  const NwxProductIsNavigable =
    product.quoteId && page.associatedProducts?.includes(product.type);

  const NonNwxProduct = !product.isDsmActive;

  return NwxProductIsNavigable || NonNwxProduct;
}

function setPageAttribute(
  pageId: PageIdentifier,
  state: NavigationState,
  attribute: keyof Page,
  value: boolean
): Page {
  const page = state.entities[pageId];
  return { ...page, [attribute]: value } as Page;
}
