import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import {
  LocationExposureAndProductInfo,
  LocationExposureEntity,
} from './location-exposures.entity';
import { ProductsSelectors } from '@core/store/selectors';
import * as fromLocationExposures from '@core/store/entities/exposures/location-exposures/location-exposures.reducer';
import { getAllFeatureFlags } from '@entities/feature-flag/feature-flag.selector';
import { LocationExposureRequest } from '@core/models/api/request/location-exposure-request.model';
import {
  getAllUnderlyingPolicies,
  getUnderlyingPoliciesAndProductInfo,
} from '@entities/underlying-policy/underlying-policy.selector';
import { getUmbrellaOptions } from '@entities/metadata/metadata.selector';
import { Nullable } from '@shared/utils/type.utils';
import { LocationExposureOptionsModel } from '@entities/metadata/models/umbrella-options.model';
import { UmbrellaUtils } from '@shared/utils/umbrella.utils';
import { getSelectedProducts } from '@entities/product/product.selectors';
import { getAddressEntities } from '@entities/address/address.selector';
import { getCoveredLocationEntities } from '@entities/covered-location/covered-location.selector';
import { getLocationExcludedExposuresAndProductInfo } from '../location-excluded-exposures/location-excluded-exposures.selector';
import { Dictionary } from '@ngrx/entity';
import { UmbrellaTileModel } from '@shared/components/umbrella-tile/umbrella-tile.component';
import { GeneralUtils } from '@shared/utils/general.utils';

export const getLocationExposureState = createSelector(
  fromCore.getCoreState,
  (state) => state.locationExposures
);

export const {
  selectAll: getAllLocationExposures,
  selectEntities: getLocationExposures,
} = fromLocationExposures.adapter.getSelectors(getLocationExposureState);

export const buildLocationExposureRequest = (
  locationExposure: LocationExposureEntity,
  correlationId?: string
) =>
  createSelector(
    ProductsSelectors.getProduct('PersonalUmbrella'),
    getAllFeatureFlags,
    (product, featureFlags): LocationExposureRequest => {
      return {
        locationExposureBody: {
          locationExposure: {
            productId: locationExposure?.productId,
            locationExposureId: locationExposure?.locationExposureId,
            underlyingPolicyNumber: locationExposure?.underlyingPolicyNumber,
            policyType: locationExposure?.policyType,
            locationUsage: locationExposure?.locationUsage,
            numberOfUnits: locationExposure?.numberOfUnits,
            locationAddress: locationExposure?.locationAddress,
            addressDescription: locationExposure?.addressDescription,
            dwellingDetails:
              locationExposure.locationUsage === 'prim'
                ? {
                    hasLiabilityLosses:
                      locationExposure?.dwellingDetails?.hasLiabilityLosses,
                    liabilityLossDescription:
                      locationExposure?.dwellingDetails
                        ?.liabilityLossDescription,
                    hasPool: locationExposure?.dwellingDetails?.hasPool,
                    isPoolFenced:
                      locationExposure?.dwellingDetails?.isPoolFenced,
                    hasDivingBoard:
                      locationExposure?.dwellingDetails?.hasDivingBoard,
                    hasTrampoline:
                      locationExposure?.dwellingDetails?.hasTrampoline,
                    hasTrampolineSafetyNet:
                      locationExposure?.dwellingDetails?.hasTrampolineSafetyNet,
                    hasNonDomesticAnimals:
                      locationExposure?.dwellingDetails?.hasNonDomesticAnimals,
                    nonDomesticedAnimalDescription:
                      locationExposure?.dwellingDetails
                        ?.nonDomesticedAnimalDescription,
                    hasNonDomesticAnimalCausedInjury:
                      locationExposure?.dwellingDetails
                        ?.hasNonDomesticAnimalCausedInjury,
                    nonDomesticAnimalInjuryDescription:
                      locationExposure?.dwellingDetails
                        ?.nonDomesticAnimalInjuryDescription,
                    haveDogs: locationExposure?.dwellingDetails?.haveDogs,
                    dogs: locationExposure?.dwellingDetails?.dogs,
                  }
                : null,
          },
        },
        quoteId: product?.quoteId,
        productType: product?.type,
      } as LocationExposureRequest;
    }
  );

export const getLocationExposuresAndProductInfo = createSelector(
  getAllLocationExposures,
  getUnderlyingPoliciesAndProductInfo,
  (locationExposures, underlyingPolicies): LocationExposureAndProductInfo[] => {
    return locationExposures.map((exposure) => {
      return {
        locationExposure: exposure,
        underlyingPolicy: underlyingPolicies.find(
          (up) =>
            up.underlyingPolicy.underlyingPolicyNumber ===
            exposure.underlyingPolicyNumber
        )?.underlyingPolicy,
        product: underlyingPolicies.find(
          (up) =>
            up.underlyingPolicy.underlyingPolicyNumber ===
            exposure.underlyingPolicyNumber
        )?.product,
      };
    }) as LocationExposureAndProductInfo[];
  }
);

export const getLocationExposuresByUnderlyingPolicyNumber = (
  underlyingPolicyNumer: string
) =>
  createSelector(
    getAllLocationExposures,
    (locationExposures): LocationExposureEntity[] => {
      return locationExposures.filter((exposure) => {
        return exposure.underlyingPolicyNumber === underlyingPolicyNumer;
      });
    }
  );

export const getLocationExposureTiles = createSelector(
  getLocationExposuresAndProductInfo,
  (locationExposures): UmbrellaTileModel[] => {
    return locationExposures.map((exposure) => {
      const titleLine1 =
        exposure.locationExposure?.locationUsage === 'prim'
          ? GeneralUtils.setTitlecase(
              exposure.locationExposure?.locationAddress?.addressLine1
            )
          : UmbrellaUtils.getPolicyTypeLabel(
              exposure?.locationExposure?.policyType
            );
      const titleLine2 =
        exposure.locationExposure?.locationUsage === 'prim'
          ? `${GeneralUtils.setTitlecase(
              exposure.locationExposure?.locationAddress?.city
            )}, ${exposure.locationExposure?.locationAddress?.state} ${
              exposure.locationExposure?.locationAddress?.postalCode
            }`
          : UmbrellaUtils.getLocationUsageLabel(
              exposure?.locationExposure?.locationUsage
            );

      return {
        data: exposure.locationExposure,
        subheading: `${UmbrellaUtils.getCurrentCarrierNameLabel(
          exposure?.underlyingPolicy?.currentCarrierName
        )} - ${exposure.underlyingPolicy?.underlyingPolicyNumber}`,
        titleLine1,
        titleLine2,
        subDetails: [],
        icon: exposure.product?.imageUrl,
        alt: 'Property product icon',
        type: 'location-exposure',
      };
    });
  }
);

export const getLocationExposureOptions = createSelector(
  getUmbrellaOptions,
  (umbrellaOptions): Nullable<LocationExposureOptionsModel> => {
    return umbrellaOptions?.locationExposures;
  }
);

export const locationExposuresExist = createSelector(
  getAllLocationExposures,
  getLocationExcludedExposuresAndProductInfo,
  (exposures, excludedExposures) => {
    if (exposures.length > 0 || excludedExposures.length > 0) {
      return true;
    }
    return false;
  }
);

export const buildLocationExposures = createSelector(
  getSelectedProducts,
  getAddressEntities,
  getCoveredLocationEntities,
  (products, addresses, coveredLocationEntities) => {
    const exposures: LocationExposureEntity[] = [];
    products.forEach((product) => {
      switch (product.type) {
        case 'Homeowner': {
          exposures.push({
            underlyingPolicyNumber: product.quoteId,
            policyType: 'HO3',
            locationUsage: 'prim',
            numberOfUnits: '1',
            locationAddress: addresses[product.type],
            dwellingDetails: {
              hasLiabilityLosses: false,
              hasNonDomesticAnimals: false,
              hasPool: false,
              hasTrampoline: false,
              haveDogs: false,
            },
          } as LocationExposureEntity);
          break;
        }
        case 'Tenant': {
          exposures.push({
            underlyingPolicyNumber: product.quoteId,
            policyType: 'HO4',
            locationUsage: 'prim',
            numberOfUnits:
              coveredLocationEntities[
                product.type
              ]?.unitsInBuilding?.toString(),
            locationAddress: addresses[product.type],
            dwellingDetails: {
              hasLiabilityLosses: false,
              hasNonDomesticAnimals: false,
              hasPool: false,
              hasTrampoline: false,
              haveDogs: false,
            },
          } as LocationExposureEntity);
          break;
        }
        case 'Condominium': {
          exposures.push({
            underlyingPolicyNumber: product.quoteId,
            policyType: 'HO6',
            locationUsage: 'prim',
            numberOfUnits: '1',
            locationAddress: addresses[product.type],
            dwellingDetails: {
              hasLiabilityLosses: false,
              hasNonDomesticAnimals: false,
              hasPool: false,
              hasTrampoline: false,
              haveDogs: false,
            },
          } as LocationExposureEntity);
          break;
        }
      }
    });
    return exposures;
  }
);

export const getAllDeletedLocationExposures = createSelector(
  getLocationExposureState,
  (locationExposureState): LocationExposureEntity[] => {
    const deleted =
      locationExposureState.deleted as Dictionary<LocationExposureEntity>;
    return Object.values(deleted).map((key) => {
      return key as LocationExposureEntity;
    });
  }
);

export const getDeletedLocationExposuresWithActivePolicy = createSelector(
  getAllDeletedLocationExposures,
  getAllUnderlyingPolicies,
  (locationExposures, underlyingPolicies): LocationExposureEntity[] => {
    const underlyingPolicyNumbers = underlyingPolicies.map(
      (policy) => policy?.underlyingPolicyNumber
    );
    return locationExposures.filter((exposure) =>
      underlyingPolicyNumbers.includes(
        exposure?.underlyingPolicyNumber as string
      )
    );
  }
);

export const getDeletedLocationExposuresByUnderlyingPolicyNumber = (
  underlyingPolicyNumer: string
) =>
  createSelector(
    getAllDeletedLocationExposures,
    (locationExposures): LocationExposureEntity[] => {
      return locationExposures.filter((exposure) => {
        return exposure.underlyingPolicyNumber === underlyingPolicyNumer;
      });
    }
  );

export const hasPrimaryLocationExposure = createSelector(
  getAllLocationExposures,
  (locationExposures): boolean => {
    return locationExposures.find(
      (exposure) => exposure.locationUsage === 'prim'
    )
      ? true
      : false;
  }
);
