import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromNavigation from '@core/store/entities/navigation/navigation.reducer';
import { Page, PageSubCategory } from './navigation.action';
import { PageIdentifier } from '@app/core/constants/pages';
import { AdjacentPages } from '@core/interfaces/interfaces';
import { NavigationBus } from '@core/services/navigation.service';

export const selectNavigationState = createSelector(
  fromCore.getCoreState,
  (state) => state.navigation
);

export const {
  selectAll: getAllPages,
  selectEntities: getPageEntities,
  selectTotal: getTotalPages,
} = fromNavigation.adapter.getSelectors(selectNavigationState);

export const selectNavigationPath = createSelector(getAllPages, (pages) =>
  pages.filter((page) => isOnNavigationPath(page.index))
);

export const getMobileNavToggled = createSelector(
  selectNavigationState,
  (state) => state.mobileNavToggled
);

export const selectCurrentPageId = createSelector(
  selectNavigationState,
  (state) => state.currentPage
);

export const selectCurrentPage = createSelector(
  selectNavigationState,
  getPageEntities,
  (state, pageEntities) => pageEntities[state.currentPage]
);

export const selectTargetPage = createSelector(
  selectNavigationState,
  (state) => state.targetPage as NavigationBus
);

export const selectAutofillPage = createSelector(
  selectNavigationState,
  (state) => state.autofillPage
);

export const selectEnabledPages = createSelector(
  selectNavigationPath,
  (pages) => pages.filter((page) => page.enabled)
);

export const selectUnvisitedProductPages = createSelector(
  selectEnabledPages,
  (pages) =>
    pages.filter(
      (page) =>
        page.subcategory === PageSubCategory.BUILD_QUOTE && !page.visited
    )
);

export const selectPageById = (id: PageIdentifier) =>
  createSelector(selectNavigationState, (state) => state.entities[id]);

export const selectNextPage = (page: Page) =>
  createSelector(selectEnabledPages, (enabledPages) => {
    const pageIndex = enabledPages.findIndex(
      (enabledPage) => enabledPage.id === page.id
    );
    return pageIndex < enabledPages.length
      ? enabledPages[pageIndex + 1]
      : enabledPages[pageIndex];
  });

export const selectPreviousPage = (page: Page) =>
  createSelector(selectEnabledPages, (enabledPages) => {
    const pageIndex = enabledPages.findIndex(
      (enabledPage) => enabledPage.id === page.id
    );
    return pageIndex > 0
      ? enabledPages[pageIndex - 1]
      : enabledPages[pageIndex];
  });

export const selectAdjacentPages = (page: Page) =>
  createSelector(
    selectNextPage(page),
    selectPreviousPage(page),
    (next, previous) => ({ next, previous } as AdjacentPages)
  );

export function isOnNavigationPath(index: number): boolean {
  return index > -1;
}
