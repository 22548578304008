import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Nullable } from '@shared/utils/type.utils';
import {
  linkToPolicyCenter,
  logLinkToPolicyCenter,
  Page,
  invalidValidationNavigation,
} from '@core/store/entities/navigation/navigation.action';
import { PageIdentifier, MEMBER_PAGE_INDEX } from '@core/constants/pages';
import { AdjacentPages } from '@core/interfaces/interfaces';
import { NavigationActions } from '@core/store/actions';
import { NavigationSelectors } from '@core/store/selectors';
import {
  getAllPages,
  selectTargetPage,
  selectUnvisitedProductPages,
} from '@core/store/entities/navigation/navigation.selector';
import { skip, take } from 'rxjs/operators';
import { getPageValidationState } from '@core/store/entities/error/error.selector';
import { addError } from '@core/store/entities/error/error.action';
import { UrlType } from '@shared/services/pc-url.service';

export enum Direction {
  'forward',
  'backward',
}

export interface NavigationBus {
  current: Page;
  destination: Page;
  direction: Direction;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private store: Store, private window: Window) {}

  getAdjacentPages(page: Page): Observable<AdjacentPages> {
    return this.store.select(NavigationSelectors.selectAdjacentPages(page));
  }

  getCurrentPage(): Observable<Nullable<Page>> {
    return this.store.select(NavigationSelectors.selectCurrentPage);
  }

  setErrors(pageId: PageIdentifier, hasError: boolean): void {
    hasError
      ? this.store.dispatch(NavigationActions.setErrors({ payload: pageId }))
      : this.store.dispatch(NavigationActions.clearErrors({ payload: pageId }));
  }

  setComplete(pageId: PageIdentifier): void {
    this.store
      .select(getPageValidationState)
      .pipe(take(1))
      .subscribe((state) => {
        if (!state[pageId]) {
          this.store.dispatch(
            NavigationActions.markComplete({ payload: pageId })
          );
        }
      });
  }

  setIncomplete(pageId: PageIdentifier): void {
    return this.store.dispatch(
      NavigationActions.markIncomplete({ payload: pageId })
    );
  }

  setCurrentPage(page: Page): void {
    this.store.dispatch(NavigationActions.setCurrentPage({ payload: page }));
  }

  submitPage(): void {
    this.getCurrentPage()
      .pipe(take(1))
      .subscribe((page) => {
        this.store.dispatch(
          NavigationActions.submitPage({ payload: page as Page })
        );
      });
  }

  setTargetPageWithDirection(
    current: Page,
    destination: Page,
    direction: Direction
  ): void {
    if (!destination.isNavigable) {
      this.presentNavigationRejectedError(current, destination);
      return;
    }
    this.store.dispatch(
      NavigationActions.setTargetPage({
        payload: {
          current,
          destination,
          direction,
        },
      })
    );
  }

  setTargetPage(current: Page, destination: Page): void {
    if (!destination?.isNavigable) {
      this.presentNavigationRejectedError(current, destination);
      return;
    }
    if (current && destination && destination.index > current.index) {
      this.store.dispatch(
        NavigationActions.setTargetPage({
          payload: {
            current,
            destination,
            direction: Direction.forward,
          },
        })
      );
    } else {
      this.store.dispatch(
        NavigationActions.setTargetPage({
          payload: {
            current,
            destination,
            direction: Direction.backward,
          },
        })
      );
    }
  }

  private presentNavigationRejectedError(
    current: Page,
    destination: Page
  ): void {
    this.store
      .select(selectUnvisitedProductPages)
      .pipe(take(1))
      .subscribe((pages) => {
        let message: string;
        if (pages.length) {
          message = `Please complete ${pages[0].name} page.`;
        } else {
          message = `${destination.name} page is not available in current state.`;
        }
        this.store.dispatch(
          invalidValidationNavigation({
            payload: {
              displayMessage: message,
              remediation: {
                description: '',
                pageName: current.name,
              },
            },
          })
        );
      });
  }

  getTargetPage(): Observable<NavigationBus> {
    return this.store.select(selectTargetPage).pipe(skip(1));
  }

  openExternalPage(
    url: string,
    target?: string | undefined,
    features?: string | undefined
  ): void {
    this.window.open(url, target, features);
  }

  pivotToPolicyCenter(reason: string, urlType?: UrlType): void {
    this.store.dispatch(linkToPolicyCenter({ payload: { reason, urlType } }));
  }

  logPivotToPolicyCenter(reason: string, urlType?: UrlType): void {
    this.store.dispatch(
      logLinkToPolicyCenter({ payload: { reason, urlType } })
    );
  }

  collapseMobileNav() {
    this.store.dispatch(NavigationActions.collapseMobileNav());
  }

  openMobileNav() {
    this.store.dispatch(NavigationActions.openMobileNav());
  }

  getMobileToggled(): Observable<boolean> {
    return this.store.select(NavigationSelectors.getMobileNavToggled);
  }

  restrictNavigationBeyondPage(index: number): void {
    this.store
      .select(getAllPages)
      .pipe(take(1))
      .subscribe((pages) => {
        pages.forEach((page) => {
          this.store.dispatch(
            NavigationActions.setPageNavigable({
              payload: page.id,
              isNavigable: page.index <= index,
            })
          );
        });
      });
  }
}
