import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromProducts from '@core/store/entities/product/product.selectors';
import * as fromPeople from '@core/store/entities/member/member.selector';
import * as fromAgency from '@core/store/entities/agency/agency.selector';
import * as fromAddress from '@core/store/entities/address/address.selector';
import * as fromUser from '@core/store/entities/user/user.selector';
import { ProductType } from '@core/models/api/dsm-types';
import { DateUtils } from '@shared/utils/date.utils';
import { DigitalIdCardsTextRequest } from '@core/models/api/request/digital-id-cards-text-request.model';
import { PersonUtils } from '@shared/utils/person.utils';
import { MemberModel } from '@core/models/views/person.model';

export const getDigitalIdCardsTextState = createSelector(
  fromCore.getCoreState,
  (state: fromCore.CoreState) => state.digitalIdCardsText
);

export const getDigitalIDCardsTextLoaded = createSelector(
  getDigitalIdCardsTextState,
  (state) => state.loaded
);

export const getDigitalIdCardsPassword = createSelector(
  getDigitalIdCardsTextState,
  (state) => (state.response ? state.response.password : '')
);

export const buildDigitalIdCardsTextRequest = (
  productType: ProductType,
  mobileNumber: string
) =>
  createSelector(
    fromProducts.getProductModelsByTypes([productType]),
    fromPeople.getPrimaryNamedInsuredForProduct(productType),
    fromAddress.getAddressForProduct(productType),
    fromAgency.getAgency,
    fromUser.getUser,
    (products, pni, policyholderAddress, agency, user) => {
      const producerCode = agency.producerCode;
      const suffix = pni?.person?.suffix ? ` ${pni.person.suffix}` : '';
      const middleName = pni?.person?.middleName
        ? ` ${pni.person.middleName}`
        : '';
      const fullName = `${pni?.person?.lastName}${suffix}, ${pni?.person?.firstName}${middleName}`;
      const daysInFuture = 5;
      return {
        submissionNumber: products[0].quoteId,
        policyHolderId: PersonUtils.policyHolderIdFromEntity(
          pni as MemberModel,
          productType
        ),
        policyNumber: products[0].policyNumber,
        effectiveDate: products[0].effectiveDate,
        zipcode: policyholderAddress?.postalCode?.split('-')[0],
        lastName: pni?.person?.lastName,
        expiry: DateUtils.getXDaysInFuture(daysInFuture),
        mobileNumber: mobileNumber.replace(/-/g, ''),
        producerCode,
        producerCodeSource: user?.role === 'IA' ? 'AMF' : 'PDS',
        fullName,
      } as DigitalIdCardsTextRequest;
    }
  );
