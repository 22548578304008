import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductType } from '@core/models/api/dsm-types';
import { ComponentStoreService } from '@core/services/component-store.service';
import { CoveredLocationService } from '@core/services/covered-location.service';
import { PropertyService } from '@core/services/property.service';
import { QuoteService } from '@core/services/quote.service';
import { CoveredLocationEntity } from '@entities/covered-location/covered-location.reducer';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentStore } from '@ngrx/component-store';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, Subject, take } from 'rxjs';

export interface MsbEstimateModalState {
  showRestoreMsbEstimateNumber?: boolean;
  updatedMsbEstimateNumber?: string;
  initialMsbEstimateNumber?: string;
  showRecalculateMessageAndButton?: boolean;
  showNotFoundErrorMessage?: boolean;
}

@Component({
  selector: 'nwx-msb-estimate-modal',
  templateUrl: './msb-estimate-modal.component.html',
  styleUrls: ['./msb-estimate-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore],
})
export class MsbEstimateModalComponent implements OnInit, OnDestroy {
  @Input() associatedMSBEstimateNumber!: string;
  @Input() initialMSBEstimateNumber!: Nullable<string>;
  @Input() productType!: ProductType;
  @Input() isLaunchedFromHubPage!: boolean;

  vm$!: Observable<MsbEstimateModalState>;

  form!: FormGroup;
  msbEstimateNumberWhenModalOpened!: string;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private coveredLocationService: CoveredLocationService,
    private readonly componentStore: ComponentStoreService<MsbEstimateModalState>,
    private propertyService: PropertyService,
    private quoteService: QuoteService
  ) {
    this.form = this.fb.group({});
  }

  ngOnInit(): void {
    this.componentStore.initialize({
      showRestoreMsbEstimateNumber:
        !!this.initialMSBEstimateNumber &&
        this.initialMSBEstimateNumber !== this.associatedMSBEstimateNumber,
      showRecalculateMessageAndButton: this.isLaunchedFromHubPage,
      showNotFoundErrorMessage: false,
    });
    this.vm$ = this.componentStore.get();

    this.msbEstimateNumberWhenModalOpened = this.associatedMSBEstimateNumber;
  }

  ngOnDestroy(): void {
    this.form.disable();
    this.unsubscribe$.next();
  }

  addChildForm(name: string, form: FormGroup): void {
    this.form.setControl(name, form);
  }

  close(): void {
    if (
      this.msbEstimateNumberWhenModalOpened !==
      this.form.value.msbEstimate.associatedMSBEstimateNumber
    ) {
      this.propertyService.storeCoveredLocation({
        associatedMSBEstimateNumber: this.associatedMSBEstimateNumber,
        productType: this.productType,
      } as CoveredLocationEntity);
    }
    this.activeModal.close();
  }

  submit(): void {
    const { valid, value } = this.form;

    if (valid) {
      this.componentStore.update({
        showNotFoundErrorMessage: false,
      });

      const updatedMsbEstimateNumber =
        value.msbEstimate.associatedMSBEstimateNumber;
      if (updatedMsbEstimateNumber === this.associatedMSBEstimateNumber) {
        this.activeModal.close();
      } else {
        this.updateCoveredLocation(updatedMsbEstimateNumber, this.productType);
      }
    }
  }

  restore(): void {
    this.updateCoveredLocation(
      this.initialMSBEstimateNumber || '',
      this.productType
    );
  }

  updateCoveredLocation(
    updatedMsbEstimateNumber: string,
    productType: ProductType
  ): void {
    this.coveredLocationService
      .orchestrateMsbEstimateNumberChangeWithSpinner(
        updatedMsbEstimateNumber,
        productType
      )
      .pipe(take(1))
      .subscribe((failed) => {
        if (failed) {
          this.componentStore.update({
            showNotFoundErrorMessage: true,
          });
        } else {
          this.activeModal.close();
          if (this.isLaunchedFromHubPage) {
            this.quoteService.rateSelectedProducts('quote');
            window.scrollTo(0, 0);
          }
        }
      });
  }

  getSubmitButtonText(isRecalcNeeded: boolean) {
    if (isRecalcNeeded) {
      return 'Retrieve estimate & recalculate';
    } else {
      return 'Retrieve estimate';
    }
  }
}
