<div class="modal-header">
  <h4 class="modal-title">Pennsylvania Prebind Notice</h4>
</div>
<div class="modal-body" *ngIf="vm$ | async as vm">
  <div class="bolt-container">
    <div class="bolt-row bolt-align-items-center bolt-space-bottom-md">
      <div class="bolt-col-auto no-padding-right">
        <span class="number-block">1</span>
      </div>
      <div class="bolt-col-10 no-padding-left">
        <div class="bolt-subheading-lg font-weight-500">
          Tell the policy holder
        </div>
        <div class="bolt-body-copy-md">
          The following notices must be provided prior to binding new business
          in the State of Pennsylvania.
        </div>
      </div>
    </div>
    <hr />
    <div class="bolt-row bolt-space-bottom-xl">
      <div class="bolt-col-12">
        <div class="pale-gray-card card-content">
          <div class="bolt-row">
            <div
              class="bolt-col white-card bolt-space-bottom-lg"
              [ngClass]="{ 'xs-margin-right': isAutoSelected && isMsaSelected }"
              *ngIf="isAutoSelected"
            >
              <div
                class="bolt-row bolt-align-items-center bolt-space-bottom-sm"
              >
                <div class="bolt-col-auto no-padding-right">
                  <img
                    src="/assets/images/icons/blue-circle-car.svg"
                    role="presentation"
                    class="tile-img"
                  />
                </div>
                <div class="bolt-col-10 bolt-subheading-lg">
                  <strong>Auto documents</strong>
                </div>
              </div>
              <hr />
              <div
                class="bolt-row"
                *ngFor="let document of vm.autoPrebindDocuments"
              >
                <div class="bolt-col-auto">
                  <bolt-icon
                    name="document"
                    color="medium-blue"
                    title="pre-bind document"
                    size="sm"
                  >
                  </bolt-icon>
                </div>
                <div class="bolt-col document-name-text">
                  <a
                    href="javascript:void(0)"
                    (click)="viewDocument(document || '', 'PersonalAuto')"
                    title="open new window"
                  >
                    {{ vm.prebindDocumentLinkLabels?.[document] }}
                  </a>
                  (PDF)
                </div>
              </div>
              <hr />
              <div class="bolt-row bolt-align-items-center">
                <div class="bolt-col-auto">
                  <bolt-icon
                    name="info-circle-filled"
                    color="dark-sea-blue"
                    size="sm"
                  ></bolt-icon>
                </div>
                <div class="bolt-col password-text no-padding-left">
                  Tell customer the password to view the documents is
                  <strong>
                    <em>{{ vm.autoPassword }}</em>
                  </strong>
                </div>
              </div>
            </div>
            <div
              class="bolt-col white-card bolt-space-bottom-lg"
              [ngClass]="{ 'xs-margin-left': isAutoSelected && isMsaSelected }"
              *ngIf="isMsaSelected"
            >
              <div
                class="bolt-row bolt-align-items-center bolt-space-bottom-sm"
              >
                <div class="bolt-col-auto no-padding-right">
                  <img
                    src="/assets/images/icons/blue-circle-moto.svg"
                    role="presentation"
                    class="tile-img"
                  />
                </div>
                <div class="bolt-col-10 bolt-subheading-lg">
                  <strong>Motorcycle documents</strong>
                </div>
              </div>
              <hr />
              <div
                class="bolt-row"
                *ngFor="let document of vm.msaPrebindDocuments"
              >
                <div class="bolt-col-auto">
                  <bolt-icon
                    name="document"
                    color="medium-blue"
                    title="pre-bind document"
                    size="sm"
                  >
                  </bolt-icon>
                </div>
                <div class="bolt-col document-name-text">
                  <a
                    href="javascript:void(0)"
                    (click)="viewDocument(document || '', 'MSA')"
                    title="open new window"
                  >
                    {{ vm.prebindDocumentLinkLabels?.[document] }}
                  </a>
                  (PDF)
                </div>
              </div>
              <hr />
              <div class="bolt-row bolt-align-items-center">
                <div class="bolt-col-auto">
                  <bolt-icon
                    name="info-circle-filled"
                    color="dark-sea-blue"
                    size="sm"
                  ></bolt-icon>
                </div>
                <div class="bolt-col password-text no-padding-left">
                  Tell customer the password to view the documents is
                  <strong>
                    <em>{{ vm.msaPassword }}</em>
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div class="bolt-row">
            <div class="bolt-col">
              <bolt-button
                *ngIf="!vm.resendEmail"
                id="send-email-button"
                class="float-right"
                type="primary"
                (click)="sendEmail()"
                >Email notices to the customer</bolt-button
              >
              <bolt-button
                *ngIf="vm.resendEmail"
                id="resend-email-button"
                class="float-right"
                (click)="sendEmail()"
                >Resend email to the customer</bolt-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bolt-row bolt-align-items-center bolt-space-bottom-md">
      <div class="bolt-col-auto no-padding-right">
        <span class="number-block">2</span>
      </div>
      <div class="bolt-col-10 no-padding-left">
        <div class="bolt-subheading-lg font-weight-500">I confirm:</div>
      </div>
    </div>
    <hr />
    <div class="bolt-row bolt-space-bottom-xl">
      <div class="bolt-col-12">
        <div class="pale-gray-card card-content">
          <bolt-checkbox
            #confirmationCheckbox
            value="true"
            required
            (change)="checkedAgreementBox()"
            [attr.invalid]="makeInvalid(0) ? 'invalid' : null"
            [attr.disabled]="!vm.resendEmail ? '' : null"
            >The customer received the Pennsylvania notices</bolt-checkbox
          >
          <bolt-checkbox
            #confirmationCheckbox
            value="true"
            required
            (change)="checkedAgreementBox()"
            [attr.invalid]="makeInvalid(1) ? 'invalid' : null"
            [attr.disabled]="!vm.resendEmail ? '' : null"
            >The customer had the chance to review the notices before
            binding</bolt-checkbox
          >
          <bolt-checkbox
            #confirmationCheckbox
            value="true"
            required
            (change)="checkedAgreementBox()"
            [attr.invalid]="makeInvalid(2) ? 'invalid' : null"
            [attr.disabled]="!vm.resendEmail ? '' : null"
            >The customer does not have questions about the
            notices</bolt-checkbox
          >
        </div>
      </div>
    </div>
    <div *ngIf="displayError" class="bolt-space-bottom-lg">
      <bolt-notification
        >All three boxes must be checked to continue</bolt-notification
      >
    </div>
  </div>
</div>
<div class="modal-footer">
  <bolt-button-bar>
    <bolt-button slot="back" (click)="closeModal()">Cancel</bolt-button>
    <bolt-button slot="forward" type="primary" (click)="continue()"
      >Continue</bolt-button
    >
  </bolt-button-bar>
</div>
