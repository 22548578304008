import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { VehicleEntity } from '@core/store/entities/vehicle/vehicle.entity';
import { UpdateSelectedEntity } from '@core/interfaces/interfaces';
import { VehicleModel } from '@core/models/views/vehicle.model';
import { ProductType } from '@core/models/api/dsm-types';

export const addVehicle = createAction(
  '[Vehicle] Add Vehicle',
  props<{ payload: VehicleEntity; syncId?: string; correlationId?: string }>()
);

export const addVehicleError = createAction(
  '[Vehicle] Add Vehicle Error',
  props<{ error: ErrorModel; syncId?: string; correlationId?: string; entityId?: string }>()
);

export const addVehicleSuccess = createAction(
  '[Vehicle] Add Vehicle Success',
  props<{ payload: VehicleEntity; syncId?: string; correlationId?: string }>()
);

export const addNewTempVehicle = createAction(
  '[Vehicle] Add Temporary Vehicle',
  props<{ payload: VehicleEntity; syncId?: string; correlationId?: string }>()
);

export const addNewTempVehicleSuccess = createAction(
  '[Vehicle] Add Temporary Vehicle Success',
  props<{ payload: VehicleEntity; syncId?: string; correlationId?: string }>()
);

export const storeVehicle = createAction(
  '[Vehicle] Store Vehicle',
  props<{ payload: VehicleEntity; correlationId?: string }>()
);

export const updateVehicle = createAction(
  '[Vehicle] Update Vehicle',
  props<{ payload: VehicleEntity; correlationId?: string }>()
);

export const updateVehicleError = createAction(
  '[Vehicle] Update Vehicle Error',
  props<{
    error: ErrorModel;
    vehicleId: string;
    correlationId?: string;
    entityId?: string;
  }>()
);

export const updateVehicleSuccess = createAction(
  '[Vehicle] Update Vehicle Success',
  props<{ payload: VehicleEntity; correlationId?: string }>()
);

export const upsertAllVehicleSuccess = createAction(
  '[Vehicle] Upsert All Vehicle Success',
  props<{ payload: VehicleEntity[]; correlationId?: string }>()
);

export const deleteVehicle = createAction(
  '[Vehicle] Delete Vehicle',
  props<{ payload: VehicleEntity; correlationId?: string }>()
);

export const deleteVehicleError = createAction(
  '[Vehicle] Delete Vehicle Error',
  props<{
    error: ErrorModel;
    vehicleId: string;
    correlationId?: string;
  }>()
);

export const deleteVehicleSuccess = createAction(
  '[Vehicle] Delete Vehicle Success',
  props<{ payload: string; correlationId?: string }>()
);

export const deleteTempVehicle = createAction(
  '[Vehicle] Delete Temporary Vehicle',
  props<{ payload: string; correlationId?: string }>()
);

export const updatedVehicleSelected = createAction(
  '[Vehicle] Update Vehicle Selected',
  props<{ payload: UpdateSelectedEntity }>()
);

export const refetchAllVehicles = createAction(
  '[Vehicle] Refetch All Vehicles',
  props<{ productType: ProductType }>()
);

export const refetchAllVehiclesSuccess = createAction(
  '[Vehicle] Refetch All Vehicles Success',
  props<{
    productType: ProductType,
    quoteId: string,
    response: VehicleEntity[],
  }>()
);

export const refetchAllVehiclesError = createAction(
  '[Vehicle] Refetch All Vehicles Error',
  props<{ productType: ProductType, error: ErrorModel }>()
);

export const refetchVehicle = createAction(
  '[Vehicle] Refetch Vehicle',
  props<{ vehicle: VehicleEntity }>()
);

export const setVehicleSeriesByVin = createAction(
  '[Vehicle] Set Vehicle Series By Vin',
  props<{ vin: string, series: string }>()
);
