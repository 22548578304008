import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DigitalIdCardsTextAdapter } from '../adapters/digital-id-cards-text.adapter';
import { DigitalIdCardsTextRequest } from '../models/api/request/digital-id-cards-text-request.model';
import { DigitalIdCardsTextResponse } from '../models/api/response/digital-id-cards-text-response.model';
import { sendAutoIdCards } from '../store/entities/digital-id-cards-text/digital-id-cards-text.action';
import { getDigitalIdCardsPassword } from '@entities/digital-id-cards-text/digital-id-cards-text.selector';

@Injectable({ providedIn: 'root' })
export class DigitalIdCardsTextService {
  constructor(
    private digitalIdCardsTextAdapter: DigitalIdCardsTextAdapter,
    private store: Store
  ) {}

  sendText(
    request: DigitalIdCardsTextRequest
  ): Observable<DigitalIdCardsTextResponse> {
    return this.digitalIdCardsTextAdapter.sendText(request);
  }

  dispatchSendAutoIdCards(mobileNumber: string): void {
    this.store.dispatch(sendAutoIdCards({ mobileNumber }));
  }

  getDigitalIdCardsPassword(): Observable<string> {
    return this.store.select(getDigitalIdCardsPassword);
  }
}
