import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ProductType } from '@core/models/api/dsm-types';
import { MemberModel } from '@core/models/views/person.model';
import { PolicyholderService } from '@core/services/policyholder.service';
import { ProductModel } from '@entities/product/product.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject, merge, of, pairwise, takeUntil } from 'rxjs';

@Component({
  selector: 'nwx-named-insured-modal',
  templateUrl: './named-insured-modal.component.html',
  styleUrls: ['./named-insured-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NamedInsuredModalComponent implements OnInit {
  @Input() product!: ProductModel;
  @Input() members: MemberModel[] = [];

  form!: FormGroup;
  pniOptions: MemberModel[] = [];
  sniOptions: MemberModel[] = [];
  aniCount = 0;
  errorMessage = '';
  callInProgress$ = new BehaviorSubject(false);
  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private policyholderService: PolicyholderService,
    private changeDetector: ChangeDetectorRef,
    private window: Window
  ) {}

  ngOnInit() {
    if (this.members.length > 2 && this.productSupportsAni(this.product.type)) {
      // (length-1) not (length-2): They might want PNI and a bunch of ANIs, or PNI + SNI + some ANIs.
      this.aniCount = this.members.length - 1;
    } else {
      this.aniCount = 0;
    }
    this.form = this.buildForm();
    merge(
      of(this.form.value),
      this.form.valueChanges,
    ).pipe(
      takeUntil(this.unsubscribe$),
      pairwise()
    ).subscribe(([prev, next]) => {
      this.setErrorMessage('');
      this.clearSelectionIfChosenElsewhere(prev, next);
    });
    this.pniOptions = this.members;
    this.sniOptions = this.members;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setErrorMessage(newMessage: string): void {
    if (this.errorMessage !== newMessage) {
      this.errorMessage = newMessage;
      this.changeDetector.markForCheck();
    }
  }

  private clearSelectionIfChosenElsewhere(prev: any, next: any): void {
    const keys = Object.keys(next);
    const changedKeys = keys.filter(k => prev[k] !== next[k]); // should be exactly one, but we'll tolerate any count
    let patch: any = null;
    for (const key of changedKeys) {
      if (next[key]) { // Blanks are allowed to be duplicates.
        for (const otherKey of keys) {
          if ((key !== otherKey) && (next[key] === next[otherKey])) {
            if (!patch) {
              patch = {};
            }
            patch[otherKey] = '';
          }
        }
      }
    }
    if (patch) {
      // Opportunity here to trigger some highlight on the changing fields. UX didn't ask for that.
      this.form.patchValue(patch);
    }
  }

  private productSupportsAni(productType: ProductType): boolean {
    switch (productType) {
      case 'Homeowner':
      case 'Tenant':
      case 'Condominium':
        return true;
    }
    return false;
  }

  buildForm(): FormGroup {
    let pniId = '';
    let sniId = '';
    for (const member of this.members) {
      const role = member.policyRoles.find(r => r.entityType === 'policyHolder' && r.productType === this.product.type);
      if (role) {
        switch (role.roleSequence) {
          case 'primary': pniId = member.entityId; break;
          case 'secondary': sniId = member.entityId; break;
        }
      }
    }
    const pregroup: { [key: string]: FormControl } = {
      primaryNamedInsured: this.fb.control(pniId, [
        Validators.required,
      ]),
      secondaryNamedInsured: this.fb.control(sniId, []),
    };
    for (let i=0; i<this.aniCount; i++) {
      const id = this.aniIdByIndex(i);
      pregroup[`ani${i}`] = this.fb.control(id, []);
    }
    return this.fb.group(pregroup);
  }

  private aniIdByIndex(index: number): string {
    for (const member of this.members) {
      const role = member.policyRoles?.find(r => (
        r.productType === this.product.type &&
        r.entityType === 'policyHolder' &&
        r.roleSequence === 'additional'
      ));
      if (role) {
        if (!index) {
          return member.entityId;
        }
        index--;
      }
    }
    return '';
  }

  onClose(): void {
    this.activeModal.close();
  }

  // null for synchronous rejection. The return value is really only used by unit tests.
  onSubmit(): Promise<void> | null {
    const formValue = this.form.value;
    const pni = this.members.find(m => m.entityId === formValue.primaryNamedInsured);
    const sni = this.members.find(m => m.entityId === formValue.secondaryNamedInsured);
    const anis: MemberModel[] = [];
    for (const key of Object.keys(formValue)) {
      if (key.startsWith('ani')) {
        const value = formValue[key];
        if (value) {
          const member = this.members.find(m => m.entityId === value);
          if (!member) {
            this.setErrorMessage('Invalid selections.');
            return null;
          }
          if (member === pni || member === sni || (anis.indexOf(member) >= 0)) {
            this.setErrorMessage(`${member.person?.firstName} ${member.person?.lastName} selected for multiple roles.`);
            return null;
          }
          anis.push(member);
        }
      }
    }
    if (!pni) {
      this.setErrorMessage(`PNI required.`);
      return null;
    }
    if (pni === sni) {
      this.setErrorMessage(`${pni.person?.firstName} ${pni.person?.lastName} selected for multiple roles.`);
      return null;
    }
    this.callInProgress$.next(true);
    return this.policyholderService.modifyPolicyHolderRoles(
      this.product.type,
      this.product.quoteId || '',
      pni,
      sni,
      anis
    ).then(() => {
      this.callInProgress$.next(false);
      this.activeModal.close();
    }).catch(e => {
      this.callInProgress$.next(false);
      this.setErrorMessage(`Failed to update policyholders. ${e?.message || ''}`);
      (this.window as any).console?.error(`role modification failed`, e);
    });
  }
}
