import { UserService } from '@core/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { HeaderService } from '@core/services/header.service';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NavLinkModel } from '@core/models/views/nav-link.model';
import { UserModel } from '@core/store/entities/user/user.model';
import { Router } from '@angular/router';
import {
  CompraterProducerSelectionPageRepresentation,
  SplashPagePageRepresentation,
} from '@core/constants/pages';
import { Page } from '@core/store/entities/navigation/navigation.action';
import { AgencyService } from '@core/services/agency.service';
import { LayoutService } from '@core/services/layout.service';

@Component({
  selector: 'nwx-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  static pagesWithoutHeader: (Page | null)[] = [
    SplashPagePageRepresentation,
    CompraterProducerSelectionPageRepresentation,
  ];

  @Input() onSplashPage!: boolean;

  shouldShowNavBar$: Observable<boolean>;
  shouldShowProfileComponent$: Observable<boolean>;
  shouldShowWelcomeMessage$: Observable<boolean>;
  navBarLinks$: Observable<NavLinkModel[]>;
  user$: Observable<UserModel>;
  maxSizeXSmall$: Observable<boolean>;
  currentLinks: NavLinkModel[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  agentCenterUrl = '';
  userName$: Observable<string>;
  producerCode$: Observable<string>;
  agentName$: Observable<string>;

  public shouldShowNavBar = false;
  public agentName = '';
  public agentFirstName = '';
  public isIaUser = false;

  constructor(
    private headerService: HeaderService,
    private userService: UserService,
    private router: Router,
    private agencyService: AgencyService,
    private layoutService: LayoutService
  ) {
    this.shouldShowNavBar$ = headerService.shouldShowNavBar();
    this.shouldShowWelcomeMessage$ = headerService.shouldShowWelcomeMessage();
    this.shouldShowProfileComponent$ =
      headerService.shouldShowProfileComponent();
    this.navBarLinks$ = headerService.getNavBar();
    this.user$ = userService.getUser();
    this.userName$ = this.userService.getUserName();
    this.producerCode$ = this.agencyService.getProducerCode();
    this.agentName$ = this.agencyService.getAgentName();
    this.maxSizeXSmall$ = this.layoutService.maxSizeXSmall();
  }

  ngOnInit(): void {
    this.navBarLinks$
      .pipe(takeUntil(this.destroy$))
      .subscribe((links: NavLinkModel[]) => {
        this.currentLinks = links;
      });
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user: UserModel) => {
      if (user?.firstName && user?.lastName) {
        this.agentName = `${user.firstName} ${user.lastName}`;
        this.agentFirstName = user.firstName;
      }
      if (user?.role && user?.role === 'IA') {
        this.isIaUser = true;
        this.agentCenterUrl = 'https://agentcenter.nationwide.com/';
      }
    });
    this.shouldShowNavBar$.pipe(takeUntil(this.destroy$)).subscribe(
      (should) => (this.shouldShowNavBar = should)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  setActive(idx: number): void {
    let url = null;
    for (let i = 0; i < this.currentLinks.length; i++) {
      if (i === idx) {
        this.currentLinks[i].active = true;
        url = this.currentLinks[i].linkValue;
      } else {
        this.currentLinks[i].active = false;
      }
    }
    if (url) {
      this.router.navigateByUrl(url);
    }
  }
}
