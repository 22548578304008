import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import {
  VehicleExposureAndProductInfo,
  VehicleExposureEntity,
} from './vehicle-exposures.entity';
import { ProductsSelectors } from '@core/store/selectors';
import * as fromVehicleExposures from '@core/store/entities/exposures/vehicle-exposures/vehicle-exposures.reducer';
import { getAllFeatureFlags } from '@entities/feature-flag/feature-flag.selector';
import {
  VehicleExcludedExposureRequest,
  VehicleExposureRequest,
} from '@core/models/api/request/vehicle-exposure-request.model';
import {
  getAllUnderlyingPolicies,
  getUnderlyingPoliciesAndProductInfo,
} from '@entities/underlying-policy/underlying-policy.selector';
import { getUmbrellaOptions } from '@entities/metadata/metadata.selector';
import { Nullable } from '../../../../../shared/utils/type.utils';
import { VehicleExposureOptionsModel } from '@entities/metadata/models/umbrella-options.model';
import { UmbrellaUtils } from '@shared/utils/umbrella.utils';
import { VehicleExcludedExposureEntity } from '../vehicle-excluded-exposures/vehicle-excluded-exposures.entity';
import { getAllVehicles } from '@entities/vehicle/vehicle.selector';
import { getVehicleExcludedExposuresAndProductInfo } from '../vehicle-excluded-exposures/vehicle-excluded-exposures.selector';
import { Dictionary } from '@ngrx/entity';
import { UmbrellaTileModel } from '../../../../../shared/components/umbrella-tile/umbrella-tile.component';

export const getVehicleExposureState = createSelector(
  fromCore.getCoreState,
  (state) => state.vehicleExposures
);

export const {
  selectAll: getAllVehicleExposures,
  selectEntities: getVehicleExposures,
} = fromVehicleExposures.adapter.getSelectors(getVehicleExposureState);

export const buildVehicleExposureRequest = (
  vehicleExposure: VehicleExposureEntity,
  correlationId?: string
) =>
  createSelector(
    ProductsSelectors.getProduct('PersonalUmbrella'),
    getAllFeatureFlags,
    (product, featureFlags): VehicleExposureRequest => {
      return {
        vehicleExposureBody: {
          vehicleExposure: {
            productId: vehicleExposure?.productId,
            vehicleExposureId: vehicleExposure?.vehicleExposureId,
            underlyingPolicyNumber: vehicleExposure?.underlyingPolicyNumber,
            vehicleType: vehicleExposure?.vehicleType,
            hasCompOnly: vehicleExposure?.hasCompOnly,
            compOnlyDescription: vehicleExposure?.compOnlyDescription,
            vehicleId: vehicleExposure?.vehicleId,
          },
        },
        quoteId: product?.quoteId,
        productType: product?.type,
      } as VehicleExposureRequest;
    }
  );

export const getVehicleExposuresAndProductInfo = createSelector(
  getAllVehicleExposures,
  getUnderlyingPoliciesAndProductInfo,
  (vehicleExposures, underlyingPolicies): VehicleExposureAndProductInfo[] => {
    return vehicleExposures.map((exposure) => {
      return {
        vehicleExposure: exposure,
        underlyingPolicy: underlyingPolicies.find(
          (up) =>
            up.underlyingPolicy.underlyingPolicyNumber ===
            exposure.underlyingPolicyNumber
        )?.underlyingPolicy,
        product: underlyingPolicies.find(
          (up) =>
            up.underlyingPolicy.underlyingPolicyNumber ===
            exposure.underlyingPolicyNumber
        )?.product,
      };
    }) as VehicleExposureAndProductInfo[];
  }
);

export const getVehicleExposureOptions = createSelector(
  getUmbrellaOptions,
  (umbrellaOptions): Nullable<VehicleExposureOptionsModel> => {
    return umbrellaOptions?.vehicleExposures;
  }
);

export const getVehicleExposureTiles = createSelector(
  getVehicleExposuresAndProductInfo,
  (vehicleExposures): UmbrellaTileModel[] => {
    return vehicleExposures.map((exposure) => {
      return {
        data: exposure.vehicleExposure,
        subheading: `${UmbrellaUtils.getCurrentCarrierNameLabel(
          exposure?.underlyingPolicy?.currentCarrierName
        )} - ${exposure.underlyingPolicy?.underlyingPolicyNumber}`,
        titleLine1: `${UmbrellaUtils.getVehicleLabel(
          exposure?.vehicleExposure?.vehicleType
        )} ${exposure.product?.name}`,
        titleLine2: `Underlying vehicle #
          ${exposure?.vehicleExposure?.vehicleExposureId}`,
        subDetails: [],
        icon: exposure.product?.imageUrl,
        alt: 'Auto product icon',
        type: 'vehicle-exposure',
      };
    });
  }
);

export const vehicleExposuresExist = createSelector(
  getAllVehicleExposures,
  getVehicleExcludedExposuresAndProductInfo,
  (exposures, excludedExposures) => {
    if (exposures.length > 0 || excludedExposures.length > 0) {
      return true;
    }
    return false;
  }
);

export const buildVehicleExposures = createSelector(
  getAllVehicles,
  ProductsSelectors.getProductIfSelected('PersonalAuto'),
  ProductsSelectors.getProductIfSelected('MSA'),
  ProductsSelectors.getProductIfSelected('RV'),
  (vehicles, autoProduct, msaProduct, rvProduct) => {
    const exposures: VehicleExposureEntity[] = [];
    vehicles.forEach((vehicle) => {
      switch (vehicle.vehicleType) {
        case 'auto':
          if (autoProduct) {
            exposures.push({
              hasCompOnly: false,
              underlyingPolicyNumber: autoProduct?.quoteId,
              vehicleType: 'PrivatePass_Ext',
            } as VehicleExposureEntity);
          }
          break;
        case 'Motorcycle':
          if (msaProduct) {
            exposures.push({
              hasCompOnly: false,
              underlyingPolicyNumber: msaProduct?.quoteId,
              vehicleType: 'OnRoad_Ext',
            } as VehicleExposureEntity);
          }
          break;
        case 'OffRoad':
          if (msaProduct) {
            exposures.push({
              hasCompOnly: false,
              underlyingPolicyNumber: msaProduct?.quoteId,
              vehicleType: 'OffRoad_Ext',
            } as VehicleExposureEntity);
          }
          break;
        case 'Motorhome':
        case 'TravelTrailer':
          if (rvProduct?.isDsmActive) {
            exposures.push({
              hasCompOnly: false,
              underlyingPolicyNumber: rvProduct?.quoteId,
              vehicleType: 'OnRoad_Ext',
            } as VehicleExposureEntity);
          }
          break;
        // vehicleTypes we don't send: NamedNO_Ext, Antique_Ext
        // NB Utility trailers do not get added to Umbrella.
      }
    });
    return exposures;
  }
);

export const getVehicleExposuresByUnderlyingPolicyNumber = (
  underlyingPolicyNumer: string
) =>
  createSelector(
    getAllVehicleExposures,
    (vehicleExposures): VehicleExposureEntity[] => {
      return vehicleExposures.filter((exposure) => {
        return exposure.underlyingPolicyNumber === underlyingPolicyNumer;
      });
    }
  );

export const getAllDeletedVehicleExposures = createSelector(
  getVehicleExposureState,
  (vehicleExposureState): VehicleExposureEntity[] => {
    const deleted =
      vehicleExposureState.deleted as Dictionary<VehicleExposureEntity>;
    return Object.values(deleted).map((key) => {
      return key as VehicleExposureEntity;
    });
  }
);

export const getDeletedVehicleExposuresWithActivePolicy = createSelector(
  getAllDeletedVehicleExposures,
  getAllUnderlyingPolicies,
  (vehicleExposures, underlyingPolicies): VehicleExposureEntity[] => {
    const underlyingPolicyNumbers = underlyingPolicies.map(
      (policy) => policy?.underlyingPolicyNumber
    );
    return vehicleExposures.filter((exposure) =>
      underlyingPolicyNumbers.includes(
        exposure?.underlyingPolicyNumber as string
      )
    );
  }
);

export const getDeletedVehicleExposuresByUnderlyingPolicyNumber = (
  underlyingPolicyNumer: string
) =>
  createSelector(
    getAllDeletedVehicleExposures,
    (vehicleExposures): VehicleExposureEntity[] => {
      return vehicleExposures.filter((exposure) => {
        return exposure.underlyingPolicyNumber === underlyingPolicyNumer;
      });
    }
  );

/**
 * Vehicles, Excluded Vehicles, Watercrafts, and Excluded Watercrafts.
 * ie everything associated with a VehicleEntity.
 */
export const countAllVehicleLikeExposures = createSelector(
  fromCore.getCoreState,
  (core) => (
    core.vehicleExcludedExposures.ids.length +
    core.vehicleExposures.ids.length +
    core.watercraftExcludedExposures.ids.length +
    core.watercraftExposures.ids.length
  )
);
