import { ProductType } from '@core/models/api/dsm-types';
import { PolicyNumberRequest } from '@core/models/api/request/policy-number-request.model';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { Action, createAction, props } from '@ngrx/store';
import { ProductModel } from '../product/product.model';

export const enum PolicyNumberTypes {
  GENERATE_POLICY_NUMBER = '[Generate Policy Number] Generate Policy Number',
  GENERATE_POLICY_NUMBER_FAIL = '[Generate Policy Number] Generate Policy Number Fail',
  GENERATE_POLICY_NUMBER_SUCCESS = '[Generate Policy Number] Generate Policy Number Success',
}

export const generatePolicyNumber = createAction(
  PolicyNumberTypes.GENERATE_POLICY_NUMBER
);

export const generatePolicyNumberSuccess = createAction(
  PolicyNumberTypes.GENERATE_POLICY_NUMBER_SUCCESS,
  props<{ response: ProductModel }>()
);

export const generatePolicyNumberFail = createAction(
  PolicyNumberTypes.GENERATE_POLICY_NUMBER_FAIL,
  props<{ response: ErrorModel; product?: ProductType }>()
);
