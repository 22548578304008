import { ObjectValidator } from '@core/helper/object-validator';
import {
  BaseEntity,
  BaseEntityOptionalFields,
  BaseEntitySanitizerFields,
} from '@core/models/entities/base.entity';
import { ProductModel } from '@entities/product/product.model';
import { UnderlyingPolicyEntity } from '@entities/underlying-policy/underlying-policy.entity';

export interface ExposureModel {
  underlyingPolicyNumber: string;
  excludedExposure?: boolean;
  excludedExposureType?: string;
}

export interface VehicleExposureAndProductInfo {
  vehicleExposure: VehicleExposureEntity;
  underlyingPolicy?: UnderlyingPolicyEntity;
  product: ProductModel;
  options?: {
    vehicleLabel?: string;
    carrierLabel?: string;
  };
}

export interface VehicleExposureEntity extends BaseEntity {
  productId?: string;
  vehicleExposureId: string;
  underlyingPolicyNumber?: string;
  vehicleType: string;
  hasCompOnly: boolean;
  compOnlyDescription: string;
  vehicleId?: string;
}

export function sanitizeVehicleExposureEntity(
  input: unknown
): VehicleExposureEntity {
  return ObjectValidator.forceSchema<VehicleExposureEntity>(
    input,
    {
      productId: 'string',
      vehicleExposureId: 'string',
      underlyingPolicyNumber: 'string',
      vehicleType: 'string',
      hasCompOnly: 'boolean',
      compOnlyDescription: 'string',
      vehicleId: 'string',
      ...BaseEntitySanitizerFields,
    },
    [
      'productId',
      'underlyingPolicyNumber',
      'vehicleId',
      ...BaseEntityOptionalFields,
    ]
  );
}
