import { UpdateSelectedEntity } from '@core/interfaces/interfaces';
import { ProductType } from '@core/models/api/dsm-types';
import { MemberModel } from '@core/models/views/person.model';
import { createAction, props } from '@ngrx/store';
import { MemberEntity } from './member.reducer';
import { PolicyHolderEntity } from '@entities/policyholder/policyholder.entity';
import { MemberPolicyRole } from '@core/models/entities/base.entity';

export const addBlankMember = createAction(
  '[Member] Add Blank Person',
  props<{ payload: string }>()
);
export const addPni = createAction(
  '[Member] Add Pni',
  props<{ payload: string }>()
);

export const addPrefillMember = createAction(
  '[Member] Add Prefill Member',
  props<{ payload: MemberModel; products: ProductType[] }>()
);

export const upsertMember = createAction(
  '[Member] Upsert Member',
  props<{ payload: MemberEntity }>()
);

export const storeMember = createAction(
  '[Member] Store Member',
  props<{ payload: MemberEntity }>()
);

export const updateMemberSelected = createAction(
  '[Member] Update Member Selected',
  props<{ payload: UpdateSelectedEntity }>()
);

export const dropMemberPrefillContent = createAction(
  '[Member] Drop Member Prefill Content',
  props<{ payload: string }>()
);

export const sendMembersToBackend = createAction(
  '[Member] Send Members To Backend'
);

export const removePolicyRolesByProduct = createAction(
  '[Member] Remove PolicyRoles by Product',
  props<{ payload: PolicyHolderEntity }>()
);

export const removePolicyRole = createAction(
  '[Member] Remove PolicyRole',
  props<{ policyRole: MemberPolicyRole }>()
);

