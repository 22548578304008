import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { PremiumEntity } from '@core/store/entities/premium/premium.entity';
import { ProductType } from '@core/models/api/dsm-types';
import { CoverageEntity } from '@core/store/entities/coverage/coverage.entity';
import { CoverageModel } from '@core/models/views/coverage.model';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil, map, withLatestFrom, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ProductModel } from '@core/store/entities/product/product.model';
import { CoveredLocationEntity } from '@core/store/entities/covered-location/covered-location.reducer';
import { CurrencyPipe } from '@angular/common';
import { DateUtils } from '@shared/utils/date.utils';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuoteService } from '@core/services/quote.service';
import { ProductsService } from '@core/services/products.service';
import { ComponentChanges } from '@shared/utils/general.utils';
import { Nullable } from '@shared/utils/type.utils';
import { PolicyDateOptions } from '@core/interfaces/interfaces';
import { DatepickerInputComponent } from '@shared/components/datepicker-input/datepicker-input.component';

interface HomeownersCoverageDisplayFormModel {
  effectiveDate: Nullable<string>; // "MM/DD/YYYY"
}

@Component({
  selector: 'nwx-homeowner-coverage-display',
  templateUrl: './homeowner-coverage-display.component.html',
  styleUrls: ['./homeowner-coverage-display.component.scss'],
})
export class HomeownerCoverageDisplayComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() premiums!: PremiumEntity[];
  @Input() coverages!: CoverageEntity[];
  @Input() product!: ProductModel;
  @Input() coveredLocation!: CoveredLocationEntity;
  @Input() maxSizeMini!: boolean;

  form = new FormGroup({
    effectiveDate: new FormControl('', [
      (control) =>
        DatepickerInputComponent.validate(
          control,
          this.policyDateOptions?.minDate || '',
          this.policyDateOptions?.maxDate || ''
        ),
    ]),
  });
  policyDateOptions: Nullable<PolicyDateOptions> = null;

  totalPolicyPremium = 0;
  formattedAddress = '';
  yearRoofReplaced: number | string = 0;
  remainingCoverageCount = 0;
  showDatePicker: boolean = false;
  formValueDate: any;
  displayCoverages: CoverageModel[] = [];
  displayDeductibles: CoverageModel[] = [];
  updateInProgress$: Observable<boolean>;
  private unsubscribe$ = new Subject<void>();

  displayCoverageIds: string[] = [
    'PersonalLiability',
    'MedicalPayments',
    'UnscheduledPersonalProperty',
    'BasicCoverage',
    'DwellingReplacementCost',
    'IncreasedOtherStructures',
    'IncreasedLossOfUse',
  ];

  deductibleCoverageIds: string[] = [
    'UnscheduledPersonalProperty',
    'SectionIDeductibles',
  ];

  deductibleCodes: string[] = [
    'AllPerilDeductible',
    'UnscheduledPersonalPropertyDeductible',
  ];

  constructor(
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private quoteService: QuoteService,
    private modalService: NgbModal,
    private productsService: ProductsService,
    private ref: ChangeDetectorRef
  ) {
    this.updateInProgress$ = this.quoteService
      .getQuoteUpdateInProgress()
      .pipe(map((v) => !!v));
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        withLatestFrom(this.updateInProgress$)
      )
      .subscribe(this.onFormChanged.bind(this));
  }

  ngOnDestroy(): void {
    this.form.disable();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.showDatePicker = false;
  }

  ngOnInit(): void {
    this.totalPolicyPremium =
      this.premiums.find((p) => p.productType === 'Homeowner')?.total?.amount ||
      0;
    this.formatAddress();
    this.getRoofReplacementYear();
    this.getDisplayCoverages();
    this.productsService
      .getPolicyDateOptions(this.product.type)
      .pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe((options) => {
        this.policyDateOptions = options;
        this.ref.detectChanges();
      });
  }

  ngOnChanges(
    changes: ComponentChanges<HomeownerCoverageDisplayComponent>
  ): void {
    if (changes.product) {
      this.form.patchValue({
        effectiveDate: DateUtils.formatDsmDateToOld(
          this.product.effectiveDate || ''
        ),
      });
    }
    if (changes.premiums){
      this.totalPolicyPremium =
      this.premiums.find((p) => p.productType === 'Homeowner')?.total?.amount ||
      0;
    }
  }

  ngDoCheck(): void {
    if (this.formValueDate !== this.form.value.effectiveDate) {
      this.showDatePicker = false;
      this.formValueDate = this.form.value.effectiveDate;
    }
  }

  private formatAddress(): void {
    const location = this.coveredLocation.location;
    if (location) {
      this.formattedAddress = `${location.addressLine1}, ${location.city}, ${
        location.state
      } ${location.postalCode?.substring(0, 5)}`;
    }
  }

  getRoofReplacementYear(): void {
    const roofRenovation = this.coveredLocation.renovations?.find(
      (r) => r.type === 'Roof'
    );
    this.yearRoofReplaced =
      roofRenovation?.year ||
      // (this.coveredLocation.constructionInfo?.yearBuilt as number) ||
      'unknown';
  }

  getDisplayCoverages(): void {
    const displayEntities = this.coverages.filter(
      (c) =>
        c.productId === 'Homeowner' &&
        this.displayCoverageIds.includes(c.coverageId)
    );
    displayEntities.forEach((cov) => {
      const val = cov?.selectedValue?.[0]?.value || '';
      if (cov.coverageId === 'IncreasedLossOfUse') {
        this.displayCoverages.push({
          name: cov.name,
          value: cov?.selectedValue?.[0]?.description || '',
        });
      } else if (this.isNumber(val)) {
        this.displayCoverages.push({
          name: cov.name,
          value: this.currencyPipe.transform(val) || '',
        });
      } else if (this.isAcceptDecline(val)) {
        this.displayCoverages.push({
          name: cov.name,
          value: val === 'true' ? 'Accept' : 'Decline',
        });
      }
    });

    this.displayDeductibles = [];
    this.remainingCoverageCount =
      this.coverages.filter((c) => c.productId === 'Homeowner').length -
      this.displayCoverages.length;

    const deductibleCoverages = this.coverages.filter(
      (c) =>
        c.productId === 'Homeowner' &&
        this.deductibleCoverageIds.includes(c.coverageId)
    );
    deductibleCoverages.forEach((cov) => {
      if (cov.coverageId === 'SectionIDeductibles') {
        const deductible = cov.selectedValue.find(
          (val) => val.code === 'AllPerilDeductible'
        );
        this.displayDeductibles.push({
          name: 'All other perils',
          value: deductible?.description || '',
        });
      }
      if (cov.coverageId === 'UnscheduledPersonalProperty') {
        const deductible = cov.selectedValue.find(
          (val) => val.code === 'UnscheduledPersonalPropertyDeductible'
        );
        this.displayDeductibles.push({
          name: 'Personal property per incident',
          value: deductible?.description || '',
        });
      }
    });
  }

  isNumber(val: string | number): boolean {
    return parseInt(`${val}`) ? true : false;
  }

  isAcceptDecline(val: string): boolean {
    return val === 'false' || val === 'true';
  }

  editCoverages(): void {
    this.router.navigate(['/quote/coverages'], {
      queryParams: { product: 'Homeowner' } as { product: ProductType },
    });
  }
  // this is no longer correct
  routeToHome(): void {
    this.router.navigate(['quote/property/homeowner'], {});
  }

  promptToRemovePolicy(event?: Event, content?: unknown): void {
    event?.preventDefault();
    if (content) {
      const modal = this.modalService.open(content);
      modal.result
        .then(() => {
          this.productsService.removeProduct(this.product);
        })
        .catch(() => {
          // Cancelled, no worries.
        });
    }
  }

  private onFormChanged(
    value: [Partial<HomeownersCoverageDisplayFormModel>, Nullable<boolean>]
  ): void {
    const updateInprogress = value[1] === true;
    const newDate = DateUtils.formatDateToDSM(value[0]?.effectiveDate);
    if (
      updateInprogress ||
      !this.product ||
      this.product.effectiveDate === newDate ||
      !this.form.valid
    ) {
      return;
    }
    this.productsService.updateQuote({
      productType: 'Homeowner',
      effectiveDate: newDate,
    });
  }
}
