import { NextStep } from '@core/services/up-next.service';
import { StepUtils } from '@shared/utils/step.utils';
import { createSelector } from '@ngrx/store';
import {
  doAnyProductsHaveErrorsOrBlocked,
  getProductsPending,
  getSelectedProducts,
  getSelectedProductsIncludingNWXInactive,
  productsNeedRerate,
} from '../entities/product/product.selectors';
import { allTasksComplete } from '../entities/task/selectors/task.selector';
import {
  getShowTelematicsConfirmationTask,
  getTelematicsLoading,
  getTelematicsReviewed,
} from '../entities/telematics/telematics.selector';
import { getPreBindTaskStatus } from '../entities/confirmations/confirmations.selector';
import {
  getQuoteInProgressCount,
  getQuoteOrUpdateInProgressCount,
  getQuoteUpdateInProgress,
} from '../entities/quote/quote.selector';
import { PreBindDocumentSelectors } from '.';

const getNextStepProductsThings = createSelector(
  getSelectedProductsIncludingNWXInactive,
  getProductsPending,
  productsNeedRerate,
  doAnyProductsHaveErrorsOrBlocked,
  getQuoteOrUpdateInProgressCount,
  getTelematicsLoading,
  (
    products,
    productsPending,
    needsRecalc,
    anyProductsHaveErrorsOrBlocked,
    quoteInProgressCount,
    telematicsLoading
  ) => ({
    products,
    productsPending,
    needsRecalc,
    anyProductsHaveErrorsOrBlocked,
    quoteInProgressCount,
    telematicsLoading,
  })
);

export const getNextStep = createSelector(
  getNextStepProductsThings,
  allTasksComplete,
  getShowTelematicsConfirmationTask,
  getTelematicsReviewed,
  getPreBindTaskStatus,
  PreBindDocumentSelectors.getPreBindCallsIdle,
  (
    {
      products,
      productsPending,
      needsRecalc,
      anyProductsHaveErrorsOrBlocked,
      quoteInProgressCount,
      telematicsLoading,
    },
    tasksComplete,
    showTelematics,
    telematicsReviewed,
    preBindTaskStatus,
    preBindCallsIdle
  ): NextStep => {
    // This is temporary, this now needs to be a service with CombineLatest
    const activeProducts = products.filter((product) => product.isDsmActive);
    const inactiveProducts = products.filter((product) => !product.isDsmActive);

    const allProductsBinding = activeProducts.every(
      (p) => p.quoteStatus === 'Binding'
    );

    const quoteHasPersonalAuto = activeProducts.some(
      (p) => p.type === 'PersonalAuto'
    );

    if (productsPending || quoteInProgressCount > 0 || telematicsLoading) {
      return StepUtils.getProductsPending();
    }

    if (anyProductsHaveErrorsOrBlocked) {
      // return StepUtils.getRemoveProductsWithErrors();
      return StepUtils.completeTasks();
    }

    if (!tasksComplete) {
      return StepUtils.completeTasks();
    }

    if (needsRecalc) {
      return StepUtils.getRecalculateRate();
    }

    if (quoteHasPersonalAuto && !telematicsReviewed && showTelematics) {
      // TODO - Might be an issue here, could need to launch the telematics modal or something
      return StepUtils.reviewTelematics();
    }

    if (quoteHasPersonalAuto && showTelematics) {
      return StepUtils.acceptTelematics();
    }

    switch (preBindTaskStatus) {
      case 'not needed':
        break;
      case 'ready':
        break;
      case 'pending':
        return StepUtils.acceptPreBindDocuments(preBindCallsIdle);
      case 'confirm':
        return StepUtils.acceptPreBindDocuments(preBindCallsIdle);
      case 'upload':
        return StepUtils.uploadPreBindDocuments(preBindCallsIdle);
    }

    // if all products are quoted and previous steps complete offer bind
    if (tasksComplete && !allProductsBinding) {
      return StepUtils.getFinalRate();
    }

    // if all products are final and telematics confirmed, offer to go Billing
    if (activeProducts.length > 0 && allProductsBinding) {
      return StepUtils.getGoToBilling();
    }

    if (activeProducts.length === 0 && inactiveProducts.length > 0) {
      return StepUtils.pivotToPolictCenter();
    }

    if (products.length === 0 && inactiveProducts.length === 0) {
      return StepUtils.noProducts();
    }

    return {} as NextStep;
  }
);
