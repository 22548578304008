/* eslint-disable @angular-eslint/no-input-rename */
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { FormName } from '@core/models/api/nwx-types';
import { ErrorMessageService } from '@core/services/error-message.service';
import { NavigationService } from '@core/services/navigation.service';
import { NavigationActions, TaskActions } from '@core/store/actions';
import * as errorActions from '@core/store/entities/error/error.action';
import { Page } from '@core/store/entities/navigation/navigation.action';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Nullable } from '@shared/utils/type.utils';
import { merge, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  filter,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { FORM_DEBOUNCE_TIME } from '@shared/constants/app-constants';

interface InvalidElement {
  element: Element;
  inputListener: () => void;
}

@Directive({
  selector: '[nwxExposeFormErrors]',
  exportAs: 'controlErrors',
})
export class ExposeFormErrorsDirective
  implements OnDestroy, AfterViewInit, OnChanges
{
  @Input('nwxExposeFormErrors') form!: FormGroup;
  @Input('formName') formName!: string;
  @Input('submitted') submitted: boolean | null = null;
  @Input() suppressNavigationObservation = false;

  private validationErrors: Map<string, string>;
  private formChangeSubscription: Nullable<Subscription>;
  private mutationObserver: MutationObserver | null = null;
  private invalidElements: InvalidElement[] = [];
  private unsubscribe = new Subject<void>();

  getError(controlName: string) {
    return this.validationErrors.get(controlName);
  }

  constructor(
    private element: ElementRef,
    private errorMessageService: ErrorMessageService,
    private navigationService: NavigationService,
    private zone: NgZone,
    private actions$: Actions,
    private store: Store,
  ) {
    this.submitted = false;
    this.formChangeSubscription = null;
    this.validationErrors = new Map<string, string>();
  }

  ngAfterViewInit(): void {
    this.navigationService
      .getCurrentPage()
      .pipe(
        take(1),
        takeUntil(this.unsubscribe),
        filter(() => !this.suppressNavigationObservation)
      )
      .subscribe((page: Nullable<Page>) => {
        this.submitted = page?.isComplete || false;
      });
    this.actions$
      .pipe(
        ofType(NavigationActions.submitPage, TaskActions.submitTasksModal),
        filter(() => !this.suppressNavigationObservation),
        withLatestFrom(this.navigationService.getCurrentPage()),
        filter(
          ([submitted, current]) => submitted.payload.name === current?.name
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe({
        next: ([_submitted, current]) => {
          this.submitted = true;
          this.form.updateValueAndValidity({
            onlySelf: false,
            emitEvent: true,
          });
          this.removeFormElementsRecursively(this.element.nativeElement);
          this.addFormElementsRecursively(this.element.nativeElement);
          this.observeFormState(current);
        },
      });
    this.zone.runOutsideAngular(() => {
      this.mutationObserver = new MutationObserver((events) =>
        this.onMutation(events)
      );
      this.mutationObserver.observe(this.element.nativeElement, {
        childList: true,
        subtree: true,
        attributes: true,
      });
    });
    this.formChangeSubscription = merge(      
      this.form.statusChanges
    )
      .pipe(
        debounceTime(FORM_DEBOUNCE_TIME),
        withLatestFrom(this.navigationService.getCurrentPage())
      )
      .subscribe(([_value, page]) => {
        this.observeFormState(page);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.submitted) {
      this.form.updateValueAndValidity({
        onlySelf: false,
        emitEvent: false,
      });
      this.removeFormElementsRecursively(this.element.nativeElement);
      this.addFormElementsRecursively(this.element.nativeElement);
      this.observeFormState(null);
    }
  }

  ngOnDestroy(): void {
    this.formChangeSubscription?.unsubscribe();
    this.mutationObserver?.disconnect();
    this.unwatchInvalidElement(this.element.nativeElement);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private observeFormState(page: Nullable<Page>) {
    if (!this.submitted && this.form.status === 'VALID') {
      this.store.dispatch(
        errorActions.setSideNavErrorIcon({
          payload: {
            errors: {},
            formName: this.formName,
            pageName: page?.name,
          },
        })
      );
    } else {
      this.validationErrors.clear();
      let invalidControlErrors = this.collectFormErrorsRecursive(this.form);
      if (invalidControlErrors === null) {
        this.store.dispatch(
          errorActions.setSideNavErrorIcon({
            payload: {
              errors: {},
              formName: this.formName,
              pageName: page?.name,
            },
          })
        );
        return;
      }
      this.store.dispatch(
        errorActions.setSideNavErrorIcon({
          payload: {
            displayMessages: Array.from(this.validationErrors.values()),
            errors: invalidControlErrors,
            formName: this.formName,
            pageName: page?.name,
          },
        })
      );
    }
  }

  private collectFormErrorsRecursive(
    fg: FormGroup,
    refName = this.formName || ''
  ): ValidationErrors | null {
    let invalidControlErrors = null;

    for (const ctrlName in fg.controls) {
      const ctrl = fg.controls[ctrlName];
      const isFormGroup = ctrl?.hasOwnProperty('controls');
      const ctrlErrors = isFormGroup
        ? this.collectFormErrorsRecursive(ctrl as FormGroup, refName)
        : ctrl?.errors;
      const isRequiredError =
        this.errorMessageService.validationErrorsAreDueToIncomplete(ctrlErrors);
      if (ctrl && ctrlErrors) {
        if (!this.submitted && isRequiredError) continue;
        invalidControlErrors ||= {} as ValidationErrors;
        Object.assign(invalidControlErrors, {
          [ctrlName]: ctrlErrors,
        });
        if (!isFormGroup) {
          this.validationErrors.set(
            ctrlName,
            this.errorMessageService.getErrorMessageFromValidationErrors(
              ctrlErrors,
              this.formName as FormName
            )
          );
        }
      }
    }

    return invalidControlErrors;
  }

  private onMutation(events: MutationRecord[]): void {
    for (const event of events) {
      for (let i = event.addedNodes.length; i-- > 0; ) {
        const element = event.addedNodes[i];
        if (!(element instanceof Element)) {
          continue;
        }
        this.addFormElementsRecursively(element as Element);
      }
      for (let i = event.removedNodes.length; i-- > 0; ) {
        const element = event.removedNodes[i];
        if (!(element instanceof Element)) {
          continue;
        }
        this.removeFormElementsRecursively(element as Element);
      }
      if (event.attributeName === 'class') {
        this.examineNgMarkerClasses(event.target as Element);
      }
    }
  }

  private addFormElementsRecursively(element: Element): void {
    if (this.elementIsFormMember(element)) {
      this.mutationObserver?.observe(element, { attributes: true });
      this.examineNgMarkerClasses(element);
    } else {
      for (let i = element.children.length; i-- > 0; ) {
        const child = element.children[i];
        if (!(child instanceof Element)) {
          continue;
        }
        this.addFormElementsRecursively(child);
      }
    }
  }

  private removeFormElementsRecursively(element: Element): void {
    this.unwatchInvalidElement(element);
    if (this.elementIsFormMember(element)) {
      // this.mutationObserver.unobserve(element);
      // TODO There's no such thing as "unobserve"!
      // Is this going to be a problem, if we have forms adding and removing lots of elements?
    } else {
      for (let i = element.children.length; i-- > 0; ) {
        const child = element.children[i];
        if (!(child instanceof Element)) {
          continue;
        }
        this.removeFormElementsRecursively(child);
      }
    }
  }

  private elementIsFormMember(element: Element): boolean {
    if (
      element.getAttribute('formcontrolname')
      // TODO Checking for [formcontrolname] will not be 100% reliable.
      // I'm at a loss to figure out a better way. -sommea1
    ) {
      return true;
    }
    return false;
  }

  private examineNgMarkerClasses(element: Element): void {
    if (['div', 'form'].includes(element.tagName.toLowerCase())) return;
    if (element.classList.contains('ng-valid')) {
      this.elementIsValid(element);
    } else if (element.classList.contains('ng-invalid')) {
      this.elementIsInvalidControl(element);
    }
  }

  private elementIsValid(element: Element): void {
    element.setAttribute('error', '');
    this.unwatchInvalidElement(element);
  }

  private elementIsInvalidControl(element: Element): void {
    if (this.form) {
      const path = this.getFormPathForElement(element);
      const control = this.getControlForPathWithPossiblePrefix(this.form, path);
      if (control && control.errors) {
        const isRequiredError =
          this.errorMessageService.validationErrorsAreDueToIncomplete(
            control.errors
          );
        if (!this.submitted && isRequiredError) {
          this.elementIsValid(element);
          return;
        }
        const errMessage =
          this.errorMessageService.getErrorMessageFromValidationErrors(
            control.errors,
            (path[path.length - 1] || '') as FormName
          );
        element.setAttribute('error', errMessage);
        this.watchInvalidElement(element);
      }
    }
  }

  private getFormPathForElement(element: Element): string[] {
    const path: string[] = [];
    for (; element; element = element.parentNode as Element) {
      const name =
        element.getAttribute?.('formcontrolname') ||
        element.getAttribute?.('formgroupname') ||
        element.getAttribute?.('formarrayname');
      if (name) {
        path.splice(0, 0, name);
      }
    }
    return path;
  }

  private getControlForPathWithPossiblePrefix(
    form: AbstractControl,
    path: string[]
  ): FormControl | null {
    for (; path.length; path = path.slice(1)) {
      const control = form.get(path.join('.'));
      if (control) {
        return control as FormControl;
      }
    }
    return null;
  }

  private getErrorMessageForFormControl(
    control: AbstractControl,
    name: FormName
  ): string {
    if (control.valid || !control.errors) {
      return '';
    }

    return this.errorMessageService.getErrorMessageFromValidationErrors(
      control.errors,
      name
    );
  }

  private recheckInvalidElements(): void {
    for (const ie of this.invalidElements) {
      this.elementIsInvalidControl(ie.element);
    }
  }

  private watchInvalidElement(element: Element): void {
    if (this.invalidElements.find((e) => e.element === element)) {
      return;
    }
    const inputListener = () => {
      this.elementIsInvalidControl(element);
    };
    element.addEventListener('input', inputListener);
    element.addEventListener('blur', inputListener);
    const ie = {
      element,
      inputListener,
    };
    this.invalidElements.push(ie);
    this.recheckInvalidElements();
  }

  private unwatchInvalidElement(element: Element): void {
    for (let index = this.invalidElements.length; index-- > 0; ) {
      const ie = this.invalidElements[index];
      if (ie.element === element) {
        this.invalidElements.splice(index, 1);
        ie.element.removeEventListener('input', ie.inputListener);
        ie.element.removeEventListener('blur', ie.inputListener);
      }
    }
  }
}
