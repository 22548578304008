import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocationExposureAdapter } from '@core/adapters/location-exposure.adapter';
import {
  LocationExcludedExposureRequest,
  LocationExposureRequest,
} from '@core/models/api/request/location-exposure-request.model';
import { Store } from '@ngrx/store';
import {
  deleteLocationExposure,
  upsertLocationExposure,
} from '@entities/exposures/location-exposures/location-exposures.action';
import {
  LocationExposureAndProductInfo,
  LocationExposureEntity,
} from '@entities/exposures/location-exposures/location-exposures.entity';
import {
  buildLocationExposures,
  getLocationExposureOptions,
  getLocationExposureTiles,
  getLocationExposuresAndProductInfo,
  hasPrimaryLocationExposure,
  locationExposuresExist,
} from '@entities/exposures/location-exposures/location-exposures.selector';
import { Nullable } from '@shared/utils/type.utils';
import { LocationExposureOptionsModel } from '@entities/metadata/models/umbrella-options.model';
import {
  LocationExcludedExposureAndProductInfo,
  LocationExcludedExposureEntity,
} from '@entities/exposures/location-excluded-exposures/location-excluded-exposures.entity';
import {
  getLocationExcludedExposureTiles,
  getLocationExcludedExposuresAndProductInfo,
} from '@entities/exposures/location-excluded-exposures/location-excluded-exposures.selector';
import {
  deleteLocationExcludedExposure,
  upsertLocationExcludedExposure,
} from '@entities/exposures/location-excluded-exposures/location-excluded-exposures.action';
import { UmbrellaTileModel } from '@shared/components/umbrella-tile/umbrella-tile.component';

@Injectable({
  providedIn: 'root',
})
export class LocationExposureService {
  constructor(private adapter: LocationExposureAdapter, private store: Store) {}

  addOrUpdateLocationExposure(
    request: LocationExposureRequest
  ): Observable<LocationExposureEntity> {
    if (request.locationExposureBody.locationExposure.locationExposureId) {
      return this.adapter.updateLocationExposure(request);
    } else {
      return this.adapter.addLocationExposure(request);
    }
  }

  upsertLocationExposure(locationExposure: LocationExposureEntity): void {
    this.store.dispatch(upsertLocationExposure({ payload: locationExposure }));
  }

  removeLocationExposure(locationExposure: LocationExposureEntity): void {
    this.store.dispatch(deleteLocationExposure({ payload: locationExposure }));
  }

  deleteLocationExposure(request: LocationExposureRequest): Observable<unknown> {
    return this.adapter.removeLocationExposure(request);
  }

  getLocationExposuresAndProductInfo(): Observable<
    LocationExposureAndProductInfo[]
  > {
    return this.store.select(getLocationExposuresAndProductInfo);
  }

  getLocationExposureTiles(): Observable<UmbrellaTileModel[]> {
    return this.store.select(getLocationExposureTiles);
  }

  getLocationExposureOptions(): Observable<
    Nullable<LocationExposureOptionsModel>
  > {
    return this.store.select(getLocationExposureOptions);
  }

  addOrUpdateLocationExcludedExposure(
    request: LocationExcludedExposureRequest
  ): Observable<LocationExcludedExposureEntity> {
    if (
      request.locationExcludedExposureBody.locationExcludedExposure
        .locationExcludedExposureId
    ) {
      return this.adapter.updateLocationExcludedExposure(request);
    } else {
      return this.adapter.addLocationExcludedExposure(request);
    }
  }

  upsertLocationExcludedExposure(
    locationExcludedExposure: LocationExcludedExposureEntity
  ): void {
    this.store.dispatch(
      upsertLocationExcludedExposure({ payload: locationExcludedExposure })
    );
  }

  removeLocationExcludedExposure(
    locationExposure: LocationExcludedExposureEntity
  ): void {
    this.store.dispatch(
      deleteLocationExcludedExposure({ payload: locationExposure })
    );
  }

  deleteLocationExcludedExposure(
    request: LocationExcludedExposureRequest
  ): Observable<unknown> {
    return this.adapter.removeLocationExcludedExposure(request);
  }

  getLocationExcludedExposuresAndProductInfo(): Observable<
    LocationExcludedExposureAndProductInfo[]
  > {
    return this.store.select(getLocationExcludedExposuresAndProductInfo);
  }

  getLocationExcludedExposureTiles(): Observable<UmbrellaTileModel[]> {
    return this.store.select(getLocationExcludedExposureTiles);
  }

  buildLocationExposures(): Observable<LocationExposureEntity[]> {
    return this.store.select(buildLocationExposures);
  }

  locationExposuresExist(): Observable<boolean> {
    return this.store.select(locationExposuresExist);
  }

  hasPrimaryLocationExposure(): Observable<boolean> {
    return this.store.select(hasPrimaryLocationExposure);
  }
}
