import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfigService } from "@core/services/app-config.service";
import { Store } from "@ngrx/store";
import { StringUtils } from "@shared/utils/string.utils";
import { combineLatest, Observable, of, throwError } from "rxjs";
import { map, take, tap, switchMap, catchError } from "rxjs/operators";
import { getTargetEnv } from "@core/store/entities/dsm/dsm.selector";
import { getAccessToken } from '@core/store/entities/session/session.selector';
import { LogService } from "@core/services/log.service";

export type CompraterRoutingDestination = 'NWX' | 'PC' | null;

export type CompraterRoutingResponse = {
  comparativeQuoteId: string;
  destination: CompraterRoutingDestination;
}[];

@Injectable({
  providedIn: 'root',
})
export class CompraterRoutingAdapter {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private store: Store,
    private logService: LogService
  ) {}

  fetchCompraterRouting(quoteId: string): Observable<CompraterRoutingResponse> {
    return of([{ comparativeQuoteId: quoteId, destination: 'NWX' }]);
    /* XXX CODEMINERS-5183. If we decide to reenable this feature, just uncomment this block.
    const url = `${this.appConfigService.config.compraterRoutingUrl}/${quoteId}`;
    const correlationId = this.logService.logApiRequest('GET', url, 'get-comprater-routing');
    return this.generateHeaders().pipe(
      switchMap((headers) => this.httpClient.get(url, { headers })),
      tap((response) => this.logService.logApiResponse(correlationId, 200, response)),
      map((response) => this.sanitizeResponse(response)),
      catchError((error) => this.logService.logApiError(correlationId, error))
    );
    /**/
  }
  
  private generateHeaders(): Observable<HttpHeaders> {
    return combineLatest([
      this.store.select(getAccessToken),
      this.store.select(getTargetEnv),
    ]).pipe(
      take(1),
      map(([accessToken, targetEnv]) => {
        let headers = new HttpHeaders()
          .set('Authorization', `Bearer ${accessToken}`)
          .set('client_id', this.appConfigService.config.apiKey)
          .set('X-NW-Message-ID', StringUtils.generateUuid());
        if (targetEnv) {
          headers = headers.set('X-NW-Target-Env', targetEnv);
        }
        return headers;
      })
    );
  }

  private sanitizeResponse(fromRemote: any): CompraterRoutingResponse {
    const response: CompraterRoutingResponse = [];
    if (fromRemote instanceof Array) {
      for (const fromRemote1 of fromRemote) {
        if (!fromRemote1 || typeof fromRemote1 !== 'object') {
          continue;
        }
        response.push({
          comparativeQuoteId: StringUtils.requireString(fromRemote1.comparativeQuoteId),
          destination: StringUtils.requireChoice(fromRemote1.destination, [null, 'NWX', 'PC']),
        });
      }
    }
    return response;
  }
}
