import { ProductType } from '@core/models/api/dsm-types';
import {
  ScheduledCategoryEntity,
  sanitizeScheduledCategoryEntity,
} from '../scheduled-categories/scheduled-category.entity';
import { ObjectValidator } from '@core/helper/object-validator';
export interface SelectedValue {
  code: string;
  value: string;
  description?: string;
}

export interface CoverageOption {
  description: string;
  value: string;
  priority: string;
  selected?: boolean;
  optional?: boolean;
}

export interface CoverageTerm {
  description: string;
  type: string;
  code: string;
  spreadable: boolean;
  options: CoverageOption[];
  editable?: boolean;
  mandatory?: boolean;
  helpText?: string;
  instructionalText?: string;
  isDummyTerm?: boolean; // non-dsm
}

export interface TermAmount {
  amount: number;
  currency: string;
}

export function sanitizeTermAmount(input: unknown): TermAmount {
  return ObjectValidator.forceSchema<TermAmount>(
    input,
    {
      amount: 'number',
      currency: 'string',
    },
    []
  );
}

export interface CoverageCost {
  actualTermAmount?: TermAmount;
  included?: string;
}

export type CoverageLevelTypes = 'Policy' | 'Vehicle' | 'Dwelling';

export interface CoveragesResponse {
  packageName: string;
  coverages: CoverageEntity[];
  productId: ProductType;
}

export interface CoverageEntity {
  // change to productType
  productId: ProductType;
  version?: string;
  terms: CoverageTerm[];
  selectedValue: SelectedValue[];
  name: string;
  description: string;
  coverageCost?: CoverageCost[];
  coverageId: string;
  coverableId?: string;
  coverageLevel: CoverageLevelTypes;
  editable: boolean;
  mandatory?: boolean;
  hasError?: boolean;
  available: boolean;
  errorId?: string;
  helpText?: string;
  reminderHelpText?: string;
  isEditableAndTermUneditable?: boolean;
  coverageWithScheduledItems?: boolean; // scheduled personal effects
  hasDummyTerms?: boolean;
  scheduledPersonalEffects?: ScheduledCategoryEntity[];
  coverageWithDeclinableInputLimit?: boolean;
}

export interface CoverageChange {
  productId?: ProductType;
  selectedValue: CoverageSelectedValue[];
  coverageId: string;
  coverageLevel?: string;
  coverableId?: number;
}

export interface CoverageSelectedValue {
  code: string;
  value: string;
}

export interface CoverageInformationalMessage {
  errors?: CoverageError[];
  warnings?: CoverageWarning[];
  productId: ProductType;
}

export interface CoverageError extends CoverageMessage {
  level?: string;
}

export interface CoverageWarning extends CoverageMessage {
  entity: WarningEntity;
}

export interface CoverageMessage {
  message: string;
  ruleId?: string;
  productId: ProductType;
}

export interface WarningEntity {
  coverableId?: number;
  coverableType?: string;
  coverageCode: string;
  displayName?: string;
  entity?: string;
}

export interface QuoteLetterCoverages {
  Name: string;
  LimitOrDed: string;
}

export function sanitizeCoveragesResponses(
  input: unknown
): CoveragesResponse[] {
  return ObjectValidator.sanitizeArray(input, sanitizeCoveragesResponse);
}

export function sanitizeCoveragesResponse(input: unknown): CoveragesResponse {
  return ObjectValidator.forceSchema<CoveragesResponse>(input, {
    packageName: 'string',
    coverages: [sanitizeCoverageEntity],
    productId: 'string',
  });
}

export function sanitizeCoverageEntity(input: unknown): CoverageEntity {
  return ObjectValidator.forceSchema<CoverageEntity>(
    input,
    {
      productId: 'string',
      version: 'string',
      terms: [sanitizeCoverageTerm],
      selectedValue: [sanitizeSelectedValue],
      name: 'string',
      description: 'string',
      coverageCost: [sanitizeCoverageCost],
      coverageId: 'string',
      coverableId: 'string',
      coverageLevel: 'string',
      editable: 'boolean',
      mandatory: 'boolean',
      hasError: 'boolean',
      available: 'boolean',
      errorId: 'string',
      helpText: 'string',
      reminderHelpText: 'string',
      isEditableAndTermUneditable: 'boolean',
      coverageWithScheduledItems: 'boolean',
      hasDummyTerms: 'boolean',
      scheduledPersonalEffects: [sanitizeScheduledCategoryEntity],
    },
    []
  );
}

export function sanitizeCoverageTerm(input: unknown): CoverageTerm {
  return ObjectValidator.forceSchema<CoverageTerm>(
    input,
    {
      description: 'string',
      type: 'string',
      code: 'string',
      spreadable: 'boolean',
      options: [sanitizeCoverageOption],
      editable: 'boolean',
      mandatory: 'boolean',
      helpText: 'string',
      instructionalText: 'string',
      isDummyTerm: 'boolean',
    },
    []
  );
}

export function sanitizeCoverageOption(input: unknown): CoverageOption {
  return ObjectValidator.forceSchema<CoverageOption>(
    input,
    {
      description: 'string',
      value: 'string',
      priority: 'string',
      selected: 'boolean',
      optional: 'boolean',
    },
    []
  );
}

export function sanitizeSelectedValue(input: unknown): SelectedValue {
  return ObjectValidator.forceSchema<SelectedValue>(
    input,
    {
      code: 'string',
      value: 'string',
      description: 'string',
    },
    []
  );
}

export function sanitizeCoverageCost(input: unknown): CoverageCost {
  return ObjectValidator.forceSchema<CoverageCost>(
    input,
    {
      actualTermAmount: {
        amount: 'number',
        currency: 'string',
      },
      included: 'string',
    },
    ['actualTermAmount']
  );
}

export function sanitizeCoverageInformationalMessage(
  input: unknown
): CoverageInformationalMessage {
  return ObjectValidator.forceSchema<CoverageInformationalMessage>(
    input,
    {
      errors: [sanitizeCoverageError],
      warnings: [sanitizeCoverageWarning],
      productId: 'string',
    },
    ['errors', 'warnings']
  );
}

export function sanitizeCoverageError(input: unknown): CoverageError {
  return ObjectValidator.forceSchema<CoverageError>(
    input,
    {
      message: 'string',
      ruleId: 'string',
      productId: 'string',
      level: 'string',
    },
    []
  );
}

export function sanitizeCoverageWarning(input: unknown): CoverageWarning {
  return ObjectValidator.forceSchema<CoverageWarning>(
    input,
    {
      message: 'string',
      ruleId: 'string',
      productId: 'string',
      entity: {
        coverableId: 'number',
        coverableType: 'string',
        coverageCode: 'string',
        displayName: 'string',
        entity: 'string',
      },
    },
    ['entity']
  );
}
