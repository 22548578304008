import { Component, ElementRef, Input } from "@angular/core";

/** Looks like bolt-checkbox or bolt-radio, but not interactive.
 * For things like product-tile, where we're asked to display a checkbox but in fact the whole tile is the input element.
 */
@Component({
  selector: 'nwx-decorative-bolt-checkbox',
  templateUrl: './decorative-bolt-checkbox.component.html',
  styleUrls: ['./decorative-bolt-checkbox.component.scss'],
})
export class DecorativeBoltCheckboxComponent {
  @Input() type: 'checkbox' | 'radio' = 'checkbox';
  @Input() checked = false;
  @Input() disabled = false;

  constructor(
    public element: ElementRef
  ) {}
}
