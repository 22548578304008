<div [ngClass]="{ locked: options.disabled }">
  <label
    [for]="labelForId || componentId"
    class="custom-label"
    *ngIf="!removeLabel"
  >
    {{ labelText }}
    <bolt-contextual-help
      *ngIf="contextHelpHeading && contextHelpText"
      [heading]="contextHelpHeading"
    >
      <p>{{ contextHelpText }}</p>
    </bolt-contextual-help>
  </label>
  <ng-select
    #dropdownElem
    [class.has-error]="isInvalid === true"
    [id]="componentId"
    [items]="itemList"
    [searchable]="!!settings?.enableSearchFilter"
    [placeholder]="settings?.text"
    [ngModelOptions]="{ standalone: true }"
    [settings]="settings"
    [loading]="loading"
    [labelForId]="labelForId"
    [bindValue]="bindValue"
    [clearable]="clearable"
    [disabled]="settings.disabled"
    (change)="onChangeSelection($event)"
    (open)="onPicklistOpened($event)"
    (close)="onPicklistClosed($event)"
  >
  </ng-select>
  <span *ngIf="helpText" class="faux-bolt-help-text">{{ helpText }}</span>
  <div class="faux-bolt-error" *ngIf="isInvalid === true">
    <img
      class="faux-bolt-error-icon error-img"
      src="assets/images/icons/ngSelect-faux-bolt-error.svg"
      alt="error icon"
      name="error icon image"
    />
    <em class="error-text">{{ invalidFeedbackText }}</em>
  </div>
</div>
