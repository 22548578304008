import { DateUtils } from './date.utils';
import { DriverIncidentEntity } from '@core/models/entities/driver-incident.entity';
import { MemberModel } from '@core/models/views/person.model';
import { ProductModel } from '@core/store/entities/product/product.model';
import { VehicleEntity } from '@core/store/entities/vehicle/vehicle.entity';
import { ProductType } from '@core/models/api/dsm-types';
import { PriceSummaryDisplayProduct } from '@app/hub/components/price-summary-display/price-summary-display-content';
import { CoverageDisplayProduct } from '@core/models/views/coverage.model';
import { ValidatorFn, Validators } from '@angular/forms';
import { Nullable } from './type.utils';

export type ComponentChange<T, P extends keyof T> = {
  previousValue: T[P];
  currentValue: T[P];
  firstChange: boolean;
};

export type ComponentChanges<T> = {
  [P in keyof T]?: ComponentChange<T, P>;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export class GeneralUtils {

  static sortProductsForDisplay(products: ProductModel[]): ProductModel[] {
    const preferredOrder = [
      'PersonalAuto',
      'MSA',
      'Boat',
      'RV',
      'PersonalUmbrella',
      'Homeowner',
      'Tenant',
      'Condominium',
      'DwellingFire',
    ];
    const sorted = [...products];
    sorted.sort(
      (a, b) => preferredOrder.indexOf(a.type) - preferredOrder.indexOf(b.type)
    );
    return sorted;
  }

  static sortProducts<T>(products: T[], key: keyof T): T[] {
    const preferredOrder: unknown[] = [
      'PersonalAuto',
      'MSA',
      'Boat',
      'RV',
      'Homeowner',
      'Tenant',
      'Condominium',
      'DwellingFire',
      'PersonalUmbrella',
    ];
    const sorted = [...products];
    sorted.sort(
      (a, b) =>
        preferredOrder.indexOf(a[key] || '') -
        preferredOrder.indexOf(b[key] || '')
    );
    return sorted;
  }

  static sortPriceSummaryDisplayProducts(
    products: PriceSummaryDisplayProduct[]
  ): PriceSummaryDisplayProduct[] {
    return GeneralUtils.sortProducts(products, 'type');
  }

  static sortCoverageDisplayProducts(
    products: CoverageDisplayProduct[]
  ): CoverageDisplayProduct[] {
    return GeneralUtils.sortProducts(products, 'productId');
  }

  static createPersonElementId(entityId: string, elementId?: string): string {
    return `${elementId}-${entityId}`;
  }

  static createVehicleElementId(
    vehicle: VehicleEntity,
    elementId?: string
  ): string {
    return `${elementId}-${vehicle.vehicleId}`;
  }

  /**
   * Parses an object for attribute key, and returns the value
   * @param obj the object to parse
   * @param key the key of the attribute to search for
   */
  static findAttributeValue(obj: any, key: string, recursionLimit = 20): any {
    if (recursionLimit <= 0) {
      return null;
    }
    if (!this.isObject(obj)) {
      return null;
    }
    if (obj[key] !== null && obj[key] !== undefined) {
      return obj[key];
    } else {
      const objectKeys = Object.keys(obj);
      for (let i = 0; i < objectKeys.length; i++) {
        const keyToParse = objectKeys[i];
        if (obj[keyToParse] && typeof obj[keyToParse] == 'object') {
          const matchingKey = this.findAttributeValue(obj[keyToParse], key, recursionLimit - 1);
          if (matchingKey !== null) {
            return matchingKey;
          }
        }
      }
      return null;
    }
  }

  static deepEqual(object1: any, object2: any): boolean {
    if (this.isObject(object1) !== this.isObject(object2)) {
      return false;
    }
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        (areObjects && !this.deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }
    return true;
  }

  static getEventValue(event: Event): any {
    return (event.target as HTMLInputElement)?.value;
  }

  static didArrayAttributesChange<T>(
    entity: string,
    attribute: keyof T,
    index: number,
    changes: ComponentChanges<any>
  ): boolean {
    const change = changes[entity];
    if (!change || change.firstChange) {
      return false;
    }
    const current = change.currentValue[index]?.[attribute];
    const previous = change.previousValue[index]?.[attribute];
    return this.isObject(current)
      ? !this.deepEqual(current, previous)
      : current !== previous;
  }

  static didAttributesChange<T>(
    entity: string,
    attribute: keyof T,
    changes: ComponentChanges<any>
  ): boolean {
    const change = changes[entity];
    if (!change || change.firstChange) {
      return false;
    }
    const current = change.currentValue?.[attribute];
    const previous = change.previousValue?.[attribute];
    return this.isObject(current)
      ? !this.deepEqual(current, previous)
      : current !== previous;
  }

  /**
   * Finds whether the provided value is empty or not.
   *
   * It will return true if the pvovided value is any of the following:
   *   - empty object
   *   - empty array
   *   - empty string
   *   - null
   *   - undefined
   * @param value any
   * @returns boolean
   */
  static isEmpty(value: any): boolean {
    if (this.isObject(value)) return Object.keys(value).length === 0;

    return value === '' || value === undefined || value === null;
  }

  private static isObject(object: any): boolean {
    return object != null && typeof object === 'object';
  }

  static isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  static isNumber(value: any): boolean {
    return !isNaN(value);
  }

  /**
   * An empty array is equivalent to null and undefined.
   */
  static arraysEquivalent<T>(
    a: T[] | null | undefined,
    b: T[] | null | undefined,
    cmp: (aa: T, bb: T) => boolean
  ): boolean {
    if (a === b) {
      return true;
    }
    if (!a?.length) {
      a = null;
    }
    if (!b?.length) {
      b = null;
    }
    if (!a && !b) {
      return true;
    }
    if (!a || !b) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      if (!cmp(a[i], b[i])) {
        return false;
      }
    }
    return true;
  }

  static pureReplace<T>(items: T[], changes: Partial<T>, i: number): T[] {
    const updatedItem = { ...items[i], ...changes };
    const newitemsArray = [
      ...items.slice(0, i),
      updatedItem,
      ...items.slice(++i),
    ] as T[];

    return newitemsArray;
  }

  static booleanFromStringOrPassThrough<T extends unknown>(
    value: T
  ): T | boolean {
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
    return value;
  }

  static parseBooleanFromString(
    value: Nullable<string> | boolean | undefined
  ): boolean | undefined {
    if (typeof value === 'boolean') {
      return value;
    }
    return value === 'true' ? true : value === 'false' ? false : undefined;
  }

  static parseStringFromBoolean(
    value: string | Nullable<boolean> | undefined
  ): 'true' | 'false' | undefined {
    if (value === 'true' || value === 'false') {
      return value;
    }
    return value === true ? 'true' : value === false ? 'false' : undefined;
  }

  static addRequiredValidator(
    isRequired: boolean,
    otherValidators: ValidatorFn[]
  ): ValidatorFn[] {
    return isRequired
      ? [...otherValidators, Validators.required]
      : otherValidators;
  }

  static containsEmptyObject(arr: Nullable<any[]>): boolean {
    return arr?.some((item) => GeneralUtils.isEmpty(item)) || false;
  }

  /**
   * @param arr the array of strings to format
   * @returns a string in the format "arr[0], arr[1], ..., and arr[n]"
   */
  static formatArrayWithCommas(arr: string[]): string {
    let result = '';
    const lastEl = arr.length - 1;
    if (arr.length === 1) {
      return arr[0];
    }
    if (arr.length === 2) {
      return `${arr[0]} and ${arr[1]}`;
    }
    arr.forEach((el, index) => {
      // add a comma after all but the last element
      if (index !== lastEl) {
        result += `${el}, `;
      } else {
        result += `and ${el}`;
      }
    });
    return result;
  }

  static setTitlecase(txt: string | undefined): string {
    if (txt) {
      return txt.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      });
    }
    return '';
  }

  static deleteUndefinedMembers<I extends O, O extends { [key: string]: any }>(input: I): O {
    if (input && typeof(input) === 'object') {
      let output: O | null = null;
      for (const key of Object.keys(input)) {
        if (input[key] === undefined) {
          if (!output) {
            output = {...input};
          }
          delete output[key];
        }
      }
      if (output) {
        return output;
      }
    }
    return input;
  }
}
