import { Component } from '@angular/core';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { getErrors } from '@core/store/entities/error/error.selector';
import { Store } from '@ngrx/store';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'nwx-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent {
  errors$: Observable<Nullable<ErrorModel[]>>;
  constructor(private store: Store) {
    this.errors$ = of(null);
  }
}
