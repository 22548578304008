import { Injectable } from '@angular/core';
import { UWReportsAdapter } from '@core/adapters/uw-reports.adapter';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of, OperatorFunction } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { Update } from '@ngrx/entity';
import { ProductModel } from '../product/product.model';
import { getProduct, getSelectedProducts } from '../product/product.selectors';
import { rateQuote } from '../quote/quote.action';
import { getHomeownersUWReports } from './uw-reports.selector';
import { MsbReportDetails } from '@core/models/api/response/uw-reports-response.model';
import {
  UWReportsActions,
  ProductActions,
  CoveredLocationActions,
  QuoteActions,
} from '@core/store/actions';
import { ProductType } from '@core/models/api/dsm-types';

@Injectable({
  providedIn: 'root',
})
export class UWReportsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private errorSanitizerService: ErrorSanitizerService,
    private adapter: UWReportsAdapter
  ) {}

  refreshUWReportsByProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UWReportsActions.refreshUWReportsByProduct),
      switchMap((action) =>
        this.store.select(getProduct(action.productType)).pipe(take(1))
      ),
      filter(
        (product) => !!product?.quoteId && !!product?.uwReportsImplemented
      ) as OperatorFunction<ProductModel | undefined, ProductModel>,
      map((product) =>
        UWReportsActions.getUWReports({
          quoteId: product.quoteId as string,
          product: product.type,
        })
      )
    )
  );

  refreshUwReportsAfterBindRating$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActions.rateBindSuccess),
      mergeMap((action) =>
        this.store.select(getProduct(action.productType)).pipe(take(1))
      ),
      filter(
        (product) => !!product?.quoteId && !!product?.uwReportsImplemented
      ) as OperatorFunction<ProductModel | undefined, ProductModel>,
      map((product) =>
        UWReportsActions.getUWReports({
          quoteId: product.quoteId as string,
          product: product.type,
        })
      )
    )
  );

  getUWReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UWReportsActions.getUWReports),
      mergeMap((action) => {
        return this.adapter.getUWReports(action.quoteId, action.product).pipe(
          switchMap((response) => {
            const productUpdate: Update<ProductModel> = {
              id: action.product,
              changes: { uwReportsLoaded: true } as ProductModel,
            };
            return from([
              ProductActions.updateProduct({
                payload: productUpdate,
              }),
              UWReportsActions.getUWReportsSuccess({
                payload: response,
                product: action.product,
              }),
            ]);
          }),
          catchError((error) => {
            return from([
              UWReportsActions.getUWReportsFail({
                error: this.errorSanitizerService.sanitizeError(
                  error,
                  action.product
                ),
              }),
            ]);
          })
        );
      })
    )
  );

  /* DSM's uw-reports call has the unintended side effect of resetting the status of "Binding" quotes.
   */
  // CK - Should this have been removed?
  // rerateBoundProductsAfterGettingReports$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UWReportsActions.getUWReportsSuccess),
  //     withLatestFrom(this.store.select(getSelectedProducts)),
  //     map(([action, products]) =>
  //       products.find((product) => product.type === action.product)
  //     ),
  //     filter(
  //       (product) => product?.quoteStatus === 'Binding'
  //     ) as OperatorFunction<ProductModel | undefined, ProductModel>,
  //     map((product) =>
  //       rateQuote({
  //         productType: product.type,
  //         ratingType: 'bind',
  //       })
  //     )
  //   )
  // );

  resetUWReportsLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.updateSelectedProducts),
      mergeMap(() => of(UWReportsActions.resetUWReportsLoaded()))
    )
  );

  getMSBEstimate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UWReportsActions.getMSBEstimate),
      exhaustMap((action) =>
        this.store.select(getProduct(action.payload)).pipe(take(1))
      ),
      mergeMap((product) => {
        return this.adapter
          .getMSBEstimate(
            product?.quoteId as string,
            product?.type as ProductType
          )
          .pipe(
            switchMap((response) => {
              return from([
                UWReportsActions.getMSBEstimateSuccess({
                  payload: {
                    ...response,
                    productType: product?.type as ProductType,
                  },
                }),
                CoveredLocationActions.getCoveredLocation({
                  payload: product?.type as ProductType,
                }),
              ]);
            }),
            catchError((error) => {
              return from([
                UWReportsActions.getMSBEstimateFail({
                  error: this.errorSanitizerService.sanitizeError(
                    error,
                    product?.type
                  ),
                }),
              ]);
            })
          );
      })
    )
  );

  getMSBLaunchUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UWReportsActions.getMSBLaunchUrl),
      withLatestFrom(this.store.select(getHomeownersUWReports)),
      mergeMap(([action, uwReports]) => {
        return this.adapter
          .getMSBLaunchUrl(action.quoteId, action.productType)
          .pipe(
            switchMap((response) => {
              let payload = {} as MsbReportDetails;
              const report = uwReports.find(
                (report) => report.reportSummaryId === 'MSB'
              );
              if (report) {
                payload = {
                  estimateNumber: report.msbReportDetails?.estimateNumber || '',
                  reportHref: response.referenceReportHref,
                };
                return from([
                  UWReportsActions.getMSBLaunchUrlSuccess({
                    payload,
                  }),
                ]);
              } else {
                // TODO - This is absolute garbage and I'm sorry to do it. Planning to fix for good soon
                payload = {
                  estimateNumber: '0',
                  reportHref: response.referenceReportHref,
                };
                return from([
                  UWReportsActions.storeUWReports({
                    product: action.productType,
                    payload: [
                      { reportSummaryId: 'MSB', msbReportDetails: payload },
                    ],
                  }),
                  UWReportsActions.getMSBLaunchUrlSuccess({
                    payload,
                  }),
                ]);
              }
            }),
            catchError((error) => {
              return from([
                UWReportsActions.getMSBLaunchUrlFail({
                  error: this.errorSanitizerService.sanitizeError(
                    error,
                    action.productType
                  ),
                }),
              ]);
            })
          );
      })
    )
  );
}
