import { ProductType } from '@core/models/api/dsm-types';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { from, of } from 'rxjs';
import {
  switchMap,
  take,
  catchError,
  concatMap,
  mergeMap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromSelectors from '@core/store/entities/policy-number/policy-number.selector';
import {
  generatePolicyNumber,
  generatePolicyNumberFail,
  generatePolicyNumberSuccess,
} from './policy-number.action';
import { ProductModel } from '../product/product.model';
import { PolicyNumberService } from '@core/services/policy-number.service';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';

@Injectable()
export class PolicyNumberEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private policyNumberService: PolicyNumberService,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  readonly GENERATE_POLICY_DEBOUNCE = 500;

  generatePolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generatePolicyNumber),
      switchMap(() =>
        this.store.select(fromSelectors.buildPolicyNumberRequests).pipe(take(1))
      ),
      mergeMap((requests) => from(requests)),
      concatMap((request) => {
        return this.policyNumberService.generatePolicyNumber(request).pipe(
          mergeMap((response) => {
            const productModel: ProductModel = {
              type: request.productType as ProductType,
              policyNumber: response.policyNumber,
              quoteId: response.quoteId,
              quoteStatus: response.quoteStatus || 'Draft',
            };
            return of(generatePolicyNumberSuccess({ response: productModel }));
          }),
          catchError((error) => {
            return of(
              generatePolicyNumberFail({
                response: this.errorSanitizerService.sanitizeError(error, request.productType),
                product: request.productType,
              })
            );
          })
        );
      })
    )
  );
}
