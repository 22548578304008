import { Injectable } from '@angular/core';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, from, of, combineLatest } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  CompositeActions,
  UmbrellaActions,
  UnderlyingPolicyActions,
  VehicleExcludedExposureActions,
  VehicleExposureActions,
} from '@core/store/actions';
import {
  VehicleExcludedExposureSelectors,
  VehicleExposureSelectors,
} from '@core/store/selectors';
import { VehicleExposureService } from '@core/services/vehicle-exposure.service';

@Injectable()
export class VehicleExposureEffect {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: VehicleExposureService,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  upsertVehicleExposure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehicleExposureActions.upsertVehicleExposure),
        mergeMap((action) =>
          this.store
            .select(
              VehicleExposureSelectors.buildVehicleExposureRequest(
                action.payload,
                action.correlationId || ''
              )
            )
            .pipe(take(1))
        ),
        mergeMap((request) => {
          return this.service.addOrUpdateVehicleExposure(request).pipe(
            mergeMap((response) => {
              return from([
                VehicleExposureActions.upsertVehicleExposureSuccess({
                  payload: response,
                }),
              ]);
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                request.productType
              );
              return of(
                VehicleExposureActions.upsertVehicleExposureFail({
                  error: saneError,
                  entityId:
                    request.vehicleExposureBody.vehicleExposure
                      .vehicleExposureId,
                })
              );
            })
          );
        })
      ) as Observable<Action>
  );

  deleteVehicleExposure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehicleExposureActions.deleteVehicleExposure),
        mergeMap((action) =>
          this.store
            .select(
              VehicleExposureSelectors.buildVehicleExposureRequest(
                action.payload,
                action.correlationId || ''
              )
            )
            .pipe(take(1))
        ),
        mergeMap((request) => {
          return this.service.deleteVehicleExposure(request).pipe(
            mergeMap(() => {
              return from([
                VehicleExposureActions.deleteVehicleExposureSuccess({
                  vehicleExposureId:
                    request.vehicleExposureBody.vehicleExposure
                      .vehicleExposureId,
                }),
              ]);
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                request.productType
              );
              return of(
                VehicleExposureActions.deleteVehicleExposureFail({
                  error: saneError,
                  entityId:
                    request.vehicleExposureBody.vehicleExposure
                      .vehicleExposureId,
                })
              );
            })
          );
        })
      ) as Observable<Action>
  );

  restoreVehicleExposure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UmbrellaActions.restoreExposures),
      exhaustMap(() =>
        combineLatest([
          this.store.select(
            VehicleExposureSelectors.getDeletedVehicleExposuresWithActivePolicy
          ),
          this.store.select(
            VehicleExcludedExposureSelectors.getDeletedVehicleExcludedExposures
          ),
        ]).pipe(take(1))
      ),
      mergeMap(([exposures, excludedExposures]) => {
        const exposureActions: Action[] = [];
        exposures.forEach((exposure) => {
          exposureActions.push(
            VehicleExposureActions.upsertVehicleExposure({
              payload: {
                ...exposure,
                vehicleExposureId: '',
              },
            })
          );
          exposureActions.push(
            VehicleExposureActions.completelyRemoveVehicleExposure({
              vehicleExposureId: exposure.vehicleExposureId,
            })
          );
        });
        excludedExposures.forEach((exposure) => {
          exposureActions.push(
            VehicleExcludedExposureActions.upsertVehicleExcludedExposure({
              payload: {
                ...exposure,
                vehicleExcludedExposureId: '',
              },
            })
          );
          exposureActions.push(
            VehicleExcludedExposureActions.completelyRemoveVehicleExcludedExposure(
              {
                vehicleExcludedExposureId: exposure.vehicleExcludedExposureId,
              }
            )
          );
        });
        return exposureActions;
      })
    )
  );

  cascadeDeleteVehicleExposure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UnderlyingPolicyActions.deleteUnderlyingPolicySuccess),
        exhaustMap((action) =>
          this.store
            .select(
              VehicleExposureSelectors.getVehicleExposuresByUnderlyingPolicyNumber(
                action.underlyingPolicyNumber
              )
            )
            .pipe(take(1))
        ),
        exhaustMap((vehicleExposures) => {
          return vehicleExposures.map((exposure) => {
            return VehicleExposureActions.deleteVehicleExposureSuccess({
              vehicleExposureId: exposure.vehicleExposureId,
            });
          });
        })
      ) as Observable<Action>
  );

  buildAccompanyingVehicleExposures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnderlyingPolicyActions.buildAccompanyingExposures),
      filter(
        (action) =>
          action.productType === 'PersonalAuto' ||
          action.productType === 'MSA' ||
          action.productType === 'RV'
      ),
      exhaustMap((action) => {
        return this.store
          .select(VehicleExposureSelectors.buildVehicleExposures)
          .pipe(
            map((exposures) => {
              return {
                exposures,
                accompanyingPolicyNumber: action.quoteId,
              };
            }),
            catchError(() => EMPTY)
          )
          .pipe(take(1));
      }),
      exhaustMap((policy) => {
        const vehicleExposuresArray: Action[] = [];
        policy.exposures.forEach((exposure) => {
          if (
            exposure.underlyingPolicyNumber === policy.accompanyingPolicyNumber
          ) {
            vehicleExposuresArray.push(
              VehicleExposureActions.upsertVehicleExposure({
                payload: {
                  ...exposure,
                  vehicleExposureId: '',
                },
              })
            );
          }
        });
        return from(vehicleExposuresArray);
      })
    )
  );

  cascadeRestoreVehicleExposures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.restoreExposure),
      exhaustMap((action) =>
        this.store
          .select(
            VehicleExposureSelectors.getDeletedVehicleExposuresByUnderlyingPolicyNumber(
              action.payload.underlyingPolicyNumber
            )
          )
          .pipe(take(1))
      ),
      exhaustMap((vehicleExposures) => {
        const vehicleExposuresArray: Action[] = [];
        vehicleExposures.forEach((exposure) => {
          vehicleExposuresArray.push(
            VehicleExposureActions.upsertVehicleExposure({
              payload: {
                ...exposure,
                vehicleExposureId: '',
              },
            })
          );
          vehicleExposuresArray.push(
            VehicleExposureActions.completelyRemoveVehicleExposure({
              vehicleExposureId: exposure.vehicleExposureId,
            })
          );
        });
        return from(vehicleExposuresArray);
      })
    )
  );
}
