import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import {
  ExtendedTelematicsVehicle,
  MobileEnrollment,
  VehicleEnrollment,
} from '@core/store/entities/telematics/telematics.model';
import { Nullable } from '@shared/utils/type.utils';

@Component({
  selector: 'nwx-smartride-instant-enrollment',
  templateUrl: './smartride-instant-enrollment.component.html',
  styleUrls: [
    './smartride-instant-enrollment.component.scss',
    './../telematics/telematics.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartrideInstantEnrollmentComponent implements OnInit {
  @Input() vehicles: ExtendedTelematicsVehicle[] = [];
  @Input() quoteState!: string;
  @Input() enrollment: Nullable<VehicleEnrollment | MobileEnrollment>;

  constructor() {}

  ngOnInit(): void {}
}
