import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProductType } from '../models/api/dsm-types';
import { EstablishEscrowAccountRequest } from '../models/api/request/establish-escrow-account-request.model';
import { EscrowAccountStatus } from '../models/entities/escrow-account.entity';
import { establishEscrowAccount } from '../store/entities/escrow/escrow.action';
import {
  buildEstablishEscrowAccountRequest,
  isEscrowOnlyPaymentMethod,
  selectEscrowSelection,
  selectEscrowStatus,
} from '../store/entities/escrow/escrow.selector';
import { CoreState } from '../store/reducers';

@Injectable({
  providedIn: 'root',
})
export class EscrowService {
  constructor(private store: Store<CoreState>) {}

  buildEstablishAccountRequest(
    productId: ProductType
  ): Observable<EstablishEscrowAccountRequest> {
    return this.store.select(buildEstablishEscrowAccountRequest(productId));
  }

  isEscrowSelectedAsPaymentMethod(): Observable<boolean> {
    return this.store.select(selectEscrowSelection);
  }

  isEscrowOnlyPaymentMethod(): Observable<boolean> {
    return this.store.select(isEscrowOnlyPaymentMethod);
  }

  dispatchEstablishEscrowAccount(productType: ProductType): void {
    this.store.dispatch(establishEscrowAccount({ productType: productType }));
  }

  getEscrowStatus(): Observable<EscrowAccountStatus> {
    return this.store.select(selectEscrowStatus);
  }
}
