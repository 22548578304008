import { AddressType } from '@core/models/api/dsm-types';
import {
  addressesEquivalent,
  BasicAddressEntity,
} from '@core/store/entities/address/address.entity';

export interface AddressModel extends BasicAddressEntity {
  addressType: AddressType;
  addressId?: string;
}

export function AddressModelsEquivalent(
  a: AddressModel | undefined,
  b: AddressModel | undefined
): boolean {
  if (a === b) {
    return true;
  }
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return a.street === b.street && addressesEquivalent(a, b);
}

export function mailingAddressesEquivalent(
  aPresent: boolean | undefined,
  a: AddressModel | undefined,
  bPresent: boolean | undefined,
  b: AddressModel | undefined
): boolean {
  if (!aPresent) {
    if (bPresent) {
      return false;
    }
    return true;
  } else {
    if (!bPresent) {
      return false;
    }
  }
  if (a === b) {
    return true;
  }
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return a.street === b.street && addressesEquivalent(a, b);
}
