import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

/**
 * Angular doesn't support redirects in ROUTES config with query parameters. So this is a pass through component to
 * redirect to the route you want while still preserving query parameters.
 */
@Component({
  selector: 'nwx-query-parameter-preserving-redirect',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryParameterPreservingRedirectComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    combineLatest([this.route.data, this.route.queryParams])
      .pipe(take(1))
      .subscribe(([data, queryParams]) => {
        const newQueryParams = Object.assign({}, queryParams);
        if (data.swap) {
          newQueryParams[data.swap.output] = queryParams[data.swap.input];
        }

        const extras: NavigationExtras = { queryParams: newQueryParams };

        this.router.navigate([data.destination], extras);
      });
  }
}
