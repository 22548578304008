import { Injectable } from '@angular/core';
import { DriverVehicleAssignmentAdapter } from '@core/adapters/driver-vehicle-assignment.adapter';
import {
  AddDriverVehicleAssignmentRequest,
  GetDriverVehicleAssignmentRequest,
} from '@core/models/api/request/driver-vehicle-assignment.request';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { from } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  mergeMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromActions from './driver-vehicle-assignment.actions';
import { DriverVehicleAssignmentEntity } from './driver-vehicle-assignment.entity';
import * as fromSelectors from './driver-vehicle-assignment.selectors';
import { DriverVehicleAssignmentUtils } from '@shared/utils/driver-vehicle-assignment.utils';
import {
  submitMsaPageSuccess,
  submitRVPageSuccess,
  submitVehiclePageSuccess,
} from '@core/store/page-submission/composite.action';
import { getAllFeatureFlags } from '../feature-flag/feature-flag.selector';
import { deleteDriverSuccess } from '../driver/driver.actions';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { VehicleEntity } from '@entities/vehicle/vehicle.entity';
import { ProductType } from '@core/models/api/dsm-types';
import { DriverActions } from '@core/store/actions';

@Injectable()
export class DriverVehicleAssignmentEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private driverVehicleAssignmentAdapter: DriverVehicleAssignmentAdapter,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  /* TODO: This might need to be derived from the driver and vehicle objects present at retrieve */
  getDriverVehicleAssignments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getDriverVehicleAssignment),
      mergeMap((action) =>
        this.store.select(fromSelectors.buildDVARequest(action.product)).pipe(
          take(1),
          map((quoteId) => ({
            ...quoteId,
            productType: action.product,
            correlationId: action.correlationId,
          }))
        )
      ),
      mergeMap((request: GetDriverVehicleAssignmentRequest) =>
        this.driverVehicleAssignmentAdapter.getAssignments(request).pipe(
          switchMap((response) => {
            return from([
              fromActions.getDriverVehicleAssignmentSuccess({
                payload: this.mapAssignment(response, request.productType),
                correlationId: request.correlationId,
                productType: request.productType,
              }),
            ]);
          }),
          catchError((error) => {
            return from([
              fromActions.getDriverVehicleAssignmentError({
                error: this.errorSanitizerService.sanitizeError(
                  error,
                  request.productType
                ),
                correlationId: request.correlationId,
              }),
            ]);
          })
        )
      )
    )
  );

  addDriverVehicleAssignment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addDriverVehicleAssignments),
      filter((action) => action.assignments.length > 0),
      switchMap((action) =>
        this.store
          .select(fromSelectors.buildDVARequest(action.productType))
          .pipe(
            take(1),
            map((quoteId) => ({
              ...quoteId,
              productType: action.productType,
              assignments: action.assignments,
            }))
          )
      ),
      switchMap((request: AddDriverVehicleAssignmentRequest) =>
        this.driverVehicleAssignmentAdapter.addAssignment(request).pipe(
          map((response) =>
            fromActions.addDriverVehicleAssignmentSuccess({
              payload: response.map(
                (dvaEntity) =>
                  (dvaEntity = {
                    ...dvaEntity,
                    productType: request.productType,
                  })
              ),
              productType: request.productType,
            })
          ),
          catchError((error) => {
            return from([
              fromActions.addDriverVehicleAssignmentError({
                payload: this.errorSanitizerService.sanitizeError(
                  error,
                  request.productType
                ),
              }),
            ]);
          })
        )
      )
    )
  );

  checkForAutoAssign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.checkForAutoAssign),
      filter((action) =>
        this.isProductVehicleTypeValid(action.vehicle, action.productType)
      ),
      switchMap((action) =>
        this.store
          .select(
            fromSelectors.checkForAutoAssignRequest(
              action.vehicle,
              action.productType
            )
          )
          .pipe(
            take(1),
            map((request) =>
              fromActions.addDriverVehicleAssignments({
                productType: action.productType,
                assignments: request,
              })
            )
          )
      )
    )
  );

  updateDriverAssignmentsForNonDriver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DriverActions.upsertDriverSuccess),
      filter((action) => action.payload.driverType === 'NonDriver'),
      switchMap((action) => {
        const productType = action.payload.productType || 'PersonalAuto';
        const driverId = action.payload.driverId;
        return this.store
          .select(
            fromSelectors.getAllDriverVehicleAssignmentsByProductType(
              productType
            )
          )
          .pipe(
            take(1),
            map((assignments) => {
              const driverAssignmentsForNonDriver = [
                ...assignments.filter(
                  (assignment) => assignment.driverId === driverId
                ),
              ];
              const otherDriverAssignments = assignments.filter(
                (assignment) => assignment.driverId !== driverId
              );
              const otherDriverId = otherDriverAssignments[0].driverId;
              const updatedDriverAssignments =
                driverAssignmentsForNonDriver.map((assignment) => {
                  return {
                    ...assignment,
                    driverId: otherDriverId,
                    isPrimaryVehicle: false,
                  };
                });
              return fromActions.addDriverVehicleAssignments({
                productType,
                assignments: [
                  ...otherDriverAssignments,
                  ...updatedDriverAssignments,
                ],
              });
            })
          );
      })
    )
  );

  private isProductVehicleTypeValid(
    vehicle: VehicleEntity,
    productType: ProductType
  ): boolean {
    if (productType === 'PersonalAuto' && vehicle.vehicleType === 'auto') {
      return true;
    }
    if (
      productType === 'MSA' &&
      (vehicle.vehicleType === 'Motorcycle' ||
        vehicle.vehicleType === 'OffRoad' ||
        vehicle.vehicleType === 'Snowmobile')
    ) {
      return true;
    }
    if (productType === 'RV') {
      return true;
    }
    return false;
  }

  getDVAafterVehicleSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        submitVehiclePageSuccess,
        submitMsaPageSuccess,
        submitRVPageSuccess,
        deleteDriverSuccess
      ),
      withLatestFrom(this.store.select(getAllFeatureFlags)),
      switchMap(([action, featureFlags]) => {
        const nextActions: Action[] = [];
        if (featureFlags.driverVehicleAssignment) {
          nextActions.push(
            fromActions.getDriverVehicleAssignment({ product: 'PersonalAuto' })
          );
        }
        if (featureFlags.msaDriverVehicleAssignment) {
          nextActions.push(
            fromActions.getDriverVehicleAssignment({ product: 'MSA' })
          );
        }
        if (featureFlags.rvDriverVehicleAssignment) {
          nextActions.push(
            fromActions.getDriverVehicleAssignment({ product: 'RV' })
          );
        }
        return nextActions;
      })
    )
  );

  /* CODEMINERS-6490: It's possible after deleting a vehicle, for one vehicle to end up with two primary drivers.
   * Evidently PC will auto-correct the assignment's vehicleId but not its isPrimaryDriver.
   * Detect and repair this when get-dva returns.
   */
  repairDuplicatePrimaryDrivers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getDriverVehicleAssignmentSuccess),
      map((action) => {
        const duplicateVehicleIds =
          this.detectDuplicatePrimaryDriverAssignments(action.payload || []);
        if (duplicateVehicleIds.length < 1) {
          return null;
        }
        const newAssignments = this.repairDuplicatePrimaryDriverAssignments(
          action.payload,
          duplicateVehicleIds
        );
        return fromActions.addDriverVehicleAssignments({
          productType: action.productType,
          assignments: newAssignments,
        });
      }),
      filterOutNull()
    )
  );

  // Returns array of impacted vehicleId.
  private detectDuplicatePrimaryDriverAssignments(
    assignments: DriverVehicleAssignmentEntity[]
  ): string[] {
    const duplicates: string[] = [];
    const singles: string[] = [];
    for (const { vehicleId, isPrimaryDriver } of assignments) {
      if (!isPrimaryDriver) {
        continue;
      }
      if (duplicates.indexOf(vehicleId) >= 0) {
        continue;
      }
      if (singles.indexOf(vehicleId) >= 0) {
        duplicates.push(vehicleId);
      } else {
        singles.push(vehicleId);
      }
    }
    return duplicates;
  }

  private repairDuplicatePrimaryDriverAssignments(
    oldAssignments: DriverVehicleAssignmentEntity[],
    duplicateVehicleIds: string[]
  ): DriverVehicleAssignmentEntity[] {
    const vehicleIdsWithPrimary: string[] = [];
    const newAssignments: DriverVehicleAssignmentEntity[] = [];
    for (const assignment of oldAssignments) {
      if (assignment.isPrimaryDriver) {
        if (vehicleIdsWithPrimary.indexOf(assignment.vehicleId) >= 0) {
          newAssignments.push({
            ...assignment,
            isPrimaryDriver: false,
          });
        } else {
          newAssignments.push(assignment);
          vehicleIdsWithPrimary.push(assignment.vehicleId);
        }
      } else {
        newAssignments.push(assignment);
      }
    }
    return newAssignments;
  }

  private mapAssignment(
    assignments: DriverVehicleAssignmentEntity[],
    productType: ProductType
  ): DriverVehicleAssignmentEntity[] {
    const returnAssignments: DriverVehicleAssignmentEntity[] = [];

    assignments.forEach((assignment) => {
      const thisAssignment = {
        isPrimaryVehicle: assignment.isPrimaryVehicle,
        vehicleId: assignment.vehicleId,
        driverId: assignment.driverId,
        productType,
      };
      // If there is no isPrimaryDriver value set,
      // then just make it true the first time you encounter a vehicle,
      // and false for each subsequent time
      if (typeof assignment.isPrimaryDriver === 'boolean') {
        returnAssignments.push({
          ...thisAssignment,
          isPrimaryDriver: assignment.isPrimaryDriver,
        });
      } else if (
        DriverVehicleAssignmentUtils.vehicleHasPrimaryDriver(
          assignment.vehicleId,
          returnAssignments
        )
      ) {
        returnAssignments.push({
          ...thisAssignment,
          isPrimaryDriver: false,
        });
      } else {
        returnAssignments.push({
          ...thisAssignment,
          isPrimaryDriver: true,
        });
      }
    });

    return returnAssignments;
  }
}
