import { QuoteStatus } from '@app/core/models/api/dsm-types';
import { ProductType } from './../models/api/dsm-types';
import { Injectable } from '@angular/core';
import { Nullable } from '@shared/utils/type.utils';
import { take } from 'rxjs/operators';
import { MemberService } from './member.service';
import { ProductsService } from './products.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class FullStoryService {
  constructor(
    private peopleService: MemberService,
    private productsService: ProductsService,
    private userService: UserService,
    private window: Window
  ) {}

  DELAY_TIME_MS = 2000;
  MAX_ATTEMPTS = 4;

  setPrimaryNamedInsured(): void {
    this.peopleService
      .getPrimaryNamedInsured()
      .pipe(take(1))
      .subscribe((namedInsured) => {
        const fullStory: any = (this.window as any).FS;
        if (!fullStory) {
          return;
        }
        const firstName = namedInsured?.person?.firstName;
        const lastName = namedInsured?.person?.lastName;
        fullStory.event('customer name', {
          name_str: `${firstName} ${lastName}`,
        });
      });
  }

  setAgentName(): void {
    this.userService
      .getUser()
      .pipe(take(1))
      .subscribe((user) => {
        const fullStory: any = (this.window as any).FS;
        if (!fullStory) {
          return;
        }
        const firstName = user.firstName;
        const lastName = user.lastName;
        fullStory.event('agent name', { name_str: `${firstName} ${lastName}` });
      });
  }

  setProductSubmissionInfo(): void {
    this.productsService
      .getSelectedProducts()
      .pipe(take(1))
      .subscribe((selectedProducts) => {
        const fullStory: any = (this.window as any).FS;
        if (!fullStory || !selectedProducts || selectedProducts.length === 0) {
          return;
        }
        let productInfo = {
          products_strs: [] as string[] | undefined,
          quoteIds_strs: [] as string[],
        };
        selectedProducts.forEach((selectedProduct) => {
          const { name, quoteId } = selectedProduct;
          productInfo = {
            ...productInfo,
            [name + '_str']: quoteId,
            products_strs: [
              ...(productInfo.products_strs as string[]),
              name as string,
            ],
            quoteIds_strs: [
              ...(productInfo.quoteIds_strs as string[]),
              quoteId as string,
            ],
          };
        });
        fullStory.event('products selected', productInfo);
      });
  }

  setSessionId(id: string, attempts = 0): void {
    if (id === null) {
      return;
    }
    const fullStory: any = (this.window as any).FS;
    if (!fullStory) {
      if (attempts < this.MAX_ATTEMPTS) {
        this.window.setTimeout(() => {
          this.setSessionId(id, attempts + 1);
        }, this.DELAY_TIME_MS);
      }
      return;
    }
    fullStory.event('session id', { sessionId_str: id });
  }

  setAccountId(id: Nullable<string>, attempts = 0): void {
    if (id === null) {
      return;
    }
    const fullStory: any = (this.window as any).FS;
    if (!fullStory) {
      if (attempts < this.MAX_ATTEMPTS) {
        this.window.setTimeout(() => {
          this.setAccountId(id, attempts + 1);
        }, this.DELAY_TIME_MS);
      }
      return;
    }
    fullStory.event('account id', { accountId_str: id });
  }

  getCurrentSessionURL(): string {
    const fullStory: any = (this.window as any).FS;
    return fullStory?.getCurrentSessionURL?.() || '(not available)';
  }

  logHubLoadStatistics(
    productType: ProductType,
    info: { status: QuoteStatus; taskCount: number }
  ): void {
    const fullStory: any = (this.window as any).FS;
    if (!fullStory) {
      return;
    }

    try {
      fullStory.event('initial-hub-load', {
        product_str: `product-${productType}`,
        status_str: `status-${productType}-${info.status}`,
        tasks_str: `tasks-${productType}-${info.taskCount}`,
      });
    } catch (e) {
      // This is lame but...its just analytics - if it fails, theres not much to be done
      (this.window as any).console.error(e);
    }
  }
}
