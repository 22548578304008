import { Injectable } from '@angular/core';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, exhaustMap, filter, mergeMap, take } from 'rxjs/operators';
import { Observable, combineLatest, from, of } from 'rxjs';
import {
  CompositeActions,
  LocationExcludedExposureActions,
  LocationExposureActions,
  UmbrellaActions,
  UnderlyingPolicyActions,
} from '@core/store/actions';
import {
  LocationExcludedExposureSelectors,
  LocationExposureSelectors,
} from '@core/store/selectors';
import { LocationExposureService } from '@core/services/location-exposure.service';

@Injectable()
export class LocationExposureEffect {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: LocationExposureService,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  upsertLocationExposure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocationExposureActions.upsertLocationExposure),
        mergeMap((action) =>
          this.store
            .select(
              LocationExposureSelectors.buildLocationExposureRequest(
                action.payload,
                action.correlationId || ''
              )
            )
            .pipe(take(1))
        ),
        mergeMap((request) => {
          return this.service.addOrUpdateLocationExposure(request).pipe(
            mergeMap((response) => {
              return from([
                LocationExposureActions.upsertLocationExposureSuccess({
                  payload: response,
                  oldLocationExposureId:
                    request.locationExposureBody.locationExposure
                      .locationExposureId,
                }),
              ]);
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                request.productType
              );
              return of(
                LocationExposureActions.upsertLocationExposureFail({
                  error: saneError,
                  entityId:
                    request.locationExposureBody.locationExposure
                      .locationExposureId,
                })
              );
            })
          );
        })
      ) as Observable<Action>
  );

  deleteLocationExposure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocationExposureActions.deleteLocationExposure),
        mergeMap((action) =>
          this.store
            .select(
              LocationExposureSelectors.buildLocationExposureRequest(
                action.payload,
                action.correlationId || ''
              )
            )
            .pipe(take(1))
        ),
        mergeMap((request) => {
          return this.service.deleteLocationExposure(request).pipe(
            mergeMap(() => {
              return from([
                LocationExposureActions.deleteLocationExposureSuccess({
                  locationExposureId:
                    request.locationExposureBody.locationExposure
                      .locationExposureId,
                }),
              ]);
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                request.productType
              );
              return of(
                LocationExposureActions.deleteLocationExposureFail({
                  error: saneError,
                  entityId:
                    request.locationExposureBody.locationExposure
                      .locationExposureId,
                })
              );
            })
          );
        })
      ) as Observable<Action>
  );

  restoreLocationExposure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UmbrellaActions.restoreExposures),
      exhaustMap(() =>
        combineLatest([
          this.store.select(
            LocationExposureSelectors.getDeletedLocationExposuresWithActivePolicy
          ),
          this.store.select(
            LocationExcludedExposureSelectors.getDeletedLocationExcludedExposures
          ),
        ]).pipe(take(1))
      ),
      mergeMap(([exposures, excludedExposures]) => {
        const exposureActions: Action[] = [];
        exposures.forEach((exposure) => {
          exposureActions.push(
            LocationExposureActions.upsertLocationExposure({
              payload: {
                ...exposure,
                locationExposureId: '',
              },
            })
          );
          exposureActions.push(
            LocationExposureActions.completelyRemoveLocationExposure({
              locationExposureId: exposure.locationExposureId,
            })
          );
        });
        excludedExposures.forEach((exposure) => {
          exposureActions.push(
            LocationExcludedExposureActions.upsertLocationExcludedExposure({
              payload: {
                ...exposure,
                locationExcludedExposureId: '',
              },
            })
          );
          exposureActions.push(
            LocationExcludedExposureActions.completelyRemoveLocationExcludedExposure(
              {
                locationExcludedExposureId: exposure.locationExcludedExposureId,
              }
            )
          );
        });
        return exposureActions;
      })
    )
  );

  cascadeDeleteLocationExposure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UnderlyingPolicyActions.deleteUnderlyingPolicySuccess),
        exhaustMap((action) =>
          this.store
            .select(
              LocationExposureSelectors.getLocationExposuresByUnderlyingPolicyNumber(
                action.underlyingPolicyNumber
              )
            )
            .pipe(take(1))
        ),
        exhaustMap((locationExposures) => {
          return locationExposures.map((exposure) => {
            return LocationExposureActions.deleteLocationExposureSuccess({
              locationExposureId: exposure.locationExposureId,
            });
          });
        })
      ) as Observable<Action>
  );

  buildAccompanyingLocationExposures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnderlyingPolicyActions.buildAccompanyingExposures),
      filter(
        (action) =>
          action.productType === 'Homeowner' ||
          action.productType === 'Condominium' ||
          action.productType === 'Tenant'
      ),
      exhaustMap((action) =>
        this.store
          .select(LocationExposureSelectors.buildLocationExposures)
          .pipe(take(1))
      ),
      exhaustMap((locationExposures) => {
        const locationExposuresArray: Action[] = [];
        locationExposures.forEach((exposure) => {
          locationExposuresArray.push(
            LocationExposureActions.upsertLocationExposure({
              payload: {
                ...exposure,
                locationExposureId: '',
              },
            })
          );
        });
        return from(locationExposuresArray);
      })
    )
  );

  cascadeRestoreLocationExposures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.restoreExposure),
      exhaustMap((action) =>
        this.store
          .select(
            LocationExposureSelectors.getDeletedLocationExposuresByUnderlyingPolicyNumber(
              action.payload.underlyingPolicyNumber
            )
          )
          .pipe(take(1))
      ),
      exhaustMap((locationExposures) => {
        const locationExposuresArray: Action[] = [];
        locationExposures.forEach((exposure) => {
          locationExposuresArray.push(
            LocationExposureActions.upsertLocationExposure({
              payload: {
                ...exposure,
                locationExposureId: '',
              },
            })
          );
          locationExposuresArray.push(
            LocationExposureActions.completelyRemoveLocationExposure({
              locationExposureId: exposure.locationExposureId,
            })
          );
        });
        return from(locationExposuresArray);
      })
    )
  );
}
