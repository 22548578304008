import { ProductType } from '@core/models/api/dsm-types';
import { BaseRequest } from '@core/models/api/request/base-request.model';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../error/error.model';
import {
  ScheduledCategoryEntity,
  ScheduledCategoryItem,
} from './scheduled-category.entity';

export const addScheduledCategory = createAction(
  '[Scheduled Category] Add Scheduled Category',
  props<{ payload: ScheduledCategoryEntity }>()
);

export const addScheduledCategoryError = createAction(
  '[Scheduled Category] Add Scheduled Category Error',
  props<{ error: ErrorModel }>()
);

export const updateScheduledCategory = createAction(
  '[Scheduled Category] Update Scheduled Category',
  props<{ payload: ScheduledCategoryEntity }>()
);

export const updateScheduledCategorySuccess = createAction(
  '[Scheduled Category] Update Scheduled Category Success',
  props<{ payload: ScheduledCategoryEntity[] }>()
);

export const updateScheduledCategoryFail = createAction(
  '[Scheduled Category] Update Scheduled Category Fail',
  props<{ error: ErrorModel }>()
);

export const addScheduledCategorySuccess = createAction(
  '[Scheduled Category] Add Scheduled Category Success',
  props<{ payload: ScheduledCategoryEntity }>()
);

export const addManyScheduledCategories = createAction(
  '[Scheduled Category] Add Many Scheduled Categories',
  props<{ payload: ScheduledCategoryEntity[] }>()
);

export const loadScheduledCategories = createAction(
  '[Scheduled Category] Load Scheduled Categories',
  props<{ payload: ProductType[] }>()
);

export const getAllScheduledCategories = createAction(
  '[Scheduled Category] Get All Scheduled Categories',
  props<{ payload: BaseRequest }>()
);

export const getAllScheduledCategoriesSuccess = createAction(
  '[Scheduled Category] Get All Scheduled Categories Success',
  props<{ payload: ScheduledCategoryEntity[]; productType: ProductType }>()
);

export const getAllScheduledCategoriesFail = createAction(
  '[Scheduled Category] Get All Scheduled Categories Fail',
  props<{ error: ErrorModel }>()
);

export const deleteAllScheduledCategories = createAction(
  '[Scheduled Category] Delete All Scheduled Categories',
  props<{ payload: ProductType }>()
);

//  SCHEDULED PERSONAL EFFECTS (RV)
export const loadScheduledPersonalEffects = createAction(
  '[Scheduled Personal Effects] Load Scheduled Personal Effects'
);

export const getAllScheduledPersonalEffects = createAction(
  '[Scheduled Personal Effects] Get All Scheduled Personal Effects',
  props<{ payload: BaseRequest }>()
);

export const updateScheduledPersonalEffectItem = createAction(
  '[Scheduled Personal Effects] Update Scheduled Personal Effect Item',
  props<{ payload: ScheduledCategoryItem; productType: ProductType }>()
);

export const removeScheduledPersonalEffectItem = createAction(
  '[Scheduled Personal Effects] Remove Scheduled Personal Effect Item',
  props<{ payload: ScheduledCategoryItem; productType: ProductType }>()
);
