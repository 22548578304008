import { createAction, props } from '@ngrx/store';
import { EntityState, Update } from '@ngrx/entity';

import { DiscountEntity } from '@core/store/entities/discount/discount.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { ProductType } from '@core/models/api/dsm-types';

export const addDiscount = createAction(
  '[Discount] Add Discount',
  props<{ payload: DiscountEntity }>()
);

export const addDiscountError = createAction(
  '[Discount] Add Discount Error',
  props<{ error: ErrorModel }>()
);

export const addDiscountSuccess = createAction(
  '[Discount] Add Discount Success',
  props<{ payload: DiscountEntity }>()
);

export const updateDiscount = createAction(
  '[Discount] Update Discount',
  props<{ payload: DiscountEntity }>()
);

export const updateDiscountError = createAction(
  '[Discount] Update Discount Error',
  props<{ error: ErrorModel }>()
);

export const updateDiscountSuccess = createAction(
  '[Discount] Update Discount Success',
  props<{ payload: Update<DiscountEntity> }>()
);

export const upsertAllDiscountsSuccess = createAction(
  '[Discount] Upsert All Discounts Success',
  props<{ payload: DiscountEntity[] }>()
);

export const replaceAllDiscountsOfProduct = createAction(
  '[Discount] Replace All Discounts of Product Type',
  props<{ payload: DiscountEntity[]; productType: ProductType }>()
);

export const removeDiscountsOfType = createAction(
  '[Discount] Remove discounts of type',
  props<{ discountType: string }>()
);

export const deleteDiscount = createAction(
  '[Discount] Delete Discount',
  props<{ payload: string }>()
);

export const deleteDiscountError = createAction(
  '[Discount] Delete Discount Error',
  props<{ error: ErrorModel }>()
);

export const deleteDiscountSuccess = createAction(
  '[Discount] Delete Discount Success',
  props<{ payload: string }>()
);
