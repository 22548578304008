import { Injectable } from '@angular/core';
import {
  PreBindDocumentSelectors,
  ProductsSelectors,
} from '@core/store/selectors';
import { ConfirmationsAction } from '@forms-store/store/actions';
import { createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  combineLatest,
  filter,
  map,
  pairwise,
  withLatestFrom,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationsEffects {
  constructor(private store: Store) {}

  resetPreBindAcknowledgementWhenDocumentsChange$ = createEffect(() => {
    return this.store
      .select(PreBindDocumentSelectors.selectPreBindDocumentsIds)
      .pipe(
        pairwise(),
        map(([prev, next]) => ({
          prev: [...prev].sort().join(','),
          next: [...next].sort().join(','),
        })),
        filter(({ prev, next }) => prev !== next),
        withLatestFrom(
          this.store.select(
            ProductsSelectors.getProductQuoteStatus('PersonalAuto')
          ),
          this.store.select(ProductsSelectors.getProductQuoteStatus('MSA'))
        ),
        filter(
          ([_, autoStatus, msaStatus]) =>
            autoStatus !== 'Binding' && msaStatus !== 'Binding'
        ),
        map(() => ConfirmationsAction.resetPreBindDocumentsAcknowledgement())
      );
  });
}
