<div class="bolt-space-bottom-2xl">
  <div class="bolt-row" *ngIf="inlineErrorMessage">
    <div class="bolt-col">
      <div class="mt-2 mb-4">
        <bolt-notification>
          {{ inlineErrorMessage }}<br />
          <a *ngIf="policyCenterUrlForLink" href="{{ policyCenterUrlForLink }}">
            Please continue quote in PolicyCenter.
          </a>
        </bolt-notification>
      </div>
    </div>
  </div>
  <div class="bolt-row">
    <div class="bolt-col" *ngIf="vehicleProducts.length">
      <ng-container *ngIf="showProductCategories">
        <h2 class="category-heading">Vehicle insurance</h2>
      </ng-container>
      <ng-container *ngFor="let product of vehicleProducts">
        <nwx-product-tile
          [form]="form"
          [product]="product"
          [name]="product.name"
          [imageUrl]="product.imageUrl"
          [helpText]="getProductHelpText(product)"
          [isAvailable]="product.isAvailable"
          (productSelected)="updateProductSelection($event)"
        ></nwx-product-tile>
      </ng-container>
    </div>
    <div class="bolt-col" *ngIf="propertyProducts.length">
      <ng-container *ngIf="showProductCategories">
        <h2 class="category-heading">Property</h2>
      </ng-container>
      <ng-container *ngFor="let product of propertyProducts">
        <nwx-product-tile
          [form]="form"
          [product]="product"
          [name]="product.name"
          [imageUrl]="product.imageUrl"
          [helpText]="getProductHelpText(product)"
          [isAvailable]="product.isAvailable"
          (productSelected)="updateProductSelection($event)"
        ></nwx-product-tile>
      </ng-container>
    </div>
    <div class="bolt-col" *ngIf="protectionProducts.length">
      <ng-container *ngIf="showProductCategories">
        <h2 class="category-heading">Protection</h2>
      </ng-container>
      <ng-container *ngFor="let product of protectionProducts">
        <nwx-product-tile
          [form]="form"
          [product]="product"
          [name]="product.name"
          [imageUrl]="product.imageUrl"
          [helpText]="getProductHelpText(product)"
          [isAvailable]="product.isAvailable"
          (productSelected)="updateProductSelection($event)"
        ></nwx-product-tile>
      </ng-container>
    </div>
  </div>
  <div
    class="bolt-row"
    *ngIf="restrictedProducts.length && showProductCategories"
  >
    <div class="bolt-col restricted-products-container">
      <hr />
      <nwx-notification-bubble
        type="warning"
        size="sm"
        *ngIf="selectedProductWasRemoved"
      >
        <ng-container class="notification-message">
          <h4>Selected product(s) are no longer available</h4>
        </ng-container>
      </nwx-notification-bubble>
      <div class="bolt-row pt-3 bolt-align-items-center">
        <div class="bolt-col-md-12">
          <h4>Products not available</h4>
          <div class="flex-center">
            <bolt-icon
              size="sm"
              name="info-circle-filled"
              color="cherry-red"
              class="inline-icon"
            ></bolt-icon>
            <div class="bolt-body-copy-md">
              User does not have access to write new business for the following
              product(s) in this state.
            </div>
          </div>
        </div>
      </div>
      <div class="bolt-row pt-3">
        <ng-container *ngFor="let productType of restrictedProducts">
          <div class="bolt-col-md-4">
            <nwx-product-tile
              *ngIf="getProductByType(productType)"
              [form]="form"
              [product]="getProductByType(productType)"
              [name]="getProductByType(productType)?.name"
              [imageUrl]="getProductByType(productType)?.imageUrl"
              [helpText]="getProductHelpText(getProductByType(productType))"
              [isAvailable]="false"
            ></nwx-product-tile>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
