import { ObjectValidator } from '@core/helper/object-validator';
import { AddressType } from '@core/models/api/dsm-types';
import { AddressModel } from '@shared/components/address-input/address-input.model';
import { Nullable } from '@shared/utils/type.utils';

export interface AddressEntity extends BasicAddressEntity {
  addressType: AddressType;
  addressId?: string;
}

export interface BasicAddressEntity {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  street?: string;
}

export function sanitizeBasicAddressEntity(input: unknown): BasicAddressEntity {
  return ObjectValidator.forceSchema(input, {
    addressLine1: 'string',
    addressLine2: 'string',
    city: 'string',
    state: 'string',
    postalCode: 'string',
    country: 'string',
    street: 'string',
  }) as BasicAddressEntity;
}

export function addressesEquivalent(
  a: Nullable<AddressModel>,
  b: Nullable<AddressModel>
): boolean {
  if (a === b) {
    return true;
  }
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return (
    a.addressType === b.addressType &&
    a.addressLine1 === b.addressLine1 &&
    a.addressLine2 === b.addressLine2 &&
    a.city === b.city &&
    a.state === b.state &&
    a.postalCode === b.postalCode &&
    a.country === b.country &&
    a.street === b.street
  );
}

export function sanitizeAddressEntity(input: unknown): AddressEntity {
  return ObjectValidator.forceSchema(input, {
    addressType: 'string',
    addressId: 'string',
    addressLine1: 'string',
    addressLine2: 'string',
    city: 'string',
    state: 'string',
    postalCode: 'string',
    country: 'string',
    street: 'string',
  }) as AddressEntity;
}
