import { AmfBankAccountsResponse } from '@core/models/api/response/amf-bank-accounts-response.moel';
import { SessionActions } from '@core/store/actions';
import { ErrorModel } from '@entities/error/error.model';
import { Action, createReducer, on } from '@ngrx/store';
import {
  loadAmfBankAccounts,
  loadAmfBankAccountsSuccess,
  loadAmfBankAccountsFail,
  amfBankAccountsNotNeeded,
} from './amf-bank-accounts.action';

export interface AmfBankAccountsState {
  amfBankAccountsLoading: boolean;
  amfBankAccountsLoaded: boolean;
  amfBankAccountsFailed: boolean;
  amfBankAccounts: AmfBankAccountsResponse;
  error: ErrorModel;
}

const initialState: AmfBankAccountsState = {
  amfBankAccountsLoading: false,
  amfBankAccountsLoaded: false,
  amfBankAccountsFailed: false,
  amfBankAccounts: {},
  error: {},
};

const amfBankAccountsReducer = createReducer<AmfBankAccountsState>(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(loadAmfBankAccounts, (state, {}) => ({
    ...state,
    amfBankAccountsLoading: true,
    amfBankAccounts: {},
  })),
  on(loadAmfBankAccountsSuccess, (state, { payload }) => ({
    ...state,
    amfBankAccounts: payload,
    amfBankAccountsLoading: false,
    amfBankAccountsLoaded: true,
  })),
  on(loadAmfBankAccountsFail, (state, { error }) => ({
    ...state,
    amfBankAccountsLoading: false,
    amfBankAccountsFailed: true,
    error,
  })),
  on(amfBankAccountsNotNeeded, (state, {}) => ({
    ...state,
    amfBankAccounts: {},
    amfBankAccountsLoading: false,
    amfBankAccountsLoaded: true,
  }))
);

export function reducer(
  state: AmfBankAccountsState | undefined,
  action: Action
): AmfBankAccountsState {
  return amfBankAccountsReducer(state, action);
}

export const getAmfBankAccountsLoaded = (state: AmfBankAccountsState) =>
  state.amfBankAccountsLoaded;
export const getAmfBankAccountsLoading = (state: AmfBankAccountsState) =>
  state.amfBankAccountsLoading;
export const getAmfBankAccountsFailed = (state: AmfBankAccountsState) =>
  state.amfBankAccountsFailed;
export const getAmfBankAccountsState = (state: AmfBankAccountsState) =>
  state.amfBankAccounts;
