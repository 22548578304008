import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[nwxTextWithMenuResults]',
})
export class TextWithMenuResultsDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

/** Usage:
 * ```
 * <nwx-text-with-menu ...bolt-textfield attributes... >
 *   <ng-template *ngIf="...show popup..." nwxTextWithMenuResults>
 *     ...content for popup...
 *   </ng-template>
 * </nwx-text-with-menu>
 * ```
 * You can have zero or one nwxTextWithMenuResults at a time.
 * If more than one exist, we use only the first.
 */
@Component({
  selector: 'nwx-text-with-menu',
  templateUrl: './text-with-menu.component.html',
  styleUrls: ['./text-with-menu.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextWithMenuComponent),
      multi: true,
    },
  ],
})
export class TextWithMenuComponent
  implements ControlValueAccessor, OnDestroy, AfterViewInit
{
  // <bolt-textfield> attributes. (autocomplete,min,max,step) not supported.
  @Input() label: string | null = null;
  @Input() instructionaltext: string | null = null;
  @Input() error: string | null = '';
  @Input() required: string | null = null;
  @Input() optionaltext: string | null = null;
  @Input() disabled: string | null = null;
  @Input() type: string | null = null;
  @Input() maxlength: string | null = null;
  @Input() inputsize: string | null = null;
  // Icon is all or nothing. If we make it available, it would have to be assigned always.
  //@Input() iconname: string | null = null;
  //@Input() indicator: string | null = null;
  //@Input() iconalt: string | null = null;
  @Input() prefix: string | null = null;
  @Input() prefixsymbol: string | null = null;
  @Input() suffix: string | null = null;
  @Input() suffixsymbol: string | null = null;
  @Input() aligninput: string | null = null;
  @Input() invalid: string | null = null;
  @Input() spellcheck: string | null = null;
  @Input() autocorrect: string | null = null;
  @Input() autocapitalize: string | null = null;
  @Input() inputmode: string | null = null;
  // tslint:disable-next-line:no-output-rename // maintaining Bolt's names, sorry TSLint.
  //@Output('bolt-icon-click') boltIconClick = new EventEmitter<Event>();

  @ContentChildren(TextWithMenuResultsDirective)
  resultsTemplates!: QueryList<TextWithMenuResultsDirective>;

  @ViewChildren('popup') popups!: QueryList<ElementRef>;

  @ViewChild('boltTextField', { read: ElementRef })
  boltTextField: ElementRef | null = null;

  private unsubscribe$ = new Subject<void>();

  private onChange = (v: string) => {};
  private onTouched = () => {};

  constructor(
    private changeDetector: ChangeDetectorRef,
    private element: ElementRef
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    combineLatest([this.resultsTemplates.changes, this.popups.changes])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.repositionResults());
  }

  private repositionResults(): void {
    const popup = this.popups.first;
    if (popup && this.boltTextField) {
      const input =
        this.boltTextField.nativeElement.renderRoot.querySelector('input');
      if (input) {
        const headRoomPx = 4;
        const hostBounds = this.element.nativeElement.getBoundingClientRect();
        const inputBounds = input.getBoundingClientRect();
        const x = inputBounds.x - hostBounds.x;
        const y = inputBounds.bottom - hostBounds.y + headRoomPx;
        const w = inputBounds.width;
        popup.nativeElement.style.left = `${x}px`;
        popup.nativeElement.style.top = `${y}px`;
        popup.nativeElement.style.width = `${w}px`;
      }
    }
  }

  // tslint:disable-next-line:no-any // ControlValueAccessor
  writeValue(v: any): void {
    if (this.boltTextField) {
      this.boltTextField.nativeElement.value = v;
    }
  }

  registerOnChange(f: (v: string) => void): void {
    this.onChange = f;
  }

  registerOnTouched(f: () => void): void {
    this.onTouched = f;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled ? 'disabled' : null;
    this.changeDetector.markForCheck();
  }

  onFocus(): void {
    this.onTouched();
  }

  onInput(): void {
    this.onChange(this.boltTextField?.nativeElement.value || '');
  }
}
