import * as models from '@core/models/api/vehicle-inquiry.model';
import * as actions from './vehicle-inquiry.action';
import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProductType } from '@core/models/api/dsm-types';
import { PicklistItem } from '@shared/models/picklist.model';
import { entityCacheSelectorProvider } from '@ngrx/data';
import { InquiryPath } from '@core/adapters/powersports.adapter';

/**
 * VehicleInquiry response cache.
 * Everything is nullable -- that means a call is in flight or failed.
 */
export interface VehicleInquiryModel {
  years: PicklistItem[];
  makes: {
    [year: number]: PicklistItem[];
  };
  models: {
    [yearColonMake: string]: PicklistItem[];
  };
  serieses: {
    [yearColonMakeColonModel: string]: PicklistItem[];
  };
  vins: {
    [vin: string]:
      | models.Retrieve10DigitVehicleIdentificationNumberResponse
      | models.Retrieve17DigitVehicleIdentificationNumberOdbiResponse
      | models.Retrieve17DigitVehicleIdentificationNumberCCResponse
      | null;
  };
  rates: {
    [yearColonMakeColonModel: string]:
      | models.RetrieveVehicleSeriesRatesOdbiResponse
      | models.RetrieveVehicleSeriesRatesCCResponse
      | null;
  };
  yearsLoaded: boolean;
  inquiryPath: InquiryPath;
}
export interface VehicleInquiryState extends EntityState<VehicleInquiryModel> {}

export function composeModelsKey(year: number, make: string): string {
  return `${year}:${make}:`;
}

export function composeSeriesKey(
  year: number,
  make: string,
  model: string
): string {
  return `${year}:${make}:${model}`;
}

export function composeRatesKey(
  year: number,
  make: string,
  model: string
): string {
  return `${year}:${make}:${model}`;
}

export const adapter: EntityAdapter<VehicleInquiryModel> =
  createEntityAdapter<VehicleInquiryModel>({
    selectId: (inquiry) => inquiry.inquiryPath || 'auto',
  });

const initialState: VehicleInquiryState = adapter.getInitialState({});

export const reducer = createReducer<VehicleInquiryState>(
  initialState,

  on(actions.clearVehicleInquiry, () => initialState),

  on(actions.setVehicleInquiryYears, (state, { response, inquiryPath }) => {
    // look up the corresponding inquiry info
    let entity = {
      ...(state.entities[inquiryPath] ||
        ({ inquiryPath } as VehicleInquiryModel)),
    };
    entity.yearsLoaded = true;
    entity.years = response;
    return adapter.upsertOne(entity, state);
  }),
  on(actions.setVehicleInquiryYearsError, (state, { inquiryPath }) => {
    // look up the corresponding inquiry info
    let entity = {
      ...(state.entities[inquiryPath] ||
        ({ inquiryPath } as VehicleInquiryModel)),
    };
    entity.yearsLoaded = true;
    return adapter.upsertOne(entity, state);
  }),
  on(actions.setPlaceholderVehicleInquiryYears, (state, { inquiryPath }) => {
    // look up the corresponding inquiry info
    let entity = {
      ...(state.entities[inquiryPath] ||
        ({ inquiryPath } as VehicleInquiryModel)),
    };
    entity.years = [];
    return adapter.upsertOne(entity, state);
  }),
  on(
    actions.setVehicleInquiryMakes,
    (state, { year, response, inquiryPath }) => {
      // look up the corresponding inquiry info
      let entity = {
        ...(state.entities[inquiryPath] || ({} as VehicleInquiryModel)),
      };
      entity.makes = {
        ...entity.makes,
        [year]: response,
      };
      return adapter.upsertOne(entity, state);
    }
  ),
  on(
    actions.setPlaceholderVehicleInquiryMakes,
    (state, { inquiryPath, year }) => {
      // look up the corresponding inquiry info
      let entity = state.entities[inquiryPath]
        ? ({ ...state.entities[inquiryPath] } as VehicleInquiryModel)
        : ({ inquiryPath } as VehicleInquiryModel);

      if (entity.makes) {
        let clonedMakes = { ...entity.makes };
        clonedMakes[year] = [];
        entity.makes = clonedMakes;
      } else {
        entity.makes = {};
        entity.makes[year] = [];
      }
      return adapter.upsertOne(entity, state);
    }
  ),
  on(actions.removeVehicleInquiryMakes, (state, { year, inquiryPath }) => {
    let entity = {
      ...(state.entities[inquiryPath] || ({} as VehicleInquiryModel)),
    };
    entity.makes = { ...entity.makes };
    delete entity.makes[year];
    return adapter.upsertOne(entity, state);
  }),
  on(
    actions.setVehicleInquiryModels,
    (state, { year, make, response, inquiryPath }) => {
      let entity = {
        ...(state.entities[inquiryPath] || ({} as VehicleInquiryModel)),
      };
      entity.models = {
        ...entity.models,
        [composeModelsKey(year, make)]: response,
      };
      return adapter.upsertOne(entity, state);
    }
  ),
  on(
    actions.setPlaceholderVehicleInquiryModels,
    (state, { year, make, inquiryPath }) => {
      // look up the corresponding inquiry info
      let entity = {
        ...(state.entities[inquiryPath] ||
          ({ inquiryPath } as VehicleInquiryModel)),
      };
      const key = composeModelsKey(year, make);
      if (entity.models) {
        let clonedModels = { ...entity.models };
        clonedModels[key] = [];
        entity.models = clonedModels;
      } else {
        entity.models = {};
        entity.models[key] = [];
      }
      return adapter.upsertOne(entity, state);
    }
  ),
  on(
    actions.removeVehicleInquiryModels,
    (state, { year, make, inquiryPath }) => {
      let entity = {
        ...(state.entities[inquiryPath] || ({} as VehicleInquiryModel)),
      };
      entity.models = { ...entity.models };
      delete entity.models[composeModelsKey(year, make)];
      return adapter.upsertOne(entity, state);
    }
  ),
  on(
    actions.setVehicleInquirySeries,
    (state, { year, make, model, response, inquiryPath }) => {
      let entity = {
        ...(state.entities[inquiryPath] ?? ({} as VehicleInquiryModel)),
      };
      entity.serieses = {
        ...entity.serieses,
        [composeSeriesKey(year, make, model)]: response,
      };
      return adapter.upsertOne(entity, state);
    }
  ),
  on(
    actions.setPlaceholderVehicleInquirySeries,
    (state, { year, make, model, inquiryPath }) => {
      // look up the corresponding inquiry info
      let entity = {
        ...(state.entities[inquiryPath] ??
          ({ inquiryPath } as VehicleInquiryModel)),
      };
      const key = composeSeriesKey(year, make, model);
      if (entity.serieses) {
        let clonedSerieses = { ...entity.serieses };
        clonedSerieses[key] = [];
        entity.serieses = clonedSerieses;
      } else {
        entity.serieses  = {};
        entity.serieses [key] = [];
      }
      return adapter.upsertOne(entity, state);
    }
  ),
  on(
    actions.removeVehicleInquirySeries,
    (state, { year, make, model, inquiryPath }) => {
      let entity = {
        ...(state.entities[inquiryPath] ?? ({} as VehicleInquiryModel)),
      };
      entity.serieses = { ...entity.serieses  };
      delete entity.serieses[composeSeriesKey(year, make, model)];
      return adapter.upsertOne(entity, state);
    }
  ),
  on(actions.setVehicleInquiryVin, (state, { vin, response, inquiryPath }) => {
    let entity = {
      ...(state.entities[inquiryPath] || ({} as VehicleInquiryModel)),
    };
    entity.vins = {
      ...entity.vins,
      [vin]: response,
    };
    return adapter.upsertOne(entity, state);
  }),
  on(actions.removeVehicleInquiryVin, (state, { vin, inquiryPath }) => {
    let entity = {
      ...(state.entities[inquiryPath] || ({} as VehicleInquiryModel)),
    };
    entity.vins = { ...entity.vins };
    delete entity.vins[vin];
    return adapter.upsertOne(entity, state);
  }),
  on(
    actions.setVehicleInquiryRates,
    (state, { year, make, model, response, inquiryPath }) => {
      let entity = {
        ...(state.entities[inquiryPath] || ({} as VehicleInquiryModel)),
      };
      entity.rates = {
        ...entity.rates,
        [composeRatesKey(year, make, model)]: response,
      };
      return adapter.upsertOne(entity, state);
    }
  ),
  on(
    actions.removeVehicleInquiryRates,
    (state, { year, make, model, inquiryPath }) => {
      let entity = {
        ...(state.entities[inquiryPath] || ({} as VehicleInquiryModel)),
      };
      entity.rates = {...entity.rates};
      delete entity.rates[composeRatesKey(year, make, model)];
      return adapter.upsertOne(entity, state);
    }
  )
);
