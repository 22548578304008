import { getFormsState } from '@forms-store/store/reducers';
import { createSelector } from '@ngrx/store';

export const getAccountRegistrationFormState = createSelector(
  getFormsState,
  (state) => state.accountRegistrationInfo
);
export const hasUpdatedRegistrationForm = createSelector(
  getAccountRegistrationFormState,
  (state) => state.hasUpdatedRegistration
);
