<div class="bolt-container">
  <div class="panel" *ngFor="let report of reportDetails">
    <div
      class="panel-header bolt-row bolt-background-pale-gray-50 bolt-justify-content-between bolt-space-inner-wide-xl"
    >
      <div class="bolt-col-4 reports-details">
        <img
          class="product-image"
          src="assets/images/icons/circle-colorized.svg"
        />Losses
      </div>
      <div class="bolt-col-2">
        <div class="reports-icon" *ngIf="report.losses.length === 0">
          <bolt-icon
            name="checkmark-circle-filled"
            color="theme-success"
          ></bolt-icon
          >&nbsp;&nbsp;Clear
        </div>
        <div class="reports-icon" *ngIf="report.losses.length > 0">
          <bolt-icon
            name="exclamation-triangle-filled"
            color="theme-warning"
          ></bolt-icon
          >&nbsp;&nbsp;Adverse
        </div>
      </div>
    </div>
    <div
      class="panel-body bolt-space-inner-xl"
      *ngIf="report.losses.length > 0"
    >
      <div class="bolt-row" *ngFor="let loss of report.losses">
        <div class="bolt-col-6"><strong>Loss cause</strong></div>
        <div class="bolt-col-6">{{ loss.cause }}</div>
        <div class="bolt-col-6"><strong>Loss date</strong></div>
        <div class="bolt-col-6">{{ loss.date }}</div>
        <div class="bolt-col-6"><strong>Loss amount</strong></div>
        <div class="bolt-col-6">{{ loss.amount }}</div>
        <div class="bolt-col-6"><strong>Catastrophic</strong></div>
        <div class="bolt-col-6">{{ loss.catastrophe ? 'Yes' : 'No' }}</div>
        <div class="bolt-col-6"><strong>Weather</strong></div>
        <div class="bolt-col-6">{{ loss.weather ? 'Yes' : 'No' }}</div>
        <div class="bolt-col-6"><strong>Subrogated</strong></div>
        <div class="bolt-col-6">{{ loss.subrogatted ? 'Yes' : 'No' }}</div>
      </div>
    </div>
  </div>
</div>
