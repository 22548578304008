import { Nullable } from '@shared/utils/type.utils';

export class MaskHelper {
  static maskPartiallyMaskedDob(input: Nullable<string>): Nullable<string> {
    if (!input || input.indexOf('*') < 0) {
      return input;
    }
    return '****-**-**';
  }
}
