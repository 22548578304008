<div class="bolt-container">
  <div class="bolt-row">
    <div class="bolt-col-auto align-top bolt-justify-items-center">
      <bolt-icon
        name="exclamation-triangle-filled"
        color="theme-warning"
        title="purchase issue"
      ></bolt-icon>
    </div>
    <div class="bolt-col">
      <div class="bolt-row bolt-space-bottom-lg">
        <div class="bolt-col">
          <div class="label">Temporary policy purchase issue</div>
          {{ getContent() }}
        </div>
      </div>
      <div class="bolt-row">
        <div class="bolt-col">
          <nwx-pivot-to-policy-center
            [buttonText]="getButtonText()"
            [externalIcon]="true"
            [urlType]="getUrlType()"
            [productType]="getProductType()"
            [errorMessage]="errorMessage"
          ></nwx-pivot-to-policy-center>
        </div>
      </div>
    </div>
  </div>
</div>
