import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromDocuments from '@core/store/entities/document/document.reducers';
import * as fromProducts from '@core/store/entities/product/product.selectors';
import { DocumentEntity, DocumentsEntity } from './document.entity';
import { ProductDisplayOrder } from '@shared/constants/app-constants';
import { BaseRequest } from '@core/models/api/request/base-request.model';

export interface DocumentsRequest extends BaseRequest {
  policyNumber: string;
}

export const getDocumentState = createSelector(
  fromCore.getCoreState,
  (state) => state.documents
);

export const {
  selectAll: getAllDocuments,
  selectEntities: getDocumentEntities,
  selectTotal: getTotalDocuments,
} = fromDocuments.adapter.getSelectors(getDocumentState);

export const getDocumentsLoaded = createSelector(
  getDocumentState,
  (state) => state.loaded
);

export const buildGetDocumentsRequests = createSelector(
  fromProducts.getSelectedQuoteProductsWithoutErrors,
  (products) => {
    return products.map((product) => {
      return {
        productType: product.type,
        policyNumber: product.policyNumber,
      } as DocumentsRequest;
    });
  }
);

export const getAllUniqueDocuments = createSelector(
  getAllDocuments,
  (entities) => {
    const outgoingEntities: DocumentsEntity[] = [];
    entities = entities.sort(compareDocumentEntities);
    for (const entity of entities) {
      const uniqueDocuments: DocumentEntity[] = [];
      for (const doc of entity.documents) {
        const previousIndex = uniqueDocuments.findIndex(
          (d) => d.docUID === doc.docUID
        );
        if (previousIndex < 0) {
          uniqueDocuments.push(doc);
        } else if (doc.pniemail) {
          uniqueDocuments[previousIndex] = doc;
        }
      }
      outgoingEntities.push({
        ...entity,
        documents: uniqueDocuments,
      });
    }
    return outgoingEntities;
  }
);

function compareDocumentEntities(
  a: DocumentsEntity,
  b: DocumentsEntity
): number {
  return (
    ProductDisplayOrder.indexOf(a.productType) -
    ProductDisplayOrder.indexOf(b.productType)
  );
}
