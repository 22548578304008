import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

interface Basic<T> {
  data: T;
}

@Injectable({
  providedIn: 'root',
})
export class ComponentStoreService<T> {
  constructor(private componentStore: ComponentStore<Basic<T>>) {}

  private readonly setValue = this.componentStore.updater(
    (state: Basic<T>, value: Basic<T>) => ({
      state,
      data: { ...state.data, ...value.data },
    })
  );

  initialize(data: T): void {
    this.componentStore.setState({ data });
  }

  update(data: T): void {
    this.setValue({ data });
  }

  get(): Observable<T> {
    return this.componentStore.select((state) => ({ ...state.data }));
  }
}
