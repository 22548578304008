import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromDriverVehicleAssignments from '@core/store/entities/driver-vehicle-assignment/driver-vehicle-assignment.reducers';
import * as fromProducts from '@core/store/entities/product/product.selectors';
import { ProductType } from '@core/models/api/dsm-types';
import { DriverVehicleAssignmentEntity } from './driver-vehicle-assignment.entity';
import { getAllFeatureFlags } from '../feature-flag/feature-flag.selector';
import { getAllSelectedDriverMemberModels } from '../member/member.selector';
import { getAllSavedVehiclesByProduct } from '../vehicle/vehicle.selector';
import { VehicleEntity } from '../vehicle/vehicle.entity';
import { PersonUtils } from '@shared/utils/person.utils';

export const getDriverVehicleAssignmentstate = createSelector(
  fromCore.getCoreState,
  (state) => state.driverVehicleAssignments
);

export const {
  selectAll: getAllDriverVehicleAssignments,
  selectEntities: getDriverVehicleAssignmentEntities,
  selectTotal: getTotalDriverVehicleAssignments,
} = fromDriverVehicleAssignments.adapter.getSelectors(
  getDriverVehicleAssignmentstate
);

export const buildDVARequest = (product: ProductType) =>
  createSelector(fromProducts.getProduct(product), (product) => ({
    quoteId: product?.quoteId,
  }));

export const checkForAutoAssignRequest = (
  vehicle: VehicleEntity,
  productType: ProductType
) =>
  createSelector(
    getAllDriverVehicleAssignmentsByProductType(productType),
    getAllSelectedDriverMemberModels(productType),
    getAllSavedVehiclesByProduct(productType),
    getAllFeatureFlags,
    (assignments, drivers, vehicles, featureFlags) =>
      ((productType === 'PersonalAuto' &&
        featureFlags.driverVehicleAssignment) ||
        (productType === 'MSA' && featureFlags.msaDriverVehicleAssignment) ||
        (productType === 'RV' && featureFlags.rvDriverVehicleAssignment)) &&
      drivers.length === 1
        ? [
            ...assignments,
            {
              driverId: PersonUtils.driverIdFromEntity(drivers[0], productType),
              vehicleId: vehicle.vehicleId,
              isPrimaryDriver: true,
              isPrimaryVehicle: vehicles.length === 1,
            } as DriverVehicleAssignmentEntity,
          ]
        : []
  );

export const getAllDriverVehicleAssignmentsByProductType = (
  productType: ProductType
) =>
  createSelector(getAllDriverVehicleAssignments, (assignments) =>
    assignments.filter((a) => a.productType === productType)
  );
