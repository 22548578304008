import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { NavLinkModel } from '../models/views/nav-link.model';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  _shouldShowNavBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  _shouldShowProfileComponent$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  _navBarContents$: BehaviorSubject<NavLinkModel[]> = new BehaviorSubject<
    NavLinkModel[]
  >([]);

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  _links: NavLinkModel[] = [];

  private shouldShowWelcomeMessage$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor() {}

  shouldShowNavBar(): BehaviorSubject<boolean> {
    return this._shouldShowNavBar$;
  }

  shouldShowProfileComponent(): BehaviorSubject<boolean> {
    return this._shouldShowProfileComponent$;
  }

  shouldShowWelcomeMessage(): BehaviorSubject<boolean> {
    return this.shouldShowWelcomeMessage$;
  }

  showNavBar(): void {
    this._shouldShowNavBar$.next(true);
  }

  suppressNavBar(): void {
    this._shouldShowNavBar$.next(false);
  }

  showProfileComponent(): void {
    this._shouldShowProfileComponent$.next(true);
  }

  suppressProfileComponent(): void {
    this._shouldShowProfileComponent$.next(false);
  }

  setNavBar(links: NavLinkModel[]): void {
    this._links = links;
    this._navBarContents$.next(this._links);
    this.showNavBar();
  }

  getNavBar(): BehaviorSubject<NavLinkModel[]> {
    return this._navBarContents$;
  }

  setActiveLink(id: string): void {
    this._links = this._links.map((linkModel) => {
      if (linkModel.id === id) {
        return {
          ...linkModel,
          active: true,
        };
      } else {
        return {
          ...linkModel,
          active: false,
        };
      }
    });
    this._navBarContents$.next(this._links);
  }

  showWelcomeMessage(): void {
    this.shouldShowWelcomeMessage$.next(true);
  }

  suppressWelcomeMessage(): void {
    this.shouldShowWelcomeMessage$.next(false);
  }
}
