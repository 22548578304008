<div class="msb-estimate-number">
  MSB estimate number:
  {{ coveredLocation?.associatedMSBEstimateNumber }}&nbsp;&nbsp;
  <a
    class="link"
    id="edit-msb-number-pencil"
    href="javascript:0"
    (click)="openMSBEstimateModal()"
  >
    <bolt-icon
      id="edit-msb-number-icon"
      name="pencil"
      [title]="pencilTitle"
      size="sm"
      color="medium-blue"
    ></bolt-icon>
  </a>
</div>
