import { createAction, props } from "@ngrx/store";
import { FortifiedHomeModel } from '@property/components/forms/fortified-home-form/fortified-home-form.model';

export const somePropertyFormAction = createAction('[Property Form] Some');

export const setRoofYearImmutable = createAction(
  '[Property Form] Set Roof Year Immutable',
  props<{ roofYearImmutable: boolean }>()
);

export const setFortifiedHome = createAction(
  '[Property Form] Set Fortified Home',
  props<{ payload: FortifiedHomeModel }>()
);
