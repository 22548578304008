import { ScheduledCategoryOption } from '@core/store/entities/scheduled-categories/scheduled-category.entity';

export interface ScheduledCategoryMetadataType {
  displayGrouping: string;
  maxPerClass?: number;
  maxPerItem?: number;
  minPerClass?: number;
  earthquakeDeductibleMessage?: string;
  itemsOnPublicDisplayRequired?: boolean;
  isIncomeCheckRequired?: boolean;
  displayName?: string; // used only in contextual help content
  availableOptions?: ScheduledCategoryOption[]; // TODO: jewelry only. temp fix for API bug
}

export const EARTHQUAKE_DEDUCTIBLE_MSG =
  '15% deductible applies to breakage caused by earthquake for Fine Arts';
export const INCOME_INELIGIBLE_MSG = 'Income over $5000 is ineligible';

export const JEWELRY_COMBINED_VALUE_MAX = 10000;

export const ScheduledCategoryMetadata: {
  [key: string]: ScheduledCategoryMetadataType;
} = {
  // BLANKET
  JewelryBlanket: {
    displayGrouping: 'Blanket',
    displayName: 'Jewelry',
    maxPerClass: 50000,
    maxPerItem: 10000,
    minPerClass: 1,
  },
  CameraBlanket: {
    displayGrouping: 'Blanket',
    displayName: 'Camera',
    maxPerClass: 5000,
    maxPerItem: 2500,
    minPerClass: 1,
  },
  FineArtsBlanket: {
    displayGrouping: 'Blanket',
    earthquakeDeductibleMessage: EARTHQUAKE_DEDUCTIBLE_MSG,
    displayName: 'Fine Arts',
    maxPerClass: 50000,
    maxPerItem: 10000,
    minPerClass: 1,
  },
  FirearmsBlanket: {
    displayGrouping: 'Blanket',
    displayName: 'Firearms',
    maxPerClass: 5000,
    maxPerItem: 2500,
    minPerClass: 1,
  },
  FursBlanket: {
    displayGrouping: 'Blanket',
    displayName: 'Furs',
    maxPerClass: 50000,
    maxPerItem: 10000,
    minPerClass: 1,
  },
  GolfEquipmentBlanket: {
    displayGrouping: 'Blanket',
    displayName: 'Golf Equipment',
    maxPerClass: 5000,
    maxPerItem: 2500,
    minPerClass: 1,
  },
  MusicNonProfessionalBlanket: {
    displayGrouping: 'Blanket',
    displayName: 'Musical Instruments',
    maxPerClass: 5000,
    maxPerItem: 2500,
    minPerClass: 1,
  },
  // PLUS
  BoatDocksLifts: {
    displayGrouping: 'Plus',
  },
  CameraNonProfessional: {
    displayGrouping: 'Plus',
  },
  CameraProfessional: {
    displayGrouping: 'Plus',
    isIncomeCheckRequired: true,
  },
  Coins: {
    displayGrouping: 'Plus',
    itemsOnPublicDisplayRequired: true,
  },
  ComputerOffPremise: {
    displayGrouping: 'Plus',
  },
  ComputerOnPremise: {
    displayGrouping: 'Plus',
  },
  FineArts: {
    displayGrouping: 'Plus',
    earthquakeDeductibleMessage: EARTHQUAKE_DEDUCTIBLE_MSG,
  },
  FineArtsBreakage: {
    displayGrouping: 'Plus',
    earthquakeDeductibleMessage: EARTHQUAKE_DEDUCTIBLE_MSG,
  },
  Fur: {
    displayGrouping: 'Plus',
  },
  Golf: {
    displayGrouping: 'Plus',
  },
  Jewelry: {
    displayGrouping: 'Plus',
    availableOptions: [
      {
        value: '5%',
        priority: '5',
        code: '5',
      },
      {
        value: '10%',
        priority: '10',
        code: '10',
      },
      {
        value: 'None',
        priority: '15',
        code: '0',
      },
    ],
  },
  MusicNonProfessional: {
    displayGrouping: 'Plus',
  },
  MusicProfessional: {
    displayGrouping: 'Plus',
    isIncomeCheckRequired: true,
  },
  Silverware: {
    displayGrouping: 'Plus',
  },
  Stamp: {
    displayGrouping: 'Plus',
    itemsOnPublicDisplayRequired: true,
  },
};
