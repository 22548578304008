import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';

export const getPageAlertState = createSelector(
  fromCore.getCoreState,
  (state) => state.pageAlert
);

export const getPageAlertsLoaded = createSelector(
  getPageAlertState,
  (state) => state.loaded
);
