<bolt-notification type="error">
  <!--TODO awaiting UX guidance-->
  <div class="message">
    {{ message$ | async }}
    <a
      href="#"
      (click)="onRemediate($event)"
      class="remediation"
      *ngIf="error.remediation"
      >{{ error.remediation.description }}</a
    >
    <!-- TODO More UX Guidance -->
    <bolt-icon
      (click)="onDismiss($event)"
      name="xmark"
      title="dismiss"
    ></bolt-icon>
  </div>
</bolt-notification>
