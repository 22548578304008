import { createAction, props } from '@ngrx/store';
import { PolicyHolderEntity } from '@core/store/entities/policyholder/policyholder.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { MemberEntity } from '../member/member.reducer';
import { MemberModel } from '@core/models/views/person.model';
import { PolicyRoleSequence } from '@core/models/entities/base.entity';
import { ProductType } from '@core/models/api/dsm-types';
import { PolicyRolesPerson, PolicyRolesResponse } from '@app/policy-roles-api/policy-roles-api.service';
import { PolicyHolderRequest } from '@core/models/api/request/update-policyholder-request.model';

export const upsertPolicyHolder = createAction(
  '[Policyholder] Add or Update Policyholder',
  props<{ payload: PolicyHolderEntity; correlationId?: string }>()
);

export const upsertPolicyHolderError = createAction(
  '[Policyholder] Add or Update Policyholder Error',
  props<{
    error: ErrorModel;
    policyHolderId: number;
    correlationId?: string;
  }>()
);

export const upsertPolicyHolderSuccess = createAction(
  '[Policyholder] Add or Update Policyholder Success',
  props<{ payload: MemberEntity; correlationId?: string }>()
);

export const deletePolicyHolder = createAction(
  '[Policyholder] Delete Policyholder',
  props<{ policyHolder: PolicyHolderEntity; correlationId?: string }>()
);

export const deletePolicyHolderError = createAction(
  '[Policyholder] Delete Policyholder Error',
  props<{
    error: ErrorModel;
    policyHolder: PolicyHolderEntity;
    correlationId?: string;
  }>()
);

export const deletePolicyHolderSuccess = createAction(
  '[Policyholder] Delete Policyholder Success',
  props<{
    payload: MemberEntity;
    correlationId?: string;
    request: PolicyHolderRequest;
  }>()
);

export const updatePniBeforeProducts = createAction(
  '[Policyholder] Update PNI Before Products',
  props<{
    pni: MemberModel,
    policyHolderIds: number[],
  }>()
);

export const patchPolicyRoles = createAction(
  '[Policy Roles] Patch',
  props<{
    productType: ProductType,
    quoteId: string,
    changes: PolicyRolesPerson[],
    correlationId: string,
  }>()
);

export const patchPolicyRolesSuccess = createAction(
  '[Policy Roles] Patch Success',
  props<{
    correlationId: string,
    response: PolicyRolesResponse,
    productType: ProductType,
    request: PolicyRolesPerson[],
  }>()
);

export const patchPolicyRolesError = createAction(
  '[Policy Roles] Patch Error',
  props<{
    correlationId: string,
    error: ErrorModel,
    productType: ProductType,
  }>()
);
