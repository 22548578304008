import { maskPii } from '@core/helper/form-hacks';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, throwError } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { SplunkAdapter } from '../adapters/splunk.adapter';
import {
  LogApiInfo,
  LogApiName,
  LogEvent,
  LogEventName,
  LogHttpMethod,
  LogUser,
} from '../models/api/log.model';
import {
  getQuoteState,
  getSessionId,
  getUserType,
} from '../store/entities/session/session.selector';
import { UserModel } from '../store/entities/user/user.model';
import { getUser } from '../store/entities/user/user.selector';
import { AppConfigService } from './app-config.service';
import { LogRedactionService } from './log-redaction.service';
import { SessionService } from './session.service';
import { ProductsService } from './products.service';
import { StringUtils } from '@shared/utils/string.utils';
import { NavigationService } from './navigation.service';
import { selectCurrentPageId } from '@core/store/entities/navigation/navigation.selector';
import { PageIdentifier } from '@core/constants/pages';

// Anything JSON.stringify() will accept -- all but undefined and function.
export type Loggable = object | string | number | null | boolean;
export type UiLogEventType = 'task' | 'navigation' | 'error' | 'validation';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private apiInfoByCorrelationId: { [correlationId: string]: LogApiInfo } = {};

  constructor(
    private window: Window,
    private appConfigService: AppConfigService,

    private logRedactionService: LogRedactionService,
    private sessionService: SessionService,
    private productsService: ProductsService,
    private splunkAdapter: SplunkAdapter,
    private navigationService: NavigationService,
    private store: Store
  ) {}

  /**
   * Log an API request.
   * Returns correlationId, randomly generated.
   * Caller must later call logApiResponse() or logApiError() with that correlationId.
   */
  logApiRequest(
    method: LogHttpMethod,
    url: string,
    logicalName: LogApiName,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any
  ): string {
    const correlationId = StringUtils.generateUuid();
    const apiInfo: LogApiInfo = {
      method,
      url,
      logicalName,
      correlationId,
    };
    if (!this.appConfigService.isProd()) {
      apiInfo.plsEnv = this.appConfigService.config.targetEnv;
    }
    this.apiInfoByCorrelationId[correlationId] = apiInfo;
    this.wrapAndLog({
      evtType: 'API',
      event: `${logicalName}-request` as LogEventName,
      apiInfo,
      message: body ? maskPii(body) : '',
    });
    return correlationId;
  }

  logApiResponse(
    correlationId: string,
    responseCode: number,
    body?: string | object | null
  ): void {
    const apiInfo = this.fetchAndRemoveApiInfo(correlationId);
    apiInfo.responseCode = responseCode;
    this.wrapAndLog({
      evtType: 'API',
      event: `${apiInfo.logicalName}-response` as LogEventName,
      apiInfo,
      message: maskPii(body) || '',
    });
  }

  /**
   * Returns an Observable which rethrows the error.
   */
  logApiError(
    correlationId: string,
    message?: string | object,
    responseCode: number = 0
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    const apiInfo = this.fetchAndRemoveApiInfo(correlationId);
    apiInfo.responseCode = responseCode;
    this.wrapAndLog({
      evtType: 'API',
      event: `${apiInfo.logicalName}-error` as LogEventName,
      apiInfo,
      message,
    });
    return throwError(message || '');
  }

  private fetchAndRemoveApiInfo(correlationId: string): LogApiInfo {
    let apiInfo = this.apiInfoByCorrelationId[correlationId];
    if (apiInfo) {
      delete this.apiInfoByCorrelationId[correlationId];
    } else {
      apiInfo = {
        url: 'unknown',
        method: 'unknown',
        logicalName: 'unknown',
        correlationId,
      };
    }
    return apiInfo;
  }

  logUiEvent(
    name: LogEventName,
    message?: string | object | any,
    type?: UiLogEventType,
    page?: PageIdentifier
  ): void {
    if (
      !type &&
      (name.startsWith('load-page') || name.startsWith('submit-page'))
    )
      type = 'navigation';
    if (name === ('load-page-home' as LogEventName)) {
      this.wrapAndLog({
        evtType: 'UI',
        event: name,
        message,
      });
      return;
    }
    combineLatest([
      this.productsService.getSelectedProductTypes(),
      this.sessionService.getQuoteState(),
    ])
      .pipe(
        take(1),
        tap(([products, state]) => {
          if (typeof message === 'object') {
            products =
              products && products.length ? products : message.products;
            state = state ? state : message.quoteState;
            delete message.products;
            delete message.state;
          }
          this.wrapAndLog({
            evtType: 'UI',
            event: name,
            message,
            products,
            quoteState: state,
          });
        })
      )
      .subscribe();
  }

  logBusinessEvent(name: LogEventName, message?: string | object): void {
    if (
      name === ('pivot-to-pc' as LogEventName) ||
      name === ('display-pivot-to-pc' as LogEventName)
    ) {
      combineLatest([
        this.productsService.getSelectedProductTypes(),
        this.navigationService.getCurrentPage(),
      ])
        .pipe(
          take(1),
          tap(([products, page]) => {
            this.wrapAndLog({
              evtType: 'Business',
              event: name,
              message,
              products,
              launchUrl: this.window.location.href,
              pivotReason: (message as any)?.reason || message || '',
              pivotLocation: page?.name,
            });
          })
        )
        .subscribe();
    } else {
      this.wrapAndLog({
        evtType: 'Business',
        event: name,
        message,
      });
    }
  }

  debug(message?: string | object): void {
    this.wrapAndLog({
      evtType: 'Debug',
      event: 'debug',
      message,
    });
  }

  trace(message?: string | object): void {
    this.wrapAndLog({
      evtType: 'Debug',
      event: 'trace',
      message,
    });
  }

  /**
   * Fill in defaults and pass along to log().
   */
  wrapAndLog(input: Partial<LogEvent>): void {
    combineLatest([
      this.store.select(getSessionId).pipe(take(1)),
      this.store.select(getUser).pipe(take(1)),
      this.store.select(getQuoteState).pipe(take(1)),
      this.store.select(getUserType).pipe(take(1)),
      this.store.select(selectCurrentPageId).pipe(take(1)),
    ]).subscribe(([sessionId, user, state, userType, page]) => {
      this.log({
        app: 'AgencyExpress',
        env: this.appConfigService.config?.environmentName,
        evtType: 'Debug',
        event: 'debug',
        timestamp: new Date().toISOString(),
        user: this.userFromStoreModel(user, state, userType) as LogUser,
        sessionId,
        quoteState: state,
        page: page,
        ...input,
      });
    });
  }

  private userFromStoreModel(
    input?: UserModel,
    state?: string,
    userType?: string
  ): LogUser | undefined {
    if (!input) {
      return undefined;
    }
    const output: LogUser = {
      initiatedBy: input.initiatedBy,
    };
    if (input.firstName) {
      output.firstName = input.firstName;
    }
    if (input.lastName) {
      output.lastName = input.lastName;
    }
    if (input.userId) {
      output.userId = input.userId;
    }
    if (state) {
      output.quoteState = state;
    }
    if (input.authMethod) {
      output.authMethod = input.authMethod;
    }
    if (input.identityMethod) {
      output.identityMethod = input.identityMethod;
    }
    if (input.nwieId) {
      output.nwieId = input.nwieId;
    }
    if (input.racfId) {
      output.racfId = input.racfId;
    }
    if (input.realm) {
      output.realm = input.realm;
    }
    if (userType) {
      output.userType = userType;
    }

    const role = input.role || this.sessionService.fetchRole();
    if (role) {
      output.role = role;
    }
    return output;
  }

  log(event: LogEvent): void {
    event = this.logRedactionService.redact(event) as LogEvent;
    if (event) {
      if (!this.appConfigService.isLocal()) {
        this.logToSplunk(event);
      }
      if (!this.appConfigService.isProd()) {
        this.logToConsole(event);
      }
    }
  }

  private logToSplunk(event: LogEvent): void {
    this.splunkAdapter.log(event);
  }

  private logToConsole(event: LogEvent): void {
    const text = this.formatEventForConsole(event);
    // window.console exists in normal builds but not in tests
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.window as any).console.log(text);
  }

  private formatEventForConsole(event: LogEvent): string {
    let body: string;
    if (!event.message) {
      body = '';
    } else if (typeof event.message === 'string') {
      body = event.message;
    } else {
      body = JSON.stringify(event.message);
    }
    if (event.evtType === 'API') {
      const words = event.event.split('-');
      switch (words[words.length - 1]) {
        case 'request':
          return `LOG:REQUEST:${event.apiInfo?.logicalName}: ${
            body || event.apiInfo?.url
          }`;
        case 'response':
          return `LOG:RESPONSE:${event.apiInfo?.logicalName}: ${body}`;
        case 'error':
          return `LOG:ERROR:${event.apiInfo?.logicalName}: ${body}`;
      }
    }
    return `LOG:${event.evtType}:${event.event} ${body}`;
  }
}
