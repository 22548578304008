import { Action, createSelector } from '@ngrx/store';
import { getCoreState } from '../../../reducers';
import { getAllFeatureFlags } from '../../feature-flag/feature-flag.selector';
import {
  PageSortedTasks,
  TaskEntityType,
  TaskModel,
} from '../task.model';
import {
  ProductType,
  QuoteStatus,
  quoteStatusGreaterThan,
} from '@core/models/api/dsm-types';
import { TaskUtils } from '../utils/task.util';
import { PageIdentifier } from '@core/constants/pages';
import * as fromTasks from '@core/store/entities/task/task.reducer';
import { removeProduct } from '../../product/product.actions';
import { navigateToPage } from '../../navigation/navigation.action';
import { openInPolicyCenter } from '../task.action';
import {
  getAllVehicles,

} from '@core/store/entities/vehicle/vehicle.selector';
import {
  getOverallFurthestQuoteStatus,
  getSelectedBlockedProductEntities,
  getSelectedProductEntitiesWithErrors,
  getSelectedProductTypes,
  isProductSelected,
} from '@core/store/entities/product/product.selectors';
import { getAllSelectedMemberModels } from '../../member/member.selector';
import {
  hasHubPageLoaded,
  getSessionState,
} from '../../session/session.selector';
import {
  isAnyTelematicsEnrolled,
  getTelematicsConfirmed,
} from '../../telematics/telematics.selector';

// get rid of this
import { getAllCoveredLocations } from '../../covered-location/covered-location.selector';
import {
  getAllCoverages,

} from '../../coverage/coverage.selector';
import { MemberEntity } from '@entities/member/member.reducer';
import { selectCurrentPage } from '@entities/navigation/navigation.selector';
import { EligibilityFormSelectors, VehicleExposureSelectors } from '@core/store/selectors';
import { getDummyProducerContent } from '@core/store/retrieve/retrieve.selector';
import * as PropertyFormSelectors from '@core/store/property-form/property-form.selector';
import { hasUpdatedRegistrationForm } from '@forms-store/store/models/account-registration/account-registration.selector';
import * as MemberSelectors from '@core/store/entities/member/member.selector';
import * as SessionSelectors from '@core/store/entities/session/session.selector';
import {
  getPropertyCoverageTasks,
  getUmbrellaCoverageTasks,
  getVehicleCoverageTasks,
} from '@entities/task/selectors/coverage-task.selector';
export interface NotificationModel {
  message: string;
  elementId?: string;
  pageUrl?: string;
  resolve?: Action;
  resolveLabel?: string;
}

export const getTaskState = createSelector(getCoreState, (state) => state.task);

export const getTaskById = (id: number) =>
  createSelector(getTaskState, (state) => state.entities[id]);

export const {
  selectAll: selectAllTasks,
  selectEntities: selectTaskEntities,
  selectTotal: selectTotalTasks,
} = fromTasks.adapter.getSelectors(getTaskState);

function compareTaskBuckets(a: TaskModel[], b: TaskModel[]): number {
  const apage = a[0].page || { index: 0 };
  const bpage = b[0].page || { index: 0 };
  // TODO Business rules around Task order
  if (apage?.index < bpage?.index) {
    return -1;
  }
  if (apage?.index > bpage?.index) {
    return 1;
  }
  return 0;
}

export const selectAllTasksHubGuard = createSelector(
  selectAllTasks,
  hasHubPageLoaded,
  (tasks, hubLoaded) => (hubLoaded ? tasks : [])
);

export const groupTasksByPage = createSelector(getTaskState, (state) =>
  (Object.values(state.entities) as TaskModel[])
    .reduce((a: TaskModel[][], v) => {
      const index = a.findIndex((b) => b[0].page === v?.page);
      let bucket: TaskModel[];
      if (index >= 0) {
        bucket = a[index];
      } else {
        bucket = [];
        a.push(bucket);
      }
      bucket.push(v);
      return a;
    }, [] as TaskModel[][])
    .sort(compareTaskBuckets)
);

export const getIncompleteTasks = createSelector(
  selectAllTasks,
  getOverallFurthestQuoteStatus,
  (tasks, status) =>
    tasks.filter(
      (t) =>
        !t.completed &&
        !quoteStatusGreaterThan(t.ratingStatusOrdinal || 'Draft', status)
    )
);

export const getIncompleteDraftTasks = createSelector(
  getIncompleteTasks,
  (tasks) =>
    tasks.filter((t) => t.ratingStatusOrdinal === 'Draft') as TaskModel[]
);

export const getIncompleteTasksOfStatus = (quoteStatus: QuoteStatus) =>
  createSelector(getIncompleteTasks, (tasks) =>
    tasks.filter((t) => t.ratingStatusOrdinal === quoteStatus)
  );

export const getCompletedTasks = createSelector(
  selectAllTasks,
  (tasks) => tasks.filter((t) => t?.completed) as TaskModel[]
);

export const getIncompleteTasksForPage = (pageId: string) =>
  createSelector(getIncompleteTasks, hasHubPageLoaded, (tasks, hubLoaded) =>
    hubLoaded
      ? (tasks.filter((t) => t?.page?.id === pageId) as TaskModel[])
      : []
  );

export const getTaskNotificationsForPage = (pageName: string) =>
  createSelector(getIncompleteTasksForPage(pageName), (tasks) =>
    tasks.map((task) => ({
      message: task.entityFriendlyId
        ? task.entityFriendlyId + ' - ' + task.message
        : task.message || '',
      elementId: task.elementId,
      pageUrl: task.page?.url,
    }))
  );

export const selectAllTasksByTaskEntityType = (
  taskEntityTypes: TaskEntityType[]
) =>
  createSelector(
    selectAllTasks,
    (tasks) =>
      tasks.filter((task) =>
        taskEntityTypes.includes(task?.entityType as TaskEntityType)
      ) as TaskModel[]
  );

export const selectIncompleteTasksByTaskEntityType = (
  taskEntityTypes: TaskEntityType[]
) =>
  createSelector(
    getIncompleteTasks,
    (incompleteTasks) =>
      incompleteTasks.filter((incompleteTask) =>
        taskEntityTypes.includes(incompleteTask?.entityType as TaskEntityType)
      ) as TaskModel[]
  );

export const getCoverageTasks = createSelector(
  selectAllTasks,
  (tasks) => tasks.filter((t) => t?.entityType === 'coverage') as TaskModel[]
);

export const getAccountTasks = createSelector(
  getIncompleteTasks,
  (tasks) => tasks.filter((t) => t.entityType === 'account') as TaskModel[]
);

export const getUmbrellaTasks = createSelector(
  getIncompleteTasks,
  (tasks) => tasks.filter((t) => t?.entityType === 'umbrella') as TaskModel[]
);

export const getReviewTasks = createSelector(
  selectAllTasks,
  (tasks) => tasks.filter((t) => t?.entityType === 'review') as TaskModel[]
);

export const getEligibilityTasks = createSelector(
  selectAllTasks,
  (tasks) => tasks.filter((t) => t?.entityType === 'eligibility') as TaskModel[]
);

export const getEffectiveDateTasks = createSelector(
  getIncompleteTasks,
  (tasks) => tasks.filter((t) => t?.field === 'effectiveDate') as TaskModel[]
);

export const getTaskByElementId = (elementId: string) =>
  createSelector(getIncompleteTasks, (tasks) =>
    tasks.find((t) => t.elementId === elementId)
  );

export const generateAccountTasks = createSelector(
  hasUpdatedRegistrationForm,
  MemberSelectors.getPniEmail,
  SessionSelectors.getIsRetrieve,
  (hasUpdatedRegistration, pniEmail, isRetrieve) => {
    return TaskUtils.generateAccountTasks({
      hasUpdatedRegistration,
      pniEmail,
      isRetrieve,
    });
  }
);

export const updateAccountTasks = createSelector(
  getAccountTasks,
  generateAccountTasks,
  (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
);

export const generateCoverageTasks = createSelector(
  getPropertyCoverageTasks,
  getVehicleCoverageTasks,
  getUmbrellaCoverageTasks,
  (propertyCoverageTasks,
   vehicleCoverageTasks,
   umbrellaCoverageTasks) => {
    return [
      ...propertyCoverageTasks,
      ...vehicleCoverageTasks,
      ...umbrellaCoverageTasks
    ];
  }
);

export const updateCoverageTasks = createSelector(
  getCoverageTasks,
  generateCoverageTasks,
  (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
);

export const generateGeneralTasks = createSelector(
  getCoreState,
  // TODO what state is required?
  () => TaskUtils.generateGeneralTasks()
);

// Break this up
export const generateReviewTasks = createSelector(
  getSelectedProductTypes,
  getAllVehicles,
  getAllSelectedMemberModels,
  // getSelectedNonRentalPropertyProduct,
  getAllFeatureFlags,
  getSessionState,
  isAnyTelematicsEnrolled,
  getAllCoveredLocations,
  EligibilityFormSelectors.getAllEligibilityForms,
  getDummyProducerContent,
  getAllCoverages,
  PropertyFormSelectors.getRoofYearImmutable,
  VehicleExposureSelectors.countAllVehicleLikeExposures,
  (
    selectedProductTypes,
    vehicles,
    drivers,
    featureFlags,
    sessionState,
    telematicsEnrolled,
    coveredLocations,
    eligibilityForms,
    dummyProducerContent,
    coverages,
    roofYearImmutable,
    exposureCount
  ) =>
    TaskUtils.generateReviewTasks(
      selectedProductTypes,
      vehicles,
      drivers as MemberEntity[],
      featureFlags,
      sessionState,
      telematicsEnrolled,
      exposureCount,
      coveredLocations,
      eligibilityForms,
      dummyProducerContent,
      coverages,
      roofYearImmutable
    )
);

export const updateReviewTasks = createSelector(
  getReviewTasks,
  getEligibilityTasks,
  generateReviewTasks,
  (reviewTasks, eligibilityTasks, newTasks) => ({
    ...TaskUtils.createUpdatedTaskObject(newTasks, [
      ...reviewTasks,
      ...eligibilityTasks,
    ]),
    // The nature of "review" tasks is that once completed, they won't prompt again:
    uncomplete: [],
  })
);

export const allTasksComplete = createSelector(
  getIncompleteTasks,
  (incompleteTasks) => incompleteTasks.length === 0
);

export const allHubTasksComplete = createSelector(
  allTasksComplete,
  getTelematicsConfirmed,
  isProductSelected('PersonalAuto'),
  (tasksComplete, telematicsConfirmed, autoSelected) =>
    tasksComplete && (telematicsConfirmed || !autoSelected)
);

export const getShowTasks = createSelector(
  getTaskState,
  (taskState) => taskState.showTasks
);

// TODO - This is a problem, and we probably need something like
// GetAllXTasks That compares to its product Status instead of
// comparing to the overall
export const getPageSortedTasks = createSelector(
  selectAllTasks,
  getOverallFurthestQuoteStatus,
  (tasks, status) => {
    const sortedTasks = tasks
      .filter(
        (t) =>
          !t.completed &&
          t.entityType !== 'review' &&
          !quoteStatusGreaterThan(t.ratingStatusOrdinal || 'Draft', status)
      )
      .reduce((sorted, task) => {
        if (!sorted[task?.page?.id as PageIdentifier]) {
          sorted[task?.page?.id as PageIdentifier] = [
            {
              title: task.overrideTitle || task.page?.name || 'Tasks',
              taskList: [task],
              page: task.page,
              action:
                task.overrideAction !== undefined
                  ? task.overrideAction
                  : navigateToPage({ pageUrl: task.page?.url || '' }),
              actionMessage:
                task.overrideActionMessage || `Update ${task?.page?.name}`,
              icon: 'exclamation-triangle-filled',
              buttonType: 'warning-button',
            },
          ];
        } else {
          sorted[task?.page?.id as PageIdentifier][0].taskList.push(task);
        }

        return sorted;
      }, {} as PageSortedTasks);

    return Object.values(sortedTasks);
  }
);

export const getErrorProductsAsSortedTasks = createSelector(
  getSelectedProductEntitiesWithErrors,
  (products) => {
    const pageSortedTasks: PageSortedTasks = {};
    products.map((product) => {
      pageSortedTasks[product.type as ProductType] = [
        {
          title: product.name || '',
          taskList: [
            {
              ratingStatusOrdinal: product?.quoteStatus || 'Draft',
              message:
                product.errorMessage || `${product.type} requires your review.`,
              productType: 'All',
              field: '',
            },
          ],
          action: removeProduct({ payload: product.type }),
          actionMessage: 'Remove ' + product.name,
          icon: 'exclamation-circle-filled',
          buttonType: 'error-button',
        },
      ];
    });

    return Object.values(pageSortedTasks);
  }
);

// don't be fooled by the generic-sounding name. this is specific to MSA DL problems in CA.
export const getBlockedProductsAsSortedTasks = createSelector(
  getSelectedBlockedProductEntities,
  (products) => {
    const pageSortedTasks: PageSortedTasks = {};
    products.map((product) => {
      pageSortedTasks[product.type as ProductType] = [
        {
          title: `${product.name} policy blocked`,
          taskList: [
            {
              entityType: 'blocked',
              ratingStatusOrdinal: product?.quoteStatus || 'Draft',
              message: `<p class="bolt-body-copy-sm"><strong>Verification of license status can't be completed in this system.</strong></p><p class="bolt-body-copy-sm">Verification of license status must be sent to an underwriter. Pivot to PolicyCenter to submit it for verification. (Submission ID: ${product.quoteId})</p>`,
              productType: 'All',
              field: '',
              resolve: openInPolicyCenter({
                quoteId: product.quoteId || '',
                reason: 'msa CA unverifiable license status',
              }),
              resolveLabel: 'Open in PolicyCenter',
            },
          ],
          action: removeProduct({ payload: product.type }),
          actionMessage: 'Remove policy to continue',
          icon: 'exclamation-circle-filled',
          buttonType: 'error-button',
        },
      ];
    });

    return Object.values(pageSortedTasks);
  }
);

export const getSortedReviewTasks = createSelector(
  getReviewTasks,
  getOverallFurthestQuoteStatus,
  (tasks, status) => {
    const sortedTasks = tasks
      .filter(
        (t) =>
          !t.completed &&
          !quoteStatusGreaterThan(t.ratingStatusOrdinal || 'Draft', status)
      )
      .reduce((sorted, task) => {
        if (!sorted[task?.page?.id as PageIdentifier]) {
          sorted[task?.page?.id as PageIdentifier] = [
            {
              title:
                task.overrideTitle ||
                `Review ${task.page?.name}` ||
                'Review Tasks',
              taskList: [task],
              page: task.page,
              action:
                task.overrideAction !== undefined
                  ? task.overrideAction
                  : navigateToPage({ pageUrl: task.page?.url || '' }),
              actionMessage: task.actionMessage || `Review Task`,
              flipActionButtons: task.flipActionButtons || false,
              icon: 'info-circle-filled',
              buttonType: 'info-button',
            },
          ];
        } else {
          sorted[task?.page?.id as PageIdentifier][0].taskList.push(task);
        }

        return sorted;
      }, {} as PageSortedTasks);

    return Object.values(sortedTasks);
  }
);

export const getIncompleteTasksForCurrentPage = createSelector(
  selectCurrentPage,
  getIncompleteTasks,
  hasHubPageLoaded,
  (currentPage, tasks, hubLoaded) =>
    hubLoaded
      ? (tasks.filter(
          (t) => t?.page?.id === currentPage?.id && !t.completed
        ) as TaskModel[])
      : []
);

export const getTaskNotificationsForCurrentPage = createSelector(
  getIncompleteTasksForCurrentPage,
  (tasks) =>
    tasks.map((task) => ({
      message: task.entityFriendlyId
        ? task.entityFriendlyId + ' - ' + task.message
        : task.message || '',
      elementId: task.elementId,
      pageUrl: task.page?.url,
      resolve: task.resolve,
      resolveLabel: task.resolveLabel,
    }))
);

export const getTaskByFieldId = (fieldId: string) =>
  createSelector(getIncompleteTasks, (tasks) => {
    return tasks.find((t) => t.field === fieldId);
  });
