import { createSelector } from '@ngrx/store';
import * as fromForms from '../../../../forms-store/store/reducers';
import * as fromProducts from '@core/store/entities/product/product.selectors';
import * as fromMetaData from '@core/store/entities/metadata/metadata.selector';
import * as PreBindDocumentSelectors from '@core/store/entities/pre-bind-documents/pre-bind-documents.selector';

export const getConfirmationsState = createSelector(
  fromForms.getFormsState,
  (state) => state.confirmations
);

export const getPreBindDocumentsForm = createSelector(
  getConfirmationsState,
  (state) => state.preBindDocumentsForm
);

export const preBindDocumentsAcknowledged = createSelector(
  getPreBindDocumentsForm,
  (state) => {
    if (
      state.hasReceivedDocuments &&
      state.hasReviewedDocuments &&
      state.doesNotHaveQuestions
    ) {
      return true;
    } else {
      return false;
    }
  }
);

export const preBindDocumentsRequired = createSelector(
  fromMetaData.getStateSpecificFlag('preBindDocuments'),
  fromProducts.getSelectedProductsWithoutErrors,
  (preBindDocumentsState, products) => {
    if (
      preBindDocumentsState &&
      products?.find((product) =>
        ['PersonalAuto', 'MSA'].includes(product.type)
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
);

export const getPreBindTaskStatus = createSelector(
  preBindDocumentsRequired,
  preBindDocumentsAcknowledged,
  PreBindDocumentSelectors.getPreBindDocumentsUploading,
  PreBindDocumentSelectors.getPreBindDocumentsUploaded,
  (docsRequired, docsAcknowledged, loading, loaded) => {
    if (!docsRequired) {
      return 'not needed';
    }
    if (docsAcknowledged) {
      return 'ready';
    }
    if (loading) {
      return 'pending';
    }
    if (loaded) {
      return 'confirm';
    }
    return 'upload';
  }
);

export const getDwellingReplacementCostConfirmation = createSelector(
  getConfirmationsState,
  (state) => state.dwellingReplacementCostForm.confirmation
);
