import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { CommonFormModule } from '@shared/modules/common-form.module';

@NgModule({
  declarations: [CardComponent],
  imports: [CommonModule, CommonFormModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [CardComponent],
})
export class CardModule {}
