import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  forwardRef
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

/**
 * Mimics the style of <bolt-select>, but permits dynamic reassignment of options.
 * Real <bolt-select> (v5.2.0) is riddled with bugs, in particular it is not possible to 
 * set `value` before the corresponding <option> is attached.
 * (and on top of that, its internals operate asynchronously, so it's impossible 
 * for us to know when that attachment has completed).
 * <unbolt-select> should look just like <bolt-select> and act just like <select>.
 */
@Component({
  selector: 'unbolt-select',
  templateUrl: './unbolt-select.component.html',
  styleUrls: ['./unbolt-select.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UnboltSelectComponent), multi: true },
  ],
})
export class UnboltSelectComponent implements ControlValueAccessor, OnDestroy, AfterViewInit {
  @Input() label = '';
  @Input() arialabel = '';
  @Input() arialabelledby = '';
  //@Input('value') value = ''; // Bolt has this but we won't -- use Angular Forms to access the value.
  //@Input('disabled') disabled: string | null = null; // ''
  //@Input('invalid') invalid: string | null = null; // '' what even is this?
  @Input() error = '';
  @Input() required: string | null = null; // not implemented
  @Input() optionaltext: 'show' | 'hide' = 'show'; // not implemented
  @Input() width: 'full' | 'fit-content' | string = 'full'; // not implemented
  @Input() placeholder = 'select'; // not implemented
  @Input() placeholdervalue = ''; // not implemented, whatever this is

  @ViewChild('select', { read: ElementRef }) select: ElementRef | null = null;
  private mutationObserver = new MutationObserver(e => this.onMutation(e));
  onChange = (v: any) => {};
  onTouched = () => {};
  value = '';
  elementId = `unbolt-select-${UnboltSelectComponent.nextDiscriminator++}`;
  static nextDiscriminator = 1;

  ngAfterViewInit(): void {
    if (this.select?.nativeElement) {
      this.pokeUiValue();
      this.mutationObserver.observe(this.select.nativeElement, { childList: true });
      this.select.nativeElement.addEventListener('change', () => this.onUiChanged());
    }
  }

  ngOnDestroy(): void {
    this.mutationObserver.disconnect();
  }

  registerOnChange(cb: (v: any) => void): void {
    this.onChange = cb;
  }

  registerOnTouched(cb: () => void): void {
    this.onTouched = cb;
  }

  writeValue(v: any): void {
    this.value = this.sanitizeValue(v);
    this.pokeUiValue();
  }

  private sanitizeValue(v: any): string {
    if (typeof(v) === 'string') {
      return v;
    }
    if (v === 0) {
      return '0';
    }
    return (v || '').toString();
  }

  private pokeUiValue(): void {
    if (this.select?.nativeElement) {
      this.select.nativeElement.value = this.value;
    }
  }

  private onMutation(events: MutationRecord[]): void {
    // Assume that all changes are addition of <option>.
    // We could check that but whatever, poking value is cheaper than that check.
    this.pokeUiValue();
  }

  private onUiChanged(): void {
    const v = this.sanitizeValue(this.select?.nativeElement?.value);
    this.onChange(v);
    this.onTouched();
  }
}
