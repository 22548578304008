import { Injectable } from '@angular/core';
import { DriverVehiclePrefillService } from '@core/services/driver-vehicle-prefill.service';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import {
  catchError,
  concatMap,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromDriverVehiclePrefillActions from '@core/store/entities/driver-vehicle-prefill/driver-vehicle-prefill.action';
import * as fromSelectors from '@core/store/entities/driver-vehicle-prefill/driver-vehicle-prefill.selector';
import { VehicleBuilder } from '@shared/utils/builders/vehicle.builder';
import { VehicleService } from '@core/services/vehicle.service';
import { ProductsService } from '@core/services/products.service';
import { DriverBuilder } from '@shared/utils/builders/driver.builder';
import { MemberActions } from '@core/store/actions';

@Injectable()
export class DriverVehiclePrefillEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private driverVehiclePrefillService: DriverVehiclePrefillService,
    private vehicleService: VehicleService,
    private productsService: ProductsService,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  getCustomersInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDriverVehiclePrefillActions.getCustomersInfo),
      switchMap(() =>
        this.store.select(fromSelectors.buildCustomersInfoRequest).pipe(take(1))
      ),
      switchMap((request) =>
        this.driverVehiclePrefillService.customersInfo(request).pipe(
          withLatestFrom(
            this.vehicleService.getAllAutoVehicleModels(),
            this.productsService.getSelectedActiveProductTypes()
          ),
          concatMap(([response, currentVehicles, products]) => {
            if (!response.drivers && !response.vehicles) {
              return from([
                fromDriverVehiclePrefillActions.getCustomersInfoNoHit(),
              ]);
            }

            const actionsArray: Action[] = [];

            const vehicleModels =
              VehicleBuilder.buildVehicleEntitiesFromPrefillResponse(
                response.vehicles,
                request.address,
                currentVehicles
              );

            const driverModels =
              DriverBuilder.buildDriverEntitiesFromPrefillResponse(
                response.drivers
              );

            if (vehicleModels) {
              actionsArray.push(
                fromDriverVehiclePrefillActions.addAllPrefillVehicles({
                  payload: vehicleModels,
                })
              );
            }

            if (driverModels) {
              driverModels.forEach((prefillDriver) =>
                actionsArray.push(
                  MemberActions.addPrefillMember({
                    payload: prefillDriver,
                    products,
                  })
                )
              );
            }

            actionsArray.push(
              fromDriverVehiclePrefillActions.getCustomersInfoSuccess()
            );

            return from(actionsArray);
          }),
          catchError((error) => {
            const safeError = this.errorSanitizerService.sanitizeError(error);
            return of(
              fromDriverVehiclePrefillActions.getCustomersInfoFail({
                payload: safeError,
              })
            );
          })
        )
      )
    )
  );
}
