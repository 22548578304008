import { DsmAdapter } from '@core/adapters/dsm.adapter';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  DocumentsEntity,
  sanitizeDocumentsEntity,
} from '../store/entities/document/document.entity';
import { map } from 'rxjs/operators';
import { DocumentsRequest } from '@core/store/entities/document/document.selectors';
import { ProductType } from '@core/models/api/dsm-types';

@Injectable({
  providedIn: 'root',
})
export class DocumentsAdapter extends DsmAdapter {
  getDocuments(request: DocumentsRequest): Observable<DocumentsEntity> {
    return this.request<DocumentsEntity>(
      sanitizeDocumentsEntity,
      request.productType as ProductType,
      'GET',
      `/policies/${request.policyNumber}/documents`,
      'documents',
      {
        baseUrl: `${this.appConfigService.config.pcEdgePolicyIssueUrl}`,
      }
    ).pipe(
      map((response) => {
        response.productType = request.productType as ProductType;
        return response;
      })
    );
  }
}
