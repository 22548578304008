import { ObjectValidator } from '@core/helper/object-validator';

export interface PreBindDocumentEmailResponse {
  status: number;
  transactionId: string;
  hasError: boolean;
}

export interface PreBindDocumentUploadResponse {
  folderId: string;
  password: string;
  folderUrl: string;
  files?: File[];
}

export interface File {
  name: string;
  url: string;
}

export function sanitizePreBindDocumentEmailResponse(
  input: unknown
): PreBindDocumentEmailResponse {
  return ObjectValidator.forceSchema<PreBindDocumentEmailResponse>(input, {
    status: 'number',
    transactionId: 'string',
    hasError: 'boolean',
  });
}

export function sanitizePreBindDocumentUploadResponse(
  input: unknown
): PreBindDocumentUploadResponse {
  return ObjectValidator.forceSchema<PreBindDocumentUploadResponse>(
    input,
    {
      folderId: 'string',
      password: 'string',
      folderUrl: 'string',
      files: [sanitizeFile],
    },
    ['files']
  );
}

export function sanitizeFile(input: unknown): File {
  return ObjectValidator.forceSchema<File>(input, {
    name: 'string',
    url: 'string',
  });
}
