import { getCoreState } from "@core/store/reducers";
import { createSelector } from "@ngrx/store";
import { OtherStructureCoverage, adapter } from './other-structures.reducer';
import { ProductsSelectors } from "@core/store/selectors";

export const getOtherStructuresState = createSelector(
  getCoreState,
  (state) => state.otherStructures
);

export const {
  selectAll: getAllOtherStructures,
  selectEntities: getOtherStructuresEntities,
  selectTotal: getTotalPremiums,
} = adapter.getSelectors(getOtherStructuresState);

export const selectOtherStructuresEntityByQuoteId = (quoteId: string) => {
  return createSelector(
    getOtherStructuresEntities,
    (entities) => entities[quoteId]
  );
};

export const selectOtherStructuresCoveragesByQuoteId = (quoteId: string) => {
  return createSelector(
    getOtherStructuresEntities,
    (entities) => entities[quoteId]?.otherStructureCoverages || []
  );
};

export const selectOtherStructureCoveragesForMultipleQuoteIds = (quoteIds: string[]) => {
  return createSelector(
    getOtherStructuresEntities,
    (entities) => {
      const coverages: OtherStructureCoverage[] = [];
      for (const quoteId of quoteIds) {
        const newCoverages = entities[quoteId]?.otherStructureCoverages;
        if (newCoverages) coverages.push(...newCoverages);
      }
      return coverages;
    }
  );
};

export const getOtherStructuresValid = createSelector(
  getOtherStructuresEntities,
  ProductsSelectors.getSelectedProducts,
  (entities, products) => {
    for (const product of products) {
      const entity = entities[product.quoteId || ''];
      if (!entity) continue;
      for (const coverage of entity.otherStructureCoverages) {
        if (!coverage.selectedValue) {
          continue;
        }
        if (coverage.additionalDwellingUnits.length > 0) {
          continue;
        }
        return false;
      }
    }
    return true;
  }
);
