import { AppConfigService } from './../services/app-config.service';
import { Role } from './../models/api/dsm-types';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import {
  Router,
  UrlTree,
  RouterStateSnapshot,
  Params,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthUrlService } from '../services/auth-url.service';
import { UserService } from '../services/user.service';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  constructor(
    private authUrlService: AuthUrlService,
    private router: Router,
    private userService: UserService,
    private sessionService: SessionService,
    private window: Window,
    private appConfigService: AppConfigService
  ) {}

  parseFragment(fragmentString: string | null): { [key: string]: string } {
    if (!fragmentString) {
      return {};
    }
    const kvs = fragmentString.split('&');
    const kvList = kvs.map((kvStr) => {
      const kv = kvStr.split('=');
      kv[1] = decodeURIComponent(kv[1]);
      return kv;
    });

    const result: { [key: string]: string } = {};
    kvList.forEach(([k, v]) => {
      if (k === 'state') {
        try {
          v = this.window.atob(v);
        } catch (e) {
          console.error('incoming EUA state not base-64', { v }, e);
        }
        const k1 = v.slice(0, v.indexOf('='));
        const requestedRoute = v.slice(v.indexOf('=') + 1);
        result[k1] = decodeURIComponent(requestedRoute);
      } else {
        result[k] = v;
      }
    });

    return result;
  }

  parseQueryParams(route: string): [string, { [key: string]: string }] {
    const [url, params] = route.split('?');
    if (!params) {
      return [url, {}];
    }

    const queryParams = params
      .split('&')
      .reduce((parms: { [key: string]: string }, kv) => {
        const [key, value] = kv.split('=');
        parms[key] = value;
        return parms;
      }, {});

    return [url, queryParams];
  }

  canActivateChild(
    route: ActivatedRouteSnapshot
  ): boolean | Observable<boolean> | UrlTree | Observable<boolean | UrlTree> {
    const routerState: RouterStateSnapshot = this.router.routerState.snapshot;
    const transformedParams = this.authUrlService.parseRoute(route);
    let jwt = this.sessionService.fetchJwt();
    let accessToken = this.sessionService.fetchAccessToken();
    let role = this.sessionService.fetchRole();
    const fragment = this.parseFragment(route.fragment!);

    const requestedRoute = route.routeConfig?.path;
    const params: Params = {
      requestedRoute,
      ...route.queryParams,
    };

    if (fragment.error) {
      // TODO Report auth errors to user. For now, just breaking the loop.
      console.error(
        `EUA error [${fragment.error}]: ${fragment.error_description}`
      );
      return false;
    }

    if (
      accessToken &&
      jwt &&
      this.sessionService.validateTokenLifetime(accessToken, jwt) == accessToken
    ) {
      const channelFromStorage =
        this.window.localStorage.getItem('agencyChannel');
      this.sessionService.storeJwt(jwt);
      this.sessionService.storeAccessToken(accessToken);
      this.sessionService.updateAccessToken(accessToken);
      if (params.userType) {
        this.sessionService.setUserType(params.userType);
      }
      if (transformedParams.agencyChannel) {
        role = transformedParams.agencyChannel;
        if (role) {
          this.sessionService.storeRole(role);
        }
      } else if (channelFromStorage) {
        this.sessionService.storeRole(channelFromStorage);
      }

      if (role) {
        this.userService.updateUser({
          role: role as Role,
        });
      }
      this.userService.populateUserWithJwt(jwt);
      return true;
    }

    if (transformedParams.access_token) {
      accessToken = transformedParams.access_token;
      if (accessToken) {
        this.sessionService.storeAccessToken(accessToken);
      }
    }
    if (transformedParams.id_token) {
      jwt = jwt_decode(transformedParams.id_token);
      if (jwt) {
        this.sessionService.storeJwt(jwt);
      }
    }
    if (transformedParams.expires_in) {
      this.window.sessionStorage.setItem(
        'expires_in',
        transformedParams.expires_in
      );
    }
    if (transformedParams.agencyChannel) {
      role = transformedParams.agencyChannel;
      if (role) {
        this.sessionService.storeRole(role);
      }
    }

    if (role) {
      this.userService.updateUser({
        role: role as Role,
      });
    }

    if (accessToken !== null) {
      this.sessionService.updateAccessToken(accessToken);
    }

    if (accessToken && jwt) {
      this.userService.populateUserWithJwt(jwt);
      accessToken = this.sessionService.validateTokenLifetime(accessToken, jwt);
      if (accessToken) {
        if (fragment.requestedRoute) {
          const [requested, queryParams] = this.parseQueryParams(
            fragment.requestedRoute
          );
          return this.router.createUrlTree([requested], { queryParams });
        } else {
          return true;
        }
      }
    }

    if (accessToken && !jwt) {
      return this.sessionService.fetchJwtWithAccessToken(accessToken).pipe(
        map((jwtFromEua) => {
          if (jwtFromEua) {
            this.sessionService.storeJwt(jwtFromEua);
            this.userService.populateUserWithJwt(jwtFromEua);
            accessToken = this.sessionService.validateTokenLifetime(
              accessToken,
              jwtFromEua
            );
            if (accessToken) {
              if (fragment.requestedRoute) {
                const [requested, queryParams] = this.parseQueryParams(
                  fragment.requestedRoute
                );
                return this.router.createUrlTree([requested], {
                  queryParams,
                });
              } else {
                return true;
              }
            } else {
              return this.router.createUrlTree(['/splash'], {
                queryParams: params,
              });
            }
          } else {
            return false;
          }
        })
      );
    }

    if (route.queryParams?.error) {
      // Authentication failed
      // TODO: implement ErrorSettableComponent or use some other auth error strategy
      this.window.localStorage.removeItem('agencyChannel');
      return false;
    }

    // Needs authenticated
    return this.userService.getUser().pipe(
      take(1),
      map((user) => {
        const agencyChannel = user?.role;
        if (
          route?.routeConfig?.path === 'retrieve/compRater' ||
          route?.routeConfig?.path === 'search/compRater'
        ) {
          if (agencyChannel === 'NSS') {
            this.authUrlService.performAuthRedirect('IA', route, routerState, {
              preferNwie: true,
            });
          } else {
            this.authUrlService.performAuthRedirect('IA', route, routerState);
          }

          return false;
        }
        if (agencyChannel) {
          // Agency Channel is known, go straight to auth redirect
          this.authUrlService.performAuthRedirect(
            agencyChannel,
            route,
            routerState
          );
          return false;
        }

        const channelFromStorage =
          this.window.localStorage.getItem('agencyChannel');
        if (channelFromStorage) {
          this.sessionService.storeRole(channelFromStorage);
          this.authUrlService.performAuthRedirect(
            channelFromStorage,
            route,
            routerState
          );
          return false;
        }
        return this.router.createUrlTree(['/splash'], {
          queryParams: params,
        });
      })
    );
  }
}
