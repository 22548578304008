import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DisambiguationOption } from '../powersports-year-make-model-form/powersports-year-make-model-form.component';

@Component({
  selector: 'nwx-disambiguation-modal',
  templateUrl: './disambiguation-modal.component.html',
  styleUrls: ['./disambiguation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisambiguationModalComponent implements OnInit {
  @Input() disambiguationOptions!: {
    value: any; //RVModel | BoatModel;
    displayName: string;
  }[];
  @Input() header!: string;
  @Input() subHeader!: string;
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}

  saveSelection(option: DisambiguationOption): void {
    this.modal.close(option.value.modelNumber || option.value.controlNumber);
  }
}
