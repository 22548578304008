import { HydrateQuoteResponse } from '../../adapters/quote-retrieve.adapter';
import { ProductType } from '@core/models/api/dsm-types';
import { QuoteRetrieveResponse } from '@core/models/api/response/retrieve-response.model';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../entities/error/error.model';

export const RETRIEVE_QUOTES_TYPE = '[Retrieve] Retrieve Quotes';
export const RETRIEVE_QUOTE_ERROR_TYPE = '[Retrieve] Retrieve Quotes Error';
export const ALL_RETRIEVES_COMPLETE_TYPE = '[Retrieve] All Retrieves Complete';
export const HYDRATE_QUOTE_TYPE = '[Retrieve] Hydrate Quote';
export const HYDRATE_QUOTE_SUCCESS_TYPE = '[Retrieve] Hydrate Quote Success';
export const HYDRATE_QUOTE_ERROR_TYPE = '[Retrieve] Hydrate Quote Error';

export const retrieveQuotes = createAction(
  RETRIEVE_QUOTES_TYPE,
  props<{
    payload: {
      quotes: { quoteId: string; productType: ProductType }[];
    };
  }>()
);

export const retrieveQuoteSuccess = createAction(
  '[Retrieve] Retrieve Quotes Success',
  props<{ payload: QuoteRetrieveResponse }>()
);

export const retrieveInactiveQuoteSuccess = createAction(
  '[Retrieve] Retrieve Inactive Quote Success',
  props<{ payload: QuoteRetrieveResponse }>()
);

export const retrieveQuoteError = createAction(
  RETRIEVE_QUOTE_ERROR_TYPE,
  props<{ payload: ErrorModel }>()
);

export const allRetrievesComplete = createAction(ALL_RETRIEVES_COMPLETE_TYPE);

export const hydrateQuote = createAction(
  HYDRATE_QUOTE_TYPE,
  props<{ quoteId: string; producerCode: string }>()
);

export const hydrateQuoteSuccess = createAction(
  HYDRATE_QUOTE_SUCCESS_TYPE,
  props<{ payload: HydrateQuoteResponse[] }>()
);

export const hydrateQuoteError = createAction(
  HYDRATE_QUOTE_ERROR_TYPE,
  props<{ payload: ErrorModel }>()
);
