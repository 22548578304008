import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { PillModel } from '@shared/components/pill-list/pill-list.component';
import { Nullable } from '@shared/utils/type.utils';

@Component({
  selector: 'nwx-pill-item',
  templateUrl: './pill-item.component.html',
  styleUrls: ['./pill-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillItemComponent implements OnInit {
  @Input() content!: PillModel;
  @Input() identifier: Nullable<string | number>; // used for unique html IDs
  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}
  handleClick() {
    this.clickEvent.emit(this.content.item);
  }
}
