import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  AbstractControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { VinHelper } from '@core/helper/vin.helper';
import { VehicleType } from '@core/models/api/dsm-types';
import { PowersportsVehicleVinModel } from '@core/models/api/vehicle-inquiry.model';
import { VehicleModel } from '@core/models/views/vehicle.model';
import { ComponentStoreService } from '@core/services/component-store.service';
import { PowersportsService } from '@core/services/powersports.service';
import { ComponentStore } from '@ngrx/component-store';
import { VinVehicleTypeToDsmVehicleType } from '@shared/constants/msa-vehicle-type-constants';
import { VehiclePageUtils } from '@shared/utils/pages/vehicles-page.utils';
import { Subject, Observable, of } from 'rxjs';
import {
  takeUntil,
  take,
  map,
  catchError,
  distinctUntilChanged,
  debounceTime,
} from 'rxjs/operators';
import { FORM_DEBOUNCE_TIME } from '@shared/constants/app-constants';

export interface MsaVinFormState {
  instructionalText?: string;
}

@Component({
  selector: 'nwx-msa-vin-form',
  templateUrl: './msa-vin-form.component.html',
  styleUrls: ['./msa-vin-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore],
  // ViewEncapsulation.None is necessary for styling the changed characters in a VIN.
  encapsulation: ViewEncapsulation.None,
})
export class MsaVinFormComponent implements OnInit, OnDestroy {
  @Input() vehicle!: Partial<VehicleModel>;
  @Input() vehicleIndex!: number;
  @Input() required!: boolean;

  @Output() valueChange = new EventEmitter<Partial<VehicleModel>>();
  @Output() formReady = new EventEmitter<FormGroup>();

  form!: FormGroup;

  vm$!: Observable<MsaVinFormState>;

  @ViewChild('disambiguationModal', { read: ElementRef })
  disambiguationModal!: ElementRef;
  disambiguationRequired = new Subject<boolean>();
  disambiguationOptions: {
    value: PowersportsVehicleVinModel;
    displayHtml: string;
  }[] = [];
  disambiguationForm = new FormGroup({
    selection: new FormControl(''),
  });

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private powersportsService: PowersportsService,
    private changeDetector: ChangeDetectorRef,
    private readonly componentStore: ComponentStoreService<MsaVinFormState>
  ) {}

  ngOnInit(): void {
    this.form = this.buildForm(this.vehicle);

    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged(this.hasChanges))
      .subscribe((changes: VehicleModel) => {
        this.emitChanges(changes);
      });

    this.formReady.emit(this.form);

    this.componentStore.initialize({
      instructionalText: 'VIN required for bindable rate.',
    });

    this.vm$ = this.componentStore.get();
  }

  ngOnDestroy(): void {
    this.form.disable();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get vin(): AbstractControl {
    return this.form?.get('vin') as AbstractControl;
  }

  disambiguateVin(): void {
    this.disambiguationForm.setValue({ selection: '' });
    this.disambiguationModal.nativeElement.openModal();
  }

  submitVinDisambiguation(): void {
    const model = this.disambiguationForm.value.selection;
    const option = this.disambiguationOptions.find(
      (option) => option.value.model === model
    );
    if (option) {
      this.valueChange.emit({
        vehicleId: this.vehicle.vehicleId,
        make: option.value.make,
        model: option.value.model,
        year: option.value.year,
        vehicleType: VinVehicleTypeToDsmVehicleType[
          option.value.type as keyof typeof VinVehicleTypeToDsmVehicleType
        ] as VehicleType,
        cubicCentimeters: option.value.engineDisplacement,
      });
    }
  }
  private validateVinSync(control: AbstractControl): ValidationErrors | null {
    const vin = control.value?.trim();
    if (!vin) {
      this.disambiguationRequired.next(false);
      return null;
    }
    const results = VinHelper.validate(vin);
    if (!results) {
      this.disambiguationRequired.next(false);
      return null;
    }
    if (results.replacement) {
      if (results.replacement !== control.value) {
        control.setValue(results.replacement);
      }
      this.disambiguationRequired.next(false);
      return null;
    }
    if (results.candidates) {
      this.disambiguationOptions = results.candidates.map((candidate) => ({
        value: candidate as unknown as PowersportsVehicleVinModel,
        displayHtml: VinHelper.highlightDifferences(vin, candidate),
      }));
      this.disambiguationRequired.next(true);
      return { vinValidationError: results.message };
    }
    this.disambiguationRequired.next(false);
    return { vinValidationError: results.message };
  }

  private validateVinAsync(
    control: AbstractControl
  ): Observable<ValidationErrors | null> {
    const vin = control.value;
    if (!vin || vin.includes('*') || control.pristine) {
      return of(null);
    }
    if (this.vehicle.vehicleType === 'UtilityTrailer') {
      return of(null);
    }
    return this.powersportsService.getMsaVinDetails(vin).pipe(
      take(1),
      map((response) => {
        this.changeDetector.markForCheck();
        if (response.motorcycleModels.length === 1) {
          const m = response.motorcycleModels[0];

          this.valueChange.emit({
            vehicleId: this.vehicle.vehicleId,
            make: m.make,
            model: m.model,
            year: m.year,
            vehicleType: VinVehicleTypeToDsmVehicleType[
              m.type as keyof typeof VinVehicleTypeToDsmVehicleType
            ] as VehicleType,
            cubicCentimeters: m.engineDisplacement,
          });
        } else {
          this.disambiguationOptions = response.motorcycleModels.map(
            (model) => ({
              value: model,
              displayHtml: `${model.year} ${model.make} ${model.model}`,
            })
          );
          this.disambiguateVin();
        }

        return null;
      }),
      catchError((error) => {
        this.changeDetector.markForCheck();
        return of({
          vinValidationError: 'VIN is not in a valid format. Please try again.',
        });
      })
    );
  }

  hasChanges(a: VehicleModel, b: VehicleModel): boolean {
    return a.vin === b.vin;
  }

  onValueChange(changes: Partial<VehicleModel>): void {
    this.valueChange.emit(changes);
  }

  emitChanges(changes: Partial<VehicleModel>): void {
    this.valueChange.emit({
      vehicleId: this.vehicle.vehicleId,
      vin: VehiclePageUtils.ValidateVinEmpty(changes.vin),
    });
  }

  buildForm(vehicle?: Partial<VehicleModel>): FormGroup {
    return this.fb.group({
      vin: this.fb.control(vehicle?.vin || null, {
        validators: this.required
          ? [Validators.required, this.validateVinSync.bind(this)]
          : [this.validateVinSync.bind(this)],
        asyncValidators: [this.validateVinAsync.bind(this)],
        updateOn: 'blur',
      }),
      doNotDisable: this.fb.control(null, []), // DO NOT DELETE
    });
  }
}
