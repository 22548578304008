import { Injectable } from '@angular/core';
import {
  Observable,
  filter,
  from,
  map,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { DriverEntity } from '@core/store/entities/driver/driver.entity';
import { DriverAdapter } from '@core/adapters/driver.adapter';
import { DriverRequest } from '@core/models/api/request/driver-request.model';
import { PersonUtils } from '@shared/utils/person.utils';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DriverActions } from '@core/store/actions';

@Injectable({
  providedIn: 'root',
})
export class DriverService {
  nextCorrelationId = 1;

  constructor(
    private adapter: DriverAdapter,
    private actions$: Actions,
    private store: Store
  ) {}

  addOrUpdateDriver(request: DriverRequest): Observable<DriverEntity> {
    if (PersonUtils.isDriverSaved(request.driver.driverId)) {
      return this.adapter.updateDriver(request);
    } else {
      return this.adapter.addDriver(request).pipe(
        map((response) => {
          // When adding a driver, DSM defaults yearsOfMotorcycleAndOffRoadExp to zero, but we want it unset. Zero is a legal value.
          if (!response.yearsOfMotorcycleAndOffRoadExp) {
            response = {
              ...response,
              yearsOfMotorcycleAndOffRoadExp: undefined,
            };
          }
          return response;
        })
      );
    }
  }

  deleteDriver(request: DriverRequest): Observable<unknown> {
    return this.adapter.deleteDriver(request);
  }

  /**
   * The returned Observable completes on success or fails on error.
   */
  updateDriverAndAwaitResponse(
    driverId: number,
    changes: Partial<DriverEntity>
  ): Observable<void> {
    const correlationId = `DriverService${this.nextCorrelationId++}`;
    const result = this.actions$.pipe(
      ofType(
        DriverActions.upsertDriverSuccess,
        DriverActions.upsertDriverError
      ),
      filter((action) => action.correlationId === correlationId),
      take(1),
      switchMap((action) => {
        if (action.type === DriverActions.upsertDriverSuccess.type) {
          return from([]);
        }
        return throwError(() => action);
      })
    );
    this.store.dispatch(
      DriverActions.upsertDriver({
        payload: {
          ...changes,
          entityId: driverId,
          productType: 'PersonalAuto',
        } as DriverEntity,
        correlationId,
      })
    );
    return result;
  }
}
