import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  UwReportsViewModel,
  UwReportsViewReport,
} from './uw-reports-view-model';

@Component({
  selector: 'nwx-uw-reports-card',
  templateUrl: './uw-reports-card.component.html',
  styleUrls: ['./uw-reports-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UwReportsCardComponent {
  @Input() model!: UwReportsViewModel;

  @Output() clickReportLink = new EventEmitter<UwReportsViewReport>();
  @Output() refresh = new EventEmitter<void>();

  constructor() {}

  onClickReportLink(report: UwReportsViewReport, event: MouseEvent): void {
    event?.preventDefault?.();
    this.clickReportLink.emit(report);
  }

  refreshReports(): void {
    this.refresh.emit();
  }
}
