<div class="helpmodal">
  <div class="modal-header">
    <h4 ngbAutoFocus class="modal-title">Questions? We're here to help.</h4>
  </div>
  <div class="modal-body custom-padding">
    <div class="bolt-container-fluid">
      <div class="bolt-row">
        <div class="bolt-col-lg-6 bolt-col-xs-12">
          <div class="border-container">
            <div>
              <p class="heading">Visit Nationwide technology support</p>
              <div class="aligned-row">
                <span class="icon-block">
                  <bolt-icon name="external-link" color="white"></bolt-icon>
                </span>
                <a
                  class="help-text link-text"
                  target="_blank"
                  href="{{ techSupportLink }}"
                >
                  Technology support
                </a>
              </div>
            </div>
            <div class="border-r"></div>
          </div>
        </div>
        <div class="bolt-col-lg-6 bolt-col-xs-12">
          <p class="heading">Call P&C Sales technical support</p>
          <div class="aligned-row">
            <span class="icon-block">
              <bolt-icon name="phone" color="white"></bolt-icon>
            </span>
            <h4 class="help-text">1-888-667-3866</h4>
          </div>
        </div>
      </div>
      <div class="bolt-row" *ngIf="quotedProducts.length > 0">
        <div class="bolt-col-12">
          <div class="quote-data">
            <div class="bolt-row">
              <div class="bolt-col-12">Currently quoting</div>
            </div>
            <div class="bolt-row" *ngFor="let product of quotedProducts">
              <div class="bolt-col-12">
                {{ product.name }} submission ID: {{ product.quoteId }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <bolt-button-bar>
      <bolt-button slot="back" (click)="activeModal.close()">Close</bolt-button>
    </bolt-button-bar>
  </div>
</div>
