import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ProductsService } from '@core/services/products.service';
import { ProductModel } from '@core/store/entities/product/product.model';
import { PcUrlService } from '@shared/services/pc-url.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'nwx-unsupported-products-container',
  templateUrl: './unsupported-products-container.component.html',
  styleUrls: ['./unsupported-products-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsupportedProductsContainerComponent implements OnInit {
  unsupportedProducts$: Observable<ProductModel[]>;
  pcURLLink$: Observable<string>;

  constructor(
    private productsService: ProductsService,
    private pcUrlService: PcUrlService
  ) {
    this.unsupportedProducts$ =
      this.productsService.getSelectedInactiveProducts();
    this.pcURLLink$ = this.pcUrlService.getPolicyCenterUrl(
      '' || undefined,
      'Account'
    );
  }

  ngOnInit(): void {}
}
