import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { TaskService } from '@core/services/task.service';
import { SortedTasks, TaskModel } from '@core/store/entities/task/task.model';
import { Nullable } from '@shared/utils/type.utils';
import {
  NgbAccordionDirective,
  NgbAccordionConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { Action } from '@ngrx/store';
import { SessionService } from '@core/services/session.service';

interface TaskPanelInterface {
  [panelId: string]: boolean;
}

@Component({
  selector: 'nwx-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
  providers: [NgbAccordionConfig],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListComponent implements AfterViewInit {
  @Input() tasks!: Nullable<SortedTasks[][]>;

  @Output() taskButtonClicked: EventEmitter<string> = new EventEmitter();

  @ViewChild(NgbAccordionDirective) accordion?: NgbAccordionDirective;

  openById: TaskPanelInterface = {};

  constructor(
    config: NgbAccordionConfig,
    private taskService: TaskService,
    private sessionService: SessionService
  ) {
    // customize default values of accordions used by this component tree
    config.animation = true;
  }

  ngAfterViewInit(): void {
    this.openPanelForBlockedTasks();
  }

  // Make this an Event
  navigateToTaskPage(pageUrl: string): void {
    this.taskButtonClicked.emit(pageUrl);
  }

  dispatchTaskAction(taskAction: Action): void {
    this.taskService.dispatchAction(taskAction);
  }

  dispatchSetTaskAsReviewed(taskName: string): void {
    this.sessionService.setTaskAsReviewed(taskName);
  }

  getTaskMessage(task: TaskModel): string {
    return task.entityFriendlyId
      ? task.entityFriendlyId + ' - ' + task.message
      : task.message;
  }

  private openPanelForBlockedTasks(): void {
    if (this.tasks && this.accordion) {
      let index = 0;
      for (const group of this.tasks) {
        for (const sortedTasks of group) {
          for (const task of sortedTasks.taskList) {
            if (task.entityType === 'blocked') {
              this.accordion.expand(`task-panel-${index}`);
              return;
            }
          }
        }
        index++;
      }
    }
  }

  getDispatchGroupTaskAction(group: SortedTasks): void {
    // Mark the task as reviewed so the generater does not generate again
    if (group.taskList[0]?.entityType === 'review') {
      this.dispatchSetTaskAsReviewed(group.taskList[0]?.field);
    }
    const action = group.flipActionButtons
      ? group.taskList[0]?.resolve || group.action
      : group.action;

    this.dispatchTaskAction(action);
  }

  getGroupTaskActionLabel(group: SortedTasks): string {
    return (
      (group.flipActionButtons
        ? group.taskList[0]?.resolveLabel
        : group.actionMessage) || 'Complete Task'
    );
  }

  getDispatchTaskAction(group: SortedTasks, task: TaskModel): void {
    if (task.resolve) {
      // Mark the task as reviewed so the generater does not generate again
      if (task.entityType === 'review') {
        this.dispatchSetTaskAsReviewed(task.field);
      }
      const action = group.flipActionButtons ? group.action : task.resolve;
      this.dispatchTaskAction(action);
    }
  }

  getTaskActionLabel(group: SortedTasks, task: TaskModel): string {
    return (
      (group.flipActionButtons ? group.actionMessage : task.resolveLabel) ||
      'Resolve Task'
    );
  }
}
