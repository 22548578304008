import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { LayoutService } from '@core/services/layout.service';
import { NavigationService } from '@core/services/navigation.service';
import { ViewportType } from '@core/store/entities/layout/layout.entity';
import { Page } from '@core/store/entities/navigation/navigation.action';
import { NavigationSelectors } from '@core/store/selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'nwx-responsive-navigation-container',
  templateUrl: './responsive-navigation-container.component.html',
  styleUrls: ['./responsive-navigation-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponsiveNavigationContainerComponent implements OnInit {
  currentPage$: Observable<Page | undefined>;
  allPages$: Observable<Page[]>;
  showNav$: Observable<boolean>;
  constructor(
    private store: Store,
    private navService: NavigationService,
    private layoutService: LayoutService
  ) {
    this.allPages$ = this.store.select(NavigationSelectors.getAllPages);
    this.currentPage$ = this.store.select(
      NavigationSelectors.selectCurrentPage
    );
    this.showNav$ = this.layoutService.maxSizeXSmall();
  }

  ngOnInit(): void {}

  triggerOpenNav() {
    this.navService.openMobileNav();
  }
}
