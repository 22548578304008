import { Injectable } from '@angular/core';
import { QuoteAdapter } from '@core/adapters/quote.adapter';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { CoreState } from '@core/store/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as fromSelectors from '@core/store/selectors/index';
import * as fromActions from '@core/store/entities/scheduled-categories/scheduled-category.action';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { from } from 'rxjs';
import { BaseRequest } from '@core/models/api/request/base-request.model';
import { ProductType } from '@core/models/api/dsm-types';
import { ProductsSelectors } from '@core/store/selectors';
import { ProductModel } from '@entities/product/product.model';

@Injectable()
export class ScheduledCategoryEffects {
  constructor(
    private actions$: Actions,
    private store: Store<CoreState>,
    private quoteAdapter: QuoteAdapter,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  loadScheduledCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadScheduledCategories),
      switchMap((action) =>
        this.store
          .select(ProductsSelectors.getProductModelsByTypes(action.payload))
          .pipe(take(1))
      ),
      mergeMap((products: ProductModel[]) => {
        const nextActions: Action[] = [];
        products.forEach((product) =>
          nextActions.push(
            fromActions.getAllScheduledCategories({
              payload: {
                productType: product?.type as ProductType,
                quoteId: product?.quoteId as string,
              },
            })
          )
        );

        return from(nextActions);
      })
    )
  );

  getAllScheduledCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllScheduledCategories),
      map((action) => action.payload),
      mergeMap((request: BaseRequest) =>
        this.quoteAdapter.getScheduledCategories(request).pipe(
          switchMap((response) =>
            from([
              fromActions.getAllScheduledCategoriesSuccess({
                payload: response,
                productType: request.productType as ProductType,
              }),
            ])
          ),
          catchError((error) =>
            from([
              fromActions.getAllScheduledCategoriesFail({
                error: {
                  ...this.errorSanitizerService.sanitizeError(error, request.productType),
                  productType: request.productType,
                },
              }),
            ])
          )
        )
      )
    )
  );

  updateScheduledCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateScheduledCategory),
      map((action) => action.payload),
      mergeMap((payload) =>
        this.store
          .select(
            fromSelectors.ScheduledCategorySelectors.buildScheduledCategoryRequest(
              payload
            )
          )
          .pipe(take(1))
      ),
      mergeMap((request) => {
        return this.quoteAdapter.updateScheduledCategory(request).pipe(
          mergeMap((response) => {
            let nextActions: Action[] = [];
            // nextActions.push(
            //   fromActions.deleteAllScheduledCategories({
            //     payload: request.productType as ProductType,
            //   })
            // );
            nextActions.push(
              fromActions.updateScheduledCategorySuccess({ payload: response })
            );
            return from(nextActions);
          }),
          catchError((error) =>
            from([
              fromActions.updateScheduledCategoryFail({
                error: {
                  ...this.errorSanitizerService.sanitizeError(error, request.productType),
                  productType: request.productType,
                },
              }),
            ])
          )
        );
      })
    )
  );

  updateScheduledItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateScheduledPersonalEffectItem),
      map((action) => {
        return {
          personalEffectItem: action.payload,
          productType: action.productType,
        };
      }),
      mergeMap(({ personalEffectItem, productType }) =>
        this.store
          .select(
            fromSelectors.ScheduledCategorySelectors.buildScheduledPersonalEffectRequest(
              personalEffectItem,
              productType
            )
          )
          .pipe(take(1))
      ),
      mergeMap((request) => {
        return this.quoteAdapter.updateScheduledPersonalEffect(request).pipe(
          mergeMap((response) => {
            let nextActions: Action[] = [];
            nextActions.push(
              fromActions.updateScheduledCategorySuccess({
                payload: response,
              })
            );
            return from(nextActions);
          })
        );
      })
    )
  );

  //  SCHEDULED PERSONAL EFFECTS

  loadScheduledPersonalEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadScheduledPersonalEffects),
      switchMap(() =>
        this.store
          .select(fromSelectors.ProductsSelectors.getProduct('RV'))
          .pipe(take(1))
      ),
      mergeMap((rvProduct) => {
        const nextActions: Action[] = [];
        nextActions.push(
          fromActions.getAllScheduledPersonalEffects({
            payload: {
              productType: rvProduct?.type,
              quoteId: rvProduct?.quoteId,
            },
          })
        );
        return from(nextActions);
      })
    )
  );

  getAllScheduledPersonalEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllScheduledPersonalEffects),
      map((action) => action.payload),
      mergeMap((request: BaseRequest) =>
        this.quoteAdapter.getScheduledPersonalEffects(request).pipe(
          switchMap((response) =>
            from([
              fromActions.getAllScheduledCategoriesSuccess({
                payload: response,
                productType: request.productType as ProductType,
              }),
            ])
          ),
          catchError((error) =>
            from([
              fromActions.getAllScheduledCategoriesFail({
                error: {
                  ...this.errorSanitizerService.sanitizeError(error, request.productType),
                  productType: request.productType,
                },
              }),
            ])
          )
        )
      )
    )
  );

  removeScheduledPersonalEffectItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeScheduledPersonalEffectItem),
      map((action) => {
        return {
          personalEffectItem: action.payload,
          productType: action.productType,
        };
      }),
      mergeMap(({ personalEffectItem, productType }) =>
        this.store
          .select(
            fromSelectors.ScheduledCategorySelectors.buildRemoveScheduledPersonalEffectRequest(
              personalEffectItem,
              productType
            )
          )
          .pipe(take(1))
      ),
      mergeMap((request) => {
        return this.quoteAdapter.updateScheduledPersonalEffect(request).pipe(
          mergeMap((response) => {
            let nextActions: Action[] = [];
            // nextActions.push(
            //   fromActions.deleteAllScheduledCategories({
            //     payload: request.productType as ProductType,
            //   })
            // );
            nextActions.push(
              fromActions.updateScheduledCategorySuccess({
                payload: response,
              })
            );
            return from(nextActions);
          })
        );
      })
    )
  );
}
