import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { Page } from '@core/store/entities/navigation/navigation.action';
import { Nullable } from '@shared/utils/type.utils';

@Component({
  selector: 'nwx-responsive-navigation-toggle',
  templateUrl: './responsive-navigation-toggle.component.html',
  styleUrls: ['./responsive-navigation-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponsiveNavigationToggleComponent implements OnChanges {
  @Input() currentPage?: Nullable<Page>;
  @Input() allPages?: Nullable<Page[]>;
  @Output() openNav: EventEmitter<boolean> = new EventEmitter<boolean>();
  countValidPages: Nullable<number>;
  currentPageIndex: number = 0;
  validPages: Page[] = [];
  constructor() {}

  ngOnChanges(): void {
    this.validPages = this.allPages?.filter((page) => page.enabled) || [];
    this.countValidPages = this.allPages?.filter((page) => page.enabled).length;
    if (this.currentPage) {
      this.currentPageIndex =
        this.validPages.findIndex((page) => page.id === this.currentPage?.id) +
        1;
    }
  }

  openMobileNav() {
    this.openNav.emit(true);
  }
}
