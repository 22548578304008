import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { LayoutService } from '@core/services/layout.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'nwx-field-display-warning',
  templateUrl: './field-display-warning.component.html',
  styleUrls: ['./field-display-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldDisplayWarningComponent {
  @Input() id!: string;
  @Input() message?: string;

  maxSizeMini$: Observable<boolean>;

  constructor(private layoutService: LayoutService) {
    this.maxSizeMini$ = this.layoutService.maxSizeMini();
  }
}
