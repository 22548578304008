import { DiffedStringArrays } from '@core/interfaces/interfaces';
import { ProductType } from '@core/models/api/dsm-types';
import { ProductModel } from '@core/store/entities/product/product.model';
import { Nullable } from './type.utils';

export class StringUtils {
  static generateUuid(): string {
    const digits = '0123456789abcdef';
    return '00000000-0000-4000-0000-000000000000'.replace(/0/g, () => {
      return digits[Math.floor(Math.random() * digits.length)];
    });
  }

  static generateMessageId(sessionId: string): string {
    const random = this.generateUuid();
    if (sessionId?.match(/^[0-9a-fA-F]{8}/)) {
      return sessionId.substring(0, 8) + random.substring(8);
    }
    return random;
  }

  static generateEntityId(): string {
    const digits = '0123456789abcdef';
    return '000000000'.replace(/0/g, () => {
      return digits[Math.floor(Math.random() * digits.length)];
    });
  }

  static StringArraysDiff(prev: string[], next: string[]): DiffedStringArrays {
    const diff = {
      added: [],
      removed: [],
      unchanged: [],
    } as DiffedStringArrays;
    prev = prev ? [...prev].sort() : [];
    next = next ? [...next].sort() : [];
    let prevPosition = 0;
    let nextPosition = 0;
    while (prevPosition < prev.length && nextPosition < next.length) {
      const prevString = prev[prevPosition];
      const nextString = next[nextPosition];
      if (prevString < nextString) {
        diff.removed.push(prevString);
        prevPosition++;
      } else if (prevString > nextString) {
        diff.added.push(nextString);
        nextPosition++;
      } else {
        diff.unchanged.push(prevString);
        prevPosition++;
        nextPosition++;
      }
    }
    while (prevPosition < prev.length) {
      diff.removed.push(prev[prevPosition++]);
    }
    while (nextPosition < next.length) {
      diff.added.push(next[nextPosition++]);
    }
    return diff;
  }

  static convertProductTypeToCamelCase(productType: ProductType): string {
    if (productType === 'MSA' || productType === 'RV') {
      return productType.toLowerCase();
    } else {
      return productType[0].toLowerCase() + productType.substring(1);
    }
  }

  static unorderedStringListsEquivalent(a: string[], b: string[]): boolean {
    if (a.length !== b.length) {
      return false;
    }
    for (const p of a) {
      if (!b.includes(p)) {
        return false;
      }
    }
    for (const p of b) {
      if (!a.includes(p)) {
        return false;
      }
    }
    return true;
  }

  static createProductNameList(products: ProductModel[]): string {
    if (products.length === 0) {
      return 'no selected products';
    } else if (products.length === 1) {
      return products[0].name as string;
    } else if (products.length === 2) {
      return `${products[0].name} and ${products[1].name}`;
    } else {
      let returnString = '';
      products.forEach((product) => (returnString += `${product.name}, `));
      return returnString.slice(0, returnString.length - 2);
    }
  }

  static createProductListBindMessage(products: ProductModel[]): string {
    if (!products || products.length === 0) {
      return 'no selected products';
    } else if (products.length === 1) {
      return (
        'Quote ' +
        this.createProductNameList(products as ProductModel[]) +
        ' - then bind the policy.'
      );
    } else {
      return (
        'Quote ' +
        this.createProductNameList(products as ProductModel[]) +
        ' - then bind the policies.'
      );
    }
  }

  static requireString(input: any): string {
    if (typeof input === 'string') {
      return input;
    }
    if (!input) {
      return '';
    }
    if (typeof input === 'function') {
      return '';
    }
    return input.toString?.() || '';
  }

  static requireChoice<T>(input: any, choicesFirstIsDefault: T[]): T {
    for (const choice of choicesFirstIsDefault) {
      if (input === choice) {
        return choice;
      }
    }
    return choicesFirstIsDefault[0];
  }

  static removeTrailingUnderscore(input: string): string {
    const last = input[input.length - 1];
    if (last === '_') {
      const substr = input.substring(0, input.length - 1);
      return substr;
    } else {
      return input;
    }
  }
  static addTrailingUnderscore(input: string): string {
    if (input.includes('_')) {
      return input;
    } else {
      return input + '_';
    }
  }

  /**
   * We sometimes get incoming URLs from brain-dead redirectors that excessively escape things.
   * eg "userType=Allied%252520Agent".
   * Escape all the escaped percents first ("%25"), then decodeURIComponent.
   */
  static fullyUnescapeUriComponent(input?: string): string {
    if (!input) {
      return '';
    }
    for (;;) {
      const output: string = input!.replace('%25', '%');
      if (output === input) {
        break;
      }
      input = output;
    }
    return decodeURIComponent(input);
  }

  static removeNonNumericCharactersFromDollarAmount(input: string): string {
    return input.replace(/[$,]/g, '');
  }

  static policyNumberIsReallyQuoteId(policyNumberOrIsIt: string): boolean {
    // quoteId are all digits. policyNumber always contain a letter.
    return !!policyNumberOrIsIt.match(/^\d+$/);
  }
}
