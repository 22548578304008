import { createAction, props } from '@ngrx/store';
import { AgencyModel } from './agency.model';
import { ProducerSearch } from '@core/adapters/producer-search.model';
import { ProducerResponse } from '@core/adapters/producer-search.model';
export const updateAgency = createAction(
  '[Agency] Update Agency',
  props<{ payload: AgencyModel }>()
);

export const setAgentState = createAction(
  '[Agency] Set Agent State',
  props<{ agentState: string }>()
);

export const setProducerSearchResponse = createAction(
  '[Agency] Set Producer Search Response',
  props<{ search: ProducerSearch }>()
);

export const setProducerSearchForState = createAction(
  '[Agency] Set State Specific Producer Search Response',
  props<{ searchState: string, search: ProducerSearch }>()
);
