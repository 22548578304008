import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AccountAdapter } from '../adapters/account.adapter';
import { AccountRequest } from '../models/api/request/account-request.model';
import { AccountResponse } from '../models/api/response/account-response.model';
import * as fromSelectors from '@core/store/entities/account/account.selector';
import * as fromActions from '@core/store/entities/account/account.action';
import { Nullable } from '@shared/utils/type.utils';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private accountAdapter: AccountAdapter, private store: Store) {}

  createAccount(request: AccountRequest): Observable<AccountResponse> {
    return this.accountAdapter.createAccount(request);
  }

  setAccountId(accountId: string): void {
    this.store.dispatch(fromActions.setAccountId({ accountId }));
  }

  linkAccount(): void {
    this.store.dispatch(fromActions.linkAccount());
  }

  getAccountLoaded(): Observable<boolean> {
    return this.store.select(fromSelectors.getAccountLoaded);
  }

  getAccountLoading(): Observable<boolean> {
    return this.store.select(fromSelectors.getAccountLoading);
  }

  getAccountId(): Observable<Nullable<string>> {
    return this.store.select(fromSelectors.getAccountId);
  }

  getAccountLinked(): Observable<Nullable<boolean>> {
    return this.store.select(fromSelectors.getAccountLinked);
  }

  dispatchCreateAccount(): void {
    this.store.dispatch(fromActions.createAccount());
  }
}
