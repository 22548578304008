import { PageIdentifier } from '@core/constants/pages';
import { createSelector } from '@ngrx/store';
import { NavigationPath } from './router.action';
import * as ProductsSelectors from '@core/store/entities/product/product.selectors';

export const getConditionalRoute = (page: string, query?: object) =>
  createSelector(ProductsSelectors.getAllProducts, (products) => {
    switch (page) {
      case 'Vehicles': {
        const vehiclesPath: NavigationPath = {
          path: ['somewhere'],
          query,
        };
        return vehiclesPath;
      }
    }

    const path: NavigationPath = {
      path: ['somewhere'],
      query,
    };
    return path;
  });
