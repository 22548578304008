import { ProductType } from '@core/models/api/dsm-types';

export enum CoverageSyncStatus {
  Undetermined = 'Undetermined',
  Clean = 'Clean',
  Dirty = 'Dirty',
  CallInProgress = 'CallInProgress',
}

export interface CoverageSyncEntity {
  id: string;
  coverageId: string;
  productType: ProductType;
  status: CoverageSyncStatus;
  coverableId?: number;
  formId?: string;
}
