import { ViewportType } from './layout.entity';
import { createAction, props } from '@ngrx/store';

export const updateLayout = createAction(
  '[Layout] Update Layout',
  props<{ payload: { viewport: ViewportType; mobileNav: boolean } }>()
);

export const getInitialSize = createAction('[Layout] Get Initial Size');

export const setInitialSize = createAction(
  '[Layout] Set Initial Size',
  props<{ payload: { viewport: ViewportType; mobileNav: boolean } }>()
);
