import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CustomValidators } from '@app/validators/custom-validators';
import { FormHelper } from '@core/helper/form-helper';
import { MemberModel } from '@core/models/views/person.model';
import { AccountService } from '@core/services/account.service';
import { ComponentStoreService } from '@core/services/component-store.service';
import { NavigationService } from '@core/services/navigation.service';
import { TaskModel } from '@core/store/entities/task/task.model';
import { ProductModel } from '@entities/product/product.model';
import { ComponentStore } from '@ngrx/component-store';
import { PcUrlService } from '@shared/services/pc-url.service';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';

interface TasksPolicyholderFormState {
  showHomeNumber?: boolean;
  showEmailAddress?: boolean;
  emailAddressInstructionalText?: string;
}

@Component({
  selector: 'nwx-tasks-policyholder-form',
  templateUrl: './tasks-policyholder-form.component.html',
  styleUrls: ['./tasks-policyholder-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore],
})
export class TasksPolicyholderFormComponent implements OnInit, OnDestroy {
  @Input() pniTasks!: Nullable<TaskModel[]>;
  @Input() policyholder!: Partial<Nullable<MemberModel>>;
  @Input() products!: ProductModel[];

  @Output() valueChange = new EventEmitter<Partial<MemberModel>>();
  @Output() formReady = new EventEmitter<FormGroup>();

  form!: FormGroup;

  vm$!: Observable<TasksPolicyholderFormState>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private readonly componentStore: ComponentStoreService<TasksPolicyholderFormState>
  ) {}

  ngOnInit(): void {
    this.form = this.buildForm(this.policyholder);

    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged(this.hasChanges))
      .subscribe((changes: MemberModel) => {
        this.emitChanges(changes);
      });

    this.componentStore.initialize({
      showHomeNumber: false,
      showEmailAddress: false,
      emailAddressInstructionalText: 'Email required for online account setup',
    });

    this.vm$ = this.componentStore.get();

    const doesQuoteIncludeAuto = this.doesQuoteIncludeAuto(this.products);

    this.initializePolicyholderQuestions(doesQuoteIncludeAuto);

    this.formReady.emit(this.form);
  }

  ngOnDestroy(): void {
    this.form.disable();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initializePolicyholderQuestions(doesQuoteIncludeAuto: boolean) {
    this.setFormField(this.doesTaskFieldExist('homeNumber'), 'homeNumber', [
      Validators.required,
      CustomValidators.MaskablePhoneNumberFormat,
    ]);
    this.setFormField(this.doesTaskFieldExist('emailAddress'), 'emailAddress', [
      CustomValidators.emailRequired(true, doesQuoteIncludeAuto),
      CustomValidators.email,
    ]);
  }

  emitChanges(changes: Partial<MemberModel>): void {
    this.valueChange.emit({
      ...this.policyholder,
      emailAddress: changes.emailAddress
        ? changes.emailAddress
        : this.policyholder?.emailAddress,
      homeNumber: changes.homeNumber
        ? changes.homeNumber
        : this.policyholder?.homeNumber,
    });
  }

  hasChanges(a: MemberModel, b: MemberModel): boolean {
    return a.emailAddress === b.emailAddress && a.homeNumber === b.homeNumber;
  }

  buildForm(person: Partial<Nullable<MemberModel>>): FormGroup {
    return this.fb.group({
      homeNumber: this.fb.control(person?.homeNumber, {
        validators: [],
        updateOn: 'blur',
      }),
      emailAddress: this.fb.control(person?.emailAddress, {
        validators: [],
      }),
    });
  }

  doesTaskFieldExist(fieldName: string): boolean {
    return !!this.pniTasks?.find((t) => t.field === fieldName);
  }

  setFormField(
    isEnabled: boolean,
    fieldName: string,
    validators: ValidatorFn[]
  ): void {
    let secondPartOfFieldName = fieldName;
    if (fieldName.includes('.'))
      secondPartOfFieldName = fieldName.split('.')[1];
    this.componentStore.update({
      ['show' +
      secondPartOfFieldName.charAt(0).toUpperCase() +
      secondPartOfFieldName.slice(1)]: isEnabled,
    });

    FormHelper.enableOrDisable(fieldName, isEnabled, validators, this.form);
  }

  doesQuoteIncludeAuto(products: ProductModel[]): boolean {
    return products?.some((product) => product.type === 'PersonalAuto');
  }
}
