import { Pipe } from "@angular/core";
import { DateUtils } from "@shared/utils/date.utils";
import { Nullable } from "@shared/utils/type.utils";

@Pipe({
  name: 'dateMmDdYyyy',
})
export class DateMmDdYyyyPipe {
  transform(input: Nullable<string>): string {
    if (!input) {
      return '';
    }
    return DateUtils.formatDsmDateToOld(input);
  }
}
