import { ProductType } from '@core/models/api/dsm-types';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@core/store/entities/error/error.model';
import {
  MsbReportDetails,
  UWReportsResponse,
} from '@core/models/api/response/uw-reports-response.model';
import { CoveredLocationEntity } from '../covered-location/covered-location.reducer';

export const refreshUWReportsByProduct = createAction(
  '[UW Reports] Refresh UW Reports by Product',
  props<{ productType: ProductType }>()
);

export const getUWReports = createAction(
  '[UW Reports] Get UW reports',
  props<{ quoteId: string; product: ProductType }>()
);

export const getUWReportsSuccess = createAction(
  '[UW Reports] Get UW reports success',
  props<{ payload: UWReportsResponse[]; product: ProductType }>()
);

export const storeUWReports = createAction(
  '[UW Reports] Store UW reports',
  props<{ payload: UWReportsResponse[]; product: ProductType }>()
);

export const getUWReportsFail = createAction(
  '[UW Reports] Get UW reports fail',
  props<{ error: ErrorModel }>()
);

export const resetUWReportsLoaded = createAction(
  '[UW Reports] Reset UW reports loaded'
);

export const getMSBEstimate = createAction(
  '[UW Reports] Get MSB Estimate',
  props<{ payload: ProductType }>()
);

export const getMSBEstimateSuccess = createAction(
  '[UW Reports] Get MSB Estimate success',
  props<{ payload: CoveredLocationEntity }>()
);

export const getMSBEstimateFail = createAction(
  '[UW Reports] Get MSB Estimate fail',
  props<{ error: ErrorModel }>()
);

export const getMSBLaunchUrl = createAction(
  '[UW Reports] Get MSB Launch Url',
  props<{ quoteId: string; productType: ProductType }>()
);

export const getMSBLaunchUrlSuccess = createAction(
  '[UW Reports] Get MSB Launch Url success',
  props<{ payload: MsbReportDetails }>()
);

export const getMSBLaunchUrlFail = createAction(
  '[UW Reports] Get MSB Launch Url fail',
  props<{ error: ErrorModel }>()
);
