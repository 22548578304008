import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as AddressActions from './address.action';
import { AddressEntity } from './address.entity';
import { Action, on, createReducer } from '@ngrx/store';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { RetrieveActions, SessionActions } from '../../actions';
import { AddressUtils } from '@shared/utils/address.utils';
import { PolicyHolderEntity } from '../policyholder/policyholder.entity';
import { Nullable } from '@shared/utils/type.utils';
import { AddressType } from '@core/models/api/dsm-types';

export interface AddressState extends EntityState<AddressEntity> {
  error: ErrorModel;
}

export const adapter: EntityAdapter<AddressEntity> =
  createEntityAdapter<AddressEntity>({
    selectId: (address) => address.addressType,
  });

const addressReducer = createReducer<AddressState>(
  adapter.getInitialState({
    error: {},
  }),
  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({ error: {} })
  ),
  on(AddressActions.upsertAddress, (state, { payload }) =>
    adapter.upsertOne(payload, state)
  ),
  on(AddressActions.storeAddress, (state, { payload }) =>
    adapter.upsertOne(payload, state)
  ),
  on(AddressActions.deleteAddress, (state, { payload }) =>
    adapter.removeOne(payload, { ...state })
  ),
  on(
    RetrieveActions.retrieveQuoteSuccess,
    RetrieveActions.retrieveInactiveQuoteSuccess,
    (state, { payload }) => {
      const returnArray: AddressEntity[] = [];
      if (payload.response.policyAddress) {
        returnArray.push(
          buildAddressEntity(
            payload.response.policyAddress,
            payload.productType
          )
        );

        returnArray.push(
          buildAddressEntity(payload.response.policyAddress, 'Default')
        );

        const pniAddress = getRetrievePniAddress(
          payload.response.policyHolders
        ) as AddressEntity;
        const hasMailingAddress = !AddressUtils.isSameAddress(
          payload.response.policyAddress,
          pniAddress as AddressEntity
        );

        returnArray.push(buildAddressEntity(pniAddress, 'Account'));

        if (hasMailingAddress) {
          returnArray.push(buildAddressEntity(pniAddress, 'Mailing'));
        }
      }

      return adapter.upsertMany(returnArray, state);
    }
  )
);

export function reducer(
  state: AddressState | undefined,
  action: Action
): AddressState {
  return addressReducer(state, action);
}

function buildAddressEntity(
  address: AddressEntity,
  addressType: AddressType
): AddressEntity {
  return {
    ...address,
    addressType,
    street: AddressUtils.SingleLineAddressFromAddressEntity(address),
  };
}

function getRetrievePniAddress(
  policyHolders: PolicyHolderEntity[]
): Nullable<AddressEntity> {
  return policyHolders.find(
    (policyHolder) => policyHolder.policyHolderType === 'PolicyPriNamedInsured'
  )?.address;
}
