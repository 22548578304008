import { Injectable } from '@angular/core';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, from, of } from 'rxjs';
import { catchError, exhaustMap, take } from 'rxjs/operators';

import { LocationExcludedExposureActions } from '@core/store/actions';
import { LocationExcludedExposureSelectors } from '@core/store/selectors';
import { LocationExposureService } from '@core/services/location-exposure.service';

@Injectable()
export class LocationExcludedExposureEffect {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: LocationExposureService,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  deleteLocationExcludedExposure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocationExcludedExposureActions.deleteLocationExcludedExposure),
        exhaustMap((action) =>
          this.store
            .select(
              LocationExcludedExposureSelectors.buildLocationExcludedExposureRequest(
                action.payload,
                action.correlationId || ''
              )
            )
            .pipe(take(1))
        ),
        exhaustMap((request) => {
          return this.service.deleteLocationExcludedExposure(request).pipe(
            exhaustMap(() => {
              return from([
                LocationExcludedExposureActions.deleteLocationExcludedExposureSuccess(
                  {
                    locationExcludedExposureId:
                      request.locationExcludedExposureBody
                        .locationExcludedExposure.locationExcludedExposureId,
                  }
                ),
              ]);
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                request.productType
              );
              return of(
                LocationExcludedExposureActions.deleteLocationExcludedExposureFail(
                  {
                    error: saneError,
                    entityId:
                      request.locationExcludedExposureBody
                        .locationExcludedExposure.locationExcludedExposureId,
                  }
                )
              );
            })
          );
        })
      ) as Observable<Action>
  );

  upsertLocationExcludedExposure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocationExcludedExposureActions.upsertLocationExcludedExposure),
        exhaustMap((action) =>
          this.store
            .select(
              LocationExcludedExposureSelectors.buildLocationExcludedExposureRequest(
                action.payload,
                action.correlationId || ''
              )
            )
            .pipe(take(1))
        ),
        exhaustMap((request) => {
          return this.service.addOrUpdateLocationExcludedExposure(request).pipe(
            exhaustMap((response) => {
              return from([
                LocationExcludedExposureActions.upsertLocationExcludedExposureSuccess(
                  {
                    payload: response,
                  }
                ),
              ]);
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                request.productType
              );
              return of(
                LocationExcludedExposureActions.upsertLocationExcludedExposureFail(
                  {
                    error: saneError,
                    entityId:
                      request.locationExcludedExposureBody
                        .locationExcludedExposure.locationExcludedExposureId,
                  }
                )
              );
            })
          );
        })
      ) as Observable<Action>
  );
}
