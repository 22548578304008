<div class="modal-header">
  <div class="modal-header-title" id="NamedInsuredModalHeader">
    {{ product?.name }} named insureds
  </div>
  <div class="modal-header-label">Verify members as primary & secondary</div>
</div>
<div class="modal-body">
  <nwx-notification-bubble *ngIf="errorMessage" type="error">{{ errorMessage }}</nwx-notification-bubble>
  <form [formGroup]="form" formName="named-insureds" ngbAutoFocus>
    <div>
      <unbolt-select
        label="Primary named insured"
        formControlName="primaryNamedInsured"
        name="primaryNamedInsured"
        id="primary-named-insured"
        required
      >
        <option value=""></option>
        <ng-container *ngFor="let option of pniOptions">
          <option [value]="option.entityId">
            {{ option.person?.firstName }} {{ option.person?.lastName }}
          </option>
        </ng-container>
      </unbolt-select>
    </div>
    <div>
      <unbolt-select
        label="Secondary named insured"
        formControlName="secondaryNamedInsured"
        name="secondaryNamedInsured"
        id="secondary-named-insured"
        required
      >
        <option value="">None</option>
        <ng-container *ngFor="let option of sniOptions">
          <option [value]="option.entityId">
            {{ option.person?.firstName }} {{ option.person?.lastName }}
          </option>
        </ng-container>
      </unbolt-select>
    </div>
    <div *ngFor="let x of [].constructor(aniCount) ; index as i">
      <unbolt-select
        label="Additional named insured"
        formControlName="ani{{i}}"
        name="ani{{i}}"
        id="additional-named-insured-{{ i }}"
      >
        <option value="">None</option>
        <ng-container *ngFor="let option of sniOptions">
          <option [value]="option.entityId">
            {{ option.person?.firstName }} {{ option.person?.lastName }}
          </option>
        </ng-container>
      </unbolt-select>
    </div>
  </form>
</div>
<div class="modal-footer">
  <bolt-button type="primary" width="full" (click)="onSubmit()" [disabled]="(callInProgress$ | async) || null">Confirm</bolt-button>
  <bolt-button width="full" (click)="onClose()" [disabled]="(callInProgress$ | async) || null">Cancel</bolt-button>
</div>
