import { Injectable } from '@angular/core';
import * as fromActions from '@core/store/actions';
import { DriverRequest } from '../models/api/request/driver-request.model';
import { DriverEntity } from '../store/entities/driver/driver.entity';
import { EligibleDiscountsEntity } from '../store/entities/eligible-discounts/eligible-discounts.entity';
import { PolicyHolderType, ProductType } from '@core/models/api/dsm-types';
import { Action } from '@ngrx/store';
import { DateUtils } from '@shared/utils/date.utils';
import { GeneralUtils } from '@shared/utils/general.utils';
import { Nullable } from '@shared/utils/type.utils';
import { MemberEntity } from '@core/store/entities/member/member.reducer';

@Injectable({
  providedIn: 'root',
})
export class DriverHelper {
  static relationToPniFromPolicyholderType(
    input: Nullable<PolicyHolderType>
  ): string {
    switch (input) {
      case 'PolicyPriNamedInsured':
        return 'PrimaryNamedInsured';
      case 'PolicySecNamedInsured':
        return 'Spouse';
      case 'PolicyAddlNamedInsured':
        return 'Other';
    }
    return 'Other';
  }

  getAddDriverNextActions(
    request: DriverRequest,
    response: DriverEntity
  ): Action[] {
    const nextActions: Action[] = [];

    response = this.setDriverEntity(request, response);

    response.eligibleDiscounts?.forEach((eligibleDiscount) => {
      switch (eligibleDiscount.eligibleDiscountId) {
        case 'USArmedForces': {
          if (GeneralUtils.isBoolean(response.usArmedForces)) {
            const discount: EligibleDiscountsEntity = {
              eligibleDiscountId: 'USArmedForces',
              modelId: '' + response.driverId,
              quoteId: '' + response.quoteId,
              productType: request.productType,
              selectedOptionValue: String(response.usArmedForces),
            };
            nextActions.push(
              fromActions.EligibleDiscountsActions.updateDriverDiscount({
                payload: discount,
              })
            );
          }
          break;
        }
        case 'MTNationalGuard': {
          if (GeneralUtils.isBoolean(response.mtNationalGuard)) {
            const discount: EligibleDiscountsEntity = {
              eligibleDiscountId: 'MTNationalGuard',
              modelId: '' + response.driverId,
              quoteId: '' + response.quoteId,
              productType: request.productType,
              selectedOptionValue: String(response.mtNationalGuard),
            };
            nextActions.push(
              fromActions.EligibleDiscountsActions.updateDriverDiscount({
                payload: discount,
              })
            );
          }
          break;
        }
        case 'AdvancedDriverTraining': {
          if (GeneralUtils.isBoolean(response.advancedDriverTraining)) {
            const discount: EligibleDiscountsEntity = {
              eligibleDiscountId: 'AdvancedDriverTraining',
              modelId: '' + response.driverId,
              quoteId: '' + response.quoteId,
              productType: request.productType,
              selectedOptionValue: String(response.advancedDriverTraining),
              qualifyingInformation: {
                advancedTrainingCourseCompletionDate:
                  response.advancedTrainingCourseCompletionDate
                    ? DateUtils.formatDateToDSM(
                        response.advancedTrainingCourseCompletionDate
                      )
                    : undefined,
              },
            };
            nextActions.push(
              fromActions.EligibleDiscountsActions.updateDriverDiscount({
                payload: discount,
              })
            );
          }
          break;
        }
        case 'GoodStudent': {
          if (GeneralUtils.isBoolean(response.goodStudent)) {
            response.goodStudent = GeneralUtils.parseBooleanFromString(
              eligibleDiscount.selectedOptionValue || false
            );
          }
          break;
        }
        case 'StudentAway': {
          if (GeneralUtils.isBoolean(response.studentAway)) {
            response.studentAway = GeneralUtils.parseBooleanFromString(
              eligibleDiscount.selectedOptionValue || false
            );
          }
          break;
        }
      }
    });
    nextActions.push(
      fromActions.DriverActions.upsertDriverSuccess({
        payload: {
          ...response,
          productType: request.productType,
        } as MemberEntity,
        correlationId: request?.correlationId,
      })
    );

    return nextActions;
  }

  setDriverEntity(
    request: DriverRequest,
    response: DriverEntity
  ): DriverEntity {
    response.quoteId = request.quoteId;
    response.entityId = request.driver.entityId;
    response.infractions = response?.infractions || request.driver.infractions;
    response.infractionType =
      response.infractions?.[0]?.infractionType ||
      request?.driver?.infractionType ||
      (response.infractions ? 'Excellent' : undefined);
    response.driverOccupation = request?.driver?.driverOccupation;
    if (response && response.person && request.driver.employmentInformation) {
      response.employmentInformation = request.driver.employmentInformation;
    }
    if (response.person) {
      response.person.gender =
        response.nonSpecifiedGender || request.driver.isGenderXSelected
          ? 'X'
          : response.person.gender || '';
    }
    response.productType = request.productType;
    response.prefillId = request.driver.prefillId;
    response.healthCarePlan = request.driver.healthCarePlan;
    response.isPersonalInjuryProtectionExcluded =
      request.driver.isPersonalInjuryProtectionExcluded;
    response.isLivingWithNWInsuredParent =
      request.driver.isLivingWithNWInsuredParent;
    response.nonSpecifiedGender = request.driver.nonSpecifiedGender;
    if (request.driver.licenseNumber) {
      response.licenseNumber = request.driver.licenseNumber;
    }
    response.membership = request.driver.membership;
    response.associateNumber = request.driver.associateNumber;
    response.goodStudent = request.driver.goodStudent;
    response.studentAway = request.driver.studentAway;
    response.driverTraining = request.driver.driverTraining;
    if (request.driver.trainingCourseCompletionDate) {
      response.trainingCourseCompletionDate = DateUtils.formatDsmDateToOld(
        request.driver.trainingCourseCompletionDate
      );
    }
    response.advancedDriverTraining = request.driver.advancedDriverTraining;
    response.advancedTrainingCourseCompletionDate = request.driver
      .advancedTrainingCourseCompletionDate
      ? DateUtils.formatDsmDateToOld(
          request.driver.advancedTrainingCourseCompletionDate
        )
      : undefined;
    response.mtNationalGuard = request.driver.mtNationalGuard;
    response.usArmedForces = request.driver.usArmedForces;
    response.hasMotorcycleEndorsement = request.driver.hasMotorcycleEndorsement;
    response.endorsementDate = request.driver.endorsementDate;
    if (!response.hasOwnProperty('yearsOfMotorcycleAndOffRoadExp')) {
      response.yearsOfMotorcycleAndOffRoadExp =
        request.driver.yearsOfMotorcycleAndOffRoadExp;
    }

    return response;
  }

  getEligibleDiscounts(
    discounts: Nullable<EligibleDiscountsEntity[]>,
    driverId: number,
    productType: ProductType
  ): Nullable<EligibleDiscountsEntity[]> {
    return discounts?.map((discount) => {
      discount.modelId = '' + driverId;
      discount.productType = productType;
      return discount;
    });
  }
}
