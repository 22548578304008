<div class="bolt-col-12 extra-bottom-space">
  <ng-template *ngIf="showText">
    <bolt-notification *ngIf="!url">
      This Auto quote can't be rated in Nationwide Express. You can continue to
      the Quote summary screen to retrieve this account in PolicyCenter if you
      would like to continue.
    </bolt-notification>
    <bolt-notification *ngIf="url">
      This quote can't be rated in Nationwide Express. You can pivot to
      PolicyCenter if you would like to try to continue with this quote.
    </bolt-notification>
  </ng-template>
</div>
<div class="bolt-row" *ngIf="url">
  <div class="bolt-col more-bottom-space">
    <bolt-button
      type="primary"
      iconright="external-link"
      attr.id="pp-{{ buttonText }}"
      (click)="launchPolicyCenter()"
    >
      {{ buttonText }}
    </bolt-button>
  </div>
</div>
