import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, of, mergeMap, catchError } from 'rxjs';
import * as fromActions from './powersports-vehicle-inquiry.action';
import { VehicleInquiryActions } from '@core/store/actions';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { PowersportsAdapter } from '@core/adapters/powersports.adapter';
import { PicklistItem } from '@shared/models/picklist.model';
import { ProductUtils } from '@shared/utils/product.util';

@Injectable({
  providedIn: 'root',
})
export class PowersportsVehicleInquiryEffect {
  constructor(
    private actions$: Actions,
    private store: Store,
    private errorSanitizerService: ErrorSanitizerService,
    private powersportsAdapter: PowersportsAdapter
  ) {}

  retrieveAndStoreYears$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.powersportsRetrieveAndStoreYears),
      map((action) => {
        return {
          inquiryPath: action.inquiryPath,
          correlationId: action.correlationId,
        };
      }),
      mergeMap(({ inquiryPath, correlationId }) => {
        return this.powersportsAdapter
          .getPowersportsVehicleYears(inquiryPath)
          .pipe(
            map((response) => {
              return response.years.map((year) => {
                return {
                  displayName: year.year,
                  value: year.year,
                };
              }) as PicklistItem[];
            }),
            mergeMap((response) =>
              of(
                VehicleInquiryActions.setVehicleInquiryYears({
                  response,
                  inquiryPath,
                  correlationId,
                })
              )
            ),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                ProductUtils.productFromInquiryPath(inquiryPath),
              );
              return of(
                VehicleInquiryActions.setVehicleInquiryYearsError({
                  inquiryPath,
                  correlationId,
                })
              );
            })
          );
      })
    )
  );

  dispatchSetVehicleInquiryMakes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.powersportsDispatchSetVehicleInquiryMakes),
      map((action) => {
        return {
          year: action.year,
          inquiryPath: action.inquiryPath,
          correlationId: action.correlationId,
        };
      }),
      mergeMap(({ year, inquiryPath, correlationId }) => {
        return this.powersportsAdapter
          .getPowersportsVehicleMakesByYear(year, inquiryPath)
          .pipe(
            map((response) => {
              return response.makes.map((make) => {
                return {
                  displayName: make.make,
                  value: make.make,
                };
              }) as PicklistItem[];
            }),
            mergeMap((response) =>
              of(
                VehicleInquiryActions.setVehicleInquiryMakes({
                  year,
                  response,
                  inquiryPath,
                  correlationId,
                })
              )
            ),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                ProductUtils.productFromInquiryPath(inquiryPath)
              );
              return of(
                VehicleInquiryActions.setVehicleInquiryYearsError({
                  inquiryPath,
                  correlationId,
                })
              );
            })
          );
      })
    )
  );

  dispatchSetVehicleInquiryModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.powersportsDispatchSetVehicleInquiryModels),
      map((action) => {
        return {
          year: action.year,
          make: action.make,
          inquiryPath: action.path,
          correlationId: action.correlationId,
        };
      }),
      mergeMap(({ year, make, inquiryPath, correlationId }) => {
        return this.powersportsAdapter
          .getPowersportsVehicleModelsByYearAndMake(
            year,
            btoa(make),
            inquiryPath
          )
          .pipe(
            map((response) => {
              return response.models.map((model) => {
                return {
                  displayName: model.model,
                  value: model.model,
                };
              }) as PicklistItem[];
            }),
            mergeMap((response) =>
              of(
                VehicleInquiryActions.setVehicleInquiryModels({
                  year,
                  make,
                  response,
                  inquiryPath,
                  correlationId,
                })
              )
            ),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                ProductUtils.productFromInquiryPath(inquiryPath)
              );
              return of(
                VehicleInquiryActions.setVehicleInquiryModelsError({
                  inquiryPath,
                  correlationId,
                })
              );
            })
          );
      })
    )
  );
}
