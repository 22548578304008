import { Injectable } from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { Observable } from 'rxjs';
import { DsmAdapter, responseUnused } from './dsm.adapter';
import { AppConfigService } from '@core/services/app-config.service';
import { HouseholdMemberEntity, sanitizeHouseholdMemberEntity, sanitizeHouseholdMembers } from '@entities/household-member/household-member.model';
import { HouseholdMemberRequest } from '@core/models/api/request/household-member-request.model';

@Injectable({
  providedIn: 'root',
})
export class HouseholdMemberAdapter {
  constructor(
    public adapter: DsmAdapter,
    public appService: AppConfigService
  ) {}

  addHouseholdMember(
    request: HouseholdMemberRequest
  ): Observable<HouseholdMemberEntity> {
    return this.adapter.request(
      sanitizeHouseholdMemberEntity,
      request.productType as ProductType,
      'POST',
      `/quotes/${request.quoteId}/household-members`,
      'add-household-member',
      {
        body: {
          householdMember: {
            ...request.householdMember,
            householdMemberId: undefined,
            entityId: undefined as unknown,
          } as HouseholdMemberEntity,
        },
      }
    );
  }

  updateHouseholdMember(
    request: HouseholdMemberRequest
  ): Observable<HouseholdMemberEntity> {
    return this.adapter.request(
      sanitizeHouseholdMemberEntity,
      request.productType as ProductType,
      'PUT',
      `/quotes/${request.quoteId}/household-members/${request.householdMember?.householdMemberId}`,
      'update-household-member',
      {
        body: {
          householdMember: {
            ...request.householdMember,
            entityId: undefined as unknown,
          } as HouseholdMemberEntity,
        },
      }
    );
  }

  removeHouseholdMember(
    request: HouseholdMemberRequest
  ): Observable<unknown> {
    return this.adapter.request(
      responseUnused,
      request.productType as ProductType,

      'DELETE',
      `/quotes/${request.quoteId}/household-members/${request.householdMember?.householdMemberId}`,
      'remove-household-member'
    );
  }

  getAllHouseholdMembers(quoteId: string): Observable<HouseholdMemberEntity[]> {
    return this.adapter.request(
      sanitizeHouseholdMembers,
      'PersonalUmbrella',
      'GET',
      `/quotes/${quoteId}/household-members`,
      'get-household-members'
    );
  }
}
