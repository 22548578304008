import { StateUtils } from './state.util';
import {
  DistributionPartnerRoleResponse,
  LicenseSummary,
} from '@core/models/api/response/distribution-partner-role-response.model';
import { DateUtils } from './date.utils';
import { AgencyModel } from '@core/store/entities/agency/agency.model';
import { UserModel } from '@core/store/entities/user/user.model';
import { ChannelNames, ProducerType } from '@shared/constants/app-constants';
import { ProducerResponse } from '@core/adapters/producer-search.model';

export class ProducerUtils {
  static buildLegacyIdentifier(producerCode: string): string {
    if (producerCode?.includes('-')) {
      const [agencyCode, producerId] = producerCode.split(/\s*-\s*/);
      return `${agencyCode}00000${producerId}`;
    } else {
      return producerCode;
    }
  }

  static isPDSProducer(producerCode: string): boolean {
    return !producerCode?.includes(' - ');
  }

  static getActiveLicenseSummaries(
    response: DistributionPartnerRoleResponse
  ): LicenseSummary[] {
    return response.licenseSummaries.filter((license) => {
      // find the P&C LOA (may need to add life here?)
      const pncLoas = license.lineOfAuthorities.filter(
        (loa) =>
          loa.licenseLineOfAuthority.toLowerCase().trim() ===
            'Property & Casualty'.toLowerCase().trim() ||
          loa.licenseLineOfAuthority.toLowerCase().trim() ===
            'Personal Lines'.toLowerCase().trim()
      );
      // if no LOA then remove state as an option (may not be possible?)
      if (pncLoas.length === 0) {
        return false;
      }

      for (const pncLoa of pncLoas) {
        if (
          !pncLoa.expirationDate ||
          DateUtils.isDateInPast(pncLoa.expirationDate)
        ) {
          // check license expiration date, if not set or in past then check appointments
          // when checking appointments check for NW / Allied companies
          // check for some appt Active status
          // this doesnt gurantee an active license but gets us close
          if (
            pncLoa?.appointments?.some((appt) =>
              appt?.roleAppointments?.some(
                (roleAppt) =>
                  roleAppt?.appointmentStatus.toLowerCase().trim() ===
                  'Active'.toLowerCase().trim()
              )
            )
          ) {
            return true;
          }
        } else {
          // if Expiration date is in future add state
          return true;
        }
      }
      return false;
    });
  }

  static getPDSStateCode(producerCode: string): string {
    return producerCode.slice(0, 2);
  }

  static getPDSStateAbbreviation(producerCode: string): string {
    const code = StateUtils.stateAbbreviationFromNumericCode(
      ProducerUtils.getPDSStateCode(producerCode)
    );
    if (code) {
      return code;
    } else {
      return '';
    }
  }

  // See producer.utils.spec.ts for a little bit of why this is so ugly but required.
  static extractProducerNumberForBilling(agency: AgencyModel): string {
    const leadingZeros = 3;
    const producerCode = agency.producerCode;
    if (producerCode && ProducerUtils.isPDSProducer(producerCode)) {
      const trailingAgentNumber = 7;
      if (
        agency.producerCode &&
        agency.producerCode.length > trailingAgentNumber
      ) {
        return agency.producerCode.slice(
          agency.producerCode.length - trailingAgentNumber
        );
      } else {
        return producerCode;
      }
    } else if (agency.agent.agencyCode) {
      if (agency.agent.agencyCode.slice(0, leadingZeros) === '000') {
        return agency.agent.agencyCode.slice(leadingZeros);
      } else {
        return agency.agent.agencyCode;
      }
    } else {
      const parts = producerCode?.split(' - ');
      if (!parts) {
        return '';
      }
      const agencyCode = parts[0];
      const producerId = parts[1];
      if (agencyCode.slice(0, leadingZeros) === '000') {
        return agencyCode.slice(leadingZeros);
      } else {
        return agencyCode;
      }
    }
  }

  static getProducerType(user: UserModel, producerCode: string): ProducerType {
    const isPDSProducer = this.isPDSProducer(producerCode);
    if (user.role === 'NSS') {
      return ChannelNames.NSS as ProducerType;
    } else {
      if (isPDSProducer) {
        return ChannelNames.EA as ProducerType;
      } else {
        return ChannelNames.IA as ProducerType;
      }
    }
  }

  static getProducerSearchStateByAMFCode(
    producerCode: string,
    producers: ProducerResponse[]
  ): string | undefined {
    return (
      producers.find((p) => p.producerCode === producerCode)?.agentState ??
      undefined
    );
  }
}
