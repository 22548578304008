import { QuoteStatus } from '@core/models/api/dsm-types';
import { FeatureFlagsModel } from '../../feature-flag/feature-flag.model';
import { TaskValidators } from '../task-validators';
import { TaskModel, UmbrellaCoverageTaskParams } from '../task.model';
import { UnderlyingPolicyEntity } from '@entities/underlying-policy/underlying-policy.entity';
import { Exposures } from '@entities/umbrella/umbrella.model';
import { PolicyDateOptions } from '@core/interfaces/interfaces';
import { ProductModel } from '@entities/product/product.model';

export class UmbrellaTaskUtils {
  static generateUmbrellaTasks(
    underlyingPolicies: UnderlyingPolicyEntity[],
    exposures: Exposures,
    features: FeatureFlagsModel,
    productQuoteStatus: QuoteStatus,
    selectedProducts: ProductModel[],
    policyDateOptions: PolicyDateOptions,
    product: ProductModel
  ): TaskModel[] {
    const tasks = [
      ...TaskValidators.hasAutoAndPropertyUnderlyingPolicies(
        underlyingPolicies
      ),
      ...TaskValidators.hasAtLeastOneExposurePerUnderlyingPolicy(
        underlyingPolicies,
        exposures
      ),
      ...TaskValidators.unissuedUnderlyingPoliciesAreActiveInSession(
        underlyingPolicies,
        selectedProducts
      ),
      ...TaskValidators.productCustomRules(
        product,
        selectedProducts.map(p => p.type),
        policyDateOptions
      ),
    ];
    return tasks;
  }

  static generateCoverageTasks(params: UmbrellaCoverageTaskParams): TaskModel[] {
    // TODO: Add coverage tasks when needed
    return [];
  }
}
