import {
  AddlInterestThirdPartyType,
  MortgageThirdPartyType,
  ProductType,
  TermType,
  VehicleType,
} from '@core/models/api/dsm-types';
import { EligibleDiscountsEntity } from '@core/store/entities/eligible-discounts/eligible-discounts.entity';
import { UmbrellaCoverageMinimum } from '@core/store/entities/umbrella/umbrella.model';
import { OptionsModel } from '@entities/metadata/models/property-options.model';
import { PicklistItem } from '@shared/models/picklist.model';

// TO update DSM_STAGE_TO_TEST_TARGET_ENV, please visit:
// nationwide.apigee.com/organizations/nationwide-prod/environments/stage/key-value-maps/DSM_TargetHosts
// next, select menu options: admin> environments > Key Value Maps > DSM_TargetHosts

export const DSM_STAGE_TO_TEST_TARGET_ENV = {
  jan24a: '',
  jan24b: '',
  feb24a: '',
  feb24b: '',
  mar24a: '',
  mar24b: '',
  apr24a: '',
  apr24b: '',
  may24a: '',
  may24b: '',
  jun24a: '',
  jun24b: '',
  jul24a: '',
  jul24b: '',
  aug24a: 'pls14',
  aug24b: 'pls17',
  sep23a: 'pls15',
  sep23b: 'pls09',
  oct23a: 'pls12',
  oct23b: 'pls02',
  nov23a: 'pls03',
  nov23b: 'pls04',
  dec23a: '',
  dec23b: '',
  comprater: 'pls04',
};

export const PC_INTERNAL_EXTERNAL_MAPPING: { [id: string]: string } = {
  it: 'it',
  st: 'st6',
  test1: 'st',
  test2: 'it2',
  test3: 'st2',
  test6: 'st3',
  test8: 'st4',
  // pls01 thru pls19 are offered via debugger overlay
  pls01: 'pls01',
  pls02: 'pls02',
  pls03: 'pls03',
  pls04: 'pls04',
  pls05: 'pls05',
  pls06: 'pls06',
  pls07: 'pls07',
  pls08: 'pls08',
  pls09: 'pls09',
  pls10: 'pls10',
  pls11: 'pls11',
  pls12: 'pls12',
  pls13: 'pls13',
  pls14: 'pls14',
  pls15: 'pls15',
  pls16: 'pls16',
  pls17: 'pls17',
  pls18: 'pls18',
  pls19: 'pls19',
  // pls20: 'pls20',
  // pls30: 'pls30',
  // pls42: 'pls42',
  // pls44: 'pls44',
  // pls45: 'pls45',
  // pls46: 'pls46',
  // pls47: 'pls47',
  // pls48: 'pls48',
  // pls54: 'pls54',
  // pls57: 'pls57',
  // pls58: 'pls58',
  // pls97: 'pls97',
  // pls98: 'pls98',
  // pls99: 'pls99',
  comprater: 'pls04',
};

export const URL_MAP = {
  'localhost:4200': 'test',
  'nwx-test0.apps-test.aws.e1.nwie.net': 'dev',
  'nwx-test1.apps-test.aws.e1.nwie.net': 'dev',
  'nwx-test2.apps-test.aws.e1.nwie.net': 'dev',
  'nwx-test3.apps-test.aws.e1.nwie.net': 'dev',
  'nwx-test4.apps-test.aws.e1.nwie.net': 'dev',
  'multiproductdev.apps.nwie.net': 'test',
  'multiproductdev2.apps.nwie.net': 'test',
  'multiproductdev3.apps.nwie.net': 'dev',
  'multiproducttest.apps.nwie.net': 'test',
  'multiproducttest2.apps.nwie.net': 'test',
  'multiproductstage.nationwide.com': 'stage',
  'multiproductstage2.nationwide.com': 'stage2',
  'multiproduct.nationwide.com': 'prod',
};

export const PRIVATE_LABEL_DOMAIN_MAP = {
  'affordableamericaninsurance.nwexpress.com': 'prod',
  'alliant.nwexpress.com': 'prod',
  'allinsurance4u.nwexpress.com': 'prod',
  'braunagency.apps.nwie.net': 'dev',
  'braunagency.nwexpress.com': 'prod',
  'choiceins.apps.nwie.net': 'dev',
  'choiceins.nwexpress.com': 'prod',
  'fireflyagency.apps.nwie.net': 'dev',
  'fireflyagency.nwexpress.com': 'prod',
  'flanaganinsuranceagency.nwexpress.com': 'prod',
  'ganleyinsurance.apps.nwie.net': 'dev',
  'ganleyinsurance.nwexpress.com': 'prod',
  'gerberinsuranceagency.apps.nwie.net': 'dev',
  'gerberinsuranceagency.nwexpress.com': 'prod',
  'iscential.nwexpress.com': 'prod',
  'insurance-loft.nwexpress.com': 'prod',
  'malhotrainsurance.apps.nwie.net': 'dev',
  'malhotrainsurance.nwexpress.com': 'prod',
  'quotewithamy.nwexpress.com': 'prod',
  'ruddyinsurancegroup.apps.nwie.net': 'dev',
  'ruddyinsurancegroup.nwexpress.com': 'prod',
  'selectquote.nwexpress.com': 'prod',
  'thestetnishagency.nwexpress.com': 'prod',
  'toyotabrightdrive.apps.nwie.net': 'test',
  'toyotabrightdrive.nwexpress.com': 'prod',
  'watkinsinsurancegroup.nwexpress.com': 'prod',
};

export const PRIVATE_LABEL_PATH_URL_MAP = {
  'nwexpress.com': 'prod',
  'www.nwexpress.com': 'prod',
  'nwexpress.apps.nwie.net': 'test',
};

export const SocialLinks = [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/nationwide',
    icon: 'facebook',
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/nationwide',
    icon: 'twitter',
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/nationwide/',
    icon: 'instagram',
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/company/nationwide/',
    icon: 'linkedIn',
  },
  {
    name: 'YouTube',
    url: 'https://www.youtube.com/nationwide',
    icon: 'youtube',
  },
];

export const OfferingTypeCodes: { [key: string]: string } = {
  HO3: 'Basic (HO-3)',
  HE7: 'Home (HE-7)',
  HE7_20: 'Expanded (HE-7 w/ HE-20)',
  HE7_21: 'Extended (HE-7 w/ HE-21)',
};

export const MaritalStatusCodes = {
  M: 'Married',
  S: 'Single',
  P: 'Separated',
  D: 'Divorced',
  W: 'Widowed',
};

export const ChannelNames = {
  IA: 'IndependentAgent',
  NSS: 'NSS',
  EA: 'ExclusiveAgent',
  INTERNET: 'Internet',
  PRIVATE_LABEL: 'Private Label',
};

export const MaritalStatusToDsmCodes = {
  Married: 'M',
  Single: 'S',
  Separated: 'P',
  Divorced: 'D',
  Widowed: 'W',
};

export const GenderCodes = {
  M: 'Male',
  F: 'Female',
};

export const ProductGroups = {
  AUTO: 'auto',
  PROPERTY: 'property',
  ADDON: 'addon',
};

export const VehicleTypes = {
  AUTO: 'auto',
};

export const DistributionPartnerSearchConstants = {
  LEGACY_ORGANIZATION_ROLE_NUMBER: 'Legacy Producer Organization Role Number',
  LEGACY_INDIVIDUAL_ROLE_NUMBER: 'Legacy Individual Role Number',
  AMF_ADMIN_SYSTEM: 'AMF',
  PDS: 'PDS',
};

export const VehicleLending = {
  LIEN: 'lien',
  LESSOR: 'lessor',
};

export const ModifierDescriptions = {
  ELITE_DRIVER: 'Elite Driver Discount',
  GOOD_DRIVER: 'Good Driver Discount',
};

export const TelematicsProgram = {
  CONNECTED_CAR: 'ConnectedCar',
  SELF_REPORTED: 'SelfReported',
  SELF_REPORTING: 'SelfReported',
  SMART_MILES: 'SmartMiles',
  SMARTRIDE_DEVICE: 'SmartRide',
  SMARTRIDE_INSTANT: 'SmartRideInstant',
  SMARTRIDE_MOBILE: 'MobileApp',
};

export const TelematicsEnrollmentStatus = {
  ENROLLED: 'Enrolled',
  NOT_ENROLLED: 'NotEnrolled',
  PRE_QUALIFIED: 'VerifiedScore',
};

export const BODY_STYLES = [
  '2DR CONV',
  '2DR CPE',
  '2DR HBK',
  '2DR PK',
  '2DR PKUP',
  '2DR',
  '3DR CPE',
  '3DR WAG',
  '4DR COUP',
  '4DR HBK',
  '4DR P',
  '4DR PK',
  '4DR PKU',
  '4DR PKUP',
  '4DR SED',
  '4DR SEDA',
  '4DR SPT',
  '4DR UTIL',
  '4DR WAG',
  '4DR WGN',
  '4DR',
  '5DR HBK',
  '5DR UTIL',
  'CABRI 2D',
  'CONV 2D',
  'CONVRTBL',
  'COUPE 2D',
  'COUPE 4D',
  'CPE 2D',
  'HBK 4D',
  'HBK 4DR',
  'HCHBK 2D',
  'HCHBK 3D',
  'HCHBK 4D',
  'K1500 EX',
  'PKP 4X2',
  'PKP 4X4',
  'PKP4X22D',
  'PKP4X24D',
  'PKP4X42D',
  'PKP4X44D',
  'SED4D4X4',
  'SEDAN 2D',
  'SEDAN 4D',
  'SUT4X24D',
  'SUT4X44D',
  'UTIL 4D',
  'UTIL 4X2',
  'UTIL 4X4',
  'UTL4X24D',
  'UTL4X42D',
  'UTL4X44D',
  'VAN 4X2',
  'VAN',
  'VAN4X22D',
  'VAN4X24D',
  'WAG 4D',
  'WAG 4X2',
  'WAG3D4X2',
  'WAG4D4X2',
  'WAG4D4X4',
  'WAG4X24D',
  'WAGON 4D',
  'WGN 4D',
  'WGN AWD',
];

/* http://policycenterdd.nwie.net/userContent/DataDictionary/content/data/R2_0/dictionary/data/typelists.html
 * DSM for some calls returns bodyType as the Name, and others the Description.
 * All of their calls support either string as input.
 * NWX will permit either.
 */

export const BODY_TYPES_NAME: { [key: string]: string } = {
  0: 'VAN',
  1: '2 DOOR',
  2: '4 DOOR',
  5: 'CONVERTIBLE',
  6: 'WAGON',
  12: '4 DOOR 100 IN',
  16: 'WAGON 100 IN',
  20: '20',
  21: 'VAN 100 IN',
  23: 'SMALL P/U',
  24: 'LARGE P/U',
  '-124': 'Large P/U',
  '-123': 'Small P/U',
  '-121': 'VAN 100IN',
  '-312': 'Four Door 100in',
  '-116': 'WAGON 100IN',
  '-112': '4 DOOR 100IN',
  '-100': 'Unavailable',
  '-224': 'Large pickup',
  '-223': 'small Pickup',
  '-221': 'Van 100 in',
  '-216': 'Wagon 100in',
  '-212': '4 Door 100in',
  '-16': 'Wagon',
  '-15': 'Convertible',
  '-12': '4 Door',
  '-11': '2 Door',
  '-10': 'Van',
};

export const BODY_TYPES_DESCRIPTION: { [key: string]: string } = {
  0: 'Van',
  1: '2 Door',
  2: '4 Door',
  5: 'Convertible',
  6: 'Wagon',
  12: 'Four Door 100in',
  16: 'Wagon 100in',
  21: 'Van 100 in',
  23: 'small Pickup', // sic case
  24: 'Large pickup',
  '-124': 'Large pickup',
  '-123': 'small Pickup',
  '-312': 'Four Door 100in',
  '-100': 'Unavailable',
  '-224': 'Large pickup',
  '-223': 'small Pickup',
  '-221': 'Van 100 in',
  '-216': 'Wagon 100in',
  '-212': 'Four Door 100in',
  '-16': 'Wagon',
  '-15': 'Convertible',
  '-12': '4 Door',
  '-11': '2 Door',
  '-10': 'Van',
};

// This is actually a PC thing, not a VehicleInquiry thing, but it's similar to BODY_TYPES and BODY_STYLES.
// http://policycenterdd.nwie.net/userContent/DataDictionary/content/data/R2_0/dictionary/data/typelists.html
// VehicleSubType_Ext
export const VEHICLE_SUB_TYPES: { [key: string]: string } = {
  Large2Door: 'Large 2 Door',
  Large4Door: 'Large 4 door',
  LargeLuxury: 'Large Luxury',
  LargeMinivan: 'Large Minivan',
  LargePickup: 'Large Pickup',
  LargeSports: 'Large Sports',
  LargeUtilityLuxury: 'Large Utility Luxury',
  LargeUtilityStandard: 'Large Utility Standard',
  LargeVanCargo: 'Large Van/Cargo',
  LargeWagon: 'Large Wagon',
  Micro2Door: 'Micro 2 Door',
  Micro4Door: 'Micro 4 Door',
  MidSizeMinivan: 'Mid Size Minivan',
  MidSizeSports: 'Midsize Sports',
  Midsize2Door: 'Midsize 2 Door',
  Midsize4Door: 'Midsize 4 Door',
  MidsizeLuxury: 'Midsize Luxury',
  MidsizeUtilityLuxury: 'Midsize Utility Luxury',
  MidsizeUtilityStandard: 'Midsize Utility Standard',
  MidsizeVanCargo: 'Mid SizeVanCargo',
  MidsizeWagon: 'Midsize Wagon',
  Mini2Door: 'Mini 2 Door',
  Mini4Door: 'Mini 4 Door',
  MiniWagon: 'Mini Wagon',
  Minisports: 'Mini sports',
  ModelYearPriorto1981: 'Model Year Prior to 1981',
  PassengerVan: 'Passenger Van',
  Small2Door: 'Small 2 Door',
  Small4Door: 'Small 4 Door',
  SmallLuxury: 'Small Luxury',
  SmallPickup: 'Small Pickup',
  SmallUtilityLuxury: 'Small Utility Luxury',
  SmallUtilityStandard: 'Small Utility Standard',
  SmallWagon: 'Small Wagon',
  Smallsports: 'Small sports',
  Unavailable: 'Unavailable',
  VeryLarge4Door: 'Very Large 4 Door',
  VeryLargeLuxury: 'Very Large Luxury',
  VeryLargeMinivan: 'Very Large Minivan',
  VeryLargePickup: 'Very Large Pickup',
  VeryLargeUtilityLuxury: 'Very Large Utility Luxury',
  VeryLargeUtilityStandard: 'Very Large Utility Standard',
};

export const ADDRESS_INPUT = 'Street address, city, state, ZIP';

export const TELEMATICS_MILES_THRESHOLD = 12000;

export const MN_SURCHARGE_DISCLOSURE_PDF_URL =
  'https://static.nationwide.com/static/MN-Surcharge-Notice-0718-accessible.pdf';

export const CONNECTED_CAR_TERMS_AND_CONDITIONSS_URL =
  'https://www.nationwide.com/cps/cps-pdf/smartride-terms.pdf';

export const AGENT_SOURCE = 'agent_web_page';

export const VehiclePrimaryUseCodeStrings = {
  VEHICLE_CODE_WORK: 'Work',
  VEHICLE_CODE_PLEASURE: 'Pleasure',
  VEHICLE_CODE_FARM: 'Farm',
  VEHICLE_CODE_BUSINESS: 'IndividualBusiness',
};

export const VehiclePrimaryUseStrings = {
  VEHICLE_USE_WORK: 'Work/School (daily commute, errands)',
  VEHICLE_USE_PLEASURE: 'Pleasure (recreational driving)',
  VEHICLE_USE_FARM: 'Farm (used strictly on a farm)',
  VEHICLE_USE_BUSINESS: 'Business (deliveries, taxi, sales calls)',
};

export const VehiclePrimaryUseCodes = {
  'Work/School (daily commute, errands)': 'Work',
  'Pleasure (recreational driving)': 'Pleasure',
  'Farm (used strictly on a farm)': 'Farm',
  'Business (deliveries, taxi, sales calls)': 'IndividualBusiness',
};

export const DiscountLabels = {
  VEHICLE_SMARTRIDE: 'SmartRide',
  VEHICLE_SELFREPORTED: 'Self-Reported Mileage',
  VEHICLE_ANTITHEFT: 'Anti Theft',
  VEHICLE_ANTILOCKBRAKES: 'Anti-Lock Brakes',
  VEHICLE_DAYTIMERUNNINGLAMPS: 'Daytime Running Lamps',
  VEHICLE_PASSIVERESTRAINT: 'Passive Restraint',
  VEHICLE_SMARTMILES: 'SmartMiles',
};

export const ModifierValues = {
  NONE: 'None',
  NATIONWIDE_AUTO_POLICY: 'NationwideAutoPolicy',
  NW_FARM_AUTO: 'NWFarmAuto',
  NW_AUTO_AND_NW_FARM_AUTO: 'NWAutoAndNWFarmAuto',
};

export const PropertyProductTypes: ProductType[] = [
  'Homeowner',
  'Condominium',
  'Tenant',
];

export const VehicleProductTypes: ProductType[] = [
  'PersonalAuto',
  'MSA',
  'RV',
  'Boat',
];

export const ProtectionProductTypes: ProductType[] = [
  'PersonalUmbrella',
  'DwellingFire',
];

export const ProductDisplayOrder: ProductType[] = [
  'PersonalAuto',
  'MSA',
  'Boat',
  'Homeowner',
  'Condominium',
  'Tenant',
  'PersonalUmbrella',
  'DwellingFire',
  'TermLife',
];

export const TermTypeToMonths: { [t in TermType]: number } = {
  HalfYear: 6,
  Annual: 12,
};

export const DriverRelationToPNI = {
  CHILD: 'Child',
  CO_OWNER: 'Co-Owner',
  EMPLOYEE: 'Employee',
  OTHER: 'Other',
  OTHER_NON_RELATIVE: 'Other - Non-Relative',
  OTHER_RELATIVE: 'Other - Relative',
  PNI: 'PrimaryNamedInsured',
  SPOUSE: 'Spouse',
};

export const DriverRelationToPniOptions = [
  {
    value: 'Child',
    display: DriverRelationToPNI.CHILD,
  },
  {
    value: 'CoOwner',
    display: DriverRelationToPNI.CO_OWNER,
  },
  {
    value: 'Employee',
    display: DriverRelationToPNI.EMPLOYEE,
  },
  {
    value: 'Other',
    display: DriverRelationToPNI.OTHER,
  },
  {
    value: 'OtherNonRelative',
    display: DriverRelationToPNI.OTHER_NON_RELATIVE,
  },
  {
    value: 'OtherRelative',
    display: DriverRelationToPNI.OTHER_RELATIVE,
  },
  {
    value: 'Spouse',
    display: DriverRelationToPNI.SPOUSE,
  },
];

export const HealthCarePlans = {
  QUALIFYING_COVERAGE: 'QualifyingHealthCoverage',
  MEDICARE: 'Medicare',
  MEDICAID: 'Medicaid',
  OTHER_PIP_POLICY: 'OtherPIPHealthBenefitPolicy',
};

export const DOLLAR = '$';
export const DEFAULT_ID = '0';
export const DEFAULT_SPID = 'Default';
export const APPLICATION_NAME = 'Multiproduct Quote and Bind';
export const PRIVATE_LABEL_APPLICATION_NAME = 'NWX Private Label';
export const DEFAULT_FACEAMOUNT = '100000';
export const TERM_LIFE_HEIGHT_FEET_LENGTH = 1;
export const TERM_LIFE_HEIGHT_FEET_MAX = 9;
export const TERM_LIFE_HEIGHT_FEET_MIN = 1;
export const TERM_LIFE_HEIGHT_INCHES_LENGTH = 2;
export const TERM_LIFE_HEIGHT_INCHES_MAX = 11;
export const TERM_LIFE_HEIGHT_INCHES_MIN = 0;
export const TERM_LIFE_WEIGHT_LENGTH = 3;
export const TERM_LIFE_WEIGHT_MAX = 999;
export const TERM_LIFE_WEIGHT_MIN = 10;
export const TERM_LIFE_DEFAULT_FACE = 100000;

export const ClickToChatParameters = {
  LOB: 'Life',
  APP_ID: 'life-quote',
  CHANNEL: 'NationwideInternet',
};

export const DSM_ANNUAL_MILES_LIMIT = 60000;
export const WEEKS_PER_YEAR = 52;
export const MIN_MILES_PERWEEK = 8;
export const MIN_MILES_PERYEAR = 365;

export const MOBILE_APPLICATION_NAME = 'NW Express Mobile';

export const MobilePartnerIDs = {
  IOS: 'NWX_iOS_Mobile',
  ANDROID: 'NWX_Android_Mobile',
};

export const BindPageTitles = {
  ADDITIONAL_INFORMATION: 'Additional Information',
  LENDERS: 'Lenders',
  PRODUCT_DETAILS: 'Product Details',
  RENTERS: 'Renters',
  PROPERTY_INFO: 'Property Information',
};

export const ProducerTypes = {
  IA: 'IndependentAgent',
  EA: 'ExclusiveAgent',
  NSS: 'NSS',
  DIRECT: 'Internet',
};

export type ProducerType =
  | 'IndependentAgent'
  | 'ExclusiveAgent'
  | 'NSS'
  | 'Internet';

export const DriversOccupationCodes = {
  Accountant: 'Accountant',
  AccreditedCollegeAlumni: 'Accredited College Alumni',
  AllOther: 'All Other',
  BusinessOwner: 'Business Owner',
  Dentist: 'Dentist',
  Educator: 'Educator',
  Firefighter: 'Firefighter',
  LawEnforcementOfficer: 'Law Enforcement Officer',
  LicensedPharmacist: 'California Licensed Pharmacist',
  NationwideEmployeeAgent:
    'Current or Retired Nationwide (or subsidiary) Employee or Agent',
  Paramedic: 'Paramedic',
  PhysicianRegisteredNurse: 'California Licensed Physician or Registered Nurse',
  Pilot: 'Pilot',
  ScientistOrEngineer: 'Scientist or Engineer',
  Veterinarian: 'Veterinarian',
};

export const MembershipTypes = [
  { value: 'Accounting', label: 'Accounting' },
  { value: 'AirlinePilot', label: 'Airline Pilot' },
  { value: 'Alumni', label: 'Alumni' },
  { value: 'Amway', label: 'Amway' },
  { value: 'CarClub', label: 'Car Club' },
  { value: 'CivilServant', label: 'Civil Servant' },
  { value: 'CommunityCivic', label: 'Community Civic' },
  { value: 'CountryClub', label: 'Country Club' },
  { value: 'CreditUnionMember', label: 'Credit Union Member' },
  { value: 'Dental', label: 'Dental' },
  { value: 'Education', label: 'Education' },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Farming', label: 'Farming' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'Medical', label: 'Medical' },
  { value: 'Motorcycle', label: 'Motorcycle' },
  {
    value: 'NWMutualInsuranceEmployee',
    label: 'Nationwide Mutual Insurance Employee',
  },
  { value: 'NationalCosmetology', label: 'National Cosmetology' },
  {
    value: 'PetIndustryJointAdversaryCouncil',
    label: 'Pet Industry Joint Advisory Council',
  },
  {
    value: 'PharmacistPharmaceuticalResearcher',
    label: 'Pharmacist and Pharmaceutical Researcher',
  },
  { value: 'RecVehicle', label: 'Recreational Vehicle' },
  { value: 'SeniorCitizenRetirement', label: 'Senior Citizen or Retirement' },
  { value: 'StateBarOfMichigan', label: 'State Bar of Michigan' },
  { value: 'WholesaleMember', label: 'Wholesale Member' },
  { value: 'YachtClubBoatowner', label: 'Yacht Club or Boatowner' },
];

export const TermLifeHealthCodes = {
  Excellent: 'Excellent',
  Good: 'Good',
  Average: 'Average',
  Fair: 'Fair',
  Poor: 'Poor',
};

export const TermLifeNicotineCodes = {
  Never: 'Never used',
  Year: 'Used within the past year',
  Five: 'Used over one year ago but less than 5 years ago',
  Fivepast: 'Used over 5 years ago',
};

export const TermLifeCvgAmtCodes = {
  100000: '$100,000',
  150000: '$150,000',
  200000: '$200,000',
  250000: '$250,000',
  300000: '$300,000',
  350000: '$350,000',
  400000: '$400,000',
  450000: '$450,000',
  500000: '$500,000',
  550000: '$550,000',
  600000: '$650,000',
  650000: '$600,000',
  700000: '$700,000',
  750000: '$750,000',
  800000: '$800,000',
  850000: '$850,000',
  900000: '$900,000',
  950000: '$950,000',
  1000000: '$1,000,000',
};

export const TermLifeYearTerms = {
  'Nationwide 10-year Term GLT': '10 years',
  'Nationwide 15-year Term GLT': '15 years',
  'Nationwide 20-year Term GLT': '20 years',
  'Nationwide 30-year Term GLT': '30 years',
};

export const UrboNumber = {
  'loading-page': 'NPR-1295AO.1 (11/19), NFW-9599AO',
  '/admin/quote-start': 'NPR-1295AO.1 (11/19), NFW-9599AO',
  '/multi-quote/getting-started': 'NPR-1295AO.1 (11/19), NFW-9599AO',
  '/multi-quote/more-details': 'NPR-1295AO.1 (11/19)',
  '/multi-quote/property-info': 'NPR-1295AO.1 (11/19)',
  '/multi-quote/vehicles': 'NPR-1295AO.1 (11/19)',
  '/multi-quote/people': 'NPR-1295AO.1 (11/19), NFW-9600AO',
  '/multi-quote/coverages': 'NPR-1295AO.1 (11/19), NFW-9258AO',
  '/multi-bind/additional-information': 'NPR-1295AO.1 (11/19), NFW-9687AO',
  '/multi-bind/product-details': 'NPR-1295AO.1 (11/19), NFW-9687AO',
};

export const medicalBenefit = {
  MDCL: 'Medical Expense Benefits',
};

export enum DriverHistoryTypes {
  EXCELLENT = 'Excellent',
  VIOLATION = 'Violation',
  ACCIDENT = 'Accident',
}

export const GoodDriverDisplayByKey = {
  Excellent: '',
  Violation: '070',
  Accident: '140',
};

export const Infractiontype = {
  Excellent: 'Excellent',
  Violation: 'Pretty Good',
  Accident: 'Needs Work',
};

export const GoodDriverDescValue = {
  Excellent: 'Excellent',
  '070': 'Violation',
  '140': 'Accident',
};

export const RatingTypes = {
  QUOTE: 'quote',
  BIND: 'bind',
  ISSUE: 'issue',
};

export const PageAlertIDs = {
  'getting-started': 'GETTING_STARTED',
  'multi-payment/summary': 'BILLING_SUMMARY',
  'multi-payment/billing': 'BILLING_FORM',
  'additional-information': 'ADDITIONAL_INFORMATION',
  lenders: 'LENDERS',
  'policy-information': 'POLICY_INFORMATION',
  'product-details': 'PRODUCT_DETAILS',
  renters: 'RENTERS',
  'multi-policy/account': 'POLICY_FORM',
  'multi-policy/summary': 'POLICY_SUMMARY',
  people: 'ADDITIONAL_DRIVERS',
  'match-driver-vehicle': 'MATCH_DRIVER_VEHICLE',
  'more-details': 'MORE_DETAILS',
  'property-info': 'PROPERTY_INFO',
  vehicles: 'VEHICLES',
  retrieve: 'RETRIEVE',
  'multi-quote/coverages': 'COVERAGES_QUOTE',
  'multi-bind/coverages': 'COVERAGES_BIND',
  'agency-route': 'AGENCY',
  'retrieve/compRater': 'COMP_RATER_LANDING',
  maintenance: 'MAINTENANCE',
  nonStandardBridge: 'NON_STANDARD_BRIDGE',
  search: 'SEARCH',
  softfall: 'SOFTFALL',
};

export const VERISK_THIRDPARTY_IDS = [
  'everquote',
  'hondaverisk',
  'hyundaiverisk',
  'toyotacc',
  'VeriskIoT',
];

export const PagesSupportingMultipleAlerts = ['COMP_RATER_LANDING'];

export const PAGE_ALERT_DISMISS_EXPIRY = 14; // number of days to store cookie

export const DEFAULT_YEARS_WITH_PRIOR_CARRIER = 4;

export const DESKTOP_VIEW = 768;

export const TERM_LIFE_DEFAULT_TEN_YEAR = 'Nationwide 10-year Term GLT';

export const CoverageErrorCodes = {
  // Includes errors beyond just OH, missing covg codes marked with TODO.  Fill in as we onboard those states
  ALN001: ['BI', 'UMBI'],
  APV002: ['BI', 'PD'],
  APV003: ['BI', 'UMBI'],
  APV004: ['UIMBI', 'BI'],
  APV005: ['COMP', 'COLL', 'PD', 'BI'],
  APV006: ['COMP', 'COLL'],
  APV051: ['UMPD', 'PD'],
  APV054: ['MP'],
  APV055: ['UIMBI'],
  APV056: ['UMBI'],
  APV057: ['UMPD'],
  APV068: ['NCRPCOLL'],
  APV069: ['GAPCOLL'],
  APV070: ['UMBI', 'UIMBI'],
  APV071: ['COLL', 'UMPD'],
  APV072: ['UMBI', 'UIMBI'],
  APV073: ['UMPD'],
  APV300: ['PIP', 'MP'],
  APV301: ['PIP'],
  APV302: ['BI', 'UIMBI'],
  APV303: ['LOI'],
  APV304: ['UMPD', 'BI'],
  APV305: ['PIP', 'BI'],
  APV306: ['UIMPD', 'PD'],
  ALN313: ['IDFR'],
  APV400: ['UMBI', 'BI'],
  ALN401: ['BI', 'OBEL'],
  ALN402: ['APIP', 'BI'],
  ALN403: ['BI'], // TODO: What is the coverage code for supplemental spousal liability?
  APV404: ['UMBI', 'BI'],
  APV405: ['PIP', 'BI'],
  APV406: ['COMP', 'COLL'],
  APV408: ['UMPD', 'UMBI'],
  ALN501: ['BI', 'PD'],
  ALN503: ['BI'], // TODO: What is the coverage code for medical benefits?
  ALN504: ['UMPD', 'BI'],
  APV504: ['BI'], // TODO: What is the coverage code for work loss benefits?
  ALN505: ['UIMPD', 'BI'],
  APV505: ['BI'], // TODO: What is the coverage code for funeral benefits?
  ALN506: ['UMBI', 'BI'],
  APV506: ['MP', 'BI'],
  APV508: ['UIMPD', 'UMPD'],
  APV509: ['UIMBI', 'UMBI'],
  APV510: ['UMBI', 'BI'],
  ALN511: ['BI'],
  APV511: ['UMBI', 'BI'],
  ALN512: ['PD'],
  APV513: ['UIMPD', 'UIMBI'],
  ALN515: ['UIMPD', 'UIMBI'],
  ALN516: ['UIMPD', 'UIMBI'],
};

export const PERSONAL_PROPERTY_MAX = 199999;
export const CUSTOM_EQUIPMENT_MIN = 3000;
export const CUSTOM_EQUIPMENT_MAX = 30000;
export const TENANT_BIND_PERSONAL_PROPERTY_LIMIT: number = 200000;
export const CONDO_BIND_PERSONAL_PROPERTY_LIMIT: number = 300001;

// START OF ALPHABETICAL ORDER SECTION
//
// vvv below vvv CoverageCodes are listed alphabetically.
export const CoverageCodes = {
  AllPerilDeductible: 'AllPerilDeductible',
  BasicCoverageDeductible: 'BasicCoverageDeductible',
  BasicCoverageLimit: 'BasicCoverageLimit',
  BioDeteriorationDamageCoverageLimit: 'BioDeteriorationDamageCoverageLimit',
  BISuppSpousalLiability: 'BISuppSpousalLiability',
  BuildingAdditionsAlterationsLimit: 'BuildingAdditionsAlterationsLimit',
  CompDeductible: 'COMPDeductible',
  CustomEquipmentLimit: 'CUSTLimit',
  EQBasicCoverageDeductible: 'EarthquakeBasicCoverageDeductible',
  EQBasicCoverageLimit: 'EarthquakeBasicCoverageLimit',
  FarmLiability: 'FarmLiability',
  FTSSLimit: 'FTSSLimit',
  HurricanePerilDeductible: 'HurricanePerilDeductible',
  IncreasedLossOfUseALS: 'IncreasedLossOfUseALS',
  LossSettlementProvision: 'LossSettlementProvision',
  MineSubsidenceLimit: 'MineSubsidenceLimit',
  MoldLimit: 'MoldLimit',
  NumberBeingCaredFor: 'NumberBeingCaredFor',
  PersonalInjuryLimit: 'PersonalInjuryLimit',
  PersonalPropOtherResLimit: 'PersonalPropOtherResLimit',
  PIPAppliesTo: 'PIPAppliesTo',
  PIPDeductible: 'PIPDeductible',
  PIPGuestOnly: 'PIPGuestOnly',
  PIPLimit: 'PIPLimit',
  PIPMedicalExpenseExcluded: 'PIPMedicalExpenseExcluded',
  PIPOption: 'PIPOption',
  PIPPackageLimit: 'PIPPackageLimit',
  PIPWorkLossExcluded: 'PIPWorkLossExcluded',
  RCPEDeductible: 'RCPEDeductible',
  RCPELimit: 'RCPELimit',
  ServiceLineDeductible: 'ServiceLineDeductible',
  TORTOption: 'TORTOption',
  TREXOption: 'TREXOption',
  UMBIOption: 'UMBIOption',
  UMPDDeductible: 'UMPDDeductible',
  UnscheduledJewelryWatchesFursLimit: 'UnscheduledJewelryWatchesFursLimit',
  UnscheduledMoneyLimit: 'UnscheduledMoneyLimit',
  UnscheduledPersonalPropertyDeductible:
    'UnscheduledPersonalPropertyDeductible',
  UnscheduledSecuritiesLimit: 'UnscheduledSecuritiesLimit',
  UnscheduledSilverwareLimit: 'UnscheduledSilverwareLimit',
  WindHailPerilDeductible: 'WindHailPerilDeductible',
};
// ^^^ above ^^^ CoverageCodes are listed alphabetically! ^^
// Thank you, in advance, for helping to preserve a nicely ordered list :)
//
// vvv below vvv CoverageIds are listed alphabetically.
export const CoverageIds = {
  AF: 'AF',
  BasicCoverage: 'BasicCoverage',
  BioDeteriorationDamageCoverage: 'BioDeteriorationDamageCoverage',
  BodilyInjury: 'BI',
  BuildingAdditionsAlterations: 'BuildingAdditionsAlterations',
  COLL: 'COLL',
  COMP: 'COMP',
  CustomEquipment: 'CEC',
  EarthquakeBasicCoverage: 'EarthquakeBasicCoverage',
  ExtendedNonOwned: 'ENO',
  FarmLiability: 'FarmLiability',
  FSHEQ: 'FSHEQ',
  FTPKG: 'FTPKG',
  FTSS: 'FTSS',
  GAPCOLL: 'GAPCOLL',
  GAPCOMP: 'GAPCOMP',
  IncreasedLossOfUse: 'IncreasedLossOfUse',
  LimitedPropertyDamage: 'LPD',
  LimitedRoofLoss: 'LimitedRoofLoss',
  LOI: 'LOI',
  MDCL: 'MDCL',
  MineSubsidence: 'MineSubsidence',
  Mold: 'Mold',
  MP: 'MP',
  MVF: 'MVF',
  NCRPCOLL: 'NCRPCOLL',
  PDSO: 'PDSO',
  PersonalInjury: 'PersonalInjury',
  PersonalLiability: 'PersonalLiability',
  PersonalPropOtherRes: 'PersonalPropOtherRes',
  PIP: 'PIP',
  PropertyDamage: 'PD',
  PROTE: 'PROTE',
  ProtectionBoost: 'ProtectionBoost',
  RCPE: 'RCPE',
  RENTRE: 'RENTRE',
  RSA: 'RSA',
  ScheduledPersonalEffects: 'SPE',
  SectionIDeductibles: 'SectionIDeductibles',
  ServiceLine: 'ServiceLine',
  SpecialComputer: 'SpecialComputer',
  TORT: 'TORT',
  UMBI: 'UMBI',
  UMPD: 'UMPD',
  UMUIMBI: 'UMUIMBI',
  UnderinsuredMotoristBodilyInjury: 'UIMBI',
  UninsuredMotoristsBodilyInjury: 'UMBI',
  UnscheduledJewelryWatchesFurs: 'UnscheduledJewelryWatchesFurs',
  UnscheduledMoney: 'UnscheduledMoney',
  UnscheduledPersonalProperty: 'UnscheduledPersonalProperty',
  UnscheduledSecurities: 'UnscheduledSecurities',
  UnscheduledSilverware: 'UnscheduledSilverware',
  WaterBackupFull: 'WaterBackupFull',
  WaterBackupLimited: 'WaterBackupLimited',
  WorkersCompensation: 'WorkersCompensation',
};
// ^^^ above ^^^ CoverageIds are listed alphabetically! ^^
// Thank you, in advance, for helping to preserve a nicely ordered list :)
//
// END OF ALPHABETICAL ORDER SECTION

export const CoverageTermIds = {
  UMPDOption: 'UMPDOption',
  DIOption: 'DIOption',
};

export const ProductAvailabilityDisclaimer = `Some products currently need to be completed in PolicyCenter.
  Select and begin these products here with your full bundle.
  Access will be provided for you to transfer to PolicyCenter to complete quoting them.
  Note: Boat and RV quoting will be fully supported in Nationwide Express soon!`;

export const garageAddressHelpText =
  'This is the address where the vehicle is usually parked or garaged. ' +
  'In many cases, this is different than the policyholder’s primary residence. ' +
  'Students living greater than 100 miles from home should use their school address as their garaging address.';

export const CoverageTermNumberFormats: {
  [termCode: string]: 'integer' | 'dollars';
} = {
  WorkersCompensationOccassionalEmployee: 'integer',
  WorkersCompFullTimeOutservant: 'integer',
  WorkersCompFullTimeEstate: 'integer',
  WorkersCompensationFullTime: 'integer',
  // default: 'dollars'
};

export const CoveragesShowDescriptionInsteadOfName = ['GAPCOMP', 'GAPCOLL'];

export const StateStringReplacement: {
  [key: string]: {
    [key: string]: {
      replacement: string;
    };
  };
} = {
  AR: {
    'Medical Benefits': {
      replacement: 'Medical Expense Benefits',
    },
  },
  CA: {
    Dwelling: {
      replacement: 'Dwelling Amount including Debris Removal',
    },
    'Workers Compensation': {
      replacement: "Worker's Compensation - Resident Employees",
    },
  },
  NC: {
    Silverware: {
      replacement: 'Theft of Silverware',
    },
    'Jewelry, Watches, Furs': {
      replacement: 'Theft of Jewelry, Watches, Furs',
    },
  },
  WV: {
    'Uninsured Motorist Bodily Injury': {
      replacement: 'Uninsured Motorists Bodily Injury Liability',
    },
    'Underinsured Motorist Bodily Injury': {
      replacement: 'Underinsured Motorists Bodily Injury Liability',
    },
    'Uninsured Motorist Property Damage': {
      replacement: 'Uninsured Motorists Property Damage Liability',
    },
    'Underinsured Motorist Property Damage': {
      replacement: 'Underinsured Motorists Property Damage Liability',
    },
  },
};

export const StateExceptionCoverageLimit: {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        value: string;
      };
    };
  };
} = {
  NY: {
    BioDeteriorationDamageCoverage: {
      BioDeteriorationDamageCoverageLimit: {
        value: '20000',
      },
    },
    Mold: {
      MoldLimit: {
        value: '50000',
      },
    },
  },
};

export const ProtectiveDeviceSelectedValueReplace: {
  [key: string]: {
    [key: string]: {
      replacement: string;
    };
  };
} = {
  OpeningProtection_Ext: {
    None: {
      replacement: 'None',
    },
  },
};

export const SECONDS_BEFORE_EXPIRE_TO_REFRESH = 30; // Refresh 30 seconds before the access token expires
export const VEHICLES_ALLOWED_ON_QUOTE = 9;

export const NationwideContactNumber = '1-855-550-0768';

// see employment-information-form.component.html for a list of acceptable CurrentEmployment value options

export const ALL_OTHER_OCCUPATION = 'AllOther';
export const MAX_AGE_FOR_STUDENT = 22;
export const MAX_AGE_FOR_YOUTH = 25;
export const MIN_AGE_FOR_RETIRED = 65;
export const CHAR_CODE_SPECIAL = 47;
export const CHAR_CODE_SYMBOL = 58;

export const RENOVATION = {
  ALL: 'When were the following installed, last renovated, or replaced?',
  SINGLE: 'Year {renovationType} was last replaced?',
};

export const AUTO_FORM_TEXT_ENG = {
  AUTO_DISCLAIMER:
    'Assumes vehicles are: not used for business, speed or racing; are ' +
    'not garaged in AK, HI, LA and MA; do not have aftermarket add-ons ' +
    'or accessories installed on the vehicles; garaged at the same ' +
    'location as the residence. ',
  ADD_ONE_AUTO: 'Please add at least one Auto.',
  ADD_ONE_MOTORCYCLE: 'Please add at least one Auto.',
  ADD_VEHICLES: 'Add vehicles',
  BUSINESS_AUTO_QUOTE_URL:
    'https://www.nationwide.com/business-insurance-quotes.jsp',
  CUSTOM_QUOTE:
    'A <business_quote> commercial auto quote</a> ' +
    'can be obtained if the vehicle is used for business or a ' +
    '<nationwide_url> customized quote</a> can be ' +
    'obtained if the vehicle has aftermarket add-ons.',
  EXCEEDED_LIMIT: 'Exceed vehicles limit.',
  FOUND_IN_PUBLIC_RECORD_SEARCH:
    'We found these vehicles in a public records search',
  INCLUDE_IN_POLICY: 'To include on the policy, click add.',
  MAXIMUM_REACHED: 'The maximum number of vehicles has been added.',
  MISSING_CONTENT: 'Please complete all required fields and save any changes',
  NATIONWIDE_URL: 'https://www.nationwide.com/',
  PLEASE_CALL: 'If a vehicle is used for business purposes, please call',
  VEHICLES: 'Vehicles',
};

export const HO_PROP_INFO_FORM_ENG = {
  PROPERTY_INFO: {
    CONSTRUCTION_INFO:
      'We were unable to find construction information on this home in a public records search. Please complete the following details.',
    CONSTRUCTION_INFO_MSB:
      'We have found the following construction information on this home in a ' +
      'public records search. Please verify the information is correct.',
    CORRECT_ADDITIONAL_DETAILS:
      'Please correct the additional property details.',
    CORRECT_YEAR: 'Please correct year of home purchased or the year built.',
    GET_RENO_ERROR: {
      AFTER_YEAR_BUILT: 'Must be after year built',
      FUTURE_NOT_ALLOWED: 'Future dates not allowed',
      INCORRECT_FORMAT: 'Must be in YYYY format',
    },
    PLEASE_CORRECT: 'Please correct the following errors',
    TELL_US: 'Tell us about the home',
    TELL_US_RENTAL: 'Tell us about the rental property',
  },
};

export const ADDITIONAL_PEOPLE_ENG = {
  AUTO_MULTI: {
    ADD_EVERYONE_ELSE:
      'Add everyone else who lives in the home, including non-drivers',
  },
  HO_RENTER: {
    ADD_EVERYONE_ELSE: 'Add everyone else who lives in the home',
  },
  EXCLUDE_DRIVERS: {
    AGENT:
      'Assumes all drivers were licensed at age 16 and have not been convicted of insurance fraud. ',
    DIRECT:
      'Assumes all drivers were licensed at age 16 and have not been convicted of insurance fraud. ' +
      'For more information on how to exclude a driver, please call ',
  },
  MISSING_CONTENT: 'Please complete all required fields and save any changes',
};

export const MortgageeTypes: {
  PRIMARY_MORTGAGEE: MortgageThirdPartyType;
  MORTGAGEE: MortgageThirdPartyType;
  PRIMARY_LOSS_PAYEE: MortgageThirdPartyType;
  LOSS_PAYEE: MortgageThirdPartyType;
} = {
  MORTGAGEE: 'Mortgagee',
  LOSS_PAYEE: 'LossPayee',
  PRIMARY_LOSS_PAYEE: 'LossPayee1',
  PRIMARY_MORTGAGEE: 'Mortgagee1',
};

export const DWELLING_ADDITIONAL_INTEREST_TYPES: AddlInterestThirdPartyType[] =
  ['AdditionalInsured_Ext', 'NoticeDesignee_Ext', 'CertHolder_Ext'];

export const MAX_ALLOWED_MORTGAGEES = 4;

export const AFMVF_SMARTRIDE_NOTICE_VERBIAGE =
  'SmartRide enrollment has been removed. Accident Forgiveness and Minor Violation Forgiveness are required for SmartRide eligibility.';

export const GarageTypeAllowedCars: { [key: string]: number } = {
  'Attached Garage': 5,
  'Basement Garage': 3,
  'Built-in Garage': 3,
  Carport: 3,
  'Detached Garage': 5,
};

export const PriorCarrierYearsOptions: { key: string; value: string }[] = [
  { key: '0', value: '0' },
  { key: '1', value: '1' },
  { key: '2', value: '2' },
  { key: '3', value: '3' },
  { key: '4', value: '4' },
  { key: '5', value: '5+' },
];

// UMBRELLA
export const PropertyPolicyTypes = {
  HOMEOWNERS: 'HO3',
  RENTERS: 'HO4',
  CONDO: 'HO6',
};

export const LocationUseage = {
  PRIMARY: 'prim',
};

export const StateExceptionTelematicsDiscountMetadata: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  // States excluded from increasing initialDiscount to 15% on the rollout schedule ***
  CA: {
    initialDiscount: '5%',
    maximumDiscount: '15%',
  },
  NC: {
    initialDiscount: '10%',
  },
  NY: {
    initialDiscount: '10%',
    maximumDiscount: '25%',
  },
  PA: {
    initialDiscount: '10%',
  },
  // end of excluded states ***********************************************************
  AL: {
    initialDiscount: '15%',
  },
  AR: {
    initialDiscount: '15%',
  },
  AZ: {
    initialDiscount: '15%',
  },
  CO: {
    initialDiscount: '15%',
  },
  DC: {
    initialDiscount: '15%',
  },
  DE: {
    initialDiscount: '15%',
  },
  CT: {
    initialDiscount: '15%',
  },
  GA: {
    initialDiscount: '15%',
  },
  IA: {
    initialDiscount: '15%',
  },
  IL: {
    initialDiscount: '15%',
  },
  IN: {
    initialDiscount: '15%',
  },
  KS: {
    initialDiscount: '15%',
  },
  KY: {
    initialDiscount: '15%',
  },
  MA: {
    initialDiscount: '15%',
  },
  MD: {
    initialDiscount: '15%',
  },
  ME: {
    initialDiscount: '15%',
  },
  MI: {
    initialDiscount: '15%',
  },
  MN: {
    initialDiscount: '15%',
  },
  MO: {
    initialDiscount: '15%',
  },
  MS: {
    initialDiscount: '15%',
  },
  MT: {
    initialDiscount: '15%',
  },
  NE: {
    initialDiscount: '15%',
  },
  NH: {
    initialDiscount: '15%',
  },
  NV: {
    initialDiscount: '15%',
  },
  OH: {
    initialDiscount: '15%',
  },
  OR: {
    initialDiscount: '15%',
  },
  SC: {
    initialDiscount: '15%',
  },
  SD: {
    initialDiscount: '15%',
  },
  TN: {
    initialDiscount: '15%',
  },
  TX: {
    initialDiscount: '15%',
  },
  UT: {
    initialDiscount: '15%',
  },
  VA: {
    initialDiscount: '15%',
  },
  VT: {
    initialDiscount: '15%',
  },
  WA: {
    initialDiscount: '15%',
  },
  WI: {
    initialDiscount: '15%',
  },
  WV: {
    initialDiscount: '15%',
  },
};

export const vehicleDiscountOptions: {
  [key: string]: EligibleDiscountsEntity;
} = {
  AntiLockBrakes: {
    name: 'Anti-Lock Brakes',
    selectedOptionValue: 'true',
    eligibleDiscountId: 'AntiLockBrakes',
    description: 'Anti-Lock Brakes',
  },
  AntiTheft: {
    name: 'Anti Theft',
    qualifyingInformation: {
      selectedAntiTheft: ['P'],
    },
    selectedOptionValue: 'true',
    availableOptions: [
      {
        optionDescription: 'Active Disabling',
        isMandatory: false,
        optionValue: 'D',
      },
      {
        optionDescription: 'VIN Etching',
        isMandatory: false,
        optionValue: 'V',
      },
      {
        optionDescription: 'Recovery Device',
        isMandatory: false,
        optionValue: 'K',
      },
      {
        optionDescription: 'Passive Disabling',
        isMandatory: true,
        optionValue: 'P',
      },
      {
        optionDescription: 'Alarm',
        isMandatory: false,
        optionValue: 'A',
      },
    ],
    eligibleDiscountId: 'AntiTheft',
    description: 'Anti Theft',
  },
  AutomaticTirePressure: {
    name: 'Automatic Tire Pressure Monitoring System',
    selectedOptionValue: 'false',
    eligibleDiscountId: 'AutomaticTirePressure',
    description: 'Automatic Tire Pressure Monitoring Discount',
  },
  DaytimeRunningLamps: {
    name: 'Daytime Running Lamps',
    selectedOptionValue: 'true',
    eligibleDiscountId: 'DaytimeRunningLamps',
    description: 'Daytime Running Lamps Discount',
  },
  FiberglassConstruction: {
    name: 'Fiberglass Construction',
    selectedOptionValue: 'false',
    eligibleDiscountId: 'FiberglassConstruction',
    description: 'Fiberglass Construction',
  },
  PassiveRestraint: {
    name: 'Passive Restraint',
    selectedOptionValue: 'AirBag_Ext',
    availableOptions: [
      {
        optionDescription: 'Airbag Full',
        optionValue: 'AirBag_Ext',
      },
      {
        optionDescription: 'Airbag Drivers Side',
        optionValue: 'DriverSideAirBags_Ext',
      },
      {
        optionDescription: 'Passive Seat Belt Full',
        optionValue: 'PassiveSeatBelt_Ext',
      },
    ],
    eligibleDiscountId: 'PassiveRestraint',
    description: 'Passive Restraint System',
  },
  SafetyDevice: {
    name: 'Safety Device',
    selectedOptionValue: 'None',

    availableOptions: [
      {
        optionDescription: 'LP Gas Protection System with Automatic Shutoff',
        optionValue: 'LPGasDetection',
      },
      {
        optionDescription: 'Spartan Chassis Fire Suppression System',
        optionValue: 'SpartanChassisFireSuppression',
      },
      {
        optionDescription: 'Fire Suppression System',
        optionValue: 'FireSuppressionSystem',
      },
    ],
    eligibleDiscountId: 'SafetyDevice',
    description: 'Safety Device',
  },
  Carpool: {
    name: 'Carpool',
    eligibleDiscountId: 'Carpool',
    selectedOptionValue: 'None',
    description: 'Car Pool Discount',
  },
};

export const RedirectDestinations = {
  PC: 'PC',
  NWX: 'NWX',
};

export const RedirectTargets = {
  PNI: 'PNI',
  QUOTE_COVERAGES: 'QUOTE_COVERAGES',
};

export const ANNUAL_MILEAGE_HELP_TEXT = `<b>For vehicles in the pay-per-mile program, SmartMiles,</b> your estimated mileage is used to calculate your
     average monthly cost. You can update your estimated annual mileage for this vehicle by changing the mileage above.`;
export const FeatureNames = {
  Michigan: 'Michigan',
};

export const WindProtectiveDeviceNames = [
  'windProtectiveDeviceMD',
  'windProtectiveDeviceCTNY',
  'windProtectiveDeviceRI',
];

export const SQUARE_FOOTAGE_MIN = 100;
export const SQUARE_FOOTAGE_MAX = 9950;

export const TermLifeConstants = {
  maxHeightFeet: 9,
  maxHeightInches: 11,
  maxWeightChars: 3,
  maxWeightTotal: 999,
};

export const TERMLIFE_TERM_MONTHS = 12;

export const ANTI_THEFT_CODES: string[] = ['A', 'D', 'K', 'P', 'V'];
export const ANTI_LOCK_BREAKES_CODES: string[] = ['R', 'S', 'U'];
export const DAY_TIME_RUNNING_LAMPS_CODE: string = 'D';

export const CoverageMinimumsForUmbrella: UmbrellaCoverageMinimum[] = [
  {
    coverageId: CoverageIds.BodilyInjury,
    code: 'BILimit',
    limit: 250000,
    productTypes: ['PersonalAuto', 'RV', 'MSA'],
  },
  {
    coverageId: CoverageIds.PropertyDamage,
    code: 'PDLimit',
    limit: 100000,
    productTypes: ['PersonalAuto', 'RV', 'MSA'],
  },
  {
    coverageId: CoverageIds.UnderinsuredMotoristBodilyInjury,
    code: 'UIMBILimit',
    limit: 250000,
    productTypes: ['PersonalAuto', 'RV', 'MSA'],
  },
  {
    coverageId: CoverageIds.UninsuredMotoristsBodilyInjury,
    code: 'UMBILimit',
    limit: 250000,
    productTypes: ['PersonalAuto', 'RV', 'MSA'],
  },
  {
    coverageId: CoverageIds.PersonalLiability,
    code: 'PersonalLiabilityLimit',
    limit: 300000,
    productTypes: ['Homeowner', 'Condominium', 'Tenant'],
  },
  {
    coverageId: CoverageIds.PropertyDamage,
    code: 'PDLimit',
    limit: 100000,
    productTypes: ['Boat'],
  },
  {
    coverageId: CoverageIds.BodilyInjury,
    code: 'BILimit',
    limit: 300000,
    productTypes: ['Boat'],
  },
  {
    coverageId: CoverageIds.UnderinsuredMotoristBodilyInjury,
    code: 'UIMBILimit',
    limit: 300000,
    productTypes: ['Boat'],
  },
  {
    coverageId: CoverageIds.UninsuredMotoristsBodilyInjury,
    code: 'UMBILimit',
    limit: 300000,
    productTypes: ['Boat'],
  },
];

// Note: not a conclusive list, some states may not have any age restrictions defined per requirements
// Structure:
//  state: {
//    age limit: number (years) minimum age someone must be for the discount to apply,
//    courseDate: number (months) restricts how many months ago a course may be taken for the discount to apply
//  }
export const DriverTrainingDiscountRestrictions = [
  {
    state: 'AL',
    restrictions: {
      ageLimit: 55,
      courseDate: 36,
    },
  },
  {
    state: 'NV',
    restrictions: {
      ageLimit: 55,
      courseDate: 36,
    },
  },
  {
    state: 'MN',
    restrictions: {
      ageLimit: 55,
      courseDate: 36,
    },
  },
  {
    state: 'CT',
    restrictions: {
      ageLimit: 60,
      courseDate: 36,
    },
  },
  {
    state: 'OH',
    restrictions: {
      ageLimit: 60,
      courseDate: 36,
    },
  },
  {
    state: 'DC',
    restrictions: {
      ageLimit: 50,
      courseDate: 24,
    },
  },
  {
    state: 'DE',
    restrictions: {
      ageLimit: 0,
      courseDate: 36,
    },
  },
  {
    state: 'GA',
    restrictions: {
      ageLimit: 0,
      courseDate: 36,
    },
  },
  {
    state: 'KS',
    restrictions: {
      ageLimit: 0,
      courseDate: 36,
    },
  },
  {
    state: 'NJ',
    restrictions: {
      ageLimit: 0,
      courseDate: 36,
    },
  },
  {
    state: 'IL',
    restrictions: {
      ageLimit: 55,
      courseDate: 1000,
      // note: no violations or at fault accidents last 12mo
      // handled by PC
    },
  },
  {
    state: 'KY',
    restrictions: {
      ageLimit: 55,
      courseDate: 36,
    },
  },
  {
    state: 'MT',
    restrictions: {
      ageLimit: 55,
      courseDate: 24,
    },
  },
  {
    state: 'ND',
    restrictions: {
      ageLimit: 0,
      courseDate: 24,
      secondaryAgeLimit: 55,
      secondaryCourseDate: 36,
    },
  },

  {
    state: 'OR',
    restrictions: {
      ageLimit: 55,
      courseDate: 36,
      secondaryAgeLimit: 69,
      secondaryCourseDate: 24,
    },
    // note: no other drivers under 25 on policy, this is handled by PC
  },
  {
    state: 'SC',
    restrictions: {
      ageLimit: 25,
      courseDate: 36,
    },
  },
  {
    state: 'WY',
    restrictions: {
      ageLimit: 55,
      courseDate: 24,
      // TODO: not offered in one product
    },
  },
];

export const SmartRideEarlyRewardReleases = [
  {
    state: 'AL',
    releaseDate: '3/19/2022', //CODEMINERS-3817 & excel has 10/22/2022
  },
  {
    state: 'AR',
    releaseDate: '9/6/2021', // excel has 7/24/2023
  },
  {
    state: 'AZ',
    releaseDate: '10/22/2022', //in excel
  },
  {
    state: 'CO',
    releaseDate: '11/10/2022', //in excel
  },
  {
    state: 'CT',
    releaseDate: '11/10/2022', //in excel
  },
  {
    state: 'DC',
    releaseDate: '6/5/2022', //in excel
  },
  {
    state: 'DE',
    releaseDate: '6/5/2022', //in excel
  },
  {
    state: 'FL',
    releaseDate: '9/24/2023', //CODEMINERS-5050 & in excel
  },
  {
    state: 'GA',
    releaseDate: '', //excel has 2/23/2024
  },
  {
    state: 'IA',
    releaseDate: '5/8/2022', //in excel
  },
  {
    state: 'ID',
    releaseDate: '11/6/2022', //in excel
  },
  {
    state: 'IL',
    releaseDate: '8/8/2022', //in excel
  },
  {
    state: 'IN',
    releaseDate: '12/4/2022', //excel has 5/8/2022
  },
  {
    state: 'KS',
    releaseDate: '1/2/2023', //CODEMINERS-4262 & in excel
  },
  {
    state: 'KY',
    releaseDate: '12/4/2022', //in excel
  },
  {
    state: 'MA',
    releaseDate: '5/8/2022', //excel has 4/8/2023
  },
  {
    state: 'MD',
    releaseDate: '9/15/2022', //in excel
  },
  {
    state: 'ME',
    releaseDate: '1/2/2023', //CODEMINERS-5323 & in excel
  },
  {
    state: 'MI',
    releaseDate: '11/7/2022', //in excel
  },
  {
    state: 'MN',
    releaseDate: '10/13/2022', //in excel
  },
  {
    state: 'MO',
    releaseDate: '1/11/2023', //CODEMINERS-4264 & in excel
  },
  {
    state: 'MS',
    releaseDate: '12/18/2021', //CODEMINERS-3816 & excel has 6/25/2022
  },
  {
    state: 'MT',
    releaseDate: '10/21/2023', //updated via CODEMINERS-6225 b/c had reexisting date of 7May22.
  },
  {
    state: 'NC',
    releaseDate: '10/2/2022', //in excel
  },
  {
    state: 'ND',
    releaseDate: '10/23/2023', //CODEMINERS-4250
  },
  {
    state: 'NE',
    releaseDate: '4/8/2023', //CODEMINERS-5884 and excel
  },
  {
    state: 'NH',
    releaseDate: '7/10/2022', //in excel
  },
  {
    state: 'NJ',
    releaseDate: '11/5/2023', //CODEMINERS-6225 date matches what appears in excel attachment
  },
  {
    state: 'NM',
    releaseDate: '5/1/2022', //in excel
  },
  {
    state: 'NV',
    releaseDate: '5/8/2022', //in excel
  },
  {
    state: 'NY',
    releaseDate: '', // tbd in excel
  },
  {
    state: 'OH',
    releaseDate: '7/28/2021', // excel has 3/3/2024
  },
  {
    state: 'OK',
    releaseDate: '', //crossed out in excel
  },
  {
    state: 'OR',
    releaseDate: '7/1/2022', //in excel
  },
  {
    state: 'PA',
    releaseDate: '6/5/2022', //in excel
  },
  {
    state: 'RI',
    releaseDate: '3/6/2022', // excel has 3/5/2023
  },
  {
    state: 'SC',
    releaseDate: '1/2/2023', //CODEMINERS-5322 & in excel
  },
  {
    state: 'SD',
    releaseDate: '7/10/2022', //in excel
  },
  {
    state: 'TN',
    releaseDate: '5/1/2022', //excel has 4/21/2023
  },
  {
    state: 'TX',
    releaseDate: '2/19/2022', //excel has 11/10/2021
  },
  {
    state: 'UT',
    releaseDate: '5/8/2022', // excel has 11/12/2021
  },
  {
    state: 'VA',
    releaseDate: '3/5/2024', // in excel
  },
  {
    state: 'VT',
    releaseDate: '1/18/2024', // in excel
  },
  {
    state: 'WI',
    releaseDate: '1/3/2022', //in CODEMINERS-3819 and excel has 11/3/2023
  },
  {
    state: 'WY',
    releaseDate: '3/6/2022', //in excel
  },
  {
    state: 'WA',
    releaseDate: '', //excel has 7/9/2023
  },
  {
    state: 'WV',
    releaseDate: '3/1/2023', //CODEMINERS-4259 and in excel
  },
];

export const FORM_DEBOUNCE_TIME = window?.navigator?.webdriver ? 0 : 500;

export const TasksModelKeyToProductType = {
  boatEffectiveDate: 'Boat',
  condominiumEffectiveDate: 'Condominium',
  homeownerEffectiveDate: 'Homeowner',
  msaEffectiveDate: 'MSA',
  personalAutoEffectiveDate: 'PersonalAuto',
  personalUmbrellaEffectiveDate: 'PersonalUmbrella',
  rvEffectiveDate: 'RV',
  tenantEffectiveDate: 'Tenant',
};

// value of RV vehicles which require higher comp deductible
export const MOTORHOME_HIGH_VALUE = 500000;
export const TRAVEL_TRAILER_HIGH_VALUE = 300000;
export const MOTORHOME_HIGH_VALUE_MED_DUTY_TOW = 100000;

// min deductible for high value RV vehicles
export const MIN_DEDUCTIBLE_HIGH_VALUE_MOTORHOME = 2500;
export const MIN_DEDUCTIBLE_TRAVEL_TRAILER = 500;

export const MAX_RV_CARHAULER_VALUE = 200000;
export const MAX_RV_UTIL_TRAILER_VALUE = 50000;

export const AUTO_PREBIND_DOCUMENTS = [
  'MIN_PACKAGE_INVOICE',
  'PA_IMPORTANT_NOTICE',
  'TORT_OPTION',
  'TORT_OPTIONS_PREM_DISCOUNTS',
];

export const MSA_PREBIND_DOCUMENTS = [
  'MIN_PACKAGE_INVOICE',
  'MOTO_OFFROAD_INS_MERIT_RATING_PLAN',
  'MOTO_OFFROAD_APPICATION_WITH_PRIVACY_STATEMENT',
  'PA_IMPORTANT_NOTICE',
  'UIM_SELECTION_REJECTION',
  'UM_SELECTION_REJECTION',
];

export const PREBIND_DOCUMENT_FILE_NAMES: { [id: string]: string } = {
  AUTO_INS_MERIT_RATING_PLAN:
    'Automobile Insurance Merit Rating Plan Information - Pennsylvania (NGI).pdf',
  MIN_PACKAGE_INVOICE: 'Minimum Package Invoice - Pennsylvania.pdf',
  MOTO_OFFROAD_INS_MERIT_RATING_PLAN:
    'Motorcycle & Off-Road Insurance Merit Rating Plan Information - Pennsylvania.pdf',
  MOTO_OFFROAD_APPICATION_WITH_PRIVACY_STATEMENT:
    'Motorcycle and Off-Road Application with Privacy Statement.pdf',
  PA_IMPORTANT_NOTICE: 'PA Important Notice.pdf',
  TORT_OPTION: 'Tort Option Form.pdf',
  TORT_OPTIONS_PREM_DISCOUNTS:
    'Important Notice - Tort Options and Notice of Premium Discounts - Pennsylvania.pdf',
  UIM_SELECTION_REJECTION: 'UIM Selection Rejection.pdf',
  UM_SELECTION_REJECTION: 'UM Selection Rejection.pdf',
};

export const PREBIND_DOCUMENT_LABELS: { [id: string]: string } = {
  AUTO_INS_MERIT_RATING_PLAN:
    'Automobile Insurance Merit Rating Plan Information (Surcharge disclosure plan)',
  MIN_PACKAGE_INVOICE: 'Minimum Package Invoice & Fraud Warning Notice',
  MOTO_OFFROAD_INS_MERIT_RATING_PLAN:
    'Motorcyle & Off-Road Insurance Merit Rating Plan Information - Pennsylvania',
  MOTO_OFFROAD_APPICATION_WITH_PRIVACY_STATEMENT:
    'Motorcycle and Off-Road Application with Privacy Statement',
  PA_IMPORTANT_NOTICE:
    'Important notice - First Party Coverage (Available Benefits and Limits)',
  TORT_OPTION: 'Tort Option Notice to insureds',
  TORT_OPTIONS_PREM_DISCOUNTS:
    'Important notice - Notice of Tort Options & Notice of Premium Discounts',
  UIM_SELECTION_REJECTION: 'UIM Selection/Rejection',
  UM_SELECTION_REJECTION: 'UM Selection/Rejection',
};

export const BIND_SUSPENSION_MESSAGE =
  'There is currently a binding suspension in effect. You will not be able to bind this policy at this time.';

export const PERSONAL_AUTO_TRAILER_TYPE: VehicleType = 'Trailer_Ext';

export const MAX_AUTO_UTIL_TRAILER_VALUE = 12000;

export const DRIVER_TYPES: OptionsModel[] = [
  {
    id: 1,
    label: 'Driver',
    value: 'Driver',
  },
  {
    id: 2,
    label: 'Non-driver',
    value: 'NonDriver',
  },
  {
    id: 3,
    label: 'Excluded',
    value: 'Excluded',
  },
];

export const PS_DRIVER_TYPES: OptionsModel[] = [
  {
    id: 1,
    label: 'Driver',
    value: 'Driver',
  },
  {
    id: 2,
    label: 'Excluded',
    value: 'Excluded',
  },
];

export const REASON_NON_DRIVERS: PicklistItem[] = [
  {
    displayName: 'Military',
    value: 'Military',
  },
  {
    displayName: 'Never been licensed',
    value: 'NeverBeenLicensed',
  },
  {
    displayName: 'Not licensed due to medical reasons',
    value: 'NotLicensedDueToMedicalReasons',
  },
  {
    displayName: 'Other',
    value: 'Other',
  },
  {
    displayName: 'Other insurance',
    value: 'OtherInsurance',
  },
  {
    displayName: 'Surrendered license due to medical reasons',
    value: 'SurrendrLcnsDueToMed',
  },
  {
    displayName: 'Voluntary surrender',
    value: 'VoluntarySurrender',
  },
];

export const PS_REASON_NON_DRIVERS: PicklistItem[] = [
  {
    displayName: 'Military',
    value: 'Military',
  },
  {
    displayName: 'Never been licensed',
    value: 'NeverBeenLicensed',
  },
  {
    displayName: 'Not licensed due to medical reasons',
    value: 'NotLicensedDueToMedicalReasons',
  },
  {
    displayName: 'Other',
    value: 'Other',
  },
  {
    displayName: 'Other insurance',
    value: 'OtherInsurance',
  },
  {
    displayName: 'Voluntary surrender',
    value: 'VoluntarySurrender',
  },
];

export const BOAT_TYPE: PicklistItem[] = [
  { value: 'BassBoat', displayName: 'Bass Boat' },
  { value: 'CabinCruiser', displayName: 'Cabin Cruiser' },
  { value: 'Canoe', displayName: 'Canoe' },
  { value: 'Catamaran', displayName: 'Catamaran' },
  { value: 'Fishing', displayName: 'Fishing' },
  { value: 'Houseboat', displayName: 'House Boat' },
  { value: 'Kayak', displayName: 'Kayak' },
  { value: 'Performance', displayName: 'Performance' },
  /*   { value: 'PersonalWatercraft', displayName: 'Personal Watercraft (PWC)' }, */
  { value: 'Pontoon', displayName: 'Pontoon' },
  { value: 'Runabout', displayName: 'Runabout' },
  { value: 'Sailboat', displayName: 'Sailboat' },
  { value: 'SkiSurf', displayName: 'Ski & Surf' },
  { value: 'Utility', displayName: 'Utility' },
];

export const BOAT_ENGINE_TYPE: PicklistItem[] = [
  { value: 'Inboard', displayName: 'Inboard' },
  { value: 'Outboard', displayName: 'Outboard' },
  { value: 'InboardOrOutboard', displayName: 'Inboard/Outboard' },
  { value: 'Jet', displayName: 'Jet' },
  { value: 'NoMotor', displayName: 'No Motor' },
];

export const UMBRELLA_BOAT_ENGINE_TYPE: PicklistItem[] = [
  { value: 'Inboard', displayName: 'Inboard' },
  { value: 'Outboard', displayName: 'Outboard' },
  { value: 'InboardOutboard', displayName: 'Inboard/Outboard' },
  { value: 'Jet', displayName: 'Jet' },
  { value: 'NoEngine', displayName: 'No Engine' },
];

export const BOAT_NUMBER_NUMBER_OF_MOTORS: PicklistItem[] = [
  { value: 'Zero', displayName: '0' },
  { value: 'One', displayName: '1' },
  { value: 'TwoPlus', displayName: '2+' },
];

export const MEMBER_OCCUPATIONS: PicklistItem[] = [
  {
    value: 'Broadcaster',
    displayName:
      'Broadcaster, Radio, TV Personality, Reporter, Editor, Columnist, Publisher',
  },
  {
    value: 'Celebrities',
    displayName: 'Celebrity or Considered as Celebrities (Local/National)',
  },
  { value: 'ElectronicMedia', displayName: 'Electronic Media' },
  {
    value: 'Entertainers',
    displayName:
      'Entertainers, Script Screen Writers, Business Managers and Agents',
  },
  {
    value: 'ForeignDiplomats',
    displayName: 'Foreign Diplomat and Foreign National',
  },
  {
    value: 'FullOrPartPublicLecturer',
    displayName: 'Full or Part-time Public Lecturer',
  },
  { value: 'PoliceAndPatrolOfficer', displayName: 'Law Enforcement Officer' },
  { value: 'Other', displayName: 'Other' },
  {
    value: 'PoliticianAndPoliticalAppointee',
    displayName:
      'Politician, Political Appointee, Lobbyist, Judge, Running for Public Office',
  },
  { value: 'SelfEmployed', displayName: 'Self Employed' },
  {
    value: 'WorkOutOfResidence',
    displayName:
      'Work out of the Residence/Customers Regularly Come Into the Residence',
  },
];

export const PropertyCoverageGroupTabIds: string[] = [
  'liability',
  'additionalProtection',
  'scheduledProperty',
];
