import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { combineLatest, Observable, of } from 'rxjs';
import {
  switchMap,
  catchError,
  take,
  map,
  filter,
  withLatestFrom,
} from 'rxjs/operators';
import { QuoteLetterService } from '@core/services/quote-letter.service';
import { CurrencyPipe } from '@angular/common';
import { DiscountNameHelper } from '@core/helper/discount-name.helper';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { CoverageHelper } from '@core/helper/coverage.helper';
import { QuoteLetterActions, RetrieveActions } from '../../actions';
import {
  ProductsSelectors,
  QuoteLetterSelectors,
  SessionSelectors,
} from '../../selectors';
import { CoreState } from '../../reducers';

@Injectable()
export class QuoteLetterEffects {
  constructor(
    private actions$: Actions,
    private store: Store<CoreState>,
    private quoteLetterService: QuoteLetterService,
    private currencyPipe: CurrencyPipe,
    private errorSanitizerService: ErrorSanitizerService,
    private discountNameHelper: DiscountNameHelper,
    private coverageHelper: CoverageHelper
  ) {}

  loadQuoteLetterPdf$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuoteLetterActions.loadQuoteLetter),
        switchMap(() =>
          combineLatest([
            this.store.select(SessionSelectors.getQuoteState),
            this.store.select(ProductsSelectors.getSelectedProducts),
          ]).pipe(take(1))
        ),
        switchMap(([quoteState, selectedProducts]) =>
          this.store
            .select(
              QuoteLetterSelectors.buildQuoteLetterRequest(
                selectedProducts,
                this.currencyPipe,
                quoteState,
                this.coverageHelper,
                this.discountNameHelper
              )
            )
            .pipe(take(1))
        ),
        switchMap((quoteLetterRequestBody) => {
          if (!quoteLetterRequestBody) {
            return of(QuoteLetterActions.loadQuoteLetterSuccess({
              payload: '',
            }));
          }
          return this.quoteLetterService.documents(quoteLetterRequestBody).pipe(
            switchMap((documentsResponse) =>
              of(
                QuoteLetterActions.loadQuoteLetterSuccess({
                  payload: documentsResponse.fileOutput,
                })
              )
            ),
            catchError((error) => {
              return of(
                QuoteLetterActions.loadQuoteLetterFail({
                  error: this.errorSanitizerService.sanitizeError(error),
                })
              );
            })
          );
        })
      ) as Observable<Action>
  );

  generateQuoteLetterAfterRetrievingRatedQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RetrieveActions.allRetrievesComplete),
      withLatestFrom(
        this.store.select(ProductsSelectors.getStatusOfAllProducts)
      ),
      filter(([action, statuses]) => {
        if (statuses.length < 1) {
          return false;
        }
        for (const status of statuses) {
          if (status !== 'Quoted' && status !== 'Binding') {
            return false;
          }
        }
        return true;
      }),
      map(() => QuoteLetterActions.loadQuoteLetter())
    )
  );
}
