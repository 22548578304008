import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { ProductType } from '@core/models/api/dsm-types';
import { QuoteSearchService } from '@core/services/quote-search.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RetrieveQuoteGuard {
  constructor(
    private router: Router,
    private quoteSearchService: QuoteSearchService,
    private route: ActivatedRoute
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const {
      autoQuoteId,
      powersportsQuoteId,
      rentersQuoteId,
      homeownersQuoteId,
      condoQuoteId,
      umbrellaQuoteId,
    } = route.queryParams;

    const quotesToRetrieve: { quoteId: string; productType: ProductType }[] =
      [];
    if (autoQuoteId) {
      quotesToRetrieve.push({
        quoteId: autoQuoteId,
        productType: 'PersonalAuto',
      });
    }
    if (powersportsQuoteId) {
      quotesToRetrieve.push({
        quoteId: powersportsQuoteId,
        productType: 'MSA',
      });
    }
    if (rentersQuoteId) {
      quotesToRetrieve.push({ quoteId: rentersQuoteId, productType: 'Tenant' });
    }
    if (homeownersQuoteId) {
      quotesToRetrieve.push({
        quoteId: homeownersQuoteId,
        productType: 'Homeowner',
      });
    }
    if (condoQuoteId) {
      quotesToRetrieve.push({
        quoteId: condoQuoteId,
        productType: 'Condominium',
      });
    }
    if (umbrellaQuoteId) {
      quotesToRetrieve.push({
        quoteId: umbrellaQuoteId,
        productType: 'PersonalUmbrella',
      });
    }

    if (quotesToRetrieve.length === 0) {
      this.router.navigate(['/home/search']);
      return of(true);
    }

    this.quoteSearchService.retrieve(...quotesToRetrieve);
    return of(true);
  }
}
