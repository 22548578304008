import { CompraterRoutingResponse } from "@core/adapters/comprater-routing.adapter";
import { createAction, props } from "@ngrx/store";

export const fetchCompraterRouting = createAction(
  '[Comprater Routing] Fetch Comprater Routing',
  props<{ quoteId: string }>()
);

export const fetchCompraterRoutingSuccess = createAction(
  '[Comprater Routing] Fetch Comprater Routing Success',
  props<{ response: CompraterRoutingResponse }>()
);

export const fetchCompraterRoutingError = createAction(
  '[Comprater Routing] Fetch Comprater Routing Error',
  props<{ quoteId: string }>()
);
