import { Action, createReducer, on } from '@ngrx/store';
import { BillingPlansFormModel } from '@app/billing-payment/components/billing-plans-form/billing-plans-form.model';
import { BillingPlansAction } from '../../actions';
import { RetrieveActions, SessionActions } from '@core/store/actions';
import { QuoteRetrieveHomeownerResponse, QuoteRetrieveResponse } from '@core/models/api/response/retrieve-response.model';

export type BillingPlansState = {
  billingPlansForm: BillingPlansFormModel;
  visitedByUser: boolean; // true if the user has seen this form, otherwise we can change it quietly
};

function mergeBillingPlansFormWithRetrieveResponse(
  original: BillingPlansFormModel,
  response?: QuoteRetrieveResponse
): BillingPlansFormModel {

  const bpmValue = response?.response?.eligibleDiscounts?.find(
    d => d.eligibleDiscountId === 'BillingPaymentMethod'
  )?.selectedOptionValue;
  if (!bpmValue) {
    return original;
  }

  // Escrow works a little different than the others.
  if (
    response.response.currentBillTo === 'Mortgagee' &&
    bpmValue === 'ThirdPartyBill'
  ) {
    return {
      ...original,
      billToEscrowAccount: true,
    };
  }

  // Everything else is bpmValue=>billingPlan
  let billingPlan = original.billingPlan;
  switch (bpmValue) {
    case 'FullPay':
      billingPlan = 'PAY IN FULL';
      break;
    case 'DirectBill':
      billingPlan = 'MONTHLY DIRECT';
      break;
    case 'RecurringEFT':
      billingPlan = 'MONTHLY EFT';
      break;
    case 'RecurringBankCard':
      billingPlan = 'MONTHLY RECURRING BANKCARD';
      break;
  }
  return {
    ...original,
    billingPlan,
  };
}

export function initialMonthlyPaymentDay(now: Date): string {
  return now.getDate().toString().padStart(2, '0');
}

const initialState: BillingPlansState = {
  billingPlansForm: {
    billingPlan: 'MONTHLY EFT',
    billToEscrowAccount: false,
    monthlyPaymentDay: initialMonthlyPaymentDay(new Date()),
  },
  visitedByUser: false,
};

const billingPlansReducer = createReducer<BillingPlansState>(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(BillingPlansAction.updateBillingPlansForm, (state, { payload }) => ({
    ...state,
    billingPlansForm: { ...state.billingPlansForm, ...payload },
  })),
  on(BillingPlansAction.billingPlansFormVisited, (state) => ({
    ...state,
    visitedByUser: true,
  })),
  on(BillingPlansAction.resetBillingPlansFormVisited, (state) => ({
    ...state,
    visitedByUser: false,
  })),

  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    return {
      ...state,
      billingPlansForm: mergeBillingPlansFormWithRetrieveResponse(state.billingPlansForm, payload),
    };
  })
);

export function reducer(
  state: BillingPlansState | undefined,
  action: Action
): BillingPlansState {
  return billingPlansReducer(state, action);
}
