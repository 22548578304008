<nwx-additional-interests-form
  [mode]="mode"
  [interest]="interest"
  [members]="members$ | async"
  [displayAddress]="displayAddress$ | async"
  [interestHolderCompanies]="interestHolderCompanies$ | async"
  [productType]="productType"
  (save)="onSave($event)"
  (deleteExisting)="onDelete($event)"
></nwx-additional-interests-form>
