import { loadDownPayment } from './../store/entities/billing/billing.action';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { BillingService } from '../services/billing.service';
import { ProductsService } from '../services/products.service';
import { Store } from '@ngrx/store';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';

@Injectable({
  providedIn: 'root',
})
export class BillingDownPaymentGuard {
  constructor(
    private billingService: BillingService,
    private productsService: ProductsService,
    private store: Store
  ) {}

  canActivate(): Observable<boolean> {
    return this.productsService.getStatusOfAllProducts().pipe(
      map((statuses) => {
        if (!statuses.length || statuses.indexOf('Error') >= 0) {
          return false;
        }
        if (statuses.indexOf('Pending') >= 0) {
          return null;
        }
        return true;
      }),
      filterOutNull(),
      switchMap((result) => {
        if (result) {
          return combineLatest([
            this.billingService.getDownPaymentLoading(),
            this.billingService.getDownPaymentLoaded(),
            this.billingService.getDownPaymentFailed(),
          ]).pipe(
            map(([isLoading, isLoaded, isFailed]) => {
              if (isFailed) {
                return false;
              }
              if (isLoading) {
                return null;
              }
              if (!isLoaded) {
                this.store.dispatch(loadDownPayment());
                return null;
              }
              return true;
            }),
            filterOutNull(),
            take(1)
          );
        } else {
          return of(false);
        }
      })
    );
  }
}
