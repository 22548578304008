import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { BoltNotification, ContentBundle } from '@core/interfaces/interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Action, Store } from '@ngrx/store';

@Component({
  selector: 'nwx-button-modal',
  templateUrl: './button-modal.component.html',
  styleUrls: [
    './button-modal.component.scss',
    '../../../../../scss/_custom-tile.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonModalComponent {
  @Input() headerText: string = '';
  @Input() content: ContentBundle[] = [];
  @Input() primaryButtonText: string = '';
  @Input() secondaryButtonText: string = '';
  @Input() primaryButtonAction = {} as Action | 'closeModal';
  @Input() secondaryButtonAction = {} as Action | 'closeModal';
  @Input() notification: BoltNotification = {} as BoltNotification;

  constructor(
    private store: Store,
    public activeModal: NgbActiveModal,
    public changeDetector: ChangeDetectorRef
  ) {}

  dispatchButtonAction(buttonType: string): void {
    if (buttonType === 'primary' && this.primaryButtonAction) {
      if (this.primaryButtonAction === 'closeModal') {
        this.activeModal.close();
      } else {
        this.store.dispatch(this.primaryButtonAction);
      }
    } else if (buttonType === 'secondary' && this.secondaryButtonAction) {
      if (this.secondaryButtonAction === 'closeModal') {
        this.activeModal.close();
      } else {
        this.store.dispatch(this.secondaryButtonAction);
      }
    }
    this.activeModal.close();
  }
}
