import { Injectable } from '@angular/core';
import { QuoteService } from '@core/services/quote.service';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { Observable } from 'rxjs';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';
import { ProductsService } from '../services/products.service';
import { TaskService } from '../services/task.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentGuard {
  constructor(
    private productsService: ProductsService,
    private quoteService: QuoteService,
    private taskService: TaskService,
    private loadingService: LoadingService
  ) {}

  canActivate(): Observable<boolean> {
    return this.checkStore();
  }

  checkStore(): Observable<boolean> {
    const loadingCallbacks: (() => void)[] = [];
    return this.productsService.isBindCompleted().pipe(
      withLatestFrom(
        this.productsService.getSelectedProductEntitiesWithoutErrors(),
        this.taskService.getIncompleteTasks()
      ),
      map(([bindComplete, products, tasks]) => {
        if (tasks.length) {
          return false;
        }
        if (!bindComplete) {
          products.forEach((product) => {
            this.quoteService.dispatchRateQuote(product.type, 'bind');
            loadingCallbacks.push(
              this.loadingService.beginLoading(
                'rate-bind',
                'Getting the final rate...'
              )
            );
          });
          return null;
        }
        return true;
      }),
      filterOutNull(),
      take(1),
      tap((_) => {
        while (loadingCallbacks.length > 0) {
          loadingCallbacks.pop()?.();
        }
      })
    );
  }
}
