import { PaymentFormModel } from '@app/billing-payment/components/payment-form/payment-form.model';
import {
  DownPaymentExperienceResponse,
  EligiblePayplansAndPayMethods,
} from '@core/models/api/response/down-payment-experience-response.model';
import { SetupAccountExperienceResponse } from '@core/models/api/response/setup-account-experience-response.model';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../error/error.model';

export const loadDownPayment = createAction('[Billing] Load Down Payment');

export const loadDownPaymentSuccess = createAction(
  '[Billing] Load Down Payment Success',
  props<{ payload: DownPaymentExperienceResponse }>()
);

export const loadDownPaymentFail = createAction(
  '[Billing] Load Down Payment Fail',
  props<{ error: ErrorModel }>()
);

export const downPaymentNotNeeded = createAction(
  '[Billing] Down Payment Not Needed'
);

export const loadDownPaymentIfAllProductsRated = createAction(
  '[Billing] Load Down Payment If All Products Rated'
);

export const setPaymentMethod = createAction(
  '[Billing] Set Payment Method',
  props<{ payload?: EligiblePayplansAndPayMethods }>()
);

export const setupAccount = createAction(
  '[Billing] Setup Account',
  props<{ form: PaymentFormModel }>()
);

export const setupAccountSuccess = createAction(
  '[Billing] Setup Account Success',
  props<{ payload: SetupAccountExperienceResponse }>()
);

export const setupAccountFail = createAction(
  '[Billing] Setup Account Fail',
  props<{ error: ErrorModel }>()
);

export const billingComplete = createAction(
  '[Billing] Billing & Payment Complete'
);

export const adjustDownPaymentCallCount = createAction(
  '[Billing] Adjust Down Payment Call Count',
  props<{ d: number }>()
);

export const showIssuePoliciesWarning = createAction(
  '[Billing] Show Issue Policies Warning'
);

export const unsetDownPaymentFailed = createAction(
  '[Billing] Unset Down Payment Failed'
);

export const clearDownPayment = createAction(
  '[Billing] Clear Down Payment'
);
