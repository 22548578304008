import { ProductType } from '@core/models/api/dsm-types';
import { SessionActions } from '@core/store/actions';
import { createEntityAdapter, Dictionary, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  createBillingAccount,
  removeBillingAccountErrors,
  setBillingAccountStatus,
  upsertBillingAccount,
} from './billing-account.action';

export type BillingAccountStatus = 'init' | 'pending' | 'ok' | 'error';

/**
 * If status is 'error', the account was not created and remains only as a record of the error.
 * Those error records are transient, can be cleared from the store explicitly.
 */
export interface BillingAccountEntity {
  id: number;
  status: BillingAccountStatus;
  productIds: ProductType[];
  accountNumber?: string;
  receiptId?: string;
}

export interface BillingAccountState extends EntityState<BillingAccountEntity> {
  nextId: number;
}

export const adapter = createEntityAdapter<BillingAccountEntity>();

export const reducer = createReducer(
  {
    ...adapter.getInitialState(),
    nextId: 1,
  },

  on(SessionActions.clearSessionState, () => ({
    ...adapter.getInitialState(),
    nextId: 1,
  })),

  on(upsertBillingAccount, (state, { payload: entity }) => {
    if (!entity.id) {
      entity = {
        ...entity,
        id: state.nextId,
      };
      state = {
        ...state,
        nextId: state.nextId + 1,
      };
    }
    return adapter.upsertOne(entity, state);
  }),

  on(createBillingAccount, (state, { payload: entity }) => {
    entity = {
      ...entity,
      id: state.nextId,
    };
    state = {
      ...state,
      nextId: state.nextId + 1,
    };
    return adapter.addOne(entity, state);
  }),

  on(
    setBillingAccountStatus,
    (state, { productType: productId, status, accountNumber, receiptId }) => {
      const entity = Object.values(state.entities).find((e) =>
        e?.productIds.includes(productId)
      );
      if (entity) {
        const changes: Partial<BillingAccountEntity> = { status };
        if (accountNumber) {
          changes.accountNumber = accountNumber;
        }
        if (receiptId) {
          changes.receiptId = receiptId;
        }
        return adapter.updateOne(
          {
            id: entity.id,
            changes,
          },
          state
        );
      }
      return state;
    }
  ),

  on(removeBillingAccountErrors, (state) => {
    const validAccountIds = Object.values(state.entities)
      .filter((a) => a?.status !== 'error')
      .map((a) => a?.id) as number[];
    const newEntities: Dictionary<BillingAccountEntity> = {};
    for (const id of validAccountIds) {
      newEntities[id] = state.entities[id];
    }
    return {
      ...state,
      ids: validAccountIds,
      entities: newEntities,
    };
  })
);
