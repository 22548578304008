<div class="modal-header">
  <h4 class="modal-title">Issue policies</h4>
</div>
<div class="modal-body">
  <div class="bolt-container outer-container" *ngIf="wasPaymentAccepted()">
    <div class="bolt-row">
      <div class="bolt-col-auto align-top bolt-justify-items-center">
        <bolt-icon
          name="checkmark-circle"
          color="theme-success"
          title="purchase issue"
        ></bolt-icon>
      </div>
      <div class="bolt-col">
        <div class="bolt-row bolt-space-bottom-lg">
          <div class="bolt-col">
            <div class="label">Payment accepted</div>
            <div>
              {{ getContent() }}
            </div>
          </div>
        </div>
        <div class="bolt-row">
          <div class="bolt-col">
            <div class="pale-gray-card">
              <div *ngFor="let product of products">
                <div id="policyInfo-{{ product.quoteId }}">
                  {{ describeProduct(product) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="billing-purchase-issue-container">
    <nwx-billing-purchase-issue
      [products]="products"
      [errorMessage]="errorMessage"
    ></nwx-billing-purchase-issue>
  </div>
</div>
<div class="modal-footer">
  <bolt-button-bar class="button-bar"></bolt-button-bar>
</div>
