import { TelematicsTextNotificationsModel } from '@app/telematics/components/telematics-text-updates/telematics-text-updates.model';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  TelematicsRecommendationRequest,
  TelematicsRecommendationVehicle,
} from '../models/api/request/telematics-recommendation-request.model';
import {
  Observable,
  combineLatest,
  ReplaySubject,
  of,
  Subject,
  firstValueFrom,
} from 'rxjs';
import { TelematicsRecommendationResponse } from '../models/api/response/telematics-recommendation-response.model';
import { TelematicsEnrollmentRequest } from '../models/api/request/telematics-enrollment-request.model';
import { map, switchMap, take } from 'rxjs/operators';
import { VehicleService } from './vehicle.service';
import { ProductsService } from './products.service';
import { DriverEntity } from '../store/entities/driver/driver.entity';
import { CoreState } from '../store/reducers';
import * as telematicsSelector from '../store/entities/telematics/telematics.selector';
import { isSmartMilesEnrolled } from '../store/entities/telematics/telematics.selector';
import { Nullable } from '@shared/utils/type.utils';
import {
  VehicleEnrollment,
  MobileEnrollment,
  QualifyingInformationVehicle,
  EnrolledVehicle,
  NotEnrolledVehicle,
  ExtendedTelematicsVehicle,
} from '../store/entities/telematics/telematics.model';
import {
  DocumentDeliveryType,
  TelematicsVehicleProgramType,
} from '../models/api/dsm-types';
import { VehicleEntity } from '../store/entities/vehicle/vehicle.entity';
import {
  removeTelematicsEnrollment,
  resetTelematicsEnrollment,
  updateRecommendationForVehicle,
  updateTelematicsConfirmation,
  updateTelematicsEnrollment,
  updateTelematicsReviewed,
  updateTelematicsTextNotification,
} from '../store/entities/telematics/telematics.action';
import { TelematicsUtils } from '@shared/utils/telematics.utils';
import { MetadataService } from './metadata.service';
import { filterOutUndefined } from '@shared/rxjs/filter-out-null.operator';
import { TelematicsEnrollmentResponse } from '../models/api/response/telematics-enrollment-response.model';
import { getBillingPlansForm } from '@forms-store/store/models/billing-plans/billing-plans.selector';
import { QuoteService } from './quote.service';
import { Actions, ofType } from '@ngrx/effects';
import * as fromActions from '@core/store/entities/telematics/telematics.action';
import { SessionService } from './session.service';
import { MemberService } from './member.service';
import { VehicleModel } from '@core/models/views/vehicle.model';
import { TelematicsActions } from '@core/store/actions';
import { TelematicsAdapter } from '@core/adapters/telematics.adapter';

@Injectable({
  providedIn: 'root',
})
export class TelematicsService {
  private telematicsTabActiveSource: Subject<void>;
  telematicsTabActive: Observable<void>;

  constructor(
    private vehicleService: VehicleService,
    private productsService: ProductsService,
    private peopleService: MemberService,
    private metadataService: MetadataService,
    private quoteService: QuoteService,
    private store: Store<CoreState>,
    private actions$: Actions,
    private sessionService: SessionService,
    private adapter: TelematicsAdapter
  ) {
    this.telematicsTabActiveSource = new Subject();
    this.telematicsTabActive = this.telematicsTabActiveSource.asObservable();
    this.actions$
      .pipe(ofType(TelematicsActions.setTelematicsTabActive))
      .subscribe(() => this.setTelematicsTabActive());
  }

  getCurrentRecommendation(): Observable<
    Nullable<TelematicsRecommendationResponse>
  > {
    return this.store.select(telematicsSelector.getCurrentRecommendation);
  }

  getCurrentEnrollment(): Observable<
    Nullable<VehicleEnrollment | MobileEnrollment>
  > {
    return this.store.select(telematicsSelector.getCurrentEnrollment);
  }

  isRecommendationEnrolled(): Observable<boolean> {
    return this.store.select(telematicsSelector.isRecommendationEnrolled);
  }

  isAnyTelematicsEnrolled(): Observable<boolean> {
    return this.store.select(telematicsSelector.isAnyTelematicsEnrolled);
  }

  isSmartMilesEnrolled(): Observable<boolean> {
    return this.store.select(isSmartMilesEnrolled);
  }

  getTelematicsConfirmed(): Observable<boolean> {
    return this.store.select(telematicsSelector.getTelematicsConfirmed);
  }

  getTelematicsReviewed(): Observable<boolean> {
    return this.store.select(telematicsSelector.getTelematicsReviewed);
  }

  dispatchUpdateEnrollment(
    selectedProgram: string,
    vehicleId: string,
    dataCollectionMethod?: 'Device' | 'ConnectedCar'
  ): void {
    this.store.dispatch(
      updateTelematicsEnrollment({
        payload: { selectedProgram, vehicleId, dataCollectionMethod },
      })
    );
  }

  dispatchRemoveEnrollment(): void {
    this.store.dispatch(removeTelematicsEnrollment());
  }

  dispatchResetEnrollment(): void {
    this.store.dispatch(resetTelematicsEnrollment());
  }

  /**
   * Mimicks the behavior of TelematicsEffects.resetTelematicsEnrollment$
   * but returns a Promise that resolves or rejects sensibly.
   */
  resetEnrollmentAsPromise(): Promise<void> {
    return new Promise((resolve, reject) => {
      combineLatest([
        this.productsService.getProduct('PersonalAuto'),
        this.getCurrentEnrollment(),
        this.getCurrentRecommendation(),
      ])
        .pipe(take(1))
        .subscribe(([product, enrollment, recommendation]) => {
          if (!product?.quoteId) {
            return reject('Auto not initialized');
          }
          if (enrollment?.enrollmentId) {
            this.deleteEnrollmentAsPromise(
              product.quoteId,
              enrollment.enrollmentId
            ).then(() => {
              resolve(recommendation);
            });
          } else {
            resolve(recommendation);
          }
        });
    }).then(
      (recommendation) =>
        new Promise((resolve, reject) => {
          this.actions$
            .pipe(
              ofType(
                fromActions.addTelematicsEnrollmentSuccess,
                fromActions.addTelematicsEnrollmentFail
              ),
              take(1)
            )
            .subscribe((action) => {
              if (
                action.type === fromActions.addTelematicsEnrollmentSuccess.type
              ) {
                resolve();
              } else {
                reject(action.payload);
              }
            });
          this.store.dispatch(
            fromActions.addTelematicsEnrollment({
              payload: recommendation as TelematicsRecommendationResponse,
            })
          );
        })
    );
  }

  deleteEnrollmentAsPromise(
    quoteId: string,
    enrollmentId: string
  ): Promise<unknown> {
    return firstValueFrom(
      this.adapter.deleteTelematicsEnrollment(quoteId, enrollmentId)
    );
  }

  dispatchUpdateTelematicsTextNotification(
    payload: TelematicsTextNotificationsModel
  ): void {
    this.store.dispatch(updateTelematicsTextNotification({ payload }));
  }

  dispatchUpdateTelematicsConfirmation(payload: boolean): void {
    this.store.dispatch(updateTelematicsConfirmation({ payload }));
  }

  dispatchUpdateTelematicsReviewed(payload: boolean): void {
    this.store.dispatch(updateTelematicsReviewed({ payload }));
  }

  dispatchUpdateRecommendationForVehicle(payload: VehicleEntity): void {
    this.store.dispatch(updateRecommendationForVehicle({ vehicle: payload }));
  }

  isSmartMilesEligibleState(): Observable<boolean> {
    return this.metadataService
      .getStateSpecificFlag('smartMiles')
      .pipe(filterOutUndefined());
  }

  isDeviceOnlyState(): Observable<boolean> {
    return this.metadataService
      .getStateSpecificFlag('isDeviceOnlyState')
      .pipe(filterOutUndefined());
  }

  isSelfReportedEligibleState(): Observable<boolean> {
    return this.metadataService
      .getStateSpecificFlag('selfReportedProgram')
      .pipe(filterOutUndefined());
  }

  getTelematicsLoading(): Observable<boolean> {
    return this.store.select(telematicsSelector.getTelematicsLoading);
  }

  buildProgramTileVehicles(): Observable<ExtendedTelematicsVehicle[]> {
    return this.store.select(telematicsSelector.buildProgramTileVehicles);
  }

  /**
   * Emits one of 'NONE', 'OK', or 'ERROR'.
   */
  dropSmartMilesIfBillingPlanIncompatible(
    billingPlan: string
  ): Observable<string> {
    if (
      billingPlan === 'MONTHLY RECURRING BANKCARD' ||
      billingPlan === 'MONTHLY EFT'
    ) {
      return of('NONE');
    }
    return this.isSmartMilesEnrolled().pipe(
      take(1),
      switchMap((enrolled) => {
        if (!enrolled) {
          return of('NONE');
        }
        this.dispatchRemoveEnrollment();
        return this.actions$.pipe(
          ofType(
            // sic "update" and "remove", see telematics.effect.ts:removeTelematicsEnrollment$
            fromActions.updateTelematicsEnrollmentSuccess,
            fromActions.removeTelematicsEnrollmentFail
          ),
          take(1),
          map((action) => {
            if (
              action.type === fromActions.updateTelematicsEnrollmentSuccess.type
            ) {
              return 'OK';
            } else {
              return 'ERROR';
            }
          })
        );
      })
    );
  }

  // re-rate a quote, possibly removing telematics due to ineligible billing plan.
  // to coordinate spinner for multiple loading actions, return the event/message for each
  // loading event as they begin. when all events have completed the obs completes.
  rerateUnenrollingTelematicsIfBillingIncompatible(
    billingPlan: string
  ): Observable<[string, string]> {
    const loadingMessages = new ReplaySubject<[string, string]>();
    this.isSmartMilesEnrolled()
      .pipe(take(1))
      .subscribe((enrolled) => {
        if (
          enrolled &&
          !['MONTHLY EFT', 'MONTHLY RECURRING BANKCARD'].includes(billingPlan)
        ) {
          loadingMessages.next(['telematics', 'Removing telematics...']);
          this.quoteService.waitForTelematicsRefresh().subscribe({
            next: () => {
              this.rerateQuote(loadingMessages);
            },
          });
          this.dispatchRemoveEnrollment();
        } else {
          this.rerateQuote(loadingMessages);
        }
      });
    return loadingMessages;
  }

  private rerateQuote(loadingMessages: ReplaySubject<[string, string]>): void {
    loadingMessages.next([
      'ratequote',
      'Please wait while we recalculate your quote...',
    ]);
    this.quoteService.onQuoteCallsCompleted(() => {
      loadingMessages.complete();
    });
    this.quoteService.rateSelectedProducts('quote');
  }

  composeTelematicsRecommendationRequest(
    changedVehicle?: VehicleEntity
  ): Observable<TelematicsRecommendationRequest> {
    return combineLatest([
      this.vehicleService.getSavedVehiclesByProductType('PersonalAuto'),
      this.productsService.getProduct('PersonalAuto'),
    ]).pipe(
      map(([vehicles, product]) => {
        if (changedVehicle) {
          vehicles = [changedVehicle];
        }
        const request = {
          quoteId: product?.quoteId,
          qualifyingInformation: {
            vehicles: vehicles.map((v) => {
              return {
                vehicleId: v.vehicleId?.toString() || '',
                estimatedAnnualMileage: +(v.annualMiles || 0),
              } as TelematicsRecommendationVehicle;
            }),
          },
        } as TelematicsRecommendationRequest;
        return request;
      })
    );
  }
  // this basically builds the first request to enroll the programs based on the
  // recommendation and this is where you do the first check to see like do they have gone paperless
  composeAddTelematicsRequest(
    recommendation: Nullable<TelematicsRecommendationResponse>
  ): Observable<TelematicsEnrollmentRequest> {
    return combineLatest([
      this.productsService.getProduct('PersonalAuto'),
      this.peopleService.getPrimaryNamedInsuredForProduct('PersonalAuto'),
      this.store.select(getBillingPlansForm),
      this.isSmartRideEarlyReward(),
    ]).pipe(
      map(([product, pni, billingPlansForm, isSmartRideEarlyReward]) => {
        let enrollmentProgram: Nullable<MobileEnrollment | VehicleEnrollment>;
        const defaultMobile = this.shouldDefaultMobile(
          recommendation,
          billingPlansForm?.billingPlan,
          product?.docDelPreference
        );
        let recommendedProgram = recommendation?.recommendedProgram;
        const textConsent = pni?.homeNumber ? true : false;
        if (
          defaultMobile ||
          recommendedProgram === 'Mobile App Program' ||
          recommendedProgram === 'MobileAppEarlyDiscount'
        ) {
          recommendedProgram = 'Mobile App Program';
          enrollmentProgram = {
            mobileEnrollment: {
              enrollmentStatus: 'Enrolled',
              mobileEnrollmentType: isSmartRideEarlyReward
                ? 'MobileAppEarlyDiscount'
                : 'MobileApp',
            },
            enrollmentConsents: {
              phoneNumber: pni?.homeNumber || undefined,
              hasConsentToOneTimeTextActivation: textConsent,
              hasConsentToOngoingTexts: false,
            },
          };
        } else if (
          recommendedProgram === 'Vehicle Program' &&
          recommendation?.qualifyingInformation?.vehicles
        ) {
          enrollmentProgram = {
            vehicles: recommendation.qualifyingInformation?.vehicles.map(
              (vehicle) =>
                this.buildEnrollmentVehicle(
                  vehicle,
                  billingPlansForm?.billingPlan,
                  product?.docDelPreference
                )
            ),
            enrollmentConsents: {
              phoneNumber: pni?.homeNumber || undefined,
              hasConsentToOneTimeTextActivation: textConsent,
              hasConsentToOngoingTexts: false,
            },
          };
        }
        const enrollment = {
          vehicleEnrollment:
            recommendedProgram === 'Vehicle Program' ? enrollmentProgram : null,
          mobileEnrollment:
            recommendedProgram === 'Mobile App Program'
              ? enrollmentProgram
              : null,
          enrollmentConsents: enrollmentProgram?.enrollmentConsents,
        };
        const request = {
          quoteId: product?.quoteId,
          enrollment,
        } as TelematicsEnrollmentRequest;
        return request;
      })
    );
  }

  composeUpdateTelematicsRequest({
    selectedProgram,
    vehicleId,
    dataCollectionMethod,
  }: {
    selectedProgram: string;
    vehicleId: string;
    dataCollectionMethod?: 'Device' | 'ConnectedCar';
  }): Observable<TelematicsEnrollmentRequest | null> {
    return combineLatest([
      this.getCurrentEnrollment(),
      this.getCurrentRecommendation(),
      this.vehicleService.getVehicleById(vehicleId),
      this.vehicleService.getAutoTypeVehicles(),
      this.productsService.getProduct('PersonalAuto'),
      this.isSmartRideEarlyReward(),
      this.isDeviceOnlyState(),
    ]).pipe(
      take(1),
      map(
        ([
          currentEnrollment,
          recommendation,
          selectedVehicle,
          vehicles,
          product,
          isSmartRideEarlyReward,
          isDeviceOnlyState,
        ]) => {
          if (!currentEnrollment?.enrollmentId) {
            return null;
          }
          if (selectedVehicle?.vehicleType !== 'auto') {
            return null;
          }
          const updatedEnrollment: {
            mobileEnrollment?: MobileEnrollment;
            vehicleEnrollment?: VehicleEnrollment;
            enrollmentConsents?: any;
          } = {
            enrollmentConsents: currentEnrollment.enrollmentConsents,
          };
          if (!dataCollectionMethod && selectedProgram === 'SmartMiles') {
            dataCollectionMethod = this.determineExistingDataCollectionMethod(
              vehicleId,
              currentEnrollment
            );
          }
          if (TelematicsUtils.isMobileEnrollment(currentEnrollment)) {
            if (
              selectedVehicle &&
              (selectedProgram === 'SmartMiles' ||
                selectedProgram === 'SmartRideInstant' ||
                selectedProgram === 'ConnectedCar')
            ) {
              const remainingVehicles: (
                | EnrolledVehicle
                | NotEnrolledVehicle
              )[] = vehicles
                .filter((v) => v.vehicleId?.toString() !== vehicleId.toString())
                .map((v) =>
                  this.makeEnrolledVehicleForMobileToDeviceTransition(
                    v,
                    recommendation
                  )
                );
              const enrollmentStatus =
                selectedProgram === 'SmartRideInstant'
                  ? 'VerifiedScore'
                  : 'Enrolled';
              updatedEnrollment.vehicleEnrollment = {
                enrollmentId: currentEnrollment?.enrollmentId,
                vehicles: [
                  {
                    vehicleId,
                    enrollmentStatus: enrollmentStatus,
                    vehicleProgram: selectedProgram,
                    annualMileage: +(selectedVehicle.annualMiles || '0'),
                    dataCollectionMethod: dataCollectionMethod,
                  },
                  ...remainingVehicles,
                ],
              };
            } else {
              updatedEnrollment.mobileEnrollment = {
                enrollmentId: currentEnrollment?.enrollmentId,
                mobileEnrollment: {
                  enrollmentStatus: 'Enrolled',
                  mobileEnrollmentType: isSmartRideEarlyReward
                    ? 'MobileAppEarlyDiscount'
                    : 'MobileApp',
                },
              };
            }
          } else if (TelematicsUtils.isVehicleEnrollment(currentEnrollment)) {
            const currentVehicleEnrollment =
              currentEnrollment as VehicleEnrollment;
            const remainingVehicles = currentVehicleEnrollment.vehicles.filter(
              (v) => {
                const vId = v.vehicleId?.toString();
                return (
                  vId !== vehicleId?.toString() &&
                  vehicles.find((vv) => vv.vehicleId?.toString() === vId)
                );
              }
            );
            if (selectedProgram === 'NotEnrolled') {
              updatedEnrollment.vehicleEnrollment = {
                enrollmentId: currentEnrollment?.enrollmentId,
                vehicles: [
                  {
                    vehicleId,
                    enrollmentStatus: 'NotEnrolled',
                  },
                  ...remainingVehicles,
                ],
              };
            } else if (
              selectedProgram === 'SmartMiles' ||
              selectedProgram === 'SmartRideInstant' ||
              selectedProgram === 'ConnectedCar'
            ) {
              const enrollmentStatus =
                selectedProgram === 'SmartRideInstant'
                  ? 'VerifiedScore'
                  : 'Enrolled';
              updatedEnrollment.vehicleEnrollment = {
                enrollmentId: currentEnrollment?.enrollmentId,
                vehicles: [
                  {
                    vehicleId,
                    enrollmentStatus: enrollmentStatus,
                    vehicleProgram: selectedProgram,
                    annualMileage: +(selectedVehicle?.annualMiles || '0'),
                    dataCollectionMethod: dataCollectionMethod,
                  },
                  ...remainingVehicles,
                ],
              };
            } else if (
              isDeviceOnlyState ||
              remainingVehicles.some(
                (v) =>
                  v.enrollmentStatus === 'VerifiedScore' ||
                  (v.enrollmentStatus === 'Enrolled' &&
                    (v.vehicleProgram === 'SmartMiles' ||
                      v.vehicleProgram === 'ConnectedCar'))
              )
            ) {
              updatedEnrollment.vehicleEnrollment = {
                enrollmentId: currentEnrollment?.enrollmentId,
                vehicles: [
                  {
                    vehicleId,
                    enrollmentStatus: 'Enrolled',
                    vehicleProgram:
                      selectedProgram as TelematicsVehicleProgramType,
                    annualMileage: +(selectedVehicle?.annualMiles || '0'),
                    dataCollectionMethod: dataCollectionMethod,
                  },
                  ...remainingVehicles,
                ],
              };
            } else {
              updatedEnrollment.mobileEnrollment = {
                enrollmentId: currentEnrollment?.enrollmentId,
                mobileEnrollment: {
                  enrollmentStatus: 'Enrolled',
                  mobileEnrollmentType: isSmartRideEarlyReward
                    ? 'MobileAppEarlyDiscount'
                    : 'MobileApp',
                },
              };
            }
          }
          if (updatedEnrollment.vehicleEnrollment) {
            updatedEnrollment.vehicleEnrollment =
              TelematicsUtils.filterOutIdlessVehiclesAndTrimNotEnrolled(
                updatedEnrollment.vehicleEnrollment
              );
          }
          const request = {
            quoteId: product?.quoteId,
            enrollment: updatedEnrollment,
          } as TelematicsEnrollmentRequest;
          return request;
        }
      )
    );
  }

  private makeEnrolledVehicleForMobileToDeviceTransition(
    vehicle: VehicleEntity,
    recommendation: Nullable<TelematicsRecommendationResponse>
  ): EnrolledVehicle | NotEnrolledVehicle {
    // sic '==' not '===', there is string/number ambiguity around these fields:
    const recommendationVehicle =
      recommendation?.qualifyingInformation?.vehicles?.find(
        (v) => v.vehicleId == vehicle.vehicleId
      );
    if (!recommendationVehicle) {
      // Not sure what to do in this case, so I've kept the original behavior. -sommea1
      return {
        vehicleId: vehicle.vehicleId || '',
        enrollmentStatus: 'Enrolled',
        vehicleProgram: 'SmartRide',
        annualMileage: +(vehicle.annualMiles || '0'),
      };
    }
    if (recommendationVehicle.availablePrograms?.length) {
      // SmartRide or SmartMiles, whatever's first in the recommendation.
      return {
        vehicleId: vehicle.vehicleId || '',
        enrollmentStatus: 'Enrolled',
        vehicleProgram: recommendationVehicle.availablePrograms[0]
          .name as TelematicsVehicleProgramType,
        annualMileage: +(vehicle.annualMiles || '0'),
      };
    } else {
      // Nothing was recommended. Must have been a mobile-only vehicle, which should now be NotEnrolled.
      return {
        vehicleId: vehicle.vehicleId || '',
        enrollmentStatus: 'NotEnrolled',
      };
    }
  }

  /**
   * Returns a request you can use for updateTelematicsEnrollment, if previous and current
   * match but current is missing any vehicleProgramType.
   * Addresses a DSM bug, see CODEMINERS-4082.
   */
  composeUpdateTelematicsRequestIfProgramTypeDropped(
    previous: Nullable<VehicleEnrollment | MobileEnrollment>,
    current: TelematicsEnrollmentResponse,
    quoteId: string
  ): Nullable<TelematicsEnrollmentRequest> {
    if (
      current.vehicleEnrollment?.vehicles?.find(
        (v) => v.enrollmentStatus === 'Enrolled' && !v.vehicleProgram
      )
    ) {
      return {
        quoteId,
        enrollment: {
          ...current,
          vehicleEnrollment: {
            ...current.vehicleEnrollment,
            enrollmentId: current.enrollmentId, // i don't get it...
            vehicles: this.vehiclesWithSubstitutedProgramType(
              current.vehicleEnrollment.vehicles,
              (previous as VehicleEnrollment).vehicles
            ),
          },
          mobileEnrollment: null,
          enrollmentConsents: current.enrollmentConsents ?? {},
        },
      };
    }
    return null;
  }

  private vehiclesWithSubstitutedProgramType(
    vehicles: Nullable<(EnrolledVehicle | NotEnrolledVehicle)[]>,
    previousVehicles: Nullable<(EnrolledVehicle | NotEnrolledVehicle)[]>
  ): (EnrolledVehicle | NotEnrolledVehicle)[] {
    const output: (EnrolledVehicle | NotEnrolledVehicle)[] = [];
    if (vehicles && previousVehicles) {
      for (const vehicle of vehicles) {
        if (vehicle.enrollmentStatus === 'NotEnrolled') {
          output.push(vehicle);
        } else if (vehicle.vehicleProgram) {
          output.push(vehicle);
        } else {
          const previous = previousVehicles.find(
            (v) => v.vehicleId === vehicle.vehicleId
          );
          if ((previous as EnrolledVehicle)?.vehicleProgram) {
            output.push({
              ...vehicle,
              vehicleProgram: (previous as EnrolledVehicle).vehicleProgram,
            });
          } else {
            output.push(vehicle);
          }
        }
      }
    }
    return output;
  }

  composeUpdateTelematicsTextNotificationRequest(
    textNotification: TelematicsTextNotificationsModel
  ): Observable<TelematicsEnrollmentRequest> {
    return combineLatest([
      this.getCurrentEnrollment(),
      this.productsService.getProduct('PersonalAuto'),
      this.peopleService.getPrimaryNamedInsuredForProduct('PersonalAuto'),
    ]).pipe(
      map(([enrollment, autoProduct, pni]) => {
        const updatedEnrollment: {
          mobileEnrollment?: MobileEnrollment;
          vehicleEnrollment?: VehicleEnrollment;
          enrollmentConsents: {
            phoneNumber?: string;
            hasConsentToOneTimeTextActivation?: boolean;
            hasConsentToOngoingTexts?: boolean;
          };
        } = {
          enrollmentConsents: {
            phoneNumber:
              (textNotification.phoneNumber || pni?.homeNumber) ?? undefined,
            hasConsentToOngoingTexts: textNotification.textNotification,
            hasConsentToOneTimeTextActivation: !!(
              textNotification.phoneNumber || pni?.homeNumber
            ),
          },
        };
        if (enrollment && 'vehicles' in enrollment) {
          updatedEnrollment.vehicleEnrollment = {
            enrollmentId: enrollment?.enrollmentId,
            vehicles: [...enrollment.vehicles],
          };
        } else if (enrollment) {
          updatedEnrollment.mobileEnrollment = {
            enrollmentId: enrollment.enrollmentId,
            mobileEnrollment: {
              enrollmentStatus: enrollment?.mobileEnrollment.enrollmentStatus,
              mobileEnrollmentType:
                enrollment?.mobileEnrollment.mobileEnrollmentType,
            },
          };
        }
        const request = {
          quoteId: autoProduct?.quoteId,
          enrollment: updatedEnrollment,
        } as TelematicsEnrollmentRequest;
        return request;
      })
    );
  }

  composeRemoveVehicleFromTelematicsRequest(
    removeVehicleId: number
  ): Observable<TelematicsEnrollmentRequest> {
    return combineLatest([
      this.getCurrentEnrollment(),
      this.productsService.getProduct('PersonalAuto'),
    ]).pipe(
      map(([enrollment, autoProduct]) => {
        let vehicleEnrollment: Nullable<VehicleEnrollment> = null;
        let mobileEnrollment: Nullable<MobileEnrollment> = null;

        if (TelematicsUtils.isMobileEnrollment(enrollment)) {
          mobileEnrollment = enrollment as MobileEnrollment;
        } else if (TelematicsUtils.isVehicleEnrollment(enrollment)) {
          vehicleEnrollment = { ...enrollment } as VehicleEnrollment;
          vehicleEnrollment.vehicles = vehicleEnrollment.vehicles?.filter(
            (v) => +v.vehicleId !== +removeVehicleId
          );
        }

        const request = {
          quoteId: autoProduct?.quoteId as string,
          enrollment: {
            vehicleEnrollment,
            mobileEnrollment,
            enrollmentConsents: enrollment?.enrollmentConsents ?? {},
          },
        };
        return request;
      }),
      take(1)
    );
  }

  composeRemoveTelematicsRequest(): Observable<TelematicsEnrollmentRequest> {
    return combineLatest([
      this.getCurrentEnrollment(),
      this.vehicleService.getAutoTypeVehicles(),
      this.productsService.getProduct('PersonalAuto'),
    ]).pipe(
      map(([currentEnrollment, vehicles, product]) => {
        const unenrolled: NotEnrolledVehicle[] = vehicles.map((v) => {
          return {
            vehicleId: v.vehicleId || '',
            enrollmentStatus: 'NotEnrolled',
          };
        });
        const updatedEnrollment = {
          vehicleEnrollment: {
            enrollmentId: currentEnrollment?.enrollmentId,
            vehicles: unenrolled,
          },
        };
        const request = {
          quoteId: product?.quoteId,
          enrollment: updatedEnrollment,
        } as TelematicsEnrollmentRequest;
        return request;
      }),
      take(1)
    );
  }

  private shouldDefaultMobile(
    recommendation: Nullable<TelematicsRecommendationResponse>,
    billingPlan: string,
    docDelPreference: Nullable<DocumentDeliveryType>
  ): boolean {
    // If we have a non-recurring payment method, and every vehicle wants to enroll SmartRide, use mobile.
    const isRecurring =
      billingPlan === 'MONTHLY RECURRING BANKCARD' ||
      billingPlan === 'MONTHLY EFT';
    const isPaperless = docDelPreference === 'OnlineAccountAccess';
    if ((!isRecurring || !isPaperless) && recommendation) {
      const vehicleRecommendations =
        recommendation.qualifyingInformation?.vehicles || [];
      const vehiclePrograms = vehicleRecommendations.map((vr) =>
        this.selectProgramForVehicle(vr, billingPlan, docDelPreference)
      );
      const allSmartRide = vehiclePrograms.every((vp) => vp === 'SmartRide');
      const allDeviceProgram = vehicleRecommendations.every(
        (vr) => vr.deviceCompatible
      );
      if (allSmartRide && !allDeviceProgram) {
        return true;
      }
    }

    return recommendation?.recommendedProgram !== 'Vehicle Program';
  }

  private buildEnrollmentVehicle(
    vehicle: QualifyingInformationVehicle,
    billingPlan: string,
    docDelPreference: Nullable<DocumentDeliveryType>
  ): EnrolledVehicle | NotEnrolledVehicle {
    let enrollmentVehicle: EnrolledVehicle | NotEnrolledVehicle;
    const recommendedProgram = this.selectProgramForVehicle(
      vehicle,
      billingPlan,
      docDelPreference
    );
    let dataCollectionMethod = vehicle.availablePrograms?.find(
      (p) => p.recommended && p.dataCollectionMethod
    )?.dataCollectionMethod;
    if (!dataCollectionMethod && recommendedProgram === 'SmartMiles') {
      dataCollectionMethod = 'Device';
    }
    if (recommendedProgram) {
      enrollmentVehicle = {
        vehicleId: vehicle.vehicleId,
        enrollmentStatus:
          recommendedProgram === 'SmartRideInstant'
            ? 'VerifiedScore'
            : 'Enrolled',
        vehicleProgram: recommendedProgram as TelematicsVehicleProgramType,
        annualMileage: vehicle.estimatedAnnualMileage,
      };
      if (
        dataCollectionMethod === 'Device' ||
        dataCollectionMethod === 'ConnectedCar'
      ) {
        enrollmentVehicle.dataCollectionMethod = dataCollectionMethod;
      }
    } else {
      enrollmentVehicle = {
        vehicleId: vehicle.vehicleId,
        enrollmentStatus: 'NotEnrolled',
      };
    }
    return enrollmentVehicle;
  }

  private selectProgramForVehicle(
    vehicle: QualifyingInformationVehicle,
    billingPlan: string,
    docDelPreference: Nullable<DocumentDeliveryType>
  ): string {
    if (!vehicle.availablePrograms) {
      return '';
    }
    const isPaperless = docDelPreference === 'OnlineAccountAccess';
    const permitSmartMiles =
      (billingPlan === 'MONTHLY RECURRING BANKCARD' ||
        billingPlan === 'MONTHLY EFT') &&
      isPaperless;
    const availablePrograms = permitSmartMiles
      ? vehicle.availablePrograms
      : vehicle.availablePrograms.filter((p) => p.name !== 'SmartMiles');
    let program = availablePrograms.find((p) => p.recommended);
    if (!program) {
      program = availablePrograms[0];
    }
    return program?.name || '';
  }

  private determineExistingDataCollectionMethod(
    vehicleId: string,
    currentEnrollment: Nullable<VehicleEnrollment | MobileEnrollment>
  ): 'Device' | 'ConnectedCar' | undefined {
    if (TelematicsUtils.isVehicleEnrollment(currentEnrollment)) {
      const enrollmentVehicle = (
        currentEnrollment as VehicleEnrollment
      ).vehicles.find((v) => v.vehicleId?.toString() === vehicleId);
      if (enrollmentVehicle?.enrollmentStatus === 'Enrolled') {
        if (enrollmentVehicle.dataCollectionMethod) {
          return enrollmentVehicle.dataCollectionMethod;
        }
      }
    }
    return;
  }

  setTelematicsTabActive(): void {
    this.telematicsTabActiveSource.next();
  }

  getTelematicsTabActive(): Observable<void> {
    return this.telematicsTabActive;
  }

  isSmartRideEarlyReward(): Observable<boolean> {
    return combineLatest([
      this.productsService.getProductEffectiveDate('PersonalAuto'),
      this.sessionService.getQuoteState(),
    ]).pipe(
      map(([quoteDate, state]) =>
        TelematicsUtils.isSmartRideEarlyReward(state, quoteDate || '')
      )
    );
  }

  shouldProposeReenrollExcluded(): Observable<boolean> {
    return combineLatest([
      this.getCurrentRecommendation(),
      this.getCurrentEnrollment(),
    ]).pipe(
      map(([recommendation, enrollment]) => !recommendation && !enrollment)
    );
  }

  clearExcludedQuotes(): void {
    this.store.dispatch(fromActions.clearTelematicsExcludedQuotes());
  }

  callGetTelematicsEnrollment(): void {
    this.store.dispatch(fromActions.getTelematicsEnrollment());
  }

  sanitizeTelematicsRequest(
    request: TelematicsEnrollmentRequest,
    storeVehicles?: VehicleEntity[]
  ): TelematicsEnrollmentRequest {
    const filteredEnrollmentVehicles =
      this.removeTrailersFromEnrollmentVehicles(
        request.enrollment.vehicleEnrollment?.vehicles || [],
        storeVehicles || []
      );

    // Default dataCollectionMethod to "Device" if unset.
    if (request.enrollment.vehicleEnrollment) {
      request = {
        ...request,
        enrollment: {
          ...request.enrollment,
          vehicleEnrollment: {
            ...request.enrollment.vehicleEnrollment,
            vehicles: filteredEnrollmentVehicles.map((vehicle) => {
              if (
                vehicle.enrollmentStatus === 'Enrolled' ||
                vehicle.enrollmentStatus === 'VerifiedScore'
              ) {
                if (
                  !vehicle.dataCollectionMethod &&
                  vehicle.vehicleProgram === 'SmartMiles'
                ) {
                  return {
                    ...vehicle,
                    dataCollectionMethod: 'Device',
                  };
                }
              }
              return vehicle;
            }),
          },
        },
      };
    }
    return request;
  }

  removeTrailersFromEnrollmentVehicles(
    enrollmentVehicles: (EnrolledVehicle | NotEnrolledVehicle)[],
    storeVehicles: VehicleEntity[]
  ): (EnrolledVehicle | NotEnrolledVehicle)[] {
    if (storeVehicles.length) {
      return enrollmentVehicles.filter(
        (v) =>
          storeVehicles.find(
            (vehicle) => vehicle.vehicleId.toString() === v.vehicleId.toString()
          )?.vehicleType === 'auto'
      );
    } else {
      return enrollmentVehicles;
    }
  }
}
