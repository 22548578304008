import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { AlhReportDetails } from '@core/models/api/response/uw-reports-response.model';

@Component({
  selector: 'nwx-alh-report-details',
  templateUrl: './alh-report-details.component.html',
  styleUrls: ['./alh-report-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlhReportDetailsComponent implements OnInit {
  @Input() reportDetails!: AlhReportDetails[];

  constructor() {}

  ngOnInit(): void {}
}
