import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'nwx-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinnerComponent {
  @Input() loading!: boolean;
  @Input() message!: string;

  constructor() {}
}
