import { ProductModel } from '@entities/product/product.model';
import {
  BaseEntity,
  BaseEntityOptionalFields,
  BaseEntitySanitizerFields,
} from '../../../models/entities/base.entity';
import { LocationExposureEntity } from '@entities/exposures/location-exposures/location-exposures.entity';
import { ObjectValidator } from '@core/helper/object-validator';

export interface UnderlyingPoliciesAndProductInfo {
  underlyingPolicy: UnderlyingPolicyEntity;
  product: ProductModel;
}
export interface UnderlyingPolicyEntity extends BaseEntity {
  underlyingPolicyId: string;
  policyType: string;
  isNationwidePolicy: boolean;
  currentCarrierName: string;
  currentCarrierLabel?: string;
  underlyingPolicyNumber: string;
  effectiveDate: string;
  expirationDate?: string;
  limitInformation: LimitInformation;
}

export function sanitizeUnderlyingPolicyEntity(
  input: unknown
): UnderlyingPolicyEntity {
  return ObjectValidator.forceSchema<UnderlyingPolicyEntity>(
    input,
    {
      underlyingPolicyId: 'string',
      policyType: 'string',
      isNationwidePolicy: 'string',
      currentCarrierName: 'string',
      currentCarrierLabel: 'string',
      underlyingPolicyNumber: 'string',
      effectiveDate: 'string',
      expirationDate: 'string',
      limitInformation: sanitizeLimitInformation,
      ...BaseEntitySanitizerFields,
    },
    ['currentCarrierLabel', 'expirationDate', ...BaseEntityOptionalFields]
  );
}

export interface LimitInformation {
  isBodilyInjurySufficient?: boolean;
  isPropertyDamageSufficient?: boolean;
  isAutoCombinedSingleLimit?: boolean;
  isUninsuredUnderinsuredMotoristSufficient?: boolean;
  isPersonalLiabilitySufficient?: boolean;
  hasPersonalInjuryCoverageEndorsement?: boolean;
  isWatercraftLiabilitySufficient?: boolean;
}

export function sanitizeLimitInformation(input: unknown): LimitInformation {
  return ObjectValidator.forceSchema<LimitInformation>(
    input,
    {
      isBodilyInjurySufficient: 'boolean',
      isPropertyDamageSufficient: 'boolean',
      isAutoCombinedSingleLimit: 'boolean',
      isUninsuredUnderinsuredMotoristSufficient: 'boolean',
      isPersonalLiabilitySufficient: 'boolean',
      hasPersonalInjuryCoverageEndorsement: 'boolean',
      isWatercraftLiabilitySufficient: 'boolean',
    },
    [
      'isBodilyInjurySufficient',
      'isPropertyDamageSufficient',
      'isAutoCombinedSingleLimit',
      'isUninsuredUnderinsuredMotoristSufficient',
      'isPersonalLiabilitySufficient',
      'hasPersonalInjuryCoverageEndorsement',
      'isWatercraftLiabilitySufficient',
    ]
  );
}

export interface ExposureTypes {
  locationExposures?: LocationExposureEntity;
}

export interface CandidateUnderlyingPolicy {
  lineOfBusiness: string;
  primaryNamedInsured: string;
  policyNumber: string;
  status: string;
  carrier: string;
  effectiveDate: string;
}

export function sanitizeCandidateUnderlyingPolicies(
  input: unknown
): CandidateUnderlyingPolicy[] {
  return ObjectValidator.sanitizeArray(
    input,
    sanitizeCandidateUnderlyingPolicy
  );
}

export function sanitizeCandidateUnderlyingPolicy(
  input: unknown
): CandidateUnderlyingPolicy {
  return ObjectValidator.forceSchema<CandidateUnderlyingPolicy>(input, {
    lineOfBusiness: 'string',
    primaryNamedInsured: 'string',
    policyNumber: 'string',
    status: 'string',
    carrier: 'string',
    effectiveDate: 'string',
  });
}
