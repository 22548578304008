import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DsmAdapter, responseUnused } from './dsm.adapter';
import { AppConfigService } from '@core/services/app-config.service';
import {
  VehicleExposureEntity,
  sanitizeVehicleExposureEntity,
} from '@entities/exposures/vehicle-exposures/vehicle-exposures.entity';
import { HttpHeaders } from '@angular/common/http';
import {
  VehicleExcludedExposureRequest,
  VehicleExposureRequest,
} from '@core/models/api/request/vehicle-exposure-request.model';
import {
  VehicleExcludedExposureEntity,
  sanitizeVehicleExcludedExposureEntity,
} from '@entities/exposures/vehicle-excluded-exposures/vehicle-excluded-exposures.entity';

@Injectable({
  providedIn: 'root',
})
export class VehicleExposureAdapter {
  constructor(
    public adapter: DsmAdapter,
    public appService: AppConfigService
  ) {}

  addVehicleExposure(
    request: VehicleExposureRequest
  ): Observable<VehicleExposureEntity> {
    let headers;
    if (!this.appService.isProd && this.appService.config.autoApproveUW) {
      headers = new HttpHeaders({
        'Auto-Approve-Rules': 'true',
      });
    }
    return this.adapter.request(
      sanitizeVehicleExposureEntity,
      'PersonalUmbrella',
      'POST',
      `/quotes/${request.quoteId}/vehicle-exposures`,
      'add-vehicle-exposure',
      {
        body: request.vehicleExposureBody,
        headers,
      }
    );
  }

  addVehicleExcludedExposure(
    request: VehicleExcludedExposureRequest
  ): Observable<VehicleExcludedExposureEntity> {
    let headers;
    if (!this.appService.isProd && this.appService.config.autoApproveUW) {
      headers = new HttpHeaders({
        'Auto-Approve-Rules': 'true',
      });
    }
    return this.adapter.request(
      sanitizeVehicleExcludedExposureEntity,
      'PersonalUmbrella',
      'POST',
      `/quotes/${request.quoteId}/vehicle-excluded-exposures`,
      'add-vehicle-excluded-exposure',
      {
        body: request.vehicleExcludedExposureBody,
        headers,
      }
    );
  }

  updateVehicleExposure(
    request: VehicleExposureRequest
  ): Observable<VehicleExposureEntity> {
    let headers;
    if (!this.appService.isProd && this.appService.config.autoApproveUW) {
      headers = new HttpHeaders({
        'Auto-Approve-Rules': 'true',
      });
    }
    return this.adapter.request(
      sanitizeVehicleExposureEntity,
      'PersonalUmbrella',
      'PUT',
      `/quotes/${request.quoteId}/vehicle-exposures/${request.vehicleExposureBody.vehicleExposure.vehicleExposureId}`,
      'update-vehicle-exposure',
      {
        body: request.vehicleExposureBody,
        headers,
      }
    );
  }

  updateVehicleExcludedExposure(
    request: VehicleExcludedExposureRequest
  ): Observable<VehicleExcludedExposureEntity> {
    let headers;
    if (!this.appService.isProd && this.appService.config.autoApproveUW) {
      headers = new HttpHeaders({
        'Auto-Approve-Rules': 'true',
      });
    }
    return this.adapter.request(
      sanitizeVehicleExcludedExposureEntity,
      'PersonalUmbrella',
      'PUT',
      `/quotes/${request.quoteId}/vehicle-excluded-exposures/${request.vehicleExcludedExposureBody.vehicleExcludedExposure.vehicleExcludedExposureId}`,
      'update-vehicle-exposure',
      {
        body: request.vehicleExcludedExposureBody,
        headers,
      }
    );
  }

  removeVehicleExposure(request: VehicleExposureRequest): Observable<unknown> {
    return this.adapter.request(
      responseUnused,
      'PersonalUmbrella',
      'DELETE',
      `/quotes/${request.quoteId}/vehicle-exposures/${request.vehicleExposureBody.vehicleExposure.vehicleExposureId}`,
      'remove-vehicle-exposure'
    );
  }

  removeVehicleExcludedExposure(
    request: VehicleExcludedExposureRequest
  ): Observable<unknown> {
    let headers;
    if (!this.appService.isProd && this.appService.config.autoApproveUW) {
      headers = new HttpHeaders({
        'Auto-Approve-Rules': 'true',
      });
    }
    return this.adapter.request(
      responseUnused,
      'PersonalUmbrella',
      'DELETE',
      `/quotes/${request.quoteId}/vehicle-excluded-exposures/${request.vehicleExcludedExposureBody.vehicleExcludedExposure.vehicleExcludedExposureId}`,
      'remove-vehicle-excluded-exposure',
      {
        body: request.vehicleExcludedExposureBody,
        headers,
      }
    );
  }
}
