import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
} from '@angular/core';
import { QuoteStatus } from '@core/models/api/dsm-types';

@Component({
  selector: 'nwx-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent implements OnChanges {
  @Input() quoteStatus!: QuoteStatus;

  constructor() {}

  isBuildQuote = true;
  isInitialRate = false;
  isFinalRate = false;
  isPurchase = false;

  ngOnChanges(): void {
    if (this.quoteStatus === 'Quoted') {
      this.isInitialRate = true;
      this.isFinalRate = false;
    } else if (this.quoteStatus === 'Binding') {
      this.isFinalRate = this.isInitialRate = true;
    } else if (this.quoteStatus === 'Draft') {
      this.isBuildQuote = true;
      this.isInitialRate = false;
      this.isFinalRate = false;
    }
  }
}
