import { CoverageActions, SessionActions } from '@core/store/actions';
import { DwellingReplacementCostFormModel } from '@app/coverages/forms/dwelling-replacement-cost-form/dwelling-replacement-cost-form.component';
import { PreBindDocumentsFormModel } from '@shared/components/pre-bind-documents/pre-bind-documents-form/pre-bind-documents-form.model';
import { Action, createReducer, on } from '@ngrx/store';
import { ConfirmationsAction } from '../../../../forms-store/store/actions';

export type ConfirmationsState = {
  dwellingReplacementCostForm: DwellingReplacementCostFormModel;
  preBindDocumentsForm: PreBindDocumentsFormModel;
};

export const initialState = {
  dwellingReplacementCostForm: {
    confirmation: false,
  },
  preBindDocumentsForm: {
    hasReceivedDocuments: false,
    hasReviewedDocuments: false,
    doesNotHaveQuestions: false,
  },
};

const confirmationsReducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(ConfirmationsAction.updatePreBindDocumentsForm, (state, { payload }) => ({
    ...state,
    preBindDocumentsForm: payload,
  })),
  on(
    CoverageActions.updateCoverageSuccess,
    ConfirmationsAction.resetPreBindDocumentsAcknowledgement,
    (state) => {
      return {
        ...state,
        preBindDocumentsForm: {
          hasReceivedDocuments: false,
          hasReviewedDocuments: false,
          doesNotHaveQuestions: false,
        },
      };
    }
  ),
  on(
    ConfirmationsAction.updateDwellingReplacementCostForm,
    (state, { payload }) => ({
      ...state,
      dwellingReplacementCostForm: {
        confirmation: payload,
      },
    })
  )
);

export function reducer(
  state: ConfirmationsState | undefined,
  action: Action
): ConfirmationsState {
  return confirmationsReducer(state, action);
}
