import { CoverageCodes } from '@shared/constants/app-constants';
import { CoverageDisplayGrouping } from './coverage-display-grouping';

export interface CoveragesMetadataType {
  displayGrouping: string;
  coverageSequence: number;
  spreadable?: boolean;
  displayName?: string; // Only used by other-structures coverages.
}

export const CoveragesMetadata: {
  [key: string]: CoveragesMetadataType;
} = {
  // START of CHRONOLOGICALLY LISTED COVERAGE GROUPS
  //
  // Property Specific Metadata: Home, Condo, Renters
  //
  // PropertyLiability Group vvv below vvv
  BasicCoverage: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 1,
  },
  DwellingReplacementCost: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 2,
  },
  DwellingReplacementCost200: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 2,
  },
  GuaranteedReplacementCost: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 2,
  },
  IncreasedOtherStructures: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 3,
  },
  InflationProtectionCoverage: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 5,
  },
  BrandNewBelongings: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 6,
  },
  BuildingAdditionsAlterations: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 7,
  },
  UnscheduledPersonalProperty: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 8,
  },
  IncreasedLossOfUse: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 9,
  },
  PersonalLiability: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 10,
  },
  PersonalInjury: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 11,
  },
  HomecareLiability: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 11,
  },
  MedicalPayments: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 12,
  },
  MineSubsidence: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 13,
  },
  SectionIDeductibles: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 14,
  },
  WindHailPerilDeductible: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 15,
  },
  WorkersCompensation: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 16,
  },
  ContaminationPollution: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 16,
  },
  WaterDamage: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 17,
  },
  Sinkhole: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 20,
  },
  SinkholeOtherStructures: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 20.1,
  },
  SinkholePersonalProperty: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 20.2,
  },
  SinkholeStructuresRented: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 20.3,
  },
  Foundation: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 21,
  },
  FarmLiability: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 50,
  },
  SpecialCoveragePersonalProperty: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 55,
  },
  SpecialCoverageDwelling: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 60,
  },
  ResidenceRentalTheft: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 65,
  },
  TreesShrubsOtherMaxPercentCovC: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 70,
  },
  AdditionalResidenceOwned: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 80,
    displayName: 'Additional units owned not rented',
  },
  AdditionalResidenceRented: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 81,
    displayName: 'Additional residence rented to others',
  },
  StructuresRented: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 82,
    displayName: 'Structures rented to others residence premises',
  },
  // PropertyLiability Group ^^^ above ^^^
  //
  // EACH GROUP CATEGORY in CHRONOLOGICAL ORDER
  // Property Specific Metadata CONT.
  //
  // Additional Protection Group vvv below vvv
  UnscheduledTools: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 15,
  },
  LossAssessment: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 16,
  },
  IdentityTheft: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 17,
  },
  TheftOfBuildingMaterials: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 18,
  },
  WaterBackupLimited: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 19,
  },
  BuildingOrdinanceOrLaw: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 20,
  },
  ActualCashValueRoof: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 21,
  },
  LimitedRoofLoss: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 22,
  },
  BetterRoofReplacement: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 23,
  },
  EquipmentBreakdown: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 24,
  },
  ServiceLine: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 25,
  },
  EarthquakeBasicCoverage: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 26,
  },
  EarthquakeUnscheduledPersonalProperty: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 27,
  },
  EarthquakeOtherStructures: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 28,
  },
  EarthquakeLossAssessment: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 29,
  },
  EarthquakeBuildingOrdinanceLaw: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 30,
  },
  EarthquakeMasonryVeneer: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 31,
  },
  EarthquakeAdditionsAlterations: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 32,
  },
  SpecialComputer: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 33,
  },
  CosmeticExcl: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 34,
  }, // Additional AdditionalProtection Group ^^^ above ^^^
  //
  // EACH GROUP CATEGORY in CHRONOLOGICAL ORDER
  // Property Specific Metadata CONT.
  //
  // SpecialLimitsOfLiability Group vvv below vvv
  //
  ProtectionBoost: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 0,
  },
  UnscheduledMoney: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 24,
  },
  UnscheduledSecurities: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 25,
  },
  WatercraftCoverage: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 26,
  },
  Trailers: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 27,
  },
  JewelryWatches: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 28,
  },
  JewelryInVault: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 29,
  },
  Firearms: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 30,
  },
  UnscheduledSilverware: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 31,
  },
  BusinessPropOn: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 32,
  },
  BusinessPropOff: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 33,
  },
  UnscheduledCamera: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 34,
  },
  FireDepartmentServiceCharge: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 35,
  },
  CreditCard: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 36,
  },
  LandlordFurnishings: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 37,
  },
  RefrigeratedPersonalProperty: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 38,
  },
  BioDeteriorationDamageCoverage: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 39,
  },
  Mold: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 39,
  },
  OutboardMotor: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 40,
  },
  DebrisRemovalTree: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 41,
  },
  TreesShrubsOtherMaxPercentCovA: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 42,
  },
  PortableEquip: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 43,
  },
  UnscheduledJewelryWatchesFurs: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 43,
  },
  PersonalPropOtherRes: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 44,
  },
  // SpecialLimitsOfLiability Group ^^^ above ^^^
  //
  // EACH GROUP CATEGORY in CHRONOLOGICAL ORDER
  //
  // START of Vehicle Specific Metadata: Auto, Moto/ATV, Boat, RV
  //
  // Policy Group vvv below vvv
  BI: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 1,
  },
  PD: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 2,
  },
  LPD: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 2.5,
    spreadable: true,
  },
  UMBI: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 3,
  },
  UIMBI: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 4,
  },
  RENTAL: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 7,
  },
  TL: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 8,
    spreadable: true,
  },
  MP: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 15,
  },
  DI: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 16,
  },
  TDI: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 17,
  },
  UMUIMBI: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 18,
  },
  UMPD: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 19,
    spreadable: true,
  },
  UIMPD: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 25,
    spreadable: true,
  },
  FSHEQ: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 52,
  },
  ACDD: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 64,
  },
  AM: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 65,
  },
  AAC: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 66,
  },
  MDCL: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 70,
    spreadable: true,
  },
  WL: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 71,
    spreadable: true,
  },
  FUNRL: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 72,
    spreadable: true,
  },
  PPI: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 79,
    spreadable: true,
  },
  PIP: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 80,
  },
  APIP: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 85,
    spreadable: true,
  },
  PEDPIP: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 86,
    spreadable: true,
  },
  PROTE: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 87,
  },
  OBEL: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 90,
    spreadable: true,
  },
  AD: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 91,
  },
  TORT: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 95,
    spreadable: true,
  }, // Policy Group ^^^ above ^^^
  //
  // EACH GROUP CATEGORY in CHRONOLOGICAL ORDER
  // Vehicle Specific Metadata CONT.
  //
  // Vehicle Group vvv below vvv
  COMP: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 9,
  },
  COLL: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 10,
  },
  WCD: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 11,
  },
  OEM: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 12,
  },
  CEC: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 13,
  },
  RENTRE: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 13,
  },
  GAPCOLL: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 14,
  },
  GAPCOMP: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 14.1,
  },
  NCRPCOLL: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 15,
  },
  EEE: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 25,
    spreadable: true,
  },
  TREX: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 26,
    spreadable: true,
  },
  EXMDCL: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 40,
    spreadable: true,
  },
  COMBL: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 50,
    spreadable: true,
  },
  LOI: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 60,
  },
  //
  // EACH GROUP CATEGORY in CHRONOLOGICAL ORDER
  // Vehicle Specific Metadata CONT.
  //
  // Driver Group vvv below vvv
  WorkLossBenefitExclusion: {
    displayGrouping: CoverageDisplayGrouping.Driver,
    coverageSequence: 35,
  },
  ENO: {
    displayGrouping: CoverageDisplayGrouping.Driver,
    coverageSequence: 47,
  },
  // Driver Group ^^^ above ^^^
  //
  // EACH GROUP CATEGORY in CHRONOLOGICAL ORDER
  // Vehicle Specific Metadata CONT.
  //
  // AdditionalPolicy Group vvv below vvv
  AF: {
    displayGrouping: CoverageDisplayGrouping.AdditionalPolicy,
    coverageSequence: 51,
  },
  MVF: {
    displayGrouping: CoverageDisplayGrouping.AdditionalPolicy,
    coverageSequence: 52,
  },
  VD: {
    displayGrouping: CoverageDisplayGrouping.AdditionalPolicy,
    coverageSequence: 53,
  },
  TLDW: {
    displayGrouping: CoverageDisplayGrouping.AdditionalPolicy,
    coverageSequence: 54,
  },
  RSA: {
    displayGrouping: CoverageDisplayGrouping.AdditionalPolicy,
    coverageSequence: 59,
  },
  IDFR: {
    displayGrouping: CoverageDisplayGrouping.AdditionalPolicy,
    coverageSequence: 60,
  }, // AdditionalPolicy Group ^^^ above ^^^
  //
  // EACH GROUP CATEGORY in CHRONOLOGICAL ORDER
  //
  // START of Umbrella Specific Metadata
  //
  // Policy Group vvv below vvv
  PULLiability: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 1,
  }, // Policy Group ^^^ above ^^^
  //
  // EACH GROUP CATEGORY in CHRONOLOGICAL ORDER
  // Umbrella Specific Metadata CONT.
  //
  //  Underlying Group vvv below vvv
  AutomobileLiability: {
    displayGrouping: CoverageDisplayGrouping.Underlying,
    coverageSequence: 3,
  },
  WatercraftLiability: {
    displayGrouping: CoverageDisplayGrouping.Underlying,
    coverageSequence: 4,
  },
  MiscOffRoadVehicleLiability: {
    displayGrouping: CoverageDisplayGrouping.Underlying,
    coverageSequence: 5,
  },
  MiscOnRoadVehicleLiability: {
    displayGrouping: CoverageDisplayGrouping.Underlying,
    coverageSequence: 6,
  }, // Underlying Group ^^^ above ^^^
};
// END CHRONOLOGICALLY LISTED COVERAGE GROUPS
//
export const StateExceptionCoverageMetadata: {
  [key: string]: {
    [key: string]: CoveragesMetadataType;
  };
} = {
  AR: {
    AD: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 65,
      spreadable: true,
    },
  },
  GA: {
    BioDeteriorationDamageCoverage: {
      displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
      coverageSequence: 44,
      spreadable: true,
    },
    Mold: {
      displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
      coverageSequence: 45,
      spreadable: true,
    },
  },
  KS: {
    PIP: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 1,
      spreadable: true,
    },
  },
  ME: {
    PIP: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 1,
      spreadable: true,
    },
  },
  NC: {
    DI: {
      displayGrouping: CoverageDisplayGrouping.Driver,
      coverageSequence: 16,
      spreadable: true,
    },
    TDI: {
      displayGrouping: CoverageDisplayGrouping.Driver,
      coverageSequence: 17,
      spreadable: true,
    },
  },
  NY: {
    BioDeteriorationDamageCoverage: {
      displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
      coverageSequence: 44,
      spreadable: true,
    },
    Mold: {
      displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
      coverageSequence: 45,
      spreadable: true,
    },
  },
  PA: {
    AD: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 65,
      spreadable: true,
    },
  },
  SD: {
    ACDD: {
      displayGrouping: CoverageDisplayGrouping.Driver,
      coverageSequence: 64,
      // spreadable: false,
    },
    AM: {
      displayGrouping: CoverageDisplayGrouping.Driver,
      coverageSequence: 65,
      // spreadable: false,
    },
    AD: {
      displayGrouping: CoverageDisplayGrouping.Driver,
      coverageSequence: 65,
      // spreadable: false,
    },
  },
  TX: {
    PIP: {
      displayGrouping: CoverageDisplayGrouping.Policy,
      coverageSequence: 10,
      spreadable: true,
    },
    BioDeteriorationDamageCoverage: {
      displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
      coverageSequence: 44,
      spreadable: true,
    },
    Mold: {
      displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
      coverageSequence: 45,
      spreadable: true,
    },
  },
  VA: {
    WaterBackupLimited: {
      displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
      coverageSequence: 45,
      spreadable: true,
    },
    WaterBackupFull: {
      displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
      coverageSequence: 46,
      spreadable: true,
    },
  },
  WA: {
    PIP: {
      displayGrouping: CoverageDisplayGrouping.Policy,
      coverageSequence: 10,
      spreadable: true,
    },
    UIMPD: {
      displayGrouping: CoverageDisplayGrouping.Policy,
      coverageSequence: 25,
      spreadable: true,
    },
  },
};

export const ProductExceptionCoverageMetadata: {
  [key: string]: {
    [key: string]: {
      displayGrouping: string;
      coverageSequence: number;
      spreadable?: boolean;
    };
  };
} = {
  MSA: {
    PDSO: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 10,
    },
    VD: {
      displayGrouping: CoverageDisplayGrouping.Policy,
      coverageSequence: 53,
    },
    RSA: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 59,
    },
  },
  Boat: {
    PKG: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 11,
    },
    PEREF: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 12,
    },
    PDSO: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 10,
    },
    RSA: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 15,
    },
    MP: {
      displayGrouping: CoverageDisplayGrouping.Policy,
      coverageSequence: 3,
    },
    UMBI: {
      displayGrouping: CoverageDisplayGrouping.Policy,
      coverageSequence: 4,
    },
    TL: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 13,
      spreadable: true,
    },
    VD: {
      displayGrouping: CoverageDisplayGrouping.Policy,
      coverageSequence: 53,
    },
    RENTRE: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 14,
    },
  },
  RV: {
    PDSO: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 11,
    },
    // TODO
    RCPE: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 16,
    },
    CEC: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 12,
    },
    VACLIAB: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 17,
    },
    SGLASS: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 13,
    },
    RSA: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 18,
    },
    FTPKG: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 15,
    },
    AWN: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 14,
    },
    FTSS: {
      displayGrouping: CoverageDisplayGrouping.Policy,
      coverageSequence: 20,
    },
    SPE: {
      displayGrouping: CoverageDisplayGrouping.Policy,
      coverageSequence: 99,
    },
    IDFR: {
      displayGrouping: CoverageDisplayGrouping.Policy,
      coverageSequence: 21,
    },
    VD: {
      displayGrouping: CoverageDisplayGrouping.Policy,
      coverageSequence: 22,
    },
  },
  PersonalUmbrella: {
    PersonalLiability: {
      displayGrouping: CoverageDisplayGrouping.Underlying,
      coverageSequence: 2,
    },
    UMUIMBI: {
      displayGrouping: CoverageDisplayGrouping.AdditionalUnderlying,
      coverageSequence: 1,
    },
  },
};

// Keyed by coverage ID, includes list of terms for that coverage that cannot be declined
export const NonDeclinableCoverageTerms: {
  [key: string]: string[];
} = {
  EarthquakeBasicCoverage: [CoverageCodes.EQBasicCoverageDeductible],
  HomecareLiability: [CoverageCodes.NumberBeingCaredFor],
};
