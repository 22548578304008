import { MemberModel } from '@core/models/views/person.model';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { DriverEntity } from '../driver/driver.entity';
import { ErrorModel } from '../error/error.model';
import { VehicleEntity } from '../vehicle/vehicle.entity';

export const getCustomersInfo = createAction(
  '[DriverVehiclePrefill] Get Customers Info'
);

export const getCustomersInfoSuccess = createAction(
  '[DriverVehiclePrefill] Get Customers Info Success'
);

export const getCustomersInfoFail = createAction(
  '[DriverVehiclePrefill] Get Customers Info Fail',
  props<{ payload: ErrorModel }>()
);

export const getCustomersInfoNoHit = createAction(
  '[DriverVehiclePrefill] Get Customers Info No Hit'
);

export const addAllPrefillVehicles = createAction(
  '[DriverVehiclePrefill] Add All Prefill Vehicles',
  props<{ payload: VehicleEntity[] }>()
);

export const addAllPrefillPeople = createAction(
  '[DriverVehiclePrefill] Add All Prefill People',
  props<{ payload: MemberModel[] }>()
);

export const updatePrefillPersonSelected = createAction(
  '[DriverVehiclePrefill] Update Prefill Driver Selected',
  props<{ payload: Update<MemberModel> }>()
);

export const storePrefillPerson = createAction(
  '[DriverVehiclePrefill] Store Prefill Person',
  props<{ payload: Update<MemberModel> }>()
);

export const updatePrefillVehicleSelected = createAction(
  '[DriverVehiclePrefill] Update Prefill Vehicle Selected',
  props<{ payload: Update<VehicleEntity> }>()
);

export const updatePrefillVehicle = createAction(
  '[DriverVehiclePrefill] Update Prefill Vehicle',
  props<{ payload: Update<VehicleEntity> }>()
);

export const updatePrefillPerson = createAction(
  '[DriverVehiclePrefill] Update Prefill Driver',
  props<{ payload: Update<DriverEntity> }>()
);
