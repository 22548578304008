import {
  ComponentFactoryResolver,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { SessionService } from '@core/services/session.service';
import { TaskService } from '@core/services/task.service';
import { TaskModel } from '@core/store/entities/task/task.model';
import { FieldDisplayWarningComponent } from '@shared/components/field-display-warning/field-display-warning.component';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[nwxDisplayWarning]',
})
export class DisplayWarningDirective implements OnInit, OnDestroy {
  @Input() id: string = ''; // fallback
  @Input() taskElementId: string = ''; // preferred
  @Input() formControlName: string = '';

  tasks$: Observable<TaskModel[]>;
  hubPageLoaded$: Observable<boolean>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private taskService: TaskService,
    private sessionService: SessionService
  ) {
    this.hubPageLoaded$ = this.sessionService.getHubPageLoaded();
    this.tasks$ = this.taskService.getIncompleteTasks();
  }

  ngOnInit(): void {
    combineLatest([this.tasks$, this.hubPageLoaded$])
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(([tasks, hubLoaded]) => hubLoaded),
        map(([tasks, hubLoaded]) => tasks)
      )
      .subscribe((tasks) => {
        const id = this.taskElementId || this.id;
        if (!id) {
          return;
        }
        const matchingTask = tasks.find((task) => task.elementId === id);
        if (!!matchingTask) {
          this.displayWarningIcon(matchingTask.errorMessage || '', id);
        } else {
          this.viewContainerRef.clear();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private displayWarningIcon(message: string, id: string): void {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        FieldDisplayWarningComponent
      );
    const component = this.viewContainerRef.createComponent(componentFactory);
    const instance = component.instance;
    instance.id = id;
    if (message) {
      instance.message = message;
    }
  }
}
