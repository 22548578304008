import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  Input,
} from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { AccountService } from '@core/services/account.service';
import { LogService } from '@core/services/log.service';
import { ProductsService } from '@core/services/products.service';
import { PcUrlService, UrlType } from '@shared/services/pc-url.service';
import { Subject } from 'rxjs';
import { takeUntil, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'nwx-pivot-to-policy-center',
  templateUrl: './pivot-to-policy-center.component.html',
  styleUrls: ['./pivot-to-policy-center.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PivotToPolicyCenterComponent implements OnInit, OnDestroy {
  @Input() buttonText!: string;
  @Input() showText!: boolean;
  @Input() externalIcon?: boolean;
  @Input() productType?: ProductType;
  @Input() urlType?: UrlType;
  @Input() errorMessage?: string;

  url!: string;

  unsubscribe$ = new Subject<void>();

  constructor(
    private logService: LogService,
    private window: Window,
    private pcUrlService: PcUrlService,
    private productsService: ProductsService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    if (!this.urlType || this.urlType === 'Job') {
      this.productsService
        .getQuoteIdForProduct(this.productType || 'PersonalAuto')
        .pipe(
          takeUntil(this.unsubscribe$),
          switchMap((quoteId) =>
            this.pcUrlService.getPolicyCenterUrl(quoteId, 'Job').pipe(take(1))
          )
        )
        .subscribe((url) => (this.url = url));
    } else {
      this.accountService
        .getAccountId()
        .pipe(
          takeUntil(this.unsubscribe$),
          switchMap((accountId) =>
            this.pcUrlService
              .getPolicyCenterUrl(accountId || undefined, 'Account')
              .pipe(take(1))
          )
        )
        .subscribe((url) => (this.url = url));
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  launchPolicyCenter(): void {
    this.logService.logBusinessEvent('pivot-to-pc', {
      reason: this.errorMessage || 'reason not provided',
    });
    this.window.open(this.url);
  }
}
