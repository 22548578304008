import {
  PreBindDocumentEmailResponse,
  PreBindDocumentUploadResponse,
} from '@core/models/api/response/pre-bind-document-response.model';
import { PreBindDocumentEntity } from '@core/models/entities/pre-bind-document.entity';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../error/error.model';
import { ProductType } from '@core/models/api/dsm-types';

export const uploadPreBindDocuments = createAction(
  '[PreBind Documents] Upload Pre-Bind Documents',
  props<{
    productType: ProductType;
  }>()
);

export const uploadPreBindDocumentsFail = createAction(
  '[PreBind Documents] Upload Pre-Bind Documents Fail',
  props<{
    error: ErrorModel;
  }>()
);

export const uploadPreBindDocumentsSuccess = createAction(
  '[PreBind Documents] Upload Pre-Bind Documents Success',
  props<{
    uploadResponse: PreBindDocumentUploadResponse;
    productType: ProductType;
  }>()
);

export const replacePreBindDocumentsByProductType = createAction(
  '[PreBind Documents] Replace Pre-Bind Documents By Product Type',
  props<{
    documents: PreBindDocumentEntity[];
    productType: ProductType;
  }>()
);

export const dropPreBindDocuments = createAction(
  '[PreBind Documents] Drop Pre-Bind Documents By Product Type',
  props<{
    productType: ProductType;
  }>()
);

export const dropAllPreBindDocuments = createAction(
  '[PreBind Documents] Drop Pre-Bind Documents'
);

export const resendDocumentUrlEmail = createAction(
  '[PreBind Documents] Resend Document URL Email',
  props<{
    productTypes: ProductType[];
  }>()
);

export const resendDocumentUrlEmailSuccess = createAction(
  '[PreBind Documents] Resend Document URL Email Success',
  props<{
    response: PreBindDocumentEmailResponse;
  }>()
);

export const resendDocumentUrlEmailFail = createAction(
  '[PreBind Documents] Resend Document URL Email Fail',
  props<{
    error: ErrorModel;
  }>()
);

export const updatePreBindDocument = createAction(
  '[PreBind Documents] Update Pre-Bind Documents',
  props<{
    document: PreBindDocumentEntity;
  }>()
);

export const uploadAllProductsPreBindDocuments = createAction(
  "[PreBind Documents] Upload All Products' Pre-Bind Documents"
);
