import {
  Component,
  Input,
  OnChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { ProductButton } from '@core/interfaces/interfaces';
import { ProductType } from '@core/models/api/dsm-types';
import { LayoutService } from '@core/services/layout.service';
import { ProductsService } from '@core/services/products.service';
import { ComponentChanges } from '@shared/utils/general.utils';
import { PriceSummaryUtils } from '@shared/utils/hub/price-summary/price-summary.util';
import { Nullable } from '@shared/utils/type.utils';
import { Observable } from 'rxjs';
import { PriceSummaryDisplayContent } from './price-summary-display-content';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductToggleFormComponent } from '@shared/components/forms/product-toggle-form/product-toggle-form.component';
import { HubPageRepresentation } from '@core/constants/pages';

@Component({
  selector: 'nwx-price-summary-display',
  templateUrl: './price-summary-display.component.html',
  styleUrls: ['./price-summary-display.component.scss'],
})
export class PriceSummaryDisplayComponent implements OnChanges {
  @Input() content: PriceSummaryDisplayContent = {};
  @Input() maxSizeMedium: Nullable<boolean>;

  @Output() addUpsell = new EventEmitter<Nullable<ProductType>>();
  @Output() openModal = new EventEmitter<void>();
  @Output() showPdf = new EventEmitter<void>();

  quoteStatusClassName = 'qs-unknown';

  productButtons$: Observable<ProductButton[]>;
  maxSizeSmall$: Observable<boolean>;

  constructor(
    private productsService: ProductsService,
    private layoutService: LayoutService,
    private modal: NgbModal
  ) {
    this.productButtons$ = this.productsService.getProductButtons(false);
    this.maxSizeSmall$ = this.layoutService.maxSizeSmall();
  }

  ngOnChanges(changes: ComponentChanges<PriceSummaryDisplayComponent>): void {
    if (changes.content) {
      this.quoteStatusClassName = PriceSummaryUtils.selectQuoteStatusClassName(
        this.content.quoteStatusLabel || ''
      );
    }
  }
  contentForSmallView(): PriceSummaryDisplayContent {
    return {
      ...this.content,
      downPayments: this.content.downPayments?.filter((dp) => dp.isEstTotal),
    };
  }
  openProductToggleModal(): void {
    const modal = this.modal.open(ProductToggleFormComponent, {
      centered: true,
      size: 'xl',
    });
    modal.componentInstance.page = HubPageRepresentation;
  }
}
