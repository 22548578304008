<label
  *ngIf="label"
  for="elementId"
  class="bolt-select-wc--label bolt-select-wc--label__"
>
  {{ label }}
</label>
<div class="bolt-select-wc--input-container">
  <select
    #select
    class="bolt-select-wc--input bolt-select-wc--input__"
    id="{{ elementId }}"
    [attr.aria-label]="arialabel || null"
    [attr.aria-labelledby]="arialabelledby || null"
  >
    <ng-content></ng-content>
  </select>
  <div class="bolt-select-wc--input-icon-container">
    <bolt-icon name="caret-down" decorative="" size="sm"></bolt-icon>
  </div>
</div>
<bolt-field-error *ngIf="error">
  {{ error }}
</bolt-field-error>
