import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { PolicyIssueRequest } from '../models/api/request/policy-issue-request.model';
import { PolicyIssueResponse } from '../models/api/response/policy-issue-response.model';
import { AppConfigService } from '../services/app-config.service';
import { LogService } from '../services/log.service';
import { ProductsService } from '../services/products.service';
import { SessionService } from '../services/session.service';
import { StringUtils } from '@shared/utils/string.utils';

@Injectable({
  providedIn: 'root',
})
export class PolicyIssueAdapter {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private log: LogService,
    private sessionService: SessionService,
    private productsService: ProductsService
  ) {}

  policies(request: PolicyIssueRequest): Observable<PolicyIssueResponse> {
    const url = `${this.appConfigService.config.pcEdgePolicyIssueUrl}/policies`;
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this.appConfigService.config.apiKey)
      .set('X-NW-Message-ID', StringUtils.generateUuid());

    if (this.appConfigService.config.targetEnv) {
      headers = headers.set(
        'X-NW-Target-Env',
        this.appConfigService.config.targetEnv || ''
      );
    }

    return combineLatest([
      this.sessionService.getAccessToken(),
      this.productsService.getProduct(request.productType),
    ]).pipe(
      take(1),
      mergeMap(([accessToken, product]) => {
        headers = headers.set('session-id', product?.sessionId || '');
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
        const correlationId = this.log.logApiRequest(
          'POST',
          url,
          'issue-policy',
          { product: product?.type }
        );
        return this.httpClient
          .post<PolicyIssueResponse>(
            url,
            {
              quoteId: request.quoteId,
            },
            {
              headers,
            }
          )
          .pipe(
            map((response) => {
              return (
                response && {
                  ...response,
                  productId: product?.type,
                }
              );
            }),
            tap((response) =>
              this.log.logApiResponse(correlationId, 200, {
                ...response,
                productId: product?.type,
              })
            ),
            catchError((error) =>
              this.log.logApiError(correlationId, {
                ...error,
                productId: product?.type,
              })
            )
          );
      })
    );
  }
}
