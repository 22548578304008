<div id="page-overlay">
  <div id="card">
    <div class="bolt-container-fluid">
      <div class="bolt-row">
        <div class="bolt-col-auto">
          <img
            src="/assets/images/icons/info-circle-filled.svg"
            alt=""
            id="infoCircleIcon"
          />
        </div>
        <div class="bolt-col">
          <h1
            id="annnouncementModalHeader"
            class="bolt-heading-xs bolt-space-bottom-2xl"
          >
            We've improved your experience!
          </h1>
          <div class="content">
            <ng-container *ngIf="announcementItems$ | async as announcements">
              <ng-container
                *ngIf="announcements.length; else noNewAnnouncements"
              >
                <ul class="bolt-space-bottom-xl">
                  <ng-container *ngFor="let entry of announcements">
                    <li class="bolt-space-bottom-xs">{{ entry }}</li>
                  </ng-container>
                </ul>
              </ng-container>
              <ng-template #noNewAnnouncements>
                <div class="bolt-space-bottom-xl">
                  No new announcements yet. Check back soon!
                </div>
              </ng-template>
            </ng-container>

            <div>
              Open this menu at any time by navigating to the profile menu and
              clicking "See what's new"
            </div>
          </div>
        </div>
      </div>
    </div>
    <bolt-button-bar>
      <bolt-button
        slot="forward"
        type="primary"
        (click)="close()"
        id="closeAnnouncementModalBtn"
      >
        Continue
      </bolt-button>
    </bolt-button-bar>
  </div>
</div>
