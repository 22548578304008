import {
  CondoPageRepresentation,
  TenantPageRepresentation,
  HomeownerPageRepresentation,
} from '@core/constants/pages';
import { PolicyDateOptions } from '@core/interfaces/interfaces';
import { ProductType } from '@core/models/api/dsm-types';
import { MortgageModel } from '@shared/components/forms/mortgage-form/mortgage-form.model';
import { Nullable } from '@shared/utils/type.utils';
import { CoveredLocationEntity } from '../../covered-location/covered-location.reducer';
import { FeatureFlagsModel } from '../../feature-flag/feature-flag.model';
import { ProductModel } from '../../product/product.model';
import { CoveredLocationRules } from '../rules/covered-location.rules';
import { TaskValidators } from '../task-validators';
import { PropertyCoverageTaskParams, TaskModel } from '../task.model';
import { TaskUtils } from './task.util';
import { PropertyTaskValidators } from '@entities/task/validators/property-task-validators';

export class PropertyTaskUtils {
  static generatePropertyTasks(
    coveredLocation: Nullable<CoveredLocationEntity>,
    features: FeatureFlagsModel,
    selectedProducts: ProductType[],
    policyDateOptions: PolicyDateOptions,
    product: Nullable<ProductModel>,
    mortgages: Nullable<MortgageModel[]>,
    isBillToEscrow: Nullable<boolean>,
    hasMortgage: boolean | undefined
  ): TaskModel[] {
    if (!coveredLocation) {
      return [];
    }
    const t = CoveredLocationRules.property;
    switch (product?.type) {
      case 'Condominium':
        return TaskValidators.runEntityRulesForSingle<CoveredLocationEntity>(
          TaskUtils.createTaskTemplates(
            t.condo,
            features,
            product.quoteStatus || 'Draft'
          ),
          coveredLocation,
          CondoPageRepresentation,
          'coveredLocation',
          'Condominium'
        )
          .concat(
            TaskValidators.propertyCustomRules(
              coveredLocation,
              mortgages,
              isBillToEscrow,
              hasMortgage
            )
          )
          .concat(
            TaskValidators.productCustomRules(
              product,
              selectedProducts,
              policyDateOptions
            )
          );
      case 'Tenant':
        return TaskValidators.runEntityRulesForSingle<CoveredLocationEntity>(
          TaskUtils.createTaskTemplates(
            t.renters,
            features,
            product.quoteStatus || 'Draft'
          ),
          coveredLocation,
          TenantPageRepresentation,
          'coveredLocation',
          'Tenant'
        )
          .concat(
            TaskValidators.propertyCustomRules(coveredLocation, mortgages)
          )
          .concat(
            TaskValidators.productCustomRules(
              product,
              selectedProducts,
              policyDateOptions
            )
          );
      case 'Homeowner':
        return TaskValidators.runEntityRulesForSingle<CoveredLocationEntity>(
          TaskUtils.createTaskTemplates(
            t.homeowner,
            features,
            product.quoteStatus || 'Draft'
          ),
          coveredLocation,
          HomeownerPageRepresentation,
          'coveredLocation',
          'Homeowner'
        )
          .concat(
            TaskValidators.runEntityRulesForSingle<ProductModel>(
              TaskUtils.createTaskTemplates(
                t.product,
                features,
                product.quoteStatus || 'Draft'
              ),
              product || {},
              HomeownerPageRepresentation,
              'coveredLocation',
              'Homeowner'
            )
          )
          .concat(
            TaskValidators.propertyCustomRules(
              coveredLocation,
              mortgages,
              isBillToEscrow,
              hasMortgage
            )
          )
          .concat(
            TaskValidators.productCustomRules(
              product,
              selectedProducts,
              policyDateOptions
            )
          );
      default:
        return [];
    }
  }

  static generatePropertyCoverageTasks(params: PropertyCoverageTaskParams): TaskModel[] {
    return [
      ...PropertyTaskValidators.coverageCustomRules(params),
      ...TaskValidators.scheduledPropertyCustomRules(
        params.scheduledCategoryEntities,
        params.products
      )
    ];
  }
}
