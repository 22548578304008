import * as fromCore from '@core/store/reducers';
import { createSelector } from '@ngrx/store';

export const selectCompraterRouting = createSelector(
  fromCore.getCoreState,
  (state) => state.compraterRouting
);

export const selectCompraterRoutingCallCount = createSelector(
  selectCompraterRouting,
  (state) => state.callCount
);

export const selectCompraterRoutingResponse = createSelector(
  selectCompraterRouting,
  (state) => state.response
);
