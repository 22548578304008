import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'nwx-tile-with-icon',
  templateUrl: './tile-with-icon.component.html',
  styleUrls: ['./tile-with-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileWithIconComponent {
  @Input() icon: string = 'pencil';
  @Input() overrideIcon?: boolean;

  @Output() iconClick = new EventEmitter<void>();

  emitIconClick(): void {
    this.iconClick.emit();
  }
}
