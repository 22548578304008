import { AgencyService } from './../../../services/agency.service';
import { AgencyModel } from './agency.model';
import { combineLatest, from, of, concatMap } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { updateAgency } from './agency.action';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { CoreState } from '../../reducers';
import { AgencySelectors, SessionSelectors } from '@core/store/selectors';
import { AppConfigService } from '@core/services/app-config.service';
import { AgencyActions, ProductActions } from '@core/store/actions';
import { retrieveQuoteSuccess } from '@core/store/retrieve/retrieve.action';
import { ProducerUtils } from '@shared/utils/producer.utils';

@Injectable()
export class AgencyEffects {
  constructor(
    private actions$: Actions,
    private store: Store<CoreState>,
    private agencyService: AgencyService,
    private appConfigService: AppConfigService
  ) {}

  fetchAgencyResidentStateOnProducerCodeChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAgency),
      map((a) => a.payload),
      distinctUntilChanged(
        (prev, current) => prev.agent?.agencyCode === current.agent?.agencyCode
      ),
      filter((agency: AgencyModel) => agency.agent?.agencyCode !== null),
      switchMap((agency: AgencyModel) => {
        return this.agencyService.getAgencyResidentState(agency).pipe(
          map(
            (state) =>
              ({
                ...agency,
                agent: {
                  ...agency.agent,
                  agentState: state,
                },
              } as AgencyModel)
          )
        );
      }),
      map((agency) => {
        return updateAgency({
          payload: {
            ...agency,
          },
        });
      })
    )
  );

  updateAgentStateAfterRetrieve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retrieveQuoteSuccess),
      switchMap((action) =>
        combineLatest([this.agencyService.getProducerCodes(), of(action)]).pipe(
          take(1)
        )
      ),
      concatMap(([producerSearch, retrieveAction]) => {
        const retrieveResponse = retrieveAction.payload.response;
        const actions: Action[] = [];
        actions.push(
          updateAgency({
            payload: {
              producerCode: retrieveResponse.producer.producerCode,
              agent: {
                agencyCode:
                  retrieveResponse.producer.type === 'IndependentAgent'
                    ? retrieveResponse.producer.agencyCode
                    : '',
                agentState: ProducerUtils.getProducerSearchStateByAMFCode(
                  retrieveResponse.producer.producerCode,
                  producerSearch.producers
                ),
              },
            },
          })
        );
        const producer = producerSearch?.producers.find(
          (p) => retrieveResponse.producer.producerCode == p.producerCode
        );
        if (producer) {
          actions.push(
            ProductActions.updateProducer({
              product: retrieveAction.payload.productType,
              producer,
            })
          );
        }
        return from(actions);
      })
    )
  );
}
