<div class="task-content-head-row" [ngClass]="{ 'pb-12': showTasks === false }">
  <div class="up-next">
    <img
      src="../../../../assets/images/icons/arrow-outline.svg"
      class="transform-img inline-icon"
      alt="Right arrow"
    />
    <h4 class="task-heading">Up Next:&nbsp;</h4>
    <h4 class="light instructions-text">
      {{ nextStep?.instructions }}
    </h4>
  </div>
  <div class="btn-row" *ngIf="!nextStep?.noButton">
    <ng-container *ngIf="!(buttonReady$ | async)?.valueOf()">
      <span class="loading">Loading&nbsp;&nbsp;&nbsp;</span>
      <bolt-waiting-indicator minimal></bolt-waiting-indicator>
    </ng-container>
    <ng-container *ngIf="nextStep?.buttonType === 'default'">
      <bolt-button
        *ngIf="buttonReady$ | async"
        class="bolt-button task-button"
        [ngClass]="{
          'hide-non-telemtcs-btn': !nextStep?.displayInCondensedView
        }"
        [attr.id]="'nshc-task-btn'"
        [type]="getButtonAttribute('type')"
        [size]="getButtonAttribute('size')"
        (click)="clickEvent.emit(nextStep?.buttonAction)"
        [disabled]="nextStep?.buttonDisabled"
      >
        <span>{{ nextStep?.buttonLabel }}</span>
      </bolt-button>
    </ng-container>
    <ng-container *ngIf="nextStep?.buttonType === 'recalculate'">
      <button
        class="faux-bolt-button recalculate task-button"
        [attr.id]="'nshc-recalculate-btn'"
        *ngIf="buttonReady$ | async"
        (click)="clickEvent.emit(nextStep?.buttonAction)"
        [disabled]="nextStep?.buttonDisabled"
      >
        {{ nextStep?.buttonLabel }}

        <bolt-icon name="refresh"></bolt-icon>
      </button>
    </ng-container>

    <!-- <div
      id="chev-containment"
      (click)="clickEvent.emit(nextStep?.buttonAction)"
      *ngIf="buttonReady$ | async"
    >
      <bolt-icon
        *ngIf="!showTasks"
        name="chevron-down"
        [attr.id]="chevron-down"
        size="sm"
      ></bolt-icon>
      <bolt-icon
        *ngIf="showTasks"
        name="chevron-up"
        [attr.id]="chevron-up"
        size="sm"
      ></bolt-icon>
    </div> -->
  </div>
</div>
