import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import {
  CoverageSelectors,
  CoveredLocationSelectors,
  CurrentCarrierSelectors,
  EligibleDiscountSelectors,
  MemberSelectors,
  ProductsSelectors,
  TelematicsSelectors,
  VehicleSelectors,
  ScheduledCategorySelectors,
  PersonaSelectors,
} from '@core/store/selectors';
import {
  PersonaRecommendation,
  PersonaRecommendationId,
  PersonaReport,
} from './persona.model';
import { ProductType } from '@core/models/api/dsm-types';
import { CoverageEntity } from '@entities/coverage/coverage.entity';
import { EligibleDiscountsEntity } from '@entities/eligible-discounts/eligible-discounts.entity';
import { TelematicsRecommendationResponse } from '@core/models/api/response/telematics-recommendation-response.model';
import { ScheduledCategoryEntity } from '@entities/scheduled-categories/scheduled-category.entity';

export const getPersonaState = createSelector(
  fromCore.getCoreState,
  (core) => core.persona
);

export const getPersonaName = createSelector(
  getPersonaState,
  (state) => state.name
);

export const getPersonaSelectionInputs = createSelector(
  ProductsSelectors.getSelectedProductEntities,
  VehicleSelectors.getAllSelectedVehiclesByProduct('PersonalAuto'),
  CurrentCarrierSelectors.getCurrentCarrierEntities,
  CoverageSelectors.getAllCoverages,
  MemberSelectors.getAllSelectedDriverMemberModels('PersonalAuto'),
  TelematicsSelectors.getCurrentEnrollment,
  CoveredLocationSelectors.getCoveredLocationEntities,
  PersonaSelectors.getPersonaName,
  (
    products,
    vehicles,
    currentCarriers,
    coverages,
    drivers,
    telematics,
    coveredLocations,
    personaName
  ) => ({
    products,
    vehicles,
    currentCarriers,
    coverages,
    drivers,
    telematics,
    coveredLocations,
    personaName,
  })
);

function populateRecommendation(
  recommendation: PersonaRecommendation,
  coverages: CoverageEntity[],
  telematicsEnrolled: boolean,
  telematicsRecommendation: TelematicsRecommendationResponse | undefined,
  selectedProducts: ProductType[],
  discounts: EligibleDiscountsEntity[],
  scheduledCategories: ScheduledCategoryEntity[] | undefined
): void {
  switch (recommendation.id) {
    case 'Telematics':
      if (telematicsEnrolled) {
        recommendation.present = true;
      } else if (telematicsRecommendation) {
        recommendation.available = true;
      }
      break;

    case 'IdTheft':
      {
        const idTheftCoverages = coverages.filter((c) =>
          ['IDFR', 'IdentityTheft'].includes(c.coverageId)
        );
        if (idTheftCoverages?.some((coverage) => coverage.available)) {
          if (
            idTheftCoverages?.some((coverage) =>
              coverage.selectedValue?.find((v) =>
                ['IDFRLimit', 'IdentityTheftLimit'].includes(v.code)
              )
            )
          ) {
            recommendation.present = true;
          } else {
            recommendation.available = true;
          }
        }
      }
      break;

    case 'ValuablesPlus':
      recommendation.available = !!scheduledCategories?.some(
        (cat) => !!cat.isScheduledItemsApplicable
      );
      recommendation.present = !!scheduledCategories?.some(
        (cat) =>
          (cat.scheduledItems ? cat.scheduledItems.length > 0 : false) ||
          (cat.limit ? cat.limit.length > 0 : false)
      );
      break;

    case 'Warranty':
      //TODO No idea what this is.
      break;

    case 'RoadsidePlus':
      {
        const coverage = coverages.find(
          (c) => c.coverageId === 'RSA' && c.productId === 'PersonalAuto'
        );
        if (coverage?.available) {
          if (
            coverage.selectedValue?.find((v) => v.code === 'RSAOption')
              ?.value === '1'
          ) {
            recommendation.present = true;
          } else {
            recommendation.available = true;
          }
        }
      }
      break;

    case 'SmartHome':
      {
        const discount = discounts.find(
          (c) => c.eligibleDiscountId === 'SmartDevice'
        );
        if (discount) {
          if (discount.selectedOptionValue === 'Participating') {
            recommendation.present = true;
          } else {
            recommendation.available = true;
          }
        }
      }
      break;

    case 'Umbrella':
      if (selectedProducts.includes('PersonalUmbrella')) {
        recommendation.present = true;
      } else {
        recommendation.available = true;
      }
      break;

    case 'HomeMaintenanceJourneyGuide':
      //TODO What is this?
      break;

    case 'ServiceLine':
      {
        const coverage = coverages.find((c) => c.coverageId === 'ServiceLine');
        if (coverage?.available) {
          if (
            coverage.selectedValue?.find((v) => v.code === 'ServiceLineLimit')
              ?.value
          ) {
            recommendation.present = true;
          } else {
            recommendation.available = true;
          }
        }
      }
      break;

    case 'EquipmentBreakdown':
      {
        const coverage = coverages.find(
          (c) => c.coverageId === 'EquipmentBreakdown'
        );
        if (coverage?.available) {
          if (
            coverage.selectedValue?.find((v) => v.code === 'selected')
              ?.value === 'true'
          ) {
            recommendation.present = true;
          } else {
            recommendation.available = true;
          }
        }
      }
      break;

    case 'WaterBackupLimited':
      {
        const coverage = coverages.find(
          (c) => c.coverageId === 'WaterBackupLimited'
        );
        if (coverage?.available) {
          if (
            coverage.selectedValue?.find(
              (v) => v.code === 'WaterBackupLimitedLimit'
            )?.value
          ) {
            recommendation.present = true;
          } else {
            recommendation.available = true;
          }
        }
        recommendation.isCoverageWithLimit = true;
      }
      break;
  }
  if (recommendation.present) {
    recommendation.available = true;
  }
}

export const getPersonaReport = createSelector(
  getPersonaName,
  CoverageSelectors.getAllCoverages,
  TelematicsSelectors.isAnyTelematicsEnrolled,
  TelematicsSelectors.getCurrentRecommendation,
  ProductsSelectors.getSelectedProductTypes,
  EligibleDiscountSelectors.selectAllEligibleDiscount,
  ScheduledCategorySelectors.getAllScheduledCategoriesByProduct('Homeowner'),
  (
    name,
    coverages,
    telematicsEnrolled,
    telematicsRecommendation,
    selectedProducts,
    discounts,
    scheduledCategories
  ) => {
    const result: PersonaReport = {
      name,
      recommendations: [],
    };
    const recommend = (
      id: PersonaRecommendationId,
      product: 'Auto' | 'Property' | ''
    ): void => {
      const recommendation = {
        id,
        product,
        present: false,
        available: false,
        isCoverageWithLimit: false,
      };
      populateRecommendation(
        recommendation,
        coverages,
        telematicsEnrolled,
        telematicsRecommendation,
        selectedProducts,
        discounts,
        scheduledCategories
      );
      result.recommendations.push(recommendation);
    };
    switch (name) {
      case 'UpcomingProfessional':
        recommend('ValuablesPlus', 'Property');
        recommend('RoadsidePlus', 'Auto');
        recommend('Telematics', 'Auto');
        recommend('IdTheft', '');
        break;
      case 'FirstTimeHomeBuyer':
        // recommend('HomeMaintenanceJourneyGuide', 'Property');
        recommend('SmartHome', 'Property');
        recommend('ValuablesPlus', 'Property');
        recommend('RoadsidePlus', 'Auto');
        recommend('IdTheft', '');
        recommend('Umbrella', '');
        break;
      case 'TeenDriver':
        recommend('SmartHome', 'Property');
        recommend('ValuablesPlus', 'Property');
        recommend('RoadsidePlus', 'Auto');
        recommend('IdTheft', '');
        recommend('Umbrella', '');
        break;
      case 'EmptyNester':
        recommend('EquipmentBreakdown', 'Property');
        recommend('ServiceLine', 'Property');
        recommend('SmartHome', 'Property');
        recommend('ValuablesPlus', 'Property');
        recommend('WaterBackupLimited', 'Property');
        recommend('RoadsidePlus', 'Auto');
        recommend('Telematics', 'Auto');
        recommend('IdTheft', '');
        recommend('Umbrella', '');
        break;
      case 'DoItYourselfer':
        break;
    }
    return result;
  }
);
