import { createReducer, on, Action } from '@ngrx/store';
import { storeAnnouncementContent } from './announcement-content.action';

export interface AnnouncementContent {
  announcementDate: string;
  announcementItems: string[];
}

const initialState: AnnouncementContent = {
  announcementDate: '',
  announcementItems: [],
};

const contentfulReducer = createReducer(
  initialState,
  on(storeAnnouncementContent, (state, { payload }) => ({
    announcementDate: payload.announcementDate,
    announcementItems: [...payload.announcementItems],
  }))
);

export function reducer(
  state: AnnouncementContent | undefined,
  action: Action
): AnnouncementContent {
  return contentfulReducer(state, action);
}
