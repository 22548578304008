import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { scrollToFirstInvalidControl } from "@core/helper/form-hacks";
import { ProductType } from "@core/models/api/dsm-types";
import { ErrorSanitizerService } from "@core/services/error-sanitizer.service";
import { TaskService } from "@core/services/task.service";
import { clearErrors } from "@entities/error/error.action";
import { ErrorModel } from "@entities/error/error.model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { filterOutNull } from "@shared/rxjs/filter-out-null.operator";
import { from, Observable, Subject, tap } from "rxjs";
import { EligibilityFormModel } from "../store/eligibility-form.model";
import { EligibilityFormService } from "../store/eligibility-form.service";

@Component({
  templateUrl: './eligibility-modal-container.component.html',
  styleUrls: ['./eligibility-modal-container.component.scss'],
})
export class EligibilityModalContainerComponent implements OnChanges {
  @Input() quoteId!: string;
  @Input() productType!: ProductType;

  model$: Observable<EligibilityFormModel> = from([]);
  status: string = '';
  highlightAcknowledgementRequired = false;
  submitted = false;
  submitError: ErrorModel | null = null;
  private model: EligibilityFormModel | undefined = undefined;

  constructor(
    private eligibilityFormService: EligibilityFormService,
    private ngbActiveModal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef,
    private taskService: TaskService,
    private errorSanitizerService: ErrorSanitizerService,
    private store: Store,
    private element: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quoteId) {
      this.model$ = this.eligibilityFormService.getEligibilityFormByQuoteId(this.quoteId).pipe(
        tap(v => this.model = v),
        filterOutNull()
      );
    }
  }

  onValueChange(value: EligibilityFormModel): void {
    this.eligibilityFormService.updateEligibilityFormModel(value);
  }

  onStatusChange(status: string): void {
    this.status = status;
  }

  onSubmit(): void {
    this.store.dispatch(clearErrors());
    this.submitError = null;
    if (!this.isValid()) {
      this.highlightAcknowledgementRequired = true;
      this.submitted = true;
      this.changeDetector.markForCheck();
      return;
    }
    this.ngbActiveModal.close();
  }

  onDismiss(): void {
    this.ngbActiveModal.close();
  }

  private isValid(): boolean {
    return (
      this.status === 'VALID' &&
      !!this.model &&
      this.dogsAreGood(this.model)
    );
  }

  private dogsAreGood(model: EligibilityFormModel): boolean {
    if (model.riskItems?.haveDogs !== 'true') {
      return true;
    }
    // dogs array is allowed to be empty, but any present member must be filled in.
    if (model.riskItems.dogs?.find((dog) => !dog.dogBreed || !dog.biteHistory)) {
      return false;
    }
    return true;
  }
}
