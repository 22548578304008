import { LogService } from '@core/services/log.service';
import { from, MonoTypeOperatorFunction, of, throwError } from 'rxjs';
import { delay, retry, retryWhen, switchMap, tap } from 'rxjs/operators';

/* eslint-disable @typescript-eslint/no-explicit-any */

const MAX_RETRY_COUNT = 4;
let RETRY_DELAY_MS = 500;

/**
 * Replace the global retry timeout to basically not wait at all.
 * For use in unit tests, not in real code.
 */
export function TEST_ONLY_retryFast(): void {
  RETRY_DELAY_MS = 1;
}

function isRetryableDeveloperMessage(message: string | undefined): boolean {
  if (!message) {
    return false;
  }
  if (message.includes('Database bean version conflict')) {
    return true;
  }
  if (message.includes('PolicyPeriod')) {
    return true;
  }
  if (message.includes('One or more entities involved in your transaction')) {
    return true;
  }
  if (isEntityError(message)) {
    return true;
  }
  if (
    message.includes(
      'PolicyCenter encountered a duplicate key error during createQuote process for quoteId'
    )
  ) {
    return true;
  }
  if (message.includes('Input Policy number/Quote Id does not match')) {
    return true;
  }
  if (message.includes('Policy Term Premium cannot be blank')) {
    return true;
  }
  if (message.includes('due to a temporary issue with our system')) {
    return true;
  }
  return false;
}

function shouldRetry(error: any): boolean {
  if (!error) {
    return false;
  }
  if (typeof error !== 'object') {
    return false;
  }
  if (error.status === 503) {
    return true;
  }
  if (error.status === 504) {
    return true;
  }
  if (
    error.error &&
    isRetryableDeveloperMessage(error.error.developerMessage)
  ) {
    return true;
  }
  return false;
}

export function retryDsmErrors(
  logService?: LogService,
  serviceName?: string
): MonoTypeOperatorFunction<any> {
  return retry({
    count: MAX_RETRY_COUNT,
    delay: (error, retryCount) => {
      if (shouldRetry(error)) {
        logService?.logBusinessEvent('retry-dsm', serviceName || '');
        return of(RETRY_DELAY_MS);
      } else {
        if (error && typeof error === 'object') {
          return throwError(() => error);
        } else {
          return throwError(() => error);
        }
      }
    },
  });
}

export function isEntityError(message: string): boolean {
  const regex = new RegExp(/^error:entity\..*\/null$/); // starts with "error:entity." and ends with "/null"
  return regex.test(message);
}
