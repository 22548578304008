import { ObjectValidator } from '@core/helper/object-validator';
import { QuoteStatus } from '../dsm-types';

export interface PolicyNumberResponse {
  productId?: string;
  policyNumber: string;
  quoteId: string;
  quoteStatus?: QuoteStatus;
}

export function sanitizePolicyNumberResponse(
  input: unknown
): PolicyNumberResponse {
  return ObjectValidator.forceSchema<PolicyNumberResponse>(input, {
    productId: 'string',
    policyNumber: 'string',
    quoteId: 'string',
    quoteStatus: 'string',
  });
}
