import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { DisclaimersService } from '@core/services/disclaimers.service';
import { NavigationService } from '@core/services/navigation.service';
import { ProductsService } from '@core/services/products.service';
import { Page } from '@core/store/entities/navigation/navigation.action';
import { ProductModel } from '@core/store/entities/product/product.model';
import { PcUrlService } from '@shared/services/pc-url.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Nullable } from '@shared/utils/type.utils';
import { SessionService } from '@core/services/session.service';

@Component({
  selector: 'nwx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit, OnDestroy {
  @ViewChild('creditNotice') creditNoticeContent!: ElementRef;

  showPrivacyAddenda = false; // /home/start
  showDriversAddenda = false; // /quote/people if PersonalAuto
  showVehiclesAddenda = false; // /quote/vehicle
  showMsaVehiclesAddenda = false; // /quote/powersports/msa
  showCoveragesAddenda = false; // /quote/coverages
  showHubAddenda = false; // /quote/hub
  showRvVehiclesAddenda = false; // quote/rv
  showBoatVehiclesAddenda = false; // quote/boat

  policyCenterUrl = '';
  hasProperty = false;
  hasAuto = false;
  quoteState = '';

  private unsubscribe$ = new Subject<void>();
  private initialized = false;

  constructor(
    private modalService: NgbModal,
    private pcUrlService: PcUrlService,
    private productsService: ProductsService,
    private changeDetector: ChangeDetectorRef,
    private navigationService: NavigationService,
    private disclaimersService: DisclaimersService,
    private sessionService: SessionService
  ) {
    this.productsService
      .getSelectedProducts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((products) => this.receiveProducts(products));
    this.navigationService
      .getCurrentPage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((page) => this.receiveCurrentPage(page));
    this.disclaimersService
      .listenForCreditNoticeRequests()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.showCreditNotice(this.creditNoticeContent));
    this.sessionService
      .getQuoteState()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((quoteState) => (this.quoteState = quoteState));
  }

  ngOnInit(): void {
    this.initialized = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showCreditNotice(content: unknown, event?: Event): void {
    this.modalService.open(content, { windowClass: 'credit-notice' });
    event?.preventDefault();
  }

  isCA(): boolean {
    return this.quoteState === 'CA';
  }

  private receiveProducts(products: ProductModel[]): void {
    // Retrieves the first product with a quoteId, since we can't do all of them. (TODO can we?)
    const quoteId = products.find((p) => p.quoteId)?.quoteId || '';
    this.pcUrlService
      .getPolicyCenterUrl(quoteId, 'Job')
      .pipe(take(1))
      .subscribe((url) => {
        this.policyCenterUrl = url;
        if (this.initialized) {
          this.changeDetector.detectChanges();
        }
      });

    this.hasProperty = !!products.find(
      (product) =>
        product.type === 'Homeowner' ||
        product.type === 'Condominium' ||
        product.type === 'Tenant'
    );
    this.hasAuto = !!products.find(
      (product) => product.type === 'PersonalAuto'
    );
    if (this.initialized) {
      this.changeDetector.detectChanges();
    }
  }

  private receiveCurrentPage(page: Nullable<Page>): void {
    this.showPrivacyAddenda = false;
    this.showDriversAddenda = false;
    this.showVehiclesAddenda = false;
    this.showMsaVehiclesAddenda = false;
    this.showCoveragesAddenda = false;
    this.showHubAddenda = false;
    this.showBoatVehiclesAddenda = false;
    this.showRvVehiclesAddenda = false;
    switch (page?.id) {
      case 'StartQuote': {
        this.showPrivacyAddenda = true;
        break;
      }

      case 'People': {
        if (this.hasAuto) {
          this.showDriversAddenda = true;
        }
        break;
      }

      case 'Vehicles': {
        this.showVehiclesAddenda = true;
        break;
      }

      case 'MSA': {
        this.showMsaVehiclesAddenda = true;
        break;
      }

      case 'Boat': {
        this.showBoatVehiclesAddenda = true;
        break;
      }

      case 'RV': {
        this.showRvVehiclesAddenda = true;
        break;
      }

      case 'Coverages': {
        this.showCoveragesAddenda = true;
        break;
      }

      case 'HUB': {
        this.showHubAddenda = true;
        break;
      }
    }
    if (this.initialized) {
      this.changeDetector.detectChanges();
    }
  }
}
