import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MemberModel } from '@core/models/views/person.model';
import { DriverVehicleAssignmentService } from '@core/services/driver-vehicle-assignment.service';
import { FeatureFlagService } from '@core/services/feature-flag.service';
import { MemberService } from '@core/services/member.service';
import { VehicleService } from '@core/services/vehicle.service';
import { DriverVehicleAssignmentEntity } from '@core/store/entities/driver-vehicle-assignment/driver-vehicle-assignment.entity';
import { FeatureFlagsModel } from '@core/store/entities/feature-flag/feature-flag.model';
import { VehicleEntity } from '@core/store/entities/vehicle/vehicle.entity';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PersonUtils } from '@shared/utils/person.utils';
import {
  BehaviorSubject,
  combineLatest,
  map,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { take, tap } from 'rxjs/operators';
import {
  DvaDriverViewModel,
  DvaVehicleViewModel,
  DvaViewModel,
} from './dva-view.model';
import { ProductType } from '@core/models/api/dsm-types';

type AmbiguousPolicyCenterEntityId = number | string | null | undefined;

@Component({
  selector: 'nwx-dva-container',
  templateUrl: './dva-container.component.html',
  styleUrls: ['./dva-container.component.scss'],
})
export class DvaContainerComponent implements OnInit, OnDestroy {
  @Input() productType!: ProductType;

  dvaViewModel$ = new ReplaySubject<DvaViewModel>(1);
  submitInProgress$ = new BehaviorSubject(false);

  readonly defaultDvaViewModel: DvaViewModel = {
    drivers: [],
    showPrincipalDriver: false,
    checkboxStyle: 'checkbox',
    errorMessage: '',
    productType: 'PersonalAuto',
  };

  private unsubscribe$ = new Subject<void>();
  private dvaViewModel: DvaViewModel = this.defaultDvaViewModel;
  private storedDrivers: MemberModel[] = [];
  private storedVehicles: VehicleEntity[] = [];
  private storedAssignments: DriverVehicleAssignmentEntity[] = [];
  private modifiedAssignments: DriverVehicleAssignmentEntity[] = [];
  private storedFeatureFlags: FeatureFlagsModel = {};

  constructor(
    private vehicleService: VehicleService,
    private memberService: MemberService,
    private driverVehicleAssignmentService: DriverVehicleAssignmentService,
    private featureFlagService: FeatureFlagService,
    private modal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    /* Build the view model from store just once, when we start up.
     * Capture its inputs so we can redo this when the user changes something.
     */
    combineLatest([
      this.vehicleService.getSelectedVehiclesByProductType(this.productType),
      this.memberService.getAllSelectedDrivers(this.productType),
      this.driverVehicleAssignmentService.getAllDriverVehicleAssignments(
        this.productType
      ),
      this.featureFlagService.getAllFeatureFlags(),
    ])
      .pipe(
        take(1),
        tap(([vehicles, people, assignments, featureFlags]) => {
          this.storedVehicles = vehicles;
          this.storedDrivers = people;
          this.storedAssignments = assignments;
          this.modifiedAssignments = assignments.map((a) => ({ ...a }));
          this.storedFeatureFlags = featureFlags;
        }),
        map(([vehicles, people, assignments, featureFlags]) =>
          this.composeViewModel(vehicles, people, assignments, featureFlags)
        )
      )
      .subscribe((model) => {
        this.dvaViewModel = model;
        this.dvaViewModel$.next(model);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private composeViewModel(
    vehicles: VehicleEntity[],
    people: MemberModel[],
    assignments: DriverVehicleAssignmentEntity[],
    featureFlags: FeatureFlagsModel
  ): DvaViewModel {
    const model: DvaViewModel = {
      drivers: [],
      showPrincipalDriver: false,
      checkboxStyle: 'radio',
      errorMessage: '',
      productType: this.productType,
    };

    this.populateInitialModel(model, vehicles, people, assignments);

    /* When more vehicles than drivers, we switch from radio buttons to checkboxes, and disable ones selected by another driver.
     */
    if (vehicles.length > people.length) {
      model.checkboxStyle = 'checkbox';
      this.disableUnselectedVehiclesIfOccupied(model, assignments);
    }

    if (featureFlags.driverVehicleAssignmentIsPrimaryDriverRequired) {
      model.showPrincipalDriver = true;
    }

    return model;
  }

  /* Fills model with drivers and vehicles.
   * We produce the correct 'selection' and 'principalDriver', but leave 'enabled' true for all options.
   */
  private populateInitialModel(
    model: DvaViewModel,
    vehicles: VehicleEntity[],
    people: MemberModel[],
    assignments: DriverVehicleAssignmentEntity[]
  ): void {
    for (const person of people) {
      const driver: DvaDriverViewModel = {
        driverId: PersonUtils.driverIdFromEntity(person, this.productType),
        name: this.personNameForDisplay(person),
        vehicles: [],
      };
      if (!driver.driverId) {
        continue;
      }
      for (const vehicle of vehicles) {
        const outVehicle: DvaVehicleViewModel = {
          vehicleId: vehicle.vehicleId,
          name: this.vehicleNameForDisplay(vehicle),
          subtitle: this.vehicleSubtitleForDisplay(vehicle),
          enabled: true,
          selection: '',
          principalDriver: false,
        };
        const assignment = assignments.find((a) =>
          this.assignmentMatches(a, driver.driverId, vehicle.vehicleId)
        );
        if (assignment) {
          if (assignment.isPrimaryVehicle) {
            outVehicle.selection = 'primary';
          } else {
            outVehicle.selection = 'additional';
          }
          if (assignment.isPrimaryDriver) {
            outVehicle.principalDriver = true;
          }
        }
        driver.vehicles.push(outVehicle);
      }
      model.drivers.push(driver);
    }
  }

  // Any vehicle already assigned is disabled for other drivers.
  private disableUnselectedVehiclesIfOccupied(
    model: DvaViewModel,
    assignments: DriverVehicleAssignmentEntity[]
  ): void {
    for (const assignment of assignments) {
      this.disableVehicleForAllDrivers(model, assignment.vehicleId);
      this.enableVehicleForOneDriver(
        model,
        assignment.vehicleId,
        assignment.driverId
      );
    }
  }

  private disableVehicleForAllDrivers(
    model: DvaViewModel,
    vehicleId: AmbiguousPolicyCenterEntityId
  ): void {
    for (const driver of model.drivers) {
      for (const vehicle of driver.vehicles) {
        if (this.equalishIds(vehicle.vehicleId, vehicleId)) {
          vehicle.enabled = false;
        }
      }
    }
  }

  private enableVehicleForOneDriver(
    model: DvaViewModel,
    vehicleId: AmbiguousPolicyCenterEntityId,
    driverId: AmbiguousPolicyCenterEntityId
  ): void {
    const driver = model.drivers.find((d) =>
      this.equalishIds(d.driverId, driverId)
    );
    if (driver) {
      const vehicle = driver.vehicles.find((v) =>
        this.equalishIds(v.vehicleId, vehicleId)
      );
      if (vehicle) {
        vehicle.enabled = true;
      }
    }
  }

  private personNameForDisplay(person: MemberModel): string {
    if (person.person) {
      return (
        (person.person.firstName || '') + ' ' + (person.person.lastName || '')
      );
    }
    return '';
  }

  private vehicleNameForDisplay(vehicle: VehicleEntity): string {
    return `${vehicle.year || ''} ${vehicle.make || ''} ${vehicle.model || ''}`;
  }

  private vehicleSubtitleForDisplay(vehicle: VehicleEntity): string {
    return vehicle.series || '';
  }

  private assignmentMatches(
    assignment: DriverVehicleAssignmentEntity,
    driverId: AmbiguousPolicyCenterEntityId,
    vehicleId: AmbiguousPolicyCenterEntityId
  ): boolean {
    return (
      this.equalishIds(assignment.driverId, driverId) &&
      this.equalishIds(assignment.vehicleId, vehicleId)
    );
  }

  private assignmentsEquivalent(
    a: DriverVehicleAssignmentEntity,
    b: DriverVehicleAssignmentEntity
  ): boolean {
    if (a === b || (!a && !b)) {
      return true;
    }
    if (!a || !b) {
      return false;
    }
    return (
      this.equalishIds(a.driverId, b.driverId) &&
      this.equalishIds(a.vehicleId, b.vehicleId) &&
      !!a.isPrimaryDriver === !!b.isPrimaryDriver &&
      !!a.isPrimaryVehicle === !!b.isPrimaryVehicle
    );
  }

  private equalishIds(
    a: AmbiguousPolicyCenterEntityId,
    b: AmbiguousPolicyCenterEntityId
  ): boolean {
    const as = (a || '').toString();
    const bs = (b || '').toString();
    return as === bs;
  }

  private forcePrimaryVehicleAssignmentIfUnset(
    inputs: DriverVehicleAssignmentEntity[],
    driverId: number
  ): DriverVehicleAssignmentEntity[] {
    let candidateIndex = -1;
    for (let i = 0; i < inputs.length; i++) {
      const assignment = inputs[i];
      if (this.equalishIds(assignment.driverId, driverId)) {
        if (assignment.isPrimaryVehicle) {
          return inputs;
        }
        if (candidateIndex < 0) {
          candidateIndex = i;
        }
      }
    }
    if (candidateIndex < 0) {
      return inputs;
    }
    const outputs: DriverVehicleAssignmentEntity[] = [];
    for (let i = 0; i < candidateIndex; i++) {
      outputs.push(inputs[i]);
    }
    outputs.push({
      ...inputs[candidateIndex],
      isPrimaryVehicle: true,
    });
    for (let i = candidateIndex + 1; i < inputs.length; i++) {
      outputs.push(inputs[i]);
    }
    return outputs;
  }

  private forcePrimaryDriverAssignments(
    inputs: DriverVehicleAssignmentEntity[]
  ): DriverVehicleAssignmentEntity[] {
    const vehicleIds = [...new Set(inputs.map((a) => a.vehicleId))];
    for (const vehicleId of vehicleIds) {
      const assignmentsForVehicle: DriverVehicleAssignmentEntity[] = [];
      let primaryDriverCount = 0;
      for (const assignment of inputs) {
        if (this.equalishIds(assignment.vehicleId, vehicleId)) {
          assignmentsForVehicle.push(assignment);
          if (assignment.isPrimaryDriver) {
            primaryDriverCount++;
          }
        }
      }
      if (assignmentsForVehicle.length > 0 && primaryDriverCount !== 1) {
        const driverId = this.pickDefaultPrimaryDriverForVehicle(
          assignmentsForVehicle
        );
        inputs = inputs.map((assignment) => {
          if (this.equalishIds(assignment.vehicleId, vehicleId)) {
            if (this.equalishIds(assignment.driverId, driverId)) {
              return { ...assignment, isPrimaryDriver: true };
            }
            return { ...assignment, isPrimaryDriver: false };
          }
          return assignment;
        });
      }
    }
    return inputs;
  }

  // Given a set of assignments for the same vehicle, pick one driverId to make the primary driver.
  private pickDefaultPrimaryDriverForVehicle(
    assignments: DriverVehicleAssignmentEntity[]
  ): number {
    const vehicleId = assignments[0].vehicleId;

    // If at least one primary driver already exists for this vehicle, take the lowest matching driverId.
    const existingPrimaryDriverIdsForVehicle = this.storedAssignments
      .filter((a) => {
        return a.isPrimaryDriver && this.equalishIds(a.vehicleId, vehicleId);
      })
      .map((a) => a.driverId);
    if (existingPrimaryDriverIdsForVehicle.length > 0) {
      existingPrimaryDriverIdsForVehicle.sort();
      return existingPrimaryDriverIdsForVehicle[0];
    }

    // Not sure any further logic is warranted, though one can imagine other criteria.
    // Whatever. Use the lowest available driverId.
    const availableDriverIds = assignments.map((a) => a.driverId);
    availableDriverIds.sort();
    return availableDriverIds[0];
  }

  onToggle({
    driverId,
    vehicleId,
  }: {
    driverId: number;
    vehicleId: string;
  }): void {
    // Quietly reject if the UI is disabled or missing.
    const viewDriver = this.dvaViewModel.drivers.find((d) =>
      this.equalishIds(d.driverId, driverId)
    );
    const viewVehicle = viewDriver?.vehicles.find((v) =>
      this.equalishIds(v.vehicleId, vehicleId)
    );
    if (!viewVehicle?.enabled) {
      return;
    }

    // Remove the assignment if it exists.
    let assignmentIndex = this.modifiedAssignments.findIndex((a) =>
      this.assignmentMatches(a, driverId, vehicleId)
    );
    if (assignmentIndex >= 0) {
      this.modifiedAssignments.splice(assignmentIndex, 1);
      this.modifiedAssignments = this.forcePrimaryVehicleAssignmentIfUnset(
        this.modifiedAssignments,
        driverId
      );

      // Assignment doesn't exist; add it.
    } else {
      // When in radio mode, remove all other assignments for this driver before proceeding. One selection at a time.
      if (this.dvaViewModel.checkboxStyle === 'radio') {
        this.modifiedAssignments = this.modifiedAssignments.filter(
          (a) => !this.equalishIds(a.driverId, viewDriver!.driverId)
        );
      }

      let isPrimaryDriver = viewVehicle.principalDriver;
      if (this.dvaViewModel.showPrincipalDriver) {
        // We're asking about primaryDriver. Default true when adding a vehicle, if the vehicle is not already selected by anyone.
        // It's important that we apply this default only when adding, and only to this one vehicle.
        isPrimaryDriver = !this.modifiedAssignments.find((a) =>
          this.equalishIds(a.vehicleId, vehicleId)
        );
      }
      const isPrimaryVehicle = !this.modifiedAssignments.find((a) =>
        this.equalishIds(a.driverId, driverId)
      );
      const productType = this.productType;
      this.modifiedAssignments.push({
        driverId,
        vehicleId,
        isPrimaryDriver,
        isPrimaryVehicle,
        productType,
      });
    }

    // If we're not asking the user for isPrimaryDriver, then we need to force it valid.
    if (!this.dvaViewModel.showPrincipalDriver) {
      this.modifiedAssignments = this.forcePrimaryDriverAssignments(
        this.modifiedAssignments
      );
    }

    // Update view model.
    this.dvaViewModel = this.composeViewModel(
      this.storedVehicles,
      this.storedDrivers,
      this.modifiedAssignments,
      this.storedFeatureFlags
    );
    this.dvaViewModel$.next(this.dvaViewModel);
  }

  onPrincipalDriverChanged({
    driverId,
    vehicleId,
    principalDriver,
  }: {
    driverId: number;
    vehicleId: string;
    principalDriver: boolean;
  }): void {
    // This comes from a checkbox with its own state.
    // So don't feed the change back to our child. Just update our own state.
    const assignment = this.modifiedAssignments.find((a) =>
      this.assignmentMatches(a, driverId, vehicleId)
    );
    if (assignment) {
      assignment.isPrimaryDriver = principalDriver;
    }
    const viewDriver = this.dvaViewModel.drivers.find((d) =>
      this.equalishIds(d.driverId, driverId)
    );
    if (viewDriver) {
      const viewVehicle = viewDriver.vehicles.find((v) =>
        this.equalishIds(v.vehicleId, vehicleId)
      );
      if (viewVehicle) {
        viewVehicle.principalDriver = true;
      }
    }
  }

  onSubmit(): void {
    if (this.submitInProgress$.getValue()) {
      return;
    }
    const status = this.validate();

    if (status === 'clean') {
      this.modal.close();
    } else if (status === 'valid') {
      this.dvaViewModel.errorMessage = '';
      this.dvaViewModel$.next(this.dvaViewModel);
      this.submitInProgress$.next(true);
      this.driverVehicleAssignmentService
        .sendAssignmentsAndAwaitResult(
          this.modifiedAssignments,
          this.productType
        )
        .then(() => {
          this.modal.close();
        })
        .catch((e) => {
          this.dvaViewModel.errorMessage = `Failed to update assignments: ${
            e?.message || 'Unknown error'
          }`;
          this.dvaViewModel$.next(this.dvaViewModel);
        })
        .finally(() => {
          this.submitInProgress$.next(false);
        });
    } else {
      this.dvaViewModel.errorMessage = status;
      this.dvaViewModel$.next(this.dvaViewModel);
    }
  }

  onCancel(): void {
    this.modal.close();
  }

  /* Compare modifiedAssignments against the "stored" things.
   * Return "clean" if no changes were made. We can dismiss without taking any action.
   * Return "valid" if assignments are changed and should be committed to PolicyCenter.
   * Any other result is an error message for display to the user.
   */
  private validate(): 'clean' | 'valid' | string {
    // Check for drivers without vehicles.
    for (const driver of this.storedDrivers) {
      const driverId = PersonUtils.driverIdFromEntity(driver, this.productType);
      if (
        !this.modifiedAssignments.find((a) =>
          this.equalishIds(a.driverId, driverId)
        )
      ) {
        return (
          `${this.personNameForDisplay(
            driver
          )} has not been assigned to a vehicle. ` +
          `Please assign everyone as either a primary or additional driver on a vehicle.`
        );
      }
    }

    // Check for vehicles without drivers.
    for (const vehicle of this.storedVehicles) {
      if (
        !this.modifiedAssignments.find((a) =>
          this.equalishIds(a.vehicleId, vehicle.vehicleId)
        )
      ) {
        return (
          `${this.vehicleNameForDisplay(
            vehicle
          )} has not been assigned to a driver. ` +
          `Please assign every vehicle.`
        );
      }
    }

    // Check primary driver if required.
    if (
      this.storedFeatureFlags.driverVehicleAssignmentIsPrimaryDriverRequired
    ) {
      for (const vehicle of this.storedVehicles) {
        if (
          !this.modifiedAssignments.find(
            (a) =>
              a.isPrimaryDriver &&
              this.equalishIds(a.vehicleId, vehicle.vehicleId)
          )
        ) {
          return `Please select a primary driver for ${this.vehicleNameForDisplay(
            vehicle
          )}`;
        }
      }
    }

    // OK it's valid. Now check for dirty.
    // "stored" and "modified" assignments must match. Don't assume that they're in the same order.
    if (this.storedAssignments.length !== this.modifiedAssignments.length) {
      return 'valid';
    }
    for (const stored of this.storedAssignments) {
      if (
        !this.modifiedAssignments.find((a) =>
          this.assignmentsEquivalent(stored, a)
        )
      ) {
        return 'valid';
      }
    }

    return 'clean';
  }
}
