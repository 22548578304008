import { InquiryPath } from '@core/adapters/powersports.adapter';
import { ProductType } from '@core/models/api/dsm-types';
import { createAction, props } from '@ngrx/store';
import { PicklistItem } from '@shared/models/picklist.model';

export const powersportsRetrieveAndStoreYears = createAction(
  '[Powersports Vehicle Inquiry] Retrieve And Store Years',
  props<{
    inquiryPath: InquiryPath;
    correlationId: string;
  }>()
);

export const powersportsSetVehicleInquiryYears = createAction(
  '[Powersports Vehicle Inquiry] Set Years',
  props<{
    response: PicklistItem[];
    productType: ProductType;
    correlationId?: string;
  }>()
);

export const powersportsSetVehicleInquiryYearsError = createAction(
  '[Powersports Vehicle Inquiry] Set Years Error',
  props<{ productType: ProductType; correlationId?: string }>()
);

export const powersportsSetPlaceholderVehicleInquiryYears = createAction(
  '[Powersports Vehicle Inquiry] Set Placeholder Years',
  props<{ productType: ProductType }>()
);

export const powersportsDispatchSetVehicleInquiryMakes = createAction(
  '[Powersports Vehicle Inquiry] Dispatch Set Makes',
  props<{
    year: number;
    correlationId: string;
    inquiryPath: InquiryPath;
  }>()
);

export const powersportsSetVehicleInquiryMakes = createAction(
  '[Powersports Vehicle Inquiry] Set Makes',
  props<{
    year: number;
    response: PicklistItem[];
    productType: ProductType;
    correlationId?: string;
  }>()
);

export const powersportsSetVehicleInquiryMakesError = createAction(
  '[Powersports Vehicle Inquiry] Set Vehicle Inquiry Makes Error'
);

export const powersportsRemoveVehicleInquiryMakes = createAction(
  '[Powersports Vehicle Inquiry] Remove Makes',
  props<{ year: number; productType: ProductType }>()
);

export const powersportsDispatchSetVehicleInquiryModels = createAction(
  '[Powersports Vehicle Inquiry] Dispatch Set Vehicle Inquiry Models',
  props<{
    year: number;
    make: string;
    path: InquiryPath;
    productType: ProductType;
    correlationId: string;
  }>()
);
export const powersportsSetVehicleInquiryModels = createAction(
  '[Powersports Vehicle Inquiry] Set Models',
  props<{
    year: number;
    make: string;
    response: PicklistItem[];
    productType: ProductType;
    correlationId?: string;
  }>()
);

export const powersportsSetVehicleInquiryModelsError = createAction(
  '[Powersports Vehicle Inquiry] Set Vehicle Inquiry Models Error',
  props<{ correlationId: string }>()
);

export const powersportsRemoveVehicleInquiryModels = createAction(
  '[Powersports Vehicle Inquiry] Remove Models',
  props<{ year: number; make: string; productType: ProductType }>()
);
