import { Pipe, PipeTransform } from '@angular/core';
import { GeneralUtils } from '@shared/utils/general.utils';

@Pipe({ name: 'alphabetizeObjectsByAttribute' })
export class AlphabetizeObjectsPipe implements PipeTransform {
  constructor() {}
  /**
   * Sorts an array of objects alphabetically, by the first character in a specified attribute of the array
   * @param arr array of objects to sort alphabetically
   * @param keyAttribute the attribute to base on for when alphabetizing
   */
  transform(arr: any[], keyAttribute: string) {
    return arr.sort((a, b) => {
      const aAttribute = GeneralUtils.findAttributeValue(a, keyAttribute);
      const bAttribute = GeneralUtils.findAttributeValue(b, keyAttribute);

      if (typeof aAttribute !== 'string' || typeof bAttribute !== 'string') {
        return 0;
      } else {
        if (aAttribute.toLowerCase() < bAttribute.toLowerCase()) {
          return -1;
        }
        if (aAttribute.toLowerCase() > bAttribute.toLowerCase()) {
          return 1;
        }
        return 0;
      }
    });
  }
}
