import { HttpResponseSanitizer } from "@core/adapters/dsm.adapter";

export type BasicObject = {
  [key: string]: unknown,
};

export type ObjectValidatorSchemaField =
  'any' |
  'string' |
  'number' |
  'boolean' |
  [ObjectValidatorSchema<unknown>] |
  ((input: any) => any) |
  ObjectValidatorSchema<unknown>;

export type ObjectValidatorSchema<T> = {
  [key in keyof T]: ObjectValidatorSchemaField;
};

/**
 * Helper with static methods for validating live objects, eg HTTP responses.
 */
export class ObjectValidator {
  /**
   * Assert or coerce fields named in schema.
   * Extra fields in input are preserved verbatim.
   * Prefer this over other ObjectValidator facilities; it lets the validation look a lot like the type definition.
   */
  static forceSchema<T>(
    input: unknown,
    schema: ObjectValidatorSchema<T>,
    optionalKeys: string[] = []
  ): T {
    if (!input || typeof input !== 'object' || input instanceof Array) {
      throw new Error(`Expected object`);
    }
    let inputAsObject = input as BasicObject;
    for (const key of Object.keys(
      schema
    ) as (keyof ObjectValidatorSchema<T>)[]) {
      if (!inputAsObject.hasOwnProperty(key)) {
        if (optionalKeys.includes(key as string)) {
          continue;
        }
        inputAsObject = {
          ...inputAsObject,
          [key]: this.defaultValueFromSchema(schema[key], key as string),
        };
      } else if (
        this.compareValueToSchema(
          inputAsObject[key as string],
          schema[key],
          key as string
        )
      ) {
        // all good
      } else {
        inputAsObject = {
          ...inputAsObject,
          [key]: this.coerceValueToSchema(
            inputAsObject[key as string],
            schema[key],
            key as string
          ),
        };
      }
    }
    return inputAsObject as T;
  }

  /**
   * Apply a sanitizer to each element of an array.
   */
  static sanitizeArray<T>(
    input: unknown,
    sanitize: HttpResponseSanitizer<T>
  ): T[] {
    if (!input) {
      return [];
    }
    if (!(input instanceof Array)) {
      throw new Error(`Expected array`);
    }
    const output: T[] = [];
    for (const inputObject of input) {
      output.push(sanitize(inputObject));
    }
    return output;
  }

  /* Return a default value matching this schema value, if the field was absent from input.
   */
  static defaultValueFromSchema(
    schemaValue: ObjectValidatorSchemaField,
    key: string
  ): any {
    switch (schemaValue) {
      case 'any':
        return null;
      case 'string':
        return '';
      case 'number':
        return 0;
      case 'boolean':
        return false;
    }
    if (schemaValue instanceof Array) {
      return [];
    }
    if (typeof schemaValue === 'function') {
      return schemaValue(null);
    }
    throw new Error(`Missing required field ${JSON.stringify(key)}`);
  }

  /* True if currentValue satisfies this schema value.
   */
  static compareValueToSchema(
    currentValue: any,
    schemaValue: ObjectValidatorSchemaField,
    key: string
  ): boolean {
    switch (schemaValue) {
      case 'any':
        return true;
      case 'string':
        return typeof currentValue === 'string';
      case 'number':
        return typeof currentValue === 'number';
      case 'boolean':
        return typeof currentValue === 'boolean';
    }
    // Array, function, and object all entail some recursive work, we can't just say yes.
    return false;
  }

  static coerceValueToSchema(
    currentValue: any,
    schemaValue: ObjectValidatorSchemaField,
    key: string
  ): any {
    switch (schemaValue) {
      case 'any':
        return currentValue;
      case 'string':
        return currentValue?.toString?.() || '';
      case 'number': {
        if (typeof currentValue === 'number') {
          return currentValue;
        }
        const asNumber = parseInt(currentValue);
        if (isNaN(asNumber)) {
          throw new Error(`Expected number for field ${JSON.stringify(key)}`);
        }
        return asNumber;
      }
      case 'boolean': {
        if (typeof currentValue === 'boolean') {
          return currentValue;
        }
        if (!currentValue) {
          return false;
        }
        if (typeof currentValue === 'number') {
          return true;
        }
        if (currentValue === 'true') return true;
        if (currentValue === 'false') return false;
        throw new Error(`Expected boolean for field ${JSON.stringify(key)}`);
      }
    }
    if (schemaValue instanceof Array) {
      if (!currentValue) {
        return [];
      }
      if (!(currentValue instanceof Array)) {
        throw new Error(`Expected array for field ${JSON.stringify(key)}`);
      }
      if (schemaValue.length !== 1) {
        throw new Error(
          `Schema arrays must have a single member. Field ${JSON.stringify(
            key
          )}`
        );
      }
      if (typeof schemaValue[0] === 'string') {
        return currentValue.map((v) =>
          this.coerceValueToSchema(
            v,
            schemaValue[0] as ObjectValidatorSchemaField,
            key
          )
        );
      }
      if (typeof schemaValue[0] === 'function') {
        const coerce = schemaValue[0] as HttpResponseSanitizer<unknown>;
        return this.sanitizeArray(currentValue, coerce);
      }
      return currentValue.map((v) => this.forceSchema(v, schemaValue[0]));
    }
    if (typeof schemaValue === 'function') {
      return schemaValue(currentValue);
    }
    if (typeof schemaValue === 'object') {
      return this.forceSchema(currentValue, schemaValue);
    }
    throw new Error(`Unexpected value for field ${JSON.stringify(key)}`);
  }
}
