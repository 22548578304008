import { MortgageEntity } from '@core/store/entities/mortgage/mortgage.reducer';
import { BaseRequest } from './base-request.model';
import { ObjectValidator } from '@core/helper/object-validator';
import { sanitizeBasicAddressEntity } from '@entities/address/address.entity';
import { sanitizePersonEntity } from '@core/models/entities/person.entity';

export interface MortgageRequest extends BaseRequest {
  mortgage: MortgageEntity;
}

export function sanitizeMortgageEntities(response: unknown): MortgageEntity[] {
  return ObjectValidator.sanitizeArray(response, sanitizeMortgageEntity);
}

export function sanitizeMortgageEntity(response: unknown): MortgageEntity {
  return ObjectValidator.forceSchema(
    response,
    {
      address: sanitizeBasicAddressEntity,
      companyId: 'string',
      companyName: 'string',
      company: 'string',
      escrow: 'boolean',
      loanNumber: 'string',
      mortgageId: 'string',
      mortgageType: 'string',
      type: 'string',
      person: sanitizePersonEntity,
      additionalInsuredType: 'string',
    },
    [
      'address',
      'companyId',
      'companyName',
      'company',
      'escrow',
      'loanNumber',
      'mortgageId',
      'mortgageType',
      'type',
      'person',
      'additionalInsuredType',
    ]
  ) as MortgageEntity;
}
