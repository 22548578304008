import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[nwxMask]',
})
export class MaskDirective {
  @Input('nwxMask') name!:
    | 'dateOfBirth'
    | 'phone'
    | 'creditcard'
    | 'monthAndYear'
    | 'year'
    | 'accountNumber'
    | 'verifyAccountNumber'
    | 'routingNumber'
    | 'cardExpDate'
    | 'cvv'
    | 'priorCarrier';

  readonly UNMASKED_DIGITS_LENGTH = 4;

  constructor(public ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInput(event: InputEvent): void {
    const target = event.target as HTMLInputElement;
    this.onValueChange(target.value?.toString?.() || '');
  }

  @HostListener('blur', ['$event']) onBlur(event: InputEvent): void {
    const target = event.target as HTMLInputElement;
    this.onElementBlur(target.value?.toString?.() || '');
  }

  private onValueChange(value: string): void {
    let newVal = value.replace(/\D/g, '');
    switch (this.name) {
      case 'dateOfBirth': {
        if (newVal.length === 0) {
          newVal = '';
        } else if (newVal.length <= 2) {
          newVal = newVal.replace(/^(\d{0,2})/, '$1');
        } else if (newVal.length <= 4) {
          newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
        } else if (newVal.length <= 8) {
          newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
        } else {
          newVal = newVal.substring(0, 8);
          newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
        }
        this.ngControl.control?.setValue(newVal);
        break;
      }
      case 'monthAndYear': {
        if (newVal.length === 0) {
          newVal = '';
        } else if (newVal.length <= 2) {
          newVal = newVal.replace(/^(\d{0,2})/, '$1');
        } else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,2})(\d{0,3})/, '$1/$2');
        } else {
          newVal = newVal.substring(0, 6);
          newVal = newVal.replace(/^(\d{0,2})(\d{0,3})/, '$1/$2');
        }
        this.ngControl.control?.setValue(newVal);
        break;
      }
      case 'year': {
        newVal = newVal.substring(0, 4);
        this.ngControl.control?.setValue(newVal);
        break;
      }
      case 'phone': {
        if (newVal.length === 0) {
          newVal = '';
        } else if (newVal.length <= 3) {
          newVal = newVal.replace(/^(\d{0,3})/, '$1');
        } else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
        } else if (newVal.length <= 10) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
        } else {
          newVal = newVal.substring(0, 10);
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
        }
        this.ngControl.control?.setValue(newVal);
        break;
      }
      case 'creditcard': {
        newVal = newVal.substring(0, 16);
        this.ngControl.control?.setValue(newVal);
        break;
      }
      case 'accountNumber':
      case 'verifyAccountNumber': {
        newVal = newVal.substring(0, 17);
        this.ngControl.control?.setValue(newVal);
        break;
      }
      case 'routingNumber': {
        newVal = newVal.substring(0, 9);
        this.ngControl.control?.setValue(newVal);
        break;
      }
      case 'cardExpDate': {
        if (newVal.length === 0) {
          newVal = '';
        } else if (newVal.length <= 2) {
          newVal = newVal.replace(/^(\d{0,2})/, '$1');
        } else if (newVal.length <= 4) {
          newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
        } else {
          newVal = newVal.substring(0, 4);
          newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
        }
        this.ngControl.control?.setValue(newVal);
        break;
      }
      case 'cvv': {
        newVal = newVal.substring(0, 4);
        this.ngControl.control?.setValue(newVal);
        break;
      }
      case 'priorCarrier': {
        newVal = newVal.substring(0, 2);
        this.ngControl.control?.setValue(newVal);
        break;
      }
    }
  }

  private onElementBlur(value: string): void {
    let newVal = value.replace(/\D/g, '');
    switch (this.name) {
      case 'accountNumber':
      case 'verifyAccountNumber':
      case 'creditcard': {
        if (newVal.length > this.UNMASKED_DIGITS_LENGTH) {
          newVal =
            this.maskOfLength(newVal.length - this.UNMASKED_DIGITS_LENGTH) +
            newVal.substring(newVal.length - this.UNMASKED_DIGITS_LENGTH);
          this.ngControl.valueAccessor?.writeValue(newVal);
        }
        break;
      }
      case 'cvv': {
        newVal = this.maskOfLength(newVal.length);
        this.ngControl.valueAccessor?.writeValue(newVal);
        break;
      }
    }
  }

  private maskOfLength(num: number): string {
    if(num < 1) return '';
    return '•'.repeat(num);
  }
}
