<div class="bolt-container-fluid no-pad">
  <div class="bolt-row bg-pale">
    <ng-container *ngFor="let product of coverageDisplayProducts">
      <div class="bolt-col-xl-6 bolt-col-lg-12 pt-20">
        <hub-coverages-selected-card
          id="hubPolicySummaryCard-{{ product.model.id }}"
          [model]="product.model"
          [formComponentType]="product.componentType"
        ></hub-coverages-selected-card>
      </div>
    </ng-container>
  </div>
</div>
