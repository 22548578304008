import { createSelector } from '@ngrx/store';
import { DriverEntity } from './driver.entity';
import * as fromProducts from '@app/core/store/entities/product/product.selectors';
import { ProductType } from '@app/core/models/api/dsm-types';
import { getAllFeatureFlags } from '../feature-flag/feature-flag.selector';
import { BuildDriverRequestUtil } from '@shared/utils/builders/drivers/build-driver-request';
import { getQuoteState } from '../session/session.selector';
import { ProductModel } from '../product/product.model';

export const buildDriverRequest = (
  driver: DriverEntity,
  correlationId?: string
) =>
  createSelector(
    fromProducts.getProduct(driver.productType as ProductType),
    getAllFeatureFlags,
    getQuoteState,
    (product, featureFlags, quoteState) =>
      BuildDriverRequestUtil.buildUpsertDriverRequest(
        driver,
        product as ProductModel,
        quoteState,
        featureFlags,
        correlationId || ''
      )
  );
