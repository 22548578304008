import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, from } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import * as fromActions from './coverage.action';
import * as fromCoverageMessageActions from '../coverage-message/coverage-message.action';
import * as fromFormCoverageActions from '@app/forms-store/store/entities/coverage/coverage.action';
import { QuoteAdapter } from '@core/adapters/quote.adapter';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { Action, Store } from '@ngrx/store';
import { CoreState } from '../../reducers';
import { CoveragesRequest } from '@core/models/api/request/coverages-request.model';
import {
  CoverageChange,
  CoverageEntity,
  CoverageInformationalMessage,
  CoverageMessage,
} from './coverage.entity';
import { CoverageRulesService } from '@core/services/coverage-rules.service';
import { VehicleEntity } from '../vehicle/vehicle.entity';
import { CoverageFormService } from '@app/forms-store/services/coverage-form.service';
import { ProductType } from '@core/models/api/dsm-types';
import { FeatureFlagsModel } from '../feature-flag/feature-flag.model';
import { getAllFeatureFlags } from '../feature-flag/feature-flag.selector';
import { isAnyTelematicsEnrolled } from '../telematics/telematics.selector';
import { removeTelematicsEnrollment } from '../telematics/telematics.action';
import { AFMVF_SMARTRIDE_NOTICE_VERBIAGE } from '@shared/constants/app-constants';
import { LogService } from '@core/services/log.service';
import { ProductsSelectors, CoverageSelectors } from '@core/store/selectors';
import { CoveredLocationEntity } from '@entities/covered-location/covered-location.reducer';

@Injectable()
export class CoverageEffects {
  constructor(
    private actions$: Actions,
    private store: Store<CoreState>,
    private quoteAdapter: QuoteAdapter,
    private errorSanitizerService: ErrorSanitizerService,
    private logService: LogService,
    private coverageRulesService: CoverageRulesService,
    private coverageFormService: CoverageFormService
  ) {}

  loadAllCoverages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAllCoverages),
      switchMap(() =>
        this.store.select(ProductsSelectors.getSelectedProducts).pipe(take(1))
      ),
      mergeMap((products) => {
        const nextActions: Action[] = [];
        products.map((product) => {
          nextActions.push(
            fromActions.getAllCoverages({
              payload: {
                productModelSync: true,
                productType: product.type,
                quoteId: product.quoteId,
              },
            })
          );
        });
        return from(nextActions);
      })
    )
  );

  getAllCoverages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllCoverages),
      map((action) => action.payload),
      mergeMap((request: CoveragesRequest) => {
        return this.quoteAdapter.retrieveAllCoverages(request).pipe(
          switchMap((response) => {
            return from([
              fromActions.getAllCoveragesSuccess({ payload: response }),
              fromActions.runCoverageRules({ payload: response }),
            ]);
          }),
          catchError((error) => {
            const safeError = this.errorSanitizerService.sanitizeError(
              error,
              request.productType
            );
            return from([
              fromActions.getAllCoveragesFail({
                error: {
                  ...safeError,
                  productType: request.productType as ProductType,
                },
              }),
            ]);
          })
        );
      })
    )
  );

  executeCoverageRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.runCoverageRules),
      switchMap((action) =>
        this.store
          .select(CoverageSelectors.getCoverageRuleData(action.payload))
          .pipe(take(1))
      ),
      switchMap(
        (data: {
          coverages: CoverageEntity[];
          quoteState: string;
          productId: ProductType;
          featureFlags: FeatureFlagsModel;
          vehicles?: VehicleEntity[];
          coveredLocations?: CoveredLocationEntity[];
          previousCoverages?: CoverageEntity[];
          isCompRater?: boolean;
        }) => {
          try {
            const updatedCoverages =
              this.coverageRulesService.initCoverageRules(
                data.coverages,
                data.quoteState,
                data.featureFlags,
                data.vehicles,
                data.coveredLocations,
                data.previousCoverages,
                data.isCompRater
              );
            const coverageModel =
              this.coverageFormService.buildFormCoverageModel(updatedCoverages);
            return from([
              fromFormCoverageActions.addFormCoverage({
                productType: updatedCoverages[0].productId as ProductType,
                payload: coverageModel,
              }),
              fromActions.deleteCoveragesByProduct({
                payload: updatedCoverages[0].productId as ProductType,
              }),
              fromActions.addManyCoverages({ payload: updatedCoverages }),
            ]);
          } catch (error) {
            const safeError = this.errorSanitizerService.sanitizeError(
              error,
              data.productId
            );
            return from([
              fromActions.getAllCoveragesFail({ error: safeError }),
            ]);
          }
        }
      )
    )
  );

  logCoverageTaskMessages$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromCoverageMessageActions.addCoverageInformationalMessage),
        tap((action) => this.logCoverageMessages(action.payload))
      ),
    { dispatch: false }
  );

  updateCoverage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateCoverage),
      map((action) => action.payload),
      mergeMap((payload) =>
        this.store
          .select(
            ProductsSelectors.getProduct(payload[0].productId as ProductType)
          )
          .pipe(
            map((product) => ({
              coverages: payload,
              productType: product?.type,
              quoteId: product?.quoteId,
            })),
            take(1)
          )
      ),
      mergeMap((request) => {
        return combineLatest([
          this.quoteAdapter.updateCoverages(request),
          this.store.select(getAllFeatureFlags),
          this.store.select(isAnyTelematicsEnrolled),
        ]).pipe(
          take(1),
          switchMap(([response, featureFlags, telematicsEnrolled]) => {
            const coverages = response.coverages.map((cvg: CoverageEntity) => ({
              ...cvg,
              version: 'CURRENT', // TODO: allow coverage version to be passed as a param to this action
            }));
            const nextActions: Action[] = [
              fromActions.deleteCoveragesByProduct({
                payload: request.productType as ProductType,
              }),
              fromActions.runCoverageRules({ payload: coverages }),
              fromActions.updateCoverageSuccess({
                payload: coverages,
                updatedCoverage: request.coverages,
              }),
              fromCoverageMessageActions.removeProductCoverageInformationalMessage(
                { id: request.productType as ProductType }
              ),
            ];
            if (featureFlags.smartrideAcceptAFMVF) {
              if (
                !(
                  this.coverageIsSelected(request.coverages, 'AF') &&
                  this.coverageIsSelected(request.coverages, 'MVF')
                ) &&
                telematicsEnrolled
              ) {
                nextActions.push(removeTelematicsEnrollment());
                nextActions.push(
                  fromCoverageMessageActions.addCoverageInformationalMessage({
                    payload: {
                      warnings: [
                        {
                          entity: {
                            coverageCode: request.coverages[0].coverageId,
                          },
                          message: AFMVF_SMARTRIDE_NOTICE_VERBIAGE,
                          productId: request.productType as ProductType,
                        },
                      ],
                      productId: request.productType as ProductType,
                    },
                  })
                );
              }
            }
            if (response.messages?.[0]) {
              nextActions.push(
                fromCoverageMessageActions.addCoverageInformationalMessage({
                  payload: {
                    ...response.messages[0], // Only one index expected in this array
                    productId: request.productType as ProductType,
                  },
                })
              );
            }
            return from(nextActions);
          }),
          catchError((error) => {
            const safeError = this.errorSanitizerService.sanitizeError(
              error,
              request.productType
            );
            return from([
              fromActions.updateCoverageError({
                error: safeError,
                updatedCoverage: request.coverages,
              }),
            ]);
          })
        );
      })
    )
  );

  private coverageIsSelected(
    coverageChanges: CoverageChange[],
    coverageCode: string
  ): boolean {
    const coverages = coverageChanges.filter(
      (coverage) => coverage.coverageId === coverageCode
    );
    return coverages.every(
      (coverage) =>
        !!coverage.selectedValue.find(
          (selected) =>
            selected.code === 'selected' && selected.value === 'true'
        )
    );
  }

  private logCoverageMessages(messages: CoverageInformationalMessage): void {
    const allErrors =
      messages.errors?.concat(messages.warnings || []) ||
      ([] as CoverageMessage[]);
    for (const err of allErrors) {
      this.logService.logUiEvent('display-error', err.message);
    }
  }
}
