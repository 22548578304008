import { PageIdentifier } from '@core/constants/pages';
import { ProductType } from '@core/models/api/dsm-types';
import { NavigationBus } from '@core/services/navigation.service';
import { createAction, props } from '@ngrx/store';
import { UrlType } from '@shared/services/pc-url.service';
import { ProductModel } from '../product/product.model';
import { ErrorModel } from '@entities/error/error.model';

export enum PageSubCategory {
  BILLING_PAYMENT,
  MEMBERS,
  BUILD_QUOTE,
  FINALIZE_QUOTE,
  COVERAGES,
  GLOBAL,
}

export interface Page {
  id: PageIdentifier;
  url: string;
  name: string;
  index: number;
  hasError: boolean;
  isComplete: boolean;
  isNavigable: boolean;
  hasWarning: boolean;
  hasReviewWarning: boolean; // for review tasks only
  enabled: boolean;
  showNavBar: boolean;
  associatedProducts?: ProductType[];
  icon?: string;
  completedIcon?: string;
  subcategory?: PageSubCategory;
  visited: boolean;
}

export const navigatePostRetrieve = createAction(
  '[Navigation] Navigate Post Retrieve'
);

export const navigatePostRetrieveComplete = createAction(
  '[Navigation] Navigate Post Retrieve Complete'
);

export const setCurrentPage = createAction(
  '[Navigation] Set Current Page',
  props<{ payload: Page }>()
);

export const setTargetPage = createAction(
  '[Navigation] Set Target Page',
  props<{ payload: NavigationBus }>()
);

export const setWarning = createAction(
  '[Navigation] Set Page Warning',
  props<{ payload: PageIdentifier }>()
);

export const setReviewWarning = createAction(
  '[Navigation] Set Page Review Warning',
  props<{ payload: PageIdentifier }>()
);
export const clearReviewWarning = createAction(
  '[Navigation] Clear Page Review Warning',
  props<{ payload: PageIdentifier }>()
);
export const clearWarning = createAction(
  '[Navigation] Clear Page Warning',
  props<{ payload: PageIdentifier }>()
);

export const setErrors = createAction(
  '[Navigation] Set Page Errors',
  props<{ payload: PageIdentifier }>()
);

export const clearErrors = createAction(
  '[Navigation] Clear Page Errors',
  props<{ payload: PageIdentifier }>()
);

export const markComplete = createAction(
  '[Navigation] Mark Page Complete',
  props<{ payload: PageIdentifier }>()
);

export const markIncomplete = createAction(
  '[Navigation] Mark Page Incomplete',
  props<{ payload: PageIdentifier }>()
);

export const setPageNavigable = createAction(
  '[Navigation] Set Page Navigable',
  props<{ payload: PageIdentifier; isNavigable: boolean }>()
);

export const setPageVisited = createAction(
  '[Navigation] Set Page Visited',
  props<{ page: PageIdentifier; visited: boolean }>()
);

export const navigateToPage = createAction(
  '[Navigation] Navigate to page',
  props<{ pageUrl: string; showSpinner?: boolean }>()
);

export const navigateToPageField = createAction(
  '[Navigation] Navigate to field on page',
  props<{ pageUrl: string; field: string }>()
);

export const navigateToBillingPage = createAction(
  '[Navigation] Navigate to Billing page'
);

export const enableBuildQuotePages = createAction(
  '[Navigation] Enable build quote pages',
  props<{ payload: ProductModel[] }>()
);

export const enablePage = createAction(
  '[Navigation] Enable page',
  props<{ payload: Page }>()
);

export const submitPage = createAction(
  '[Navigation] Submit current page',
  props<{ payload: Page }>()
);

export const collapseMobileNav = createAction(
  '[Navigation] Collapse mobile navigation'
);

export const openMobileNav = createAction(
  '[Navigation] Open mobile navigation'
);
export const disableQuotePagesOnIssue = createAction(
  '[Navigation] Disable Quoting Pages on Issue'
);

export const linkToPolicyCenter = createAction(
  '[Navigation] Pivot to PolicyCenter',
  props<{ payload: { reason: string; urlType?: UrlType; id?: string } }>()
);

export const logLinkToPolicyCenter = createAction(
  '[Navigation] Log Pivot to PolicyCenter',
  props<{ payload: { reason: string; urlType?: UrlType } }>()
);

export const setAutofillTarget = createAction(
  '[Navigation] Set Autofill Target',
  props<{ payload: Page }>()
);

export const launchMSBSite = createAction(
  '[Navigation] Navigate to MSB Site',
  props<{ payload: ProductType }>()
);

export const invalidValidationNavigation = createAction(
  '[Navigation] Set Invalid Validation Error',
  props<{ payload: ErrorModel }>()
);

export const reloadApp = createAction(
  '[Navigation] Reload App'
);
