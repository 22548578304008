import {
  Component,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { ProductModel } from '@core/store/entities/product/product.model';

@Component({
  selector: 'nwx-billing-issue-policies-modal',
  templateUrl: './billing-issue-policies-modal.component.html',
  styleUrls: ['./billing-issue-policies-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingIssuePoliciesModalComponent {
  // products and errorMessage must be assigned by whoever creates the NgbModal:
  products!: ProductModel[];
  errorMessage!: string;

  constructor() {}

  getContent(): string {
    if (this.products.length === 1) {
      return 'Payment has been accepted, but this policy still needs to be issued.';
    } else {
      return 'Payment has been accepted, but these policies still need to be issued.';
    }
  }

  wasPaymentAccepted(): boolean {
    if (this.errorMessage?.includes('SAE025')) {
      return true;
    }
    return false;
  }

  describeProduct(product: ProductModel): string {
    let description = product.name || '';
    if (product.quoteId) {
      description += ` submission ID ${product.quoteId}`;
    }
    if (product.policyNumber) {
      if (product.quoteId) {
        description += ',';
      }
      description += ` policy number ${product.policyNumber}`;
    }
    return description;
  }
}
