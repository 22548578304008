<div class="modal-header">
  <h4 id="smartrideDataSharingHeader" class="modal-title">
    SmartRide data sharing consent
  </h4>
</div>
<div class="modal-body">
  <div class="bolt-container-fluid">
    <p class="text-header">
      Nationwide Insurance partners with vehicle manufacturers and third parties
      to collect vehicle driving data to apply discounts when available.
    </p>
    <div class="card">
      <h4 class="modal-sub-header">Please ask customer:</h4>
      <p class="modal-text-content">
        “Do you agree to share the driving data or personal information
        collected by your vehicle(s) manufacturer and/or third party with
        Nationwide to calculate and apply a SmartRide discount to the quote if
        requirements are met?”
      </p>
    </div>
    <div class="add-padding bolt-row">
      <div class="bolt-col aligned-row">
        <bolt-icon name="info-circle-filled" color="theme-info"></bolt-icon>
        <strong class="label horz-space">Participating partners:</strong>
      </div>
      <div class="bolt-col-1" (click)="showPartnersPanel()">
        <div class="icon-circle">
          <bolt-icon
            *ngIf="!showPartners"
            name="chevron-down"
            size="sm"
          ></bolt-icon>
          <bolt-icon
            *ngIf="showPartners"
            name="chevron-up"
            size="sm"
          ></bolt-icon>
        </div>
      </div>
      <div class="bolt-row" *ngIf="showPartners">
        <p class="align-content modal-text-content">
          Participating partners and vehicle types include: Toyota's affiliate
          Connected Analytic Services LLC ('CAS')
        </p>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <bolt-button-bar>
    <bolt-button (click)="decline()" slot="cancel">Decline</bolt-button>
    <bolt-button slot="forward" (click)="confirm()" type="primary"
      >Customer agrees</bolt-button
    >
  </bolt-button-bar>
</div>
