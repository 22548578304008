<nwx-telematics
  [enrollment]="telematics$ | async"
  [programTileVehicles]="(programTileVehicles$ | async) || []"
  [quoteState]="(quoteState$ | async) || ''"
  [isRecommendationEnrolled]="(isRecommendationEnrolled$ | async) || false"
  [isAnyTelematicsEnrolled]="(isAnyTelematicsEnrolled$ | async) || false"
  [shouldProposeReenrollExcluded]="
    (shouldProposeReenrollExcluded$ | async) || false
  "
  [hasDrivingDataConsent]="(hasDrivingDataConsent$ | async) || false"
  [maxSizeMini]="maxSizeMini$ | async"
  [maxSizeMedium]="maxSizeMedium$ | async"
  [premium]="premium$ | async"
></nwx-telematics>
