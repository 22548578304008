import { Injectable } from '@angular/core';
import { PageAlertAdapter } from '@core/adapters/page-alert.adapter';
import { PageAlertActions } from '@core/store/actions';
import { PageAlert } from '@core/store/entities/page-alert/page-alert.model';
import { PageAlertSelectors } from '@core/store/selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageAlertService {
  constructor(
    private pageAlertAdapter: PageAlertAdapter,
    private store: Store
  ) {}

  establishPageAlerts(): Observable<PageAlert[]> {
    return this.pageAlertAdapter.getPageAlerts();
  }

  loadAnnouncementModalContent(): void {
    return this.pageAlertAdapter.loadAnnouncementModalContent();
  }

  loadPageAlerts(): void {
    this.store.dispatch(PageAlertActions.LoadAllPageAlerts());
  }

  getPageAlertLoaded(): Observable<boolean> {
    return this.store.select(PageAlertSelectors.getPageAlertsLoaded);
  }
}
