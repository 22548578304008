import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import {
  AddlInterestThirdPartyType,
  ProductType,
} from '@core/models/api/dsm-types';
import { getMortgagesOfMortgageTypes } from '../mortgage/mortgage.selector';

export const getMortgageState = createSelector(
  fromCore.getCoreState,
  (state) => state.hasMortgage
);

export const selectHasMortgages = (
  productType: ProductType,
  mortgageTypes: AddlInterestThirdPartyType[]
) =>
  createSelector(
    getMortgageState,
    getMortgagesOfMortgageTypes(productType, mortgageTypes),
    (hasMortgage, existingMortgaes) => {
      return !!hasMortgage || existingMortgaes.length > 0;
    }
  );
