<div
  [class.warning]="type === 'warning'"
  [class.error]="type === 'error'"
  [class.info]="type === 'info'"
  [class.nwx-error]="type === 'nwx-error'"
>
  <div class="bolt-container-fluid">
    <div class="bolt-row">
      <div class="bolt-col-auto icon-bg">
        <bolt-icon
          [attr.name]="getIconName()"
          color="white"
          [attr.size]="size"
        ></bolt-icon>
      </div>
      <div
        class="bolt-col content"
        [class.lg-padding]="paddingsize === 'lg'"
        [class.sm-padding]="paddingsize === 'sm'"
      >
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
