import { ProductType } from '@core/models/api/dsm-types';
import { createAction, props } from '@ngrx/store';

export const setSessionId = createAction(
  '[DSM] Set Session Id',
  props<{ productId: ProductType; sessionId: string }>()
);

export const setAccessToken = createAction(
  '[DSM] Set Access Token',
  props<{ productId: ProductType; accessToken: string }>()
);

export const setTargetEnv = createAction(
  '[DSM] Set Target Env',
  props<{ targetEnv: string }>()
);

export const setApplicationName = createAction(
  '[DSM] Set Application Name',
  props<{ applicationName: string }>()
);

export const setEffectiveDate = createAction(
  '[DSM] Set Effective Date',
  props<{ effectiveDate: string }>()
);

export const addCallInFlight = createAction(
  '[DSM] Add Call In Flight',
  props<{ name: string }>()
);

export const removeCallInFlight = createAction(
  '[DSM] Remove Call In Flight',
  props<{ name: string }>()
);
