import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromDriverVehiclePrefill from '@core/store/entities/driver-vehicle-prefill/driver-vehicle-prefill.reducer';
import * as fromPeople from '@core/store/entities/member/member.selector';
import * as fromAddress from '@core/store/entities/address/address.selector';
import * as fromAccount from '@core/store/entities/account/account.selector';
import * as fromUser from '@core/store/entities/user/user.selector';
import { DriverVehiclePrefillRequest } from '@core/models/api/request/driver-vehicle-prefill-request.model';
import { UserType } from '@core/models/api/request/user-context.model';
import { UserModel } from '../user/user.model';
import { ProductType } from '@core/models/api/dsm-types';

export const getDriverVehiclePrefillState = createSelector(
  fromCore.getCoreState,
  (state) => state.driverVehiclePrefill
);

export const getDriverVehiclePrefillLoaded = createSelector(
  getDriverVehiclePrefillState,
  (state) => state.loaded
);

export const getDriverPrefillState = createSelector(
  getDriverVehiclePrefillState,
  (state) => state.drivers
);

export const getVehiclePrefillState = createSelector(
  getDriverVehiclePrefillState,
  (state) => state.vehicles
);

export const { selectAll: selectAllPrefillPeople } =
  fromDriverVehiclePrefill.driverAdapter.getSelectors(getDriverPrefillState);

export const { selectAll: selectAllPrefillVehicles } =
  fromDriverVehiclePrefill.vehicleAdapter.getSelectors(getVehiclePrefillState);

export const getNonAddedPrefillVehicles = (productId: ProductType) =>
  createSelector(selectAllPrefillVehicles, (vehicles) =>
    vehicles.filter(
      (vehicle) =>
        vehicle.vehicleId?.toString().startsWith('PREFILL') &&
        vehicle.productType === productId
    )
  );

export const buildCustomersInfoRequest = createSelector(
  fromPeople.getPrimaryNamedInsuredForMultipleProducts(['PersonalAuto', 'MSA']),
  fromAddress.getAddressByType('Account'),
  fromAccount.getAccountId,
  fromUser.getUser,
  (policyholder, policyAddress, accountId, user) => {
    const businessChannel = getBusinessChannel(user);
    const request: DriverVehiclePrefillRequest = {
      agreementID: accountId || '',
      businessChannel,
      person: {
        firstName: policyholder?.person?.firstName || '',
        lastName: policyholder?.person?.lastName || '',
        dateOfBirth: policyholder?.person?.dateOfBirth || '',
        middleName: policyholder?.person?.middleName || '',
      },
      address: {
        city: policyAddress?.city || '',
        state: policyAddress?.state || '',
        streetName: policyAddress?.addressLine1 || '',
        zipCode: policyAddress?.postalCode || '',
      },
    };

    return request;
  }
);

function getBusinessChannel(user: UserModel): string {
  let businessChannel = 'Internet';
  if (user !== null && user.role !== null) {
    const channel = user.role;
    if (channel === UserType.IA) {
      businessChannel = 'Independent';
    } else if (channel === UserType.EA) {
      businessChannel = 'Exclusive';
    } else if (channel === UserType.NSS) {
      businessChannel = channel;
    }
  }
  return businessChannel;
}
