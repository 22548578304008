import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DisplayWarningDirectiveModule } from '@shared/directives/display-warning/display-warning.directive.module';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { BoltDirectivesModule } from '@nationwide-bolt/ng-directives';

@NgModule({
  exports: [
    ReactiveFormsModule,
    DisplayWarningDirectiveModule,
    SharedDirectivesModule,
    BoltDirectivesModule,
  ],
})
export class CommonFormModule {}
