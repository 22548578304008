import { Injectable } from "@angular/core";
import { CompraterRoutingAdapter, CompraterRoutingResponse } from "@core/adapters/comprater-routing.adapter";
import { CompraterRoutingActions } from "@core/store/actions";
import { CompraterRoutingSelectors } from "@core/store/selectors";
import { Store } from "@ngrx/store";
import { filterOutNull } from "@shared/rxjs/filter-out-null.operator";
import { combineLatest, Observable } from "rxjs";
import { filter, map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompraterRoutingService {
  constructor(
    private store: Store
  ) {}

  getCompraterRouting(quoteId: string): Observable<CompraterRoutingResponse> {
    return this.store.select(CompraterRoutingSelectors.selectCompraterRouting).pipe(
      map((state) => {
        if (state.callCount) {
          // stall
          return null;
        }
        if (state.response.find((r) => r.comparativeQuoteId === quoteId)) {
          // already have it, great
          return state.response;
        }
        if (state.failedQuoteIds.indexOf(quoteId) >= 0) {
          // failed, return empty
          return [];
        }
        // trigger request and stall
        this.store.dispatch(CompraterRoutingActions.fetchCompraterRouting({ quoteId }));
        return null;
      }),
      filterOutNull(),
    )
  }

  getCompraterRoutingPassively(): Observable<CompraterRoutingResponse> {
    return this.store.select(CompraterRoutingSelectors.selectCompraterRoutingResponse);
  }
}
