import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';

export const getUWReportsState = createSelector(
  fromCore.getCoreState,
  (state) => state.uwReports
);

export const getHomeownersUWReports = createSelector(
  getUWReportsState,
  (state) => state.homeowner
);

export const getUWReportsByProduct = createSelector(
  getUWReportsState,
  (state) => ({
    auto: state.auto,
    homeowner: state.homeowner,
    condo: state.condo,
    renters: state.renters,
    msa: state.msa,
    rv: state.rv,
    boat: state.boat,
  })
);

export const getLoading = createSelector(
  getUWReportsState,
  (state) => state.loading
);

export const getLoaded = createSelector(
  getUWReportsState,
  (state) => state.loaded
);
// put this on hold. Requested countOfAdverseReports API calls from from Wildlings
// export const getCountOfAdverseReports = createSelector(
//   getUWReportsState,
//   (state) => 99
// );

export const getUwReportsLoading = createSelector(
  getLoading,
  (count) => count > 0
);
