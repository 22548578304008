import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromQuoteLetter from '@core/store/entities/quote-letter/quote-letter.reducer';
import { CurrencyPipe } from '@angular/common';
import { CoverageHelper } from '@core/helper/coverage.helper';
import { getAllMembers } from '@core/store/entities/member/member.selector';
import { getAgency } from '@core/store/entities/agency/agency.selector';
import { getStateSpecificFlags } from '@core/store/entities/metadata/metadata.selector';
import { getAllCoverages } from '../coverage/coverage.selector';
import { getAllPremiums } from '../premium/premium.selectors';
import { getSelectedDiscounts } from '../discount/discount.selectors';
import { CoverageDisplayGrouping } from '@core/constants/coverage-display-grouping';
import { DiscountEntity } from '../discount/discount.entity';
import { CoverageUtils } from '@shared/utils/coverage.utils';
import {
  CoverageEntity,
  CoverageTerm,
  SelectedValue,
} from '../coverage/coverage.entity';
import { DiscountNameHelper } from '@core/helper/discount-name.helper';
import { ProductType } from '@core/models/api/dsm-types';
import { getAllVehicles } from '../vehicle/vehicle.selector';
import { getAllFeatureFlags } from '../feature-flag/feature-flag.selector';
import { FeatureFlagsModel } from '../feature-flag/feature-flag.model';
import { getScheduledCategoryEntities } from '../scheduled-categories/scheduled-category.selector';
import { ScheduledCategoryEntity } from '../scheduled-categories/scheduled-category.entity';
import { CoverageIds } from '@shared/constants/app-constants';
import {
  Coverage,
  Member,
  Product,
  Property,
  QuoteLetterModel,
  ScheduledPersonalProperty,
  Vehicle,
} from './quote-letter.model';
import { DateUtils } from '@shared/utils/date.utils';
import { MemberEntity } from '@entities/member/member.reducer';
import { getAllCoveredLocations } from '@entities/covered-location/covered-location.selector';
import { ProductModel } from '@entities/product/product.model';
import { BoatTrailer, OutboardMotor } from '@core/models/views/vehicle.model';
import { getUser } from '@entities/user/user.selector';
import { suffixConstants } from '@shared/constants/suffix-constants';
import { OtherStructureCoverage } from '@entities/other-structures/other-structures.reducer';
import { OtherStructuresSelectors } from '@core/store/selectors';
import { CoveragesMetadata } from '@core/constants/coverage-metadata';
import { getAllVehicleExposures } from '@entities/exposures/vehicle-exposures/vehicle-exposures.selector';
import { getAllWatercraftExposures } from '@entities/exposures/watercraft-exposures/watercraft-exposures.selector';
import { getAllLocationExposures } from '@entities/exposures/location-exposures/location-exposures.selector';
import { LocationExposureEntity } from '@entities/exposures/location-exposures/location-exposures.entity';
import { VehicleExposureEntity } from '@entities/exposures/vehicle-exposures/vehicle-exposures.entity';
import { WatercraftExposureEntity } from '@entities/exposures/watercraft-exposures/watercraft-exposures.entity';
import { PremiumEntity } from '@entities/premium/premium.entity';

export interface ScheduledCategoryDisplay {
  Name: string;
  Limit?: string;
  Items: ScheduledItemDisplay;
}
export interface ScheduledItemDisplay {
  Item: ScheduledItem[];
}
export interface ScheduledItem {
  Value: string;
  Name: string;
}

export const getQuoteLetterState = createSelector(
  fromCore.getCoreState,
  (state) => state.quoteLetter
);

export const getQuoteLetter = createSelector(
  getQuoteLetterState,
  fromQuoteLetter.getQuoteLetter
);

export const getQuoteLetterLoaded = createSelector(
  getQuoteLetterState,
  fromQuoteLetter.getQuoteLetterLoaded
);

export const getStaticData = createSelector(
  getStateSpecificFlags,
  getAgency,
  (stateSpecificFlags, agency) => ({
    stateSpecificFlags,
    agency,
  })
);

export const buildQuoteLetterRequest = (
  selectedProducts: ProductModel[],
  currencyPipe: CurrencyPipe,
  quoteState: string,
  coverageHelper: CoverageHelper,
  discountNameHelper: DiscountNameHelper
) =>
  createSelector(
    getAllVehicles,
    getAllCoverages,
    OtherStructuresSelectors.selectOtherStructureCoveragesForMultipleQuoteIds(
      selectedProducts.map((p) => p.quoteId).filter((v) => !!v) as string[]
    ),
    getAllPremiums,
    getSelectedDiscounts,
    getAllFeatureFlags,
    getScheduledCategoryEntities,
    getAllMembers,
    getAllCoveredLocations,
    getUser,
    getAllVehicleExposures,
    getAllWatercraftExposures,
    getAllLocationExposures,
    (
      vehicles,
      coverages,
      otherStructureCoverages,
      premiums,
      discounts,
      featureFlags,
      scheduledCategories,
      members,
      coveredLocations,
      user,
      vehicleExposures,
      watercraftExposures,
      locationExposures
    ) => {
      const policyholder = members?.find(
        (member) => member.policyHolderType === 'PolicyPriNamedInsured'
      );
      const quoteLetterRequest: QuoteLetterModel = {
        policyholder: {
          firstName: policyholder?.person?.firstName,
          middleName: policyholder?.person?.middleName,
          lastName: policyholder?.person?.lastName,
          suffix: suffixConstants.find(
            (suffix) => suffix.value === policyholder?.person?.suffix
          )?.display,
        },
        producer: {
          firstName: user?.firstName,
          lastName: user?.lastName,
        },
        products: Array(),
      };

      const selectedProductsWithoutErrors = selectedProducts.filter(
        (product) => !product.hasError
      );
      selectedProductsWithoutErrors.forEach((product) => {
        const returnPremium = premiums.find(
          (premium) => premium.productType === product.type
        );
        if (returnPremium && returnPremium.total && returnPremium.termMonths) {
          const returnProduct = {
            productType: product.type,
            fullPremium: currencyPipe.transform(
              returnPremium.total.amount,
              'USD',
              'symbol',
              '1.2-2'
            ),
            installmentPremium: currencyPipe.transform(
              returnPremium.total.amount / returnPremium.termMonths,
              'USD',
              'symbol',
              '1.2-2'
            ),
            policyTerm: returnPremium.termMonths + ' months',
            quoteId: product.quoteId,
            effectiveDate: DateUtils.formatDsmDateToOld(
              product.effectiveDate || ''
            ),
            propertyLocation: {},
            discounts: Array(),
            drivers: Array(),
            coverages: Array(),
            scheduledPersonalProperty: Array(),
            vehicleList: Array(),
            vehicles: Array(),
          } as Product;

          const coveredLocation = coveredLocations.find(
            (covLoc) => covLoc.productType === product.type
          );
          if (coveredLocation) {
            returnProduct.propertyLocation = {
              addressLine1: coveredLocation.location?.addressLine1,
              city: coveredLocation.location?.city,
              state: coveredLocation.location?.state,
              zip: coveredLocation.location?.postalCode,
            };
          }

          const qlDiscounts = buildQuoteLetterDiscounts(
            discounts,
            product.type,
            discountNameHelper
          );
          returnProduct.discounts = qlDiscounts;

          if (
            product.type !== 'Homeowner' &&
            product.type !== 'Condominium' &&
            product.type !== 'Tenant'
            // && product.type !== 'PersonalUmbrella'
          ) {
            const qlDrivers = buildQuoteLetterDrivers(members);
            returnProduct.drivers = qlDrivers;
          }

          let coverageGroups: string[] = [];
          if (product.type === 'PersonalAuto') {
            coverageGroups = [
              CoverageDisplayGrouping.Policy,
              CoverageDisplayGrouping.AdditionalPolicy,
            ];
          } else if (
            product.type === 'Homeowner' ||
            product.type === 'Condominium' ||
            product.type === 'Tenant'
          ) {
            coverageGroups = [
              CoverageDisplayGrouping.PropertyLiability,
              CoverageDisplayGrouping.AdditionalProtection,
              CoverageDisplayGrouping.SpecialLimitsOfLiability,
            ];
          } else if (['MSA', 'RV', 'Boat'].includes(product.type)) {
            coverageGroups = [CoverageDisplayGrouping.Policy];
          } else if (product.type === 'PersonalUmbrella') {
            coverageGroups = [
              CoverageDisplayGrouping.Policy,
              CoverageDisplayGrouping.Underlying,
              CoverageDisplayGrouping.AdditionalUnderlying,
            ];
          }

          const covEntities = extractCoverages(
            coverages,
            otherStructureCoverages,
            coverageGroups,
            product.type,
            quoteState,
            coverageHelper
          );
          const qlCoverages = buildQuoteLetterCoverages(
            covEntities,
            featureFlags
          );
          returnProduct.coverages = qlCoverages;

          if (['Condominium', 'Homeowner', 'RV'].includes(product.type)) {
            const allScheduledCategories = buildQuoteLetterScheduledCategories(
              scheduledCategories[product.type]?.scheduledCategories || [],
              product.type,
              currencyPipe
            );
            returnProduct.scheduledPersonalProperty = allScheduledCategories;
          }
          if (['PersonalAuto', 'MSA', 'RV', 'Boat'].includes(product.type)) {
            const vehicleCoverages = coverages.filter(
              (cov) => cov.productId === product.type
            );
            let count = 0;
            const productVehicles = vehicles.filter(
              (v) => v.productType === product.type
            );
            productVehicles.forEach((veh) => {
              count++;
              const vehicleListItem = {
                vehicleId: count.toString(),
                year: veh.year?.toString(),
                make: veh.make,
                model: veh.model,
              };
              returnProduct.vehicleList?.push(vehicleListItem);

              const vehicle = {
                vehicleId: count.toString(),
                coverages: Array(),
                motorsAndTrailers: Array(),
              };
              coverageGroups = [CoverageDisplayGrouping.Vehicle];
              let coverageEntities = extractVehicleCoverages(
                vehicleCoverages,
                coverageGroups,
                veh.vehicleId || '',
                quoteState,
                veh.productType || 'PersonalAuto'
              );
              if (
                veh.productType === 'RV' &&
                veh.vehicleType === 'UtilityTrailer'
              ) {
                coverageEntities = coverageEntities.filter((c) => c.available);
              }
              const quoteLetterCoverages = buildQuoteLetterCoverages(
                coverageEntities,
                featureFlags
              );
              vehicle.coverages = quoteLetterCoverages;

              if (product.type === 'Boat') {
                if (veh.motorDetails || veh.trailer) {
                  const motorsOrTrailers = buildMotorsOrTrailers(
                    veh.motorDetails,
                    veh.trailer
                  );
                  vehicle.motorsAndTrailers = motorsOrTrailers;
                }
              }
              returnProduct.vehicles?.push(vehicle);
            });
          }
          if (product.type === 'PersonalUmbrella') {
            buildPersonalUmbrellaProduct(
              returnProduct,
              locationExposures,
              vehicleExposures,
              watercraftExposures,
              members,
              premiums,
              currencyPipe
            );
          }

          quoteLetterRequest.products?.push(returnProduct);
        }
      });

      if (!quoteLetterRequest.products?.length) {
        return null;
      }

      const base64EncodedJson = btoa(JSON.stringify(quoteLetterRequest));

      const quoteLetterRequestBody = {
        pubName: 'SalesQuote2.pub',
        referenceArea: '',
        fileName: 'INPUT',
        driverFile: base64EncodedJson,
      };

      return quoteLetterRequestBody;
    }
  );

function extractCoverages(
  coverages: CoverageEntity[],
  otherStructureCoverages: OtherStructureCoverage[],
  coverageGroups: string[],
  productId: ProductType,
  quoteState: string,
  coverageHelper: CoverageHelper
): CoverageEntity[] {
  let quoteLetterCoverages: CoverageEntity[] = [];
  const productCoverages = coverages.filter(
    (cov) => cov.productId === productId && cov.available
  );
  coverageGroups.forEach((group) => {
    const productGroupCoverages = productCoverages.filter((coverage) =>
      CoverageUtils.filterCoverageByGrouping(
        coverage,
        group,
        quoteState,
        productId
      )
    );

    if (group === 'Property / Liability') {
      for (const os of otherStructureCoverages) {
        productGroupCoverages.push({
          coverageId: os.coverageId,
          available: true,
          editable: true,
          mandatory: false,
          name: CoveragesMetadata[os.coverageId]?.displayName || os.coverageId,
          selectedValue: [
            {
              code: 'selected',
              value: (os.selectedValue || false).toString(),
            },
          ],
        } as CoverageEntity);
      }
    }

    productGroupCoverages.sort(
      CoverageUtils.coverageSorter(quoteState, productId)
    );
    quoteLetterCoverages = quoteLetterCoverages.concat(productGroupCoverages);
  });
  quoteLetterCoverages =
    coverageHelper.mergeSpreadableCoverages(quoteLetterCoverages);
  return quoteLetterCoverages;
}

function extractVehicleCoverages(
  coverages: CoverageEntity[],
  coverageGroups: string[],
  vehicleId: string | null,
  quoteState: string,
  productId: ProductType
): CoverageEntity[] {
  let quoteLetterCoverages: CoverageEntity[] = [];
  coverageGroups.forEach((group) => {
    const productGroupCoverages = coverages.filter(
      (coverage) =>
        coverage.coverableId === vehicleId &&
        CoverageUtils.filterCoverageByGrouping(
          coverage,
          group,
          quoteState,
          productId
        )
    );
    productGroupCoverages.sort(
      CoverageUtils.coverageSorter(quoteState, productId)
    );
    quoteLetterCoverages = quoteLetterCoverages.concat(productGroupCoverages);
  });

  return quoteLetterCoverages;
}

function buildQuoteLetterDiscounts(
  discounts: DiscountEntity[],
  productId: ProductType,
  discountNameHelper: DiscountNameHelper
): string[] {
  const quoteLetterDiscounts: string[] = [];
  const selectedProductDiscounts = discounts.filter(
    (discount) => discount.productType === productId
  );
  for (const discount of selectedProductDiscounts) {
    quoteLetterDiscounts.push(
      discountNameHelper.helpDiscountName(discount)?.description
    );
  }
  return [...new Set(quoteLetterDiscounts)];
}

function buildQuoteLetterDrivers(members: MemberEntity[]): any[] {
  const quoteLetterDrivers: Member[] = [];
  members.forEach((member) => {
    if (member.driverType === 'Driver') {
      const driver = {
        firstName: member.person?.firstName,
        middleName: member.person?.middleName,
        lastName: member.person?.lastName,
        suffix: suffixConstants.find(
          (suffix) => suffix.value === member.person?.suffix
        )?.display,
      };
      quoteLetterDrivers.push(driver);
    }
  });
  return quoteLetterDrivers;
}

function buildQuoteLetterCoverages(
  coverages: CoverageEntity[],
  featureFlags: FeatureFlagsModel
): Coverage[] {
  const quoteLetterCoverages: Coverage[] = [];
  coverages?.forEach((cov) => {
    let limitOrDed = '';
    if (!cov.available) {
      limitOrDed = 'Not Available';
    } else {
      if (cov.coverageId === CoverageIds.ScheduledPersonalEffects) {
        limitOrDed = cov.selectedValue[0].description || 'Decline';
      } else if (!cov.terms?.length) {
        limitOrDed = getLimitOrDedWithoutCoverageTerms(cov);
      } else if (cov.terms) {
        limitOrDed = getLimitOrDedWithCoverageTerms(
          cov.terms[0],
          cov.selectedValue
        );
      }
    }

    if (limitOrDed) {
      const coverage = {
        name: cov.name,
        limitOrDed: limitOrDed,
      };
      quoteLetterCoverages.push(coverage);

      if (
        cov.terms &&
        cov.terms.length > 1 &&
        CoverageUtils.isCoverageSelected(cov)
      ) {
        for (const [index, term] of cov.terms.entries()) {
          if (index > 0) {
            const quoteLetterCoverage: Coverage = {
              name: term.type,
              limitOrDed: getLimitOrDedWithCoverageTerms(
                term,
                cov.selectedValue
              ),
            };
            quoteLetterCoverages.push(quoteLetterCoverage);
          }
        }
      }
    }
  });
  return splitWindAndHail(quoteLetterCoverages, featureFlags);
}

function buildQuoteLetterScheduledCategories(
  scheduledCategories: ScheduledCategoryEntity[],
  productType: ProductType,
  currencyPipe: CurrencyPipe
): ScheduledPersonalProperty[] {
  return scheduledCategories
    .filter((category) => category.scheduledItems?.length || category.limit)
    .map((category) => {
      let scheduledPersonalProperty = {} as ScheduledPersonalProperty;
      scheduledPersonalProperty.classType = category.name;
      scheduledPersonalProperty.items = [];

      if (['Homeowner', 'Condominium'].includes(productType)) {
        if (category.scheduledItems?.length) {
          let limit = 0;
          category.scheduledItems.forEach((item) => {
            limit += +item.value;
          });
          scheduledPersonalProperty.limit = currencyPipe.transform(
            limit,
            'USD',
            'symbol',
            '1.2-2'
          );
        } else {
          scheduledPersonalProperty.limit = currencyPipe.transform(
            category.limit,
            'USD',
            'symbol',
            '1.2-2'
          );
        }
      } else {
        category.scheduledItems?.forEach((scheduledItem) => {
          let item = {} as Coverage;
          item.coverage = scheduledItem.description;
          item.limitOrDed = currencyPipe.transform(
            scheduledItem.value,
            'USD',
            'symbol',
            '1.2-2'
          );
          scheduledPersonalProperty.items?.push(item);
        });
      }
      return scheduledPersonalProperty;
    });
}

function buildMotorsOrTrailers(
  motorDetails: OutboardMotor[] | undefined,
  boatTrailer: BoatTrailer | undefined
): Vehicle[] {
  const motorsOrTrailers: Vehicle[] = [];
  motorDetails?.forEach((detail) => {
    const motor = {
      year: detail.year?.toString(),
      make: detail.make,
      model: detail.model,
      type: 'Motor',
    };
    motorsOrTrailers.push(motor);
  });
  if (boatTrailer) {
    const trailer = {
      year: boatTrailer.year?.toString(),
      make: boatTrailer.make,
      model: boatTrailer.model,
      type: 'Trailer',
    };
    motorsOrTrailers.push(trailer);
  }
  return motorsOrTrailers;
}

function splitWindAndHail(
  quoteLetterCoverages: Coverage[],
  featureFlags: FeatureFlagsModel
): Coverage[] {
  const indexOfCoverage = quoteLetterCoverages.findIndex((cov) => {
    return cov.name === 'Wind & Hail';
  });
  if (indexOfCoverage >= 0 && featureFlags.splitWindAndHail) {
    quoteLetterCoverages = [...quoteLetterCoverages];
    const windAndHail: Coverage = quoteLetterCoverages[indexOfCoverage];
    // coverage order should be maintained
    quoteLetterCoverages.splice(
      indexOfCoverage,
      1,
      {
        name: 'Wind Peril',
        limitOrDed: windAndHail.limitOrDed,
      },
      {
        name: 'Hail Peril',
        limitOrDed: windAndHail.limitOrDed,
      }
    );
  }
  return quoteLetterCoverages;
}

function getLimitOrDedWithoutCoverageTerms(cov: CoverageEntity): string {
  let limitOrDed = '';
  if (cov.editable && !cov.mandatory) {
    if (cov.selectedValue) {
      if (cov.selectedValue[0].value === 'true') {
        limitOrDed = 'Accept';
      } else {
        limitOrDed = 'Decline';
      }
    }
  } else if (!!cov.mandatory) {
    limitOrDed = 'Included';
  }
  return limitOrDed;
}

function getLimitOrDedWithCoverageTerms(
  coverageTerm: CoverageTerm,
  coverageSelectedValues: SelectedValue[]
): string {
  const isSelected = !coverageSelectedValues.some(
    (value) => value.code === 'selected' && value.value === 'false'
  );
  if (coverageTerm.isDummyTerm) {
    return isSelected ? 'Accept' : 'Decline';
  }

  let limitOrDed = '';
  const selectedValue = coverageSelectedValues.find(
    (value) => value.code === coverageTerm.code
  );
  if (coverageTerm?.options?.length > 0) {
    const selectedCoverageOption = coverageTerm.options.find(
      (option) => option.value === selectedValue?.value
    );
    if (selectedCoverageOption) {
      limitOrDed = selectedCoverageOption.description;
    } else {
      if (selectedValue?.value === 'true') {
        limitOrDed = 'Accept';
      } else {
        limitOrDed = 'Decline';
      }
    }
  } else if (!coverageTerm?.options?.length && coverageSelectedValues) {
    limitOrDed = selectedValue?.description
      ? selectedValue?.description
      : selectedValue?.value
      ? selectedValue?.value
      : '';
  }
  return limitOrDed;
}

function buildPropertyList(
  locationExposures: LocationExposureEntity[]
): Property[] {
  const propertyList: Property[] = [];
  locationExposures.forEach((exposure) => {
    const property = {
      propertyType: getProductName(exposure.policyType),
      addressLine1: exposure.locationAddress?.addressLine1,
      city: exposure.locationAddress?.city,
      state: exposure.locationAddress?.state,
      zip: exposure.locationAddress?.postalCode,
    };
    propertyList.push(property);
  });
  return propertyList;
}

function getProductName(productCode: string): string {
  switch (productCode) {
    case 'HO3':
      return 'Homeowner';
    case 'HO6':
      return 'Condominium';
    case 'HO4':
      return 'Tenant';
    default:
      return '';
  }
}

function buildVehicleList(
  vehicleExposures: VehicleExposureEntity[],
  vehicleType: string
): Vehicle[] {
  const vehicleList: Vehicle[] = [];
  let counter = 0;
  vehicleExposures.forEach((exposure) => {
    if (exposure.vehicleType === vehicleType) {
      counter += 1;
      const vehicle = {
        model: `${getVehicleTypeName(exposure.vehicleType)} #${counter}`,
      };
      vehicleList.push(vehicle);
    }
  });
  return vehicleList;
}

function getVehicleTypeName(vehicleType: string): string {
  switch (vehicleType) {
    case 'Antique_Exe':
      return 'Antique';
    case 'NamedNO_Ext':
      return 'Named Non-owner';
    case 'OffRoad_Ext':
      return 'Off Road';
    case 'OnRoad_Ext':
      return 'On Road';
    default:
      return 'Private Passenger';
  }
}

function buildWatercraftList(
  watercraftExposures: WatercraftExposureEntity[]
): Vehicle[] {
  const watercraftList: Vehicle[] = [];
  let counter = 0;
  watercraftExposures.forEach((exposure) => {
    counter += 1;
    const vehicle = {
      model: `Watercraft #${counter}`,
    };
    watercraftList.push(vehicle);
  });
  return watercraftList;
}

function buildHouseholdMembersList(members: MemberEntity[]): Member[] {
  const householdMembers: Member[] = [];
  members.forEach((member) => {
    const householdMember = {
      firstName: member.person?.firstName,
      middleName: member.person?.middleName,
      lastName: member.person?.lastName,
      suffix: suffixConstants.find(
        (suffix) => suffix.value === member.person?.suffix
      )?.display,
    };
    householdMembers.push(householdMember);
  });
  return householdMembers;
}

function buildProductPremiums(
  premiums: PremiumEntity[],
  productTypes: ProductType[]
): number | undefined {
  let productPremium = 0;
  productTypes.forEach((productType) => {
    const filteredPremiums = premiums.filter(
      (premium) => premium.productType === productType
    );
    filteredPremiums.forEach((premium) => {
      if (premium.total?.amount) {
        productPremium += premium.total?.amount;
      }
    });
  });
  return productPremium;
}

function buildPersonalUmbrellaProduct(
  returnProduct: Product,
  locationExposures: LocationExposureEntity[],
  vehicleExposures: VehicleExposureEntity[],
  watercraftExposures: WatercraftExposureEntity[],
  members: MemberEntity[],
  premiums: PremiumEntity[],
  currencyPipe: CurrencyPipe
) {
  const propertyList = buildPropertyList(locationExposures);
  returnProduct.propertyList = !!propertyList.length ? propertyList : undefined;

  let vehicleList = buildVehicleList(vehicleExposures, 'PrivatePass_Ext');
  vehicleList = vehicleList.concat(
    buildVehicleList(vehicleExposures, 'Antique_Ext')
  );
  vehicleList = vehicleList.concat(
    buildVehicleList(vehicleExposures, 'NamedNO_Ext')
  );
  returnProduct.vehicleList = !!vehicleList.length ? vehicleList : undefined;

  const watercraftList = buildWatercraftList(watercraftExposures);
  returnProduct.watercraftList = !!watercraftList.length
    ? watercraftList
    : undefined;

  let msaRvList = buildVehicleList(vehicleExposures, 'OffRoad_Ext');
  msaRvList = msaRvList.concat(
    buildVehicleList(vehicleExposures, 'OnRoad_Ext')
  );
  returnProduct.msaList = !!msaRvList.length ? msaRvList : undefined;

  const householdMembers = buildHouseholdMembersList(members);
  returnProduct.drivers = householdMembers;

  const propertyPremium = buildProductPremiums(premiums, [
    'Homeowner',
    'Condominium',
    'Tenant',
  ]);
  if (!!propertyPremium) {
    returnProduct.propertyPremium =
      currencyPipe.transform(propertyPremium, 'USD', 'symbol', '1.2-2') ||
      undefined;
  }

  const autoPremium = buildProductPremiums(premiums, ['PersonalAuto']);
  if (!!autoPremium) {
    returnProduct.autoPremium =
      currencyPipe.transform(autoPremium, 'USD', 'symbol', '1.2-2') ||
      undefined;
  }

  const boatPremium = buildProductPremiums(premiums, ['Boat']);
  if (!!boatPremium) {
    returnProduct.boatPremium =
      currencyPipe.transform(boatPremium, 'USD', 'symbol', '1.2-2') ||
      undefined;
  }

  const msaPremium = buildProductPremiums(premiums, ['MSA', 'RV']);
  if (!!msaPremium) {
    returnProduct.msaPremium =
      currencyPipe.transform(msaPremium, 'USD', 'symbol', '1.2-2') || undefined;
  }
}
