import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { MultiProductDiscountService } from './multi-product-discount.service';
import { EligibleDiscountsService } from '@core/services/eligible-discounts.service';
import { combineLatest, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  mergeMap,
  pairwise,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ProductType } from '@core/models/api/dsm-types';
import { ProductsService } from '@core/services/products.service';
import { StringUtils } from '@shared/utils/string.utils';
import { EligibleDiscountsActions, RetrieveActions } from '@core/store/actions';
import { EligibleDiscountsEntity } from '@entities/eligible-discounts/eligible-discounts.entity';
import { getMultiProductDiscountsForm } from '@forms-store/store/models/multi-product-discounts-form/multi-product-discounts-form.selector';
import { MultiProductDiscountsFormModel } from '@forms-store/store/models/multi-product-discounts-form/multi-product-discounts-form.reducer';
import {
  updateMultiProductDiscountsForm,
  updatePartialMultiProductDiscountsForm,
} from '@forms-store/store/models/multi-product-discounts-form/multi-product-discounts-form.action';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { syncMultiProductDiscountsToBackEnd } from './multi-product-discount.action';

@Injectable({
  providedIn: 'root',
})
export class MultiProductDiscountEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private productsService: ProductsService,
    private eligibleDiscountsService: EligibleDiscountsService,
    private multiProductDiscountService: MultiProductDiscountService
  ) {}

  reexamineMultiProductDiscounts$ = createEffect(() =>
    combineLatest([
      this.getSelectedProductTypes(),
      this.actions$.pipe(
        ofType(
          EligibleDiscountsActions.getPolicyLineSuccess,
          syncMultiProductDiscountsToBackEnd
        )
      ),
    ]).pipe(
      withLatestFrom(
        this.eligibleDiscountsService.getAllEligibleDiscounts(),
        this.store.select(getMultiProductDiscountsForm)
      ),
      map(([[productTypes, action], discounts, form]) => {
        // All business logic is in this service call:
        return this.multiProductDiscountService.determineChanges(
          productTypes,
          discounts,
          form
        );
      }),
      withLatestFrom(
        this.eligibleDiscountsService.getEligibleDiscountCallsInFlight()
      ),
      map(([changes, callsInFlight]) =>
        this.filterOutCallsInFlight(changes, callsInFlight)
      ),
      mergeMap((changes) => changes)
    )
  );

  private getSelectedProductTypes(): Observable<ProductType[]> {
    return this.productsService
      .getSelectedProductTypes()
      .pipe(
        distinctUntilChanged((a, b) =>
          StringUtils.unorderedStringListsEquivalent(a, b)
        )
      );
  }

  private filterOutCallsInFlight(
    changes: Action[],
    callsInFlight: string[]
  ): Action[] {
    for (const call of callsInFlight) {
      const [productType, eligibleDiscountId] = call.split('.');
      const index = changes.findIndex((action) => {
        if (this.isUpdatePolicyLine(action)) {
          return (
            productType === action.entity.productType &&
            eligibleDiscountId === action.entity.eligibleDiscountId
          );
        }
        return false;
      });
      if (index >= 0) {
        changes = [...changes];
        changes.splice(index, 1);
      }
    }
    return changes;
  }

  private isUpdatePolicyLine(action: Action): action is {
    type: '[Eligible Discounts] Update Policy Line';
    entity: EligibleDiscountsEntity;
  } {
    return (
      action.type === '[Eligible Discounts] Update Policy Line' &&
      action.hasOwnProperty('entity')
    );
  }

  updateMpdFormWhenSelectedProductsChange$ = createEffect(() =>
    this.productsService.getSelectedProductTypes().pipe(
      pairwise(),
      withLatestFrom(this.store.select(getMultiProductDiscountsForm)),
      map(([[prev, next], form]) => {
        const removed = this.getProductsAdded(next, prev);
        const added = this.getProductsAdded(prev, next);
        if (!removed.length && !added.length) {
          return null;
        }
        return this.formActionForProductChange(added, removed, form);
      }),
      filterOutNull()
    )
  );

  rebuildMpdFormFromScratchAfterRetrieve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RetrieveActions.allRetrievesComplete),
      withLatestFrom(this.eligibleDiscountsService.getAllEligibleDiscounts()),
      map(([action, discounts]) => this.formFromDiscounts(discounts)),
      map((form) => updateMultiProductDiscountsForm({ model: form }))
    )
  );

  private getProductsAdded(
    prev: ProductType[],
    next: ProductType[]
  ): ProductType[] {
    return next.filter((productType) => prev.indexOf(productType) < 0);
  }

  private formActionForProductChange(
    added: ProductType[],
    removed: ProductType[],
    form: MultiProductDiscountsFormModel
  ): Action | null {
    const changes: Partial<MultiProductDiscountsFormModel> = {};
    for (const productType of removed) {
      switch (productType) {
        case 'Homeowner':
          changes.property = '';
          break;
        case 'Tenant':
          changes.property = '';
          break;
        case 'Condominium':
          changes.property = '';
          break;
        case 'PersonalAuto':
          changes.personalAuto = false;
          break;
        case 'MSA':
          changes.powersports = false;
          break;
        case 'PersonalUmbrella':
          changes.personalUmbrella = false;
          break;
        case 'Boat':
          changes.boatRv = false;
          break;
        case 'RV':
          changes.boatRv = false;
          break;
      }
    }
    for (const productType of added) {
      switch (productType) {
        case 'Homeowner':
          changes.property = 'Homeowner';
          break;
        case 'Tenant':
          changes.property = 'Tenant';
          break;
        case 'Condominium':
          changes.property = 'Condominium';
          break;
        case 'PersonalAuto':
          changes.personalAuto = true;
          break;
        case 'MSA':
          changes.powersports = true;
          break;
        case 'PersonalUmbrella':
          changes.personalUmbrella = true;
          break;
        case 'Boat':
          changes.boatRv = true;
          break;
        case 'RV':
          changes.boatRv = true;
          break;
      }
    }
    // Now remove any fields that match prior state. If it goes empty, take no action.
    if (changes.property === form.property) delete changes.property;
    if (changes.personalAuto === form.personalAuto) delete changes.personalAuto;
    if (changes.powersports === form.powersports) delete changes.powersports;
    if (changes.personalUmbrella === form.personalUmbrella)
      delete changes.personalUmbrella;
    if (changes.boatRv === form.boatRv) delete changes.boatRv;
    if (!Object.keys(changes).length) return null;
    return updatePartialMultiProductDiscountsForm({ model: changes });
  }

  /** Only for immediately after retrieve.
   * Replace the entire form, based only on eligibleDiscounts.
   * Once the session is up and running, the form is (mostly) read-only to us, for the user to modify.
   */
  private formFromDiscounts(
    discounts: EligibleDiscountsEntity[]
  ): MultiProductDiscountsFormModel {
    // There's just one Problem Child discount with values other than "true" and "false":
    const homeCarIndicator =
      discounts.find((d) => d.eligibleDiscountId === 'HomeCarIndicator_I')
        ?.selectedOptionValue || 'None';

    const enabled = (id: string) =>
      !!discounts.find(
        (d) => d.eligibleDiscountId === id && d.selectedOptionValue === 'true'
      );

    const form: MultiProductDiscountsFormModel = {
      property: '',
      personalAuto: enabled('Auto'),
      farmAuto: false,
      powersports: enabled('Powersports'),
      personalUmbrella: enabled('Umbrella') || enabled('Umbrella/Pet'),
      vetPet: enabled('VetPet'),
      farm: enabled('FarmPP') || enabled('FarmAgribusiness'),
      commercial: enabled('Commercial'),
      nationwideBank: enabled('Bank'),
      homeFinancial: enabled('HomeFinancial') || enabled('FinancialProduct'),
      annuities: enabled('AnnuityFP'),
      boatRv: enabled('RV'),
      nonNWHomeowner: enabled('NNWHomeownersPP'),
      nonNWCondo: enabled('NNWCondoPP'),
    };

    if (enabled('HomeownerPP')) form.property = 'Homeowner';
    else if (enabled('TenantsPP')) form.property = 'Tenant';
    else if (enabled('CondoPP')) form.property = 'Condominium';

    if (!form.personalAuto) {
      if (
        homeCarIndicator === 'NationwideAutoPolicy' ||
        homeCarIndicator === 'NWAutoAndNWFarmAuto'
      ) {
        form.personalAuto = true;
      }
    }
    if (
      homeCarIndicator === 'NWFarmAuto' ||
      homeCarIndicator === 'NWAutoAndNWFarmAuto'
    ) {
      form.farmAuto = true;
    }

    // Finally, we should check the boxes for all selected products, if they aren't already.
    const setField = (k: keyof MultiProductDiscountsFormModel, v: any) => {
      if (form[k] === v) return;
      (form as any)[k] = v;
    };
    for (const productType of Array.from(
      new Set(discounts.map((d) => d.productType))
    )) {
      switch (productType) {
        case 'Homeowner':
          setField('property', 'Homeowner');
          break;
        case 'Condominium':
          setField('property', 'Condominium');
          break;
        case 'Tenant':
          setField('property', 'Tenant');
          break;
        case 'PersonalAuto':
          setField('personalAuto', true);
          break;
        case 'MSA':
          setField('powersports', true);
          break;
        case 'PersonalUmbrella':
          setField('personalUmbrella', true);
          break;
        case 'Boat':
          setField('boatRv', true);
          break;
        case 'RV':
          setField('boatRv', true);
          break;
      }
    }

    return form;
  }
}
