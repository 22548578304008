<ng-container *ngIf="vm$ | async as vm">
  <nwx-card class="bolt-typography">
    <div header class="bolt-container-fluid">
      <h2 class="card-header-title">{{ header }}</h2>
    </div>
    <div class="bolt-container-fluid">
      <div class="discount-title">
        <img
          src="../../../../assets/images/icons/money-colorized.svg"
          alt="Two overlapping dollar bills"
        />
        <strong>Discounts and surcharges applied</strong>
      </div>
      <ul class="bolt-col-lg-12 bolt-space-bottom-xs" *ngIf="discounts.length">
        <li
          class="bolt-col-lg-4 bolt-col-md- override-bottom-margin"
          *ngFor="
            let discount of discounts
              | alphabetizeObjectsByAttribute : 'description'
          "
        >
          &bull; {{ discount.description }}
        </li>
      </ul>
      <p *ngIf="!discounts.length">
        No discounts have been applied to this quote
      </p>
    </div>
    <hr class="card-full-width-hr bolt-space-bottom-sm" />
    <div class="bolt-container-fluid" *ngIf="vm?.driverDiscountsApplicable">
      <div class="bolt-row bolt-space-bottom-sm bolt-align-items-center">
        <div class="bolt-col-sm-6 bolt-col-xs-12">
          <p class="stronger">Find more driver discounts</p>
        </div>
        <div class="bolt-col-sm-6 bolt-col-xs-12 text-align-center">
          <bolt-button
            [attr.id]="'memberDiscountsButton-' + product.type"
            (click)="navigateToMemberPage()"
            [attr.size]="maxSizeXSmall ? 'sm' : 'md'"
            >Answer discount questions</bolt-button
          >
        </div>
      </div>
      <div class="bolt-row">
        <div class="bolt-col-12">
          <hr class="no-margin bolt-space-bottom-sm" />
        </div>
      </div>
      <div class="bolt-row bolt-align-items-center">
        <div class="bolt-col-sm-6 bolt-col-xs-12">
          <p class="stronger">Find more vehicle discounts</p>
        </div>
        <div class="bolt-col-sm-6 bolt-col-xs-12 text-align-center">
          <bolt-button
            [attr.id]="'vehicleDiscountsButton-' + product.type"
            (click)="navigateToPage()"
            [attr.size]="maxSizeXSmall ? 'sm' : 'md'"
            >Answer discount questions</bolt-button
          >
        </div>
      </div>
      <div *ngIf="!hasPaperlessDiscount && eligibleForPaperless">
        <div class="bolt-row">
          <div class="bolt-col-12">
            <hr class="no-margin bolt-space-bottom-sm" />
          </div>
        </div>

        <div class="bolt-row bolt-align-items-center">
          <div class="bolt-col-sm-6 bolt-col-xs-12">
            <p class="stronger">Paperless policy discount</p>
          </div>
          <div class="bolt-col-sm-6 bolt-col-xs-12 text-align-center">
            <bolt-button
              [attr.id]="'paperlessDiscountsButton-' + product.type"
              (click)="navigateToPniPage()"
              [attr.size]="maxSizeXSmall ? 'sm' : 'md'"
              >Answer discount questions</bolt-button
            >
          </div>
        </div>
      </div>
      <div
        class="bolt-row bolt-align-items-center"
        *ngIf="hasPaperlessDiscount && eligibleForPaperless"
      >
        <div class="bolt-col-12">
          <hr class="bolt-space-bottom-sm" />
        </div>
        <div class="bolt-col-12">
          <div class="bolt-row bolt-no-gutters bolt-align-items-center">
            <div class="bolt-col-auto">
              <bolt-icon
                name="info-circle-filled"
                color="theme-info"
                class="sub-align"
              ></bolt-icon>
            </div>
            <div class="bolt-col">
              <p class="stronger">
                Paperless Policy Discount has been applied.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bolt-container-fluid" *ngIf="!vm.driverDiscountsApplicable">
      <div class="bolt-row bolt-space-bottom-sm bolt-align-items-center">
        <div class="bolt-col-sm-6 bolt-col-xs-12">
          <p class="stronger">Find more {{ product?.name }} discounts</p>
        </div>
        <div class="bolt-col-sm-6 bolt-col-xs-12 text-align-center">
          <bolt-button
            [attr.id]="'productDiscountButton' + product.type"
            (click)="navigateToPage()"
            [attr.size]="maxSizeXSmall ? 'sm' : 'md'"
            >Answer discount questions</bolt-button
          >
        </div>
      </div>
    </div>
  </nwx-card>
</ng-container>
