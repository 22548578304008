import { EligibleDiscountsEntity } from '@core/store/entities/eligible-discounts/eligible-discounts.entity';
import { Nullable } from '@shared/utils/type.utils';
import { DriverType, NonDriverReason, ProductType } from '../api/dsm-types';

export interface BaseEntity {
  entityId: string | number;
  prefillId?: string;
  productIds?: ProductType[];
  productType?: ProductType;
  quoteId?: string;
  selected?: boolean;
}

export const BaseEntitySanitizerFields = {
  entityId: 'string',
  prefillId: 'string',
  productIds: ['string'],
  productType: 'string',
  quoteId: 'string',
  selected: 'boolean',
};
export const BaseEntityOptionalFields = [
  'prefillId',
  'productIds',
  'productType',
  'quoteId',
  'selected',
];

export type DsmMemberEntityType = 'policyHolder' | 'driver' | 'householdMember';

export type PolicyRoleSequence = '' | 'primary' | 'secondary' | 'additional';

export interface MemberPolicyRole {
  productType: ProductType;
  entityType: DsmMemberEntityType;
  entityId: number;
  roleSequence?: PolicyRoleSequence;
}

export interface MemberDriverRole {
  productType: ProductType;
  driverType: DriverType;
  reasonNonDriver?: NonDriverReason;
  entityId: number;
}

export interface BasePersonEntity extends BaseEntity {
  eligibleDiscountIds?: {
    [productId: string]: Nullable<EligibleDiscountsEntity[]>;
  };
  policyRoles: MemberPolicyRole[];
  driverRoles?: MemberDriverRole[];
}
