<div class="bolt-container">
  <div class="panel" *ngFor="let report of reportDetails">
    <div
      class="panel-header bolt-row bolt-background-pale-gray-50 bolt-justify-content-between bolt-space-inner-wide-xl"
    >
      <div class="bolt-col-4 reports-details">
        <img
          class="product-image"
          src="assets/images/icons/person-circle-colorized.svg"
        />{{ report.driverName }}
      </div>
      <div class="bolt-col-2">
        <div class="reports-icon" *ngIf="report.status === 'Clean'">
          <bolt-icon
            name="checkmark-circle-filled"
            color="theme-success"
          ></bolt-icon
          >&nbsp;&nbsp;Clear
        </div>
        <div class="reports-icon" *ngIf="report.status !== 'Clean'">
          <bolt-icon
            name="exclamation-triangle-filled"
            color="theme-warning"
          ></bolt-icon
          >&nbsp;&nbsp;Adverse
        </div>
      </div>
    </div>
    <div
      class="panel-body bolt-space-inner-xl"
      *ngIf="report.claims.length > 0"
    >
      <ng-container *ngFor="let claim of report.claims">
        <div class="bolt-row" *ngFor="let coverage of claim.claimCoverages">
          <div class="bolt-col-6"><strong>Claim type</strong></div>
          <div class="bolt-col-6">{{ coverage.claimType }}</div>
          <div class="bolt-col-6"><strong>Claim status</strong></div>
          <div class="bolt-col-6">{{ coverage.status }}</div>
          <div class="bolt-col-6"><strong>Amount paid</strong></div>
          <div class="bolt-col-6">{{ coverage.amountPaid }}</div>
          <div class="bolt-col-6"><strong>Claim date</strong></div>
          <div class="bolt-col-6">{{ claim.claimDate }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
