<div
  class="outer"
  [ngClass]="{ 'profile-collapsed': !menuOpen }"
  *ngIf="maxSizeXSmall"
></div>
<div class="wrapper">
  <div
    #buttonElement
    class="button"
    [ngClass]="{ open: menuOpen }"
    (click)="toggleMenu()"
    (mouseover)="addHover()"
    (mouseleave)="removeHover()"
  >
    <bolt-icon
      name="person"
      class="toggle-margin-right"
      class="circle"
      decorative
      size="sm"
      [attr.id]="'profilePersonIcon'"
      [attr.color]="hoverStyling ? 'black' : 'mint'"
    ></bolt-icon>
    <span id="profile-menu-toggle-label" *ngIf="!maxSizeXSmall">{{
      userNameForToggleButton
    }}</span>
    <bolt-icon
      [name]="menuOpen ? 'chevron-up' : 'chevron-down'"
      size="sm"
      class="toggle-margin-left"
      [attr.color]="hoverStyling ? 'black' : 'white'"
      [attr.id]="'profileChevIcon'"
    ></bolt-icon>
  </div>
  <div
    #menuElement
    class="menu"
    [@.disabled]="!maxSizeXSmall"
    [@slide]="maxSizeXSmall"
    *ngIf="menuOpen"
  >
    <ng-container *ngIf="maxSizeXSmall">
      <div class="close-icon">
        <bolt-icon name="xmark" size="sm" (click)="toggleMenu()"></bolt-icon>
      </div>
    </ng-container>
    <div class="upper-message">
      <div class="logged-in-as">Logged in as</div>
      <div class="username">
        {{ userName }}
      </div>
    </div>

    <a
      class="link agent-center-link"
      [href]="agentCenterUrl"
      *ngIf="agentCenterUrl"
    >
      <span> Go to Agent Center </span>
      <bolt-icon
        name="external-link"
        color="medium-blue"
        size="sm"
        class="vertical-align-sub"
      ></bolt-icon>
    </a>
    <a
      id="seeWhatsNewLink"
      class="link"
      href="javascript:0"
      (click)="openAnnouncementOverlay()"
    >
      <span> See what's new </span>
    </a>
    <a id="logOutLink" class="link" href="javascript:0" (click)="onLogOut()"
      ><span> Log out </span>
    </a>
  </div>
</div>
