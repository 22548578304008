import {
  updateCurrentCarrier,
  updateCurrentCarrierFail,
  updateCurrentCarrierSuccess,
} from './../store/entities/current-carrier/current-carrier.action';
import { CurrentCarrierAdapter } from './../adapters/current-carrier.adapter';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UpdateCurrentCarrierRequest } from '../models/api/request/update-current-carrier-request.model';
import { CurrentCarrierEntity } from '../store/entities/current-carrier/current-carrier.reducer';
import { ProductModel } from '../store/entities/product/product.model';
import { Store } from '@ngrx/store';
import { getCurrentCarrier } from '../store/entities/current-carrier/current-carrier.action';
import * as fromSelectors from '@core/store/entities/current-carrier/current-carrier.selector';
import { Nullable } from '@shared/utils/type.utils';
import {
  CurrentCarrierFormModel,
  CurrentCarrierFormOptions,
} from '../interfaces/interfaces';
import { CurrentCarrierActions } from '../store/actions';
import { CurrentCarrierUtils } from '@shared/utils/current-carrier.utils';
import { ProductType } from '@core/models/api/dsm-types';
import { StringUtils } from '@shared/utils/string.utils';
import { Actions, ofType } from '@ngrx/effects';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrentCarrierService {
  constructor(
    private store: Store,
    private currentCarrierAdapter: CurrentCarrierAdapter,
    private actions$: Actions
  ) {}

  getCurrentCarrierFormModel(
    productId: ProductType
  ): Observable<CurrentCarrierFormModel> {
    return this.store.select(
      fromSelectors.getCurrentCarrierFormModel(productId)
    );
  }

  getCurrentCarrierFormOptions(
    productId: ProductType
  ): Observable<CurrentCarrierFormOptions> {
    return this.store.select(
      fromSelectors.getCurrentCarrierFormOptions(productId)
    );
  }

  getMsaCurrentCarrierFormOptions(
    productId: ProductType
  ): Observable<CurrentCarrierFormOptions> {
    return this.store.select(
      fromSelectors.getMsaCurrentCarrierFormOptions(productId)
    );
  }

  saveIfChanged(
    newModel?: CurrentCarrierFormModel,
    oldModel?: CurrentCarrierFormModel
  ): Promise<CurrentCarrierEntity | null> {
    if (!newModel) {
      return Promise.resolve(null);
    }
    if (this.currentCarrierFormModelsEquivalent(newModel, oldModel)) {
      return Promise.resolve(null);
    }
    return this.updateCurrentCarrierAndAwaitResult(
      CurrentCarrierUtils.currentCarrierEntityFromForm(newModel)
    );
  }

  currentCarrierFormModelsEquivalent(
    a?: CurrentCarrierFormModel,
    b?: CurrentCarrierFormModel
  ): boolean {
    if (a === b) {
      return true;
    }
    if (!a || !b) {
      return false;
    }
    return (
      a.coverageLapse === b.coverageLapse &&
      a.currentCarrierName === b.currentCarrierName &&
      a.currentBodilyInjuryLimit === b.currentBodilyInjuryLimit &&
      a.currentCarrierTerms === b.currentCarrierTerms &&
      a.reasonForCoverageLapse === b.reasonForCoverageLapse
    );
  }

  updateCurrentCarrierAndAwaitResult(
    entity: CurrentCarrierEntity
  ): Promise<CurrentCarrierEntity> {
    return new Promise((resolve, reject) => {
      let correlationId: string;
      this.actions$
        .pipe(
          ofType(updateCurrentCarrierSuccess, updateCurrentCarrierFail),
          filter((action) => action.correlationId === correlationId),
          take(1)
        )
        .subscribe(
          (action) => {
            if (action.type === updateCurrentCarrierSuccess.type) {
              resolve?.(action.payload);
            } else {
              reject?.(action?.error);
            }
            resolve = null as unknown as () => void;
            reject = null as unknown as () => void;
          },
          (error) => reject?.(error),
          () => reject?.('unexpected completion')
        );
      correlationId = this.dispatchUpdateCurrentCarrier(entity);
    });
  }

  dispatchUpdateCurrentCarrier(entity: CurrentCarrierEntity): string {
    const correlationId = StringUtils.generateUuid();
    this.store.dispatch(
      updateCurrentCarrier({ payload: entity, correlationId })
    );
    return correlationId;
  }

  updateCurrentCarrier(
    request: UpdateCurrentCarrierRequest
  ): Observable<CurrentCarrierEntity> {
    return this.currentCarrierAdapter.updateCurrentCarrier(request);
  }

  storeCurrentCarrier(currentCarrier: CurrentCarrierFormModel): void {
    this.store.dispatch(
      CurrentCarrierActions.storeCurrentCarrier({
        payload:
          CurrentCarrierUtils.currentCarrierEntityFromForm(currentCarrier),
      })
    );
  }

  getCurrentCarrierHit(): Observable<boolean> {
    return this.store.select(fromSelectors.getCurrentCarrierHit);
  }

  loadCurrentCarrier(): void {
    this.store.dispatch(getCurrentCarrier());
  }

  buildUpdateCurrentCarrierRequest(
    currentCarrier: CurrentCarrierEntity,
    product: Nullable<ProductModel>
  ): UpdateCurrentCarrierRequest {
    const request = {
      quoteId: product?.quoteId,
      productType: product?.type,
      body: {
        currentCarrier: { ...currentCarrier },
      },
    } as UpdateCurrentCarrierRequest;
    const terms = +(request.body.currentCarrier.currentCarrierTerms ?? -1);
    if (terms < 0) {
      request.body.currentCarrier.currentCarrierTerms = 0;
    } else if (terms > 99) {
      request.body.currentCarrier.currentCarrierTerms = 99;
    }

    return request;
  }
}
