import { HeaderService } from '@core/services/header.service';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { LogService } from '@core/services/log.service';
import { LogEventName } from '@core/models/api/log.model';

@Component({
  selector: 'nwx-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplashComponent implements OnInit {
  constructor(private headerService: HeaderService, private log: LogService) {}

  ngOnInit(): void {
    this.headerService.suppressNavBar();
    this.headerService.suppressWelcomeMessage();
    this.headerService.suppressProfileComponent();
    this.log.logUiEvent('load-page-splash' as LogEventName);
  }
}
