<div *ngIf="vm$ | async as vm">
  <div class="modal-header">
    <div
      class="modal-header-title bolt-body-copy-lg"
      id="msbEstimateModalHeader"
    >
      MSB estimate
    </div>
    <div class="bolt-body-copy-sm">
      Current estimate {{ associatedMSBEstimateNumber }}
    </div>
  </div>
  <div class="modal-body bolt-background-pale-gray-25">
    Property details will be updated on retrieval of entered estimate number.
  </div>
  <div class="modal-body">
    <nwx-msb-estimate-number
      [associatedMSBEstimateNumber]="associatedMSBEstimateNumber"
      (formReady)="addChildForm('msbEstimate', $event)"
    ></nwx-msb-estimate-number>
    <ng-container *ngIf="vm.showRecalculateMessageAndButton">
      <div class="message-card bolt-space-bottom-lg">
        <div class="bolt-container">
          <div class="bolt-row message-row">
            <div class="bolt-col-auto">
              <bolt-icon
                name="info-circle-filled"
                color="dark-sea-blue"
              ></bolt-icon>
            </div>
            <div class="bolt-col message-text">
              Retrieving a MSB estimate will result in a recalculation of this
              submisssion.
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="vm.showNotFoundErrorMessage">
      <div class="message-card bolt-space-bottom-lg">
        <div class="bolt-container">
          <div class="bolt-row message-row">
            <div class="bolt-col-auto">
              <bolt-icon
                name="info-circle-filled"
                color="cherry-red"
              ></bolt-icon>
            </div>
            <div class="bolt-col message-text">
              No report found with this number.
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="vm.showRestoreMsbEstimateNumber">
      <a
        class="link"
        id="restore-msb-number"
        href="javascript:0"
        (click)="restore()"
      >
        <bolt-icon name="refresh" color="blue"></bolt-icon>
        <span class="link-text">
          Restore to first MSB report {{ initialMSBEstimateNumber }}</span
        >
      </a>
    </ng-container>
  </div>
  <div class="modal-footer">
    <bolt-button-bar>
      <bolt-button slot="cancel" id="cancel" (click)="close()"
        >Cancel</bolt-button
      >
      <bolt-button
        slot="forward"
        type="primary"
        id="retrieveReport"
        (click)="submit()"
      >
        {{ getSubmitButtonText(vm.showRecalculateMessageAndButton || false) }}
      </bolt-button>
    </bolt-button-bar>
  </div>
</div>
