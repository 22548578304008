export class BankCardUtils {
  static cardType: { [key: string]: string } = {
    MAST: 'MasterCard',
    VISA: 'Visa',
    AMEX: 'American Express',
    DISC: 'Discover',
  };

  static mapCardToCardType(card: string | undefined) {
    if (!card) {
      return undefined;
    }
    return BankCardUtils.cardType[card];
  }
}
