<div class="bg-pale pt-20">
  <ng-container *ngFor="let section of model.sections">
    <nwx-card>
      <nwx-card-header
        ngProjectAs="[header]"
        title="{{ section.productDescription }} reports & information"
      ></nwx-card-header>
      <div class="bolt-container-fluid">
        <ng-container *ngIf="model.loading">
          <div class="loading-row">
            <div class="reports-icon">
              <bolt-waiting-indicator minimal></bolt-waiting-indicator>
            </div>
            <div class="reports-loading">
              Collecting underwriting reports...
            </div>
          </div>
        </ng-container>
        <div *ngFor="let report of section.reports" class="bolt-row report-row">
          <div class="bolt-col-1 icon-column">
            <bolt-icon
              *ngIf="report.iconName"
              [name]="report.iconName"
              [attr.color]="report.iconColor"
              [attr.size]="model.maxSizeMini ? 'sm' : 'md'"
            ></bolt-icon>
          </div>
          <div class="bolt-col-3 title-column">
            <strong>{{ report.title }}</strong>
          </div>
          <div
            class="summary-column"
            [class.bolt-col-4]="report.linkBehavior || report.linkText"
            [class.bolt-col-8]="!report.linkBehavior && !report.linkText"
          >
            {{ report.summary }}
          </div>
          <div class="bolt-col-4 link-column">
            <a
              *ngIf="report.linkBehavior; else textLink"
              href="#"
              [id]="report.linkElementId"
              (click)="onClickReportLink(report, $event)"
              >{{ report.linkText }}</a
            >
            <ng-template #textLink>
              {{ report.linkText }}
            </ng-template>
          </div>
        </div>
        <ng-container *ngIf="section.autoCharacteristics">
          <hr />
          <div class="bolt-row auto-characteristics-report-row">
            <div class="bolt-col-1 icon-column">
              <bolt-icon
                name="checkmark-circle-filled"
                color="theme-success"
                [attr.size]="model.maxSizeMini ? 'sm' : 'md'"
              ></bolt-icon>
            </div>
            <div class="bolt-col-3 title-column">
              <strong>Auto characteristics report</strong>
            </div>
          </div>
          <div class="bolt-row auto-characteristics-report-row">
            <div class="bolt-col-1 icon-column"></div>
            <div class="bolt-col-3 title-column">
              Auto BI Occurrence Limit (highest)
            </div>
            <div class="summary-column bolt-col-auto">
              {{ section.autoCharacteristics.autoBiOccurLimit }}
            </div>
          </div>
          <div class="bolt-row auto-characteristics-report-row">
            <div class="bolt-col-1 icon-column"></div>
            <div class="bolt-col-3 title-column">
              Auto BI per person limit (highest)
            </div>
            <div class="summary-column bolt-col-auto">
              {{ section.autoCharacteristics.autoBiPerPersonLimit }}
            </div>
          </div>
          <div class="bolt-row report-row">
            <div class="bolt-col-1 icon-column"></div>
            <div class="bolt-col-3 title-column">
              Minimum Vehicle Model Year
            </div>
            <div class="summary-column bolt-col-auto">
              {{ section.autoCharacteristics.minVehicleModelYear }}
            </div>
          </div>
        </ng-container>
      </div>
    </nwx-card>
  </ng-container>
  <div class="button-row">
    <bolt-button
      [attr.disabled]="model.refreshEnabled ? null : 'disabled'"
      [attr.id]="'uw-refresh-report'"
      (click)="refreshReports()"
      [attr.size]="model.maxSizeMini ? 'sm' : 'md'"
      >Refresh reports</bolt-button
    >
  </div>
</div>
