import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { VehicleModel } from '@core/models/views/vehicle.model';
import { TaskModel } from '@core/store/entities/task/task.model';
import { GeneralUtils } from '@shared/utils/general.utils';
import { Nullable } from '@shared/utils/type.utils';
import { VehicleUtils } from '@shared/utils/vehicle.utils';

@Component({
  selector: 'nwx-tasks-vehicles-list',
  templateUrl: './tasks-vehicles-list.component.html',
  styleUrls: ['./tasks-vehicles-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksVehiclesListComponent implements OnInit {
  @Input() vehicleTasks!: Nullable<TaskModel[]>;
  @Input() vehicles!: VehicleModel[];
  @Input() submitted!: boolean;

  @Output() valueChange = new EventEmitter<VehicleModel[]>();
  @Output() formReady = new EventEmitter<FormGroup>();

  form!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      vehicles: this.fb.array(this.vehicles?.map((v) => this.fb.group({}))),
    });

    this.formReady.emit(this.form);
  }

  get formVehicles(): FormArray {
    return this.form.get('vehicles') as FormArray;
  }

  onValueChange(changes: Partial<VehicleModel>): void {
    const vehicleIndex = VehicleUtils.getVehicleIndex(this.vehicles, changes);
    if (vehicleIndex > -1) {
      this.vehicles = GeneralUtils.pureReplace<VehicleModel>(
        this.vehicles,
        changes,
        vehicleIndex
      );
      this.valueChange.emit(this.vehicles);
    }
  }

  addChildForm(child: FormGroup, index: number, name: string): void {
    let group = this.formVehicles.at(index) as FormGroup;
    if (!group && index === -1) {
      group = this.form;
    }
    if (!!group) {
      group.setControl(name, child);
    } else {
      this.formVehicles.setControl(index, child);
    }
  }

  getVehicleSpecificTasks(vehicle: VehicleModel): TaskModel[] | undefined {
    return this.vehicleTasks?.filter(
      (t) => t.entityId === vehicle.vehicleId?.toString()
    );
  }

  trackVehicles(index: number, vehicle: VehicleModel): string {
    return vehicle.vehicleId as string;
  }

  isVehicleFormInvalid(index: number): boolean {
    return (
      (this.form.get('vehicles') as FormArray)?.at(index)?.status ===
        'INVALID' && this.submitted
    );
  }
}
