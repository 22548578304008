<div class="modal-header bolt-space-bottom-2xl">
  <h4>{{ headerText }}</h4>
</div>

<div class="modal-body bolt-container-fluid">
  <ng-container *ngIf="notification.type">
    <ng-container [ngSwitch]="notification.type">
      <div *ngSwitchCase="'info-circle-filled'" class="bolt-space-bottom-lg">
        <bolt-notification type="info">
          <strong *ngIf="notification.header">{{ notification.header }}</strong>
          <p>
            {{ notification.message }}
          </p>
        </bolt-notification>
      </div>
    </ng-container>
    <hr />
  </ng-container>

  <div *ngFor="let bundle of content; let i = index">
    <div
      class="bolt-row bolt-align-items-center bolt-no-gutters bolt-space-bottom-lg"
    >
      <div class="bolt-col-auto">
        <span class="number-block">{{ i + 1 }}</span>
      </div>
      <div class="bolt-col-auto">
        <p class="modal-section-title">{{ bundle.header }}</p>
      </div>
    </div>

    <ng-container [ngSwitch]="bundle.contentType" ]>
      <ng-container *ngSwitchCase="'tile'">
        <div
          class="bolt-row bolt-align-items-center bolt-background-pale-gray-25 standard-rounded-border info-tile bolt-space-bottom-lg offset"
        >
          <div class="bolt-col-auto tile-icon">
            <img src="{{ bundle.iconUrl }}" />
          </div>
          <div class="bolt-col">
            <div class="bolt-heading-xs">
              {{ bundle?.orderedText?.first }}
            </div>
            <div class="bolt-body-copy-md">
              {{ bundle?.orderedText?.second }}
            </div>
            <div class="bolt-body-copy-md">
              {{ bundle?.orderedText?.third }}
            </div>
          </div>
        </div>
      </ng-container>

      <div *ngSwitchCase="'productTiles'">
        <div class="bolt-row" *ngIf="bundle.text">
          <div class="bolt-col-12" *ngFor="let line of bundle.text">
            <p class="offset">{{ line }}</p>
          </div>
        </div>
        <div class="offset">
          <div class="bolt-row">
            <div class="bolt-col-auto" *ngFor="let product of bundle.products">
              <div
                class="bolt-background-pale-gray-25 standard-rounded-border info-tile bolt-space-bottom-lg"
              >
                <div class="flex-row align-items-center">
                  <img
                    src="{{ product.imageUrl }}"
                    alt="member icon"
                    class="bolt-space-right-md"
                  />
                  <div>
                    <div class="bolt-body-copy-lg font-weight-med">
                      {{ product.name }}
                    </div>
                    <div *ngIf="product.quoteId">
                      Submission #{{ product.quoteId }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngSwitchDefault>
        <div class="bolt-row text-placement">
          <p>{{ bundle.text }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="modal-footer">
  <bolt-button-bar>
    <bolt-button
      *ngIf="secondaryButtonText"
      slot="cancel"
      (click)="dispatchButtonAction('secondary')"
      >{{ secondaryButtonText }}</bolt-button
    >
    <bolt-button
      *ngIf="primaryButtonText"
      slot="forward"
      (click)="dispatchButtonAction('primary')"
      type="primary"
      >{{ primaryButtonText }}</bolt-button
    >
  </bolt-button-bar>
</div>
