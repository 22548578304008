import { Injectable } from '@angular/core';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, from, of } from 'rxjs';
import { catchError, exhaustMap, take } from 'rxjs/operators';

import { VehicleExcludedExposureActions } from '@core/store/actions';
import { VehicleExcludedExposureSelectors } from '@core/store/selectors';
import { VehicleExposureService } from '@core/services/vehicle-exposure.service';

@Injectable()
export class VehicleExcludedExposureEffect {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: VehicleExposureService,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  deleteVehicleExcludedExposure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehicleExcludedExposureActions.deleteVehicleExcludedExposure),
        exhaustMap((action) =>
          this.store
            .select(
              VehicleExcludedExposureSelectors.buildVehicleExcludedExposureRequest(
                action.payload,
                action.correlationId || ''
              )
            )
            .pipe(take(1))
        ),
        exhaustMap((request) => {
          return this.service.deleteVehicleExcludedExposure(request).pipe(
            exhaustMap(() => {
              return from([
                VehicleExcludedExposureActions.deleteVehicleExcludedExposureSuccess(
                  {
                    vehicleExcludedExposureId:
                      request.vehicleExcludedExposureBody
                        .vehicleExcludedExposure.vehicleExcludedExposureId,
                  }
                ),
              ]);
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                request.productType
              );
              return of(
                VehicleExcludedExposureActions.deleteVehicleExcludedExposureFail(
                  {
                    error: saneError,
                    entityId:
                      request.vehicleExcludedExposureBody
                        .vehicleExcludedExposure.vehicleExcludedExposureId,
                  }
                )
              );
            })
          );
        })
      ) as Observable<Action>
  );

  upsertVehicleExcludedExposure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehicleExcludedExposureActions.upsertVehicleExcludedExposure),
        exhaustMap((action) =>
          this.store
            .select(
              VehicleExcludedExposureSelectors.buildVehicleExcludedExposureRequest(
                action.payload,
                action.correlationId || ''
              )
            )
            .pipe(take(1))
        ),
        exhaustMap((request) => {
          return this.service.addOrUpdateExcludedVehicleExposure(request).pipe(
            exhaustMap((response) => {
              return from([
                VehicleExcludedExposureActions.upsertVehicleExcludedExposureSuccess(
                  {
                    payload: response,
                  }
                ),
              ]);
            }),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                request.productType
              );
              return of(
                VehicleExcludedExposureActions.upsertVehicleExcludedExposureFail(
                  {
                    error: saneError,
                    entityId:
                      request.vehicleExcludedExposureBody
                        .vehicleExcludedExposure.vehicleExcludedExposureId,
                  }
                )
              );
            })
          );
        })
      ) as Observable<Action>
  );
}
