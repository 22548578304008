import {
  AccountInfoPageRepresentation,
  CondoPageRepresentation,
  CoveragesPageRepresentation,
  getPageRepresentationByProductId,
  HomeownerPageRepresentation,
  PageIdentifier,
  PeoplePageRepresentation,
  VehiclesPageRepresentation,
} from '@core/constants/pages';
import {
  ProductType,
  QuoteStatus,
  TaskProductType,
} from '@core/models/api/dsm-types';
import { Nullable } from '@shared/utils/type.utils';
import { FeatureFlagsModel } from '../../feature-flag/feature-flag.model';
import { VehicleEntity } from '../../vehicle/vehicle.entity';
import { dismissReviewTask } from '../task.action';
import { TaskModel, TaskRule, UpdateTaskModel } from '../task.model';
import { ProductModel } from '../../product/product.model';
import { SessionState } from '../../session/session.reducer';
import { CoveredLocationEntity } from '@core/store/entities/covered-location/covered-location.reducer';
import { CoverageEntity } from '../../coverage/coverage.entity';
import { TaskValidators } from '../task-validators';
import { MemberEntity } from '@entities/member/member.reducer';
import { PolicyDateOptions } from '@core/interfaces/interfaces';
import { EligibilityFormModel } from '@app/eligibility/store/eligibility-form.model';
import { EligibilityFormActions } from '@core/store/actions';
import { DummyProducerProduct } from '@core/store/retrieve/retrieve.selector';
import { AccountActions } from '@core/store/actions';

export interface TaskTemplate {
  rule: TaskRule;
  productTypes?: ProductType[];
  feature?: string;
  quoteStatus?: QuoteStatus;
}
export class TaskUtils {
  static generateUmbrellaTasks(
    product: ProductModel,
    selectedProducts: ProductType[],
    policyDateOptions: PolicyDateOptions
  ): TaskModel[] {
    return TaskValidators.productCustomRules(
      product,
      selectedProducts,
      policyDateOptions
    );
  }

  static generateGeneralTasks(): TaskModel[] {
    return [];
  }

  static generateReviewTasks(
    selectedProducts: ProductType[],
    vehicles: VehicleEntity[],
    drivers: MemberEntity[],
    featureFlags: FeatureFlagsModel,
    sessionState: SessionState,
    telematicsEnrolled: boolean,
    exposureCount: number,
    coveredLocations?: Nullable<CoveredLocationEntity[]>,
    eligibilityForms?: EligibilityFormModel[],
    dummyProducerContent?: DummyProducerProduct[] | null,
    coverages?: CoverageEntity[],
    roofYearImmutable?: boolean
  ): TaskModel[] {
    let returnTasks: TaskModel[] = [];

    if (dummyProducerContent?.length) {
      for (let product of dummyProducerContent) {
        if (selectedProducts.includes(product.productType)) {
          returnTasks.push({
            entityType: 'review',
            field: 'producerCode',
            message: 'Producer code must be updated',
            productType: product.productType,
            ratingStatusOrdinal: 'Draft',
          });
        }
      }
    }

    if (selectedProducts.includes('Homeowner') && !roofYearImmutable) {
      coveredLocations
        ?.filter(
          (coveredLocation) =>
            coveredLocation.productType !== 'Tenant' &&
            selectedProducts.includes(coveredLocation.productType)
        )
        .forEach((coveredLocation) => {
          const roof = coveredLocation?.renovations?.find(
            (r) => r.type === 'Roof'
          );
          if (roof?.isEditable) {
            const pageRepresentation =
              coveredLocation.productType === 'Homeowner'
                ? HomeownerPageRepresentation
                : CondoPageRepresentation;

            returnTasks.push({
              actionMessage: 'Change year',
              page: pageRepresentation,
              field: 'roofYear',
              flipActionButtons: true,
              productType: coveredLocation.productType,
              ratingStatusOrdinal: 'Draft',
              message: 'Roof year last replaced must be confirmed',
            });
          }
        });
    }

    if (sessionState.isComprater) {
      vehicles
        .filter((vehicle) => !vehicle.additionalInterests?.length)
        .forEach((vehicle) =>
          returnTasks.push({
            page: VehiclesPageRepresentation,
            field: 'lenderInfo',
            entityType: 'review',
            entityId: vehicle.vehicleId,
            productType: 'PersonalAuto',
            ratingStatusOrdinal: 'Quoted',
            message:
              'Please review vehicle loan or lease selections for accuracy before continuing.',
            resolve: dismissReviewTask({
              payload: {
                field: 'lenderInfo',
                productType: 'PersonalAuto',
              },
            }),
            resolveLabel: 'Dismiss Reminder',
          })
        );

      if (featureFlags.assignedRiskPlan) {
        returnTasks.push({
          page: VehiclesPageRepresentation,
          field: 'isAssignedRiskPlan',
          entityType: 'review',
          productType: 'PersonalAuto',
          ratingStatusOrdinal: 'Draft',
          message: 'Please review policy assigned risk status.', //TODO verbiage
          resolve: dismissReviewTask({
            payload: {
              field: 'isAssignedRiskPlan',
              productType: 'PersonalAuto',
            },
          }),
          resolveLabel: 'Dismiss Reminder',
        });
      }

      if (
        selectedProducts.includes('Homeowner') ||
        selectedProducts.includes('Condominium')
      ) {
        let mortgageReviewTask: TaskModel = {
          page: HomeownerPageRepresentation,
          field: 'mortgage',
          entityType: 'review',
          productType: 'Homeowner',
          ratingStatusOrdinal: 'Draft',
          message:
            'Please review property mortgage selections for accuracy before continuing.',
          resolve: dismissReviewTask({
            payload: {
              field: 'mortgage',
              productType: 'Homeowner',
            },
          }),
          resolveLabel: 'Dismiss Reminder',
        };
        if (selectedProducts.includes('Condominium')) {
          mortgageReviewTask.page = CondoPageRepresentation;
          mortgageReviewTask.productType = 'Condominium';
          mortgageReviewTask.resolve = dismissReviewTask({
            payload: {
              field: 'mortgage',
              productType: 'Condominium',
            },
          });
        }
        returnTasks.push(mortgageReviewTask);
      }

      if (featureFlags.driverOccupation) {
        if (
          drivers.some(
            (d) =>
              d.driverOccupation === 'AllOther' &&
              d.driverRoles?.some((role) => role.driverType === 'Driver')
          )
        ) {
          returnTasks.push({
            page: PeoplePageRepresentation,
            field: 'driverOccupation',
            entityType: 'review',
            productType: 'PersonalAuto',
            ratingStatusOrdinal: 'Quoted',
            message: 'Review driver occupation',
            resolve: dismissReviewTask({
              payload: {
                field: 'driverOccupation',
                productType: 'PersonalAuto',
              },
            }),
            resolveLabel: 'Dismiss',
          });
        }
      }
    } else {
      if (
        featureFlags.smartrideAcceptAFMVF &&
        telematicsEnrolled &&
        !this.afmvfAreSelected(coverages)
      ) {
        returnTasks.push({
          page: CoveragesPageRepresentation,
          field: 'accidentForgiveness',
          entityType: 'review',
          productType: 'PersonalAuto',
          ratingStatusOrdinal: 'Quoted',
          message:
            '<strong>Policy coverage options added</strong>: Eligibility for SmartRide<sup>&reg;</sup> telematics ' +
            'program enrollment requires the addition of Accident Forgiveness and Minor Violation Forgiveness.',
          resolve: dismissReviewTask({
            payload: {
              field: 'accidentForgiveness',
              productType: 'PersonalAuto',
            },
          }),
          resolveLabel: 'Dismiss',
        });
      }
      if (featureFlags.driverOccupation) {
        if (
          drivers.some(
            (d) =>
              d.driverOccupation === 'AllOther' &&
              d.driverRoles?.some((role) => role?.driverType === 'Driver')
          )
        ) {
          returnTasks.push({
            page: PeoplePageRepresentation,
            field: 'driverOccupation',
            entityType: 'review',
            productType: 'PersonalAuto',
            ratingStatusOrdinal: 'Quoted',
            message: 'Review driver occupation',
            resolve: dismissReviewTask({
              payload: {
                field: 'driverOccupation',
                productType: 'PersonalAuto',
              },
            }),
            resolveLabel: 'Dismiss',
          });
        }
      }
    }

    if (eligibilityForms?.length) {
      const unacknowledgedForms = eligibilityForms.filter(
        (f) => !f.acknowledgement
      );
      for (const form of unacknowledgedForms) {
        if (!selectedProducts.includes(form.productType)) {
          continue;
        }
        // We currently are showing eligibility only for Property products, but forms get generated for all of them.
        if (
          !['Homeowner', 'Tenant', 'Condominium'].includes(form.productType)
        ) {
          continue;
        }
        const page = getPageRepresentationByProductId(form.productType);
        returnTasks.push({
          page,
          field: 'acknowledgement',
          entityType: 'eligibility',
          productType: form.productType,
          ratingStatusOrdinal: 'Draft',
          message: 'Please review and acknowledge property risk details',
          resolve: EligibilityFormActions.showEligibilityFormModal({
            quoteId: form.quoteId,
            productType: form.productType,
          }),
          resolveLabel: 'Review property eligibility',
        });
      }
    }
    if (sessionState.isRetrieve) {
      returnTasks.push({
        page: AccountInfoPageRepresentation,
        entityType: 'review',
        productType: 'All',
        field: 'onlineAccountRegistrationReview',
        ratingStatusOrdinal: 'Quoted',
        message: 'Review online account registration preferences',
        actionMessage: 'Review registration',
        overrideAction: AccountActions.showAccountRegistrationModal({
          dismissReviewTask: true,
        }),
        overrideTitle: 'Online account registration',
      });
    }

    if (
      exposureCount &&
      vehicles.length &&
      selectedProducts.includes('PersonalUmbrella')
    ) {
      returnTasks = [
        ...returnTasks,
        ...TaskValidators.allVehiclesListedAsExposures(exposureCount, vehicles),
      ];
    }

    return returnTasks;
  }

  static generateAccountTasks(data: {
    hasUpdatedRegistration: boolean;
    pniEmail: Nullable<string>;
    isRetrieve: boolean;
  }): TaskModel[] {
    const tasks: TaskModel[] = [];
    if (!data.hasUpdatedRegistration && data.pniEmail && !data.isRetrieve) {
      tasks.push({
        message: 'Complete online account registration before binding',
        productType: 'All',
        entityType: 'account',
        ratingStatusOrdinal: 'Quoted',
        field: 'none',
        page: AccountInfoPageRepresentation,
        overrideAction: AccountActions.showAccountRegistrationModal({
          dismissReviewTask: false,
        }),
        overrideTitle: 'Online account registration',
        overrideActionMessage: 'Confirm registration',
      });
    }
    return tasks;
  }

  private static afmvfAreSelected(coverages?: CoverageEntity[]): boolean {
    if (!coverages) {
      return false;
    }
    let af = false,
      mvf = false;
    for (const coverage of coverages) {
      switch (coverage.coverageId) {
        case 'AF':
          {
            if (
              coverage.selectedValue?.find(
                (v) => v.code === 'selected' && v.value === 'true'
              )
            ) {
              af = true;
            }
          }
          break;
        case 'MVF':
          {
            if (
              coverage.selectedValue?.find(
                (v) => v.code === 'selected' && v.value === 'true'
              )
            ) {
              mvf = true;
            }
          }
          break;
      }
    }
    return af && mvf;
  }

  static createTaskTemplates(
    templates: TaskTemplate[],
    features: FeatureFlagsModel,
    productQuoteStatus: QuoteStatus,
    productTypes?: ProductType[]
  ): TaskRule[] {
    const returnTaskTemplates: ((
      a: Partial<TaskModel>,
      b: any | any[]
    ) => TaskModel | null)[] = [];
    templates?.forEach((template) => {
      // the feature must exist and apply
      // the quote status must exist and apply
      if (
        !template.feature &&
        TaskUtils.ruleAppliesByQuoteStatus(template, productQuoteStatus) &&
        (!template.productTypes ||
          !productTypes ||
          TaskUtils.ruleAppliesByLineOfBusiness(template, productTypes))
      ) {
        returnTaskTemplates.push(template.rule);
      } else if (
        template.feature &&
        Object.keys(features).includes(template.feature)
      ) {
        returnTaskTemplates.push(template.rule);
      }
    });

    return returnTaskTemplates;
  }

  // This should determine if the product has ever reached a quote status beyond the rule's required status
  static ruleAppliesByQuoteStatus(
    template: TaskTemplate,
    quoteStatus: QuoteStatus
  ): boolean {
    return !template.quoteStatus ? true : template.quoteStatus <= quoteStatus;
  }

  static ruleAppliesByLineOfBusiness(
    template: TaskTemplate,
    selectedProductTypes: ProductType[]
  ): boolean {
    return !template.productTypes
      ? true
      : template.productTypes.some((productType) =>
          selectedProductTypes.includes(productType)
        );
  }

  static createUpdatedTaskObject(
    generatedTasks: TaskModel[],
    existingTasks: TaskModel[]
  ): UpdateTaskModel {
    const addTasks: TaskModel[] = generatedTasks.filter(
      (task) => !!task && this.isNewTask(task, existingTasks)
    );
    const uncompleteTasks: TaskModel[] = [];
    generatedTasks.forEach((task) => {
      const uncomplete = this.getUncompletedTask(task, existingTasks);
      if (uncomplete) {
        uncompleteTasks.push(uncomplete);
      }
    });
    const finalTaskSet = {
      add: addTasks,
      complete: existingTasks.filter(
        (t) =>
          !t.completed &&
          !generatedTasks.some((task) => this.compareTasks(task, t))
      ),
      uncomplete: uncompleteTasks,
    };
    return finalTaskSet;
  }

  static isNewTask(
    generated: TaskModel,
    existing: (TaskModel | undefined)[]
  ): boolean {
    return !existing.some((task) => this.compareTasks(task, generated));
  }

  static getUncompletedTask(
    generated: TaskModel,
    existing: (TaskModel | undefined)[]
  ): TaskModel | null {
    return (
      existing.find(
        (task) => task?.completed && this.compareTasks(task, generated)
      ) || null
    );
  }

  static compareTasks(
    a: TaskModel | undefined,
    b: TaskModel | undefined
  ): boolean {
    return (
      a?.entityType === b?.entityType &&
      a?.productType === b?.productType &&
      a?.page?.id === b?.page?.id &&
      a?.message === b?.message &&
      a?.entityId === b?.entityId &&
      a?.field === b?.field
    );
  }

  static hasMatchingTask(field: string, tasks: TaskModel[]): boolean {
    return tasks.some((task) => task.field === field);
  }

  static findTaskByEntityTypeAndField(
    entityType: string,
    field: string,
    tasks: (TaskModel | undefined)[]
  ): TaskModel | undefined {
    return tasks.find(
      (task) => task?.entityType === entityType && task?.field === field
    );
  }

  static findAllTasksByEntityTypeAndPage(
    entityType: string,
    page: PageIdentifier,
    tasks: (TaskModel | undefined)[]
  ): TaskModel[] {
    return tasks.filter(
      (task) => task?.entityType === entityType && task?.page?.id === page
    ) as TaskModel[];
  }

  static isProductType(
    taskProductType: TaskProductType,
    productType: ProductType
  ): boolean {
    switch (taskProductType) {
      case 'All':
        return true;
      default:
        return taskProductType === productType;
    }
  }

  static reviewTaskExistsForPage(
    tasks: TaskModel[],
    pageId: PageIdentifier
  ): boolean {
    return tasks.some(
      (t) => t.entityType === 'review' && t.page?.id === pageId
    );
  }

  /* static anyDraftTaskExistsWithUmbrella(
    tasks: TaskModel[],
    productType: ProductType
  ): boolean {
    return (
      productType === 'PersonalUmbrella' &&
      tasks.some((t) => t.ratingStatusOrdinal === 'Draft')
    );
  } */
}
