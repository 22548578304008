import { Injectable } from '@angular/core';
import { ProductType } from '../models/api/dsm-types';
import { ErrorModel } from '../store/entities/error/error.model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyError = any;

@Injectable({
  providedIn: 'root',
})
export class ErrorSanitizerService {
  static readonly GENERIC_ERROR_MESSAGE = 'An error occurred.';
  static readonly MSB_NOHIT_PHRASE = 'MSB NoHit:';

  constructor() {}

  errorIsOk(error: AnyError): error is ErrorModel {
    if (!error) {
      return false;
    }
    if (error.remediation) {
      const r = error.remediation;
      if (typeof r !== 'object') {
        return false;
      }
      if (!r.description || typeof r.description !== 'string') {
        return false;
      }
      return true;
    }
    return false;
  }

  sanitizeError(error: AnyError, productType?: ProductType): ErrorModel {
    if (this.errorIsOk(error)) {
      return error;
    }
    switch (typeof error) {
      case 'number':
        if (isNaN(error)) {
          break;
        } else {
          return {
            displayMessage: ErrorSanitizerService.GENERIC_ERROR_MESSAGE,
            status: error,
            productType,
          };
        }
      case 'string':
        if (error) {
          return {
            displayMessage: error,
            productType,
          };
        } else {
          break;
        }
      case 'object':
        // Careful, null is an "object", $#@!
        if (Array.isArray(error)) {
          return this.bestErrorFromArray(error, productType);
        } else if (error) {
          return this.sanitizeErrorObject(error, productType);
        } else {
          break;
        }
    }
    return {
      displayMessage: ErrorSanitizerService.GENERIC_ERROR_MESSAGE,
      productType,
    };
  }

  private bestErrorFromArray(
    error: any[],
    productType?: ProductType
  ): ErrorModel {
    let best: ErrorModel = {
      displayMessage: ErrorSanitizerService.GENERIC_ERROR_MESSAGE,
      productType,
    };
    for (const child of error) {
      const candidate = this.sanitizeError(child, productType);
      if (
        candidate.displayMessage === ErrorSanitizerService.GENERIC_ERROR_MESSAGE
      ) {
        best = candidate;
      } else {
        return candidate;
      }
    }
    return best;
  }

  sanitizeErrorObject(
    error: { [key: string]: string },
    productType?: ProductType
  ): ErrorModel {
    const output: ErrorModel = { productType };

    // If it looks like an ErrorModel already, roll with it.
    if (
      error.displayMessage &&
      error.developerMessage &&
      error.productType &&
      error.status
    ) {
      return {
        displayMessage: error.displayMessage,
        developerMessage: error.developerMessage,
        userMessage: error.userMessage,
        productType: error.productType as ProductType,
        status: +error.status,
        entityId: error.entityId,
      };
    }

    this.findContentInDsmError(output, error);

    if (!output.displayMessage) {
      if (output.developerMessage) {
        output.displayMessage = output.developerMessage;
      } else if (
        error.displayMessage &&
        typeof error.displayMessage === 'string'
      ) {
        output.displayMessage = error.displayMessage;
      } else if (error.message && typeof error.message === 'string') {
        output.displayMessage = error.message;
      }
    }
    if (!output.developerMessage) {
      if (
        error.developerMessage &&
        typeof error.developerMessage === 'string'
      ) {
        output.developerMessage = error.developerMessage;
      }
    }
    if (!output.hasOwnProperty('status')) {
      if (typeof error.status === 'number') {
        output.status = error.status;
      }
    }
    if (!output.productType && error.hasOwnProperty('productId')) {
      output.productType = error.productId as ProductType;
    }
    if (!output.productType && error.hasOwnProperty('productType')) {
      output.productType = error.productType as ProductType;
    }
    if (!output.entityId && error.hasOwnProperty('entityId')) {
      output.entityId = error.entityId;
    }
    if (!output.displayMessage) {
      output.displayMessage = ErrorSanitizerService.GENERIC_ERROR_MESSAGE;
    }

    if (typeof error.stack === 'string' && !output.developerMessage) {
      output.developerMessage = this.sanitizeStackTrace(error.stack);
    }

    return output;
  }

  private sanitizeStackTrace(input: string): string {
    const noNewLines = input
      .replace(/^([^\n]*\n\s*)at/, '')
      .replace(/\n\s*/g, ' ; ');
    if (noNewLines.length > 300) {
      return noNewLines.substring(0, 300);
    }
    return noNewLines;
  }

  findContentInDsmError(
    output: ErrorModel,
    input: { [key: string]: AnyError }
  ): void {
    if (this.handleSpecificErrorMessageExceptionCases(output, input)) {
      return;
    }
    if (input.error && typeof input.error === 'object') {
      if (
        input.error.userMessage &&
        typeof input.error.userMessage === 'string'
      ) {
        const userMessage: string = input.error.userMessage;
        output.displayMessage = userMessage;
        output.userMessage = userMessage;
      }
      if (
        input.error.developerMessage &&
        typeof input.error.developerMessage === 'string'
      ) {
        output.developerMessage = input.error.developerMessage;
      }
      if (typeof input.error.code === 'number') {
        output.status = input.error.code;
      }
    }
  }

  private handleSpecificErrorMessageExceptionCases(
    output: ErrorModel,
    input: { [key: string]: AnyError }
  ): boolean {
    if (input.error && typeof input.error === 'object') {
      // setupAccountExperience does this; their userMessage is never helpful:
      if (input.error.userMessage === 'Validation error') {
        if (
          input.error.developerMessage &&
          typeof input.error.developerMessage === 'string'
        ) {
          output.displayMessage = input.error.developerMessage;
          output.developerMessage = input.error.developerMessage;
          if (typeof input.error.code === 'number') {
            output.status = input.error.code;
          }
          return true;
        }
      }
    }
    return false;
  }
}
