import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  DriverVehicleAssignmentActions,
  SessionActions,
  VehicleActions,
} from '@core/store/actions';
import { DriverVehicleAssignmentEntity } from '@core/store/entities/driver-vehicle-assignment/driver-vehicle-assignment.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';

export interface DriverVehicleAssignmentState
  extends EntityState<DriverVehicleAssignmentEntity> {
  error: ErrorModel;
}

export const adapter: EntityAdapter<DriverVehicleAssignmentEntity> =
  createEntityAdapter<DriverVehicleAssignmentEntity>({
    selectId: (driverVehicleAssignment) =>
      `${driverVehicleAssignment.driverId}:${driverVehicleAssignment.vehicleId}`,
  });

const driverVehicleAssignmentReducer = createReducer(
  adapter.getInitialState({
    error: {},
  }),
  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({ error: {} })
  ),
  on(
    DriverVehicleAssignmentActions.getDriverVehicleAssignmentSuccess,
    (state, { payload }) =>
      adapter.addMany(
        payload,
        adapter.getInitialState({
          error: {},
        })
      )
  ),
  on(
    DriverVehicleAssignmentActions.getDriverVehicleAssignmentError,
    (state, { error }) => {
      return {
        ...state,
        error,
      };
    }
  ),
  on(
    DriverVehicleAssignmentActions.addDriverVehicleAssignmentSuccess,
    (state, { payload }) =>
      adapter.addMany(
        payload,
        adapter.getInitialState({
          error: state.error,
        })
      )
  ),
  on(
    DriverVehicleAssignmentActions.addDriverVehicleAssignmentError,
    (state, { payload: error }) => {
      return {
        ...state,
        error,
      };
    }
  ),
  on(VehicleActions.deleteVehicleSuccess, (state, { payload }) => {
    const keys = (state.ids as string[])
      .map((id) => {
        return { id, entity: state.entities[id] };
      })
      .filter((e) => e?.entity?.vehicleId === payload)
      .map((e) => e.id);
    return adapter.removeMany(keys, state);
  })
);

export function reducer(
  state: DriverVehicleAssignmentState | undefined,
  action: Action
): DriverVehicleAssignmentState {
  return driverVehicleAssignmentReducer(state, action);
}
