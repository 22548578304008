import {
  CoveragesPageRepresentation,
  getPageRepresentationByProductId,
  PeoplePageRepresentation,
  VehiclesPageRepresentation,
  RVPageRepresentation,
} from '@core/constants/pages';
import {
  ProductType,
  QuoteStatus,
  quoteStatusGreaterThan,
  TaskProductType,
} from '@core/models/api/dsm-types';
import { MortgageModel } from '@shared/components/forms/mortgage-form/mortgage-form.model';
import { Nullable } from '@shared/utils/type.utils';
import { ConstructionInfoModel } from '../covered-location/covered-location.model';
import { DriverVehicleAssignmentEntity } from '../driver-vehicle-assignment/driver-vehicle-assignment.entity';
import { DriverEntity } from '../driver/driver.entity';
import { FeatureFlagsModel } from '../feature-flag/feature-flag.model';
import { VehicleEntity } from '../vehicle/vehicle.entity';
import { TaskEntityType, TaskModel } from './task.model';
import { CoveredLocationEntity } from '../covered-location/covered-location.reducer';
import { MemberEntity } from '../member/member.reducer';
import { Page } from '../navigation/navigation.action';
import { ProductModel } from '../product/product.model';
import { CoverageEntity } from '../coverage/coverage.entity';
import {
  CONDO_BIND_PERSONAL_PROPERTY_LIMIT,
  CoverageCodes,
  CoverageIds,
  MAX_RV_CARHAULER_VALUE,
  MAX_RV_UTIL_TRAILER_VALUE,
  PERSONAL_AUTO_TRAILER_TYPE,
  TENANT_BIND_PERSONAL_PROPERTY_LIMIT,
  VehiclePrimaryUseCodeStrings,
} from '@shared/constants/app-constants';
import { PolicyDateOptions } from '@core/interfaces/interfaces';
import { ScheduledCategoryItem } from '@entities/scheduled-categories/scheduled-category.entity';
import {
  FullTimePrimaryUses,
  PrimaryUseType,
} from '@core/models/views/vehicle.model';
import { NavigationActions, TaskActions } from '@core/store/actions';
import { ScheduledCategoriesEntityState } from '@entities/scheduled-categories/scheduled-category.reducer';
import { Dictionary } from '@ngrx/entity';
import { VehicleUtils } from '@shared/utils/vehicle.utils';
import { COMP_COLL_DECLINED_ADDITIONAL_INTEREST_TEXT } from '@shared/constants/help-text-constants';
import { StringUtils } from '@shared/utils/string.utils';
import { VinHelper } from '@core/helper/vin.helper';
import { TaskHelper } from '@core/helper/task.helper';
import { CoverageUtils } from '@shared/utils/coverage.utils';
import { ModalService } from '@shared/services/modal.service';
import { UnderlyingPolicyEntity } from '@entities/underlying-policy/underlying-policy.entity';
import { Exposures } from '@entities/umbrella/umbrella.model';
import { UmbrellaUtils } from '@shared/utils/umbrella.utils';
import { dismissReviewTask } from './task.action';
import { formatCurrency } from '@angular/common';

export class TaskValidators {
  constructor(private modalService: ModalService) {}
  static atLeastOne<T>(
    field: string,
    message: string,
    ratingStatusOrdinal: QuoteStatus,
    productType: TaskProductType
  ): (template: Partial<TaskModel>, entities: T[]) => TaskModel | null {
    return (template: Partial<TaskModel>, entities: T[]) => {
      if (entities.length < 1) {
        return {
          ...template,
          field,
          message,
          ratingStatusOrdinal,
          productType,
        };
      }
      return null;
    };
  }

  static requiredField<EntityType, FormType = EntityType>(
    message: string,
    ratingStatusOrdinal: QuoteStatus,
    productType: TaskProductType,
    entityKey: keyof EntityType & string,
    elementId: string
  ): (template: Partial<TaskModel>, entity: EntityType) => TaskModel | null {
    return (template: Partial<TaskModel>, entity: EntityType) => {
      if (
        !!entity &&
        !entity?.[entityKey] &&
        typeof entity?.[entityKey] !== 'boolean'
      ) {
        return {
          ...template,
          field: entityKey,
          message,
          ratingStatusOrdinal,
          productType,
          elementId,
        };
      }
      return null;
    };
  }

  static nonNullField<EntityType, FormType = EntityType>(
    extraCondition: (entity: EntityType) => boolean,
    message: string,
    ratingStatusOrdinal: QuoteStatus,
    productType: TaskProductType,
    entityKey: keyof EntityType & string,
    elementId: string
  ): (template: Partial<TaskModel>, entity: EntityType) => TaskModel | null {
    return (template: Partial<TaskModel>, entity: EntityType) => {
      if (
        !!entity &&
        (entity[entityKey] === null || entity[entityKey] === undefined) &&
        extraCondition(entity)
      ) {
        return {
          ...template,
          field: entityKey,
          message,
          ratingStatusOrdinal,
          productType,
          elementId,
        };
      }
      return null;
    };
  }

  /**
   * If (conditional) is boolean, the existence of the required field is tested only when true.
   * If (conditional) is a function, it must perform the *entire* test (ie also check whether the required field is there).
   */
  static requiredFieldIf<EntityType, FormType = EntityType>(
    conditional: boolean | ((entity: EntityType) => boolean),
    message: string,
    ratingStatusOrdinal: QuoteStatus,
    productType: TaskProductType,
    entityKey: keyof EntityType & string,
    elementId: string
  ): (template: Partial<TaskModel>, entity: EntityType) => TaskModel | null {
    return (template: Partial<TaskModel>, entity: EntityType) => {
      if (
        (typeof conditional === 'function' && conditional(entity)) ||
        (typeof conditional === 'boolean' && !entity[entityKey])
      ) {
        return {
          ...template,
          field: entityKey,
          message,
          ratingStatusOrdinal,
          productType,
          elementId,
        };
      }
      return null;
    };
  }

  // TODO - Do not generate these tasks if NeedConstructionInfo is false
  static requiredConstructionInfo<FormType = ConstructionInfoModel>(
    message: string,
    entityKey: keyof ConstructionInfoModel & string,
    elementId: string,
    ratingStatusOrdinal?: QuoteStatus
  ): (
    template: Partial<TaskModel>,
    entity: CoveredLocationEntity
  ) => TaskModel | null {
    return (template: Partial<TaskModel>, entity: CoveredLocationEntity) => {
      const constructionInfo: ConstructionInfoModel | undefined =
        entity.constructionInfo;
      if (template.productType && !constructionInfo?.[entityKey]) {
        return {
          ...template,
          message,
          field: entityKey,
          ratingStatusOrdinal: ratingStatusOrdinal
            ? ratingStatusOrdinal
            : 'Quoted',
          productType: template.productType,
          entityType: 'coveredLocation',
          page: getPageRepresentationByProductId(
            template.productType as ProductType
          ),
          elementId,
        };
      }
      return null;
    };
  }

  static nonEntityObjectRequiredField<EntityType, FormType = EntityType>(
    entityKey: keyof EntityType & string,
    message: string,
    ratingStatusOrdinal: QuoteStatus,
    productType: TaskProductType,
    elementId: string,
    requiredFields: string[]
  ): (template: Partial<TaskModel>, entity: EntityType) => TaskModel | null {
    return (template: Partial<TaskModel>, entity: EntityType) => {
      if (
        !entity?.[entityKey] ||
        Object.keys(entity[entityKey] as unknown as object).length === 0 ||
        this.missingRequiredFields(entity[entityKey] as any, requiredFields)
      ) {
        return {
          ...template,
          field: entityKey,
          message,
          ratingStatusOrdinal,
          productType,
          elementId,
        };
      }
      return null;
    };
  }

  static nonEntityObjectRequiredFieldIf<EntityType, FormType = EntityType>(
    conditional: boolean | ((entity: EntityType) => boolean),
    entityKey: keyof EntityType & string,
    message: string,
    ratingStatusOrdinal: QuoteStatus,
    productType: TaskProductType,
    elementId: string,
    requiredFields: string[]
  ): (template: Partial<TaskModel>, entity: EntityType) => TaskModel | null {
    return (template: Partial<TaskModel>, entity: EntityType) => {
      if (
        (typeof conditional === 'boolean' && !entity[entityKey]) ||
        (typeof conditional === 'function' && !conditional(entity))
      ) {
        return null;
      }

      if (
        !entity?.[entityKey] ||
        Object.keys(entity[entityKey] as unknown as object).length === 0 ||
        this.missingRequiredFields(entity[entityKey] as any, requiredFields)
      ) {
        return {
          ...template,
          field: entityKey,
          message,
          ratingStatusOrdinal,
          productType,
          elementId,
        };
      }
      return null;
    };
  }

  static nonEntityRequiredField<
    EntityType extends object,
    FormType = EntityType
  >(
    entityKey: keyof EntityType & string,
    message: string,
    ratingStatusOrdinal: QuoteStatus,
    productType: TaskProductType,
    elementId: string
  ): (template: Partial<TaskModel>, entity: EntityType) => TaskModel | null {
    return (template: Partial<TaskModel>, entity: EntityType) => {
      const value = entity?.[entityKey] as unknown;
      // Zero, false, you're cool.
      if (value === 0 || value === false) {
        return null;
      }
      // Any other falsey value generates a task.
      if (!value) {
        return {
          ...template,
          field: entityKey,
          message,
          ratingStatusOrdinal,
          productType,
          elementId,
        };
      }
      return null;
    };
  }

  static requiredIfConstructionInfo<FormType = ConstructionInfoModel>(
    conditional: boolean | ((entity: CoveredLocationEntity) => boolean),
    entityKey: keyof ConstructionInfoModel & string,
    message: string,
    formKey?: keyof FormType & string
  ): (
    template: Partial<TaskModel>,
    entity: CoveredLocationEntity
  ) => TaskModel | null {
    return (template: Partial<TaskModel>, entity: CoveredLocationEntity) => {
      const constructionInfo: ConstructionInfoModel | undefined =
        entity.constructionInfo;
      if (
        template.productType &&
        ((typeof conditional === 'function' && conditional(entity)) ||
          (typeof conditional === 'boolean' && !constructionInfo?.[entityKey]))
      ) {
        return {
          ...template,
          message,
          field: formKey || entityKey,
          ratingStatusOrdinal: 'Quoted',
          productType: template.productType,
          entityType: 'coveredLocation',
          page: getPageRepresentationByProductId(
            template.productType as ProductType
          ),
        };
      }
      return null;
    };
  }

  static numberOfCarsInGarage(
    message: string
  ): (
    template: Partial<TaskModel>,
    entity: CoveredLocationEntity
  ) => TaskModel | null {
    return (template: Partial<TaskModel>, entity: CoveredLocationEntity) => {
      const garages = entity?.constructionInfo?.garages || [];
      if (!garages.length || !template.productType) {
        return null;
      }
      for (const garage of garages) {
        if (garage.numberOfCars || !garage.garageType) {
          return null;
        }
      }
      return {
        ...template,
        message,
        field: 'numberOfCars',
        ratingStatusOrdinal: 'Quoted',
        productType: template.productType,
        entityType: 'coveredLocation',
        page: getPageRepresentationByProductId(
          template.productType as ProductType
        ),
      };
    };
  }

  static runEntityRulesForAll<T>(
    rulesForAll: ((a: Partial<TaskModel>, b: T[]) => TaskModel | null)[],
    entities: T[],
    page: Page,
    taskEntityType: TaskEntityType,
    taskProductType: TaskProductType
  ): TaskModel[] {
    const tasks: TaskModel[] = [];
    const template: TaskModel = {
      page,
    } as TaskModel;
    if (taskEntityType) {
      template.entityType = taskEntityType;
    }
    if (taskProductType) {
      template.productType = taskProductType;
    }
    rulesForAll.forEach((rule) => {
      const task = rule(template, entities);
      if (task) {
        tasks.push(task);
      }
    });
    return tasks;
  }

  static runEntityRulesForEachSubEntity<T, S>(
    rulesForEach: ((a: Partial<TaskModel>, b: S) => TaskModel | null)[],
    entities: T[],
    page: Page,
    taskEntityType: TaskEntityType,
    taskProductType: TaskProductType,
    subEntityKey: keyof T,
    entityIdKey?: keyof T
  ): TaskModel[] {
    const tasks: TaskModel[] = [];
    entities.forEach((entity) => {
      const subEntity: S = entity[subEntityKey] as unknown as S;
      const entityTemplate = this.setEntityTemplate<T>(
        entity,
        page,
        taskEntityType,
        taskProductType,
        entityIdKey
      );

      this.applyRuleTemplates(tasks, rulesForEach, entityTemplate, subEntity);
    });
    return tasks;
  }

  static runEntityRulesForEach<T>(
    rulesForEach: ((a: Partial<TaskModel>, b: T) => TaskModel | null)[],
    entities: T[],
    page: Page,
    taskEntityType: TaskEntityType,
    taskProductType: TaskProductType,
    entityIdKey?: keyof T
  ): TaskModel[] {
    const tasks: TaskModel[] = [];
    entities.forEach((entity) => {
      const entityTemplate = this.setEntityTemplate<T>(
        entity,
        page,
        taskEntityType,
        taskProductType,
        entityIdKey
      );

      this.applyRuleTemplates(tasks, rulesForEach, entityTemplate, entity);
    });
    return tasks;
  }

  static runEntityRulesForSingle<T>(
    rulesForSingle: ((a: Partial<TaskModel>, b: T) => TaskModel | null)[],
    entity: T,
    page: Page,
    taskEntityType: TaskEntityType,
    taskProductType: TaskProductType,
    entityIdKey?: keyof T
  ): TaskModel[] {
    const tasks: TaskModel[] = [];
    const entityTemplate = this.setEntityTemplate<T>(
      entity,
      page,
      taskEntityType,
      taskProductType,
      entityIdKey
    );
    this.applyRuleTemplates(tasks, rulesForSingle, entityTemplate, entity);
    return tasks;
  }

  static autoCustomRules(
    vehicles: VehicleEntity[],
    features: FeatureFlagsModel,
    quoteStatus: QuoteStatus
  ): TaskModel[] {
    const autoCustomTasks: TaskModel[] = [];
    vehicles.forEach((vehicle) => {
      const dailyUseQuestions = this.vehicleDailyUseQuestions(
        vehicle,
        features
      );
      if (dailyUseQuestions) {
        dailyUseQuestions.forEach((questionTask) =>
          autoCustomTasks.push(questionTask)
        );
      }

      if (quoteStatusGreaterThan(quoteStatus, 'Draft')) {
        vehicle.additionalInterests?.forEach((additionalInterest) => {
          if (
            additionalInterest.additionalInterestType &&
            !(additionalInterest.companyName || additionalInterest.person)
          ) {
            autoCustomTasks.push({
              page: getPageRepresentationByProductId(
                vehicle.productType || 'PersonalAuto'
              ),
              field: 'lenderInfo',
              entityId: vehicle.vehicleId,
              entityType: 'vehicle',
              productType: vehicle.productType,
              ratingStatusOrdinal: 'Quoted',
              elementId: 'lender',
              message:
                'You indicated there is a loan or lease, but have not entered the loan or lease information.',
            } as TaskModel);
          }
        });
      }
    });
    return autoCustomTasks;
  }

  static utilityTrailerCustomRules(
    vehicles: VehicleEntity[],
    coverages: CoverageEntity[]
  ): TaskModel[] {
    // cost new is required if a util trailer has comp:
    const tasks: TaskModel[] = [];
    const utilityTrailers = vehicles.filter(
      (v) => v.vehicleType === PERSONAL_AUTO_TRAILER_TYPE
    );
    utilityTrailers.forEach((vehicle) => {
      const comp = coverages.find(
        (c) =>
          c.coverageId === CoverageIds.COMP &&
          c.coverableId === vehicle.vehicleId &&
          CoverageUtils.isCoverageSelected(c)
      );
      if (comp && !vehicle.costNew) {
        tasks.push({
          page: VehiclesPageRepresentation,
          field: 'costNew',
          entityId: vehicle.vehicleId,
          entityType: 'vehicle',
          productType: 'PersonalAuto',
          ratingStatusOrdinal: 'Draft',
          elementId: 'cost-new',
          message:
            'Cost new is required for utility trailers with comprehensive coverage.',
        });
      }
    });
    return tasks;
  }

  static multiOwnerSurchargeRule(
    vehiclesForProduct: VehicleEntity[],
    product: ProductModel
  ): TaskModel | null {
    const invalidOwnershipVehicle =
      TaskValidators.multiOwnerSurchargeTaskApplies(vehiclesForProduct);
    if (invalidOwnershipVehicle !== undefined) {
      return {
        page: getPageRepresentationByProductId(product.type),
        field: `numberOfCoOwners-${invalidOwnershipVehicle.vehicleId}`,
        entityId: invalidOwnershipVehicle.vehicleId,
        entityType: 'vehicle',
        productType: product.type,
        message:
          'Vehicles with 2 or more owners from outside household are ineligible.',
        ratingStatusOrdinal: 'Draft',
        resolve: TaskActions.openInPolicyCenter({
          quoteId: product?.quoteId || '',
          removeProduct: product?.type,
          reason:
            'Vehicles with 2 or more owners from outside household are ineligible.',
        }),
        resolveLabel: 'Complete quote in PolicyCenter',
        elementId: `taskField-numberOfCoOwners-${invalidOwnershipVehicle.vehicleId}`,
      };
    }
    return null;
  }

  static multiOwnerSurchargeTaskApplies(
    vehicles: VehicleEntity[]
  ): VehicleEntity | undefined {
    return vehicles.find(
      (v) =>
        v.additionalInformation?.isCoOwned &&
        v.additionalInformation?.numberOfCoOwners === 'twomore'
    );
  }

  static dvaCustomRules(
    drivers: DriverEntity[],
    vehicles: VehicleEntity[],
    driverVehicleAssignments: DriverVehicleAssignmentEntity[],
    features: FeatureFlagsModel,
    quoteStatus: QuoteStatus
  ): TaskModel[] {
    const dvaCustomTasks: TaskModel[] = [];

    if (
      features.driverVehicleAssignment &&
      quoteStatusGreaterThan(quoteStatus, 'Quoted') &&
      (driverVehicleAssignments.length < vehicles.length ||
        driverVehicleAssignments.length < drivers.length)
    ) {
      dvaCustomTasks.push({
        page: VehiclesPageRepresentation,
        field: 'driverVehicleAssignment',
        entityType: 'driverVehicleAssignment',
        productType: 'PersonalAuto',
        ratingStatusOrdinal: 'Quoted',
        elementId: 'driver-vehicle-assignment',
        message: 'Driver vehicle assignments are required',
      });
    }

    return dvaCustomTasks;
  }

  static rvCustomRules(
    rvVehicles: VehicleEntity[],
    rvProduct: ProductModel,
    coverages: CoverageEntity[]
  ): TaskModel[] {
    const tasks: TaskModel[] = [];

    // Utility trailer is over 50,000, but less than 200,000 require underwriting approval
    const carHaulerUtilTrailers = rvVehicles.filter(
      (vehicle) =>
        vehicle.vehicleType === 'UtilityTrailer' &&
        vehicle.subType === 'CarHauler'
    );
    if (carHaulerUtilTrailers.length > 0) {
      const invalidValueVehicle = carHaulerUtilTrailers.find(
        (vehicle) =>
          (vehicle.marketValue || 0) > MAX_RV_UTIL_TRAILER_VALUE &&
          (vehicle.marketValue || 0) <= MAX_RV_CARHAULER_VALUE
      );
      if (!!invalidValueVehicle) {
        const message = `Interior and exterior photos and receipts are required to initiate the prior approval request for the Car Hauler ${invalidValueVehicle.year} ${invalidValueVehicle.make} ${invalidValueVehicle.model}.
        Please continue quote in PolicyCenter or remove the product to continue quote in Nationwide Express.`;
        tasks.push({
          page: RVPageRepresentation,
          entityType: 'rvProduct',
          field: 'marketValue',
          entityId: invalidValueVehicle.vehicleId,
          productType: 'RV',
          ratingStatusOrdinal: 'Quoted',
          elementId: 'marketValue-',
          message,
          resolve: TaskActions.openInPolicyCenter({
            quoteId: rvProduct?.quoteId || '',
            removeProduct: 'RV',
            reason: 'car hauler marketValue out of range',
          }),
          resolveLabel: 'Complete quote in PolicyCenter',
        });
      }
    }

    // Receipt or appraisal is required for Prior approval - Use Case 1:
    /**
     * Two ways to get this task:
     *  1.
     *  Any RV vehicle type with settlement option selected as 'agreed value'
        AND the market value is greater than $100,000
        AND Vehicle subtype is NOT Medium Duty Tow or Vintage travel trailer or Vintage motorhome
        AND quote has 'Comprehensive Coverage'
      * 2.
        Any RV vehicle type with settlement option selected as 'agreed value'
        AND the vehicle age is greater than 5 years
        AND quote has 'Comprehensive Coverage'
     */
    const receiptTaskVehicle = TaskHelper.findApplicableVehicleForReceiptTask(
      rvVehicles,
      coverages
    );

    if (receiptTaskVehicle !== undefined) {
      tasks.push({
        page: RVPageRepresentation,
        entityType: 'rvProduct',
        productType: 'RV',
        entityId: receiptTaskVehicle.vehicleId,
        ratingStatusOrdinal: 'Draft',
        message: `A receipt or appraisal within the last 3 years is required to initiate the prior approval request for the ${receiptTaskVehicle.year} ${receiptTaskVehicle.make} ${receiptTaskVehicle.model}.
        Please continue the quote in PolicyCenter or remove the product to continue in Nationwide Agency Express.`,
        field: 'doesnt really have one',
        resolve: TaskActions.openInPolicyCenter({
          quoteId: rvProduct?.quoteId || '',
          removeProduct: 'RV',
          reason: 'RV agreed value',
        }),
        resolveLabel: 'Complete quote in PolicyCenter',
      });
    }

    return tasks;
  }

  static unmaskedVinRequired(): (
    template: Partial<TaskModel>,
    entity: VehicleEntity
  ) => TaskModel | null {
    return (template: Partial<TaskModel>, entity: VehicleEntity) => {
      const vin = entity.vin || '';
      if (vin.length !== 10) {
        return null;
      }
      const error = VinHelper.validate(entity.vin || '', false);
      if (error) {
        return {
          ...template,
          message: error.message,
          ratingStatusOrdinal: 'Quoted',
          productType: 'PersonalAuto',
          field: 'vin',
        };
      } else {
        return null;
      }
    };
  }

  static vehicleDailyUseQuestions(
    vehicle: VehicleEntity,
    features: FeatureFlagsModel
  ): TaskModel[] | null {
    if (vehicle.vehicleType === PERSONAL_AUTO_TRAILER_TYPE) {
      return null;
    }
    if (
      features.vehicleDailyUseQuestions &&
      vehicle.primaryUse === VehiclePrimaryUseCodeStrings.VEHICLE_CODE_WORK &&
      (!vehicle.milesOneWay || !vehicle.daysCommutingPerWeek)
    ) {
      const vehicletask: TaskModel[] = [];
      if (!vehicle.milesOneWay) {
        vehicletask.push({
          page: VehiclesPageRepresentation,
          field: 'daysCommutingPerWeek',
          entityId: vehicle.vehicleId,
          entityType: 'vehicle',
          productType: 'PersonalAuto',
          message: 'Days Per Week Required',
          ratingStatusOrdinal: 'Quoted',
        });
      }
      if (!vehicle.daysCommutingPerWeek) {
        vehicletask.push({
          page: VehiclesPageRepresentation,
          field: 'milesOneWay',
          entityId: vehicle.vehicleId,
          entityType: 'vehicle',
          productType: 'PersonalAuto',
          message: ' Miles One Way Required',
          ratingStatusOrdinal: 'Quoted',
        });
        return vehicletask;
      }
    }
    return null;
  }

  static requiredEvenMarriedPeople(): (
    template: Partial<TaskModel>,
    entities: MemberEntity[]
  ) => Nullable<TaskModel> {
    return (template: Partial<TaskModel>, entities: MemberEntity[]) => {
      const evenNumberOfMarried =
        entities.filter(
          (member: Nullable<MemberEntity>) =>
            member?.person?.maritalStatus === 'M'
        ).length %
          2 ===
        0;
      // const pniIsMarried = entities.some(
      //   (member) =>
      //     member.person?.maritalStatus === 'M' &&
      //     member.relationToPrimaryNamedInsured === 'PrimaryNamedInsured'
      // );
      // const noRelationSpouse = !entities.some(
      //   (member) => member.relationToPrimaryNamedInsured === 'Spouse'
      // );
      if (entities.length > 1) {
        if (!evenNumberOfMarried) {
          return {
            ...template,
            message:
              'There are a non-even number of married policy holders. Please make sure there are an even number.',
            field: 'maritalStatus',
            productType: 'All',
            entityType: 'person',
            ratingStatusOrdinal: 'Draft',
            page: PeoplePageRepresentation,
          };
        }
        // } else if (evenNumberOfMarried && pniIsMarried && noRelationSpouse) {
        //   // This needs to be a specific task with an indicator on the spouse's RelationshipToPRimaryNamed selector
        //   return {
        //     ...template,
        //     message:
        //       'At least one married additional member must have relationship to primary insured: spouse',
        //     field: 'maritalStatus',
        //     productType: 'All',
        //     entityType: 'person',
        //     ratingStatusOrdinal: 'Draft',
        //     page: PeoplePageRepresentation,
        //   };
      } else if (entities.length === 1) {
        // This is ONLY okay because we know the length of Entities is 1 - CK
        const member = entities[0];
        if (member?.person?.maritalStatus === 'M') {
          return {
            ...template,
            message:
              "Please add the married primary policy holder's spouse as an additional member",
            field: 'maritalStatus',
            productType: 'All',
            entityId: member?.person?.firstName as string,
            entityType: 'person',
            ratingStatusOrdinal: 'Draft',
            page: PeoplePageRepresentation,
            resolveLabel: 'Go to marriage ',
          };
        }
      }

      return null;
    };
  }

  static propertyCustomRules(
    coveredLocation: CoveredLocationEntity,
    mortgages: Nullable<MortgageModel[]>,
    isBillToEscrow?: Nullable<boolean>,
    hasMortgage?: boolean
  ): TaskModel[] {
    const propertyCustomTasks: TaskModel[] = [];
    if (isBillToEscrow && !mortgages?.length) {
      propertyCustomTasks.push({
        page: getPageRepresentationByProductId(coveredLocation.productType),
        elementId: 'mortgage-info',
        field: 'mortgage',
        entityType: 'coveredLocation',
        entityId: 'mortgage-' + coveredLocation.productType,
        productType: coveredLocation.productType,
        ratingStatusOrdinal: 'Quoted',
        message: 'Mortgage information is required to bill to escrow.',
      });
    }
    if (hasMortgage && !mortgages?.length) {
      propertyCustomTasks.push({
        page: getPageRepresentationByProductId(coveredLocation.productType),
        elementId: 'has-mortgage',
        field: 'mortgage',
        entityType: 'coveredLocation',
        entityId: 'mortgage-' + coveredLocation.productType,
        productType: coveredLocation.productType,
        ratingStatusOrdinal: 'Draft',
        message: 'Mortgage has not been added. Please add.',
      });
    }

    return propertyCustomTasks;
  }

  static productCustomRules(
    product: ProductModel,
    selectedProducts: ProductType[],
    policyDateOptions: PolicyDateOptions
  ): TaskModel[] {
    const productCustomTasks: TaskModel[] = [];
    if (selectedProducts.includes(product.type) && product.isDsmActive) {
      const effectiveDate = product.effectiveDate
        ? new Date(product.effectiveDate + 'T00:00:00')
        : new Date();
      const minDate = new Date(policyDateOptions.minDate);
      if (effectiveDate.getTime() < minDate.getTime()) {
        productCustomTasks.push({
          page: getPageRepresentationByProductId(product.type),
          field: 'effectiveDate',
          entityId: product.type,
          entityType: this.getProductTaskEntityType(product.type),
          productType: product.type,
          ratingStatusOrdinal: 'Draft',
          elementId: 'datepickerInput-' + product.type,
          message: "Please update this quote's effective date.",
        } as TaskModel);
      }
    }
    return productCustomTasks;
  }

  static scheduledPropertyCustomRules(
    scheduledCategories: Nullable<Dictionary<ScheduledCategoriesEntityState>>,
    products: ProductModel[]
  ): TaskModel[] {
    const schedPropTasks: TaskModel[] = [];

    if (products.some((p) => p.type === 'Condominium')) {
      const scheduledCategoriesForProduct =
        scheduledCategories?.['Condominium']?.scheduledCategories;
      const itemsOver25k = scheduledCategoriesForProduct?.filter(
        (category) =>
          category.scheduledItems?.find(
            (item) => +item.value >= 25000 && !item.isPreviouslyInsuredByNW
          ) !== undefined
      );

      if (itemsOver25k?.length) {
        const productType = scheduledCategoriesForProduct?.map(
          (category) => category.productType
        )[0];
        const matchingProduct = products.find(
          (prod) => prod.type === productType
        );
        schedPropTasks.push({
          page: CoveragesPageRepresentation,
          field: 'value',
          entityType: 'coverage',
          productType: 'Condominium',
          ratingStatusOrdinal: 'Quoted',
          elementId: 'scheduled-item-form',
          message:
            'One or more items are valued over $25,000 and not previously insured by Nationwide. Remove the item or get underwriting approval to bind',
          resolve: TaskActions.openInPolicyCenter({
            quoteId: matchingProduct?.quoteId || '',
            removeProduct: matchingProduct?.type,
            reason: 'scheduled property over $25k',
          }),
          resolveLabel: 'Complete quote in PolicyCenter',
        });
      }
    }
    if (products.some((p) => p.type === 'Homeowner')) {
      const scheduledCategoriesForProduct =
        scheduledCategories?.['Homeowner']?.scheduledCategories;
      const itemsOver25k = scheduledCategoriesForProduct?.filter(
        (category) =>
          category.scheduledItems?.find(
            (item) => +item.value >= 25000 && !item.isPreviouslyInsuredByNW
          ) !== undefined
      );

      if (itemsOver25k?.length) {
        const productType = scheduledCategoriesForProduct?.map(
          (category) => category.productType
        )[0];
        const matchingProduct = products.find(
          (prod) => prod.type === productType
        );
        schedPropTasks.push({
          page: CoveragesPageRepresentation,
          field: 'value',
          entityType: 'coverage',
          productType: 'Homeowner',
          ratingStatusOrdinal: 'Quoted',
          elementId: 'scheduled-item-form',
          message:
            'One or more items are valued over $25,000 and not previously insured by Nationwide. Remove the item or get underwriting approval to bind',
          resolve: TaskActions.openInPolicyCenter({
            quoteId: matchingProduct?.quoteId || '',
            removeProduct: matchingProduct?.type,
            reason: 'scheduled property over $25k',
          }),
          resolveLabel: 'Complete quote in PolicyCenter',
        });
      }
    }

    return schedPropTasks;
  }

  static hasAutoAndPropertyUnderlyingPolicies(
    entity: UnderlyingPolicyEntity[]
  ): TaskModel[] {
    const underlyingPolicies = entity;
    const hasAutoPolicy = underlyingPolicies.find(
      (policy) => policy.policyType === 'PA'
    );
    const hasPropertyPolicy = underlyingPolicies.find(
      (policy) => policy.policyType === 'HO'
    );
    if (hasAutoPolicy && hasPropertyPolicy) {
      return [];
    }
    return [
      {
        message:
          'Please add at least one auto and one property underlying policy.',
        field: '',
        ratingStatusOrdinal: 'Draft',
        productType: 'PersonalUmbrella',
        entityType: 'umbrella',
        page: getPageRepresentationByProductId('PersonalUmbrella'),
      },
    ];
  }

  static hasAtLeastOneExposurePerUnderlyingPolicy(
    underlyingPolicies: UnderlyingPolicyEntity[],
    exposures: Exposures
  ): TaskModel[] {
    let policyMessage = '';

    const hasExposures = underlyingPolicies.every((policy) => {
      const vehicleExposureMatch = exposures?.vehicleExposures?.some(
        (exposure) =>
          exposure?.underlyingPolicyNumber === policy?.underlyingPolicyNumber
      );
      const locationExposureMatch = exposures?.locationExposures?.some(
        (exposure) =>
          exposure?.underlyingPolicyNumber === policy?.underlyingPolicyNumber
      );
      const watercraftExposureMatch = exposures?.watercraftExposures?.some(
        (exposure) =>
          exposure?.underlyingPolicyNumber === policy?.underlyingPolicyNumber
      );

      if (
        vehicleExposureMatch ||
        locationExposureMatch ||
        watercraftExposureMatch
      ) {
        return true;
      }
      policyMessage = this.buildUnderlyingPolicyMessage(
        policy.policyType,
        policy.currentCarrierName,
        policy.underlyingPolicyNumber
      );
      return false;
    });

    if (hasExposures) {
      return [];
    }
    return [
      {
        message: policyMessage,
        field: '',
        ratingStatusOrdinal: 'Draft',
        productType: 'PersonalUmbrella',
        entityType: 'umbrella',
        page: getPageRepresentationByProductId('PersonalUmbrella'),
      },
    ];
  }

  static allVehiclesListedAsExposures(
    exposureCount: number,
    vehicles: VehicleEntity[]
  ): TaskModel[] {
    if (vehicles.length < 1) {
      return [];
    }
    if (exposureCount < 1) {
      // Either no vehicle policy is in play, or they haven't added any exposures yet.
      // This case is caught by other rules.
      return [];
    }
    // It's not possible to know which vehicle an exposure refers to.
    // But we can check that there are as many exposures as vehicles.
    if (exposureCount >= vehicles.length) {
      return [];
    }
    const missingCount = vehicles.length - exposureCount;
    const vehicleNoun = missingCount > 1 ? 'vehicles' : 'vehicle';
    return [
      {
        message: `${missingCount} ${vehicleNoun} not listed as an exposure for the Umbrella quote.`,
        field: 'vehicleExposureMissing',
        ratingStatusOrdinal: 'Draft',
        productType: 'PersonalUmbrella',
        entityType: 'review',
        page: getPageRepresentationByProductId('PersonalUmbrella'),
        resolve: dismissReviewTask({
          payload: {
            field: 'vehicleExposureMissing',
            productType: 'PersonalUmbrella',
          },
        }),
        resolveLabel: 'Dismiss Reminder',
      },
    ];
  }

  static unissuedUnderlyingPoliciesAreActiveInSession(
    underlyingPolicies: UnderlyingPolicyEntity[],
    selectedProducts: ProductModel[]
  ): TaskModel[] {
    const tasks: TaskModel[] = [];
    for (const up of underlyingPolicies) {
      if (up.currentCarrierName !== 'Nationwide_Ext') {
        // Not NW, anything goes.
        continue;
      }
      if (!StringUtils.policyNumberIsReallyQuoteId(up.underlyingPolicyNumber)) {
        // Has a real policyNumber, let it thru.
        continue;
      }
      if (
        selectedProducts.find((p) => p.quoteId === up.underlyingPolicyNumber)
      ) {
        // They're issuing this underlying product at the same time, all good.
        continue;
      }
      tasks.push({
        message: `Underlying policy ${up.underlyingPolicyNumber} has not been issued.`,
        field: '',
        ratingStatusOrdinal: 'Quoted',
        productType: 'PersonalUmbrella',
        entityType: 'umbrella',
        page: getPageRepresentationByProductId('PersonalUmbrella'),
      });
    }
    return tasks;
  }

  private static buildUnderlyingPolicyMessage(
    policyType: string,
    carrierName: string,
    policyNumber: string
  ): string {
    let type = '';
    switch (policyType) {
      case 'HO':
        type = 'property';
        break;
      case 'PA':
        type = 'auto';
        break;
      case 'PWL':
        type = 'watercraft';
        break;
      default:
        type = 'misc vehicle';
        break;
    }

    return `Please add an exposure for <strong>${UmbrellaUtils.getCurrentCarrierNameLabel(
      carrierName
    )} ${type} policy - ${policyNumber}</strong>. At least one exposure must be listed for each underlying policy.`;
  }

  private static missingRequiredFields(
    entityObject: any,
    fields: string[]
  ): boolean {
    // all fields exist and have values
    const objectKeys = Object.keys(entityObject);
    let returnValue = false;

    fields.forEach((field) => {
      if (
        !objectKeys.includes(field) ||
        entityObject[field] === (null || undefined || '')
      ) {
        returnValue = true;
      }
    });

    return returnValue;
  }

  private static setEntityTemplate<T>(
    entity: T,
    page: Page,
    taskEntityType: TaskEntityType,
    taskProductType: TaskProductType,
    entityIdKey?: keyof T
  ): TaskModel {
    const entityTemplate: TaskModel = {
      page,
    } as TaskModel;
    if (entityIdKey && entity?.[entityIdKey]) {
      entityTemplate.entityId = '' + entity[entityIdKey];
    }
    if (taskEntityType) {
      entityTemplate.entityType = taskEntityType;
    }
    if (taskProductType) {
      entityTemplate.productType = taskProductType;
    }
    return entityTemplate;
  }

  private static applyRuleTemplates<T>(
    tasks: TaskModel[],
    rulesForEach: ((a: Partial<TaskModel>, b: T) => TaskModel | null)[],
    entityTemplate: TaskModel,
    entity: T
  ): void {
    for (const rule of rulesForEach) {
      const task = rule(entityTemplate, entity);
      if (task) {
        tasks.push(task);
      }
    }
  }

  private static getProductTaskEntityType(productType: ProductType): string {
    if (['PersonalAuto', 'MSA', 'RV', 'Boat'].includes(productType)) {
      return StringUtils.convertProductTypeToCamelCase(productType) + 'Product';
    } else if (['PersonalUmbrella'].includes(productType)) {
      return 'umbrella';
    } else {
      return 'coveredLocation';
    }
  }
}
