import { PageAlertActions, SessionActions } from '@core/store/actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { PageAlert } from './page-alert.model';

export interface PageAlertState extends EntityState<PageAlert> {
  loaded: boolean;
  loading: boolean;
}

function idFromPageAlert(alert: PageAlert): string {
  if (alert.id) {
    return alert.id;
  }
  if (alert.appliedStates.length > 0) {
    return `${alert.pageId}_${alert.priority}_${alert.appliedChannels.join(
      '-'
    )}_${alert.appliedStates.join('-')}`;
  } else {
    return `${alert.pageId}_${alert.priority}_${alert.appliedChannels.join(
      '-'
    )}`;
  }
}

export const adapter: EntityAdapter<PageAlert> = createEntityAdapter<PageAlert>(
  {
    selectId: (pageAlert) => idFromPageAlert(pageAlert),
  }
);

const pageAlertReducer = createReducer(
  adapter.getInitialState({
    loaded: false,
    loading: false,
  }),
  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({
      loaded: false,
      loading: false,
    })
  ),
  on(PageAlertActions.LoadAllPageAlerts, (state) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(PageAlertActions.LoadAllPageAlertSuccess, (state, { payload }) =>
    adapter.addMany(payload, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),
  on(PageAlertActions.LoadAllPageAlertFail, (state) => {
    return {
      ...state,
      loading: false,
      loaded: false,
    };
  })
);

export function reducer(
  state: PageAlertState | undefined,
  action: Action
): PageAlertState {
  return pageAlertReducer(state, action);
}
