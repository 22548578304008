import { ObjectValidator } from '@core/helper/object-validator';
import { BaseEntity } from '@core/models/entities/base.entity';

export interface FilingEntity extends BaseEntity {
  filingState: string;
  filingType: string;
}

export function sanitizeFilingEntity(response: unknown): FilingEntity {
  return ObjectValidator.forceSchema<FilingEntity>(response, {
    entityId: 'any',
    prefillId: 'string',
    productIds: 'any',
    productType: 'string',
    quoteId: 'string',
    selected: 'boolean',
    filingState: 'string',
    filingType: 'string',
  }, [
    'entityId',
    'prefillId',
    'productIds',
    'productType',
    'quoteId',
    'selected',
  ]);
}
