<form [nwxExposeFormErrors]="form" [formGroup]="form" formName="producer-selection-{{product.productType}}"
  id="producer-selection-{{product.productType}}">
  <div class="bolt-row bolt-space-bottom-lg nwx-task-tile">
    <div id="loading-spinner" *ngIf="submitting">
      <span>Updating...</span>
      <bolt-waiting-indicator minimal></bolt-waiting-indicator>
    </div>
    <div class="bolt-col-12 bolt-col-sm-6 bolt-col-lg-4">
      <div class="bolt-row bolt-no-gutters">
        <div class="bolt-col-auto title-img-content">
          <img [src]="imageUrl(product.productType)" alt="{{ product.productType }} icon" class="title-img" />
        </div>
        <div class="bolt-col title-text-content">
          <div class="content-label">
            <strong>{{ product.name }}</strong>
          </div>
          <div class="content-subtext bolt-body-copy-sm">
            Submission ID: <span style="font-weight: bold">{{ product.quoteId }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="bolt-col-12 bolt-col-sm-6 bolt-col-lg-8">
      <nwx-advanced-picklist class="white" [formControlName]="product.productType"
        [id]="product.productType" [labelForId]="product.productType+'-input'" componentId="dummyProducerCodeInput"
        placeholder="" loadingText="Loading..." [options]="{
              singleSelection: true,
              placeholderText: 'Producer code selection is required',
              removeLabel: true,
              disabled: disabled
            }" [clearable]="false" [loading]="loading" [isInvalid]="!disabled && !isProductSelected(product.productType)"
        [invalidFeedbackText]="'Producer code is required'" [itemList]="producerCodeOptions"
        (itemsSelected)="onProducerCodeSelected($event,product.productType)"></nwx-advanced-picklist>
    </div>
    </div>
</form>
