<div id="discounts-modal">
  <div class="modal-header">
    <h4 class="bolt-body-copy-lg" id="discountInfoModalHeader">
      Discount information
    </h4>
  </div>
  <div class="inner-body">
    <div class="bolt-container-fluid">
      <bolt-accordion-group header="" class="discounts-group">
        <div *ngFor="let product of products">
          <bolt-accordion
            *ngIf="product.type !== 'PersonalUmbrella'"
            class="discount-group"
            header="{{ product.name }} discounts "
          >
            <ng-container
              *ngFor="let helptext of discountInformation[product.type]"
            >
              <ul>
                <li>
                  <strong>{{ helptext.discountName }}</strong> –
                  {{ helptext.helpText }}
                </li>
              </ul>
            </ng-container>
            <br>
            <p *ngIf="product.type === 'PersonalAuto'">
              {{ agentDisclaimer }}
            </p>
          </bolt-accordion>
          <hr *ngIf="product.type !== 'PersonalUmbrella'" />
        </div>
        <div>
          <bolt-accordion
            class="discount-group"
            header="Policy level discounts"
          >
            <ng-container
              *ngFor="let helptext of discountInformation['PolicyLevel']"
            >
              <ul>
                <li>
                  <strong>{{ helptext.discountName }}</strong> –
                  {{ helptext.helpText }}
                </li>
              </ul>
            </ng-container>
          </bolt-accordion>
          <hr />
        </div>
      </bolt-accordion-group>
    </div>
  </div>
  <div class="modal-footer">
    <bolt-button-bar>
      <bolt-button slot="back" type="primary" (click)="closeModal()"
        >Close</bolt-button
      >
    </bolt-button-bar>
  </div>
</div>
