import { Injectable } from '@angular/core';
import { HouseholdMemberAdapter } from '@core/adapters/household-member.adapter';
import { HouseholdMemberRequest } from '@core/models/api/request/household-member-request.model';
import { HouseholdMemberEntity } from '@entities/household-member/household-member.model';
import { PersonUtils } from '@shared/utils/person.utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HouseholdMemberService {
  constructor(private adapter: HouseholdMemberAdapter) {}

  addOrUpdateHouseholdMember(
    request: HouseholdMemberRequest
  ): Observable<HouseholdMemberEntity> {
    if (
      PersonUtils.isHouseholdMemberSaved(
        Number(request.householdMember?.householdMemberId)
      )
    ) {
      return this.adapter.updateHouseholdMember(request);
    } else {
      return this.adapter.addHouseholdMember(request);
    }
  }

  removeHouseholdMember(
    request: HouseholdMemberRequest
  ): Observable<unknown> {
    return this.adapter.removeHouseholdMember(request);
  }
}
