export const MsaVehicleDisplayTypes: UiVehicleType[] = [
  {
    type: 'Motorcycle',
    description: 'Motorcycle & On Road',
  },
  {
    type: 'OffRoad',
    description: 'Off Road',
  },
  {
    type: 'Snowmobile',
    description: 'Snowmobile',
  },
];

export const RVVehicleDisplayTypes: UiVehicleType[] = [
  {
    type: 'Motorhome',
    description: 'Motorhome',
  },
  {
    type: 'TravelTrailer',
    description: 'Travel Trailer',
  },
];

export const VinVehicleTypeToDsmVehicleType = {
  CYCLE: 'Motorcycle',
  OFFRD: 'OffRoad',
  SNOWM: 'Snowmobile',
  UTLTR: 'UtilityTrailer',
};

export const GolfCartSubTypes = ['GolfCart1', 'GolfCart2'];

/**
 * Vehicle SubType codes - https://github.nwie.net/Nationwide/pls-policycenter/blob/main/config/extensions/typelist/MSASubType_Ext.tti
 * Vehicle SubType Class - https://github.nwie.net/Nationwide/pls-policycenter/blob/main/config/extensions/typelist/MSASubTypeClass_Ext.tti
 */
export const MSA_VEHICLE_SUB_TYPE_CLASSES: MsaVehicleSubTypeClass[] = [
  {
    code: 'Unacceptable',
    desc: 'Unacceptable',
    subTypes: [{ code: 'Unacceptable', desc: 'Unacceptable' }],
  },
  {
    code: 'HarleyDavidsonFX',
    desc: 'Harley Davidson FX',
    subTypes: [{ code: 'HarleyDavidsonFx', desc: 'Harley Davidson FX' }],
  },
  {
    code: 'HarleyDavidsonXL',
    desc: 'Harley Davidson XL',
    subTypes: [{ code: 'HarleyDavidsonXL', desc: 'Harley Davidson XL' }],
  },
  {
    code: 'HarleyDavidsonVRod',
    desc: 'Harley Davidson VRod',
    subTypes: [{ code: 'HarleyDavidsonVRod', desc: 'Harley Davidson VRod' }],
  },
  {
    code: 'NewHarleyFB',
    desc: 'New Harley FB',
    subTypes: [{ code: 'NewHarleyFB', desc: 'New Harley FB' }],
  },
  {
    code: 'NewHarleyFL',
    desc: 'New Harley FL',
    subTypes: [{ code: 'NewHarleyFL', desc: 'New Harley FL' }],
  },
  {
    code: 'Touring',
    desc: 'Touring',
    subTypes: [{ code: 'Touring', desc: 'Touring' }],
  },
  {
    code: 'SportTouring',
    desc: 'Sport Touring',
    subTypes: [{ code: 'SportTouring', desc: 'Sport Touring' }],
  },
  {
    code: 'ReverseTrikeAutocycle',
    desc: 'Reverse Trike/Autocycle',
    subTypes: [
      { code: 'ReverseTrikeAutocycle', desc: 'Reverse Trike/Autocycle' },
    ],
  },
  {
    code: 'SportBike',
    desc: 'Sport Bike',
    subTypes: [
      { code: 'HighPerformance1', desc: 'High Performance 1' },
      { code: 'HighPerformance2', desc: 'High Performance 2' },
      { code: 'StreetSport1', desc: 'Street Sport 1' },
      { code: 'StreetSport2', desc: 'Street Sport 2' },
      { code: 'StreetSport3', desc: 'Street Sport 3' },
    ],
  },
  {
    code: 'Regular',
    desc: 'Regular',
    subTypes: [{ code: 'Regular', desc: 'Regular' }],
  },
  {
    code: 'Cruiser',
    desc: 'Cruiser',
    subTypes: [{ code: 'Cruiser', desc: 'Cruiser' }],
  },
  {
    code: 'DualPurpose',
    desc: 'Dual Purpose',
    subTypes: [{ code: 'DualPurpose', desc: 'Dual Purpose' }],
  },
  {
    code: 'CustomLimitedProductionCruiser',
    desc: 'Custom / Limited Production Cruiser',
    subTypes: [
      { code: 'Custom', desc: 'Custom' },
      {
        code: 'LimitedProductionCruiserNoNADA',
        desc: 'Limited Production Cruiser (no value in NADA)',
      },
      {
        code: 'LimitedProductionCruiserNADA',
        desc: 'Limited Production Cruiser (value in NADA)',
      },
    ],
    uiQuestions: ['AgreedValue'],
  },
  {
    code: 'AssembledKit',
    desc: 'Assembled / Kit',
    subTypes: [{ code: 'AssembledKit', desc: 'Assembled / Kit' }],
    uiQuestions: ['AgreedValue'],
  },
  {
    code: 'Trike',
    desc: 'Trike (converted)',
    subTypes: [{ code: 'Trike', desc: 'Trike (converted)' }],
    uiQuestions: ['AgreedValue'],
    vehicleTypes: ['Motorcycle'],
  },
  {
    code: 'AgreedValueVintage',
    desc: 'Agreed Value Vintage (> 25 years)',
    subTypes: [{ code: 'VintageMC', desc: 'Vintage (25+ years old)' }],
    uiQuestions: ['AgreedValue'],
  },
  {
    code: 'AgreedValueOn-Road',
    desc: 'Agreed Value On-Road',
    subTypes: [
      { code: 'OtherAgreedValueOnRoad', desc: 'Other Agreed Value On-Road' },
    ],
    uiQuestions: ['AgreedValue'],
  },
  {
    code: 'AgreedValueOff-Road',
    desc: 'Agreed Value Off-Road',
    subTypes: [{ code: 'AgreedValueOffRoad', desc: 'Agreed Value Off-Road' }],
    uiQuestions: ['AgreedValue'],
  },
  {
    code: 'ElectricBicycle',
    desc: 'Electric Bicycle',
    subTypes: [{ code: 'ElectricBicycle', desc: 'Electric Bicycle' }],
  },
  {
    code: 'PersonalTransporter',
    desc: 'Personal Transporter',
    subTypes: [{ code: 'PersonalTransporter', desc: 'Personal Transporter' }],
  },
  {
    code: 'ElectricVehicle',
    desc: 'Electric Vehicle',
    subTypes: [{ code: 'ElectricVehicle', desc: 'Electric Vehicle' }],
  },
  {
    code: 'ATV',
    desc: 'ATV',
    subTypes: [
      { code: 'ATVStandard', desc: 'ATV Standard' },
      { code: 'ATVSport', desc: 'ATV Sport' },
    ],
  },
  {
    code: 'DirtBike',
    desc: 'Dirt Bike',
    subTypes: [{ code: 'DirtBike', desc: 'Dirt Bike' }],
  },
  {
    code: 'GolfCart',
    desc: 'Golf Cart',
    subTypes: [
      { code: 'GolfCart1', desc: 'Golf Cart 1' },
      { code: 'GolfCart2', desc: 'Golf Cart 2' },
    ],
  },
  {
    code: 'LowSpeedVehicle',
    desc: 'Low Speed Vehicle',
    subTypes: [{ code: 'LowSpeedVehicle', desc: 'Low Speed Vehicle' }],
  },
  {
    code: 'DuneBuggy',
    desc: 'Dune Buggy',
    subTypes: [{ code: 'DuneBuggy', desc: 'Dune Buggy' }],
    uiQuestions: ['MarketValue'],
  },
  {
    code: 'UtilityVehicle',
    desc: 'Utility Vehicle (UTV)',
    subTypes: [
      { code: 'Utility', desc: 'Utility' },
      { code: 'SportUtility', desc: 'Sport Utility' },
    ],
  },
  {
    code: 'LawnandGardenTractors',
    desc: 'Lawn and Garden Tractors',
    subTypes: [
      { code: 'LawnAndGardenTractors', desc: 'Lawn and Garden Tractors' },
    ],
    uiQuestions: ['MarketValue', 'Horsepower'],
  },
  {
    code: 'Snowmobile',
    desc: 'Snowmobile',
    subTypes: [
      { code: 'SnowmobileRegular', desc: 'Snowmobile Regular' },
      { code: 'SnowmobilePerformance', desc: 'Snowmobile Performance' },
      { code: 'SnowmobileTouring', desc: 'Snowmobile Touring' },
    ],
  },
  {
    code: 'UtilityTrailer',
    desc: 'Utility Trailer',
    subTypes: [{ code: 'UtilityTrailer', desc: 'Trailer' }],
  },
  {
    code: 'ScooterMoped',
    desc: 'Scooter / Moped',
    subTypes: [
      { code: 'ScooterMoped', desc: 'Scooter / Moped' },
      { code: 'LPScooterMoped', desc: 'LP Scooter / Moped' },
    ],
  },
];

export interface MsaVehicleSubTypeClass {
  code: string;
  desc: string;
  subTypes: MsaVehicleSubType[];
  uiQuestions?: string[];
  vehicleTypes?: string[];
}

export interface MsaVehicleSubType {
  code: string;
  desc: string;
}

export interface UiVehicleType {
  type: string;
  description: string;
}
