import { getSelectedProductTypes } from '../entities/product/product.selectors';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import {
  Actions,
  ofType,
  createEffect,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';

import { map, mergeMap } from 'rxjs/operators';
import { HydrateActions } from '../actions';
import { Action, Store } from '@ngrx/store';
import { defer } from 'rxjs';

@Injectable()
export class HydrationEffects {
  constructor(private actions$: Actions, private store: Store) {}

  attemptHydrationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HydrateActions.ATTEMPT_HYDRATE_TYPE),
      mergeMap((hydrateAction) => {
        const nextActions: Action[] = [];
        const hydrateString = localStorage.getItem('hydrate');
        localStorage.removeItem('hydrate');
        let hydrateStorage = { expiry: 0, actions: [] };
        if (!hydrateString) {
          return nextActions;
        }

        hydrateStorage = JSON.parse(hydrateString);
        if (!hydrateStorage.expiry || !hydrateStorage.actions) {
          return nextActions;
        }

        const now = new Date();
        if (now.getTime() < hydrateStorage.expiry) {
          hydrateStorage.actions.forEach((storedAction: any) => {
            const action = storedAction as Action;
            nextActions.push(action);
          });
        }

        localStorage.setItem(
          'hydrate',
          JSON.stringify({ expiry: 0, actions: [] })
        );

        return nextActions;
      })
    )
  );

  triggerRehydrate$ = defer(() =>
    createEffect(() =>
      this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        map((action) => {
          return HydrateActions.attemptHydrate();
        })
      )
    )
  );
}
