<nwx-notification-bubble
  class="top-notification"
  *ngIf="(errors$ | async)?.length"
  type="error"
  id="notification"
>
  <div
    class="message bolt-typography bolt-row"
    *ngFor="let error of errors$ | async"
  >
    <div class="bolt-col-8">
      <p class="text-line-height">
        <strong *ngIf="error.productType">
          {{ productTypeRename(error.productType) }}
        </strong>
        <span [innerHTML]="error.displayMessage"></span>
      </p>
    </div>
    <div class="bolt-col-4">
      <bolt-button
        class="button-float"
        size="sm"
        (click)="clickErrorButton(error)"
        >{{ error.remediation.description }}</bolt-button
      >
    </div>
  </div>
</nwx-notification-bubble>
<nwx-notification-bubble
  class="bottom-notification"
  *ngIf="(warningLevelErrors$ | async)?.length || (warnings$ | async)?.length"
  type="warning"
>
<div
  class="message bolt-typography bolt-row"
  *ngFor="let warning of warnings$ | async"
>
  <div class="bolt-col-8">
    <p class="text-line-height">
      <strong *ngIf="warning.productType">
        {{ productTypeRename(warning.productType) }}
      </strong>
      <span [innerHTML]="warning.message"></span>
    </p>
  </div>
  <div class="bolt-col-4">
    <bolt-button
      class="button-float"
      size="sm"
      (click)="clickWarningButton(warning)"
      >{{ warning.buttonLabel }}</bolt-button
    >
  </div>
</div>
  <div
    class="message bolt-typography bolt-row"
    *ngFor="let warning of warningLevelErrors$ | async"
  >
    <div class="bolt-col-8">
      <p class="text-line-height">
        <strong *ngIf="warning.productType">
          {{ productTypeRename(warning.productType) }}
        </strong>
        <span [innerHTML]="warning.displayMessage"></span>
      </p>
    </div>
    <div class="bolt-col-4">
      <bolt-button
        class="button-float"
        size="sm"
        (click)="dismissError(warning)"
        >Dismiss</bolt-button>
    </div>
  </div>
</nwx-notification-bubble>
<nwx-notification-bubble
class="bottom-notification"
*ngIf="(notifications$ | async)?.length || (infoLevelErrors$ | async)?.length" type="info">
  <strong *ngIf="(notifications$ | async)?.length">Tasks:</strong>
  <div
    class="message d-flex bolt-justify-content-between align-items-center mt-3"
    *ngFor="let notification of notifications$ | async"
  >
    <a
      *ngIf="notification.elementId; else noLink"
      [routerLink]="notification.pageUrl || ''"
      [fragment]="notification.elementId || ''"
      [skipLocationChange]="true"
    >
      <p [innerHTML]="notification.message"></p>
    </a>
    <ng-template #noLink>
      <p [innerHTML]="notification.message"></p>
    </ng-template>
    <bolt-button
      *ngIf="notification.resolve"
      size="sm"
      type="primary"
      (click)="resolveTask(notification.resolve)"
      >{{ notification.resolveLabel || 'Dismiss Task' }}
    </bolt-button>
  </div>
  <div
    class="message bolt-typography bolt-row"
    *ngFor="let error of infoLevelErrors$ | async"
  >
    <div class="bolt-col-8">
      <p class="text-line-height">
        <strong *ngIf="error.productType">
          {{ productTypeRename(error.productType) }}
        </strong>
        <span [innerHTML]="error.displayMessage"></span>
      </p>
    </div>
    <div class="bolt-col-4">
      <bolt-button class="button-float" size="sm" (click)="dismissError(error)"
        >Dismiss</bolt-button>
    </div>
  </div>
</nwx-notification-bubble>
