import {
  BaseEntity,
  BaseEntityOptionalFields,
  BaseEntitySanitizerFields,
} from '@core/models/entities/base.entity';
import {
  AdditionalInsuredType,
  AdditionalInterestType,
  NumberOfCoOwnersType,
  VehicleType,
} from '@app/core/models/api/dsm-types';
import {
  BoatLength,
  BoatTrailer,
  FuelType,
  HullType,
  MotorTypeCode,
  OutboardMotor,
  PassiveRestraintModel,
  PrimaryUseType,
  sanitizeBoatTrailer,
  sanitizeOutboardMotor,
} from '@core/models/views/vehicle.model';
import { CurrentCarrierEntity } from '../current-carrier/current-carrier.reducer';
import { VehiclesPageModel } from '@core/models/views/vehicle-page-model';
import { Nullable } from '@app/shared/utils/type.utils';
import { MsaPageModel } from '@app/powersports/msa/pages/msa-page.component';
import {
  BasicAddressEntity,
  sanitizeBasicAddressEntity,
} from '../address/address.entity';
import {
  EligibleDiscountsEntity,
  sanitizeEligibleDiscountsEntity,
} from '../eligible-discounts/eligible-discounts.entity';
import {
  PersonEntity,
  sanitizePersonEntity,
} from '@core/models/entities/person.entity';
import { ObjectValidator } from '@core/helper/object-validator';

export interface VehicleEntity extends BaseEntity {
  // MSA specific fields
  // TODO The above is from OAS for addVehicle request. Check other calls.
  additionalInformation?: Nullable<AdditionalInformationEntity>;
  additionalInterests?: VehicleAdditionalInterestEntity[];
  agreedValue?: number;
  annualMiles?: Nullable<number>;
  antiLockBrakes?: string;
  antiTheftDevices?: AntiTheftDeviceEntity[];
  auxiliaryLights?: string;
  bodyStyle?: Nullable<string>;
  bodyType?: Nullable<string>;
  carPool?: Nullable<string>;
  costNew?: Nullable<VehicleCostNewEntity>;
  cubicCentimeters?: number;
  customizationAmount?: number;
  customizationDetails?: string;
  damageLocation?: string;
  daysCommutingPerWeek?: number;
  easyPayDiscountAmount?: EasyPayDiscountAmountEntity;
  eligibleDiscounts?: EligibleDiscountsEntity[];
  excludedDrivers?: VehicleExcludedDriverEntity[];
  garageLocation?: BasicAddressEntity;
  hasPriorDamage?: boolean;
  horsepower?: number;
  index?: number;
  isAnyRegisteredOwnerAPolicyDriver?: boolean;
  isConnectable?: boolean;
  isMakeNotFound?: boolean;
  isModelNotFound?: boolean;
  isPurchasedNew?: boolean;
  isRegisteredInState?: boolean;
  isSmartRideDeviceEligible?: boolean;
  make?: string;
  marketValue?: number;
  milesOneWay?: number;
  model?: string;
  odometerReading?: number;
  passiveRestraint?: PassiveRestraintModel;
  prequalifiedPartnerScoreId?: string; // UUID
  previouslyOwned?: boolean;
  primaryUse?: PrimaryUseType;
  purchasedNewDate?: string | null; // 8601
  registeredOwners?: VehicleRegisteredOwnerEntity[];
  sequenceNumber?: number;
  series?: Nullable<string>;
  subTypeClass?: string;
  subType?: string;
  value?: number;
  vehicleId: string;
  vehicleSubType?: Nullable<string>;
  vehicleType?: VehicleType; // eg "auto"
  vin?: string;
  hin?: string;
  serialNumber?: string;
  motorSerialNumber?: string;
  year?: number;
  hullType?: HullType;
  motorType?: MotorTypeCode;
  motorDetails?: OutboardMotor[];
  fuelType?: FuelType;
  boatLength?: BoatLength;
  numOfMotors?: number;
  topSpeed?: number;
  vehicleLength?: number;
  boatWeight?: number;
  weight?: number;
  purchasedDate?: string;
  estimatedMarketValue?: number;
  msrp?: number; // boat
  totalMarketValue?: number;
  totalManufacturersSuggestedRetailPrice?: number;
  totalHorsepower?: number;
  hasTrailer?: boolean;
  trailer?: BoatTrailer;
  isVehicleCoOwned?: boolean;
  type?: string; // TODO boat types
  modelNumber?: string; // rv
  safetyDevice?: string;
  hasFiberglassShell?: string;
  hasTirePressureMonitoring?: string;
  controlNumber?: string;
  // RV Utility Trailer
  enclosedToTransportVehicles?: boolean;
}

export function sanitizeVehicleEntities(input: unknown): VehicleEntity[] {
  return ObjectValidator.sanitizeArray(input, sanitizeVehicleEntity);
}

export function sanitizeVehicleEntity(input: unknown): VehicleEntity {
  return ObjectValidator.forceSchema<VehicleEntity>(
    input,
    {
      additionalInformation: sanitizeAdditionalInformationEntity,
      additionalInterests: [sanitizeVehicleAdditionalInterestEntity],
      agreedValue: 'number',
      annualMiles: 'number',
      antiLockBrakes: 'string',
      antiTheftDevices: [sanitizeAntiTheftDeviceEntity],
      auxiliaryLights: 'string',
      bodyStyle: 'string',
      bodyType: 'string',
      carPool: 'string',
      costNew: { currency: 'string', amount: 'number' },
      cubicCentimeters: 'number',
      customizationAmount: 'number',
      customizationDetails: 'string',
      damageLocation: 'string',
      daysCommutingPerWeek: 'number',
      easyPayDiscountAmount: { currency: 'string', amount: 'number' },
      eligibleDiscounts: [sanitizeEligibleDiscountsEntity],
      excludedDrivers: [{ driverId: 'string' }],
      garageLocation: sanitizeBasicAddressEntity,
      hasPriorDamage: 'boolean',
      horsepower: 'number',
      index: 'number',
      isAnyRegisteredOwnerAPolicyDriver: 'boolean',
      isConnectable: 'boolean',
      isMakeNotFound: 'boolean',
      isModelNotFound: 'boolean',
      isPurchasedNew: 'boolean',
      isRegisteredInState: 'boolean',
      isSmartRideDeviceEligible: 'boolean',
      make: 'string',
      marketValue: 'number',
      milesOneWay: 'number',
      model: 'string',
      odometerReading: 'number',
      passiveRestraint: 'string',
      prequalifiedPartnerScoreId: 'string',
      previouslyOwned: 'boolean',
      primaryUse: 'string',
      purchasedNewDate: 'string',
      registeredOwners: [
        {
          driverId: 'number',
        },
      ],
      sequenceNumber: 'number',
      series: 'string',
      subTypeClass: 'string',
      subType: 'string',
      value: 'number',
      vehicleId: 'string',
      vehicleSubType: 'string',
      vehicleType: 'string',
      vin: 'string',
      hin: 'string',
      serialNumber: 'string',
      motorSerialNumber: 'string',
      year: 'number',
      hullType: 'string',
      motorType: 'string',
      motorDetails: [sanitizeOutboardMotor],
      fuelType: 'string',
      boatLength: {
        boatLengthFeet: 'number',
        boatLengthInches: 'number',
      },
      numOfMotors: 'number',
      topSpeed: 'number',
      vehicleLength: 'number',
      boatWeight: 'number',
      weight: 'number',
      purchasedDate: 'string',
      estimatedMarketValue: 'number',
      msrp: 'number',
      totalMarketValue: 'number',
      totalManufacturersSuggestedRetailPrice: 'number',
      totalHorsepower: 'number',
      hasTrailer: 'boolean',
      trailer: sanitizeBoatTrailer,
      isVehicleCoOwned: 'boolean',
      type: 'string',
      modelNumber: 'string',
      safetyDevice: 'string',
      hasFiberglassShell: 'string',
      hasTirePressureMonitoring: 'string',
      controlNumber: 'string',
      enclosedToTransportVehicles: 'boolean',
      ...BaseEntitySanitizerFields,
    },
    [
      'additionalInformation',
      'additionalInterests',
      'agreedValue',
      'annualMiles',
      'antiLockBrakes',
      'antiTheftDevices',
      'auxiliaryLights',
      'bodyStyle',
      'bodyType',
      'carPool',
      'costNew',
      'cubicCentimeters',
      'customizationAmount',
      'customizationDetails',
      'damageLocation',
      'daysCommutingPerWeek',
      'easyPayDiscountAmount',
      'eligibleDiscounts',
      'excludedDrivers',
      'garageLocation',
      'hasPriorDamage',
      'horsepower',
      'index',
      'isAnyRegisteredOwnerAPolicyDriver',
      'isConnectable',
      'isMakeNotFound',
      'isModelNotFound',
      'isPurchasedNew',
      'isRegisteredInState',
      'isSmartRideDeviceEligible',
      'make',
      'marketValue',
      'milesOneWay',
      'model',
      'odometerReading',
      'passiveRestraint',
      'prequalifiedPartnerScoreId',
      'previouslyOwned',
      'primaryUse',
      'purchasedNewDate',
      'registeredOwners',
      'sequenceNumber',
      'series',
      'subTypeClass',
      'subType',
      'value',
      'vehicleSubType',
      'vehicleType',
      'vin',
      'hin',
      'serialNumber',
      'motorSerialNumber',
      'year',
      'hullType',
      'motorType',
      'motorDetails',
      'fuelType',
      'boatLength',
      'numOfMotors',
      'topSpeed',
      'vehicleLength',
      'boatWeight',
      'weight',
      'purchasedDate',
      'estimatedMarketValue',
      'msrp',
      'totalMarketValue',
      'totalManufacturersSuggestedRetailPrice',
      'totalHorsepower',
      'hasTrailer',
      'trailer',
      'isVehicleCoOwned',
      'type',
      'modelNumber',
      'safetyDevice',
      'hasFiberglassShell',
      'hasTirePressureMonitoring',
      'controlNumber',
      'enclosedToTransportVehicles',
      ...BaseEntityOptionalFields,
    ]
  );
}

export interface AdditionalInformationEntity {
  isCoOwned?: boolean;
  isStoredInLockedGarage?: boolean;
  numberOfCoOwners?: NumberOfCoOwnersType;
  isRebuiltSalvagedFrameReplaced?: boolean;
  specialHazard?: string;
  hasVehicleBeenConvertedToTrike?: boolean;
  isVehicleBuiltByFirm?: boolean;
  isVehicleRegisteredAsHistorical?: boolean;
  registrationState?: string;
}

export function sanitizeAdditionalInformationEntity(
  input: unknown
): AdditionalInformationEntity {
  return ObjectValidator.forceSchema<AdditionalInformationEntity>(
    input,
    {
      isCoOwned: 'boolean',
      isStoredInLockedGarage: 'boolean',
      numberOfCoOwners: 'string',
      isRebuiltSalvagedFrameReplaced: 'boolean',
      specialHazard: 'string',
      hasVehicleBeenConvertedToTrike: 'boolean',
      isVehicleBuiltByFirm: 'boolean',
      isVehicleRegisteredAsHistorical: 'boolean',
      registrationState: 'string',
    },
    [
      'isCoOwned',
      'isStoredInLockedGarage',
      'numberOfCoOwners',
      'isRebuiltSalvagedFrameReplaced',
      'specialHazard',
      'hasVehicleBeenConvertedToTrike',
      'isVehicleBuiltByFirm',
      'isVehicleRegisteredAsHistorical',
      'registrationState',
    ]
  );
}

export interface VehicleCostNewEntity {
  amount: number;
  currency: string;
}

export interface VehicleRegisteredOwnerEntity {
  driverId: number;
}

export interface VehicleExcludedDriverEntity {
  driverId: string;
}

export interface VehicleQualifyingInformation {
  selectedAntiTheft?: string[];
  hasConsentedToSmartRideText?: boolean;
  mobileNumber?: string;
}

export interface VehicleAdditionalInterestEntity {
  additionalInterestId?: number | string; // string only for temporary ids on unsaved entities
  expirationDate?: string | null; // 8601, date only
  additionalInterestType: AdditionalInterestType;
  companyName?: string;
  isCertRequired?: boolean;
  loanNumber?: string;
  address?: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    postalCode: string; // ZIP+4
  };
  person?: PersonEntity;
  thirdPartyDescription?: AdditionalInsuredType;
}

export function sanitizeVehicleAdditionalInterestEntity(
  input: unknown
): VehicleAdditionalInterestEntity {
  return ObjectValidator.forceSchema<VehicleAdditionalInterestEntity>(
    input,
    {
      additionalInterestId: 'any',
      expirationDate: 'string',
      additionalInterestType: 'string',
      companyName: 'string',
      isCertRequired: 'boolean',
      loanNumber: 'string',
      address: sanitizeBasicAddressEntity,
      person: sanitizePersonEntity,
      thirdPartyDescription: 'string',
    },
    [
      'additionalInterestId',
      'expirationDate',
      'companyName',
      'isCertRequired',
      'loanNumber',
      'address',
      'person',
      'thirdPartyDescription',
    ]
  );
}

export interface AntiTheftDeviceEntity {
  displayName: string;
  selected: boolean;
  mandatory: boolean;
  deviceType: string;
}

export function sanitizeAntiTheftDeviceEntity(
  input: unknown
): AntiTheftDeviceEntity {
  return ObjectValidator.forceSchema<AntiTheftDeviceEntity>(
    input,
    {
      displayName: 'string',
      selected: 'boolean',
      mandatory: 'boolean',
      deviceType: 'string',
    },
    []
  );
}

export interface EasyPayDiscountAmountEntity {
  currency: string;
  amount: number;
}

export interface VehiclePageSubmission {
  created: VehicleEntity[];
  updated: VehicleEntity[];
  deleted: VehicleEntity[];
  currentCarrier: Nullable<CurrentCarrierEntity>;
}

export interface VehiclePageSubmitEvent {
  model: VehiclesPageModel;
  nextPage: string;
}

export interface VehiclePageNavigation {
  nextPage: string;
  model: VehiclesPageModel;
  showSpinner?: boolean | string;
}

// Used by MSA, Boat, RV
export interface PowersportsPageSubmission {
  created: VehicleEntity[];
  updated: VehicleEntity[];
  deleted: VehicleEntity[];
  currentCarrier: Nullable<CurrentCarrierEntity>;
}

export interface MsaPageNavigation {
  nextPage: string;
  model: MsaPageModel;
}
