import { ProductType } from '@core/models/api/dsm-types';
import { createAction, props } from '@ngrx/store';
import { CoverageSyncEntity, CoverageSyncStatus } from './coverage-sync.entity';

export const updateCoverageSync = createAction(
  '[CoverageSync] Update',
  props<{ payload: CoverageSyncEntity }>()
);

export const forceCoverageSync = createAction('[CoverageSync] Force');

export const setCoverageSyncStatusById = createAction(
  '[CoverageSync] Set Status By Id',
  props<{ syncId: string; status: CoverageSyncStatus }>()
);

export const setCoverageSyncStatusByEntityId = createAction(
  '[CoverageSync] Set Status By Coverage Id',
  props<{
    coverageId: string;
    status: CoverageSyncStatus;
    productId: ProductType;
    coverableId?: number;
  }>()
);

export const rebuildFormState = createAction(
  '[CoverageSync] Rebuild Form State'
);
