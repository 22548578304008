import { quoteCallsCompleted } from './../quote/quote.action';
import { QuoteStatus } from '@app/core/models/api/dsm-types';
import { ProductType } from '@core/models/api/dsm-types';
import { getSelectedProducts } from './../product/product.selectors';
import { Injectable } from '@angular/core';
import { FullStoryService } from '@core/services/fullstory.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { map, switchMap, take, withLatestFrom, mergeMap } from 'rxjs/operators';
import { setHubLoaded, updateRatingState } from './session.action';
import { ProductUtils } from '@shared/utils/product.util';
import { ProductActions } from '@core/store/actions';
import * as fromActions from '@core/store/actions/index';
import { from } from 'rxjs';
import { retrieveQuoteSuccess } from '@core/store/retrieve/retrieve.action';
import { selectAllTasks } from '@entities/task/selectors/task.selector';
import {
  BoatSupportedStates,
  RvSupportedStates,
  UmbrellaSupportedStates,
} from '@shared/constants/product-availability-constants';

@Injectable()
export class SessionEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private fullStoryService: FullStoryService
  ) {}

  logInitialHubPageStatistics$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setHubLoaded),
        take(1),
        switchMap(() =>
          this.actions$.pipe(ofType(quoteCallsCompleted), take(1))
        ),
        withLatestFrom(
          this.store.select(getSelectedProducts),
          this.store.select(selectAllTasks)
        ),
        map(([_, selectedProducts, tasks]) => {
          const tasksCount: {
            [key in ProductType]?: { status: QuoteStatus; taskCount: number };
          } = selectedProducts.reduce((prev, next) => {
            return {
              ...prev,
              [next.type]: {
                status: next.quoteStatus || 'Draft',
                taskCount: 0,
              },
            };
          }, {});

          const selectedPropertyProduct = selectedProducts.find((product) =>
            ProductUtils.isPropertyProduct(product.type)
          )?.type;

          tasks.forEach((task) => {
            if (task.productType === 'All') {
              return;
            }

            let count: number;
            if (task.productType === 'Homeowner') {
              if (selectedPropertyProduct) {
                count = tasksCount[selectedPropertyProduct]?.taskCount || 0;
                tasksCount[selectedPropertyProduct] = {
                  status:
                    tasksCount[selectedPropertyProduct]?.status || 'Draft',
                  taskCount: count + 1,
                };
                return;
              } else {
                return;
              }
            }

            count = tasksCount[task.productType]?.taskCount || 0;
            tasksCount[task.productType] = {
              status: tasksCount[task.productType]?.status || 'Draft',
              taskCount: count + 1,
            };
          });

          Object.entries(tasksCount).forEach(([productType, count]) => {
            if (count) {
              this.fullStoryService.logHubLoadStatistics(
                productType as ProductType,
                count
              );
            }
          });
        })
      ),
    { dispatch: false }
  );

  updateRatingStateOnRetrieve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retrieveQuoteSuccess),
      map((action) => action.payload.response),
      mergeMap((response) => {
        return from([
          fromActions.SessionActions.updateRatingState({
            payload: response.policyAddress?.state as string,
          }),
        ]);
      })
    )
  );

  setDsmActiveByRatingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRatingState),
      map((action) => action.payload),
      mergeMap((ratingState) => {
        let nextActions: Action[] = [];
        nextActions.push(
          ProductActions.updateProduct({
            payload: {
              id: 'Boat',
              changes: {
                isDsmActive: BoatSupportedStates.includes(ratingState),
                type: 'Boat',
              },
            },
          })
        );
        nextActions.push(
          ProductActions.updateProduct({
            payload: {
              id: 'RV',
              changes: {
                isDsmActive: RvSupportedStates.includes(ratingState),
                type: 'RV',
              },
            },
          })
        );
        nextActions.push(
          ProductActions.updateProduct({
            payload: {
              id: 'PersonalUmbrella',
              changes: {
                isDsmActive: UmbrellaSupportedStates.includes(ratingState),
                type: 'PersonalUmbrella',
              },
            },
          })
        );
        return from(nextActions);
      })
    )
  );
}
