<div
  class="bolt-container-fluid help-padding"
  [formGroup]="form"
  [nwxExposeFormErrors]="form"
  formName="pni"
  *ngIf="vm$ | async as vm"
>
  <div class="bolt-row" *ngIf="vm.showEmailAddress">
    <div class="bolt-col-12">
      <bolt-textfield
        class="nwpii"
        label="Email address"
        type="emailAddress"
        formControlName="emailAddress"
        name="emailAddress"
        id="email"
        ngDefaultControl
        required
        ></bolt-textfield>
    </div>
  </div>
  <div class="bolt-row" *ngIf="vm.showHomeNumber">
    <div class="bolt-col">
      <bolt-textfield
        class="nwpii"
        label="Mobile phone number"
        instructionaltext="ex: 555-555-5555"
        formControlName="homeNumber"
        ngDefaultControl
        [nwxMask]="'phone'"
        name="homeNumber"
        id="phone"
        required
      ></bolt-textfield>
    </div>
  </div>
</div>
