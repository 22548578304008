<div class="modal-header">
  <h4 ngbAutoFocus class="modal-title" id="billingAccountsModalHeader">
    {{ modalTitle }}
  </h4>
</div>
<div class="modal-body">
  <nwx-billing-plans-form
    [billingPlansFormModel]="billingPlansFormModel"
    [downPaymentResponse]="downPaymentResponse"
    [products]="productModels"
    [quoteState]="quoteState"
    [hasMortgage]="hasMortgage"
    [displayEscrowQuestion]="displayEscrowQuestion"
    [displayBillingPlanAsOptions]="displayBillingPlanAsOptions"
    [easyPayDiscountAmount]="easyPayDiscountAmount"
    [featureFlags]="featureFlags"
    [updateDiscountsImmediately]="false"
    [isModalVersion]="true"
    [maxSizeSmall$]="maxSizeSmall$"
    (changed)="onBillingPlansFormChange($event)"
  ></nwx-billing-plans-form>
</div>
<div class="modal-footer">
  <bolt-button-bar>
    <bolt-button
      slot="forward"
      type="primary"
      [attr.id]="'bam-save-recalculate-quote'"
      (click)="onSubmit()"
      >Save & recalculate quote</bolt-button
    >
    <bolt-button
      slot="back"
      [attr.id]="'bam-cancel'"
      (click)="activeModal.close()"
      >Cancel</bolt-button
    >
  </bolt-button-bar>
</div>
