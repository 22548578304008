import { ObjectValidator } from '@core/helper/object-validator';

export interface AgencyProducerFilterResponse {
  data: AgencyProducerFilter;
}

export interface AgencyProducerFilter {
  recordCount?: string;
  moreRecords?: string;
  cursor?: string;
  filteredProducers?: FilteredProducer[];
  filteredAgencies?: FilteredAgency[];
}

export interface FilteredProducer {
  npn?: string;
  lob?: string;
  state?: string;
  product: string;
  insertId?: string;
  insertTimestamp?: string;
}

export interface FilteredAgency {
  agency?: string;
  agencyName?: string;
  lob?: string;
  state?: string;
  product: string;
  stateRuleId?: string;
  insertId?: string;
  insertTimestamp?: string;
}

export const ACORDProductCode: Record<string, string | string[]> = {
  AUTOP: 'PersonalAuto',
  HOME: ['Homeowner', 'Condominium', 'Tenant'],
  BOAT: 'Boat',
  DFIRE: 'DwellingFire',
  CYCL: 'MSA',
  PUMBR: 'PersonalUmbrella',
  RECV: 'RV',
  ALL: [
    'PersonalAuto',
    'Homeowner',
    'Condominium',
    'Tenant',
    'Boat',
    'DwellingFire',
    'MSA',
    'PersonalUmbrella',
    'RV',
  ],
};

export function sanitizeAgencyProducerFilterResponse(
  input: unknown
): AgencyProducerFilterResponse {
  return ObjectValidator.forceSchema<AgencyProducerFilterResponse>(input, {
    data: sanitizeAgencyProducerFilter,
  });
}

export function sanitizeAgencyProducerFilter(
  input: unknown
): AgencyProducerFilter {
  return ObjectValidator.forceSchema<AgencyProducerFilter>(
    input,
    {
      recordCount: 'string',
      moreRecords: 'string',
      cursor: 'string',
      filteredProducers: 'any',
      filteredAgencies: 'any',
    },
    [
      'recordCount',
      'moreRecords',
      'cursor',
      'filteredProducers',
      'filteredAgencies',
    ]
  );
}
