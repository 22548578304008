import { ObjectValidator } from '@core/helper/object-validator';
import { ProductType } from '@core/models/api/dsm-types';

/* I can't find a source for this in PC, so instead just naming the discounts we have seen:
 *   index=apigee "eligibleDiscountId"
 *   | rex field=_raw "eligibleDiscountId.:.(?<capturedId>[^\"]*)" max_match=0
 *   | stats count by capturedId
 */

// Thank you for maintaining alphabetical order.
export type EligibleDiscountId =
  | 'AdvanceQuote'
  | 'AdvancedDriverTraining'
  | 'AnnuityFP'
  | 'AntiLockBrakes'
  | 'AntiTheft'
  | 'Associate'
  | 'AssociationMember'
  | 'Auto'
  | 'AutomaticTirePressure'
  | 'AuxillaryLightingSystem'
  | 'Bank'
  | 'BillingPaymentMethod'
  | 'Carpool'
  | 'Commercial'
  | 'CondoPP'
  | 'ConnectedCar'
  | 'DaytimeRunningLamps'
  | 'DriverTraining'
  | 'EasyPay'
  | 'FarmAgribusiness'
  | 'FarmBureau'
  | 'FarmPP'
  | 'FiberglassConstruction'
  | 'Financial'
  | 'FinancialProduct'
  | 'FortifiedHome'
  | 'FullPay' /* No longer in use, but we are still coded for it. */
  | 'GoodStudent'
  | 'GroupOccupation'
  | 'HomeCarIndicator_I'
  | 'HomeFinancial'
  | 'HomeownerPP'
  | 'MTNationalGuard'
  | 'Membership'
  | 'Mitigation'
  | 'MobileApp'
  | 'MobileAppEarlyDiscount'
  | 'NNCondoPP'
  | 'NNFarmPP'
  | 'NNHomeownersPP'
  | 'NNTenantsPP'
  | 'NNWCondoPP'
  | 'NNWFarmPP'
  | 'NNWHomePP'
  | 'NNWHomeownersPP'
  | 'NNWMobileHomePP'
  | 'NNWTenantsPP'
  | 'PIPExclusion'
  | 'PaidInFull'
  | 'Paperless'
  | 'PassiveRestraint'
  | 'Powersports'
  | 'RV'
  | 'SafetyCourse'
  | 'SafetyDevice'
  | 'SelfReported'
  | 'SmartDevice'
  | 'SmartMiles'
  | 'SmartRide'
  | 'SmartRideInstant'
  | 'SmartRideObsolete'
  | 'StudentAway' //Resident Student Discount
  | 'TenantsPP'
  | 'TraditionalLifeFP'
  | 'USArmedForces'
  | 'Umbrella'
  | 'Umbrella/Pet'
  | 'VariableLifeFP'
  | 'VetPet'
  | 'WindProtectiveDevice';
// Thank you for maintaining alphabetical order.
export interface EligibleDiscountsEntity {
  id?: string;
  entityId?: string;
  name?: string;
  description?: string;
  productType?: ProductType;
  modelId?: string;
  eligibleDiscountId: EligibleDiscountId;
  selectedOptionValue: string | null;
  quoteId?: string;
  vehicleId?: string;
  driverId?: string;
  helpContent?: any; // TODO type
  qualifyingInformation?: QualifyingInformation;
  availableOptions?: AvailableOption[];
  isMultiProduct?: boolean;
}

export interface QualifyingInformation {
  groupOccupation?: string;
  trainingCourseCompletionDate?: string;
  advancedTrainingCourseCompletionDate?: string;
  selectedAntiTheft?: string[];
  associateNumber?: number;
  membership?: string;
  hasConsentedToSmartRideText?: boolean;
  mobileNumber?: string;
  fortifiedDesignationDate?: string;
}

export interface AvailableOption {
  optionValue: string;
  optionDescription: string;
  isMandatory?: boolean;
}

export function sanitizeEligibleDiscountsEntity(response: unknown): EligibleDiscountsEntity {
  return ObjectValidator.forceSchema<EligibleDiscountsEntity>(response, {
    id: 'string',
    entityId: 'string',
    name: 'string',
    description: 'string',
    productType: 'string',
    modelId: 'string',
    eligibleDiscountId: 'string',
    selectedOptionValue: 'string',
    quoteId: 'string',
    vehicleId: 'string',
    driverId: 'string',
    helpContent: 'any',
    qualifyingInformation: sanitizeQualifyingInformation,
    availableOptions: [sanitizeAvailableOption],
    isMultiProduct: 'boolean',
  }, [
    'id',
    'entityId',
    'name',
    'description',
    'productType',
    'modelId',
    'quoteId',
    'vehicleId',
    'driverId',
    'helpContent',
    'qualifyingInformation',
    'availableOptions',
    'isMultiProduct',
  ]);
}

export function sanitizeQualifyingInformation(response: unknown): QualifyingInformation {
  return ObjectValidator.forceSchema<QualifyingInformation>(response, {
    groupOccupation: 'string',
    trainingCourseCompletionDate: 'string',
    advancedTrainingCourseCompletionDate: 'string',
    selectedAntiTheft: ['string'],
    associateNumber: 'number',
    membership: 'string',
    hasConsentedToSmartRideText: 'boolean',
    mobileNumber: 'string',
    fortifiedDesignationDate: 'string',
  }, [
    'groupOccupation',
    'trainingCourseCompletionDate',
    'advancedTrainingCourseCompletionDate',
    'selectedAntiTheft',
    'associateNumber',
    'membership',
    'hasConsentedToSmartRideText',
    'mobileNumber',
    'fortifiedDesignationDate',
  ]);
}

export function sanitizeAvailableOption(response: unknown): AvailableOption {
  return ObjectValidator.forceSchema<AvailableOption>(response, {
    optionValue: 'string',
    optionDescription: 'string',
    isMandatory: 'boolean',
  }, [
    'isMandatory',
  ]);
}
