import { ProductType } from '@core/models/api/dsm-types';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  switchMap,
  take,
  catchError,
  map,
  concatMap,
  tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { PolicyIssueService } from '@core/services/policy-issue.service';
import {
  issuePolicyFail,
  issuePolicyForProduct,
  issuePolicySuccess,
} from './policy-issue.action';
import { buildPolicyIssueRequest } from './policy-issue.selector';
import { LogService } from '@core/services/log.service';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';

@Injectable()
export class PolicyIssueEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private policyIssueService: PolicyIssueService,
    private errorHelper: ErrorSanitizerService,
    private logService: LogService
  ) {}

  readonly ISSUE_POLICY_DEBOUNCE = 500;

  issuePolicyForProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(issuePolicyForProduct),
      switchMap((action) =>
        this.store.select(buildPolicyIssueRequest(action.product)).pipe(take(1))
      ),
      filterOutNull(),
      concatMap((request) => {
        return this.policyIssueService.policies(request).pipe(
          map((response) => issuePolicySuccess({ response })),
          catchError((error) =>
            of(
              issuePolicyFail({
                product: request.productType as ProductType,
                error: this.errorHelper.sanitizeError(error, request.productType),
              })
            )
          )
        );
      })
    )
  );

  logIssuePolicyFailuresSpecial$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(issuePolicyFail),
        tap((action) => {
          const { type, ...body } = action;
          this.logService.logBusinessEvent('issue-failed', body);
        })
      ),
    { dispatch: false }
  );
}
