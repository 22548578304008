import { ObjectValidator } from '@core/helper/object-validator';

export type ProducerSearchResultType = 'AMF' | 'PDS' | 'MIXED';

export interface ProducerSearch {
  type: ProducerSearchResultType;
  warning?: string;
  producers: ProducerResponse[];
}

export interface ProducerResponse {
  producerCode: string;
  agencyCode: string;
  agentName?: string;
  agentState?: string;
}

export interface JITProducerResponse {
  data: {
    producerList: JITProducer[];
  };
}

export interface JITProducer {
  firstName?: string;
  lastName?: string;
  producerCode?: string;
}

export interface AgencyCodeSearchResponse {
  agencies: AgencyCodeSearchAgency[];
}

export interface AgencyCodeSearchAgency {
  agencyCode: string;
  agencyName?: string;
  agencyState?: string;
}

export interface ProducerLicenseDetailsResponse {
  data: {
    licenses: any[];
    npn: string;
  };
}

export function sanitizeJITProducerResponse(
  input: unknown
): JITProducerResponse {
  return ObjectValidator.forceSchema<JITProducerResponse>(input, {
    data: {
      producerList: sanitizeJITProducers,
    },
  });
}

export function sanitizeJITProducers(input: unknown): JITProducer[] {
  return ObjectValidator.sanitizeArray(input, sanitizeJITProducer);
}

export function sanitizeJITProducer(input: unknown): JITProducer {
  return ObjectValidator.forceSchema<JITProducer>(
    input,
    {
      firstName: 'string',
      lastName: 'string',
      producerCode: 'string',
    },
    ['firstName', 'lastName', 'producerCode']
  );
}

export function sanitizeAgencyCodeSearchResponse(
  input: unknown
): AgencyCodeSearchResponse {
  return ObjectValidator.forceSchema<AgencyCodeSearchResponse>(input, {
    agencies: sanitizeAgencyCodeSearchAgencies,
  });
}

export function sanitizeAgencyCodeSearchAgencies(
  input: unknown
): AgencyCodeSearchAgency[] {
  return ObjectValidator.sanitizeArray(input, sanitizeAgencyCodeSearchAgency);
}

export function sanitizeAgencyCodeSearchAgency(
  input: unknown
): AgencyCodeSearchAgency {
  return ObjectValidator.forceSchema<AgencyCodeSearchAgency>(
    input,
    {
      agencyCode: 'string',
      agencyName: 'string',
      agencyState: 'string',
    },
    ['agencyName', 'agencyState']
  );
}

export function sanitizeProducerLicenseDetailsResponse(
  input: unknown
): ProducerLicenseDetailsResponse {
  return ObjectValidator.forceSchema<ProducerLicenseDetailsResponse>(input, {
    data: {
      licenses: 'any',
      npn: 'string',
    },
  });
}
