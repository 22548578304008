import { createAction, props } from '@ngrx/store';
import * as models from '@core/models/api/vehicle-inquiry.model';
import { Picklist, PicklistItem } from '@shared/models/picklist.model';
import { InquiryPath } from '@core/adapters/powersports.adapter';

export const clearVehicleInquiry = createAction('[Vehicle Inquiry] Clear');

export const retrieveAndStoreYears = createAction(
  '[Vehicle Inquiry] Retrieve And Store Years',
  props<{ inquiryPath: InquiryPath; correlationId: string }>()
);

export const setVehicleInquiryYears = createAction(
  '[Vehicle Inquiry] Set Years',
  props<{
    response: PicklistItem[];
    inquiryPath: InquiryPath;
    correlationId?: string;
  }>()
);

export const setVehicleInquiryYearsError = createAction(
  '[Vehicle Inquiry] Set Years Error',
  props<{ inquiryPath: InquiryPath; correlationId?: string }>()
);

export const setPlaceholderVehicleInquiryYears = createAction(
  '[Vehicle Inquiry] Set Placeholder Years',
  props<{ inquiryPath: InquiryPath }>()
);

export const dispatchSetVehicleInquiryMakes = createAction(
  '[Vehicle Inquiry] Dispatch Set Makes',
  props<{ year: number; inquiryPath: InquiryPath; correlationId: string }>()
);

export const setPlaceholderVehicleInquiryMakes = createAction(
  '[Vehicle Inquiry] Set Placeholder Makes',
  props<{ inquiryPath: InquiryPath; year: number }>()
);

export const setVehicleInquiryMakes = createAction(
  '[Vehicle Inquiry] Set Makes',
  props<{
    year: number;
    response: PicklistItem[];
    inquiryPath: InquiryPath;
    correlationId?: string;
  }>()
);

export const setVehicleInquiryMakesError = createAction(
  '[Vehicle Inquiry] Set Vehicle Inquiry Makes Error',
  props<{ inquiryPath: InquiryPath; correlationId?: string }>()
);

export const removeVehicleInquiryMakes = createAction(
  '[Vehicle Inquiry] Remove Makes',
  props<{ year: number; inquiryPath: InquiryPath }>()
);

export const dispatchSetVehicleInquiryModels = createAction(
  '[Vehicle Inquiry] Dispatch Set Vehicle Inquiry Models',
  props<{ year: number; make: string; correlationId: string }>()
);

export const setPlaceholderVehicleInquiryModels = createAction(
  '[Vehicle Inquiry] Set Placeholder Models',
  props<{ year: number; make: string; inquiryPath: InquiryPath }>()
);

export const setVehicleInquiryModels = createAction(
  '[Vehicle Inquiry] Set Models',
  props<{
    year: number;
    make: string;
    response: PicklistItem[];
    inquiryPath: InquiryPath;
    correlationId?: string;
  }>()
);

export const setVehicleInquiryModelsError = createAction(
  '[Vehicle Inquiry] Set Vehicle Inquiry Models Error',
  props<{ inquiryPath: InquiryPath; correlationId: string }>()
);

export const removeVehicleInquiryModels = createAction(
  '[Vehicle Inquiry] Remove Models',
  props<{ year: number; make: string; inquiryPath: InquiryPath }>()
);

export const setPlaceholderVehicleInquirySeries = createAction(
  '[Vehicle Inquiry] Set Placeholder Series',
  props<{
    year: number;
    make: string;
    model: string;
    inquiryPath: InquiryPath
  }>()
);

export const setVehicleInquirySeries = createAction(
  '[Vehicle Inquiry] Set Series',
  props<{
    year: number;
    make: string;
    model: string;
    response: PicklistItem[];
    inquiryPath: InquiryPath;
    correlationId?: string;
  }>()
);

export const setVehicleInquirySerieses = createAction(
  '[Vehicle Inquiry] Set Serieses',
  props<{
    serieses: Picklist[];
    response:
      | models.RetrieveVehicleModelSeriesResponse
      | null;
    inquiryPath: InquiryPath;
    correlationId?: string;
  }>()
);

export const setVehicleInquirySeriesError = createAction(
  '[Vehicle Inquiry] Set Vehicle Inquiry Series ERror',
  props<{ correlationId: string }>()
);

export const removeVehicleInquirySeries = createAction(
  '[Vehicle Inquiry] Remove Series',
  props<{ year: number; make: string; model: string; inquiryPath: InquiryPath }>()
);

export const setVehicleInquiryVin = createAction(
  '[Vehicle Inquiry] Set Vin',
  props<{
    vin: string;
    response:
      | models.Retrieve10DigitVehicleIdentificationNumberResponse
      | models.Retrieve17DigitVehicleIdentificationNumberOdbiResponse
      | models.Retrieve17DigitVehicleIdentificationNumberCCResponse
      | null;
    inquiryPath: InquiryPath;
  }>()
);

export const removeVehicleInquiryVin = createAction(
  '[Vehicle Inquiry] Remove Vin',
  props<{
    vin: string;
    inquiryPath: InquiryPath;
  }>()
);

export const dispatchSetVehicleInquiryRates = createAction(
  '[Vehicle Inquiry] Dispatch Set Vehicle Inquiry Rates',
  props<{
    vin: string;
    year: number;
    make: string;
    model: string;
    inquiryPath: InquiryPath;
    correlationId: string;
  }>()
);

export const setVehicleInquiryRates = createAction(
  '[Vehicle Inquiry] Set Rates',
  props<{
    year: number;
    make: string;
    model: string;
    response:
      | models.RetrieveVehicleSeriesRatesOdbiResponse
      | models.RetrieveVehicleSeriesRatesCCResponse
      | null;
    inquiryPath: InquiryPath;
    correlationId?: string;
  }>()
);

export const setVehicleInquiryRatesError = createAction(
  '[Vehicle Inquiry] Set Vehicle Inquiry Rates Error',
  props<{ correlationId: string }>()
);

export const removeVehicleInquiryRates = createAction(
  '[Vehicle Inquiry] Remove Rates',
  props<{
    year: number;
    make: string;
    model: string;
    inquiryPath: InquiryPath;
  }>()
);
