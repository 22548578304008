import { ProductType } from '@core/models/api/dsm-types';
import { getCoreState } from '@core/store/reducers';
import { RiskItem } from '@entities/covered-location/covered-location.model';
import { CoveredLocationEntity } from '@entities/covered-location/covered-location.reducer';
import { createSelector } from '@ngrx/store';
import { EligibilityFormModel, EligibilityPrequalificationAnswers, EligibilityRiskItems, RISK_ITEM_KEYS_IN_MAIN_COVERED_LOCATION_OBJECT } from './eligibility-form.model';
import { adapter } from './eligibility-form.reducer';

export const getEligibilityFormState = createSelector(
  getCoreState,
  (state) => state.eligibilityForm
);

export const {
  selectAll: getAllEligibilityForms,
  selectEntities: getEligibilityFormEntities,
} = adapter.getSelectors(getEligibilityFormState);

export const getEligibilityFormByQuoteId = (quoteId: string) => createSelector(
  getEligibilityFormEntities,
  (entities) => entities[quoteId]
);

export const getEligibilityFormsByProductType = (productType: ProductType) => createSelector(
  getAllEligibilityForms,
  (entities) => entities.filter(e => e.productType === productType)
);

// If it ever becomes possible to have multiple quotes of the same product, this will need to be removed.
export const getAnyEligibilityFormByProductType = (productType: ProductType) => createSelector(
  getEligibilityFormsByProductType(productType),
  (forms): EligibilityFormModel | undefined => forms[0]
);

/* This ought to take quoteId, but that would be inconvenient for quote selectors, which only have productType initially.
 */
export const buildQuotePrequalificationAnswersFromEligibilityForm = (productType: ProductType) => createSelector(
  getAnyEligibilityFormByProductType(productType),
  (form) => {
    if (!form?.prequalificationAnswers) {
      return null;
    }
    return (Object.keys(form.prequalificationAnswers) as (keyof EligibilityPrequalificationAnswers)[]).map(k => ({
      questionCode: k,
      answerValue: form.prequalificationAnswers![k] === 'true',
    }));
  }
);

/* This ought to take quoteId, but that would be inconvenient for covered-location selectors, which only have productType initially.
 */
export interface PartialCoveredLocationBits {
  coveredLocation?: Partial<CoveredLocationEntity>;
  riskItems?: Partial<RiskItem>;
}
export const buildPartialCoveredLocationBitsFromEligibilityForm = (productType: ProductType) => createSelector(
  getAnyEligibilityFormByProductType(productType),
  (form): PartialCoveredLocationBits => {
    const output: PartialCoveredLocationBits = {};
    if (form?.riskItems) {
      for (const key of Object.keys(form.riskItems) as (keyof EligibilityRiskItems)[]) {
        if (RISK_ITEM_KEYS_IN_MAIN_COVERED_LOCATION_OBJECT.includes(key)) {
          if (!output.coveredLocation) output.coveredLocation = {};
          output.coveredLocation[key as keyof CoveredLocationEntity] = formatValueForRequest(key, form.riskItems[key] as string);
        } else {
          if (!output.riskItems) output.riskItems = {};
          if (key === 'dogs') {
            if (form.riskItems.haveDogs === 'true') {
              output.riskItems.dogs = form.riskItems.dogs?.map(d => ({
                dogBreed: d.dogBreed,
                biteHistory: d.biteHistory === 'true',
                canineGoodCitizen: d.canineGoodCitizen === 'true',//TODO this field doesn't exist in dsm
              })) || [];
            }
          } else {
            output.riskItems[key as keyof RiskItem] = formatValueForRequest(key, form.riskItems[key] as string);
          }
        }
      }
    }

    // If haveDogs, then dogs must be present. It's allowed to be empty.
    if (output.riskItems?.haveDogs && !output.riskItems?.dogs) {
      output.riskItems.dogs = [];
    }

    return removeUnnecessaryConditionalCoveredLocationBits(output);
  }
);

function formatValueForRequest(key: string, value: string): any {
  switch (key) {
  // boolean
    case 'registeredHistoric': return (value === 'true');
    case 'nonResidentialToResidential': return (value === 'true');
    case 'deededOwner': return (value === 'true');
    case 'isShortSaleAuctForeclose': return (value === 'true');
    case 'trampoline': return (value === 'true');
    case 'trampolineFence': return (value === 'true');
    case 'trampolineTieDowns': return (value === 'true');
    case 'trampolineNet': return (value === 'true');
    case 'dangerousOrExoticAnimal': return (value === 'true');
    case 'animalThatCausedInjury': return (value === 'true');
    case 'haveDogs': return (value === 'true');
    case 'biteHistory': return (value === 'true');
    case 'incidentalFarm': return (value === 'true');
    case 'currentConstruction': return (value === 'true');
    case 'currentRemodelingOrRehabiliation': return (value === 'true');
    case 'swimmingPool': return (value === 'true');
    case 'swimmingLessonsProvided': return (value === 'true');
    case 'poolLadder': return (value === 'true');
    case 'removableLadder': return (value === 'true');
    case 'divingBoard': return (value === 'true');
    case 'poolSlide': return (value === 'true');
    case 'poolFence': return (value === 'true');
    case 'selfLatchingGate': return (value === 'true');

  // string
    case 'distanceToNonOwnedBuildingTrampoline': return value;
    case 'dogBreed': return value;
    case 'typeOfSwimmingPool': return value;
    case 'distanceToNonOwnedBuildingPool': return value;

  // number
    case 'waterDepthUnderDivingBoard': return +value || 0;
    case 'waterDepthUnderSlide': return +value || 0;
  }
  return value;
}

/** WARNING: Modifies input in place.
 */
function removeUnnecessaryConditionalCoveredLocationBits(
  input: PartialCoveredLocationBits
): PartialCoveredLocationBits {

  if (input.riskItems) {
    if (input.riskItems.trampoline) {
    } else {
      delete input.riskItems.trampolineFence;
      delete input.riskItems.trampolineTieDowns;
      delete input.riskItems.trampolineNet;
      delete input.riskItems.distanceToNonOwnedBuildingTrampoline;
    }

    if (input.riskItems.swimmingPool) {
      if (!input.riskItems.poolLadder) {
        delete input.riskItems.removableLadder;
      }
      if (!input.riskItems.divingBoard) {
        delete input.riskItems.waterDepthUnderDivingBoard;
      }
      if (!input.riskItems.poolSlide) {
        delete input.riskItems.waterDepthUnderSlide;
      }
      if (!input.riskItems.poolFence) {
        delete input.riskItems.selfLatchingGate;
      }
    } else {
      delete input.riskItems.swimmingLessonsProvided;
      delete input.riskItems.poolLadder;
      delete input.riskItems.removableLadder;
      delete input.riskItems.divingBoard;
      delete input.riskItems.poolSlide;
      delete input.riskItems.poolFence;
      delete input.riskItems.selfLatchingGate;
      delete input.riskItems.typeOfSwimmingPool;
      delete input.riskItems.distanceToNonOwnedBuildingPool;
      delete input.riskItems.waterDepthUnderDivingBoard;
      delete input.riskItems.waterDepthUnderSlide;
    }
  }

  return input;
}
