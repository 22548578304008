import { Injectable } from '@angular/core';
import { updateAccountRegistrationInfoForm } from '@forms-store/store/models/account-registration/account-registration.action';
import { AccountRegistrationInfoFormModel } from '@forms-store/store/models/account-registration/account-registration.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AccountRegistrationFormSelectors } from '@forms-store/store/selectors/index';
@Injectable({ providedIn: 'root' })
export class AccountRegistrationFormService {
  constructor(private store: Store) {}

  updateAccountRegistrationInfoForm(
    model: AccountRegistrationInfoFormModel
  ): void {
    this.store.dispatch(updateAccountRegistrationInfoForm({ model }));
  }

  hasUpdatedRegistrationForm(): Observable<boolean> {
    return this.store.select(
      AccountRegistrationFormSelectors.hasUpdatedRegistrationForm
    );
  }
}
