import { createReducer, on } from '@ngrx/store';
import { QuoteActions, SessionActions } from '../../actions';
import { patchProductQuote } from '../product/product.actions';

export interface QuoteState {
  updateInProgress: number;
  quoteInProgress: number;
}

export const initialState: QuoteState = {
  updateInProgress: 0,
  quoteInProgress: 0,
};

export const reducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(QuoteActions.updateQuote, patchProductQuote, (state) => {
    return { ...state, updateInProgress: state.updateInProgress + 1 };
  }),
  on(QuoteActions.updateQuoteSuccess, QuoteActions.updateQuoteFail, (state) => {
    return { ...state, updateInProgress: state.updateInProgress - 1 };
  }),
  on(QuoteActions.rateQuote, (state) => {
    return { ...state, quoteInProgress: state.quoteInProgress + 1 };
  }),
  // NOT createRatedUmbrellaQuoteSuccess: That goes with rateQuoteSuccess/rateBindSuccess.
  on(QuoteActions.rateQuoteSuccess, (state) => {
    return { ...state, quoteInProgress: state.quoteInProgress - 1 };
  }),
  on(QuoteActions.rateBindSuccess, (state) => {
    return { ...state, quoteInProgress: state.quoteInProgress - 1 };
  }),
  on(QuoteActions.rateQuoteFail, (state) => {
    return { ...state, quoteInProgress: state.quoteInProgress - 1 };
  })
);
