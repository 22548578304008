<ng-container *ngIf="{ showNavBar: shouldShowNavBar$ | async } as obvs">
  <header>
    <div
      [class.blue-angled-bg]="obvs.showNavBar"
      [class.bg-dark-blue]="!obvs.showNavBar"
    >
      <div
        class="app-header bolt-container-fluid wrapper-container"
        [class.page-wrapper-lg]="obvs.showNavBar"
        [ngClass]="{ bigger: obvs.showNavBar, solid: !obvs.showNavBar }"
      >
        <div
          class="header"
          [ngClass]="{
            centered: obvs.showNavBar,
            stretched: !obvs.showNavBar
          }"
        >
          <div
            class="bolt-row bolt-justify-content-between bolt-align-items-center bolt-space-bottom-md"
          >
            <div class="bolt-col-4 logo-container">
              <h3>
                <a [routerLink]="'/'"
                  >Nationwide&nbsp;<span class="post-header">Express&reg;</span>
                </a>
              </h3>
              <div class="badge-container" *ngIf="!(maxSizeXSmall$ | async)">
                <img
                  *ngIf="!obvs.showNavBar"
                  src="/assets/images/icons/nwx-agent-version-2-badge.svg"
                  alt="Version 2"
                />
                <img
                  *ngIf="obvs.showNavBar"
                  src="/assets/images/icons/nwx-agent-version-2-transparent-badge.svg"
                  alt="Version 2"
                />
              </div>
            </div>
            <div class="bolt-col-auto">
              <ng-container *ngIf="shouldShowProfileComponent$ | async">
                <nwx-profile-menu
                  [userName]="(this.userName$ | async) || ''"
                  [agentCenterUrl]="agentCenterUrl"
                  [producerCode]="(this.producerCode$ | async) || ''"
                  [producerName]="(this.agentName$ | async) || ''"
                  [maxSizeXSmall]="maxSizeXSmall$ | async"
                ></nwx-profile-menu>
              </ng-container>
            </div>
          </div>
          <div
            *ngIf="
              ((shouldShowWelcomeMessage$ | async) || false) && agentFirstName
            "
            class="bolt-row"
          >
            <div class="bolt-col-12 welcome-message-content">
              <h1 class="bolt-heading-lg bolt-serif">Personal lines quoting</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</ng-container>

<nwx-task-bar nwxClassWhenOffscreen="stuck-shadow"></nwx-task-bar>
