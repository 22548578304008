import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ModifierActions, SessionActions } from '@core/store/actions';

import { ModifierEntity } from '@core/store/entities/modifier/modifier.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';

export interface ModifierState extends EntityState<ModifierEntity> {
  error: ErrorModel;
}

export const adapter: EntityAdapter<ModifierEntity> =
  createEntityAdapter<ModifierEntity>({
    selectId: (modifier) => modifier.modifierId,
  });

const modifierReducer = createReducer(
  adapter.getInitialState({
    error: {},
  }),
  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({ error: {} })
  ),
  on(ModifierActions.addModifierSuccess, (state, { payload }) =>
    adapter.addOne(payload, { ...state })
  ),
  on(ModifierActions.addModifierError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(ModifierActions.updateModifierSuccess, (state, { payload }) =>
    adapter.updateOne(payload, { ...state })
  ),
  on(ModifierActions.updateModifierError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(ModifierActions.deleteModifierSuccess, (state, { payload }) =>
    adapter.removeOne(payload, { ...state })
  ),
  on(ModifierActions.deleteModifierError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  })
);

export function reducer(
  state: ModifierState | undefined,
  action: Action
): ModifierState {
  return modifierReducer(state, action);
}
