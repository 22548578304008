export class PageUtils {
  static getExpectedActions(
    success: string,
    error: string,
    correlationId: string
  ): {
    success: {
      type: string;
      correlationId: string;
    }[];
    error: {
      type: string;
      correlationId: string;
    }[];
  } {
    return {
      success: [
        {
          type: success,
          correlationId,
        },
      ],
      error: [
        {
          type: error,
          correlationId,
        },
      ],
    };
  }
}
