import { Injectable } from '@angular/core';
import { Nullable } from '@shared/utils/type.utils';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  DiscountEntity,
  GroupedDiscounts,
} from '../store/entities/discount/discount.entity';
import { getEasyPayDiscountAmount } from '../store/entities/vehicle/vehicle.selector';

import { DiscountSelectors } from '../store/selectors';
@Injectable({
  providedIn: 'root',
})
export class DiscountsService {
  constructor(private store: Store) {}

  getGroupedSelectedDiscounts(): Observable<GroupedDiscounts[][]> {
    return this.store.select(DiscountSelectors.getGroupedSelectedDiscounts);
  }

  getAllDiscounts(): Observable<DiscountEntity[]> {
    return this.store.select(DiscountSelectors.getAllDiscounts);
  }

  getAllNonDuplicateDiscounts(): Observable<DiscountEntity[]> {
    return this.store.select(DiscountSelectors.getFilteredAppliedDiscounts);
  }

  getEasyPayDiscountAmount(): Observable<Nullable<number>> {
    return this.store.select(getEasyPayDiscountAmount);
  }

  getAppliedPaymentMethodDiscounts(): Observable<DiscountEntity[]> {
    return this.store.select(
      DiscountSelectors.getAppliedPaymentMethodDiscounts
    );
  }
}
