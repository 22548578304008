import { Injectable } from '@angular/core';
import {
  Params,
  ActivatedRouteSnapshot,
  ActivatedRoute,
  RouterStateSnapshot,
} from '@angular/router';
import { AppConfigService } from '@core/services/app-config.service';
import { HttpParams } from '@angular/common/http';
import { StringUtils } from '@shared/utils/string.utils';

interface EUAOptions {
  preferNwie: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class AuthUrlService {
  // need to disable this lint rule for auth parameter names
  /* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
  NONCE_LENGTH = 32;
  IA_AUTH_TYPE = 'ping-racf-ia';
  DEFAULT_AUTH_TYPE = 'ping';
  IA_IDENTITY_METHOD = 'ac';
  DEFAULT_IDENTITY_METHOD = 'nwie';
  IA_REALM = 'distribution-partner';
  EMPLOYEE_REALM = 'employee';
  TRAMPOLINE_URL = 'https://nwxv2-trampoline-test.apps.nwie.net/';

  constructor(
    private appConfig: AppConfigService,
    private window: Window,
    private route: ActivatedRoute
  ) {}

  get redirect_uri(): string {
    return this.appConfig.isKubeTestEnv
      ? this.TRAMPOLINE_URL
      : this.window.location.protocol + '//' + this.window.location.host + '/';
  }

  buildEuaUrlForAgencyRoute(
    params: Params,
    authType: 'internal' | 'external',
    opts: EUAOptions = { preferNwie: false }
  ): string {
    const EUA_URL = this.appConfig.config.euaUrl;
    const CLIENT_ID = this.appConfig.config.apiKey;
    const SCOPE = 'openid pci';
    const RESPONSE_TYPE = 'token';
    const message_id = StringUtils.generateUuid();
    const NONCE = this.nonce(this.NONCE_LENGTH);
    const auth_method = this.getAuthMethod(authType);
    const identity_method = this.getIdentityMethod(authType);
    const realm = this.getRealm(authType);

    const queryString = this.window.btoa(this.paramsToQueryString(params));

    const query = {
      client_id: CLIENT_ID,
      state: queryString,
      response_type: RESPONSE_TYPE,
      scope: SCOPE,
      nonce: NONCE,
      redirect_uri: this.redirect_uri,
      auth_method,
      realm,
      message_id,
      identity_method,
    } as { [key: string]: string };

    /**
     * auth_id_user_type is an indicator to EUA2 to give us back NWIE ID for agents that have them.
     * This is because "transitioned" EA's have both a RACF and a NWIE ID, but their RACF will not be configured in PC until 2021.
     * So we want to authenticate through Agent Center using NWIE or RACF, but then have the id_token that PolicyCenter uses use
     * the NWIE ID.
     */
    if (
      opts.preferNwie ||
      params.userType === 'Employee' ||
      params.userType === 'AGIMC'
    ) {
      query.auth_id_user_type = 'nwie';
    }

    const pairs = [];
    for (const key of Object.keys(query)) {
      pairs.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
      );
    }

    const qs = pairs.join('&');
    const euaUrl = EUA_URL + '?' + qs;
    return euaUrl;
  }

  performAuthRedirect(
    agencyChannel: string,
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot | null,
    opts: EUAOptions = { preferNwie: false }
  ): void {
    let authType: 'internal' | 'external';
    if (agencyChannel === 'NSS') {
      authType = 'internal';
    } else {
      authType = 'external';
    }
    const params = {
      agencyChannel,
      requestedRoute: routerState?.url || '/' + route.routeConfig?.path || '',
      ...route.params,
      ...route.queryParams,
    };
    const euaUrl = this.buildEuaUrlForAgencyRoute(params, authType, opts);
    // redirect for user auth
    this.window.location.href = euaUrl;
  }

  parseRoute(route: ActivatedRouteSnapshot): { [key: string]: string } {
    let fragment = route.fragment!;

    if (fragment) {
      fragment = this.recoverPreviousState(fragment);

      const queryParams = this.transformQueryParams(fragment);

      return {
        ...route.queryParams,
        ...queryParams,
      };
    } else {
      return { ...route.queryParams };
    }
  }

  getRoutePath(queryParams: string): string {
    const httpParams = new HttpParams({ fromString: queryParams });
    return httpParams.get('route') || '';
  }

  private nonce(length: number): string {
    let nonce = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      nonce += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return nonce;
  }

  private getAuthMethodByChannel(channel: string): string {
    if (channel === 'IA' || channel === 'EA') {
      return this.IA_AUTH_TYPE;
    } else {
      return this.DEFAULT_AUTH_TYPE;
    }
  }

  private getAuthMethod(authType: 'internal' | 'external'): string {
    if (authType === 'external') {
      return this.IA_AUTH_TYPE;
    } else {
      return this.DEFAULT_AUTH_TYPE;
    }
  }

  private getIdentityMethodByChannel(channel: string): string {
    if (channel === 'IA' || channel === 'EA') {
      return this.IA_IDENTITY_METHOD;
    } else {
      return this.DEFAULT_IDENTITY_METHOD;
    }
  }

  private getIdentityMethod(authType: 'internal' | 'external'): string {
    if (authType === 'external') {
      return this.IA_IDENTITY_METHOD;
    } else {
      return this.DEFAULT_IDENTITY_METHOD;
    }
  }

  private getRealmByChannel(channel: string): string {
    if (channel === 'IA' || channel === 'EA') {
      return this.IA_REALM;
    } else {
      return this.EMPLOYEE_REALM;
    }
  }

  private getRealm(authType: 'internal' | 'external'): string {
    if (authType === 'external') {
      return this.IA_REALM;
    } else {
      return this.EMPLOYEE_REALM;
    }
  }

  private paramsToQueryString(params: Params): string {
    const redirectUrl = params.requestedRoute || '/';
    let separator = redirectUrl.indexOf('?') >= 0 ? '&' : '?';
    let ret = Object.keys(params)
      .filter((key) => key !== 'requestedRoute')
      .reduce((acc, key) => {
        const result = `${acc}${separator}${encodeURIComponent(
          key
        )}=${encodeURIComponent(params[key])}`;
        separator = '&';
        return result;
      }, redirectUrl);

    if (this.appConfig.isKubeTestEnv) {
      ret = `${ret}&kubernetesEndpoint=${this.window.location.host}`;
    }
    ret = encodeURIComponent(ret);
    ret = 'requestedRoute=' + ret;
    return ret;
  }

  private recoverPreviousState(fragment: string): string {
    let stateIndex = fragment.indexOf('state=');
    if (stateIndex < 0) {
      return fragment;
    }
    stateIndex += 'state='.length;
    let termIndex = fragment.indexOf('&', stateIndex);
    if (termIndex < 0) {
      termIndex = fragment.length;
    }
    let previousState = fragment.substring(stateIndex, termIndex);
    previousState = encodeURIComponent(previousState);
    fragment = fragment.slice(0, stateIndex) + previousState;
    return fragment;
  }

  private transformQueryParams(queryParams: string): { [key: string]: string } {
    const httpParams = new HttpParams({ fromString: queryParams });
    const httpParamsObj: { [key: string]: string } = {};
    for (const k of httpParams.keys()) {
      httpParamsObj[k] = httpParams.get(k) || '';
    }
    const state = httpParamsObj.state;
    let previousState;
    if (state) {
      previousState = this.parseHashParams(decodeURIComponent(state));
    }
    let queryParamsJson: { [key: string]: string } = httpParamsObj;
    if (previousState) {
      queryParamsJson = {
        ...previousState,
        ...httpParamsObj,
        quoteState: previousState.state || previousState.quoteState,
        state: previousState.state || previousState.quoteState,
      } as { [key: string]: string };
    }
    return queryParamsJson;
  }

  private parseHashParams(rawParams: string): { [key: string]: string } {
    const res: { [key: string]: string } = {};

    rawParams.split('&').forEach((keyValuePair) => {
      const key = keyValuePair.substr(0, keyValuePair.indexOf('='));
      const value = keyValuePair.substr(keyValuePair.indexOf('=') + 1);
      res[key] = value;
    });

    return res;
  }
}
