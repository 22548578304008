import { retrieveQuoteSuccess } from '../../retrieve/retrieve.action';
import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  DiscountActions,
  RetrieveActions,
  SessionActions,
} from '@core/store/actions';
import { DiscountEntity } from '@core/store/entities/discount/discount.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';

export interface DiscountState extends EntityState<DiscountEntity> {
  error: ErrorModel;
}

const getId = (discount?: DiscountEntity) =>
  `${discount?.productType}_${discount?.description}_${discount?.coverableId}`;

export const adapter: EntityAdapter<DiscountEntity> =
  createEntityAdapter<DiscountEntity>({
    selectId: getId,
  });

const discountReducer = createReducer(
  adapter.getInitialState({
    error: {},
  }),
  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({ error: {} })
  ),
  on(DiscountActions.addDiscountSuccess, (state, { payload }) =>
    adapter.addOne(payload, { ...state })
  ),
  on(DiscountActions.upsertAllDiscountsSuccess, (state, { payload }) => {
    return adapter.upsertMany(payload, { ...state });
  }),
  on(
    DiscountActions.replaceAllDiscountsOfProduct,
    (state, { payload, productType }) => {
      const entityIdsTobeRemoved = Object.values(state.entities)
        .filter(
          (entity?: DiscountEntity) => entity?.productType === productType
        )
        .map(getId);

      const state1 = adapter.removeMany(entityIdsTobeRemoved, { ...state });

      return adapter.upsertMany(payload, { ...state1 });
    }
  ),
  on(DiscountActions.removeDiscountsOfType, (state, { discountType }) => {
    const entityIdsToRemove = (state.ids as string[]).filter((id: string) =>
      String(id).includes(discountType)
    );
    return adapter.removeMany(entityIdsToRemove, { ...state });
  }),
  on(DiscountActions.addDiscountError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(DiscountActions.updateDiscountSuccess, (state, { payload }) =>
    adapter.updateOne(payload, { ...state })
  ),
  on(DiscountActions.updateDiscountError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(DiscountActions.deleteDiscountSuccess, (state, { payload }) =>
    adapter.removeOne(payload, { ...state })
  ),
  on(DiscountActions.deleteDiscountError, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(retrieveQuoteSuccess, (state, { payload }) => {
    const productType = payload.productType;
    const discounts = payload.response.discounts?.map((d) => ({
      ...d,
      productType,
    }));
    if (discounts) {
      return adapter.addMany(discounts, { ...state });
    } else {
      return {
        ...state,
      };
    }
  })
);

export function reducer(
  state: DiscountState | undefined,
  action: Action
): DiscountState {
  return discountReducer(state, action);
}
