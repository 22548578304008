import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { JsonMetadataAdapter } from '@core/adapters/json-metadata.adapter';
import * as fromActions from './metadata.action';
import * as featureFlagActions from '@core/store/entities/feature-flag/feature-flag.action';
import {
  initiateNewBusinessSuccess,
  updateQuoteSuccess,
} from '../quote/quote.action';

@Injectable()
export class MetadataEffects {
  constructor(
    private actions$: Actions,
    private metadataAdapter: JsonMetadataAdapter
  ) {}

  loadStateSpecificFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.loadStateSpecificFlags,
        initiateNewBusinessSuccess,
        updateQuoteSuccess
      ),
      switchMap(() =>
        this.metadataAdapter.getStateSpecificFlags().pipe(
          switchMap((response) => [
            // featureFlagActions.setAllFeaureFlags(),
            fromActions.loadStateSpecificFlagsSuccess({ response }),
          ]),
          catchError((error) =>
            of(fromActions.loadStateSpecificFlagsFail({ error }))
          )
        )
      )
    )
  );

  loadStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadStates),
      switchMap(() =>
        this.metadataAdapter.getStates().pipe(
          switchMap((response) => [
            fromActions.loadStatesSuccess({ response }),
          ]),
          catchError((error) => of(fromActions.loadStatesFail({ error })))
        )
      )
    )
  );

  loadPropertyOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPropertyOptions),
      switchMap(() =>
        this.metadataAdapter.getPropertyOptions().pipe(
          map((response) =>
            fromActions.loadPropertyOptionsSuccess({ response })
          ),
          catchError((error) =>
            of(fromActions.loadPropertyOptionsFail({ error }))
          )
        )
      )
    )
  );

  loadUmbrellaOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadUmbrellaOptions),
      switchMap(() =>
        this.metadataAdapter.getUmbrellaOptions().pipe(
          map((response) =>
            fromActions.loadUmbrellaOptionsSuccess({ response })
          ),
          catchError((error) =>
            of(fromActions.loadUmbrellaOptionsFail({ error }))
          )
        )
      )
    )
  );
}
