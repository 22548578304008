import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, take, map, filter, tap } from 'rxjs/operators';
import { updateCoveredLocationSuccess } from '../../covered-location/covered-location.action';
import { TaskService } from '@core/services/task.service';
import { updateCoveredLocationTasks } from '../selectors/covered-location-task.selector';
import { TaskActions } from '@core/store/actions';

@Injectable({
  providedIn: 'root',
})
export class CoveredLocationTaskEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private taskService: TaskService
  ) {}

  updateHomeownerTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCoveredLocationSuccess, TaskActions.updateTasks),
      filter((action) =>
        action.type === TaskActions.updateTasks.type
          ? true
          : action.payload.productType === 'Homeowner'
      ),
      switchMap(() =>
        this.store.select(updateCoveredLocationTasks('Homeowner')).pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updateCondoTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCoveredLocationSuccess, TaskActions.updateTasks),
      filter((action) =>
        action.type === TaskActions.updateTasks.type
          ? true
          : action.payload.productType === 'Condominium'
      ),
      switchMap(() =>
        this.store
          .select(updateCoveredLocationTasks('Condominium'))
          .pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );

  updateTenantTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCoveredLocationSuccess, TaskActions.updateTasks),
      filter((action) =>
        action.type === TaskActions.updateTasks.type
          ? true
          : action.payload.productType === 'Tenant'
      ),
      switchMap(() =>
        this.store.select(updateCoveredLocationTasks('Tenant')).pipe(take(1))
      ),
      map((tasks) => this.taskService.createUpdateActions(tasks)),
      switchMap((actions) => [...actions])
    )
  );
}
