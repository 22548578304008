<div class="bolt-container-fluid">
  <div class="bolt-row bolt-align-items-center">
    <div class="bolt-col-12">
      <p *ngIf="!isCA; else CAValueProp">
        SmartRide&reg; <strong>rewards safe driving.</strong> Save
        {{ initialDiscount }} for enrolling.<br />Then, the safer the customer
        drives, the higher the discount they can earn –
        <strong>up to {{ maximumDiscount }}.</strong>
      </p>
      <ng-template #CAValueProp>
        <p>
          Smartride&reg; <strong>rewards low-mileage drivers</strong>. Save 15%
          just for enrolling, then, the fewer miles the customer drives, the
          higher the discount they can earn - <strong>up to 15%</strong>
        </p>
      </ng-template>
    </div>
    <div *ngIf="!isMobile" class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4">
      <div class="image-section">
        <div class="image-circle">
          <img
            class="tm-icon"
            src="assets/images/telematics/smartmiles-device-teal.svg"
            alt=""
          />
        </div>
        <p class="info">
          Plug a device into the vehicle.
          <bolt-contextual-help>
            <p>
              <strong>How does this work?</strong><br />
              <ng-container *ngIf="!isCA; else CAHelpContent1">
                We send the customer a device that plugs into a port below the
                vehicle's dashboard. The device measures mileage, hard
                braking/fast acceleration, idle time and nighttime driving.
              </ng-container>
              <ng-template #CAHelpContent1>
                We send the customer a device that plugs into a port below the
                vehicle's dashboard. The device measures mileage.
              </ng-template>
            </p>
          </bolt-contextual-help>
        </p>
      </div>
    </div>
    <div *ngIf="!isMobile" class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4">
      <div class="image-section">
        <div class="image-circle">
          <img
            class="tm-icon"
            src="assets/images/icons/calendar_blue.svg"
            alt=""
          />
        </div>
        <p class="info">
          Earn the final discount in about 6 months.
          <bolt-contextual-help>
            <p>
              <strong>How does this work?</strong><br />
              <ng-container *ngIf="!isCA; else CAHelpContent2">
                The device will measure mileage, hard braking/fast acceleration,
                idle time and nighttime driving. Once enough driving data is
                collected (about 4 - 6 months), the final discount is calculated
                based on how safely the customer drives - up to 40%. The
                discount is applied at policy renewal.
              </ng-container>
              <ng-template #CAHelpContent2>
                The device will measure mileage. Once enough driving data is
                collected ( about 4 - 6 months), the final discount is
                calculated based on how many miles the customer drives - up to
                15%. The discount is applied at policy renewal.
              </ng-template>
            </p>
          </bolt-contextual-help>
        </p>
      </div>
    </div>
    <div
      *ngIf="!isMobile && !isIncreasedDiscount"
      class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4 video"
    >
      <iframe
        id="kaltura_player_srd"
        src="https://cdnapisec.kaltura.com/p/2256561/sp/225656100/embedIframeJs/uiconf_id/38502422/partner_id/2256561?iframeembed=true&playerId=kaltura_player_srd&entry_id=1_rbjkxll9&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=1_onqa8oqw"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        frameborder="0"
        title="Kaltura Player"
      ></iframe>
    </div>
    <div
      *ngIf="!isMobile && isIncreasedDiscount"
      class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4 video"
    >
      <iframe
        id="kaltura_player_srd_inc"
        src="https://fast.wistia.net/embed/iframe/bo1d4q2qh2"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        frameborder="0"
        title="Kaltura Player"
      ></iframe>
    </div>
    <div *ngIf="isMobile" class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4">
      <div class="image-section">
        <div class="image-circle">
          <img
            class="tm-icon"
            src="assets/images/telematics/phone.svg"
            alt=""
          />
        </div>
        <ng-container *ngIf="!isCA; else CAHelpContent3">
          <p *ngIf="isSmartRideEarlyReward" class="info">
            The SmartRide&reg; mobile app measures driving behavior.
          </p>
        </ng-container>
        <ng-template #CAHelpContent3>
          <p *ngIf="isSmartRideEarlyReward" class="info">
            Install a mobile app that measures driving behavior.
          </p>
        </ng-template>
        <p *ngIf="!isSmartRideEarlyReward" class="info">
          Download and activate a mobile app that measures driving behavior.
        </p>
      </div>
    </div>
    <div *ngIf="isMobile" class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4">
      <div class="image-section">
        <div class="image-circle">
          <img
            class="tm-icon"
            src="assets/images/icons/calendar_blue.svg"
            alt=""
          />
        </div>
        <p *ngIf="isSmartRideEarlyReward" class="info">
          Most drivers can earn a discount in 80 days.
        </p>
        <p *ngIf="!isSmartRideEarlyReward" class="info">
          In about 6 months, we calculate the final discount.
        </p>
      </div>
    </div>
    <div
      *ngIf="isMobile && isIncreasedDiscount"
      class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4 video"
    >
      <iframe
        id="kaltura_player_srm_inc"
        src="https://fast.wistia.net/embed/iframe/8bdf3qrmik"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        frameborder="0"
        title="Kaltura Player"
      ></iframe>
    </div>
    <div
      *ngIf="isMobile && !isIncreasedDiscount"
      class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4 video"
    >
      <iframe
        id="kaltura_player_srm"
        src="https://fast.wistia.net/embed/iframe/m9alb1a91v"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        frameborder="0"
        title="Kaltura Player"
      ></iframe>
    </div>
    <div
      *ngIf="isMobile && isSmartRideEarlyReward"
      class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-6"
    >
      <div class="image-section">
        <div class="image-circle">
          <img
            class="tm-icon"
            src="assets/images/telematics/percent-up-icon.svg"
            alt=""
          />
        </div>
        <p class="info">
          Opportunity to improve the discount at every renewal - up to 40% -
          based on driving behavior.
        </p>
      </div>
    </div>
  </div>
  <div *ngFor="let vehicle of vehicles; let i = index">
    <nwx-telematics-vehicle-premium
      [vehicle]="vehicle"
      [vehicleIndex]="i"
      [currentProgram]="'SmartRide'"
      [isMobile]="isMobile"
      [enrollment]="enrollment"
      [maxSizeMini]="maxSizeMini"
    ></nwx-telematics-vehicle-premium>
  </div>
</div>
