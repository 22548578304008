import { Injectable } from '@angular/core';
import {
  PowersportsAdapter,
  InquiryPath,
} from '@core/adapters/powersports.adapter';
import { PowersportsVehicleVinDetailsResponse } from '@core/models/api/vehicle-inquiry.model';
import {
  BoatModel,
  BoatTrailerModel,
  RVModel,
} from '@core/models/views/vehicle.model';
import { VehicleInquiryActions } from '@core/store/actions';
import { VehicleInquirySelectors } from '@core/store/selectors';
import { Store } from '@ngrx/store';
import { PicklistItem } from '@shared/models/picklist.model';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, OperatorFunction } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PowersportsService {
  constructor(
    private powersportsAdapter: PowersportsAdapter,
    private store: Store
  ) {}

  getPowersportsVehicleYears(
    inquiryPath: InquiryPath
  ): Observable<PicklistItem[]> {
    return this.getVehicleInquiryYears(inquiryPath).pipe(
      filter((years) => {
        if (!years) {
          this.store.dispatch(
            VehicleInquiryActions.setPlaceholderVehicleInquiryYears({
              inquiryPath,
            })
          );
          this.dispatchGetYears(inquiryPath);
          return false;
        } else {
          return true;
        }
      }) as OperatorFunction<Nullable<PicklistItem[]>, PicklistItem[]>
    );
  }
  dispatchGetYears(inquiryPath: InquiryPath): void {
    this.powersportsAdapter
      .getPowersportsVehicleYears(inquiryPath)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          const formattedResponse: PicklistItem[] = response.years.map((y) => {
            return {
              displayName: y.year,
              value: y.year,
            };
          });
          this.store.dispatch(
            VehicleInquiryActions.setVehicleInquiryYears({
              response: formattedResponse,
              inquiryPath,
            })
          );
        },
      });
  }

  getPowersportsVehicleMakesByYear(
    modelYear: number,
    inquiryPath: InquiryPath
  ): Observable<PicklistItem[]> {
    let failureCount = 0;
    return this.getVehicleInquiryMakes(modelYear, inquiryPath).pipe(
      filter((response) => {
        if (response === undefined) {
          if (failureCount++ > 0) {
            throw new Error('VehicleInquiry error');
          }
          this.store.dispatch(
            VehicleInquiryActions.setVehicleInquiryMakes({
              year: modelYear,
              response: [],
              inquiryPath,
            })
          );
          this.dispatchGetMakesByYear(modelYear, inquiryPath);
          return false;
        } else if (!response) {
          return false;
        } else {
          return true;
        }
      }) as OperatorFunction<Nullable<PicklistItem[]>, PicklistItem[]>
    );
  }

  dispatchGetMakesByYear(year: number, inquiryPath: InquiryPath): void {
    this.powersportsAdapter
      .getPowersportsVehicleMakesByYear(year, inquiryPath)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          const formattedResponse: PicklistItem[] = response.makes.map(
            (make) => {
              return {
                displayName: make.make,
                value: make.make,
              };
            }
          );
          this.store.dispatch(
            VehicleInquiryActions.setVehicleInquiryMakes({
              year,
              response: formattedResponse,
              inquiryPath,
            })
          );
        },
        error: (error) => {
          this.store.dispatch(
            VehicleInquiryActions.removeVehicleInquiryMakes({
              year,
              inquiryPath,
            })
          );
        },
      });
  }

  getPowersportsVehicleModelsByYearAndMake(
    modelYear: number,
    make: string,
    inquiryPath: InquiryPath
  ): Observable<PicklistItem[]> {
    let failureCount = 0;
    return this.getVehicleInquiryModels(modelYear, make, inquiryPath).pipe(
      filter((response) => {
        if (response === undefined) {
          if (failureCount++ > 0) {
            throw new Error('VehicleInquiry error');
          }
          this.store.dispatch(
            VehicleInquiryActions.setVehicleInquiryModels({
              year: modelYear,
              make,
              response: [],
              inquiryPath,
            })
          );
          const encodedMake = btoa(make);
          this.dispatchGetModelsByYearAndMake(
            modelYear,
            encodedMake,
            make,
            inquiryPath
          );
          return false;
        } else if (!response) {
          return false;
        } else {
          return true;
        }
      }) as OperatorFunction<Nullable<PicklistItem[]>, PicklistItem[]>
    );
  }

  dispatchGetModelsByYearAndMake(
    year: number,
    encodedMake: string,
    stringMake: string,
    inquiryPath: InquiryPath
  ): void {
    this.powersportsAdapter
      .getPowersportsVehicleModelsByYearAndMake(year, encodedMake, inquiryPath)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          const formattedResponse: PicklistItem[] = response.models.map(
            (model) => {
              return {
                displayName: model.model,
                value: model.model,
              };
            }
          );
          this.store.dispatch(
            VehicleInquiryActions.setVehicleInquiryModels({
              year,
              make: stringMake,
              response: formattedResponse,
              inquiryPath,
            })
          );
        },
        error: () => {
          this.store.dispatch(VehicleInquiryActions.removeVehicleInquiryModels({
            year,
            make: stringMake,
            inquiryPath,
          }));
        },
      });
  }

  getMsaVinDetails(
    vehicleIdentificationNumber: string
  ): Observable<PowersportsVehicleVinDetailsResponse> {
    return this.powersportsAdapter.getMsaVinDetails(
      vehicleIdentificationNumber
    );
  }

  // 'Retrieve the model information for a given year, make and model for known boat.
  getBoatModels(
    year: string,
    make: string,
    model: string
  ): Observable<BoatModel[]> {
    return this.powersportsAdapter
      .getBoatModels(year, make, model)
      .pipe(map((response) => response.boatModels));
  }

  // 'Retrieve the model information for a given year, make and model for known boat.
  getBoatMotorModels(
    year: string,
    make: string,
    model: string
  ): Observable<BoatModel[]> {
    return this.powersportsAdapter
      .getBoatMotorModels(year, make, model)
      .pipe(map((response) => response.boatMotorModels));
  }

  getBoatTrailerModels(
    year: string,
    make: string,
    model: string
  ): Observable<BoatTrailerModel[]> {
    return this.powersportsAdapter
      .getBoatTrailerModels(year, make, model)
      .pipe(map((response) => response.boatTrailerModels));
  }

  // 'Retrieve the model information for a given year, make and model for known RV.
  getRVModels(
    year: string,
    make: string,
    model: string
  ): Observable<RVModel[]> {
    return this.powersportsAdapter
      .getRVModels(year, make, model)
      .pipe(map((response) => response.rvModels));
  }

  getVehicleInquiryYears(
    inquiryPath: InquiryPath
  ): Observable<Nullable<PicklistItem[]>> {
    return this.store.select(
      VehicleInquirySelectors.getVehicleInquiryYears(inquiryPath)
    );
  }

  getVehicleInquiryMakes(
    year: number,
    inquiryPath: InquiryPath
  ): Observable<Nullable<PicklistItem[]>> {
    return this.store.select(
      VehicleInquirySelectors.getVehicleInquiryMakes(year, inquiryPath)
    );
  }
  getVehicleInquiryModels(
    year: number,
    make: string,
    inquiryPath: InquiryPath
  ): Observable<Nullable<PicklistItem[]>> {
    return this.store.select(
      VehicleInquirySelectors.getVehicleInquiryModels(year, make, inquiryPath)
    );
  }
}
