import { PolicyHolderType, ProductType } from '@core/models/api/dsm-types';
import {
  QuoteRetrievePersonalAutoResponse,
  QuoteRetrieveResponse,
  QuoteRetrieveUmbrellaResponse,
} from '@core/models/api/response/retrieve-response.model';
import { MemberModel } from '@core/models/views/person.model';
import { StringUtils } from '@shared/utils/string.utils';
import {
  DriverActions,
  PolicyHolderActions,
  RetrieveActions,
  MemberActions,
  SessionActions,
  EligibleDiscountsActions,
  ProductActions,
  HouseholdMemberActions,
} from '@core/store/actions';
import {
  EntityState,
  EntityAdapter,
  createEntityAdapter,
  Dictionary,
} from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { PersonUtils } from '@shared/utils/person.utils';
import { Nullable } from '@shared/utils/type.utils';
import { DriverEntity } from '../driver/driver.entity';
import { EligibleDiscountsEntity } from '../eligible-discounts/eligible-discounts.entity';
import { PolicyHolderEntity } from '../policyholder/policyholder.entity';

import {
  MemberDriverRole,
  MemberPolicyRole,
} from '@core/models/entities/base.entity';
import { HouseholdMemberEntity } from '@entities/household-member/household-member.model';
import { AddressEntity } from '@entities/address/address.entity';

export interface MemberEntity
  extends Partial<DriverEntity>,
    Partial<PolicyHolderEntity>,
    Partial<HouseholdMemberEntity> {
  // policyRoles is from BasePersonEntity -- redeclaring to get around Partial<>
  policyRoles: MemberPolicyRole[];
  driverRoles: MemberDriverRole[];
  entityId: string;
}

export interface MemberState extends EntityState<MemberEntity> {
  driverCallCount: number;
}

export const adapter: EntityAdapter<MemberEntity> =
  createEntityAdapter<MemberEntity>({
    selectId: (member) => member.entityId,
  });

const initialState = adapter.getInitialState({
  driverCallCount: 0,
});

const memberReducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(MemberActions.storeMember, (state, { payload }) =>
    adapter.upsertOne(payload, state)
  ),
  on(DriverActions.upsertDriver, DriverActions.deleteDriver, (state) => ({
    ...state,
    driverCallCount: state.driverCallCount + 1,
  })),
  on(
    DriverActions.upsertDriverSuccess,
    DriverActions.upsertDriverError,
    DriverActions.deleteDriverSuccess,
    DriverActions.deleteDriverError,
    (state) => ({ ...state, driverCallCount: state.driverCallCount - 1 })
  ),
  on(
    MemberActions.upsertMember,
    DriverActions.upsertDriverSuccess,
    PolicyHolderActions.upsertPolicyHolderSuccess,
    HouseholdMemberActions.upsertHouseholdMemberSuccess,
    (state, { payload }) =>
      adapter.upsertOne(transformMemberForSave(payload, state.entities), state)
  ),
  on(DriverActions.deleteDriverSuccess, (state, { request }) => {
    const member = state.entities[request.driver.entityId];
    if (!member) return state;
    const policyRoles = member.policyRoles.filter(
      (r) => r.entityType !== 'driver' || r.productType !== request.productType
    );
    if (policyRoles.length) {
      return adapter.updateOne(
        {
          id: member.entityId,
          changes: { policyRoles },
        },
        state
      );
    } else {
      return adapter.removeOne(member.entityId, state);
    }
  }),
  on(PolicyHolderActions.deletePolicyHolderSuccess, (state, { request }) => {
    const member = state.entities[request.policyHolder.entityId];
    if (!member) return state;
    const policyRoles = member.policyRoles.filter(
      (r) =>
        r.entityType !== 'policyHolder' || r.productType !== request.productType
    );
    if (policyRoles.length) {
      return adapter.updateOne(
        {
          id: member.entityId,
          changes: { policyRoles },
        },
        state
      );
    } else {
      return adapter.removeOne(member.entityId, state);
    }
  }),
  on(
    HouseholdMemberActions.removeHouseholdMemberSuccess,
    (state, { request }) => {
      const member = state.entities[request.householdMember.entityId];
      if (!member) return state;
      const policyRoles = member.policyRoles.filter(
        (r) =>
          r.entityType !== 'householdMember' ||
          r.productType !== request.productType
      );
      if (policyRoles.length) {
        return adapter.updateOne(
          {
            id: member.entityId,
            changes: { policyRoles },
          },
          state
        );
      } else {
        return adapter.removeOne(member.entityId, state);
      }
    }
  ),
  on(
    PolicyHolderActions.patchPolicyRolesSuccess,
    (state, { response, productType, request }) => {
      const existingPniAddress: Nullable<AddressEntity> = Object.values(
        state.entities
      ).find((member) =>
        member?.policyRoles.find(
          (r) =>
            r.productType === productType && r.entityType === 'policyHolder'
        )
      )?.address;
      const impactedMembers = Object.values(state.entities)
        .map((member) => {
          const existingRole = member?.policyRoles.find(
            (r) =>
              r.productType === productType && r.entityType === 'policyHolder'
          );
          if (existingRole) {
            // Typical case, policyHolder to policyHolder.
            const changedRole = response.policyRoles.find(
              (r) => r.policyHolderId === existingRole.entityId
            );
            if (changedRole) {
              const newRoleSequence =
                PersonUtils.policyRoleSequenceFromPolicyHolderType(
                  changedRole.policyHolderType
                );
              if (newRoleSequence !== existingRole.roleSequence) {
                return {
                  ...member,
                  policyHolderType: changedRole.policyHolderType,
                  policyRoles: [
                    ...member!.policyRoles.filter(
                      (r) =>
                        r.productType !== productType ||
                        r.entityType !== 'policyHolder'
                    ),
                    {
                      productType,
                      entityType: 'policyHolder',
                      entityId: existingRole.entityId,
                      roleSequence: newRoleSequence,
                    },
                  ],
                };
              }
            }
          } else if (member) {
            // Driver added as policyHolder. An unfortunate addition to policy-roles, which take my word for it, way better than the alternative.
            const driverRole = member.policyRoles.find(
              (r) => r.productType === productType && r.entityType === 'driver'
            );
            const requestedChangeByDriverId = request.find(
              (change) => change.driverId === driverRole?.entityId
            );
            if (driverRole && requestedChangeByDriverId) {
              const responseChange = response.policyRoles.find(
                (change) =>
                  change.policyHolderType ===
                  requestedChangeByDriverId.policyHolderType
              );
              if (responseChange) {
                const newRoleSequence =
                  PersonUtils.policyRoleSequenceFromPolicyHolderType(
                    responseChange.policyHolderType
                  );
                return {
                  ...member,
                  address: existingPniAddress,
                  policyHolderType: responseChange.policyHolderType,
                  policyRoles: [
                    ...member.policyRoles,
                    {
                      productType,
                      entityType: 'policyHolder',
                      entityId: responseChange.policyHolderId,
                      roleSequence: newRoleSequence,
                    },
                  ],
                };
              }
            }
          }
          return null;
        })
        .filter((v) => !!v) as MemberEntity[];
      if (impactedMembers.length > 0) {
        return adapter.upsertMany(impactedMembers, state);
      } else {
        return state;
      }
    }
  ),
  on(MemberActions.removePolicyRole, (state, { policyRole }) => {
    const member = Object.values(state.entities).find((m) =>
      m?.policyRoles.find(
        (r) =>
          r.productType === policyRole.productType &&
          r.entityType === policyRole.entityType &&
          r.entityId === policyRole.entityId &&
          r.roleSequence === policyRole.roleSequence
      )
    );
    if (member) {
      return adapter.updateOne(
        {
          id: member.entityId,
          changes: {
            policyRoles: member.policyRoles.filter(
              (r) =>
                !(
                  r.productType === policyRole.productType &&
                  r.entityType === policyRole.entityType &&
                  r.entityId === policyRole.entityId &&
                  r.roleSequence === policyRole.roleSequence
                )
            ),
          },
        },
        state
      );
    } else {
      return state;
    }
  }),
  on(MemberActions.updateMemberSelected, (state, { payload }) => {
    const person = {
      ...state.entities[payload.entityId],
      selected: payload.selected,
    } as MemberEntity;

    return adapter.upsertOne(person, state);
  }),
  on(MemberActions.addPrefillMember, (state, { payload, products }) => {
    return adapter.upsertOne(
      transformPrefillDriverForSave(payload, products, state.entities),
      state
    );
  }),
  on(MemberActions.dropMemberPrefillContent, (state, { payload: entityId }) => {
    return dropMemberPrefillContent(state, entityId);
  }),
  on(
    RetrieveActions.retrieveQuoteSuccess,
    RetrieveActions.retrieveInactiveQuoteSuccess,
    (state, { payload }) => {
      return adapter.upsertMany(
        combineRetrievedPolicyHoldersAndDrivers(payload, state),
        state
      );
    }
  ),
  on(
    EligibleDiscountsActions.updateDriverDiscountSuccess,
    (state, { payload }) => {
      return applyDiscountToState(state, payload);
    }
  ),
  on(MemberActions.removePolicyRolesByProduct, (state, { payload }) => {
    return removeMemberPolicyRolesByProductFromState(state, payload);
  }),
  on(ProductActions.updateSelectedProducts, (state, { payload }) => {
    const oldEntities: Dictionary<MemberEntity> = { ...state.entities };
    const entities =
      (Object.entries(oldEntities)?.map(([id, person]) => {
        return {
          ...person,
          driverRoles:
            person?.driverRoles?.filter((driverRole) =>
              payload.includes(driverRole?.productType)
            ) ?? [],
        };
      }) as MemberEntity[]) ?? [];
    return adapter.upsertMany(entities, state);
  }),
  on(ProductActions.removeProduct, (state, { payload }) => {
    const oldEntities: Dictionary<MemberEntity> = { ...state.entities };
    const entities =
      (Object.entries(oldEntities)?.map(([id, person]) => {
        return {
          ...person,
          driverRoles:
            person?.driverRoles?.filter(
              (driverRole) => payload !== driverRole?.productType
            ) ?? [],
        };
      }) as MemberEntity[]) ?? [];
    return adapter.upsertMany(entities, state);
  })
);

export function reducer(
  state: MemberState | undefined,
  action: Action
): MemberState {
  return memberReducer(state, action);
}

function combineRetrievedPolicyHoldersAndDrivers(
  response: QuoteRetrieveResponse,
  state: MemberState
): MemberEntity[] {
  const combinedPeopleDict: Dictionary<MemberEntity> = { ...state.entities };

  if (response.response.policyHolders) {
    response.response.policyHolders.forEach((policyHolder) =>
      transformPolicyHolderForSave(
        {
          ...(policyHolder as MemberEntity),
          productType: response.productType,
        },
        combinedPeopleDict
      )
    );
  }

  const drivers = (response.response as QuoteRetrievePersonalAutoResponse)
    .drivers;
  if (drivers) {
    drivers.forEach((driver: DriverEntity) => {
      driver = PersonUtils.addDiscountsToDriverEntity(
        driver,
        response.response as QuoteRetrievePersonalAutoResponse
      );
      return transformDriverForSave(
        {
          ...(driver as unknown as MemberEntity),
          productType: response.productType,
        },
        combinedPeopleDict
      );
    });
  }

  const householdMembers = (response.response as QuoteRetrieveUmbrellaResponse)
    .householdMembers;
  if (householdMembers) {
    householdMembers.forEach((member) =>
      transformHouseholdMemberForSave(
        {
          ...(member as MemberEntity),
          productType: response.productType,
        },
        combinedPeopleDict
      )
    );
  }

  return Object.values(combinedPeopleDict).map((person) => ({
    ...person,
    selected: true,
  })) as MemberEntity[];
}

function transformMemberForSave(
  member: MemberEntity,
  entities: Dictionary<MemberEntity>
): MemberEntity {
  let existingMember = entities[member.entityId];

  if (!existingMember) {
    existingMember = Object.values(entities).find((found) =>
      PersonUtils.isSamePerson(found?.person, {
        ...member?.person,
        gender: undefined,
      })
    );
  }

  // If there is an existing member, don't let explicitly undefined fields in the new one override. TIB-676
  if (existingMember) {
    const fieldsToRemove: (keyof MemberEntity)[] = [];
    for (const key of Object.keys(member) as (keyof MemberEntity)[]) {
      if (member[key] === undefined && existingMember[key]) {
        fieldsToRemove.push(key);
      }
    }
    if (!!fieldsToRemove.length) {
      member = { ...member };
      for (const key of fieldsToRemove) {
        delete member[key];
      }
    }
  }

  const newMember: MemberEntity = {
    ...(existingMember || PersonUtils.blankMemberEntity()),
    ...member,
    person: {
      ...existingMember?.person,
      ...member.person,
      gender: existingMember?.person?.gender || undefined,
    },
    entityId:
      existingMember?.entityId ||
      member.entityId ||
      StringUtils.generateEntityId(),
    selected: existingMember?.selected ?? true,
    eligibleDiscountIds: {},
  };

  PersonUtils.addPolicyRolesFromSingleRoleMemberEntity(newMember, member);

  newMember.eligibleDiscountIds = {
    ...existingMember?.eligibleDiscountIds,
  };
  if (member.eligibleDiscounts) {
    newMember.eligibleDiscountIds[member.productType as ProductType] =
      member.eligibleDiscounts;
  }
  if (member.infractions) {
    if (newMember.infractionsByProduct) {
      newMember.infractionsByProduct = { ...newMember.infractionsByProduct };
    } else {
      newMember.infractionsByProduct = {};
    }
    newMember.infractionsByProduct = {
      ...newMember.infractionsByProduct,
      [member.productType as string]: member.infractions,
    };
  }

  /**
   *  Why are we deleting?
   *
   *
   */
  delete newMember.productType;
  delete newMember.eligibleDiscounts;
  delete newMember.driverId;
  delete newMember.policyHolderId;
  delete newMember.householdMemberId;
  delete newMember.infractions;

  /**
   * response returns masked dateOfBirth so need to use current
   * state if masked value is provided
   */
  if (
    newMember?.person?.dateOfBirth === '****-**-**' &&
    existingMember &&
    existingMember.person
  ) {
    newMember.person.dateOfBirth = existingMember.person.dateOfBirth;
  }

  /* CODEMINERS-5509 2022-12-14
   * If we get policyHolderType="PolicyDriver", and had a more valid one before, keep the old one.
   * This appears to be a bug somewhere in DSM; PolicyDriver is not a real policyHolderType.
   */
  if (
    newMember.policyHolderType === ('PolicyDriver' as PolicyHolderType) &&
    existingMember?.policyHolderType
  ) {
    newMember.policyHolderType = existingMember.policyHolderType;
  }

  if (
    newMember.person &&
    (newMember.nonSpecifiedGender || existingMember?.nonSpecifiedGender) &&
    !newMember.person.gender
  ) {
    newMember.person = {
      ...newMember.person,
      gender: 'X',
    };
  }

  return newMember;
}

function transformPrefillDriverForSave(
  prefillPerson: MemberModel,
  products: ProductType[],
  entities: Dictionary<MemberEntity>
): MemberEntity {
  let updatedPrefillPerson = prefillPerson;

  const matchedPerson = Object.values(entities).find((person) =>
    PersonUtils.isSamePerson(person?.person, prefillPerson.person)
  );

  if (matchedPerson) {
    updatedPrefillPerson = {
      ...matchedPerson,
      prefillId: prefillPerson.prefillId,
    };
  } else {
    updatedPrefillPerson = {
      ...PersonUtils.blankMemberEntity(),
      ...prefillPerson,
      entityId: StringUtils.generateEntityId(),
    };
  }

  return updatedPrefillPerson;
}

function transformPolicyHolderForSave(
  policyHolder: MemberEntity,
  entities: Dictionary<MemberEntity>
): void {
  const currentStatePolicyHolder = entities[policyHolder?.entityId];
  let updatedPolicyHolder = {
    ...(currentStatePolicyHolder || PersonUtils.blankMemberEntity()),
    ...policyHolder,
  };

  if (!currentStatePolicyHolder) {
    const matchedPerson = Object.values(entities).find((person) =>
      PersonUtils.isSamePerson(person?.person, {
        ...policyHolder.person,
        gender: undefined,
      })
    );

    if (matchedPerson) {
      updatedPolicyHolder = {
        ...matchedPerson,
        ...policyHolder,
      };
    } else {
      updatedPolicyHolder.entityId = StringUtils.generateEntityId();
    }
  }

  PersonUtils.addPolicyRolesFromSingleRoleMemberEntity(
    updatedPolicyHolder,
    policyHolder
  );

  delete updatedPolicyHolder.productType;
  delete updatedPolicyHolder.policyHolderId;

  /**
   * response returns masked dateOfBirth so need to use current
   * state if masked value is provided
   */
  if (
    updatedPolicyHolder?.person?.dateOfBirth === '****-**-**' &&
    currentStatePolicyHolder &&
    currentStatePolicyHolder.person
  ) {
    updatedPolicyHolder.person.dateOfBirth =
      currentStatePolicyHolder.person.dateOfBirth;
  }

  // We could be missing relationToPrimaryNamedInsured if there is a policyholder but not a driver in a retrieve.
  // If it's the PNI, we can guess the relation.
  if (
    updatedPolicyHolder.policyHolderType === 'PolicyPriNamedInsured' &&
    !updatedPolicyHolder.relationToPrimaryNamedInsured
  ) {
    updatedPolicyHolder.relationToPrimaryNamedInsured = 'PrimaryNamedInsured';
  }

  entities[updatedPolicyHolder.entityId] = updatedPolicyHolder;
}

function transformDriverForSave(
  driver: MemberEntity,
  entities: Dictionary<MemberEntity>
): void {
  const currentStateDriver = entities[driver.entityId];
  let updatedDriver: MemberEntity = {
    ...(currentStateDriver || PersonUtils.blankMemberEntity()),
    ...driver,
    eligibleDiscountIds: driver.eligibleDiscounts
      ? {
          [driver.productType as ProductType]:
            driver.eligibleDiscounts as unknown as EligibleDiscountsEntity[],
        }
      : {},
  };

  if (!currentStateDriver) {
    const matchedPerson = Object.values(entities).find((person) =>
      PersonUtils.isSamePerson(person?.person, {
        ...driver.person,
        gender: undefined,
      })
    );

    if (matchedPerson) {
      updatedDriver = {
        ...matchedPerson,
        ...driver,
        eligibleDiscountIds: {
          ...matchedPerson.eligibleDiscountIds,
          ...updatedDriver.eligibleDiscountIds,
        },
      };
    } else {
      updatedDriver.entityId = StringUtils.generateEntityId();
    }
  }

  PersonUtils.setDriverRoles(updatedDriver, driver);
  PersonUtils.addPolicyRolesFromSingleRoleMemberEntity(updatedDriver, driver);

  if (updatedDriver.infractions) {
    if (updatedDriver.infractionsByProduct) {
      updatedDriver.infractionsByProduct = {
        ...updatedDriver.infractionsByProduct,
      };
    } else {
      updatedDriver.infractionsByProduct = {};
    }
    updatedDriver.infractionsByProduct[driver.productType as string] =
      updatedDriver.infractions;
  }

  if (
    driver.nonSpecifiedGender &&
    updatedDriver.person &&
    !updatedDriver.person.gender
  ) {
    updatedDriver.person = {
      ...updatedDriver.person,
      gender: 'X',
    };
  }

  delete updatedDriver.productType;
  delete updatedDriver.eligibleDiscounts;
  delete updatedDriver.driverId;
  delete updatedDriver.infractions;

  /**
   * response returns masked dateOfBirth so need to use current
   * state if masked value is provided
   */
  if (
    updatedDriver?.person?.dateOfBirth === '****-**-**' &&
    currentStateDriver &&
    currentStateDriver.person
  ) {
    updatedDriver.person.dateOfBirth = currentStateDriver.person.dateOfBirth;
  }

  entities[updatedDriver.entityId] = {
    ...entities[updatedDriver.entityId],
    ...updatedDriver,
  };
}

function transformHouseholdMemberForSave(
  member: MemberEntity,
  entities: Dictionary<MemberEntity>
): void {
  const currentStateHouseholdMember = entities[member.entityId];
  let updatedMember: MemberEntity = {
    ...(currentStateHouseholdMember || PersonUtils.blankMemberEntity()),
    ...member,
    eligibleDiscountIds: member.eligibleDiscounts
      ? {
          [member.productType as ProductType]:
            member.eligibleDiscounts as unknown as EligibleDiscountsEntity[],
        }
      : {},
  };

  if (!currentStateHouseholdMember) {
    const matchedPerson = Object.values(entities).find((person) =>
      PersonUtils.isSamePerson(person?.person, {
        ...member.person,
        gender: undefined,
      })
    );

    if (matchedPerson) {
      updatedMember = {
        ...matchedPerson,
        ...member,
      };
    } else {
      updatedMember.entityId = StringUtils.generateEntityId();
    }
  }

  PersonUtils.addPolicyRolesFromSingleRoleMemberEntity(updatedMember, member);

  delete updatedMember.productType;
  delete updatedMember.eligibleDiscounts;
  delete updatedMember.householdMemberId;

  /**
   * response returns masked dateOfBirth so need to use current
   * state if masked value is provided
   */
  if (
    updatedMember?.person?.dateOfBirth === '****-**-**' &&
    currentStateHouseholdMember &&
    currentStateHouseholdMember.person
  ) {
    updatedMember.person.dateOfBirth =
      currentStateHouseholdMember.person.dateOfBirth;
  }

  entities[updatedMember.entityId] = {
    ...entities[updatedMember.entityId],
    ...updatedMember,
  };
}

// This is ONLY going to have the deleted Person, so we need to probably look at other people
// Need to get a matched Person first.
function resetPrefillMember(
  member: MemberEntity,
  existingMember: Nullable<MemberEntity>
): MemberEntity {
  return PersonUtils.removeProductReferences(
    existingMember,
    member.productType
  );
}

function dropMemberPrefillContent(
  state: MemberState,
  entityId: string
): MemberState {
  const entity = state.entities[entityId];
  if (!entity) {
    return state;
  }
  const replacement = drop1MemberPrefillContent(entity);
  return {
    ...state,
    entities: {
      ...state.entities,
      [entityId]: replacement,
    },
  };
}

function drop1MemberPrefillContent(entity: MemberEntity): MemberEntity {
  if (!entity.prefillId) {
    return entity;
  }
  const replacement: MemberEntity = {
    ...entity,
    person: {
      ...entity.person,
    },
  };
  delete replacement.prefillId;
  if (replacement.licenseNumber?.includes('*')) {
    replacement.licenseNumber = '';
  }
  if (replacement.person?.dateOfBirth?.includes('*')) {
    replacement.person.dateOfBirth = '';
  }
  return replacement;
}

function applyDiscountToState(
  state: MemberState,
  discount: EligibleDiscountsEntity
): MemberState {
  let member: MemberEntity | null = null;
  let policyRole: MemberPolicyRole | null = null;
  outer: for (const searchMember of Object.values(
    state.entities
  ) as MemberEntity[]) {
    for (const searchRole of searchMember.policyRoles) {
      if (searchRole.entityId === +(discount.modelId || 0)) {
        member = searchMember;
        policyRole = searchRole;
        break outer;
      }
    }
  }
  if (!member || !policyRole) {
    return state;
  }
  const existingDiscounts =
    member.eligibleDiscountIds?.[policyRole.productType] || [];
  return {
    ...state,
    entities: {
      ...state.entities,
      [member.entityId]: {
        ...member,
        eligibleDiscountIds: {
          ...member.eligibleDiscountIds,
          [policyRole.productType]: [
            ...existingDiscounts.filter(
              (d) => d.eligibleDiscountId !== discount.eligibleDiscountId
            ),
            discount,
          ],
        },
      },
    },
  };
}

function removeMemberPolicyRolesByProductFromState(
  state: MemberState,
  policyHolder: PolicyHolderEntity
): MemberState {
  let member: MemberEntity | null = null;
  outer: for (const searchMember of Object.values(
    state.entities
  ) as MemberEntity[]) {
    for (const searchRole of searchMember.policyRoles) {
      if (searchRole.entityId === policyHolder.policyHolderId) {
        member = searchMember;
        break outer;
      }
    }
  }
  if (!member) {
    return state;
  }
  return {
    ...state,
    entities: {
      ...state.entities,
      [member.entityId]: {
        ...member,
        doesPolicyHolderTypeNeedUpdated: undefined,
        policyHolderType: undefined,
        policyRoles: [
          ...member.policyRoles?.filter(
            (pr) => pr.productType !== policyHolder.productType
          ),
        ],
      },
    },
  };
}

export const exportedFunctionsForTestOnly = {
  combineRetrievedPolicyHoldersAndDrivers,
  transformMemberForSave,
  transformPolicyHolderForSave,
  transformPrefillDriverForSave,
  resetPrefillMember,
  dropMemberPrefillContent,
  removeMemberPolicyRolesByProductFromState,
};
