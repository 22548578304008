import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { PremiumEntity } from '@core/store/entities/premium/premium.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';

export const addPremium = createAction(
  '[Premium] Add Premium',
  props<{ payload: PremiumEntity }>()
);

export const addPremiumError = createAction(
  '[Premium] Add Premium Error',
  props<{ error: ErrorModel }>()
);

export const addPremiumSuccess = createAction(
  '[Premium] Add Premium Success',
  props<{ payload: PremiumEntity }>()
);

export const updatePremium = createAction(
  '[Premium] Update Premium',
  props<{ payload: PremiumEntity }>()
);

export const updatePremiumError = createAction(
  '[Premium] Update Premium Error',
  props<{ error: ErrorModel }>()
);

export const updatePremiumSuccess = createAction(
  '[Premium] Update Premium Success',
  props<{ payload: Update<PremiumEntity> }>()
);

export const deletePremium = createAction(
  '[Premium] Delete Premium',
  props<{ payload: string }>()
);

export const deletePremiumError = createAction(
  '[Premium] Delete Premium Error',
  props<{ error: ErrorModel }>()
);

export const deletePremiumSuccess = createAction(
  '[Premium] Delete Premium Success',
  props<{ payload: string }>()
);
