<ng-container *ngIf="vm$ | async as vm">
  <form class="unsupported-container">
    <h3 class="bolt-heading-xs font-weight-med">Quote in PolicyCenter</h3>
    <div class="standard-rounded-border outer-padding">
      <div
        class="bolt-row bolt-align-items-center bolt-justify-content-between"
      >
        <div class="bolt-col-auto">
          <div class="bolt-row bolt-no-gutters bolt-align-items-center">
            <bolt-icon
              name="plus"
              color="vibrant-blue"
              class="bolt-space-right-2xs"
            ></bolt-icon>
            <span class="font-weight-med bolt-space-right-2xs">
              {{ vm.displayableProductsList }}:
            </span>
            Use PolicyCenter to bundle with this quote
          </div>
        </div>
        <div class="bolt-col-auto" (click)="toggleProductDetails()">
          <div class="gray-icon">
            <bolt-icon
              name="chevron-down"
              size="sm"
              id="rotateIcon"
              [class.rotated]="showProductDetails"
              [class.not-rotated]="!showProductDetails"
            ></bolt-icon>
          </div>
        </div>
      </div>
      <ng-container *ngIf="showProductDetails">
        <hr />
        <div
          class="bolt-container-fluid standard-rounded-border bolt-background-pale-gray-25 bolt-space-inner-sm"
        >
          <div
            class="bolt-row"
            [class.bolt-align-items-center]="unsupportedProducts?.length === 1"
          >
            <ng-container
              *ngFor="let product of unsupportedProducts; index as i"
            >
              <div class="bolt-col-9 border-right">
                <div
                  class="bolt-row bolt-align-items-center"
                  [class.bolt-space-bottom-md]="
                    (unsupportedProducts?.length || 0) > 1
                  "
                >
                  <div class="bolt-col-3">
                    <div class="product-info">
                      <img src="{{ product.imageUrl }}" alt="product icon" />
                      <div>
                        <div class="content-label mb-0">
                          {{ product.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bolt-col">
                    <p class="bolt-body-copy-sm mb-0">
                      Continue to PolicyCenter to add
                      {{ product.name }} with your current submissions
                    </p>
                  </div>
                </div>
              </div>
              <div *ngIf="i === 0" class="bolt-col-3">
                <bolt-button
                  (click)="launchPcUrl()"
                  [attr.id]="'pivot-to-pc-from-hub'"
                  type="primary"
                  size="sm"
                  width="full"
                  >Quote in PolicyCenter</bolt-button
                >
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</ng-container>
