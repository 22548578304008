import { PreBindDocumentsFormModel } from '@shared/components/pre-bind-documents/pre-bind-documents-form/pre-bind-documents-form.model';
import { createAction, props } from '@ngrx/store';
import { ProductType } from '@core/models/api/dsm-types';

export const updatePreBindDocumentsForm = createAction(
  '[Pre-bind Documents] Update Pre-bind Documents Form',
  props<{
    payload: PreBindDocumentsFormModel;
  }>()
);

export const openPreBindDocumentsModal = createAction(
  '[Pre-bind Documents] Launch Pre-bind Documents Modal'
);

export const resetPreBindDocumentsAcknowledgement = createAction(
  '[Pre-bind Documents] Reset Pre-bind Documents Acknowledgement'
);

export const updateDwellingReplacementCostForm = createAction(
  '[Dwelling Replacement Cost] Update Dwelling Replacement Cost Form',
  props<{
    payload: boolean;
  }>()
);
