<div class="bolt-container-fluid">
  <div class="bolt-row bolt-align-items-center">
    <div class="bolt-col-12">
      <p>
        The self-reported program rewards <strong>low-mileage drivers</strong>.
        Save 5% for enrolling. Then, the fewer miles the customer drives, the
        higher the discount they can earn – <strong>up to 15%</strong>.
      </p>
    </div>
    <div class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4">
      <div class="image-section">
        <div class="image-circle">
          <img
            class="tm-icon"
            src="assets/images/telematics/document-filled.svg"
            alt=""
          />
        </div>
        <p class="info">
          Form submitted to report mileage
          <bolt-contextual-help>
            <p>
              <strong>How does this work?</strong><br />
              We send the customer a form to provide their vehicle's odometer
              reading at enrollment, and before every policy renewal.
            </p>
          </bolt-contextual-help>
        </p>
      </div>
    </div>
    <div class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4">
      <div class="image-section">
        <div class="image-circle">
          <img
            class="tm-icon"
            src="assets/images/icons/calendar_blue.svg"
            alt=""
          />
        </div>
        <p class="info">
          Earn a discount at every renewal
          <bolt-contextual-help>
            <p>
              <strong>How does this work?</strong><br />
              The discount is based on the vehicle's mileage, and it would be
              applied at renewal. The discount remains on the policy as long as
              the customer continues to provide mileage, and the vehicle(s) are
              insured with us.
            </p>
          </bolt-contextual-help>
        </p>
      </div>
    </div>
    <div class="bolt-col-sm-12 bolt-col-md-6 bolt-col-lg-4"></div>
  </div>
  <div *ngFor="let vehicle of vehicles; let i = index">
    <nwx-telematics-vehicle-premium
      [vehicle]="vehicle"
      [vehicleIndex]="i"
      [currentProgram]="'SelfReported'"
      [enrollment]="enrollment"
    ></nwx-telematics-vehicle-premium>
  </div>
</div>
