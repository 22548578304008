import { Injectable } from '@angular/core';
import { Nullable } from '@shared/utils/type.utils';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProductModel } from '../store/entities/product/product.model';
import { UserModel } from '../store/entities/user/user.model';
import { getNextStep } from '../store/selectors/next-step.selector';
import { TelematicsService } from './telematics.service';
import { UserService } from './user.service';

export interface NextStep {
  instructions: string;
  noButton: boolean;
  buttonLabel: string;
  buttonAction?: Action;
  buttonFunction?: any;
  buttonEvent?: Event;
  buttonDisabled?: boolean;
  buttonType?: string;
  buttonReady?: boolean;
  isTelematicsStep?: boolean;
  displayInCondensedView?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UpNextService {
  constructor(
    private telematicsService: TelematicsService,
    private store: Store,
    private userService: UserService
  ) {}

  getNextStep(): Observable<NextStep> {
    return this.store.select(getNextStep);
  }

  openInPolicyCenter(user: Nullable<UserModel>): void {
    let isIAUser = false;
    if (user?.role === 'IA') {
      isIAUser = true;
    }
    this.userService.goToPolicyCenter(isIAUser);
  }

  getStatusMessageForProduct(product: ProductModel): string {
    let returnString = `${product.name} is in ${product.quoteStatus} status.`;
    switch (product.quoteStatus) {
      case 'Quoted': {
        returnString = `${product.name} has an initial rate.`;
        break;
      }
      case 'Draft': {
        returnString = `${product.name} is in draft.`;
        break;
      }
      case 'Error': {
        returnString = `${product.name} requires your review.`;
        break;
      }
      case 'Pending': {
        returnString = `${product.name} is pending.`;
        break;
      }
      case 'Issued': {
        returnString = `${product.name} has been issued.`;
        break;
      }
      case 'Binding': {
        returnString = `${product.name} has a final rate.`;
        break;
      }
    }
    return returnString;
  }
}
