import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromSelector from '@core/store/entities/session/session.selector';
import { Observable } from 'rxjs';
import { Nullable } from '@shared/utils/type.utils';
import { EuaJwtModel } from '../store/entities/session/session.model';
import { EuaAdapter } from '../adapters/eua.adapter';
import { setIsComprater } from '@core/store/entities/session/session.action';
import { SessionActions } from '@core/store/actions';
import { SessionSelectors } from '@core/store/selectors';
import { StringUtils } from '@shared/utils/string.utils';
import { SortedTasks } from '@entities/task/task.model';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(
    private store: Store,
    private euaAdapter: EuaAdapter,
    private window: Window
  ) {}

  getQuoteState(): Observable<string> {
    return this.store.select(fromSelector.getQuoteState);
  }

  getAccessToken(): Observable<Nullable<string>> {
    return this.store.select(fromSelector.getAccessToken);
  }

  getSessionId(): Observable<string> {
    return this.store.select(fromSelector.getSessionId);
  }

  getHubPageLoaded(): Observable<boolean> {
    return this.store.select(fromSelector.hasHubPageLoaded);
  }

  updateQuoteState(ratingState: string): void {
    this.store.dispatch(
      SessionActions.updateRatingState({ payload: ratingState })
    );
  }

  updateAccessToken(accessToken: string): void {
    this.store.dispatch(
      SessionActions.updateAccessToken({ payload: accessToken })
    );
  }

  fetchJwtWithAccessToken(accessToken: string): Observable<EuaJwtModel> {
    return this.euaAdapter.getEuaUserInfo(accessToken);
  }

  validateTokenLifetime(
    accessToken: string | null,
    jwt: EuaJwtModel
  ): string | null {
    const currentTimeSeconds = Date.now() / 1000 + 60;
    if (accessToken && jwt.exp && currentTimeSeconds < jwt.exp) {
      // TODO:dispatch action to wait on expiry and do something
      return accessToken;
    } else {
      this.clearSessionStorage();
      return null;
    }
  }

  storeJwt(jwt: EuaJwtModel): void {
    this.window.sessionStorage.setItem('jwt', JSON.stringify(jwt));
  }

  storeAccessToken(accessToken: string): void {
    this.window.sessionStorage.setItem('access_token', accessToken);
  }

  storeRole(role: string): void {
    this.window.sessionStorage.setItem('role', role);
  }

  fetchJwt(): EuaJwtModel | null {
    const json = this.window.sessionStorage.getItem('jwt');
    if (json) {
      return JSON.parse(json);
    } else {
      return null;
    }
  }

  fetchAccessToken(): string | null {
    return this.window.sessionStorage.getItem('access_token');
  }

  fetchRole(): string | null {
    if (this.window.sessionStorage.getItem('role')) {
      return this.window.sessionStorage.getItem('role');
    } else {
      return this.window.localStorage.getItem('agencyChannel');
    }
  }

  clearSessionStorage(): void {
    this.window.sessionStorage.clear();
  }

  setIsComprater(isComprater: boolean): void {
    this.store.dispatch(setIsComprater({ isComprater }));
  }

  setTaskAsReviewed(key: string): void {
    this.store.dispatch(SessionActions.setTaskAsReviewed({ key }));
  }

  dispatchHubLoaded(): void {
    this.store.dispatch(SessionActions.setHubLoaded());
  }

  setUserType(userType?: string): void {
    userType = StringUtils.fullyUnescapeUriComponent(userType);
    this.store.dispatch(SessionActions.setUserType({ userType }));
  }

  getUserType(): Observable<string> {
    return this.store.select(SessionSelectors.getUserType);
  }

  getAllReviewedTasks(): Observable<Nullable<{ [key: string]: boolean }>> {
    return this.store.select(SessionSelectors.getAllReviewedTasks);
  }

  getReviewedTask(taskField: string): Observable<Nullable<boolean>> {
    return this.store.select(SessionSelectors.getReviewedTask(taskField));
  }
}
