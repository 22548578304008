import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'nwx-card-with-action-button',
  templateUrl: './card-with-action-button.component.html',
  styleUrls: ['./card-with-action-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardWithActionButtonComponent {
  @Input() icon: string = 'person';
  @Input() button: string = '';
  @Input() buttonIcon?: string = '';
  @Input() buttonId: string = '';

  @Output() buttonClick = new EventEmitter<void>();

  onClick(): void {
    this.buttonClick.emit();
  }
}
