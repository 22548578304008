import { MemberModel } from '@core/models/views/person.model';
import {
  DriverVehiclePrefillActions,
  SessionActions,
} from '@core/store/actions';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ErrorModel } from '../error/error.model';
import { VehicleEntity } from '../vehicle/vehicle.entity';

interface DriverPrefillState extends EntityState<MemberModel> {}
interface VehiclePrefillState extends EntityState<VehicleEntity> {}

export interface DriverVehiclePrefillState {
  loaded: boolean;
  drivers: DriverPrefillState;
  vehicles: VehiclePrefillState;
  error?: ErrorModel | null;
}

export const driverAdapter: EntityAdapter<MemberModel> =
  createEntityAdapter<MemberModel>({
    selectId: (driver) => driver.prefillId || '',
  });

export const vehicleAdapter: EntityAdapter<VehicleEntity> =
  createEntityAdapter<VehicleEntity>({
    selectId: (vehicle) => vehicle.prefillId || '',
  });

const driverInitialState: DriverPrefillState = driverAdapter.getInitialState();
const vehicleInitialState: VehiclePrefillState =
  vehicleAdapter.getInitialState();
const initialState: DriverVehiclePrefillState = {
  loaded: false,
  drivers: driverInitialState,
  vehicles: vehicleInitialState,
};

const driverVehiclePrefillReducer = createReducer(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(
    DriverVehiclePrefillActions.addAllPrefillPeople,
    (state, { payload: drivers }) => ({
      ...state,
      drivers: driverAdapter.addMany(drivers, state.drivers),
    })
  ),
  on(
    DriverVehiclePrefillActions.addAllPrefillVehicles,
    (state, { payload: vehicles }) => ({
      ...state,
      vehicles: vehicleAdapter.addMany(vehicles, state.vehicles),
    })
  ),
  on(DriverVehiclePrefillActions.getCustomersInfoSuccess, (state, {}) => ({
    ...state,
    loaded: true,
  })),
  on(
    DriverVehiclePrefillActions.getCustomersInfoFail,
    (state, { payload: error }) => ({
      ...state,
      error,
      loaded: true,
    })
  ),
  on(
    DriverVehiclePrefillActions.updatePrefillVehicleSelected,
    DriverVehiclePrefillActions.updatePrefillVehicle,
    (state, { payload: vehicle }) => ({
      ...state,
      vehicles: vehicleAdapter.updateOne(vehicle, state.vehicles),
    })
  ),
  on(DriverVehiclePrefillActions.getCustomersInfoNoHit, (state, {}) => ({
    ...state,
    loaded: true,
  }))
);

export function reducer(
  state: DriverVehiclePrefillState | undefined,
  action: Action
): DriverVehiclePrefillState {
  return driverVehiclePrefillReducer(state, action);
}
