import { EligibilityFormActions, SessionActions } from "@core/store/actions";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { EligibilityFormModel } from './eligibility-form.model';

export interface EligibilityFormState extends EntityState<EligibilityFormModel> {
}

export const adapter = createEntityAdapter<EligibilityFormModel>({
  selectId: (entity) => entity.quoteId,
});

const initialState: EligibilityFormState = {
  ...adapter.getInitialState(),
};

export const reducer = createReducer<EligibilityFormState>(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),

  on(EligibilityFormActions.upsertEligibilityForm, (state, { model }) => {
    const existing = state.entities[adapter.selectId(model as EligibilityFormModel)];
    model = {
      quoteId: '',
      acknowledgement: false,
      ...existing,
      ...model,
    };
    return adapter.upsertOne(model as EligibilityFormModel, state);
  }),

  on(EligibilityFormActions.removeEligibilityForm, (state, { quoteId }) => {
    return adapter.removeOne(quoteId, state);
  }),
  on(EligibilityFormActions.removeEligibilityFormsForProduct, (state, { productType }) => {
    return adapter.removeMany((entity) => entity.productType === productType, state);
  }),

  on(EligibilityFormActions.addDog, (state, { quoteId }) => {
    const form = state.entities[quoteId];
    if (form) {
      return adapter.upsertOne({
        ...form,
        riskItems: {
          ...form.riskItems,
          dogs: [
            ...(form.riskItems?.dogs || []),
            {
              dogBreed: '',
              biteHistory: '',
              canineGoodCitizen: '',
            },
          ],
        },
      }, state);
    }
    return state;
  }),
  on(EligibilityFormActions.removeDog, (state, { quoteId, index }) => {
    let form = state.entities[quoteId];
    if (form) {
      if ((index >= 0) && (index < (form.riskItems?.dogs?.length || 0))) {
        const dogs = [...form.riskItems!.dogs!];
        dogs.splice(index, 1);
        form = {
          ...form,
          riskItems: {
            ...form.riskItems,
            dogs,
          },
        };
      }
      return adapter.upsertOne({
        ...form,
      }, state);
    }
    return state;
  })
);
