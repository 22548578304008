import {
  AddressEntity,
  sanitizeAddressEntity,
} from '../address/address.entity';
import {
  TelematicsDeviceStatus,
  TelematicsVehicleProgramType,
  TelematicsMobileEnrollmentType,
} from '../../../models/api/dsm-types';
import { VehicleEntity } from '../vehicle/vehicle.entity';
import {
  CoverablePremium,
  PremiumEntity,
  VehiclePremium,
} from '../premium/premium.entity';
import { ObjectValidator } from '@core/helper/object-validator';

export interface VehicleEnrollment {
  enrollmentId?: string;
  vehicles: (EnrolledVehicle | NotEnrolledVehicle)[];
  enrollmentConsents?: {
    phoneNumber?: string;
    hasConsentToOneTimeTextActivation?: boolean;
    hasConsentToOngoingTexts?: boolean;
  };
}

export function sanitizeVehicleEnrollment(input: unknown): VehicleEnrollment {
  return ObjectValidator.forceSchema<VehicleEnrollment>(
    input,
    {
      enrollmentId: 'string',
      vehicles: sanitizeAllEnrollmentVehicles,
      enrollmentConsents: sanitizeEnrollmentConsent,
    },
    ['enrollmentId', 'enrollmentConsents']
  );
}

export function sanitizeEnrollmentConsent(input: unknown) {
  return ObjectValidator.forceSchema(input, {
    phoneNumber: 'string',
    hasConsentToOneTimeTextActivation: 'boolean',
    hasConsentToOngoingTexts: 'boolean',
  });
}

export interface MobileEnrollment {
  enrollmentId?: string;
  mobileEnrollment: MobileEnrollmentValue;
  enrollmentConsents?: {
    phoneNumber?: string;
    hasConsentToOneTimeTextActivation?: boolean;
    hasConsentToOngoingTexts?: boolean;
  };
}

export interface MobileEnrollmentValue {
  enrollmentStatus: 'Enrolled' | 'NotEnrolled';
  mobileEnrollmentType: TelematicsMobileEnrollmentType;
}

export function sanitizeMobileEnrollment(input: unknown): MobileEnrollment {
  return ObjectValidator.forceSchema<MobileEnrollment>(
    input,
    {
      enrollmentId: 'string',
      mobileEnrollment: sanitizeMobileEnrollmentValue,
      enrollmentConsents: sanitizeEnrollmentConsent,
    },
    ['enrollmentId', 'enrollmentConsents']
  );
}

export function sanitizeMobileEnrollmentValue(input: unknown) {
  return ObjectValidator.forceSchema(
    input,
    {
      enrollmentStatus: 'string',
      mobileEnrollmentType: 'string',
    },
    []
  );
}

export type ExtendedTelematicsVehicle = VehicleEntity &
  VehiclePremium &
  CoverablePremium &
  Pick<PremiumEntity, 'termMonths'> &
  (EnrolledVehicle | NotEnrolledVehicle);

export interface EnrolledVehicle {
  enrollmentStatus: 'Enrolled' | 'VerifiedScore';
  annualMileage?: number;
  deviceCompatible?: boolean;
  deviceStatus?: TelematicsDeviceStatus;
  enrollmentDate?: string; // CCYY-MM-DD
  isConnectable?: boolean;
  typicalAnnualMileage?: number;
  discountPercentage?: number;
  textNotification?: boolean;
  vehicleId: string;
  vehicleProgram: TelematicsVehicleProgramType;
  smartMilesAnnualMileage?: number; // CA only, don't use this field without a specific reason
  dataCollectionMethod?: 'Device' | 'ConnectedCar';
  version?: SmartMilesVersion; // SmartMiles and SmartMiles ConnectedCar only
}

export type SmartMilesVersion = '1.0' | '2.0';

export interface NotEnrolledVehicle {
  enrollmentStatus: 'NotEnrolled';
  annualMileage?: number;
  deviceCompatible?: boolean;
  isConnectable?: boolean;
  typicalAnnualMileage?: number;
  vehicleId: string;
}

export function sanitizeAllEnrollmentVehicles(
  input: unknown
): (EnrolledVehicle | NotEnrolledVehicle)[] {
  return ObjectValidator.sanitizeArray(input, sanitizeEnrollmentVehicle);
}

export function sanitizeEnrollmentVehicle(
  input: unknown
): EnrolledVehicle | NotEnrolledVehicle {
  return ObjectValidator.forceSchema<EnrolledVehicle | NotEnrolledVehicle>(
    input,
    {
      enrollmentStatus: 'string',
      annualMileage: 'number',
      dataCollectionMethod: 'string',
      deviceCompatible: 'boolean',
      deviceStatus: 'string',
      discountPercentage: 'number',
      enrollmentDate: 'string',
      isConnectable: 'boolean',
      smartMilesAnnualMileage: 'number',
      textNotification: 'boolean',
      typicalAnnualMileage: 'number',
      version: 'string',
      vehicleId: 'string',
      vehicleProgram: 'string',
    },
    [
      'annualMileage',
      'deviceCompatible',
      'deviceStatus',
      'enrollmentDate',
      'isConnectable',
      'typicalAnnualMileage',
      'discountPercentage',
      'textNotification',
      'smartMilesAnnualMileage',
      'dataCollectionMethod',
      'version',
    ]
  );
}

export interface TelematicsQualifyingInformation {
  effectiveDate?: string;
  primaryAddress?: Partial<AddressEntity>;
  originalPolicyEffectiveDate?: string;
  drivers?: QualifyingInformationDriver[];
  vehicles?: QualifyingInformationVehicle[];
}

export function sanitizeTelematicsQualifyingInformation(
  input: unknown
): TelematicsQualifyingInformation {
  return ObjectValidator.forceSchema<TelematicsQualifyingInformation>(
    input,
    {
      effectiveDate: 'string',
      primaryAddress: sanitizeAddressEntity,
      originalPolicyEffectiveDate: 'string',
      drivers: [sanitizeQualifyingInfoDriver],
      vehicles: [sanitizeQualifyingInfoVehicle],
    },
    [
      'effectiveDate',
      'primaryAddress',
      'originalPolicyEffectiveDate',
      'drivers',
      'vehicles',
    ]
  );
}

export interface QualifyingInformationDriver {
  driverId: number;
  dateOfBirth: string;
  yearsLicensed: number;
}

export function sanitizeQualifyingInfoDriver(
  input: unknown
): QualifyingInformationDriver {
  return ObjectValidator.forceSchema<QualifyingInformationDriver>(
    input,
    {
      driverId: 'number',
      dateOfBirth: 'string',
      yearsLicensed: 'number',
    },
    []
  );
}

export interface QualifyingInformationVehicle {
  vehicleId: string;
  deviceCompatible?: boolean;
  estimatedAnnualMileage: number;
  availablePrograms?: TelematicsAvailableProgram[];
  typicalAnnualMileage?: number;
}

export function sanitizeQualifyingInfoVehicle(
  input: unknown
): QualifyingInformationVehicle {
  const inputAsObject = input as Object;
  return ObjectValidator.forceSchema<QualifyingInformationVehicle>(
    input,
    {
      vehicleId: 'string',
      deviceCompatible: 'boolean',
      estimatedAnnualMileage: 'number',
      availablePrograms: [sanitizeTelematicsAvailablePrograms],
      typicalAnnualMileage: 'number',
    },
    ['year', 'availablePrograms', 'typicalAnnualMileage']
  );
}

export interface TelematicsRecommendationMessage {
  userMessage?: string;
  developerMessage: string;
  messageId?: string;
  code?: string;
  source?: string;
  docs?: string;
}

export function sanitizeTelematicsRecommendationMessages(
  input: unknown
): TelematicsRecommendationMessage[] {
  return ObjectValidator.sanitizeArray(
    input,
    sanitizeTelematicsRecommendationMessage
  );
}

export function sanitizeTelematicsRecommendationMessage(
  input: unknown
): TelematicsRecommendationMessage {
  return ObjectValidator.forceSchema<TelematicsRecommendationMessage>(
    input,
    {
      userMessage: 'string',
      developerMessage: 'string',
      messageId: 'string',
      code: 'string',
      source: 'string',
      docs: 'string',
    },
    ['userMessage', 'messageId', 'code', 'source', 'docs']
  );
}

export interface TelematicsAvailableProgram {
  name: string;
  displayName?: string;
  recommended: boolean;
  dataCollectionMethod?: 'Device' | 'ConnectedCar';
}

export function sanitizeTelematicsAvailablePrograms(
  input: unknown
): TelematicsAvailableProgram {
  return ObjectValidator.forceSchema<TelematicsAvailableProgram>(
    input,
    {
      name: 'string',
      displayName: 'string',
      recommended: 'boolean',
      dataCollectionMethod: 'string',
    },
    ['displayName', 'dataCollectionMethod']
  );
}

export interface InformationalMessage {
  warnings?: Warning[];
}

interface Warning {
  ruleId: string;
  entity?: WarningEntity;
  message?: string;
}

export function sanitizeWarning(input: unknown): Warning {
  return ObjectValidator.forceSchema<Warning>(
    input,
    {
      ruleId: 'string',
      message: 'string',
      entity: sanitizeWarningEntity,
    },
    ['entity', 'message']
  );
}

interface WarningEntity {
  displayName?: string;
  entity?: string;
  fixedId: string;
  vin?: string;
}

export function sanitizeWarningEntity(input: unknown): WarningEntity {
  return ObjectValidator.forceSchema<WarningEntity>(
    input,
    {
      displayName: 'string',
      entity: 'string',
      fixedId: 'string',
      vin: 'string',
    },
    ['displayName', 'entity', 'vin']
  );
}
