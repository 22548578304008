import { AmfBankAccountsResponse } from '@core/models/api/response/amf-bank-accounts-response.moel';
import { ErrorModel } from '@entities/error/error.model';
import { createAction, props } from '@ngrx/store';

export const loadAmfBankAccounts = createAction(
  '[Agency Sweep] Load AMF Bank Accounts'
);

export const loadAmfBankAccountsSuccess = createAction(
  '[Agency Sweep] Load AMF Bank Accounts Success',
  props<{ payload: AmfBankAccountsResponse }>()
);

export const loadAmfBankAccountsFail = createAction(
  '[Agency Sweep] Load AMF Bank Accounts Fail',
  props<{ error: ErrorModel }>()
);

export const amfBankAccountsNotNeeded = createAction(
  '[Agency Sweep] AMF Bank Accounts Not Needed'
);
