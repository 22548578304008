import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FieldDisplayWarningModule } from '@shared/components/field-display-warning/field-display-warning.module';
import { DisplayWarningDirective } from './display-warning.directive';

@NgModule({
  declarations: [DisplayWarningDirective],
  imports: [CommonModule, FieldDisplayWarningModule],
  exports: [DisplayWarningDirective],
})
export class DisplayWarningDirectiveModule {}
