import { PolicyHolderEntity } from './../store/entities/policyholder/policyholder.entity';
import { Injectable } from '@angular/core';
import { Observable, filter, map, switchMap, take, tap } from 'rxjs';
import { PolicyholderAdapter } from '@core/adapters/policyholder.adapter';
import { PolicyHolderRequest } from '@core/models/api/request/update-policyholder-request.model';
import { PersonUtils } from '@shared/utils/person.utils';
import { Action, ActionCreator, Store } from '@ngrx/store';
import { removePolicyRolesByProduct } from '@entities/member/member.actions';
import { MemberModel } from '@core/models/views/person.model';
import { PolicyHolderType, ProductType, RelationToPNIType } from '@core/models/api/dsm-types';
import { MemberService } from './member.service';
import { Nullable } from '@shared/utils/type.utils';
import { DriverActions, MemberActions, PolicyHolderActions } from '@core/store/actions';
import { StringUtils } from '@shared/utils/string.utils';
import { Actions, ofType } from '@ngrx/effects';
import { PolicyRolesPerson } from '@app/policy-roles-api/policy-roles-api.service';

interface PolicyHolderSingleChange {
  action: 'policyRolesApi' | 'addPolicyHolder' | 'deletePolicyHolder' | 'updateDriver' | 'unlistPolicyHolder';
  entityId: string;
  newRoleSequence?: 'primary' | 'secondary' | 'additional';
  relationToPrimaryNamedInsured?: RelationToPNIType;
  policyRoles?: PolicyRolesPerson[];
  policyHolderType?: string;
  policyHolderId?: number;
}

interface PolicyChangePerson {
  entityId: string;
  firstName: string;
  driverId: number;
  policyHolderId: number;
  oldPolicyHolderType: PolicyHolderType | '';
  newPolicyHolderType: PolicyHolderType | '';
  oldRelationToPni: RelationToPNIType;
  newRelationToPni: RelationToPNIType;
  member: MemberModel;
}

interface PolicyHolderChangesDigest {
  productType: ProductType;
  quoteId: string;
  people: PolicyChangePerson[];
  newPni: MemberModel;
  newSni: MemberModel | null;
  oldPni: MemberModel | null;
  oldSni: MemberModel | null;
}

@Injectable({
  providedIn: 'root',
})
export class PolicyholderService {
  constructor(
    private adapter: PolicyholderAdapter,
    private store: Store,
    private actions$: Actions,
    private memberService: MemberService
  ) {}

  addOrUpdatePolicyHolder(
    request: PolicyHolderRequest
  ): Observable<PolicyHolderEntity> {
    if (PersonUtils.isPolicyHolderSaved(request.policyHolder.policyHolderId)) {
      if (!!request.policyHolder.doesPolicyHolderTypeNeedUpdated) {
        return this.adapter.deletePolicyholder(request).pipe(
          take(1),
          tap(() =>
            this.store.dispatch(
              removePolicyRolesByProduct({ payload: request.policyHolder })
            )
          ),
          switchMap(() => this.adapter.addPolicyHolder(request))
        );
      } else {
        return this.adapter.updatePolicyHolder(request);
      }
    } else {
      const addRequest = {...request}
      delete addRequest?.policyHolder?.person?.ssn;
      return this.adapter.addPolicyHolder(addRequest);
    }
  }

  deletePolicyHolder(
    request: PolicyHolderRequest
  ): Observable<unknown> {
    return this.adapter.deletePolicyholder(request);
  }

  modifyPolicyHolderRoles(
    productType: ProductType,
    quoteId: string,
    pni: MemberModel,
    sni: Nullable<MemberModel>,
    anis: MemberModel[]
  ): Promise<void> {
    return new Promise<any>((resolve, reject) => {
      this.memberService.getAllSelectedPeople().pipe(take(1)).subscribe({
        next: (people) => {
          resolve({
            digest: this.digestRequestedRoleChanges(productType, quoteId, pni, sni, anis, people),
            people,
          })
        },
        error: (error) => reject(error),
        complete: () => {
          reject(new Error('policyholder lookup did not emit synchronously'));
        },
      });
    }).then(({ digest, people }) => {
      const changes = this.listChangesForRoleModificationDigest(digest);
      return changes.reduce((a, v) => a.then(() => this.applyPhRoleAction(v, digest)), Promise.resolve());
    });
  }

  private digestRequestedRoleChanges(
    productType: ProductType,
    quoteId: string,
    pni: MemberModel,
    sni: Nullable<MemberModel>,
    anis: MemberModel[],
    people: MemberModel[]
  ): PolicyHolderChangesDigest {
    let oldPni: MemberModel | null = null;
    let oldSni: MemberModel | null = null;
    // Ensure our pni and sni are the exact objects from people:
    pni = people.find(person => person.entityId === pni.entityId)!;
    sni = (sni ? people.find(person => person.entityId === sni!.entityId) : null) || null;
    const state = {
      productType,
      quoteId,
      newPni: pni,
      newSni: sni,
      oldPni: null,
      oldSni: null,
      people: people.map(person => {
        const record: PolicyChangePerson = {
          entityId: person.entityId,
          firstName: person.person?.firstName || '',
          driverId: 0,
          policyHolderId: 0,
          oldPolicyHolderType: '',
          newPolicyHolderType: '',
          oldRelationToPni: person.relationToPrimaryNamedInsured || 'Other',
          newRelationToPni: 'Other',
          member: person,
        };
        for (const role of person.policyRoles) {
          if (role.productType !== productType) {
            continue;
          }
          if (role.entityType === 'driver') {
            record.driverId = role.entityId;
          } else if (role.entityType === 'policyHolder') {
            record.policyHolderId = role.entityId;
            record.oldPolicyHolderType = PersonUtils.policyHolderTypeFromPolicyRoleSequence(role.roleSequence || '');
            switch (record.oldPolicyHolderType) {
              case 'PolicyPriNamedInsured': oldPni = person; break;
              case 'PolicySecNamedInsured': oldSni = person; break;
            }
          }
        }
        if (person.entityId === pni.entityId) {
          record.newPolicyHolderType = 'PolicyPriNamedInsured';
        } else if (person.entityId === sni?.entityId) {
          record.newPolicyHolderType = 'PolicySecNamedInsured';
        } else if (anis.find(ani => ani.entityId === person.entityId)) {
          record.newPolicyHolderType = 'PolicyAddlNamedInsured';
        }
        if (person.entityId === pni.entityId) {
          record.newRelationToPni = 'PrimaryNamedInsured';
        } else if (person.person?.maritalStatus === 'M' && pni.person?.maritalStatus === 'M') {
          // This is not perfect. If the PNI is married, assume that any other married person is their spouse.
          record.newRelationToPni = 'Spouse';
        } else if (
          person.relationToPrimaryNamedInsured &&
          person.relationToPrimaryNamedInsured !== 'PrimaryNamedInsured' &&
          person.relationToPrimaryNamedInsured !== 'Spouse'
        ) {
          record.newRelationToPni = person.relationToPrimaryNamedInsured;
        } else {
          record.newRelationToPni = 'Other';
        }
        return record;
      }),
    };
    state.oldPni = oldPni;
    state.oldSni = oldSni;
    return state;
  }

  private listChangesForRoleModificationDigest(
    digest: PolicyHolderChangesDigest
  ): PolicyHolderSingleChange[] {
    const prApiChanges: PolicyRolesPerson[] = [];
    const driverChanges: PolicyHolderSingleChange[] = [];
    let phChanges: PolicyHolderSingleChange[] = [];
    for (const person of digest.people) {
      if (person.oldPolicyHolderType !== person.newPolicyHolderType) {

        if (person.oldPolicyHolderType === '' && person.newPolicyHolderType === 'PolicyPriNamedInsured' && person.driverId) {
          prApiChanges.push({
            driverId: person.driverId,
            policyHolderType: 'PolicyPriNamedInsured',
          });

        } else if (person.oldPolicyHolderType === '' && person.newPolicyHolderType === 'PolicySecNamedInsured' && person.driverId) {
          prApiChanges.push({
            driverId: person.driverId,
            policyHolderType: 'PolicySecNamedInsured',
          });

        } else if (person.oldPolicyHolderType === '' && this.productSupportsAddPolicyHolder(digest.productType, person.newPolicyHolderType)) {
          phChanges.push({
            action: 'addPolicyHolder',
            entityId: person.entityId,
            policyHolderType: person.newPolicyHolderType,
          });

        } else if (person.newPolicyHolderType === '' && this.productSupportsDeletePolicyHolder(digest.productType, person.oldPolicyHolderType)) {
          phChanges.push({
            action: 'deletePolicyHolder',
            entityId: person.entityId,
            policyHolderId: person.policyHolderId,
          });

        } else if (person.newPolicyHolderType === '' && person.oldPolicyHolderType === 'PolicyPriNamedInsured') {
          if (!digest.people.find((other: any) => other.newPolicyHolderType === 'PolicyPriNamedInsured')) {
            throw new Error(`Changes would leave the quote without a PNI.`);
          }
          phChanges.push({
            action: 'unlistPolicyHolder',
            entityId: person.entityId,
            policyHolderId: person.policyHolderId,
          });

        } else if (person.oldPolicyHolderType && person.newPolicyHolderType) {
          prApiChanges.push({
            policyHolderId: person.policyHolderId,
            policyHolderType: person.newPolicyHolderType,
          });

        } else {
          throw new Error(`Unimplemented PH role change for ${digest.productType}: ${person.oldPolicyHolderType} => ${person.newPolicyHolderType}`);
        }
      }
      if (person.driverId) {
        if (person.oldRelationToPni !== person.newRelationToPni) {
          driverChanges.push({
            action: 'updateDriver',
            entityId: person.entityId,
            relationToPrimaryNamedInsured: person.newRelationToPni,
          });
        }
      }
    }
    phChanges = this.filterOutDeletesObviatedByPolicyRolesApi(phChanges, prApiChanges, digest);
    if (prApiChanges.length > 0) {
      return [
        {
          action: 'policyRolesApi',
          policyRoles: prApiChanges,
          entityId: '',
        },
        ...phChanges,
        ...driverChanges,
      ];
    } else {
      return [
        ...phChanges,
        ...driverChanges,
      ];
    }
  }

  /* If policy-roles-api changes role to Primary or Secondary, and we don't tell it where to put the old occupant of that role,
   * it implicitly deletes the old one.
   * That's perfectly sensible behavior, but we would also have captured the old occupant as a Delete action.
   * So detect this case, and remove our extra Delete actions with unlistPolicyHolder.
   */
  private filterOutDeletesObviatedByPolicyRolesApi(
    phChanges: PolicyHolderSingleChange[],
    prApiChanges: PolicyRolesPerson[],
    digest: PolicyHolderChangesDigest
  ): PolicyHolderSingleChange[] {
    if (prApiChanges.length < 1) {
      return phChanges;
    }
    return phChanges.map((change) => {
      if (change.action === 'deletePolicyHolder') {
        const summary = digest.people.find(p => p.entityId === change.entityId);
        if (summary && (
          summary.oldPolicyHolderType === 'PolicyPriNamedInsured' ||
          summary.oldPolicyHolderType === 'PolicySecNamedInsured'
        )) {
          if (prApiChanges.find(change => change.policyHolderType === summary.oldPolicyHolderType)) {
            return {
              ...change,
              action: 'unlistPolicyHolder',
            };
          }
        }
      }
      return change;
    });
  }

  private productSupportsAddPolicyHolder(
    productType: ProductType,
    policyHolderType: PolicyHolderType | ''
  ): boolean {
    switch (productType) {
      case 'PersonalAuto':
      //TODO powersports?
        return (policyHolderType !== 'PolicyAddlNamedInsured');
    }
    return true;
  }

  private productSupportsDeletePolicyHolder(
    productType: ProductType,
    policyHolderType: PolicyHolderType | ''
  ): boolean {
    if (policyHolderType === 'PolicyPriNamedInsured') {
      return false;
    }
    return true;
  }

  private applyPhRoleAction(
    change: PolicyHolderSingleChange,
    digest: PolicyHolderChangesDigest,
  ): Promise<void> {
    const correlationId = StringUtils.generateUuid();
    switch (change.action) {
      case 'policyRolesApi': {
        return this.actionsAsPromise(
          PolicyHolderActions.patchPolicyRoles({
            productType: digest.productType,
            quoteId: digest.quoteId,
            changes: change.policyRoles || [],
            correlationId,
          }),
          PolicyHolderActions.patchPolicyRolesSuccess,
          PolicyHolderActions.patchPolicyRolesError
        );
      }

      case 'updateDriver': {
        const person = digest.people.find(
          (p) => p.entityId === change.entityId
        )!;
        return this.actionsAsPromise(
          DriverActions.upsertDriver({
            payload: {
              ...person.member,
              driverId: person.driverId,
              productType: digest.productType,
              relationToPrimaryNamedInsured:
                change.relationToPrimaryNamedInsured,
            },
            correlationId,
          }),
          DriverActions.upsertDriverSuccess,
          DriverActions.upsertDriverError
        );
      }

      case 'addPolicyHolder': {
        const person = digest.people.find(
          (p) => p.entityId === change.entityId
        )!;
        return this.actionsAsPromise(
          PolicyHolderActions.upsertPolicyHolder({
            payload: {
              ...person.member,
              policyHolderId: person.policyHolderId,
              productType: digest.productType,
              policyHolderType:
                person.newPolicyHolderType || 'PolicyAddlNamedInsured',
              policyRoles: [
                ...person.member.policyRoles.filter(
                  (r) =>
                    r.productType !== digest.productType ||
                    r.entityType !== 'policyHolder'
                ),
                {
                  productType: digest.productType,
                  entityType: 'policyHolder',
                  roleSequence: change.newRoleSequence,
                  entityId: 0,
                },
              ],
            },
            correlationId,
          }),
          PolicyHolderActions.upsertPolicyHolderSuccess,
          PolicyHolderActions.upsertPolicyHolderError
        );
      }

      case 'deletePolicyHolder': {
        const person = digest.people.find(
          (p) => p.entityId === change.entityId
        )!;
        return this.actionsAsPromise(
          PolicyHolderActions.deletePolicyHolder({
            policyHolder: {
              ...person.member,
              policyHolderId: person.policyHolderId,
              productType: digest.productType,
            },
            correlationId,
          }),
          PolicyHolderActions.deletePolicyHolderSuccess,
          PolicyHolderActions.deletePolicyHolderError
        );
      }

      case 'unlistPolicyHolder': {
        const person = digest.people.find(
          (m) => m.entityId === change.entityId
        );
        if (person) {
          const policyRole = person.member.policyRoles.find(
            (r) =>
              r.productType === digest.productType &&
              r.entityType === 'policyHolder'
          );
          if (policyRole) {
            this.store.dispatch(MemberActions.removePolicyRole({ policyRole }));
          }
        }
        return Promise.resolve();
      }

      default:
        return Promise.reject(
          `Unimplemented PH role action ${JSON.stringify(change.action)}`
        );
    }
  }

  private actionsAsPromise(
    trigger: Action,
    success: ActionCreator,
    failure: ActionCreator,
  ): Promise<void> {
    type MyAction = { correlationId: string, type: string };
    return new Promise((resolve, reject) => {
      this.actions$.pipe(
        ofType(success, failure),
        map(action => action as MyAction),
        filter(action => action.correlationId === (trigger as MyAction).correlationId),
        take(1),
      ).subscribe((result) => {
        if (result.type === success.type) {
          resolve();
        } else {
          reject();
        }
      });
      this.store.dispatch(trigger);
    });
  }
}
