import { Injectable } from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { DriverRequest } from '../models/api/request/driver-request.model';
import { DriverEntity, sanitizeDriverEntity } from '../store/entities/driver/driver.entity';
import { DsmAdapter, responseUnused } from './dsm.adapter';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DriverAdapter extends DsmAdapter {
  addDriver(request: DriverRequest): Observable<DriverEntity> {
    return this.request(
      sanitizeDriverEntity,
      request.productType as ProductType,
      'post',
      `/quotes/${request.quoteId}/drivers`,
      'add-driver',
      {
        body: {
          driver: {
            ...request.driver,
            driverId: undefined,
            entityId: undefined as unknown,
          } as DriverEntity,
        },
      }
    );
  }

  updateDriver(request: DriverRequest): Observable<DriverEntity> {
    return this.request(
      sanitizeDriverEntity,
      request.productType as ProductType,
      'put',
      `/quotes/${request.quoteId}/drivers/${request.driver.driverId}`,
      'update-driver',
      {
        body: {
          driver: {
            ...request.driver,
            entityId: undefined as unknown,
          } as DriverEntity,
        },
      }
    );
  }

  deleteDriver(request: DriverRequest): Observable<unknown> {
    return this.request(
      responseUnused,
      request.productType as ProductType,
      'delete',
      `/quotes/${request.quoteId}/drivers/${request.driver.driverId}`,
      'delete-driver'
    );
  }
}
