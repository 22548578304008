import { Injectable } from '@angular/core';

import {
  createClient,
  Entry,
  EntryCollection,
  ContentfulClientApi,
} from 'contentful';

import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  constructor(private appConfig: AppConfigService) {}

  getEntries(
    contentType: string,
    query?: Record<string, unknown>,
    isPreview?: boolean
  ): Promise<Entry<any>[]> {
    return this.getClient(isPreview || false)
      .getEntries(
        Object.assign(
          {
            content_type: contentType,
          },
          query
        )
      )
      .then((response: EntryCollection<any>) => response.items);
  }

  getMostRecentEntry(contentType: string): Promise<Entry<any>> {
    return this.getEntries(contentType).then((entries) => {
      const recent = entries.sort((a, b) => {
        const updatedAtA = new Date(a.sys.updatedAt);
        const updatedAtB = new Date(b.sys.updatedAt);
        const result = updatedAtB.getTime() - updatedAtA.getTime();
        return result;
      })?.[0];
      return recent;
    });
  }

  private getClient(isPreview: boolean): ContentfulClientApi {
    const options: any = {
      space: this.appConfig.config.contentfulSpaceId,
      environment: this.appConfig.config.contentfulEnvironment,
    };
    if (isPreview) {
      options['accessToken'] =
        this.appConfig.config.contentfulPreviewAccessToken;
      options['host'] = 'preview.contentful.com';
    } else {
      options['accessToken'] = this.appConfig.config.contentfulAccessToken;
    }
    return createClient(options);
  }
}
