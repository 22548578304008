import { issuePolicy } from './../store/entities/policy-issue/policy-issue.action';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PolicyIssueAdapter } from '@core/adapters/policy-issue.adapter';
import { PolicyIssueRequest } from '../models/api/request/policy-issue-request.model';
import { PolicyIssueResponse } from '../models/api/response/policy-issue-response.model';
import { Store } from '@ngrx/store';
import { CoreState } from '../store/reducers';
import { PolicyIssueActions } from '../store/actions';
import { ProductType } from '../models/api/dsm-types';

@Injectable({
  providedIn: 'root',
})
export class PolicyIssueService {
  constructor(
    private policyIssueAdapter: PolicyIssueAdapter,
    private store: Store
  ) {}

  policies(request: PolicyIssueRequest): Observable<PolicyIssueResponse> {
    return this.policyIssueAdapter.policies(request);
  }

  issuePolicyForProduct(product: ProductType): void {
    this.store.dispatch(PolicyIssueActions.issuePolicyForProduct({ product }));
  }
}
