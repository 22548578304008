import { Reducers } from '@core/constants/reducers';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromGetStarted from '../models/get-started/get-started.reducer';
import * as fromCoverage from '../entities/coverage/coverage.reducer';
import * as fromBillingPlans from '../models/billing-plans/billing-plans.reducer';
import * as fromConfirmations from '../../../core/store/entities/confirmations/confirmations.reducer';
import * as fromMultiProductDiscounts from '../models/multi-product-discounts-form/multi-product-discounts-form.reducer';
import * as fromAccountRegistrationForm from '../models/account-registration/account-registration.reducer';
export interface FormsState {
  billingPlans: fromBillingPlans.BillingPlansState;
  confirmations: fromConfirmations.ConfirmationsState;
  coverages: fromCoverage.CoverageFormState;
  getStarted: fromGetStarted.GetStartedState;
  multiProductDiscountsForm: fromMultiProductDiscounts.MultiProductDiscountsFormModel;
  accountRegistrationInfo: fromAccountRegistrationForm.AccountRegistrationInfoFormModel;
}

export const reducers: ActionReducerMap<FormsState> = {
  billingPlans: fromBillingPlans.reducer,
  confirmations: fromConfirmations.reducer,
  coverages: fromCoverage.reducer,
  getStarted: fromGetStarted.reducer,
  multiProductDiscountsForm: fromMultiProductDiscounts.reducer,
  accountRegistrationInfo: fromAccountRegistrationForm.reducer,
};

export const getFormsState = createFeatureSelector<FormsState>(Reducers.Forms);
