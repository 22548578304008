import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { NextStep } from '@core/services/up-next.service';
import { Nullable } from '@shared/utils/type.utils';
import { ComponentChanges } from '@shared/utils/general.utils';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'nwx-next-step-header',
  templateUrl: './next-step-header.component.html',
  styleUrls: ['./next-step-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NextStepHeaderComponent implements OnChanges {
  @Input() nextStep!: Nullable<NextStep>;
  @Input() showTasks!: boolean;
  @Input() maxSizeMedium!: Nullable<boolean>;
  @Output() clickEvent = new EventEmitter();

  buttonReady$ = new BehaviorSubject(true);

  constructor() {}

  ngOnChanges(changes: ComponentChanges<NextStepHeaderComponent>): void {
    if (changes.nextStep) {
      this.buttonReady$.next(changes.nextStep.currentValue?.buttonReady ?? true);
    }
  }

  getButtonAttribute(attribute: string): string {
    switch (attribute) {
      case 'type':
        return this.maxSizeMedium
          ? this.nextStep?.isTelematicsStep
            ? 'tertiary'
            : 'primary'
          : 'primary';
      case 'size':
        return this.maxSizeMedium
          ? this.nextStep?.displayInCondensedView
            ? 'sm'
            : 'md'
          : 'md';
      default:
        return '';
    }
  }
}
