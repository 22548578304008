import { ProducerUtils } from '@shared/utils/producer.utils';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, interval, Observable, Subject, throwError } from 'rxjs';
import { filter, map, take, takeUntil, switchMap } from 'rxjs/operators';
import { BillingAdapter } from '../adapters/billing.adapter';
import { DownPaymentExperienceRequest } from '../models/api/request/down-payment-experience-request.model';
import {
  AccountHolder,
  EmailPreference,
  Payer,
  PaymentSetupAccount,
  PaymentTransaction,
  SetupAccountExperience,
  SetupAccountExperienceRequest,
  TextingPreference,
} from '../models/api/request/setup-account-experience-request.model';
import {
  DownPaymentExperienceResponse,
  EligiblePayplansAndPayMethods,
} from '../models/api/response/down-payment-experience-response.model';
import { SetupAccountExperienceResponse } from '../models/api/response/setup-account-experience-response.model';
import { BillingActions } from '../store/actions';
import { PaymentFormModel } from '@app/billing-payment/components/payment-form/payment-form.model';
import { BankNameResponseModel } from '../models/api/response/bank-name-response.model';
import { ProductsService } from './products.service';
import {
  DocumentDeliveryType,
  ProductType,
  Role,
} from '../models/api/dsm-types';
import { EscrowService } from './escrow.service';
import { EscrowAccountStatus } from '../models/entities/escrow-account.entity';
import * as TelematicsSelectors from '@core/store/entities/telematics/telematics.selector';
import * as BillingSelectors from '@core/store/entities/billing/billing.selector';
import * as BillingFormSelectors from '@forms-store/store/models/billing-plans/billing-plans.selector';
import * as BillingAccountSelectors from '@core/store/entities/billing-account/billing-account.selector';
import * as UserSelectors from '@core/store/entities/user/user.selector';
import * as MemberSelectors from '@core/store/entities/member/member.selector';
import { BillingAccountEntity } from '../store/entities/billing-account/billing-account.reducer';
import { ProductModel } from '../store/entities/product/product.model';
import {
  createBillingAccountIfAbsent,
  removeBillingAccountErrors,
} from '../store/entities/billing-account/billing-account.action';
import { PolicyNumberService } from './policy-number.service';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { PolicyIssueService } from './policy-issue.service';
import { LogService } from './log.service';
import { ErrorMessageService } from './error-message.service';
import { PremiumService } from './premium.service';
import { PremiumEntity } from '../store/entities/premium/premium.entity';
import { BillingPlansFormModel } from '@app/billing-payment/components/billing-plans-form/billing-plans-form.model';
import { DateUtils } from '@shared/utils/date.utils';
import { suffixConstants } from '@shared/constants/suffix-constants';
import { BankCardUtils } from '@shared/utils/bank-card.utils';
import { PolicyHolderEntity } from '../store/entities/policyholder/policyholder.entity';
import { Nullable } from '@shared/utils/type.utils';
import { EligibleDiscountsService } from './eligible-discounts.service';
import { VehicleService } from '@core/services/vehicle.service';
import { TelematicsService } from './telematics.service';
import { MortgageService } from '@core/services/mortgage.service';
import { AmfBankAccountsResponse } from '@core/models/api/response/amf-bank-accounts-response.moel';
import {
  getAmfBankAccountsFailed,
  getAmfBankAccountsLoaded,
  getAmfBankAccountsLoading,
  hasAgencySweepBankAccount,
} from '@entities/amf-bank-accounts/amf-bank-accounts.selector';
import { loadAmfBankAccounts } from '@entities/amf-bank-accounts/amf-bank-accounts.action';

// private; exported only for specs
export interface BillingPurchaseRequirements {
  escrowStatus: EscrowAccountStatus;
  productsByBillingAccount: ProductsByBillingAccount[];
  products: ProductModel[];
}

export interface ProductsByBillingAccount {
  billingAccountType: 'billing' | 'escrow';
  productIds: ProductType[];
}

export interface BillingInvoiceAllProducts {
  totalPayment: number;
  downPayment: number;
  monthlyPayment: number;
}

export interface BillingInvoiceProduct {
  productType: ProductType;
  totalPayment: number;
  downPayment: number;
  monthlyPayment: number;
  termLength: number;
}

export interface BillingInvoiceFee {
  description: string;
  totalPayment: number;
  downPayment: number;
  monthlyPayment: number;
}

export interface BillingInvoiceAccount {
  billingAccountType: 'billing' | 'escrow';
  totalPayment: number;
  downPayment: number;
  monthlyPayment: number;
  products: BillingInvoiceProduct[];
  fees: BillingInvoiceFee[];
}

export interface BillingInvoice {
  allProducts: BillingInvoiceAllProducts;
  accounts: BillingInvoiceAccount[];
  containsPolicyFee: boolean;
  downPaymentsUnavailable: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(
    private billingAdapter: BillingAdapter,
    private store: Store,
    private productsService: ProductsService,
    private escrowService: EscrowService,
    private mortgageService: MortgageService,
    private policyNumberService: PolicyNumberService,
    private policyIssueService: PolicyIssueService,
    private logService: LogService,
    private errorMessageService: ErrorMessageService,
    private window: Window,
    private premiumService: PremiumService,
    private eligibleDiscountsService: EligibleDiscountsService,
    private vehicleService: VehicleService,
    private telematicsService: TelematicsService
  ) {}

  downPaymentExperience(
    request: DownPaymentExperienceRequest
  ): Observable<DownPaymentExperienceResponse> {
    return this.billingAdapter.downPaymentExperience(request);
  }

  dispatchLoadDownPayment(): void {
    this.store.dispatch(BillingActions.loadDownPayment());
  }

  loadDownPaymentIfAllProductsRated(): void {
    this.store.dispatch(BillingActions.loadDownPaymentIfAllProductsRated());
  }

  dispatchSetupAccount(form: PaymentFormModel): void {
    this.store.dispatch(BillingActions.setupAccount({ form }));
  }

  getDownPaymentLoaded(): Observable<boolean> {
    return this.store.select(BillingSelectors.getDownPaymentLoaded);
  }

  getDownPaymentLoading(): Observable<boolean> {
    return this.store.select(BillingSelectors.getDownPaymentLoading);
  }

  getDownPaymentFailed(): Observable<boolean> {
    return this.store.select(BillingSelectors.getDownPaymentFailed);
  }

  getBillingPlan(): Observable<string> {
    return this.store.select(BillingFormSelectors.getBillingPlan);
  }

  getDownPayment(): Observable<DownPaymentExperienceResponse> {
    return this.store.select(BillingSelectors.getDownPayment);
  }

  getDownPaymentForProducts(
    products: ProductModel[]
  ): Observable<DownPaymentExperienceResponse | undefined> {
    return this.store.select(
      BillingSelectors.getDownPaymentForProducts(products)
    );
  }

  getAllDownPayments(): Observable<DownPaymentExperienceResponse[]> {
    return this.store.select(BillingSelectors.getAllDownPayments);
  }

  unsetDownPaymentFailed(): void {
    this.store.dispatch(BillingActions.unsetDownPaymentFailed());
  }

  /**
   * Emits true when the state of downPayment calls is settled.
   * Throws an error if not loaded and not loading (ie state will never be settled, without further action).
   * Uses an internal timeout to allow other effects to trigger.
   */
  waitForDownPayment(): Observable<boolean> {
    const TIMEOUT = 1000;
    return interval(TIMEOUT).pipe(
      take(1),
      switchMap(() =>
        combineLatest([
          this.getDownPaymentLoading(),
          this.getDownPaymentFailed(),
          this.getDownPaymentLoaded(),
        ])
      ),
      map(([loading, failed, loaded]) => {
        if (loading) {
          return false;
        }
        if (failed) {
          throw new Error('downPayment failed');
        }
        if (loaded) {
          return true;
        }
        throw new Error(
          'Billing information incomplete. Please return to Finalize Quote page and continue again.'
        );
      })
    );
  }

  refreshDownPaymentAndWait(): Observable<boolean> {
    this.store.dispatch(BillingActions.clearDownPayment());
    this.dispatchLoadDownPayment();
    return this.waitForDownPayment();
  }

  setupAccount(
    request: SetupAccountExperienceRequest | null
  ): Observable<SetupAccountExperienceResponse> {
    if (!request) {
      return throwError(`unable to generate billing request`);
    }
    return this.billingAdapter.setupAccount(request);
  }

  getBankName(routingNumber: string): Observable<BankNameResponseModel> {
    return this.billingAdapter.getBankName(routingNumber);
  }

  dispatchBillingComplete(): void {
    this.store.dispatch(BillingActions.billingComplete());
  }

  adjustDownPaymentCallCount(d: number): void {
    this.store.dispatch(BillingActions.adjustDownPaymentCallCount({ d }));
  }

  /**
   * Returns one entry for each billing account that ought to be set up.
   * Up to three: Escrow, regular Billing, Auto FullPay Billing.
   */
  getProductsByBillingAccount(): Observable<ProductsByBillingAccount[]> {
    return combineLatest([
      this.productsService.getSelectedProducts(),
      this.escrowService.isEscrowSelectedAsPaymentMethod(),
      this.store.select(BillingFormSelectors.getBillingPlansForm),
      this.eligibleDiscountsService.getProductTypesWithDiscount(
        'BillingPaymentMethod'
      ),
      this.eligibleDiscountsService.getProductTypesWithDiscount('PaidInFull'),
    ]).pipe(
      map(
        ([
          products,
          escrowSelected,
          billingForm,
          bpmDiscountProducts,
          pifDiscountProducts,
        ]) => {
          const accounts: ProductsByBillingAccount[] = [];

          if (escrowSelected) {
            const propertyProducts =
              products?.filter(
                (product) =>
                  product.type === 'Homeowner' || product.type === 'Condominium'
              ) || [];
            if (propertyProducts.length) {
              accounts.push({
                billingAccountType: 'escrow',
                productIds: propertyProducts.map((product) => product.type),
              });
              products = products.filter(
                (product) => propertyProducts.indexOf(product) < 0
              );
            }
          }

          if (billingForm?.billingPlan === 'PAY IN FULL') {
            const combinableProducts = [];
            for (const product of products) {
              if (bpmDiscountProducts.includes(product.type)) {
                accounts.push({
                  billingAccountType: 'billing',
                  productIds: [product.type],
                });
              } else if (pifDiscountProducts.includes(product.type)) {
                accounts.push({
                  billingAccountType: 'billing',
                  productIds: [product.type],
                });
              } else {
                combinableProducts.push(product);
              }
            }
            products = combinableProducts;
          }

          if (products?.length) {
            accounts.push({
              billingAccountType: 'billing',
              productIds: products.map((product) => product.type),
            });
          }

          return accounts;
        }
      )
    );
  }

  purchase(form: PaymentFormModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.store.dispatch(removeBillingAccountErrors());
      this.gatherPurchaseRequirements()
        .pipe(take(1))
        .subscribe({
          next: (requirements) => {
            this.beginPurchase(form, requirements);
            this.awaitPurchaseResult(requirements, resolve, reject);
          },
          error: (error) => reject(error),
        });
    });
  }

  /**
   * Generates line items and totals, the amount that we propose to collect given current state.
   * Views and the setupAccount request (and anything else dealing with payment) should use this output.
   * Connecting downPayment responses to selected products and payment methods is not as simple as it sounds.
   * Don't try to do this yourself.
   */
  generateInvoice(): Observable<BillingInvoice> {
    return combineLatest([
      this.getProductsByBillingAccount(),
      this.productsService.getSelectedProducts(),
      this.getAllDownPayments(),
      this.premiumService.getAllPremiums(),
      this.store.select(BillingFormSelectors.getBillingPlansForm),
    ]).pipe(
      map(
        ([
          productsByBillingAccount,
          products,
          downPayments,
          premiums,
          billingPlansForm,
        ]) => {
          const invoice: BillingInvoice = {
            allProducts: {
              totalPayment: 0,
              downPayment: 0,
              monthlyPayment: 0,
            },
            accounts: [],
            containsPolicyFee: false,
            downPaymentsUnavailable: this.determineDownPaymentsUnavailable(
              downPayments,
              productsByBillingAccount
            ),
          };
          for (const pbb of productsByBillingAccount) {
            this.addAccountToInvoice(
              invoice,
              pbb,
              products,
              downPayments,
              premiums,
              billingPlansForm
            );
          }
          this.scrubFloatsOnInvoice(invoice);
          return invoice;
        }
      )
    );
  }

  private determineDownPaymentsUnavailable(
    downPayments: DownPaymentExperienceResponse[],
    productsByBillingAccount: ProductsByBillingAccount[]
  ): boolean {
    // If there's just one account and it's escrow, we are an edge case where zero is the correct answer.
    if (
      productsByBillingAccount.length === 1 &&
      productsByBillingAccount[0].billingAccountType === 'escrow'
    ) {
      return false;
    }
    // In any other case (even "no accounts"), it's driven off downPayments.
    return downPayments.length === 0;
  }

  /* We do a bunch of floating-point arithmetic in generating the invoice.
   * Here, we rewrite it in place and round everything to 1/100.
   * (eg 123.45 + 543.21 = 666.6600000000001, without intervention).
   * */
  private scrubFloatsOnInvoice(invoice: BillingInvoice): void {
    invoice.allProducts.totalPayment =
      Math.round(invoice.allProducts.totalPayment * 100) / 100;
    invoice.allProducts.downPayment =
      Math.round(invoice.allProducts.downPayment * 100) / 100;
    invoice.allProducts.monthlyPayment =
      Math.round(invoice.allProducts.monthlyPayment * 100) / 100;

    for (const account of invoice.accounts) {
      account.totalPayment = Math.round(account.totalPayment * 100) / 100;
      account.downPayment = Math.round(account.downPayment * 100) / 100;
      account.monthlyPayment = Math.round(account.monthlyPayment * 100) / 100;

      for (const product of account.products) {
        product.totalPayment = Math.round(product.totalPayment * 100) / 100;
        product.downPayment = Math.floor(product.downPayment * 100) / 100;
        product.monthlyPayment = Math.floor(product.monthlyPayment * 100) / 100;
      }
      for (const fee of account.fees) {
        fee.totalPayment = Math.round(fee.totalPayment * 100) / 100;
        fee.downPayment = Math.round(fee.downPayment * 100) / 100;
        fee.monthlyPayment = Math.round(fee.monthlyPayment * 100) / 100;
      }
    }
  }

  private addAccountToInvoice(
    invoice: BillingInvoice,
    pbb: ProductsByBillingAccount,
    products: ProductModel[],
    downPayments: DownPaymentExperienceResponse[],
    premiums: PremiumEntity[],
    billingPlansForm: BillingPlansFormModel
  ): void {
    const account: BillingInvoiceAccount = {
      billingAccountType: pbb.billingAccountType,
      totalPayment: 0,
      downPayment: 0,
      monthlyPayment: 0,
      products: [],
      fees: [],
    };
    const calculatePerProductMonthly =
      billingPlansForm.billingPlan !== 'PAY IN FULL';
    for (const productId of pbb.productIds) {
      const premium = premiums.find((p) => p.productType === productId);
      const invoiceProduct: BillingInvoiceProduct = {
        productType: productId,
        totalPayment: premium?.total?.amount || 0,
        downPayment: 0,
        monthlyPayment: 0,
        termLength: premium?.termMonths || 0,
      };
      if (premium?.total && premium?.termMonths && calculatePerProductMonthly) {
        invoiceProduct.monthlyPayment =
          premium.total.amount / premium.termMonths;
      }
      account.products.push(invoiceProduct);
    }
    if (pbb.billingAccountType === 'escrow') {
      this.addEscrowToBillingInvoiceAccount(account);
    } else {
      this.addDownPaymentToBillingInvoiceAccount(
        invoice,
        account,
        downPayments,
        products,
        pbb.productIds,
        billingPlansForm,
        premiums
      );
    }
    invoice.accounts.push(account);
    invoice.allProducts.totalPayment += account.totalPayment;
    invoice.allProducts.downPayment += account.downPayment;
    invoice.allProducts.monthlyPayment += account.monthlyPayment;
  }

  private addEscrowToBillingInvoiceAccount(
    account: BillingInvoiceAccount
  ): void {
    account.totalPayment = account.products.reduce(
      (a, v) => a + v.totalPayment,
      0
    );
  }

  private addFeesToBillingInvoiceAccount(
    invoice: BillingInvoice,
    account: BillingInvoiceAccount,
    products: ProductType[],
    premiums: PremiumEntity[],
    fullPay: boolean
  ): void {
    for (const productId of products) {
      const premium = premiums.find((p) => p.productType === productId);
      if (premium?.fees?.amount) {
        invoice.containsPolicyFee = true;
        account.fees.push({
          description: 'Policy fee',
          downPayment: premium.fees.amount,
          monthlyPayment: 0,
          totalPayment: premium.fees.amount,
        });
        if (!fullPay) {
          account.totalPayment += premium.fees.amount;
        }
      }
    }
  }

  private addDownPaymentToBillingInvoiceAccount(
    invoice: BillingInvoice,
    account: BillingInvoiceAccount,
    downPayments: DownPaymentExperienceResponse[],
    products: ProductModel[],
    productIds: ProductType[],
    billingPlansForm: BillingPlansFormModel,
    premiums: PremiumEntity[]
  ): void {
    products = productIds
      .map((id) => products.find((product) => product.type === id))
      .filter((p) => !!p) as ProductModel[];
    if (products.length !== productIds.length) {
      return;
    }
    const downPayment = downPayments.find((dp) => {
      if (dp.policyLevel.length !== products.length) {
        return false;
      }
      for (const pl of dp.policyLevel) {
        const product = products.find(
          (p) =>
            p.quoteId === pl.displayPolicyNumber ||
            p.policyNumber === pl.displayPolicyNumber
        );
        if (!product) {
          return false;
        }
      }
      return true;
    });
    if (!downPayment) {
      return;
    }
    const payPlan = this.selectPayPlan(downPayment, billingPlansForm);
    if (!payPlan) {
      return;
    }
    const termLength = account.products[0].termLength;
    if (billingPlansForm.billingPlan === 'PAY IN FULL') {
      account.monthlyPayment = 0;
      account.totalPayment = this.billingAmountAsNumber(payPlan.fullPayAmount);
      account.downPayment = account.totalPayment;
      this.addFeesToBillingInvoiceAccount(
        invoice,
        account,
        productIds,
        premiums,
        true
      );
    } else {
      account.downPayment = this.billingAmountAsNumber(
        payPlan.totalDownPaymentWithFees
      );
      account.monthlyPayment = this.billingAmountAsNumber(
        payPlan.monthlyInstallment
      );
      account.totalPayment = account.products.reduce(
        (a, v) => a + v.totalPayment,
        0
      );
      this.addFeesToBillingInvoiceAccount(
        invoice,
        account,
        productIds,
        premiums,
        false
      );
    }

    if (billingPlansForm.billingPlan !== 'PAY IN FULL') {
      const downPaymentFee = this.billingAmountAsNumber(payPlan.downPaymentFee);
      const monthlyFee = this.billingAmountAsNumber(payPlan.monthlyFees);
      if (downPaymentFee || monthlyFee) {
        const total = downPaymentFee + monthlyFee * (termLength - 1);
        account.fees.push({
          description: 'Installment fee',
          downPayment: downPaymentFee,
          monthlyPayment: monthlyFee,
          totalPayment: total,
        });
        account.totalPayment += total;
      }
    }
  }

  private selectPayPlan(
    downPayment: DownPaymentExperienceResponse,
    billingPlansForm: BillingPlansFormModel
  ): EligiblePayplansAndPayMethods | undefined {
    const plans = downPayment.eligiblePayplansAndPayMethods;
    switch (billingPlansForm.billingPlan) {
      case 'PAY IN FULL': {
        const monthlyDirects = plans.filter(
          (pp) => pp.payMethod === 'MONTHLY DIRECT'
        );
        // NB: "FULL PAYMENT PLAN" if present, otherwise "COMBINED MONTHLY". Don't combine these conditions.
        return (
          monthlyDirects.find((pp) => pp.payPlan === 'FULL PAYMENT PLAN') ||
          monthlyDirects.find((pp) => pp.payPlan === 'COMBINED MONTHLY')
        );
      }
      case 'MONTHLY DIRECT':
      case 'MONTHLY EFT':
      case 'MONTHLY RECURRING BANKCARD': {
        return plans.find(
          (pp) =>
            pp.payMethod === billingPlansForm.billingPlan &&
            pp.payPlan === 'COMBINED MONTHLY'
        );
      }
    }
    return undefined;
  }

  private billingAmountAsNumber(input: string): number {
    if (!input) {
      return 0;
    }
    // NB it's not just spaces, commas too.
    const dotsAndDigits = input.replace(/[^0-9.]/g, '');
    return +dotsAndDigits || 0;
  }

  private beginPurchase(
    form: PaymentFormModel,
    requirements: BillingPurchaseRequirements
  ): void {
    for (const pbb of requirements.productsByBillingAccount) {
      switch (pbb.billingAccountType) {
        case 'billing':
          {
            this.store.dispatch(
              createBillingAccountIfAbsent({
                productTypes: pbb.productIds,
                form,
              })
            );
          }
          break;
        case 'escrow':
          {
            if (
              requirements.escrowStatus === 'success' ||
              requirements.escrowStatus === 'pending'
            ) {
              // Don't create a new escrow account; we already have one.
            } else {
              // If we get to a point where more than one property product is allowed this will need to change
              pbb.productIds.forEach((lineOfBusiness) =>
                this.escrowService.dispatchEstablishEscrowAccount(
                  lineOfBusiness
                )
              );
            }
          }
          break;
      }
    }
  }

  private awaitPurchaseResult(
    requirements: BillingPurchaseRequirements,
    resolve: () => void,
    reject: (cause: unknown) => void
  ): void {
    this.awaitEscrowAccount(requirements)
      .then(() => this.awaitBillingAccounts(requirements))
      .then(() => {
        this.issuePolicies(requirements);
        return this.awaitPolicies(requirements);
      })
      .then(resolve)
      .catch((error) => {
        this.reportAccountsOrphanedByPurchaseFailure();
        reject(error);
      });
  }

  private awaitEscrowAccount(
    requirements: BillingPurchaseRequirements
  ): Promise<void> {
    const productsByBillingAccount = requirements.productsByBillingAccount.find(
      (bpp) => bpp.billingAccountType === 'escrow'
    );
    if (!productsByBillingAccount) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      const unsubscribe = new Subject<void>();
      this.escrowService
        .getEscrowStatus()
        .pipe(takeUntil(unsubscribe))
        .subscribe((status) => {
          if (status === 'error') {
            unsubscribe.next();
            unsubscribe.complete();
            reject();
            return;
          }
          if (status === 'success') {
            unsubscribe.next();
            unsubscribe.complete();
            resolve();
            return;
          }
        });
    });
  }

  private awaitBillingAccounts(
    requirements: BillingPurchaseRequirements
  ): Promise<void> {
    const total = requirements.productsByBillingAccount.filter(
      (bpp) => bpp.billingAccountType === 'billing'
    ).length;
    if (total < 1) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      const unsubscribe = new Subject<void>();
      this.store
        .select(BillingAccountSelectors.getAllBillingAccounts)
        .pipe(takeUntil(unsubscribe))
        .subscribe((accounts) => {
          if (accounts.length < total) {
            return;
          }
          if (accounts.find((a) => a.status === 'pending')) {
            return;
          }
          if (accounts.find((a) => a.status === 'error')) {
            unsubscribe.next();
            unsubscribe.complete();
            reject();
            return;
          }
          unsubscribe.next();
          unsubscribe.complete();
          resolve();
        });
    });
  }

  private issuePolicies(requirements: BillingPurchaseRequirements): void {
    for (const product of requirements.products) {
      this.policyIssueService.issuePolicyForProduct(product.type);
    }
  }

  private awaitPolicies(
    requirements: BillingPurchaseRequirements
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const unsubscribe = new Subject<void>();
      this.productsService
        .getSelectedProducts()
        .pipe(takeUntil(unsubscribe))
        .subscribe((products) => {
          if (products.every((p) => p.quoteStatus === 'Issued')) {
            unsubscribe.next();
            unsubscribe.complete();
            resolve();
            return;
          }
          if (products.find((p) => p.quoteStatus === 'Pending')) {
            return;
          }
          unsubscribe.next();
          unsubscribe.complete();
          reject();
        });
    });
  }

  /**
   * Check for any Billing or Escrow accounts and issue a loud, unique warning if found.
   * These are accounts created but no policy issued.
   * The Billing and Escrow APIs do not allow us to delete or nullify accounts once created.
   * Called just before terminating, if any error occurs during purchase().
   */
  private reportAccountsOrphanedByPurchaseFailure(): void {
    combineLatest([
      this.escrowService.getEscrowStatus(),
      this.store.select(BillingAccountSelectors.getAllBillingAccounts),
    ])
      .pipe(take(1))
      .subscribe(([escrowStatus, accounts]) => {
        if (escrowStatus === 'success') {
          this.reportOrphanedEscrowAccount();
        }
        const orphanedAccounts = accounts.filter((a) => a.status === 'ok');
        if (orphanedAccounts.length) {
          this.reportOrphanedBillingAccounts(orphanedAccounts);
        }
      });
  }

  private reportOrphanedEscrowAccount(): void {
    const message = `Escrow account was established but policy was not issued due to other errors.`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.window as any).console.warn(message);
    this.logService.logBusinessEvent('orphaned-escrow-account', message);
  }

  private reportOrphanedBillingAccounts(
    accounts: BillingAccountEntity[]
  ): void {
    const productList = accounts
      .reduce((a, v) => [...a, ...v.productIds], [] as ProductType[])
      .join(',');
    const accountNumberList = accounts.map((a) => a.accountNumber).join(',');
    const message = `Billing accounts (${accountNumberList}) for products (${productList}) were created but the products not issued, due to other errors.`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.window as any).console.warn(message);
    this.logService.logBusinessEvent('orphaned-billing-account', message);
  }

  /**
   * Stalls until policy numbers are generated (PolicyNumberService does its own aggregation and orchestration).
   */
  private gatherPurchaseRequirements(): Observable<BillingPurchaseRequirements> {
    return combineLatest([
      this.policyNumberService.generatePolicyNumberIfAbsent(),
      this.escrowService.getEscrowStatus(),
      this.mortgageService.hasMortgage(),
      this.getProductsByBillingAccount(),
      this.productsService.getSelectedProducts(),
      this.store.select(TelematicsSelectors.isSmartMilesEnrolled),
      this.store.select(BillingFormSelectors.getBillingPlansForm),
    ]).pipe(
      map(
        ([
          policyNumbersAcquired,
          escrowStatus,
          hasMortgage,
          productsByBillingAccount,
          products,
          isSmartMilesEnrolled,
          billingPlansForm,
        ]) => {
          if (!policyNumbersAcquired) {
            // If generating policy numbers fails, generatePolicyNumberIfAbsent() throws, we don't stall.
            return null;
          }
          // Not really our problem, but this is a convenient place for final safety checks.
          // If SmartMiles is enrolled and the payment method is non-recurring, we must abort.
          // (that's not supposed to be possible, but enforcement is spotty elsewhere).
          if (isSmartMilesEnrolled) {
            switch (billingPlansForm.billingPlan) {
              case 'MONTHLY EFT':
              case 'MONTHLY RECURRING BANKCARD':
                break;
              default: {
                const message = `Payment method '${billingPlansForm.billingPlan}' incompatible with SmartMiles.`;
                this.errorMessageService.addError({
                  displayMessage: message,
                });
                throw new Error(message);
              }
            }
          }

          // If they somehow got here with a product not ready, fail hard.
          // (like SmartMiles, this is not our problem, just a safety catch).
          const faultyProduct = products.find(
            (product) => product.quoteStatus !== 'Binding'
          );
          if (faultyProduct) {
            throw new Error(
              `Product ${faultyProduct.type} not in Binding status. Please re-rate.`
            );
          }

          // Likewise, if there's an escrow account, there must be a mortgage.
          if (
            productsByBillingAccount.find(
              (pbb) => pbb.billingAccountType === 'escrow'
            )
          ) {
            if (!hasMortgage) {
              throw new Error(`Mortgage required for escrow payment.`);
            }
          }

          return {
            escrowStatus,
            productsByBillingAccount,
            products,
          };
        }
      ),
      filterOutNull(),
      switchMap((results) =>
        this.waitForDownPayment().pipe(
          filter((loaded) => loaded),
          map(() => results)
        )
      )
    );
  }

  buildSetupAccountExperienceRequest(
    form: PaymentFormModel,
    products: ProductModel[]
  ): Observable<SetupAccountExperienceRequest | null> {
    return combineLatest([
      this.generateInvoice(),
      this.store.select(BillingFormSelectors.getBillingPlansForm),
      this.store.select(BillingSelectors.getAgent),
      this.store.select(UserSelectors.getEffectiveUserRole),
      this.store.select(MemberSelectors.getPrimaryNamedInsuredForMultipleProducts(
        products.map(p => p.type)
      )),
      this.getDownPaymentForProducts(products),
      this.productsService.getDocumentDeliveryPreference(),
    ]).pipe(
      map(
        ([
          invoice,
          billingPlansForm,
          agent,
          role,
          policyholder,
          downPayment,
          docDeliveryPreference,
        ]) => {
          const invoiceAccount = this.billingInvoiceAccountForProducts(
            invoice,
            products
          );
          if (!invoiceAccount || !downPayment) {
            return null;
          }
          const body: SetupAccountExperience = {
            dayofMonthDue:
              billingPlansForm.monthlyPaymentDay || this.defaultDayOfMonth(),
            accountPayMethod: this.getBillingAccountPaymentMethod(
              billingPlansForm,
              downPayment,
              form
            ),
            payPlan: this.getPayPlan(billingPlansForm, downPayment),
            producerNumber:
              '00' + ProducerUtils.extractProducerNumberForBilling(agent),
            producerState: agent.agent.agentState || '',
            distributionChannel: this.getDistributionChannel(role),
            accountHolder: this.buildAccountHolder(form),
            emailPreference: this.buildEmailPreference(
              policyholder?.emailAddress,
              docDeliveryPreference
            ),
            textingPreference: this.buildTextingPreference(
              policyholder?.homeNumber
            ),
            payment: this.buildPayment(
              invoiceAccount,
              form,
              role,
              policyholder
            ),
            policiesAndQuotes: products.map((p) => ({
              policyNumber: p.policyNumber || '',
              quoteId: p.quoteId || '',
            })),
          };
          return { body };
        }
      )
    );
  }

  private billingInvoiceAccountForProducts(
    invoice: BillingInvoice,
    products: ProductModel[]
  ): BillingInvoiceAccount | null {
    for (const account of invoice.accounts) {
      if (account.billingAccountType !== 'billing') {
        continue;
      }
      if (account.products.length !== products.length) {
        continue;
      }
      let ok = true;
      for (const accountProduct of account.products) {
        if (
          !products.find(
            (product) => product.type === accountProduct.productType
          )
        ) {
          ok = false;
          break;
        }
      }
      if (!ok) {
        continue;
      }
      return account;
    }
    return null;
  }

  private defaultDayOfMonth(): string {
    const today = DateUtils.getDay();
    return today.toString().padStart(2, '0');
  }

  private getBillingAccountPaymentMethod(
    billingPlansFormModel: BillingPlansFormModel,
    downPayment: DownPaymentExperienceResponse,
    paymentFormModel: PaymentFormModel
  ): string {
    if (
      billingPlansFormModel?.billingPlan === 'PAY IN FULL' &&
      paymentFormModel?.autoRenew
    ) {
      if (this.downPaymentResponseHasRecurringFullPayPlan(downPayment)) {
        switch (paymentFormModel.paymentType) {
          case 'bankAccount':
            return 'Recurring EFT';
          case 'creditCard':
            return 'Recurring BankCard';
        }
      }
    }
    switch (billingPlansFormModel?.billingPlan) {
      case 'MONTHLY RECURRING BANKCARD':
        return 'Recurring BankCard';
      case 'MONTHLY EFT':
        return 'Recurring EFT';
      default:
        return 'Direct Bill';
    }
  }

  private getPayPlan(
    billingPlansFormModel: BillingPlansFormModel,
    downPayment: DownPaymentExperienceResponse
  ): string {
    if (billingPlansFormModel.billingPlan === 'PAY IN FULL') {
      if (this.downPaymentResponseHasFullPaymentPlan(downPayment)) {
        return 'FULL PAYMENT PLAN';
      } else {
        return 'COMBINED MONTHLY';
      }
    } else {
      return 'COMBINED MONTHLY';
    }
  }

  private downPaymentResponseHasFullPaymentPlan(
    downPayment: DownPaymentExperienceResponse
  ): boolean {
    for (const plan of downPayment.eligiblePayplansAndPayMethods) {
      if (
        plan.payPlan === 'FULL PAYMENT PLAN' &&
        plan.payMethod === 'MONTHLY DIRECT'
      ) {
        return true;
      }
    }
    return false;
  }

  private downPaymentResponseHasRecurringFullPayPlan(
    downPayment: DownPaymentExperienceResponse
  ): boolean {
    for (const plan of downPayment.eligiblePayplansAndPayMethods) {
      if (
        plan.payPlan === 'FULL PAYMENT PLAN' &&
        (plan.payMethod === 'MONTHLY RECURRING BANKCARD' ||
          plan.payMethod === 'MONTHLY EFT')
      ) {
        return true;
      }
    }
    return false;
  }

  private getDistributionChannel(role: Role | undefined): string {
    if (role) {
      switch (role) {
        case 'EA':
          return 'EXCLUSIVE AGENT - FULL';
        case 'IA':
          return 'INDEPENDENT AGENT';
        case 'NSS':
        default:
          return 'NSS';
      }
    } else {
      return 'NSS';
    }
  }

  private buildAccountHolder(paymentForm: PaymentFormModel): AccountHolder {
    const accountHolder = {} as AccountHolder;
    accountHolder.name = {
      person: {
        firstName: paymentForm.name?.firstName || '',
        middleName: paymentForm.name?.middleName
          ? paymentForm.name.middleName.trim().charAt(0)
          : '',
        lastName: paymentForm.name?.lastName || '',
        suffix: this.getSuffix(paymentForm.name?.suffix),
      },
    };
    accountHolder.address = {
      optOutFromNCOAAddressUpdate: 'Y',
      domesticAddress: {
        addressCleansingBypassReason: 'Use customer preferred city',
        addressLine1: paymentForm?.address?.addressLine1 || '',
        addressLine2: paymentForm?.address?.addressLine2 || '',
        city: paymentForm.address?.city || '',
        state: paymentForm.address?.state || '',
        zip: paymentForm.address?.postalCode?.replace('-', '') || '',
      },
    };
    return accountHolder;
  }

  private getSuffix(suffix: string | undefined): string {
    const displaySuffix = suffixConstants.find(({ value }) => value === suffix);
    return displaySuffix ? displaySuffix.display : '';
  }

  private buildEmailPreference(
    emailAddress: Nullable<string>,
    docDeliveryPreference: Nullable<DocumentDeliveryType>
  ): EmailPreference {
    const emailPreference = {} as EmailPreference;
    const isPaperless = docDeliveryPreference === 'OnlineAccountAccess';
    emailPreference.consentStatus = 'Y';
    emailPreference.paperlessPreference = isPaperless ? 'EMAL' : 'UMAL';
    emailPreference.emailAddress = emailAddress || '';
    emailPreference.paymentReminderPreference = isPaperless ? 'Y' : 'N';
    emailPreference.paymentConfirmationPreference = isPaperless ? 'Y' : 'N';
    return emailPreference;
  }

  private buildTextingPreference(
    mobilePhoneNumber: Nullable<string>
  ): TextingPreference[] {
    const textingPreferences = [];
    const textingPref = {} as TextingPreference;
    textingPref.preferenceType = 'Payment Reminder Text';
    textingPref.preferenceValue = 'No';
    textingPref.deliveryMethod = 'BillingTextDelivery';
    textingPref.deliveryInformation = mobilePhoneNumber || '';
    textingPreferences.push(textingPref);
    textingPreferences.push({
      ...textingPref,
      preferenceType: 'Payment Notification Text',
    });
    return textingPreferences;
  }

  private buildPayment(
    invoice: BillingInvoiceAccount,
    paymentForm: PaymentFormModel,
    userRole: string | undefined,
    policyholder: Nullable<PolicyHolderEntity>
  ): PaymentSetupAccount {
    const payment: PaymentSetupAccount = {
      businessChannel: 'INTERNET-ELECTRONIC BILL',
      amount: invoice.downPayment.toFixed(2),
      paymentTransaction: this.buildPaymentTransaction(
        invoice,
        paymentForm,
        userRole
      ),
      payer: this.buildPayer(policyholder, paymentForm),
    };
    return payment;
  }

  private buildPaymentTransaction(
    invoice: BillingInvoiceAccount,
    paymentForm: PaymentFormModel,
    userRole: string | undefined
  ): PaymentTransaction {
    const paymentTrans = {} as PaymentTransaction;
    if (paymentForm.paymentType === 'agencySweep') {
      paymentTrans.paymentMethod = 'EZSWEEP';
      paymentTrans.ezSweep = {
        ezSweepCheckNumber:
          paymentForm?.agencySweep?.agencySweepPaymentType === 'cash'
            ? 'cash'
            : paymentForm?.agencySweep?.checkNumber,
      };
    } else if (paymentForm.paymentType === 'bankAccount') {
      paymentTrans.paymentMethod = 'electronicFunds';
      paymentTrans.electronicFunds = {
        bankName: paymentForm?.bankAccount?.bankName || '',
        bankRoutingNumber: paymentForm?.bankAccount?.routingNumber || '',
        bankAccountNumber: paymentForm?.bankAccount?.accountNumber || '',
        bankAccountType: 'CHECKING',
      };
    } else {
      if (userRole === 'NSS') {
        paymentTrans.paymentMethod = 'bankCard';
        paymentTrans.bankCard = {
          cardBrand: BankCardUtils.mapCardToCardType(
            paymentForm.bankCard?.cardType || ''
          ),
          expirationDate: DateUtils.buildPaymentCreditCardExpirationDate(
            paymentForm?.bankCard?.expYear,
            paymentForm?.bankCard?.expMonth
          ),
          ccLastFour: paymentForm.bankCard.cardLastFour,
          cardVerificationValue: paymentForm.bankCard.cvv,
          profileId: paymentForm.bankCard.profileId,
        };

        if (paymentTrans?.bankCard?.cardBrand === 'American Express') {
          paymentTrans.bankCard.cardVerificationValue =
            paymentForm?.bankCard?.cid;
        }
      } else {
        paymentTrans.paymentMethod = 'bankCardWithClearPan';
        paymentTrans.bankCardWithClearPan = {
          cardNumber: paymentForm.bankCard?.cardNumber,
          cardType: BankCardUtils.mapCardToCardType(
            paymentForm.bankCard?.cardType || ''
          ),
          cardVerificationValue: paymentForm?.bankCard?.cvv,
          expirationDate: DateUtils.buildPaymentCreditCardExpirationDate(
            paymentForm?.bankCard?.expYear,
            paymentForm?.bankCard?.expMonth
          ),
        };

        if (
          paymentTrans?.bankCardWithClearPan?.cardType === 'American Express'
        ) {
          paymentTrans.bankCardWithClearPan.cardVerificationValue =
            paymentForm?.bankCard?.cid;
        }
      }
    }
    return paymentTrans;
  }

  private buildPayer(
    policyholder: Nullable<PolicyHolderEntity>,
    paymentForm: PaymentFormModel
  ): Payer {
    const payer = {} as Payer;
    payer.firstName = paymentForm.name.firstName || '';
    payer.middleName = paymentForm.name.middleName
      ? paymentForm.name.middleName.trim().charAt(0)
      : '';
    payer.lastName = paymentForm.name.lastName || '';
    payer.displayName =
      paymentForm.name.firstName +
      ' ' +
      (paymentForm.name.middleName
        ? paymentForm.name.middleName.trim().charAt(0) + ' '
        : '') +
      paymentForm.name.lastName;
    payer.addressLine1 = paymentForm?.address?.addressLine1 || '';
    payer.addressLine2 = paymentForm?.address?.addressLine2 || '';
    payer.city = paymentForm?.address?.city || '';
    payer.state = paymentForm?.address?.state || '';
    payer.postalCode = paymentForm?.address?.postalCode?.replace('-', '') || '';
    payer.phone = policyholder?.homeNumber || '';
    payer.emailAddress = policyholder?.emailAddress || '';
    return payer;
  }

  /**
   * @returns true for mono-line, mono-vehicle quotes wihtout smartmiles, and false otherwise
   */
  isInstallmentFeeDisclaimerApplicable(): Observable<boolean> {
    return combineLatest([
      this.productsService.isOnlyProductSelected('PersonalAuto'),
      this.vehicleService.getVehiclesByProductType('PersonalAuto'),
      this.telematicsService.isSmartMilesEnrolled(),
    ]).pipe(
      map(
        ([isMonolineAuto, vehicles, smartmilesEnrolled]) =>
          isMonolineAuto && vehicles.length === 1 && !smartmilesEnrolled
      )
    );
  }

  getAmfBankAccounts(
    agencyNumber: string
  ): Observable<AmfBankAccountsResponse> {
    return this.billingAdapter.getAmfBankAccounts(agencyNumber);
  }

  getAmfBankAccountsLoaded(): Observable<boolean> {
    return this.store.select(getAmfBankAccountsLoaded);
  }

  getAmfBankAccountsLoading(): Observable<boolean> {
    return this.store.select(getAmfBankAccountsLoading);
  }

  getAmfBankAccountsFailed(): Observable<boolean> {
    return this.store.select(getAmfBankAccountsFailed);
  }

  dispatchLoadAmfBankAccounts(): any {
    return this.store.dispatch(loadAmfBankAccounts());
  }

  hasAgencySweepBankAccount(): Observable<boolean> {
    return this.store.select(hasAgencySweepBankAccount);
  }
}
