export interface BillingPlanDisplay {
  name: string;
  id: string;
  hasPaymentDay: boolean;
  payWith: string;
  discount: string;
  serviceFee: string;
  smartMiles: string;
  askAutoRenew: boolean;
  icon: string;
  monthlyFees: string;
  billDelivery: string;
}

export const DefaultBillingPlans: BillingPlanDisplay[] = [
  {
    name: 'Recurring EFT',
    id: 'MONTHLY EFT',
    hasPaymentDay: true,
    payWith: 'Bank account',
    discount: 'None',
    serviceFee: 'Calculated at payment page.',
    smartMiles: 'If enrolled, the monthly amount due may vary.',
    askAutoRenew: false,
    icon: '',
    monthlyFees: '',
    billDelivery: '',
  },
  {
    name: 'Recurring card',
    id: 'MONTHLY RECURRING BANKCARD',
    hasPaymentDay: false,
    payWith: 'Credit or bank card.',
    discount: 'None',
    serviceFee: 'Calculated at payment page.',
    smartMiles: 'If enrolled, the monthly amount due may vary.',
    askAutoRenew: false,
    icon: '',
    monthlyFees: '',
    billDelivery: '',
  },
  {
    name: 'Pay in full',
    id: 'PAY IN FULL',
    hasPaymentDay: false,
    payWith: 'Card or bank account.',
    discount: 'None',
    serviceFee: 'None',
    smartMiles: 'If enrolled, this payment method is not available.',
    askAutoRenew: true,
    icon: '',
    monthlyFees: '',
    billDelivery: '',
  },
  {
    name: 'Monthly direct bill',
    id: 'MONTHLY DIRECT',
    hasPaymentDay: false,
    payWith:
      'Online today. Online, mail-in, or phone methods for future bills.',
    discount: 'None',
    serviceFee: 'Calculated at payment page.',
    smartMiles: 'If enrolled, this payment method is not available.',
    askAutoRenew: false,
    icon: '',
    monthlyFees: '',
    billDelivery: 'Email',
  },
];

export const DaysInMonth = {
  '01': '1st day of the month',
  '02': '2nd day of the month',
  '03': '3rd day of the month',
  '04': '4th day of the month',
  '05': '5th day of the month',
  '06': '6th day of the month',
  '07': '7th day of the month',
  '08': '8th day of the month',
  '09': '9th day of the month',
  10: '10th day of the month',
  11: '11th day of the month',
  12: '12th day of the month',
  13: '13th day of the month',
  14: '14th day of the month',
  15: '15th day of the month',
  16: '16th day of the month',
  17: '17th day of the month',
  18: '18th day of the month',
  19: '19th day of the month',
  20: '20th day of the month',
  21: '21st day of the month',
  22: '22nd day of the month',
  23: '23rd day of the month',
  24: '24th day of the month',
  25: '25th day of the month',
  26: '26th day of the month',
  27: '27th day of the month',
  28: '28th day of the month',
  29: '29th day of the month',
  30: '30th day of the month',
};

export const BillingInsuranceFraudDisplayVerbiage = `
        "Any person who knowingly and with intent to defraud any insurance
        company or other person files an application for insurance containing
        any materially false information or conceals, for the purpose of
        misleading, information concerning any fact material thereto commits a
        fraudulent insurance act, which is a crime."
      `;

export const BillingEftDisclaimerDisplayVerbiage = `<p>Please read to the customer, before you click "Submit Payment":</p>
        <p>
          "I want to advise you, <strong>{{ nameForDisclaimer }}</strong
          >, that your verbal authorization obtained today,
          <strong>{{ dateForDisclaimer }}</strong
          >, will be used to originate a onetime Electronic Fund Transfer in the
          amount <strong>{{ amountForDisclaimer }}</strong> to your bank account
          ending in <strong>{{ accountNumberForDisclaimer }}</strong
          >. This amount will be deducted from your bank account on or after
          <strong>{{ dateForDisclaimer }}</strong
          >. Your verbal authorization also authorizes your bank to accept and
          post this payment to your account."
        </p>
        <p>
          Advise your customer that they acknowledge Nationwide to
          electronically deduct one NSF fee payment from the same account in an
          amount permitted by their state department of insurance. If this fee
          is unpaid, their policy may be cancelled. In that event, Nationwide
          will send them a separate notice of cancellation in accordance with
          applicable state law.
        </p>
        <p>
          Advise that this authorization allows Nationwide to initiate
          additional ACH debits and/or credits to their bank account for
          corrections, as necessary. If the bank account is a joint account,
          advise the customer that their authorization constitutes authorization
          of all account owners, whether they’re a current owner of this account
          or they’re added after the date they authorize this transaction.
        </p>
        <p>
          Advise if an ACH debit is declined or returned for any reason, their
          Nationwide policy may lapse or be cancelled for non-payment, in which
          case, they will receive a separate notice of cancellation in
          accordance with applicable law. If an ACH debit is declined or
          returned for any reason, they must contact Nationwide right away to
          arrange for a timely payment alternative.
        </p>`;

export const BillingBankcardDisclaimerDisplayVerbiage = `<p>Please read to the customer, before you click "Submit Payment":</p>
        <p>
          "I want to advise you, <strong>{{ nameForDisclaimer }}</strong
          >, that your verbal authorization obtained today,
          <strong>{{ dateForDisclaimer }}</strong
          >, will be used to originate a onetime Electronic Fund Transfer in the
          amount <strong>{{ amountForDisclaimer }}</strong> to your card ending
          in <strong>{{ accountNumberForDisclaimer }}</strong
          >. This amount will be deducted from your card on or after
          <strong>{{ dateForDisclaimer }}</strong
          >. Your verbal authorization also authorizes your bank to accept and
          post this payment to your account."
        </p>
        <p>
          Advise your customer that they acknowledge Nationwide to
          electronically deduct one NSF fee payment from the same account in an
          amount permitted by their state department of insurance. If this fee
          is unpaid, their policy may be cancelled. In that event, Nationwide
          will send them a separate notice of cancellation in accordance with
          applicable state law.
        </p>
        <p>
          Advise that this authorization allows Nationwide to initiate
          additional debits and/or credits to their card for corrections, as
          necessary. If the account is a joint account, advise the customer that
          their authorization constitutes authorization of all account owners,
          whether they’re a current owner of this account or they’re added after
          the date they authorize this transaction.
        </p>
        <p>
          Advise if a transaction is declined or returned for any reason, their
          Nationwide policy may lapse or be cancelled for non-payment, in which
          case, they will receive a separate notice of cancellation in
          accordance with applicable law. If a transaction is declined or
          returned for any reason, they must contact Nationwide right away to
          arrange for a timely payment alternative.
        </p>`;

export const BillingPaymentAuthorizationDisplayVerbiage = `<p>
    <strong>Electronic payment authorization:</strong><br />
    By signing this Authorization, you authorize Nationwide Mutual
    Insurance Company or any of it's affiliates ("Nationwide") to
    initiate recurring ACH debit entries for your policy payments for
    the policy(ies) referenced above and any additional Nationwide
    policies in place at any time, each in the amount
    <strong>{{ amountForDisclaimer }}</strong> of the payment amount
    shown on each of your policy billing statements, beginning on
    <strong>{{ dateForDisclaimer }}</strong> and continuing on each
    payment date shown on each of your policy billing statements
    thereafter, from your bank account listed below
    <strong>{{ accountNumberForDisclaimer }}</strong
    >, as allowed by law.
  </p>
  <p>
    You further authorize Nationwide to initiate ACH credits to your
    Bank Account for any refund, payment or other amount that may now or
    hereafter be due to you from Nationwide (unless you instruct
    Nationwide otherwise).
  </p>
  <p>
    This Authorization allows Nationwide to initiate additional ACH
    debits and/or credits to your Bank Account for corrections, as
    necessary.
  </p>
  <p>
    <strong>I understand and agree:<br />Possible fees<br /></strong>
    If an ACH debit is declined for any reason, a fee may be charged. I
    authorize Nationwide to debit my Bank Account for a one-time fee for
    each declined payment in an amount as permitted by law.
  </p>
  <p>
    <strong>Declined payments<br /></strong>
    If an ACH debit is declined or returned for any reason, my
    applicable Nationwide policy may lapse or be cancelled for
    non-payment, in which case, I will receive a separate notice of
    cancellation in accordance with applicable law. If an ACH debit is
    declined or returned for any reason, I must contact Nationwide right
    away to arrange for a timely payment alternative.
  </p>
  <p>
    <strong>Stopping automatic payments<br /></strong>
    This Authorization has no expiration date and shall continue in full
    force and effect until I reovke it. I may revoke this Authorization
    only by contacting Nationwide between Monday through Friday during
    the regular business hours of 8:00 a.m. - 9:00 p.m. ET, at least
    <strong>3 business days before</strong> the date I would like ACH
    debits from my Bank Account to discontinue.
  </p>
  <p>
    <strong>Note:</strong> If the bank account is a joint account,
    authorization by one account owner constitutes authorization by all
    account owners - whether a current owner of this account or added
    after the date of this Authorization.
  </p>
  <p>
    <strong
      >Please allow up to 5 business days for payment to be withdrawn
      from your Bank Account.</strong
    >
  </p>`;
