import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromCoverageMessages from '@core/store/entities/coverage-message/coverage-message.reducer';
import { ProductType } from '@core/models/api/dsm-types';

export const getCoverageMessageState = createSelector(
  fromCore.getCoreState,
  (state) => state.coverageMessages
);

export const {
  selectAll: getAllCoverageMessages,
  selectEntities: getCoverageMessageEntities,
  selectTotal: getTotalCoverageMessages,
} = fromCoverageMessages.adapter.getSelectors(getCoverageMessageState);

export const getCoverageMessagesByProduct = (productId: ProductType) =>
  createSelector(getAllCoverageMessages, (messages) => {
    const productMessages = messages.filter(
      (msg) => msg.productId === productId
    );
    return !productMessages?.length ? null : productMessages;
  });

export const getPageLevelCoverageMessagesByProduct = (productId: ProductType) =>
  createSelector(getCoverageMessagesByProduct(productId), (messages) => {
    if (!messages?.length) {
      return null;
    }
    return {
      errors: messages[0].errors,
      warnings: messages[0].warnings?.filter(
        (warn) => !warn.entity || !warn.entity.coverageCode
      ),
      productId,
    };
  });

export const getCoverageLevelMessagesByProduct = (productId: ProductType) =>
  createSelector(getCoverageMessagesByProduct(productId), (messages) => {
    if (!messages?.length) {
      return null;
    }
    return messages[0].warnings?.filter(
      (warn) => !!warn.entity && !!warn.entity.coverageCode
    );
  });
