import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  RetrieveActions,
  SessionActions,
  UnderlyingPolicyActions,
  WatercraftExposureActions,
} from '@core/store/actions';
import { WatercraftExposureEntity } from './watercraft-exposures.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { Nullable } from '@shared/utils/type.utils';
import { Dictionary } from '@ngrx/entity';
import { UmbrellaUtils } from '@shared/utils/umbrella.utils';

export interface WatercraftExposureState
  extends EntityState<WatercraftExposureEntity> {
  error?: Nullable<ErrorModel>;
  deleted?: Dictionary<WatercraftExposureEntity>;
}

export const adapter: EntityAdapter<WatercraftExposureEntity> =
  createEntityAdapter<WatercraftExposureEntity>({
    selectId: (watercraftExposure: WatercraftExposureEntity) =>
      watercraftExposure.watercraftExposureId,
  });

const WatercraftExposureReducer = createReducer(
  adapter.getInitialState({
    error: null,
    deleted: {},
  }) as WatercraftExposureState,

  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({
      error: null,
      deleted: {},
    } as WatercraftExposureState)
  ),
  on(
    WatercraftExposureActions.upsertWatercraftExposureSuccess,
    (state, { payload }) => {
      const watercraftExposureDeleted = {
        ...state.deleted,
      };

      UmbrellaUtils.findAccompanyingExposureToRemove<WatercraftExposureEntity>(
        watercraftExposureDeleted,
        payload
      );

      return adapter.upsertOne(payload, {
        ...state,
        deleted: watercraftExposureDeleted,
      });
    }
  ),
  on(WatercraftExposureActions.deleteWatercraftExposure, (state, { payload }) =>
    adapter.upsertOne(payload, state)
  ),
  on(
    WatercraftExposureActions.deleteWatercraftExposureSuccess,
    (state, { watercraftExposureId }) =>
      adapter.removeOne(watercraftExposureId, {
        ...state,
        deleted: {
          ...state.deleted,
          [watercraftExposureId]: state.entities[watercraftExposureId],
        },
      })
  ),
  on(
    WatercraftExposureActions.completelyRemoveWatercraftExposure,
    (state, { watercraftExposureId }) => {
      const watercraftExposuresDeleted = {
        ...state.deleted,
      };
      delete watercraftExposuresDeleted?.[watercraftExposureId];
      return {
        ...state,
        deleted: watercraftExposuresDeleted,
      };
    }
  ),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    if (payload.productType !== 'PersonalUmbrella') {
      return state;
    }
    return adapter.upsertMany(payload.response.watercraftExposures, {
      ...state,
    });
  }),
  on(
    UnderlyingPolicyActions.upsertUnderlyingPolicySuccess,
    (state, { payload, existingPolicyNumber }) => {
      if (
        existingPolicyNumber &&
        !!existingPolicyNumber.length &&
        existingPolicyNumber !== payload.underlyingPolicyNumber
      ) {
        const updatedExposures: WatercraftExposureEntity[] = [];
        const exposuresToUpdate = Object.values(state.entities).filter(
          (exposure) =>
            exposure?.underlyingPolicyNumber === existingPolicyNumber
        );
        for (const exposure of exposuresToUpdate) {
          updatedExposures.push({
            ...exposure,
            underlyingPolicyNumber: payload.underlyingPolicyNumber,
          } as WatercraftExposureEntity);
        }
        return adapter.upsertMany(updatedExposures, state);
      }
      return state;
    }
  )
);

export function reducer(
  state: WatercraftExposureState | undefined,
  action: Action
): WatercraftExposureState {
  return WatercraftExposureReducer(state, action);
}
