import { Action, createReducer, on } from '@ngrx/store';
import { RetrieveActions, SessionActions } from '@core/store/actions';
import { StringUtils } from '@shared/utils/string.utils';

export interface SessionState {
  sessionId: string;
  quoteState: string;
  accessToken?: string;
  isRetrieve: boolean;
  isComprater: boolean;
  reviewedTasks?: {
    [key: string]: boolean;
  };
  hubLoaded: boolean;
  // userType values: Allied Agent, EC Agent, NSS, Employee, Bonds, Marsh (from Agent Center)
  userType: string;
}

const initialState: SessionState = {
  sessionId: StringUtils.generateUuid(),
  quoteState: '',
  isRetrieve: false,
  isComprater: false,
  reviewedTasks: {},
  hubLoaded: false,
  userType: '',
};

const sessionReducer = createReducer(
  initialState,
  // Ironically, this 'session' slice is pretty much the only one that doesn't fully wipe on clearSessionState.
  // It's important that we preserve accessToken.
  on(SessionActions.clearSessionState, (state) => ({
    ...state,
    quoteState: '',
    reviewedTasks: {},
    hubLoaded: false,
  })),
  on(SessionActions.updateRatingState, (state, { payload }) => ({
    ...state,
    quoteState: payload,
  })),
  on(SessionActions.updateAccessToken, (state, { payload }) => ({
    ...state,
    accessToken: payload,
  })),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => ({
    ...state,
    isRetrieve: true,
    reviewedTasks: {},
  })),
  on(SessionActions.setIsComprater, (state, { isComprater }) => ({
    ...state,
    isComprater,
  })),
  on(SessionActions.setTaskAsReviewed, (state, { key }) => ({
    ...state,
    reviewedTasks: {
      ...state.reviewedTasks,
      [key]: true,
    },
  })),
  on(SessionActions.setHubLoaded, (state) => ({ ...state, hubLoaded: true })),
  on(SessionActions.setUserType, (state, { userType }) => ({ ...state, userType }))
);

export function reducer(
  state: SessionState | undefined,
  action: Action
): SessionState {
  return sessionReducer(state, action);
}
