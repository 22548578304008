<div id="loading-spinner-container" *ngIf="loading">
  <div id="white-spinner-bg">
    <bolt-waiting-indicator
      [attr.id]="'loading-spinner'"
      *ngIf="message; else default"
    >
      {{ message }}
    </bolt-waiting-indicator>
  </div>

  <ng-template #default>
    <div id="white-spinner-bg">
      <bolt-waiting-indicator [attr.id]="'loading-spinner'">
        Please wait while we retrieve data
      </bolt-waiting-indicator>
    </div>
  </ng-template>
</div>
