<div class="persona-container" *ngIf="showSmartBundleInsights(personaReport)">
  <h3 class="smart-bundle-heading">
    <strong>Enhance your initial quote with a <i>Protector</i> package</strong>
  </h3>
  <div class="smart-bundle-subheading">
    Protectors are individuals who understand the value of insurance and are not just focused on price.
    Consider these tailored coverages to safeguard their lifestyle which can be modified as needed.
  </div>
  <hr/>
  <ng-container *ngTemplateOutlet="persona"></ng-container>
  <ng-container *ngTemplateOutlet="options"></ng-container>
  <nwx-notification-bubble
    *ngIf="errorMessage$ | async; let message"
    type="error"
    >{{ message }}</nwx-notification-bubble
  >
</div>

<ng-template #persona>
  <div class="subsection-header">
    Choose <i>Protector</i> coverages that fit your customer's life stage.
  </div>
  <nwx-carousel [items]="enabledPersonaChoices">
    <ng-template let-item>
      <div
        class="persona-name"
        [ngClass]="{ selected: personaReport?.name === item.name }"
        (click)="onChoosePersona(item.name, $event)"
      >{{ item.label }}</div>
    </ng-template>
  </nwx-carousel>
</ng-template>

<ng-template #options>
  <div class="persona-inner-container">
    <div class="bolt-container-fluid">
      <div class="bolt-row">
        <div class="bolt-col-12">
          {{ selectedPersonaDescription() }}
        </div>
      </div>
      <div class="bolt-row">
        <div class="bolt-col-12 bolt-col-lg-4" *ngFor="let product of products">
          <div class="bolt-row bolt-align-items-end bolt-space-bottom-sm header-row">
            <div class="bolt-col-8">
              <h5 class="bolt-subheading-lg">
                <strong>{{ product.length > 0 ? product : 'More' }}</strong>
              </h5>
            </div>
            <ng-container
              *ngIf="getProductIconName(product).length > 0; else EmptyIcon"
            >
              <div class="bolt-col-4 no-left-padding product-header-icon">
                <img
                  [src]="'/assets/images/icons/' + getProductIconName(product)"
                  role="presentation"
                />
              </div>
            </ng-container>
            <ng-template #EmptyIcon>
              <div class="bolt-col-4 no-left-padding"></div>
            </ng-template>
          </div>
          <ng-container *ngIf="getProductRecommendations(product).length > 0">
            <div
              class="bolt-row recommendation-row"
              *ngFor="let recommendation of getProductRecommendations(product)"
            >
              <div class="bolt-col-auto">
                <ng-container *ngIf="!recommendation.present; else Present">
                  <ng-container
                    *ngIf="
                      getRecommendationDisplayDetails(
                        recommendation.id
                      )?.icon?.includes('.svg');
                      else BoltIcon
                    "
                  >
                    <img
                      [src]="
                        '/assets/images/icons/' +
                        getRecommendationDisplayDetails(recommendation.id)?.icon
                      "
                      role="presentation"
                      class="tile-img"
                    />
                  </ng-container>
                  <ng-template #BoltIcon>
                    <bolt-icon
                      [name]="
                        getRecommendationDisplayDetails(recommendation.id)?.icon
                      "
                      color="vibrant-blue"
                    ></bolt-icon>
                  </ng-template>
                </ng-container>
                <ng-template #Present>
                  <bolt-icon
                    name="checkmark-circle-filled"
                    color="vibrant-blue"
                    decorative
                    class="float-left"
                  ></bolt-icon>
                </ng-template>
              </div>
              <div class="bolt-col no-left-padding normal-line-height">
                {{ getRecommendationDisplayDetails(recommendation.id)?.label }}
              </div>
              <div class="bolt-col-4 no-left-padding text-center">
                <ng-container *ngIf="!recommendation.present; else Remove">
                  <bolt-button
                    size="sm"
                    arialabel="Add option"
                    [attr.id]="'add-button-' + recommendation.id"
                    (click)="addRecommendation(recommendation)"
                    *ngIf="
                      !recommendationInFlight(recommendation.id);
                      else presentSpinner
                    "
                    >Add</bolt-button
                  >
                  <ng-template #presentSpinner>
                    <bolt-waiting-indicator
                      [attr.id]="'add-spinner-' + recommendation.id"
                      minimal
                    ></bolt-waiting-indicator>
                  </ng-template>
                </ng-container>
                <ng-template #Remove>
                  <bolt-button
                    type="tertiary"
                    size="sm"
                    arialabel="Remove option"
                    [attr.id]="'remove-button-' + recommendation.id"
                    (click)="removeRecommendation(recommendation)"
                    *ngIf="
                      !recommendationInFlight(recommendation.id);
                      else absentSpinner
                    "
                    >Remove</bolt-button
                  >
                  <ng-template #absentSpinner>
                    <bolt-waiting-indicator
                      [attr.id]="'remove-spinner-' + recommendation.id"
                      minimal
                    ></bolt-waiting-indicator>
                  </ng-template>
                </ng-template>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- <div class="bolt-row">
        <div class="bolt-col">
          <bolt-button
            type="primary"
            arialabel="Add all options"
            id="add-all-recommendations"
            class="float-right"
            [attr.disabled]="areAllRecommendationsSelected() ? true : null"
            (click)="addAllRecommendations()"
            >Add all options</bolt-button
          >
        </div>
      </div> -->
    </div>
  </div>
</ng-template>
