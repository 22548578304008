import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotificationBarComponent } from './notification-bar.component';
import { SharedComponentsModule } from '../shared-components.module';

@NgModule({
  declarations: [NotificationBarComponent],
  exports: [NotificationBarComponent],
  imports: [CommonModule, RouterModule, SharedComponentsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NotificationBarModule {}
