import { Injectable } from '@angular/core';
import { DocumentsRequest } from '@core/store/entities/document/document.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DocumentsAdapter } from '../adapters/documents.adapter';
import { DocumentsActions } from '../store/actions';
import { DocumentsEntity } from '../store/entities/document/document.entity';
import { DocumentSelectors } from '../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(
    private documentsAdapter: DocumentsAdapter,
    private store: Store
  ) {}

  getDocuments(request: DocumentsRequest): Observable<DocumentsEntity> {
    return this.documentsAdapter.getDocuments(request);
  }

  dispatchGetDocuments(): void {
    this.store.dispatch(DocumentsActions.getAllDocuments());
  }

  getDocumentsLoaded(): Observable<boolean> {
    return this.store.select(DocumentSelectors.getDocumentsLoaded);
  }

  getAllDocuments(): Observable<DocumentsEntity[]> {
    return this.store.select(DocumentSelectors.getAllDocuments);
  }

  getAllUniqueDocuments(): Observable<DocumentsEntity[]> {
    return this.store.select(DocumentSelectors.getAllUniqueDocuments);
  }
}
