import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ErrorModel } from '../error/error.model';
import {
  DisplayGroupingType,
  ScheduledCategoryEntity,
} from './scheduled-category.entity';
import { RetrieveActions, ScheduledCategoryActions } from '@core/store/actions';
import { Nullable } from '@shared/utils/type.utils';
import { ScheduledCategoryMetadata } from '@core/constants/scheduled-categories-metadata';
import { ProductType } from '@core/models/api/dsm-types';

export interface ScheduledCategoriesEntityState {
  productType: ProductType;
  scheduledCategories: ScheduledCategoryEntity[];
}

export interface ScheduledCategoriesState
  extends EntityState<ScheduledCategoriesEntityState> {
  error: ErrorModel;
  loaded: boolean;
  loading: number;
  categoryLoading?: Nullable<DisplayGroupingType>;
}

export const adapter = createEntityAdapter<ScheduledCategoriesEntityState>({
  selectId: (scheduledCategory) => `${scheduledCategory.productType}`,
});

const ScheduledCategoryReducer = createReducer(
  adapter.getInitialState({
    error: {},
    loaded: false,
    loading: 0,
  }),
  on(ScheduledCategoryActions.getAllScheduledCategories, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(ScheduledCategoryActions.getAllScheduledPersonalEffects, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(ScheduledCategoryActions.updateScheduledPersonalEffectItem, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(
    ScheduledCategoryActions.getAllScheduledCategoriesSuccess,
    (state, { payload, productType }) => {
      return adapter.upsertOne(
        {
          productType,
          scheduledCategories: payload,
        },
        {
          ...state,
          loading: state.loading - 1,
          loaded: true,
        }
      );
    }
  ),

  on(
    ScheduledCategoryActions.getAllScheduledCategoriesFail,
    (state, { error }) => ({
      ...state,
      error,
      loading: state.loading - 1,
      loaded: true,
    })
  ),
  // on(
  //   ScheduledCategoryActions.addManyScheduledCategories,
  //   (state, { payload }) => adapter.addMany(payload, { ...state })
  // ),
  on(
    ScheduledCategoryActions.updateScheduledCategory,
    (state, { payload }) => ({
      ...state,
      loading: state.loading + 1,
      loaded: false,
      categoryLoading:
        ScheduledCategoryMetadata[payload.scheduledCategoryId].displayGrouping,
    })
  ),
  on(
    ScheduledCategoryActions.updateScheduledCategoryFail,
    (state, { error }) => ({
      ...state,
      error,
      loading: state.loading - 1,
      loaded: true,
      categoryLoading: null,
    })
  ),
  on(
    ScheduledCategoryActions.deleteAllScheduledCategories,
    (state, { payload }) => adapter.removeOne(payload, state)
  ),
  on(
    ScheduledCategoryActions.updateScheduledCategorySuccess,
    (state, { payload }) => {
      const product = payload[0].productType;
      return adapter.upsertOne(
        {
          productType: product as ProductType,
          scheduledCategories: payload,
        },
        {
          ...state,
          loading: state.loading - 1,
          loaded: true,
          categoryLoading: null,
        }
      );
    }
  ),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    if (
      payload.productType === 'Condominium' ||
      payload.productType === 'Homeowner'
    ) {
      let scheduledCategories =
        payload.response.scheduledCategories?.map((category) => {
          return { ...category, productType: payload.productType };
        }) || [];
      return adapter.upsertOne(
        { productType: payload.productType, scheduledCategories },
        { ...state }
      );
    } else if (payload.productType === 'RV') {
      let scheduledCategories =
        payload.response.scheduledPersonalEffects?.map((category) => {
          return { ...category, productType: payload.productType };
        }) || [];
      return adapter.upsertOne(
        { productType: payload.productType, scheduledCategories },
        { ...state }
      );
    } else {
      return {
        ...state,
      };
    }
  })
);

export function reducer(
  state: ScheduledCategoriesState | undefined,
  action: Action
): ScheduledCategoriesState {
  return ScheduledCategoryReducer(state, action);
}
