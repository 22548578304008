import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FormHelper } from '@core/helper/form-helper';
import { VehicleModel } from '@core/models/views/vehicle.model';
import { ComponentStoreService } from '@core/services/component-store.service';
import { TaskModel } from '@core/store/entities/task/task.model';
import { ComponentStore } from '@ngrx/component-store';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap, distinctUntilChanged } from 'rxjs/operators';

interface TasksVehicleFormState {
  showVin?: boolean;
  showHin?: boolean;
  showAnnualMiles?: boolean;
}

@Component({
  selector: 'nwx-tasks-vehicles-form',
  templateUrl: './tasks-vehicles-form.component.html',
  styleUrls: ['./tasks-vehicles-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore],
})
export class TasksVehiclesFormComponent implements OnInit, OnDestroy {
  @Input() index: number = 0;
  @Input() vehicle!: Partial<Nullable<VehicleModel>>;
  @Input() vehicleTasks!: TaskModel[] | undefined;

  @Output() valueChange = new EventEmitter<Partial<VehicleModel>>();
  @Output() formReady = new EventEmitter<FormGroup>();

  form!: FormGroup;

  vm$!: Observable<TasksVehicleFormState>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private readonly componentStore: ComponentStoreService<TasksVehicleFormState>
  ) {}

  ngOnInit(): void {
    this.form = this.buildForm(this.vehicle);

    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$),
      distinctUntilChanged(this.hasChanges))
      .subscribe((changes: VehicleModel) => {
        this.emitChanges(changes);
      });

    this.componentStore.initialize({
      showVin: false,
      showHin: false,
      showAnnualMiles: false,
    });

    this.vm$ = this.componentStore.get();

    this.initializeVehicleQuestions();

    this.formReady.emit(this.form);
  }

  ngOnDestroy(): void {
    this.form.disable();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initializeVehicleQuestions(): void {
    this.setFormField(this.doesTaskFieldExist('annualMiles'), 'annualMiles', [
      Validators.max(60000),
    ]);

    this.setFormField(this.doesTaskFieldExist('hin'), 'hin', []);

    this.setFormField(this.doesTaskFieldExist('vin'), 'vin', []);
  }

  onValueChange(changes: Partial<VehicleModel>): void {
    this.valueChange.emit(this.sanitizeOutgoingChanges(changes));
  }

  emitChanges(changes: Partial<VehicleModel>): void {
    this.valueChange.emit(this.sanitizeOutgoingChanges({
      ...this.vehicle,
      annualMiles: changes.annualMiles
        ? changes.annualMiles
        : this.vehicle?.annualMiles,
      hin: changes.hin ? changes.hin : this.vehicle?.hin,
      vin: changes.vin ? changes.vin : this.vehicle?.vin,
    }));
  }

  private sanitizeOutgoingChanges(input: Partial<VehicleModel>): Partial<VehicleModel> {
    // Our "vin" field can be either (string) or ({ vin: string, doNotDisable: any }).
    if (input.vin && typeof input.vin === 'object') {
      return {
        ...input,
        vin: (input.vin as any).vin,
      };
    }
    return input;
  }

  hasChanges(a: VehicleModel, b: VehicleModel): boolean {
    return (
      a.annualMiles === b.annualMiles && a.hin === b.hin && a.vin === b.vin
    );
  }

  addChildForm(name: string, form: FormGroup): void {
    this.form.setControl(name, form);
  }

  buildForm(vehicle: Partial<Nullable<VehicleModel>>): FormGroup {
    return this.fb.group({
      annualMiles: this.fb.control(vehicle?.annualMiles || null, []),
      hin: this.fb.control(vehicle?.hin || null, []),
      vin: this.fb.control(vehicle?.vin || null, []),
      fake: this.fb.control(vehicle?.vin || null, []),
    });
  }

  doesTaskFieldExist(fieldName: string): boolean {
    return !!this.vehicleTasks?.find((t) => t.field === fieldName);
  }

  setFormField(
    isEnabled: boolean,
    fieldName: string,
    validators: ValidatorFn[]
  ): void {
    let secondPartOfFieldName = fieldName;
    if (fieldName.includes('.')) {
      secondPartOfFieldName = fieldName.split('.')[1];
    }
    this.componentStore.update({
      ['show' +
      secondPartOfFieldName.charAt(0).toUpperCase() +
      secondPartOfFieldName.slice(1)]: isEnabled,
    });

    FormHelper.enableOrDisable(fieldName, isEnabled, validators, this.form);
  }
}
