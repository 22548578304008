import { createSelector } from "@ngrx/store";
import { TaskModel } from "../task.model";
import { TaskProductType, quoteStatusGreaterThan } from "@core/models/api/dsm-types";
import { ProductsSelectors, RetrieveSelectors, TaskSelectors } from "@core/store/selectors";
import { TaskHelper } from "@core/helper/task.helper";
import { DummyProducerProduct } from "@core/store/retrieve/retrieve.selector";

export interface TasksModalModel {
  incompleteTasks: TaskModel[];
  dummyProducts: DummyProducerProduct[];
  // categoryTasks:
  availabilityTasks: TaskModel[];
  dummyProducerCodeTasks: TaskModel[];
  effectiveDateTasks: TaskModel[];
  pniTasks: TaskModel[];
  memberTasks: TaskModel[];
  propertyTasks: TaskModel[];
  autoTasks: TaskModel[];
  msaTasks: TaskModel[];
  boatTasks: TaskModel[];
  rvTasks: TaskModel[];
  taskProductTypes: TaskProductType[];
}

export const getCategoryTasks = createSelector(
  TaskSelectors.getIncompleteTasks,
  RetrieveSelectors.getDummyProducerContent,
  (incompleteTasks, dummyProducts) => ({
    incompleteTasks,
    dummyProducts: dummyProducts || [],
    availabilityTasks: TaskHelper.getModalTasksByGrouping(incompleteTasks, 'Availability'),
    dummyProducerCodeTasks: TaskHelper.getModalTasksByGrouping(incompleteTasks, 'ProducerCode'),
    effectiveDateTasks: TaskHelper.getModalTasksByGrouping(incompleteTasks, 'EffectiveDate'),
    pniTasks: TaskHelper.getModalTasksByGrouping(incompleteTasks, 'PNI'),
    memberTasks: TaskHelper.getModalTasksByGrouping(incompleteTasks, 'Member'),
    propertyTasks: TaskHelper.getModalTasksByGrouping(incompleteTasks, 'Property'),
    autoTasks: TaskHelper.getModalTasksByGrouping(incompleteTasks, 'PersonalAuto'),
    msaTasks: TaskHelper.getModalTasksByGrouping(incompleteTasks, 'MSA'),
    boatTasks: TaskHelper.getModalTasksByGrouping(incompleteTasks, 'Boat'),
    rvTasks: TaskHelper.getModalTasksByGrouping(incompleteTasks, 'RV'),
    taskProductTypes: TaskHelper.getProductTaskTypes(incompleteTasks),
  })
);

// Same as getCategoryTasks, but ignores 'completed'.
export const getStickyTasksForPostRetrieveModal = createSelector(
  TaskSelectors.selectAllTasks,
  ProductsSelectors.getOverallFurthestQuoteStatus,
  RetrieveSelectors.getDummyProducerContent,
  (tasks, status, dummyProducts) => {
    tasks = tasks
      .filter((t) => !quoteStatusGreaterThan(t.ratingStatusOrdinal || 'Draft', status));
    return {
      incompleteTasks: tasks,
      dummyProducts: dummyProducts || [],
      availabilityTasks: TaskHelper.getModalTasksByGrouping(tasks, 'Availability'),
      dummyProducerCodeTasks: TaskHelper.getModalTasksByGrouping(tasks, 'ProducerCode'),
      effectiveDateTasks: TaskHelper.getModalTasksByGrouping(tasks, 'EffectiveDate'),
      pniTasks: TaskHelper.getModalTasksByGrouping(tasks, 'PNI'),
      memberTasks: TaskHelper.getModalTasksByGrouping(tasks, 'Member'),
      propertyTasks: TaskHelper.getModalTasksByGrouping(tasks, 'Property'),
      autoTasks: TaskHelper.getModalTasksByGrouping(tasks, 'PersonalAuto'),
      msaTasks: TaskHelper.getModalTasksByGrouping(tasks, 'MSA'),
      boatTasks: TaskHelper.getModalTasksByGrouping(tasks, 'Boat'),
      rvTasks: TaskHelper.getModalTasksByGrouping(tasks, 'RV'),
      taskProductTypes: TaskHelper.getProductTaskTypes(tasks),
    };
  }
);

function shouldPresentModal(tasksModalModel: TasksModalModel): boolean {
  return !!(
    tasksModalModel.availabilityTasks.length ||
    tasksModalModel.dummyProducerCodeTasks.length ||
    tasksModalModel.effectiveDateTasks.length ||
    tasksModalModel.memberTasks.length ||
    tasksModalModel.pniTasks.length ||
    tasksModalModel.taskProductTypes.length
  );
}

export const getTasksForPostRetrieveModal = createSelector(
  getCategoryTasks,
  (
    tasksModalModel
  ) => {
    if (!shouldPresentModal(tasksModalModel)) {
      return null;
    }
    return tasksModalModel;
  }
);
