import { ProductType } from '@core/models/api/dsm-types';
import { VehicleModel } from '@core/models/views/vehicle.model';
import { DriverVehicleAssignmentEntity } from '@core/store/entities/driver-vehicle-assignment/driver-vehicle-assignment.entity';
import { DriverEntity } from '@core/store/entities/driver/driver.entity';
import { MemberEntity } from '@core/store/entities/member/member.reducer';
import { VehicleEntity } from '@core/store/entities/vehicle/vehicle.entity';
import { PersonUtils } from './person.utils';

export class DriverVehicleAssignmentUtils {
  static getVehiclePrimaryDriver(
    vehicle: VehicleEntity | VehicleModel,
    drivers: DriverEntity[],
    assignments: DriverVehicleAssignmentEntity[]
  ): DriverEntity | undefined {
    const primaryDriverAssignment = assignments.find(
      (assignment) =>
        assignment.vehicleId === vehicle.vehicleId && assignment.isPrimaryDriver
      // isPrimaryVehicle might need to be involved
    );
    if (primaryDriverAssignment && vehicle.productType) {
      return PersonUtils.findMemberByEntityId(
        drivers,
        'driver',
        vehicle.productType,
        +primaryDriverAssignment.driverId
      );
    }
    return undefined;
  }

  static vehicleHasPrimaryDriver(
    vehicleId: string,
    assignments: DriverVehicleAssignmentEntity[]
  ): boolean {
    return assignments.some(
      (assignment) =>
        assignment.vehicleId === vehicleId && assignment.isPrimaryDriver
    );
  }

  static getVehicleSecondaryDrivers(
    vehicle: VehicleEntity | VehicleModel,
    drivers: DriverEntity[],
    assignments: DriverVehicleAssignmentEntity[]
  ): DriverEntity[] {
    return assignments
      .filter(
        (assignment) =>
          assignment.vehicleId === vehicle.vehicleId &&
          !assignment.isPrimaryDriver
      )
      .map((assignment) =>
        PersonUtils.findMemberByEntityId(
          drivers,
          'driver',
          vehicle.productType as ProductType,
          +assignment.driverId
        )
      )
      .filter((driver) => !!driver) as DriverEntity[];
  }

  static getVehicleDrivers(
    vehicle: VehicleEntity | VehicleModel,
    drivers: DriverEntity[],
    assignments: DriverVehicleAssignmentEntity[]
  ): DriverEntity[] {
    return assignments
      .filter((assignment) => assignment.vehicleId === vehicle.vehicleId)
      .map((assignment) =>
        PersonUtils.findMemberByEntityId(
          drivers,
          'driver',
          vehicle.productType as ProductType,
          +assignment.driverId
        )
      )
      .filter((driver) => !!driver) as DriverEntity[];
  }

  static getVehiclePrimaryDriverLabel(
    vehicle: VehicleEntity | VehicleModel,
    drivers: DriverEntity[],
    assignments: DriverVehicleAssignmentEntity[]
  ): string {
    const primaryDriver = this.getVehiclePrimaryDriver(
      vehicle,
      drivers,
      assignments
    );
    return primaryDriver ? this.getDriverLabel(primaryDriver) : '';
  }

  static getDriverLabel(driver: DriverEntity): string {
    return (
      'This is the vehicle ' +
      driver.person?.firstName +
      ' ' +
      driver.person?.lastName +
      ' drives the most'
    );
  }

  static getVehicleYMM(vehicle: VehicleEntity | VehicleModel): string {
    return vehicle.year
      ? `${vehicle.year} ${vehicle.make} ${vehicle.model}`
      : 'New Vehicle';
  }
}
