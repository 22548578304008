import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  RetrieveActions,
  SessionActions,
  VehicleExcludedExposureActions,
} from '@core/store/actions';
import { VehicleExcludedExposureEntity } from './vehicle-excluded-exposures.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { Nullable } from '@shared/utils/type.utils';
import { Dictionary } from '@ngrx/entity';

export interface VehicleExcludedExposureState
  extends EntityState<VehicleExcludedExposureEntity> {
  error?: Nullable<ErrorModel>;
  deleted?: Dictionary<VehicleExcludedExposureEntity>;
}

export const adapter: EntityAdapter<VehicleExcludedExposureEntity> =
  createEntityAdapter<VehicleExcludedExposureEntity>({
    selectId: (vehicleExcludedExposure: VehicleExcludedExposureEntity) =>
      vehicleExcludedExposure.vehicleExcludedExposureId,
  });

const VehicleExcludedExposureReducer = createReducer(
  adapter.getInitialState({
    error: null,
    deleted: {},
  }) as VehicleExcludedExposureState,

  on(SessionActions.clearSessionState, () =>
    adapter.getInitialState({
      error: null,
      deleted: {},
    } as VehicleExcludedExposureState)
  ),
  on(
    VehicleExcludedExposureActions.upsertVehicleExcludedExposureSuccess,
    (state, { payload }) =>
      adapter.upsertOne(payload, {
        ...state,
      })
  ),
  on(
    VehicleExcludedExposureActions.deleteVehicleExcludedExposure,
    (state, { payload }) =>
      adapter.upsertOne(payload, {
        ...state,
        deleted: { [payload.vehicleExcludedExposureId]: payload },
      })
  ),
  on(
    VehicleExcludedExposureActions.deleteVehicleExcludedExposureSuccess,
    (state, { vehicleExcludedExposureId }) =>
      adapter.removeOne(vehicleExcludedExposureId, {
        ...state,
      })
  ),
  on(
    VehicleExcludedExposureActions.completelyRemoveVehicleExcludedExposure,
    (state, { vehicleExcludedExposureId }) => {
      const deleted = { ...state.deleted };
      delete deleted?.[vehicleExcludedExposureId];
      return { ...state, deleted };
    }
  ),
  on(RetrieveActions.retrieveQuoteSuccess, (state, { payload }) => {
    if (payload.productType !== 'PersonalUmbrella') {
      return state;
    }
    return adapter.upsertMany(payload.response.vehicleExcludedExposures, {
      ...state,
    });
  })
);

export function reducer(
  state: VehicleExcludedExposureState | undefined,
  action: Action
): VehicleExcludedExposureState {
  return VehicleExcludedExposureReducer(state, action);
}
