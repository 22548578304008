/* eslint-disable */
export const CoverageHelpText: { [key: string]: string } = {
  AAC:
    'Coverage for charges incurred for necessary products, services, and accommodations for ' +
    'an injured person’s care in excess of their PIP Medical coverage.',
  ACDD: 'Accidental Disability Benefit pays up to $60 per week, for up to 1 year, if the Named Insured is disabled and unable to work.',
  AD: 'This provides a lump sum due to death in a covered loss.',
  AF:
    'Avoid a rate increase following the first at-fault accident on your policy. ' +
    "Accident Forgiveness keeps your premium from increasing if you're involved in an accident " +
    'as long as you have been accident-free for the past 3 years.',
  allperils: '',
  AM: 'Automobile Death Benefits pays $10,000 in the event of death of the Named Insured.',
  APIP:
    'Pays for expenses exceeding the limits of basic Personal Injury Protection required by law. ' +
    'Pays additional benefits for Medical Expenses, Loss of Earnings and Substitute Service Expenses, ' +
    'up to the limits of Additional Personal Injury Protection.',
  BasicCoverage:
    'Covers the house and attached structures for accidental direct physical loss and includes built-in or attached items,' +
    ' such as built-in appliances or wall-to-wall carpet. The Dwelling amount is directly related to the reconstruction cost,' +
    ' which is the cost to rebuild your house completely at the current labor rate with materials of similar kind and quality.' +
    ' We determined this amount based on the interior and exterior details of your home. We want to provide you with the ' +
    'best possible protection by making sure your home is insured to 100% of the replacement cost. If your home has ' +
    'increased or decreased in value, or you’ve made improvements, then please call us to request a change to this amount.',
  BetterRoofReplacement:
    'Rebuild your roof with stronger, safer roofing materials to better ' +
    'withstand wind, rain, and hail in the event of a covered loss that ' +
    'results in the replacement of your entire roof. This optional coverage ' +
    'pays the difference between a standard roof replacement and one with ' +
    'fortified construction, including nails, flashing, underlayment and ' +
    'shingles.',
  BI:
    'If you cause an accident resulting in injuries, then Bodily Injury (BI) protects you ' +
    'by paying for damages you may become legally liable for as a result of an automobile accident. ' +
    "This includes paying for an injured party's medical bills and loss of income up to your coverage limit.",
  BioDeteriorationDamageCoverage:
    'Covers specified clean up and removal costs and expenses, as a ' +
    'result of an otherwise covered loss to your property, due to living ' +
    'organisms such as molds, algae, lichens, bacteria, fungi, and wet ' +
    'and dry rot.',
  BrandNewBelongings:
    'Pays the full cost to replace or repair covered personal ' +
    'property, whichever costs less, without a deduction ' +
    'for depreciation. This coverage may also be referred to as Personal ' +
    'Property Replacement Cost. If ' +
    'replacement cost is provided in lieu of the cost to repair, this ' +
    'covers the cost, at the time of the loss, of ' +
    'a new item identical to the one damaged or stolen. When an identical ' +
    'item is no longer available, we cover the ' +
    'cost of a new item similar to the one covered in the loss. ' +
    'For example, with this coverage, if you ' +
    'purchased a TV five years ago for $500, you could be paid the ' +
    'current market value of an equivalent TV today. ' +
    'However, without replacement coverage, you would be paid the ' +
    'depreciated amount for the TV, which may only be $50. ' +
    'Any special limits of liability in the policy will apply to this ' +
    'coverage.',
  BuildingAdditionsAlterations:
    ' For the best possible protection, consider insuring your condo for ' +
    'an amount that would cover the cost to replace everything inside your ' +
    'unit such as built-ins and attached items. This means the drywall, ' +
    'carpeting, cabinets, fixtures, lighting, and anything else related to ' +
    'the construction of the unit. The owner of the building itself will have ' +
    'coverage for the exterior of the structure, including the roof and any areas ' +
    'shared by all the unit owners.',
  BuildingOrdinanceOrLaw:
    'Pays to bring your residence into compliance with the latest ' +
    'building codes and standards in the event that your residence needs ' +
    'repairs following an insured loss.</ul>',
  BusinessPropOff:
    'The special limit of liability on business property is increased ' +
    'to the amount shown on the Declarations Page. It includes up to $500 ' +
    'of coverage for business property off the residence premises.',
  BusinessPropOn:
    'Coverage that insures your business property while on the ' +
    'residence premises. This coverage is limited to $2,500.',
  COLL:
    'Regardless of who is at fault, Collision pays for damage to your vehicle if you ' +
    'hit another vehicle or object, another vehicle hits you, or your vehicle rolls over. ' +
    "You're responsible for paying the deductible.",
  COMBL:
    'This coverage is a combination of Funeral Expenses, Loss of Income, and Accidental Death. Instead of selecting limits for the individual ' +
    'coverages, you can choose this coverage to combine them. Please note that if Combined Loss Benefit is selected, individual coverages cannot be customized. ' +
    'In the policy, this may be referred to as Combination First Party Benefits.',
  COMP:
    'Comprehensive coverage can help pay for damage to your vehicle from ' +
    'vandalism, theft, weather events and accidents involving animals. ' +
    "You're responsible for paying the deductible at the time of the claim.",
  COMPSafetyGlass:
    'This differs from glass coverage included in Comprehensive in that there ' +
    'is no deductible for cracks or for full glass replacements.',
  CreditCard:
    'Pays up to your selected limit for unauthorized transactions on ' +
    'your credit cards and bank debit/ATM card. It also applies to forged ' +
    'checks and counterfeit money. This does not provide Identity Theft ' +
    'coverage.',
  DebrisRemovalTree:
    'This additional coverage pays up to $1,000 to remove fallen trees ' +
    'and debris, and up to $500 to remove one tree. It also pays to ' +
    'remove debris that has damaged covered property as specified in your ' +
    'policy as part of your liability limits. If the amount to be paid ' +
    'for damaged property and debris removal exceeds your liability ' +
    'limits, an additional 5% is available to remove debris.',
  DI: 'Coverage for the death or dismemberment of a driver as a result of an automobile accident.',
  dwellingreplaceementcost:
    'Pays an additional amount if more money is needed to rebuild after a loss. This coverage helps ' +
    "offset increased costs that exceed your main Dwelling amount, which is insured to 100% of the home's value.",
  DwellingReplacementCost:
    'Pays an additional amount if more money is needed to rebuild after ' +
    'a covered loss, 150% of the Dwelling amount. This coverage helps ' +
    'offset increased costs that exceed your main Dwelling amount, which ' +
    "is insured to 100% of the home's value.",
  DwellingReplacementCost200:
    'Pays an additional amount if more money is needed to rebuild after ' +
    'a covered loss, 200% of the Dwelling amount. This coverage helps ' +
    'offset increased costs that exceed your main Dwelling amount, which ' +
    "is insured to 100% of the home's value.",
  EarthquakeAdditionsAlterations:
    'Pays for covered damages to additions, alterations, fixtures, improvements or installations that you make to your apartment due to earthquake covered losses.',

  // TODO: What is the ID here?
  EarthquakeDwelling:
    'This limit reflects the current Dwelling liability coverage on your condo. ' +
    'This limit covers the cost of replacing built-in and attached items within your unit due to damage caused by earthquake or volcanic eruption, ' +
    'such as drywall, carpeting, cabinets, fixtures, lighting, and anything else related to the construction of the unit. ' +
    'The owner of the building itself will have coverage for the exterior of the structure, including the roof and any areas shared by all unit owners',
  EarthquakeLossAssessment:
    'Pays for fees charged by a homeowners association for damage to shared spaces caused by earthquake or volcanic eruption.',
  EarthquakeMasonryVeneer: `This covers the masonry veneer material around your home.`,
  EarthquakeOtherStructures: `Covers earthquake and volcanic damage to property not attached to your home.`,
  EarthquakeUnscheduledPersonalProperty: `Covers damage done to your personal property by earthquake/volcanic eruption.`,
  EquipmentBreakdown:
    'Covers expensive appliances such as furnaces, air conditioners ' +
    'and refrigerators that need repaired or replaced due to ' +
    'mechanical, electrical, or electronic breakdown. A per occurrence ' +
    '$50,000 limit and a $500 deductible applies. Also pays up to 125% of ' +
    'the value for like kind and quality equipment to replace it with ' +
    'equipment that is better for the environment. Up to $3,000 is ' +
    'available for additional costs to clean/remove pollutants or ' +
    'repair/replace equipment with energy efficient models.',
  EXMDCL:
    "Pays for Named Insured or family members' additional first-party medical coverage for injuries resulting from an automobile accident, " +
    'regardless of fault. In the policy, this may be referred to as Extraordinary Medical Benefits.',
  FireDepartmentServiceCharge:
    'Pays up to the limit stated in your policy for customary charges ' +
    'assessed by the fire department when they are called to save or ' +
    'protect your insured property from a covered peril.',
  Firearms:
    'Covers direct physical loss to firearms and accessories. Pays up ' +
    'to the stated limit named in your policy, which is the total limit ' +
    'for each occurrence. A maximum special limit of liability applies to ' +
    'theft losses. For maximum protection, firearms and accessories ' +
    'should be insured on a Valuables Plus<sup>&reg;</sup> policy.',
  Foundation:
    'Foundation Coverage provides coverage for settling, cracking, bulging, shrinking or expansion of foundations, ' +
    'floor slabs or footings that support the dwelling caused by seepage or leakage of water or steam from within a ' +
    'plumbing, heating, air conditioning or automatic fire protective sprinkler system. The liability limit will be 15% of Coverage A – Dwelling.',
  FSHEQ:
    'Comprehensive and Collision coverage is required on at least one vehicle to have Fishing equipment coverage on policy.' +
    ' Fishing equipment limit will be automatically updated  based on a package type. When there is more than one package type on a policy, the highest Fishing Equipment limit will extend to all boats on the policy.',
  FUNRL:
    'Coverage for any drivers or passengers for funeral expenses if there’s a car-accident related death.',
  GAPCOLL:
    'If your car is a total loss after an accident, Gap coverage may pay the difference ' +
    'between the actual cash value and what you owe on the lease or loan.',
  GuaranteedReplacementCost:
    'Pays to rebuild after a covered loss. This coverage helps offset ' +
    'increased costs that exceed your main Dwelling amount, which is ' +
    "insured to 100% of the home's value.",
  IdentityTheft:
    'Pays for expenses up to the policy limits resulting from any ' +
    'identity theft or fraud discovered during the policy period. For the ' +
    'additional premium, you will enjoy a host of identity theft services ' +
    'that will help stop the damage to your credit within minutes of your ' +
    'authorization. We will: ' +
    'Quickly determine if fraud has occurred ' +
    'Make all required calls to banks, creditors and agencies ' +
    'Assist you in replacing important documents and I.D. cards ' +
    'Provide up to $25,000 in expense reimbursement ' +
    'Provide FREE credit monitoring with email alerts if your credit report changes',
  IDFR:
    'If you or anyone in your household are a victim of identity theft or fraud, ' +
    'Identity Theft Expense Reimbursement pays up to $25,000 for expenses.',
  IncreasedLossOfUse:
    'Provides reimbursement for additional living expenses when a ' +
    'covered loss prevents you from living in your home. ' +
    'This coverage limit is automatically included. Payment will not ' +
    'exceed the limit on the policy or Dwelling amount, ' +
    'and will be for the shortest time required to repair or replace the ' +
    'premises, whichever occurs first, or up to the ' +
    'policy limit of maximum months. This coverage may also be referred ' +
    'to as Actual Loss Sustained.',
  IncreasedOtherStructures:
    'Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos or ' +
    'detached garages and carports. We automatically provide this at 10% of your Dwelling amount. If you would ' +
    'like more coverage then please call Nationwide.',
  InflationProtectionCoverage:
    'A coverage that automatically increases the policy limits for ' +
    'Dwelling, Other Structures and Personal Property ' +
    'coverages during the policy term to keep pace with inflation.',
  JewelryInVault:
    'Pays for theft losses to jewelry, watches, furs, precious and ' +
    'semi-precious stones, from within a vault at a federal or state ' +
    'chartered bank, savings and loan association or thrift institution.' +
    '',
  JewelryWatches:
    'Pays for losses to jewelry, watches, furs, precious and ' +
    'semi-precious stones, caused by a covered peril as named within your ' +
    "policy contract. Pays up to your policy's stated limit, which is the " +
    'total limit for each occurrence. A special maximum limit of ' +
    'liability applies to theft losses. For maximum protection, items ' +
    'should be insured under a scheduled personal property endorsement or ' +
    'Valuables Plus<sup>&reg;</sup> policy.',
  LandlordFurnishings:
    'Covers damage or loss of appliances, carpeting and other ' +
    'household furnishings in a rental unit. This is the maximum amount ' +
    'paid in any one loss. This coverage does not apply to losses due to ' +
    'theft. This coverage does not increase the limit of liability ' +
    'applying to the damaged property. In the event of a loss, this ' +
    'coverage is applied after other valid and collectible insurance.',
  LimitedRoofLoss:
    'This optional endorsement revises the homeowners policy settlement provisions for roof losses, resulting from windstorm or hail, to a pre-determined schedule of replacement cost based on roof age and roof type.',
  LOI:
    'Loss of Income is a required coverage when liability is present on a policy. Loss of Income provides ' +
    'coverage for Work Loss, Replacement Services, and Survivor’s Loss Benefits.',
  LossAssessment:
    'Pays for fees charged by a homeowners association for damages to ' +
    'a shared space such as a gym, pool or community room.',
  LossSettlementProvision:
    '<strong>Replacement cost:</strong> We will settle covered losses to the dwelling without regard for the limit of liability shown in the Dwelling amount.<br /><strong>Functional replacement cost:</strong> For houses with a construction year of 1950 or earlier, we will settle covered losses to the building according to the cost to repair or replace the damaged building with common construction material and methods.',
  MDCL: 'This pays reasonable expenses for medical, hospital, nursing, ambulance services incurred in a covered loss.',
  MedicalPayments:
    'Pays for medical or funeral expenses of others who are injured ' +
    'on your property or caused by your activities on or ' +
    'off your property. It will not apply to you or regular residents of ' +
    'your household.',
  MineSubsidence:
    'Covers damage due to collapse or movement of man made underground ' +
    'mines, such as coal mines. Helps pay for excavation ' +
    'or grading, as well as damage to foundations, boilers, engines, ' +
    'underground supports, pipes, flues and drains. Damage ' +
    'caused by earthquakes, strip/surface mines, drains or rapid transit ' +
    'tunnels are not covered.',
  MP:
    'Regardless of who is at fault, Medical Payments coverage pays medical expenses, ' +
    'up to your coverage limit, for you, your family household members and your passengers arising ' +
    'from motor vehicle accidents. Also known as Personal Injury Protection (PIP), ' +
    'this coverage could help pay for certain medical costs, such as dental or specialized nursing care, ' +
    'which your health insurance may not cover.',
  MVF:
    'Minor Violation Forgiveness will keep your insurance premium from increasing ' +
    'if you receive a citation for a minor traffic violation within a 3 year period.',
  NCRPCOLL:
    'If your covered vehicle is declared a total loss, this coverage provides Gap coverage, ' +
    'plus protection against depreciation to replace your covered vehicle, 0-2 years old, with a ' +
    'new vehicle (up to 110% of MSRP on totaled vehicle), or a vehicle 3-5 years old, with vehicle in ' +
    'ready-to-sell condition. Ends at renewal when vehicle is 6 years old. This coverage does not apply to ' +
    'leased vehicles.',
  OBEL:
    'New York residents can choose to purchase Optional Basic Economic Loss (OBEL) in addition ' +
    "to the state-mandated personal injury protection (or no-fault) coverage. New York's state-mandated " +
    'coverage insures you up to $50,000 if you have an auto accident. OBEL adds $25,000 in addition to ' +
    'this coverage for injuries, lost wages or other loss. OBEL can cover the driver, family members, ' +
    'passengers or any pedestrians who are injured. ' +
    'A few important notes about OBEL: ' +
    'This additional insurance will go into effect after the $50,000 of personal injury protection is exhausted. ' +
    'As the policyholder, you can decide where to direct the $25,000, whether it goes toward lost wages, ' +
    'physical rehabilitation or psychiatric therapy. ' +
    'OBEL is available for purchase in addition to your state-mandated personal injury protection. ' +
    'It is optional insurance intended to provide an additional $25,000 in coverage.',
  OEM:
    "Specifies that parts from your vehicle's manufacturer - Original Equipment " +
    'Manufacturer (OEM) parts - are used, when available, to repair your vehicle ' +
    'after a collision or comprehensive claim.',
  otherstructureswithincreasedlimits:
    'Provides more insurance, in addition to the amount Other Structures provides, for property not attached to ' +
    'your house. This is helpful when Other Structures will not cover all detached structures on your premises ' +
    'during a loss.',
  OutboardMotor:
    'Covers outboard engines or motors with 25 total horsepower or ' +
    'less. 100 total horsepower with Protection Boost Coverage.',
  PD:
    "If you cause an accident resulting in damage to another person's vehicle or property, " +
    'Property Damage (PD) Liability pays for those repairs up to your coverage limit. ' +
    'It will also provide for your legal defense if a suit is brought against you as a result of the accident.',
  PersonalInjury:
    'Provides coverage for damages to others due to injuries such as ' +
    'false arrest, libel, slander and invasion of privacy ' +
    'for which you are legally liable.',
  PersonalLiability:
    'Covers bodily injury and property damage for which you are held ' +
    'legally liable except as limited or excluded by the ' +
    'policy. It will not apply to you or regular residents of your household.',
  PersonalPropOtherRes:
    'Covers the loss of your personal property while it is at another location ' +
    '(including self-storage facilities) up to 10% of your personal property limit ' +
    'or $1,000, whichever is greater. This limit does NOT apply if the property is ' +
    'in transit to or in a newly acquired principal residence (up to 30 days from ' +
    'the time the property began to move) or if the property was moved from the residence ' +
    'premises because it was being repaired, renovated, or rebuilt, and the premises is ' +
    'not fit to live in or store property in.',
  PIP:
    'Personal Injury Protection coverage pays for the Member, their family members ' +
    "or non-related passenger's medical expenses in the event of an accident, regardless " +
    'of who is at fault. This coverage may also pay for funeral cost, death benefit, ' +
    'lost wages, and costs for household services.',
  PIPGuestOnly:
    'Guest PIP provides protection to the guest passengers in your vehicle in the event of an accident. It provides $10,000 coverage for medical expenses, ' +
    'lost wages and othe out-of-pocket costs. In kentucky, if you choose to reject PIP for yourself, you must purchase Guest PIP.',
  PIPOption:
    'Personal Injury Protection Option – Coordination of Medical Expenses (excluding Medicare or ' +
    'Medicaid benefits provided by the federal government) applies to you or any “family member”.',
  PortableEquip:
    'Electronic equipment that could be insured while in the residence or in/on a motor vehicle ' +
    '(speakers, sensors, generators, etc.).',
  ProtectionBoost:
    'Protection Boost increases specified special limits of liability ' +
    'throughout the policy, including, but not limited to, theft of ' +
    'jewelry and watches. These coverages are subject to the policy ' +
    'deductible.<br><em>Selecting Protection Boost increases specified special limits of liability.</em>',
  RefrigeratedPersonalProperty:
    'Covers up to $500 for spoilage of food or other perishable items ' +
    'caused by power outage or interruption that occurs off the ' +
    'residences premises. You must use all reasonable means to protect ' +
    'the perishables from damage for this coverage to apply.',
  RENTAL:
    'This provides comprehensive and collision coverage to rental cars and coverage for loss of use.',
  RENTRE:
    'Rental Reimbursement saves you time and hassle by providing access to a rental vehicle ' +
    'or other transportation while your vehicle is in the repair shop after a covered accident, ' +
    "in accordance with the terms of your policy. Without it, you'll need to find another way to work, " +
    'the store or around town.',
  ResidenceRentalTheft:
    'Coverage to insure against loss by theft to covered property when all or part of the residence, ' +
    'usually occupied by the insured, is occasionally rented to others.',
  RSA:
    'Roadside Assistance provides limited reimbursement for 24/7 emergency help if you need fuel delivery, a tire change, jumpstart or tow ' +
    '(or use our Roadside Assistance Representative). The Plus option adds trip interruption (food, lodging, transport assistance for covered ' +
    'losses more than 100 miles from home), and increased roadside assistance reimbursement. See endorsement for details, limits and exclusions.',
  SectionIDeductibles:
    "The dollar amount you're responsible for paying as part of a " +
    "covered loss. Be sure to choose a deductible you're comfortable " +
    'paying.',
  ServiceLine:
    'Covers damage to and repair of exterior underground piping and wiring providing ' +
    'one of the following services to your residence:  cable or data transmission, compressed air, ' +
    'drainage, electrical power, heating, waste disposal, or water.',
  Sinkhole:
    'Damage to covered property caused by sudden settlement or collapse of the ground from collapse of subterranean voids.',
  TDI:
    'This is liability insurance that provides benefits in scenarios that result in a complete loss of ability to earn income. ' +
    'This coverage is contingent upon selecting the automobile death benefit coverage.',
  TheftOfBuildingMaterials:
    'Covers up to $5,000 per loss and a $250 deductible applied per loss.',
  TLDW:
    'If your car is declared a total loss after an accident, Total Loss Deductible Waiver ' +
    'will pay the deductible for your Comprehensive or Collision insurance.',
  TORT:
    'Coverage for legal fees/lawsuits brought by the policyholder in case of a serious injury. ' +
    '<b>Full</b> means coverage ability to recover all damages brought in a lawsuit, ' +
    '<b>Limited</b> is for coverage to recover <em>pain and suffering</em> incurred.',
  Trailers:
    'Protects against damage to the trailer, not used with ' +
    'watercrafts. Requires Physical Damage coverage for the item on the ' +
    'trailer. In addition, the deductible must be the same amount or ' +
    'percentage as the deductible selected for Physical Damage coverage.',
  TreesShrubsOtherMaxPercentCovA:
    'Pays up to 5% of your Dwelling Coverage limit for the loss of ' +
    'trees, shrubs, plants or lawns at your residence. Causes include ' +
    'vandalism, theft, fire, vehicles other than your own, or collapse ' +
    'of a building. No more than $500 will be paid for any one tree, ' +
    'shrub or plant. This does not cover plants grown for business.',
  TREX:
    '<b>Optional Limits Transportation Expenses,</b> saves you time and hassle by providing access to a rental car or other transportation ' +
    'while your car is in the repair shop after a covered accident, ' +
    "in accordance with the terms of your policy. Without it, you'll need to find another way to work, the store or around town.",
  TREXOption800:
    'Rental Days Plus -The limit for non-owned autos is not capped by a dollar amount, but it is limited to 30 days.',
  TREXOption:
    'Covers expenses incurred as a result of loss to a your covered auto and expenses incurred as a result of loss to a non-owned auto. Up to the limit of liability stated in the Declarations, not less than $600',
  UIMBI:
    'If you are in an accident caused by an underinsured driver, ' +
    'this coverage protects you, your family members, and other passengers ' +
    'by paying for medical expense, loss of income and other damages owed to you ' +
    'by the underinsured motorist. Generally, an underinsured motorist is a motorist ' +
    'who does not have enough insurance to pay for your damages. However, the definition ' +
    'of an underinsured motorist varies from state to state.',
  UIMBIStacked:
    'Stacking coverage allows you to increase the amount of underinsured motorist coverage by the number of vehicles on your auto insurance policy.',
  UIMPD:
    'Covers property damage claims if you are in ' +
    'an auto accident caused by a driver who is insured, ' +
    'but who has less coverage than your underinsured motorist coverage ',
  UMBI:
    'This coverage reimburses you for damages resulting from injuries caused by a driver ' +
    'who does not have any applicable liability insurance. ' +
    'The definition of an uninsured motorist varies from state to state.',
  UMBIStacked:
    'Stacking coverage allows you to increase the amount of uninsured motorist coverage by the number of vehicles on your auto insurance policy.',
  UMPD:
    'This coverage reimburses you for damages to your vehicle caused by a driver ' +
    "who either doesn't have enough, or doesn't have any, insurance.",
  UMUIMBI:
    'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
    'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
    'reimburse you for damages they caused.',
  UnscheduledCamera:
    'Cameras and accessories are covered up to the stated limit in ' +
    'your policy declaration. A special limit of liability for theft ' +
    'losses applies. For maximum protection, cameras and accessories ' +
    'should be insured under a scheduled personal property endorsement ' +
    'or Valuables Plus<sup>&reg;</sup> policy.',
  UnscheduledJewelryWatchesFurs:
    'Non-specific coverage for jewelry/watches and fur garments/garments lined with fur.<br><em>Coverage limit can be increased in increments of $500 up to $6,500.</em>',
  UnscheduledMoney:
    'Pays up to the special limit on the declarations page of the ' +
    'policy for the physical loss of money, bank notes, bullion, gold ' +
    'other than goldware, silver other than silverware, platinum, coins ' +
    'and medals.<br><em>Coverage limit can be increased in increments of $100 up to $1,000.</em>',
  UnscheduledPersonalProperty:
    'Covers your personal property, such as furniture and clothing, ' +
    'and items that are not attached to or built into ' +
    'the residence. Covers these personal items for loss due to specific ' +
    'causes such as: fire, smoke, wind, theft and ' +
    'other causes as described in the policy. Taking inventory helps ' +
    "estimate the value of your home's contents.",
  UnscheduledSecurities:
    'Pays up to the special limit on the declarations page of the ' +
    'policy for the physical loss of securities, accounts, bills, deeds ' +
    'documents, evidences of debt, letters of credit, notes other than ' +
    'bank notes, passports, stamps and tickets.<br><em>Coverage limit can be increased in increments of $100 up to $3,000.</em>',
  UnscheduledSilverware:
    'Pays up to your selected limit for physical loss to silverware, ' +
    'goldware, and pewterware. There is a special limit of liability for ' +
    'a theft loss. For maximum protection, items should be insured under ' +
    'a scheduled personal property endorsement or Valuables Plus<sup>&reg;</sup> policy.' +
    '<br><em>Coverage limit can be increased in increments of $500 up to $10,000.</em>',
  UnscheduledTools:
    'Tools have a special limit of liability for a theft loss. ' +
    'For maximum protection, you can increase the theft limit ' +
    'for tools.',
  VD:
    'The last thing you want to worry about after being in an accident is coming up ' +
    'with hundreds of dollars to fix your vehicle. Vanishing Deductible&reg; will lower your ' +
    'Comprehensive and/or Collision deductible by $100 for every year of safe driving - up to $500 total. ' +
    'Initial $100 credit is added when this feature is purchased.',
  WaterBackupLimited:
    'Protects you against losses caused by water that backed up through ' +
    'sewers or drains, or overflows a sump pump, sump pump well, or other ' +
    'system designed to remove water from the foundation area. This is not ' +
    'flood insurance.',
  WatercraftCoverage:
    'Pays up to a specified limit for watercraft of all types, ' +
    'including their trailers, furnishings, equipment and outboard ' +
    'engines or motors.',
  WCD:
    'Waiver of Collision Deductible waives the deductible for Collision if the ' +
    'insured is hit by an identified uninsured motorist. A hit and run does not ' +
    'qualify as an uninsured motorist.',
  WindHailPerilDeductible:
    'This is a separate deductible to be paid in the event that a windstorm or hail causes the covered loss.',
  WL: 'This provides a portion of income lost from being unable to work due to a covered loss.',
  WorkersCompensation:
    "Provides a workers' compensation policy up to a fixed liability limit if a person on your property is injured while working. " +
    'This coverage is included for one occasional employee, there is an additional charge for full and part time employees.',
  WorkLossBenefitExclusion:
    'For 60+ customers, this is an option to waive coverage for lost wages from ' +
    'a covered accident that leaves the policy holder unable to work.',
  // Thank you for maintaining alphabetical order.
};

export const ProductSpecificCoverageHelpText: {
  [key: string]: { [key: string]: string };
} = {
  MSA: {
    PIPGuestOnly:
      'Guest PIP provides protection to the guest passengers in your vehicle in the event of an accident. It provides $10,000 coverage for medical expenses, ' +
      'lost wages and othe out-of-pocket costs. In Kentucky, if you choose to reject PIP for yourself, you must purchase Guest PIP. Pedestrian PIP of $10,000 ' +
      'is included and covers pedestrians other than you or a household member, who are injured after being struck by your motorcycle.',
    PIP:
      "Personal Injury Protection (PIP) covers medical expenses to you or other persons who are covered under your vehicle's policy, in the event of an accident, " +
      'regardless of who is at fault. PIP may also pay a certain amount for funeral costs, lost wages, and costs for household services. PIP has a $10,000 limit, ' +
      'regardless of the deductible you choose. ' +
      'Guest PIP provides protection to guest passengers in your vehicle in the event of an accident. It provides $10,000 coverage for medical expenses, lost wages ' +
      'and other out-of-pocket costs. Basic PIP provides coverage for you and your guest passengers. Guest PIP provides coverage for guest passengers only. If you ' +
      'select Guest PIP instead of Basic PIP, you will not have coverage for your own medical expenses, lost wages, or other out-of-pocket costs. ' +
      'Pedestrian PIP of $10,000 is included and covers pedestrians other than you or a household member, who are injured after being struck by your motorcycle. ' +
      'This coverage is mandatory unless you reject, in writing the limitation upon tort rights as allowed by Kentucky law. In Kentucky, a person who accepts PIP ' +
      'gives up the right to sue for bodily injury except under certain circumstances and up to the extent of the PIP benefits payable. A person may refuse to ' +
      'consent to the limitations of his/her tort rights by signing a PIP rejection that must be filed with the Department of Insurance.',
    GAPCOLL:
      'If your vehicle is a total loss after an accident, Gap coverage may pay the difference ' +
      'between the actual cash value and what you owe on the lease or loan.',
  },
  RV: {
    GAPCOLL:
      'If your vehicle is a total loss after an accident, Gap coverage may pay the difference ' +
      'between the actual cash value and what you owe on the lease or loan.',
  },
  Boat: {
    GAPCOLL:
      'If your vehicle is a total loss after an accident, Gap coverage may pay the difference ' +
      'between the actual cash value and what you owe on the lease or loan.',
    RENTRE:
      'Rental Reimbursement is an optional vehicle level coverage. ' +
      'Rental Reimbursement will become available for selection after Comprehensive and Collision is selected on the boat. ' +
      'Coverage is subject to an annual limit of $1,000. Note: no per day limit. ' +
      'Coverage is not available for Personal Watercraft or motor only boat rating units.',
    RSA:
      'Roadside Assistance endorsement for Boatowners provides a maximum limit of payment per disablement at $100. ' +
      'Roadside Assistance is an optional coverage that may be purchased for watercraft with COMP and COLL coverage with a trailer. ' +
      'The coverage reimburses expenses up to $100 two (2) times per policy year for off-water towing to the nearest qualified ' +
      'repair facility and necessary labor at the time and place of disablement, when the tow vehicle or watercraft trailer is disabled. No deductible applies.',
  },
  Homeowner: {
    CosmeticExcl: `Excludes coverage for a loss that alters the physical appearance of metal roof coverings.
      This exclusion does not apply to a loss by hail that results in the failure of the metal roof to perform its intended function of keeping out the elements over an extended period of time.`,
    EarthquakeBasicCoverage: `This is a coverage that protects against exterior damage caused by earthquake or volcanic eruption. Additional coverages may apply if selected.
      This limit directly matches the property Dwelling limit/reconstruction cost.`,
    HurricanePerilDeductible:
      'The percentage deductible amount is calculated based on dwelling amount. Insured is responsible for paying the deductible as part of a covered loss.',
  },
  Condominium: {
    EarthquakeAdditionsAlterations: `This limit reflects the current Dwelling liability coverage on your condo.
    This limit covers the cost of replacing built-in and attached items within your unit due to damage caused by earthquake or volcanic eruption,
    such as drywall, carpeting, cabinets, fixtures, lighting, and anything else related to the construction of the unit.
    The owner of the building itself will have coverage for the exterior of the structure, including the roof and any areas shared by all unit owners.`,
    EarthquakeBasicCoverage: `This is a coverage that protects against exterior damage caused by earthquake or volcanic eruption. Additional coverages may apply if selected.
    This limit directly matches the Personal Property liability coverage.`,
    HurricanePerilDeductible:
      'The percentage deductible amount is calculated based on personal property coverage value. Insured is responsible for paying the deductible as part of a covered loss.',
  },
  Tenant: {
    EarthquakeBasicCoverage: `This is a coverage that protects against exterior damage caused by earthquake or volcanic eruption. Additional coverages may apply if selected.
    This limit directly matches the Personal Property liability coverage.`,
    HurricanePerilDeductible:
      'The percentage deductible amount is calculated based on personal property coverage value. Insured is responsible for paying the deductible as part of a covered loss.',
    BasicCoverage: '',
  },
};

export const StateSpecificCoverageHelpText: {
  [key: string]: { [key: string]: string };
} = {
  AL: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  AR: {
    LossSettlementProvision:
      '<strong>Replacement cost:</strong> We will settle covered losses to the dwelling without regard for the limit of liability shown in the Dwelling amount.',
  },
  CA: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  CO: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  CT: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
    LossSettlementProvision:
      '<strong>Replacement cost:</strong> We will settle covered losses to the dwelling without regard for the limit of liability shown in the Dwelling amount.',
  },
  DE: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  FL: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  GA: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
    UMBIOption:
      'Reduced By ("Traditional" Uninsured Motorist Coverage) - means the limit of Uninsured Motorist Coverage that ' +
      'you carry is reduced or "off-set" by any amounts payable to you under any other available bodily injury or property damage liability insurance.' +
      ' Therefore, if an insurance payment is received from the at-fault party, it will count towards your  benefit amount.' +
      ' Added To ("New" Uninsured Motorist Coverage) - means the entire limit of Uninsured Motorist Coverage is made available to you in' +
      ' "excess" of any amounts payable under available bodily injury or property damage liability insurance. An insurance payment received from' +
      ' the at-fault party will not be counted against your limit of Uninsured Motorist Coverage (there is no "off-set").',

    UMPDOption:
      'Reduced By ("Traditional" Uninsured Motorist Coverage) - means the limit of Uninsured Motorist Coverage that ' +
      'you carry is reduced or "off-set" by any amounts payable to you under any other available bodily injury or property damage liability insurance.' +
      ' Therefore, if an insurance payment is received from the at-fault party, it will count towards your  benefit amount.' +
      ' Added To ("New" Uninsured Motorist Coverage) - means the entire limit of Uninsured Motorist Coverage is made available to you in' +
      ' "excess" of any amounts payable under available bodily injury or property damage liability insurance. An insurance payment received from ' +
      ' the at-fault party will not be counted against your limit of Uninsured Motorist Coverage (there is no "off-set").',
  },
  IL: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  KS: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  KY: {
    PIP:
      'Personal Injury Protection coverage pays for the Member, their family members ' +
      "or non-related passenger's medical expenses in the event of an accident, regardless " +
      'of who is at fault. This coverage may also pay for funeral cost, death benefit, ' +
      'lost wages, and costs for household services. ' +
      'This coverage is mandatory unless you reject, in writing the limitation upon tort ' +
      'rights as allowed by Kentucky law. In Kentucky, a person who accepts PIP gives up the right ' +
      'to sue for bodily injury except under certain circumstances and up to the extent of the PIP ' +
      'benefits payable. A person may refuse to consent to the limitations of his/her tort rights by ' +
      'signing a PIP rejection that must be filed with the Department of Insurance.',
    TORT:
      'This optional coverage allows you to seek compensation for you and people in your ' +
      'household for medical and other out-of-pocket expenses due to injuries caused by ' +
      'other drivers. This coverage applies to both Personal Injury Protection (PIP) and Additional Personal Injury Protection (APIP).',
    PEDPIP:
      'Pedestrian PIP provides protection to pedestrians (other than you or a household member) who are injured ' +
      'after being struck by your motorcycle. It provides $10,000 in coverage for medical expenses, lost wages, ' +
      'and other out of pocket costs.',
  },
  MD: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  ME: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  MI: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  MS: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  NE: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  NH: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  NJ: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  NM: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  NV: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  NY: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
    UMBIOption:
      '<b>Statutory Uninsured Motorists Protection</b> has a limit of 25/50 only and provides coverage for accidents occurring within the state of New York.<br>' +
      '<b>Supplementary Uninsured Motorists Protection</b> provides coverage for accidents occurring in and outside the state of New York, and is available ' +
      'up to the BI limits of the policy.',
  },
  OH: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  OK: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  PA: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  RI: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  TN: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  TX: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  VA: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
    RSA:
      'Roadside Assistance provides limited reimbursement for 24/7 emergency help if you need fuel delivery, a tire change, ' +
      'jumpstart or tow (or use our Roadside Assistance Representative). The Plus option adds trip interruption ' +
      '(food, lodging, transport assistance for covered losses more than 100 miles from home and up to $600 with no sub limits), ' +
      'and increased roadside assistance reimbursement. See endorsement for details, limits and exclusions.',
  },
  VT: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
  WA: {
    UMBI:
      'Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.',
  },
};

export const AnnualMilesHelpText = {
  ALL:
    'The estimated annual mileage for this vehicle is suggested to the right. ' +
    'Please verify and adjust if needed. See help content for more details.',
  CA:
    'California regulations require the applicant to provide us with the ' +
    'estimated number of miles the applicant expects this vehicle will be ' +
    'driven during the 12 month period following policy issuance.',
};

export const MSBDisclaimerMessage = {
  Customer:
    "To review or edit any of the home's features including:  Site Access, Wall Heights, " +
    'HVAC, Interior Walls, Interior Flooring, or Interior Ceilings, please call {phoneNumber}.',

  Agent:
    "To review or edit any of the home's features including:  Site Access, Wall Heights, " +
    'HVAC, Interior Walls, Interior Flooring, or Interior Ceilings, please pivot to PolicyCenter.',
};

export const EarthquakeCoverageMessage = {
  Customer:
    'You will be receiving an Earthquake offer in the mail, ' +
    "if you'd like to purchase today, please call {phoneNumber}. " +
    'The offering of Earthquake coverage is subject to eligibility and underwriting guidelines.',
  Agent:
    "An Earthquake policy offering will be sent to the insured via mail, if you'd like to write " +
    'a policy today, please transfer to PolicyCenter. ' +
    'The offering of Earthquake coverage is subject to eligibility and underwriting guidelines.',
};

export const PIPWorkLossWaiver = {
  Customer:
    'PIP Work Loss benefits are included.  If you are 65 years old or 60 years old and receiving ' +
    'a pension you may waive work loss benefits by contacting us at {phoneNumber}.',
  Agent:
    'PIP Work Loss benefits are included.  If you would like to waive PIP Work Loss, please transfer to PolicyCenter.',
};

export const PIPEntireHouseholdExcluded = {
  Customer:
    'To exclude Personal Injury Protection for the entire household, please call {phoneNumber}.',
  Agent:
    'To exclude Personal Injury Protection for the entire household, please pivot to PolicyCenter.',
};

// Dwelling Replacement Cost Options BEGIN
export const DwellingReplacementCostVerbiage =
  'I have offered the applicant dwelling replacement cost options H 03 34 CA (150%) and H 03 34 CA (200%) if eligible for these coverages. ' +
  'Also, I have explained Other structures coverage to applicant, including the limits available, for applicant to determine if the ammount ' +
  'of such coverages selected is sufficient for their needs.';

export const WorkersCompensationHelpText =
  'This coverage assumes 1 occasional employee. Please pivot to PolicyCenter to add full or part time employees.';

export const CustomEquipmentHelpText =
  'Premium already included for up to $3000. Additional premium charge is for coverage in excess of $3000. Maximum acceptable value is $30000.';
export const FishingEquipmentHelpText =
  "Fishing equipment limit may vary based on 'package' coverage selection.";
export const AdditionalCoveragesDisplayHelpText = {
  UnscheduledMoney:
    'Coverage limit can be increased in increments of $100 up to $1,000.',
  UnscheduledSecurities:
    'Coverage limit can be increased in increments of $100 up to $3,000.',
  UnscheduledJewelryWatchesFurs:
    'Coverage limit can be increased in increments of $500 up to $6,500.',
  UnscheduledSilverware:
    'Coverage limit can be increased in increments of $500 up to $10,000.',
  ProtectionBoost:
    'Selecting Protection Boost increases specified special limits of liability.',
};

export const SmartHomeHelpText = {
  labelInnerHTML:
    '<strong><font color=black>Smart home program</strong></font> (optional)',
  SmartHome:
    'A home monitoring system can alert you of a potential issue before ' +
    'it becomes a bigger problem, such as a water leak, fire or intruder. ' +
    '<strong>Requires:</strong> ' +
    '<font color=#575757><ul><li>The purchase of a smart home monitoring system from a third-party ' +
    'provider at a discount price</li><li>A mobile device, Wi-Fi, and third-party ' +
    'app</li><li>Installation and activation within 55 days of enrolling</li></ul></font>',
};

export const PageHelperText = {
  UNDERWRITING_DISCLAIMER:
    'Products underwritten by Nationwide Mutual Insurance Company and ' +
    'Affiliated Companies. Not all Nationwide affiliated companies are ' +
    'mutual companies, and not all Nationwide members are insured by a ' +
    'mutual company. Subject to underwriting guidelines, review, and ' +
    'approval. Products and discounts not available to all persons in all ' +
    'states. Nationwide Investment Services Corporation, member FINRA. Home ' +
    'Office: One Nationwide Plaza, Columbus, OH. Nationwide, the Nationwide ' +
    'N and Eagle, and other marks displayed on this page are service marks ' +
    'of Nationwide Mutual Insurance Company, unless otherwise disclosed.',
  UNDERWRITING_DISCLAIMER__ADDON_DOUBLE_ASTERIK: '** ',
  UNDERWRITING_DISCLAIMER_ADDON:
    'Guarantees are subject to the claims paying ability of Insurance Company ' +
    'and Nationwide Life and Annuity Insurance Company. ' +
    'As personal situations change (i.e., marriage, birth of a child or job promotion), ' +
    'so will life insurance needs. Care should be taken to ensure this product ' +
    'is suitable for long-term life insurance needs. You should weigh any associated ' +
    'costs before making a purchase. Life insurance has fees and charges associated ' +
    'with it that includes costs of insurance that vary with such characteristics ' +
    'of the insured as gender, health and age, and have additional charges for ' +
    'riders that customize a policy to fit individual needs.',
};

export const AllPerilWindHailDeductibleHelpText =
  'All Peril and/or Wind/Hail deductibles have changed to meet the product requirements';

export const LimitedRoofLossHelpText =
  'Limited Roof coverage has been added to the quote due to the roof age and roof type.';

export const UnderWritingDisclaimerReplacement: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  WA: {
    Auto:
      'Products underwritten by Nationwide Mutual Insurance Company, Columbus, Ohio and ' +
      'Affiliated Companies. Not all Nationwide affiliated companies are ' +
      'mutual companies, and not all Nationwide members are insured by a ' +
      'mutual company. Subject to underwriting guidelines, review, and ' +
      'approval. Products and discounts not available to all persons in all ' +
      'states. Nationwide Investment Services Corporation, member FINRA. Home ' +
      'Office: One Nationwide Plaza, Columbus, OH. Nationwide, the Nationwide ' +
      'N and Eagle, and other marks displayed on this page are service marks ' +
      'of Nationwide Mutual Insurance Company, unless otherwise disclosed.',

    Property:
      'Products underwritten by National General Insurance Company, Columbus, Ohio and ' +
      'Affiliated Companies. Not all Nationwide affiliated companies are ' +
      'mutual companies, and not all Nationwide members are insured by a ' +
      'mutual company. Subject to underwriting guidelines, review, and ' +
      'approval. Products and discounts not available to all persons in all ' +
      'states. Nationwide Investment Services Corporation, member FINRA. Home ' +
      'Office: One Nationwide Plaza, Columbus, OH. Nationwide, the Nationwide ' +
      'N and Eagle, and other marks displayed on this page are service marks ' +
      'of Nationwide Mutual Insurance Company, unless otherwise disclosed.',

    BothProducts:
      'Products underwritten by Nationwide Mutual Insurance Company and/or ' +
      'National General Insurance Company, Columbus, Ohio and ' +
      'Affiliated Companies. Not all Nationwide affiliated companies are ' +
      'mutual companies, and not all Nationwide members are insured by a ' +
      'mutual company. Subject to underwriting guidelines, review, and ' +
      'approval. Products and discounts not available to all persons in all ' +
      'states. Nationwide Investment Services Corporation, member FINRA. Home ' +
      'Office: One Nationwide Plaza, Columbus, OH. Nationwide, the Nationwide ' +
      'N and Eagle, and other marks displayed on this page are service marks ' +
      'of Nationwide Mutual Insurance Company, unless otherwise disclosed.',
  },
};

export const DrivingHistoryHelpText = {
  labelInnerHTML: 'Driving history',
  DrivingHistory:
    '<strong>Elite</strong> – Driver has not had any accidents, violations or claims in the last 5 years.<br>' +
    '<strong> Good </strong> – Driver has not had more than one minor accident, violation or claim in the last ' +
    '3 years and has not had any major accidents, violations or claims in the last 3 years.<br>' +
    '<strong> None </strong> – Driver has had 2 or more minor accidents, violations, or claims in ' +
    'the last 3 years and/or driver has had one or more major accident, claim, or violation in the last 3 years.<br>',
};

export const AssignedRiskPlanHelpText = {
  labelInnerHTML:
    '<strong><font color=black>Is the current auto policy a State Automobile Insurance Plan?</strong>',
  assignedRiskPlan:
    "New York's Automobile Insurance Plan (NYAIP) offers auto liability and physical damage coverages to drivers " +
    'unable to obtain in the voluntary market.',
};

export const PreBindDocumentsContent = {
  PAGE_HEADER: '{state} Required Notices',
  AGENT: {
    HEADER:
      'In the state of Pennsylvania the customer must be presented with the following and/or discuss these notices prior to binding new business:',
    FOOTER:
      'The customer has been sent the required PA pre-bind documents at <b>{pniEmail}</b>. Please share the following password with the customer ' +
      'so they are able to access the documents: <b>{docPassword}</b>',
    RECEIVED_DOCS_TEXT:
      'I have confirmed that the customer has received the Pennsylvania notices.',
    VIEWED_DOCS_TEXT:
      'I have confirmed that the customer was given the opportunity to review and discuss any questions they had on the documents prior to binding.',
  },
  DIRECT: {
    HEADER:
      'In the state of Pennsylvania we must provide the following notices prior to binding new business:',
    FOOTER:
      'We have sent the required PA pre-bind documents to <b>{pniEmail}</b>. Please use the following password to access the documents: ' +
      '{docPassword}' +
      ' If you have questions regarding these documents, please call: {phoneNumber}.',
    RECEIVED_DOCS_TEXT:
      'I confirm that I have received the Pennsylvania notices.',
    VIEWED_DOCS_TEXT:
      'I confirm that I was given the opportunity to review the documents prior to binding and I do not have any questions.',
  },
};

export const HealthCarePlanHelpText = {
  labelInnerHTML:
    '<strong><font color="black">Type of Health Care Plan</strong>',
  HealthCarePlan:
    'Qualified health coverage* means either of the following:<br>' +
    '(i) Other health or accident coverage to which both of the following apply:<br>' +
    '&emsp;(A) The coverage does not exclude or limit coverage for injuries related to motor vehicle accidents.<br>' +
    '&emsp;(B) Any annual deductible for the coverage is $6,000.00 or less per individual. The director shall adjust the amount in ' +
    'this sub-subparagraph on July 1 of each year by the percentage change in the medical component of the Consumer Price Index ' +
    'for the preceding calendar year. However, the director shall not make the adjustment unless the adjustment, or the total ' +
    'of the adjustment and previous unadded adjustments, is $500.00 or more.<br>' +
    '(ii) Coverage under parts A and B of the federal Medicare program established under subchapter XVIII of the social security ' +
    'act, 42 USC 1395 to 1395lll.',
};

export const PipExcludedHelpText = {
  labelInnerHTML:
    '<strong><font color="black">Should Personal Injury Protection be excluded for this driver?</strong>',
  pipExcluded:
    'In order to exclude an individual driver Option 4 on the PIP Selection form must be selected.<br>' +
    'This includes selecting a PIP Medical limit of $250,000.<br>' +
    'The Named insured who wishes to exclude PIP Medical must have Qualified Health Coverage that is not Medicare.<br>' +
    'Any resident relative or spouse who wishes to exclude PIP Medical must have Qualified Health Coverage.<br>' +
    'The PIP selection form and proof of Qualified Health Coverage is required if any driver is excluded.',
};

export const TRANSPORTATION_EXPENSE_HELP_TEXT = `<b>Optional Limits Transportation Expenses,</b> saves you time and hassle by providing access to a rental car or other transportation while your car is in the repair shop after a covered accident, in accordance with the terms of your policy. Without it, you'll need to find another way to work, the store or around town.`;

export const RCPELIMIT_HELP_TEXT = `$250 deductible applies`;
export const LOADING = {
  PNI_NEW: 'Updating PNI and product information...',
  PNI_UPDATE: '',
};

export const COMP_COLL_DECLINED_ADDITIONAL_INTEREST_TEXT =
  "Comprehensive and/or Collision coverage are required on any vehicle with a Third Party Type of lessor or lienholder.  Please update the <VEH_YEAR> <VEH_MAKE> <VEH_MODEL>'s coverages.";

export const NonNWXProductPivotToPolicyCenterHeaderText =
  'Next Steps to continue in PolicyCenter';

export const NonNWXProductPivotToPolicyCenterText = `In order to continue with the products you have selected, follow the link below to the Account page in PolicyCenter to complete your quote.
Tap "New Submission" to add these products with this customer's account.`;

export const NonNWXProductPivotToPolicyCenterButtonText =
  'Continue to PolicyCenter account';

export const UnsupportedProductText =
  "We are working hard to enable full support for writing <product> policies in Nationwide Express. \n\n Until then, you will still need to use PolicyCenter to quote and bind this policy. After issuing policies for all supported products that you have selected through Nationwide Express, you will be provided a link to this member's Account page in PolicyCenter, where you can easily add and issue a <product> policy as well. \n\nWhen we have enabled full support for this product in Nationwide Express, this screen is where you should expect to provide relevant information. \n Thank you for your partnership!";

export const RvUtilityTrailerHelpText =
  'The maximum value for Utility Trailer cannot exceed $50,000 and maximum value for Car Hauler cannot exceed $200,000.';
export const AutoUtilityTrailerHelpText =
  'Trailers exceeding $12,000 in value must be rated on a Recreational Vehicle Policy.';

export const OnlineAccountRegHelpText =
  'Customers opted for paperless document delivery must set up an online account or they will lose the paperless discount if applicable';

export const MSB_HELP_CONTENT =
  'If you have an existing estimate through MSB, it can be entered here to return the existing construction information to the property page.';

export const MSB_ADDITIONAL_HELP_CONTENT =
  '  If we cannot find an estimate with the number entered here, a new estimate will be ordered for you.';
