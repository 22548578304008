import {
  FeatureFlagCriteria,
  FeatureFlagId,
} from '@core/store/entities/feature-flag/feature-flag.model';

export type StateSpecificFlagsModel = {
  [key in FeatureFlagId]: FeatureFlagCriteria;
};

export const stateSpecificFlags: StateSpecificFlagsModel = {
  ageFirstLicensed: {
    states: ['ALL'],
    offerings: ['PersonalAuto', 'MSA', 'Boat'],
  },
  antiLockBrakesDiscount: {
    states: ['FL', 'NY'],
    offerings: ['PersonalAuto', 'RV'],
  },
  antiTheftDevices: {
    states: ['FL', 'IL', 'MN', 'NM', 'NY', 'PA', 'KY'],
    offerings: ['PersonalAuto'],
  },
  antiTheftDevicesRV: {
    states: ['FL', 'IL', 'KY', 'MI', 'MN', 'NJ', 'NM', 'NY', 'PA', 'RI'],
    offerings: ['RV'],
  },
  assignedRiskPlan: { states: ['NY'], offerings: ['PersonalAuto'] },
  autoAnnualMiles: {
    states: [
      'AK',
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'HI',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'KY',
      'LA',
      'MA',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'NC',
      'ND',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    offerings: ['PersonalAuto'],
  },
  automaticTirePressure: {
    states: [
      'AL',
      'AZ',
      'CO',
      'CT',
      'DE',
      'FL',
      'IA',
      'IL',
      'KS',
      'MD',
      'MI',
      'MN',
      'MO',
      'MT',
      'NC',
      'ND',
      'NH',
      'NV',
      'NY',
      'OH',
      'OR',
      'PA',
      'SC',
      'SD',
      'TX',
      'WA',
      'WV',
      'WY',
    ],
    offerings: ['RV'],
  },
  autoNoNeedForPriorInsurance: {
    states: [
      'CO',
      'DC',
      'GA',
      'IN',
      'KY',
      'MI',
      'MN',
      'MT',
      'NH',
      'OK',
      'OR',
      'TN',
      'TX',
      'UT',
    ],
    offerings: ['PersonalAuto'],
  },
  auxiliaryLighting: { states: ['MN'], offerings: ['MSA'] },
  carpoolDiscountVisible: {
    states: ['DE'],
  },
  currentCarrierDefaults: { states: ['CA'], offerings: ['MSA'] },
  currentCarrierRequired: {
    states: [
      'AK',
      'AL',
      'AR',
      'AZ',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'HI',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'KY',
      'LA',
      'MA',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'NC',
      'ND',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    offerings: ['PersonalAuto', 'MSA', 'RV', 'Boat'],
  },
  dateFirstLicensedOfLeastExperiencedDriver: {
    states: ['NC'],
    offerings: ['PersonalAuto'],
  },
  daytimeRunningLampsDiscount: { states: ['NY'] },
  driverOccupation: { states: ['CA'], offerings: ['PersonalAuto'] },
  driverRelationToPNIOptions: { states: ['MI'], offerings: ['PersonalAuto'] },
  driverTypeExcludedNotAllowed: {
    states: ['KS', 'KY', 'MN', 'NC', 'NJ', 'NV', 'NY', 'OK', 'VA', 'VT', 'WI'],
    offerings: ['PersonalAuto'],
  },
  driverTypeExcludedNotAllowedPS: {
    states: ['KS', 'KY', 'MN', 'NC', 'NV', 'NY', 'VA', 'VT'],
    offerings: ['MSA', 'RV'],
  },
  driverVehicleAssignment: {
    states: ['VA', 'CA', 'NC', 'NY', 'MN', 'DE'],
    offerings: ['PersonalAuto'],
  },
  driverVehicleAssignmentIsPrimaryDriverRequired: {
    states: ['CA'],
    offerings: ['PersonalAuto'],
  },
  dwellingReplacementCost: { states: ['CA'] },
  earthquakeCoverageEligible: {
    states: ['CA', 'MO'],
  },
  earthquakeCoverageMessage: {
    states: ['CA', 'MO'],
    offerings: ['Condominium', 'Homeowner'],
  },
  employmentInfo: { states: ['CA'], offerings: ['PersonalAuto'] },
  excludeDriversFromPIP: { states: ['MI'] },
  footerUnderwritingDisclaimer: { states: ['WA'] },
  hasMotorcycleEndorsement: { states: ['CA'], offerings: ['MSA'] },
  healthCarePlan: { states: ['MI'], offerings: ['PersonalAuto', 'RV'] },
  infractionDesc: { states: ['CA'], offerings: ['PersonalAuto'] },
  isBiologicalDeteriorationCoverageApplicable: { states: ['NY'] },
  isDeviceOnlyState: { states: ['NY', 'CA'] },
  isEligibleDiscountDriverRequest: {
    states: [
      'AL',
      'AR',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'ID',
      'IL',
      'KS',
      'KY',
      'LA',
      'ME',
      'MN',
      'MS',
      'MT',
      'NV',
      'NM',
      'NY',
      'ND',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'TN',
      'UT',
      'VA',
      'WA',
      'WV',
      'WY',
    ],
    offerings: ['PersonalAuto', 'RV'],
  },
  isNationwideAssociateVisible: {
    states: [
      'AK',
      'AL',
      'AR',
      'AZ',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'HI',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'KY',
      'LA',
      'MA',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MT',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'NC',
      'ND',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'SD',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    offerings: [
      'PersonalAuto',
      'MSA',
      'Homeowner',
      'Condominium',
      'Tenant',
      'RV',
      'Boat',
    ],
  },
  isPipExcluded: { states: ['MI'] },
  msaAnnualMiles: { states: ['CA'], offerings: ['MSA'] },
  msaDriverTrainingCourse: {
    states: [
      'AL',
      'AR',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'ID',
      'KS',
      'KY',
      'LA',
      'ME',
      'MS',
      'MT',
      'NV',
      'NM',
      'NY',
      'OH',
      'OK',
      'OR',
      'RI',
      'SC',
      'TN',
      'UT',
      'VA',
      'WA',
      'WV',
      'WY',
    ],
    offerings: ['MSA'],
  },
  msaDriverVehicleAssignment: {
    states: ['NC'],
    offerings: ['MSA'],
  },
  msaNoNeedForPriorInsurance: {
    states: [
      'CO',
      'DC',
      'GA',
      'IN',
      'KY',
      'MI',
      'MN',
      'MT',
      'NH',
      'OR',
      'TN',
      'TX',
      'UT',
    ],
    offerings: ['MSA'],
  },
  msaVehicleDailyUseQuestions: {
    states: ['NC'],
    offerings: ['MSA'],
  },
  msaVehicleIsHistorical: { states: ['MI', 'NY'], offerings: ['MSA'] },
  msaYouthfulDriver: { states: ['AR', 'GA'], offerings: ['MSA'] },
  msbDisclaimerMessage: { states: ['CA'] },
  nonNWCondoDiscountVisible: {
    states: ['MD', 'DE', 'VA'],
    offerings: ['PersonalAuto'],
  },
  nonNWHomeownerDiscountVisible: {
    states: ['AL', 'FL', 'TX', 'MA', 'MD', 'NC', 'SC', 'DE', 'VA'],
    offerings: ['PersonalAuto'],
  },
  nonStandardBridgeAllowed: {
    states: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'KY',
      'MD',
      'MI',
      'MN',
      'MO',
      'MS',
      'NC',
      'NH',
      'NM',
      'NV',
      'OH',
      'OR',
      'PA',
      'RI',
      'SC',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
    ],
    offerings: ['PersonalAuto'],
  },
  nonStandardDefaultPipDeductible: { states: ['DE'] },
  noPaperlessDiscount: {
    states: ['CA', 'WA'],
  },
  offeringTypeApplicable: { states: ['NC'], offerings: ['Homeowner'] },
  passiveDisablingOnly: { states: ['PA'] },
  pipWorkLossWaiver: { states: ['MN'] },
  preBindDocuments: { states: ['PA'], offerings: ['PersonalAuto', 'MSA'] },
  productBlockedLicenseStatus: { states: ['CA'], offerings: ['MSA'] },
  removeEftPaymentOption: { states: ['TX'] },
  removeManualEftPaymentOption: { states: ['GA', 'NJ', 'NY', 'TX'] },
  requireAdvancedAccidentPrevention: {
    states: ['DE'],
    offerings: ['PersonalAuto', 'MSA', 'RV'],
  },
  requiredMSAUSArmedForces: { states: ['KY', 'MT'], offerings: ['MSA'] },
  requiredOilTank: {
    states: ['NJ'],
    offerings: ['Homeowner', 'Condominium'],
  },
  requiredUSANationalGuard: {
    states: ['MT'],
    offerings: ['PersonalAuto', 'RV'],
  },
  requiredUSArmedForces: { states: ['KY'], offerings: ['PersonalAuto', 'RV'] },
  residentStudentBasedOnYearsLicensed: {
    states: ['CA'],
    offerings: ['PersonalAuto'],
  },
  residentStudentByAgeExcluded: {
    states: ['CA', 'NC'],
    offerings: ['PersonalAuto'],
  },
  restrictAllProducts: { states: ['AK', 'HI', 'LA', 'MA', 'OK'] },
  restrictBoatProduct: { states: ['NJ'] },
  restrictHOCondoProductForIA: { states: ['FL'] },
  restrictMSAProduct: { states: ['NJ'] },
  restrictPropertyProductsForNSS: { states: ['FL'] },
  restrictRVProduct: { states: ['NJ'] },
  restrictUmbrellaProduct: { states: ['FL'] },
  restrictWindstormProtectiveDeviceToHomeowners: { states: ['CT'] },
  reviewConstructionInfo: { states: ['CA'] },
  rvDriverTrainingCourse: {
    states: [
      'AL',
      'AR',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'ID',
      'IL',
      'KS',
      'KY',
      'LA',
      'ME',
      'MS',
      'MT',
      'NV',
      'NM',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'TN',
      'UT',
      'VA',
      'WA',
      'WV',
      'WY',
    ],
    offerings: ['RV'],
  },
  rvDriverVehicleAssignment: {
    states: ['NY'],
    offerings: ['RV'],
  },
  rvGoodStudent: { states: ['AR', 'GA'], offerings: ['RV'] },
  rvPassiveRestraintDiscount: {
    states: ['FL', 'NV', 'NY', 'PA'],
    offerings: ['RV'],
  },
  rvRegistrationState: {
    states: ['DC', 'NY', 'WA'],
    offerings: ['RV'],
  },
  rvUmpdAndCollMutuallyExclusive: {
    states: [
      'CO',
      'CT',
      'ID',
      'KY',
      'ME',
      'MO',
      'MT',
      'ND',
      'NE',
      'NH',
      'UT',
      'WA',
      'WI',
      'AL',
      'AZ',
      'FL',
      'IA',
      'KS',
      'MI',
      'MN',
      'NV',
      'NY',
      'OH',
      'PA',
      'SD',
      'WY',
    ],
    offerings: ['RV'],
  },
  rvVehicleIsHistorical: { states: ['NY'], offerings: ['RV'] },
  rvWeightRequired: { states: ['NY', 'PA'], offerings: ['RV'] },
  safetyCourse: { states: ['CA', 'CT', 'OR', 'TN'], offerings: ['MSA'] },
  safetyCourseBoat: { states: ['CT', 'DE'], offerings: ['Boat'] },
  selfReportedProgram: { states: ['CA'] },
  showAdditionalHelpTextForCoverages: { states: ['NC'] },
  showAdditionalProtectionDevices: { states: ['NC'] },
  showArsonQuestionInPropertyDisqualifier: { states: ['RI'] },
  showCondoRenovation: { states: ['MN'] },
  showDogsInPropertyDisqualifier: {
    states: ['CT', 'FL', 'GA', 'IL', 'NC', 'NJ', 'PA', 'VA', 'VT'],
  },
  showDwellingReplacementCost: { states: ['CA'] },
  showFullPayDiscountBoat: {
    states: [],
    offerings: ['Boat'],
  },
  showFullPayDiscountCondominium: {
    states: ['AR', 'CO', 'ID', 'IL', 'MO', 'NH', 'OH', 'OR', 'SD', 'VA'],
    offerings: ['Condominium'],
    effectiveDate: {
      CO: '2024-11-01',
      OH: '2024-09-02',
      OR: '2024-11-03',
      VA: '2024-10-01',
    },
  },
  showFullPayDiscountHomeowner: {
    states: ['AR', 'CO', 'ID', 'IL', 'MO', 'NH', 'OH', 'OR', 'SD', 'VA'],
    offerings: ['Homeowner'],
    effectiveDate: {
      CO: '2024-11-01',
      OH: '2024-09-02',
      OR: '2024-11-03',
      VA: '2024-10-01',
    },
  },
  showFullPayDiscountMSA: {
    states: [],
    offerings: ['MSA'],
  },
  showFullPayDiscountPersonalAuto: {
    states: ['ALL'],
    offerings: ['PersonalAuto'],
  },
  showFullPayDiscountRV: {
    states: [],
    offerings: ['RV'],
  },
  showFullPayDiscountTenant: {
    states: ['AR', 'CO', 'ID', 'IL', 'MO', 'NH', 'OH', 'OR', 'SD', 'VA'],
    offerings: ['Tenant'],
    effectiveDate: {
      CO: '2024-11-01',
      OH: '2024-09-02',
      OR: '2024-11-03',
      VA: '2024-10-01',
    },
  },
  showGenderX: {
    states: ['CO', 'NJ', 'NY', 'OR', 'VA'],
    offerings: ['PersonalAuto'],
  },
  showGenderXPowersports: {
    states: ['NY', 'VA'],
    offerings: ['Boat', 'MSA', 'RV'],
  },
  showGolfCartValue: {
    states: ['NC'],
    offerings: ['MSA'],
  },
  showMembership: {
    states: ['MI'],
    offerings: ['PersonalAuto', 'Condominium', 'Homeowner', 'Tenant'],
  },
  showPropertyFourOne: {
    states: ['AR', 'CO', 'ID', 'IL', 'NH', 'OH', 'OR', 'SD'],
    offerings: ['Homeowner', 'Condominium', 'Tenant'],
    effectiveDate: {
      CO: '2024-11-01',
      OH: '2024-09-02',
      OR: '2024-11-03',
    },
  },
  showPropertyFourZero: {
    states: ['AR', 'CO', 'ID', 'IL', 'MO', 'NH', 'OH', 'OR', 'SD', 'VA'],
    offerings: ['Homeowner', 'Condominium'],
    effectiveDate: {
      CO: '2024-11-01',
      OH: '2024-09-02',
      OR: '2024-11-03',
      VA: '2024-10-01',
    },
  },
  showPropertyFourZeroAll: {
    states: ['ALL'],
    offerings: ['Homeowner', 'Condominium'],
  },
  showRecurringEFTDiscountBoat: {
    states: [],
  },
  showRecurringEFTDiscountMSA: {
    states: [],
  },
  showRecurringEFTDiscountPersonalAuto: {
    states: [
      'AR',
      'CT',
      'DE',
      'FL',
      'ME',
      'MI',
      'MT',
      'ND',
      'NE',
      'NJ',
      'NM',
      'NY',
      'OH',
      'PA',
      'SC',
      'SD',
      'UT',
      'TX',
      'WI',
    ],
    offerings: ['PersonalAuto'],
    effectiveDate: {
      AR: '2023-07-24',
      CT: '2023-12-03',
      FL: '2023-09-24',
      ME: '2024-04-07',
      MI: '2024-07-02',
      MT: '2024-06-05',
      ND: '2024-07-22',
      NE: '2023-04-08',
      NJ: '2023-11-05',
      NM: '2023-04-22',
      NY: '2024-05-23',
      SD: '2024-03-25',
      UT: '2023-07-22',
      WI: '2024-05-26',
    },
  },
  showRecurringEFTDiscountRV: {
    states: [],
  },
  showRoofDetails: {
    states: ['AR', 'CO', 'ID', 'IL', 'MO', 'NH', 'OH', 'OR', 'SD'],
    offerings: ['Homeowner', 'Condominium'],
    effectiveDate: {
      CO: '2024-11-01',
      OH: '2024-09-02',
      OR: '2024-11-03',
    },
  },
  sinkhole: { states: ['FL'] },
  smartMiles: {
    states: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'KY',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    offerings: ['PersonalAuto'],
  },
  smartrideAcceptAFMVF: { states: ['NC'] },
  smartRideInstantExcluded: {
    states: ['CA', 'MA', 'NY'],
    offerings: ['PersonalAuto'],
  },
  splitWindAndHail: {
    states: [
      'AL',
      'AZ',
      'AR',
      'CO',
      'CT',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'KY',
      'MO',
      'MI',
      'MN',
      'MS',
      'MT',
      'NE',
      'NM',
      'OH',
      'OR',
      'PA',
      'RI',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY',
    ],
    offerings: ['Homeowner'],
    effectiveDate: {
      MN: '2024-10-06',
    },
  },
  surchargeDisclosureStatement: { states: ['MN'], offerings: ['PersonalAuto'] },
  trueUmbrella: {
    states: [
      'AR',
      'CO',
      'IA',
      'IL',
      'IN',
      'MI',
      'NV',
      'OH',
      'RI',
      'SD',
      'TX',
      'UT',
      'VA',
      'WA',
      'WI',
      'WV',
    ],
    offerings: ['PersonalUmbrella'],
    effectiveDate: {
      IA: '2024-11-03',
      IL: '2024-11-03',
      NV: '2024-10-06',
      OH: '2024-11-03',
      RI: '2024-09-01',
      TX: '2024-11-03',
      VA: '2024-11-03',
    },
  },
  umbrellaLocationExposureDogsNotAllowed: {
    states: ['CO', 'NV'],
  },
  umbrellaLocationExposureNotEditable: {
    states: [],
  },
  vehicleCostNew: { states: [], offerings: ['PersonalAuto'] },
  vehicleDailyUseQuestions: {
    states: ['CA', 'NC'],
    offerings: ['PersonalAuto'],
  },
  vehicleFeaturesApplicable: {
    states: ['NY', 'FL'],
    offerings: ['PersonalAuto', 'MSA'],
  },
  vehicleMiles: { states: ['CA'], offerings: ['PersonalAuto'] },
  vehicleOdometerReading: { states: ['CA'], offerings: ['PersonalAuto'] },
  vehicleRegisteredInState: { states: ['NC'], offerings: ['PersonalAuto'] },
  vehicleRegisteredOwners: {
    states: ['NY', 'MD', 'NC'],
    offerings: ['PersonalAuto', 'MSA', 'RV'],
  },
  workersCompensationHelpText: { states: ['CA'] },
  yearsOfMotorcycleAndOffRoadExp: { states: ['CA'], offerings: ['MSA'] },
  // Thank you for maintaining alphabetical order.
};
