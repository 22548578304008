import { TaskModel, VehicleCoverageTaskParams } from '@entities/task/task.model';
import { COMP_COLL_DECLINED_ADDITIONAL_INTEREST_TEXT } from '@shared/constants/help-text-constants';
import { CoveragesPageRepresentation } from '@core/constants/pages';
import { CoverageCodes, CoverageIds } from '@shared/constants/app-constants';
import { FullTimePrimaryUses, PrimaryUseType } from '@core/models/views/vehicle.model';
import { VehicleEntity } from '@entities/vehicle/vehicle.entity';
import { VehicleUtils } from '@shared/utils/vehicle.utils';
import { ScheduledCategoryItem } from '@entities/scheduled-categories/scheduled-category.entity';

export class VehicleTaskValidators {
  static getDeclinedCompCollWithAdditionalInterestsTasks(params: VehicleCoverageTaskParams): TaskModel[] {
    let tasks: TaskModel[] = [];
    const autoProduct = params.products.find(
      (product) => product.type === 'PersonalAuto'
    );
    if (autoProduct) {
      const autoVehiclesWithAdditionalInterests = params.vehicles!.filter(
        (vehicle) =>
          vehicle.additionalInterests && vehicle.additionalInterests.length > 0
      );
      autoVehiclesWithAdditionalInterests.forEach((vehicle) => {
        const compCollCoverages = params.coverages?.filter(
          (cov) =>
            cov.coverableId === vehicle.vehicleId &&
            (cov.coverageId === 'COMP' || cov.coverageId === 'COLL')
        );
        const compCollNotSelected: boolean = compCollCoverages?.every(
          (cov) => cov.selectedValue[0]?.value === 'false'
        ) ?? false
        if (compCollNotSelected) {
          let message = COMP_COLL_DECLINED_ADDITIONAL_INTEREST_TEXT.replace(
            '<VEH_YEAR>',
            String(vehicle.year)
          );
          message = message.replace('<VEH_MAKE>', String(vehicle.make));
          message = message.replace('<VEH_MODEL>', String(vehicle.model));
          const vehicleIndex = params.vehicles!.findIndex(
            (veh) => veh.vehicleId === vehicle.vehicleId
          );
          tasks.push({
            page: CoveragesPageRepresentation,
            field: 'compDeductible',
            elementId: `vehicle-coverage-${vehicleIndex}`,
            entityType: 'coverage',
            productType: 'PersonalAuto',
            ratingStatusOrdinal: 'Draft',
            message: message,
            errorMessage:
              'Please update the comprehensive or collision coverage to rate.',
          });
        }
      });
    }
    return tasks;
  }

  static getRcpeWithFtssTasks(params: VehicleCoverageTaskParams): TaskModel[] {
    let coverageTasks: TaskModel[] = [];

    if (params.products.some((p) => p.type === 'RV')) {
      // policy specific
      const ftssCoverageSelected = params.coverages
        ?.find((coverage) => coverage.coverageId === CoverageIds.FTSS)
        ?.selectedValue?.find(
          (selVal) =>
            selVal.code === CoverageCodes.FTSSLimit && +selVal.value > 0
        );
      if (ftssCoverageSelected) {
        const rcpeCoverage = params.coverages?.find(
          (coverage) => coverage.coverageId === CoverageIds.RCPE
        );
        const rcpeCovSelected = rcpeCoverage?.selectedValue?.find(
          (selVal) =>
            selVal.code === CoverageCodes.RCPELimit && +selVal.value > 0
        );
        if (!rcpeCovSelected) {
          coverageTasks.push({
            page: CoveragesPageRepresentation,
            message:
              'Replacement Cost Personal Effects required on at least one Motorhome or Travel Trailer when Full Timers Secure Storage exists on the policy.',
            field: 'dwelling-replacement-cost-confirmation', // TODO FIX THIS
            elementId: 'dwelling-replacement-cost-verbiage',
            productType: 'RV',
            entityType: 'coverage',
            ratingStatusOrdinal: 'Draft',
            errorMessage: 'Required before quoting',
          });
        }
      }
    }

    return coverageTasks;
  }

  static getFtssRequiredWhenFullTimerTasks(params: VehicleCoverageTaskParams): TaskModel[] {
    let coverageTasks: TaskModel[] = [];
    if (!params.products.some((p) => p.type === 'RV')) {
      return [];
    }

    params.vehicles?.forEach((v) => {
      if (FullTimePrimaryUses.includes(v.primaryUse as PrimaryUseType)) {
        // if full timer package declined
        const fteCoverage = params.coverages?.find(
          (cov) =>
            cov.coverageId === CoverageIds.FTPKG &&
            cov.coverableId === v.vehicleId
        );
        if (
          fteCoverage?.selectedValue?.find(
            (selVal) => selVal.code === 'selected' && selVal.value === 'false'
          )
        ) {
          coverageTasks.push({
            page: CoveragesPageRepresentation,
            message:
              'Full Timers Package required if vehicle use is Full Timer',
            field: 'dwelling-replacement-cost-confirmation', // TODO fix this
            elementId: 'dwelling-replacement-cost-verbiage', // TODO Fix this
            productType: 'RV',
            entityType: 'coverage',
            ratingStatusOrdinal: 'Draft',
            errorMessage: 'Required before quoting',
          });
        }
      }
    });

    return coverageTasks;
  }

  static getRvMinimumDeductibleTasks(params: VehicleCoverageTaskParams): TaskModel[] {
    let coverageTasks: TaskModel[] = [];
    if (!params.products.some((p) => p.type === 'RV')) {
      return [];
    }

    params.vehicles?.forEach((vehicle) => {
      const compCov = params.coverages?.find(
        (coverage) =>
          coverage.coverageId === CoverageIds.COMP &&
          coverage.coverableId === vehicle.vehicleId
      );
      const collCov = params.coverages?.find(
        (coverage) =>
          coverage.coverageId === CoverageIds.COLL &&
          coverage.coverableId === vehicle.vehicleId
      );
      if (VehicleUtils.vehicleRequiresHighDeductible(vehicle)) {
        const compDeductible = compCov?.selectedValue.find(
          (selVal) => selVal.code === CoverageCodes.CompDeductible
        );
        const collDeductible = collCov?.selectedValue.find(
          (selVal) => selVal.code === 'COLLDeductible'
        );

        if (
          +(compDeductible?.value || 0) <
          VehicleUtils.getDeductibleMinByVehicleType(vehicle)
        ) {
          coverageTasks.push({
            page: CoveragesPageRepresentation,
            message: this.getMessageByVehicleType(vehicle, 'Comp.'),
            field: `COMP_${vehicle.vehicleId}_COMPDeductible`,
            elementId: 'ctc-type-COMPDeductible-RV', // TODO Fix this
            productType: 'RV',
            entityType: 'coverage',
            ratingStatusOrdinal: 'Draft',
            errorMessage: 'Required before quoting',
          });
        }
        if (
          +(collDeductible?.value || 0) <
          VehicleUtils.getDeductibleMinByVehicleType(vehicle)
        ) {
          coverageTasks.push({
            page: CoveragesPageRepresentation,
            message: this.getMessageByVehicleType(vehicle, 'Coll.'),
            field: `COLL_${vehicle.vehicleId}_COLLDeductible`,
            elementId: 'ctc-type-COLLDeductible-RV', // TODO Fix this
            productType: 'RV',
            entityType: 'coverage',
            ratingStatusOrdinal: 'Draft',
            errorMessage: 'Required before quoting',
          });
        }
      }
    });

    return coverageTasks;
  }

  static getRvScheduledPersonalEffectsTasks(params: VehicleCoverageTaskParams): TaskModel[] {
    let coverageTasks: TaskModel[] = [];
    if (!params.products.some((p) => p.type === 'RV')) {
      return [];
    }

    const speCoverageHasLimitSelected = params.coverages
      ?.find((c) => c.coverageId === CoverageIds.ScheduledPersonalEffects)
      ?.selectedValue?.some(
        (value) => value.code === 'SPELimit' && +value.value > 0
      );
    if (speCoverageHasLimitSelected) {
      const scheduledCategoriesForProduct =
        params.scheduledCategoryEntities?.['RV']?.scheduledCategories;
      let allPersonalEffectItems: ScheduledCategoryItem[] = [];
      scheduledCategoriesForProduct?.forEach((c) => {
        c.scheduledItems?.forEach((item) => {
          allPersonalEffectItems.push(item);
        });
      });
      if (!scheduledCategoriesForProduct || !allPersonalEffectItems.length) {
        coverageTasks.push({
          page: CoveragesPageRepresentation,
          field: 'value',
          entityType: 'coverage',
          productType: 'RV',
          ratingStatusOrdinal: 'Draft',
          elementId: 'scheduled-item-form',
          message:
            'Scheduled Personal Effects coverage requires at least one scheduled personal effect item',
        });
      }
    }

    return coverageTasks
  }

  private static getMessageByVehicleType(vehicle: VehicleEntity, coverage: string): string {
    switch (vehicle.vehicleType) {
      case 'Motorhome':
        return `${coverage} deductible must be $2,500 minimum for ${vehicle.year} ${vehicle.make}`;
      case 'TravelTrailer':
        return `${coverage} deductible must be $500 minimum for ${vehicle.year} ${vehicle.make}`;
      default:
        return '';
    }
  }
}
