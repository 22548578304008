import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VehicleExposureAdapter } from '@core/adapters/vehicle-exposure.adapter';
import {
  VehicleExcludedExposureRequest,
  VehicleExposureRequest,
} from '@core/models/api/request/vehicle-exposure-request.model';
import { Store } from '@ngrx/store';
import {
  deleteVehicleExposure,
  upsertVehicleExposure,
} from '@entities/exposures/vehicle-exposures/vehicle-exposures.action';
import {
  VehicleExposureAndProductInfo,
  VehicleExposureEntity,
} from '@entities/exposures/vehicle-exposures/vehicle-exposures.entity';
import {
  buildVehicleExposures,
  getVehicleExposureOptions,
  getVehicleExposureTiles,
  getVehicleExposuresAndProductInfo,
  vehicleExposuresExist,
} from '@entities/exposures/vehicle-exposures/vehicle-exposures.selector';
import { Nullable } from '@shared/utils/type.utils';
import { VehicleExposureOptionsModel } from '@entities/metadata/models/umbrella-options.model';
import { getVehicleExcludedExposureTiles } from '@entities/exposures/vehicle-excluded-exposures/vehicle-excluded-exposures.selector';
import {
  deleteVehicleExcludedExposure,
  upsertVehicleExcludedExposure,
} from '@entities/exposures/vehicle-excluded-exposures/vehicle-excluded-exposures.action';
import {
  VehicleExcludedExposureAndProductInfo,
  VehicleExcludedExposureEntity,
} from '@entities/exposures/vehicle-excluded-exposures/vehicle-excluded-exposures.entity';
import { UmbrellaTileModel } from '@shared/components/umbrella-tile/umbrella-tile.component';

@Injectable({
  providedIn: 'root',
})
export class VehicleExposureService {
  constructor(private adapter: VehicleExposureAdapter, private store: Store) {}

  addOrUpdateVehicleExposure(
    request: VehicleExposureRequest
  ): Observable<VehicleExposureEntity> {
    if (request.vehicleExposureBody.vehicleExposure.vehicleExposureId) {
      return this.adapter.updateVehicleExposure(request);
    } else {
      return this.adapter.addVehicleExposure(request);
    }
  }

  addOrUpdateExcludedVehicleExposure(
    request: VehicleExcludedExposureRequest
  ): Observable<VehicleExcludedExposureEntity> {
    if (
      request.vehicleExcludedExposureBody.vehicleExcludedExposure
        .vehicleExcludedExposureId
    ) {
      return this.adapter.updateVehicleExcludedExposure(request);
    } else {
      return this.adapter.addVehicleExcludedExposure(request);
    }
  }

  upsertVehicleExposure(VehicleExposure: VehicleExposureEntity): void {
    this.store.dispatch(upsertVehicleExposure({ payload: VehicleExposure }));
  }

  upsertVehicleExcludedExposure(
    vehicleExcludedExposure: VehicleExcludedExposureEntity
  ): void {
    this.store.dispatch(
      upsertVehicleExcludedExposure({ payload: vehicleExcludedExposure })
    );
  }

  removeVehicleExposure(vehicleExposure: VehicleExposureEntity): void {
    this.store.dispatch(deleteVehicleExposure({ payload: vehicleExposure }));
  }

  removeVehicleExcludedExposure(
    vehicleExcludedExposure: VehicleExcludedExposureEntity
  ): void {
    this.store.dispatch(
      deleteVehicleExcludedExposure({ payload: vehicleExcludedExposure })
    );
  }

  deleteVehicleExposure(request: VehicleExposureRequest): Observable<unknown> {
    return this.adapter.removeVehicleExposure(request);
  }

  deleteVehicleExcludedExposure(
    request: VehicleExcludedExposureRequest
  ): Observable<unknown> {
    return this.adapter.removeVehicleExcludedExposure(request);
  }

  getVehicleExposuresAndProductInfo(): Observable<
    VehicleExposureAndProductInfo[]
  > {
    return this.store.select(getVehicleExposuresAndProductInfo);
  }

  getVehicleExposureTiles(): Observable<UmbrellaTileModel[]> {
    return this.store.select(getVehicleExposureTiles);
  }

  getVehicleExcludedExposureTiles(): Observable<UmbrellaTileModel[]> {
    return this.store.select(getVehicleExcludedExposureTiles);
  }

  getVehicleExposureOptions(): Observable<
    Nullable<VehicleExposureOptionsModel>
  > {
    return this.store.select(getVehicleExposureOptions);
  }

  buildVehicleExposures(): Observable<VehicleExposureEntity[]> {
    return this.store.select(buildVehicleExposures);
  }

  vehicleExposuresExist(): Observable<boolean> {
    return this.store.select(vehicleExposuresExist);
  }
}
