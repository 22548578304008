import { createAction, props } from '@ngrx/store';
import {
  PowersportsPageSubmission,
  VehiclePageSubmission,
} from '../entities/vehicle/vehicle.entity';
import { PeoplePageSubmission } from '@app/people/pages/member/member-page.component';
import { PniPageSubmission } from '@app/pni/pages/pni/pni-page.component';
import { NationwideAccountRegistrationInfo } from '@core/models/api/dsm-types';

export const submitPeoplePage = createAction(
  '[People] Submit People Page',
  props<{
    submission: PeoplePageSubmission;
  }>()
);

export const submitPeoplePageError = createAction(
  '[People] Submit People Error',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitPeoplePageSuccess = createAction(
  '[People] Submit People Success',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitPniPage = createAction(
  '[PNI] Submit Pni Page',
  props<{ submission: PniPageSubmission }>()
);

export const submitPniPageError = createAction(
  '[PNI] Submit PNI Error',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitPniPageSuccess = createAction(
  '[PNI] Submit PNI Success',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitVehiclePage = createAction(
  '[Vehicle] Submit Vehicle Page',
  props<{ submission: VehiclePageSubmission }>()
);

export const submitVehiclePageError = createAction(
  '[Vehicle] Submit Vehicle Page Error',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitVehiclePageSuccess = createAction(
  '[Vehicle] Submit Vehicle Page Success',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitMsaPage = createAction(
  '[MSA] Submit MSA Page',
  props<{ submission: PowersportsPageSubmission }>()
);

export const submitMsaPageError = createAction(
  '[MSA] Submit MSA Page Error',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitMsaPageSuccess = createAction(
  '[MSA] Submit MSA Page Success',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitRVPage = createAction(
  '[RV] Submit RV Page',
  props<{ submission: PowersportsPageSubmission }>()
);

export const submitRVPageError = createAction(
  '[RV] Submit RV Page Error',
  props<{ payload: any }>()
);
export const retrieveVehicleInquirySuccess = createAction(
  '[Vehicle Inquiry] Retrieve Vehicle Inquiry Success',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitRVPageSuccess = createAction(
  '[RV] Submit RV Page Success',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitBoatPage = createAction(
  '[BOAT] Submit Boat Page',
  props<{ submission: PowersportsPageSubmission }>()
);

export const submitBoatPageError = createAction(
  '[BOAT] Submit Boat Page Error',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitBoatPageSuccess = createAction(
  '[BOAT] Submit Boat Page Success',
  props<{ payload: any }>()
);
export const retrieveVehicleInquiryError = createAction(
  '[Vehicle Inquiry] Retrieve Vehicle Inquiry Error',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitUmbrellaPage = createAction(
  '[Umbrella] Submit Umbrella Page'
);

export const submitUmbrellaPageError = createAction(
  '[Umbrella] Submit Umbrella Page Error',
  props<{ payload: any }>()
);

export const submitHomeownerPage = createAction(
  '[Homeowner] Submit Homeowner Page'
  //props<{ submission: HomeownerPageSubmission }>()
);

export const submitHomeownerPageError = createAction(
  '[Homeowner] Submit Homeowner Page Error',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitUmbrellaPageSuccess = createAction(
  '[Umbrella] Submit Umbrella Page Success',
  props<{ payload: any }>()
);

export const submitUmbrellaUnderlyingPoliciesSuccess = createAction(
  '[Umbrella] Submit Umbrella Underlying Policies Success',
  props<{ payload: any }>()
);

export const submitUmbrellaUnderlyingPoliciesError = createAction(
  '[Umbrella] Submit Umbrella Underlying Policies Error',
  props<{ payload: any }>()
);

export const submitHomeownerPageSuccess = createAction(
  '[Homeowner] Submit Homeowner Page Success',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitCondoPage = createAction(
  '[Condo] Submit Condo Page'
  //props<{ submission: CondoPageSubmission }>()
);

export const submitCondoPageError = createAction(
  '[Condo] Submit Condo Page Error',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitCondoPageSuccess = createAction(
  '[Condo] Submit Condo Page Success',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitTenantPage = createAction(
  '[Tenant] Submit Tenant Page'
  //props<{ submission: TenantPageSubmission }>()
);

export const submitTenantPageError = createAction(
  '[Tenant] Submit Tenant Page Error',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitTenantPageSuccess = createAction(
  '[Tenant] Submit Tenant Page Success',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);
export const submitAutoPage = createAction(
  '[PersonalAuto] Submit Auto Page'
  //props<{ submission: AutoPageSubmission }>()
);

export const submitAutoPageError = createAction(
  '[PersonalAuto] Submit Auto Page Error',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitAutoPageSuccess = createAction(
  '[PersonalAuto] Submit Autot Page Success',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const submitAccountRegistrationModal = createAction(
  '[All Products] Submit Account Registration Modal',
  props<{ registrationInfo: NationwideAccountRegistrationInfo }>()
);

export const submitAccountRegistrationModalSuccess = createAction(
  '[All Products] Submit Account Registration Modal Success'
);

export const submitAccountRegistrationModalError = createAction(
  '[All Products] Submit Account Registration Modal error',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const upsertUnderlyingPoliciesSuccess = createAction(
  '[Underlying Policy] Upsert all underlying policies success',
  props<{ payload: any }>()
);

export const upsertUnderlyingPoliciesError = createAction(
  '[Underlying Policy] Upsert all underlying policies error',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ payload: any }>()
);

export const restoreExposure = createAction(
  '[Underlying Policy] Restored exposures for restored underlying policy',
  props<{ payload: any }>()
);
