import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromLoading from '@core/store/loading/loading.reducer';

export const getLoadingState = createSelector(
  fromCore.getCoreState,
  (state) => state.loadingState
);

export const isAppLoading = createSelector(
  getLoadingState,
  (loadingState) => Object.keys(loadingState.inFlight).length > 0
);

export const selectAppLoadingMessage = createSelector(
  getLoadingState,
  (loadingState) => loadingState.message
);
