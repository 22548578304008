import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ProductType } from '@core/models/api/dsm-types';
import { ProductsService } from '@core/services/products.service';
import { ProductModel } from '@core/store/entities/product/product.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from '@shared/utils/date.utils';
import { ProductUtils } from '@shared/utils/product.util';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormHelper } from '@core/helper/form-helper';
import { StringUtils } from '@shared/utils/string.utils';
import { ComponentChanges } from '@shared/utils/general.utils';
import { Store } from '@ngrx/store';
import { getEffectiveDate } from '@core/store/entities/dsm/dsm.selector';
import { formatDate } from '@angular/common';

@Component({
  selector: 'nwx-effective-date-form',
  templateUrl: './effective-date-form.component.html',
  styleUrls: ['./effective-date-form.component.scss'],
})
export class EffectiveDateFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() products!: ProductModel[];
  @Input() product!: ProductModel;
  @Input() submitted!: boolean;
  @Input() prefill!: Subject<string>;

  @Output() valueChange = new EventEmitter<ProductModel>();
  @Output() formReady = new EventEmitter<FormGroup>();
  @Output() policyRemoved = new EventEmitter<ProductType>();

  minEffectiveDate!: string;
  maxEffectiveDate!: string;
  calendarMinDate!: string;
  camelCaseProductType: string = '';
  form!: FormGroup;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private productsService: ProductsService,
    private modalService: NgbModal,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.camelCaseProductType = StringUtils.convertProductTypeToCamelCase(
      this.product.type
    );

    this.form = this.buildForm(this.product);

    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged(this.hasChanges))
      .subscribe((changes: ProductModel) => {
        this.emitChanges(changes);
      });

    this.productsService
      .getPolicyDateOptions(this.product.type)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((policyDateOptions) => {
        this.minEffectiveDate = DateUtils.formatDsmDateToOld(
          policyDateOptions.minDate
        );
        this.maxEffectiveDate = DateUtils.formatDsmDateToOld(
          policyDateOptions.maxDate
        );
        this.calendarMinDate = DateUtils.formatDsmDateToOld(
          DateUtils.getCalendarMinDate(this.product, policyDateOptions)
        );
      });

    this.prefill
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(this.prefillValue.bind(this));

    this.formReady.emit(this.form);
  }

  ngOnChanges(changes: ComponentChanges<EffectiveDateFormComponent>): void {
    if (this.product.type === 'PersonalUmbrella') {
      if (changes.products && this.effectiveDate) {
        this.effectiveDate.updateValueAndValidity();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  hasChanges(a: ProductModel, b: ProductModel): boolean {
    return DateUtils.formatDateToDSM(a.effectiveDate) === b.effectiveDate;
  }

  buildForm(product: Partial<ProductModel>): FormGroup {
    return this.fb.group({
      effectiveDate: this.fb.control(
        DateUtils.formatDsmDateToOld(product.effectiveDate || '') || null,
        this.product.type === 'PersonalUmbrella'
          ? [
              (control) => this.validateUmbrellaEffectiveDate(control),
            ]
          : [
              (control) => this.validateEffectiveDate(control),
            ]
      ),
    });
  }

  emitChanges(changes: Partial<ProductModel>): void {
    if (
      DateUtils.formatDateToDSM(changes.effectiveDate) !==
      this.product?.effectiveDate
    ) {
      this.valueChange.emit({
        ...this.product,
        effectiveDate: DateUtils.formatDateToDSM(changes.effectiveDate),
      });
    }
  }

  imageUrl(productType: ProductType): string {
    return ProductUtils.getImageUrlForProduct(productType);
  }

  promptToRemovePolicy(event?: Event, content?: unknown): void {
    event?.preventDefault();
    if (content) {
      const modal = this.modalService.open(content);
      modal.result
        .then(() => {
          this.productsService.removeProduct(this.product);
          FormHelper.enableOrDisable('effectiveDate', false, [], this.form);
          this.policyRemoved.emit(this.product.type);
        })
        .catch(() => {
          // Cancelled, no worries.
        });
    }
  }

  validateEffectiveDate(control: AbstractControl): ValidationErrors | null {
    const rawInput = control.value || '';
    if (!rawInput) {
      return null;
    }
    if (!rawInput.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
      return { required: true };
    }
    const value = DateUtils.formatDateToDSM(rawInput);
    const min = DateUtils.formatDateToDSM(this.minEffectiveDate);
    const max = DateUtils.formatDateToDSM(this.maxEffectiveDate);
    if (value < min) {
      return { requiredBefore: this.minEffectiveDate };
    }
    if (value > max) {
      return { requiredAfter: this.maxEffectiveDate };
    }
    return null;
  }

  validateUmbrellaEffectiveDate(
    control: AbstractControl
  ): ValidationErrors | null {
    const rawInput = control.value || '';
    if (!rawInput) {
      return null;
    }
    if (!rawInput.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
      return { required: true };
    }
    let dsmEffectiveDate = '';
    this.store
      .select(getEffectiveDate)
      .subscribe((effDate) => (dsmEffectiveDate = effDate));
    const value = DateUtils.formatDateToDSM(rawInput);
    const dates = (this.products || [])
      .filter((product) => product.type !== 'PersonalUmbrella')
      .map((product) => new Date(`${product.effectiveDate + 'T12:00'}`));

    // Add today's date to the group of dates
    const todayDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    const todayDateTime = todayDate + 'T12:00';
    dates.push(new Date(todayDateTime));

    const latestDate = dates.reduce((a, b) => {
      return a > b ? a : b;
    }, {} as Date);
    const minProductEffectiveDate = DateUtils.formatDateAsString(latestDate);

    // Umbrella max date should be 60 days from current date despite any future minimum effective date
    // The dsmEffectiveDate param is either today or the PC /clocks system date as set by effectiveDateInitializerFn
    const maxUmbrellaEffectiveDate = DateUtils.formatDateAsString(
      DateUtils.getFutureDate(dsmEffectiveDate, 60)
    );

    if (value < minProductEffectiveDate) {
      return {
        umbrellaEffectiveDateRequiredBefore: DateUtils.formatDsmDateToOld(
          minProductEffectiveDate
        ),
      };
    }

    if (value > maxUmbrellaEffectiveDate) {
      return { requiredAfter: this.maxEffectiveDate };
    }
    return null;
  }

  getFormName(): string {
    return `${this.product?.name}-effective-date`;
  }

  prefillValue(newDate: string) {
    if(this.form.get("effectiveDate")?.status == "INVALID") {
      if (!newDate.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
        newDate = DateUtils.formatDsmDateToOld(newDate);
      }
      this.form.patchValue({
        effectiveDate: newDate,
      });
    }
  }

  get effectiveDate(): AbstractControl | null {
    return this.form?.get('effectiveDate');
  }
}
