import {
  ProducerResponse,
  sanitizeProducerResponse,
} from './producer-response.model';
import {
  NationwideAccountRegistrationInfo,
  OfferingType,
  ProductType,
  QuoteStatus,
  TermType,
  sanitizeNationwideAccountRegistrationInfo,
} from '../dsm-types';
import {
  CurrentCarrierEntity,
  sanitizeCurrentCarrierEntity,
} from '@core/store/entities/current-carrier/current-carrier.reducer';
import {
  PolicyHolderEntity,
  sanitizePolicyHolderEntities,
} from '@core/store/entities/policyholder/policyholder.entity';
import {
  AddressEntity,
  sanitizeAddressEntity,
} from '@core/store/entities/address/address.entity';
import {
  PreQualification,
  sanitizePreQualifications,
} from '@core/interfaces/interfaces';
import {
  InformationalMessages,
  sanitizeInformationalMessage,
  sanitizeInformationalMessages,
} from '@core/adapters/quote-retrieve.adapter';
import {
  EligibilityInformationEntity,
  sanitizeEligibilityInformation,
} from '@entities/product/product.model';
import {
  HouseholdMemberEntity,
  sanitizeHouseholdMembers,
} from '@entities/household-member/household-member.model';
import { ObjectValidator } from '@core/helper/object-validator';

export interface InitiateNewBusinessResponse {
  automateElectronicDocumentDelivery: boolean;
  creditConsent: boolean;
  currentBillTo?: 'PolicyPriNamedInsured' | 'Mortgagee';
  currentCarrier?: CurrentCarrierEntity;
  effectiveDate: string;
  eligibilityInformation?: EligibilityInformationEntity;
  hasDrivingDataConsent?: boolean;
  informationalMessages?: InformationalMessages[];
  isAssignedRiskPlan?: boolean;
  nationwideAccountRegistrationInfo?: NationwideAccountRegistrationInfo;
  needConstructionInfo?: boolean;
  offeringType?: OfferingType;
  policyAddress: AddressEntity;
  policyHolders: PolicyHolderEntity[];
  householdMembers?: HouseholdMemberEntity[];
  prequalificationAnswers?: PreQualification[];
  producer: ProducerResponse;
  productType: ProductType;
  quoteId: string;
  quoteStatus: QuoteStatus;
  sessionId: string;
  termType?: TermType;
  yearsWithPriorCarrier?: string;
}

export function sanitizeInitiateResponse(
  input: unknown
): InitiateNewBusinessResponse {
  return ObjectValidator.forceSchema<InitiateNewBusinessResponse>(
    input,
    {
      automateElectronicDocumentDelivery: 'boolean',
      creditConsent: 'boolean',
      currentBillTo: 'string',
      currentCarrier: sanitizeCurrentCarrierEntity,
      effectiveDate: 'string',
      eligibilityInformation: sanitizeEligibilityInformation,
      hasDrivingDataConsent: 'boolean',
      informationalMessages: [sanitizeInformationalMessage],
      isAssignedRiskPlan: 'boolean',
      nationwideAccountRegistrationInfo:
        sanitizeNationwideAccountRegistrationInfo,
      needConstructionInfo: 'boolean',
      offeringType: 'string',
      policyAddress: sanitizeAddressEntity,
      policyHolders: sanitizePolicyHolderEntities,
      householdMembers: sanitizeHouseholdMembers,
      prequalificationAnswers: sanitizePreQualifications,
      producer: sanitizeProducerResponse,
      productType: 'string',
      quoteId: 'string',
      quoteStatus: 'string',
      sessionId: 'string',
      termType: 'string',
      yearsWithPriorCarrier: 'string',
    },
    [
      'currentBillTo',
      'currentCarrier',
      'eligibilityInformation',
      'hasDrivingDataConsent',
      'informationalMessages',
      'isAssignedRiskPlan',
      'nationwideAccountRegistrationInfo',
      'needConstructionInfo',
      'offeringType',
      'householdMembers',
      'prequalificationAnswers',
      'termType',
      'yearsWithPriorCarrier',
    ]
  );
}
