import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SplashFormModule } from '@app/splash/components/splash-form/splash-form.module';
import { SplashContainerComponent } from './splash-container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  imports: [CommonModule, SplashFormModule, FormsModule, ReactiveFormsModule],
  exports: [SplashContainerComponent],
  declarations: [SplashContainerComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SplashContainerModule {}
