<div class="bg-pale bolt-container-fluid no-pad pt-20">
  <div class="bolt-row bolt-align-items-center bolt-justify-content-between">
    <div class="bolt-col-xs-12 bolt-col-sm-6">
      <h2 class="tab-header">Programs applied to this auto quote</h2>
    </div>
    <div
      class="bolt-col-xs-12 bolt-col-md-3 change-btn"
      *ngIf="shouldProposeReenrollExcluded"
    >
      <!-- When explicitly disabled (ie no telematics present at retrieve) -->
      <bolt-button
        type="primary"
        class="float-right text-nowrap"
        [attr.id]="'tc-enroll-lowest-cost-program-btn'"
        [size]="maxSizeMedium ? 'sm' : 'md'"
        (click)="reenrollExcluded()"
        width="full-md"
        >Enroll lowest cost programs</bolt-button
      >
    </div>
    <div
      class="bolt-col-xs-12 bolt-col-md-3 change-btn"
      *ngIf="!!enrollment && !isRecommendationEnrolled"
    >
      <!-- Enrolled in something that is not the recommendation -->
      <bolt-button
        type="primary"
        class="float-right text-nowrap"
        [attr.id]="'tc-enroll-lowest-cost-program-btn'"
        (click)="resetAllPrograms()"
        [attr.size]="maxSizeMedium ? 'sm' : 'md'"
        width="full-md"
        >Enroll lowest cost programs</bolt-button
      >
    </div>
    <div
      class="bolt-col-xs-12 bolt-col-md-3 change-btn"
      *ngIf="isAnyTelematicsEnrolled"
    >
      <bolt-button
        class="float-right text-nowrap"
        [attr.id]="'tc-remove-all-program-btn'"
        (click)="removeAllPrograms()"
        [attr.size]="maxSizeMedium ? 'sm' : 'md'"
        width="full-md"
        >Remove all programs</bolt-button
      >
    </div>
  </div>
  <p class="description">
    The auto quote reflects savings associated with these optional programs.
  </p>
  <div *ngIf="isRecommendationEnrolled">
    <p class="description">
      <em
        >Each vehicle is enrolled in the program that could provide the highest
        potential savings.</em
      >
    </p>
  </div>

  <div
    class="card sri-consent-card"
    *ngIf="displaySmartRideDataSharingConsent()"
  >
    <div class="bolt-row bolt-align-items-center">
      <div class="bolt-col-1">
        <bolt-icon name="info-circle-filled" color="theme-info"></bolt-icon>
      </div>
      <div class="bolt-col-8">
        <p>
          <strong
            >Consent is required to determine qualification for SmartRide
            Instant</strong
          >
        </p>
        <p>
          To apply discounts, when available, Nationwide Insurance partners with
          vehicle manufacturers and third parties to collect vehicle driving
          data.
        </p>
      </div>
      <div class="bolt-col-3">
        <bolt-button (click)="openSmartRideDataSharingModal()"
          >Grant consent <bolt-icon name="pencil" size="sm"></bolt-icon
        ></bolt-button>
      </div>
    </div>
  </div>

  <div
    id="smartride-instant"
    *ngIf="smartRideInstantVehicles.length"
    class="card"
  >
    <h3 class="card-header">
      <img
        class="tm-icon"
        src="assets/images/telematics/connected-car.svg"
        alt=""
      />
      <ng-container *ngIf="!isCA; else SRInstantCA">
        SmartRide&reg; safe-driving discount
      </ng-container>
      <ng-template #SRInstantCA>
        SmartRide&reg; low-mileage discount
      </ng-template>
    </h3>
    <div class="card-body">
      <nwx-smartride-instant-enrollment
        [vehicles]="smartRideInstantVehicles"
        [quoteState]="quoteState"
        [enrollment]="enrollment"
      ></nwx-smartride-instant-enrollment>
      <ng-container *ngIf="premium?.total?.amount">
        <div
          class="bolt-container-fluid bolt-body-copy-sm"
          id="premiumDisclaimer-SRInstant"
        >
          <sup>*</sup>cost included in the total premium of
          {{ premium?.total?.amount | currency }}
        </div>
      </ng-container>
    </div>
  </div>
  <div
    id="smartride-connected-car"
    *ngIf="smartRideConnectedCarVehicles.length"
    class="card"
  >
    <h3 class="card-header">
      <img
        class="tm-icon"
        src="assets/images/telematics/connected-car.svg"
        alt=""
      />
      <ng-container *ngIf="!isCA; else SRConnectedCarCA">
        SmartRide&reg; safe-driving discount
      </ng-container>
      <ng-template #SRConnectedCarCA>
        SmartRide&reg; low-mileage discount
      </ng-template>
    </h3>
    <div class="card-body">
      <nwx-smartride-connected-car-enrollment
        [vehicles]="smartRideConnectedCarVehicles"
        [quoteState]="quoteState"
        [enrollment]="enrollment"
      ></nwx-smartride-connected-car-enrollment>
      <ng-container *ngIf="premium?.total?.amount">
        <div
          class="bolt-container-fluid bolt-body-copy-sm"
          id="premiumDisclaimer-SRConnectedCar"
        >
          <sup>*</sup>cost included in the total premium of
          {{ premium?.total?.amount | currency }}
        </div>
      </ng-container>
    </div>
  </div>
  <div
    id="smartmiles-connected-car"
    *ngIf="smartMilesConnectedCarVehicles.length"
    class="card"
  >
    <h3 class="card-header">
      <img
        class="tm-icon"
        src="assets/images/telematics/connected-car.svg"
        alt=""
      />
      SmartMiles&reg; pay-per-mile program
    </h3>
    <div class="card-body">
      <nwx-smartmiles-connected-car-enrollment
        [vehicles]="smartMilesConnectedCarVehicles"
        [quoteState]="quoteState"
        [enrollment]="enrollment"
        [maxSizeMini]="maxSizeMini"
      ></nwx-smartmiles-connected-car-enrollment>
      <ng-container *ngIf="premium?.total?.amount">
        <div
          class="bolt-container-fluid bolt-body-copy-sm"
          id="premiumDisclaimer-SMConnectedCar"
        >
          <sup>*</sup>cost included in the total premium of
          {{ premium?.total?.amount | currency }}
        </div>
      </ng-container>
    </div>
  </div>
  <div id="smartmiles" *ngIf="smartMilesVehicles.length" class="card">
    <h3 class="card-header">
      <img
        class="tm-icon"
        src="assets/images/telematics/smartride-white.svg"
        alt=""
      />
      SmartMiles&reg; pay-per-mile program
    </h3>
    <div class="card-body">
      <nwx-smartmiles-enrollment
        [vehicles]="smartMilesVehicles"
        [quoteState]="quoteState"
        [enrollment]="enrollment"
        [maxSizeMini]="maxSizeMini"
      ></nwx-smartmiles-enrollment>
      <ng-container *ngIf="premium?.total?.amount">
        <div
          class="bolt-container-fluid bolt-body-copy-sm"
          id="premiumDisclaimer-SmartMiles"
        >
          <sup>*</sup>cost included in the total premium of
          {{ premium?.total?.amount | currency }}
        </div>
      </ng-container>
    </div>
  </div>
  <div id="smartride" *ngIf="smartRideVehicles.length" class="card">
    <h3 class="card-header">
      <img
        class="tm-icon"
        src="assets/images/telematics/smartride-white.svg"
        alt=""
      />
      <ng-container *ngIf="!isCA; else SmartRideCA">
        SmartRide&reg; safe-driving discount
      </ng-container>
      <ng-template #SmartRideCA>
        SmartRide&reg; low-mileage discount
      </ng-template>
    </h3>
    <div class="card-body">
      <nwx-smartride-enrollment
        [vehicles]="smartRideVehicles"
        [isMobile]="isMobile"
        [quoteState]="quoteState"
        [enrollment]="enrollment"
        [maxSizeMini]="maxSizeMini"
      ></nwx-smartride-enrollment>
      <ng-container *ngIf="premium?.total?.amount">
        <div
          class="bolt-container-fluid bolt-body-copy-sm"
          id="premiumDisclaimer-SmartRide"
        >
          <sup>*</sup>cost included in the total premium of
          {{ premium?.total?.amount | currency }}
        </div>
      </ng-container>
    </div>
  </div>
  <div id="self-reported" *ngIf="selfReportedVehicles.length" class="card">
    <h3 class="card-header">
      <img
        class="tm-icon"
        src="assets/images/telematics/smartride-white.svg"
        alt=""
      />
      Verified Self-Reported Annual Program
    </h3>
    <div class="card-body">
      <nwx-self-reported-enrollment
        [vehicles]="selfReportedVehicles"
        [enrollment]="enrollment"
      ></nwx-self-reported-enrollment>
    </div>
  </div>
  <div id="unenrolled" *ngIf="unenrolledVehicles.length" class="card">
    <h3 class="card-header">No usage-based insurance program</h3>
    <div class="card-body">
      <nwx-no-program-enrollment
        [vehicles]="unenrolledVehicles"
        [enrollment]="enrollment"
      ></nwx-no-program-enrollment>
    </div>
  </div>
</div>
