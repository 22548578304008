import { ObjectValidator } from '@core/helper/object-validator';
import { Nullable } from '@shared/utils/type.utils';

export interface PersonRequest {
  firstName?: Nullable<string>;
  middleName?: Nullable<string>;
  lastName?: Nullable<string>;
  suffix?: Nullable<string>;
  gender?: Nullable<string>;
  maritalStatus?: Nullable<string>;
  dateOfBirth?: Nullable<string>;
}

export function sanitizePersonRequest(input: unknown): PersonRequest {
  return ObjectValidator.forceSchema(input, {
    firstName: 'string',
    middleName: 'string',
    lastName: 'string',
    suffix: 'string',
    gender: 'string',
    maritalStatus: 'string',
    dateOfBirth: 'string',
  }) as PersonRequest;
}
