import { createAction, props } from "@ngrx/store";
import { OtherStructuresAdditionalDwellingUnit, OtherStructuresEntity } from "./other-structures.reducer";
import { ErrorModel } from "@entities/error/error.model";

/** Does not trigger service call.
 */
export const storeOtherStructures = createAction(
  '[Other Structures] Store',
  props<{ otherStructures: OtherStructuresEntity }>()
);

/** Trigger service call. Does not affect stored entities.
 */
export const updateOtherStructures = createAction(
  '[Other Structures] Update',
  props<{ request: OtherStructuresEntity, correlationId?: string }>()
);

export const updateOtherStructuresSuccess = createAction(
  '[Other Structures] Update Success',
  props<{ response: OtherStructuresEntity, correlationId?: string }>()
);

export const updateOtherStructuresError = createAction(
  '[Other Structures] Update Error',
  props<{ error: ErrorModel, correlationId?: string }>()
);
