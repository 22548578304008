import { Injectable } from '@angular/core';
import { ContentfulService } from '@core/services/contentful.service';
import { PageAlert } from '@core/store/entities/page-alert/page-alert.model';
import { storeAnnouncementContent } from '@entities/announcement-content/announcement-content.action';
import { AnnouncementContent } from '@entities/announcement-content/announcement-content.reducer';
import { Store } from '@ngrx/store';
import { Entry } from 'contentful';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageAlertAdapter {
  private CONTENT_TYPE = 'applicationMessages';

  constructor(
    private contentfulService: ContentfulService,
    private store: Store
  ) {}

  getPageAlerts(): Observable<PageAlert[]> {
    return from(
      this.contentfulService
        .getEntries(this.CONTENT_TYPE, {
          include: 0,
        })
        .then((response: Entry<any>[]) => {
          return response.map((alert) => {
            const priority = alert.fields.priority ? alert.fields.priority : 0;
            const appliedStates = alert.fields.appliedStates
              ? alert.fields.appliedStates
              : [];
            return {
              ...alert.fields,
              priority,
              appliedStates,
              id: alert.sys.id,
            } as PageAlert;
          }) as PageAlert[];
        })
    );
  }

  loadAnnouncementModalContent(): void {
    this.contentfulService
      .getMostRecentEntry('announcementModalContent')
      .then((entry: Entry<any>) => {
        // map the Contentful entry to the interface
        const payload: AnnouncementContent = {
          announcementDate: entry?.fields?.announcementDate || '',
          announcementItems: entry?.fields?.announcementItems || [],
        };
        this.store.dispatch(storeAnnouncementContent({ payload }));
      });
  }
}
