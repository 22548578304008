import {
  AccountsSearchRequest,
  QuoteRetrieveAdapter,
  sanitizeInformationalMessage,
  sanitizeInformationalMessages,
} from './../adapters/quote-retrieve.adapter';
import { Injectable } from '@angular/core';
import { SearchFormModel } from '@app/search/components/search-form/search-form.model';
import { from, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { retrieveQuotes } from '../store/retrieve/retrieve.action';
import { ProductType } from '../models/api/dsm-types';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  PersonEntity,
  sanitizePersonEntity,
} from '@core/models/entities/person.entity';
import {
  BasicAddressEntity,
  sanitizeBasicAddressEntity,
} from '@entities/address/address.entity';
import { ObjectValidator } from '@core/helper/object-validator';
import { InformationalMessage } from '@entities/telematics/telematics.model';
import { sanitizeAccountResponse } from '@core/models/api/response/account-response.model';
import { sanitizeProducerResponse } from '@core/models/api/response/producer-response.model';
import { producerData } from '@tests/mock-producer-search-data';

export interface QuoteSearchResult {
  address: {
    state: string;
    addressLine1: string;
    city: string;
    postalCode: string;
  };
  creationTime: string;
  phoneNumber: string;
  quoteId: string;
  accountNumber: string;
  lastName: string;
  firstName: string;
  lineOfBusiness: ProductType;
  producerCode: string;
  quoteSource: string;
  quoteStatus: string;
}

export function sanitizeQuoteSearchResult(input: unknown): QuoteSearchResult {
  return ObjectValidator.forceSchema<QuoteSearchResult>(
    input,
    {
      address: {
        state: 'string',
        addressLine1: 'string',
        city: 'string',
        postalCode: 'string',
      },
      creationTime: 'string',
      phoneNumber: 'string',
      quoteId: 'string',
      accountNumber: 'string',
      lastName: 'string',
      firstName: 'string',
      lineOfBusiness: 'string',
      producerCode: 'string',
      quoteSource: 'string',
      quoteStatus: 'string',
    },
    []
  );
}

export interface AccountsSearchResult {
  accounts: Accounts[];
  informationalMessages?: InformationalMessage[];
}

export interface Accounts {
  accountHolder: AccountHolder;
  producer: Producer;
  accountId: string;
}

export interface AccountHolder {
  person: PersonEntity;
  address: BasicAddressEntity;
}

export interface Producer {
  producerCode?: string;
  type?: string;
  agencyCode?: string;
}

export function sanitizeAccounts(input: unknown): Accounts {
  return ObjectValidator.forceSchema<Accounts>(
    input,
    {
      accountHolder: sanitizeAccountHolder,
      producer: {
        producerCode: 'string',
        type: 'string',
        agencyCode: 'string',
      },
      accountId: 'string',
    },
    []
  );
}

export function sanitizeAccountSearchResult(
  input: unknown
): AccountsSearchResult {
  return ObjectValidator.forceSchema<AccountsSearchResult>(
    input,
    {
      accounts: [sanitizeAccounts],
      informationalMessages: sanitizeInformationalMessages,
    },
    []
  );
}

export function sanitizeAccountHolder(input: unknown): AccountHolder {
  return ObjectValidator.forceSchema<AccountHolder>(
    input,
    {
      person: sanitizePersonEntity,
      address: sanitizeBasicAddressEntity,
    },
    []
  );
}

export interface QuoteSearchResults {
  quotes: QuoteSearchResult[];
}

@Injectable({
  providedIn: 'root',
})
export class QuoteSearchService {
  constructor(
    private store: Store,
    private quoteRetrieveAdapter: QuoteRetrieveAdapter
  ) {}

  searchAccounts(
    request: AccountsSearchRequest
  ): Observable<AccountsSearchResult> {
    return this.quoteRetrieveAdapter.searchAccounts(request);
  }

  search(request: SearchFormModel): Observable<QuoteSearchResults> {
    switch (request.searchtype) {
      case 'firstLastName':
        return this.searchByFirstLastName(
          request.firstName,
          request.lastName
        ).pipe(
          map((results) => this.removeVersion(results)),
          map((results) => this.removeDuplicates(results))
        );
      case 'lastNameZip':
        return this.searchByLastNameZip(request.lastName, request.zipCode).pipe(
          map((results) => this.removeVersion(results)),
          map((results) => this.removeDuplicates(results))
        );
      case 'quoteId':
        return this.searchByQuoteId(request.quoteId).pipe(
          map((result) => this.removeVersion(result)),
          switchMap((results) => {
            const accountNumber = results.quotes?.[0]?.accountNumber;
            if (accountNumber) {
              return this.quoteRetrieveAdapter
                .searchByAccountNumber(accountNumber)
                .pipe(
                  map((results) => this.removeVersion(results)),
                  map((results) => this.removeDuplicates(results))
                );
            }
            return of(results);
          })
        );
      case 'accountNumber':
        return this.quoteRetrieveAdapter
          .searchByAccountNumber(request.accountNumber)
          .pipe(
            map((results) => this.removeVersion(results)),
            map((results) => this.removeDuplicates(results))
          );
    }
    return from([]);
  }

  searchByFirstLastName(
    firstName?: string,
    lastName?: string
  ): Observable<QuoteSearchResults> {
    if (
      lastName === null ||
      firstName === null ||
      lastName === undefined ||
      firstName === undefined
    ) {
      return of({ quotes: [] });
    }
    return this.quoteRetrieveAdapter.searchByFirstLastName(firstName, lastName);
  }

  searchByLastNameZip(
    lastName?: string,
    zip?: string
  ): Observable<QuoteSearchResults> {
    if (
      lastName === null ||
      zip === null ||
      lastName === undefined ||
      zip === undefined
    ) {
      return of({ quotes: [] });
    }
    return this.quoteRetrieveAdapter.searchByLastNameZip(lastName, zip);
  }

  searchByQuoteId(quoteId?: string): Observable<QuoteSearchResults> {
    if (quoteId === null || quoteId === undefined) {
      return of({ quotes: [] });
    }
    return this.quoteRetrieveAdapter.searchByQuoteId(quoteId);
  }

  retrieve(...quotes: { quoteId: string; productType: ProductType }[]): void {
    this.store.dispatch(retrieveQuotes({ payload: { quotes } }));
  }

  private removeVersion(result: QuoteSearchResults): QuoteSearchResults {
    return {
      ...result,
      quotes: result.quotes.map((quote) => ({
        ...quote,
        quoteId: quote.quoteId.split('-')[0],
      })),
    };
  }

  private removeDuplicates(result: QuoteSearchResults): QuoteSearchResults {
    const quotes: QuoteSearchResult[] = [];
    for (const quote of result.quotes) {
      if (!quotes.find((q) => q.quoteId === quote.quoteId)) {
        quotes.push(quote);
      }
    }
    return {
      ...result,
      quotes,
    };
  }
}
