import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { switchMap, take, concatMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CoreState } from '@core/store/reducers';
import * as fromActions from './protective-devices.action';
import {
  getAdvancedProtectiveDevices,
  getBasicProtectiveDevices,
} from './protective-devices.selector';
import { ProtectiveDevicesService } from '@core/services/protective-devices.service';
import { getCoveredLocationEntity } from '@entities/covered-location/covered-location.selector';

@Injectable()
export class ProtectiveDevicesEffects {
  constructor(
    private store: Store<CoreState>,
    private actions$: Actions,
    private protectiveDevicesService: ProtectiveDevicesService
  ) {}

  getProtectiveDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getProtectiveDevices),
      switchMap((action) =>
        combineLatest([
          this.store.select(
            getBasicProtectiveDevices(action.payload.quoteState)
          ),
          this.store.select(
            getAdvancedProtectiveDevices(
              action.payload.quoteState,
              action.payload.productType
            )
          ),
          this.store.select(
            getCoveredLocationEntity(action.payload.productType)
          ),
          of(action),
        ]).pipe(take(1))
      ),
      concatMap(
        ([
          basicProtectiveDevices,
          advancedProtectiveDevices,
          coveredLocation,
          protectiveDevicesAction,
        ]) => {
          const protectiveDevices = [
            ...basicProtectiveDevices,
            ...advancedProtectiveDevices,
          ];
          return of(
            fromActions.getProtectiveDevicesSuccess({
              payload: {
                productType: protectiveDevicesAction.payload.productType,
                devices:
                  this.protectiveDevicesService.sanitizeGetProtectiveDevicesResponse(
                    protectiveDevices,
                    coveredLocation
                  ),
              },
            })
          );
        }
      )
    )
  );
}
