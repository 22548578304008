<form [formGroup]="form" class="container" [ngClass]="{ open: open }">
  <ng-container *ngIf="open; else ladybug">
    <div
      class="button-wrapperr bolt-space-bottom-md"
      id="closeMenu"
      (click)="onToggle()"
    >
      <bolt-icon name="xmark"></bolt-icon>
    </div>
  </ng-container>
  <ng-template #ladybug>
    <div class="button-wrapper">
      <input
        id="openMenu"
        type="button"
        [value]="'\ud83d\udc1e'"
        (click)="onToggle()"
      />
    </div>
  </ng-template>

  <ng-container *ngIf="open">
    <div class="bolt-container-fluid">
      <p class="bolt-heading-xs bolt-space-bottom-md">Debug menu</p>
      <div class="bolt-row bolt-align-items-center">
        <div class="bolt-col">
          <label for="test-environment" class="faux-bolt-label">NWX Env</label>
        </div>
        <div class="bolt-col">
          <bolt-select
            id="test-environment"
            label=""
            formControlName="testEnvironment"
            arialabel="NWX Environment"
          >
            <option *ngFor="let item of nwxEnvs | keyvalue" [value]="item.key">
              {{ item.value }}
            </option>
          </bolt-select>
        </div>
      </div>
      <div class="bolt-row bolt-align-items-center" *ngIf="showEnvName()">
        <div class="bolt-col">
          <label for="debug-pc-env" class="faux-bolt-label">Env Config</label>
        </div>
        <div class="bolt-col">
          <bolt-select
            label=""
            id="env-name"
            formControlName="environmentName"
            arialabel="Environment Config"
          >
            <option *ngFor="let item of envNames | keyvalue" [value]="item.key">
              {{ item.value }}
            </option>
          </bolt-select>
        </div>
      </div>
      <div class="bolt-row bolt-align-items-center">
        <div class="bolt-col">
          <label for="debug-pc-env" class="faux-bolt-label">PC Env</label>
        </div>
        <div class="bolt-col">
          <bolt-select
            label=""
            id="debug-pc-env"
            formControlName="pcEnv"
            arialabel="PC Environment"
          >
            <option *ngFor="let v of pcEnvironments" [value]="v">
              {{ v }}
            </option>
          </bolt-select>
          <span *ngIf="alertPcEnvError" id="pcAlertError" class="error">!</span>
        </div>
        <div class="bolt-col-12">
          <div class="bolt-body-copy-sm">
            Don't change PC env after submitting PNI.
          </div>
        </div>
      </div>

      <div class="bolt-row bolt-align-items-center">
        <div class="bolt-col">
          <label for="debug-billing-env" class="faux-bolt-label">
            Billing Env
          </label>
        </div>
        <div class="bolt-col">
          <bolt-select
            id="debug-billing-env"
            formControlName="billingEnv"
            arialabel="Billing environment"
          >
            <option *ngFor="let v of billingEnvs" [value]="v">{{ v }}</option>
          </bolt-select>
        </div>
      </div>

      <div class="bolt-row bolt-align-items-center bolt-space-bottom-lg">
        <div class="bolt-col">
          <label for="debug-ignore-risk-filter" class="faux-bolt-label no-mb">
            Ignore risk filter
          </label>
        </div>
        <div class="bolt-col-auto">
          <bolt-checkbox
            id="debug-ignore-risk-filter"
            formControlName="ignoreRiskFilter"
            arialabel="Ignore risk filter"
          ></bolt-checkbox>
        </div>
      </div>

      <!-- <tr *ngIf="products$ | async">
        <td>
          <label for="debug-enabled-products">Enabled Products:</label>
        </td>
        <td formArrayName="products">
          <tr *ngFor="let product of formProducts?.controls">
            <input
              type="checkbox"
              id="debug-{{ product.value.type }}-product"
              [checked]="product.value.isAvailable"
              (change)="updateProducts(product.value, $event)"
            />&nbsp;<label for="debug-{{ product.value.type }}-product">{{
              product.value.type
            }}</label>
          </tr>
        </td>
      </tr> -->

      <div class="bolt-row bolt-align-items-center bolt-space-bottom-lg">
        <div class="bolt-col">
          <label for="auto-approve-uw" class="faux-bolt-label no-mb">
            Auto Approve UW Rules
          </label>
        </div>
        <div class="bolt-col-auto">
          <bolt-checkbox
            id="auto-approve-uw"
            formControlName="autoApproveUW"
            arialabel="Auto Approve UW Rules"
          >
          </bolt-checkbox>
        </div>
      </div>

      <div class="bolt-row bolt-align-items-center bolt-space-bottom-lg">
        <div class="bolt-col">
          <label for="autofill" class="faux-bolt-label no-mb">
            Enable autofill
          </label>
        </div>
        <div class="bolt-col-auto">
          <bolt-checkbox
            id="autofill"
            formControlName="autofill"
            arialabel="Enable autofill"
          >
          </bolt-checkbox>
        </div>
      </div>

      <div class="bolt-row bolt-align-items-center">
        <div class="bolt-col">
          <label for="navTarget" class="faux-bolt-label">Auto navigate</label>
        </div>
        <div class="bolt-col">
          <bolt-select
            id="navTarget"
            formControlName="autofillTarget"
            (change)="onAutofillChange($event)"
            arialabel="Auto navigate"
          >
            <option *ngFor="let page of pages$ | async" [value]="page.url">
              {{ page.name }}
            </option>
          </bolt-select>
        </div>
      </div>
    </div>
  </ng-container>
</form>
