import {
  CoveragesMetadata,
  CoveragesMetadataType,
  NonDeclinableCoverageTerms,
  ProductExceptionCoverageMetadata,
  StateExceptionCoverageMetadata,
} from '@app/core/constants/coverage-metadata';
import {
  CoverageEntity,
  CoverageOption,
  CoverageTerm,
  SelectedValue,
} from '@app/core/store/entities/coverage/coverage.entity';
import { ProductType } from '@core/models/api/dsm-types';
import { Nullable } from './type.utils';
import { PropertyCoverageGroupTabIds } from '@shared/constants/app-constants';

export class CoverageUtils {
  static coverageSorter(quoteState: string, lineOfBusiness: ProductType) {
    return (a: CoverageEntity, b: CoverageEntity) =>
      this.getSequenceNumber(a, quoteState, lineOfBusiness) -
      this.getSequenceNumber(b, quoteState, lineOfBusiness);
  }

  static getMetadata(
    coverage: CoverageEntity,
    quoteState: string,
    lineOfBusiness: ProductType
  ): CoveragesMetadataType {
    return (
      ProductExceptionCoverageMetadata[lineOfBusiness]?.[coverage.coverageId] ||
      StateExceptionCoverageMetadata[quoteState]?.[coverage.coverageId] ||
      CoveragesMetadata[coverage.coverageId]
    );
  }

  static getSequenceNumber(
    coverage: CoverageEntity,
    quoteState: string,
    lineOfBusiness: ProductType
  ): number {
    const coverageBMetadata = this.getMetadata(
      coverage,
      quoteState,
      lineOfBusiness
    );
    return coverageBMetadata ? coverageBMetadata.coverageSequence : 0;
  }

  static filterCoverageByGrouping(
    coverage: CoverageEntity,
    grouping: string,
    quoteState: string,
    lineOfBusiness: ProductType
  ): boolean {
    const metadata = this.getMetadata(coverage, quoteState, lineOfBusiness);

    return metadata && metadata.displayGrouping === grouping;
  }

  static filterCoverageByAvailable(coverage: CoverageEntity): boolean {
    return coverage.available;
  }

  static getFormKey(
    coverageId: string,
    coverableId?: string | undefined,
    termCode?: Nullable<string>
  ): string {
    return coverableId && termCode
      ? coverageId + `_${coverableId}` + `_${termCode}`
      : coverableId
      ? coverageId + `_${coverableId}`
      : termCode
      ? coverageId + `_${termCode}`
      : coverageId;
  }

  static doesNotRequireUpdate(
    previousVal: string,
    currentValue: string,
    isFieldInvalid: boolean
  ): boolean {
    return (
      previousVal === currentValue ||
      ((previousVal === '' || previousVal === undefined) &&
        (currentValue === 'true' || currentValue === 'false')) ||
      currentValue === '' ||
      previousVal === '' ||
      isFieldInvalid
    );
  }

  static filterTermOptionsForMatchingValue(
    matchingValue: string,
    term: CoverageTerm
  ): CoverageOption[] {
    return term.options.filter((option) => option.value === matchingValue);
  }

  static removeNonSelectedTermOptionsForPackageCoverages(
    coverages: CoverageEntity[],
    coverage: CoverageEntity
  ): CoverageEntity {
    const packageCoverage = coverages?.find((cov) => cov.coverageId === 'PKG');
    if (packageCoverage) {
      const packageCoverageSelected =
        CoverageUtils.isCoverageSelected(packageCoverage);
      if (packageCoverageSelected) {
        const searchCode = coverage.coverageId + 'Limit';

        const coverageValue = coverage.selectedValue?.find(
          (selectedValue) => selectedValue.code === searchCode
        )?.value;

        if (coverageValue) {
          const termToEdit = coverage?.terms?.find(
            (term) => term.code === searchCode
          );
          if (termToEdit) {
            const selectedValueTermOption =
              CoverageUtils.filterTermOptionsForMatchingValue(
                coverageValue,
                termToEdit
              );
            const updatedCoverageTerm = {
              ...termToEdit,
              options: selectedValueTermOption,
            };
            const termsExcludingEdited = coverage.terms?.filter(
              (term) => term.code !== searchCode
            );
            return {
              ...coverage,
              terms: [...termsExcludingEdited, updatedCoverageTerm],
            };
          }
        }
      }
    }

    return coverage;
  }

  static removeNonSelectedTermOptionsForLiabilityCoverages(
    coverages: CoverageEntity[],
    coverage: CoverageEntity
  ): CoverageEntity {
    const personalLiabilityCoverage = coverages?.find(
      (cov) => cov.coverageId === 'PersonalLiability'
    );
    if (personalLiabilityCoverage) {
      const personalLiabilityCoverageSelected =
        CoverageUtils.isCoverageSelected(personalLiabilityCoverage);
      if (personalLiabilityCoverageSelected) {
        const searchCode = coverage.coverageId + 'Limit';

        const coverageValue = personalLiabilityCoverage.selectedValue?.find(
          (selectedValue) => selectedValue.code === 'PersonalLiabilityLimit'
        )?.value;

        if (coverageValue) {
          const termToEdit = coverage?.terms?.find(
            (term) => term.code === searchCode
          );
          if (termToEdit) {
            const selectedValueTermOption =
              CoverageUtils.filterTermOptionsForMatchingValue(
                coverageValue,
                termToEdit
              );
            const updatedCoverageTerm = {
              ...termToEdit,
              options: selectedValueTermOption,
            };
            const termsExcludingEdited = coverage.terms?.filter(
              (term) => term.code !== searchCode
            );
            return {
              ...coverage,
              terms: [...termsExcludingEdited, updatedCoverageTerm],
            };
          }
        }
      }
    }

    return coverage;
  }

  static isCoverageSelected(coverage: Nullable<CoverageEntity>): boolean {
    return !coverage?.selectedValue?.some(
      (selectedValue) =>
        selectedValue.code === 'selected' && selectedValue.value === 'false'
    );
  }

  static removeTermsAndForceSelectedValueBoolean(
    coverage: CoverageEntity
  ): CoverageEntity {
    let value = 'true';
    if (
      coverage.selectedValue?.find(
        (v) => v.code === 'selected' && v.value === 'false'
      )
    ) {
      value = 'false';
    }
    return {
      ...coverage,
      selectedValue: [
        {
          code: 'selected',
          value,
        },
      ],
      terms: [],
    };
  }

  static getCoverageById(
    coverages: CoverageEntity[],
    coverageId: string,
    coverableId?: string
  ): CoverageEntity | undefined {
    return coverages.find((cov) => {
      const coverageIds = cov.coverageId === coverageId;
      const coverableIds = cov.coverableId === coverableId;
      return coverableId ? coverageIds && coverableIds : coverageIds;
    });
  }

  static selectedValueDescEquals(
    coverage: Nullable<CoverageEntity>,
    expected: Partial<SelectedValue>
  ): boolean {
    return (
      coverage?.selectedValue?.some(
        (selectedValue) =>
          selectedValue.code === expected.code &&
          selectedValue.description === expected.description
      ) || false
    );
  }

  static termIsDeclinable(termCode: string, coverageId: string): boolean {
    const metadata = NonDeclinableCoverageTerms[coverageId];
    const isDeclinable = !metadata?.includes(termCode);
    return isDeclinable;
  }

  static isPropertyCoverageGroupTabId(tabId: string): boolean {
    return PropertyCoverageGroupTabIds.includes(tabId);
  }
}
