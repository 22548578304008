<div
  class="discount-badge"
  [class]="outputColor"
  [ngClass]="{ 'fixed-badge-width': fixedWidth, mini: mini }"
>
  <div>{{ name }}</div>
  <div class="icon-box">
    <bolt-icon name="invoice" size="md"></bolt-icon>
  </div>
</div>
