import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { Nullable } from '@shared/utils/type.utils';
import { PriceSummaryDisplayProduct } from '../price-summary-display/price-summary-display-content';

@Component({
  selector: 'nwx-product-display',
  templateUrl: './product-display.component.html',
  styleUrls: ['./product-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDisplayComponent {
  @Input() product!: PriceSummaryDisplayProduct;

  @Output() addUpsell = new EventEmitter<Nullable<ProductType>>();
}
