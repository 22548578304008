import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'nwx-discount-badge',
  templateUrl: './discount-badge.component.html',
  styleUrls: ['./discount-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountBadgeComponent implements OnInit {
  @Input() name!: string;
  @Input() mini: boolean = false;
  // @Input() icon!: string;
  @Input() bgColor!: string;
  // ONLY true if being used on people,vehicles,or msa vehicles
  @Input() fixedWidth: boolean = false;

  outputColor: string = 'dark-mint';

  constructor() {}

  ngOnInit(): void {
    this.outputColor = this.getbgColor();
  }

  // protect against non-colors being sent in, or no colors being sent in at all
  getbgColor(): string {
    switch (this.bgColor) {
      case 'dark-mint': {
        return 'dark-mint';
      }
      default: {
        return 'dark-mint';
      }
    }
  }
}
