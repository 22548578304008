import { Injectable } from '@angular/core';
import { PreBindDocumentsFormModel } from '@shared/components/pre-bind-documents/pre-bind-documents-form/pre-bind-documents-form.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  getPreBindDocumentsForm,
  preBindDocumentsAcknowledged,
} from '../../core/store/entities/confirmations/confirmations.selector';
import { updatePreBindDocumentsForm } from '../../core/store/entities/confirmations/confirmations.action';
import { FormsState } from '../store/reducers';
import { resendDocumentUrlEmail } from '@core/store/entities/pre-bind-documents/pre-bind-documents.action';
import { ProductType } from '@core/models/api/dsm-types';

@Injectable({
  providedIn: 'root',
})
export class PreBindDocumentsFormService {
  constructor(private store: Store<FormsState>) {}

  updatePreBindDocumentsForm(changes: PreBindDocumentsFormModel): void {
    this.store.dispatch(updatePreBindDocumentsForm({ payload: changes }));
  }

  getPreBindDocumentsForm(): Observable<PreBindDocumentsFormModel> {
    return this.store.select(getPreBindDocumentsForm);
  }

  preBindDocumentsAcknowledged(): Observable<boolean> {
    return this.store.select(preBindDocumentsAcknowledged);
  }

  resendEmail(productTypes: ProductType[]): void {
    this.store.dispatch(resendDocumentUrlEmail({ productTypes }));
  }
}
