<div [ngClass]="{ 'bolt-space-bottom-lg': bottomSpacingNeeded }">
  <bolt-date-picker
    attr.id="{{ controlId }}-{{ relevantProduct }}"
    attr.name="{{ controlId }}-{{ relevantProduct }}"
    [label]="label"
    [min]="minDate | dateMmDdYyyy"
    [max]="maxDate | dateMmDdYyyy"
    [formControlName]="controlName"
    [attr.formControlName]="controlName"
    ngDefaultControl
    optionaltext=""
  ></bolt-date-picker>
  <div *ngIf="hasError" class="faux-bolt-error" attr.id="{{ controlId }}Error">
    <bolt-icon
      class="faux-bolt-error-icon"
      name="exclamation-circle-filled"
      color="theme-error"
    ></bolt-icon>
    <span class="error-text">{{ invalidFeedbackText }}</span>
  </div>
  <ng-template #footerTemplate>
    <!-- TODO This used to appear as a footer in the ngbDatepicker popup.
      That's not doable with bolt-date-picker, and really, it shouldn't have been attached to the popup anyway.
      (the popup being an optional data-input concern, and the disclaimer being a business concern).
      2022-12-07T10:00 Nick is considering new placement options, wait to hear on that.
    -->
    <div *ngIf="showAdvancedQuoteDiscount || true">
      <hr class="my-0" />
      <div class="dp-footer">
        <div class="badge-house">
          <nwx-discount-badge
            name="Advanced quote discount"
            [mini]="true"
          ></nwx-discount-badge>
        </div>
        <p class="mt-3 condensed-lh">
          Effective Date must be at least eight days after the initial quote
          date to qualify for Advanced Quote Discount. Initial quote date is the
          date that credit is ordered.
        </p>
      </div>
    </div>
  </ng-template>
</div>
