import { Injectable } from '@angular/core';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { VehicleService } from '@core/services/vehicle.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import * as dvaAction from '../driver-vehicle-assignment/driver-vehicle-assignment.actions';
import {
  DriverVehiclePrefillActions,
  EligibleDiscountsActions,
  TelematicsActions,
  VehicleActions,
} from '../../actions';
import { ProductsSelectors, VehicleSelectors } from '../../selectors';
import { ExtendedTelematicsVehicle } from '../telematics/telematics.model';
import { EligibleDiscountsEntity } from '../eligible-discounts/eligible-discounts.entity';
import {
  AddVehicleRequest,
  UpdateVehicleRequest,
} from '@core/models/api/request/vehicle-request.model';
import { ProductsService } from '@core/services/products.service';
import { VehicleEntity } from './vehicle.entity';
import { vehicleDiscountOptions } from '@shared/constants/app-constants';
import { VehicleUtils } from '@shared/utils/vehicle.utils';

@Injectable({
  providedIn: 'root',
})
export class VehicleEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private vehicleService: VehicleService,
    private errorSanitizerService: ErrorSanitizerService,
    private productsService: ProductsService
  ) {}

  addVehicle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VehicleActions.addVehicle),
      concatMap((requestAction) => {
        return this.vehicleService
          .fillMissingEntityFields(requestAction.payload)
          .pipe(
            switchMap((entity) =>
              this.vehicleService.composeAddVehicleRequest(entity)
            ),
            take(1),
            switchMap((request) =>
              this.vehicleService.callAddVehicle(request).pipe(
                switchMap((response) => {
                  const successPayload = {
                    ...response,
                    carPool: request.body.vehicle.carPool,
                    series: request.body.vehicle.series,
                    // annualMiles isnt returned by dsm response, so append it from request body here
                    annualMiles: request.body.vehicle.annualMiles,
                    // antiLockBrakes  isnt returned by dsm response.
                    // This data is required to enableOrDisable antiLockBreaks edit in the form
                    antiLockBrakes: request.body.vehicle.antiLockBrakes,
                    prefillId: request.body.vehicle.prefillId,
                    selected: true,
                    productType: request.productType,
                    additionalInformation:
                      request.body.vehicle.additionalInformation,
                    auxiliaryLights: request.body.vehicle.auxiliaryLights,
                    antiTheftDevices: request.body.vehicle.antiTheftDevices,
                  };
                  const returnActions: Action[] = [];
                  if (
                    !VehicleUtils.VehicleIsSaved(
                      requestAction.payload.vehicleId
                    )
                  ) {
                    returnActions.push(
                      VehicleActions.deleteTempVehicle({
                        payload: requestAction.payload.vehicleId,
                      })
                    );
                  }
                  returnActions.push(
                    VehicleActions.addVehicleSuccess({
                      payload: successPayload,
                      correlationId: requestAction.correlationId,
                    })
                  );

                  if (
                    (request.productType === 'PersonalAuto' &&
                      response.vehicleType === 'auto') ||
                    (request.productType === 'MSA' &&
                      (response.vehicleType === 'Motorcycle' ||
                        response.vehicleType === 'OffRoad' ||
                        response.vehicleType === 'Snowmobile')) ||
                    (request.productType === 'RV' &&
                      response.vehicleType === 'Motorhome')
                  ) {
                    returnActions.push(
                      dvaAction.checkForAutoAssign({
                        productType: request.productType,
                        vehicle: response,
                      })
                    );
                  }

                  this.setAuxiliaryLightsDiscount(
                    request,
                    response,
                    returnActions
                  );

                  this.setCarPoolDiscount(request, response, returnActions);

                  if (request.body.vehicle.prefillId) {
                    returnActions.push(
                      DriverVehiclePrefillActions.updatePrefillVehicle({
                        payload: {
                          id: request.body.vehicle.prefillId,
                          changes: { vehicleId: response.vehicleId },
                        },
                      })
                    );
                  }

                  returnActions.push(
                    TelematicsActions.updateStoreTelematicsVehicle({
                      payload: {
                        ...(response as unknown as ExtendedTelematicsVehicle),
                        annualMiles: request.body.vehicle.annualMiles,
                      },
                    })
                  );

                  return returnActions;
                }),
                catchError((error) => {
                  const saneError = this.errorSanitizerService.sanitizeError(
                    error,
                    request.productType
                  );
                  return of(
                    VehicleActions.addVehicleError({
                      error: saneError,
                      correlationId: requestAction.correlationId,
                      entityId: request.body.vehicle.vehicleId,
                    })
                  );
                })
              )
            )
          );
      })
    );
  });

  addTemporaryVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.addNewTempVehicle),
      map((action) => action.payload),
      switchMap((vehicle) =>
        this.store
          .select(VehicleSelectors.buildTemporaryVehicleEntity(vehicle))
          .pipe(
            take(1),
            map((temporaryVehicle) =>
              VehicleActions.addNewTempVehicleSuccess({
                payload: { ...temporaryVehicle, selected: true },
              })
            )
          )
      )
    )
  );

  updateVehicle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VehicleActions.updateVehicle),
      tap((action) =>
        this.store.dispatch(
          TelematicsActions.updateStoreTelematicsVehicle({
            payload: action.payload as unknown as ExtendedTelematicsVehicle,
          })
        )
      ),
      concatMap((requestAction) => {
        return this.vehicleService
          .fillMissingEntityFields(requestAction.payload)
          .pipe(
            switchMap((entity) =>
              this.vehicleService.composeUpdateVehicleRequest(entity)
            ),
            take(1),
            switchMap((request) =>
              this.vehicleService.callUpdateVehicle(request).pipe(
                switchMap((response) => {
                  const successPayload = {
                    vehicleSubType: undefined, // TODO: why are we doing this?
                    annualMiles: requestAction.payload.annualMiles,
                    ...response,
                    additionalInterests: response.additionalInterests?.length
                      ? response.additionalInterests
                      : [],
                    additionalInformation:
                      request.body.vehicle.additionalInformation,
                    antiLockBrakes: request.body.vehicle.antiLockBrakes,
                    antiTheftDevices: request.body.vehicle.antiTheftDevices,
                    selected: true,
                    productType: request.productType,
                  };
                  const returnActions: Action[] = [
                    VehicleActions.updateVehicleSuccess({
                      payload: successPayload,
                      correlationId: requestAction.correlationId,
                    }),
                  ];

                  this.setAuxiliaryLightsDiscount(
                    request,
                    response,
                    returnActions
                  );

                  this.setCarPoolDiscount(request, response, returnActions);

                  return returnActions;
                }),
                catchError((error) => {
                  const saneError = this.errorSanitizerService.sanitizeError(
                    error,
                    request.productType
                  );
                  return of(
                    VehicleActions.updateVehicleError({
                      error: saneError,
                      vehicleId: requestAction.payload.vehicleId || '',
                      correlationId: requestAction.correlationId,
                      entityId: request.body.vehicle.vehicleId,
                    })
                  );
                })
              )
            )
          );
      })
    );
  });

  deleteVehicle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VehicleActions.deleteVehicle),
      concatMap((requestAction) => {
        return this.vehicleService
          .composeDeleteVehicleRequest(requestAction.payload)
          .pipe(
            take(1),
            switchMap((request) =>
              this.vehicleService.callDeleteVehicle(request)
            ),
            map((response) =>
              VehicleActions.deleteVehicleSuccess({
                payload: requestAction.payload.vehicleId,
                correlationId: requestAction.correlationId,
              })
            ),
            catchError((error) => {
              const saneError = this.errorSanitizerService.sanitizeError(
                error,
                requestAction.payload.productType
              );
              return of(
                VehicleActions.deleteVehicleError({
                  error: saneError,
                  vehicleId: requestAction.payload.vehicleId,
                  correlationId: requestAction.correlationId,
                })
              );
            })
          );
      })
    );
  });

  refetchAllVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.refetchAllVehicles),
      switchMap((action) =>
        this.store
          .select(ProductsSelectors.getQuoteIdForProduct(action.productType))
          .pipe(
            take(1),
            map((quoteId) => ({ action, quoteId }))
          )
      ),
      switchMap(({ action, quoteId }) =>
        this.vehicleService.callGetVehicles(action.productType, quoteId).pipe(
          map((response) =>
            VehicleActions.refetchAllVehiclesSuccess({
              productType: action.productType,
              quoteId: quoteId!, // can't be undefined if callGetVehicles succeeded
              response,
            })
          ),
          catchError((error) => {
            return of(
              VehicleActions.refetchAllVehiclesError({
                productType: action.productType,
                error: this.errorSanitizerService.sanitizeError(
                  error,
                  action.productType
                ),
              })
            );
          })
        )
      )
    )
  );

  refetchVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.refetchVehicle),
      filter((action) => !!action.vehicle.productType),
      switchMap((action) =>
        this.productsService
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .getQuoteIdForProduct(action.vehicle.productType!)
          .pipe(
            take(1),
            filter((quoteId) => !!quoteId),
            map((quoteId) => ({ quoteId, action }))
          )
      ),
      concatMap(({ quoteId, action }) =>
        this.vehicleService
          .callGetVehicle(
            action.vehicle.productType || 'PersonalAuto',
            quoteId,
            action.vehicle.vehicleId
          )
          .pipe(catchError((error) => from([])))
      ),
      map((response) =>
        VehicleActions.storeVehicle({
          payload: response,
        })
      )
    )
  );

  private setAuxiliaryLightsDiscount(
    request: AddVehicleRequest | UpdateVehicleRequest,
    response: VehicleEntity,
    returnActions: Action[]
  ): void {
    if (
      request.productType === 'MSA' &&
      response.vehicleType === 'Snowmobile' &&
      request.body.vehicle.auxiliaryLights
    ) {
      const discount: EligibleDiscountsEntity = {
        eligibleDiscountId: 'AuxillaryLightingSystem',
        vehicleId: '' + response.vehicleId,
        quoteId: '' + request.quoteId,
        name: 'AuxillaryLightingSystem',
        productType: request.productType,
        selectedOptionValue: request.body.vehicle.auxiliaryLights || 'false',
      };
      returnActions.push(
        EligibleDiscountsActions.updateVehicleDiscount({
          payload: discount,
        })
      );
    }
  }

  private setCarPoolDiscount(
    request: AddVehicleRequest | UpdateVehicleRequest,
    response: VehicleEntity,
    returnActions: Action[]
  ): void {
    const carPoolDiscount = response.eligibleDiscounts?.find(
      (disc) => disc.eligibleDiscountId === 'Carpool'
    );

    if (!carPoolDiscount || request.body.vehicle.carPool === undefined) return;

    const discount: EligibleDiscountsEntity = {
      ...vehicleDiscountOptions.Carpool,
      selectedOptionValue: request.body.vehicle.carPool,
      vehicleId: '' + response.vehicleId,
      quoteId: '' + request.quoteId,
      productType: request.productType,
    };
    returnActions.push(
      EligibleDiscountsActions.updateVehicleDiscount({
        payload: discount,
      })
    );
  }
}
