import { Injectable } from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { EligibleDiscountsActions } from '@core/store/actions';
import * as fromEligibleDiscountsActions from '@core/store/entities/eligible-discounts/eligible-discounts.action';
import * as fromSelector from '@core/store/entities/eligible-discounts/eligible-discounts.selector';
import { manuallyInvalidateProduct } from '@core/store/entities/quote/quote.action';
import { getFortifiedHome } from '@core/store/property-form/property-form.selector';
import { Dictionary, Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { EligibleDiscountsBuilder } from '@shared/utils/builders/eligible-discounts.builder';
import { from, Observable, of, OperatorFunction } from 'rxjs';
import { concatMap, filter, map, switchMap, take } from 'rxjs/operators';
import { EligibleDiscountsAdapter } from '../adapters/eligible-discounts.adapter';
import { EligibleDiscountRequest } from '../models/api/request/eligible-discount-request.model';
import { EligibleDiscountResponse } from '../models/api/response/eligible-discount-response.model';
import {
  getPolicyLine,
  updatePolicyLine,
} from '../store/entities/eligible-discounts/eligible-discounts.action';
import {
  EligibleDiscountId,
  EligibleDiscountsEntity,
} from '../store/entities/eligible-discounts/eligible-discounts.entity';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root',
})
export class EligibleDiscountsService {
  constructor(
    private store: Store,
    private eligibleDiscountsAdapter: EligibleDiscountsAdapter,
    private actions$: Actions
  ) {}

  areEligibleDiscountsLoading(): Observable<boolean> {
    return this.store.select(fromSelector.getEligibleDiscountsLoading);
  }

  dispatchUpdatePolicyLine(entity: EligibleDiscountsEntity): void {
    this.store.dispatch(updatePolicyLine({ entity }));
  }

  dispatchUpdatePolicyLineAsPromise(
    entity: EligibleDiscountsEntity
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.actions$
        .pipe(
          ofType(
            EligibleDiscountsActions.updatePolicyLineSuccess,
            EligibleDiscountsActions.updatePolicyLineFail
          ),
          take(1)
        )
        .subscribe((action) => {
          if (
            action.type ===
            EligibleDiscountsActions.updatePolicyLineSuccess.type
          ) {
            resolve();
          } else {
            reject(action.error);
          }
        });
      this.store.dispatch(updatePolicyLine({ entity }));
    });
  }

  getModifiersForMultipleEntities(
    eligibleDiscountId: EligibleDiscountId
  ): Observable<Dictionary<EligibleDiscountsEntity>> {
    return this.store.select(
      fromSelector.getModifiersForMultipleEntities(eligibleDiscountId)
    );
  }

  getProductTypesWithDiscount(
    eligibleDiscountId: EligibleDiscountId
  ): Observable<ProductType[]> {
    return this.getModifiersForMultipleEntities(eligibleDiscountId).pipe(
      map(
        (discounts) =>
          Object.values(discounts)
            .map((d) => d?.productType)
            .filter((t) => !!t) as ProductType[]
      )
    );
  }

  dispatchGetPolicyLine(productType: ProductType): void {
    this.store.dispatch(getPolicyLine({ productType }));
  }

  getAllEligibleDiscounts(): Observable<EligibleDiscountsEntity[]> {
    return this.store.select(fromSelector.getAllEligibleDiscounts());
  }

  updateVehicleDiscount(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    return this.eligibleDiscountsAdapter.updateVehicleDiscount(request);
  }

  getPolicyLine(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse[]> {
    return this.eligibleDiscountsAdapter.getPolicyLine(request);
  }

  updatePolicyLine(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    return this.eligibleDiscountsAdapter.updatePolicyLine(request);
  }

  getPolicyLineModifier(
    name: EligibleDiscountId,
    productId: ProductType
  ): Observable<EligibleDiscountsEntity | undefined> {
    return this.store.select(
      fromSelector.getPolicyLineModifier(name, productId)
    );
  }

  updateDriverDiscount(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    return this.eligibleDiscountsAdapter.updateDriverDiscount(request);
  }

  getFortifiedHome() {
    return this.store.select(getFortifiedHome);
  }

  updateFortifiedHomeDiscount(): void {
    this.store.dispatch(EligibleDiscountsActions.updateFortifiedHome());
  }

  isEligibleDiscountPresent(
    eligibleDiscountId: EligibleDiscountId
  ): Observable<boolean> {
    return this.store.select(
      fromSelector.isEligibleDiscountPresent(eligibleDiscountId)
    );
  }

  getEligibleDiscountCallsInFlight(): Observable<string[]> {
    return this.store.select(fromSelector.getEligibleDiscountCallsInFlight);
  }

  /**
   * Emits once, when all calls are complete.
   */
  updateEligibleDiscountIfChanged(
    eligibleDiscountId: EligibleDiscountId,
    newValue: string,
    productTypeFilter?: (p: ProductType) => boolean
  ): Observable<null> {
    return this.store
      .select(
        fromSelector.getModifiersForMultipleEntities(
          eligibleDiscountId,
          productTypeFilter
        )
      )
      .pipe(
        take(1),
        switchMap((discounts) => {
          const updates: (EligibleDiscountsEntity | null)[] = [];
          for (const discount of Object.values(
            discounts
          ) as EligibleDiscountsEntity[]) {
            if ((discount.selectedOptionValue || '') === (newValue || '')) {
              continue;
            }
            updates.push(discount);
          }
          updates.push(null);
          return from(updates);
        }),
        concatMap((discount) => {
          if (!discount) {
            return of({ response: null, request: null });
          }
          const request = {
            eligibleDiscount: {
              ...discount,
              selectedOptionValue: newValue,
            },
            productType: discount.productType,
            quoteId: discount.modelId,
            name: discount.eligibleDiscountId,
          };
          return this.eligibleDiscountsAdapter
            .updatePolicyLine(request)
            .pipe(map((response) => ({ response, request })));
        }),
        map(({ response, request }) => {
          if (response) {
            if (request?.productType) {
              this.store.dispatch(
                manuallyInvalidateProduct({
                  productType: request.productType,
                  reason: `Discount ${request.eligibleDiscount.eligibleDiscountId}`,
                })
              );
            }
            this.store.dispatch(
              fromEligibleDiscountsActions.storeEligibleDiscount({
                entity: EligibleDiscountsBuilder.buildEntityFromResponse(
                  response,
                  request as EligibleDiscountRequest
                ),
              })
            );
          }
          return response;
        }),
        filter((result) => !result) as OperatorFunction<
          EligibleDiscountResponse | null,
          null
        >
      );
  }

  storePolicyLineModifier(discount: EligibleDiscountsEntity): void {
    this.store.dispatch(
      EligibleDiscountsActions.storePolicyLineModifier({
        discount: {
          id: `${discount.eligibleDiscountId}_${discount.modelId}`,
          changes: discount,
        } as Update<EligibleDiscountsEntity>,
      })
    );
  }
}
