import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProductType } from '@core/models/api/dsm-types';
import { getCoverageSyncByEntityId } from '@core/store/entities/coverage-sync/coverage-sync.selector';
import { CoverageSyncEntity } from '../store/entities/coverage-sync/coverage-sync.entity';

@Injectable({
  providedIn: 'root',
})
export class CoverageSyncService {
  constructor(private store: Store) {}

  getCoverageSyncByEntityId(
    coverageId: string,
    productId: ProductType,
    coverableId?: number
  ): Observable<CoverageSyncEntity | null> {
    return this.store.select(
      getCoverageSyncByEntityId(coverageId, productId, coverableId)
    );
  }
}
