import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/services/app-config.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LogService } from '../services/log.service';
import {
  StandardizeAddressResponse,
  SuggestAddressesResponse,
} from './gis.model';

@Injectable({
  providedIn: 'root',
})
export class GisAdapter {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private log: LogService
  ) {}

  standardizeAddress(input: string): Observable<StandardizeAddressResponse> {
    const params = new HttpParams({
      fromObject: {
        street: input,
        apikey: this.appConfigService.config.apiKey,
        partnerId: this.appConfigService.config.standardizedAddressPartnerId,
      },
    });

    const url = this.appConfigService.config.standardizedAddressUrl;
    const correlationId = this.log.logApiRequest(
      'GET',
      url,
      'standardize-address'
    );

    return this.httpClient
      .get<StandardizeAddressResponse>(url, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((response) =>
          this.log.logApiResponse(correlationId, response.status, response.body)
        ),
        map((response) => response.body || { standardizedAddresses: [] }),
        catchError((error) => this.log.logApiError(correlationId, error))
      );
  }

  suggestAddresses(
    input: string,
    state?: string
  ): Observable<SuggestAddressesResponse> {
    let params = new HttpParams({
      fromObject: {
        text: input,
      },
    });
    if (state) {
      params = params.set('state', state);
    }
    const headers = new HttpHeaders().set(
      'client_id',
      this.appConfigService.config.apiKey
    );
    const url = this.appConfigService.config.suggestAddressUrl;

    const correlationId = this.log.logApiRequest('GET', url, 'suggest-address');

    return this.httpClient
      .get<SuggestAddressesResponse>(url, {
        params,
        headers,
        observe: 'response',
      })
      .pipe(
        tap((response) =>
          this.log.logApiResponse(correlationId, response.status, response.body)
        ),
        map((response) => response.body || { suggestions: [] }),
        catchError((error) => this.log.logApiError(correlationId, error))
      );
  }
}
