import {
  PNIPageRepresentation,
  PeoplePageRepresentation,
} from '@core/constants/pages';
import { QuoteStatus, ProductType } from '@core/models/api/dsm-types';
import { PersonEntity } from '@core/models/entities/person.entity';
import { FeatureFlagsModel } from '../../feature-flag/feature-flag.model';
import { MemberEntity } from '../../member/member.reducer';
import { MemberRules } from '../rules/member.rules';
import { TaskValidators } from '../task-validators';
import { TaskModel } from '../task.model';
import { TaskUtils } from './task.util';
import { ProductModel } from '@entities/product/product.model';
import {
  MobileEnrollment,
  VehicleEnrollment,
} from '@entities/telematics/telematics.model';

export class MemberTaskUtils {
  static generatePNITasks(
    pni: MemberEntity,
    features: FeatureFlagsModel,
    productQuoteStatus: QuoteStatus,
    selectedProducts: ProductModel[]
  ): TaskModel[] {
    const emailTask = this.generateEmailTask(pni, selectedProducts);
    const tasks = TaskValidators.runEntityRulesForSingle(
      TaskUtils.createTaskTemplates(
        MemberRules.members.pni,
        features,
        productQuoteStatus,
        selectedProducts.map((p) => p.type)
      ),
      pni,
      PNIPageRepresentation,
      'pni',
      'All',
      'entityId'
    );
    if (emailTask) tasks.push(emailTask);
    return tasks;
  }

  static generateMemberTasks(
    members: MemberEntity[],
    features: FeatureFlagsModel,
    productQuoteStatus: QuoteStatus,
    selectedProductTypes: ProductType[]
  ): TaskModel[] {
    const t = MemberRules.members;
    return [
      ...TaskValidators.runEntityRulesForEach<MemberEntity>(
        TaskUtils.createTaskTemplates(
          t.driver,
          features,
          productQuoteStatus,
          selectedProductTypes
        ),
        members,
        PeoplePageRepresentation,
        'member',
        'All',
        'entityId'
      ),
      ...TaskValidators.runEntityRulesForAll<MemberEntity>(
        TaskUtils.createTaskTemplates(t.people, features, productQuoteStatus),
        members,
        PeoplePageRepresentation,
        'person',
        'All'
      ),
      ...TaskValidators.runEntityRulesForEachSubEntity<
        MemberEntity,
        PersonEntity
      >(
        TaskUtils.createTaskTemplates(t.person, features, productQuoteStatus),
        members,
        PeoplePageRepresentation,
        'person',
        'All',
        'person',
        'entityId'
      ),
    ];
  }

  static generateEmailTask(
    pni: MemberEntity,
    products: ProductModel[]
  ): TaskModel | undefined {
    // Email is required if customer wants docs delivered electronically
    const docDeliveryCase = products.some(
      (p) => p.docDelPreference === 'OnlineAccountAccess'
    );
    // Email is required if customer wants to automate electronic delivery of required documents (regardless of doc delivery preference)
    const automatingElectronicDelivery = products.some(
      (p) => p.automateElectronicDocumentDelivery
    );
    const noPniEmail = !pni?.emailAddress;

    if ((docDeliveryCase || automatingElectronicDelivery) && noPniEmail) {
      return {
        message: 'Email address is required',
        ratingStatusOrdinal: 'Quoted',
        productType: 'All',
        field: 'emailAddress',
        elementId: 'email',
        entityType: 'pni',
        entityId: pni.entityId,
        page: PNIPageRepresentation,
      };
    }
    return undefined;
  }
}
