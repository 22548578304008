<form
  *ngIf="model"
  [formGroup]="form"
  [nwxExposeFormErrors]="form"
  [submitted]="submitted"
  id="Eligibility-form"
>
  <ng-container *ngIf="model.prequalificationAnswers as o">
    <h2>Eligibility questions</h2>
    <div class="bolt-container-fluid inner-card">
      <ng-container *ngFor="let questionCode of getEligibilityQuestionCodes()">
        <ng-container
          *ngTemplateOutlet="booleanQuestion; context: { o, k: questionCode }"
        ></ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="showRiskItems && model.riskItems as o">
    <h2>Property risk</h2>
    <div class="bolt-container-fluid inner-card">
      <ng-container
        *ngTemplateOutlet="
          booleanQuestion;
          context: { o, k: 'registeredHistoric' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          booleanQuestion;
          context: { o, k: 'nonResidentialToResidential' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="booleanQuestion; context: { o, k: 'deededOwner' }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          booleanQuestion;
          context: { o, k: 'isShortSaleAuctForeclose' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          booleanQuestion;
          context: { o, k: 'currentConstruction' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          booleanQuestion;
          context: { o, k: 'currentRemodelingOrRehabiliation' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="booleanQuestion; context: { o, k: 'incidentalFarm' }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          booleanQuestion;
          context: { o, k: 'animalThatCausedInjury' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          booleanQuestion;
          context: { o, k: 'dangerousOrExoticAnimal' }
        "
      ></ng-container>

      <ng-container
        *ngTemplateOutlet="booleanQuestion; context: { o, k: 'haveDogs' }"
      ></ng-container>
      <nwx-eligibility-dog-list
        *ngIf="shouldShowDogList()"
        [riskItems]="o"
        [quoteId]="quoteId"
        [submitted]="submitted"
        (valueChange)="onDogsChanged($event)"
        (removeProduct)="removeProduct()"
      ></nwx-eligibility-dog-list>

      <ng-container
        *ngTemplateOutlet="booleanQuestion; context: { o, k: 'trampoline' }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          enumQuestion;
          context: {
            o,
            k: 'distanceToNonOwnedBuildingTrampoline',
            options: ['Greater1000ft', 'LessThanEq1000ft']
          }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          booleanQuestion;
          context: { o, k: 'trampolineFence' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          booleanQuestion;
          context: { o, k: 'trampolineTieDowns' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="booleanQuestion; context: { o, k: 'trampolineNet' }"
      ></ng-container>

      <ng-container
        *ngTemplateOutlet="booleanQuestion; context: { o, k: 'swimmingPool' }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          enumQuestion;
          context: {
            o,
            k: 'typeOfSwimmingPool',
            options: ['aboveGround', 'inground']
          }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          enumQuestion;
          context: {
            o,
            k: 'distanceToNonOwnedBuildingPool',
            options: ['Greater1000ft', 'LessThanEq1000ft']
          }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          booleanQuestion;
          context: { o, k: 'swimmingLessonsProvided' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="booleanQuestion; context: { o, k: 'poolLadder' }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          booleanQuestion;
          context: { o, k: 'removableLadder' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="booleanQuestion; context: { o, k: 'divingBoard' }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          numberQuestion;
          context: { o, k: 'waterDepthUnderDivingBoard', min: 1, max: 99 }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="booleanQuestion; context: { o, k: 'poolSlide' }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          numberQuestion;
          context: { o, k: 'waterDepthUnderSlide', min: 1, max: 99 }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="booleanQuestion; context: { o, k: 'poolFence' }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          booleanQuestion;
          context: { o, k: 'selfLatchingGate' }
        "
      ></ng-container>
    </div>
  </ng-container>

  <ng-template #booleanQuestion let-key="k" let-container="o">
    <ng-container *ngIf="getQuestionTextIfVisible(key) as text">
      <div class="bolt-row question-row">
        <div class="bolt-col-12 bolt-col-sm-9 question-col">
          {{ text }}
        </div>
        <div class="bolt-col-12 bolt-col-sm-3">
          <bolt-radio-group
            [formControlName]="key"
            [attr.formcontrolname]="key"
            [arialabel]="text"
            [attr.horizontal]="horizontalRadio || null"
            attr.id="ef-{{ key }}"
            required
          >
            <bolt-radio value="true" id="{{ key }}-yes">Yes</bolt-radio>
            <bolt-radio value="false" id="{{ key }}-no">No</bolt-radio>
          </bolt-radio-group>
        </div>
      </div>
      <ng-container
        *ngTemplateOutlet="message; context: { key }"
      ></ng-container>
      <div class="bolt-row">
        <div class="bolt-col-12">
          <hr />
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template
    #numberQuestion
    let-key="k"
    let-container="o"
    let-min="min"
    let-max="max"
  >
    <ng-container *ngIf="getQuestionTextIfVisible(key) as text">
      <div class="bolt-row question-row">
        <div class="bolt-col-12 bolt-col-sm-9 question-col">
          {{ text }}
        </div>
        <div class="bolt-col-12 bolt-col-sm-3">
          <bolt-textfield
            type="number"
            [min]="min"
            [max]="max"
            [formControlName]="key"
            [attr.formcontrolname]="key"
            [attr.name]="key"
            [attr.id]="key"
            [arialabel]="text"
            required
          ></bolt-textfield>
        </div>
      </div>
      <ng-container
        *ngTemplateOutlet="message; context: { key }"
      ></ng-container>
      <div class="bolt-row">
        <div class="bolt-col-12">
          <hr />
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template
    #enumQuestion
    let-key="k"
    let-container="o"
    let-options="options"
  >
    <ng-container *ngIf="getQuestionTextIfVisible(key) as text">
      <div class="bolt-row question-row">
        <div class="bolt-col-12 bolt-col-sm-9 question-col">
          {{ text }}
        </div>
        <div class="bolt-col-12 bolt-col-sm-3">
          <bolt-select
            [formControlName]="key"
            [attr.formcontrolname]="key"
            [attr.name]="key"
            [attr.id]="key"
            [arialabel]="text"
            required
          >
            <option *ngFor="let option of options" [value]="option">
              {{ getPicklistDisplayString(key, option) }}
            </option>
          </bolt-select>
        </div>
      </div>
      <ng-container
        *ngTemplateOutlet="message; context: { key }"
      ></ng-container>
      <div class="bolt-row">
        <div class="bolt-col-12">
          <hr />
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #message let-key="key">
    <div class="bolt-row" *ngIf="getQuestionMessage(key) as message">
      <div class="bolt-col-12">
        <bolt-notification
          [type]="message.action === 'pc' ? 'warning' : 'error'"
        >
          <div class="inline-message-content">
            <div
              class="inline-message-text"
              [innerHTML]="inlineMessageHtmlFromString(message.message)"
            ></div>
            <ng-container [ngSwitch]="message.action">
              <ng-container *ngSwitchCase="'pc'">
                <bolt-button type="primary" (click)="pivotToPc(key)"
                  >Go to PolicyCenter</bolt-button
                >
              </ng-container>
              <ng-container *ngSwitchCase="'abort'">
                <bolt-button type="primary" (click)="removeProduct()"
                  >Remove product</bolt-button
                >
              </ng-container>
            </ng-container>
          </div>
        </bolt-notification>
      </div>
    </div>
  </ng-template>

  <ng-container *ngIf="showRiskItems">
    <div class="bolt-container-fluid">
      <div class="bolt-row">
        <div
          class="bolt-col ack"
          [ngClass]="{ invalid: showAcknowledgementHighlight }"
        >
          <bolt-icon
            *ngIf="showAcknowledgementHighlight"
            name="exclamation-circle-filled"
            color="theme-error"
          ></bolt-icon>
          <bolt-checkbox
            formControlName="acknowledgement"
            name="acknowledgement"
            value="true"
            arialabelledby="eligibility-ack-text"
            ngDefaultControl
          >
            <strong>
              I reviewed and confirm the eligibility &amp; property risk answers
              are accurate
            </strong>
          </bolt-checkbox>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="bolt-container-fluid error-alert" *ngIf="error">
    <bolt-notification type="error">
      {{ error.displayMessage }}
    </bolt-notification>
  </div>

  <div class="edit-proof-blotter" [ngClass]="{ visible: readonly }"></div>
  <div class="passive-spinner-container" *ngIf="readonly">
    <nwx-passive-spinner label="Updating..."></nwx-passive-spinner>
  </div>
</form>
