import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { from, Observable } from 'rxjs';
import { EligibilityFormModel } from '../store/eligibility-form.model';
import { EligibilityFormService } from '../store/eligibility-form.service';

@Component({
  selector: 'nwx-eligibility-inline-container',
  templateUrl: './eligibility-inline-container.component.html',
  styleUrls: ['./eligibility-inline-container.component.scss'],
})
export class EligibilityInlineContainerComponent implements OnInit {
  @Input() productType!: ProductType;
  @Input() showRiskItems!: boolean;
  @Output() statusChange = new EventEmitter<string>();

  status: string = '';
  highlightAcknowledgementRequired = false;

  model$: Observable<EligibilityFormModel>;

  constructor(
    private eligibilityFormService: EligibilityFormService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.model$ = from([]);
  }

  ngOnInit(): void {
    this.model$ = this.eligibilityFormService
      .getEligibilityFormByProductType(this.productType)
      .pipe(filterOutNull());
  }

  onValueChange(value: EligibilityFormModel): void {
    this.eligibilityFormService.updateEligibilityFormModel(value);
  }

  onStatusChange(status: string): void {
    this.status = status;
    this.statusChange.next(status);
  }

  highlightRequiredErrors(): void {
    this.highlightAcknowledgementRequired = true;
    this.changeDetector.markForCheck();
  }
}
