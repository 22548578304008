export class ProtectiveDeviceTypes {
  static readonly BUILDING_FOUNDATION_CONNECTION: ProtectiveDeviceType = {
    typekey: 'BldgFoundationConnection_Ext',
    pcDisplayString: 'Building Foundation Connection',
  };

  static readonly BURGLAR_ALARM: ProtectiveDeviceType = {
    typekey: 'Burglar Alarm',
    pcDisplayString: 'Burglar Alarm',
  };

  static readonly DOOR_TYPE: ProtectiveDeviceType = {
    typekey: 'DoorType_Ext',
    pcDisplayString: 'Door Type',
  };

  static readonly FIRE_SMOKE_ALARM: ProtectiveDeviceType = {
    typekey: 'FireOrSmokeAlarm_Ext',
    pcDisplayString: 'Fire/Smoke Alarm',
  };

  static readonly SECONDARY_WATER_RESISTANCE: ProtectiveDeviceType = {
    typekey: 'HasSecondaryWaterResist_Ext',
    pcDisplayString: 'Secondary Water Resistance',
  };

  static readonly OPENING_PROTECTION: ProtectiveDeviceType = {
    typekey: 'OpeningProtection_Ext',
    pcDisplayString: 'Opening Protection',
  };

  static readonly ROOF_COVERING: ProtectiveDeviceType = {
    typekey: 'RoofCovering_Ext',
    pcDisplayString: 'Roof Covering',
  };

  static readonly ROOF_DECK_ATTACHMENT: ProtectiveDeviceType = {
    typekey: 'RoofDeckAttachment_Ext',
    pcDisplayString: 'Roof Deck Attachment',
  };

  static readonly ROOF_GEOMETRY: ProtectiveDeviceType = {
    typekey: 'RoofGeometry_Ext',
    pcDisplayString: 'Roof Geometry',
  };

  static readonly ROOF_WALL_CONNECTION: ProtectiveDeviceType = {
    typekey: 'RoofToWallConnection_Ext',
    pcDisplayString: 'Roof To Wall Connection',
  };

  static readonly SPRINKLER_SYSTEM: ProtectiveDeviceType = {
    typekey: 'Sprinkler System',
    pcDisplayString: 'Sprinkler System',
  };

  static readonly WIND_PROTECTIVE_DEVICE: ProtectiveDeviceType = {
    typekey: 'WindProtectiveDevice_Ext',
    pcDisplayString: 'Wind Protective Device',
  };

  static readonly WINDOW_PROTECTION: ProtectiveDeviceType = {
    typekey: 'WindowProtection_Ext',
    pcDisplayString: 'Window Protection',
  };

  static readonly WROUGHT_IRON_BARS: ProtectiveDeviceType = {
    typekey: 'Wrought Iron Bars',
    pcDisplayString: 'Wrought Iron Bars'
  };

  static readonly WIND_MITIGATION: ProtectiveDeviceType = {
    typekey: 'Wind Mitigation',
    pcDisplayString: 'Wind Mitigation'
  };
}

export interface ProtectiveDeviceType {
  typekey: string; // eg "DoorType_Ext"
  pcDisplayString: string; // eg "Door Type", PC uses sometimes in place of typekey
}

export interface ProtectiveDeviceStrings extends ProtectiveDeviceType {
  nwxDisplayString?: string; // Override for our display purposes only.
  helpText?: string;
  values: {
    typekey: string;
    pcDisplayString: string;
    nwxDisplayString?: string;
  }[];
}

export const PROTECTIVE_DEVICE_STRINGS: ProtectiveDeviceStrings[] = [
  {
    typekey: ProtectiveDeviceTypes.BUILDING_FOUNDATION_CONNECTION.typekey,
    pcDisplayString: ProtectiveDeviceTypes.BUILDING_FOUNDATION_CONNECTION.pcDisplayString,
    values: [
      // pls-policycenter/config/resources/systables/Avail_RadioObjects_Ext.xml
      {
        typekey: 'HurricaneTies',
        pcDisplayString:
          'Hurricane Ties, Anchor Bolts or Structurally Connected, Nails/Screws',
      },
      {
        typekey: 'Nails',
        pcDisplayString: 'Nails/Screws',
      },
      {
        typekey: 'GravityFriction',
        pcDisplayString: 'Gravity/Friction or Adhesive/Epoxy or Unknown',
      },
    ],
  },
  {
    typekey: ProtectiveDeviceTypes.BURGLAR_ALARM.typekey,
    pcDisplayString: ProtectiveDeviceTypes.BURGLAR_ALARM.pcDisplayString,
    helpText:
      '<strong>Local:</strong> Alarm Sounds on Premises only.<br>' +
      '<strong>Central:</strong> Alerts a security company that may send someone to investigate or call the ' +
      'police/fire department. <br>' +
      '<strong>Direct:</strong> Alerts the police or fire department directly.',
    values: [
      // BurglarAlarmType_HOE
      {
        typekey: 'LocalAlarm_Ext',
        pcDisplayString: 'Local Alarm',
      },
      {
        typekey: 'CentralAlarm_Ext',
        pcDisplayString: 'Central Burglar Alarm',
      },
      {
        typekey: 'CentralBurglarAlarm_Ext',
        pcDisplayString: 'Central/Direct Burglar Alarm',
      },
      {
        typekey: 'DirectAlarm_Ext',
        pcDisplayString: 'Direct Burglar Alarm',
      },
      {
        typekey: 'FirePoliceSecurity_Ext',
        pcDisplayString: 'Fire / Police Security',
      },
      {
        typekey: 'LocalBurglarAlarm_Ext',
        pcDisplayString: 'Complete Local Burglary Alarm',
      },
      {
        typekey: 'PoliceStation_Ext',
        pcDisplayString: 'Police Station',
      },
      {
        typekey: 'UltrasonicLocalAlarm_Ext',
        pcDisplayString: 'Ultrasonic Alarm',
      },
      {
        typekey: 'central',
        pcDisplayString: 'Central Station',
      },
    ],
  },
  {
    typekey: ProtectiveDeviceTypes.DOOR_TYPE.typekey,
    pcDisplayString: ProtectiveDeviceTypes.DOOR_TYPE.pcDisplayString,
    values: [
      // pls-policycenter/config/resources/systables/Avail_RadioObjects_Ext.xml
      {
        typekey: 'Doublewidth',
        pcDisplayString: 'Double-width, Sliding or Unknown',
      },
      {
        typekey: 'ReinforcedSingleWidthDoor',
        pcDisplayString: 'Reinforced Single-width Doors',
      },
      {
        typekey: 'Singlewidth',
        pcDisplayString:
          'Single-width, Reinforced Double-width or Reinforced Sliding Doors',
      },
    ],
  },
  {
    typekey: ProtectiveDeviceTypes.FIRE_SMOKE_ALARM.typekey,
    pcDisplayString: ProtectiveDeviceTypes.FIRE_SMOKE_ALARM.pcDisplayString,
    helpText:
      '<strong>Local:</strong> Alarm Sounds on Premises only.<br>' +
      '<strong>Central:</strong> Alerts a security company that may send someone to investigate or call the ' +
      'police/fire department. <br>' +
      '<strong>Direct:</strong> Alerts the police or fire department directly.',
    values: [
      // FireSmokeAlarmType_Ext
      {
        typekey: 'LocalAlarm',
        pcDisplayString: 'Local Alarm',
      },
      {
        typekey: 'CentralAlarm',
        pcDisplayString: 'Central Fire Alarm',
      },
      {
        typekey: 'CentralFireAlarm_Ext',
        pcDisplayString: 'Central/Direct Fire Alarm',
      },
      {
        typekey: 'DirectAlarm',
        pcDisplayString: 'Direct Fire Alarm',
      },
      {
        typekey: 'CentralStation',
        pcDisplayString: 'Central Station',
      },
      {
        typekey: 'FireDepartment',
        pcDisplayString: 'Fire Department',
      },
      {
        typekey: 'LocalFireOrSmoke_Ext',
        pcDisplayString: 'Local Fire or Smoke Alarm',
      },
    ],
  },
  {
    typekey: ProtectiveDeviceTypes.SECONDARY_WATER_RESISTANCE.typekey,
    pcDisplayString: ProtectiveDeviceTypes.SECONDARY_WATER_RESISTANCE.pcDisplayString,
    values: [
      { typekey: 'true', pcDisplayString: 'true' },
      { typekey: 'false', pcDisplayString: 'false' },
    ],
  },
  {
    typekey: ProtectiveDeviceTypes.OPENING_PROTECTION.typekey,
    pcDisplayString: ProtectiveDeviceTypes.OPENING_PROTECTION.pcDisplayString,
    values: [
      // pls-policycenter/config/resources/systables/Avail_RadioObjects_Ext.xml
      {
        typekey: 'NoValue',
        pcDisplayString: 'No Value',
      },
      {
        typekey: 'None', // becomes "OpeningProtectionNone" in NC
        pcDisplayString: 'None',
      },
      {
        typekey: 'Basic',
        pcDisplayString: 'Basic',
      },
      {
        typekey: 'Hurricane',
        pcDisplayString: 'Hurricane',
      },
      {
        typekey: 'WoodPanels',
        pcDisplayString: 'Wood Panels',
      },
      {
        typekey: 'NotRated',
        pcDisplayString: 'Not Rated',
      },
      {
        typekey: 'OpeningProtectionApplies', // NC
        pcDisplayString: 'Opening Protection applies',
      },
    ],
  },
  {
    typekey: ProtectiveDeviceTypes.ROOF_COVERING.typekey,
    pcDisplayString: ProtectiveDeviceTypes.ROOF_COVERING.pcDisplayString,
    values: [
      // pls-policycenter/config/resources/systables/Avail_RadioObjects_Ext.xml
      {
        typekey: 'AsphaltWoodShingesWithScrewsClaySlateTiles',
        pcDisplayString:
          'Asphalt or Wood Shingles attached with Screws or Clay/Concrete Tiles, Slate, Build-up Roof with Gravel, Built-up Roof without Gravel Standing, Seam Metal, Single Ply Membrane or Single Ply Membrane Ballasted attached with Nails/Staples, Mortar, Screw or Adhesive/Epoxy',
        nwxDisplayString: 'Typical Setup',
      },
      {
        typekey: 'AsphaltWoodShingleWithNailsStaplesMortarAdhesive',
        pcDisplayString:
          'Asphalt or Wood Shingles attached with Nails/Staples, Mortar or Adhesive/Epoxy',
      },
      {
        typekey: 'LightMetalPanels',
        pcDisplayString:
          'Light Metal Panels attached with Nails/Staples, Mortar, Screws or Adhesive/Epoxy or Unknown',
      },
      {
        typekey: 'MeetsFloridabuildingcode',
        pcDisplayString: 'Meets Florida building code',
      },
      {
        typekey: 'DoesnotmeetFloridabuildingcode',
        pcDisplayString: 'Does not meet Florida building code',
      },
      {
        typekey: 'RoofCoveringUnknown',
        pcDisplayString: 'Unknown',
      },
    ],
  },
  {
    typekey: ProtectiveDeviceTypes.ROOF_DECK_ATTACHMENT.typekey,
    pcDisplayString: ProtectiveDeviceTypes.ROOF_DECK_ATTACHMENT.pcDisplayString,
    values: [
      // pls-policycenter/config/resources/systables/Avail_RadioObjects_Ext.xml
      {
        typekey: 'LevelA',
        pcDisplayString: 'Level A',
      },
      {
        typekey: 'LevelB',
        pcDisplayString: 'Level B',
      },
      {
        typekey: 'LevelC',
        pcDisplayString: 'Level C',
      },
      {
        typekey: 'LevelD',
        pcDisplayString: 'Level D',
      },
      {
        typekey: 'ReinforcedConcrete',
        pcDisplayString: 'Reinforced Concrete',
      },
      {
        typekey: 'RoofDeckAttachmentUnknown',
        pcDisplayString: 'Unknown',
      },
    ],
  },
  {
    typekey: ProtectiveDeviceTypes.ROOF_GEOMETRY.typekey,
    pcDisplayString: ProtectiveDeviceTypes.ROOF_GEOMETRY.pcDisplayString,
    values: [
      // pls-policycenter/config/resources/systables/Avail_RadioObjects_Ext.xml
      {
        typekey: 'GableEndWithoutBracing',
        pcDisplayString:
          'Gable End without Bracing, Complex, Flat, Shed or Unknown',
      },
      {
        typekey: 'GableEndWithBracing',
        pcDisplayString: 'Gable End with Bracing, Stepped, or Gambrel',
      },
      {
        typekey: 'Hip',
        pcDisplayString: 'Hip, Mansard or Pyramid',
      },
      {
        typekey: 'NoValue',
        pcDisplayString: 'No Value',
      },
      {
        typekey: 'RoofGeometryHipRoof', // FL. In NC it is "HipRoof"
        pcDisplayString: 'Hip Roof',
      },
      {
        typekey: 'Other',
        pcDisplayString: 'Other',
      },
      {
        typekey: 'RoofGeometryNone', // NC
        pcDisplayString: 'None',
      },
    ],
  },
  {
    typekey: ProtectiveDeviceTypes.ROOF_WALL_CONNECTION.typekey,
    pcDisplayString: ProtectiveDeviceTypes.ROOF_WALL_CONNECTION.pcDisplayString,
    values: [
      // pls-policycenter/config/resources/systables/Avail_RadioObjects_Ext.xml
      {
        typekey: 'AnchorBolts',
        pcDisplayString:
          'Anchor Bolts, Hurricane Ties, or Structurally Connected',
      },
      {
        typekey: 'NailsScrews',
        pcDisplayString: 'Nails or Screws',
      },
      {
        typekey: 'GravityFriction',
        pcDisplayString: 'Gravity/Friction or Adhesive/Epoxy or Unknown',
      },
      {
        typekey: 'ToeNail',
        pcDisplayString: 'Toe Nail',
      },
      {
        typekey: 'Clips',
        pcDisplayString: 'Clips',
      },
      {
        typekey: 'SingleWraps',
        pcDisplayString: 'Single Wraps',
      },
      {
        typekey: 'DoubleWraps',
        pcDisplayString: 'Double Wraps',
      },
      {
        typekey: 'Structural',
        pcDisplayString: 'Structural',
      },
      {
        typekey: 'RooftoWallConnectionUnknown',
        pcDisplayString: 'Unknown',
      },
    ],
  },
  {
    typekey: ProtectiveDeviceTypes.SPRINKLER_SYSTEM.typekey,
    pcDisplayString: ProtectiveDeviceTypes.SPRINKLER_SYSTEM.pcDisplayString,
    helpText:
      '<strong>Partial:</strong> Automatic Sprinklers in major areas of the home but not in bathrooms, attics, ' +
      'closets, attached structures etc.<br>' +
      '<strong>Full: </strong> Automatic Sprinklers in all areas of the home.',
    values: [
      // SprinklerSystemType_HOE
      {
        typekey: 'FullyAutomatic_Ext',
        pcDisplayString: 'Fully Automatic',
      },
      {
        typekey: 'Partial_Ext',
        pcDisplayString: 'Partial',
      },
    ],
  },
  {
    typekey: ProtectiveDeviceTypes.WIND_PROTECTIVE_DEVICE.typekey,
    pcDisplayString: ProtectiveDeviceTypes.WIND_PROTECTIVE_DEVICE.pcDisplayString,
    helpText:
      '<strong>Wind Protective Devices:</strong> Loss avoidance home features like impact-resistant doors/windows, ' +
      'storm shutters, hurricane straps, or clips connecting your roof to your walls. For example, ' +
      'Engineered or Non-Engineered shutters installed on all roof and exterior wall openings.',
    values: [
      // HOMod_WindProtective_Ext
      {
        typekey: 'EngineeredShutters',
        pcDisplayString: 'Engineered Shutters (with any glass type)',
      },
      {
        typekey: 'NonEngineeredShutters',
        pcDisplayString: 'Non-Engineered Shutters (with any glass type)',
      },
      {
        typekey: 'HurrResistStormShutters',
        pcDisplayString: 'Hurricane Resistant Permanent Storm Shutters',
      },
      {
        typekey: 'HurrResistLaminatedGlass',
        pcDisplayString: 'Hurricane Resistant Laminated Glass',
      },
      {
        typekey: 'HurrResistStormShuttersLaminatedGlass',
        pcDisplayString:
          'Hurricane Resistant Permanent Storm Shutters and Hurricane Resistant Glass',
      },
    ],
  },
  {
    typekey: ProtectiveDeviceTypes.WINDOW_PROTECTION.typekey,
    pcDisplayString: ProtectiveDeviceTypes.WINDOW_PROTECTION.pcDisplayString,
    values: [
      {
        typekey: 'Unknown',
        pcDisplayString: 'Unknown',
      },
      {
        typekey: 'EngineeredShuttersWithAnyGlassType',
        pcDisplayString: 'Engineered Shutters (with any glass type)',
      },
      {
        typekey: 'NonEngineeredShuttersWithAnyGlassType',
        pcDisplayString: 'Non-Engineered Shutters (with any glass type)',
      },
    ],
  },
];
