import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { DownPaymentExperienceResponse } from '@core/models/api/response/down-payment-experience-response.model';
import { ProductModel } from '@core/store/entities/product/product.model';
import { MortgageModel } from '@shared/components/forms/mortgage-form/mortgage-form.model';
import { Nullable } from '@shared/utils/type.utils';
import { FeatureFlagsModel } from '@core/store/entities/feature-flag/feature-flag.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { BillingPlansFormModel } from '../billing-plans-form/billing-plans-form.model';
import { ViewportType } from '@core/store/entities/layout/layout.entity';

@Component({
  selector: 'nwx-billing-accounts-modal',
  templateUrl: './billing-accounts-modal.component.html',
  styleUrls: ['./billing-accounts-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingAccountsModalComponent implements OnInit {
  // Must be populated by whoever owns the NgbModal:
  billingPlansFormModel!: BillingPlansFormModel | null;
  mortgageFormModel!: MortgageModel | null;
  downPaymentResponse!: Nullable<DownPaymentExperienceResponse> | null;
  productModels!: ProductModel[];
  quoteState!: string;
  hasMortgage!: boolean;
  displayEscrowQuestion!: boolean;
  displayBillingPlanAsOptions!: boolean;
  easyPayDiscountAmount!: Nullable<number>;
  featureFlags!: Nullable<FeatureFlagsModel>;
  maxSizeSmall$!: Observable<boolean>;
  modalTitle!: string;

  @Output() billingPlanChanged = new EventEmitter<
    Partial<BillingPlansFormModel>
  >();
  @Output() saveRecalculateQuote = new EventEmitter<void>();

  unsubscribe: Subject<void> = new Subject<void>();
  updatedPayPlanForm: BillingPlansFormModel = { billingPlan: '' };

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.updatedPayPlanForm.billingPlan =
      this.billingPlansFormModel?.billingPlan || '';
    this.updatedPayPlanForm.billToEscrowAccount =
      this.billingPlansFormModel?.billToEscrowAccount;
    this.updatedPayPlanForm.monthlyPaymentDay =
      this.billingPlansFormModel?.monthlyPaymentDay;
  }

  onBillingPlansFormChange(event: Partial<BillingPlansFormModel>): void {
    if (event.billingPlan) {
      this.updatedPayPlanForm.billingPlan = event.billingPlan;
    }
    if (event.billToEscrowAccount !== undefined) {
      this.updatedPayPlanForm.billToEscrowAccount = event.billToEscrowAccount;
    }
    if (event.monthlyPaymentDay) {
      this.updatedPayPlanForm.monthlyPaymentDay = event.monthlyPaymentDay;
    }
  }

  onSubmit(): void {
    if (!this.modelsEquivalent(this.billingPlansFormModel, this.updatedPayPlanForm)) {
      this.billingPlanChanged.next(this.updatedPayPlanForm);
      this.saveRecalculateQuote.next();
    }
    this.activeModal.close();
  }

  private modelsEquivalent(
    a: BillingPlansFormModel | null,
    b: BillingPlansFormModel | null
  ): boolean {
    if (!a || !b) {
      return !!(a || b);
    }
    return (
      a.billToEscrowAccount === b.billToEscrowAccount &&
      a.billingPlan === b.billingPlan &&
      a.monthlyPaymentDay === b.monthlyPaymentDay
    );
  }
}
