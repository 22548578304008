import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromVehicles from '@core/store/entities/vehicle/vehicle.reducer';
import { VehicleUtils } from '@shared/utils/vehicle.utils';
import { getAllFeatureFlags } from '../feature-flag/feature-flag.selector';
import { getQuoteState } from '../session/session.selector';
import {
  getProductFurthestQuoteStatus,
  getIsAssignedRiskPlan,
  getProductQuoteStatus,
} from '../product/product.selectors';
import { VehicleEntity } from './vehicle.entity';
import { HeaderCardItem } from '@core/interfaces/interfaces';
import { getCurrentCarrierFormModel } from '../current-carrier/current-carrier.selector';
import { VehiclesPageModel } from '@core/models/views/vehicle-page-model';
import { getNonAddedPrefillVehicles } from '../driver-vehicle-prefill/driver-vehicle-prefill.selector';
import { VehicleFormFromEntityBuilder } from '@app/shared/utils/builders/vehicles/vehicle-form-from-entity';
import { MsaPageModel } from '@app/powersports/msa/pages/msa-page.component';
import { ProductType } from '@core/models/api/dsm-types';
import {
  getAllSelectedDriverMemberModels,
  getAllSelectedMemberModels,
} from '../member/member.selector';
import { getAddressForProduct } from '../address/address.selector';
import { DriverEntity } from '../driver/driver.entity';
import { BasicCompanyInformation } from '@app/additional-interests/additional-interest-holder-selection/additional-interest-holder-selection.component';
import { PersonEntity } from '@core/models/entities/person.entity';
import { MemberModel } from '@core/models/views/person.model';

export const getVehicleState = createSelector(
  fromCore.getCoreState,
  (state) => state.vehicles
);

export const {
  selectAll: getAllVehicles,
  selectEntities: getVehicleEntities,
  selectTotal: getTotalVehicles,
} = fromVehicles.adapter.getSelectors(getVehicleState);

export const getAllVehiclesByProduct = (productType: ProductType) =>
  createSelector(getAllVehicles, (vehicles) => {
    return vehicles?.filter((vehicle) => vehicle.productType === productType);
  });

export const getAllSavedVehiclesByProduct = (productId: ProductType) =>
  createSelector(getAllVehiclesByProduct(productId), (vehicles) =>
    vehicles.filter((vehicle) => VehicleUtils.VehicleIsSaved(vehicle.vehicleId))
  );

export const getAutoTypeVehicles = createSelector(
  getAllSavedVehiclesByProduct('PersonalAuto'),
  (vehicles) => vehicles.filter((vehicle) => vehicle.vehicleType === 'auto')
);

export const getAllSelectedVehiclesByProduct = (productType: ProductType) =>
  createSelector(getAllVehiclesByProduct(productType), (vehicles) => {
    return vehicles?.filter((vehicle) => vehicle.selected);
  });

export const getAllVehicleModelsFromEntityByProductType = (
  productType: ProductType
) =>
  createSelector(
    getAllVehiclesByProduct(productType),
    getNonAddedPrefillVehicles(productType),
    (vehicles, prefillVehicles) =>
      [...vehicles, ...prefillVehicles].map((vehicle) =>
        VehicleFormFromEntityBuilder.generateVehicleFormFromEntity(vehicle)
      )
  );

export const getAllAutoVehicleModels = createSelector(
  getAllVehicleModelsFromEntityByProductType('PersonalAuto'),
  getAllFeatureFlags,
  getAllSelectedDriverMemberModels('PersonalAuto'),
  getQuoteState,
  getProductFurthestQuoteStatus('PersonalAuto'),
  (vehicleModels, featureFlags, drivers, quoteState, quoteStatus) => {
    return vehicleModels.map((vehicle) => {
      return {
        ...vehicle,
        registeredOwners: VehicleUtils.SetRegisteredOwner(
          vehicle,
          drivers,
          featureFlags
        ),
        options: VehicleUtils.formOptionsForVehicles(
          vehicle,
          featureFlags,
          drivers as DriverEntity[],
          quoteState,
          quoteStatus || 'Draft'
        ),
      };
    });
  }
);

export const getAllAutoSelectedVehicleModels = createSelector(
  getAllAutoVehicleModels,
  (vehicleModels) => {
    return vehicleModels.filter((vehicle) => vehicle.selected);
  }
);

export const getAllMsaVehicleModels = createSelector(
  getAllVehicleModelsFromEntityByProductType('MSA'),
  getAllFeatureFlags,
  getAllSelectedMemberModels,
  getQuoteState,
  getProductFurthestQuoteStatus('MSA'),
  (vehicleModels, featureFlags, drivers, quoteState, quoteStatus) => {
    return vehicleModels.map((vehicle) => {
      return {
        ...vehicle,
        registeredOwners: VehicleUtils.SetRegisteredOwner(
          vehicle,
          drivers,
          featureFlags
        ),
        options: VehicleUtils.formOptionsForVehicles(
          vehicle,
          featureFlags,
          drivers as DriverEntity[],
          quoteState,
          quoteStatus || 'Draft'
        ),
      };
    });
  }
);

export const getAllMsaSelectedVehicleModels = createSelector(
  getAllMsaVehicleModels,
  (vehicleModels) => {
    return vehicleModels.filter((vehicle) => vehicle.selected);
  }
);

export const getAllProductTypeSelectedVehicleModels = (
  productType: ProductType
) =>
  createSelector(
    getAllAutoSelectedVehicleModels,
    getAllMsaSelectedVehicleModels,
    getAllRVSelectedVehicleModels,
    (autoVehicleModels, msaVehicleModels, rvVehicleModels) => {
      if (productType === 'PersonalAuto') {
        return autoVehicleModels.filter((v) => v.vehicleType === 'auto');
      }
      if (productType === 'MSA') {
        return msaVehicleModels.filter(
          (v) =>
            v.vehicleType === 'Motorcycle' ||
            v.vehicleType === 'OffRoad' ||
            v.vehicleType === 'Snowmobile'
        );
      }
      if (productType === 'RV') {
        return rvVehicleModels;
      }
      return [];
    }
  );

export const getAllBoatVehicleModels = createSelector(
  getAllVehicleModelsFromEntityByProductType('Boat'),
  getAllFeatureFlags,
  getAllSelectedMemberModels,
  getQuoteState,
  getProductFurthestQuoteStatus('Boat'),
  (vehicleModels, featureFlags, drivers, quoteState, quoteStatus) => {
    return vehicleModels.map((vehicle) => {
      return {
        ...vehicle,
        options: VehicleUtils.formOptionsForVehicles(
          vehicle,
          featureFlags,
          drivers as DriverEntity[],
          quoteState,
          quoteStatus || 'Draft'
        ),
      };
    });
  }
);

export const getAllRVVehicleModels = createSelector(
  getAllVehicleModelsFromEntityByProductType('RV'),
  getAllFeatureFlags,
  getAllSelectedMemberModels,
  getQuoteState,
  getProductFurthestQuoteStatus('RV'),
  (vehicleModels, featureFlags, drivers, quoteState, quoteStatus) => {
    return vehicleModels.map((vehicle) => {
      return {
        ...vehicle,
        registeredOwners: VehicleUtils.SetRegisteredOwner(
          vehicle,
          drivers,
          featureFlags
          ),
        additionalInformation: {
          ...vehicle.additionalInformation,
          registrationState: vehicle.additionalInformation?.registrationState
            ? vehicle.additionalInformation?.registrationState
            : quoteState,
        },
        options: VehicleUtils.formOptionsForVehicles(
          vehicle,
          featureFlags,
          drivers as DriverEntity[],
          quoteState,
          quoteStatus || 'Draft'
        ),
      };
    });
  }
);

export const getAllRVSelectedVehicleModels = createSelector(
  getAllRVVehicleModels,
  (vehicleModels) => {
    return vehicleModels.filter((vehicle) => vehicle.selected);
  }
);

export const getVehiclesPageModel = createSelector(
  getAllAutoVehicleModels,
  getCurrentCarrierFormModel('PersonalAuto'),
  getIsAssignedRiskPlan,
  getProductQuoteStatus('PersonalAuto'),
  (vehicles, currentCarrier, assignedRiskPlan, quoteStatus) => {
    return {
      vehicles,
      currentCarrier,
      assignedRiskPlan,
      allowMaskedVin: quoteStatus === 'Draft',
    } as VehiclesPageModel;
  }
);

export const getAllVehicleHeaderItems = createSelector(
  getAllAutoVehicleModels,
  (vehicleModels) => {
    return vehicleModels.map(
      (vehicle) =>
        ({
          title:
            vehicle.year && vehicle.make
              ? `${vehicle.year} ${vehicle.make}`
              : 'New Vehicle',
          subtitle: vehicle.model ? `${vehicle.model}` : '',
          selected: vehicle.selected,
          entityId: vehicle.vehicleId,
          prefillId: vehicle.prefillId,
          unselectable: false,
          imageSource:
            '../../../../../assets/images/products/personalAuto/car-circle.svg',
        } as HeaderCardItem)
    );
  }
);

export const getMsaPageModel = createSelector(
  getAllMsaVehicleModels,
  getCurrentCarrierFormModel('MSA'),
  (vehicles, currentCarrier) => {
    return {
      vehicles,
      currentCarrier,
    } as MsaPageModel;
  }
);

export const getBoatPageModel = createSelector(
  getAllBoatVehicleModels,
  getCurrentCarrierFormModel('Boat'),
  (vehicles, currentCarrier) => {
    return {
      vehicles,
      currentCarrier,
    } as MsaPageModel;
  }
);

export const getRvPageModel = createSelector(
  getAllRVVehicleModels,
  getCurrentCarrierFormModel('RV'),
  (vehicles, currentCarrier) => {
    return {
      vehicles,
      currentCarrier,
    } as MsaPageModel;
  }
);

export const getAllMsaHeaderItems = createSelector(
  getAllMsaVehicleModels,
  (vehicleModels) => {
    return vehicleModels.map(
      (vehicle) =>
        ({
          title:
            vehicle.year && vehicle.make
              ? `${vehicle.year} ${vehicle.make}`
              : 'New Vehicle',
          subtitle: vehicle.model ? `${vehicle.model}` : null,
          selected: vehicle.selected,
          entityId: vehicle.vehicleId,
          prefillId: vehicle.prefillId,
          saved: VehicleUtils.VehicleIsSaved(vehicle.vehicleId),
          unselectable: false,
          imageSource:
            '../../../../../assets/images/products/msa/motorcycle_circle.svg',
        } as HeaderCardItem)
    );
  }
);

// TODO refactor into one
export const getAllBoatHeaderItems = createSelector(
  getAllBoatVehicleModels,
  (vehicleModels) => {
    return vehicleModels.map(
      (vehicle) =>
        ({
          title:
            vehicle.year && vehicle.make
              ? `${vehicle.year} ${vehicle.make}`
              : 'New Boat',
          subtitle: vehicle.model ? `${vehicle.model}` : null,
          selected: vehicle.selected,
          entityId: vehicle.vehicleId,
          prefillId: vehicle.prefillId,
          saved: VehicleUtils.VehicleIsSaved(vehicle.vehicleId),
          unselectable: false,
          imageSource:
            '../../../../../assets/images/products/boat/boat_circle.svg',
        } as HeaderCardItem)
    );
  }
);

// TODO refactor into one
export const getAllRVHeaderItems = createSelector(
  getAllRVVehicleModels,
  (vehicleModels) => {
    return vehicleModels.map(
      (vehicle) =>
        ({
          title:
            vehicle.year && vehicle.make
              ? `${vehicle.year} ${vehicle.make}`
              : 'New RV',
          subtitle: vehicle.model ? `${vehicle.model}` : null,
          selected: vehicle.selected,
          entityId: vehicle.vehicleId,
          prefillId: vehicle.prefillId,
          saved: VehicleUtils.VehicleIsSaved(vehicle.vehicleId),
          unselectable: false,
          imageSource: '../../../../../assets/images/products/rv/rv_circle.svg',
        } as HeaderCardItem)
    );
  }
);
export const buildTemporaryVehicleEntity = (vehicle: VehicleEntity) =>
  createSelector(
    getAddressForProduct(vehicle.productType as ProductType),
    (address) => ({
      ...vehicle,
      garageLocation: address,
    })
  );

export const getVehicleById = (id: string | undefined) =>
  createSelector(getVehicleEntities, (entities) => {
    return id ? entities[id] || null : null;
  });

export const getVehicleError = createSelector(
  getVehicleState,
  (state) => state.error || null
);

export const getEasyPayDiscountAmount = createSelector(
  getAllVehicles,
  (entities) => {
    const vehicleEntity = entities.find(
      (entity) => entity.sequenceNumber === 1
    );
    return vehicleEntity?.easyPayDiscountAmount?.amount;
  }
);

export const getUniqueAdditionalInterestCompanies = createSelector(
  getAllVehicles,
  (vehicles) => {
    let companies: BasicCompanyInformation[] = [];
    // Get company additional interests, then filter out "falsy" values
    const aiCompanies = vehicles
      .flatMap((v) => {
        return v.additionalInterests?.filter((ai) => ai.companyName);
      })
      .filter(Boolean);
    // Creating map with companyName as key will replace duplicates with last found
    const aiUniqueCompanies = [
      ...new Map(aiCompanies?.map((ai) => [ai?.companyName, ai])).values(),
    ];
    aiUniqueCompanies.forEach((ai) => {
      companies.push({
        company: ai?.companyName!,
        address: { ...ai?.address },
      });
    });
    return companies;
  }
);

export const getUniqueAdditionalInterestPersons = createSelector(
  getAllVehicles,
  (vehicles) => {
    let persons: PersonEntity[] = [];
    // Get person additional interests, then filter out "falsy" values
    const aiPersons = vehicles
      .flatMap((v) => {
        return v.additionalInterests?.filter(
          (ai) => ai.person && ai.person.firstName
        );
      })
      .filter(Boolean);
    // Create map with person's name as key will replace duplicates with last found
    const aiUniquePersons = [
      ...new Map(
        aiPersons?.map((ai) => {
          return [`${ai?.person?.firstName} ${ai?.person?.lastName}`, ai];
        })
      ).values(),
    ];
    aiUniquePersons.forEach((ai) => {
      persons.push(ai?.person!);
    });
    return persons;
  }
);

export const getAdditionalInterestsForVehicle = (id: string | undefined) =>
  createSelector(getVehicleById(id), (v) => v?.additionalInterests);

export const getSelectedMembersAndPersonInterests = createSelector(
  getAllSelectedMemberModels,
  getUniqueAdditionalInterestPersons,
  (members, persons) => {
    let allMembers: MemberModel[] = structuredClone(members);
    persons.forEach((p) => {
      // Ignore persons who are already members
      const isMember: boolean = allMembers.some((m) => {
        return (
          `${m.person?.firstName} ${m.person?.lastName}` ===
          `${p.firstName} ${p.lastName}`
        );
      });
      if (isMember) return;

      // Otherwise, add person to members clone
      allMembers.push({
        driverRoles: [],
        entityId: '',
        policyRoles: [],
        person: p,
      });
    });
    return allMembers;
  }
);
