<ng-container>
  <header>
    <div class="blue-angled-bg">
      <div
        class="app-header bolt-container-fluid wrapper-container page-wrapper-lg"
      >
        <div class="header">
          <div
            class="bolt-row bolt-justify-content-between bolt-align-items-center bolt-space-bottom-md"
          >
            <div class="bolt-col-4 logo-container">
              <h3>
                <a [routerLink]="'/'"
                  >Nationwide&nbsp;<span class="post-header"
                    >Express&reg;</span
                  ></a
                >
              </h3>
              <div
                class="badge-container"
                *ngIf="(maxSizeXSmall$ | async) !== true"
              >
                <img
                  src="/assets/images/icons/nwx-agent-version-2-transparent-badge.svg"
                  alt="Version 2"
                />
              </div>
            </div>
            <div class="bolt-col-auto">
              <ng-container *ngIf="shouldShowProfileComponent$ | async">
                <nwx-profile-menu
                  [userName]="(this.userName$ | async) || ''"
                  [agentCenterUrl]="agentCenterUrl"
                  [producerCode]="(this.producerCode$ | async) || ''"
                  [producerName]="(this.agentName$ | async) || ''"
                  [maxSizeXSmall]="maxSizeXSmall$ | async"
                ></nwx-profile-menu>
              </ng-container>
            </div>
          </div>

          <div class="bolt-row" [ngSwitch]="routingSummary$ | async">
            <ng-container *ngSwitchCase="'nwxSingle'">
              <div class="bolt-col-12">
                <span class="badge nwx-badge">Great!</span>
              </div>
              <div class="bolt-col-12 welcome-message-content">
                <span class="pre-message bolt-heading-md">
                  <strong> This quote is eligible for </strong>
                </span>
                <span class="post-message bolt-heading-md"
                  ><strong>Nationwide Express&reg;</strong></span
                >
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'nwxMulti'">
              <div class="bolt-col-12">
                <span class="badge nwx-badge">Great!</span>
              </div>
              <div class="bolt-col-12 welcome-message-content">
                <span class="pre-message"
                  >This quote package is eligible for
                </span>
                <span class="post-message">Nationwide Express&reg;</span>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'pcSingle'">
              <div class="bolt-col-12">
                <span class="badge nwx-badge">Heads up!</span>
              </div>
              <div class="bolt-col-12 welcome-message-content">
                <span class="pre-message"
                  >This quote should be completed in PolicyCenter</span
                >
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'pcMulti'">
              <div class="bolt-col-12">
                <span class="badge nwx-badge">Heads up!</span>
              </div>
              <div class="bolt-col-12 welcome-message-content">
                <span class="pre-message"
                  >This quote package should be completed in PolicyCenter</span
                >
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </header>
</ng-container>
