import { ErrorModel } from '@core/store/entities/error/error.model';
import { ProductType } from '@core/models/api/dsm-types';
import { PolicyIssueResponse } from './../../../models/api/response/policy-issue-response.model';
import { createAction, props } from '@ngrx/store';

export const enum PolicyIssueTypes {
  ISSUE_POLICY = '[Policy Issue] Issue Policy',
  ISSUE_POLICY_FOR_PRODUCT = '[Policy Issue] Issue Policy For Product',
  ISSUE_POLICY_FAIL = '[Policy Issue] Issue Policy Fail',
  ISSUE_POLICY_SUCCESS = '[Policy Issue] Issue Policy Success',
  MARK_PRODUCT_ISSUING = '[Policy Issue] Mark Product Issuing',
}

export const issuePolicy = createAction(PolicyIssueTypes.ISSUE_POLICY);

export const issuePolicyForProduct = createAction(
  PolicyIssueTypes.ISSUE_POLICY_FOR_PRODUCT,
  props<{ product: ProductType }>()
);

export const issuePolicySuccess = createAction(
  PolicyIssueTypes.ISSUE_POLICY_SUCCESS,
  props<{ response: PolicyIssueResponse }>()
);

export const issuePolicyFail = createAction(
  PolicyIssueTypes.ISSUE_POLICY_FAIL,
  props<{ product: ProductType; error: ErrorModel }>()
);
