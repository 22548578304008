<div class="headline">
  <div class="responsive-nav-header">
    <h1 class="bolt-heading-md bolt-serif">
      {{ content.pniFirstName || 'PolicyHolder' | titlecase }}'s quote
    </h1>
    <nwx-responsive-navigation-container></nwx-responsive-navigation-container>
  </div>
</div>

<div [ngClass]="content.packageDisplayHidden ? 'price-box-no-package' : 'price-box'">
  <div>
    <ng-container *ngFor="let product of content.products">
      <nwx-product-display [product]="product"></nwx-product-display>
    </ng-container>

    <ng-container *ngIf="content.upsellProducts?.length">
      <hr />
      <ng-container *ngFor="let product of content.upsellProducts">
        <nwx-product-display [product]="product" (addUpsell)="addUpsell.emit($event)"></nwx-product-display>
      </ng-container>
    </ng-container>

    <nwx-package-display *ngIf="!content.packageDisplayHidden && !maxSizeMedium" [content]="content" [maxSizeMedium]="!!maxSizeMedium"
      (showPdf)="showPdf.emit()" (openModal)="openModal.emit()"></nwx-package-display>
  </div>
  <div id="summary-add-product" *ngIf="!content.products || content.products.length < 1" class="bolt-space-inner-sm bolt-background-pale-gray-25">
    <bolt-button id="summary-add-product-btn" type="primary" (click)="openProductToggleModal()">Add additional products</bolt-button>
    Additional products will require requoting
  </div>
</div>
<ng-container *ngIf="!content.packageDisplayHidden && maxSizeMedium">
  <nwx-package-display [content]="contentForSmallView()" [maxSizeMedium]="!!maxSizeMedium" (showPdf)="showPdf.emit()"
    (openModal)="openModal.emit()"></nwx-package-display>
</ng-container>
