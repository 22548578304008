import { createSelector } from '@ngrx/store';

import * as fromCore from '@core/store/reducers';
import {
  composeModelsKey,
  composeRatesKey,
  composeSeriesKey,
} from './vehicle-inquiry.reducer';
import * as fromVehicleInquiry from './vehicle-inquiry.reducer';
import { ProductType } from '@core/models/api/dsm-types';
import { InquiryPath } from '@core/adapters/powersports.adapter';

export const getVehicleInquiryState = createSelector(
  fromCore.getCoreState,
  (state) => state.vehicleInquiry
);

export const {
  selectAll: getAllVehicleInquiries,
  selectEntities: getVehicleInquiryEntities,
} = fromVehicleInquiry.adapter.getSelectors(getVehicleInquiryState);

export const getProductVehicleInquiry = (inquiryPath: InquiryPath) =>
  createSelector(
    getVehicleInquiryEntities,
    (entities) => entities[inquiryPath]
  );

export const getVehicleInquiryYears = (inquiryPath: InquiryPath) =>
  createSelector(
    getProductVehicleInquiry(inquiryPath),
    (state) => state?.years
  );

export const getVehicleInquiryYearsLoaded = (inquiryPath: InquiryPath) =>
  createSelector(
    getProductVehicleInquiry(inquiryPath),
    (state) => state?.yearsLoaded
  );

export const getVehicleInquiryMakes = (
  year: number,
  inquiryPath: InquiryPath
) =>
  createSelector(getProductVehicleInquiry(inquiryPath), (state) => {
    return state?.makes?.[year];
  });

export const getVehicleInquiryModels = (
  year: number,
  make: string,
  inquiryPath: InquiryPath
) =>
  createSelector(
    getProductVehicleInquiry(inquiryPath),
    (state) => state?.models?.[composeModelsKey(year, make)]
  );

export const getVehicleInquirySeries = (
  year: number,
  make: string,
  model: string,
  inquiryPath: InquiryPath
) =>
  createSelector(
    getProductVehicleInquiry(inquiryPath),
    (state) => state?.serieses?.[composeSeriesKey(year, make, model)]
  );

  export const getSeries = (year: number, make: string, model: string, inquiryPath: InquiryPath) =>
  createSelector(
    getProductVehicleInquiry(inquiryPath),
    (state) => state?.serieses?.[composeSeriesKey(year, make, model)]
  );

export const getVin = (vin: string, inquiryPath: InquiryPath) =>
  createSelector(
    getProductVehicleInquiry(inquiryPath),
    (state) => state?.vins?.[vin]
  );

export const getRates = (
  year: number,
  make: string,
  model: string,
  inquiryPath: InquiryPath
) =>
  createSelector(
    getProductVehicleInquiry(inquiryPath),

    (state) => state?.rates?.[composeRatesKey(year, make, model)]
  );
