import { getCoreState } from "@core/store/reducers";
import { createSelector } from "@ngrx/store";
import { PrequalificationVerbiageModel } from "./prequalification-verbiage.reducer";

export const selectPrequalificationVerbiage = (questionCode: string) => createSelector(
  getCoreState,
  (core) => core.prequalificationVerbiage.entities[questionCode]?.questionText || questionCode
);

export const selectAllPrequalificationVerbiage = createSelector(
  getCoreState,
  (core) => Object.values(core.prequalificationVerbiage.entities) as PrequalificationVerbiageModel[]
);
