<form class="price-section">
  <div class="progress-box">
    <div class="bolt-row">
      <div class="bolt-col-auto progress-header-box">
        <h3 class="align-middle progress-bar-header">Finalize</h3>
      </div>
      <div class="bolt-col">
        <div class="bar-container">

          <div class="bar-stage" [ngClass]="{ complete: isBuildQuote }">
            <div class="graphics">
              <div class="check yes" *ngIf="isBuildQuote">
                <bolt-icon
                  name="checkmark"
                  color="white"
                  class="mod-icon"
                  size="sm"
                ></bolt-icon>
              </div>
              <div class="check no" *ngIf="!isBuildQuote"></div>
            </div>
            <div class="label-wrapper">
              <div class="stage-label left">Build quote</div>
            </div>
          </div>

          <div class="bar">
            <div
              [ngClass]="{
                fill: true,
                half: isBuildQuote && !isInitialRate,
                full: isInitialRate
              }"
            ></div>
          </div>

          <div class="bar-stage" [ngClass]="{ complete: isInitialRate }">
            <div class="graphics">
              <div class="check yes" *ngIf="isInitialRate">
                <bolt-icon
                  name="checkmark"
                  color="white"
                  class="mod-icon"
                  size="sm"
                ></bolt-icon>
              </div>
              <div class="check no" *ngIf="!isInitialRate"></div>
            </div>
            <div class="label-wrapper">
              <div class="stage-label center">Initial rate</div>
            </div>
          </div>

          <div class="bar">
            <div
              [ngClass]="{
                fill: true,
                half: isInitialRate && !isFinalRate,
                full: isFinalRate
              }"
            ></div>
          </div>

          <div class="bar-stage" [ngClass]="{ complete: isFinalRate }">
            <div class="graphics">
              <div class="check yes" *ngIf="isFinalRate">
                <bolt-icon
                  name="checkmark"
                  color="white"
                  class="mod-icon"
                  size="sm"
                ></bolt-icon>
              </div>
              <div class="check no" *ngIf="!isFinalRate"></div>
            </div>
            <div class="label-wrapper">
              <div class="stage-label right">Final rate</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</form>
