import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StringUtils } from '@shared/utils/string.utils';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { DigitalIdCardsTextRequest } from '../models/api/request/digital-id-cards-text-request.model';
import { DigitalIdCardsTextResponse } from '../models/api/response/digital-id-cards-text-response.model';
import { AppConfigService } from '../services/app-config.service';
import { LogService } from '../services/log.service';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root',
})
export class DigitalIdCardsTextAdapter {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private log: LogService,

    private sessionService: SessionService
  ) {}

  sendText(
    request: DigitalIdCardsTextRequest
  ): Observable<DigitalIdCardsTextResponse> {
    return this.sessionService.getAccessToken().pipe(
      take(1),
      mergeMap((accessToken) => {
        const url = `${this.appConfigService.config.multiproductApi}/digital-id-cards`;
        const headers = new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('client_id', this.appConfigService.config.apiKey)
          .set('Authorization', 'Bearer ' + accessToken)
          .set('X-NW-Message-ID', StringUtils.generateUuid());

        const requestBody = { ...request, targetEnv: '' };
        if (!this.appConfigService.isProd()) {
          requestBody.targetEnv = this.appConfigService.config.targetEnv || '';
        }

        const correlationId = this.log.logApiRequest(
          'POST',
          url,
          'send-digital-id-cards-text',
          requestBody
        );

        return this.httpClient
          .post<DigitalIdCardsTextResponse>(url, requestBody, {
            headers,
          })
          .pipe(
            tap((response) =>
              this.log.logApiResponse(correlationId, 200, response)
            ),
            map((response) => response || {}),
            catchError((error) => this.log.logApiError(correlationId, error))
          );
      })
    );
  }
}
