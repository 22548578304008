import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonFormModule } from '@shared/modules/common-form.module';
import { AcountInfoModule } from '../account-info/account-info.module';
import { AccountInfoFormComponent } from './account-info-form.component';

@NgModule({
  declarations: [AccountInfoFormComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, CommonFormModule],
  exports: [AccountInfoFormComponent],
})
export class AcountInfoFormModule {}
