import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlContainer, ValidationErrors } from '@angular/forms';
import { ProductType } from '@core/models/api/dsm-types';
import { DateUtils } from '@shared/utils/date.utils';

@Component({
  selector: 'nwx-datepicker-input',
  templateUrl: './datepicker-input.component.html',
  styleUrls: ['./datepicker-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class DatepickerInputComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @Input() controlName!: string;
  @Input() controlId!: string;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() hasError!: boolean | null;
  @Input() invalidFeedbackText!: string;
  @Input() displayMonths: number = 2;
  // 'select' (select boxes for month and navigation arrows) | 'arrows' (only navigation arrows) | 'none' (no navigation visible at all)
  @Input() navigation: string = 'select';
  @Input() showWeekNumbers: boolean = false;
  @Input() outsideDays: string = 'visible';
  @Input() minDate: string | null = null;
  @Input() maxDate: string | null = null;
  @Input() startDate!: string | null;
  @Input() showAdvancedQuoteDiscount!: boolean;
  @Input() disabled = false;
  @Input() relevantProduct!: ProductType | null;
  @Input() bottomSpacingNeeded = true;
  @ViewChild('inputField', { read: ElementRef }) inputField?: ElementRef;

  blankNgbDate = {
    day: 0,
    month: 0,
    year: 0,
  };

  private mutationObserver: MutationObserver;

  constructor(private changeDetector: ChangeDetectorRef) {
    this.mutationObserver = new MutationObserver((events) => {
      for (const event of events) {
        if (
          event.type === 'attributes' &&
          event.attributeName === 'error' &&
          event.target === this.inputField?.nativeElement
        ) {
          const value = this.inputField.nativeElement.getAttribute('error');
          if (value) {
            this.hasError = true;
            this.invalidFeedbackText = value;
          } else {
            this.hasError = false;
            this.invalidFeedbackText = '';
          }
          this.changeDetector.markForCheck();
        }
      }
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.inputField) {
      this.mutationObserver.observe(this.inputField.nativeElement, {
        attributeFilter: ['error'],
        attributes: true,
      });
    }
  }

  static validate(
    control: AbstractControl, min: string, max: string
  ): ValidationErrors | null {
    const rawInput = control.value || '';
    if (!rawInput.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
      return { required: true };
    }
    const value = DateUtils.formatDateToDSM(rawInput);
    if (value < min) {
      return {
        requiredBefore: DateUtils.formatDsmDateToOld(min),
      };
    }
    if (value > max) {
      return {
        requiredAfter: DateUtils.formatDsmDateToOld(max),
      };
    }
    return null;
  }

  ngOnDestroy(): void {
    this.mutationObserver.disconnect();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  onIconClick(event: Event, datepicker: any): void {
    event.preventDefault();
    if (!this.disabled) {
      datepicker.toggle();
    }
  }
}
