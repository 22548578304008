import { Injectable } from '@angular/core';
import { Nullable } from '@shared/utils/type.utils';
import { ValidatorFn, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormHelper {
  static enableOrDisable<T>(
    name: keyof T,
    v: boolean | undefined,
    validators: ValidatorFn[],
    form: FormGroup,
    updateOn?: 'changes' | 'blur' | 'submit'
  ): void {
    const control = form?.get(`${name as string}`);
    if (control) {
      if (this.defaultTrue(v)) {
        control.setValidators(validators);
        if (!control.enabled) {
          control.enable();
        }
      } else {
        if (control.enabled) {
          control.disable();
          control.clearValidators();
          control.updateValueAndValidity();
        }
      }
    }
  }

  private static defaultTrue(v: boolean | undefined): boolean {
    if (typeof v !== 'boolean') {
      return true;
    }
    return v;
  }

  static getFormRoot(formGroup: FormGroup): FormGroup {
    if (!formGroup.parent) {
      return formGroup;
    } else {
      return this.getFormRoot(formGroup.parent as FormGroup);
    }
  }
}
