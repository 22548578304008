<div
  class="bolt-container-fluid help-padding"
  [formGroup]="form"
  [nwxExposeFormErrors]="form"
  formName="member"
  *ngIf="vm$ | async as vm"
>
  <ng-container *ngIf="vm.isPni && pniTasks?.length; else noPniQuestions">
    <div class="bolt-row">
      <div class="bolt-col">
        <nwx-tasks-policyholder-form
          [pniTasks]="pniTasks"
          [policyholder]="policyholder"
          [products]="products"
          (valueChange)="onValueChange($event)"
          (formReady)="addChildForm('pni', $event)"
        >
        </nwx-tasks-policyholder-form>
      </div>
      <div class="bolt-col">
        <div class="bolt-row" *ngIf="vm.showMaritalStatus">
          <div class="bolt-col" formGroupName="person">
            <bolt-select
              ngDefaultControl
              formControlName="maritalStatus"
              label="Marital status"
              name="maritalStatus"
              attr.id="marital-status-{{ index }}"
            >
              <option value="S">Single</option>
              <option value="M">Married</option>
              <option value="P">Separated</option>
              <option value="D">Divorced</option>
              <option value="W">Widowed</option>
            </bolt-select>
          </div>
        </div>
        <ng-container *ngIf="vm.showLicenseNumber">
          <div class="bolt-row">
            <div class="bolt-col">
              <bolt-select
                ngDefaultControl
                formControlName="licenseState"
                label="Licensed in state"
                name="licenseState"
                attr.id="license-state-{{ index }}"
                class="no-help-padding-r"
              >
                <option *ngFor="let state of vm.states" value="{{ state }}">
                  {{ state }}
                </option>
              </bolt-select>
            </div>
          </div>
          <div class="bolt-row">
            <div class="bolt-col">
              <bolt-textfield
                ngDefaultControl
                formControlName="licenseNumber"
                label="Driver's license number"
                name="licenseNumber"
                attr.id="license-number-{{ index }}"
                instructionaltext="Required for bindable rate."
                class="nwpii no-help-padding-r"
              ></bolt-textfield>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-template #noPniQuestions>
    <div class="bolt-row">
      <div class="bolt-col" *ngIf="vm.showLicenseNumber">
        <bolt-select
          ngDefaultControl
          formControlName="licenseState"
          label="Licensed in state"
          name="licenseState"
          attr.id="license-state-{{ index }}"
          class="no-help-padding-r"
        >
          <option *ngFor="let state of vm.states" value="{{ state }}">
            {{ state }}
          </option>
        </bolt-select>
      </div>
      <div class="bolt-col" *ngIf="vm.showLicenseNumber">
        <bolt-textfield
          ngDefaultControl
          formControlName="licenseNumber"
          label="Driver's license number"
          name="licenseNumber"
          attr.id="license-number-{{ index }}"
          instructionaltext="Required for bindable rate."
          class="nwpii no-help-padding-r"
        ></bolt-textfield>
      </div>
      <div class="bolt-col" *ngIf="vm.showMaritalStatus" formGroupName="person">
        <bolt-select
          ngDefaultControl
          formControlName="maritalStatus"
          label="Marital status"
          name="maritalStatus"
          attr.id="marital-status-{{ index }}"
        >
          <option value="S">Single</option>
          <option value="M">Married</option>
          <option value="P">Separated</option>
          <option value="D">Divorced</option>
          <option value="W">Widowed</option>
        </bolt-select>
      </div>
      <div class="bolt-col" *ngIf="vm.showRelationToPrimaryNamedInsured">
        <bolt-select
          ngDefaultControl
          formControlName="relationToPrimaryNamedInsured"
          label="Relationship to primary insured"
          name="relationToPrimaryNamedInsured"
          attr.id="relation-to-pni-{{ index }}"
          class="no-help-padding-r"
        >
          <option
            *ngFor="let option of vm.relationToPrimaryNamedInsuredOptions"
            value="{{ option.value }}"
          >
            {{ option.display }}
          </option>
        </bolt-select>
      </div>
    </div>
  </ng-template>
</div>
