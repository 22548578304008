import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { DocumentsEntity } from '@core/store/entities/document/document.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';

export const getAllDocuments = createAction('[Document] Get All Documents');

export const addDocument = createAction(
  '[Document] Add Document',
  props<{ payload: DocumentsEntity; syncId?: string }>()
);

export const addDocumentError = createAction(
  '[Document] Add Document Error',
  props<{ error: ErrorModel; syncId?: string }>()
);

export const addDocumentSuccess = createAction(
  '[Document] Add Document Success',
  props<{ payload: DocumentsEntity }>()
);

export const updateDocument = createAction(
  '[Document] Update Document',
  props<{ payload: Update<DocumentsEntity> }>()
);

export const updateDocumentError = createAction(
  '[Document] Update Document Error',
  props<{ error: ErrorModel; DocumentId: number }>()
);

export const updateDocumentSuccess = createAction(
  '[Document] Update Document Success',
  props<{ payload: Update<DocumentsEntity> }>()
);

export const deleteDocument = createAction(
  '[Document] Delete Document',
  props<{ payload: number }>()
);

export const deleteDocumentError = createAction(
  '[Document] Delete Document Error',
  props<{ error: ErrorModel; DocumentId: number }>()
);

export const deleteDocumentSuccess = createAction(
  '[Document] Delete Document Success',
  props<{ payload: number }>()
);
