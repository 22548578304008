/***
 * Put all DSM stringey types in this file please
 */

import { ObjectValidator } from '@core/helper/object-validator';
import { OtherStructuresModel } from '@entities/covered-location/covered-location.model';
import { ProductHeaderModel } from '@shared/components/product-header/product-header.model';

/**
 * Nice for when you have some union type that also has some ordinal relationship e.g. QuoteStatus
 */
export type OrdinalityDefinition<T extends string> = {
  [k in T]: number;
};

/**
 * For when you have some domain value that needs to be mapped on to the screen
 */
export type DisplayValueMap<T extends string> = {
  [k in T]: string;
};

export const TermTypeDisplayValueMap: DisplayValueMap<TermType> = {
  Annual: 'Annual',
  HalfYear: 'Half Year',
};

export function displayValue<T extends string>(
  t: T,
  map: DisplayValueMap<T>
): string {
  return map[t];
}

export function compareOrdinals<T extends string>(
  first: T | null | undefined,
  second: T | null | undefined,
  definition: OrdinalityDefinition<T>
): number {
  if (!first && !second) {
    return 0;
  } else if (!first && second) {
    return -1;
  } else if (first && !second) {
    return 1;
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
  } else if (definition[first!] > definition[second!]) {
    return 1;
  } else if (definition[first!] < definition[second!]) {
    return -1;
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  } else {
    return 0;
  }
}

export function quoteStatusGreaterThan(
  first: QuoteStatus,
  second: QuoteStatus
): boolean {
  return compareOrdinals(first, second, QuoteStatusOrdinality) === 1;
}

export type WindMitigationType =
  | 'RoofCoveringUnknown'
  | 'Other'
  | 'RoofDeckAttachmentUnknown'
  | 'RooftoWallConnectionUnknown'
  | 'None'
  | 'MeetsFloridabuildingcode'
  | 'Other'
  | 'LevelC'
  | 'Clips'
  | 'None';

export type TelematicsEnrollmentStatus = 'Enrolled' | 'NotEnrolled';
export type TelematicsDeviceStatus = 'Shipped' | 'NotShipped';
export type TelematicsVehicleProgramType =
  | 'SmartMiles'
  | 'SmartRide'
  | 'SelfReported'
  | 'ConnectedCar'
  | 'SmartRideInstant';

export type TelematicsMobileEnrollmentType =
  | 'MobileApp'
  | 'MobileAppEarlyDiscount';

export type ProductType =
  | 'PersonalAuto'
  | 'Homeowner'
  | 'Tenant'
  | 'Condominium'
  | 'PersonalUmbrella'
  | 'TermLife'
  | 'MSA'
  | 'Boat'
  | 'RV'
  | 'DwellingFire'
  | 'Life'
  | 'Pet';

export type RequestType =
  | ProductType
  | 'AgencyCodeSearch'
  | 'ProducerCodeSearch'
  | 'Account'
  | 'MortgageCompanies';

export type TaskProductType = ProductType | 'All';

export type AddressType =
  | ProductType
  | 'Default'
  | 'Account'
  | 'Mailing'
  | 'Billing'
  | 'AdditionalInterest'
  | 'Garaging'
  | 'Lending';

export type ProductDisplayType = 'selected' | 'addOn';
export type TermType = 'Annual' | 'HalfYear';

export type RenovationType =
  | 'Roof'
  | 'Cooling'
  | 'Electrical'
  | 'Heating'
  | 'Plumbing';

export interface TermTypeEvent {
  length: TermType;
  lineOfBusiness: ProductType;
}

export type LicenseStatus =
  | 'Cancelled'
  | 'Revoked'
  | 'Suspended'
  | 'Other'
  | 'UnverifiableNoHit'
  | 'Active';

export type PolicyHolderType =
  | 'PolicyPriNamedInsured'
  | 'PolicySecNamedInsured'
  | 'PolicyAddlNamedInsured';

export type RelationToPNIType =
  | 'PrimaryNamedInsured'
  | 'Spouse'
  | 'Other'
  | 'Child'
  | 'CoOwner'
  | 'Employee'
  | 'OtherRelative'
  | 'OtherNonRelative';

export type CCYYMMDDDate = string;

// TODO - Error is not a status
export type QuoteStatus =
  | 'NotStarted'
  | 'Error'
  | 'Draft'
  | 'Pending'
  | 'Quoted'
  | 'Binding'
  | 'Issued'
  | 'Withdrawn';

export type RatingType = 'quote' | 'bind' | 'issue';

export type Role = 'DIRECT' | 'EA' | 'IA' | 'NSS';

export const QuoteStatusOrdinality: OrdinalityDefinition<QuoteStatus> = {
  NotStarted: -100,
  Withdrawn: -2,
  Error: -1,
  Draft: 0,
  Pending: 1,
  Quoted: 2,
  Binding: 3,
  Issued: 4,
};

export type AdditionalInterestType =
  | 'LIEN'
  | 'LESSOR'
  | 'AdditionalInsured_Ext'
  | 'AdditionalInterest_Ext';

export type OfferingType = 'HO3' | 'HE7' | 'HE7_20' | 'HE7_21';

export interface OfferingTypeEvent {
  offeringType: OfferingType;
  lineOfBusiness: ProductType;
}

export interface EffectiveDateEvent {
  productHeader: Partial<ProductHeaderModel>;
  lineOfBusiness: ProductType;
}

export enum CurrentCarrierCoverageLapse {
  ZeroDays = '0Days',
  ThirtyDays = '30Days',
  ZeroDaysPrior6Months = '0DaysLapseInPrior6Months',
  OverThirtyDays = 'GT30Days',
  NoNeedForPriorInsurance = 'NoNeedForPriorInsurance',
  NoPriorInsurance = 'NoPriorInsurance',
}

export enum MsaCurrentCarrierNames {
  AllOther = 'AllOtherSelectRisk',
  Nationwide = 'Nationwide',
  NoPrevInsurance = 'NoPrevInsCarrwReason',
}

export enum CurrentCarrierLapseReasons {
  OverseasForMilitary = 'OverseasForMilitary',
  VehicleNewlyPurchased = 'VehicleNewlyPurchased',
  VehicleNotDriven = 'VehicleNotDriven',
  VehicleWithoutInsurance = 'VehicleWithoutInsurance',
}

export type VehicleType =
  | 'auto'
  | 'boat'
  | 'motor'
  | 'BoatTrailer'
  | 'Motorcycle'
  | 'Motorhome'
  | 'OffRoad'
  | 'Snowmobile'
  | 'TravelTrailer'
  | 'UtilityTrailer'
  | 'Trailer_Ext';

export const searchResultProducts = {
  PersonalAuto: 'PersonalAuto',
  Homeowner: 'Homeowner',
  Tenant: 'Tenant',
  Condominium: 'Condominium',
  MCOffRoad_Ext: 'MSA',
  PleasureBoatowners_Ext: 'Boat',
  RVTrailer_Ext: 'RV',
  PersonalUmbrella: 'PersonalUmbrella',
  DwellingFire: 'DwellingFire',
};

export type SearchProductTypes =
  | 'PersonalAuto'
  | 'Homeowner'
  | 'Tenant'
  | 'Condominium'
  | 'MCOffRoad_Ext'
  | 'PleasureBoatowners_Ext'
  | 'RVTrailer_Ext'
  | 'DwellingFire';

export type NumberOfCoOwnersType = 'exactlyone' | 'twomore';
export interface ProtectiveDevicesType {
  sprinklerSystem?: string;
  burglarAlarm?: string;
  fireOrSmokeAlarm?: string;
  hasWroughtIronBar?: boolean;
  windProtectiveDevices?: string[];
  windMitigations?: { category: string; selectedOption: string }[];
  hasSecondaryWaterResistance?: boolean;
  fireProtectiveDevice?: string;
}

export type SmartDeviceSelection = 'Participating' | 'Declined';

export type OtherStructureType =
  | 'breezeway'
  | 'openPorch'
  | 'screenPorch'
  | 'sunSolarRoom'
  | 'woodDeck';

export const OtherStructureToDisplay: { [key: string]: string } = {
  breezeway: 'Breeze way',
  openPorch: 'Open porch',
  screenPorch: 'Screen porch',
  sunSolarRoom: 'Sun/solar room',
  woodDeck: 'Wood deck',
};

export type FoundationType =
  | 'basement'
  | 'crawlSpace'
  | 'daylightWalkoutBasement'
  | 'piersRaised'
  | 'slab'
  | 'suspendedOverHillside';
export const FoundationToDisplay: { [key: string]: string } = {
  basement: 'Basement',
  crawlSpace: 'Crawl space',
  daylightWalkoutBasement: 'Basement walkout',
  piersRaised: 'Piers raised',
  slab: 'Slab',
  suspendedOverHillside: 'Suspended over hillside',
};

export type DocumentDeliveryType = 'USMail' | 'OnlineAccountAccess';

export type DriverType = 'Driver' | 'Excluded' | 'NonDriver';

export type NonDriverReason =
  | 'Military'
  | 'NeverBeenLicensed'
  | 'NotLicensedDueToMedicalReasons'
  | 'Other'
  | 'OtherInsurance'
  | 'SurrendrLcnsDueToMed'
  | 'VoluntarySurrender';
export type RegistrationLinkCommunicationMethod =
  | 'MobilePhone'
  | 'ElectronicAddress';
export interface NationwideAccountRegistrationInfo {
  doesCustomerWantOnlineAccount: boolean;
  registrationLinkCommunicationMethod: RegistrationLinkCommunicationMethod;
  primaryNamedInsuredMobileNumber?: string;
  hasConsentedToOneTimeRegistrationText?: boolean;

  primaryNamedInsuredEmail?: string; // NOT IN THE DSM MODEL
}

export function sanitizeNationwideAccountRegistrationInfo(
  input: unknown
): NationwideAccountRegistrationInfo {
  return ObjectValidator.forceSchema<NationwideAccountRegistrationInfo>(
    input,
    {
      doesCustomerWantOnlineAccount: 'boolean',
      registrationLinkCommunicationMethod: 'string',
      primaryNamedInsuredMobileNumber: 'string',
      hasConsentedToOneTimeRegistrationText: 'boolean',
      primaryNamedInsuredEmail: 'string',
    },
    [
      'doesCustomerWantOnlineAccount',
      'registrationLinkCommunicationMethod',
      'primaryNamedInsuredMobileNumber',
      'hasConsentedToOneTimeRegistrationText',
      'primaryNamedInsuredEmail',
    ]
  );
}

export type MortgageThirdPartyType =
  | 'Mortgagee1'
  | 'Mortgagee'
  | 'LossPayee1'
  | 'LossPayee';

export type AddlInterestThirdPartyType =
  | 'AdditionalInsured_Ext'
  | 'AdditionalInterest_Ext'
  | 'CONSALE'
  | 'CertHolder_Ext'
  | 'CertificateOfIns_Ext'
  | 'LENDLOSS'
  | 'LESSOR'
  | 'LIEN'
  | 'LOSSP'
  | 'LOSSPAY'
  | 'NoticeDesignee_Ext'
  | 'Mortgagee_Ext'
  | 'ServiceCompany_Ext'
  | 'ThirdParty_Ext';

export type AdditionalInsuredType =
  | 'Trust'
  | 'Individual'
  | 'LLCorCompanynotsolelyownedbythePrimaryInsured'
  | 'LLCorCompanysolelyownedbythePrimaryInsured'
  | 'TrustnotinnameofPrimaryInsured'
  | 'TrustinNameofPrimaryInsured'
  | 'Campground'
  | 'HomeOwnersAssociation'
  | 'Marina'
  | 'StorageFacility'
  | 'Other';
