import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators,
} from '@angular/forms';
import { ProducerResponse, ProducerSearch } from '@core/adapters/producer-search.model';
import { AgencyService } from '@core/services/agency.service';
import { QuoteService } from '@core/services/quote.service';
import { DummyProducerProduct } from '@core/store/retrieve/retrieve.selector';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { AdvancedPicklistItem } from '@core/models/advanced-picklist.model';
import { ProductType } from '@core/models/api/dsm-types';
import { ProductUtils } from '@shared/utils/product.util';
import { Nullable } from '@shared/utils/type.utils';
import { TaskService } from '@core/services/task.service';
import { DsmActions } from '@core/store/actions';
import { Store } from '@ngrx/store';
import { AgencyModel } from '@entities/agency/agency.model';
import { ProductsService } from '@core/services/products.service';

@Component({
  templateUrl: './dummy-producer-form.component.html',
  selector: 'nwx-dummy-producer-form',
  styleUrls: ['./dummy-producer-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DummyProducerFormComponent implements OnInit, OnDestroy {

  @Input()
  product!: DummyProducerProduct;
  @Input()
  modalClosed!: Subject<boolean>;
  @Input()
  form!: FormGroup;
  @Input()
  agency!: Nullable<AgencyModel>;
  @Input()
  submitted!: Nullable<Subject<boolean>>;
  @Input()
  state!: string;
  @Input()
  prefill!: Subject<AdvancedPicklistItem>;
  @Input()
  disabled!: boolean;
  @Output()
  selected = new Subject<AdvancedPicklistItem>();

  producerCodeOptionItems = [] as AdvancedPicklistItem[];
  selectedProducer = {} as AdvancedPicklistItem;
  callInFlight: Nullable<Subscription>;
  invalid = false;
  unsubscribe = new Subject<boolean>();
  loading = false;
  submitting = false;
  error: string = '';
  uniqueStates: string[] = [];

  constructor(
    private agencyService: AgencyService,
    private productsService: ProductsService,
    private quoteService: QuoteService,
    public activeModal: NgbActiveModal,
    public taskService: TaskService,
    private changeDetector: ChangeDetectorRef,
    private store: Store,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    const type = this.product.productType;
    this.loading=true;
    this.form.addControl(type, this.fb.control(null,[Validators.required]));
    this.changeDetector.markForCheck();
    this.callInFlight = this.agencyService
    .getProducerCodes(this.state)
    .pipe(
      take(1),
      tap((search: ProducerSearch) => {
        const validProducerOptions = search.producers
          .filter((producer) => this.isValidCodeForAgent(producer))
          .map((producer) => {
            return {
              label: producer.agentName
              ? `${producer.producerCode} | ${producer.agentName}`
              : producer.producerCode,
              value: producer.producerCode,
            } as AdvancedPicklistItem
          });
        this.producerCodeOptionItems=validProducerOptions;
        this.loading=false;
        this.changeDetector.markForCheck();
        this.callInFlight = null;
      }),
    )
    .subscribe();

    this.modalClosed.pipe(
      filter((value) => value == true),
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: () => {
        if(this.callInFlight){
          this.callInFlight?.unsubscribe();
          this.store.dispatch(DsmActions.removeCallInFlight({name: "search-producer-codes"}))
        }
      }
    });

    this.prefill.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(this.prefillValue.bind(this));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  get selection(): AbstractControl<string> | null {
    return this.form.get('selection');
  }

  private isValidCodeForAgent(producer: ProducerResponse): boolean {
    if(!producer || !producer.producerCode) return false;
    return (
      producer.agencyCode == this.agency?.agent?.agencyCode &&
      !producer.producerCode.endsWith('000')
    );
  }

  isProductSelected(product: ProductType): boolean {
    return this.form.get(product as string)?.value !== null || false;
  }

  onProducerCodeSelected(data: Nullable<AdvancedPicklistItem[]>, product: ProductType, prefill:boolean = false): void {
    if (this.disabled || !data || data.length == 0 || !data[0].value || !this.product) {
      return;
    }
    const selection = data[0];
    const control = this.form.controls[product as string];
    control?.setValue(selection);
    control?.markAsTouched();
    control?.markAsDirty();
    const agentName = selection.label.includes('|')
      ? selection.label.slice(selection.label.indexOf('|') + 1)
      : "";
    this.changeDetector.detectChanges();

    if(!prefill) {
      this.selected.next(selection);
    }

    this.submitted?.next(true);
    this.submitting = true;
    this.quoteService
      .updateQuote({
        productType: product,
        body: {
          productType: product,
          producer: {
            agencyCode: this.agency?.agent?.agencyCode || "",
            producerCode: selection.value,
          },
        },
        quoteId: this.product.quoteId,
      })
      .subscribe({
        next: (response) => {
          this.productsService.setProducer(product,{
            ...response.producer,
            agentName: agentName
          });
          this.agencyService.updateAgency({
            producerCode: response.producer.producerCode,
            agent: {
              agentName: response.producer.agentName,
              agencyCode: response.producer.agencyCode
            }
          });
          this.taskService.completeTasksByProductTypeAndField(
            product, 'producerCode'
          )
          this.submitting = false;
          this.changeDetector.markForCheck();
        },
      });
  }

  agencyCodeFromProducerCode(prodCode: string) {
    return prodCode.split(/\s*-\s*/)[0] || '';
  }

  imageUrl(productType: ProductType): string {
    return ProductUtils.getImageUrlForProduct(productType);
  }

  prefillValue(value: AdvancedPicklistItem) {
    const control = this.form.get(this.product.productType as string);
    if(control?.value === null && !this.disabled) {
      this.submitting = true;
      this.onProducerCodeSelected([value],this.product.productType,true);
    }
  }

  get producerCodeOptions() {
    if(this.disabled){
      return [];
    }
    return this.producerCodeOptionItems || [];
  }
}
