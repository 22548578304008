import { PageIdentifier } from '@core/constants/pages';
import {
  ErrorModel,
  ValidationErrorModel,
} from '@core/store/entities/error/error.model';
import { createAction, props } from '@ngrx/store';

export const addError = createAction(
  '[Error] Add',
  props<{ payload: ErrorModel }>()
);

export const setSideNavErrorIcon = createAction(
  '[Error] Set SideNav Validation Error Icon',
  props<{
    payload: ValidationErrorModel;
  }>()
);

export const clearErrorsByType = createAction(
  '[Error] Clear Errors by Type',
  props<{ payload: ErrorModel }>()
);

export const clearErrors = createAction('[Error] Clear');

// TODO - currently doing nothing.
export const clearPageErrors = createAction(
  '[Error] Clear Errors on Page',
  props<{ page: PageIdentifier }>()
);

export const removeErrorsById = createAction(
  '[Error] Remove By Id',
  props<{ ids: number[] }>()
);

export const removeError = createAction(
  '[Error] Remove',
  props<{ id: number }>()
);

// export const showPageError = createAction(
//   '[Error] Form Submission Fail',
//   props<{ payload: ErrorModel }>()
// );

export const clearValidationErrorsForForm = createAction(
  '[Error] Clear Validation Errors For Page And Form',
  props<{ formName: string; }>()
);
