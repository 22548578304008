<form [formGroup]="form" [nwxExposeFormErrors]="form" formName="vin">
  <ng-container *ngIf="!vehicle.prefillId; else immutableVIN">
    <bolt-textfield
      class="nwpii"
      label="VIN"
      formControlName="vin"
      name="vin"
      [id]="'vin-' + vehicleIndex"
      instructionaltext="VIN required for bindable rate."
      [iconname]="(disambiguationRequired | async) ? 'menu' : ''"
      (bolt-icon-click)="disambiguateVin()"
      [attr.required]="required || null"
    ></bolt-textfield>
  </ng-container>

  <ng-template #immutableVIN>
    <div class="readonly-label">
      <div class="vin-label">VIN</div>
      <div class="readonly">
        {{ vehicle.vin }}
      </div>
    </div>
  </ng-template>

  <bolt-modal
    #disambiguationModal
    type="question"
    heading="Pick a VIN"
    (bolt-modal-primary-button-click)="submitVinDisambiguation()"
  >
    <form [formGroup]="disambiguationForm">
      <bolt-select
        label=""
        formControlName="selection"
        name="selection"
        [id]="'disambiguation-selection-' + vehicleIndex"
        optionaltext="hide"
      >
        <option
          *ngFor="let option of disambiguationOptions"
          [value]="option.value"
        >
          {{ option.value }}
        </option>
      </bolt-select>
    </form>
  </bolt-modal>
</form>
