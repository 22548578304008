import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import {
  VehicleExcludedExposureAndProductInfo,
  VehicleExcludedExposureEntity,
} from './vehicle-excluded-exposures.entity';
import { ProductsSelectors } from '@core/store/selectors';
import * as fromVehicleExcludedExposures from '@core/store/entities/exposures/vehicle-excluded-exposures/vehicle-excluded-exposures.reducer';
import { getAllFeatureFlags } from '@entities/feature-flag/feature-flag.selector';
import { VehicleExcludedExposureRequest } from '@core/models/api/request/vehicle-exposure-request.model';
import { getUnderlyingPoliciesAndProductInfo } from '@entities/underlying-policy/underlying-policy.selector';
import { UmbrellaUtils } from '@shared/utils/umbrella.utils';
import { UmbrellaTileModel } from '@shared/components/umbrella-tile/umbrella-tile.component';
import { VehicleExposureEntity } from '../vehicle-exposures/vehicle-exposures.entity';

export const getVehicleExcludedExposureState = createSelector(
  fromCore.getCoreState,
  (state) => state.vehicleExcludedExposures
);

export const {
  selectAll: getAllVehicleExcludedExposures,
  selectEntities: getVehicleExcludedExposures,
} = fromVehicleExcludedExposures.adapter.getSelectors(
  getVehicleExcludedExposureState
);

export const buildVehicleExcludedExposureRequest = (
  vehicleExcludedExposure: VehicleExcludedExposureEntity,
  correlationId?: string
) =>
  createSelector(
    ProductsSelectors.getProduct('PersonalUmbrella'),
    getAllFeatureFlags,
    (product, featureFlags): VehicleExcludedExposureRequest => {
      return {
        vehicleExcludedExposureBody: {
          vehicleExcludedExposure: {
            productId: vehicleExcludedExposure?.productId,
            underlyingPolicyNumber: null,
            carrier: vehicleExcludedExposure?.carrier,
            vehicleExcludedExposureId:
              vehicleExcludedExposure?.vehicleExcludedExposureId,
            vehicleType: vehicleExcludedExposure?.vehicleType,
            year: vehicleExcludedExposure?.year,
            make: vehicleExcludedExposure?.make,
            model: vehicleExcludedExposure?.model,
            vin: vehicleExcludedExposure?.vin,
          },
        },
        quoteId: product?.quoteId,
        productType: product?.type,
      } as VehicleExcludedExposureRequest;
    }
  );

export const getVehicleExcludedExposuresAndProductInfo = createSelector(
  getAllVehicleExcludedExposures,
  getUnderlyingPoliciesAndProductInfo,
  (
    vehicleExcludedExposures,
    underlyingPolicies
  ): VehicleExcludedExposureAndProductInfo[] => {
    return vehicleExcludedExposures.map((exposure) => {
      return {
        vehicleExcludedExposure: exposure,
        underlyingPolicy: underlyingPolicies.find(
          (up) =>
            up.underlyingPolicy.underlyingPolicyNumber ===
            exposure.underlyingPolicyNumber
        )?.underlyingPolicy,
        product: underlyingPolicies.find(
          (up) =>
            up.underlyingPolicy.underlyingPolicyNumber ===
            exposure.underlyingPolicyNumber
        )?.product,
      };
    }) as VehicleExcludedExposureAndProductInfo[];
  }
);

export const getVehicleExcludedExposureTiles = createSelector(
  getVehicleExcludedExposuresAndProductInfo,
  (vehicleExposures): UmbrellaTileModel[] => {
    return vehicleExposures.map((exposure) => {
      return {
        data: exposure.vehicleExcludedExposure,
        subheading: 'EXCLUDED EXPOSURE',
        titleLine1: `${exposure?.vehicleExcludedExposure?.year} ${exposure?.vehicleExcludedExposure?.make} ${exposure?.vehicleExcludedExposure?.model}`,
        titleLine2: '',
        subDetails: [
          {
            value: `${UmbrellaUtils.getCurrentCarrierNameLabel(
              exposure?.vehicleExcludedExposure?.carrier
            )} - ${UmbrellaUtils.getVehicleLabel(
              exposure?.vehicleExcludedExposure?.vehicleType
            )}`,
          },
        ],
        icon: exposure.product?.imageUrl,
        alt: 'Vehicle product icon',
        type: 'vehicle-excluded-exposure',
      };
    });
  }
);

export const getDeletedVehicleExcludedExposures = createSelector(
  getVehicleExcludedExposureState,
  (state) => {
    return Object.values(state.deleted || {}).filter(
      (v) => !!v
    ) as VehicleExcludedExposureEntity[];
  }
);
