import { Injectable } from '@angular/core';
import {
  CompositeActions,
  MemberActions,
  RetrieveActions,
} from '@core/store/actions';
import { MemberSelectors } from '@core/store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PeoplePageUtils } from '@shared/utils/pages/people-page.utils';
import { PersonUtils } from '@shared/utils/person.utils';
import { of } from 'rxjs';
import { switchMap, withLatestFrom, take, map } from 'rxjs/operators';
import {
  getSelectedProducts,
  getSelectedProductTypes,
} from '../product/product.selectors';
import { getAllMembers } from './member.selector';

@Injectable({
  providedIn: 'root',
})
export class MemberEffects {
  constructor(private actions$: Actions, private store: Store) {}

  addNewBlankPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberActions.addBlankMember),
      switchMap((action) =>
        this.store
          .select(MemberSelectors.createNewMember(action.payload))
          .pipe(take(1))
      ),
      switchMap((person) => of(MemberActions.storeMember({ payload: person })))
    )
  );

  addPni$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberActions.addPni),
      switchMap((action) =>
        this.store
          .select(MemberSelectors.createNewMember(action.payload))
          .pipe(take(1))
      ),
      switchMap((person) => of(MemberActions.storeMember({ payload: person })))
    )
  );

  fixupMemberRolesAfterRetrieve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RetrieveActions.allRetrievesComplete),
      withLatestFrom(
        this.store.select(getAllMembers),
        this.store.select(getSelectedProducts)
      ),
      switchMap(([action, members, products]) =>
        PersonUtils.makeActionsForMissingMemberRoles(members, products)
      )
    )
  );

  sendMembersToBackend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberActions.sendMembersToBackend),
      withLatestFrom(
        this.store.select(getAllMembers),
        this.store.select(getSelectedProducts)
      ),
      map(([action, members, products]) =>
        PeoplePageUtils.CreatePeoplePageSubmission(
          members,
          products,
          null,
          null
        )
      ),
      map((submission) => CompositeActions.submitPeoplePage({ submission }))
    )
  );
}
