import { ProductType } from '@core/models/api/dsm-types';
import { createAction, props } from '@ngrx/store';
import { CoverageInformationalMessage } from '../coverage/coverage.entity';

export const addCoverageInformationalMessage = createAction(
  '[Coverage Messages] Add coverage messages',
  props<{ payload: CoverageInformationalMessage }>()
);

export const removeProductCoverageInformationalMessage = createAction(
  '[Coverage Messages] Remove product coverage messages',
  props<{ id: ProductType }>()
);

export const removeAllCoverageInformationalMessage = createAction(
  '[Coverage Messages] Remove all coverage messages'
);
