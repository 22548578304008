import {
  ProductType,
  QuoteStatus,
  RatingType,
} from '@core/models/api/dsm-types';
import { QuoteRequest } from '@core/models/api/request/update-quote-request.model';
import { UpdateQuoteResponse } from '@core/models/api/response/update-quote-response.model';
import {
  CoverageActions,
  DiscountActions,
  MemberActions,
  PreBindDocumentsActions,
  PremiumActions,
  ProductActions,
  QuoteActions,
  QuoteLetterActions,
  VehicleActions,
} from '@core/store/actions';
import { ProductModel } from '@core/store/entities/product/product.model';
import { FeatureFlagsModel } from '@entities/feature-flag/feature-flag.model';
import { MemberEntity } from '@entities/member/member.reducer';
import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { RatingTypes } from '@shared/constants/app-constants';

export class QuoteUtils {
  static rateQuoteResponse(
    request: QuoteRequest,
    response: UpdateQuoteResponse,
    featureFlags: FeatureFlagsModel
  ): Action[] {
    const quoteAction: Action[] = [];
    this.addRateResponse(
      request.ratingType,
      quoteAction,
      request.productType,
      response.quoteStatus
    );
    this.addUpdatedProduct(request.productType, response, quoteAction);
    this.addResponseBasedActions(request.productType, response, quoteAction);
    this.addCoverageAndPremiumActions(
      request.productType,
      response,
      quoteAction
    );
    this.addPeopleActions(
      request.productType,
      response,
      quoteAction,
      featureFlags
    );
    return quoteAction;
  }

  static addRateResponse(
    rating: RatingType,
    actions: Action[],
    productId: ProductType,
    quoteStatus: QuoteStatus
  ): void {
    actions.push(
      rating === RatingTypes.QUOTE
        ? QuoteActions.rateQuoteSuccess({ productType: productId, quoteStatus })
        : QuoteActions.rateBindSuccess({ productType: productId, quoteStatus })
    );
  }

  static addUpdatedProduct(
    productId: ProductType,
    response: UpdateQuoteResponse,
    actions: Action[]
  ): void {
    actions.push(
      ProductActions.updateProduct({
        payload: this.createUpdatedProduct(productId, response),
      })
    );
  }

  static createUpdatedProduct(
    productId: ProductType,
    response: UpdateQuoteResponse
  ): Update<ProductModel> {
    return {
      id: productId,
      changes: {
        effectiveDate: response.effectiveDate,
        quoteStatus: response.quoteStatus,
        sessionId: response.sessionId,
        quoteId: response.quoteId,
        policyNumber: response.policyNumber,
        termType: response.termType,
        creditConsent: response.creditConsent,
        hasReceivedRequiredDocuments: response.hasReceivedRequiredDocuments,
        hasCustomerViewedDocuments: response.hasCustomerViewedDocuments,
        hasDrivingDataConsent: response.hasDrivingDataConsent,
        currentBillTo: response.currentBillTo,
        informationalMessages: response.informationalMessages,
        autoCharacteristics: response.autoCharacteristics,
      },
    };
  }

  static addResponseBasedActions(
    productId: ProductType,
    response: UpdateQuoteResponse,
    actions: Action[]
  ): void {
    if (response.discounts) {
      const discounts = response.discounts.map((discount) => {
        return { ...discount, productType: productId };
      });

      if (productId === 'PersonalAuto') {
        actions.push(
          DiscountActions.removeDiscountsOfType({
            discountType: 'Multi Car Discount',
          })
        );
      }
      actions.push(
        DiscountActions.replaceAllDiscountsOfProduct({
          payload: discounts,
          productType: productId,
        })
      );
    }

    // It's important that we not refresh pre-bind docs in Binding status.
    if (response.documents) {
      if (response.quoteStatus === 'Binding') {
        actions.push(
          PreBindDocumentsActions.dropPreBindDocuments({
            productType: response.productType,
          })
        );
      } else {
        const documents = response.documents.map((doc) => {
          return { ...doc, productType: response.productType };
        });
        actions.push(
          PreBindDocumentsActions.replacePreBindDocumentsByProductType({
            documents,
            productType: response.productType,
          })
        );
      }
    }

    // Added to store Annual Miles from QPC Call in quote response
    if (response.vehicles) {
      actions.push(
        VehicleActions.upsertAllVehicleSuccess({
          payload: response.vehicles,
        })
      );
    }
  }

  static addCoverageAndPremiumActions(
    productType: ProductType,
    response: UpdateQuoteResponse,
    actions: Action[]
  ): void {
    actions.push(
      CoverageActions.deleteCoveragesByProduct({
        payload: productType,
      })
    );
    actions.push(
      CoverageActions.runCoverageRules({
        payload: response.offeredQuotes[0].coverages.map((coverage) => {
          coverage.productId = productType;
          coverage.version = response.offeredQuotes[0].packageName;
          return coverage;
        }),
      })
    );
    actions.push(
      PremiumActions.updatePremium({
        payload: {
          ...response.offeredQuotes[0].premium,
          productType,
        },
      })
    );
    actions.push(QuoteLetterActions.loadQuoteLetter());
  }

  static addPeopleActions(
    productType: ProductType,
    response: UpdateQuoteResponse,
    actions: Action[],
    featureFlags: FeatureFlagsModel
  ): void {
    for (const person of [
      ...(response.drivers || []),
      ...(response.policyHolders || []),
      ...(response.householdMembers || []),
    ]) {
      actions.push(
        MemberActions.upsertMember({
          payload: {
            ...person,
            productType,
            entityId: '',
          } as MemberEntity,
        })
      );
    }
  }
}
