import {
  NationwideAccountRegistrationInfo,
  ProductType,
} from '@core/models/api/dsm-types';
import { InitiateNewBusinessResponse } from '@core/models/api/response/initiate-new-business-response.model';
import { FeatureFlagsModel } from '@core/store/entities/feature-flag/feature-flag.model';
import { ProductModel } from '@core/store/entities/product/product.model';
import { UserModel } from '@core/store/entities/user/user.model';
import { ErrorModel } from '@entities/error/error.model';
import { FeatureFlagUtils } from '@shared/utils/feature-flag.utils';
import { ProductUtils } from '@shared/utils/product.util';

export class ProductHelper {
  static buildProductChanges(
    response: InitiateNewBusinessResponse,
    productType: ProductType
  ): Partial<ProductModel> {
    let returnProduct = {
      id: productType,
      type: productType,
      effectiveDate: response.effectiveDate,
      quoteId: response.quoteId,
      creditConsent: response.creditConsent,
      termType: response.termType,
      sessionId: response.sessionId,
      quoteStatus: response.quoteStatus,
      tokenLoaded: false,
      tokenLoading: false,
      quoteLoading: false,
      offeringType: response.offeringType || null,
      currentBillTo: response.currentBillTo,
      automateElectronicDocumentDelivery:
        response.automateElectronicDocumentDelivery,
    } as Partial<ProductModel>;

    if (productType === 'PersonalAuto') {
      if (typeof response?.isAssignedRiskPlan === 'boolean') {
        returnProduct = {
          ...returnProduct,
          isAssignedRiskPlan: response.isAssignedRiskPlan,
        };
      }
      if (typeof response?.hasDrivingDataConsent === 'boolean') {
        returnProduct = {
          ...returnProduct,
          hasDrivingDataConsent: response.hasDrivingDataConsent,
        };
      }
    }

    if (productType === 'Homeowner' || productType === 'Condominium') {
      returnProduct = {
        ...returnProduct,
        needConstructionInfo: response.needConstructionInfo,
        prequalificationAnswers: response.prequalificationAnswers,
      };
    }

    if (productType === 'Tenant') {
      returnProduct = {
        ...returnProduct,
        prequalificationAnswers: response.prequalificationAnswers,
      };
    }

    if (productType === 'PersonalUmbrella') {
      returnProduct = {
        ...returnProduct,
        prequalificationAnswers: response.prequalificationAnswers,
        eligibilityInformation: response.eligibilityInformation,
      };
    }

    return returnProduct;
  }

  static setProductAvailable(
    product: ProductModel,
    featureFlags: FeatureFlagsModel,
    quoteState: string,
    user: UserModel,
    producerCode: string | undefined
  ): ProductModel {
    const isNSSAgent = user.role === 'NSS';
    if (
      ((product.type === 'Homeowner' || product.type === 'Condominium') &&
        !isNSSAgent &&
        FeatureFlagUtils.FlagApplies(
          'restrictHOCondoProductForIA',
          featureFlags
        )) ||
      (ProductUtils.isPropertyProduct(product.type) &&
        isNSSAgent &&
        FeatureFlagUtils.FlagApplies(
          'restrictPropertyProductsForNSS',
          featureFlags
        )) ||
      (product.type === 'MSA' &&
        FeatureFlagUtils.FlagApplies('restrictMSAProduct', featureFlags)) ||
      (product.type === 'RV' &&
        FeatureFlagUtils.FlagApplies('restrictRVProduct', featureFlags)) ||
      (product.type === 'Boat' &&
        FeatureFlagUtils.FlagApplies('restrictBoatProduct', featureFlags)) ||
      (product.type === 'PersonalUmbrella' &&
        FeatureFlagUtils.FlagApplies('restrictUmbrellaProduct', featureFlags))
    ) {
      return { ...product, isAvailable: false };
    }
    return product;
  }

  private static isRivianProducer(agencyCode: string): boolean {
    return !!(agencyCode === '00061629');
  }

  private static isFordProducer(agencyCode: string): boolean {
    return !!['00056758', '00063810', '00067346', '0068830'].includes(
      agencyCode
    );
  }

  static displayNoticeOfIssuance(agencyCode: string) {
    if (this.isFordProducer(agencyCode) || this.isRivianProducer(agencyCode)) {
      return false;
    } else {
      return true;
    }
  }

  static isQuoteBlockingError(
    productOrErrorModel: ProductModel | ErrorModel
  ): boolean {
    const quoteBlockingErrorCodes = [
      'HO320',
      'HO321',
      'HO322',
      'HO323',
      'HO324',
      'HO325',
      'A0237',
      'MO175',
      'BO159',
      'RO167',
    ];
    for (var code of quoteBlockingErrorCodes) {
      if (
        ('errorMessage' in productOrErrorModel &&
          productOrErrorModel.errorMessage?.includes(code)) ||
        ('displayMessage' in productOrErrorModel &&
          productOrErrorModel.displayMessage?.includes(code))
      ) {
        return true;
      }
    }
    return false;
  }

  static shouldProposePcForError(error: ErrorModel): boolean {
    return !!error.displayMessage?.match(/\[P0181\]/);
  }

  static buildAccountRegistrationInfo(
    registration: NationwideAccountRegistrationInfo
  ): NationwideAccountRegistrationInfo {
    return {
      doesCustomerWantOnlineAccount: registration.doesCustomerWantOnlineAccount,
      registrationLinkCommunicationMethod:
        registration.registrationLinkCommunicationMethod,
      primaryNamedInsuredMobileNumber:
        registration.primaryNamedInsuredMobileNumber,
      hasConsentedToOneTimeRegistrationText:
        registration.hasConsentedToOneTimeRegistrationText,
    };
  }
}
