import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../error/error.model';
import { UnderlyingPolicyEntity } from './underlying-policy.entity';
import { ProductType } from '../../../models/api/dsm-types';

export const upsertUnderlyingPolicy = createAction(
  '[Underlying Policy] Upsert Underlying Policy',
  props<{
    payload: UnderlyingPolicyEntity;
    existingPolicyNumber?: string;
    correlationId?: string;
  }>()
);

export const upsertUnderlyingPolicySuccess = createAction(
  '[Underlying Policy] Upsert Underlying Policy Success',
  props<{
    payload: UnderlyingPolicyEntity;
    existingPolicyNumber: string;
    correlationId?: string;
  }>()
);

export const upsertUnderlyingPolicyFail = createAction(
  '[Underlying Policy] Upsert Underlying Policy Fail',
  props<{
    error: ErrorModel;
    correlationId?: string;
    entityId?: string;
  }>()
);

export const restoreUnderlyingPolicies = createAction(
  '[Underlying Policy] Restore Deleted Underlying Policies'
);

export const clearDeletedUnderlyingPolicies = createAction(
  '[Underlying Policy] Clear Deleted Underlying Policies'
);

export const removeDeletedAccompanyingPolicy = createAction(
  '[Underlying Policy] Remove Accompanying Underlying Policy',
  props<{
    underlyingPolicyId: string;
    underlyingPolicyNumber: string;
    correlationId?: string;
  }>()
);

export const deleteUnderlyingPolicy = createAction(
  '[Underlying Policy] Delete Underlying Policy',
  props<{ payload: UnderlyingPolicyEntity; correlationId?: string }>()
);

export const deleteUnderlyingPolicySuccess = createAction(
  '[Underlying Policy] Delete Underlying Policy Success',
  props<{
    underlyingPolicyId: string;
    underlyingPolicyNumber: string;
    correlationId?: string;
  }>()
);

export const deleteUnderlyingPolicyFail = createAction(
  '[Underlying Policy] Delete Underlying Policy Fail',
  props<{
    error: ErrorModel;
    correlationId?: string;
    entityId?: string;
  }>()
);

export const buildAccompanyingExposures = createAction(
  '[Underlying Policy] Build Accompanying Exposures',
  props<{
    productType: ProductType;
    quoteId: string | undefined;
  }>()
);

export const removeAccompanyingPolicyIfRateFails = createAction(
  '[Underlying Policy] Removed Accompanying Policy Because Rate Failed',
  props<{
    productType: ProductType;
  }>()
);
