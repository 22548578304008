import { SessionActions } from '@core/store/actions';
import { createReducer, on } from '@ngrx/store';
import {
  updateMultiProductDiscountsForm,
  updatePartialMultiProductDiscountsForm,
} from './multi-product-discounts-form.action';

export interface MultiProductDiscountsFormModel {
  property: MultiProductPropertyType;
  personalAuto: boolean;
  farmAuto: boolean;
  powersports: boolean;
  personalUmbrella: boolean;
  vetPet: boolean;
  farm: boolean;
  commercial: boolean;
  nationwideBank: boolean;
  homeFinancial: boolean;
  annuities: boolean;
  boatRv: boolean;
  nonNWHomeowner: boolean;
  nonNWCondo: boolean;
}

export type MultiProductPropertyType =
  | ''
  | 'Condominium'
  | 'Homeowner'
  | 'Tenant'
  | 'FarmProperty';

const initialState: MultiProductDiscountsFormModel = {
  property: '',
  personalAuto: false,
  farmAuto: false,
  powersports: false,
  personalUmbrella: false,
  vetPet: false,
  farm: false,
  commercial: false,
  nationwideBank: false,
  homeFinancial: false,
  annuities: false,
  boatRv: false,
  nonNWHomeowner: false,
  nonNWCondo: false,
};

export const reducer = createReducer<MultiProductDiscountsFormModel>(
  initialState,
  on(SessionActions.clearSessionState, () => initialState),
  on(updateMultiProductDiscountsForm, (state, action) => action.model),
  on(updatePartialMultiProductDiscountsForm, (state, action) => ({
    ...state,
    ...action.model,
  }))
);
