import { ErrorModel } from './../error/error.model';
import * as fromActions from './protective-devices.action';
import { Nullable } from '@shared/utils/type.utils';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import { ProductType } from '@core/models/api/dsm-types';
import { SessionActions } from '@core/store/actions';
import { ObjectValidator } from '@core/helper/object-validator';

export interface ProtectiveDeviceEntity {
  type: string;
  value?: string;
  selectedValues?: Nullable<string[]>;
  category?: string;
}

export function sanitizeProtectiveDeviceEntities(
  response: unknown
): ProtectiveDeviceEntity[] {
  return ObjectValidator.sanitizeArray(
    response,
    sanitizeProtectiveDeviceEntity
  );
}

export function sanitizeProtectiveDeviceEntity(
  response: unknown
): ProtectiveDeviceEntity {
  return ObjectValidator.forceSchema<ProtectiveDeviceEntity>(
    response,
    {
      type: 'string',
      value: 'string',
      selectedValues: 'any',
      category: 'string',
    },
    ['type', 'value', 'selectedValues', 'category']
  );
}

export interface ProtectiveDevicesListEntity {
  productType: ProductType;
  devices: ProtectiveDeviceEntity[];
}

export interface ProtectiveDevicesState
  extends EntityState<ProtectiveDevicesListEntity> {
  error?: Nullable<ErrorModel>;
  loaded: boolean;
}

export const adapter: EntityAdapter<ProtectiveDevicesListEntity> =
  createEntityAdapter<ProtectiveDevicesListEntity>({
    selectId: (protectiveDevices) => protectiveDevices.productType,
  });

const protectiveDevicesReducer = createReducer(
  adapter.getInitialState({
    error: null,
    loaded: false,
  }) as ProtectiveDevicesState,
  on(
    SessionActions.clearSessionState,
    () =>
      adapter.getInitialState({
        error: null,
        loaded: false,
      }) as ProtectiveDevicesState
  ),
  on(fromActions.getProtectiveDevicesSuccess, (state, { payload: response }) =>
    adapter.upsertOne(response, {
      ...state,
      loaded: true,
    })
  ),
  on(fromActions.getProtectiveDevicesFail, (state, { error }) => ({
    ...state,
    error,
    loaded: true,
  })),
  on(fromActions.storeProtectiveDevices, (state, { payload }) =>
    adapter.upsertOne(payload, {
      ...state,
      loaded: true,
    })
  )
);

export function reducer(
  state: ProtectiveDevicesState | undefined,
  action: Action
): ProtectiveDevicesState {
  return protectiveDevicesReducer(state, action);
}
