import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'nwx-splash-form',
  templateUrl: './splash-form.component.html',
  styleUrls: ['./splash-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplashFormComponent implements OnInit {
  @Output() formSubmit = new EventEmitter();

  form!: FormGroup;
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.form = this.fb.group({
      agencyChannel: this.fb.control('', [Validators.required]),
    });
  }

  onSubmit(form: FormGroup): void {
    // TODO Don't forward the event if invalid (confirm)
    this.formSubmit.emit(form.value);
  }
}
