import * as fromCore from '@core/store/reducers';
import { createSelector } from '@ngrx/store';

export const getSessionState = createSelector(
  fromCore.getCoreState,
  (state) => state.session
);

export const getSessionId = createSelector(
  getSessionState,
  (state) => state.sessionId || ''
);

export const getQuoteState = createSelector(
  getSessionState,
  (state) => state.quoteState
);

export const getAccessToken = createSelector(
  getSessionState,
  (state) => state.accessToken
);

export const hasHubPageLoaded = createSelector(
  getSessionState,
  (state) => state.hubLoaded
);

export const getUserType = createSelector(
  getSessionState,
  (state) => state.userType
);

export const getIsRetrieve = createSelector(
  getSessionState,
  (state) => state.isRetrieve
);

export const getAllReviewedTasks = createSelector(
  getSessionState,
  (state) => state.reviewedTasks
);

export const getReviewedTask = (taskField: string) =>
  createSelector(getAllReviewedTasks, (tasks) => tasks?.[taskField]);
