import { createAction, props } from '@ngrx/store';
import { CurrentCarrierEntity } from './current-carrier.reducer';
import { ErrorModel } from '../error/error.model';

export const enum CurrentCarrierActionTypes {
  GET_CURRENT_CARRIER = '[Carrier] Get Current Carrier',
  GET_CURRENT_CARRIER_SUCCESS = '[Carrier] Get Current Carrier Success',
  GET_CURRENT_CARRIER_FAIL = '[Carrier] Get Current Carrier Fail',
  UPDATE_CURRENT_CARRIER = '[Carrier] Update Current Carrier',
  UPDATE_CURRENT_CARRIER_SUCCESS = '[Carrier] Update Current Carrier Success',
  UPDATE_CURRENT_CARRIER_FAIL = '[Carrier] Update Current Carrier Fail',
  UPDATE_CURRENT_CARRIER_FROM_QUOTE = '[Carrier] Update Current Carrier From Quote',
  STORE_CURRENT_CARRIER = '[Carrier] Store Current Carrier',
  SET_CURRENT_CARRIER = '[Carrier] Set Current Carrier',
  SET_IS_ASSIGNED_RISK_PLAN = '[Carrier] Set Is Assigned Risk Plan',
  SET_PROPERTY_YEARS_WITH_PRIOR_CARRIER = '[Carrier] Set Property Years With Prior Carrier',
}

export const updateCurrentCarrier = createAction(
  CurrentCarrierActionTypes.UPDATE_CURRENT_CARRIER,
  props<{ payload: CurrentCarrierEntity; correlationId: string }>()
);

export const updateCurrentCarrierFromQuote = createAction(
  CurrentCarrierActionTypes.UPDATE_CURRENT_CARRIER_FROM_QUOTE,
  props<{ payload: CurrentCarrierEntity }>()
);

export const updateCurrentCarrierSuccess = createAction(
  CurrentCarrierActionTypes.UPDATE_CURRENT_CARRIER_SUCCESS,
  props<{ payload: CurrentCarrierEntity; correlationId?: string }>()
);

export const storeCurrentCarrier = createAction(
  CurrentCarrierActionTypes.STORE_CURRENT_CARRIER,
  props<{ payload: CurrentCarrierEntity; correlationId?: string }>()
);

export const updateCurrentCarrierFail = createAction(
  CurrentCarrierActionTypes.UPDATE_CURRENT_CARRIER_FAIL,
  props<{ error: ErrorModel; correlationId: string }>()
);

export const getCurrentCarrier = createAction(
  CurrentCarrierActionTypes.GET_CURRENT_CARRIER
);

export const getCurrentCarrierSuccess = createAction(
  CurrentCarrierActionTypes.GET_CURRENT_CARRIER_SUCCESS,
  props<{ payload: CurrentCarrierEntity }>()
);

export const getCurrentCarrierFail = createAction(
  CurrentCarrierActionTypes.GET_CURRENT_CARRIER_FAIL,
  props<{ error: ErrorModel }>()
);

export const setCurrentCarrier = createAction(
  CurrentCarrierActionTypes.SET_CURRENT_CARRIER,
  props<{ payload: CurrentCarrierEntity; hit: boolean }>()
);
