import { AddressType } from '@core/models/api/dsm-types';
import { createAction, props } from '@ngrx/store';
import { AddressEntity } from './address.entity';

export const upsertAddress = createAction(
  '[Address] Upsert Address',
  props<{ payload: AddressEntity }>()
);

export const storeAddress = createAction(
  '[Address] Store Address',
  props<{ payload: AddressEntity }>()
);

export const deleteAddress = createAction(
  '[Address] Delete Address',
  props<{ payload: AddressType }>()
);
