import { PniPageSubmission } from '@app/pni/pages/pni/pni-page.component';
import { MemberModel } from '@core/models/views/person.model';
import { ExpectedActions } from '@core/services/action-aggregator.service';
import { StringUtils } from '@shared/utils/string.utils';
import {
  QuoteActions,
  EligibleDiscountsActions,
  CoveredLocationActions,
} from '@core/store/actions';
import { ProductModel } from '@core/store/entities/product/product.model';
import { Action } from '@ngrx/store';
import { AddressModel } from '@shared/components/address-input/address-input.model';
import { Nullable } from '../type.utils';
import { PageUtils } from './page.utils';
import { ProductUtils } from '../product.util';

export class PniPageUtils extends PageUtils {
  static CreatePniPageSubmission(
    pni: MemberModel,
    accountAddress: AddressModel,
    mailingAddress?: Nullable<AddressModel>,
    hasMailingAddress?: Nullable<boolean>
  ): PniPageSubmission {
    if (!hasMailingAddress) {
      mailingAddress = null;
    }

    return {
      pni,
      accountAddress,
      mailingAddress,
    } as PniPageSubmission;
  }

  static InitiateOrUpdateProducts(
    products: ProductModel[],
    nextActions: Action[],
    expectedActions: ExpectedActions
  ): void {
    products.forEach((product) => {
      const correlationId = StringUtils.generateUuid();

      if (!product?.quoteId) {
        nextActions.push(
          QuoteActions.initiateNewBusiness({
            payload: product.type,
            correlationId,
          })
        );
        expectedActions.push(
          PageUtils.getExpectedActions(
            QuoteActions.initiateNewBusinessSuccess.type,
            QuoteActions.initiateNewBusinessFail.type,
            correlationId
          )
        );
      } else {
        nextActions.push(
          QuoteActions.updateQuote({
            productType: product.type,
            override: {
              docDelPreference: product.docDelPreference,
              automateElectronicDocumentDelivery:
                product.automateElectronicDocumentDelivery,
            },
            correlationId,
          })
        );
        expectedActions.push(
          PageUtils.getExpectedActions(
            QuoteActions.updateQuoteSuccess.type,
            QuoteActions.updateQuoteFail.type,
            correlationId
          )
        );
        nextActions.push(
          EligibleDiscountsActions.getPolicyLine({
            productType: product.type,
            correlationId,
          })
        );
        expectedActions.push(
          PageUtils.getExpectedActions(
            EligibleDiscountsActions.getPolicyLineSuccess.type,
            EligibleDiscountsActions.getPolicyLineFail.type,
            correlationId
          )
        );
        if (
          ['Homeowner', 'Condominium'].includes(product.type) &&
          !!product.associatedMSBEstimateNumber
        ) {
          nextActions.push(
            CoveredLocationActions.updateCoveredLocation({
              payload: product.type,
              includeMsbEstimateNumber: true,
              correlationId,
            })
          );
          // Failures from this updateCoveredLocation must not prevent navigation. TIB-606
          expectedActions.push({
            success: [{
              type: CoveredLocationActions.updateCoveredLocationSuccess.type,
              correlationId,
            }, {
              type: CoveredLocationActions.updateCoveredLocationError.type,
              correlationId,
            }],
            error: [],
          });
        }
      }
    });
  }
}
