import { Injectable } from '@angular/core';
import { FeatureFlagService } from '@core/services/feature-flag.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, take } from 'rxjs/operators';
import { retrieveQuoteSuccess } from '../../retrieve/retrieve.action';
import { updateSelectedProducts } from '../product/product.actions';
import {
  initiateNewBusinessSuccessAfterFirstReduction,
  updateQuoteSuccess,
} from '../quote/quote.action';
import { updateRatingState } from '../session/session.action';
import * as fromAction from './feature-flag.action';
import * as fromSelector from './feature-flag.selector';

const API_DEBOUNCE_MS = 1000;

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagEffects {
  constructor(
    private actions$: Actions,
    private service: FeatureFlagService,
    private store: Store
  ) {}

  // Get all the SS flags as a FF object and trigger replace all effect
  loadStateSpecificFlagsAsFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        retrieveQuoteSuccess,
        updateRatingState,
        updateQuoteSuccess,
        updateSelectedProducts,
        initiateNewBusinessSuccessAfterFirstReduction
      ),
      switchMap(() =>
        this.store
          .select(fromSelector.getStateSpecificFlagsAsFeatureFlags)
          .pipe(take(1))
      ),
      map((featureFlags) => fromAction.replaceAllFeatureFlags({ featureFlags }))
    )
  );
}
