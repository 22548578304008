import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductType } from '@core/models/api/dsm-types';
import { ComponentStoreService } from '@core/services/component-store.service';
import { NavigationService } from '@core/services/navigation.service';
import { ScheduledCategoriesService } from '@core/services/scheduled-categories.service';
import {
  ScheduledCategoryEntity,
  ScheduledCategoryItem,
  ScheduledItemType,
} from '@core/store/entities/scheduled-categories/scheduled-category.entity';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentStore } from '@ngrx/component-store';
import {
  PillModel,
  WellContent,
} from '@shared/components/pill-list/pill-list.component';
import { Nullable } from '@shared/utils/type.utils';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalEventMetadata } from '../scheduled-categories-container/scheduled-categories-container.component';

export interface WrapperComponentState {
  wellContent?: WellContent;
  modalTitle?: string;
  disableSubmissionBtns?: boolean;
}
@Component({
  selector: 'nwx-scheduled-item-form-wrapper',
  templateUrl: './scheduled-item-form-wrapper.component.html',
  styleUrls: ['./scheduled-item-form-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore],
})
export class ScheduledItemFormWrapperComponent implements OnInit, OnDestroy {
  // Category: used for Scheduled Property, or PersonalEffect: used for Scheduled Personal Effects
  @Input() scheduledItemType: ScheduledItemType = 'Category';

  // The current scheduled item being added or edited
  @Input() scheduledItem!: Nullable<ScheduledCategoryItem>;

  // The current scheduled category
  @Input() parentCategory$!: Observable<Nullable<ScheduledCategoryEntity>>;

  // All scheduled personal effect items already added
  @Input() personalEffectItemsAdded$!: Observable<ScheduledCategoryItem[]>;

  // The entire list of scheduled categories for a single product
  @Input() allScheduledCategories$!: Observable<ScheduledCategoryEntity[]>;

  @Input() productType!: ProductType;

  mode: 'edit' | 'add' = 'add';
  unsubscribe$: Subject<void> = new Subject();

  localScheduledItem!: Nullable<Partial<ScheduledCategoryItem>>;

  form!: FormGroup;
  vm$!: Observable<WrapperComponentState>;
  loading$!: Observable<boolean>;
  combinedPersonalEffectsValue$!: Observable<Nullable<number>>;

  constructor(
    private scheduledCategoriesService: ScheduledCategoriesService,
    private readonly componentStore: ComponentStoreService<WrapperComponentState>,
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({});
    this.loading$ =
      this.scheduledCategoriesService.getScheduledCategoriesLoading();
    this.combinedPersonalEffectsValue$ =
      this.scheduledCategoriesService.getScheduledPersonalEffectsCombinedValue(
        this.productType
      );
    this.componentStore.initialize({
      wellContent: {} as WellContent,
      modalTitle: '',
      disableSubmissionBtns: false,
    });
    this.vm$ = this.componentStore.get();
    if (this.scheduledItem?.itemId) {
      this.mode = 'edit';
    }
    if (this.scheduledItemType === 'Category') {
      this.parentCategory$
        ?.pipe(takeUntil(this.unsubscribe$))
        .subscribe((category: Nullable<ScheduledCategoryEntity>) => {
          this.componentStore.update({
            wellContent: {
              description: 'Scheduled items already added:',
              itemList: this.populatePillList(category?.scheduledItems),
              displayButton: false,
              entityId: category?.scheduledCategoryId || '',
            },
            modalTitle: this.getModalTitle(category),
          });
        });
    } else {
      this.personalEffectItemsAdded$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((items) => {
          this.componentStore.update({
            wellContent: {
              description: 'Scheduled personal effects already added:',
              itemList: this.populatePillList(items || []),
              displayButton: false,
              entityId: '',
            },
            modalTitle: this.getModalTitle(),
          });
        });
    }
  }

  ngOnDestroy(): void {
    this.localScheduledItem = null;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onFormReady(name: string, event: FormGroup) {
    this.form?.setControl(name, event);
  }

  onValueChange(event: Partial<ScheduledCategoryItem>): void {
    this.localScheduledItem = event;
    if (this.scheduledItem?.itemId) {
      this.localScheduledItem.itemId = this.scheduledItem.itemId;
    }
  }

  saveItem(metadata?: ModalEventMetadata): void {
    if (this.form?.valid) {
      this.modal.close({ item: this.localScheduledItem, metadata });
    } else {
      this.navigationService.submitPage();
    }
  }
  close(): void {
    this.modal.close();
  }
  removeItem(): void {
    this.modal.close({ item: this.scheduledItem, metadata: 'removeItem' });
  }
  editExisting(event: Nullable<ScheduledCategoryItem>): void {
    this.scheduledItem = { ...(event as ScheduledCategoryItem) };
  }

  populatePillList(items?: ScheduledCategoryItem[]): PillModel[] {
    return (
      items?.map((item) => {
        return {
          name: item.description,
          item,
        };
      }) || []
    );
  }

  getModalTitle(category?: Nullable<ScheduledCategoryEntity>): string {
    if (category?.name) {
      return (
        (this.mode === 'edit' ? 'Edit ' : 'Add ') +
        'scheduled item: ' +
        category?.name
      );
    } else {
      return (this.mode === 'edit' ? 'Edit ' : 'Add ') + 'scheduled item: ';
    }
  }

  enableOrDisableButtons(event: boolean): void {
    const disableSubmissionBtns = event;
    this.componentStore.update({ disableSubmissionBtns });
  }
}
