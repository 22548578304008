import { Action, createReducer, on } from '@ngrx/store';

import { UserActions } from '@core/store/actions';
import { UserModel } from '@core/store/entities/user/user.model';

export interface UserState {
  user: UserModel;
}

const initialState: UserState = {
  user: {
    isAuthorized: false,
    initiatedBy: null,
  },
};

const userReducer = createReducer(
  initialState,
  on(UserActions.updateUser, (state, { payload }) => ({
    ...state,
    user: { ...state.user, ...payload },
  }))
);

export function reducer(
  state: UserState | undefined,
  action: Action
): UserState {
  return userReducer(state, action);
}
