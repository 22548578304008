import { StateSpecificFlagsModel } from '@assets/metadata/stateSpecificFlags';
import { Action, createAction, props } from '@ngrx/store';
import { VersionInformation } from './metadata.reducer';
import { PropertyOptionsModel } from './models/property-options.model';
import { UmbrellaOptionsModel } from './models/umbrella-options.model';

export const LOAD_STATE_SPECIFIC_FLAGS = '[Core] Load State Specific Flags';
export const LOAD_STATE_SPECIFIC_FLAGS_FAIL =
  '[Core] Load State Specific Flags Fail';
export const LOAD_STATE_SPECIFIC_FLAGS_SUCCESS =
  '[Core] Load State Specific Flags Success';

export const LOAD_PROPERTY_OPTIONS = '[Core] Load Property Options';
export const LOAD_PROPERTY_OPTIONS_SUCCESS =
  '[Core] Load Property Options Success';
export const LOAD_PROPERTY_OPTIONS_FAIL = '[Core] Load Property Options Fail';
export const REPLACE_PROPERTY_OPTIONS = '[Core] Replace Property Options';

export const SET_VERSION_INFORMATION = '[Core] Set Version Information';

export const LOAD_UMBRELLA_OPTIONS = '[Core] Load Umbrella Options';
export const LOAD_UMBRELLA_OPTIONS_SUCCESS =
  '[Core] Load Umbrella Options Success';
export const LOAD_UMBRELLA_OPTIONS_FAIL = '[Core] Load Umbrella Options Fail';

export const loadStateSpecificFlags = createAction(LOAD_STATE_SPECIFIC_FLAGS);

export const loadStateSpecificFlagsSuccess = createAction(
  LOAD_STATE_SPECIFIC_FLAGS_SUCCESS,
  props<{ response: StateSpecificFlagsModel }>()
);

export const loadStateSpecificFlagsFail = createAction(
  LOAD_STATE_SPECIFIC_FLAGS_FAIL,
  props<{ error: string }>()
);

export const loadStates = createAction('[Metadata] Load States');

export const loadStatesSuccess = createAction(
  '[Metadata] Load States Success',
  props<{ response: string[] }>()
);

export const loadStatesFail = createAction(
  '[Metadata] Load States Fail',
  props<{ error: string }>()
);

export const loadPropertyOptions = createAction(LOAD_PROPERTY_OPTIONS);

export const loadPropertyOptionsSuccess = createAction(
  LOAD_PROPERTY_OPTIONS_SUCCESS,
  props<{ response: PropertyOptionsModel }>()
);

export const loadPropertyOptionsFail = createAction(
  LOAD_PROPERTY_OPTIONS_FAIL,
  props<{ error: string }>()
);

export const replacePropertyOptions = createAction(
  REPLACE_PROPERTY_OPTIONS,
  props<{ propertyOptions: PropertyOptionsModel }>()
);

export const setVersionInformation = createAction(
  SET_VERSION_INFORMATION,
  props<{ payload: VersionInformation }>()
);

export const loadUmbrellaOptions = createAction(LOAD_UMBRELLA_OPTIONS);

export const loadUmbrellaOptionsSuccess = createAction(
  LOAD_UMBRELLA_OPTIONS_SUCCESS,
  props<{ response: UmbrellaOptionsModel }>()
);

export const loadUmbrellaOptionsFail = createAction(
  LOAD_UMBRELLA_OPTIONS_FAIL,
  props<{ error: string }>()
);
