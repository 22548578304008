import { Injectable } from '@angular/core';
import { PageIdentifier } from '@app/core/constants/pages';
import { MemberModel } from '@app/core/models/views/person.model';
import { VehicleModel } from '@app/core/models/views/vehicle.model';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  clearReviewTasksForPage,
  displayPageTasksAsErrors,
  setShowTasks,
  updateTasks,
} from '../store/entities/task/task.action';
import {
  SortedTasks,
  TaskModel,
  UpdateTaskModel,
} from '../store/entities/task/task.model';
import { LogService } from './log.service';
import {
  MemberTaskSelectors,
  ProductsSelectors,
  TaskSelectors,
  VehicleTaskSelectors,
} from '@core/store/selectors';
import { NavigationActions, TaskActions } from '@core/store/actions';
import { Page } from '@entities/navigation/navigation.action';
import {
  ProductType,
  QuoteStatus,
  quoteStatusGreaterThan,
} from '@core/models/api/dsm-types';
import { TaskUtils } from '@entities/task/utils/task.util';
import { Nullable } from '@shared/utils/type.utils';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  constructor(private store: Store, private loggingService: LogService) {}

  getMemberTasksForMember(member?: MemberModel): Observable<TaskModel[]> {
    return this.store.select(
      MemberTaskSelectors.getMemberTasksForMember(member?.entityId as string)
    );
  }

  getVehicleTasksforVehicle(vehicle: VehicleModel): Observable<TaskModel[]> {
    return this.store.select(
      VehicleTaskSelectors.getVehicleTasksForVehicle(vehicle)
    );
  }

  getPniTasks(): Observable<TaskModel[]> {
    return this.store.select(
      TaskSelectors.selectIncompleteTasksByTaskEntityType(['pni'])
    );
  }

  showTasksAsErrorsForPage(pageName: PageIdentifier): void {
    this.store.dispatch(displayPageTasksAsErrors({ payload: pageName }));
  }

  clearReviewTasksForPage(pageName: PageIdentifier): void {
    this.store
      .select(ProductsSelectors.getOverallFurthestQuoteStatus)
      .pipe(take(1))
      .subscribe((status) => {
        this.store.dispatch(
          clearReviewTasksForPage({
            payload: pageName,
            quoteStatusLessOrEqual: status,
          })
        );
      });
  }

  getAllTasks(): Observable<TaskModel[]> {
    return this.store.select(TaskSelectors.selectAllTasks);
  }

  getPageSortedTasks(): Observable<SortedTasks[][]> {
    return this.store.select(TaskSelectors.getPageSortedTasks);
  }

  getReviewTasks(): Observable<SortedTasks[][]> {
    return this.store.select(TaskSelectors.getSortedReviewTasks);
  }

  getAccountTasks(): Observable<TaskModel[]> {
    return this.store.select(TaskSelectors.getAccountTasks);
  }

  getErrorProductsAsSortedTasks(): Observable<SortedTasks[][]> {
    return this.store.select(TaskSelectors.getErrorProductsAsSortedTasks);
  }

  getBlockedProductsAsSortedTasks(): Observable<SortedTasks[][]> {
    return this.store.select(TaskSelectors.getBlockedProductsAsSortedTasks);
  }

  getIncompleteTasks(): Observable<TaskModel[]> {
    return this.store.select(TaskSelectors.getIncompleteTasks);
  }

  getPageTasks(pageName: PageIdentifier): Observable<TaskModel[]> {
    return this.store.select(TaskSelectors.getIncompleteTasksForPage(pageName));
  }

  allHubTasksComplete(): Observable<boolean> {
    return this.store.select(TaskSelectors.allHubTasksComplete);
  }

  dispatchAction(taskAction: Action): void {
    this.store.dispatch(taskAction);
  }

  getShowTasks(): Observable<boolean> {
    return this.store.select(TaskSelectors.getShowTasks);
  }

  setShowTasks(showTasks: boolean): void {
    this.store.dispatch(setShowTasks({ payload: showTasks }));
  }

  getIncompleteDraftTasks(): Observable<TaskModel[]> {
    return this.store.select(TaskSelectors.getIncompleteDraftTasks);
  }

  updatePropertyTasks(): void {
    this.store.dispatch(updateTasks());
  }

  submitTasksModal(): void {
    this.store.dispatch(
      TaskActions.submitTasksModal({
        payload: { name: 'Quote summary' },
      })
    );
  }

  closeTasksModal(): void {
    this.store.dispatch(TaskActions.closeTasksModal());
  }

  dismissReviewTask(fieldName: string, productType: ProductType): void {
    this.store.dispatch(
      TaskActions.dismissReviewTask({
        payload: { field: fieldName, productType },
      })
    );
  }

  getEffectiveDateTasks(): Observable<TaskModel[]> {
    return this.store.select(TaskSelectors.getEffectiveDateTasks);
  }

  getTaskByFieldId(field: string): Observable<Nullable<TaskModel>> {
    return this.store.select(TaskSelectors.getTaskByFieldId(field));
  }

  createUpdateActions(tasks: UpdateTaskModel): Action[] {
    const addTasks: Action[] = [];
    tasks.add.forEach((t) => {
      addTasks.push(TaskActions.addTask({ payload: t }));
    });
    const completeTasks: Action[] = [];
    tasks.complete.forEach((t) => {
      completeTasks.push(TaskActions.completeTask({ payload: t }));
    });
    const uncompleteTasks: Action[] = [];
    tasks.uncomplete.forEach((t) => {
      if (t) {
        uncompleteTasks.push(TaskActions.uncompleteTask({ payload: t }));
      }
    });
    return [...addTasks, ...completeTasks, ...uncompleteTasks];
  }

  setPageWarnings(
    returnActions: Action[],
    incompleteTasks: TaskModel[],
    page: Nullable<Page>
  ): void {
    if (TaskUtils.reviewTaskExistsForPage(incompleteTasks, page?.id as PageIdentifier)) {
      if (page && !page.hasReviewWarning) {
        returnActions.push(
          NavigationActions.setReviewWarning({ payload: page.id })
        );
      }
    } else {
      if (page && page.hasReviewWarning) {
        returnActions.push(
          NavigationActions.clearReviewWarning({ payload: page.id })
        );
      }
    }

    const nonReviewTasks = incompleteTasks.filter(
      (t) => t.entityType !== 'review' && t.page?.id === page?.id
    );
    if (nonReviewTasks?.length > 0) {
      if (page && !page.hasWarning) {
        returnActions.push(
          NavigationActions.setWarning({
            payload: page.id,
          })
        );
      }
    } else {
      if (page && page.hasWarning) {
        returnActions.push(
          NavigationActions.clearWarning({
            payload: page.id,
          })
        );
      }
    }
  }

  setPageComplete(
    returnActions: Action[],
    incompleteTasks: TaskModel[],
    overallQuoteStatus: QuoteStatus,
    page: Nullable<Page>
  ): void {
    if (
      incompleteTasks?.length === 0 &&
      quoteStatusGreaterThan(overallQuoteStatus || 'Draft', 'Pending')
    ) {
      if (page && !page.isComplete) {
        returnActions.push(
          NavigationActions.markComplete({
            payload: page.id,
          })
        );
      }
    } else {
      if (page && page.isComplete) {
        returnActions.push(
          NavigationActions.markIncomplete({
            payload: page.id,
          })
        );
      }
    }
  }

  completeTasksByProductTypeAndField(
    productType: ProductType,
    field: string
  ): void {
    this.store
      .select(TaskSelectors.selectAllTasks)
      .pipe(take(1))
      .subscribe((tasks) => {
        for (const task of tasks) {
          if (task.productType === productType && task.field === field) {
            this.store.dispatch(TaskActions.completeTask({ payload: task }));
          }
        }
      });
  }
}
