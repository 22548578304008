import { createAction, props } from '@ngrx/store';
import {
  CoverageChange,
  CoverageEntity,
} from '@core/store/entities/coverage/coverage.entity';
import { ErrorModel } from '@core/store/entities/error/error.model';
import { CoveragesRequest } from '@core/models/api/request/coverages-request.model';
import { ProductType } from '@core/models/api/dsm-types';

export const addCoverage = createAction(
  '[Coverage] Add Coverage',
  props<{ payload: CoverageEntity }>()
);

export const addCoverageError = createAction(
  '[Coverage] Add Coverage Error',
  props<{ error: ErrorModel }>()
);

export const addCoverageSuccess = createAction(
  '[Coverage] Add Coverage Success',
  props<{ payload: CoverageEntity }>()
);

export const addManyCoverages = createAction(
  '[Coverage] Add Many Coverages',
  props<{ payload: CoverageEntity[] }>()
);

export const updateCoverage = createAction(
  '[Coverage] Update Coverage',
  props<{ payload: CoverageChange[] }>()
);

export const updateCoverageError = createAction(
  '[Coverage] Update Coverage Error',
  props<{ error: ErrorModel; updatedCoverage: CoverageChange[] }>()
);

export const updateCoverageSuccess = createAction(
  '[Coverage] Update Coverage Success',
  props<{ payload: CoverageEntity[]; updatedCoverage: CoverageChange[] }>()
);

export const deleteCoverage = createAction(
  '[Coverage] Delete Coverage',
  props<{ payload: string }>()
);

export const deleteCoverageError = createAction(
  '[Coverage] Delete Coverage Error',
  props<{ error: ErrorModel }>()
);

export const deleteCoverageSuccess = createAction(
  '[Coverage] Delete Coverage Success',
  props<{ payload: string }>()
);

export const deleteCoveragesByProduct = createAction(
  '[Coverage] Delete Coverages By Product',
  props<{ payload: ProductType }>()
);

export const getAllCoverages = createAction(
  '[Coverage] Get All Coverages',
  props<{ payload: CoveragesRequest }>()
);

export const getAllCoveragesSuccess = createAction(
  '[Coverage] Get All Coverages Success',
  props<{ payload: CoverageEntity[] }>()
);

export const getAllCoveragesFail = createAction(
  '[Coverage] Get All Coverages Fail',
  props<{ error: ErrorModel }>()
);

export const runCoverageRules = createAction(
  '[Coverage] Run Coverage Rules',
  props<{ payload: CoverageEntity[] }>()
);

export const loadAllCoverages = createAction(
  '[Coverage] Load All Product Coverages'
);

// Causes some no-op change to store, to make it re-emit.
export const pokeCoverageEntityStore = createAction(
  '[Coverage] Poke Coverage Entity Store'
);
