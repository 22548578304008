import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';
import * as fromSelectors from './policyholder.selectors';
import { Injectable } from '@angular/core';
import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as fromActions from './policyholder.actions';
import { PolicyHolderEntity } from './policyholder.entity';
import { PolicyholderBuilder } from '@shared/utils/builders/policyholder.builder';
import { of } from 'rxjs';
import { EligibleDiscountsActions } from '@core/store/actions';
import { MemberEntity } from '../member/member.reducer';
import { PolicyholderService } from '@core/services/policyholder.service';
import { ProductType } from '@core/models/api/dsm-types';
import { PolicyRolesApiService } from '@app/policy-roles-api/policy-roles-api.service';

@Injectable({
  providedIn: 'root',
})
export class PolicyholderEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private service: PolicyholderService,
    private errorSanitizerService: ErrorSanitizerService,
    private policyRolesApiService: PolicyRolesApiService
  ) {}

  upsertPolicyHolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.upsertPolicyHolder),
      switchMap((action) =>
        this.store
          .select(
            fromSelectors.buildPolicyholderRequest(
              action.payload,
              action.correlationId
            )
          )
          .pipe(take(1))
      ),
      mergeMap((request) =>
        this.service.addOrUpdatePolicyHolder(request).pipe(
          switchMap((response) => {
            const policyHolderEntity =
              PolicyholderBuilder.buildPolicyholderEntityFromResponse(
                response,
                request
              );

            const nextActions: Action[] = [
              fromActions.upsertPolicyHolderSuccess({
                payload: policyHolderEntity as MemberEntity,
                correlationId: request.correlationId,
              }),
            ];

            if (request.policyHolder.membership) {
              // Membership Discount
              nextActions.push(
                EligibleDiscountsActions.updatePolicyLineIfDefined({
                  entity: {
                    name: 'Membership',
                    eligibleDiscountId: 'Membership',
                    selectedOptionValue: request.policyHolder.membership,
                    productType: request.productType as ProductType,
                  },
                })
              );
            }

            if (
              typeof request.policyHolder.isNationwideEmployee === 'boolean'
            ) {
              // Associate Discount
              nextActions.push(
                EligibleDiscountsActions.updatePolicyLineIfDefined({
                  entity: {
                    name: 'Associate',
                    eligibleDiscountId: 'Associate',
                    selectedOptionValue: request.policyHolder?.associateNumber
                      ? 'true'
                      : 'false',
                    qualifyingInformation: {
                      associateNumber:
                        Number(request.policyHolder?.associateNumber) ??
                        undefined,
                    },
                    productType: request.productType as ProductType,
                  },
                })
              );
            }

            return nextActions;
          }),
          catchError((error) => {
            return of(
              fromActions.upsertPolicyHolderError({
                error: this.errorSanitizerService.sanitizeError(error, request.productType),
                policyHolderId: request.policyHolder.policyHolderId as number,
                correlationId: request.correlationId,
              })
            );
          })
        )
      )
    )
  );

  deletePolicyholder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deletePolicyHolder),
      switchMap((action) =>
        this.store
          .select(
            fromSelectors.buildPolicyholderRequest(
              action.policyHolder,
              action.correlationId
            )
          )
          .pipe(take(1))
      ),
      mergeMap((request) =>
        this.service.deletePolicyHolder(request).pipe(
          switchMap(() =>
            of(
              fromActions.deletePolicyHolderSuccess({
                payload: request.policyHolder as MemberEntity,
                correlationId: request.correlationId,
                request,
              })
            )
          ),
          catchError((error) => {
            return of(
              fromActions.deletePolicyHolderError({
                error: this.errorSanitizerService.sanitizeError(error, request.productType),
                policyHolder: request.policyHolder as PolicyHolderEntity,
                correlationId: request.correlationId,
              })
            );
          })
        )
      )
    )
  );

  patchPolicyRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.patchPolicyRoles),
      mergeMap((action) => {
        return this.policyRolesApiService.patchPolicyRoles(
          action.productType,
          action.quoteId,
          action.changes
        ).pipe(
          map((response) => fromActions.patchPolicyRolesSuccess({
            correlationId: action.correlationId,
            response,
            productType: action.productType,
            request: action.changes,
          })),
          catchError((error) => {
            return of(fromActions.patchPolicyRolesError({
              correlationId: action.correlationId,
              error: this.errorSanitizerService.sanitizeError(error, action.productType),
              productType: action.productType,
            }));
          })
        );
      })
    )
  );
}
