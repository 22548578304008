<div class="discounts-container">
  <ng-container *ngFor="let product of products">
    <div class="discount-block">
      <nwx-discounts-list
        *ngIf="product.name !== 'PersonalUmbrella'"
        [discounts]="findDiscountsForProduct(product.type)"
        [product]="product"
        [header]="product.name + ' policy discounts'"
        [maxSizeXSmall]="maxSizeXSmall$ | async"
      ></nwx-discounts-list>
    </div>
  </ng-container>
  <ng-container *ngIf="hasMultiProductDiscounts">
    <div class="discount-block">
      <nwx-discounts-list
        [discounts]="findMultiProductDiscounts()"
        [header]="'Additional discounts'"
        [maxSizeXSmall]="maxSizeXSmall$ | async"
      ></nwx-discounts-list>
    </div>
  </ng-container>
  <ng-container>
    <div class="help-section">
      <bolt-icon name="question-circle" color="medium-blue"></bolt-icon>
      <a
        class="help-content bolt-body-copy-sm"
        href="javascript:void(0)"
        (click)="openDiscountInfoModal()"
        >Information on all available discounts</a
      >
    </div>
  </ng-container>
</div>
