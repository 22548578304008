import { ObjectValidator } from '@core/helper/object-validator';
import { ProductType } from '@core/models/api/dsm-types';
import { BaseEntity } from '@core/models/entities/base.entity';

// used to have extends BaseEntity
export interface DriverVehicleAssignmentEntity {
  driverId: number;
  vehicleId: string;
  isPrimaryDriver?: boolean;
  isPrimaryVehicle?: boolean;
  productType: ProductType;
}

export function sanitizeDriverVehicleAssignmentEntities(
  response: unknown
): DriverVehicleAssignmentEntity[] {
  return ObjectValidator.sanitizeArray(
    response,
    sanitizeDriverVehicleAssignmentEntity
  );
}

export function sanitizeDriverVehicleAssignmentEntity(
  response: unknown
): DriverVehicleAssignmentEntity {
  return ObjectValidator.forceSchema<DriverVehicleAssignmentEntity>(
    response,
    {
      driverId: 'number',
      vehicleId: 'string',
      isPrimaryDriver: 'boolean',
      isPrimaryVehicle: 'boolean',
      productType: 'string',
    },
    ['isPrimaryDriver', 'isPrimaryVehicle']
  );
}
