import { createAction, props } from "@ngrx/store";
import { EligibilityFormModel } from "./eligibility-form.model";
import { ProductType } from '@core/models/api/dsm-types';

export const upsertEligibilityForm = createAction(
  '[Eligibility Form] Upsert',
  props<{ model: Partial<EligibilityFormModel> }>()
);

export const removeEligibilityForm = createAction(
  '[Eligibility Form] Remove',
  props<{ quoteId: string }>()
);

export const removeEligibilityFormsForProduct = createAction(
  '[Eligibility Form] Remove For Product',
  props<{ productType: ProductType }>()
);

export const showEligibilityFormModal = createAction(
  '[Eligibility Form] Show Modal',
  props<{ productType: ProductType, quoteId: string }>()
);

export const addDog = createAction(
  '[Eligibility Form] Add Dog',
  props<{ quoteId: string }>()
);

export const removeDog = createAction(
  '[Eligibility Form] Remove Dog',
  props<{ quoteId: string, index: number }>()
);
