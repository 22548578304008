import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BoatTrailer, VehicleModel } from '@core/models/views/vehicle.model';
import { ComponentStoreService } from '@core/services/component-store.service';
import { ErrorMessageService } from '@core/services/error-message.service';
import { NavigationService } from '@core/services/navigation.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentStore } from '@ngrx/component-store';
import {
  ComponentChange,
  ComponentChanges,
  GeneralUtils,
} from '@shared/utils/general.utils';
import {
  distinctUntilChanged,
  Observable,
  Subject,
  take,
  takeUntil,
} from 'rxjs';

export interface BoatTrailerFormState {
  localVehicle?: Partial<BoatTrailer>;
  makeModelNotFound?: boolean;
}
@Component({
  selector: 'nwx-boat-trailer-form',
  templateUrl: './boat-trailer-form.component.html',
  styleUrls: ['./boat-trailer-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore],
})
export class BoatTrailerFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() vehicle!: Partial<VehicleModel>;
  @Input() vehicleIndex!: number;

  @Output() valueChange = new EventEmitter<Partial<VehicleModel>>();
  @Output() formReady = new EventEmitter<FormGroup>();
  form!: FormGroup;
  vm$!: Observable<BoatTrailerFormState>;
  submitted = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private modal: NgbActiveModal,
    private readonly componentStore: ComponentStoreService<BoatTrailerFormState>,
    private navigationService: NavigationService,
    private errorMessageService: ErrorMessageService
  ) {}

  ngOnInit(): void {
    this.form = this.buildForm(this.vehicle);
    this.componentStore.initialize({
      localVehicle: this.vehicle.trailer,
      makeModelNotFound:
        this.vehicle.trailer?.isMakeNotFound ||
        this.vehicle.trailer?.isModelNotFound ||
        false,
    });
    this.vm$ = this.componentStore.get();

    this.watchFormForChanges();
  }
  ngOnDestroy(): void {
    this.form.disable();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  ngOnChanges(changes: ComponentChanges<BoatTrailerFormComponent>): void {
    if (
      GeneralUtils.isBoolean(
        changes.vehicle?.currentValue.trailer?.isMakeNotFound
      ) ||
      GeneralUtils.isBoolean(
        changes.vehicle?.currentValue.trailer?.isModelNotFound
      )
    ) {
      const makeModelNotFound =
        changes.vehicle?.currentValue.trailer?.isModelNotFound ||
        changes.vehicle?.currentValue.trailer?.isMakeNotFound ||
        false;
      this.componentStore.update({ makeModelNotFound });
    }
  }
  buildForm(vehicle?: Partial<VehicleModel>): FormGroup {
    return this.fb.group({
      serialNumber: this.fb.control(vehicle?.trailer?.serialNumber || null),
      doNotDisable: this.fb.control(null, []), // DO NOT DELETE
    });
  }

  watchFormForChanges() {
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged(this.hasChanges))
      .subscribe((changes: BoatTrailer) => {
        this.componentStore
          .get()
          .pipe(take(1))
          .subscribe((vm) => {
            this.componentStore.update({
              localVehicle: {
                ...vm.localVehicle,
                serialNumber: changes.serialNumber,
              },
            });
          });
      });
  }

  addChildForm(name: string, form: FormGroup): void {
    this.form.setControl(name, form);
  }
  onValueChange(changes: Partial<BoatTrailer>): void {
    this.componentStore
      .get()
      .pipe(take(1))
      .subscribe((vm) => {
        this.componentStore.update({
          localVehicle: {
            serialNumber: vm.localVehicle?.serialNumber,
            year: changes.year,
            make: changes.make,
            model: changes.model,
            msrp: changes.msrp,
            estimatedMarketValue: changes.estimatedMarketValue,
            controlNumber: changes.controlNumber,
            weight: changes.weight,
            isMakeNotFound: changes.isMakeNotFound,
            isModelNotFound: changes.isModelNotFound,
          },
          makeModelNotFound:
            changes.isMakeNotFound || changes.isModelNotFound || false,
        });
      });
  }
  hasChanges(a: BoatTrailer, b: BoatTrailer): boolean {
    return a.serialNumber === b.serialNumber;
  }
  emitChanges() {}
  onSubmit(trailer: Partial<BoatTrailer>) {
    this.submitted = true;
    if (this.form.valid) {
      this.modal.close({ vehicleId: this.vehicle.vehicleId, trailer });
    } else {
      this.navigationService.submitPage();
    }
  }
  onCancel() {
    this.errorMessageService.clearValidationErrorsForForm('boat-trailer');
    this.submitted = false;
    this.modal.close();
  }
}
