import { ProductModel } from '@core/store/entities/product/product.model';

export const Products: ProductModel[] = [
  {
    type: 'PersonalAuto',
    name: 'Auto',
    imageUrl: 'assets/images/products/personalAuto/car-circle.svg',
    isAddon: false,
    isAvailable: true,
    isDsmActive: true,
    quoteStatus: 'NotStarted',
    hasDrivers: true,
    hasVehicles: true,
    hasTelematics: true,
    uwReportsImplemented: true,
    docDelPreference: 'OnlineAccountAccess',
  },
  {
    type: 'Homeowner',
    name: 'Homeowner',
    imageUrl: 'assets/images/products/homeowner/home-circle-nwx.svg',
    isAddon: false,
    isAvailable: true,
    isDsmActive: true,
    quoteStatus: 'NotStarted',
    hasPolicyholders: true,
    uwReportsImplemented: true,
    docDelPreference: 'OnlineAccountAccess',
  },
  {
    type: 'Condominium',
    name: 'Condo',
    imageUrl: 'assets/images/products/condo/condo-circle-nwx.svg',
    isAddon: false,
    isAvailable: true,
    isDsmActive: true,
    quoteStatus: 'NotStarted',
    hasPolicyholders: true,
    uwReportsImplemented: true,
    docDelPreference: 'OnlineAccountAccess',
  },
  {
    type: 'Tenant',
    name: 'Renters',
    imageUrl: 'assets/images/products/tenant/renters_new.svg',
    isAddon: false,
    isAvailable: true,
    isDsmActive: true,
    quoteStatus: 'NotStarted',
    hasPolicyholders: true,
    uwReportsImplemented: true,
    docDelPreference: 'OnlineAccountAccess',
  },
  {
    type: 'DwellingFire',
    name: 'Dwelling Fire',
    imageUrl: 'assets/images/products/dwellingFire/fire_circle.svg',
    isAddon: false,
    isAvailable: true,
    isDsmActive: false,
    quoteStatus: 'NotStarted',
    hasPolicyholders: true,
    uwReportsImplemented: false,
    docDelPreference: 'OnlineAccountAccess',
  },
  {
    type: 'MSA',
    name: 'Motorcycle / ATV',
    imageUrl: 'assets/images/products/msa/motorcycle_circle.svg',
    isAddon: false,
    isAvailable: true,
    isDsmActive: true,
    quoteStatus: 'NotStarted',
    hasDrivers: true,
    hasVehicles: true,
    uwReportsImplemented: true,
    docDelPreference: 'OnlineAccountAccess',
  },
  {
    type: 'Boat',
    name: 'Boat',
    imageUrl: 'assets/images/products/boat/boat_circle.svg',
    isAddon: false,
    isAvailable: true,
    isDsmActive: true,
    quoteStatus: 'NotStarted',
    hasDrivers: true,
    hasVehicles: true,
    uwReportsImplemented: true,
    docDelPreference: 'OnlineAccountAccess',
  },
  {
    type: 'RV',
    name: 'RV',
    imageUrl: 'assets/images/products/rv/rv_circle.svg',
    isAddon: false,
    isAvailable: true,
    isDsmActive: true,
    quoteStatus: 'NotStarted',
    hasDrivers: true,
    hasVehicles: true,
    uwReportsImplemented: true,
    docDelPreference: 'OnlineAccountAccess',
  },
  {
    type: 'PersonalUmbrella',
    name: 'Umbrella',
    imageUrl: 'assets/images/products/umbrella/umbrella_circle.svg',
    isAddon: true,
    isAvailable: true,
    isDsmActive: true,
    quoteStatus: 'NotStarted',
    termType: 'Annual',
    uwReportsImplemented: false,
    docDelPreference: 'OnlineAccountAccess',
  },
];
