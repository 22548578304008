<div
  [ngClass]="{ 'bolt-container-fluid': flexStyles === false }"
  style="padding-left: 0; margin-left: 0"
>
  <div
    class="card-list"
    [ngClass]="{
      'bolt-row': flexStyles === false,
      'form-cards-container': flexStyles === true
    }"
  >
    <ng-container *ngFor="let cardRef of cards; let i = index">
      <div
        class="card-wrapper"
        attr.id="card-list-{{ idDiscriminator }}-{{ i }}"
        [ngClass]="{ cardClass: flexStyles === false }"
        [hidden]="hideList.includes(i) || unselectedList.includes(i)"
      >
        <ng-container [ngTemplateOutlet]="cardRef.template"></ng-container>
      </div>
    </ng-container>
  </div>
</div>
