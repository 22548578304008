import { ProductType } from '@core/models/api/dsm-types';
import { ExpectedActions } from '@core/services/action-aggregator.service';
import { Action } from '@ngrx/store';
import { PageUtils } from './page.utils';
import { StringUtils } from '../string.utils';
import { QuoteActions, UnderlyingPolicyActions } from '@core/store/actions';
import { UnderlyingPolicyEntity } from '@entities/underlying-policy/underlying-policy.entity';
import { upsertUnderlyingPolicyFail } from '../../../core/store/entities/underlying-policy/underlying-policy.action';

export class UmbrellaPageUtils extends PageUtils {
  static updateUnderlyingPolicy(
    underlyingPolicy: UnderlyingPolicyEntity,
    nextActions: Action[],
    expectedActions: ExpectedActions
  ): void {
    const correlationId = StringUtils.generateUuid();
    nextActions.push(
      UnderlyingPolicyActions.upsertUnderlyingPolicy({
        payload: underlyingPolicy,
        correlationId,
      })
    );

    expectedActions.push(
      this.getExpectedActions(
        UnderlyingPolicyActions.upsertUnderlyingPolicySuccess.type,
        UnderlyingPolicyActions.upsertUnderlyingPolicyFail.type,
        correlationId
      )
    );
  }

  static updateQuote(
    productType: ProductType,
    nextActions: Action[],
    expectedActions: ExpectedActions
  ): void {
    const correlationId = StringUtils.generateUuid();
    nextActions.push(
      QuoteActions.updateQuote({
        productType,
        correlationId,
      })
    );

    expectedActions.push(
      this.getExpectedActions(
        QuoteActions.updateQuoteSuccess.type,
        QuoteActions.updateQuoteFail.type,
        correlationId
      )
    );
  }
}
