import { createSelector } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromMortgage from '@core/store/entities/mortgage/mortgage.reducer';
import {
  AddlInterestThirdPartyType,
  ProductType,
} from '@core/models/api/dsm-types';
import { MortgageModel } from '@shared/components/forms/mortgage-form/mortgage-form.model';
import { getProduct, getSelectedProducts } from '../product/product.selectors';
import { MortgageRequest } from '@core/models/api/request/mortgage.request.model';
import { MortgageeTypes } from '@shared/constants/app-constants';
import { Nullable } from '@shared/utils/type.utils';
import { BasicCompanyInformation } from '@app/additional-interests/additional-interest-holder-selection/additional-interest-holder-selection.component';

export const getMortgageState = createSelector(
  fromCore.getCoreState,
  (state) => state.mortgage
);

export const {
  selectAll: getAllMortgages,
  selectEntities: getMortgageEntities,
} = fromMortgage.adapter.getSelectors(getMortgageState);

export const selectMortgages = (productType: ProductType) =>
  createSelector(getAllMortgages, (mortgages) => {
    return mortgages.filter(
      (mortgage) => mortgage.productType === productType
    ) as MortgageModel[];
  });

export const selectFirstMortgageOfType = (
  productType: ProductType,
  mortgageType: AddlInterestThirdPartyType
) =>
  createSelector(
    getMortgagesOfMortgageType(productType, mortgageType),
    (mortgages) => mortgages[0]
  );

export const anyProductHasMortgage = createSelector(
  getSelectedProducts,
  getAllMortgages,
  (products, mortgages) => {
    for (const product of products) {
      if (mortgages.find((m) => m.productType === product.type)) {
        return true;
      }
    }
    return false;
  }
);

export const getPrimaryMortgage = createSelector(getAllMortgages, (state) =>
  state?.find((mortage) => mortage?.type === MortgageeTypes.PRIMARY_MORTGAGEE)
);

export const getMortgagesOfMortgageType = (
  productType: ProductType,
  mortgageType: AddlInterestThirdPartyType
) =>
  createSelector(selectMortgages(productType), (mortgages) =>
    mortgages.filter((m) => m.mortgageType === mortgageType)
  );

export const getMortgagesOfMortgageTypes = (
  productType: ProductType,
  mortgageTypes: AddlInterestThirdPartyType[]
) =>
  createSelector(selectMortgages(productType), (mortgages) => {
    return mortgages.filter((m) => mortgageTypes.includes(m.mortgageType));
  });

export const getAddlInterestHolderCompanies = (
  productType: ProductType,
  mortgageTypes: AddlInterestThirdPartyType[]
) =>
  createSelector(
    getMortgagesOfMortgageTypes(productType, mortgageTypes),
    (mortgages) => {
      return mortgages
        .filter((mortgage) => !!mortgage.company)
        .map(
          (mortgage) =>
            ({
              company: mortgage.company,
              address: mortgage.address,
            } as BasicCompanyInformation)
        );
    }
  );

// filters by *company* attribute
export const getUniqueAddlInterestHolderCompanies = (
  productType: ProductType,
  mortgageTypes: AddlInterestThirdPartyType[]
) =>
  createSelector(
    getAddlInterestHolderCompanies(productType, mortgageTypes),
    (mortgages) => {
      const withoutDuplicates: BasicCompanyInformation[] = [];
      mortgages.map((m) => {
        if (!withoutDuplicates.find((el) => el.company === m.company)) {
          withoutDuplicates.push(m);
        }
      });
      return withoutDuplicates;
    }
  );

export const buildMortgageRequest = (mortgage: MortgageModel) =>
  createSelector(
    getProduct(mortgage.productType),
    (product) =>
      ({
        mortgage: {
          ...mortgage,
          address: mortgage.address,
          companyId: mortgage.companyId,
          companyName: mortgage.companyName,
          escrow: mortgage.escrow,
          loanNumber: mortgage.loanNumber,
          mortgageId: mortgage.mortgageId,
          mortgageType: mortgage.mortgageType,
          type: mortgage.type,
        },
        productType: product?.type,
        quoteId: product?.quoteId,
      } as MortgageRequest)
  );
export const buildPrimaryToSecondaryMortgageRequest = (
  productType: ProductType
) =>
  createSelector(
    getProduct(productType),
    getPrimaryMortgage,
    (product, mortgage: Nullable<fromMortgage.MortgageEntity>) =>
      mortgage
        ? ({
            mortgage: {
              address: mortgage.address,
              companyId: mortgage.companyId,
              companyName: mortgage.companyName,
              escrow: mortgage.escrow,
              loanNumber: mortgage.loanNumber,
              mortgageId: mortgage.mortgageId,
              mortgageType: mortgage.mortgageType,
              type: MortgageeTypes.MORTGAGEE,
            },
            productType: product?.type,
            quoteId: product?.quoteId,
          } as MortgageRequest)
        : null
  );
