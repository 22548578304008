<div
  class="nwx-card"
  [ngClass]="{
    'full-height': fullHeight,
    'const-card-w': flexStyles
  }"
>
  <div *ngIf="showHeader" class="nwx-card-header">
    <div style="display: inline-block">
      <ng-content select="[header]"></ng-content>
    </div>
    <div style="float: right" *ngIf="deletable" (click)="remove.emit()">
      <bolt-button
        iconleft="xmark"
        class="nwx-borderless-bolt-button"
      ></bolt-button>
    </div>
  </div>
  <div class="nwx-card-body" [class.no-padding]="noPadding">
    <ng-content></ng-content>
  </div>
</div>
