import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChildren,
  ElementRef,
  QueryList,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
} from '@angular/core';
import { PreBindDocumentUploadResponse } from '@core/models/api/response/pre-bind-document-response.model';
import { ComponentStoreService } from '@core/services/component-store.service';
import { PreBindDocumentsService } from '@core/services/pre-bind-documents.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentStore } from '@ngrx/component-store';
import {
  AUTO_PREBIND_DOCUMENTS,
  MSA_PREBIND_DOCUMENTS,
  PREBIND_DOCUMENT_FILE_NAMES,
  PREBIND_DOCUMENT_LABELS,
} from '@shared/constants/app-constants';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PreBindDocumentsFormModel } from '../pre-bind-documents-form/pre-bind-documents-form.model';

export interface PreBindDocumentsModalState {
  prebindDocumentLinkLabels?: { [id: string]: string };
  autoPrebindDocuments?: string[];
  msaPrebindDocuments?: string[];
  autoPassword?: string;
  msaPassword?: string;
  resendEmail?: boolean;
}

@Component({
  selector: 'nwx-pre-bind-documents-modal',
  templateUrl: './pre-bind-documents-modal.component.html',
  styleUrls: ['./pre-bind-documents-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStoreService, ComponentStore],
})
export class PreBindDocumentsModalComponent implements OnInit, OnDestroy {
  @Input() isAutoSelected!: boolean;
  @Input() isMsaSelected!: boolean;
  @Output() resendEmail = new EventEmitter();

  @ViewChildren('confirmationCheckbox', { read: ElementRef })
  confirmationCheckboxes!: QueryList<ElementRef>;
  btnDisabled = true;
  unsubscribe$ = new Subject<void>();
  personalAutoUploadResponse: PreBindDocumentUploadResponse | undefined;
  msaUploadResponse: PreBindDocumentUploadResponse | undefined;
  displayError = false;
  vm$!: Observable<PreBindDocumentsModalState>;

  constructor(
    public modal: NgbActiveModal,
    private preBindDocumentsService: PreBindDocumentsService,
    private readonly componentStore: ComponentStoreService<PreBindDocumentsModalState>
  ) {
    this.preBindDocumentsService
      .getPreBindDocumentUploadResponse('PersonalAuto')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (uploadResponse) => (this.personalAutoUploadResponse = uploadResponse)
      );
    this.preBindDocumentsService
      .getPreBindDocumentUploadResponse('MSA')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((uploadResponse) => (this.msaUploadResponse = uploadResponse));
  }

  ngOnInit(): void {
    this.componentStore.initialize({
      prebindDocumentLinkLabels: PREBIND_DOCUMENT_LABELS,
      autoPrebindDocuments: AUTO_PREBIND_DOCUMENTS,
      autoPassword: this.personalAutoUploadResponse?.password,
      msaPrebindDocuments: MSA_PREBIND_DOCUMENTS,
      msaPassword: this.msaUploadResponse?.password,
      resendEmail: false,
    });

    this.vm$ = this.componentStore.get();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkedAgreementBox(): void {
    const allCheckBoxes = this.confirmationCheckboxes;
    const elements = allCheckBoxes.map((el) => el.nativeElement);
    if (elements.every((el) => el.checked) && this.displayError) {
      this.displayError = false;
    }
  }

  viewDocument(document: string, productType: string): void {
    if (productType === 'PersonalAuto') {
      const fileUrl = this.personalAutoUploadResponse?.files?.find(
        (file) => file.name === PREBIND_DOCUMENT_FILE_NAMES[document]
      )?.url;
      window.open(fileUrl, '_blank');
    } else if (productType === 'MSA') {
      const fileUrl = this.msaUploadResponse?.files?.find(
        (file) => file.name === PREBIND_DOCUMENT_FILE_NAMES[document]
      )?.url;
      window.open(fileUrl, '_blank');
    }
  }

  sendEmail(): void {
    this.componentStore.update({
      resendEmail: true,
    });
    this.resendEmail.emit({});
  }

  closeModal(): void {
    this.modal.close();
  }

  continue(): void {
    const allCheckBoxes = this.confirmationCheckboxes;
    const elements = allCheckBoxes.map((el) => el.nativeElement);
    if (elements.every((el) => el.checked)) {
      const formModel: PreBindDocumentsFormModel = {
        hasReceivedDocuments: true,
        hasReviewedDocuments: true,
        doesNotHaveQuestions: true,
      };
      this.modal.close(formModel);
    } else {
      this.displayError = true;
    }
  }

  makeInvalid(box: number): boolean {
    const allCheckBoxes = this.confirmationCheckboxes;
    if (allCheckBoxes) {
      const elements = allCheckBoxes.map((el) => el.nativeElement);
      return this.displayError && !elements[box].checked;
    }
    return false;
  }
}
