import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonFormModule } from '@shared/modules/common-form.module';
import { DebugOverlayComponent } from './debug-overlay/debug-overlay.component';

@NgModule({
  declarations: [DebugOverlayComponent],
  exports: [DebugOverlayComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, ReactiveFormsModule, CommonFormModule],
})
export class DebugModule {}
