import {
  getAllDeletedLocationExposures,
  getAllLocationExposures,
  getDeletedLocationExposuresWithActivePolicy,
} from '@entities/exposures/location-exposures/location-exposures.selector';
import {
  getAllDeletedVehicleExposures,
  getAllVehicleExposures,
  getDeletedVehicleExposuresWithActivePolicy,
} from '@entities/exposures/vehicle-exposures/vehicle-exposures.selector';
import {
  getAllDeletedWatercraftExposures,
  getAllWatercraftExposures,
  getDeletedWatercraftExposuresWithActivePolicy,
} from '@entities/exposures/watercraft-exposures/watercraft-exposures.selector';
import { getAllFeatureFlags } from '@entities/feature-flag/feature-flag.selector';
import {
  getMetadataState,
  getUmbrellaOptions,
} from '@entities/metadata/metadata.selector';
import {
  UmbrellaOptionsModel,
  UnderlyingPolicyOptionsModel,
} from '@entities/metadata/models/umbrella-options.model';
import {
  getOverallFurthestQuoteStatus,
  getPolicyDateOptions,
  getProduct,
  getSelectedProductEntities,
  getSelectedProductTypes,
} from '@entities/product/product.selectors';
import { selectAllTasksByTaskEntityType } from '@entities/task/selectors/task.selector';
import { TaskUtils } from '@entities/task/utils/task.util';
import { UmbrellaTaskUtils } from '@entities/task/utils/umbrella-task.utils';
import {
  getAllUnderlyingPolicies,
  getUnderlyingPoliciesLabels,
} from '@entities/underlying-policy/underlying-policy.selector';
import { createSelector } from '@ngrx/store';
import { Nullable } from '@shared/utils/type.utils';
import { Exposures } from './umbrella.model';
import { getAllVehicleExcludedExposures, getDeletedVehicleExcludedExposures } from '@entities/exposures/vehicle-excluded-exposures/vehicle-excluded-exposures.selector';
import { getAllLocationExcludedExposures, getDeletedLocationExcludedExposures } from '@entities/exposures/location-excluded-exposures/location-excluded-exposures.selector';
import { getAllWatercraftExcludedExposures, getDeletedWatercraftExcludedExposures } from '@entities/exposures/watercraft-excluded-exposures/watercraft-excluded-exposures.selector';
import { ProductModel } from '@entities/product/product.model';
import {
  AccountSelectors,
  MemberSelectors,
  VehicleSelectors,
} from '@core/store/selectors';

export const getAllUnderlyingPolicyOptions = createSelector(
  getUmbrellaOptions,
  (umbrellaOptions): Nullable<UnderlyingPolicyOptionsModel> => {
    return umbrellaOptions?.underlyingPolicy;
  }
);

export const getUmbrellaOptionsAndFlags = createSelector(
  getMetadataState,
  getAllFeatureFlags,
  (state, featureFlags) => {
    return {
      ...state.umbrellaOptions.data,
      trueUmbrella: featureFlags?.trueUmbrella ?? false,
      umbrellaLocationExposureNotEditable:
        featureFlags?.umbrellaLocationExposureNotEditable ?? false,
      umbrellaLocationExposureDogsNotAllowed:
        featureFlags?.umbrellaLocationExposureDogsNotAllowed ?? false,
    };
  }
);

export const getAllUnderlyingAndExposureOptions = createSelector(
  getUmbrellaOptionsAndFlags,
  getUnderlyingPoliciesLabels,
  (umbrellaOptions, policyLabels): UmbrellaOptionsModel => {
    return {
      underlyingPolicy: umbrellaOptions?.underlyingPolicy,
      underlyingPolicyLabels: policyLabels,
      excludedExposureTypes: umbrellaOptions?.excludedExposureTypes,
      vehicleExposures: umbrellaOptions?.vehicleExposures,
      locationExposures: umbrellaOptions?.locationExposures,
      watercraftExposures: umbrellaOptions?.watercraftExposures,
      trueUmbrella: umbrellaOptions.trueUmbrella ?? false,
      umbrellaLocationExposureNotEditable:
        umbrellaOptions.umbrellaLocationExposureNotEditable ?? false,
      umbrellaLocationExposureDogsNotAllowed:
        umbrellaOptions.umbrellaLocationExposureDogsNotAllowed ?? false,
    };
  }
);

export const getAllExposures = createSelector(
  getAllVehicleExposures,
  getAllLocationExposures,
  getAllWatercraftExposures,
  (vehicleExposures, locationExposures, watercraftExposures) => {
    return {
      vehicleExposures: vehicleExposures,
      locationExposures: locationExposures,
      watercraftExposures: watercraftExposures,
      vehicleExcludedExposures: [],
      locationExcludedExposures: [],
      watercraftExcludedExposures: [],
    };
  }
);

export const getAllDeletedExposures = createSelector(
  getAllDeletedVehicleExposures,
  getAllDeletedLocationExposures,
  getAllDeletedWatercraftExposures,
  getDeletedVehicleExcludedExposures,
  getDeletedLocationExcludedExposures,
  getDeletedWatercraftExcludedExposures,
  (
    vehicleExposures,
    locationExposures,
    watercraftExposures,
    vehicleExcludedExposures,
    locationExcludedExposures,
    watercraftExcludedExposures
  ): Exposures => {
    return {
      vehicleExposures,
      locationExposures,
      watercraftExposures,
      vehicleExcludedExposures,
      locationExcludedExposures,
      watercraftExcludedExposures,
    };
  }
);

export const getAllDeletedExposuresWithActivePolicy = createSelector(
  getDeletedVehicleExposuresWithActivePolicy,
  getDeletedLocationExposuresWithActivePolicy,
  getDeletedWatercraftExposuresWithActivePolicy,
  getDeletedVehicleExcludedExposures,
  getDeletedLocationExcludedExposures,
  getDeletedWatercraftExcludedExposures,
  (
    vehicleExposures,
    locationExposures,
    watercraftExposures,
    vehicleExcludedExposures,
    locationExcludedExposures,
    watercraftExcludedExposures
  ): Exposures => {
    return {
      vehicleExposures,
      locationExposures,
      watercraftExposures,
      vehicleExcludedExposures,
      locationExcludedExposures,
      watercraftExcludedExposures,
    };
  }
);

export const getDeletedExposureCountWithActivePolicy = createSelector(
  getAllDeletedExposuresWithActivePolicy,
  (exposures): number => {
    return (
      (exposures?.vehicleExposures?.length || 0) +
      (exposures?.locationExposures?.length || 0) +
      (exposures?.watercraftExposures?.length || 0) +
      (exposures?.vehicleExcludedExposures?.length || 0) +
      (exposures?.locationExcludedExposures?.length || 0) +
      (exposures?.watercraftExcludedExposures?.length || 0)
    );
  }
);

export const hasExcludedExposures = createSelector(
  getAllVehicleExcludedExposures,
  getAllLocationExcludedExposures,
  getAllWatercraftExcludedExposures,
  (vehicleExExposures, locationExExposures, watercraftExExposures): boolean => {
    if (
      vehicleExExposures?.length > 0 ||
      locationExExposures?.length > 0 ||
      watercraftExExposures?.length > 0
    ) {
      return true;
    }
    return false;
  }
);

export const generateUmbrellaTasks = createSelector(
  getAllUnderlyingPolicies,
  getAllExposures,
  getAllFeatureFlags,
  getOverallFurthestQuoteStatus,
  getSelectedProductEntities,
  getPolicyDateOptions('PersonalUmbrella'),
  getProduct('PersonalUmbrella'),
  (
    underlyingPolcies,
    exposures,
    features,
    quoteStatus,
    selectedProducts,
    policyDateOptions,
    product
  ) =>
    selectedProducts.find((p) => p.type === 'PersonalUmbrella')
      ? UmbrellaTaskUtils.generateUmbrellaTasks(
          underlyingPolcies,
          exposures,
          features,
          quoteStatus || 'Draft',
          selectedProducts,
          policyDateOptions,
          product as ProductModel
        )
      : []
);

export const updateUmbrellaTasks = createSelector(
  selectAllTasksByTaskEntityType(['umbrella']),
  generateUmbrellaTasks,
  (tasks, newTasks) => TaskUtils.createUpdatedTaskObject(newTasks, tasks)
);

export interface AccountDetailsForUmbrellaAccountPoliciesModal {
  policyHolderName: string;
  accountNumber: string;
  phoneNumber: string;
  zipCode: string;
}

export const getAccountDetailsForUmbrellaAccountPoliciesModal = createSelector(
  MemberSelectors.getPrimaryNamedInsuredForProduct('PersonalUmbrella'),
  AccountSelectors.getAccountId,
  (pni, accountNumber) => {
    let policyHolderName = '';
    let phoneNumber = '';
    let zipCode = '';
    if (pni) {
      policyHolderName = (
        (pni.person?.firstName || '') +
        ' ' +
        (pni.person?.lastName || '')
      ).trim();
      const phoneBits = (pni.homeNumber || '').match(
        /^(\d{3})-(\d{3})-(\d{4})$/
      );
      if (phoneBits) {
        phoneNumber = `(${phoneBits[1]}) ${phoneBits[2]}-${phoneBits[3]}`;
      }
      zipCode = pni.address?.postalCode || '';
    }
    return {
      accountNumber: accountNumber || '',
      policyHolderName,
      phoneNumber,
      zipCode,
    };
  }
);
