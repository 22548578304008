import { getEffectiveDate } from './dsm.selector';
import { Action, createReducer, on } from '@ngrx/store';
import { DsmModel, DsmProduct } from './dsm.model';
import * as DsmActions from './dsm.action';
import { ProductType } from '@core/models/api/dsm-types';

export const DEFAULT_APPLICATION_NAME = 'Multiproduct Quote and Bind';

export type DsmState = DsmModel;

function addToProduct(
  state: DsmState,
  productId: ProductType,
  newFields: Partial<DsmProduct>
): DsmState {
  return {
    ...state,
    products: {
      ...state.products,
      [productId]: {
        ...state.products[productId],
        productId,
        ...newFields,
      },
    },
  };
}

const dsmReducer = createReducer<DsmState>(
  {
    applicationName: DEFAULT_APPLICATION_NAME,
    products: {},
    effectiveDate: '',
    callsInFlight: [],
  },

  on(DsmActions.setSessionId, (state, { productId, sessionId }) => {
    return addToProduct(state, productId, { sessionId });
  }),

  on(DsmActions.setAccessToken, (state, { productId, accessToken }) => {
    return addToProduct(state, productId, { accessToken });
  }),

  on(DsmActions.setTargetEnv, (state, { targetEnv }) => {
    return { ...state, targetEnv };
  }),
  on(DsmActions.setApplicationName, (state, { applicationName }) => {
    return { ...state, applicationName };
  }),
  on(DsmActions.setEffectiveDate, (state, { effectiveDate }) => {
    return { ...state, effectiveDate };
  }),

  on(DsmActions.addCallInFlight, (state, { name }) => {
    return {
      ...state,
      callsInFlight: [...state.callsInFlight, name],
    };
  }),
  on(DsmActions.removeCallInFlight, (state, { name }) => {
    const index = state.callsInFlight.indexOf(name);
    if (index < 0) return state;
    const callsInFlight = [...state.callsInFlight];
    callsInFlight.splice(index, 1);
    return { ...state, callsInFlight };
  })
);

export function reducer(state: DsmState | undefined, action: Action): DsmState {
  return dsmReducer(state, action);
}
