import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  Input,
} from '@angular/core';
import { ProductType } from '@core/models/api/dsm-types';
import { BillingFormService } from '@app/forms-store/services/billing-form.service';
import { from, Observable, Subject } from 'rxjs';
import { PriceSummaryDisplayContent } from '@app/hub/components/price-summary-display/price-summary-display-content';
import { QuoteLetterService } from '@core/services/quote-letter.service';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillingAccountsModalComponent } from '@app/billing-payment/components/billing-accounts-modal/billing-accounts-modal.component';
import { QuoteService } from '@core/services/quote.service';
import { ProductsService } from '@core/services/products.service';
import { LoadingService } from '@core/services/loading.service';
import { LayoutService } from '@core/services/layout.service';
import { Nullable } from '@shared/utils/type.utils';
import { UmbrellaModalContainerComponent } from '@app/umbrella/components/umbrella-modal-container/umbrella-modal-container.component';
import { CompositeActions } from '@core/store/actions';
import { Actions, ofType } from '@ngrx/effects';
import { RouterService } from '@core/services/router.service';

@Component({
  selector: 'nwx-price-summary-container',
  templateUrl: './price-summary-container.component.html',
  styleUrls: ['./price-summary-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceSummaryContainerComponent implements OnDestroy {
  @Input() maxSizeMedium!: Nullable<boolean>;

  maxSizeSmall$: Observable<boolean>;
  content$: Observable<PriceSummaryDisplayContent>;
  unsubscribe$ = new Subject<void>();

  constructor(
    private billingFormService: BillingFormService,
    private quoteLetterService: QuoteLetterService,
    private modalService: NgbModal,
    private quoteService: QuoteService,
    private productsService: ProductsService,
    private loadingService: LoadingService,
    private layoutService: LayoutService,
    private actions$: Actions,
    private routerService: RouterService
  ) {
    this.content$ =
      this.billingFormService.getPriceSummaryDisplayContentInputs();
    this.billingFormService.setBillingPlansFormVisited();
    this.maxSizeSmall$ = this.layoutService.maxSizeSmall();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onAddUpsell(productType: Nullable<ProductType>): void {
    if (productType === 'PersonalUmbrella') {
      const endLoading = this.loadingService.beginLoading('add-umbrella', 'Adding product...');
      this.actions$.pipe(
        ofType(CompositeActions.submitPniPageSuccess, CompositeActions.submitPniPageError),
        take(1),
      ).subscribe((action) => {
        if (action.type === CompositeActions.submitPniPageSuccess.type) {
          this.routerService.go({ path: ['quote', 'umbrella'] });
        }
        endLoading();
      });
      this.productsService.selectProduct('PersonalUmbrella', true);
      this.productsService.updateProductsAtHub().catch(() => {});
    }
  }

  // this modal needs to know if max size is small:
  onOpenModal(): void {
    const modal = this.modalService.open(BillingAccountsModalComponent, {
      size: 'xl',
      centered: true,
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'billingAccountsModalHeader',
    });
    modal.componentInstance.maxSizeSmall$ = this.maxSizeSmall$;
    this.billingFormService.populateBillingAccountsModal(
      modal.componentInstance
    );
    modal.componentInstance.billingPlanChanged
      .pipe(
        takeUntil(this.unsubscribe$),
        take(1),
        switchMap((form) =>
          this.productsService.getOverallQuoteStatus().pipe(
            take(1),
            map((status) => ({ form, status }))
          )
        ),
        switchMap(({ form, status }) =>
          this.billingFormService.orchestrateBillingPlanChangeWithSpinner(
            form,
            status === 'Binding'
          )
        )
      )
      .subscribe();
  }

  onShowPdf(): void {
    this.quoteLetterService.showQuoteLetterInNewTab();
  }
}
