import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[nwxClassWhenOffscreen]',
})
export class ClassWhenOffscreenDirective {
  @Input('nwxClassWhenOffscreen') className = '';

  constructor(private element: ElementRef, private window: Window) {
    this.installObserver();
  }

  private installObserver(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const IntersectionObserver = (this.window as any).IntersectionObserver;
    if (IntersectionObserver) {
      const observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          if (!this.element.nativeElement || !this.className) {
            return;
          }
          if (entries.find((e) => e.intersectionRatio < 1)) {
            this.element.nativeElement.classList.add(this.className);
          } else {
            this.element.nativeElement.classList.remove(this.className);
          }
        },
        {
          threshold: 1,
        }
      );
      observer.observe(this.element.nativeElement);
    }
  }
}
