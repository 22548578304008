import { ObjectValidator } from '@core/helper/object-validator';
import { BaseEntity } from '@core/models/entities/base.entity';

export interface ModifierEntity extends BaseEntity {
  refCode: string;
  modifierId: string;
  name: string;
  description: string;
  state: string;
  priority: number;
  modifierDataType: boolean;
  selected: boolean;
}

export function sanitizeModifierEntity(response: unknown): ModifierEntity {
  return ObjectValidator.forceSchema<ModifierEntity>(response, {
    entityId: 'any',
    prefillId: 'string',
    productIds: 'any',
    productType: 'string',
    quoteId: 'string',
    refCode: 'string',
    modifierId: 'string',
    name: 'string',
    description: 'string',
    state: 'string',
    priority: 'number',
    modifierDataType: 'boolean',
    selected: 'boolean',
  });
}
