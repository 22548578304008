export const FORM_VALIDATION_VERBIAGE: {
  [key: string]: string;
} = {
  addressNotFound: 'Address not found.',
  afterCurrentDate: 'Must be before current date.',
  afterEffectiveDate: 'Purchase date needs to be on or before effective date.',
  afterFutureDate: 'Must be within 60 days from current date.',
  arrayTooLong: 'Limit {}.',
  arrayTooShort: 'At least {} required.',
  beforeYearBuilt: 'Must be after year built ({}).',
  basementRequiredForBasementGarage:
    'A basement foundation type is required for a basement garage.',
  builtInGarageOneStory: 'A 1-story home cannot have built-in garage.',
  cardExpiryDateInPast: 'Card expiration date must in future.',
  cardNoLengthExceed: 'Card number length must be 16 digits.',
  checkNumberInvalid: 'Check or money order number must be a whole number.',
  cidLengthExceed: 'AMEX card CVV length must be 4 digits.',
  consentRequired: 'This field is required to send a text',
  courseDateInvalid: 'Invalid course date',
  cvvLengthExceed: 'CVV length must not be greater than 3 digits.',
  dateFormat: 'Must be MM/DD/YYYY format.',
  dateFormatMmYyyy: 'Must be MM/YYYY format.',
  dateInThePast: 'Expiration date can not be prior to the current date',
  dateInvalid: 'Date must be valid.',
  dateOfBirth: 'Date of Birth is Required',
  dateOfBirthFuture: 'Date of birth cannot be in the future.',
  dateOfBirthInvalid: 'Please enter MM/DD/YYYY',
  dateOfBirthTooOld: 'Field is invalid.',
  dateOfYearBuiltInvalid: 'Year built cannot be after purchased year ({})',
  driverAgeInvalidForTrainingCourse: 'Driver age ineligible for discount',
  duneBuggyMarketValueHigh: 'Market value over 15,000 is ineligible',
  duplicateLicense: 'License "{}" already in use.',
  email: 'Invalid email address.',
  emailIncomplete: 'Format should be your@address.com',
  emailRequired: 'Email is Required.',
  expirationDateNotAfterEffectiveDate:
    'Expiration date needs to be after effective date.',
  firstName: 'First Name is Required.',
  fullVerbiageProvided: '{}', // general use
  futureDate: 'Date cannot be in future',
  futurePurchaseDate: 'Purchase date cannot be in the future',
  futureYear: 'Year ({year}) can not be in the future.',
  gender: 'Gender is Required',
  giserror: 'Failed to validate address. Please try again.',
  greaterThanAge: 'Age first licensed can not be more than current age.',
  horsepowerValueHigh: 'Horsepower over 40 is ineligible',
  illegalCharacter: 'Invalid character "{}".',
  incomplete: 'Incomplete address. Please try again.',
  invalid: 'Field is invalid.',
  invalidAMEXCardLength: 'AMEX card number length must be 15 digits.',
  invalidAddress: 'Invalid address.',
  invalidAnnualMiles: 'Value must be greater than 0.',
  invalidAssociateNumber: 'Must be 6 digits',
  invalidCardNumber: 'Card number is invalid.',
  invalidDays: 'Days per week is invalid.',
  invalidHin: 'Invalid HIN',
  invalidMiles: 'Value must be greater than 0.',
  invalidMonth: 'Invalid month',
  invalidMsbNumber: 'Invalid MSB estimate number.',
  invalidMsaSubType: 'Unsupported sub type',
  invalidYear: 'Year too far in the future',
  isClosureFillCertifiedMsg:
    'Underground oil tank ineligible unless rendered inoperable and Department of Environmental Protection certificate of satisfactory closure and fill is provided',
  isPurchasedNew: 'Purchased new status required',
  isDecimal: 'Must be an integer with no more than 2 digits.',
  isUnder15: 'Driver may not be under 15 years of age.',
  lastName: 'Last Name is Required.',
  lawnandGardenTractorsMarketValueHigh:
    'Market value over 35,000 is ineligible',
  liabilityLossesIsIneligible:
    'There is an auto liability loss over $50,000 in the last three years. Please contact Nationwide Brokerage Solutions for other possible placement options. [P0115].',
  licenseNumber:
    "Driver's License number is required to Purchase an Auto Policy",
  maritalStatus: 'Marital Status is Required',
  max: 'Above maximum ({max}).',
  maxlength: 'Maximum length {requiredLength}.',
  min: 'Below minimum ({min}).',
  minlength: 'Minimum length {requiredLength}.',
  multipleAddresses: 'Matched {} addresses.',
  mustBeIncrementOf: 'Must be in increments of {}',
  mustBeWholeNumber: 'Please round to the nearest whole number',
  mustMatch: "Account numbers don't match.",
  oneMotorRequired: 'One motor required',
  phone: 'Phone Number is Required.',
  phoneNumberFormat: 'Invalid phone number.',
  phoneNumberRequired: 'Format should be 555-555-5555',
  picklistRequired: 'Field is required.',
  poBoxNotAllowed: 'Enter a residential street address.',
  postalCodeInvalid: 'Must be #####-#### format.',
  productsRequired: 'Please select the desired product(s)',
  relationToPrimaryNamedInsured: 'Relationship is required',
  required: 'This field is required to quote.',
  requiredAfter: 'Date cannot be after {}',
  requiredAfterEffectiveDate: 'Date cannot be prior to effective date',
  requiredBeforeEffectiveDate: 'Date cannot be after effective date',
  requiredBefore: 'Date cannot be prior to {}',
  rvCarHaulerValueHigh:
    'Utility trailers enclosed for transport cannot exceed $200,000 in value',
  rvUtilityTrailerValueHigh:
    'Utility trailers not enclosed for transport cannot exceed $50,000 in value',
  scheduledPersonalEffectValue:
    'Combined value of scheduled personal effects cannot exceed $99,000',
  spouseNotMarried:
    'Member must be married or separated to have a Spouse relationship to Primary Insured.',
  step: 'Must be multiple of {}.',
  street: 'Address is Required',
  stringFormat: 'Field is invalid.',
  thisDateOrEarlier: 'The date needs to be today or earlier.',
  thisYearOrEarlier: 'The year needs to be this year or earlier.',
  tooManyRegisteredOwners:
    'Please select between 1 and 2 Registered Owners per vehicle.',
  umbrellaEffectiveDateRequiredBefore:
    'Umbrella effective date cannot be earlier than effective dates of underlying policies.',
  utilityTrailerValueHigh:
    'The utility trailer valued over $12,000 is ineligible',
  vehicleRequired: 'Please add at least one vehicle',
  vin: 'VIN is Required for Final Rate',
  vinOrYmmRequired: 'VIN or year/make/model is required.',
  vinValidationError: '{}', // VinHelper provides its own verbiage
  wrongState: 'Address must be in {}.',
  year: 'Must be YYYY format.',
  yearBuiltInvalidAgainstRoofYear: 'Year built cannot be after roof year ({})',
  yearsBoatExpGreaterThanAge:
    "Years of experience cannot be greater than the driver's age.",
  zipFormat: 'Field is invalid.',
};
